import { memo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import AppTable from 'common/components/AppTable';
import ReportExportButton from '../components/ReportExportButton';
import { IMemberSummaryViewMemberField } from 'common/interfaces/report.interface';
import AppSelect from 'common/components/AppSelect';
import { getPaymentType, getProgramType } from 'common/helpers/index.helper';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import dayjs from 'dayjs';

interface Props {
  data: {
    data: IMemberSummaryViewMemberField[];
    total: number;
  };
  loading?: boolean;
  limit?: number;
  page?: number;
  onChangeLimit?: (value: number) => void;
  onChangePage?: (value: number) => void;
  onChangeMemberStatus?: (value: 'joiner' | 'leaver') => void;
  onExportReport: () => void;
}

const ReportMemberDetailedView = ({
  data,
  loading,
  page = 1,
  limit = 10,
  onChangeLimit,
  onChangePage,
  onChangeMemberStatus,
  onExportReport
}: Props) => {
  const columnHelper = createColumnHelper<IMemberSummaryViewMemberField>();

  const columns = [
    columnHelper.accessor('student', {
      header: () => <span>student ID</span>,
      cell: (info) => formatData(info.getValue()?.memberId)
    }),
    columnHelper.accessor('student', {
      header: () => <span>Student name</span>,
      cell: (info) =>
        `${formatData(info.getValue()?.lastName)}, ${formatData(
          info.getValue()?.firstName
        )}`
    }),
    columnHelper.accessor('student', {
      header: () => <span>RP Name</span>,
      cell: (info) =>
        `${formatData(
          info.getValue()?.listResponsiblePerson?.[0]?.responsiblePersonInfo
            ?.lastName
        )}, ${formatData(
          info.getValue()?.listResponsiblePerson?.[0]?.responsiblePersonInfo
            ?.firstName
        )}`
    }),
    columnHelper.accessor('memberStatus', {
      header: () => <span>Member Status</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('student', {
      header: () => <span>RP Mobile</span>,
      cell: (info) =>
        formatData(
          info.getValue()?.listResponsiblePerson?.[0]?.responsiblePersonInfo
            ?.phoneNumber
        )
    }),
    columnHelper.accessor('sessions', {
      header: () => <span>First lesson date</span>,
      cell: (info) => formatDate(info.getValue()?.[0]?.startTime)
    }),
    columnHelper.accessor('class', {
      header: () => <span>Program Type</span>,
      cell: (info) =>
        formatData(getProgramType(info.getValue().type as CLASS_TYPES))
    }),
    columnHelper.accessor('paymentType', {
      header: () => <span>Payment Type</span>,
      cell: (info) =>
        formatData(getPaymentType(info.getValue() as PAYMENT_VALUE))
    }),
    columnHelper.accessor('class', {
      header: () => <span>Class Detail</span>,
      cell: (info) => (
        <>
          {`${dayjs(info.getValue()?.startDate).format('dddd, ')} ${dayjs(
            info.getValue()?.startDate
          ).format(' HH:mm')}, ${
            info.getValue()?.allLevel
              ? 'All level'
              : formatData(info.getValue()?.levelBreakdown?.shortName)
          }, ${formatData(info.getValue()?.instructor?.lastName)}, ${formatData(
            info.getValue()?.instructor?.firstName
          )}`}
        </>
      )
    })
  ];

  const [selectedStatus, setSelectedStatus] = useState('');

  return (
    <section className="reportMemberDetailedView">
      <div className="reportMemberDetailedView__header">
        <p className="reportMemberDetailedView__header--title">Members</p>
        <div className="reportMemberDetailedView__header--actions">
          <AppSelect
            inputSize={'small'}
            label={''}
            placeholder="Filter by member status"
            searchable
            options={[
              {
                label: 'All',
                value: ''
              },
              {
                label: 'Joiner',
                value: 'joiner'
              },
              {
                label: 'Leaver',
                value: 'leaver'
              }
            ]}
            value={selectedStatus}
            onChange={(val) => {
              setSelectedStatus(val.target.value);

              if (onChangeMemberStatus) onChangeMemberStatus(val.target.value);
            }}
          />

          <ReportExportButton  onClick={onExportReport}/>
        </div>
      </div>

      <div className="reportMemberDetailedView__table">
        <AppTable
          loading={loading}
          data={data?.data}
          columns={columns}
          pagination={{
            index: page,
            size: limit,
            total: data?.total
          }}
          onChangePage={(index, size) => {
            if (onChangePage) onChangePage(index);
            if (onChangeLimit) onChangeLimit(size);
          }}
        />
      </div>
    </section>
  );
};

export default memo(ReportMemberDetailedView);
