import {
  Suspense,
  lazy,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import StudentList, { RefHandle } from 'components/Student/StudentList';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useSearchParams } from 'react-router-dom';

const StudentAdd = lazy(() => import('../StudentAdd'));

const IndexPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const ref = useRef<RefHandle>(null);

  const [openAdd, setOpenAdd] = useState(false);

  const onOpenAdd = useCallback(() => setOpenAdd(true), []);

  const isDirectAddStudent = !!searchParams.get('directAddStudent');

  const onCloseAdd = useCallback(() => {
    searchParams.delete('directAddStudent');
    setSearchParams(searchParams);

    setOpenAdd(false);

    ref.current?.refetchReset();
  }, [searchParams, setSearchParams]);

  const onAddSuccess = useCallback(
    (addAnother: boolean) => {
      if (!addAnother) {
        onCloseAdd();
      }
    },
    [onCloseAdd]
  );

  useEffect(() => {
    if (isDirectAddStudent) onOpenAdd();
  }, [isDirectAddStudent, onOpenAdd]);

  return (
    <div className="student">
      {/* ADD */}
      <PermissionWrapper permission={PERMISSION.CREATE_STUDENT}>
        <Suspense>
          <StudentAdd
            openAdd={openAdd}
            onCloseAdd={onCloseAdd}
            onSuccess={onAddSuccess}
          />
        </Suspense>
      </PermissionWrapper>

      <PermissionWrapper permission={PERMISSION.LIST_STUDENT}>
        <StudentList ref={ref} onOpenAdd={onOpenAdd} />
      </PermissionWrapper>
    </div>
  );
};

export default memo(IndexPage);
