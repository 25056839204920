export const convertArrayToString = (array: Array<string> = []) => {
  if (array.length === 0) return '';
  return array.join(',');
};

export const isValidJSON = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};
