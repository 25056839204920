import React, { memo, useEffect, useState } from 'react';

import AppSelect from 'common/components/AppSelect';
import AppButton from 'common/components/AppButton';

import { HiArrowLeft } from 'react-icons/hi2';

import { FilterValue, FilterCalendarProps } from './types';
import {
  CLASS_TEMPLATE_DURATIONS,
  PROGRAM_TYPE_OPTIONS
} from 'common/constants/index';

import './scss/calendar.scss';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
const initialValue: FilterValue = {
  classType: '',
  levelIds: '',
  instructorIds: '',
  areas: '',
  locationId: '',
  startDate: 0,
  endDate: 0,
  duration: '',
  vacancy: 0,
  termId: '',
  canBeBookMakeUp: false,
  applyVacancy: false
};
const FilterCalendar: React.FC<FilterCalendarProps> = memo(
  ({
    handleFilter,
    goBack,
    showFilter,
    currentFilter,
    areas,
    instructors,
    setVacancy,
    levels
  }: FilterCalendarProps) => {
    const [filterValue, setFilterValue] = useState<FilterValue>(currentFilter);
    useEffect(() => {
      setFilterValue(currentFilter);
    }, [currentFilter]);

    useEffect(() => {
      if (!showFilter) {
        return;
      }
    }, [showFilter]);

    const onChangeFilterValue = (
      event: React.ChangeEvent<HTMLInputElement>,
      key: string
    ) => {
      setFilterValue({ ...filterValue, [key]: event.target.value });
    };
    const onClearSelectionValue = (key: string) => {
      setFilterValue({ ...filterValue, [key]: '' });
    };
    const handleSaveAndApplyFilter = () => {
      handleFilter(filterValue, true);
    };

    const handleReset = () => {
      setVacancy(0);
      setFilterValue(initialValue);
      handleFilter(initialValue);
    };
    return (
      <div className={`filter-component ${showFilter && 'open'}`}>
        <div>
          <div
            className="filter-header"
            style={{
              paddingTop: '16px',
              paddingBottom: '16px',
              borderBottom: '1px solid #40404A'
            }}
          >
            <div className="filter-header" style={{ padding: 0 }}>
              <HiArrowLeft
                size={24}
                style={{ cursor: 'pointer' }}
                onClick={goBack}
              />
              <div className="filter-title">Filters</div>
            </div>
            <div className="reset-label" onClick={handleReset}>
              Reset
            </div>
          </div>
          <div className="filter-body">
            <AppSelect
              name="classType"
              options={PROGRAM_TYPE_OPTIONS}
              inputSize="small"
              label="Program Type"
              value={filterValue.classType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeFilterValue(e, 'classType')
              }
              onClearSelection={() => onClearSelectionValue('classType')}
              searchable={false}
            />
            <PermissionWrapper permission={PERMISSION.LIST_INSTRUCTOR}>
              <AppSelect
                options={instructors}
                inputSize="small"
                label="Instructor"
                value={filterValue.instructorIds}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeFilterValue(e, 'instructorIds')
                }
                multiValue
                onClearSelection={() => onClearSelectionValue('instructorIds')}
              />
            </PermissionWrapper>

            <PermissionWrapper permission={PERMISSION.LIST_LEVEL_BREAKDOWN}>
              <AppSelect
                options={levels}
                inputSize="small"
                label="Level"
                value={filterValue.levelIds}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeFilterValue(e, 'levelIds')
                }
                multiValue
                onClearSelection={() => onClearSelectionValue('levelIds')}
              />
            </PermissionWrapper>
            <PermissionWrapper permission={PERMISSION.LIST_LOCATION_AREA}>
              <AppSelect
                value={filterValue.areas}
                options={areas}
                inputSize="small"
                label="Area"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeFilterValue(e, 'areas')
                }
                multiValue
                searchable={false}
              />
            </PermissionWrapper>
            <AppSelect
              value={filterValue.duration}
              options={CLASS_TEMPLATE_DURATIONS}
              inputSize="small"
              label="Duration"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeFilterValue(e, 'duration')
              }
              searchable={false}
            />
          </div>
        </div>

        <div className="footer-filter">
          <PermissionWrapper permission={PERMISSION.CREATE_FILTER}>
            <AppButton
              variant="secondary"
              buttonSize="small"
              className="apply-btn"
              onClick={handleSaveAndApplyFilter}
              style={{ marginRight: '8px' }}
            >
              Save & apply
            </AppButton>
          </PermissionWrapper>
          <AppButton
            buttonSize="small"
            className="apply-btn"
            onClick={() => handleFilter(filterValue)}
          >
            Apply
          </AppButton>
        </div>
      </div>
    );
  }
);
export default FilterCalendar;
