import {
  useState,
  useEffect,
  useRef,
  lazy,
  Suspense,
  useCallback,
  memo
} from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppTabs from 'common/components/AppTabs';
import RPSummaryInfo from './RPSummaryInfo';
import ResponsiblePersonDetail from './ResponsiblePersonDetail';
import { TAB } from 'common/constants';

import './desktop.scss';

const NotificationList = lazy(
  () => import('../../../components/notifications/NotificationList')
);
const Enrollment = lazy(() => import('./Enrollment'));
const Billings = lazy(() => import('./Billings'));
const Assessment = lazy(() => import('./Assessment'));

const ResponsiblePersonDetailRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const ref = useRef<any>(null);

  const handleRefetch = useCallback(() => {
    if (ref.current) ref.current.refetch();
  }, []);

  const [tabIndex, setTabIndex] = useState<number>(TAB.IDLE);

  const tabs = [
    {
      name: 'RP detail',
      component: <ResponsiblePersonDetail onUpdated={handleRefetch} />
    },
    {
      name: 'Enrolment',
      component: <Enrollment onUpdated={handleRefetch} />
    },
    {
      name: 'Assessment',
      component: <Assessment />
    },
    {
      name: 'Billing',
      component: <Billings />
    },
    {
      name: 'Notification',
      component: <NotificationList />
    }
    // {
    //   name: 'Card/Fob',
    //   component: <AppComingSoon />
    // }
  ];

  const handleSelectedTab = useCallback(
    (val: number) => {
      setTabIndex(val);

      // delete it's sub page when it's changed
      searchParams.delete('sub1');
      searchParams.delete('sub2');
      searchParams.delete('stId');
      setSearchParams(searchParams, { replace: true });

      searchParams.set('indexRoute', `${val}`);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const isDirectAddStudent = !!searchParams.get('directAddStudent');

  useEffect(() => {
    if (!isDirectAddStudent) return;
    handleSelectedTab(0);
  }, [isDirectAddStudent, handleSelectedTab]);

  const indexRoute = Number(searchParams.get('indexRoute'));

  useEffect(() => {
    if (indexRoute >= 0 && indexRoute <= tabs.length) {
      setTabIndex(indexRoute);
    } else {
      setTabIndex(TAB.INIT);

      searchParams.set('indexRoute', `${TAB.INIT}`);
      setSearchParams(searchParams, { replace: true });
    }
  }, [indexRoute, searchParams, setSearchParams, tabs.length]);

  return (
    <div className="responsible_person_route">
      {tabIndex >= 0 ? (
        <>
          <AppBreadCrumb
            items={[
              { name: 'Responsible Person', href: '/responsible-person' },
              {
                name: tabs[tabIndex].name,
                href: location?.pathname + location?.search
              }
            ]}
          />

          <RPSummaryInfo ref={ref} />

          <div className="responsible_person_route-tab">
            <AppTabs
              tabs={tabs}
              activeTabIndex={tabIndex}
              onChangeTab={handleSelectedTab}
            />

            {tabIndex >= 0 ? (
              <Suspense>{tabs[tabIndex].component}</Suspense>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default memo(ResponsiblePersonDetailRoute);
