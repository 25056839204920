import React from 'react';
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { useToast } from 'context/ToastContext';
import { useAuth } from 'context/AuthContext';
import { Auth } from 'aws-amplify';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const axiosClient = axios.create({
  baseURL: BASE_URL
});

const AxiosWrapper: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { me, setMe, logout } = useAuth();

  const toast = useToast();

  const [isSet, setIsSet] = React.useState<boolean>(false);

  React.useEffect(() => {
    let newToken: string = '';

    const handleRequest = (config: InternalAxiosRequestConfig<any>) => {
      const token = newToken || me || '';

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    };

    const handleResponse = (response: AxiosResponse<any, any>) => {
      return response;
    };

    const handleError = async (error: any) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && originalRequest?._retry) {
        toast.error('Your account is not verified');
        logout();
      } else if (error.response?.status === 401 && !originalRequest?._retry) {
        originalRequest._retry = true;

        const user = await Auth.currentAuthenticatedUser();
        newToken = user?.signInUserSession?.idToken?.jwtToken || '';
        setMe(newToken || null);

        return axiosClient(originalRequest);
      }
      // else if (error.response.status === 403) {
      //   toast.error('You have no permission');
      // }
      return Promise.reject(error);
    };

    const requestInterceptor = axiosClient.interceptors.request.use(
      handleRequest,
      handleError
    );
    const responseInterceptor = axiosClient.interceptors.response.use(
      handleResponse,
      handleError
    );

    setIsSet(true);

    return () => {
      axiosClient.interceptors.request.eject(requestInterceptor);
      axiosClient.interceptors.response.eject(responseInterceptor);
    };
    // eslint-disable-next-line
  }, [axiosClient, me]);

  return isSet && children;
};

export default axiosClient;
export { AxiosWrapper };
