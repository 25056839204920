import React, { useMemo } from 'react';
import AppAvatar from 'common/components/AppAvatar';
import AppToolTip from 'common/components/AppToolTip';
import { Link } from 'react-router-dom';
import {
  formatData,
  formatDate,
  formatTime
} from 'common/helpers/dataFormat.helper';
import { convertLogRecurrenceToString } from 'common/helpers/time.helper';
import { IActivity } from 'common/interfaces/log.interface';
import { ACTIVITY_EVENT_TYPE } from 'common/enums/log.enum';
import { HiOutlinePhotograph } from 'react-icons/hi';
import { HiArrowSmallRight, HiOutlineCloudArrowDown } from 'react-icons/hi2';

interface IActivityProps {
  activity: IActivity;
}

const Activity = (props: IActivityProps) => {
  const { activity } = props;

  const __renderEventInformation = useMemo((): React.ReactNode => {
    switch (activity.event) {
      // BOOK CLASS === DISPLAY: INSTRUCTOR NAME + DATE/START/END TIME
      case ACTIVITY_EVENT_TYPE.BOOK_CLASS:
        return (
          <>
            <div className="activityList__main-block-item-content-info-subtitle">
              Instructor:
            </div>
            <Link
              to={`/instructors/${activity.data?.instructorId}`}
              className="activityList__main-block-item-content-info-fill"
            >
              {formatData(activity.data?.instructorName)}
            </Link>
            <div className="activityList__main-block-item-content-info-subtitle">
              Date time:
            </div>
            <div className="activityList__main-block-item-content-info-fill">
              {`${formatDate(activity?.data?.startDate)} ${formatTime(
                activity?.data?.startTime
              )} - ${formatTime(activity?.data?.endTime)}`}
            </div>
          </>
        );
      // TIME CHANGE === DISPLAY: OLD START/END TIME & NEW START/END TIME
      case ACTIVITY_EVENT_TYPE.TIME_CHANGE:
        return (
          <>
            <div className="activityList__main-block-item-content-info-fill activityList__main-block-item-content-info-fillSecondary">
              {`${formatTime(activity.data?.oldStartTime)} - ${formatTime(
                activity.data?.oldEndTime
              )}`}
            </div>
            <HiArrowSmallRight size={24} />
            <div className="activityList__main-block-item-content-info-fill activityList__main-block-item-content-info-fillSecondary">
              {`${formatTime(activity.data?.newStartTime)} - ${formatTime(
                activity.data?.newEndTime
              )}`}
            </div>
          </>
        );
      // STATUS CHANGE === DISPLAY: OLD STATUS & NEW STATUS
      case ACTIVITY_EVENT_TYPE.STATUS_CHANGE:
        return (
          <>
            <div className="activityList__main-block-item-content-info-fill activityList__main-block-item-content-info-fillSecondary">
              {`${formatData(
                activity?.data?.oldStatus ? 'ACTIVE' : 'INACTIVE'
              )}`}
            </div>
            <HiArrowSmallRight size={24} />
            <div className="activityList__main-block-item-content-info-fill activityList__main-block-item-content-info-fillSecondary">
              {`${formatData(
                activity?.data?.newStatus ? 'ACTIVE' : 'INACTIVE'
              )}`}
            </div>
          </>
        );
      // DATE CHANGE === DISPLAY: OLD START/END TIME & NEW START/END TIME
      case ACTIVITY_EVENT_TYPE.DATE_CHANGE:
        return (
          <>
            <div className="activityList__main-block-item-content-info-fill activityList__main-block-item-content-info-fillSecondary">
              {`${formatDate(activity.data?.oldStartDate)} - ${formatDate(
                activity.data?.oldEndDate
              )}`}
            </div>
            <HiArrowSmallRight size={24} />
            <div className="activityList__main-block-item-content-info-fill activityList__main-block-item-content-info-fillSecondary">
              {`${formatDate(activity.data?.newStartDate)} - ${formatDate(
                activity.data?.newEndDate
              )}`}
            </div>
          </>
        );
      // RECURRENCE CHANGE === DISPLAY: OLD RECURRENCE & NEW RECURRENCE
      case ACTIVITY_EVENT_TYPE.RECURRENCE_CHANGE:
        return (
          <>
            <div className="activityList__main-block-item-content-info-fill activityList__main-block-item-content-info-fillSecondary">
              {convertLogRecurrenceToString(activity.data.oldRecurrence)}
            </div>
            <HiArrowSmallRight size={24} />
            <div className="activityList__main-block-item-content-info-fill activityList__main-block-item-content-info-fillSecondary">
              {convertLogRecurrenceToString(activity.data.newRecurrence)}
            </div>
          </>
        );

      default:
        break;
    }
  }, [activity.event, activity.data]);

  return (
    <div className="activityList__main-block-item">
      <AppAvatar
        src={activity?.avatarUrl || ''}
        name={activity?.createdByUserName}
        size="medium"
      />
      <div className="activityList__main-block-item-content">
        <div className="activityList__main-block-item-content-info">
          {/* COMMON === DISPLAY: NAME + EVENT + CLASS */}
          <div className="activityList__main-block-item-content-info-title">
            {formatData(activity.createdByUserName)}
          </div>
          <div className="activityList__main-block-item-content-info-subtitle">
            {formatData(activity.event?.replace('_', ' '))}
          </div>
          <Link
            to={`/classes/${activity.data?.classId}`}
            className="activityList__main-block-item-content-info-fill"
          >
            {formatData(activity.data.className)}
          </Link>
          {__renderEventInformation}
        </div>

        {/* COMMON */}
        <div className="activityList__main-block-item-content-time">
          <AppToolTip
            tooltipText={`${formatDate(activity.createdAt)} at ${formatTime(
              activity.createdAt
            )}`}
            typeToolTipText="max"
          >
            {formatTime(activity.createdAt)}
          </AppToolTip>
        </div>

        {/* ABSENT === DISPLAY: INSTRUCTOR NAME + DATE/START/END TIME */}
        {activity.event === ACTIVITY_EVENT_TYPE.ABSENCE && (
          <div className="activityList__main-block-item-content-forms">
            {activity.data?.certificateMedicals?.length > 0 &&
              activity.data.certificateMedicals.map((certificateMedical) => {
                return (
                  <div
                    key={certificateMedical}
                    className="activityList__main-block-item-content-form"
                  >
                    <a
                      href={certificateMedical || '#'}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <HiOutlinePhotograph />
                    </a>
                    <div className="activityList__main-block-item-content-form-title">
                      <a
                        href={certificateMedical || '#'}
                        target="_blank"
                        rel="noreferrer"
                        className="activityList__main-block-item-content-form-title-name line-clamp-1"
                      >
                        {formatData(certificateMedical)}
                      </a>
                    </div>
                    <a
                      href={certificateMedical || '#'}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <HiOutlineCloudArrowDown />
                    </a>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Activity);
