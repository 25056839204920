export enum PATH {
  FIXED_VOUCHER = 'fixedVoucher',
  PERCENTAGE_VOUCHER = 'percentageVoucher',
  COMPLIMENTARY_VOUCHER = 'classes'
}

export const PATHS = [
  PATH.FIXED_VOUCHER,
  PATH.PERCENTAGE_VOUCHER,
  PATH.COMPLIMENTARY_VOUCHER
];

export enum MODE {
  ADD = 'add',
  EDIT = 'edit'
}