import { memo, useEffect, useRef, useState } from 'react';
import useDebounce from 'common/hooks/useDebounce';
import { IColorSetting } from 'common/interfaces/dashboard.interface';

interface Props {
  colorSettings: IColorSetting[];
  onClickLevel: (levelId: string) => void;
  onClickAllLevel: () => void;
  levelIds: string;
  onSortColorSetting: (newColorSettings: IColorSetting[]) => void;
}
const ColorLevelQuickFilter: React.FC<Props> = memo(
  ({
    colorSettings,
    onClickLevel,
    onClickAllLevel,
    levelIds,
    onSortColorSetting
  }: Props) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [draggedItem, setDraggedItem] = useState<IColorSetting>(
      colorSettings[0]
    );
    const [colorList, setColorList] = useState<IColorSetting[]>([]);

    const levelsDebounce = useDebounce(colorList, 1500);

    useEffect(() => {
      setColorList(colorSettings);
    }, [colorSettings]);

    const handleWheel = (e: any) => {
      if (!scrollContainerRef.current) return;

      scrollContainerRef.current.scrollLeft += e.deltaY;
    };

    const handleDragStart = (e: any, index: number) => {
      setDraggedItem(colorList[index]);

      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/html', e.target.parentNode);
      e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    };

    useEffect(() => {
      onSortColorSetting(levelsDebounce);
    }, [onSortColorSetting, levelsDebounce]);

    const onDragOver = (index: number) => {
      if (!draggedItem) return;
      const draggedOverItem = colorList[index];

      // if the item is dragged over itself, ignore
      if (draggedItem === draggedOverItem) {
        return;
      }

      // filter out the currently dragged item
      const items = colorList.filter((item) => item !== draggedItem);

      // add the dragged item after the dragged over item
      items.splice(index, 0, draggedItem);

      setColorList(items);
    };

    return (
      <div
        className="color-by-level-container"
        ref={scrollContainerRef}
        onWheel={handleWheel}
        onMouseOver={() => {
          document.documentElement.style.cssText = 'scrollbar-gutter: stable';
          document.body.style.overflow = 'hidden';
        }}
        onMouseOut={() => (document.body.style.overflow = 'auto')}
      >
        <div
          className={`color-by-level${!levelIds.length ? ' active' : ''}`}
          onClick={onClickAllLevel}
        >
          <div className="level-name">All Levels</div>
        </div>
        {colorList.map((level, idx) => {
          return (
            <div onDragOver={() => onDragOver(idx)} key={idx}>
              <div
                className={`color-by-level${level.active ? ' active' : ''}`}
                style={{ borderTop: `3px solid ${level.colorCode}` }}
                onClick={() => onClickLevel(level._id)}
                draggable
                onDragStart={(e) => handleDragStart(e, idx)}
              >
                <div className="level-name">{level.shortName}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
);
export default ColorLevelQuickFilter;
