export enum NOTIFICATION_TYPE {
  SMS = 'sms',
  EMAIL = 'email',
  PUSH = 'push'
}

export enum NOTIFICATION_STATUS {
  SENT = 'sent',
  SCHEDULED = 'scheduled',
  FAILED = 'failed',
  DELIVERED = 'delivered',
  DELIVERY_FAILED = 'delivery_failed',
  CANCELED = 'canceled'
}
