import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import React, { useRef, useState } from 'react';
import { HiMiniEllipsisVertical } from 'react-icons/hi2';
import './styles.scss';

interface IActionPopperProps {
  onViewStudent?: () => void;
  onAccessStudent?: () => void;
  onViewAssessment?: () => void;
  onViewAbsence?: () => void;
}

const ActionPopper: React.FC<IActionPopperProps> = (
  props: IActionPopperProps
) => {
  const { onViewStudent, onAccessStudent, onViewAssessment, onViewAbsence } =
    props;

  const popperRef = useRef<any>(null);
  const verticalButtonRef = useRef<any>(null);

  const [open, setOpen] = useState<boolean>(false);

  const onToggle = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // Close popper if click outside the popper
    const handleClickOutside = (event: MouseEvent) => {
      // Click at vertical button to close
      if (
        verticalButtonRef.current &&
        verticalButtonRef.current.contains(event.target)
      ) {
        return;
      }
      // Handle click outside to close pop up
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        onClose();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [verticalButtonRef, popperRef]);

  return (
    <React.Fragment>
      <div ref={verticalButtonRef} className="classDetail__ellipsisVertical">
        <HiMiniEllipsisVertical onClick={onToggle} />
        {open && (
          <div ref={popperRef} className="viewDetailPopup" onClick={onClose}>
            {!!onViewStudent && (
              <div className="viewDetailPopup-item" onClick={onViewStudent}>
                View Student
              </div>
            )}
            {!!onAccessStudent && (
              <PermissionWrapper
                permission={PERMISSION.CREATE_ASSESSMENT_RESULT}
              >
                <div className="viewDetailPopup-item" onClick={onAccessStudent}>
                  Assess Student
                </div>
              </PermissionWrapper>
            )}
            {onViewAssessment && (
              <div className="viewDetailPopup-item" onClick={onViewAssessment}>
                View Assessment
              </div>
            )}
            {onViewAbsence && (
              <div className="viewDetailPopup-item" onClick={onViewAbsence}>
                View Absence
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ActionPopper;
