export const KEY_ME = 'me';
export const KEY_GLOBAL_LOCATION = 'g__location';
export const KEY_USER_PROFILE_ROLE = 'g__up-role';
export const KEY_USER_PROFILE_USER = 'g__up-user';
export const KEY_STEP_CONTROLLER = 'g__step-controller';
export const KEY_THEME = 'g__theme';
export const KEY_SAVED_REGISTER_EMAIL = `g__register__email`;
export const KEY_FILTER_CLASSES = `g__filter__classes`;
export const KEY_CURRENT_ENROLMENT_CLASS = `g__current__enrolment__class`;
export const KEY_MOVE_BOOKING_COUNT = `g__move__booking__count`;
export const KEY_SPECIAL_ENROLMENT = `g__special__enrolment`;
