import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateInstructorContactDetailsDTO } from 'DTOs/instructor.dto';
import PermissionWrapper from 'components/PermissionWrapper';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import AppPhoneNumberInput from 'common/components/AppPhoneNumberInput';
import { useToast } from 'context/ToastContext';
import { PERMISSION } from 'common/enums/permission.enum';
import {
  formatData,
  formatSecretPhoneNumber
} from 'common/helpers/dataFormat.helper';
import { IInstructor } from 'common/interfaces/instructor.interface';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { HiOutlineBookmark, HiOutlineXCircle } from 'react-icons/hi';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { BeatLoader } from 'react-spinners';
import { updateInstructor } from 'services/instructor.service';
import yup from 'validators/instructor.validators';
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi';

const validationSchema = yup.OBJECT({
  phoneNumber: yup.INSTRUCTOR_MOBILE_NUMBER,
  email: yup.INSTRUCTOR_EMAIL_ADDRESS
});

interface Props {
  state: IInstructor;
  onSuccess: () => void;
}

type ShowPhoneType = { idx: number; value: boolean };

const ContactDetails = (props: Props) => {
  const { state, onSuccess } = props;
  const phoneNumberError = useRef<string>('');
  const toast = useToast();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isUpdateData, setIsUpdateData] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [instructor, setInstructor] = useState({
    ...state
  });

  const [showPhone, setShowPhone] = useState<ShowPhoneType[]>([
    {
      idx: 0,
      value: false
    }
  ]);

  const handleShowPhone = (phoneIndex: number) => {
    setShowPhone((pre: ShowPhoneType[]) => {
      if (pre?.filter((ob: ShowPhoneType) => ob.idx === phoneIndex)?.length) {
        return pre?.map((obj: ShowPhoneType) => {
          if (obj?.idx === phoneIndex) {
            return { ...obj, value: !obj?.value };
          }
          return obj;
        });
      }

      return [...pre, { idx: phoneIndex, value: true }];
    });
  };

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<UpdateInstructorContactDetailsDTO>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<UpdateInstructorContactDetailsDTO>,
    defaultValues: instructor
  });

  useEffect(() => {
    setInstructor(state);
    reset({
      ...state
    });
  }, [state, reset]);

  const onOpenEdit = () => {
    setIsEdit(true);
  };

  const onCloseEdit = useCallback(() => {
    setIsEdit(false);
    reset({
      ...state
    });
  }, [setIsEdit, reset, state]);

  const handleChangePhoneNumber = useCallback(
    (value: string) => {
      setIsUpdateData(true);
      setInstructor({
        ...instructor,
        phoneNumber: value
      });
      setValue('phoneNumber', value);
      trigger('phoneNumber');
      phoneNumberError.current = isValidPhoneNumber(value || '')
        ? ''
        : 'Invalid phone number';
    },
    [instructor, setValue, trigger]
  );
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsUpdateData(true);
      setInstructor({
        ...instructor,
        [event.target.name]: event.target.value
      });
      // @ts-ignore
      setValue(event.target.name, event.target.value);
      trigger();
    },
    [instructor, setValue, trigger]
  );

  const onSubmit = useCallback(
    async (data: UpdateInstructorContactDetailsDTO) => {
      if (!isUpdateData) {
        onCloseEdit();
        return;
      }
      // Custom error
      setLoading(true);
      try {
        const payload: UpdateInstructorContactDetailsDTO = {
          email: data.email,
          phoneNumber: data.phoneNumber
        };
        await updateInstructor(instructor._id, payload);

        reset({
          ...instructor
        });
        onCloseEdit();
        onSuccess();
        toast.success('Update instructor successfully');
      } catch (error: any) {
        toast.error(
          error.response.data.message || 'Failed to update instructor'
        );
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [instructor, reset, setIsEdit, setIsUpdateData]
  );

  const __renderIcons = useMemo((): React.ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSubmit)}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
  }, [isEdit, loading, onSubmit, onCloseEdit, handleSubmit]);

  const __renderContent = useMemo((): React.ReactNode => {
    if (isEdit) {
      return (
        <>
          <AppPhoneNumberInput
            label="Mobile Number*"
            {...register('phoneNumber')}
            value={instructor.phoneNumber}
            onChange={handleChangePhoneNumber}
            message={{
              type: 'error',
              text:
                errors?.phoneNumber?.message || phoneNumberError.current || ''
            }}
          />
          <AppInput
            label="Email Address*"
            {...register('email')}
            value={instructor.email}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.email?.message || ''
            }}
          />
        </>
      );
    }
    return (
      <>
        <AppCardContentItem subtitle="Mobile Number">
          <span className="custom_show_phone">
            {!!showPhone.filter(
              (obj: ShowPhoneType) => obj.idx === 0 && !!obj.value
            )?.length ? (
              <a href={`tel:${state?.phoneNumber}`}>{state?.phoneNumber} </a>
            ) : (
              <span>{formatSecretPhoneNumber(state?.phoneNumber)}</span>
            )}

            <button onClick={() => handleShowPhone(0)}>
              {!!showPhone.filter(
                (obj: ShowPhoneType) => obj.idx === 0 && !!obj.value
              )?.length ? (
                <HiOutlineEye fontSize={20} />
              ) : (
                <HiOutlineEyeOff fontSize={20} />
              )}
            </button>
          </span>
        </AppCardContentItem>
        <AppCardContentItem
          subtitle="Email Address"
          title={formatData(state.email)}
        />
      </>
    );
  }, [
    isEdit,
    state,
    handleChangePhoneNumber,
    errors,
    register,
    handleChange,
    instructor,
    showPhone
  ]);

  return (
    <section className="contactDetails">
      <AppCard>
        <AppCardHeader
          title="Contact Details"
          suffix={
            <PermissionWrapper permission={PERMISSION.UPDATE_INSTRUCTOR}>
              {__renderIcons}
            </PermissionWrapper>
          }
        />
        <AppCardContent className="cardContent">
          {__renderContent}
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default ContactDetails;
