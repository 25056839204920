import React from 'react';
import { useToast } from 'context/ToastContext';
import { useBrandLocation } from 'context/BrandLocationContext';
import dayjs from 'dayjs';
import { REPORT_PATH } from '../constant';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { createColumnHelper } from '@tanstack/react-table';
import {
  formatData,
  formatDate,
  formatTime
} from 'common/helpers/dataFormat.helper';
import AppTable from 'common/components/AppTable';
import { getGraduatedAndNotMovedReport } from 'services/report.service';
import { IGraduatedAndNotMovedReport } from 'common/interfaces/report.interface';
import './desktop.scss';

const ReportGraduatedAndNotMoved = () => {
  const toast = useToast();
  const { selectedLocation: __globalLocation } = useBrandLocation();

  const columnHelper = createColumnHelper<IGraduatedAndNotMovedReport>();

  const columns = [
    columnHelper.accessor('student.firstName', {
      header: () => <span>Student name</span>,
      cell: (info) => (
        <div>
          {`${formatData(info.row.original?.student?.lastName)}, ${formatData(
            info.getValue()
          )}`}
        </div>
      )
    }),
    columnHelper.accessor('student.memberId', {
      header: () => <span>student ID</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('student.dob', {
      header: () => <span>Student Age</span>,
      cell: (info) => {
        const birthDate = dayjs(info.getValue());
        const currentDate = dayjs();
        const years = currentDate.diff(birthDate, 'year');
        const months = currentDate.diff(birthDate.add(years, 'year'), 'month');

        return `${years} years ${months} months`;
      }
    }),
    columnHelper.accessor('lessonDay', {
      header: () => <span>Lesson day</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('lessonTime', {
      header: () => <span>Lesson Time</span>,
      cell: (info) => formatTime(info.getValue())
    }),
    columnHelper.accessor('level', {
      header: () => <span>Level</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('level', {
      header: () => <span>Area</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('instructor', {
      header: () => <span>Instructor</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('graduationDate', {
      header: () => <span>graduation date</span>,
      cell: (info) => formatDate(info.getValue())
    }),
    columnHelper.accessor('responsiblePerson.memberId', {
      header: () => <span>rp id</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('responsiblePerson', {
      header: () => <span>RP Name</span>,
      cell: (info) => (
        <div>
          {`${formatData(info.getValue()?.lastName)}, ${formatData(
            info.getValue()?.firstName
          )}`}
        </div>
      )
    }),
    columnHelper.accessor('responsiblePerson.phoneNumber', {
      header: () => <span>RP PHONE</span>,
      cell: (info) => formatData(info.getValue())
    })
  ];

  const [isGenerated, setIsGenerated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [limit, setLimit] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(-1);

  const [data, setData] = React.useState<Array<IGraduatedAndNotMovedReport>>(
    []
  );

  const fetchDataReport = React.useCallback(async () => {
    if (!__globalLocation?._id) return;

    setLoading(true);
    try {
      const result = await getGraduatedAndNotMovedReport(
        page,
        limit,
        __globalLocation?._id
      );
      setData(result?.data?.data?.data);
      setTotal(result?.data?.data?.total);

      setIsGenerated(true);
    } catch (error: any) {
      setData([]);
      setTotal(-1);
      toast.error(
        error?.response?.data?.message ||
          'Failed to get report graduated and not moved'
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [page, limit, __globalLocation]);

  React.useEffect(() => {
    fetchDataReport();
  }, [fetchDataReport]);

  return (
    <main
      id="reportGraduatedAndNotMoved"
      className="reportGraduatedAndNotMoved"
    >
      <AppBreadCrumb
        items={[
          { name: 'Reporting', href: REPORT_PATH },
          { name: 'Graduated and not moved' }
        ]}
      />
      <div className="layoutContainer reportGraduatedAndNotMoved__wrapper">
        {!isGenerated && loading && <AppLoadingContainer />}

        {isGenerated && (
          <section className="reportGraduatedAndNotMoved__content">
            <div className="reportGraduatedAndNotMoved__content__header">
              <p className="reportGraduatedAndNotMoved__content__header--title">
                Graduation
              </p>
            </div>

            <div className="reportGraduatedAndNotMoved__content__table">
              <AppTable
                loading={loading}
                data={data}
                columns={columns}
                pagination={{
                  index: page,
                  size: limit,
                  total: total
                }}
                onChangePage={(index: number, size: number) => {
                  setPage(index);
                  setLimit(size);
                }}
              />
            </div>
          </section>
        )}
      </div>
    </main>
  );
};

export default ReportGraduatedAndNotMoved;
