import {
  CreateNotificationTemplateDTO,
  UpdateNotificationTemplateDTO
} from './../DTOs/notificationTemplate.dto';

import API from './API';
const NOTIFICATIONS_ENDPOINT = `/notification-template`;

export const getNotificationTemplateList = (
  page: number,
  limit: number,
  keyword: string
) => {
  return API.get(`${NOTIFICATIONS_ENDPOINT}`, {
    page,
    limit,
    keyword
  });
};

export const getNotificationTemplateById = (id: string) => {
  return API.get(`${NOTIFICATIONS_ENDPOINT}/${id}`);
};

export const createNotificationTemplate = (
  notificationTemplate: CreateNotificationTemplateDTO
) => {
  return API.post(`${NOTIFICATIONS_ENDPOINT}`, {
    body: notificationTemplate
  });
};

export const updateNotificationTemplate = (
  id: string,
  notificationTemplate: UpdateNotificationTemplateDTO
) => {
  return API.put(`${NOTIFICATIONS_ENDPOINT}/${id}`, {
    body: notificationTemplate
  });
};

export const deleteNotificationTemplate = (id: string) => {
  return API.delete(`${NOTIFICATIONS_ENDPOINT}/${id}`);
};
