import AppSelect from 'common/components/AppSelect';
import { CLASS_BOOKING_PAYMENT_OPTIONS } from 'common/constants/classBooking.constant';
import { DISCOUNT_TYPE } from 'common/enums';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { BOOKING_TYPE, PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import {
  AUTOMATION_DISCOUNT_TYPE,
  DISCOUNT_FROM
} from 'common/enums/voucher.enum';
import {
  getFirstLastLessonBookingItem,
  handleDateTimeRecord
} from 'common/helpers/classBooking.helper';
import { getLessonsDayInRange } from 'common/helpers/classListBooking.helper';
import {
  formatData,
  formatMoneySign,
  roundByTwo
} from 'common/helpers/dataFormat.helper';
import { getEnrolmentType, getProgramType } from 'common/helpers/index.helper';
import { DiscountApplied } from 'common/interfaces/bookingClass.interface';
import {
  IBookingData,
  ISchedule,
  NextBillingCircle
} from 'common/interfaces/schedules.interface';
import { IVoucher } from 'common/interfaces/voucher.interface';
import PromoCodeInput from 'components/PromoCodeInput';
import { useMoveBooking } from 'context/MoveBookingContext';
import dayjs from 'dayjs';
import { handleGetPayNowForMoveBooking } from 'helpers/enrolment.helper';
import { getNewEnrolmentSessions } from 'helpers/moveBooking.helper';
import { round } from 'lodash';
import React, { useMemo } from 'react';
// import { HiOutlineExclamationCircle } from 'react-icons/hi';

interface Props {
  bookingData: IBookingData;
  onChangePaymentOption: (
    event: React.ChangeEvent<HTMLInputElement>,
    bookingData: IBookingData
  ) => void;
  onShowNextBillDay: (
    nextBillingCircle: NextBillingCircle[],
    studentId: string
  ) => void;
  onApplyPromoCode: (code: IVoucher | undefined) => void;
  discountData?: DiscountApplied;
  currentPaymentOption: PAYMENT_VALUE;
}
const ReviewBookingDataRow: React.FC<Props> = ({
  bookingData,
  onChangePaymentOption,
  onShowNextBillDay,
  onApplyPromoCode,
  discountData,
  currentPaymentOption
}: Props) => {
  const { currentEnrolmentClass, studentDataBooking, newEnrolmentClass } =
    useMoveBooking();

  const { classInfo, activeTermSchedules } = bookingData;

  const __lessonsRemaining = useMemo(() => {
    return (
      activeTermSchedules?.filter(
        (s) =>
          !dayjs(s.startTime).isBefore(
            dayjs(bookingData.schedules[0].startTime)
          )
      ) || []
    );
  }, [activeTermSchedules, bookingData.schedules]);

  const __showDiscountApplied = useMemo((): boolean => {
    if (bookingData.enrollmentType === BOOKING_TYPE.HOLIDAY_PROGRAM) {
      if (
        discountData?.discountType === DISCOUNT_TYPE.AMOUNT &&
        discountData.remainDiscountAmount === 0
      ) {
        return false;
      }
      return !!discountData;
    }
    if (
      discountData?.discountFrom === DISCOUNT_FROM.AUTOMATION_DISCOUNT &&
      discountData?.automationDiscountType ===
        AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT &&
      bookingData.paymentOption === PAYMENT_VALUE.UPFRONT
    ) {
      return false;
    }
    if (
      discountData?.discountType === DISCOUNT_TYPE.AMOUNT &&
      discountData.remainDiscountAmount === 0
    ) {
      return false;
    }
    return !!discountData;
  }, [discountData, bookingData.paymentOption, bookingData.enrollmentType]);

  const getDisplayPriceBookingItem = (bookingItem: IBookingData): string => {
    let price: string = formatMoneySign(0);
    if (bookingItem.classInfo.type === CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM) {
      const totalPrice =
        bookingItem.schedules.length * bookingItem.classInfo.price;
      return (price = `${formatMoneySign(totalPrice)} for ${
        bookingItem.schedules.length
      } lessons`);
    }
    if (bookingItem.paymentOption === PAYMENT_VALUE.DIRECT_DEBIT) {
      price = `${formatMoneySign(
        bookingItem.classInfo.price * bookingItem.schedules.length * 2
      )} per fortnight`;
    } else if (bookingItem.paymentOption === PAYMENT_VALUE.UPFRONT) {
      if (
        discountData?.discountType === DISCOUNT_TYPE.COMPLIMENTARY &&
        currentEnrolmentClass &&
        studentDataBooking
      ) {
        const { startDateFree, endDateFree } = discountData;

        const newEnrolmentSessions = getNewEnrolmentSessions(
          { ...studentDataBooking.bookingData[0] },
          newEnrolmentClass?.schedules || []
        );
        const lastLessonDateTime =
          newEnrolmentSessions?.[newEnrolmentSessions.length - 1]?.startTime;
        const endDateOfVoucher = endDateFree ? endDateFree : lastLessonDateTime;
        const endDateForNewClass = dayjs(endDateOfVoucher).isBefore(
          lastLessonDateTime
        )
          ? endDateOfVoucher
          : lastLessonDateTime;

        const lessonsFreeInNewClass = getLessonsDayInRange(
          newEnrolmentSessions as ISchedule[],
          dayjs(startDateFree).format(),
          dayjs(endDateForNewClass).format(),
          []
        );
        const totalPrice =
          lessonsFreeInNewClass.length * (newEnrolmentClass?.price || 0) +
          (bookingItem?.pricePayNow || 0);
        price = `${formatMoneySign(totalPrice)} for ${round(
          totalPrice / bookingItem.classInfo.price
        )} lessons`;
      } else {
        const priceOriginal =
          __lessonsRemaining.length * (newEnrolmentClass?.price || 0);
        price = `${formatMoneySign(priceOriginal)} for ${
          __lessonsRemaining.length
        } lessons`;
      }
    }
    return price;
  };

  const handleGetDiscountedPrice = (bookingItem: IBookingData) => {
    const { pricePayNow = 0, paymentOption, classInfo } = bookingItem;
    if (paymentOption === PAYMENT_VALUE.IDLE) return '--';
    if (discountData?.discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
      return '--';
    }
    if (classInfo.type === CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM) {
      const totalPrice =
        bookingItem.schedules.length * (newEnrolmentClass?.price || 0);
      if (discountData?.discountType === DISCOUNT_TYPE.AMOUNT) {
        if (discountData?.remainDiscountAmount === 0) return '--';
        const discountedPrice = roundByTwo(
          totalPrice - discountData?.remainDiscountAmount
        );
        return `${formatMoneySign(
          discountedPrice > 0 ? discountedPrice : 0
        )} for ${bookingItem.schedules.length} lessons`;
      }
      if (discountData?.discountType === DISCOUNT_TYPE.PERCENTAGE) {
        let amountOriginal = pricePayNow;
        if (currentEnrolmentClass?.freeLessonFromMove) {
          amountOriginal =
            bookingItem.schedules.length * (newEnrolmentClass?.price || 0);
        }
        const discountAmount = roundByTwo(
          amountOriginal * (discountData?.discountValue / 100)
        );
        const discountedPrice = roundByTwo(pricePayNow - discountAmount);
        return `${formatMoneySign(discountedPrice)} for ${
          bookingItem.schedules.length
        } lessons`;
      }
    }
    if (paymentOption === PAYMENT_VALUE.UPFRONT) {
      if (discountData?.discountFrom === DISCOUNT_FROM.VOUCHER) {
        if (discountData?.discountType === DISCOUNT_TYPE.AMOUNT) {
          const discountedPrice = roundByTwo(
            __lessonsRemaining.length * (newEnrolmentClass?.price || 0) -
              discountData?.remainDiscountAmount
          );
          return `${formatMoneySign(
            discountedPrice > 0 ? discountedPrice : 0
          )} for ${round(__lessonsRemaining.length)} lessons`;
        }
        if (discountData?.discountType === DISCOUNT_TYPE.PERCENTAGE) {
          let amountOriginal = pricePayNow;
          if (currentEnrolmentClass?.freeLessonFromMove) {
            amountOriginal =
              __lessonsRemaining.length * (newEnrolmentClass?.price || 0);
          }
          const discountAmount = roundByTwo(
            amountOriginal * (discountData?.discountValue / 100)
          );
          const discountedPrice = roundByTwo(pricePayNow - discountAmount);
          return `${formatMoneySign(discountedPrice)} for ${
            __lessonsRemaining.length
          } lessons`;
        }
      }
      if (discountData?.discountFrom === DISCOUNT_FROM.AUTOMATION_DISCOUNT) {
        if (__showDiscountApplied) {
          if (currentEnrolmentClass?.freeLessonFromMove) {
            const amountOriginal =
              __lessonsRemaining.length * (newEnrolmentClass?.price || 0);
            const discountAmount = roundByTwo(
              amountOriginal * (discountData?.discountValue / 100)
            );
            const discountedPrice = roundByTwo(amountOriginal - discountAmount);
            return `${formatMoneySign(
              discountedPrice > 0 ? discountedPrice : 0
            )} for ${round(__lessonsRemaining.length)} lessons`;
          }
          const discountAmount = roundByTwo(
            pricePayNow * (discountData?.discountValue / 100)
          );
          const discountedPrice = roundByTwo(pricePayNow - discountAmount);
          return `${formatMoneySign(discountedPrice)} for ${round(
            (pricePayNow || 0) / bookingItem.classInfo.price
          )} lessons`;
        } else {
          return '--';
        }
      }
    }
    if (paymentOption === PAYMENT_VALUE.DIRECT_DEBIT) {
      if (discountData?.discountFrom === DISCOUNT_FROM.VOUCHER) {
        if (discountData?.discountType === DISCOUNT_TYPE.AMOUNT) {
          return `--`;
        }
        if (discountData?.discountType === DISCOUNT_TYPE.PERCENTAGE) {
          const discountedAmount = roundByTwo(
            classInfo.price * 2 * (discountData?.discountValue / 100)
          );
          const discountedPrice = roundByTwo(
            classInfo.price * 2 - discountedAmount
          );
          return `${formatMoneySign(discountedPrice)} per fortnight`;
        }
      }
      if (discountData?.discountFrom === DISCOUNT_FROM.AUTOMATION_DISCOUNT) {
        const discountedAmount = roundByTwo(
          classInfo.price * 2 * (discountData?.discountValue / 100)
        );
        const discountedPrice = roundByTwo(
          classInfo.price * 2 - discountedAmount
        );
        return `${formatMoneySign(discountedPrice)} per fortnight`;
      }
    }
    return '--';
  };

  const renderPaymentOption = () => {
    let options = CLASS_BOOKING_PAYMENT_OPTIONS;
    if (currentPaymentOption === PAYMENT_VALUE.UPFRONT) {
      options = options.filter((item) => item.value === PAYMENT_VALUE.UPFRONT);
    }
    if (
      [
        CLASS_TYPES.ASSESSMENT_TRIAL,
        CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM
      ].includes(bookingData.classInfo.type)
    ) {
      return formatData(PAYMENT_VALUE.UPFRONT.replaceAll('_', ' '));
    }
    const termInLesson = bookingData.schedules[0]?.termId;
    const termIsActive = newEnrolmentClass?.template?.terms.find(
      (item) => item.termId === termInLesson
    );

    if (dayjs().isBefore(termIsActive?.termDetail?.startDate)) {
      options = options.filter((item) => item.value !== PAYMENT_VALUE.UPFRONT);
    }
    return (
      <AppSelect
        options={options}
        value={bookingData.paymentOption || ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChangePaymentOption(event, bookingData)
        }
        inputSize="small"
        searchable={false}
      />
    );
  };
  return (
    <div className="review_body_body">
      <>
        <div className="review_body_body-row">
          <div className="span3">{getProgramType(classInfo.type)}</div>
          <div className="span3">
            {getEnrolmentType(bookingData?.enrollmentType)}
          </div>
          <div className="span2">{handleDateTimeRecord(bookingData)}</div>
          <div className="span2">
            {getFirstLastLessonBookingItem(bookingData, activeTermSchedules)}
          </div>
          <div className="span3">{renderPaymentOption()}</div>

          <div className="span2" style={{ textAlign: 'right' }}>
            {getDisplayPriceBookingItem(bookingData)}
          </div>
          <div className="span2" style={{ textAlign: 'right' }}>
            {handleGetDiscountedPrice(bookingData)}
          </div>

          <div className="span2 debit_custom" style={{ textAlign: 'right' }}>
            <div className="pay-now-container">
              {/* {bookingData.paymentOption === PAYMENT_VALUE.DIRECT_DEBIT &&
                (bookingData.nextBillingCircle || [])?.length > 0 && (
                  <HiOutlineExclamationCircle
                    onClick={() =>
                      onShowNextBillDay(
                        bookingData.nextBillingCircle || [],
                        bookingData.studentId || ''
                      )
                    }
                  />
                )} */}
              {formatMoneySign(
                handleGetPayNowForMoveBooking(bookingData, discountData)
              )}
            </div>
          </div>
        </div>
        {!!discountData && __showDiscountApplied && (
          <PromoCodeInput
            onApplyPromoCode={onApplyPromoCode}
            hideInput
            discountApplied={discountData}
          />
        )}
      </>
    </div>
  );
};

export default ReviewBookingDataRow;
