import {
  CreateBulkCredentialForInstructorDTO,
  CreateCredentialForInstructorDTO,
  UpdateCredentialForInstructorDTO
} from 'DTOs/credential.dto';
import API from './API';

const CREDENTIAL_ENDPOINT = `/credentials`;

export const getCredentialById = (id: string) => {
  return API.get(`${CREDENTIAL_ENDPOINT}/${id}`);
};

export const getCredentialsForInstructor = (
  instructorId: string,
  page: number,
  limit: number
) => {
  return API.get(`${CREDENTIAL_ENDPOINT}`, {
    instructorId,
    page,
    limit
  });
};

export const createCredentialsForInstructor = (
  payload: CreateCredentialForInstructorDTO
) => {
  return API.post(`${CREDENTIAL_ENDPOINT}`, {
    body: payload
  });
};

export const updateCredentialsForInstructor = (
  id: string,
  payload: UpdateCredentialForInstructorDTO
) => {
  return API.patch(`${CREDENTIAL_ENDPOINT}/${id}`, {
    body: payload
  });
};

export const deleteCredentialsForInstructor = (id: string) => {
  return API.delete(`${CREDENTIAL_ENDPOINT}/${id}`);
};
export const createBulkCredentialsForInstructor = (
  payload: CreateBulkCredentialForInstructorDTO
) => {
  return API.post(`${CREDENTIAL_ENDPOINT}/bulk`, {
    body: payload
  });
};
