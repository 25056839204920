export enum AUTOMATION_DISCOUNT_VALUE {
  THIRD_STUDENT_ENROLLMENT = 25,
  SECOND_ENROLLMENT = 50
}

// FROM API
export enum DISCOUNT_TYPE {
  PERCENTAGE = 'percentage',
  AMOUNT = 'amount',
  COMPLIMENTARY = 'complimentary'
}

export enum DISCOUNT_FROM {
  VOUCHER = 'voucher',
  AUTOMATION_DISCOUNT = 'automation_discount'
}

export enum AUTOMATION_DISCOUNT_TYPE {
  THIRD_STUDENT_ENROLLMENT = 'third_student_enrollment',
  SECOND_ENROLLMENT = 'second_enrollment'
}

export enum VOUCHER_DURATION {
  ONE_SESSION = '1 session',
  TWO_SESSION = '2 session',
  DIRECT_DEBIT = 'direct debit',
  SPECIFIC_NUMBER_OF_LESSONS = 'specific number of lessons',
  ONGOING = 'ongoing',
  TIME_RANGE = 'time range'
}

export enum DISCOUNT_STATUS {
  USE_NOW = 'use now',
  EXPIRED = 'expired',
  NOT_STARTED = 'not started'
}
