import React from 'react';
import AppInputSearch from 'common/components/AppInputSearch';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import { IPeriodSummaryView } from 'common/interfaces/report.interface';
import { formatMoneySign } from 'common/helpers/dataFormat.helper';
import { renderCamelCaseToSpace } from '../helper';

interface IReportPeriodSummaryViewProps {
  periodSummaryView: IPeriodSummaryView;
}

const ReportPeriodSummaryView = (props: IReportPeriodSummaryViewProps) => {
  const { periodSummaryView } = props;

  const [search, setSearch] = React.useState<string>('');

  const __periodSummaryView: Array<string> = React.useMemo(() => {
    return Object.keys(periodSummaryView).filter((item: string) =>
      item
        .toLowerCase()
        .replace(/\s/g, '')
        .includes(search.toLowerCase().replace(/\s/g, ''))
    );
  }, [periodSummaryView, search]);

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  const handleClearSearch = () => {
    setSearch('');
  };

  const renderColumnData = React.useCallback((item: string, value: number) => {
    if (item === 'percentBookingsAttended') {
      return value + '%';
    } else if (item === 'totalClassTypePayment') {
      return formatMoneySign(value);
    } else return value;
  }, []);

  return (
    <section className="reportStudentBookingSummaryView">
      <div className="reportStudentBookingSummaryView__header">
        <p className="reportStudentBookingSummaryView__header--title">
          Summary View
        </p>
        <AppInputSearch
          type="text"
          startIcon={<HiMagnifyingGlass size={22} />}
          placeholder="Search"
          value={search}
          onChange={handleChangeSearch}
          onClearSearch={handleClearSearch}
        />
      </div>

      <div className="reportStudentBookingSummaryView__table">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Weekly Learn to Swim</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {__periodSummaryView.map((item: string, index: number) => {
              return (
                <tr key={index}>
                  <td>{renderCamelCaseToSpace(item)}</td>
                  <td>
                    {renderColumnData(
                      item,
                      periodSummaryView[item as keyof IPeriodSummaryView]
                        .countByType
                    )}
                  </td>
                  <td>
                    {renderColumnData(
                      item,
                      periodSummaryView[item as keyof IPeriodSummaryView].total
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default React.memo(ReportPeriodSummaryView);
