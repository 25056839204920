import { useRef, useState, memo, useCallback } from 'react';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useBrandLocation } from 'context/BrandLocationContext';
import LocationList, { RefHandle } from 'components/Location/LocationList';
import LocationAdd from '../LocationAdd';

const IndexPage = () => {
  const { onRefetchLocation } = useBrandLocation();

  const ref = useRef<RefHandle>(null);

  const [openAdd, setOpenAdd] = useState(false);

  const onOpenAdd = useCallback(() => setOpenAdd(true), []);

  const onCloseAdd = useCallback(() => {
    setOpenAdd(false);

    ref.current?.refetchReset();
  }, []);

  const onAddSuccess = useCallback(() => {
    onCloseAdd();
    onRefetchLocation();
  }, [onCloseAdd, onRefetchLocation]);

  return (
    <div className="location">
      {/* ADD */}
      <PermissionWrapper permission={PERMISSION.CREATE_LOCATION}>
        <LocationAdd
          openAdd={openAdd}
          onCloseAdd={onCloseAdd}
          onSuccess={onAddSuccess}
        />
      </PermissionWrapper>

      <PermissionWrapper permission={PERMISSION.LIST_STUDENT}>
        <LocationList ref={ref} onOpenAdd={onOpenAdd} />
      </PermissionWrapper>
    </div>
  );
};

export default memo(IndexPage);
