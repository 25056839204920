import React, { memo, useCallback, useEffect, useState } from 'react';

import AppButton from 'common/components/AppButton';
import AppSelect from 'common/components/AppSelect';
import { FilterValue } from 'pages/classes/types';
import { HiArrowLeft } from 'react-icons/hi2';
import { IInstructor } from 'common/interfaces/instructor.interface';
import { ILevelBreakdown } from 'common/interfaces/levelBreakdown.interface';
import { ILocationArea } from 'common/interfaces/locationArea.interface';
import { IOption } from 'common/interfaces';
import {
  CLASS_TEMPLATE_DURATIONS,
  PROGRAM_TYPE_OPTIONS,
  WEEK_DAY_STRING_OPTIONS
} from 'common/constants';
import { getInstructorList } from 'services/instructor.service';
import { getLevelBreakdowns } from 'services/levelBreakdown.service';
import { getLocationAreas } from 'services/locationArea.service';
import { useBrandLocation } from 'context/BrandLocationContext';
import './desktop.scss';
import { useAuth } from 'context/AuthContext';
import { PERMISSION } from 'common/enums/permission.enum';
import PermissionWrapper from 'components/PermissionWrapper';

interface FilterClassProps {
  handleFilter: (value: any, saveFilter?: boolean) => void;
  goBack: () => void;
  showFilter: boolean;
  levelSelected: string;
  currentFilter?: FilterValue;
}
const initialValue = {
  classType: '',
  level: '',
  instructor: '',
  area: '',
  dayOfWeek: '',
  duration: ''
};
const FilterClasses: React.FC<FilterClassProps> = memo(
  ({
    handleFilter,
    goBack,
    showFilter,
    levelSelected,
    currentFilter
  }: FilterClassProps) => {
    const [filterValue, setFilterValue] = useState<FilterValue>(
      currentFilter || initialValue
    );
    const [instructorList, setInstructorList] = useState<IInstructor[]>([]);
    const [levelBreakdowns, setLevelBreakdowns] = useState<ILevelBreakdown[]>(
      []
    );

    const [areas, setAreas] = useState<IOption[]>([]);
    const { selectedLocation: globalLocation } = useBrandLocation();
    const { hasPermission } = useAuth();

    const fetchData = useCallback(async () => {
      if (!globalLocation?._id) return;
      try {
        const [
          { data: instructorList },
          { data: levelBreakdowns },
          { data: areaList }
        ] = await Promise.all([
          hasPermission(PERMISSION.LIST_INSTRUCTOR) &&
            getInstructorList({
              page: 1,
              limit: 100
            }),
          hasPermission(PERMISSION.LIST_LEVEL_BREAKDOWN) &&
            getLevelBreakdowns(1, 100, globalLocation?._id),
          hasPermission(PERMISSION.LIST_LOCATION_AREA) &&
            getLocationAreas({
              page: 1,
              limit: 100,
              locationId: globalLocation?._id
            })
        ]);

        setInstructorList(
          [...(instructorList || [])].map((item: IInstructor) => ({
            ...item,
            value: item._id,
            label: item.firstName
          }))
        );
        setLevelBreakdowns(
          [...(levelBreakdowns?.data?.data || [])].map(
            (item: ILevelBreakdown) => ({
              ...item,
              value: item._id,
              label: item.shortName || item.name
            })
          )
        );
        setAreas(
          [...(areaList?.data?.data || [])].map((item: ILocationArea) => ({
            value: item.areaName,
            label: item.areaName
          }))
        );
        setFilterValue(initialValue);
      } catch (error) {
        console.log(error);
      }
    }, [globalLocation?._id, hasPermission]);

    useEffect(() => {
      fetchData();
    }, [fetchData]);

    useEffect(() => {
      setFilterValue({ ...filterValue, level: levelSelected });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [levelSelected]);

    const onChangeFilterValue = (
      event: React.ChangeEvent<HTMLInputElement>,
      key: string
    ) => {
      setFilterValue({ ...filterValue, [key]: event.target.value.trim() });
    };
    const onClearSelectionValue = (key: string) => {
      setFilterValue({ ...filterValue, [key]: '' });
    };

    const handleReset = () => {
      setFilterValue(initialValue);
      handleFilter(initialValue);
    };
    return (
      <div className={`class-filter-component ${showFilter && 'open'}`}>
        <div>
          <div className="filter-header">
            <div className="filter-header filter-header-text">
              <HiArrowLeft
                size={24}
                style={{ cursor: 'pointer' }}
                onClick={goBack}
              />
              <div className="filter-title">Filters</div>
            </div>
            <div
              className="reset-label"
              style={{ cursor: 'pointer' }}
              onClick={handleReset}
            >
              Reset
            </div>
          </div>
          <div className="filter-body">
            <AppSelect
              name="classType"
              options={[{ value: '', label: 'ALL' }, ...PROGRAM_TYPE_OPTIONS]}
              searchable={false}
              inputSize="small"
              label="Program type"
              value={filterValue.classType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeFilterValue(e, 'classType')
              }
              onClearSelection={() => onClearSelectionValue('classType')}
              multiValue
            />
            <PermissionWrapper permission={PERMISSION.LIST_LEVEL_BREAKDOWN}>
              <AppSelect
                options={levelBreakdowns}
                inputSize="small"
                multiValue
                label="Level"
                value={filterValue.level}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeFilterValue(e, 'level')
                }
                onClearSelection={() => onClearSelectionValue('level')}
              />
            </PermissionWrapper>

            <PermissionWrapper permission={PERMISSION.LIST_INSTRUCTOR}>
              <AppSelect
                options={instructorList}
                inputSize="small"
                label="Instructor"
                value={filterValue.instructor}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeFilterValue(e, 'instructor')
                }
                onClearSelection={() => onClearSelectionValue('instructor')}
                multiValue
              />
            </PermissionWrapper>
            <PermissionWrapper permission={PERMISSION.LIST_LOCATION_AREA}>
              <AppSelect
                value={filterValue.area}
                options={areas}
                inputSize="small"
                label="Area"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeFilterValue(e, 'area')
                }
                searchable={false}
                multiValue
              />
            </PermissionWrapper>
            <AppSelect
              value={filterValue.dayOfWeek}
              options={WEEK_DAY_STRING_OPTIONS}
              inputSize="small"
              label="Day"
              placeholder="Select option"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeFilterValue(e, 'dayOfWeek')
              }
              searchable={false}
              multiValue
            />
            <AppSelect
              value={filterValue.duration}
              options={CLASS_TEMPLATE_DURATIONS}
              inputSize="small"
              label="Duration (min)"
              placeholder="Select option"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeFilterValue(e, 'duration');
              }}
              searchable={false}
              multiValue
            />
          </div>
        </div>

        <div className="footer-filter">
          <AppButton
            buttonSize="small"
            className="apply-btn"
            onClick={() => handleFilter(filterValue)}
          >
            Apply
          </AppButton>
        </div>
      </div>
    );
  }
);
export default FilterClasses;
