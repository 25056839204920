import { InputHTMLAttributes } from 'react';
import './styles.scss';
import React from 'react';

interface IAppCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AppCheckbox = React.forwardRef(
  (props: IAppCheckboxProps, ref: React.Ref<HTMLInputElement>) => {
    const { label, onChange } = props;

    return (
      <label className="c__checkbox">
        {!!label && label}
        <input
          className="c__checkbox-input"
          type="checkbox"
          {...props}
          hidden={!label}
          ref={ref}
          onChange={onChange}
        />
        <span
          className={`c__checkbox-checkMark ${
            !!label ? '' : 'c__checkbox-checkMark-noLabel'
          }`}
        ></span>
      </label>
    );
  }
);

export default AppCheckbox;
