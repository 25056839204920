import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useToast } from 'context/ToastContext';
import { IMilestone, ISkill } from 'common/interfaces/levelBreakdown.interface';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import { BeatLoader } from 'react-spinners';
import {
  HiOutlineBookmark,
  HiOutlineTrash,
  HiOutlineXCircle
} from 'react-icons/hi';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import yupLevel from 'validators/level.validator';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateMilestoneDTO, UpdateSkillDTO } from 'DTOs/levelBreakdown.dto';
import AppInput from 'common/components/AppInput';
import { formatData } from 'common/helpers/dataFormat.helper';
import {
  deleteSkill,
  updateBatchMilestone,
  updateSkill
} from 'services/levelBreakdown.service';
import LevelMilestoneCard from 'pages/levels/components/LevelMilestoneCard';
import LevelMilestoneCardAdd from 'pages/levels/components/LevelMilestoneCardAdd';
import MilestoneDeleteModal from './MilestoneDeleteModal';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import AppButton from 'common/components/AppButton';
import {
  ERROR_MILESTONE_NAME,
  ERROR_SKILL_MILESTONES,
  initMilestone
} from 'pages/levels/constants';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useAuth } from 'context/AuthContext';

const validationSchema = yupLevel.OBJECT({
  name: yupLevel.SKILL_NAME
});

interface ILevelsBreakdownDetailSkillUpdateProps {
  data: ISkill;
  onSuccess: () => void;
  isCanEdit: boolean;
}

const LevelsBreakdownDetailSkillUpdate = (
  props: ILevelsBreakdownDetailSkillUpdateProps
) => {
  const { data, onSuccess } = props;

  const { hasPermission } = useAuth();

  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  const [skill, setSkill] = useState<ISkill>({
    ...data
  });

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [openRemoveSkillModal, setOpenRemoveSkillModal] =
    useState<boolean>(false);

  const [selectedRemoveMilestone, setSelectedRemoveMilestone] =
    useState<IMilestone | null>(null);

  const [newMilestones, setNewMilestones] = useState<Array<CreateMilestoneDTO>>(
    []
  );

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<UpdateSkillDTO>({
    resolver: yupResolver(validationSchema) as Resolver<UpdateSkillDTO>,
    defaultValues: skill
  });

  const onOpenEdit = () => {
    setIsEdit(true);
  };

  const onCloseEdit = () => {
    setIsEdit(false);
    setSkill({ ...data });
    reset({ ...data });
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSkill({
        ...skill,
        [event.target.name]: event.target.value
      });
      // @ts-ignore
      setValue(event.target.name, event.target.value);
      // @ts-ignore
      trigger(event.target.name);
    },
    [skill, setValue, trigger]
  );

  const onSubmit = useCallback(
    async (data: UpdateSkillDTO) => {
      // Validate with state
      const tempSkill = {
        ...skill,
        name: data.name,
        error:
          skill.milestones.length + newMilestones.length === 0
            ? ERROR_SKILL_MILESTONES
            : '',
        milestones: skill.milestones.map((milestone) => {
          return {
            ...milestone,
            error: !milestone.name ? ERROR_MILESTONE_NAME : ''
          };
        })
      };
      const tempNewMilestones = [...newMilestones].map((milestone) => {
        return {
          ...milestone,
          error: !milestone.name ? ERROR_MILESTONE_NAME : ''
        };
      });
      setSkill(tempSkill);
      setNewMilestones(tempNewMilestones);

      const isHaveErrors =
        !tempSkill.name ||
        tempSkill.milestones.length + newMilestones.length === 0 ||
        tempSkill.milestones.some((milestone) => {
          return !!milestone?.error;
        }) ||
        tempNewMilestones.some((milestone) => {
          return !!milestone?.error;
        });
      if (isHaveErrors) return;

      setLoading(true);
      try {
        const payloadSkillName: UpdateSkillDTO = {
          name: data.name
        };
        const payloadMilestones: Array<CreateMilestoneDTO> = skill.milestones
          .map((milestone) => ({
            name: milestone.name,
            position: milestone.position
          }))
          .concat(
            newMilestones.map((milestone) => ({
              name: milestone.name,
              position: milestone.position
            }))
          );

        await Promise.all([
          updateSkill(skill._id, payloadSkillName),
          updateBatchMilestone({
            skillId: skill._id,
            milestones: payloadMilestones
          })
        ]);
        onSuccess();
        toast.success('Saved');
        setIsEdit(false);
        setNewMilestones([]);
      } catch (error: any) {
        toast.error(error?.response?.data?.message || 'Failed to edit skill');
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [onSuccess, skill._id, newMilestones, skill.milestones]
  );

  // SKILL SECTION
  const handleOpenRemoveSkillModal = () => {
    setOpenRemoveSkillModal(true);
  };
  const handleCloseRemoveSkillModal = () => {
    setOpenRemoveSkillModal(false);
  };
  const handleRemoveSkill = useCallback(async () => {
    setLoading(true);
    try {
      await deleteSkill(skill._id);
      toast.success('Deleted this skill');
      onSuccess();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to delete this skill'
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [onSuccess, skill._id]);

  // EXIST MILESTONES SECTION
  const handleRemoveMilestone = useCallback(
    (milestonePosition: number) => {
      const found = skill.milestones.find(
        (milestone) => milestone.position === milestonePosition
      );
      setSelectedRemoveMilestone(found || null);
    },
    [skill.milestones]
  );
  const handleChangeMilestone = useCallback(
    (value: string, milestonePosition: number) => {
      setSkill((currentSkill) => {
        return {
          ...currentSkill,
          milestones: currentSkill.milestones.map((milestone) => {
            if (milestone.position === milestonePosition)
              return {
                ...milestone,
                name: value
              };
            else return milestone;
          })
        };
      });
    },
    []
  );
  // NEW MILESTONES SECTION
  const handleRemoveNewMilestone = useCallback(
    (milestoneIndex: number) => {
      setNewMilestones((currentNewMilestones) => {
        return currentNewMilestones
          .filter((milestone) => milestone.position !== milestoneIndex)
          .map((milestone, index) => ({
            ...milestone,
            position: skill?.milestones?.length + index
          }));
      });
    },
    [skill?.milestones?.length]
  );

  const handleChangeNewMilestone = useCallback(
    (value: string, milestonePosition: number) => {
      setNewMilestones((currentNewMilestones) => {
        return currentNewMilestones.map((newMilestone) => {
          if (newMilestone.position === milestonePosition) {
            return { ...newMilestone, name: value };
          }
          return newMilestone;
        });
      });
    },
    []
  );
  const handleAddMilestone = useCallback(() => {
    setNewMilestones((currentNewMilestones) => [
      ...currentNewMilestones,
      {
        ...initMilestone,
        position: skill?.milestones?.length + newMilestones?.length
      }
    ]);
  }, [skill?.milestones?.length, newMilestones?.length]);

  const handleCancelRemoveMilestone = () => {
    setSelectedRemoveMilestone(null);
  };
  const handleRemoveMilestoneSuccess = () => {
    onSuccess();
    handleCancelRemoveMilestone();
  };

  const __renderIcons = useMemo((): React.ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSubmit)}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <>
        <PermissionWrapper permission={PERMISSION.UPDATE_SKILL}>
          <div className="icon" onClick={onOpenEdit}>
            <HiOutlinePencilSquare />
          </div>
        </PermissionWrapper>
        <PermissionWrapper permission={PERMISSION.DELETE_SKILL}>
          <div className="icon" onClick={handleOpenRemoveSkillModal}>
            <HiOutlineTrash />
          </div>
        </PermissionWrapper>
      </>
    );
    // eslint-disable-next-line
  }, [isEdit, onCloseEdit, loading, onSubmit]);

  const __renderContent = useMemo((): React.ReactNode => {
    if (isEdit) {
      return (
        <>
          <AppInput
            label="Skill of the level*"
            {...register('name')}
            id={`skill-input-update-${skill._id}`}
            value={skill.name}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.name?.message || skill.error || ''
            }}
          />
          <div className="milestones edit">
            {skill.milestones.length > 0 &&
              skill.milestones.map((milestone, index) => {
                return (
                  <LevelMilestoneCard
                    key={milestone?._id || index}
                    milestone={milestone}
                    onRemoveMilestone={
                      hasPermission(PERMISSION.DELETE_MILESTONE)
                        ? handleRemoveMilestone
                        : undefined
                    }
                    onChangeMilestone={
                      hasPermission(PERMISSION.UPDATE_MILESTONE)
                        ? handleChangeMilestone
                        : undefined
                    }
                    errorMessage={milestone.error}
                  />
                );
              })}

            {newMilestones.length > 0 &&
              newMilestones.map((milestone) => {
                return (
                  <LevelMilestoneCard
                    key={milestone.position}
                    milestone={milestone}
                    onRemoveMilestone={handleRemoveNewMilestone}
                    onChangeMilestone={handleChangeNewMilestone}
                    index={milestone.position}
                    errorMessage={milestone.error}
                  />
                );
              })}

            <PermissionWrapper permission={PERMISSION.UPDATE_MILESTONE}>
              <div onClick={handleAddMilestone}>
                <LevelMilestoneCardAdd />
              </div>
            </PermissionWrapper>
          </div>
        </>
      );
    }
    return (
      <>
        <AppCardContentItem
          subtitle="Skill of the level"
          title={formatData(skill?.name)}
        />
        <div className="milestones">
          {skill.milestones.length > 0 &&
            skill.milestones.map((milestone) => {
              return (
                <LevelMilestoneCard key={milestone._id} milestone={milestone} />
              );
            })}
        </div>
      </>
    );
  }, [
    isEdit,
    handleChange,
    register,
    errors,
    skill,
    handleRemoveMilestone,
    newMilestones,
    handleChangeMilestone,
    handleAddMilestone,
    handleChangeNewMilestone,
    handleRemoveNewMilestone,
    hasPermission
  ]);

  useEffect(() => {
    const matchMilestones = [...data.milestones].map((itemOfData) => {
      const matchingItem = [...skill.milestones].find(
        (itemOfSkill) => itemOfSkill._id === itemOfData._id
      );
      if (matchingItem) {
        return { ...itemOfData, name: matchingItem.name };
      }
      return itemOfData;
    });

    const newData = {
      ...data,
      milestones: matchMilestones
    };

    setSkill(newData);
    // eslint-disable-next-line
  }, [data]);

  return (
    <section className="levelSkill">
      <AppModal
        open={openRemoveSkillModal}
        onClose={handleCloseRemoveSkillModal}
      >
        <AppModalTitle>Delete skill</AppModalTitle>
        <AppModalContent>
          ARE YOU SURE YOU WANT TO DELETE THIS skill?
        </AppModalContent>
        <AppModalActions>
          <AppButton
            disabled={loading}
            variant="secondary"
            onClick={handleCloseRemoveSkillModal}
          >
            No
          </AppButton>
          <AppButton isLoading={loading} onClick={handleRemoveSkill}>
            Yes
          </AppButton>
        </AppModalActions>
      </AppModal>

      {/* DELETE MILESTONE */}
      {!!selectedRemoveMilestone && (
        <MilestoneDeleteModal
          milestone={selectedRemoveMilestone}
          onClose={handleCancelRemoveMilestone}
          onSuccess={handleRemoveMilestoneSuccess}
        />
      )}

      <AppCard>
        <AppCardHeader title="Skill" suffix={__renderIcons} />
        <AppCardContent
          className={`cardContent ${isEdit ? 'cardContent-edit' : ''}`}
        >
          {__renderContent}
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default LevelsBreakdownDetailSkillUpdate;
