import React, { useCallback, useEffect, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppTable from 'common/components/AppTable';
import AppCard from 'common/components/AppCard';
import AppButton from 'common/components/AppButton';
import AppInputSearch from 'common/components/AppInputSearch';
import { useBrandLocation } from 'context/BrandLocationContext';
import useDebounce from 'common/hooks/useDebounce';
import { HiChevronDown, HiOutlineDocumentDownload } from 'react-icons/hi';
import ViewDetailPopper from './components/ViewDetailPopper';
import AppDatePicker from 'common/components/AppDatePicker';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import { getStudentLogs } from 'services/log.service';
import { ILog, ILogSearch } from 'common/interfaces/log.interface';
import {
  formatData,
  formatDate,
  formatTime
} from 'common/helpers/dataFormat.helper';
import { LOG_EVENT_TYPE } from 'common/enums/log.enum';
import CreateAuditLogModal from './components/CreateAuditLogModal';
import UpdateAuditLogModal from './components/UpdateAuditLogModal';
import dayjs from 'dayjs';
import { convertToUnixTime } from 'common/helpers/time.helper';
import AppInput from 'common/components/AppInput';
import './desktop.scss';
import { USER_TYPE } from 'common/enums/user.enum';

const initialSearch: ILogSearch = {
  keyword: '',
  what: '',
  fromDate: '',
  toDate: ''
};

const AuditLogList = () => {
  const columnHelper = createColumnHelper<ILog>();

  const columns = [
    columnHelper.accessor('createdAt', {
      header: () => <span>date, time</span>,
      cell: (info) => (
        <div>
          {formatDate(info.getValue())} {formatTime(info.getValue())}
        </div>
      )
    }),
    columnHelper.accessor('createdByUserName', {
      header: () => <span>Actor</span>,
      cell: (info) => (
        <div>
          {info?.row.original?.createdByUserType ===
          USER_TYPE.RESPONSIBLE_PERSON
            ? 'RP'
            : USER_TYPE.STAFF}
          : {formatData(info.getValue())}
        </div>
      )
    }),
    columnHelper.accessor('event', {
      header: () => <span>Event type</span>,
      cell: (info) => <div>{formatData(info.getValue())} student</div>
    }),
    columnHelper.accessor('data', {
      header: () => <span>Student Name</span>,
      cell: (info) => (
        <div>
          {formatData(
            `${info.getValue()?.lastName}, ${info.getValue()?.firstName}`
          )}
        </div>
      )
    }),
    columnHelper.accessor('_id', {
      header: () => (
        <span
          style={{
            display: 'block',
            textAlign: 'center'
          }}
        >
          Action
        </span>
      ),
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <ViewDetailPopper
              log={info.row.original}
              onClick={(log: ILog) => {
                if (log.event === LOG_EVENT_TYPE.CREATE)
                  setSelectedCreateLog(log);
                else if (log.event === LOG_EVENT_TYPE.UPDATE)
                  setSelectedUpdateLog(log);
              }}
            />
          </div>
        );
      }
    })
  ];

  const { selectedLocation: globalLocation } = useBrandLocation();

  const [loading, setLoading] = useState<boolean>(true);

  const [logs, setLogs] = useState<Array<ILog>>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageTotal, setPageTotal] = useState<number>(-1);

  const [selectedCreateLog, setSelectedCreateLog] = useState<ILog | null>(null);
  const [selectedUpdateLog, setSelectedUpdateLog] = useState<ILog | null>(null);

  const [openSearch, setOpenSearch] = useState<boolean>(false);

  const [search, setSearch] = useState<ILogSearch>(initialSearch);

  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const debouncedSearchKeyword = useDebounce<string>(searchKeyword);
  // Search input
  const onChangeSearchKeyword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchKeyword(event.target.value);
  };
  const onClearSearchKeyword = () => {
    setSearchKeyword('');
  };
  // Search component
  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({ ...search, [event.target.name]: event.target.value });
  };
  const onClearSearch = (key: string) => {
    setSearch({ ...search, [key]: '' });
  };
  const onChangeFromDate = (value: dayjs.Dayjs | null) => {
    setSearch({ ...search, fromDate: value?.format('YYYY-MM-DD') || '' });
  };
  const onChangeToDate = (value: dayjs.Dayjs | null) => {
    setSearch({ ...search, toDate: value?.format('YYYY-MM-DD') || '' });
  };

  const onToggleSearch = () => {
    setOpenSearch(!openSearch);
  };
  const onCloseSearch = () => {
    setOpenSearch(false);
  };

  const onSearch = () => {
    setSearchKeyword(search.keyword);
    fetchData(search.keyword, search.what, search?.fromDate, search?.toDate);
    onCloseSearch();
  };
  const onReset = () => {
    setSearch(initialSearch);
    setSearchKeyword('');
    fetchData('', '', undefined, undefined);
    onCloseSearch();
  };

  const fetchData = useCallback(
    async (
      keyword: string,
      what: string,
      fromDate?: string,
      toDate?: string
    ) => {
      setLoading(true);
      try {
        const result = await getStudentLogs(
          globalLocation?._id || '',
          pageIndex,
          pageSize,
          keyword,
          what,
          !!fromDate ? convertToUnixTime(fromDate) : undefined,
          !!toDate
            ? convertToUnixTime(toDate)
            : convertToUnixTime(dayjs().format('YYYY-MM-DD'))
        );
        setLogs(result.data.data.data);
        setPageTotal(result.data.data.total);
      } catch (error) {
        setLogs([]);
      } finally {
        setLoading(false);
      }
    },
    [globalLocation?._id, pageIndex, pageSize]
  );

  useEffect(() => {
    fetchData(search.keyword, search.what, search.fromDate, search.toDate);
    // eslint-disable-next-line
  }, [fetchData]);

  useEffect(() => {
    setPageIndex(1);
  }, [globalLocation?._id]);

  useEffect(() => {
    setPageIndex(1);
    if (searchKeyword !== search.keyword) {
      fetchData(searchKeyword, search.what, search.fromDate, search.toDate);
      setSearch({ ...search, keyword: searchKeyword });
    }
    // eslint-disable-next-line
  }, [debouncedSearchKeyword]);

  return (
    <main className="auditLogListPage">
      {/* CREATE MODAL */}
      {!!selectedCreateLog && (
        <CreateAuditLogModal
          log={selectedCreateLog}
          open={!!selectedCreateLog}
          onClose={() => setSelectedCreateLog(null)}
        />
      )}

      {/* UPDATE MODAL */}
      {!!selectedUpdateLog && (
        <UpdateAuditLogModal
          log={selectedUpdateLog}
          open={!!selectedUpdateLog}
          onClose={() => setSelectedUpdateLog(null)}
        />
      )}

      {/* MAIN */}
      <AppBreadCrumb items={[{ name: 'Audit Log', href: '/audit-log' }]} />
      <div className="layoutContainer auditLogList">
        <AppCard>
          <div className="auditLogList__table">
            <div className="auditLogList__table-header">
              <h2 className="auditLogList__table-header-title">Audit log(s)</h2>
              <div className="auditLogList__table-header-search">
                <div className="searchContainer">
                  <AppInputSearch
                    type="text"
                    placeholder="Name"
                    name="keyword"
                    value={searchKeyword}
                    onChange={onChangeSearchKeyword}
                    onClearSearch={onClearSearchKeyword}
                    startIcon={<HiMagnifyingGlass />}
                    endIcon={
                      <div
                        className={`arrowIcon ${openSearch ? 'active' : ''}`}
                        onClick={onToggleSearch}
                      >
                        <HiChevronDown />
                      </div>
                    }
                  />
                  <div
                    className={`searchComponent ${openSearch ? 'active' : ''}`}
                  >
                    <AppInput
                      inputSize="small"
                      label="What"
                      name="what"
                      value={search.what}
                      onChange={onChangeSearch}
                      onClearSearch={() => onClearSearch('what')}
                    />
                    <AppInput
                      inputSize="small"
                      label="Who"
                      name="keyword"
                      value={search.keyword}
                      onChange={onChangeSearch}
                      onClearSearch={() => onClearSearch('keyword')}
                    />
                    <AppDatePicker
                      size="small"
                      label="Date"
                      value={dayjs(search.fromDate)}
                      onChange={onChangeFromDate}
                      maxDate={dayjs(search.toDate)}
                      disableFuture
                    />
                    <AppDatePicker
                      size="small"
                      label="Date"
                      value={dayjs(search.toDate)}
                      onChange={onChangeToDate}
                      minDate={dayjs(search.fromDate)}
                      disableFuture
                    />

                    <div className="searchComponent__actions">
                      <AppButton
                        variant="secondary"
                        buttonSize="small"
                        isLoading={loading}
                        onClick={onReset}
                      >
                        Reset
                      </AppButton>
                      <AppButton
                        variant="primary"
                        buttonSize="small"
                        isLoading={loading}
                        onClick={onSearch}
                      >
                        Search
                      </AppButton>
                    </div>
                  </div>
                </div>

                <AppButton variant="secondary" buttonSize="small" type="submit">
                  <HiOutlineDocumentDownload />
                  Export
                </AppButton>
              </div>
            </div>
            <div className="auditLogList__table-content">
              <AppTable
                data={logs}
                columns={columns}
                pagination={{
                  index: pageIndex,
                  size: pageSize,
                  total: pageTotal
                }}
                onChangePage={(index: number, size: number) => {
                  setPageIndex(index);
                  setPageSize(size);
                }}
                loading={loading}
              />
            </div>
          </div>
        </AppCard>
      </div>
    </main>
  );
};

export default AuditLogList;
