import {
  ChangeEvent,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import { BeatLoader } from 'react-spinners';
import { useToast } from 'context/ToastContext';
import {
  HiOutlinePencilSquare,
  HiOutlineXCircle,
  HiOutlineBookmark
} from 'react-icons/hi2';
import AppSelect from 'common/components/AppSelect';
import { formatData } from 'common/helpers/dataFormat.helper';
import { ILevel } from 'common/interfaces/levelBreakdown.interface';
import { useParams } from 'react-router-dom';
import {
  getLevelByStudentId,
  updateLevelByStudent
} from 'services/students.service';
import { getLevelBreakdowns } from 'services/levelBreakdown.service';
import { IOption } from 'common/interfaces';
import { useBrandLocation } from 'context/BrandLocationContext';

import './desktop.scss';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';

interface Props {
  onUpdated: () => void;
}

const AssessmentLevel = ({ onUpdated }: Props) => {
  const params = useParams();
  const toast = useToast();
  const { selectedLocation } = useBrandLocation();

  const [loadingAPI, setLoadingAPI] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [levels, setLevels] = useState<ILevel[]>([]);
  const [level, setLevel] = useState<{
    actualLevel?: ILevel;
    enrolledLevel?: ILevel;
    trialLevel?: ILevel;
  } | null>(null);
  const initLevel = useRef<{
    actualLevel?: ILevel;
    enrolledLevel?: ILevel;
    trialLevel?: ILevel;
  } | null>(null);

  const handleOpenEdit = useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleCloseEdit = useCallback(() => {
    setLevel(initLevel.current);
    setIsEdit(false);
  }, []);

  const handleChangeActualAssessment = useCallback(
    (id: string) => {
      setLevel((prev) => {
        return {
          ...prev,
          actualLevel: levels.find((level) => level._id === id)
        };
      });
    },
    [levels]
  );

  const handleChangeTrialAssessment = useCallback(
    (id: string) => {
      setLevel((prev) => {
        return {
          ...prev,
          trialLevel: levels.find((level) => level._id === id)
        };
      });
    },
    [levels]
  );

  const handleSave = useCallback(async () => {
    try {
      const payload: {
        trialLevelId?: string;
        actualLevelId?: string;
      } = {};

      if (level?.actualLevel?._id)
        payload.actualLevelId = level.actualLevel._id;
      if (level?.trialLevel?._id) payload.trialLevelId = level.trialLevel._id;

      setLoadingAPI(true);

      await updateLevelByStudent(params.id || '', payload);

      toast.success('Saved successfully');

      setIsEdit(false);

      onUpdated();
      fetchData();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || `Failed to update student level`
      );
    } finally {
      setLoadingAPI(false);
    }
    // eslint-disable-next-line
  }, [level, onUpdated]);

  const __renderIcons = useMemo((): ReactNode => {
    if (isEdit) {
      return loadingAPI ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSave}>
            <HiOutlineBookmark size={24} />
          </div>
          <div className="icon" onClick={handleCloseEdit}>
            <HiOutlineXCircle size={24} />
          </div>
        </>
      );
    }
    return (
      <PermissionWrapper permission={PERMISSION.UPDATE_LEVEL_STUDENT}>
        <div className="icon" onClick={handleOpenEdit}>
          <HiOutlinePencilSquare size={24} />
        </div>{' '}
      </PermissionWrapper>
    );
  }, [loadingAPI, isEdit, handleCloseEdit, handleSave, handleOpenEdit]);

  const __levelOptions = useMemo((): IOption[] => {
    return levels.map((level) => ({ value: level._id, label: level.name }));
  }, [levels]);

  const __renderContent = useMemo((): ReactNode => {
    if (isEdit) {
      return (
        <div className="assessmentInStudentDetail__level__card">
          <AppSelect
            disabled
            label="New Student Assessment"
            options={__levelOptions}
            value={level?.trialLevel?._id || ''}
            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
              handleChangeTrialAssessment(event.target.value)
            }
          />
          <AppSelect
            label="Enrolment Level"
            options={__levelOptions}
            value={level?.enrolledLevel?._id || ''}
            disabled
          />
          <AppSelect
            label="Current Level"
            options={__levelOptions}
            value={level?.actualLevel?._id || level?.enrolledLevel?._id || ''}
            onChange={(event: ChangeEvent<HTMLSelectElement>) =>
              handleChangeActualAssessment(event.target.value)
            }
          />
        </div>
      );
    } else {
      return (
        <div className="assessmentInStudentDetail__level__card">
          <AppCardContentItem
            subtitle="New Student Assessment"
            title={formatData(level?.trialLevel?.name)}
          />
          <AppCardContentItem
            subtitle="Enrolment Level"
            title={formatData(level?.enrolledLevel?.name)}
          />
          <AppCardContentItem
            subtitle="Current Level"
            title={formatData(
              level?.actualLevel?.name || level?.enrolledLevel?.name
            )}
          />
        </div>
      );
    }
  }, [
    isEdit,
    level,
    __levelOptions,
    handleChangeActualAssessment,
    handleChangeTrialAssessment
  ]);

  const fetchData = useCallback(async () => {
    if (!params.id) return;

    try {
      const [{ data: dataLevel }, { data: dataLevels }] = await Promise.all([
        getLevelByStudentId(params.id),
        getLevelBreakdowns(1, 100, selectedLocation?._id || '')
      ]);
      initLevel.current = dataLevel.data;
      setLevel(dataLevel.data);
      setLevels(dataLevels.data.data);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Failed to get level');
      initLevel.current = null;
      setLevel(null);
      setLevels([]);
    } finally {
      setLoadingAPI(false);
    }
    // eslint-disable-next-line
  }, [params.id, selectedLocation]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="assessmentInStudentDetail__level">
      <AppCard>
        <AppCardHeader title="Level" suffix={__renderIcons} />
        <AppCardContent>{__renderContent}</AppCardContent>
      </AppCard>
    </div>
  );
};

export default memo(AssessmentLevel);
