import { yupResolver } from '@hookform/resolvers/yup';
import {
  CreateUpdateVoucherDTO,
  CreateUpdateVoucherPayloadDTO
} from 'DTOs/voucher.dto';
import AppButton from 'common/components/AppButton';
import AppCard, {
  AppCardContent,
  AppCardHeader
} from 'common/components/AppCard';
import AppDatePicker from 'common/components/AppDatePicker';
import AppInput from 'common/components/AppInput';
import AppSelect from 'common/components/AppSelect';
import AppSelection from 'common/components/AppSelection';
import AppTextArea from 'common/components/AppTextArea';
import {
  MEMBER_TYPE_OPTIONS,
  PROGRAM_TYPE_OPTIONS,
  VOUCHER_DURATION_OPTIONS
} from 'common/constants';
import { BOOKING_OPTIONS } from 'common/constants/classBooking.constant';
import { DISCOUNT_TYPE } from 'common/enums';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { BOOKING_TYPE } from 'common/enums/classBooking.enum';
import { VOUCHER_DURATION } from 'common/enums/voucher.enum';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { IOption } from 'common/interfaces';
import { IVoucher } from 'common/interfaces/voucher.interface';
import { useBrandLocation } from 'context/BrandLocationContext';
import { useToast } from 'context/ToastContext';
import dayjs, { Dayjs } from 'dayjs';
import {
  FORMAT_END_OF_DATE,
  FORMAT_START_OF_DATE
} from 'pages/reports/constant';
import { MODE } from 'pages/vouchers/constant';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { HiArrowLeft } from 'react-icons/hi2';
import { createVoucher, updateVoucher } from 'services/voucher.service';
import yupVoucher from 'validators/voucher.validator';
import './desktop.scss';
import { handleNumberInput } from 'helpers/index.helper';

interface IAddEditVoucherProps {
  data: IVoucher | null;
  title: string;
  open: boolean;
  mode: MODE;
  type: DISCOUNT_TYPE;
  onClose: () => void;
  onSuccess: () => void;
}

const validationSchema = yupVoucher.OBJECT({
  code: yupVoucher.VOUCHER_CODE,
  discountType: yupVoucher.VOUCHER_TYPE,
  discountValue: yupVoucher.VOUCHER_VALUE,
  startDate: yupVoucher.VOUCHER_START_DATE,
  endDate: yupVoucher.VOUCHER_END_DATE,
  maxUsage: yupVoucher.VOUCHER_MAX_USAGE,
  joiningFee: yupVoucher.VOUCHER_JOINING_FEE,
  activationFee: yupVoucher.VOUCHER_ACTIVATION_FEE,
  accessFee: yupVoucher.VOUCHER_ACCESS_FEE,
  duration: yupVoucher.DISCOUNT_DURATION,
  numberOfLesson: yupVoucher.VOUCHER_NUMBER_LESSON,
  memberTypes: yupVoucher.VOUCHER_MEMBER_TYPES,
  programTypes: yupVoucher.VOUCHER_PROGRAM_TYPES,
  bookingTypes: yupVoucher.VOUCHER_BOOKING_TYPES,
  notes: yupVoucher.VOUCHER_NOTES,
  forAllLocation: yupVoucher.VOUCHER_ALL_LOCATION,
  locations: yupVoucher.VOUCHER_LOCATIONS,
  quantity: yupVoucher.VOUCHER_QUANTITY,
  startDateFree: yupVoucher.VOUCHER_START_DATE_FREE,
  endDateFree: yupVoucher.VOUCHER_END_DATE_FREE
});

const AddEditVoucher = (props: IAddEditVoucherProps) => {
  const { title, mode, data, type, open, onClose, onSuccess } = props;
  const { locations } = useBrandLocation();
  const toast = useToast();

  const initVoucher = useMemo((): CreateUpdateVoucherDTO => {
    return {
      code: data?.code || '',
      discountType: type,
      discountValue: data?.discountValue || 0,
      startDate: data?.startDate || '',
      endDate: data?.endDate || '',
      maxUsage: !data?.maxUsage || data?.maxUsage === -1 ? 0 : data?.maxUsage,
      joiningFee: data?.joiningFee || 0,
      activationFee: data?.activationFee || 0,
      accessFee: data?.accessFee || 0,
      duration: data?.duration || VOUCHER_DURATION.TIME_RANGE,
      numberOfLesson: data?.numberOfLesson || 0,
      memberTypes: data?.memberTypes || [],
      programTypes: data?.programTypes || [],
      bookingTypes: data?.bookingTypes || [],
      notes: data?.notes || '',
      forAllLocation: data?.forAllLocation || false,
      locations: data?.locations || [],
      quantity: data?.quantity || 0,
      startDateFree: data?.startDateFree || '',
      endDateFree: data?.endDateFree || ''
    };
  }, [type, data]);

  const [loading, setLoading] = useState<boolean>(false);
  const [voucher, setVoucher] = useState<CreateUpdateVoucherDTO>(initVoucher);
  const __locationIdsCannotBeRemoved = useMemo((): string[] => {
    return data?.locations?.map((item) => item) || [];
  }, [data?.locations]);

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<CreateUpdateVoucherDTO>({
    resolver: yupResolver(validationSchema) as Resolver<CreateUpdateVoucherDTO>,
    defaultValues: voucher,
    context: {
      isEdit: mode === MODE.EDIT,
      oldQuantity: data?.quantity || 0,
      oldEndDate: data?.endDate || '',
      oldEndDateFree: data?.endDateFree || ''
    }
  });

  const locationList = useMemo((): IOption[] => {
    const result = locations
      ?.filter((item) => item?._id)
      ?.map((item) => {
        return {
          label: item.shortName || item.name,
          value: item._id
        };
      });
    return result;
  }, [locations]);

  const programTypeOptions = useMemo(() => {
    return PROGRAM_TYPE_OPTIONS.filter(
      (option) => option.value !== CLASS_TYPES.ASSESSMENT_TRIAL
    );
  }, []);

  const bookingTypeOptions = useMemo(() => {
    const isOnlyIntensiveProgram =
      voucher.programTypes?.length === 1 &&
      voucher.programTypes[0] === CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM;

    return BOOKING_OPTIONS.filter((option: IOption) => {
      if (isOnlyIntensiveProgram) {
        return (
          option.value !== BOOKING_TYPE.MAKE_UP &&
          option.value !== BOOKING_TYPE.CASUAL_CLASS
        );
      }
      return option.value !== BOOKING_TYPE.MAKE_UP;
    });
  }, [voucher]);

  const getPlaceholderText = (type: string): string => {
    switch (type) {
      case 'locations':
        return voucher.locations?.length === 0
          ? 'Location*'
          : `${voucher.locations?.length} selected`;
      case 'memberTypes':
        return voucher.memberTypes?.length === 0
          ? 'Member type*'
          : `${voucher.memberTypes?.length} selected`;
      case 'programTypes':
        return voucher.programTypes?.length === 0
          ? 'Program type*'
          : `${voucher.programTypes?.length} selected`;
      case 'bookingTypes':
        return voucher.bookingTypes?.length === 0
          ? 'Enrolment type*'
          : `${voucher.bookingTypes?.length} selected`;
      default:
        return '';
    }
  };

  const handleSaveVoucher = async (dataForm: CreateUpdateVoucherDTO) => {
    let createUpdateVoucherPayload: CreateUpdateVoucherPayloadDTO = {
      code: dataForm.code,
      discountType: dataForm.discountType,
      memberTypes: dataForm.memberTypes,
      startDate: convertToUnixTime(dataForm.startDate),
      endDate: convertToUnixTime(dataForm.endDate),
      programTypes: dataForm.programTypes,
      bookingTypes: dataForm.bookingTypes,
      notes: dataForm.notes,
      forAllLocation: dataForm.locations.length === locationList?.length,
      locations: dataForm.locations,
      quantity: dataForm.quantity,
      discountValue: dataForm.discountValue
    };

    if (type === DISCOUNT_TYPE.COMPLIMENTARY) {
      createUpdateVoucherPayload = {
        ...createUpdateVoucherPayload,
        duration: dataForm.duration,
        numberOfLesson:
          dataForm.duration === VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS
            ? dataForm.numberOfLesson
            : 0,
        startDateFree:
          dataForm.duration === VOUCHER_DURATION.TIME_RANGE &&
          dataForm.startDateFree
            ? convertToUnixTime(dataForm.startDateFree)
            : null,
        endDateFree:
          dataForm.duration === VOUCHER_DURATION.TIME_RANGE &&
          dataForm.endDateFree
            ? convertToUnixTime(dataForm.endDateFree)
            : null
      };
    }

    setLoading(true);
    if (mode === MODE.ADD) {
      createVoucher(createUpdateVoucherPayload)
        .then(() => {
          setVoucher({ ...initVoucher });
          reset(initVoucher);
          toast.success('Create voucher successfully');
          onSuccess();
        })
        .catch((error: any) => {
          toast.error(
            error?.response?.data?.message || 'Failed to create voucher'
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      updateVoucher(data?._id || '', createUpdateVoucherPayload)
        .then(() => {
          toast.success('Update voucher successfully');
          onSuccess();
          reset();
        })
        .catch((error: any) => {
          toast.error(
            error?.response?.data?.message || 'Failed to update voucher'
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      let value: string | number = event.target.value;

      if (event.target.name === 'code') {
        value = value?.toUpperCase();
      }

      if (event.target.name === 'discountValue') {
        value = handleNumberInput(value, voucher.discountValue);

        setVoucher({
          ...voucher,
          discountValue: Number(value)
        });
      }
      if (event.target.name !== 'discountValue') {
        setVoucher({
          ...voucher,
          [event.target.name]: value
        });
      }

      // @ts-ignore
      setValue(event.target.name, value);
      // @ts-ignore
      trigger(event.target.name);
    },
    [voucher, setValue, trigger]
  );

  const handleToggleAllSelection = useCallback(
    (fieldName: string, options: IOption[]): void => {
      const values = options.map((option) => option.value);

      // @ts-ignore
      const isAllSelected = voucher[fieldName]?.length === options.length;

      // @ts-ignore
      let newVoucherValues: IVoucher = {
        ...voucher,
        [fieldName]: isAllSelected ? [...__locationIdsCannotBeRemoved] : values
      };

      // Reset bookingTypes when programTypes changes
      if (fieldName === 'programTypes') {
        newVoucherValues = {
          ...newVoucherValues,
          bookingTypes: []
        };
        setValue('bookingTypes', []);
      }

      setVoucher(newVoucherValues);
      if (fieldName === 'locations') {
        setValue(
          'locations',
          // @ts-ignore
          isAllSelected ? [...__locationIdsCannotBeRemoved] : values
        );
      } else {
        // @ts-ignore
        setValue(fieldName, isAllSelected ? [] : values);
      }
      // @ts-ignore
      trigger(fieldName);
    },
    [voucher, setValue, trigger, __locationIdsCannotBeRemoved]
  );

  const handleToggleSelection = useCallback(
    (fieldName: string, value: string): void => {
      // @ts-ignore
      const values = [...voucher[fieldName]];
      const findIndex = values.findIndex((item) => item === value);

      if (findIndex === -1) {
        values.push(value);
      } else {
        if (__locationIdsCannotBeRemoved.includes(value)) return;
        values.splice(findIndex, 1);
      }

      let voucherValues = {
        ...voucher,
        [fieldName]: values
      };

      // Reset bookingTypes when programTypes changes
      if (fieldName === 'programTypes') {
        voucherValues = {
          ...voucherValues,
          bookingTypes: []
        };
        setValue('bookingTypes', []);
      }

      setVoucher(voucherValues);
      // @ts-ignore
      setValue(fieldName, values);
      // @ts-ignore
      trigger(fieldName);
    },
    [voucher, setValue, trigger, __locationIdsCannotBeRemoved]
  );

  const handleChangeDate = useCallback(
    (fieldName: string, value: string): void => {
      setVoucher({
        ...voucher,
        [fieldName]: value
      });

      // @ts-ignore
      setValue(fieldName, value);
      // @ts-ignore
      trigger(fieldName);

      // re-trigger when endDate had value and changing start date
      if (fieldName === 'startDate' && voucher.endDate) {
        trigger('endDate');
      }

      // re-trigger when endDateFree had value and changing date to
      if (fieldName === 'startDateFree' && voucher.endDateFree) {
        trigger('endDateFree');
      }

      // re-trigger when startDateFree endDateFree had value and changing start date, end date
      if (fieldName === 'startDate' || fieldName === 'endDate') {
        if (voucher.startDateFree) {
          trigger('startDateFree');
        }

        if (voucher.endDateFree) {
          trigger('endDateFree');
        }
      }
    },
    [voucher, setValue, trigger]
  );

  const __renderDiscountValueLabel = useMemo(() => {
    if (voucher.discountType === DISCOUNT_TYPE.AMOUNT) {
      return 'Value ($)*';
    } else {
      return 'Value (%)*';
    }
  }, [voucher]);

  const shouldDisableDate = (fieldName: string, date: Dayjs): boolean => {
    if (mode === MODE.EDIT) {
      if (fieldName === 'endDate' && data?.endDate) {
        return date.isBefore(dayjs(data.endDate), 'day');
      }

      if (fieldName === 'endDateFree' && data?.endDateFree) {
        return date.isBefore(dayjs(data.endDateFree), 'day');
      }
    }

    return false;
  };

  return (
    <div className="voucherListPageAddEditContainer">
      <div
        className={`overlay ${open ? 'active' : ' '}`}
        onClick={onClose}
      ></div>
      <div className={`voucherAddEditForm ${open ? 'active' : ' '}`}>
        <div className="voucherAddEditForm__header">
          <HiArrowLeft
            size={24}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
          <p>{title}</p>
        </div>
        <div className="voucherAddEditForm__content">
          <AppCard>
            <div className="voucherAddEditForm__content-wrapper">
              <AppCardHeader title="Voucher information" />
              <AppCardContent className="voucherAddEditForm__content-info">
                <AppInput
                  label="Voucher code*"
                  {...register('code')}
                  disabled={mode === MODE.EDIT}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.code?.message || ''
                  }}
                />
                {type !== DISCOUNT_TYPE.COMPLIMENTARY && (
                  <AppInput
                    label={__renderDiscountValueLabel}
                    {...register('discountValue')}
                    disabled={mode === MODE.EDIT}
                    onChange={handleChange}
                    message={{
                      type: 'error',
                      text: errors?.discountValue?.message || ''
                    }}
                  />
                )}
                <AppInput
                  label="Quantity*"
                  type="number"
                  {...register('quantity')}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.quantity?.message || ''
                  }}
                />
                <AppDatePicker
                  label="Start date*"
                  {...register('startDate')}
                  value={dayjs(voucher.startDate)}
                  onChange={(date: Dayjs | null) =>
                    handleChangeDate(
                      'startDate',
                      date?.format(FORMAT_START_OF_DATE) || ''
                    )
                  }
                  shouldDisableDate={(date: Dayjs) =>
                    shouldDisableDate('startDate', date)
                  }
                  disablePast
                  disabled={mode === MODE.EDIT}
                  message={{
                    type: 'error',
                    text: errors.startDate?.message || ''
                  }}
                />
                <AppDatePicker
                  label="End date*"
                  {...register('endDate')}
                  value={dayjs(voucher.endDate)}
                  onChange={(date: Dayjs | null) =>
                    handleChangeDate(
                      'endDate',
                      date?.format(FORMAT_END_OF_DATE) || ''
                    )
                  }
                  shouldDisableDate={(date: Dayjs) =>
                    shouldDisableDate('endDate', date)
                  }
                  disablePast
                  message={{
                    type: 'error',
                    text: errors.endDate?.message || ''
                  }}
                />
                <AppSelection
                  label="Location*"
                  options={locationList}
                  onSelectAll={() =>
                    handleToggleAllSelection('locations', locationList)
                  }
                  size="large"
                  selectedIds={voucher.locations}
                  onSelect={(value: string) =>
                    handleToggleSelection('locations', value)
                  }
                  message={{
                    type: 'error',
                    text: errors?.locations?.message || ''
                  }}
                  placeholder={getPlaceholderText('locations')}
                  isSearch={false}
                  selectedIdsCannotBeRemoved={__locationIdsCannotBeRemoved}
                />
                <AppSelection
                  label="Member type*"
                  options={MEMBER_TYPE_OPTIONS}
                  onSelectAll={() =>
                    handleToggleAllSelection('memberTypes', MEMBER_TYPE_OPTIONS)
                  }
                  size="large"
                  selectedIds={voucher.memberTypes}
                  onSelect={(value: string) =>
                    handleToggleSelection('memberTypes', value)
                  }
                  message={{
                    type: 'error',
                    text: errors?.memberTypes?.message || ''
                  }}
                  disabled={mode === MODE.EDIT}
                  placeholder={getPlaceholderText('memberTypes')}
                  isSearch={false}
                />
                <AppSelection
                  label="Program type*"
                  options={programTypeOptions}
                  onSelectAll={() =>
                    handleToggleAllSelection('programTypes', programTypeOptions)
                  }
                  size="large"
                  selectedIds={voucher.programTypes}
                  onSelect={(value: string) =>
                    handleToggleSelection('programTypes', value)
                  }
                  message={{
                    type: 'error',
                    text: errors?.programTypes?.message || ''
                  }}
                  disabled={mode === MODE.EDIT}
                  placeholder={getPlaceholderText('programTypes')}
                  isSearch={false}
                />
                <AppSelection
                  label="Enrolment type*"
                  options={bookingTypeOptions}
                  onSelectAll={() =>
                    handleToggleAllSelection('bookingTypes', bookingTypeOptions)
                  }
                  size="large"
                  selectedIds={voucher.bookingTypes}
                  onSelect={(value: string) =>
                    handleToggleSelection('bookingTypes', value)
                  }
                  message={{
                    type: 'error',
                    text: errors?.bookingTypes?.message || ''
                  }}
                  placeholder={getPlaceholderText('bookingTypes')}
                  isSearch={false}
                  disabled={
                    voucher.programTypes?.length === 0 || mode === MODE.EDIT
                  }
                />
              </AppCardContent>
              <AppCardContent className="voucherAddEditForm__content-description">
                <AppTextArea
                  label="Notes*"
                  {...register('notes')}
                  name="notes"
                  value={voucher.notes}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.notes?.message || ''
                  }}
                />
              </AppCardContent>
            </div>
          </AppCard>
          {type === DISCOUNT_TYPE.COMPLIMENTARY && (
            <AppCard>
              <AppCardContent className="voucherAddEditForm__content-duration">
                <AppSelect
                  label="Duration*"
                  {...register('duration')}
                  options={VOUCHER_DURATION_OPTIONS}
                  message={{
                    type: 'error',
                    text: errors?.duration?.message || ''
                  }}
                  name="duration"
                  value={voucher.duration || ''}
                  onChange={handleChange}
                  searchable={false}
                  disabled={mode === MODE.EDIT}
                />
                {voucher.duration === VOUCHER_DURATION.TIME_RANGE && (
                  <>
                    <AppDatePicker
                      label="Date from*"
                      {...register('startDateFree')}
                      value={dayjs(voucher.startDateFree)}
                      onChange={(date: Dayjs | null) =>
                        handleChangeDate(
                          'startDateFree',
                          date?.format(FORMAT_START_OF_DATE) || ''
                        )
                      }
                      shouldDisableDate={(date: Dayjs) =>
                        shouldDisableDate('startDateFree', date)
                      }
                      disablePast
                      disabled={
                        !voucher.startDate ||
                        !voucher.endDate ||
                        mode === MODE.EDIT
                      }
                      message={{
                        type: 'error',
                        text: errors.startDateFree?.message || ''
                      }}
                    />
                    <AppDatePicker
                      label="Date to"
                      {...register('endDateFree')}
                      value={dayjs(voucher.endDateFree)}
                      onChange={(date: Dayjs | null) =>
                        handleChangeDate(
                          'endDateFree',
                          date?.format(FORMAT_END_OF_DATE) || ''
                        )
                      }
                      shouldDisableDate={(date: Dayjs) =>
                        shouldDisableDate('endDateFree', date)
                      }
                      disablePast
                      disabled={!voucher.startDate || !voucher.endDate}
                      message={{
                        type: 'error',
                        text: errors.endDateFree?.message || ''
                      }}
                    />
                  </>
                )}
                {voucher.duration ===
                  VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS && (
                  <AppInput
                    label="Number of lesson*"
                    {...register('numberOfLesson')}
                    disabled={mode === MODE.EDIT}
                    type="number"
                    onChange={handleChange}
                    message={{
                      type: 'error',
                      text: errors?.numberOfLesson?.message || ''
                    }}
                  />
                )}
              </AppCardContent>
            </AppCard>
          )}
        </div>
        <div className="voucherAddEditForm__actions">
          <AppButton
            variant="primary"
            isLoading={loading}
            onClick={handleSubmit(handleSaveVoucher)}
          >
            Save
          </AppButton>
          <AppButton variant="secondary" onClick={onClose}>
            Cancel
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default AddEditVoucher;
