// API Methods
import { RawAxiosRequestHeaders } from 'axios';
import axiosClient from './axiosClient';

const API = {
  get: (
    url: string,
    params: Record<string, unknown> = {},
    header: RawAxiosRequestHeaders = {},
    signal?: AbortSignal,
    requestOptions: any = {}
  ): Promise<any> => {
    return axiosClient.get(url, {
      params,
      headers: { ...header },
      signal,
      ...requestOptions
    });
  },
  post: (
    url: string,
    payload: {
      params?: Record<string, unknown>;
      body?: Record<string, unknown>;
    } = {
      params: {},
      body: {}
    },
    header: RawAxiosRequestHeaders = {},
    signal?: AbortSignal,
    requestOptions: any = {}
  ): Promise<any> => {
    return axiosClient.post(url, payload.body, {
      params: payload.params,
      headers: { ...header },
      signal,
      ...requestOptions
    });
  },
  postFormData: (
    url: string,
    payload: {
      params?: Record<string, unknown>;
      body?: FormData;
    } = {
      params: {},
      body: undefined
    },
    header: RawAxiosRequestHeaders = {
      'Content-Type': 'multipart/form-data'
    }
  ): Promise<any> => {
    return axiosClient.post(url, payload.body, {
      params: payload.params,
      headers: { ...header }
    });
  },
  put: (
    url: string,
    payload: {
      params?: Record<string, unknown>;
      body?: Record<string, unknown>;
    } = {
      params: {},
      body: {}
    }
  ): Promise<any> => {
    return axiosClient.put(url, payload.body, {
      params: payload.params
    });
  },
  patch: (
    url: string,
    payload: {
      params?: Record<string, unknown>;
      body?: Record<string, unknown>;
    } = {
      params: {},
      body: {}
    }
  ): Promise<any> => {
    return axiosClient.patch(url, payload.body, {
      params: payload.params
    });
  },
  delete: (
    url: string,
    payload: {
      params?: Record<string, unknown>;
      data?: Record<string, unknown>;
    } = {
      params: {},
      data: undefined
    }
  ): Promise<any> => {
    return axiosClient.delete(url, {
      ...payload,
      data: payload.data
    });
  }
};
export default API;
