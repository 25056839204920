import API from './API';
import {
  CreateLocationAreaDto,
  FormUpdateLocationAreaDto
} from 'DTOs/locationArea.dto';
import {
  ILocationAreaNameFilter,
  ILocationAreaTableFilter
} from 'common/interfaces/locationArea.interface';

const LOCATION_AREA_ENDPOINT = `/location-areas`;

export const getLocationAreas = (params: ILocationAreaTableFilter) => {
  return API.get(`${LOCATION_AREA_ENDPOINT}`, params);
};

export const getLocationAreaName = (
  params: ILocationAreaNameFilter,
  signal?: AbortSignal
) => {
  return API.get(
    `${LOCATION_AREA_ENDPOINT}/check-name`,
    params,
    undefined,
    signal
  );
};

export const addLocationArea = async (payload: CreateLocationAreaDto) => {
  const response = await API.post(`${LOCATION_AREA_ENDPOINT}`, {
    body: payload
  });
  return response.data;
};

export const getLocationAreaDetail = async (locationAreaId: string) => {
  const response = await API.get(`${LOCATION_AREA_ENDPOINT}/${locationAreaId}`);
  return response.data.data;
};

export const updateLocationArea = (
  locationAreaId: string,
  body: FormUpdateLocationAreaDto
) => {
  return API.patch(`${LOCATION_AREA_ENDPOINT}/${locationAreaId}`, {
    body
  });
};

export const deleteLocationArea = async (id: string) => {
  const response = await API.delete(`${LOCATION_AREA_ENDPOINT}/${id}`);
  return response.data;
};
