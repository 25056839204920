import API from './API';

import {
  IResponsiblePersonTableFilter,
  IRPMoneyCreditHistoryTableFilter
} from 'common/interfaces/responsiblePerson.interface';
import {
  CreateResponsiblePersonDto,
  UpdateResponsiblePersonPersonalInfoDto,
  UpdateResponsiblePersonContactDetailsDto,
  AddStudentToResponsiblePersonDto,
  UpdateRPMoneyCreditDto
} from 'DTOs/responsiblePerson.dto';
import { ApplyCreditDTO } from 'DTOs/student.dto';

const RP_API_ENDPOINT = '/responsible-persons';
const ASSESSMENT_RESULT_API_ENDPOINT = '/assessment-results';

export const getResponsiblePersonList = async (
  params: IResponsiblePersonTableFilter,
  signal?: AbortSignal
) => {
  return API.get(`${RP_API_ENDPOINT}`, params, undefined, signal);
};

export const addResponsiblePerson = async (
  payload: CreateResponsiblePersonDto
) => {
  const response = await API.post(`${RP_API_ENDPOINT}`, { body: payload });
  return response.data;
};

export const getResponsiblePersonDetail = async (
  responsiblePersonId: string
) => {
  return API.get(`${RP_API_ENDPOINT}/${responsiblePersonId}`);
};

export const updateRPPersonalInfo = async (
  responsiblePersonId: string,
  payload: UpdateResponsiblePersonPersonalInfoDto
) => {
  const response = await API.patch(
    `${RP_API_ENDPOINT}/${responsiblePersonId}/personal-information`,
    {
      body: payload
    }
  );
  return response.data;
};

export const updateRPContactDetails = async (
  responsiblePersonId: string,
  payload: UpdateResponsiblePersonContactDetailsDto
) => {
  const response = await API.patch(
    `${RP_API_ENDPOINT}/${responsiblePersonId}/contact-detail`,
    {
      body: payload
    }
  );
  return response.data;
};

export const addStudentToRP = async (
  responsiblePersonId: string,
  payload: AddStudentToResponsiblePersonDto
) => {
  const response = await API.patch(
    `${RP_API_ENDPOINT}/${responsiblePersonId}/add-students`,
    {
      body: payload
    }
  );
  return response.data;
};

export const deleteStudentFromRP = async (
  responsiblePersonId: string,
  payload: AddStudentToResponsiblePersonDto
) => {
  const response = await API.patch(
    `${RP_API_ENDPOINT}/${responsiblePersonId}/remove-students`,
    {
      body: payload
    }
  );
  return response.data;
};

export const getRPUpcomingClasses = async (
  responsiblePersonId: string,
  page: number,
  limit: number,
  keyword: string,
  studentId?: string,
  isCanAbsence?: boolean
) => {
  const params: {
    page: number;
    limit: number;
    keyword: string;
    studentId?: string;
    isCanAbsence?: boolean;
  } = { page, limit, keyword };

  if (typeof isCanAbsence !== 'undefined') params.isCanAbsence = isCanAbsence;
  if (typeof studentId !== 'undefined' && studentId !== '')
    params.studentId = studentId;

  const response = await API.get(
    `${RP_API_ENDPOINT}/${responsiblePersonId}/upcoming-classes`,
    params
  );
  return response.data.data;
};

export const getRPAttendanceHistory = (
  responsiblePersonId: string,
  page: number,
  limit: number,
  keyword: string,
  studentId?: string,
  isCanAbsence?: boolean
) => {
  const params: {
    page: number;
    limit: number;
    keyword?: string;
    studentId?: string;
    isCanAbsence?: boolean;
  } = { page, limit };

  if (typeof isCanAbsence !== 'undefined') params.isCanAbsence = isCanAbsence;
  if (typeof studentId !== 'undefined' && studentId !== '')
    params.studentId = studentId;

  if (typeof keyword !== 'undefined' && keyword !== '')
    params.keyword = keyword;

  return API.get(
    `${RP_API_ENDPOINT}/${responsiblePersonId}/attendance-histories`,
    params
  );
};

export const getRPEnrollments = (
  responsiblePersonId: string,
  page: number,
  limit: number,
  studentId?: string
) => {
  const params: {
    page: number;
    limit: number;
    studentId?: string;
  } = { page, limit };

  if (typeof studentId !== 'undefined' && studentId !== '')
    params.studentId = studentId;

  return API.get(
    `${RP_API_ENDPOINT}/${responsiblePersonId}/enrollments`,
    params
  );
};

export const getRPAssessment = (
  page: number,
  limit: number,
  keyword: string,
  responsiblePersonId: string,
  studentId?: string
) => {
  const params: {
    page: number;
    limit: number;
    responsiblePersonId: string;
    keyword: string;
    studentId?: string;
  } = { page, limit, responsiblePersonId, keyword };

  if (typeof studentId !== 'undefined' && studentId !== '')
    params.studentId = studentId;

  return API.get(`${ASSESSMENT_RESULT_API_ENDPOINT}/student`, params);
};

export const getMoneyCreditHistory = (
  responsiblePersonId: string,
  params: IRPMoneyCreditHistoryTableFilter
) => {
  return API.get(
    `${RP_API_ENDPOINT}/${responsiblePersonId}/money-credit-histories`,
    params
  );
};

export const applyMoneyCreditsToStudents = (body: ApplyCreditDTO) => {
  return API.post(`${RP_API_ENDPOINT}/bulk-money-credit`, { body });
};

export const getStudentsActiveOngoingOfRP = (RPId: string) => {
  return API.get(`${RP_API_ENDPOINT}/${RPId}/students-active-ongoing`);
};

export const updateRPMoneyCredit = (
  responsiblePersonId: string,
  payload: UpdateRPMoneyCreditDto
) => {
  return API.patch(`${RP_API_ENDPOINT}/${responsiblePersonId}/money-credit`, {
    body: payload
  });
};
