import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { cloneDeep, every, groupBy, some, sortBy } from 'lodash';
import { IGroupClassSession } from 'common/interfaces/dashboard.interface';
import { useDashboardContext } from '../DashboardContext';
import { BiInfoCircle } from 'react-icons/bi';

interface RenderLevelsProps {
  date: Date;
}

const LevelListPerDay: React.FC<RenderLevelsProps> = ({ date }) => {
  const { classesData, onChangeSelectedDayForPreview, onChangeSelectedLevel } =
    useDashboardContext();
  const [levelsInCell, setLevelsInCell] = useState<IGroupClassSession[]>([]);
  const [textShowMore, setTextShowMore] = useState('');
  const [numberRow, setNumberRow] = useState<number>(0);

  useEffect(() => {
    const levelsInCell = classesData.filter(
      (item: IGroupClassSession) =>
        dayjs(item.startTime).format('YYYY-MM-DD') ===
        dayjs(date).format('YYYY-MM-DD')
    );

    if (levelsInCell.length > 0) {
      const data: IGroupClassSession[] = [];
      const levelGroups = groupBy(
        cloneDeep(levelsInCell),
        'levelBreakdown._id'
      );
      Object.keys(levelGroups).forEach((key: string) => {
        const level = levelGroups[key][0];
        if (levelGroups[key].length > 1) {
          const levelTemp = { ...levelGroups[key][0] };
          data.push(levelTemp);
        } else {
          data.push({ ...level });
        }
      });
      const sortedLevel = sortBy(data, 'levelBreakdown.shortName').map(
        (item) => {
          return {
            ...item,
            isCanBook: some(
              item.schedules,
              (schedule) => schedule.vacancySession > 0
            ),
            isOverBooked: every(
              item.schedules,
              (schedule) => schedule.vacancySession < 0
            )
          };
        }
      );

      let text = '';
      const levelsShowed = sortedLevel.slice(0, 6);
      const remainingLevels = sortedLevel.length - 6;
      if (remainingLevels > 1) {
        text = `+${remainingLevels}`;
      } else if (remainingLevels === 1) {
        levelsShowed.push(sortedLevel[6]);
      }
      setLevelsInCell(levelsShowed);
      setTextShowMore(text);
      if (text.length) {
        setNumberRow(levelsShowed.length + 1);
      } else {
        setNumberRow(levelsShowed.length);
      }
    } else {
      setLevelsInCell([]);
      setTextShowMore('');
    }
  }, [classesData, date]);
  const handleOpenPopup = (item: IGroupClassSession) => {
    onChangeSelectedDayForPreview(date);
    onChangeSelectedLevel(item);
  };

  return (
    <>
      {[...levelsInCell].map((item: IGroupClassSession, idx: number) => (
        <div
          key={idx}
          className="level-item"
          style={{
            borderLeft: `4px solid ${
              item?.colorCode || item?.levelBreakdown?.colorCode || '#034ea2'
            }`,
            height: `calc((100% - ${numberRow * 2}px) / ${numberRow})`
          }}
          onClick={() => handleOpenPopup(item)}
        >
          <div className="text">
            {item.isOverBooked ? <BiInfoCircle /> : null}
            <span>{`${item.levelBreakdown?.shortName || 'All Levels'} `}</span>
          </div>
          {item.isCanBook ? (
            <div
              className="active"
              style={{
                backgroundColor:
                  item?.colorCode ||
                  item?.levelBreakdown?.colorCode ||
                  '#034ea2'
              }}
            ></div>
          ) : null}
        </div>
      ))}
      {textShowMore.length > 0 && (
        <div>
          <div
            className="level-item"
            style={{
              height: `calc((100% - ${numberRow * 2}px) / ${numberRow})`,
              justifyContent: 'center'
            }}
            onClick={() => handleOpenPopup(levelsInCell[0])}
          >
            <div className="text more">{textShowMore}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default LevelListPerDay;
