import React from 'react';
import { HiOutlinePrinter, HiOutlineDocumentText } from 'react-icons/hi2';
import styles from './styles.module.scss';

interface IReportPrintButtonProps {}

const ReportPrintButton = (props: IReportPrintButtonProps) => {
  const popperRef = React.useRef<any>(null);
  const buttonRef = React.useRef<any>(null);

  const [open, setOpen] = React.useState<boolean>(false);

  const onToggle = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // Close popper if click outside the popper
    const handleClickOutside = (event: MouseEvent) => {
      // Click at vertical button to close
      if (buttonRef.current && buttonRef.current.contains(event.target)) {
        return;
      }
      // Handle click outside to close pop up
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        onClose();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonRef, popperRef]);

  return (
    <div className={styles.reportPrintButton}>
      <div
        ref={buttonRef}
        className={styles.reportPrintButtonMain}
        onClick={onToggle}
      >
        <HiOutlinePrinter size={22} />
        Print
      </div>
      {open && (
        <div ref={popperRef} className={styles.reportPrintButtonPopper}>
          <div className={styles.reportPrintButtonPopperItem}>
            <HiOutlineDocumentText size={22} /> .xls
          </div>
          <div className={styles.reportPrintButtonPopperItem}>
            <HiOutlineDocumentText size={22} /> .csv
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportPrintButton;
