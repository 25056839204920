import { useLayout } from 'context/LayoutContext';
import React from 'react';
import styles from './calendar/scss/index.module.scss';
import MenuIcon from 'components/layout/MenuIcon';
import { ISearchCalendar } from './calendar/types';

interface Props {
  handleSearch: (searchValue: string, advance?: boolean) => void;
  searchValue: string;
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
  searchAdvanceValue: ISearchCalendar;
}
const HeaderDashboardComponent: React.FC<Props> = ({
  handleSearch,
  searchValue,
  searchAdvanceValue,
  setShowSearch
}: Props) => {
  const { isShowSidebar, handleMouseLeaveMenu } = useLayout();
  return (
    <div
      className={
        isShowSidebar ? styles.dashboardHeaderCollapsed : styles.dashboardHeader
      }
      onMouseEnter={handleMouseLeaveMenu}
    >
      <div className="flex" onMouseEnter={handleMouseLeaveMenu}>
        <MenuIcon />
        <div className={styles.dashboardHeaderText}>Dashboard</div>
      </div>
      {/* 
      <div style={{ position: 'relative' }} onMouseEnter={handleMouseLeaveMenu}>
        <AppSearchCalendar
          handleSearch={handleSearch}
          searchValue={searchValue}
          setShowSearch={setShowSearch}
          searchAdvanceValue={searchAdvanceValue}
        />
      </div>
      */}
    </div>
  );
};
const HeaderDashboard = React.memo(HeaderDashboardComponent);
export default HeaderDashboard;
