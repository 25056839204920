import { CreateTermPayloadDto, UpdateTermPayloadDto } from 'DTOs/term.dto';
import API from './API';

const TERM_ENDPOINT = '/term';

export const getTerms = (
  page: number,
  limit: number,
  locationId?: string,
  keyword?: string,
  type?: string[],
  isOngoing?: boolean
) => {
  let url = `${TERM_ENDPOINT}?page=${page}&limit=${limit}`;
  if (isOngoing !== undefined) {
    url += `&isOngoing=${isOngoing}`;
  }
  if (type?.length) {
    url += `&type=${type?.join(',')}`;
  }
  if (keyword) {
    url += `&keyword=${keyword}`;
  }
  if (locationId?.length) {
    url += `&locationId=${locationId}`;
  }
  return API.get(url);
};

export const getTermById = (id: string) => {
  return API.get(`${TERM_ENDPOINT}/${id}`);
};

export const createTerm = (payload: {
  terms: CreateTermPayloadDto[];
  timezone: string;
}) => {
  return API.post(`${TERM_ENDPOINT}`, {
    body: payload
  });
};

export const updateTermById = (id: string, payload: UpdateTermPayloadDto) => {
  return API.put(`${TERM_ENDPOINT}/${id}`, {
    body: payload
  });
};

export const deleteTerm = (id: string) => {
  return API.delete(`${TERM_ENDPOINT}/${id}`);
};

export const getTemplateByTermId = (id: string, search?: string) => {
  return API.get(`${TERM_ENDPOINT}/${id}/list-class-template`, {
    keyword: search
  });
};
