import { useState } from 'react';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import { IClassTemplate } from 'common/interfaces/classTemplate.interface';
import AppButton from 'common/components/AppButton';
import { deleteClassTemplate } from 'services/classTemplate.service';
import { useToast } from 'context/ToastContext';

interface IClassTemplateDeleteModalProps {
  classTemplate: IClassTemplate;
  onClose: () => void;
  onSuccess: () => void;
}

const ClassTemplateDeleteModal = (props: IClassTemplateDeleteModalProps) => {
  const { classTemplate, onClose, onSuccess } = props;

  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteClassTemplate(classTemplate?._id);
      toast.success('Deleted this class template');
      onSuccess();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to delete this class template'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppModal open={!!classTemplate} onClose={onClose}>
      <AppModalTitle>Delete CLASS TEMPLATE</AppModalTitle>
      <AppModalContent>
        ARE YOU SURE YOU WANT TO DELETE THIS CLASS TEMPLATE?
      </AppModalContent>
      <AppModalActions>
        <AppButton variant="secondary" onClick={onClose}>
          No
        </AppButton>
        <AppButton isLoading={loading} onClick={handleDelete}>
          Yes
        </AppButton>
      </AppModalActions>
    </AppModal>
  );
};

export default ClassTemplateDeleteModal;
