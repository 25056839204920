import AppInput from 'common/components/AppInput';
import React, { useCallback, useEffect, useState } from 'react';
import './desktop.scss';
import AppButton from 'common/components/AppButton';
import { HiXMark } from 'react-icons/hi2';
import { validateVoucher } from 'services/voucher.service';
import { DISCOUNT_TYPE } from 'common/enums';
import {
  IAutomaticDiscount,
  IValidateVoucherData,
  IVoucher
} from 'common/interfaces/voucher.interface';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import { DiscountApplied } from 'common/interfaces/bookingClass.interface';
import { getAutomationDiscountText } from 'common/helpers/voucher.helper';
import { ValidateVoucherDTO } from 'DTOs/voucher.dto';
import { VOUCHER_DURATION } from 'common/enums/voucher.enum';

interface Props {
  onApplyPromoCode: (promoCode: IVoucher | undefined) => void;
  automaticDiscount?: IAutomaticDiscount;
  hideInput?: boolean;
  discountApplied?: DiscountApplied;
  bookingData?: IValidateVoucherData;
}
const PromoCodeInput: React.FC<Props> = ({
  onApplyPromoCode,
  automaticDiscount,
  hideInput,
  discountApplied,
  bookingData
}: Props) => {
  const [promoCode, setPromoCode] = useState<string>('');
  const [promoCodeError, setPromoCodeError] = useState<string>('');
  const [validPromoCode, setValidPromoCode] = useState<string>('');
  const [voucher, setVoucher] = useState<IVoucher>();
  const __isDisplayAutomaticVoucher = React.useMemo(() => {
    return !validPromoCode && !!automaticDiscount;
  }, [validPromoCode, automaticDiscount]);

  const __isDisplayDiscountApplied = React.useMemo(() => {
    return !validPromoCode && !!discountApplied;
  }, [validPromoCode, discountApplied]);

  const __discountAppliedText = React.useMemo((): string => {
    if (discountApplied) {
      if (discountApplied?.discountType === DISCOUNT_TYPE.AMOUNT) {
        return `$${discountApplied?.discountValue} off applied`;
      } else if (discountApplied?.discountType === DISCOUNT_TYPE.PERCENTAGE) {
        return `${discountApplied?.discountValue}% off applied`;
      } else if (
        discountApplied?.discountType === DISCOUNT_TYPE.COMPLIMENTARY
      ) {
        const { numberOfFreeLesson = 0 } = discountApplied;
        const duration =
          discountApplied.duration === VOUCHER_DURATION.TIME_RANGE
            ? `${formatDate(discountApplied.startDateFree, 'slash')} - ${
                !!discountApplied.endDateFree
                  ? formatDate(discountApplied.endDateFree, 'slash')
                  : 'Ongoing'
              }`
            : `${numberOfFreeLesson} ${
                numberOfFreeLesson > 1 ? 'Lessons' : 'Lesson'
              }`;
        return `Complimentary ${duration}`;
      }
    }
    return '';
  }, [discountApplied]);

  useEffect(() => {
    if (!voucher) {
      setPromoCodeError('');
      setValidPromoCode('');
      setPromoCode('');
      return;
    }
    if (voucher.discountType === DISCOUNT_TYPE.AMOUNT) {
      setValidPromoCode(`$${voucher.discountValue} off applied`);
    }
    if (voucher.discountType === DISCOUNT_TYPE.PERCENTAGE) {
      setValidPromoCode(`${voucher.discountValue}% off applied`);
    }
    if (voucher.discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
      const { numberOfLesson = 0 } = voucher;
      const duration =
        voucher.duration === VOUCHER_DURATION.TIME_RANGE
          ? `${formatDate(voucher.startDateFree, 'slash')} - ${
              !!voucher.endDateFree
                ? formatDate(voucher.endDateFree, 'slash')
                : 'Ongoing'
            }`
          : `${numberOfLesson} ${numberOfLesson > 1 ? 'Lessons' : 'Lesson'}`;
      setValidPromoCode(`Complimentary ${duration}`);
    }
  }, [voucher]);

  const handleApplyCode = useCallback(async () => {
    if (promoCode.length === 0) {
      setPromoCodeError('Please provide a voucher');
      return;
    }
    if (!bookingData) return;
    try {
      const payload: ValidateVoucherDTO = {
        code: promoCode?.toUpperCase().trim(),
        ...bookingData
      };
      const { data } = await validateVoucher(payload);
      setVoucher(data.data);

      onApplyPromoCode(data.data);
      setPromoCodeError('');
      setPromoCode('');
    } catch (error: any) {
      setPromoCodeError(error?.response?.data?.message);
      return;
    }
  }, [promoCode, onApplyPromoCode, bookingData]);

  const handleRemovePromoCode = () => {
    setPromoCode('');
    setValidPromoCode('');
    setPromoCodeError('');
    onApplyPromoCode(undefined);
  };

  return (
    <section className="promoCode--wrapper">
      <div className="promoCode--input-wrapper">
        <span>VOUCHER</span>
        {!hideInput && (
          <>
            <AppInput
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              label=""
              inputSize="small"
              message={{ type: 'error', text: promoCodeError }}
            />
            <AppButton
              onClick={handleApplyCode}
              buttonSize="small"
              variant="secondary"
            >
              Apply
            </AppButton>
          </>
        )}
      </div>
      <div className="promoCode--valid-wrapper">
        {!!validPromoCode && (
          <div className="promoCode--valid">
            <HiXMark size={16} onClick={handleRemovePromoCode} />
            <div className="promoCode--valid--wrapper">
              <p className="promoCode--type">{formatData(voucher?.code)}</p>
              <div className="text">{formatData(validPromoCode)}</div>
            </div>
          </div>
        )}
        {__isDisplayAutomaticVoucher && (
          <div className="automaticDiscount--wrapper">
            <p className="automaticDiscount--text">
              {automaticDiscount?.value}% off applied
            </p>
            <p className="automaticDiscount--type">
              {getAutomationDiscountText(automaticDiscount?.type)}
            </p>
          </div>
        )}
        {__isDisplayDiscountApplied && (
          <div className="promoCode--valid">
            <div className="promoCode--valid">
              <div className="promoCode--valid--wrapper">
                {discountApplied?.voucherCode && (
                  <p className="promoCode--type">
                    {formatData(discountApplied?.voucherCode)}
                  </p>
                )}
                <div className="text">{formatData(__discountAppliedText)}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default PromoCodeInput;
