import { FILE_ASSET_TYPE } from 'common/enums/uploadFile.enum';
import API from './API';

const UPLOAD_FILES_ENDPOINT = `/uploads`;

export const uploadFile = (assetType: FILE_ASSET_TYPE, file: File) => {
  let formData = new FormData();
  formData.append('assetType', assetType);
  formData.append('file', file);
  return API.postFormData(`${UPLOAD_FILES_ENDPOINT}`, {
    body: formData
  });
};
