import { IGroupClassSession } from 'common/interfaces/dashboard.interface';
import { createContext, useContext, useState } from 'react';

interface DashboardContextType {
  classesData: IGroupClassSession[];
  selectedDateForPreview: Date;
  selectedTime: string;
  selectedEndTime: string;
  selectedLevel: IGroupClassSession | null;
  onChangeSelectedTime: (time: string) => void;
  onChangeSelectedEndTime: (time: string) => void;
  onChangeSelectedDayForPreview: (date: Date) => void;
  onChangeClassesDate: (data: IGroupClassSession[]) => void;
  onChangeSelectedLevel: (level: IGroupClassSession | null) => void;
}

const DashboardContext = createContext<DashboardContextType | null>(null);
export const useDashboardContext = (): DashboardContextType => {
  const dashboardContext = useContext(DashboardContext);
  if (!dashboardContext) {
    throw new Error(
      'useDashboardContext must be used within a DashboardContext'
    );
  }
  return dashboardContext;
};

export const DashboardProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children
}) => {
  const [classesData, setClassesData] = useState<IGroupClassSession[]>([]);
  const [selectedDateForPreview, setSelectedDateForPreview] = useState<Date>(
    new Date()
  );
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [selectedEndTime, setSelectedEndTime] = useState<string>('');
  const [selectedLevel, setSelectedLevel] = useState<IGroupClassSession | null>(
    null
  );

  const onChangeClassesDate = (data: IGroupClassSession[]) => {
    setClassesData(data);
  };
  const onChangeSelectedDayForPreview = (date: Date) => {
    setSelectedDateForPreview(date);
  };
  const onChangeSelectedTime = (time: string) => {
    setSelectedTime(time);
  };
  const onChangeSelectedEndTime = (time: string) => {
    setSelectedEndTime(time);
  };
  const onChangeSelectedLevel = (level: IGroupClassSession | null) => {
    setSelectedLevel(level);
  };
  const value: DashboardContextType = {
    classesData,
    onChangeClassesDate,
    selectedDateForPreview,
    selectedTime,
    selectedEndTime,
    selectedLevel,
    onChangeSelectedLevel,
    onChangeSelectedTime,
    onChangeSelectedEndTime,
    onChangeSelectedDayForPreview
  };
  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};
