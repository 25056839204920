import { ReactNode, memo } from 'react';
import { HiArrowLeft } from 'react-icons/hi2';
import AppButton from 'common/components/AppButton';

import './desktop.scss';

interface Props {
  children: ReactNode;
  footer?: ReactNode;
  openEdit: boolean;
  onCloseEdit: () => void;
  onSubmit?: () => void;
  tittle?: string;
  loading?: boolean;
  showCancel?: boolean;
}

const IndexPage = ({
  children,
  footer,
  openEdit,
  onCloseEdit,
  onSubmit,
  tittle = 'Update Form',
  showCancel = false,
  loading = false
}: Props) => {
  return (
    <div className="updateModal">
      <div
        className={`overlay ${openEdit ? 'active' : ' '}`}
        onClick={onCloseEdit}
      ></div>
      <div className={`updateModalForm ${openEdit ? 'active' : ' '}`}>
        <div className="updateModalForm__header">
          <HiArrowLeft
            size={24}
            style={{ cursor: 'pointer' }}
            onClick={onCloseEdit}
          />
          <p>{tittle}</p>
          <div></div>
        </div>

        <div className="updateModalForm__content">{children}</div>

        {/* form action */}

        {footer ? (
          footer
        ) : (
          <div className="updateModalForm__actions">
            <AppButton
              type="button"
              buttonSize="small"
              onClick={onSubmit}
              isLoading={loading}
            >
              Save
            </AppButton>

            {showCancel ? (
              <AppButton
                type="button"
                buttonSize="small"
                variant="secondary"
                onClick={onCloseEdit}
              >
                Cancel
              </AppButton>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(IndexPage);
