import {
  ENROLMENT_TYPE,
  MEMBER_TYPE,
  PAYMENT_TYPE,
  PROGRAM_TYPE
} from '../enums';
import { CLASS_TYPES } from '../enums/class.enum';
import { BOOKING_TYPE, PAYMENT_VALUE } from '../enums/classBooking.enum';
import { IOption } from '../interfaces';
import { BOOKING_OPTIONS } from '../../common/constants/classBooking.constant';
import {
  MEMBER_TYPE_OPTIONS,
  PROGRAM_TYPE_OPTIONS
} from '../../common/constants';

export const queryToObject = (queryString: string): Record<string, string> => {
  return queryString
    .slice(1)
    .split('&')
    .map((p) => p.split('='))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
};

export const objectToQuery = (object: Record<string, string>): string => {
  return new URLSearchParams(object).toString();
};

export const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

export const findAllIndices = <T,>(arr: Array<T>, val: T): Array<number> => {
  return arr.reduce((indices: Array<number>, element: T, index: number) => {
    if (element === val) {
      indices.push(index);
    }
    return indices;
  }, []);
};

export const getProgramType = (classType?: CLASS_TYPES): string => {
  if (!classType) return '';
  switch (classType) {
    case CLASS_TYPES.SERIES:
      return PROGRAM_TYPE.SERIES;
    case CLASS_TYPES.PRIVATE:
      return PROGRAM_TYPE.PRIVATE;
    case CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM:
      return PROGRAM_TYPE.INTENSIVE_HOLIDAY_PROGRAM;
    case CLASS_TYPES.ASSESSMENT_TRIAL:
      return PROGRAM_TYPE.ASSESSMENT_TRIAL;
    default:
      return '';
  }
};

export const getEnrolmentType = (enrolmentType?: BOOKING_TYPE): string => {
  if (!enrolmentType) return '';
  switch (enrolmentType) {
    case BOOKING_TYPE.CASUAL_CLASS:
      return ENROLMENT_TYPE.CASUAL_CLASS;
    case BOOKING_TYPE.ONGOING_CLASS:
      return ENROLMENT_TYPE.ONGOING_CLASS;
    case BOOKING_TYPE.HOLIDAY_PROGRAM:
      return ENROLMENT_TYPE.HOLIDAY_PROGRAM;
    case BOOKING_TYPE.ASSESSMENT_TRIAL:
      return ENROLMENT_TYPE.ASSESSMENT_TRIAL;
    case BOOKING_TYPE.MAKE_UP:
      return ENROLMENT_TYPE.MAKE_UP;
    default:
      return '';
  }
};

export const getPaymentType = (paymentType?: PAYMENT_VALUE): string => {
  if (!paymentType) return '';
  switch (paymentType) {
    case PAYMENT_VALUE.DIRECT_DEBIT:
      return PAYMENT_TYPE.DIRECT_DEBIT;
    case PAYMENT_VALUE.UPFRONT:
      return PAYMENT_TYPE.UPFRONT;
    default:
      return '';
  }
};

/**
 *
 * @param classType
 * @returns Array<IOption>
 */
export const enrolmentTypeOptionsHelper = (
  classType: CLASS_TYPES,
  isShowMakeup?: boolean
): Array<IOption> => {
  switch (classType) {
    case CLASS_TYPES.SERIES:
      const seriesArray = [
        {
          label: ENROLMENT_TYPE.ONGOING_CLASS,
          value: BOOKING_TYPE.ONGOING_CLASS
        },
        {
          label: ENROLMENT_TYPE.CASUAL_CLASS,
          value: BOOKING_TYPE.CASUAL_CLASS
        }
      ];
      if (isShowMakeup)
        seriesArray.push({
          label: ENROLMENT_TYPE.MAKE_UP,
          value: BOOKING_TYPE.MAKE_UP
        });
      return seriesArray;
    case CLASS_TYPES.PRIVATE:
      const privateArray = [
        {
          label: ENROLMENT_TYPE.ONGOING_CLASS,
          value: BOOKING_TYPE.ONGOING_CLASS
        }
      ];
      if (isShowMakeup)
        privateArray.push({
          label: ENROLMENT_TYPE.MAKE_UP,
          value: BOOKING_TYPE.MAKE_UP
        });
      return privateArray;
    case CLASS_TYPES.ASSESSMENT_TRIAL:
      return [
        {
          label: ENROLMENT_TYPE.ASSESSMENT_TRIAL,
          value: BOOKING_TYPE.ASSESSMENT_TRIAL
        }
      ];
    case CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM:
      return [
        {
          label: ENROLMENT_TYPE.HOLIDAY_PROGRAM,
          value: BOOKING_TYPE.HOLIDAY_PROGRAM
        }
      ];
    default:
      return [];
  }
};

export const getProgramTypeLabels = (classTypes?: CLASS_TYPES[]): string[] => {
  if (!classTypes) return ['--'];

  const filteredOptions = PROGRAM_TYPE_OPTIONS.filter((option) =>
    classTypes?.includes(option.value as CLASS_TYPES)
  );

  return filteredOptions.map((option) => option.label);
};

export const getEnrolmentTypeLabels = (
  enrollmentTypes?: BOOKING_TYPE[]
): string[] => {
  if (!enrollmentTypes) return ['--'];

  const filteredOptions = BOOKING_OPTIONS.filter((option) =>
    enrollmentTypes?.includes(option.value as BOOKING_TYPE)
  );

  return filteredOptions.map((option) => option.label);
};

export const getMemberTypeLabels = (memberTypes?: MEMBER_TYPE[]): string[] => {
  if (!memberTypes) return ['--'];

  const filteredOptions = MEMBER_TYPE_OPTIONS.filter((option) =>
    memberTypes?.includes(option.value as MEMBER_TYPE)
  );

  return filteredOptions.map((option) => option.label);
};

export const copyToClipboard = (data: string): void => {
  navigator.clipboard.writeText(data);
};
