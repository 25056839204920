import { FC, Fragment, useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import AppCheckbox from 'common/components/AppCheckbox';
import AppSelect from 'common/components/AppSelect';
import { IOption } from 'common/interfaces';
import { FormUpdateNotificationSettings } from 'DTOs/firebase.dto';
import { formatData } from '../../common/helpers/dataFormat.helper';
import { useAuth } from 'context/AuthContext';
import { PERMISSION } from 'common/enums/permission.enum';

import './desktop.scss';

interface Props {
  roles: IOption[];
  data: FormUpdateNotificationSettings;
  index: number;
  control: any;
  setValue: any;
  getValues: any;
  onUpdate: () => void;
}

const SettingItem: FC<Props> = ({
  roles,
  data,
  index,
  control,
  setValue,
  getValues,
  onUpdate
}) => {
  const { hasPermission } = useAuth();

  useFieldArray({
    control,
    name: `settingForm`
  });

  const handleSubText = (text: string): string => {
    if (text === 'New Website Enquiries')
      return 'Account created with no enrolment (abandoned cart)';
    if (text === 'New Enrollments')
      return 'All new enrolments including casual lessons, weekly lesson and intensive lessons';
    if (text === 'Student Cancellations')
      return 'Ongoing (DD) weekly lesson has submitted their 28 days notice. ';
    if (text === 'Absence Forms') return 'Absence forms that require approval';
    if (text === 'Non Attendance')
      return 'When a student has missed 4 or more consecutive lessons without notification';
    return '';
  };

  const handleUpdateField = useCallback(
    (
      field: 'isEmail' | 'isPushNotification' | 'userGroupIds' | 'weekDay',
      value?: string
    ) => {
      if (!hasPermission(PERMISSION.UPDATE_SETTING_USER_ROLE_NOTIFICATION))
        return;

      if (value) {
        setValue(`settingForm[${index}]`, {
          ...getValues().settingForm[index],
          [`${field}`]: value
        });
      } else {
        setValue(`settingForm[${index}]`, {
          ...getValues().settingForm[index],
          [`${field}`]: !!!data?.[`${field}`]
        });
      }
    },
    [data, getValues, setValue, index, hasPermission]
  );

  return (
    <>
      <Fragment>
        <div className="item1 name_ct">
          <div>{formatData(data?.eventName)}</div>
          <div className="text2">
            {formatData(handleSubText(data?.eventName))}
          </div>
        </div>
        <div className="item2 ct_event">
          <AppCheckbox
            checked={!!data?.isEmail}
            onChange={() => handleUpdateField('isEmail')}
          />
        </div>
        <div className="item2 ct_event">
          <AppCheckbox
            checked={!!data?.isPushNotification}
            onChange={() => handleUpdateField('isPushNotification')}
          />
        </div>
        <div className="item3">
          <AppSelect
            style={{ width: '100%' }}
            multiValue
            placeholder={'Select User Group'}
            searchable={true}
            options={roles}
            value={data?.userGroupIds}
            onChange={(val) =>
              handleUpdateField('userGroupIds', val.target.value)
            }
          />
        </div>
      </Fragment>
    </>
  );
};

export default SettingItem;
