import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import {
  ERROR_MESSAGE_DATE,
  FORMAT_END_OF_DATE,
  FORMAT_START_OF_DATE,
  REPORT_PATH,
  REPORT_PROGRAM_TYPE_OPTIONS
} from '../constant';
import AppDatePicker from 'common/components/AppDatePicker';
import AppSelect from 'common/components/AppSelect';
import AppButton from 'common/components/AppButton';
import { WEEK_DAY_STRING_OPTIONS } from 'common/constants';
import './desktop.scss';
import { CLASS_TYPES } from 'common/enums/class.enum';
import dayjs, { Dayjs } from 'dayjs';
import { useToast } from 'context/ToastContext';
import { getStudentOutstandingReport } from 'services/report.service';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { formatData, formatMoneySign } from 'common/helpers/dataFormat.helper';
import { IOutstandingReport } from 'common/interfaces/report.interface';
import { createColumnHelper } from '@tanstack/react-table';
import AppTable from 'common/components/AppTable';

type FilterOutstandingReport = {
  dateFrom: string;
  dateTo: string;
  programType: string;
  lessonDay: string;
};
const initFilterValue: FilterOutstandingReport = {
  dateFrom: '',
  dateTo: '',
  programType: '',
  lessonDay: ''
};
const ReportOutstanding: React.FC = () => {
  const toast = useToast();

  const [isGenerated, setIsGenerated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageTotal, setPageTotal] = useState<number>(0);

  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');
  const [programType, setProgramType] = useState<CLASS_TYPES | ''>('');
  const [lessonDay, setLessonDay] = useState<string>('');
  const currenFilter = useRef<FilterOutstandingReport>(initFilterValue);
  const dateError = React.useRef<string>('');

  const [data, setData] = useState<IOutstandingReport[]>([]);

  const __isDisableGenerateButton: boolean = React.useMemo(() => {
    return !dateFrom || !dateTo || !programType || !!dateError.current;
  }, [dateFrom, dateTo, programType]);

  const columnHelper = createColumnHelper<IOutstandingReport>();

  const columns = [
    columnHelper.accessor('studentDetail', {
      header: () => <span>Student Name</span>,
      cell: (info) => (
        <span>
          {formatData(info.getValue().lastName) +
            ', ' +
            formatData(info.getValue().firstName)}
        </span>
      )
    }),
    columnHelper.accessor('studentDetail', {
      header: () => <span>Student ID</span>,
      cell: (info) => <span>{formatData(info.getValue().memberId)}</span>
    }),
    columnHelper.accessor('rpDetail', {
      header: () => <span>RP Name</span>,
      cell: (info) => (
        <span>
          {formatData(info.getValue().lastName) +
            ', ' +
            formatData(info.getValue().firstName)}
        </span>
      )
    }),
    columnHelper.accessor('rpDetail', {
      header: () => <span>RP ID</span>,
      cell: (info) => <span>{formatData(info.getValue().memberId)}</span>
    }),
    columnHelper.accessor('enrolledClassCount', {
      header: () => <span>STUDENT NO. CURRENT BOOK</span>,
      cell: (info) => <span>{formatData(info.getValue())}</span>
    }),
    columnHelper.accessor('rpOutstandingAmount', {
      header: () => <span>FAMILY BALANCE</span>,
      cell: (info) => <span>{formatMoneySign(info.getValue())}</span>
    }),
    columnHelper.accessor('studentOutstandingAmount', {
      header: () => <span>STUDENT BOOKING BALANCE</span>,
      cell: (info) => <span>{formatMoneySign(info.getValue())}</span>
    })
  ];

  const handleChangeDateFrom = (date: Dayjs | null) => {
    setDateFrom(date?.format(FORMAT_START_OF_DATE) || '');
    if (date?.isAfter(dayjs(dateTo))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeDateTo = (date: Dayjs | null) => {
    setDateTo(date?.format(FORMAT_END_OF_DATE) || '');
    if (date?.isBefore(dayjs(dateFrom))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const fetchDataOutstanding = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getStudentOutstandingReport(
        pageIndex,
        pageSize,
        convertToUnixTime(currenFilter.current.dateFrom),
        convertToUnixTime(currenFilter.current.dateTo),
        currenFilter.current.programType,
        currenFilter.current.lessonDay
      );
      setIsGenerated(true);
      setData(data.data.data);
      setPageTotal(data.data.total);
      setDateFrom(currenFilter.current.dateFrom);
      setDateTo(currenFilter.current.dateTo);
      setProgramType(currenFilter.current.programType as CLASS_TYPES);
      setLessonDay(currenFilter.current.lessonDay);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || ' Failed to generate data');
      setIsGenerated(false);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [pageIndex, pageSize]);
  const handleGenerateData = async () => {
    currenFilter.current = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      programType: programType,
      lessonDay: lessonDay
    };
    await fetchDataOutstanding();
  };

  useEffect(() => {
    if (isGenerated) fetchDataOutstanding();
    // eslint-disable-next-line
  }, [pageIndex, pageSize]);

  return (
    <main id="reportOutstanding" className="reportOutstanding">
      <AppBreadCrumb
        items={[
          { name: 'Reporting', href: REPORT_PATH },
          { name: 'Outstanding' }
        ]}
      />
      <div className="layoutContainer reportOutstanding__wrapper">
        <section className="reportOutstanding__search">
          <div className="reportOutstanding__search__fields">
            <AppDatePicker
              size="small"
              label="Date from"
              value={dayjs(dateFrom)}
              onChange={handleChangeDateFrom}
            />
            <AppDatePicker
              size="small"
              label="Date to"
              value={dayjs(dateTo)}
              onChange={handleChangeDateTo}
              message={{
                type: 'error',
                text: dateError.current
              }}
            />
            <AppSelect
              searchable={false}
              inputSize="small"
              label="Program Type"
              placeholder="Select option"
              options={REPORT_PROGRAM_TYPE_OPTIONS}
              value={programType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setProgramType(e.target.value as CLASS_TYPES);
              }}
            />
            <AppSelect
              searchable={false}
              inputSize="small"
              label="Lesson Day"
              placeholder="Select option"
              options={[
                { label: 'All', value: '' },
                ...WEEK_DAY_STRING_OPTIONS
              ]}
              value={lessonDay}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLessonDay(e.target.value);
              }}
            />
          </div>
          <AppButton
            variant="primary"
            buttonSize="small"
            onClick={handleGenerateData}
            disabled={__isDisableGenerateButton}
          >
            generate
          </AppButton>
        </section>

        {isGenerated && (
          <section className="reportOutstanding__content">
            <div className="reportOutstanding__content__header">
              <p className="reportOutstanding__content__header--title">
                Outstanding
              </p>
            </div>

            <div className="reportOutstanding__content__table">
              <AppTable
                data={data}
                columns={columns}
                pagination={{
                  index: pageIndex,
                  size: pageSize,
                  total: pageTotal
                }}
                loading={loading}
                onChangePage={(index: number, size: number) => {
                  setPageIndex(index);
                  setPageSize(size);
                }}
              />
            </div>
          </section>
        )}
      </div>
    </main>
  );
};

export default ReportOutstanding;
