import {
  HEIGHT_CELL_CALENDAR,
  WEEK_DAY_STRING_OPTIONS
} from '../constants/index';
import dayjs, { Dayjs } from 'dayjs';
import { IClass, IRecurrence } from '../interfaces/class.interface';
import { pickBy, sortBy } from 'lodash';
import { formatData, formatTime } from './dataFormat.helper';
import { CLASS_TYPES, RECURRENCE_VALUES } from '../enums/class.enum';
import { ITerm } from '../interfaces/term.interface';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { IRPEnrollmentClass } from '../interfaces/responsiblePerson.interface';
dayjs.extend(utc);
dayjs.extend(timezone);

export const secondsToHms = (d: number) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? '' : '') : '';
  var mDisplay = m > 0 ? m + (m === 1 ? '' : '') : '';
  var sDisplay = s > 0 ? s + (s === 1 ? '' : '') : '';
  if (hDisplay !== '') {
    return (
      (hDisplay.length > 1 ? hDisplay : '0' + hDisplay) +
      ':' +
      (mDisplay.length > 1 ? mDisplay : '0' + mDisplay) +
      ':' +
      (sDisplay.length > 1 ? sDisplay : '0' + sDisplay)
    );
  } else if (mDisplay !== '') {
    return (
      (mDisplay.length > 1 ? mDisplay : '0' + mDisplay) +
      ':' +
      (sDisplay.length > 1 ? sDisplay : '0' + sDisplay)
    );
  } else if (sDisplay !== '') {
    return '00:' + (sDisplay.length > 1 ? sDisplay : '0' + sDisplay);
  }
  return '00:00';
};

export const getCurrentTime = () => {
  const d = new Date();
  const hours = d.getHours();
  const minutes = d.getMinutes();
  if (hours >= 22 || hours < 6) return { top: -10, hours: 0, minutes: '00' };
  const top =
    hours * HEIGHT_CELL_CALENDAR +
    (HEIGHT_CELL_CALENDAR * minutes) / 60 -
    6 * HEIGHT_CELL_CALENDAR;

  return {
    top,
    hours,
    minutes: minutes < 10 ? `0${minutes}` : minutes
  };
};
export function convertFloatToTime(input: number): string {
  const hours = Math.floor(input);
  const minutes = Math.round((input - hours) * 60);
  return `${hours}:${minutes.toString().padStart(2, '0')}`;
}
export function convertToUnixTime(input: string): number {
  return dayjs(input).valueOf();
}
export function calculateEndTime(
  startTime: Dayjs,
  duration: number | string
): string {
  const resultTime = startTime.add(Number(duration), 'minutes').format('HH:mm');
  return resultTime;
}

export const convertRecurrenceToString = (classData: IClass | undefined) => {
  if (!classData) return '--';
  const { recurrence } = classData;
  const recurrenceValue = recurrence?.type;
  const recurrenceType = recurrence.type?.replaceAll('_', ' ');

  if (!recurrenceType || recurrenceValue === '') {
    return '-';
  }

  if (recurrenceValue === RECURRENCE_VALUES.DO_NOT_REPEAT) {
    return recurrenceType;
  }
  const startTime = classData.session?.startTime || classData.startTime;
  const endTime = classData.session?.endTime || classData.endTime;

  const days = pickBy(recurrence, (value) => value === true);
  if (Object.keys(days).length === 7) {
    return `Daily at ${formatTime(startTime)} - ${formatTime(endTime)}`;
  }

  const customValue = Object.keys(days)
    .map((item) => item.toLocaleUpperCase())
    .join(', ');

  return `WEEKLY on ${customValue} at ${formatTime(startTime)} - ${formatTime(
    endTime
  )}`;
};
export const getLessonDay = (enrolment: IRPEnrollmentClass) => {
  if (enrolment?.class?.type === CLASS_TYPES.SERIES) {
    if (
      enrolment?.actualSessions[0]?.startTime ||
      enrolment?.allSessions[0]?.startTime
    ) {
      return dayjs(
        enrolment?.actualSessions[0]?.startTime ||
          enrolment?.allSessions[0]?.startTime
      ).format('dddd');
    } else {
      return '--';
    }
  }
  const recurrenceValue = enrolment?.class?.recurrence?.type;
  const recurrenceType = enrolment?.class?.recurrence?.type?.replaceAll(
    '_',
    ' '
  );

  if (recurrenceValue === '') {
    return '-';
  }

  if (recurrenceValue === RECURRENCE_VALUES.DO_NOT_REPEAT) {
    return formatData(recurrenceType);
  }

  const days = pickBy(enrolment?.class?.recurrence, (value) => value === true);
  if (Object.keys(days).length === 7) {
    return `Daily`;
  }
  const customValue = Object.keys(days)
    .map(
      (item) => WEEK_DAY_STRING_OPTIONS.filter((i) => i.value === item)[0].label
    )
    .join(', ');

  return formatData(customValue);
};

export const convertLogRecurrenceToString = (
  recurrence: IRecurrence
): string | undefined => {
  const recurrenceValue = recurrence.type;
  const recurrenceType = recurrence.type?.replaceAll('_', ' ');

  if (recurrenceValue === '') {
    return '-';
  }

  if (recurrenceValue === RECURRENCE_VALUES.DO_NOT_REPEAT) {
    return recurrenceType;
  }

  const days = pickBy(recurrence, (value) => value === true);
  const customValue = Object.keys(days)
    .map((item) => item.toLocaleUpperCase())
    .join(', ');

  return `WEEKLY ON ${customValue} END OF ${dayjs(recurrence?.endDate).format(
    'MMM D YYYY'
  )}`;
};
export const getCurrentUserTimeZone = () => {
  return dayjs.tz.guess();
};

export function getTimezoneOffset(timezoneString: string) {
  if (!timezoneString || timezoneString.length < 6) {
    return 0;
  }

  const offset = Number(timezoneString.slice(1, 3));

  if (timezoneString[0] === '-') {
    return -offset;
  } else {
    return offset;
  }
}

export const getDaysArray = (start: Date, end: Date) => {
  const daysArray: Date[] = [];
  let currentDate = new Date(start);
  end.setHours(23);
  end.setMinutes(59);
  end.setSeconds(59);

  while (currentDate <= new Date(end)) {
    daysArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return daysArray;
};

export const getNearestFromNowByDateNTime = (listDates: Date[]) => {
  const listDatesWithTimeStamp = listDates.map((d) => d.getTime());
  return new Date(Math.max(...listDatesWithTimeStamp));
};

export const getFarthestFromNowByDateNTime = (listDates: Date[]) => {
  const listDatesWithTimeStamp = listDates.map((d) => d.getTime());
  return new Date(Math.min(...listDatesWithTimeStamp));
};

export const getStartDateNEndDate = (
  terms: ITerm[]
): { startDate: string; endDate: string } => {
  const sortedTerms = sortBy(terms, 'endDate');
  if (terms.length === 1) {
    return {
      startDate: dayjs(
        terms[0].startDate || terms[0].termDetail?.startDate
      ).format('YYYY-MM-DD'),
      endDate: dayjs(terms[0].endDate || terms[0].termDetail?.endDate).format(
        'YYYY-MM-DD'
      )
    };
  }
  let index = 0;
  while (
    dayjs(
      sortedTerms[index].endDate || sortedTerms[index].termDetail?.endDate
    ).isBefore(dayjs())
  ) {
    index += 1;
  }
  const result = {
    startDate: dayjs(
      sortedTerms[index].startDate || sortedTerms[index].termDetail?.startDate
    ).format('YYYY-MM-DD'),
    endDate: dayjs(
      sortedTerms[index].endDate || sortedTerms[index].termDetail?.endDate
    ).format('YYYY-MM-DD')
  };
  return result;
};
