import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HiArrowLeft, HiXMark } from 'react-icons/hi2';
import AppButton from 'common/components/AppButton';
import AppCard from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import { CreateTermDto } from 'DTOs/term.dto';
import { TERM_TYPE } from 'common/enums/term.enum';
import yupTerm from 'validators/term.validator';
import { Resolver, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AppSelect from 'common/components/AppSelect';
import AppDatePicker from 'common/components/AppDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useToast } from 'context/ToastContext';
import { createTerm } from 'services/term.service';
import {
  convertToUnixTime,
  getCurrentUserTimeZone
} from 'common/helpers/time.helper';
import { useBrandLocation } from 'context/BrandLocationContext';
import { useLocation } from 'react-router-dom';

import './desktop.scss';
import { FORMAT_END_OF_DATE } from 'pages/reports/constant';

type TermListType = {
  terms?: CreateTermDto[];
};

interface ITermAddFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const validationSchema = yupTerm.OBJECT({
  terms: yupTerm.ARRAY(
    yupTerm.OBJECT({
      name: yupTerm.TERM_NAME,
      startDate: yupTerm.TERM_START_DATE,
      endDate: yupTerm.TERM_END_DATE.test(
        'start-date-after-end-date',
        function (value, testContext) {
          const { parent, path } = testContext;
          const { startDate } = parent;
          if (dayjs(startDate).isAfter(dayjs(value))) {
            return this.createError({
              path: path,
              message: 'Please select end date after start date'
            });
          }
          return true;
        }
      ),
      type: yupTerm.TERM_TYPE,
      locationIds: yupTerm.TERM_LOCATIONS
    })
  )
});

const TermAddForm = ({ open, onClose, onSuccess }: ITermAddFormProps) => {
  const toast = useToast();
  const location = useLocation();
  const { locations } = useBrandLocation();
  const [disabled, setDisabled] = useState(true);

  const locationList = useMemo(() => {
    const result = locations
      ?.filter((item) => item?._id)
      ?.map((item) => {
        return {
          label: item.shortName || item.name,
          value: item._id
        };
      });
    result.unshift({ label: 'All Locations', value: 'all' });
    return result;
  }, [locations]);

  const isHoliday = useMemo(
    () => location.pathname.includes('/holiday'),
    [location.pathname]
  );

  const initTerm: CreateTermDto = useMemo(() => {
    return {
      name: '',
      startDate: '',
      endDate: '',
      type: location.pathname.includes('/holiday')
        ? TERM_TYPE.STOP_BILLING
        : TERM_TYPE.TERM,
      locationIds: ''
    };
  }, [location]);

  const {
    register,
    setValue,
    reset,
    control,
    trigger,
    handleSubmit: onSubmit,
    formState: { errors }
  } = useForm<TermListType>({
    resolver: yupResolver(validationSchema) as Resolver<TermListType>,
    defaultValues: { terms: [initTerm] }
  });

  const watchAllFields = useWatch({ control });

  useEffect(() => {
    if (
      !watchAllFields.terms?.filter(
        (term: Partial<CreateTermDto>) =>
          Object.values(term)?.filter((values: string) => !values)?.length
      )?.length &&
      !Object.values(errors)?.filter((values: any) => values.message)?.length
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [watchAllFields, errors]);

  const { fields, append, remove } = useFieldArray({
    name: 'terms',
    control
  });

  const deleteTerm = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const handleAddTermItem = useCallback(() => {
    append({
      name: '',
      startDate: '',
      endDate: '',
      type: location.pathname.includes('/holiday')
        ? TERM_TYPE.STOP_BILLING
        : TERM_TYPE.TERM,
      locationIds: ''
    });
  }, [append, location.pathname]);

  const onCloseAdd = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  const handleSubmit = async () => {
    const formData = { ...watchAllFields };

    if (disabled) {
      trigger();
      return;
    }

    try {
      setDisabled(true);

      await createTerm({
        terms: (formData.terms || []).map((term) => {
          const allLocationsSelected =
            term.locationIds === 'all' ||
            [...(term.locationIds?.split(',') || [])]?.filter(
              (item) => item === 'all'
            )?.length > 0;

          return {
            name: term.name || '',
            startDate: convertToUnixTime(term.startDate || ''),
            endDate: convertToUnixTime(term.endDate || ''),
            type: term.type as TERM_TYPE,
            locationIds:
              term.locationIds
                ?.split(',')
                .filter((item) => item !== 'all' && item !== '') || [],
            forAllLocation: allLocationsSelected
          };
        }),
        timezone: getCurrentUserTimeZone()
      });

      toast.success('Session created successfully');

      reset({ terms: [initTerm] });

      onSuccess();
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Failed to create Session');
    } finally {
      setDisabled(false);
    }
  };

  const onChangeValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    // @ts-ignore
    setValue(event.target.name, event.target.value);
    // @ts-ignore
    trigger(event.target.name);
  };

  const handleChangeDate = useCallback(
    (value: Dayjs | null, index: number, field: string) => {
      if (isHoliday && field === 'endDate') {
        // @ts-ignore
        setValue(`terms.${index}.${field}`, value?.format(FORMAT_END_OF_DATE));

        // @ts-ignore
        trigger(`terms.${index}.${field}`);
        return;
      }
      // @ts-ignore
      setValue(`terms.${index}.${field}`, value?.format('YYYY-MM-DD'));

      // @ts-ignore
      trigger(`terms.${index}.${field}`);
    },
    [setValue, trigger, isHoliday]
  );

  return (
    <div className="termAdditionFormContainer">
      <div
        className={`overlay ${open ? 'active' : ' '}`}
        onClick={onClose}
      ></div>
      <div className={`termAdditionForm ${open ? 'active' : ' '}`}>
        <div className="termAdditionForm__header">
          <HiArrowLeft
            size={24}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
          <p>Add {isHoliday ? 'a Public Holiday' : 'Session'}</p>
        </div>
        <div className="termAdditionForm__content">
          <AppCard>
            <h3>{isHoliday ? 'Public Holidays' : 'Session'}</h3>
            <div className="termAdditionForm__content-wrapper">
              {fields.map((_, index) => {
                return (
                  <div className="termAdditionForm__content-row" key={index}>
                    <AppInput
                      {...register(`terms.${index}.name`)}
                      label="Name"
                      message={{
                        type: 'error',
                        text: errors?.terms?.[index]?.name?.message || ''
                      }}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeValue(event, index)
                      }
                    />
                    <AppDatePicker
                      {...register(`terms.${index}.startDate`)}
                      label="Start date*:dd/mm/yyyy"
                      value={dayjs(fields[index].startDate)}
                      message={{
                        type: 'error',
                        text: errors?.terms?.[index]?.startDate?.message || ''
                      }}
                      onChange={(value: Dayjs | null) =>
                        handleChangeDate(value, index, 'startDate')
                      }
                    />
                    <AppDatePicker
                      {...register(`terms.${index}.endDate`)}
                      label="End date*:dd/mm/yyyy"
                      value={dayjs(fields[index].endDate)}
                      message={{
                        type: 'error',
                        text: errors?.terms?.[index]?.endDate?.message || ''
                      }}
                      onChange={(value: Dayjs | null) =>
                        handleChangeDate(value, index, 'endDate')
                      }
                    />
                    <AppSelect
                      {...register(`terms.${index}.locationIds`)}
                      options={locationList}
                      searchable={false}
                      label="Location"
                      multiValue
                      value={fields[index].locationIds}
                      onChange={(val) => {
                        setValue(
                          `terms.${index}.locationIds`,
                          val.target.value
                        );
                        trigger(`terms.${index}.locationIds`);
                      }}
                      onClearSelection={() => {}}
                      message={{
                        type: 'error',
                        text: errors?.terms?.[index]?.locationIds?.message || ''
                      }}
                    />
                    <div className="item_single">
                      {fields.length > 0 && index > 0 ? (
                        <button type="button" onClick={() => deleteTerm(index)}>
                          <HiXMark fontSize={24} />
                        </button>
                      ) : null}
                    </div>
                  </div>
                );
              })}

              <AppButton
                onClick={handleAddTermItem}
                variant="secondary"
                buttonSize="small"
                className="button-add-more"
              >
                Add {isHoliday ? 'a Public Holiday' : 'Session'}
              </AppButton>
            </div>
          </AppCard>
        </div>
        <div className="termAdditionForm__actions">
          <AppButton
            onClick={onSubmit(handleSubmit)}
            // isLoading={loading}
          >
            Save
          </AppButton>
          <AppButton variant="secondary" onClick={onCloseAdd}>
            Cancel
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default TermAddForm;
