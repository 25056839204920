import { ITerm } from '../interfaces/term.interface';
import { formatDate } from './dataFormat.helper';

export const generateTermData = (terms: ITerm[]) => {
  if (!terms.length) return '--';
  return terms
    .map(
      (t: ITerm) =>
        `${t.termDetail?.name || t.name} (${formatDate(
          t.termDetail?.startDate || t.startDate
        )} - ${formatDate(t.termDetail?.endDate || t.endDate)})`
    )
    .join('\n');
};

export const generateTermDataHtml = (terms: ITerm[], termIsActive?: string) => {
  if (!terms.length) return '--';
  return terms.map((t: ITerm) => (
    <div key={t._id} style={{ marginBottom: '6px' }}>
      {termIsActive && termIsActive === t.termId && (
        <span
          style={{
            fontWeight: '500',
            fontSize: '8px',
            textTransform: 'uppercase',
            borderRadius: '9px',
            background: '#034EA2',
            padding: '4px 6px',
            marginRight: '8px'
          }}
        >
          Active
        </span>
      )}
      <span>{`${t.termDetail?.name || t.name} (${formatDate(
        t.termDetail?.startDate || t.startDate
      )} - ${formatDate(t.termDetail?.endDate || t.endDate)})`}</span>
    </div>
  ));
};
