import { useAuth } from 'context/AuthContext';
import { privateIndexRoutes } from './indexRoutes';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { useCallback, useEffect, useMemo } from 'react';
import NotFound from 'pages/NotFound';
import {
  getFirebaseNotifications,
  updateUserFcmToken
} from 'services/firebase.service';
import { onMessageListener, requestForToken } from './../firebase';
import { IFirebaseNotification } from 'common/interfaces/firebase.interface';
import { useToast } from 'context/ToastContext';

const PrivateRoutes = (): JSX.Element => {
  const { me, hasPermission, user, onInitTotalNotifications } = useAuth();
  const toast = useToast();

  const __isAllowed = useMemo((): boolean => {
    return !!me;
  }, [me]);

  const fetchNotifications = useCallback(async () => {
    if (!user?._id || !me) return;

    try {
      const { data } = await getFirebaseNotifications({
        userId: user?._id,
        page: 1,
        limit: 100
      });

      onInitTotalNotifications(
        data?.data?.data?.filter((dt: IFirebaseNotification) => !dt?.isRead)
          ?.length
      );
    } catch (error: any) {
      toast.error(
        'failed: ',
        error?.response?.data?.message || 'Fail to fetch notifications'
      );
    }

    // eslint-disable-next-line
  }, [user?._id, onInitTotalNotifications]);

  useEffect(() => {
    if (!user?._id) return;

    try {
      requestForToken((fcmToken) => {
        updateUserFcmToken(user?._id, {
          oldToken: '',
          newToken: fcmToken
        });

        fetchNotifications();
      });
    } catch (error: any) {
      toast.error(
        'failed: ',
        error?.response?.data?.message || 'Fail to update fcm token'
      );
    }

    onMessageListener()
      .then(() => fetchNotifications())
      .catch((err) =>
        toast.error('failed: ', err || 'Fail to listen notifications')
      );

    // eslint-disable-next-line
  }, [user?._id, fetchNotifications]);

  return (
    <Routes>
      {privateIndexRoutes.map((item, key) => {
        return (
          <Route
            key={key}
            element={
              <ProtectedRoute isAllowed={__isAllowed} redirectPath={'/login'} />
            }
          >
            <Route path={item.path} element={item.layout}>
              {item?.routes?.map((route, idx) => {
                if (!!route.permission && !hasPermission(route.permission))
                  return (
                    <Route
                      path={route.path}
                      element={<NotFound />}
                      key={idx + route.path}
                    />
                  );
                else if (!route.children) {
                  return (
                    <Route
                      path={route.path}
                      element={route.component}
                      key={idx + route.path}
                    />
                  );
                } else {
                  return route.children.map((child, indexChild) => {
                    return (
                      <Route
                        path={child.path}
                        element={child.component}
                        key={'children' + indexChild + child.path}
                      />
                    );
                  });
                }
              })}
            </Route>
          </Route>
        );
      })}
    </Routes>
  );
};

export default PrivateRoutes;
