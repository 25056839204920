import { useCallback, useEffect, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import useDebounce from 'common/hooks/useDebounce';
import AppTable from 'common/components/AppTable';
import AppInputSearch from 'common/components/AppInputSearch';
import AppCard from 'common/components/AppCard';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import { IStudentListResponsiblePerson } from 'common/interfaces/student.interface';
import {
  formatData,
  formatDate,
  formatSecretPhoneNumber
} from 'common/helpers/dataFormat.helper';
import { useToast } from 'context/ToastContext';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssessmentResultsHistory } from 'services/assessment.service';
import { IAssessmentResults } from 'common/interfaces/assessment.interface';
import ActionPopper from '../ActionPopper';
import ClassAssessmentModal from 'components/Assessment/ClassAssessmentModal';
import { IClass } from 'common/interfaces/class.interface';
import { useAuth } from 'context/AuthContext';
import { PERMISSION } from 'common/enums/permission.enum';
import './desktop.scss';
import { CLASS_TYPES } from 'common/enums/class.enum';

interface IAssessmentHistoryInClassProps {
  classDetail: IClass | null;
}

const AssessmentHistoryInClass: React.FC<IAssessmentHistoryInClassProps> = (
  props: IAssessmentHistoryInClassProps
) => {
  const { classDetail } = props;

  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { hasPermission } = useAuth();

  const columnHelper = createColumnHelper<IAssessmentResults>();

  const columns = [
    columnHelper.accessor('student', {
      header: () => <span>SURNAME, NAME</span>,
      cell: (info) =>
        formatData(info.getValue().lastName) +
        ', ' +
        formatData(info.getValue().firstName)
    }),
    columnHelper.accessor('student', {
      header: () => <span>DOB</span>,
      cell: (info) => formatDate(info.getValue().dob)
    }),
    columnHelper.accessor('student', {
      header: () => <span>RP NAME</span>,
      cell: (info) => {
        if (info.getValue().listResponsiblePerson?.length) {
          return info
            .getValue()
            .listResponsiblePerson?.map(
              (person: IStudentListResponsiblePerson, i: number) => {
                if (i === 0) {
                  return (
                    formatData(person?.responsiblePersonInfo?.lastName) +
                    ', ' +
                    formatData(person?.responsiblePersonInfo?.firstName)
                  );
                }
                return null;
              }
            );
        }

        return formatData(false) + ', ' + formatData(false);
      },
      size: 200
    }),
    columnHelper.accessor('student', {
      header: () => <span>RP Mobile</span>,
      cell: (info) => {
        if (info.getValue().listResponsiblePerson?.length) {
          return info
            .getValue()
            .listResponsiblePerson?.map(
              (person: IStudentListResponsiblePerson, i: number) => {
                if (i === 0) {
                  return formatSecretPhoneNumber(
                    person?.responsiblePersonInfo?.phoneNumber
                  );
                }
                return null;
              }
            );
        }

        return formatData(false);
      }
    }),
    columnHelper.accessor('skillResults', {
      header: () => <span>last assessment</span>,
      cell: (info) => {
        const index = (info.getValue()?.length || 0) - 1;
        if (index < 0) {
          return formatData(null);
        }
        return formatDate(info.getValue()?.[index]?.createdAt);
      }
    }),
    columnHelper.accessor('assessmentStatus', {
      header: () => <span>Result</span>,
      cell: (info) => {
        const isNewSTAssessment =
          classDetail?.type === CLASS_TYPES.ASSESSMENT_TRIAL;
        if (isNewSTAssessment) {
          return formatData(info.row.original?.resultLevel?.name);
        }
        return (
          <div
            className={`assessmentStatus assessmentStatus__${info.getValue()}`}
          >
            {formatData(info.getValue()?.replace('-', ' '))}
          </div>
        );
      }
    }),
    columnHelper.accessor('reviewStatus', {
      header: () => <span>status</span>,
      cell: (info) => (
        <div
          className={`assessmentStatus assessmentStatus__${info.getValue()}`}
        >
          {formatData(info.getValue().replaceAll('-', ' '))}
        </div>
      )
    }),
    columnHelper.accessor('studentId', {
      header: () => (
        <span style={{ display: 'block', textAlign: 'center' }}>Action</span>
      ),
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <ActionPopper
              onViewStudent={
                hasPermission(PERMISSION.VIEW_DETAIL_STUDENT)
                  ? () => handleViewStudent(info.getValue())
                  : undefined
              }
              onViewAssessment={() => {
                if (
                  (!info.row.original.skillResults ||
                    info.row.original.skillResults?.length === 0) &&
                  !hasPermission(PERMISSION.VIEW_DETAIL_LEVEL_BREAKDOWN)
                )
                  return;
                else handleViewAssessStudent(info.row.original);
              }}
            />
          </div>
        );
      }
    })
  ];

  const [data, setData] = useState<Array<IAssessmentResults>>([]);
  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const debouncedSearch = useDebounce<string>(search, 800);

  // const [selectedAssessmentResult, setSelectedAssessmentResult] =
  //   useState<Array<ISkillResult> | null>(null);
  const [selectedAssessmentResult, setSelectedAssessmentResult] =
    useState<IAssessmentResults | null>(null);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const handleViewStudent = (studentId: string) => {
    navigate(`/students/${studentId}`);
  };

  const handleViewAssessStudent = (assessmentResult: IAssessmentResults) => {
    setSelectedAssessmentResult(assessmentResult);
  };
  const handleCloseViewAssessStudent = () => {
    setSelectedAssessmentResult(null);
  };

  const fetchData = useCallback(async () => {
    if (!params.id) return;

    setLoading(true);

    try {
      const result = await getAssessmentResultsHistory(
        params.id,
        page,
        limit,
        debouncedSearch
      );

      setData(result.data.data.data);
      setTotal(result.data.data.total);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'Failed to get assessment history list'
      );
      setData([]);
      setTotal(0);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [debouncedSearch, page, limit, params.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="assessmentHistoryInClass">
      {/* View */}
      {!!selectedAssessmentResult && selectedAssessmentResult.levelId && (
        <ClassAssessmentModal
          levelId={selectedAssessmentResult.levelId}
          open={!!selectedAssessmentResult}
          onClose={handleCloseViewAssessStudent}
          defaultSkillResults={selectedAssessmentResult.skillResults}
          title={`${formatData(
            selectedAssessmentResult.student.firstName
          )} - (${formatData(selectedAssessmentResult?.level?.name)})`}
          newSTAssessment={classDetail?.type === CLASS_TYPES.ASSESSMENT_TRIAL}
          resultLevel={selectedAssessmentResult.resultLevel}
        />
      )}

      <AppCard>
        <div className="assessmentHistoryInClass__table">
          <div className="assessmentHistoryInClass__table-header">
            <h2 className="assessmentHistoryInClass__table-header-title">
              Students
            </h2>
            <div className="assessmentHistoryInClass__table-header-search">
              <AppInputSearch
                type="text"
                value={search}
                onChange={onChangeSearch}
                placeholder="Surname, name, member id, mobile"
                onClearSearch={onClearSearch}
                startIcon={<HiMagnifyingGlass />}
              />
            </div>
          </div>
          <div className="assessmentHistoryInClass__table-content">
            <AppTable
              data={data}
              columns={columns}
              pagination={{
                index: page,
                size: limit,
                total: total
              }}
              onChangePage={(index: number, size: number) => {
                setPage(index);
                setLimit(size);
              }}
              loading={loading}
            />
          </div>
        </div>
      </AppCard>
    </div>
  );
};

export default AssessmentHistoryInClass;
