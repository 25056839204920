import { HiOutlineChevronDown } from 'react-icons/hi';
import { NavLink, useLocation } from 'react-router-dom';

import { useStepController } from 'context/StepControllerContext';

interface SidebarItemProps {
  open?: boolean;
  setOpen?: () => void;
  item: {
    children?: Array<any>;
    title: string;
    path?: string;
  };
}

export default function SidebarItem({ open, setOpen, item }: SidebarItemProps) {
  const location = useLocation();
  const { dataSet } = useStepController();

  const isActive = !!(
    (item.path?.length === 1 && location.pathname?.length === 1) ||
    (item.path &&
      item.path?.length > 1 &&
      location.pathname?.includes(item.path)) ||
    item.children?.find((i) => i.path === location.pathname)
  );

  const handleClickSidebarItem = (path: string) => {
    if (path === '/classes') {
      dataSet?.cleanUpFlow();
    }
  };

  if (item.children) {
    return (
      <div
        className={`sidebar-item ${isActive && open ? 'active' : ''} ${
          open ? 'open' : ''
        }`}
      >
        <div
          className={`sidebar-title ${isActive && open ? 'active' : ''} ${
            open && 'open'
          }`}
        >
          <div
            className="sidebar_tittle_content"
            onClick={() => {
              if (setOpen) setOpen();
            }}
          >
            {item.title}
            <HiOutlineChevronDown />
          </div>
        </div>
        <div className="sidebar-content">
          {item.children.map((child, index) => (
            <SidebarItem key={index} item={child} />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <NavLink
        to={item.path || '#'}
        className={({ isActive }) =>
          `sidebar-item ${isActive ? 'active' : ''} sidebar-title`
        }
        onClick={() => {
          if (item.path) handleClickSidebarItem(item.path);

          if (setOpen) setOpen();
        }}
      >
        <div className="sidebar_tittle_content">{item.title}</div>
      </NavLink>
    );
  }
}
