import React, { useCallback, useEffect, useState } from 'react';
import ColorLevelQuickFilter from 'components/colorLevelFilter/ColorLevelQuickFilter';
import SummaryInformation from './SummaryInformation';
import RollCallForClassSession from './RollCallForClassSession';
import '../desktop.scss';
import { IColorSetting } from 'common/interfaces/dashboard.interface';
import AppSelect from 'common/components/AppSelect';
import { useBrandLocation } from 'context/BrandLocationContext';
import { getDetailGroupRollCall } from 'services/rollCalls.service';
import {
  IRollCallDetail,
  ISummaryInfoRollCall
} from 'common/interfaces/rollCall.interface';
import { ROLL_CALL_TYPE } from 'common/enums/rollCall.enum';
import dayjs from 'dayjs';
import { useToast } from 'context/ToastContext';
import AppButton from 'common/components/AppButton';
import { useNavigate } from 'react-router-dom';
import { LevelSetting } from 'common/interfaces/levelBreakdown.interface';
import {
  getLevelBreakdowns,
  updateLevelSetting
} from 'services/levelBreakdown.service';
import { useAuth } from 'context/AuthContext';

type FilterValue = {
  levelIds: string;
  areas: string;
};
interface Props {
  rollCalls: IRollCallDetail[];
}
const RollCallByTime: React.FC<Props> = (props: Props) => {
  const { selectedLocation: globalLocation } = useBrandLocation();
  const toast = useToast();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [colorSettings, setColorSettings] = useState<IColorSetting[]>([]);
  const [currentFilter, setCurrentFilter] = useState<FilterValue>({
    levelIds: '',
    areas: ''
  });
  const [rollCalls, setRollCalls] = useState<IRollCallDetail[]>(
    props.rollCalls
  );
  const [isEmergency, setIsEmergency] = useState<boolean>(false);
  const [summaryInfo, setSummaryInfo] = useState<ISummaryInfoRollCall>({
    date: '',
    time: ''
  });

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getDetailGroupRollCall(
        props.rollCalls.map((i) => i._id),
        currentFilter.levelIds,
        currentFilter.areas
      );
      setRollCalls(data.data);
      if (data.data.length) {
        const rollCall = data.data?.[0];
        setIsEmergency(rollCall?.type === ROLL_CALL_TYPE.EMERGENCY);
        setSummaryInfo({
          date: dayjs(rollCall?.startTime).format('ddd D, MMMM'),
          time: `${dayjs(rollCall?.startTime).format('HH:mm')} to ${dayjs(
            rollCall?.endTime
          ).format('HH:mm')}`
        });
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to load roll call!'
      );
    }
    // eslint-disable-next-line
  }, [props.rollCalls, currentFilter]);

  useEffect(() => {
    fetchData();
  }, [currentFilter, fetchData]);

  const getColorSettingData = useCallback(async () => {
    try {
      const response = await getLevelBreakdowns(
        1,
        100,
        globalLocation?._id || ''
      );
      setColorSettings(
        response.data.data.data.map((item: IColorSetting) => {
          return {
            ...item,
            colorCode: item.userColorSetting?.colorCode || item.colorCode
          };
        })
      );
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to load list level!'
      );
    }
    // eslint-disable-next-line
  }, [globalLocation?._id]);

  useEffect(() => {
    getColorSettingData();
  }, [getColorSettingData]);

  const onClickLevel = useCallback(
    (levelId: string) => {
      const newSetting = colorSettings.map((item) => {
        if (item._id === levelId) {
          item.active = !item.active;
        }
        return item;
      });
      setColorSettings(newSetting);
      const levelIds = newSetting
        .filter((item) => item.active)
        .map((item) => item._id);
      setCurrentFilter({ ...currentFilter, levelIds: levelIds.join(',') });
    },
    [colorSettings, currentFilter]
  );

  const onClickAllLevel = () => {
    const newSetting = colorSettings.map((item) => {
      item.active = false;
      return item;
    });
    setColorSettings(newSetting);
    setCurrentFilter({ ...currentFilter, levelIds: '' });
  };

  const onChangeFilterValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    setCurrentFilter({ ...currentFilter, [key]: event.target.value });
  };

  const handleSave = () => {
    navigate('/roll-calls');
  };
  const onSortColorSetting = useCallback(
    async (newColorSettings: IColorSetting[]) => {
      if (!user?._id || !newColorSettings.length) return;
      setColorSettings(newColorSettings);
      try {
        const payload: LevelSetting = {
          levelOrders: newColorSettings.map((item, index) => {
            return { levelId: item._id, order: index };
          })
        };
        await updateLevelSetting(user?._id, payload);
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Cannot sort color setting'
        );
      }
    },
    // eslint-disable-next-line
    [user?._id]
  );
  return (
    <div className="rollCall">
      <SummaryInformation data={summaryInfo} />
      <div className="filter-section">
        <ColorLevelQuickFilter
          colorSettings={colorSettings}
          onClickLevel={onClickLevel}
          onClickAllLevel={onClickAllLevel}
          levelIds={currentFilter.levelIds}
          onSortColorSetting={onSortColorSetting}
        />
        <div className="area-select">
          <AppSelect
            value={currentFilter.areas}
            options={(globalLocation?.areas || [])?.map((item: string) => {
              return {
                value: item,
                label: item
              };
            })}
            inputSize="small"
            label="Area"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeFilterValue(e, 'areas')
            }
            multiValue
            searchable={false}
          />
        </div>
      </div>
      {(rollCalls || []).map((item: IRollCallDetail, index: number) => (
        <RollCallForClassSession
          key={index}
          data={item}
          isEmergency={isEmergency}
        />
      ))}
      {rollCalls.length > 0 && (
        <div className="footer-btn">
          <AppButton buttonSize="small" onClick={handleSave}>
            Save
          </AppButton>
        </div>
      )}
      {rollCalls.length === 0 && (
        <div className="no-data">No roll call here</div>
      )}
    </div>
  );
};
export default RollCallByTime;
