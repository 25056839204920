import { useCallback, useEffect, useState } from 'react';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import StaffAccess from './components/StaffAccess';
import PersonalInformation from './components/PersonalInfomation';
import ContactDetails from './components/ContactDetails';
import { IStaff } from 'common/interfaces/staff.interface';
import { getStaffById } from 'services/staff.service';
import { useParams } from 'react-router-dom';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import './desktop.scss';

const StaffDetail = () => {
  const params = useParams();

  const [loading, setLoading] = useState<boolean>(true);

  const [staff, setStaff] = useState<IStaff | null>(null);

  const onEditSuccess = () => {
    fetchData();
  };

  const fetchData = useCallback(() => {
    getStaffById(params?.id || '')
      .then((result) => {
        setStaff(result.data.data);
      })
      .catch(() => {
        setStaff(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params?.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <main className="staffDetailPage">
      <AppBreadCrumb
        items={[
          { name: 'Staff', href: '/staff' },
          { name: 'Staff details', href: '' }
        ]}
      />
      <div className="layoutContainer">
        {loading ? (
          <AppLoadingContainer />
        ) : !!staff ? (
          <>
            <PersonalInformation data={staff} onSuccess={onEditSuccess} />
            <ContactDetails data={staff} onSuccess={onEditSuccess} />
            <StaffAccess data={staff} onSuccess={onEditSuccess} />
          </>
        ) : (
          <></>
        )}
      </div>
    </main>
  );
};

export default StaffDetail;
