import React, { InputHTMLAttributes, useMemo } from 'react';
import { GoXCircleFill } from 'react-icons/go';
import './styles.scss';

type InputSize = 'large' | 'small';

interface IAppInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inputSize?: InputSize;
  noFloatingLabel?: boolean;
  onClearSearch?: () => void;
  message?: {
    type: 'error' | 'success' | 'caption';
    text: string;
  };
  value?: string;
  placeholder?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const AppInputSearch = React.forwardRef<HTMLInputElement, IAppInputProps>(
  (
    {
      label,
      inputSize = 'large',
      noFloatingLabel = false,
      onClearSearch,
      message,
      value,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => {
    const __renderClearSearch = useMemo(() => {
      if (value === '' || !onClearSearch) return null;
      return (
        <div
          className={`c__input-search-clear ${!!endIcon ? 'withSuffix' : ''}`}
          onClick={onClearSearch}
        >
          <GoXCircleFill />
        </div>
      );
    }, [onClearSearch, value, endIcon]);

    return (
      <div className="c__input-search" style={props.style}>
        <input
          {...props}
          value={value}
          style={{
            paddingLeft: !!startIcon ? 42 : 'auto',
            paddingRight:
              onClearSearch && !!endIcon ? 68 : onClearSearch ? 42 : 'auto'
          }}
        />
        {__renderClearSearch}
        {!!startIcon && (
          <div className="c__input-search-prefix">{startIcon}</div>
        )}
        {!!endIcon && <div className="c__input-search-suffix">{endIcon}</div>}
      </div>
    );
  }
);

export default AppInputSearch;
