import { DashboardProvider } from './DashboardContext';
import Dashboard from '.';

const DashboardPage = () => {
  return (
    <DashboardProvider>
      <Dashboard />
    </DashboardProvider>
  );
};
export default DashboardPage;
