import React, { useRef, useState } from 'react';
import UploadDocument from './UploadDocument';
import UploadFile from './UploadFile';
import UploadForm from './UploadForm';
import UploadDocumentSlider from './UploadDocumentSlider';
import { calculateFileSizeInMB } from 'common/helpers/file.helper';
import {
  UPLOAD_MAX_FILES,
  UPLOAD_MAX_MB,
  UPLOAD_ACCEPT_FILES
} from 'common/constants/upload.constant';
import './styles.scss';

export const ERROR_MESSAGE_LIMIT_SIZE = 'ERROR_MESSAGE_LIMIT_SIZE';
export const ERROR_MESSAGE_LIMIT_FILES = 'ERROR_MESSAGE_LIMIT_FILES';

interface IUploadProps {
  files: Array<File>;
  documents: Array<string>;
  onChangeFiles: (files: Array<File>) => void;
  onErrorFiles?: (errorMessage: string) => void;
  onChangeDocuments: (documents: Array<string>) => void;
  limitFiles?: number;
  // MB
  limitSize?: number;
  accept?: string;
  disabled?: boolean;
}

const Upload = (props: IUploadProps) => {
  const {
    files,
    documents,
    onChangeFiles,
    onErrorFiles,
    onChangeDocuments,
    limitFiles = UPLOAD_MAX_FILES,
    limitSize = UPLOAD_MAX_MB,
    accept = UPLOAD_ACCEPT_FILES,
    disabled
  } = props;

  const [openSlider, setOpenSlider] = useState<boolean>(false);

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const initialSlide = useRef<number>(0);
  const inputRef = useRef<HTMLInputElement | null>(null);

  // SLIDER
  const handleOpenSlider = () => {
    setOpenSlider(true);
  };

  const handleCloseSlider = () => {
    setOpenSlider(false);
  };
  // END SLIDER

  // handle drag events
  const handleDrag = (
    event: React.DragEvent<HTMLFormElement | HTMLDivElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setIsDragging(true);
    } else if (event.type === 'dragleave') {
      setIsDragging(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      handleAddFiles(event.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      handleAddFiles(event.target.files);
    }
  };

  // handle when dropped or selected files.
  const handleAddFiles = (fileList: FileList) => {
    const tempFileList = Array.from(new Set(fileList));
    const newFiles: Array<File> = [];

    // Check number of files.
    if (tempFileList.length + files.length + documents.length > limitFiles) {
      if (!!onErrorFiles) {
        onErrorFiles(ERROR_MESSAGE_LIMIT_FILES);
      }
      return;
    }

    // Check if exist.
    for (const file of tempFileList) {
      if (files.every((e) => e.name !== file.name)) {
        newFiles.push(file);
      }
    }
    const resultFiles = [...files, ...newFiles];

    // Check sizes.
    let size: number = 0;
    for (const file of resultFiles) {
      size += calculateFileSizeInMB(file);
    }
    if (size > limitSize) {
      if (!!onErrorFiles) {
        onErrorFiles(ERROR_MESSAGE_LIMIT_SIZE);
      }
      return;
    }

    onChangeFiles(resultFiles);
  };

  // handle delete file.
  const handleRemoveFile = (file: File) => {
    const fileIndex = files.findIndex((e: File) => e.name === file.name);
    if (fileIndex > -1) {
      const newFiles = [...files];
      newFiles.splice(fileIndex, 1);
      onChangeFiles(newFiles);
    }
  };

  // Documents
  const handleRemoveDocument = (document: string) => {
    const documentIndex = documents.findIndex((e: string) => e === document);
    if (documentIndex > -1) {
      const newDocuments = [...documents];
      newDocuments.splice(documentIndex, 1);
      onChangeDocuments(newDocuments);
    }
  };

  const handleViewDocument = (document: string) => {
    const documentIndex = documents.findIndex((e: string) => e === document);
    if (documentIndex > -1) {
      initialSlide.current = documentIndex;
    }
    handleOpenSlider();
  };

  return (
    <div className="c__upload">
      {/* UPLOAD FIELD */}
      {!disabled && (
        <UploadForm
          ref={inputRef}
          isDragging={isDragging}
          onDrag={handleDrag}
          onDrop={handleDrop}
          onChange={handleChange}
          limitFiles={limitFiles}
          limitSize={limitSize}
          accept={accept}
        />
      )}

      {/* VIEW UPLOADED FILES */}
      {documents.length > 0 && (
        <div className="c__upload-documents">
          <div className="c__upload-documents-container">
            {documents.map((document: string) => {
              return (
                <UploadDocument
                  key={document}
                  document={document}
                  onRemove={
                    !disabled
                      ? (document) => handleRemoveDocument(document)
                      : undefined
                  }
                  onView={handleViewDocument}
                />
              );
            })}
          </div>
        </div>
      )}

      {/* VIEW UPLOADING FILES */}
      {files.length > 0 && (
        <div className="c__upload-files">
          <div className="c__upload-files-container">
            {files.map((file: File) => {
              return (
                <UploadFile
                  key={file.name}
                  file={file}
                  onRemove={handleRemoveFile}
                />
              );
            })}
          </div>
        </div>
      )}

      {/* SLIDER */}
      <UploadDocumentSlider
        open={openSlider}
        onClose={handleCloseSlider}
        documents={documents}
        initialSlide={initialSlide.current}
      />
    </div>
  );
};

export default Upload;
