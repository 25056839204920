import { object as OBJECT, object, array, string, number, boolean } from 'yup';

const LEVEL_NAME = string().required('Please provide name of the level');
const LEVEL_AGE_FROM = string();
const LEVEL_AGE_TO = string();
const LEVEL_SHORTNAME = string().required('Please provide level short name');
const LEVEL_COLOR_CODE = string();
const LEVEL_DESCRIPTION = string().required('Please provide level description');
const LEVEL_ASSESSMENT_SCORE = object({
  levelId: string(),
  maxScore: number(),
  icon: string(),
  assessmentScoreDetails: array()
});
const LEVEL_SKILLS = array();

const SKILL_NAME = string().required('Please provide skill name');
const SKILL_POSITION = number();
const SKILL_MILESTONES = array();
const SKILL_LEVEL_ID = string();
const LEVEL_MASCOT_IMAGE = string();
const LEVEL_BACKGROUND_IMAGE = string();
const LEVEL_NEXT_LEVEL = string();
const LEVEL_LOCATION = array().min(1, 'Please select at least one location');
const LEVEL_IS_ALL_LOCATION = boolean();

const validations = {
  OBJECT,
  LEVEL_MASCOT_IMAGE,
  LEVEL_BACKGROUND_IMAGE,
  LEVEL_NEXT_LEVEL,
  LEVEL_NAME,
  LEVEL_AGE_FROM,
  LEVEL_AGE_TO,
  LEVEL_SHORTNAME,
  LEVEL_COLOR_CODE,
  LEVEL_DESCRIPTION,
  LEVEL_ASSESSMENT_SCORE,
  LEVEL_SKILLS,
  SKILL_NAME,
  SKILL_POSITION,
  SKILL_MILESTONES,
  SKILL_LEVEL_ID,
  LEVEL_LOCATION,
  LEVEL_IS_ALL_LOCATION
};
export default validations;
