import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ITerm } from 'common/interfaces/term.interface';
import { TERM_TYPE } from 'common/enums/term.enum';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppInput from 'common/components/AppInput';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { HiOutlineBookmark, HiOutlineXCircle } from 'react-icons/hi';
import { HiMagnifyingGlass, HiOutlinePencilSquare } from 'react-icons/hi2';
import { BeatLoader } from 'react-spinners';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import AppDatePicker from 'common/components/AppDatePicker';
import AppSelect from 'common/components/AppSelect';
import AppTable from 'common/components/AppTable';
import AppInputSearch from 'common/components/AppInputSearch';
import AppToggle from 'common/components/AppToggle';
import dayjs, { Dayjs } from 'dayjs';
import { UpdateTermDto, UpdateTermPayloadDto } from 'DTOs/term.dto';
import { Resolver, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yupTerm from 'validators/term.validator';
import { useToast } from 'context/ToastContext';
import { IClassTemplate } from 'common/interfaces/classTemplate.interface';
import { createColumnHelper } from '@tanstack/react-table';
import {
  getTemplateByTermId,
  getTermById,
  updateTermById
} from 'services/term.service';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { useBrandLocation } from 'context/BrandLocationContext';
import { PERMISSION } from 'common/enums/permission.enum';
import PermissionWrapper from 'components/PermissionWrapper';
import useDebounce from 'common/hooks/useDebounce';

import './desktop.scss';
import { FORMAT_END_OF_DATE } from 'pages/reports/constant';

const validationSchema = yupTerm.OBJECT({
  name: yupTerm.TERM_NAME,
  startDate: yupTerm.TERM_START_DATE,
  endDate: yupTerm.TERM_END_DATE.test(
    'start-date-after-end-date',
    function (value, testContext) {
      const { parent, path } = testContext;
      const { startDate } = parent;
      if (dayjs(startDate).isAfter(dayjs(value))) {
        return this.createError({
          path: path,
          message: 'Please select end date after start date'
        });
      }
      return true;
    }
  ),
  type: yupTerm.TERM_TYPE,
  locationIds: yupTerm.TERM_LOCATIONS
});

const TermDetail: React.FC = () => {
  const params = useParams();
  const toast = useToast();
  const location = useLocation();

  const isHoliday = useMemo(
    () => location.pathname.includes('/holiday'),
    [location.pathname]
  );
  const columnHelper = createColumnHelper<IClassTemplate>();
  const { locations } = useBrandLocation();

  const locationList = useMemo(() => {
    const result = locations
      ?.filter((item) => item?._id)
      ?.map((item) => {
        return {
          label: item.shortName || item.name,
          value: item._id
        };
      });
    result.unshift({ label: 'All Locations', value: 'all' });
    return result;
  }, [locations]);

  const [data, setData] = useState<ITerm | null>(null);
  const [classTemplates, setClassTemplates] = useState<IClassTemplate[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageTotal, setPageTotal] = useState<number>(-1);

  const debounceSearch = useDebounce(search, 500);

  const {
    register,
    setValue,
    control,
    clearErrors,
    trigger,
    handleSubmit,
    formState: { errors }
  } = useForm<UpdateTermDto>({
    resolver: yupResolver(validationSchema) as Resolver<UpdateTermDto>,
    defaultValues: {
      name: data?.name,
      startDate: data?.startDate,
      endDate: data?.endDate,
      type: data?.type,
      locationIds: data?.forAllLocation ? 'all' : data?.locationIds?.join(',')
    }
  });

  const watchAllFields = useWatch({ control });

  const handleInitValue = useCallback(() => {
    clearErrors();

    if (data?.name) {
      setValue('name', data?.name);
    }
    if (data?.startDate) {
      setValue('startDate', data?.startDate);
    }
    if (data?.endDate) {
      setValue('endDate', data?.endDate);
    }
    if (data?.type) {
      setValue('type', data?.type);
    }
    if (data?.forAllLocation) {
      setValue('locationIds', 'all');
    } else if (data?.locationIds) {
      setValue('locationIds', data?.locationIds?.join(','));
    }
  }, [setValue, data, clearErrors]);

  useEffect(() => {
    if (!data?._id) return;

    handleInitValue();
  }, [data, handleInitValue]);

  useEffect(() => {
    if (isEdit) return;

    handleInitValue();
  }, [isEdit, handleInitValue]);

  const columns = [
    columnHelper.accessor('name', {
      header: () => <span>NAME</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('duration', {
      header: () => <span>duration</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('classInUsed', {
      header: () => <span>class in used</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('active', {
      header: () => <span>active</span>,
      cell: (info) => {
        return (
          <div>
            <AppToggle value={info.getValue()} disabled />
          </div>
        );
      }
    })
  ];

  const fetchTermDetail = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await getTermById(params.id || '');

      setData({
        ...data.data,
        locationIds: data.data.forAllLocation ? ['all'] : data.data.locationIds
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to fetch Session detail'
      );

      setData(null);
      setClassTemplates([]);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [params.id, setData]);

  const fetchClassTemplate = useCallback(async () => {
    try {
      const { data } = await getTemplateByTermId(
        params.id || '',
        debounceSearch
      );

      setClassTemplates(data.data.data);
      setPageTotal(data.data.total);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to fetch class template'
      );

      setClassTemplates([]);
    }
    // eslint-disable-next-line
  }, [params.id, setClassTemplates, debounceSearch]);

  useEffect(() => {
    fetchTermDetail();
  }, [fetchTermDetail]);

  useEffect(() => {
    fetchClassTemplate();
  }, [fetchClassTemplate]);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const onOpenEdit = useCallback(() => {
    setIsEdit(true);
  }, [setIsEdit]);

  const onCloseEdit = useCallback(() => {
    setIsEdit(false);
  }, [setIsEdit]);

  const onChangeValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      setValue(event.target.name, event.target.value);
      trigger();
    },
    [setValue, trigger]
  );

  const handleChangeDate = useCallback(
    (value: Dayjs | null, field: string) => {
      if (isHoliday && field === 'endDate') {
        // @ts-ignore
        setValue(`${field}`, value?.format(FORMAT_END_OF_DATE));

        // @ts-ignore
        trigger(`${field}`);
        return;
      }
      // @ts-ignore
      setValue(`${field}`, value?.format('YYYY-MM-DD') || '');

      trigger();
    },
    [setValue, trigger, isHoliday]
  );

  const onSubmit = useCallback(
    async () => {
      try {
        const submitData = { ...watchAllFields };

        const allLocationsSelected =
          submitData.locationIds === 'all' ||
          [...(submitData.locationIds?.split(',') || [])]?.filter(
            (item) => item === 'all'
          )?.length > 0;
        const payload: UpdateTermPayloadDto = {
          name: submitData.name || '',
          type: submitData.type || ('' as TERM_TYPE),
          startDate: convertToUnixTime(submitData?.startDate || ''),
          endDate: convertToUnixTime(submitData?.endDate || ''),
          locationIds:
            submitData.locationIds
              ?.split(',')
              .filter((item) => item !== 'all' && item !== '') || [],
          forAllLocation: allLocationsSelected
        };

        await updateTermById(params.id || '', payload);

        toast.success('Session updated successfully');

        onCloseEdit();

        fetchTermDetail();
      } catch (error: any) {
        toast.error(error?.response?.data?.message || 'Failed to update term');
      }
    },
    // eslint-disable-next-line
    [params.id, fetchTermDetail, onCloseEdit, watchAllFields]
  );

  const __renderIcons = useMemo((): React.ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSubmit)}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <PermissionWrapper permission={PERMISSION.UPDATE_TERM}>
        <div className="icon" onClick={onOpenEdit}>
          <HiOutlinePencilSquare />
        </div>
      </PermissionWrapper>
    );
  }, [isEdit, loading, onCloseEdit, onOpenEdit, onSubmit, handleSubmit]);

  const __renderContent = useMemo((): React.ReactNode => {
    if (isEdit) {
      return (
        <div className="content-row">
          <AppInput
            label="Name*"
            {...register(`name`)}
            message={{
              type: 'error',
              text: errors?.name?.message || ''
            }}
            onChange={onChangeValue}
          />
          <AppDatePicker
            {...register(`startDate`)}
            label="Start date*:dd/mm/yyyy"
            value={dayjs(data?.startDate)}
            message={{
              type: 'error',
              text: errors?.startDate?.message || ''
            }}
            onChange={(value: Dayjs | null) =>
              handleChangeDate(value, 'startDate')
            }
          />
          <AppDatePicker
            {...register('endDate')}
            label="End date*:dd/mm/yyyy"
            value={dayjs(data?.endDate)}
            message={{
              type: 'error',
              text: errors?.endDate?.message || ''
            }}
            onChange={(value: Dayjs | null) =>
              handleChangeDate(value, 'endDate')
            }
          />
          <AppSelect
            {...register(`locationIds`)}
            options={locationList}
            searchable={false}
            label="Location"
            multiValue
            value={data?.locationIds?.join(',') || ''}
            onChange={(val) => setValue(`locationIds`, val.target.value)}
            onClearSelection={() => {}}
            message={{
              type: 'error',
              text: errors?.locationIds?.message || ''
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="termDetail">
          <AppCardContentItem subtitle="Name" title={formatData(data?.name)} />
          <AppCardContentItem
            subtitle="Start date"
            title={formatDate(data?.startDate)}
          />
          <AppCardContentItem
            subtitle="End date"
            title={formatDate(data?.endDate)}
          />
          <AppCardContentItem
            subtitle="Location"
            title={
              data?.forAllLocation
                ? 'All locations'
                : formatData(
                    data?.locationIds
                      ?.map((x) => {
                        const item = locations?.find((e) => e._id === x);
                        return item?.shortName || item?.name;
                      })
                      .join(', ')
                  )
            }
          />
        </div>
      );
    }
  }, [
    isEdit,
    data,
    register,
    errors,
    onChangeValue,
    handleChangeDate,
    locations,
    setValue,
    locationList
  ]);

  return (
    <>
      <AppBreadCrumb
        items={[
          {
            name: isHoliday
              ? 'Public Holiday Management'
              : 'Session Management',
            href: isHoliday ? '/holiday' : '/terms'
          },
          {
            name: isHoliday ? 'Public Holiday Details' : 'Session details',
            href: ''
          }
        ]}
      />
      {loading ? (
        <AppLoadingContainer />
      ) : (
        <div className="layoutContainer termDetail-page">
          <AppCard>
            <AppCardHeader
              title={isHoliday ? 'Public Holiday' : 'Session'}
              suffix={__renderIcons}
            />
            <AppCardContent>{__renderContent}</AppCardContent>
          </AppCard>

          {!isHoliday && (
            <AppCard>
              <div className="classTemplate__table">
                <div className="classTemplate__table-header">
                  <h2 className="classTemplate__table-header-title">
                    Template(s)
                  </h2>
                  <div className="classTemplate__table-header-search">
                    <AppInputSearch
                      type="text"
                      value={search}
                      onChange={onChangeSearch}
                      placeholder="Name"
                      onClearSearch={onClearSearch}
                      startIcon={<HiMagnifyingGlass />}
                    />
                  </div>
                </div>
                <div className="classTemplate__table-content">
                  <AppTable
                    data={classTemplates}
                    columns={columns}
                    pagination={{
                      index: pageIndex,
                      size: pageSize,
                      total: pageTotal
                    }}
                    onChangePage={(index: number, size: number) => {
                      setPageIndex(index);
                      setPageSize(size);
                    }}
                    loading={loading}
                  />
                </div>
              </div>
            </AppCard>
          )}
        </div>
      )}
    </>
  );
};

export default TermDetail;
