import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import {
  ERROR_MESSAGE_DATE,
  FORMAT_END_OF_DATE,
  FORMAT_START_OF_DATE,
  REPORT_PATH
} from '../constant';
import AppDatePicker from 'common/components/AppDatePicker';
import AppButton from 'common/components/AppButton';
import dayjs, { Dayjs } from 'dayjs';
import { useToast } from 'context/ToastContext';
import { getCreditAdjustmentReport } from 'services/report.service';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { formatData, formatMoneySign } from 'common/helpers/dataFormat.helper';
import { ICreditAdjustmentReport } from 'common/interfaces/report.interface';
import { createColumnHelper } from '@tanstack/react-table';
import AppTable from 'common/components/AppTable';
import ReportSelection from '../components/ReportSelection';
import { IOption } from 'common/interfaces';
import { REFUND_CREDIT_TYPE } from 'common/enums/absence.enum';
import { useBrandLocation } from 'context/BrandLocationContext';
import './desktop.scss';

const creditTypeOptions: Array<IOption> = [
  {
    label: 'Make-up (s)',
    value: REFUND_CREDIT_TYPE.MAKEUP_CREDIT
  },
  {
    label: 'Money Credit',
    value: REFUND_CREDIT_TYPE.MONEY_CREDIT
  }
];
type FilterCreditReport = {
  dateFrom: string;
  dateTo: string;
  creditTypes: string[];
};
const initFilterValue: FilterCreditReport = {
  dateFrom: '',
  dateTo: '',
  creditTypes: []
};
const ReportCreditAdjustment: React.FC = () => {
  const toast = useToast();
  const { selectedLocation } = useBrandLocation();

  const [isGenerated, setIsGenerated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageTotal, setPageTotal] = useState<number>(0);

  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');
  const [creditTypes, setCreditTypes] = useState<string[]>([]);
  const currenFilter = useRef<FilterCreditReport>(initFilterValue);
  const dateError = React.useRef<string>('');

  const [data, setData] = useState<ICreditAdjustmentReport[]>([]);

  const __isDisableGenerateButton: boolean = React.useMemo(() => {
    return (
      !dateFrom || !dateTo || creditTypes.length === 0 || !!dateError.current
    );
  }, [dateFrom, dateTo, creditTypes]);

  const columnHelper = createColumnHelper<ICreditAdjustmentReport>();

  const __creditTypePlaceholderText: string = React.useMemo(() => {
    return creditTypes.length === 0
      ? 'Select option'
      : `${creditTypes.length} selected`;
  }, [creditTypes]);

  const columns = [
    columnHelper.accessor('student.firstName', {
      header: () => <span>Student Name</span>,
      cell: (info) => (
        <span>
          {formatData(info.row.original.student?.lastName) +
            ', ' +
            formatData(info.getValue())}
        </span>
      )
    }),
    columnHelper.accessor('student.memberId', {
      header: () => <span>Student ID</span>,
      cell: (info) => <span>{formatData(info.getValue())}</span>
    }),
    columnHelper.accessor('responsiblePerson.firstName', {
      header: () => <span>RP Name</span>,
      cell: (info) => {
        if (!info.row.original?.responsiblePerson) return <>--</>;
        return (
          <span>
            {formatData(info.row.original.responsiblePerson?.lastName) +
              ', ' +
              formatData(info.getValue())}
          </span>
        );
      }
    }),
    columnHelper.accessor('responsiblePerson.memberId', {
      header: () => <span>RP ID</span>,
      cell: (info) => <span>{formatData(info.getValue())}</span>
    }),
    columnHelper.accessor('givenBy.firstName', {
      header: () => <span>Given by</span>,
      cell: (info) => {
        if (!info.row.original?.givenBy) return <>System</>;
        return (
          <span>
            {formatData(info.row.original.givenBy?.lastName) +
              ', ' +
              formatData(info?.getValue())}
          </span>
        );
      }
    }),
    columnHelper.accessor('refundCreditType', {
      header: () => <span>Type</span>,
      cell: (info) => (
        <span>{formatData(info.getValue().replaceAll('_', ' '))}</span>
      )
    }),
    columnHelper.accessor('amount', {
      header: () => <span>Credit</span>,
      cell: (info) => {
        const { refundCreditType } = info.row.original;
        if (refundCreditType === REFUND_CREDIT_TYPE.MAKEUP_CREDIT) {
          return <span>{formatData(info.getValue())}</span>;
        }

        return <span>{formatMoneySign(info.getValue())}</span>;
      }
    }),
    columnHelper.accessor('referenceType', {
      header: () => <span>Reason</span>,
      cell: (info) => (
        <span>{formatData(info.getValue()?.replaceAll('-', ' '))}</span>
      )
    })
  ];

  const handleChangeDateFrom = (date: Dayjs | null) => {
    setDateFrom(date?.format(FORMAT_START_OF_DATE) || '');
    if (date?.isAfter(dayjs(dateTo))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeDateTo = (date: Dayjs | null) => {
    setDateTo(date?.format(FORMAT_END_OF_DATE) || '');
    if (date?.isBefore(dayjs(dateFrom))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleToggleCreditType = (value: string) => {
    const newCreditTypes = [...creditTypes];
    const findIndex = newCreditTypes.findIndex((levelId) => levelId === value);
    if (findIndex === -1) {
      newCreditTypes.push(value);
    } else {
      newCreditTypes.splice(findIndex, 1);
    }
    setCreditTypes(newCreditTypes);
  };

  const handleSelectAllCreditType = () => {
    const ids = creditTypeOptions.map((type) => type.value) as string[];
    if (creditTypes.length === creditTypeOptions.length) {
      setCreditTypes([]);
    } else {
      setCreditTypes(ids);
    }
  };

  const fetchDataOutstanding = useCallback(async () => {
    setLoading(true);
    if (!selectedLocation?._id) return;
    try {
      const { data } = await getCreditAdjustmentReport(
        pageIndex,
        pageSize,
        convertToUnixTime(currenFilter.current.dateFrom),
        convertToUnixTime(currenFilter.current.dateTo),
        currenFilter.current.creditTypes,
        selectedLocation?._id
      );
      setIsGenerated(true);
      setData(data.data.data);
      setPageTotal(data.data.total);
      setDateFrom(currenFilter.current.dateFrom);
      setDateTo(currenFilter.current.dateTo);
      setCreditTypes(currenFilter.current.creditTypes);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || ' Failed to generate data');
      setIsGenerated(false);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [pageIndex, pageSize, selectedLocation?._id]);
  const handleGenerateData = async () => {
    currenFilter.current = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      creditTypes: creditTypes
    };
    await fetchDataOutstanding();
  };

  useEffect(() => {
    if (isGenerated) fetchDataOutstanding();
    // eslint-disable-next-line
  }, [pageIndex, pageSize]);

  return (
    <main id="reportCreditAdjustment" className="reportCreditAdjustment">
      <AppBreadCrumb
        items={[
          { name: 'Reporting', href: REPORT_PATH },
          { name: 'Credit / Adjustment Report' }
        ]}
      />
      <div className="layoutContainer reportCreditAdjustment__wrapper">
        <section className="reportCreditAdjustment__search">
          <div className="reportCreditAdjustment__search__fields">
            <AppDatePicker
              size="small"
              label="Date from"
              value={dayjs(dateFrom)}
              onChange={handleChangeDateFrom}
            />
            <AppDatePicker
              size="small"
              label="Date to"
              value={dayjs(dateTo)}
              onChange={handleChangeDateTo}
              message={{
                type: 'error',
                text: dateError.current
              }}
            />
            <ReportSelection
              label="Credit Type"
              placeholder={__creditTypePlaceholderText}
              options={creditTypeOptions}
              onSelect={handleToggleCreditType}
              onSelectAll={handleSelectAllCreditType}
              selectedIds={creditTypes}
            />
          </div>
          <AppButton
            variant="primary"
            buttonSize="small"
            onClick={handleGenerateData}
            disabled={__isDisableGenerateButton}
          >
            generate
          </AppButton>
        </section>

        {isGenerated && (
          <section className="reportCreditAdjustment__content">
            <div className="reportCreditAdjustment__content__header">
              <p className="reportCreditAdjustment__content__header--title">
                Credit / Adjustment
              </p>
            </div>

            <div className="reportCreditAdjustment__content__table">
              <AppTable
                data={data}
                columns={columns}
                pagination={{
                  index: pageIndex,
                  size: pageSize,
                  total: pageTotal
                }}
                loading={loading}
                onChangePage={(index: number, size: number) => {
                  setPageIndex(index);
                  setPageSize(size);
                }}
              />
            </div>
          </section>
        )}
      </div>
    </main>
  );
};

export default ReportCreditAdjustment;
