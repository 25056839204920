import { EnrollmentProvider } from './EnrollmentContext';
import EnrollmentFlow from './EnrollmentFlow';

const Enrollment: React.FC = () => {
  return (
    <EnrollmentProvider>
      <EnrollmentFlow />
    </EnrollmentProvider>
  );
};
export default Enrollment;