import { createColumnHelper } from '@tanstack/react-table';
import AppButton from 'common/components/AppButton';
import AppCard from 'common/components/AppCard';
import AppCheckbox from 'common/components/AppCheckbox';
import AppInputSearch from 'common/components/AppInputSearch';
import AppTable from 'common/components/AppTable';
import {
  formatData,
  formatDate,
  formatTime
} from 'common/helpers/dataFormat.helper';
import PermissionWrapper from 'components/PermissionWrapper';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { GoFilter } from 'react-icons/go';
import {
  HiArrowLeft,
  HiMagnifyingGlass,
  HiOutlineExclamationCircle
} from 'react-icons/hi2';
import { HiOutlineTicket } from 'react-icons/hi2';
import './desktop.scss';
import { useBrandLocation } from 'context/BrandLocationContext';
import { initFilterValue } from 'components/notifications/constants';
import useDebounce from 'common/hooks/useDebounce';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { DATE_CONSTANT } from 'common/constants';
import {
  bulkApplyVoucherToEnrolment,
  getEnrolmentList
} from 'services/voucher.service';
import { useToast } from 'context/ToastContext';
import { IStudentFilter } from 'components/notifications/interface';
import StudentFilter from 'components/notifications/components/StudentFilter';
import { getEnrolmentType } from 'common/helpers/index.helper';
import { IRecurrence } from 'common/interfaces/class.interface';
import { filter, pickBy, some, uniq } from 'lodash';
import AppTooltip from 'common/components/AppToolTip';
import { DISCOUNT_TYPE } from 'common/enums';
import { DISCOUNT_FROM, VOUCHER_DURATION } from 'common/enums/voucher.enum';
import { IEnrolmentData, IEnrolmentFilter } from 'pages/vouchers/interface';
import { getAutomationDiscountText } from 'common/helpers/voucher.helper';
import { IVoucher } from 'common/interfaces/voucher.interface';
import { BulkApplyVoucherToEnrolmentDTO } from 'DTOs/voucher.dto';
import AppModal, {
  AppModalContent,
  AppModalFormActions,
  AppModalTitle
} from 'common/components/AppModal';
import { CLASS_TYPES } from 'common/enums/class.enum';
import dayjs from 'dayjs';
import { PAYMENT_VALUE } from 'common/enums/classBooking.enum';

type ApplyToMemberProps = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  voucher: IVoucher;
};

const ApplyToMember = (props: ApplyToMemberProps) => {
  const { open, onClose, voucher, onSuccess } = props;
  const toast = useToast();
  const { selectedLocation: globalLocation } = useBrandLocation();

  const columnHelper = createColumnHelper<IEnrolmentData>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [filterFieldNumber, setFilterFieldNumber] = useState<number>(0);
  const [pageTotal, setPageTotal] = useState<number>(0);
  const currentFilter = useRef<IStudentFilter | null>({
    ...initFilterValue,
    locationId: globalLocation?._id || ''
  });

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingEnrolment, setLoadingEnrolment] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndexForModal, setPageIndexForModal] = useState(1);
  const [pageSizeForModal, setPageSizeForModal] = useState(10);
  const [pageTotalForModal, setPageTotalForModal] = useState(10);

  const [enrolmentList, setEnrolmentList] = useState<IEnrolmentData[]>([]);
  const [enrolmentListForModal, setEnrolmentListForModal] = useState<
    IEnrolmentData[]
  >([]);
  const [selectedEnrolmentList, setSelectedEnrolmentList] = useState<
    IEnrolmentData[]
  >([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [unselectedEnrolmentIds, setUnselectedEnrolmentIds] = useState<
    Array<string>
  >([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const __enrolmentHasDiscountList = useMemo(() => {
    return (
      filter(selectedEnrolmentList, (enrolment) => {
        return !!enrolment.discountFrom;
      }) || []
    );
  }, [selectedEnrolmentList]);

  const __remainingQuantity = useMemo(() => {
    if (isSelectAll) {
      return (
        voucher.quantity -
        voucher.redeemCount -
        pageTotal +
        unselectedEnrolmentIds.length
      );
    }
    return (
      voucher.quantity - voucher.redeemCount - selectedEnrolmentList.length
    );
  }, [
    voucher,
    selectedEnrolmentList,
    isSelectAll,
    unselectedEnrolmentIds,
    pageTotal
  ]);

  const __showWarmingQuantity = useMemo(() => {
    return __remainingQuantity < 0;
  }, [__remainingQuantity]);

  const __showWarningAlreadyAppliedDiscount = useMemo(() => {
    if (__showWarmingQuantity) return false;
    return some(selectedEnrolmentList, (enrolment) => {
      return !!enrolment.discountFrom;
    });
  }, [__showWarmingQuantity, selectedEnrolmentList]);

  const __showWarningCannotApply = useMemo(() => {
    if (__showWarmingQuantity) return false;
    return some(selectedEnrolmentList, (enrolment) => {
      return !enrolment.validVoucher;
    });
  }, [__showWarmingQuantity, selectedEnrolmentList]);

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  const getClassDay = useCallback((recurrence: IRecurrence) => {
    if (!recurrence) return '--';
    const days = pickBy(recurrence, (value) => value === true);

    const customValue = Object.keys(days)
      .map((item) => item.toLocaleUpperCase())
      .join(', ');
    return customValue;
  }, []);

  const handleToggleEnrolment = (item: IEnrolmentData) => {
    let newSelectedEnrolmentIds = [...selectedEnrolmentList];
    const findIndex = newSelectedEnrolmentIds.findIndex(
      (e) => e._id === item._id
    );
    if (findIndex === -1) {
      newSelectedEnrolmentIds.push(item);
      if (unselectedEnrolmentIds.length) {
        setUnselectedEnrolmentIds(
          unselectedEnrolmentIds.filter((id) => id !== item._id)
        );
      }
    } else {
      newSelectedEnrolmentIds = newSelectedEnrolmentIds.filter(
        (enrolment) => enrolment._id !== item._id
      );
      if (isSelectAll) {
        setUnselectedEnrolmentIds([...unselectedEnrolmentIds, item._id]);
      }
    }
    setSelectedEnrolmentList(newSelectedEnrolmentIds);
  };
  const handleToggleAll = useCallback(() => {
    if (!unselectedEnrolmentIds.length) {
      if (isSelectAll) {
        setSelectedEnrolmentList([]);
        setUnselectedEnrolmentIds([]);
        setIsSelectAll(false);
      } else {
        setSelectedEnrolmentList(enrolmentList);
        setUnselectedEnrolmentIds([]);
        setIsSelectAll(true);
      }
    } else {
      setSelectedEnrolmentList(enrolmentList);
      setUnselectedEnrolmentIds([]);
      setIsSelectAll(true);
    }
  }, [enrolmentList, unselectedEnrolmentIds, isSelectAll]);

  const columns = [
    columnHelper.accessor('_id', {
      header: () => (
        <AppCheckbox
          checked={isSelectAll && !unselectedEnrolmentIds.length}
          onChange={handleToggleAll}
          disabled={!enrolmentList.length}
        />
      ),
      cell: (info) => (
        <AppCheckbox
          value={info.row.original._id}
          checked={
            !!selectedEnrolmentList.find(
              (enrolment) => enrolment._id === info.row.original._id
            )
          }
          onChange={() => handleToggleEnrolment(info.row.original)}
        />
      )
    }),
    columnHelper.accessor('type', {
      header: () => <span>enrolment</span>,
      cell: (info) => {
        return (
          <div className="enrollmentType-cell">
            {info.row.original.discountType ? (
              <AppTooltip
                tooltipText={renderDiscountFrom(info.row.original)}
                position="top"
              >
                <>
                  <HiOutlineTicket size={20} className="appliedVoucher" />
                </>
              </AppTooltip>
            ) : null}
            <div className="line-clamp-1">
              {getEnrolmentType(info.getValue())}
            </div>
          </div>
        );
      }
    }),
    columnHelper.accessor('type', {
      header: () => null,
      cell: (info) => {
        return (
          <div className="discount-info-cell ">
            {info.row.original.discountType ? (
              <AppTooltip
                tooltipText={renderDiscountText(info.row.original)}
                position="top"
              >
                <>
                  <HiOutlineExclamationCircle
                    size={20}
                    className="appliedVoucher"
                  />
                </>
              </AppTooltip>
            ) : null}
          </div>
        );
      },
      size: 10
    }),
    columnHelper.accessor('validVoucher', {
      header: () => null,
      cell: (info) => {
        return (
          <div className="validated-cell ">
            {!info.getValue() ? (
              <AppTooltip
                tooltipText={formatData(
                  info.row.original.voucherError.replaceAll('_', ' ')
                )}
                position="top"
              >
                <>
                  <HiOutlineExclamationCircle
                    size={20}
                    className="appliedVoucher"
                  />
                </>
              </AppTooltip>
            ) : null}
          </div>
        );
      },
      size: 10
    }),
    columnHelper.accessor('student', {
      header: () => <span>surname, name</span>,
      cell: (info) =>
        `${info.getValue().lastName}, ${formatData(info.getValue().firstName)}`
    }),
    columnHelper.accessor('responsiblePerson', {
      header: () => <span>surname, name</span>,
      cell: (info) =>
        `${info.getValue().lastName}, ${formatData(info.getValue().firstName)}`
    }),
    columnHelper.accessor('responsiblePerson.email', {
      header: () => <span>rp email</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('classInfo', {
      header: () => <span>Class Day</span>,
      cell: (info) => getClassDay(info.getValue()?.recurrence)
    }),

    columnHelper.accessor('classInfo', {
      header: () => <span>Class Time</span>,
      cell: (info) =>
        info.getValue()
          ? formatTime(info.getValue()?.startTime) +
            '-' +
            formatTime(info.getValue()?.endTime)
          : '--'
    }),
    columnHelper.accessor('classInfo', {
      header: () => <span>Level</span>,
      cell: (info) => {
        const classData = info.getValue();
        return formatData(
          classData?.allLevel ? 'All Level' : classData?.levelInfo?.name
        );
      }
    })
  ];

  const confirmColumns = [...columns.slice(1, 10)];

  const handleOpenFilter = useCallback(() => {
    setShowFilter(true);
  }, []);

  const handleCloseFilter = useCallback(() => {
    setShowFilter(false);
  }, []);

  const fetchDataEnrolment = useCallback(async () => {
    if (!globalLocation?._id || !currentFilter.current) return;

    try {
      setLoadingEnrolment(true);

      const params: IEnrolmentFilter = {
        page: pageIndex,
        limit: pageSize,
        voucherCode: voucher.code,
        excludeBookClassIds: ''
      };
      if (debouncedSearch) {
        params.keyword = debouncedSearch;
      }

      let filterFieldNumber = 0;
      if (currentFilter.current) {
        if (currentFilter.current.termId) {
          params.termId = currentFilter.current.termId;
          filterFieldNumber += 1;
        }
        if (currentFilter.current.levelId) {
          params.levelIds = currentFilter.current.levelId;
          filterFieldNumber += 1;
        }
        if (currentFilter.current.instructorId) {
          params.instructorIds = currentFilter.current.instructorId;
          filterFieldNumber += 1;
        }
        if (currentFilter.current.assessmentResult) {
          params.assessmentResult = currentFilter.current.assessmentResult;
          filterFieldNumber += 1;
        }
        if (currentFilter.current.assessmentStatus) {
          params.assessmentStatus = currentFilter.current.assessmentStatus;
          filterFieldNumber += 1;
        }
        params.paymentType = PAYMENT_VALUE.DIRECT_DEBIT;
        filterFieldNumber += 1;
        if (currentFilter.current.outstandingFee) {
          params.outstandingFee = currentFilter.current.outstandingFee;
          filterFieldNumber += 1;
        }
        if (currentFilter.current.enrollmentType) {
          params.enrollmentTypes = currentFilter.current.enrollmentType;
          filterFieldNumber += 1;
        }

        if (currentFilter.current.dateField) {
          filterFieldNumber += 1;
          if (currentFilter.current.dateFrom) {
            if (currentFilter.current.dateFrom < dayjs().format('YYYY-MM-DD')) {
              currentFilter.current.dateFrom = dayjs().format('YYYY-MM-DD');
            } else {
              params.fromDate = convertToUnixTime(
                currentFilter.current.dateFrom
              );
            }
          }
          if (currentFilter.current.dateTo) {
            if (currentFilter.current.dateTo < dayjs().format('YYYY-MM-DD')) {
              currentFilter.current.dateTo = dayjs().format('YYYY-MM-DD');
            } else {
              params.toDate = convertToUnixTime(currentFilter.current.dateTo);
            }
          }
        } else {
          params.fromDate = convertToUnixTime(dayjs().format('YYYY-MM-DD'));
        }
        if (currentFilter.current.timeField) {
          filterFieldNumber += 1;
          if (currentFilter.current.timeFrom)
            params.fromTime = convertToUnixTime(
              `${DATE_CONSTANT} ${currentFilter.current.timeFrom}`
            );
          if (currentFilter.current.timeTo)
            params.toTime = convertToUnixTime(
              `${DATE_CONSTANT} ${currentFilter.current.timeTo}`
            );
        }
        if (currentFilter.current.areaId) {
          filterFieldNumber += 1;
          params.areas = currentFilter.current.areaId;
        }

        if (currentFilter.current.programType) {
          filterFieldNumber += 1;
          params.classTypes = currentFilter.current.programType;
        } else {
          params.classTypes = [
            CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM,
            CLASS_TYPES.SERIES
          ].toString();
        }

        if (currentFilter.current.locationId) {
          filterFieldNumber += 1;
          params.locationIds = currentFilter.current.locationId;
        }

        setFilterFieldNumber(filterFieldNumber);
      }

      if (Object.keys(params).length === 0) {
        return;
      }

      const { data } = await getEnrolmentList(params);
      setEnrolmentList(data.data.data);
      setPageTotal(data.data.total);
      if (isSelectAll) {
        setSelectedEnrolmentList(
          uniq([...data.data.data, ...selectedEnrolmentList]).filter(
            (item) => !unselectedEnrolmentIds.includes(item?._id)
          )
        );
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fetch Student List failed'
      );
    } finally {
      setLoadingEnrolment(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearch,
    currentFilter,
    globalLocation?._id,
    pageIndex,
    pageSize,
    voucher.code
  ]);

  const fetchDataEnrolmentHaveDiscount = useCallback(async () => {
    if (!globalLocation?._id || !currentFilter.current) return;

    try {
      setLoadingEnrolment(true);

      const params: IEnrolmentFilter = {
        page: pageIndexForModal,
        limit: pageSizeForModal,
        voucherCode: voucher.code,
        isDiscount: true,
        excludeBookClassIds: unselectedEnrolmentIds.join(',')
      };
      if (debouncedSearch) {
        params.keyword = debouncedSearch;
      }

      let filterFieldNumber = 0;
      if (currentFilter.current) {
        if (currentFilter.current.termId) {
          params.termId = currentFilter.current.termId;
          filterFieldNumber += 1;
        }
        if (currentFilter.current.levelId) {
          params.levelIds = currentFilter.current.levelId;
          filterFieldNumber += 1;
        }
        if (currentFilter.current.instructorId) {
          params.instructorIds = currentFilter.current.instructorId;
          filterFieldNumber += 1;
        }
        if (currentFilter.current.assessmentResult) {
          params.assessmentResult = currentFilter.current.assessmentResult;
          filterFieldNumber += 1;
        }
        if (currentFilter.current.assessmentStatus) {
          params.assessmentStatus = currentFilter.current.assessmentStatus;
          filterFieldNumber += 1;
        }
        if (currentFilter.current.paymentType) {
          params.paymentType = currentFilter.current.paymentType;
          filterFieldNumber += 1;
        }
        if (currentFilter.current.outstandingFee) {
          params.outstandingFee = currentFilter.current.outstandingFee;
          filterFieldNumber += 1;
        }
        if (currentFilter.current.enrollmentType) {
          params.enrollmentTypes = currentFilter.current.enrollmentType;
          filterFieldNumber += 1;
        }

        if (currentFilter.current.dateField) {
          filterFieldNumber += 1;
          if (currentFilter.current.dateFrom)
            params.fromDate = convertToUnixTime(currentFilter.current.dateFrom);
          if (currentFilter.current.dateTo)
            params.toDate = convertToUnixTime(currentFilter.current.dateTo);
        }
        if (currentFilter.current.timeField) {
          filterFieldNumber += 1;
          if (currentFilter.current.timeFrom)
            params.fromTime = convertToUnixTime(
              `${DATE_CONSTANT} ${currentFilter.current.timeFrom}`
            );
          if (currentFilter.current.timeTo)
            params.toTime = convertToUnixTime(
              `${DATE_CONSTANT} ${currentFilter.current.timeTo}`
            );
        }
        if (currentFilter.current.areaId) {
          filterFieldNumber += 1;
          params.areas = currentFilter.current.areaId;
        }

        if (currentFilter.current.programType) {
          filterFieldNumber += 1;
          params.classTypes = currentFilter.current.programType;
        }

        if (currentFilter.current.locationId) {
          filterFieldNumber += 1;
          params.locationIds = currentFilter.current.locationId;
        }

        setFilterFieldNumber(filterFieldNumber);
      }

      if (Object.keys(params).length === 0) {
        return;
      }

      const { data } = await getEnrolmentList(params);
      setEnrolmentListForModal(data.data.data);
      setPageTotalForModal(data.data.total);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fetch Student List failed'
      );
    } finally {
      setLoadingEnrolment(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearch,
    currentFilter,
    globalLocation?._id,
    pageIndexForModal,
    pageSizeForModal,
    unselectedEnrolmentIds,
    isSelectAll,
    voucher.code
  ]);

  useEffect(() => {
    isSelectAll && showConfirmModal && fetchDataEnrolmentHaveDiscount();
  }, [fetchDataEnrolmentHaveDiscount, isSelectAll, showConfirmModal]);

  const renderDiscountFrom = useCallback((item: IEnrolmentData) => {
    if (!item?.discountType) return '';

    switch (item.discountFrom) {
      case DISCOUNT_FROM.VOUCHER:
        return formatData(item.voucherCode);
      case DISCOUNT_FROM.AUTOMATION_DISCOUNT:
        return getAutomationDiscountText(item.automationDiscountType);
      default:
        return '';
    }
  }, []);

  const renderDiscountText = useCallback((item: IEnrolmentData) => {
    if (!item?.discountType) return '';

    switch (item.discountType) {
      case DISCOUNT_TYPE.AMOUNT:
        return `$${item?.discountValue} OFF`;
      case DISCOUNT_TYPE.PERCENTAGE:
        return `${item?.discountValue}% OFF`;
      case DISCOUNT_TYPE.COMPLIMENTARY:
        let durationText = '';
        if (item.duration === VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS) {
          durationText = `${item.numberOfFreeLesson} ${
            item.numberOfFreeLesson > 1 ? 'Lessons' : 'Lesson'
          }`;
        } else if (
          !!item.startDateFree &&
          item.duration === VOUCHER_DURATION.TIME_RANGE
        ) {
          durationText = `${formatDate(item.startDateFree, 'slash')} - ${
            !!item.endDateFree
              ? formatDate(item.endDateFree, 'slash')
              : 'Ongoing'
          }`;
        }

        return `${DISCOUNT_TYPE.COMPLIMENTARY.toUpperCase()} ${durationText}`;
      default:
        return '';
    }
  }, []);

  const renderVoucherText = useCallback((item: IVoucher) => {
    switch (item.discountType) {
      case DISCOUNT_TYPE.AMOUNT:
        return `$${item?.discountValue} OFF`;
      case DISCOUNT_TYPE.PERCENTAGE:
        return `${item?.discountValue}% OFF`;
      case DISCOUNT_TYPE.COMPLIMENTARY:
        let durationText = '';
        if (item.duration === VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS) {
          const { numberOfLesson = 0 } = item;
          durationText = `${numberOfLesson} ${
            numberOfLesson > 1 ? 'Lessons' : 'Lesson'
          }`;
        } else if (
          !!item.startDateFree &&
          item.duration === VOUCHER_DURATION.TIME_RANGE
        ) {
          durationText = `${formatDate(item.startDateFree, 'slash')} - ${
            !!item.endDateFree
              ? formatDate(item.endDateFree, 'slash')
              : 'Ongoing'
          }`;
        }

        return `${DISCOUNT_TYPE.COMPLIMENTARY.toUpperCase()} ${durationText}`;
      default:
        return '';
    }
  }, []);
  const handleFilter = useCallback(
    (filterValue: IStudentFilter) => {
      handleCloseFilter();
      currentFilter.current = filterValue;
      setPageIndex(1);
      fetchDataEnrolment();
    },
    [handleCloseFilter, fetchDataEnrolment]
  );
  useEffect(() => {
    fetchDataEnrolment();
  }, [fetchDataEnrolment]);

  const handleApplyVoucher = useCallback(
    async (isConfirmed?: boolean) => {
      if (!selectedEnrolmentList.length) {
        toast.error('Please select at least one enrolment');
        return;
      }
      if (__showWarningAlreadyAppliedDiscount && !isConfirmed) {
        setShowConfirmModal(true);
        if (!isSelectAll) {
          setEnrolmentListForModal(__enrolmentHasDiscountList);
        } else {
          fetchDataEnrolmentHaveDiscount();
        }
        return;
      }
      try {
        setLoading(true);
        const payload: BulkApplyVoucherToEnrolmentDTO = {
          voucherCode: voucher.code,
          bookClassIds: selectedEnrolmentList.map((enrolment) => enrolment._id)
        };
        if (isSelectAll) {
          let filter: IEnrolmentFilter = {
            excludeBookClassIds: unselectedEnrolmentIds.join(',')
          } as IEnrolmentFilter;
          if (debouncedSearch) {
            filter.keyword = debouncedSearch;
          }
          if (currentFilter.current) {
            if (currentFilter.current.termId) {
              filter.termId = currentFilter.current.termId;
            }
            if (currentFilter.current.levelId) {
              filter.levelIds = currentFilter.current.levelId;
            }
            if (currentFilter.current.instructorId) {
              filter.instructorIds = currentFilter.current.instructorId;
            }
            if (currentFilter.current.assessmentResult) {
              filter.assessmentResult = currentFilter.current.assessmentResult;
            }
            if (currentFilter.current.assessmentStatus) {
              filter.assessmentStatus = currentFilter.current.assessmentStatus;
            }
            if (currentFilter.current.paymentType) {
              filter.paymentType = currentFilter.current.paymentType;
            }
            if (currentFilter.current.outstandingFee) {
              filter.outstandingFee = currentFilter.current.outstandingFee;
            }
            if (currentFilter.current.enrollmentType) {
              filter.enrollmentTypes = currentFilter.current.enrollmentType;
            }

            if (currentFilter.current.dateField) {
              if (currentFilter.current.dateFrom)
                filter.fromDate = convertToUnixTime(
                  currentFilter.current.dateFrom
                );
              if (currentFilter.current.dateTo)
                filter.toDate = convertToUnixTime(currentFilter.current.dateTo);
            }
            if (currentFilter.current.timeField) {
              if (currentFilter.current.timeFrom)
                filter.fromTime = convertToUnixTime(
                  `${DATE_CONSTANT} ${currentFilter.current.timeFrom}`
                );
              if (currentFilter.current.timeTo)
                filter.toTime = convertToUnixTime(
                  `${DATE_CONSTANT} ${currentFilter.current.timeTo}`
                );
            }
            if (currentFilter.current.areaId) {
              filter.areas = currentFilter.current.areaId;
            }

            if (currentFilter.current.programType) {
              filter.classTypes = currentFilter.current.programType;
            }

            if (currentFilter.current.locationId) {
              filter.locationIds = currentFilter.current.locationId;
            }

            setFilterFieldNumber(filterFieldNumber);
          }
          payload.filter = filter;
        }
        await bulkApplyVoucherToEnrolment(payload);
        toast.success('Apply voucher successfully');
        onSuccess();
        setUnselectedEnrolmentIds([]);
        setSelectedEnrolmentList([]);
        setIsSelectAll(false);
      } catch (error: any) {
        toast.error(error?.response?.data?.message || 'Apply voucher failed');
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [
      selectedEnrolmentList,
      voucher,
      onSuccess,
      __showWarningAlreadyAppliedDiscount,
      __enrolmentHasDiscountList,
      isSelectAll,
      fetchDataEnrolmentHaveDiscount
    ]
  );

  return (
    <section className="vouchersApplyToMemberContainer">
      <div
        className={`overlay ${open ? 'active' : ' '}`}
        onClick={onClose}
      ></div>
      {showConfirmModal && (
        <AppModal
          open={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
        >
          <AppModalTitle>ENROLMENTS have ALREADY DISCOUNTED </AppModalTitle>
          <AppModalContent style={{ paddingBottom: '12px' }}>
            <div className="vouchersApplyToMember__table__content--modal">
              Are you sure to apply the selected voucher to following enrolment?
              The new voucher will override the current discount
              <div className="vouchersApplyToMember__table__content">
                <AppTable
                  data={enrolmentListForModal}
                  columns={confirmColumns}
                  onChangePage={(index: number, size: number) => {
                    setPageIndexForModal(index);
                    setPageSizeForModal(size);
                  }}
                  loading={loadingEnrolment}
                  pagination={{
                    index: pageIndexForModal,
                    size: pageSizeForModal,
                    total: pageTotalForModal
                  }}
                />
              </div>
            </div>
          </AppModalContent>
          <AppModalFormActions style={{ justifyContent: 'center' }}>
            <AppButton
              variant="secondary"
              onClick={() => setShowConfirmModal(false)}
            >
              Cancel
            </AppButton>
            <AppButton
              variant="primary"
              onClick={() => {
                setShowConfirmModal(false);
                handleApplyVoucher(true);
              }}
            >
              Confirm
            </AppButton>
          </AppModalFormActions>
        </AppModal>
      )}
      <div className={`vouchersApplyToMember ${open ? 'active' : ' '}`}>
        <div className="vouchersApplyToMember__header">
          <HiArrowLeft
            size={24}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
          <p>APPLY TO MEMBER</p>
        </div>
        <div className="vouchersApplyToMember__content">
          <AppCard>
            <div className="vouchersApplyToMember__table">
              <div className="vouchersApplyToMember__table__header">
                <h2 className="vouchersApplyToMember__table__header--title">
                  Voucher: {formatData(voucher.code)} (
                  {renderVoucherText(voucher)})
                  <div className="vouchersApplyToMember__table__header--description">
                    <span>Quantity: {voucher.quantity}</span>
                    <span>remaining: {__remainingQuantity}</span>
                  </div>
                </h2>
                <div className="vouchersApplyToMember__table__header--actions">
                  <AppInputSearch
                    type="text"
                    onChange={handleSearchChange}
                    value={search}
                    placeholder="Student surname, name"
                    startIcon={<HiMagnifyingGlass />}
                  />
                  <AppButton
                    variant="secondary"
                    buttonSize="small"
                    onClick={handleOpenFilter}
                  >
                    <GoFilter size={22} />
                    Filters
                    {filterFieldNumber > 0 && (
                      <div className="filter-field-number">
                        {filterFieldNumber}
                      </div>
                    )}
                  </AppButton>
                </div>
              </div>

              <div className="vouchersApplyToMember__table__warning">
                {__showWarmingQuantity && (
                  <div className="vouchersApplyToMember__table__warning--wrapper warning-quantity">
                    <HiOutlineExclamationCircle size={20} />
                    <span className="warning-quantity-text">
                      The remaining vouchers are not enough for selected
                      enrolments. Please increase voucher quantity or unselect
                      some enrolment
                    </span>
                  </div>
                )}
                {__showWarningAlreadyAppliedDiscount && (
                  <div className="vouchersApplyToMember__table__warning--wrapper warning-have-discount">
                    <HiOutlineExclamationCircle size={20} />
                    <span className="warning-have-discount-text">
                      Enrolments with this warning is already discounted and
                      applying the selected voucher will override the discount
                    </span>
                  </div>
                )}
                {__showWarningCannotApply && (
                  <div className="vouchersApplyToMember__table__warning--wrapper warning-quantity">
                    <HiOutlineExclamationCircle size={20} />
                    <span className="warning-quantity-text">
                      Enrolments with this warning CAN NOT be applied with the
                      selected voucher and will be skipped
                    </span>
                  </div>
                )}
              </div>
              <div className="vouchersApplyToMember__table__content">
                <AppTable
                  data={enrolmentList}
                  columns={columns}
                  onChangePage={(index: number, size: number) => {
                    setPageIndex(index);
                    setPageSize(size);
                  }}
                  loading={loadingEnrolment}
                  pagination={{
                    index: pageIndex,
                    size: pageSize,
                    total: pageTotal
                  }}
                />
              </div>
            </div>
          </AppCard>
        </div>
        <div className="vouchersApplyToMember__actions">
          <AppButton
            variant="primary"
            buttonSize="large"
            disabled={__remainingQuantity < 0}
            isLoading={loading}
            onClick={() => handleApplyVoucher()}
          >
            Apply
          </AppButton>
          <AppButton variant="secondary" onClick={onClose}>
            Cancel
          </AppButton>
        </div>
      </div>
      <PermissionWrapper permission={[]}>
        <StudentFilter
          showFilter={showFilter}
          onClose={handleCloseFilter}
          onFilter={handleFilter}
          isVoucherModule={true}
        />
      </PermissionWrapper>
    </section>
  );
};

export default ApplyToMember;
