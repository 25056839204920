import { BOOKING_TYPE, PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import { AUTOMATION_DISCOUNT_TYPE } from 'common/enums/voucher.enum';
import {
  getAutomationDiscountPosition,
  getAutomationDiscountValue
} from 'common/helpers/voucher.helper';
import { CustomFormForStudentEnrollment } from 'common/interfaces/bookingClass.interface';
import { IActiveOngoingStudent } from 'common/interfaces/student.interface';
import { IAutomaticDiscount } from 'common/interfaces/voucher.interface';
import { some } from 'lodash';

const AUTOMATIC_DISCOUNT_N1 = getAutomationDiscountPosition(
  AUTOMATION_DISCOUNT_TYPE.THIRD_STUDENT_ENROLLMENT
);
const AUTOMATIC_DISCOUNT_N2 = getAutomationDiscountPosition(
  AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT
);

export const handleGetDataWithDiscountClassListFlow = (
  newStudents: CustomFormForStudentEnrollment[],
  activeOngoingStudents: IActiveOngoingStudent[]
): CustomFormForStudentEnrollment[] => {
  const validActiveOngoingStudent = activeOngoingStudents.filter(
    (activeOngoingStudent: IActiveOngoingStudent) =>
      activeOngoingStudent?.count > 0
  );
  // start N1
  const inactiveStudent = activeOngoingStudents.filter(
    (activeOngoingStudent: IActiveOngoingStudent) =>
      activeOngoingStudent?.count === 0
  );

  const studentListHaveOngoingClass = newStudents.filter(
    (studentBookingData: CustomFormForStudentEnrollment) =>
      some(
        studentBookingData?.classes,
        (bookingItem) =>
          bookingItem?.form.enrollmentType === BOOKING_TYPE.ONGOING_CLASS
      )
  );
  let studentIdsCanApplyN1AutomaticDiscount: string[] = [];
  // studentIds maybe can apply N1
  const inactiveSTIdHaveOngoingClass = studentListHaveOngoingClass
    .filter((item) =>
      inactiveStudent.find((invalid) => invalid.studentId === item.info._id)
    )
    .map((item) => item.info._id);
  const inactiveSTIdHaveOngoingClassLength =
    inactiveSTIdHaveOngoingClass.length;
  const totalValidActiveOngoingStudent = validActiveOngoingStudent.length;
  if (inactiveSTIdHaveOngoingClassLength > 0) {
    if (totalValidActiveOngoingStudent >= AUTOMATIC_DISCOUNT_N1 - 1) {
      studentIdsCanApplyN1AutomaticDiscount = inactiveSTIdHaveOngoingClass;
    } else {
      if (inactiveSTIdHaveOngoingClassLength >= AUTOMATIC_DISCOUNT_N1 - 1) {
        studentIdsCanApplyN1AutomaticDiscount =
          inactiveSTIdHaveOngoingClass.slice(
            AUTOMATIC_DISCOUNT_N1 - totalValidActiveOngoingStudent - 1,
            inactiveSTIdHaveOngoingClassLength
          );
      }
    }
  }
  // end N1
  // start N2
  const studentIdsCanApplyN2AutomaticDiscount: string[] =
    validActiveOngoingStudent
      .filter(
        (activeOngoingStudent: IActiveOngoingStudent) =>
          activeOngoingStudent?.count >= 1
      )
      .map((activeOngoingStudent) => activeOngoingStudent.studentId);

  let currentStudentId = newStudents[0]?.info?._id;
  let countOngoingClass = 0;

  const newStudentsWithAutoDiscount = [...newStudents].map((studentItem) => {
    if (currentStudentId !== studentItem?.info?._id) {
      countOngoingClass = 0;
      currentStudentId = studentItem?.info?._id;
    }

    const classes = [...studentItem?.classes]?.map((classItem) => {
      if (classItem?.form?.enrollmentType === BOOKING_TYPE.ONGOING_CLASS) {
        delete classItem.automaticDiscount;
        const canApplyN1Discount =
          studentIdsCanApplyN1AutomaticDiscount.includes(
            studentItem?.info?._id
          );
        // first class will apply N1
        if (countOngoingClass === 0) {
          countOngoingClass += 1;
          if (canApplyN1Discount && !classItem.info.isPrivate) {
            return {
              ...classItem,
              automaticDiscount: {
                type: AUTOMATION_DISCOUNT_TYPE.THIRD_STUDENT_ENROLLMENT,
                value: getAutomationDiscountValue(
                  AUTOMATION_DISCOUNT_TYPE.THIRD_STUDENT_ENROLLMENT
                )
              } as IAutomaticDiscount
            };
          } else {
            if (
              classItem.form.paymentType === PAYMENT_VALUE.DIRECT_DEBIT &&
              studentIdsCanApplyN2AutomaticDiscount.includes(
                studentItem?.info?._id
              ) &&
              !classItem.info.isPrivate
            ) {
              return {
                ...classItem,
                automaticDiscount: {
                  value: getAutomationDiscountValue(
                    AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT
                  ),
                  type: AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT
                } as IAutomaticDiscount
              };
            }
          }
        } else {
          countOngoingClass += 1;
          const activeClassNumberOfST =
            validActiveOngoingStudent?.find(
              (activeOngoingStudent: IActiveOngoingStudent) =>
                activeOngoingStudent?.studentId === studentItem?.info?._id
            )?.count || 0;
          if (
            classItem.form.paymentType === PAYMENT_VALUE.DIRECT_DEBIT &&
            (countOngoingClass > 1 ||
              activeClassNumberOfST >= AUTOMATIC_DISCOUNT_N2 - 1) &&
            !classItem.info.isPrivate
          ) {
            return {
              ...classItem,
              automaticDiscount: {
                value: getAutomationDiscountValue(
                  AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT
                ),
                type: AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT
              } as IAutomaticDiscount
            };
          }
        }
      }
      return classItem;
    });

    return { ...studentItem, classes };
  });

  return newStudentsWithAutoDiscount;
};
