import { Suspense, memo, useState } from 'react';
import ClassBookingSection from './ClassBookingSection';
import { ISchedulesOrigin } from 'common/interfaces/schedules.interface';
import { IStudentBookingData } from 'common/interfaces/student.interface';
import { useSearchParams } from 'react-router-dom';
import AppTabs from 'common/components/AppTabs';
import { useMoveBooking } from 'context/MoveBookingContext';
import MovingEnrolmentWarning from '../MovingEnrolmentWarning';
import { WARNING_MOVING_TYPE } from 'common/enums/moving.enum';
import AppLoadingContainer from 'common/components/AppLoadingContainer';

import './desktop.scss';

const BookingClasses = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { studentDataBooking, classesData, __warningMovingType } =
    useMoveBooking();

  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleSelectedTab = (val: number) => {
    setTabIndex(val);

    searchParams.set('tabNumber', `${val}`);
    setSearchParams(searchParams);
  };

  if (!studentDataBooking) return null;
  const tabs = [studentDataBooking].map((student: IStudentBookingData) => {
    return {
      name: studentDataBooking?.lastName + ', ' + studentDataBooking?.firstName,
      component: (
        <div className="booking-classes" key={student._id}>
          {classesData.map((item: ISchedulesOrigin, index: number) => {
            return (
              <ClassBookingSection
                key={item._id || index}
                classData={item}
                studentId={student._id}
              />
            );
          })}
        </div>
      )
    };
  });

  return (
    <div className="layoutContainer classes-main">
      {studentDataBooking.classesData?.length > 0 ? (
        <>
          <AppTabs
            tabs={tabs}
            activeTabIndex={tabIndex}
            onChangeTab={handleSelectedTab}
            key="enrollment_route-tab"
          />
          {__warningMovingType !== WARNING_MOVING_TYPE.IDLE && (
            <MovingEnrolmentWarning warningMovingType={__warningMovingType} />
          )}
          {tabIndex >= 0 ? (
            <Suspense fallback={<AppLoadingContainer />}>
              {tabs[tabIndex]?.component}
            </Suspense>
          ) : null}
        </>
      ) : (
        <AppLoadingContainer />
      )}
    </div>
  );
};

export default memo(BookingClasses);
