import { memo } from 'react';
import AppSummaryCard from 'common/components/AppSummaryCard';
import { IMemberReport } from 'common/interfaces/report.interface';
import { formatData } from 'common/helpers/dataFormat.helper';

interface Props {
  data: IMemberReport;
}

const ReportMemberSummaryView = ({ data }: Props) => {
  return (
    <section className="reportMemberSummaryView">
      <div className="reportMemberSummaryView__table">
        <AppSummaryCard
          title={'joiner'}
          label={'joiner'}
          value={formatData(data?.joiner)}
          icon={<></>}
        />

        <AppSummaryCard
          title={'leaver'}
          label={'leaver'}
          value={formatData(data?.leaver)}
          icon={<></>}
        />

        <AppSummaryCard
          title={'Net Member Movement'}
          label={'Net Member Movement'}
          value={formatData(data?.netMemberMovement)}
          icon={<></>}
        />

        <AppSummaryCard
          title={'Churn'}
          label={'Churn'}
          value={`${formatData(data?.churn)}%`}
          icon={<></>}
        />
      </div>
    </section>
  );
};

export default memo(ReportMemberSummaryView);
