import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import { useLayout } from 'context/LayoutContext';
import { useToast } from 'context/ToastContext';
import { useMoveBooking } from 'context/MoveBookingContext';
import { getResponsiblePersonDetail } from 'services/responsiblePerson.service';
import Footer from './Footer';
import {
  formatData,
  formatDate,
  formatMoneySign,
  formatTime,
  roundByTwo
} from 'common/helpers/dataFormat.helper';
import { PAYMENT_CARDS } from 'common/constants/classBooking.constant';
import { PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import {
  AUTOMATION_DISCOUNT_TYPE,
  DISCOUNT_FROM,
  VOUCHER_DURATION
} from 'common/enums/voucher.enum';
import {
  DiscountApplied,
  DiscountData
} from 'common/interfaces/bookingClass.interface';
import { DISCOUNT_TYPE } from 'common/enums';
import { getEnrolmentType, getProgramType } from 'common/helpers/index.helper';
import dayjs from 'dayjs';
import PromoCodeInput from 'components/PromoCodeInput';
import MenuIcon from 'components/layout/MenuIcon';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import './desktop.scss';
import { getLessonsDayInRange } from 'common/helpers/classListBooking.helper';
import { ISchedule } from 'common/interfaces/schedules.interface';

const ChangePaymentMethodFlow = () => {
  const { handleMouseLeaveMenu, isShowSidebar } = useLayout();
  const params = useParams();
  const toast = useToast();
  const { nextDebitDay, currentEnrolmentClass } = useMoveBooking();

  const [moneyCredit, setMoneyCredit] = useState<number>(0);
  const [paymentType] = useState<PAYMENT_VALUE>(PAYMENT_VALUE.UPFRONT);
  const [loading, setLoading] = useState<boolean>(false);

  const __numberOfFreeLessonFromMove = useMemo(
    () => currentEnrolmentClass?.freeLessonFromMove || 0,
    [currentEnrolmentClass]
  );

  const __activeTerm = useMemo(() => {
    return currentEnrolmentClass?.class.template.activeTerm;
  }, [currentEnrolmentClass]);

  const __remainingLessonsFromTodayToEndTermActive = useMemo(() => {
    const result = currentEnrolmentClass?.allSessions?.filter((lesson) => {
      return (
        dayjs().isBefore(dayjs(lesson.startTime)) &&
        dayjs(lesson.startTime).isBefore(
          dayjs(`${dayjs(__activeTerm?.endDate).format('YYYY-MM-DD')} 23:59:59`)
        )
      );
    }) as ISchedule[];
    return result || [];
  }, [currentEnrolmentClass?.allSessions, __activeTerm]);

  const __lessonHavePaid = useMemo(() => {
    return (
      currentEnrolmentClass?.actualSessions?.filter((lesson) => {
        return (
          dayjs(lesson.startTime).isBefore(dayjs(nextDebitDay)) &&
          dayjs().isBefore(dayjs(lesson.startTime))
        );
      }) || []
    );
  }, [currentEnrolmentClass?.actualSessions, nextDebitDay]);

  const __amountPayOriginal = useMemo(() => {
    const newPrice =
      __remainingLessonsFromTodayToEndTermActive.length *
      (currentEnrolmentClass?.class.price || 0);
    const oldPrice =
      __lessonHavePaid.length * (currentEnrolmentClass?.price || 0);
    return newPrice - oldPrice > 0 ? newPrice - oldPrice : 0;
  }, [
    __lessonHavePaid,
    __remainingLessonsFromTodayToEndTermActive,
    currentEnrolmentClass
  ]);

  const handleDateTimeRecord = useCallback(() => {
    const lesson =
      currentEnrolmentClass?.actualSessions[0] ||
      currentEnrolmentClass?.allSessions[0];
    return `Weekly on ${dayjs(lesson?.startTime).format('ddd')} at ${formatTime(
      lesson?.startTime
    )} - ${formatTime(lesson?.endTime)}`;
  }, [currentEnrolmentClass]);

  const __discountApplied = useMemo((): DiscountApplied | undefined => {
    if (!currentEnrolmentClass || !currentEnrolmentClass.discountType)
      return undefined;

    if (
      currentEnrolmentClass?.discountType === DISCOUNT_TYPE.PERCENTAGE &&
      currentEnrolmentClass.discountFrom === DISCOUNT_FROM.AUTOMATION_DISCOUNT
    ) {
      if (currentEnrolmentClass.isQualified) {
        return {
          discountType: currentEnrolmentClass.discountType,
          discountFrom: currentEnrolmentClass.discountFrom as DISCOUNT_FROM,
          discountValue: currentEnrolmentClass.discountValue || 0,
          automationDiscountType: currentEnrolmentClass.automationDiscountType,
          remainDiscountAmount: currentEnrolmentClass.remainDiscountAmount || 0,
          voucherCode: currentEnrolmentClass.voucherCode
        };
      } else {
        return undefined;
      }
    }
    if (currentEnrolmentClass.discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
      return {
        discountType: currentEnrolmentClass.discountType,
        discountFrom: currentEnrolmentClass.discountFrom as DISCOUNT_FROM,
        discountValue: currentEnrolmentClass.discountValue || 0,
        automationDiscountType: currentEnrolmentClass.automationDiscountType,
        remainDiscountAmount: currentEnrolmentClass.remainDiscountAmount || 0,
        numberOfFreeLesson: currentEnrolmentClass.numberOfFreeLesson,
        remainFreeLesson: currentEnrolmentClass.remainFreeLesson,
        duration: currentEnrolmentClass.duration,
        startDateFree: currentEnrolmentClass.startDateFree,
        endDateFree: currentEnrolmentClass.endDateFree,
        voucherCode: currentEnrolmentClass.voucherCode
      };
    }
    return {
      discountType: currentEnrolmentClass.discountType,
      discountFrom: currentEnrolmentClass.discountFrom as DISCOUNT_FROM,
      discountValue: currentEnrolmentClass.discountValue || 0,
      automationDiscountType: currentEnrolmentClass.automationDiscountType,
      remainDiscountAmount: currentEnrolmentClass.remainDiscountAmount || 0,
      voucherCode: currentEnrolmentClass.voucherCode
    };
  }, [currentEnrolmentClass]);

  const __numberOfFreeLesson = useMemo((): number => {
    if (__discountApplied?.discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
      const {
        remainFreeLesson = 0,
        startDateFree,
        endDateFree
      } = __discountApplied;
      if (
        __discountApplied.duration ===
        VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS
      ) {
        return remainFreeLesson;
      } else if (__discountApplied.duration === VOUCHER_DURATION.TIME_RANGE) {
        const lastLesson =
          currentEnrolmentClass?.allSessions?.[
            currentEnrolmentClass?.allSessions?.length - 1
          ];
        if (!lastLesson) return 0;
        let lessonsFree: string[] = [];
        if (!endDateFree) {
          lessonsFree = getLessonsDayInRange(
            __remainingLessonsFromTodayToEndTermActive,
            startDateFree || '',
            lastLesson?.startTime,
            []
          );
        } else {
          const endDate = dayjs(endDateFree).isAfter(lastLesson?.startTime)
            ? lastLesson?.startTime
            : endDateFree;
          lessonsFree = getLessonsDayInRange(
            __remainingLessonsFromTodayToEndTermActive,
            startDateFree || '',
            endDate || '',
            []
          );
        }
        return lessonsFree.length;
      }
    }
    return 0;
  }, [
    currentEnrolmentClass?.allSessions,
    __remainingLessonsFromTodayToEndTermActive,
    __discountApplied
  ]);

  const __showDiscountApplied = useMemo((): boolean => {
    if (
      __discountApplied?.discountFrom === DISCOUNT_FROM.AUTOMATION_DISCOUNT &&
      __discountApplied?.automationDiscountType ===
        AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT &&
      paymentType === PAYMENT_VALUE.UPFRONT
    ) {
      return false;
    }
    if (
      __discountApplied?.discountType === DISCOUNT_TYPE.AMOUNT &&
      __discountApplied.remainDiscountAmount === 0
    ) {
      return false;
    }
    return !!__discountApplied;
  }, [__discountApplied, paymentType]);

  const __numberOfLessonLeft = useMemo((): number => {
    const numberOfLesson =
      __remainingLessonsFromTodayToEndTermActive.length -
      __lessonHavePaid.length;
    return numberOfLesson;
  }, [__remainingLessonsFromTodayToEndTermActive, __lessonHavePaid]);

  const __discountedPrice = useMemo(() => {
    if (!__discountApplied) return 0;
    if (!__showDiscountApplied) return 0;
    return __discountApplied?.discountType === DISCOUNT_TYPE.AMOUNT
      ? __discountApplied?.remainDiscountAmount
      : (__discountApplied?.discountValue *
          (__numberOfLessonLeft - __numberOfFreeLessonFromMove) *
          (currentEnrolmentClass?.class.price || 0)) /
          100;
  }, [
    __discountApplied,
    __numberOfLessonLeft,
    currentEnrolmentClass,
    __showDiscountApplied,
    __numberOfFreeLessonFromMove
  ]);

  const __amountPayToday = useMemo(() => {
    const newPrice =
      (__remainingLessonsFromTodayToEndTermActive.length -
        __numberOfFreeLesson) *
      (currentEnrolmentClass?.class.price || 0);
    const oldPrice =
      (__lessonHavePaid.length + __numberOfFreeLessonFromMove) *
      (currentEnrolmentClass?.price || 0);
    return newPrice - oldPrice - __discountedPrice > 0
      ? newPrice - oldPrice - __discountedPrice
      : 0;
  }, [
    __lessonHavePaid,
    __remainingLessonsFromTodayToEndTermActive,
    currentEnrolmentClass,
    __discountedPrice,
    __numberOfFreeLesson,
    __numberOfFreeLessonFromMove
  ]);

  const handleGetDiscountedPrice = useCallback(() => {
    if (!__discountApplied) return '--';
    if (__discountApplied.discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
      return '--';
    }
    if (
      __discountApplied.discountFrom === DISCOUNT_FROM.AUTOMATION_DISCOUNT &&
      __discountApplied.automationDiscountType ===
        AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT
    ) {
      return '--';
    }
    if (__amountPayOriginal - __discountedPrice <= 0) {
      return `${formatMoneySign(0)} for ${__numberOfLessonLeft} lessons`;
    }

    return `${formatMoneySign(
      __amountPayOriginal - __discountedPrice
    )} for ${__numberOfLessonLeft} lessons`;
  }, [
    __discountApplied,
    __numberOfLessonLeft,
    __discountedPrice,
    __amountPayOriginal
  ]);

  const __discountData = useMemo(() => {
    if (!__discountApplied || !currentEnrolmentClass) return [];
    const result = [];
    if (__discountApplied) {
      const {
        discountType,
        discountValue,
        automationDiscountType,
        discountFrom,
        remainDiscountAmount,
        numberOfFreeLesson = 0,
        remainFreeLesson = 0,
        endDateFree,
        startDateFree,
        duration,
        voucherCode
      } = __discountApplied;
      let amountDisplay = '';

      if (
        discountFrom === DISCOUNT_FROM.AUTOMATION_DISCOUNT &&
        automationDiscountType === AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT &&
        paymentType === PAYMENT_VALUE.UPFRONT
      ) {
        return [];
      }
      if (discountType === DISCOUNT_TYPE.AMOUNT && remainDiscountAmount === 0) {
        return [];
      }
      let amount = 0;
      let valueDiscount = '';
      if (discountType === DISCOUNT_TYPE.PERCENTAGE) {
        amount = roundByTwo(__amountPayOriginal * (discountValue / 100));

        valueDiscount = `${discountValue}% off ${
          voucherCode ? `(${voucherCode})` : ''
        }`;
      } else if (discountType === DISCOUNT_TYPE.AMOUNT) {
        amount = remainDiscountAmount;
        valueDiscount = `${formatMoneySign(
          discountValue
        )} off (${voucherCode})`;
      } else if (discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
        if (paymentType === PAYMENT_VALUE.DIRECT_DEBIT) {
          amountDisplay = `--`;
        }
        if (
          !!numberOfFreeLesson &&
          duration === VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS
        ) {
          const discountPrice = roundByTwo(
            remainFreeLesson * currentEnrolmentClass.class.price
          );
          amount =
            discountPrice < __amountPayOriginal
              ? discountPrice
              : __amountPayOriginal;
        } else if (duration === VOUCHER_DURATION.TIME_RANGE) {
          amount = roundByTwo(__amountPayOriginal - __amountPayToday);
        }
        const durationText =
          duration === VOUCHER_DURATION.TIME_RANGE
            ? `${formatDate(startDateFree, 'slash')} - ${
                !!endDateFree ? formatDate(endDateFree, 'slash') : 'Ongoing'
              }`
            : `${numberOfFreeLesson} ${
                numberOfFreeLesson > 1 ? 'Lessons' : 'Lesson'
              }`;
        valueDiscount = `Complimentary ${durationText}`;
      }

      result.push({
        description: `${valueDiscount} (${getProgramType(
          currentEnrolmentClass.class.type
        )}) (${handleDateTimeRecord()}) ${formatData(
          currentEnrolmentClass.student.lastName
        )}, ${formatData(currentEnrolmentClass.student.firstName)}`,
        amount: amount,
        amountDisplay
      });
    }
    return result;
  }, [
    currentEnrolmentClass,
    __discountApplied,
    handleDateTimeRecord,
    paymentType,
    __amountPayOriginal,
    __amountPayToday
  ]);

  const __moneyCredit = useMemo((): number => {
    const subTotalNow = __amountPayToday || 0;
    if (subTotalNow === 0) return 0;
    if (subTotalNow > moneyCredit) {
      return moneyCredit;
    } else {
      return subTotalNow;
    }
  }, [__amountPayToday, moneyCredit]);

  const __finalAmount = useMemo(() => {
    const price = roundByTwo(__amountPayToday);
    if (price < __moneyCredit) return 0;
    return roundByTwo(roundByTwo(__amountPayToday) - __moneyCredit);
  }, [__amountPayToday, __moneyCredit]);

  const fetchResponsiblePerson = useCallback(async () => {
    setLoading(true);
    if (!params.RPId) return;
    try {
      const { data } = await getResponsiblePersonDetail(params.RPId);

      setMoneyCredit(data?.data.additionalInfo.moneyCredit || 0);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Get RP detail failed');
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [params.RPId]);

  useEffect(() => {
    fetchResponsiblePerson();
  }, [fetchResponsiblePerson]);

  const getLastLesson = useCallback(() => {
    const allSessionsLength = currentEnrolmentClass?.allSessions.length || 0;
    const length = __remainingLessonsFromTodayToEndTermActive.length;
    if (length === 0) return null;
    const date =
      __remainingLessonsFromTodayToEndTermActive?.[length - 1]?.startTime;
    if (dayjs(date).isBefore(dayjs()) && allSessionsLength > 0) {
      return currentEnrolmentClass?.allSessions[allSessionsLength - 1]
        ?.startTime;
    } else {
      return date;
    }
  }, [__remainingLessonsFromTodayToEndTermActive, currentEnrolmentClass]);

  return (
    <div
      className="change-payment-method-wrapper"
      onMouseEnter={handleMouseLeaveMenu}
    >
      <div
        className={`app_header__wrapper ${
          isShowSidebar ? ' ' : 'c_app_header__wrapper-collapsed'
        }`}
        style={{ width: isShowSidebar ? 'calc(100% - 280px)' : '100%' }}
      >
        <div>
          <MenuIcon />
          <div className="app_header" onMouseEnter={handleMouseLeaveMenu}></div>
        </div>

        <div></div>
      </div>
      <div className="layoutContainer classes-main">
        {loading ? (
          <AppLoadingContainer />
        ) : (
          <>
            <div className="review-n-payment-wrapper">
              {/* start review section */}
              <div className="section review-section">
                <div className="title">Review Summary</div>
                {/* start student info */}
                <div style={{ width: '100%' }}>
                  <div className="student-info-name">
                    {`${currentEnrolmentClass?.student.lastName}, ${currentEnrolmentClass?.student.firstName}`}
                  </div>
                  <div className="student-info">
                    <div className="review_body_body-row">
                      <div className="span3 header">PROGRAM TYPE</div>
                      <div className="span3 header">ENROLMENT TYPE</div>
                      <div className="span2 header">Day, Time</div>
                      <div className="span2 header">FIRST, LAST LESSON</div>
                      <div className="span3 header">Payment Option</div>
                      <div
                        className="span2 header"
                        style={{ textAlign: 'right' }}
                      >
                        Price
                      </div>
                      <div
                        className="span2 header"
                        style={{ textAlign: 'right' }}
                      >
                        Discounted Price
                      </div>
                      <div
                        className="span2 header"
                        style={{ textAlign: 'right' }}
                      >
                        Pay now
                      </div>
                    </div>
                    <div className="review_body_body-row">
                      <div className="span3">
                        {getProgramType(currentEnrolmentClass?.class?.type)}
                      </div>
                      <div className="span3">
                        {getEnrolmentType(currentEnrolmentClass?.type)}
                      </div>
                      <div className="span2">{handleDateTimeRecord()}</div>
                      <div className="span2">
                        {`${formatDate(
                          __remainingLessonsFromTodayToEndTermActive[0]
                            ?.startTime,
                          'slash'
                        )} - ${formatDate(getLastLesson(), 'slash')}`}
                      </div>
                      <div className="span3">
                        {PAYMENT_VALUE.UPFRONT.replaceAll('_', ' ')}
                      </div>

                      <div className="span2" style={{ textAlign: 'right' }}>
                        {`${formatMoneySign(__amountPayOriginal)} for ${
                          __remainingLessonsFromTodayToEndTermActive.length -
                          __lessonHavePaid.length
                        } lessons`}
                      </div>
                      <div className="span2" style={{ textAlign: 'right' }}>
                        {handleGetDiscountedPrice()}
                      </div>

                      <div
                        className="span2 debit_custom"
                        style={{ textAlign: 'right' }}
                      >
                        <div className="pay-now-container">
                          {formatMoneySign(__amountPayToday)}
                        </div>
                      </div>
                    </div>
                    {!!__discountApplied && __showDiscountApplied && (
                      <PromoCodeInput
                        onApplyPromoCode={() => {}}
                        hideInput
                        discountApplied={__discountApplied}
                      />
                    )}
                  </div>
                </div>
                {__numberOfFreeLessonFromMove > 0 && (
                  <div className="warning-wrapper number-of-lesson-free">
                    <div className="text">
                      You have {__numberOfFreeLessonFromMove} paid lesson
                      {__numberOfFreeLessonFromMove > 1 ? 's' : ''} not yet
                      happened on the current enrolment. It will be used to
                      deduct the enrolment fee on your next payment(s).
                    </div>
                  </div>
                )}
                {/* end student info */}
                <div className="total-section">
                  <div className="item">
                    <div className="title">SUBTOTAL</div>
                    <div>{formatMoneySign(__amountPayToday)}</div>
                  </div>
                  <div className="item">
                    <div className="title">Discount</div>
                    <div></div>
                  </div>
                  {__discountData?.map((item: DiscountData, index: number) => (
                    <div className="item discount" key={index}>
                      <div>{item.description}</div>
                      <div className="value">
                        -{formatMoneySign(item.amount)}
                      </div>
                    </div>
                  ))}
                  {__moneyCredit > 0 && (
                    <div className="item ">
                      <div className="title">Money credit</div>
                      <div>-{formatMoneySign(__moneyCredit)}</div>
                    </div>
                  )}
                  <div className="item">
                    <div className="title">TOTAL PAYING TODAY</div>
                    <div className="total-price">
                      {formatMoneySign(__finalAmount)}
                    </div>
                  </div>
                </div>
              </div>
              {/* end review section */}
              {/* start payment section */}
              <div className="section payment-section">
                <div className="title">Payment method</div>
                <div className="payment-method-list">
                  {PAYMENT_CARDS.map((item, index) => (
                    <Fragment key={index}>
                      {
                        <button
                          type="button"
                          className="item card"
                          // className={`item card ${
                          //   item.name === paymentMethod ? 'active' : ''
                          // }`}
                          // onClick={() => setPaymentMethod(item.name)}
                        >
                          <img
                            className={`logo ${
                              ![1, 8].includes(index)
                                ? 'bg-white square'
                                : 'round'
                            }`}
                            src={item.image}
                            alt="logo"
                          />
                          <div className="name">
                            {formatData(item.name).replaceAll('_', ' ')}
                          </div>
                        </button>
                      }
                    </Fragment>
                  ))}
                </div>
              </div>
              {/* end payment section */}
            </div>
            <Footer amount={__finalAmount} paymentType={paymentType} />
          </>
        )}
      </div>
    </div>
  );
};

export default memo(ChangePaymentMethodFlow);
