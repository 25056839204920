import { Suspense, lazy, memo, useEffect, useMemo, useState } from 'react';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import AppTabs from 'common/components/AppTabs';
import PermissionWrapper from 'components/PermissionWrapper';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import { useSearchParams } from 'react-router-dom';
import { TAB } from 'common/constants';

import './desktop.scss';

const TabStudentList = lazy(() => import('./TabStudentList'));
const TabVoucherDetails = lazy(() => import('./TabVoucherDetail'));

const VoucherDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tabIndex, setTabIndex] = useState<number>(TAB.IDLE);

  const tabs = useMemo(() => {
    return [
      {
        name: 'Voucher details',
        component: <TabVoucherDetails />
      },
      {
        name: 'Student List',
        component: <TabStudentList />
      }
    ];
  }, []);

  const handleSelectedTab = (val: number) => {
    setTabIndex(val);

    searchParams.set('indexRoute', `${val}`);
    setSearchParams(searchParams);
  };

  const indexRoute = Number(searchParams.get('indexRoute'));

  useEffect(() => {
    if (indexRoute >= 0 && indexRoute <= tabs.length) {
      setTabIndex(indexRoute);
    } else {
      setTabIndex(TAB.INIT);

      searchParams.set('indexRoute', `${TAB.INIT}`);
      setSearchParams(searchParams, { replace: true });
    }
  }, [indexRoute, searchParams, setSearchParams, tabs]);

  return (
    <PermissionWrapper permission={[]}>
      <div className="voucher-content-detail">
        <AppBreadCrumb
          items={[
            { name: 'Vouchers', href: '/vouchers' },
            { name: 'View Voucher', href: '' }
          ]}
        />
        <div className="voucher-tab-wrapper">
          <div className="voucher-tab-container">
            <AppTabs
              tabs={tabs}
              activeTabIndex={tabIndex}
              onChangeTab={handleSelectedTab}
            />

            {tabIndex >= 0 ? (
              <Suspense fallback={<AppLoadingContainer />}>
                {tabs[tabIndex].component}
              </Suspense>
            ) : null}
          </div>
        </div>
      </div>
    </PermissionWrapper>
  );
};

export default memo(VoucherDetail);
