import { object as OBJECT, array, string } from 'yup';

const ABSENCE_STUDENT_ID = string();
const ABSENCE_SCHEDULE_IDS = array();
const ABSENCE_CERTIFICATE_MEDICALS = array();
const ABSENCE_REASON = string().required('Please provide reason');
const ABSENCE_DECLINE_REASON_REQUIRED = string().required(
  'Please provide decline reason'
);
const ABSENCE_DECLINE_REASON = string();
const ABSENCE_STATUS = string();
const ABSENCE_REFUND_CREDIT_TYPE = string();

const validations = {
  OBJECT,
  ABSENCE_STUDENT_ID,
  ABSENCE_SCHEDULE_IDS,
  ABSENCE_CERTIFICATE_MEDICALS,
  ABSENCE_REASON,
  ABSENCE_DECLINE_REASON_REQUIRED,
  ABSENCE_DECLINE_REASON,
  ABSENCE_STATUS,
  ABSENCE_REFUND_CREDIT_TYPE
};
export default validations;

export const validationDeclineAbsenceSchema = validations.OBJECT({
  declineReason: validations.ABSENCE_DECLINE_REASON_REQUIRED,
  status: validations.ABSENCE_STATUS
});
