export const SEARCH_PARAMS = 'path';

export enum PATH {
  NOTIFICATIONS = 'notifications',
  CREDITS = 'credits',
  CLASSES = 'classes',
  BILLINGS = 'billings',
  NOTIFICATION_TEMPLATE = 'notification-template'
}

export const PATHS = [
  PATH.NOTIFICATIONS,
  PATH.CREDITS,
  PATH.CLASSES,
  PATH.BILLINGS,
  PATH.NOTIFICATION_TEMPLATE
];
