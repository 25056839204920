import React from 'react';
import { IActivity, IGroupActivity } from 'common/interfaces/log.interface';
import Activity from './Activity';
import { formatDate } from 'common/helpers/dataFormat.helper';
import dayjs from 'dayjs';

interface IGroupActivityProps {
  groupActivity: IGroupActivity;
}

const GroupActivity = (props: IGroupActivityProps) => {
  const { groupActivity } = props;
  return (
    <div className="activityList__main-block">
      <div className="activityList__main-block-horizontal">
        <p>
          {dayjs(groupActivity.date).diff(dayjs(), 'day') === 0
            ? 'Today'
            : dayjs(groupActivity.date).diff(dayjs(), 'day') === -1
            ? 'Yesterday'
            : formatDate(groupActivity.date)}
        </p>
      </div>
      <div className="activityList__main-block-items">
        {groupActivity.activities.map((activity: IActivity) => {
          return <Activity key={activity._id} activity={activity} />;
        })}
      </div>
    </div>
  );
};

export default React.memo(GroupActivity);
