import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import VersionModal from 'components/VersionModal';
import AppButton from 'common/components/AppButton';

import './index.scss';
import PaymentModal from 'components/PaymentModal';
import { every, forEach, reduce } from 'lodash';
import { bookClass } from 'services/students.service';
import { BookClassDto, BookingItem } from 'DTOs/student.dto';
import { useBrandLocation } from 'context/BrandLocationContext';
import { useToast } from 'context/ToastContext';
import AppModal, { AppModalContent } from 'common/components/AppModal';
import {
  IBookingData,
  ISchedule,
  ISchedulesOrigin
} from 'common/interfaces/schedules.interface';
import { ENROLLMENT_STEP } from 'common/enums/student.enum';
import {
  BOOKING_ON,
  PAYMENT_METHOD,
  PAYMENT_VALUE
} from 'common/enums/classBooking.enum';
import { useEnrollmentContext } from '../EnrollmentContext';
import { IStudentBookingData } from 'common/interfaces/student.interface';
import { FAILED_TO_GET_CARD_TOKEN } from 'common/constants/classBooking.constant';
import { roundByTwo } from 'common/helpers/dataFormat.helper';

interface Props {
  selectedClassTotal: number;
}
const EnrollmentFooter = ({ selectedClassTotal }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [stepBooking, setStepBooking] = useState<string>(
    searchParams.get('stepBooking') || ENROLLMENT_STEP.BOOKING
  );
  const toast = useToast();
  const { selectedLocation: globalLocation } = useBrandLocation();
  const {
    students,
    paymentMethod,
    selectedClassIds,
    canConfirm,
    finalData,
    moneyCredit,
    __discountAmount
  } = useEnrollmentContext();
  const params = useParams();
  const { RPId } = params;
  const navigate = useNavigate();

  const [isFailedToGetCardToken, setIsFailedToGetCardToken] =
    useState<boolean>(false);

  const [showModal, setShowModal] = useState(false);
  const [allAssessmentTrial, setAllAssessmentTrial] = useState<boolean>(false);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [openPaymentSuccessModal, setOpenPaymentSuccessModal] =
    useState<boolean>(false);

  const amount = useMemo(() => {
    const totalPayNow = finalData.reduce(
      (a: number, b: IStudentBookingData) =>
        a + reduce(b.bookingData, (a, b) => a + (b.pricePayNow || 0), 0),
      0
    );
    const result = totalPayNow - __discountAmount - moneyCredit;
    if (result > 0) {
      return roundByTwo(result);
    } else {
      return roundByTwo(0);
    }
  }, [finalData, __discountAmount, moneyCredit]);

  useEffect(() => {
    setStepBooking(searchParams.get('stepBooking') || ENROLLMENT_STEP.BOOKING);
  }, [searchParams]);

  useEffect(() => {
    let allAssessmentTrial = true;
    forEach(finalData, (student: IStudentBookingData) => {
      forEach(student.bookingData, (item: IBookingData) => {
        if (item.enrollmentType !== 'assessment_trial') {
          allAssessmentTrial = false;
          return;
        }
      });
    });
    // Check if all enrollment type are assessment_trial
    if (allAssessmentTrial) {
      setAllAssessmentTrial(true);
    } else {
      setAllAssessmentTrial(false);
    }
  }, [finalData]);

  const disabledNext = useMemo(() => {
    if (stepBooking === ENROLLMENT_STEP.PAYMENT) {
      // Check if all enrollment type are assessment_trial
      if (allAssessmentTrial) {
        return false;
      } else if (paymentMethod === PAYMENT_METHOD.VIVA_PAY) {
        return !canConfirm;
      }
    }
    if (stepBooking === ENROLLMENT_STEP.ENROLLMENT) {
      let validData = false;
      const validDataArr: boolean[] = [];
      let countCheckedClasses = 0;
      students.forEach((student, index) => {
        const classesBook = student.classesData?.filter((item) => item.checked);
        countCheckedClasses = countCheckedClasses + classesBook?.length || 0;
        if (classesBook?.length > 0) {
          if (
            every(
              classesBook,
              (item: ISchedulesOrigin) =>
                item.schedules.filter((schedule: ISchedule) => schedule.checked)
                  .length > 0
            )
          ) {
            validData = true;
          } else {
            validData = false;
          }
        } else {
          validData = true;
        }
        validDataArr[index] = validData;
      });
      if (countCheckedClasses < 1) {
        return true;
      }
      if (every(validDataArr, (item) => item)) {
        return false;
      }
    }
    if (selectedClassIds.length && stepBooking === ENROLLMENT_STEP.BOOKING) {
      return false;
    }
    return true;
  }, [
    paymentMethod,
    students,
    selectedClassIds,
    canConfirm,
    stepBooking,
    allAssessmentTrial
  ]);

  const handleClassBooking = () => {
    const step = Number(stepBooking);
    if (step <= 2) {
      searchParams.set('stepBooking', `${step + 1}`);

      setSearchParams(searchParams, { replace: true });
    } else {
      if (allAssessmentTrial) {
        handleBookAssessmentTrial();
        return;
      }
      setOpenPaymentModal(true);
    }
  };

  const getBookingItems = useCallback(() => {
    const bookingItems: BookingItem[] = [];
    forEach(finalData, (item: IStudentBookingData) => {
      forEach(item.bookingData, (booking: IBookingData) => {
        const result: BookingItem = {
          studentId: item._id,
          classId: booking.classInfo._id || '',
          bookingType: booking.enrollmentType,
          scheduleIds: booking.schedules
            .filter((schedule: ISchedule) => schedule.checked)
            .map((schedule: ISchedule) => schedule._id),
          paymentType: booking.paymentOption || PAYMENT_VALUE.DIRECT_DEBIT,
          totalCredit: 0,
          bookingOn: BOOKING_ON.STAFF_PORTAL
        };
        if (booking?.voucher?.code) {
          result.voucherCode = booking?.voucher?.code;
        }
        bookingItems.push(result);
      });
    });
    return bookingItems;
  }, [finalData]);

  const handleBookAssessmentTrial = async () => {
    if (stepBooking === ENROLLMENT_STEP.PAYMENT) {
      try {
        const payload: BookClassDto = {
          bookingItems: getBookingItems(),
          responsiblePersonId: RPId || '',
          amount: amount,
          locationId: globalLocation?._id || '',
          paymentMethod: null
        };
        await bookClass(payload);

        setOpenPaymentModal(false);
        setOpenPaymentSuccessModal(true);
        setTimeout(() => {
          navigate(`/responsible-person/${RPId}?indexRoute=1&tabNumber=0`);
        }, 2000);
      } catch (error: any) {
        toast.error(error?.response?.data?.message || 'Fail to make payment');
      }
    }
  };

  const handleGoBack = () => {
    if (!selectedClassTotal) {
      searchParams.delete('stepBooking');
      setSearchParams(searchParams);
      return;
    }

    if (stepBooking === ENROLLMENT_STEP.ENROLLMENT) {
      searchParams.delete('stepBooking');
      setSearchParams(searchParams);
    }

    if (stepBooking === ENROLLMENT_STEP.PAYMENT) {
      searchParams.set('stepBooking', ENROLLMENT_STEP.ENROLLMENT);
      setSearchParams(searchParams);
    }
  };

  const handlePayment = async (cardId: string) => {
    try {
      const payload: BookClassDto = {
        bookingItems: getBookingItems(),
        responsiblePersonId: RPId || '',
        amount: amount,
        cardId: cardId,
        locationId: globalLocation?._id || '',
        isSaveCardToken: true,
        statementDescriptor: {
          name: 'Payer'
        },
        paymentMethod: paymentMethod
      };
      await bookClass(payload);

      setOpenPaymentModal(false);
      setOpenPaymentSuccessModal(true);

      setTimeout(() => {
        navigate(`/responsible-person/${RPId}?indexRoute=1&tabNumber=0`);
      }, 2000);
    } catch (error: any) {
      if (error?.response?.data?.errorCode === FAILED_TO_GET_CARD_TOKEN) {
        setIsFailedToGetCardToken(true);
      } else {
        setIsFailedToGetCardToken(false);
        toast.error(error?.response?.data?.message || 'Fail to make payment');
      }
    }
  };
  const handleCancel = useCallback(() => {
    navigate('/students');
    // eslint-disable-next-line
  }, []);

  const handleCloseModalSuccess = () => {
    setOpenPaymentSuccessModal(false);
    navigate(`/responsible-person/${RPId}?indexRoute=1&tabNumber=0`);
  };
  return (
    <>
      {openPaymentModal && (
        <PaymentModal
          open={openPaymentModal}
          onClose={() => {
            setIsFailedToGetCardToken(false);
            setOpenPaymentModal(false);
          }}
          amount={amount}
          payerId={RPId || ''}
          onMakePayment={handlePayment}
          isFailedToGetCardToken={isFailedToGetCardToken}
        />
      )}
      <AppModal
        open={openPaymentSuccessModal}
        onClose={handleCloseModalSuccess}
      >
        <img
          src="/icons/approve-icon.svg"
          alt=""
          style={{ display: 'flex', margin: '38px auto 14px' }}
        />
        <AppModalContent>
          You have successfully booked the class
        </AppModalContent>
      </AppModal>
      <div className="c_footer-app-enrollment">
        <div className="c_footer-app_content">
          <div>© Viva Labs, a division of Viva Leisure Limited | </div>
          <div>ver 1.0.0</div>
          <img
            src="/icons/document.svg"
            width={15}
            alt=""
            style={{ cursor: 'pointer' }}
            onClick={() => setShowModal(true)}
          />
        </div>
        <div className="c_footer-app_btn">
          <AppButton
            type="button"
            variant="secondary"
            buttonSize="small"
            onClick={handleCancel}
          >
            Cancel
          </AppButton>
          {Number(searchParams.get('stepBooking') || ENROLLMENT_STEP.BOOKING) >=
          2 ? (
            <>
              <AppButton
                type="button"
                variant="secondary"
                buttonSize="small"
                onClick={handleGoBack}
              >
                Previous
              </AppButton>
            </>
          ) : null}
          {!!selectedClassTotal && (
            <AppButton
              type="button"
              buttonSize="small"
              onClick={handleClassBooking}
              disabled={disabledNext}
            >
              {stepBooking === ENROLLMENT_STEP.PAYMENT ? 'Confirm' : 'Next'}
            </AppButton>
          )}
        </div>
      </div>
      <VersionModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default EnrollmentFooter;
