import { Suspense, memo, useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import MoveBookingHeader from './Header/index';
import MoveBookingFooter from './Footer';
import { useLayout } from 'context/LayoutContext';
import { ENROLLMENT_STEP } from 'common/enums/student.enum';
import { useToast } from 'context/ToastContext';
import { useMoveBooking } from 'context/MoveBookingContext';
import BookingClasses from './EnrolmentStep';
import BookingStep from './BookingStep';
import ReviewNPayment from './ReviewNPaymentStep';
import { getResponsiblePersonDetail } from 'services/responsiblePerson.service';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { TAB } from 'common/constants';

const MoveBookingFlow = () => {
  const { handleMouseLeaveMenu } = useLayout();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const toast = useToast();
  const { selectedClassIds, onChangeMoneyCredit, nextDebitDay } =
    useMoveBooking();
  const [tabIndex, setTabIndex] = useState<number>(TAB.IDLE);

  const fetchResponsiblePerson = useCallback(async () => {
    if (!params.RPId) return;
    try {
      const { data } = await getResponsiblePersonDetail(params.RPId);

      onChangeMoneyCredit(data?.data.additionalInfo.moneyCredit || 0);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Get RP detail failed');
    }
    // eslint-disable-next-line
  }, [params.RPId]);

  useEffect(() => {
    fetchResponsiblePerson();
  }, [fetchResponsiblePerson]);

  const tabs = [
    {
      name: 'Booking',
      component: <BookingStep />
    },
    {
      name: 'Enrolment',
      component: <BookingClasses />
    },
    {
      name: 'Review & Payment',
      component: <ReviewNPayment nextDebitDay={nextDebitDay} />
    }
  ];

  useEffect(() => {
    const params = searchParams.get('stepBooking') || ENROLLMENT_STEP.BOOKING;
    setTabIndex(Number(params) - 1);
  }, [searchParams]);

  const handleClickStep = (value: string) => {
    searchParams.set('stepBooking', value);
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <div className="move-booking" onMouseEnter={handleMouseLeaveMenu}>
      <MoveBookingHeader items={[]} onClickStep={handleClickStep} />
      {tabIndex >= 0 ? (
        <Suspense fallback={<AppLoadingContainer />}>
          {tabs[tabIndex].component}
        </Suspense>
      ) : null}
      <MoveBookingFooter
        selectedClassTotal={selectedClassIds.length}
        nextDebitDay={nextDebitDay}
      />
    </div>
  );
};

export default memo(MoveBookingFlow);
