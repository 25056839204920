import {
  CreateAssessmentScoreDTO,
  CreateLevelDTO,
  CreateMilestoneDTO,
  CreateSkillDTO
} from 'DTOs/levelBreakdown.dto';

export const defaultIcon =
  'https://swim-school-be.s3.ap-southeast-1.amazonaws.com/icon/4a73a8c4-a92b-47eb-9ced-edb4bc270afa-star.png';

export const ERROR_SKILL_NAME: string =
  'Please provide skill of the level name';
export const ERROR_SKILL_MILESTONES: string =
  'Please provide at least one milestone';
export const ERROR_MILESTONE_NAME: string = 'Please provide milestone name';

export const initSkill: CreateSkillDTO = {
  name: '',
  position: 0,
  milestones: [],
  error: ''
};

export const initMilestone: CreateMilestoneDTO = {
  name: '',
  position: 0,
  error: ''
};

export const initAssessmentScore: CreateAssessmentScoreDTO = {
  maxScore: 3,
  icon: defaultIcon,
  assessmentScoreDetails: [
    {
      fieldName: 'Beginner',
      fieldValue: 1
    },
    {
      fieldName: 'Developing',
      fieldValue: 2
    },
    {
      fieldName: 'Achieved',
      fieldValue: 3
    }
  ]
};

export const initLevel: CreateLevelDTO = {
  name: '',
  shortName: '',
  fromAge: '',
  toAge: '',
  colorCode: '#034ea2',
  description: '',
  skills: [],
  assessmentScore: initAssessmentScore,
  mascotImage: '',
  backgroundImage: '',
  nextLevelIds: '',
  locationIds: []
};
