import React, { useCallback, useMemo, useState } from 'react';
import { CreateSkillForLevelDTO } from 'DTOs/levelBreakdown.dto';
import AppInput from 'common/components/AppInput';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yupLevel from 'validators/level.validator';
import LevelMilestoneCard from 'pages/levels/components/LevelMilestoneCard';
import LevelMilestoneCardAdd from 'pages/levels/components/LevelMilestoneCardAdd';
import AppCard, {
  AppCardContent,
  AppCardHeader
} from 'common/components/AppCard';
import { BeatLoader } from 'react-spinners';
import { HiOutlineBookmark, HiOutlineXCircle } from 'react-icons/hi';
import { useToast } from 'context/ToastContext';
import { createSkill } from 'services/levelBreakdown.service';
import {
  ERROR_MILESTONE_NAME,
  ERROR_SKILL_MILESTONES,
  initMilestone
} from 'pages/levels/constants';

const validationSchema = yupLevel.OBJECT({
  name: yupLevel.SKILL_NAME,
  milestones: yupLevel.SKILL_MILESTONES,
  position: yupLevel.SKILL_POSITION,
  levelId: yupLevel.SKILL_LEVEL_ID
});

interface ILevelsBreakdownDetailSkillAddProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  position: number;
  levelId: string;
}

const LevelsBreakdownDetailSkillAdd = (
  props: ILevelsBreakdownDetailSkillAddProps
) => {
  const { open, onClose, onSuccess, position, levelId } = props;

  const toast = useToast();

  const [skill, setSkill] = useState<CreateSkillForLevelDTO>({
    name: '',
    milestones: [],
    position: position,
    levelId: levelId,
    error: ''
  });

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<CreateSkillForLevelDTO>({
    resolver: yupResolver(validationSchema) as Resolver<CreateSkillForLevelDTO>
  });

  const handleAddMilestone = useCallback(() => {
    setSkill((currentSkill) => {
      return {
        ...currentSkill,
        milestones: [
          ...currentSkill.milestones,
          { ...initMilestone, position: skill.milestones.length }
        ]
      };
    });
  }, [skill.milestones.length]);

  const handleRemoveMilestone = useCallback((position: number) => {
    setSkill((currentSkill) => {
      return {
        ...currentSkill,
        milestones: currentSkill.milestones
          .filter((milestone) => milestone.position !== position)
          .map((milestone, index) => ({ ...milestone, position: index }))
      };
    });
  }, []);

  const handleChangeMilestone = useCallback(
    (value: string, milestonePosition: number) => {
      setSkill((currentSkill) => {
        return {
          ...currentSkill,
          milestones: currentSkill.milestones.map((milestone) => {
            if (milestone.position === milestonePosition)
              return {
                ...milestone,
                name: value
              };
            else return milestone;
          })
        };
      });
    },
    []
  );

  const onSubmit = useCallback(
    async (data: CreateSkillForLevelDTO) => {
      try {
        // Validate with state
        const tempSkill = {
          ...skill,
          name: data.name,
          error: skill.milestones.length === 0 ? ERROR_SKILL_MILESTONES : '',
          milestones: skill.milestones.map((milestone) => {
            return {
              ...milestone,
              error: !milestone.name ? ERROR_MILESTONE_NAME : ''
            };
          })
        };
        setSkill(tempSkill);

        const isHaveErrors =
          !tempSkill.name ||
          tempSkill.milestones.length === 0 ||
          tempSkill.milestones.some((milestone) => {
            return !!milestone?.error;
          });
        if (isHaveErrors) return;

        setLoading(true);
        delete skill.error;
        const payload: CreateSkillForLevelDTO = {
          ...skill,
          name: data.name,
          milestones: skill.milestones.map(({ error, ...milestone }) => {
            return {
              ...milestone
            };
          })
        };
        await createSkill(payload);
        toast.success('Create a new skill successfully');
        onSuccess();
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Failed to add new skill'
        );
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [skill.milestones, onSuccess]
  );

  const __renderIcons = useMemo((): React.ReactNode => {
    return loading ? (
      <BeatLoader color="white" />
    ) : (
      <>
        <div className="icon" onClick={handleSubmit(onSubmit)}>
          <HiOutlineBookmark />
        </div>
        <div className="icon" onClick={onClose}>
          <HiOutlineXCircle />
        </div>
      </>
    );
  }, [loading, onSubmit, handleSubmit, onClose]);

  if (!open) return null;

  return (
    <div className="levelDetailSkillAdd">
      <AppCard>
        <AppCardHeader title="Skill" suffix={__renderIcons} />
        <AppCardContent>
          <div className="levelDetailSkillAdd__form">
            <AppInput
              label="Skill of the level*"
              {...register('name')}
              id={`skill-input-add`}
              message={{
                type: 'error',
                text: errors?.name?.message || skill.error || ''
              }}
            />
          </div>
          <div className="levelDetailSkillAdd__milestones">
            {skill.milestones.length > 0 &&
              skill.milestones.map((milestone) => {
                return (
                  <LevelMilestoneCard
                    key={milestone.position}
                    milestone={milestone}
                    onRemoveMilestone={handleRemoveMilestone}
                    onChangeMilestone={handleChangeMilestone}
                    errorMessage={milestone.error}
                  />
                );
              })}
            <div onClick={handleAddMilestone}>
              <LevelMilestoneCardAdd />
            </div>
          </div>
        </AppCardContent>
      </AppCard>
    </div>
  );
};

export default LevelsBreakdownDetailSkillAdd;
