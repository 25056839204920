import { IPermission } from 'common/interfaces/hierarchy.interface';
import API from './API';

const PERMISSION_ENDPOINT = `/permissions`;
const ROLE_ENDPOINT = `/roles`;

export const getAllPermissions = async (): Promise<IPermission[]> => {
  const response = await API.get(`${PERMISSION_ENDPOINT}`);
  return response.data.data;
};
export const getAllRole = (staffType?: string) => {
  return API.get(`${ROLE_ENDPOINT}`, {
    staffType
  });
};
export const updateRoles = (id: string, payload: any) => {
  return API.patch(`${ROLE_ENDPOINT}/${id}`, { body: payload });
};
