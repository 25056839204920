import {
  useCallback,
  useEffect,
  useState,
  lazy,
  Suspense,
  memo,
  useMemo
} from 'react';
import AppHeader from 'components/common/AppHeader';
import AddClass from './AddClass';
import TableClasses from './TableClasses';
import { useToast } from 'context/ToastContext';
import { useStepController } from 'context/StepControllerContext';
import useDebounce from 'common/hooks/useDebounce';
import { useBrandLocation } from 'context/BrandLocationContext';
import { getClassList } from 'services/classes.service';
import { FilterValue, IClass } from 'common/interfaces/class.interface';
import { IClassSearchAdvance } from 'common/interfaces/class.interface';
import { FLOW_ENUM } from 'common/enums/step.enum';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { FilterClasses } from '../types';
import { KEY_FILTER_CLASSES } from 'common/constants/localStorage.constant';
import useLocalStorage from 'common/hooks/useLocalStorage';
import AppLoadingContainer from 'common/components/AppLoadingContainer';

import './desktop.scss';
import { useAuth } from 'context/AuthContext';
import { USER_TYPE } from 'common/constants/permission.constant';

const ClassBooking = lazy(() => import('../ClassBooking'));

const initialSearchAdvanceValue = {
  what: '',
  who: '',
  fromDate: '',
  toDate: ''
};

const initialFilterClasses = {
  classType: '',
  level: '',
  instructor: '',
  area: '',
  dayOfWeek: '',
  duration: '',
  vacancy: 1,
  applyVacancy: false,
  canBeBookMakeUp: false
};

const Classes = () => {
  const { dataSet } = useStepController();
  const { user } = useAuth();

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageTotal, setPageTotal] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [classes, setClasses] = useState<IClass[]>([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [searchByValue, setSearchByValue] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchAdvanceValue, setSearchAdvanceValue] =
    useState<IClassSearchAdvance>(initialSearchAdvanceValue);
  const [filterValue, setFilterValue] = useLocalStorage<FilterValue>(
    KEY_FILTER_CLASSES,
    initialFilterClasses
  );
  const debouncedSearch = useDebounce(searchValue);
  const { selectedLocation: globalLocation } = useBrandLocation();
  const [filterFieldNumber, setFilterFieldNumber] = useState(0);

  const toast = useToast();

  useEffect(() => {
    setPageIndex(1);
  }, [globalLocation?._id]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      let numberFilterField = 0;

      const params: FilterClasses = {
        limit: pageSize,
        page: pageIndex,
        locationId: globalLocation?._id
      };

      if (filterValue.classType) {
        params.classTypes = filterValue.classType;
        numberFilterField++;
      }

      if (filterValue.level) {
        params.levels = filterValue.level;
        numberFilterField++;
      }
      if (user?.userType === USER_TYPE.INSTRUCTOR) {
        params.instructors = user._id;
      } else if (filterValue.instructor) {
        params.instructors = filterValue.instructor;
        numberFilterField++;
      }

      if (filterValue.area) {
        params.areas = filterValue.area;
        numberFilterField++;
      }

      if (filterValue.dayOfWeek) {
        params.dayOfWeeks = filterValue.dayOfWeek;
        numberFilterField++;
      }

      if (filterValue.duration) {
        params.durations = filterValue.duration;
        numberFilterField++;
      }

      if (filterValue.applyVacancy) {
        params.vacancy = filterValue.vacancy;
      }
      if (filterValue.canBeBookMakeUp) {
        params.makeupVacancy = filterValue.canBeBookMakeUp;
      }
      setFilterFieldNumber(numberFilterField);

      if (searchByValue) {
        params.keyword = debouncedSearch;
      } else {
        if (searchAdvanceValue.who) {
          params.instructorName = searchAdvanceValue.who;
        }

        if (searchAdvanceValue.what) {
          params.keyword = searchAdvanceValue.what;
        }

        if (searchAdvanceValue.fromDate) {
          params.fromDate = convertToUnixTime(searchAdvanceValue.fromDate);
        }

        if (searchAdvanceValue.toDate) {
          params.toDate = convertToUnixTime(searchAdvanceValue.toDate);
        }
      }

      const { data, total } = await getClassList(params);

      setPageTotal(total);
      setClasses(data);
    } catch (error) {
      setClasses([]);
      toast.error('Failed to get class list');
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [
    pageSize,
    pageIndex,
    debouncedSearch,
    filterValue,
    searchAdvanceValue,
    searchByValue,
    globalLocation?._id
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSearchAdvance = (value: any) => {
    setSearchAdvanceValue(value);
    setSearchByValue(false);
  };
  const onSearchValue = (value: any) => {
    setSearchByValue(true);
    setSearchValue(value);
  };

  const onOpenAdd = () => {
    setOpenAdd(true);
  };
  const onCloseAdd = () => {
    setOpenAdd(false);
  };
  const onAddSuccess = async () => {
    onCloseAdd();
    setPageIndex(1);
    setSearchValue('');
    await fetchData();
  };
  const onDeleteSuccess = async () => {
    setPageIndex(1);
    await fetchData();
  };

  const onChangeFilterValue = useCallback(
    (value: FilterValue) => {
      setFilterValue(value);
    },
    [setFilterValue]
  );

  const __showTableClasses = useMemo((): boolean => {
    if (dataSet) {
      if (dataSet?.flow === FLOW_ENUM.INIT) {
        return true;
      }

      if (dataSet?.flow === FLOW_ENUM.CLASS_BOOKING) {
        if (dataSet?.step >= 2) {
          return false;
        }
      }
    }

    return true;
  }, [dataSet]);

  return (
    <div className="classes classPage">
      {/* LIST */}

      <AppHeader items={[{ name: 'Classes', href: '/classes' }]} />

      {__showTableClasses ? (
        <>
          <TableClasses
            classes={classes}
            onOpenAdd={onOpenAdd}
            setSearchValue={(value: string) => onSearchValue(value)}
            onChangeFilterValue={onChangeFilterValue}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            onDeleteSuccess={onDeleteSuccess}
            searchAdvance={onSearchAdvance}
            searchValue={searchValue}
            loading={loading}
            pageTotal={pageTotal}
            pageIndex={pageIndex}
            pageSize={pageSize}
            filterFieldNumber={filterFieldNumber}
          />
          {/* ADD */}
          {openAdd && (
            <AddClass
              openAdd={openAdd}
              onCloseAdd={onCloseAdd}
              onSuccess={onAddSuccess}
            />
          )}
        </>
      ) : (
        <Suspense fallback={<AppLoadingContainer />}>
          <ClassBooking />
        </Suspense>
      )}
    </div>
  );
};

export default memo(Classes);
