import { DISCOUNT_TYPE } from 'common/enums';
import { VOUCHER_DURATION } from 'common/enums/voucher.enum';
import dayjs from 'dayjs';
import { notAllowDiffNumCharRegExp, notAllowSpaceRegExp } from 'validators';
import { object as OBJECT, array, boolean, number, string } from 'yup';

const VOUCHER_CODE = string()
  .required('Please provide voucher code')
  .max(12, 'This value must be at most 12 characters long')
  .matches(
    notAllowDiffNumCharRegExp,
    'This value can only contain letters and number'
  )
  .matches(notAllowSpaceRegExp, 'This value cannot contain spaces');
const VOUCHER_VALUE = number()
  .required('Please provide value')
  .typeError('This value must be a number')
  .test('greater-than-0', function (value, context) {
    const { parent } = context;
    const discountType = parent?.discountType || '';

    if (discountType !== DISCOUNT_TYPE.COMPLIMENTARY && value <= 0) {
      return this.createError({
        path: context.path,
        message: 'This value must be greater than 0'
      });
    }

    return true;
  })
  .test('validate-percentage-discount', function (value, context) {
    const { parent } = context;
    const discountType = parent?.discountType || '';

    if (discountType === DISCOUNT_TYPE.PERCENTAGE && value > 100) {
      return this.createError({
        path: context.path,
        message: 'Discount value must be equal or less than 100'
      });
    }

    return true;
  });
const VOUCHER_LOCATIONS = array().min(1, 'Please select at least one location');
const VOUCHER_START_DATE = string()
  .required('Please select start date')
  .test('validate-start-date', function (value, context) {
    const { options } = context;
    const { isEdit } = options.context || {};

    if (
      (!isEdit && dayjs(value)?.isBefore(dayjs(), 'day')) ||
      value === 'Invalid Date'
    ) {
      return this.createError({
        path: context.path,
        message: 'Start date must be equal or greater than today '
      });
    }

    return true;
  });
const VOUCHER_END_DATE = string()
  .required('Please select end date')
  .test('validate-end-date', function (value, context) {
    const { parent, path, options } = context;
    const startDate = parent?.startDate;
    const endDate = parent?.endDate;
    const { isEdit, oldEndDate } = options.context || {};

    if (
      (!isEdit && dayjs(value)?.isBefore(dayjs(), 'day')) ||
      value === 'Invalid Date'
    ) {
      return this.createError({
        path: context.path,
        message: 'End date must be equal or greater than today'
      });
    }

    if (startDate && dayjs(endDate)?.isBefore(dayjs(startDate), 'day')) {
      return this.createError({
        path: path,
        message: 'End date must be equal or greater than start date'
      });
    }

    if (
      (isEdit && dayjs(value)?.isBefore(dayjs(oldEndDate), 'day')) ||
      value === 'Invalid Date'
    ) {
      return this.createError({
        path: context.path,
        message: 'End date must be equal or greater than previous value'
      });
    }

    return true;
  });
const VOUCHER_START_DATE_FREE = string().test(
  'validate-start-date-free',
  function (value, context) {
    const { parent, options } = context;
    const { isEdit } = options.context || {};
    const { duration, discountType } = parent;

    if (
      discountType === DISCOUNT_TYPE.COMPLIMENTARY &&
      duration === VOUCHER_DURATION.TIME_RANGE &&
      value?.length === 0
    ) {
      return this.createError({
        path: context.path,
        message: 'Please select date from'
      });
    }

    if (
      (!isEdit && dayjs(value)?.isBefore(dayjs(), 'day')) ||
      value === 'Invalid Date'
    ) {
      return this.createError({
        path: context.path,
        message: 'Date from must be equal or greater than today'
      });
    }

    return true;
  }
);
const VOUCHER_END_DATE_FREE = string().test(
  'validate-end-date-free',
  function (value, context) {
    const { parent, path, options } = context;
    const { isEdit, oldEndDateFree } = options.context || {};
    const { startDateFree } = parent;

    if (
      (!isEdit && dayjs(value)?.isBefore(dayjs(), 'day')) ||
      value === 'Invalid Date'
    ) {
      return this.createError({
        path: context.path,
        message: 'Date to must be equal or greater than today'
      });
    }

    if (startDateFree && dayjs(value)?.isBefore(dayjs(startDateFree), 'day')) {
      return this.createError({
        path: path,
        message: 'Date to must be equal or greater than date from'
      });
    }

    if (
      (isEdit && dayjs(value)?.isBefore(dayjs(oldEndDateFree), 'day')) ||
      value === 'Invalid Date'
    ) {
      return this.createError({
        path: context.path,
        message: 'Date to must be equal or greater than previous value'
      });
    }

    return true;
  }
);
const VOUCHER_MEMBER_TYPES = array().min(
  1,
  'Please select at least one member type'
);
const VOUCHER_PROGRAM_TYPES = array().min(
  1,
  'Please select at least one program type'
);
const VOUCHER_BOOKING_TYPES = array().min(
  1,
  'Please select at least one enrollment type'
);
const VOUCHER_NOTES = string().required('Please provide notes');
const VOUCHER_ALL_LOCATION = boolean();
const VOUCHER_JOINING_FEE = number()
  .required('Please provide joining fee')
  .typeError('This value must be a number')
  .min(0, 'This value must be equal or greater than 0');
const VOUCHER_ACTIVATION_FEE = number()
  .required('Please provide activation fee')
  .typeError('This value must be a number')
  .min(0, 'This value must be equal or greater than 0');
const VOUCHER_ACCESS_FEE = number()
  .required('Please provide access fee')
  .typeError('This value must be a number')
  .min(0, 'This value must be equal or greater than 0');
const VOUCHER_MAX_USAGE = number()
  .required('Please provide max usage')
  .typeError('This value must be a number')
  .min(0, 'This value must be equal or greater than 0');
const VOUCHER_TYPE = string().required('Please provide type');
const DISCOUNT_DURATION = string().required('Please provide duration');
const VOUCHER_NUMBER_LESSON = number()
  .required('Please provide number of lesson')
  .integer('This value must be an integer')
  .typeError('This value must be a number')
  .test('greater-than-0', function (value, context) {
    const { parent } = context;
    const discountType = parent?.discountType || '';
    const duration = parent?.duration || '';

    if (
      discountType === DISCOUNT_TYPE.COMPLIMENTARY &&
      duration === VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS &&
      value <= 0
    ) {
      return this.createError({
        path: context.path,
        message: 'This value must be greater than 0'
      });
    }

    return true;
  });
const VOUCHER_QUANTITY = number()
  .integer('This value must be an integer')
  .required('Please provide quantity')
  .typeError('This value must be a number')
  .moreThan(0, 'This value must greater than 0')
  .test('validate-quantity-when-editing', function (value, context) {
    const { options } = context;
    const { isEdit, oldQuantity } = options.context || {};

    if (isEdit && Number(value) < oldQuantity) {
      return this.createError({
        path: context.path,
        message: 'This value must be equal or greater than previous value'
      });
    }

    return true;
  });
const VOUCHER_ID = string().required('Please voucher ID');

const NOTIFICATION_TEMPLATE = string();
const NOTIFICATION_TYPES = array().min(1, 'Please select at least one type');
const NOTIFICATION_TITLE = string().required('Please provide title');
const NOTIFICATION_CONTENT = string().required('Please provide content');
const NOTIFICATION_STUDENT_ID = array();

const validations = {
  OBJECT,

  VOUCHER_CODE,
  VOUCHER_VALUE,
  VOUCHER_LOCATIONS,
  VOUCHER_START_DATE,
  VOUCHER_END_DATE,
  VOUCHER_MEMBER_TYPES,
  VOUCHER_PROGRAM_TYPES,
  VOUCHER_BOOKING_TYPES,
  VOUCHER_NOTES,
  VOUCHER_ALL_LOCATION,
  VOUCHER_JOINING_FEE,
  VOUCHER_ACTIVATION_FEE,
  VOUCHER_ACCESS_FEE,
  VOUCHER_TYPE,
  VOUCHER_MAX_USAGE,
  DISCOUNT_DURATION,
  VOUCHER_NUMBER_LESSON,
  VOUCHER_QUANTITY,
  VOUCHER_START_DATE_FREE,
  VOUCHER_END_DATE_FREE,
  VOUCHER_ID,

  NOTIFICATION_TEMPLATE,
  NOTIFICATION_TYPES,
  NOTIFICATION_TITLE,
  NOTIFICATION_CONTENT,
  NOTIFICATION_STUDENT_ID
};
export default validations;
