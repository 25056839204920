import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  ReactNode
} from 'react';
import { STATIC_BRAND_CLUBLIME } from 'common/constants/index';
import { KEY_GLOBAL_LOCATION } from 'common/constants/localStorage.constant';
import useLocalStorage from 'common/hooks/useLocalStorage';
import { ILocation } from 'common/interfaces/location.interface';
import { getLocationsByBrandId } from 'services/location.service';
import { useAuth } from './AuthContext';

interface BrandLocationContextType {
  isLoadLocations: boolean;
  locations: Array<ILocation>;
  selectedLocation: ILocation | null;
  onChangeLocation: (locationId: string) => void;
  onRefetchLocation: () => void;
}

const BrandLocationContext = createContext<BrandLocationContextType | null>(
  null
);

export const useBrandLocation = (): BrandLocationContextType => {
  const brandLocationContext = useContext(BrandLocationContext);
  if (!brandLocationContext) {
    throw new Error(
      'useBrandLocation must be used within an BrandLocationContext'
    );
  }
  return brandLocationContext;
};

interface Props {
  children: ReactNode;
}

export const BrandLocationProvider = ({ children }: Props) => {
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [selectedLocation, setSelectedLocation] =
    useLocalStorage<ILocation | null>(KEY_GLOBAL_LOCATION, null);

  const { user, __isInstructorAccount } = useAuth();
  const [isLoadLocations, setIsLoadLocations] = useState(true);

  const fetchLocation = useCallback(async () => {
    try {
      setIsLoadLocations(true);

      const { data } = await getLocationsByBrandId(STATIC_BRAND_CLUBLIME._id);

      let locations = data.data;
      if (__isInstructorAccount) {
        locations = data.data?.filter((location: ILocation) =>
          user?.locationIds?.includes(location._id)
        );
      }

      setLocations(locations);

      if (locations?.length > 0) {
        if (user?.defaultLocationId) {
          const location = locations?.find(
            (location: ILocation) => location._id === user?.defaultLocationId
          );
          if (location) {
            setSelectedLocation(location);
            return;
          }
        }
        if (
          !selectedLocation ||
          !locations?.find(
            (location: ILocation) => location._id === selectedLocation._id
          )
        ) {
          setSelectedLocation(locations?.[0]);
        } else {
          setSelectedLocation(
            locations?.find(
              (location: ILocation) => location._id === selectedLocation._id
            )
          );
        }
      } else {
        setSelectedLocation(null);
      }
    } catch (error) {
      setLocations([]);
      setSelectedLocation(null);
    } finally {
      setTimeout(() => setIsLoadLocations(false), 100);
    }
  }, [selectedLocation, setSelectedLocation, user, __isInstructorAccount]);

  useEffect(() => {
    fetchLocation();
    // eslint-disable-next-line
  }, [user]);

  const onChangeLocation = useCallback(
    (locationId: string): void => {
      const newLocation = locations.find(
        (location) => location._id === locationId
      );

      if (newLocation) {
        setSelectedLocation(newLocation);
      }
    },
    [locations, setSelectedLocation]
  );

  const onRefetchLocation = useCallback((): void => {
    fetchLocation();

    // eslint-disable-next-line
  }, []);

  const layoutContextValue: BrandLocationContextType = {
    locations,
    selectedLocation,
    isLoadLocations,
    onChangeLocation,
    onRefetchLocation
  };

  return (
    <BrandLocationContext.Provider value={layoutContextValue}>
      {children}
    </BrandLocationContext.Provider>
  );
};
