import { useMemo, useState } from 'react';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import AppButton from 'common/components/AppButton';
import { deleteTerm } from 'services/term.service';
import { useToast } from 'context/ToastContext';
import { useLocation } from 'react-router-dom';

interface ITermDeleteModalProps {
  termId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const TermDeleteModal = (props: ITermDeleteModalProps) => {
  const { termId, onClose, onSuccess } = props;

  const toast = useToast();
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);

  const isHoliday = useMemo(
    () => location.pathname.includes('/holiday'),
    [location.pathname]
  );

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteTerm(termId);
      if (isHoliday) {
        toast.success('Deleted this Holiday successfully');
      } else toast.success('Deleted this Session successfully');
      onSuccess();
    } catch (error: any) {
      if (isHoliday) {
        toast.error(
          error?.response?.data?.message || 'Failed to delete this Holiday'
        );
      } else
        toast.error(
          error?.response?.data?.message || 'Failed to delete this Session'
        );
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppModal open={!!termId} onClose={onClose}>
      <AppModalTitle>Delete Session</AppModalTitle>
      <AppModalContent>
        ARE YOU SURE YOU WANT TO DELETE THIS {isHoliday ? 'Holiday' : 'Session'}
        ?
      </AppModalContent>
      <AppModalActions>
        <AppButton variant="secondary" onClick={onClose}>
          No
        </AppButton>
        <AppButton isLoading={loading} onClick={handleDelete}>
          Yes
        </AppButton>
      </AppModalActions>
    </AppModal>
  );
};

export default TermDeleteModal;
