import {
  CustomStudentEnrollment,
  DataSetFlowBookingClass,
  IBillingCycle,
  IResponsiblePersonBooked
} from '../../common/interfaces/bookingClass.interface';
import { CLASS_TYPES, RECURRENCE_VALUES } from '../../common/enums/class.enum';
import {
  BOOKING_ERROR_TYPE,
  BOOKING_TYPE,
  PAYMENT_METHOD,
  PAYMENT_VALUE
} from '../../common/enums/classBooking.enum';
import { FLOW_ENUM } from '../../common/enums/step.enum';
import { ITerm } from '../../common/interfaces/term.interface';
import dayjs from 'dayjs';
import {
  IBookingData,
  ISchedule
} from '../../common/interfaces/schedules.interface';
import { getDaysArray, handleGetPriceDirectDebit } from './classBooking.helper';
import { formatDate, roundByTwo } from './dataFormat.helper';
import { forEach, sortBy, uniqBy } from 'lodash';
import { DISCOUNT_TYPE } from '../enums';
import { VOUCHER_DURATION } from '../enums/voucher.enum';
import { FORMAT_END_OF_DATE } from '../constants';

export const getLessonsDayInRange = (
  schedules: ISchedule[],
  startDate: string,
  endDateInput: string,
  stopBilling: ITerm[]
) => {
  if (!schedules) return [];

  const endDate = dayjs(endDateInput);

  let startDateToCalculate = dayjs(startDate);

  const recurrenceClassOnGoing = schedules.map(
    (item: ISchedule) => item?.startTime
  );

  if (
    dayjs(startDateToCalculate).isBefore(dayjs(schedules?.[0]?.startTime)) &&
    dayjs(endDate).isBefore(dayjs(schedules?.[0]?.startTime))
  ) {
    return [];
  }
  if (dayjs(startDateToCalculate).isBefore(dayjs(schedules?.[0]?.startTime))) {
    startDateToCalculate = dayjs(schedules?.[0]?.startTime);
  }
  const listDateBetweenStartAndEnd = getDaysArray(
    // get the nearest day between the date student start class and the date class start
    new Date(startDateToCalculate.toDate()),
    new Date(endDate.toDate())
  );

  let noStudyDays: string[] = [];
  forEach(stopBilling, (term) => {
    const termEndDate = dayjs(term.endDate).format(FORMAT_END_OF_DATE);
    if (
      dayjs(termEndDate).isAfter(startDateToCalculate) &&
      dayjs(term.startDate).isBefore(endDate)
    ) {
      const listDateOfTerm = getDaysArray(
        new Date(term.startDate),
        new Date(term.endDate)
      ).map((item) => dayjs(item).format('YYYY-MM-DD'));
      noStudyDays.push(...listDateOfTerm);
    }
  });

  const listDateOfClass = listDateBetweenStartAndEnd
    .map((item) => dayjs(item).format('YYYY-MM-DD'))
    .filter((item) => !noStudyDays.includes(item));
  const array = recurrenceClassOnGoing.map((i) => dayjs(i).format('ddd'));
  const totalSessionStudentHaveLeft = listDateOfClass.filter((item) =>
    array.includes(dayjs(item).format('ddd'))
  );

  return totalSessionStudentHaveLeft;
};
export const onlyBookMakeupOrAssessmentClass = (
  resultData: DataSetFlowBookingClass
): boolean => {
  if (
    resultData?.flow === FLOW_ENUM.CLASS_BOOKING &&
    (resultData?.step === 6 || resultData?.step === 7)
  ) {
    return resultData?.responsiblePersonsBooked?.[0]?.students?.every(
      (STdata) => {
        return !!STdata?.classes
          ?.filter((classesData) => checkBookingClassData(classesData))
          ?.every(
            (classesData) =>
              classesData?.form?.paymentType === PAYMENT_VALUE?.MAKE_CREDIT ||
              classesData?.info?.type === CLASS_TYPES?.ASSESSMENT_TRIAL
          );
      }
    );
  }

  return false;
};

export const checkIfAllEnrollmentDataHavePaymentTypeSelected = (
  resultData: DataSetFlowBookingClass
): boolean => {
  let isAllEnrollmentHavePaymentType = false;

  if (
    resultData?.flow === FLOW_ENUM?.CLASS_BOOKING &&
    (resultData?.step === 6 || resultData?.step === 7)
  ) {
    isAllEnrollmentHavePaymentType =
      resultData?.responsiblePersonsBooked?.[0]?.students?.every((STdata) => {
        return !!STdata?.classes
          ?.filter((classesData) => checkBookingClassData(classesData))
          ?.every((classesData) => classesData?.form?.paymentType);
      });

    if (onlyBookMakeupOrAssessmentClass(resultData)) {
      return onlyBookMakeupOrAssessmentClass(resultData);
    } else {
      return (
        isAllEnrollmentHavePaymentType &&
        resultData?.paymentMethod === PAYMENT_METHOD?.VIVA_PAY
      );
    }
  }

  return false;
};

export const checkBookingClassData = (
  studentEnrollment: CustomStudentEnrollment
) => {
  return (
    !!studentEnrollment?.form?.classBooked &&
    !!studentEnrollment?.form?.enrollmentType &&
    !!studentEnrollment?.form?.schedulesBooked?.length
  );
};

export const handleSumAllNextDirectDebitPayment = (
  data: DataSetFlowBookingClass
): number => {
  let newSumPayment = 0;

  if (
    data?.flow === FLOW_ENUM.CLASS_BOOKING &&
    (data?.step === 5 || data?.step === 6)
  ) {
    for (const STdata of data?.responsiblePersonsBooked?.[0]?.students) {
      for (const classesData of STdata?.classes) {
        if (classesData?.form?.paymentType === PAYMENT_VALUE?.DIRECT_DEBIT) {
          newSumPayment += classesData?.form?.billingCycle?.cycles?.[1]?.price;
        }
      }
    }
  }

  return newSumPayment;
};

export const handleSumAllFinalPayment = (
  data: DataSetFlowBookingClass
): number => {
  let newSumPayment = 0;

  if (
    data?.flow === FLOW_ENUM.CLASS_BOOKING &&
    (data?.step === 5 || data?.step === 6 || data?.step === 7)
  ) {
    for (const STdata of data?.responsiblePersonsBooked?.[0]?.students) {
      for (const classesData of STdata?.classes) {
        if (
          typeof classesData?.form?.payNow === 'number' &&
          classesData?.form?.paymentType !== PAYMENT_VALUE?.MAKE_CREDIT
        ) {
          newSumPayment += classesData?.form?.payNow;
        }
      }
    }
  }

  return newSumPayment;
};

export const handleSumAllMakeupPayment = (
  data: DataSetFlowBookingClass
): number => {
  let newSumPayment = 0;

  if (
    data?.flow === FLOW_ENUM.CLASS_BOOKING &&
    (data?.step === 5 || data?.step === 6 || data?.step === 7)
  ) {
    for (const STdata of data?.responsiblePersonsBooked?.[0]?.students) {
      for (const classesData of STdata?.classes) {
        if (
          typeof classesData?.form?.payNow === 'number' &&
          !!classesData?.form?.payNow &&
          classesData?.form?.paymentType === PAYMENT_VALUE?.MAKE_CREDIT
        ) {
          newSumPayment += classesData?.form?.payNow;
        }
      }
    }
  }

  return newSumPayment;
};

export const getBillingCycle = (
  studentEnrollment: CustomStudentEnrollment,
  nextDebitDay: string,
  subtractTerms: ITerm[],
  getForNearestDebitDay?: boolean
): IBillingCycle[] => {
  const listDebitDay: IBillingCycle[] = [];
  const sortedTerms = sortBy(
    studentEnrollment?.info.template.terms,
    'termDetail.startDate'
  );
  const currentTermId = studentEnrollment.schedules[0]?.termId;
  const currentTermIndex = sortedTerms.findIndex(
    (term) => term.termId === currentTermId
  );
  const currentTerm = sortedTerms.find((term) => term.termId === currentTermId);
  const endDateOfClass = dayjs(currentTerm?.termDetail?.endDate).format(
    'YYYY-MM-DD 23:59:59'
  );

  let endDate =
    studentEnrollment?.info?.recurrence?.type ===
    RECURRENCE_VALUES.DO_NOT_REPEAT
      ? studentEnrollment.schedules[0].startTime
      : endDateOfClass;
  const nextTerm = sortedTerms[currentTermIndex + 1];
  if (nextTerm) {
    endDate = dayjs(nextTerm.termDetail?.endDate).format('YYYY-MM-DD');
  }

  let debitDayTemp = dayjs(nextDebitDay).add(14, 'days');
  let previousDebitDay = dayjs(nextDebitDay);

  const customClassItem: IBookingData = {
    _id: studentEnrollment?.info?._id,
    classInfo: studentEnrollment?.info,
    schedulesUntilNextDD: studentEnrollment?.schedulesUntilNextDD,
    schedulesUntilNextDD2: studentEnrollment?.schedulesUntilNextDD2,
    schedules: studentEnrollment?.schedules?.map((schedule) => {
      if (
        !!studentEnrollment?.form?.schedulesBooked?.filter(
          (scheduleBooked) => scheduleBooked?._id === schedule?._id
        )?.length
      ) {
        return { ...schedule, checked: true };
      }

      return { ...schedule, checked: false };
    }),
    enrollmentType: studentEnrollment?.form?.enrollmentType
  } as IBookingData;
  const pricePayNowOriginal = handleGetPriceDirectDebit(
    subtractTerms,
    customClassItem,
    nextDebitDay,
    undefined,
    getForNearestDebitDay
  );
  listDebitDay.push({
    paymentDate: 'PAY_NOW',
    price: pricePayNowOriginal
  });
  let index = 0;

  const { voucher, automaticDiscount, form } = studentEnrollment;
  const { payNow } = form || {};
  let discountAmountLeft = 0;
  let discountAmountPercentage = 0;
  if (voucher?.code) {
    const { numberOfLesson = 0 } = voucher;
    if (voucher.discountType === DISCOUNT_TYPE.AMOUNT) {
      discountAmountLeft =
        voucher.discountValue - pricePayNowOriginal > 0
          ? roundByTwo(voucher.discountValue - pricePayNowOriginal)
          : 0;
    } else {
      if (voucher.discountType === DISCOUNT_TYPE.PERCENTAGE) {
        discountAmountPercentage = voucher.discountValue;
      } else if (voucher.discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
        if (voucher.duration === VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS) {
          const discountPrice = numberOfLesson * studentEnrollment.info.price;
          discountAmountLeft = roundByTwo(discountPrice - payNow);
        } else if (voucher.duration === VOUCHER_DURATION.TIME_RANGE) {
          // no end date
          if (!voucher.endDateFree) {
            if (!dayjs(voucher.startDateFree).isAfter(nextDebitDay)) {
              return [];
            }
          }
        }
      }
    }
  } else if (automaticDiscount) {
    discountAmountPercentage = automaticDiscount.value;
  }

  while (dayjs(debitDayTemp).isBefore(dayjs(endDate))) {
    let price = 0;
    if (index === 0) {
      price = handleGetPriceDirectDebit(
        subtractTerms,
        customClassItem,
        debitDayTemp.format(),
        previousDebitDay.format(),
        false,
        true
      );
    } else {
      price = handleGetPriceDirectDebit(
        subtractTerms,
        customClassItem,
        debitDayTemp.format(),
        previousDebitDay.format()
      );
    }
    index++;

    if (price > 0) {
      if (
        studentEnrollment.voucher?.discountType === DISCOUNT_TYPE.COMPLIMENTARY
      ) {
        if (
          studentEnrollment.voucher.duration ===
          VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS
        ) {
          if (discountAmountLeft > 0) {
            const result = roundByTwo(price - discountAmountLeft);
            discountAmountLeft = roundByTwo(discountAmountLeft - price);
            if (result > 0) {
              price = result;
            } else {
              price = 0;
            }
          }
        } else if (
          studentEnrollment.voucher.duration === VOUCHER_DURATION.TIME_RANGE
        ) {
          let endDateFree = studentEnrollment.voucher.endDateFree;
          const { startDateFree } = studentEnrollment.voucher;
          if (!endDateFree) {
            endDateFree = debitDayTemp.format(FORMAT_END_OF_DATE);
          }
          if (
            !(
              dayjs(startDateFree).isAfter(debitDayTemp) ||
              dayjs(endDateFree).isBefore(previousDebitDay)
            )
          ) {
            const startDate = previousDebitDay.isAfter(startDateFree)
              ? previousDebitDay.format('YYYY-MM-DD')
              : dayjs(startDateFree).format('YYYY-MM-DD');
            const endDate = debitDayTemp.isBefore(endDateFree)
              ? debitDayTemp.format('YYYY-MM-DD')
              : dayjs(endDateFree).format(FORMAT_END_OF_DATE);

            const numberOfLessonFree = getLessonsDayInRange(
              studentEnrollment.form.schedulesBooked,
              startDate,
              endDate,
              subtractTerms
            );
            const discountPrice =
              numberOfLessonFree.length * studentEnrollment.info.price;
            if (discountPrice < price) {
              price = roundByTwo(price - discountPrice);
            } else {
              price = 0;
            }
          }
        }
      } else if (discountAmountLeft > 0) {
        const result =
          price - discountAmountLeft > 0
            ? roundByTwo(price - discountAmountLeft)
            : 0;
        discountAmountLeft =
          discountAmountLeft - price > 0
            ? roundByTwo(discountAmountLeft - price)
            : 0;
        price = result;
      } else if (discountAmountPercentage > 0) {
        const discountAmount = roundByTwo(
          price * (discountAmountPercentage / 100)
        );
        price = roundByTwo(price - discountAmount);
      }
      if (price > 0) {
        listDebitDay.push({
          paymentDate: formatDate(previousDebitDay),
          price
        });
      }
    }
    previousDebitDay = dayjs(debitDayTemp);
    debitDayTemp = debitDayTemp.add(14, 'days');
  }

  if (dayjs(endDate).isAfter(dayjs(previousDebitDay))) {
    let price = 0;
    if (index === 0) {
      price = handleGetPriceDirectDebit(
        subtractTerms,
        customClassItem,
        debitDayTemp.format(),
        previousDebitDay.format(),
        false,
        true
      );
    } else {
      price = handleGetPriceDirectDebit(
        subtractTerms,
        customClassItem,
        debitDayTemp.format(),
        previousDebitDay.format()
      );
    }
    if (price > 0) {
      if (
        studentEnrollment.voucher?.discountType === DISCOUNT_TYPE.COMPLIMENTARY
      ) {
        if (
          studentEnrollment.voucher.duration ===
          VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS
        ) {
          if (discountAmountLeft > 0) {
            const result = roundByTwo(price - discountAmountLeft);
            discountAmountLeft = roundByTwo(discountAmountLeft - price);
            if (result > 0) {
              price = result;
            } else {
              price = 0;
            }
          }
        } else if (
          studentEnrollment.voucher.duration === VOUCHER_DURATION.TIME_RANGE
        ) {
          let endDateFree = studentEnrollment.voucher.endDateFree;
          const { startDateFree } = studentEnrollment.voucher;
          if (!endDateFree) {
            endDateFree = debitDayTemp.format(FORMAT_END_OF_DATE);
          }
          if (
            !(
              dayjs(startDateFree).isAfter(debitDayTemp) ||
              dayjs(endDateFree).isBefore(previousDebitDay)
            )
          ) {
            const startDate = previousDebitDay.isAfter(startDateFree)
              ? previousDebitDay.format('YYYY-MM-DD')
              : dayjs(startDateFree).format('YYYY-MM-DD');
            const endDate = debitDayTemp.isBefore(endDateFree)
              ? debitDayTemp.format('YYYY-MM-DD')
              : dayjs(endDateFree).format(FORMAT_END_OF_DATE);

            const numberOfLessonFree = getLessonsDayInRange(
              studentEnrollment.form.schedulesBooked,
              startDate,
              endDate,
              subtractTerms
            );
            const discountPrice =
              numberOfLessonFree.length * studentEnrollment.info.price;
            if (discountPrice < price) {
              price = roundByTwo(price - discountPrice);
            } else {
              price = 0;
            }
          }
        }
      } else if (discountAmountLeft > 0) {
        const result =
          price - discountAmountLeft > 0
            ? roundByTwo(price - discountAmountLeft)
            : 0;
        discountAmountLeft =
          discountAmountLeft - price > 0
            ? roundByTwo(discountAmountLeft - price)
            : 0;
        price = result;
      } else if (discountAmountPercentage > 0) {
        const discountAmount = roundByTwo(
          price * (discountAmountPercentage / 100)
        );
        price = roundByTwo(price - discountAmount);
      }
      if (price > 0) {
        listDebitDay.push({
          paymentDate: formatDate(previousDebitDay),
          price: price
        });
      }
    }
  }

  return listDebitDay;
};

export const handleCheckTotalSlotSelected = (
  data: DataSetFlowBookingClass
): number => {
  let newTotalSchedulesBooked = 0;

  if (
    data?.flow === FLOW_ENUM?.CLASS_BOOKING &&
    (data?.step === 4 || data?.step === 5) &&
    !!data?.responsiblePersonsBooked?.[0]?.students?.length
  ) {
    const listStudent = data?.responsiblePersonsBooked?.[0]?.students;

    const formFieldsByStudents = listStudent?.map(({ classes }) =>
      classes?.map(({ form }) => form)
    );

    for (const formFields of formFieldsByStudents) {
      for (const form of formFields) {
        if (
          !!form?.classBooked &&
          !!form?.enrollmentType &&
          !!form?.schedulesBooked?.length
        ) {
          newTotalSchedulesBooked += form?.schedulesBooked?.length;
        }
      }
    }
  }

  return newTotalSchedulesBooked;
};
export const getStudentClasses = (
  classes: CustomStudentEnrollment[],
  resetForm?: boolean
) => {
  if (classes.length === 1) return classes;
  let currentClassId = classes[0]?.info?._id;
  let classItem = classes[0] as CustomStudentEnrollment;
  let classIndex = 0;
  const result = classes.reduce(
    (result: CustomStudentEnrollment[], item) => {
      if (currentClassId === item?.info?._id) {
        let form = classItem?.form;
        if (resetForm) {
          form = {
            classBooked: false,
            enrollmentType: BOOKING_TYPE?.NONE,
            schedulesBooked: [],
            payNow: 0,
            totalPrice: 0,
            error: BOOKING_ERROR_TYPE?.NONE,
            paymentType: PAYMENT_VALUE?.IDLE
          };
        }
        classItem = {
          ...classItem,
          form: {
            ...form,
            schedulesBooked: uniqBy(
              [
                ...(classItem?.form?.schedulesBooked || []),
                ...(item?.form?.schedulesBooked || [])
              ],
              '_id'
            )
          }
        };
        result[classIndex] = classItem;
      } else {
        classIndex += 1;
        classItem = item;
        result = [...result, classItem];
        currentClassId = item?.info?._id;
      }
      return result;
    },
    [classes[0]]
  );
  return result;
};

export const handleClearSTform = (
  currentDateSet?: DataSetFlowBookingClass
): IResponsiblePersonBooked[] => {
  if (
    currentDateSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
    (currentDateSet?.step === 5 ||
      currentDateSet?.step === 6 ||
      currentDateSet?.step === 7)
  ) {
    if (currentDateSet?.step === 5) {
      return currentDateSet?.responsiblePersonsBooked?.map((RPdata) => {
        return {
          ...RPdata,
          students: RPdata?.students?.map((STdata) => {
            return {
              ...STdata,
              classes: getStudentClasses(STdata?.classes, true),
              voucher: undefined,
              form: {
                classBooked: false,
                enrollmentType: BOOKING_TYPE?.NONE,
                schedulesBooked: [],
                payNow: 0,
                totalPrice: 0,
                error: BOOKING_ERROR_TYPE?.NONE,
                paymentType: PAYMENT_VALUE?.IDLE
              },
              automaticDiscount: undefined
            };
          })
        };
      });
    }

    if (currentDateSet?.step === 6) {
      return currentDateSet?.responsiblePersonsBooked?.map((RPdata) => {
        return {
          ...RPdata,
          students: RPdata?.students?.map((STdata) => {
            return {
              ...STdata,
              classes: getStudentClasses(STdata?.classes),
              voucher: undefined,
              form: {
                classBooked: false,
                enrollmentType: BOOKING_TYPE?.NONE,
                schedulesBooked: [],
                payNow: 0,
                totalPrice: 0,
                error: BOOKING_ERROR_TYPE?.NONE,
                paymentType: PAYMENT_VALUE?.IDLE
              },
              automaticDiscount: undefined
            };
          })
        };
      });
    }

    return currentDateSet?.responsiblePersonsBooked?.map((RPdata) => {
      return {
        ...RPdata,
        students: RPdata?.students?.map((STdata) => {
          return {
            ...STdata,
            voucher: undefined,
            classes: getStudentClasses(STdata?.classes)
          };
        })
      };
    });
  } else if (
    currentDateSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
    currentDateSet?.step === 4
  ) {
    return currentDateSet?.responsiblePersonsBooked?.map((RPdata) => {
      return {
        ...RPdata,
        students: RPdata?.students?.map((STdata) => {
          return {
            ...STdata,
            voucher: undefined,
            classes: currentDateSet.classesBooked?.map((classData) => {
              return {
                ...classData,
                form: {
                  classBooked: false,
                  enrollmentType: BOOKING_TYPE?.NONE,
                  schedulesBooked: [],
                  totalPrice: 0,
                  payNow: 0,
                  error: BOOKING_ERROR_TYPE?.NONE,
                  paymentType: PAYMENT_VALUE?.IDLE
                }
              };
            })
          };
        })
      };
    });
  }
  return [];
};

export const checkIfAnyEnrollmentTypeBefore = (
  responsiblePersonsBooked: IResponsiblePersonBooked[],
  STuuid: string,
  classId: string
): boolean => {
  let isHavePreviousEnrollmentType = false;

  for (const RPdata of responsiblePersonsBooked) {
    for (const STdata of RPdata?.students) {
      if (STdata?.info?._id === STuuid) {
        for (const classesData of STdata?.classes) {
          if (classesData?.info?._id === classId) {
            if (classesData?.form?.enrollmentType) {
              isHavePreviousEnrollmentType =
                !!classesData?.form?.enrollmentType;

              break;
            }
          }
        }
      }
    }
  }

  return isHavePreviousEnrollmentType;
};

export const __isGoBackToStep5 = (data: DataSetFlowBookingClass): boolean => {
  if (
    data?.flow === FLOW_ENUM.CLASS_BOOKING &&
    data?.step === 7 &&
    data?.responsiblePersonsBooked?.[0]?.students?.length
  ) {
    // when all booking item is belongs to certain case below, therefore no select payment type => we can skip step 5
    return data?.responsiblePersonsBooked?.[0]?.students?.every((STdata) => {
      return !!STdata?.classes
        ?.filter((classesData) => checkBookingClassData(classesData))
        ?.every(
          (classesData) =>
            classesData?.form?.paymentType === PAYMENT_VALUE?.MAKE_CREDIT ||
            classesData?.form?.enrollmentType === BOOKING_TYPE?.CASUAL_CLASS ||
            classesData?.form?.enrollmentType === BOOKING_TYPE?.ONGOING_CLASS ||
            classesData?.info?.type === CLASS_TYPES.ASSESSMENT_TRIAL ||
            classesData?.info?.type === CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM
        );
    });
  }

  return false;
};
