import {
  IAppCardContentItemProps,
  IAppCardContentProps,
  IAppCardHeaderProps,
  IAppCardProps
} from './interfaces';

import './desktop.scss';

const AppCard = (props: IAppCardProps) => {
  return (
    <div
      {...props}
      className={`c__card ${props?.className ? props.className : ' '}`}
    >
      {props.children}
    </div>
  );
};
export default AppCard;

// ==============================

const AppCardHeader = (props: IAppCardHeaderProps) => {
  return (
    <div
      {...props}
      className={`c__card-header ${props?.className ? props.className : ' '}`}
    >
      <p className="c__card-header-title">{props.title}</p>
      {!!props.suffix && (
        <div className="c__card-header-suffix">{props.suffix}</div>
      )}
    </div>
  );
};
const AppCardContent = (props: IAppCardContentProps) => {
  return (
    <div
      {...props}
      className={`c__card-content ${props?.className ? props.className : ' '}`}
    >
      {props.children}
    </div>
  );
};
const AppCardContentItem = (props: IAppCardContentItemProps) => {
  const {
    subtitle = '',
    title = '',
    isColor = false,
    children,
    isHtml = false
  } = props;
  return (
    <div
      className={`c__card-content-item ${
        props?.className ? props.className : ' '
      }`}
    >
      <p className="c__card-content-item-sub">{subtitle}</p>
      {isColor ? (
        <div style={{ backgroundColor: title }} className="block-color" />
      ) : isHtml ? (
        <div className="c__card-content-item-title">{children}</div>
      ) : (
        <p className="c__card-content-item-title">{title ? title : children}</p>
      )}
    </div>
  );
};

export { AppCardHeader, AppCardContent, AppCardContentItem };
