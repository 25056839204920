import React, { memo, useEffect, useState } from 'react';
import AppInput from 'common/components/AppInput';
import './scss/colorSettings.scss';
import { ColorSettingProps } from './types';
import PickColor from './PickColor';
import { formatData } from 'common/helpers/dataFormat.helper';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { IColorSetting } from 'common/interfaces/dashboard.interface';
import {
  createColorSetting,
  resetColorSetting,
  updateColorSetting
} from 'services/dashboard.service';
import { useToast } from 'context/ToastContext';
import useDebounce from 'common/hooks/useDebounce';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';

const ColorSetting: React.FC<ColorSettingProps> = memo(
  ({ goBack, showSettings, colorSettings, onSuccess }: ColorSettingProps) => {
    const [showPickColor, setShowPickColor] = useState(false);
    const [levels, setLevels] = useState<IColorSetting[]>(colorSettings);
    const [selectedLevel, setSelectedLevel] = useState<IColorSetting>(
      colorSettings[0]
    );
    const [search, setSearch] = useState<string>('');
    const debouncedSearch = useDebounce<string>(search, 800);

    const toast = useToast();

    useEffect(() => {
      setLevels(
        colorSettings.filter((level) => {
          return (
            level.name
              .toLocaleUpperCase()
              .includes(search.toLocaleUpperCase()) ||
            level.shortName
              .toLocaleUpperCase()
              .includes(search.toLocaleUpperCase())
          );
        })
      );
      // eslint-disable-next-line
    }, [debouncedSearch]);

    useEffect(() => {
      if (colorSettings.length) {
        setLevels(colorSettings);
      } else {
        setLevels([]);
      }
    }, [colorSettings]);
    useEffect(() => {
      if (!showSettings) {
        return;
      }
    }, [showSettings]);

    const handlePickColor = (item: IColorSetting) => {
      setShowPickColor(true);
      setSelectedLevel(item);
    };
    const handleClose = () => {
      goBack();
      setShowPickColor(false);
    };
    const onChangeLevelColor = async (colorCode: string) => {
      if (!showPickColor) return;
      setShowPickColor(false);
      if (selectedLevel.userColorSetting?._id) {
        try {
          selectedLevel.userColorSetting.colorCode = colorCode;
          await updateColorSetting(selectedLevel.userColorSetting?._id, {
            levelId: selectedLevel._id,
            colorCode
          });
          onSuccess();
        } catch (error) {
          toast.error('Failed to update setting color');
        }
      } else {
        try {
          await createColorSetting({ levelId: selectedLevel._id, colorCode });
          onSuccess();
        } catch (error) {
          toast.error('Failed to setting color');
        }
      }
    };
    const handleReset = async () => {
      try {
        setShowPickColor(false);
        handleClose();
        await resetColorSetting();
        onSuccess();
      } catch (error) {
        toast.error('Failed to reset color');
      }
    };
    return (
      <div className={`settings-component ${showSettings && 'open'}`}>
        <div
          className="pick-color-container"
          style={{ display: showPickColor ? 'block' : 'none' }}
        >
          <PickColor
            onClose={() => setShowPickColor(false)}
            classColor={selectedLevel?.colorCode || ''}
            onChangeItemColor={(color: string) => onChangeLevelColor(color)}
            showPickColor={showPickColor}
          />
        </div>
        <div>
          <div
            className="settings-header"
            style={{
              paddingTop: '16px',
              paddingBottom: '16px',
              borderBottom: '1px solid #40404A'
            }}
          >
            <div className="settings-header" style={{ padding: 0 }}>
              <div onClick={handleClose}>
                <HiOutlineArrowLeft fontSize={22} />
              </div>
              <div className="settings-title">Colors Settings</div>
            </div>
            <PermissionWrapper permission={PERMISSION.DELETE_COLOR_SETTING}>
              <div className="reset-label" onClick={handleReset}>
                Reset
              </div>
            </PermissionWrapper>
          </div>
          <div className="colors-body">
            <AppInput
              inputSize="small"
              label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="color-setting-header">
              <div className="content">LEVEL</div>
              <div className="color-setting-item-right">COLOR</div>
            </div>
            <div className="color-setting-list">
              {levels.map((item: IColorSetting) => {
                return (
                  <div className="color-setting-item" key={item._id}>
                    <div
                      className="colorSettings-content"
                      style={{
                        // backgroundColor: convertHexToRgb(item.colorCode, 0.8),
                        borderTop: `3px solid ${item.colorCode}`
                      }}
                    >
                      <div className="content">
                        {formatData(item.shortName || item.name)}
                      </div>
                    </div>
                    <div className="color-setting-item-right">
                      <PermissionWrapper
                        permission={PERMISSION.UPDATE_COLOR_SETTING}
                      >
                        <div
                          className="color-box"
                          style={{
                            backgroundColor: item.colorCode
                          }}
                          onClick={() => handlePickColor(item)}
                        ></div>
                      </PermissionWrapper>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default ColorSetting;
