export const objectContainObj = (
  bigObj: {
    [key: string]: any;
  },
  smallObj: {
    [key: string]: any;
  }
) => {
  /*
  check If one big object contain a smaller object
  ex:
    a: { name: 'random name', lastName: 'random name'}
    b: { name: 'random name'}
  => true: obj a contain obj b
*/

  let isContain = true;

  // return Big object contains small object with the exact same value
  for (let [key, value] of Object.entries({ ...smallObj })) {
    if (bigObj[key] !== value) {
      isContain = false;
      return;
    }
  }

  return isContain;
};

export const arrayContainArray = (bigArr: any[], smallArr: any[]) => {
  /*
  check If item from big array of obj contain item from a smaller array of obj
  ex:
    a: [
      {
          "_id": "e870e65e-1ba9-43f3-8861-2b6baece427b",
          "phoneNumber": "+61343331231",
          "contactName": "Today RP 2",
          "contactSurname": "Today RP 2",
          "relationship": "ád"
      },
      {
          "_id": "8b665bb5-dda9-4257-8a52-88ec6926ced8",
          "phoneNumber": "+61333333333",
          "contactName": "a",
          "contactSurname": "a",
          "relationship": "ad"
      },
    ] 
    b: [
      {
          "phoneNumber": "+61343331231",
          "contactName": "Today RP 2",
          "contactSurname": "Today RP 2",
          "relationship": "ád"
      },
      {
          "phoneNumber": "+61333333333",
          "contactName": "a",
          "contactSurname": "a",
          "relationship": "ad"
      },
    ]
  => true: item from big array of obj is contain item from a smaller array of obj
*/

  let ArrayContain = true;

  if (bigArr?.length !== smallArr?.length) {
    ArrayContain = false;
  } else {
    for (const [index, contact] of bigArr.entries()) {
      if (!objectContainObj(contact, smallArr[index])) {
        ArrayContain = false;
        break;
      }
    }
  }

  return ArrayContain;
};

export const arrayToStringAsPayload = (name: string, arr: any[]) => {
  return name + '=' + arr.map((n) => `${n}`).join(',');
};

type Obj<T> = Record<string, T>;

export const mapObject = <T, U>(
  obj: Obj<T>,
  mapper: (key: string, value: T, index?: number) => U
): Obj<U> => {
  const newObj: Obj<U> = {};
  let index = 0;
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[key] = mapper(key, obj[key], index);
      index++;
    }
  }
  return newObj;
};
