import { object as OBJECT, string, array as ARRAY } from 'yup';

const TERM_NAME = string().required('Please provide Session name');
const TERM_START_DATE = string().required('Please provide Session start date');
const TERM_END_DATE = string().required('Please provide Session end date');
const TERM_TYPE = string().required('Please provide Session type');
const TERM_LOCATIONS = string().required('Please provide Session locations');

const validations = {
  ARRAY,
  OBJECT,
  TERM_NAME,
  TERM_START_DATE,
  TERM_END_DATE,
  TERM_TYPE,
  TERM_LOCATIONS
};

export default validations;
