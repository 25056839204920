import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import { IStaff } from 'common/interfaces/staff.interface';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import yupStaff from 'validators/staff.validator';
import {
  HiOutlinePencilSquare,
  HiOutlineXCircle,
  HiOutlineBookmark,
  HiOutlineExclamationCircle
} from 'react-icons/hi2';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { STAFF_TYPE_OPTIONS } from 'common/constants/index';
import { useParams } from 'react-router-dom';
import { useToast } from 'context/ToastContext';
import { BeatLoader } from 'react-spinners';
import { UpdateStaffAccessDTO } from 'DTOs/staff.dto';
import dayjs from 'dayjs';
import AppSelect from 'common/components/AppSelect';
import AppDatePicker from 'common/components/AppDatePicker';
import AppToolTip from 'common/components/AppToolTip';
import { useBrandLocation } from 'context/BrandLocationContext';
import { IOption } from 'common/interfaces';
import { getAllRole } from 'services/hierarchy.service';
import { IRole } from 'common/interfaces/hierarchy.interface';
import { updateStaffAccess } from 'services/staff.service';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';

const validationSchema = yupStaff.OBJECT({
  locationId: yupStaff.STAFF_LOCATION,
  staffType: yupStaff.STAFF_TYPE,
  roleId: yupStaff.STAFF_ROLE,
  joiningDate: yupStaff.STAFF_JOINING_DATE,
  endDate: yupStaff.STAFF_END_DATE
});

interface IStaffAccessProps {
  data: IStaff;
  onSuccess: () => void;
}

const StaffAccess = (props: IStaffAccessProps) => {
  const { data, onSuccess } = props;

  const params = useParams();
  const toast = useToast();
  const { locations: globalLocations } = useBrandLocation();

  const [loading, setLoading] = useState<boolean>(false);

  const [roles, setRoles] = useState<Array<IOption>>([]);

  const [staff, setStaff] = useState<UpdateStaffAccessDTO>({
    locationId: data.locationId,
    staffType: data.additionalInfo.staffType,
    roleId: data.additionalInfo.roleId,
    joiningDate: data.additionalInfo.joiningDate,
    endDate: data.additionalInfo.endDate
  });

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const endDateError = useRef<string>('');

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<UpdateStaffAccessDTO>({
    resolver: yupResolver(validationSchema) as Resolver<UpdateStaffAccessDTO>,
    defaultValues: staff
  });

  const onOpenEdit = () => {
    setIsEdit(true);
  };

  const onCloseEdit = () => {
    setIsEdit(false);
    setStaff({
      locationId: data.locationId,
      staffType: data.additionalInfo.staffType,
      roleId: data.additionalInfo.roleId,
      joiningDate: data.additionalInfo.joiningDate,
      endDate: data.additionalInfo.endDate
    });
    reset({ ...data });
    endDateError.current = '';
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStaff({
        ...staff,
        [event.target.name]: event.target.value
      });
      // @ts-ignore
      setValue(event.target.name, event.target.value);
      // @ts-ignore
      trigger(event.target.name);
    },
    [staff, setValue, trigger]
  );

  const handleChangeJoiningDate = useCallback(
    (value: dayjs.Dayjs | null) => {
      setStaff({
        ...staff,
        joiningDate: value?.format('YYYY-MM-DD') || ''
      });
      setValue('joiningDate', value?.format('YYYY-MM-DD') || '');
      trigger('joiningDate');
      if (!!staff.endDate) {
        endDateError.current =
          dayjs(value).isSame(staff.endDate) ||
          dayjs(value).isAfter(staff.endDate)
            ? 'End date must be greater than joining date'
            : '';
      }
    },
    [staff, setValue, trigger]
  );

  const handleChangeEndDate = useCallback(
    (value: dayjs.Dayjs | null) => {
      setStaff({
        ...staff,
        endDate: value?.format('YYYY-MM-DD') || ''
      });
      setValue('endDate', value?.format('YYYY-MM-DD') || '');
      trigger('endDate');
      if (!!value) {
        endDateError.current =
          dayjs(value).isSame(staff.joiningDate) ||
          dayjs(value).isBefore(staff.joiningDate)
            ? 'End date must be greater than joining date'
            : '';
      }
    },
    [staff, setValue, trigger]
  );

  const onSubmit = async (data: UpdateStaffAccessDTO) => {
    // Custom error
    if (!!endDateError.current) return;
    setLoading(true);
    try {
      const payload: UpdateStaffAccessDTO = {
        staffType: data.staffType,
        roleId: data.roleId,
        joiningDate: convertToUnixTime(data.joiningDate.toString()),
        endDate: convertToUnixTime(data?.endDate?.toString() || '')
      };

      if (!data?.endDate) delete payload.endDate;

      await updateStaffAccess(params?.id || '', payload);
      onSuccess();
      toast.success('Saved');
      setIsEdit(false);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to update staff access'
      );
    } finally {
      setLoading(false);
    }
  };

  const __renderIcons = useMemo((): React.ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSubmit)}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
    // eslint-disable-next-line
  }, [isEdit, onCloseEdit, loading]);

  const __renderContent = useMemo((): React.ReactNode => {
    if (isEdit) {
      return (
        <>
          <AppSelect
            label="Staff Type*"
            options={STAFF_TYPE_OPTIONS}
            {...register('staffType')}
            value={staff.staffType}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.staffType?.message || ''
            }}
          />
          <AppSelect
            label="Role*"
            options={roles}
            {...register('roleId')}
            value={staff.roleId}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.roleId?.message || ''
            }}
          />
          <AppSelect
            label="Home Club*"
            options={[...globalLocations].map((location) => {
              return {
                value: location._id,
                label: location.name
              };
            })}
            {...register('locationId')}
            value={staff?.locationId || ''}
            disabled
          />
          <AppDatePicker
            label="Joining date*"
            {...register('joiningDate')}
            value={dayjs(staff.joiningDate)}
            onChange={handleChangeJoiningDate}
            message={{
              type: 'error',
              text: errors?.joiningDate?.message || ''
            }}
            disablePast
          />
          <div className="editStaff__endDateWrapper">
            <AppDatePicker
              label="End date"
              {...register('endDate')}
              value={staff?.endDate ? dayjs(staff?.endDate) : null}
              onChange={handleChangeEndDate}
              message={{
                type: 'error',
                text: errors?.endDate?.message || endDateError.current || ''
              }}
              disablePast
            />
            <AppToolTip tooltipText={`This is the end of the contract`}>
              <HiOutlineExclamationCircle />
            </AppToolTip>
          </div>
        </>
      );
    }
    return (
      <>
        <AppCardContentItem
          subtitle="Staff Type"
          title={formatData(staff.staffType)}
        />
        <AppCardContentItem
          subtitle="Role"
          title={formatData(roles.find((e) => e.value === staff.roleId)?.label)}
        />
        <AppCardContentItem
          subtitle="Home CLub"
          title={formatData(
            globalLocations.find((e) => e._id === staff.locationId)?.name
          )}
        />
        <AppCardContentItem
          subtitle="Joining date"
          title={formatDate(staff?.joiningDate)}
        />
        <AppCardContentItem
          subtitle="End date"
          title={formatDate(staff?.endDate)}
        />
      </>
    );
  }, [
    isEdit,
    handleChange,
    handleChangeEndDate,
    handleChangeJoiningDate,
    register,
    errors,
    staff,
    globalLocations,
    roles
  ]);

  useEffect(() => {
    if (staff?.staffType) {
      // Reset role list when change the staff type
      setRoles([]);
      getAllRole(staff?.staffType)
        .then((result) => {
          const formattedRoles = result.data.data.map((role: IRole) => {
            return {
              label: role.name,
              value: role._id
            };
          });
          setRoles(formattedRoles);
        })
        .catch(() => {
          setRoles([]);
        });
    }
  }, [staff?.staffType]);

  return (
    <section className="staffAccess">
      <AppCard>
        <AppCardHeader
          title="Staff Access"
          suffix={
            <PermissionWrapper
              permission={[
                PERMISSION.UPDATE_STAFF,
                PERMISSION.UPDATE_STAFF_ACCESS
              ]}
            >
              {__renderIcons}
            </PermissionWrapper>
          }
        />
        <AppCardContent
          className={`cardContent ${isEdit ? 'cardContent-edit' : ''}`}
        >
          {__renderContent}
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default StaffAccess;
