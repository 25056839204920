import API from './API';
import {
  CreateLocationPinDto,
  FormUpdateLocationPinDto
} from 'DTOs/locationPin.dto';
import { ILocationPinTableFilter } from 'common/interfaces/locationPin.interface';

const LOCATION_PINS_ENDPOINT = `/location-pins`;

export const getLocationPins = (params: ILocationPinTableFilter) => {
  return API.get(`${LOCATION_PINS_ENDPOINT}`, params);
};

export const addLocationPins = async (payload: CreateLocationPinDto) => {
  const response = await API.post(`${LOCATION_PINS_ENDPOINT}`, {
    body: payload
  });
  return response.data;
};

export const getLocationPinsDetail = async (locationPinsId: string) => {
  const response = await API.get(`${LOCATION_PINS_ENDPOINT}/${locationPinsId}`);
  return response.data.data;
};

export const updateLocationPins = (
  locationPinsId: string,
  body: FormUpdateLocationPinDto
) => {
  return API.patch(`${LOCATION_PINS_ENDPOINT}/${locationPinsId}`, {
    body
  });
};
