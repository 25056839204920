import { HiOutlineEye, HiOutlineTrash } from 'react-icons/hi';

interface IUploadDocumentProps {
  document: string;
  onView: (document: string) => void;
  onRemove?: (document: string) => void;
}

const UploadDocument = (props: IUploadDocumentProps) => {
  const { document, onView, onRemove } = props;
  return (
    <div className="c__upload-documents-item">
      <div className="c__upload-documents-item-image">
        {document.includes('pdf') ? (
          <embed src={document || ''} />
        ) : (
          <img src={document || ''} alt="document" />
        )}
      </div>
      <div className="c__upload-documents-item-overlay">
        <div
          onClick={() => onView(document)}
          className="c__upload-documents-item-overlay-icon"
        >
          <HiOutlineEye size={22} />
        </div>
        {onRemove && (
          <div
            onClick={() => onRemove(document)}
            className="c__upload-documents-item-overlay-icon"
          >
            <HiOutlineTrash size={22} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadDocument;
