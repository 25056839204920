import { object as OBJECT, string, array } from 'yup';

const NOTIFICATION_TEMPLATE_NAME = string().required('Please provide name');
const NOTIFICATION_TEMPLATE_TYPES = string().required('Please provide types');
const NOTIFICATION_TEMPLATE_CONTENT = string().required(
  'Please provide content'
);
const NOTIFICATION_TEMPLATE_ATTACHMENT_FILES = array();
const NOTIFICATION_TEMPLATE_LOCATION_ID = string();

const validations = {
  OBJECT,
  NOTIFICATION_TEMPLATE_NAME,
  NOTIFICATION_TEMPLATE_TYPES,
  NOTIFICATION_TEMPLATE_CONTENT,
  NOTIFICATION_TEMPLATE_ATTACHMENT_FILES,
  NOTIFICATION_TEMPLATE_LOCATION_ID
};
export default validations;
