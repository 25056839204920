import React from 'react';
import './table.scss';
import { groupBy } from 'lodash';
import dayjs from 'dayjs';
import ClassDetail from './calendar/ClassDetail';
import { formatTime } from 'common/helpers/dataFormat.helper';
import Popup from 'reactjs-popup';

interface Props {
  classesData: any[];
}
const Table = ({ classesData }: Props) => {
  const groupData = groupBy(classesData, 'date');
  const handleClick = () => {};
  const renderClassName = (row: any) => {
    return `${
      dayjs(row.date).format('YYYY-MM-DD') ===
      dayjs(new Date()).format('YYYY-MM-DD')
        ? 'div-wrapper-today'
        : 'div-wrapper'
    }`;
  };
  return (
    <div className="table">
      <div className="div">
        <div className="head-instance">DATE</div>
        <div className="head-instance">TIME</div>
        <div className="head-instance">LEVEL</div>
        <div className="head-instance">INSTRUCTOR</div>
      </div>
      {Object.keys(groupData).map((key, idx) => {
        const rows = groupData[key];
        return (
          <div key={idx} className="row">
            {rows.map((row, index) => {
              const date = dayjs(key);
              return (
                <div key={row._id} onClick={() => handleClick()}>
                  <div onClick={() => handleClick()}></div>
                  <Popup
                    position={['top center', 'bottom center', 'center center']}
                    className="content-tooltip-calendar"
                    trigger={
                      <div className="tr">
                        <div className="td">
                          {index === 0 && (
                            <div className="element">
                              <div className={renderClassName(row)}>
                                <div className="text-wrapper">
                                  {date.get('date')}
                                </div>
                              </div>
                              <div className="text-wrapper-2">
                                {date.format('ddd, MMM D, YYYY')}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="td">
                          <div className="element-wrapper">
                            <div className="rectangle-2" />
                            <p className="text-wrapper-3">{`${formatTime(
                              row.startTime
                            )} - ${formatTime(row.endTime)}`}</p>
                          </div>
                        </div>
                        <div className="td">
                          <div className="text-wrapper-2">
                            {row.levelBreakdown.name}
                          </div>
                        </div>
                        <div className="td">
                          <div className="text-wrapper-2">
                            {row.class.instructor?.firstName}
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <ClassDetail
                      content={row}
                      level={row.levelBreakdown.name}
                    />
                  </Popup>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
export default Table;
