import { object as OBJECT, boolean, string, number } from 'yup';

const EMAIL = string()
  .email('Please provide correct email format')
  .required('Please provide email address');

const CLASS_TEMPLATE_ID = string().required('Please select class template ');
const CLASS_LEVEL = string().required('Please select class level ');
const CLASS_INSTRUCTOR = string().required('Please select Instructor ');
const CLASS_NAME = string().required('Please input class name ');
const CLASS_ACTIVE = boolean();
const CLASS_START_DATE = string().required('Please select start date');
const CLASS_START_TIME = string()
  .required('Please input start date')
  .matches(
    /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
    'Please enter a time in the format "H:MM AM/PM"'
  );
const CLASS_END_TIME = string();
const CLASS_TYPE = string().required('Please select program type');

//  ===== CLASS  =====

//  ===== END CLASS  =====

const validations = {
  OBJECT,
  EMAIL,
  CLASS_TEMPLATE_ID,
  CLASS_LEVEL,
  CLASS_INSTRUCTOR,
  CLASS_NAME,
  CLASS_ACTIVE,
  CLASS_START_DATE,
  CLASS_START_TIME,
  CLASS_END_TIME,
  CLASS_TYPE,
  string,
  number,
  boolean
};
export default validations;
