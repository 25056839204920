import React from 'react';
import AppInputSearch from 'common/components/AppInputSearch';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import { createColumnHelper } from '@tanstack/react-table';
import { formatData } from 'common/helpers/dataFormat.helper';
import AppTable from 'common/components/AppTable';
import { mockPeriodDetailedViewData } from '../constant';
import AppSelect from 'common/components/AppSelect';
import ReportPrintButton from '../components/ReportPrintButton';
import ReportExportButton from '../components/ReportExportButton';
import { IOption } from 'common/interfaces';

interface IReportPeriodDetailedViewProps {
  filterOptions: Array<IOption>;
}

const ReportPeriodDetailedView = (props: IReportPeriodDetailedViewProps) => {
  const { filterOptions } = props;

  const columnHelper = createColumnHelper<any>();

  const columns = [
    columnHelper.accessor('programType', {
      header: () => <span>Program Type</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('studentId', {
      header: () => <span>student ID</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('firstName', {
      header: () => <span>Student name</span>,
      cell: (info) => (
        <div>
          {`${formatData(info.row.original.lastName)}, ${formatData(
            info.getValue()
          )}`}
        </div>
      )
    }),
    columnHelper.accessor('rpName', {
      header: () => <span>RP Name</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('rpMobile', {
      header: () => <span>RP Mobile</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('firstLessonDate', {
      header: () => <span>First lesson date</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('paid', {
      header: () => <span>Paid ($)</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('owing', {
      header: () => <span>Owing ($)</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('classDetail', {
      header: () => <span>Class Detail</span>,
      cell: (info) => formatData(info.getValue())
    })
  ];

  const [pageIndex, setPageIndex] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  // eslint-disable-next-line
  const [pageTotal, setPageTotal] = React.useState<number>(-1);
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <section className="reportStudentBookingDetailedView">
      <div className="reportStudentBookingDetailedView__header">
        <p className="reportStudentBookingDetailedView__header--title">
          Detailed View
        </p>
        <div className="reportStudentBookingDetailedView__header--actions">
          <AppInputSearch
            startIcon={<HiMagnifyingGlass size={22} />}
            placeholder="Search"
          />
          <AppSelect
            inputSize="small"
            label="Filter by"
            placeholder="Select option"
            options={filterOptions}
            value=""
          />
          <ReportPrintButton />
          <ReportExportButton />
        </div>
      </div>

      <div className="reportStudentBookingDetailedView__table">
        <AppTable
          loading={loading}
          data={mockPeriodDetailedViewData}
          columns={columns}
          pagination={{
            index: pageIndex,
            size: pageSize,
            total: pageTotal
          }}
          onChangePage={(index: number, size: number) => {
            setPageIndex(index);
            setPageSize(size);
          }}
        />
      </div>
    </section>
  );
};

export default React.memo(ReportPeriodDetailedView);
