export enum USER_TYPE {
  STAFF = 'staff',
  INSTRUCTOR = 'instructor',
  STUDENT = 'student',
  RESPONSIBLE_PERSON = 'responsible_person',
  SUPER_ADMIN = 'super_admin'
}

export enum GENDER {
  IDLE = '',
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other'
}

export enum STAFF_TYPE {
  IDLE = '',
  STAFF = 'staff'
}
