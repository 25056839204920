import React, { memo } from 'react';
import './scss/index.scss';
import { formatDate } from 'common/helpers/dataFormat.helper';

interface Props {
  dates: Date[];
  handleSelectDate: (value: Date) => void;
  publicHolidayDateArray: string[];
}
const Header: React.FC<Props> = memo(
  ({ dates, handleSelectDate, publicHolidayDateArray }: Props) => {
    const today = new Date();
    const headers = [
      {
        date: dates[0],
        day: 'Monday'
      },
      {
        date: dates[1],
        day: 'Tuesday'
      },
      {
        date: dates[2],
        day: 'Wednesday'
      },
      {
        date: dates[3],
        day: 'Thursday'
      },
      {
        date: dates[4],
        day: 'Friday'
      },
      {
        date: dates[5],
        day: 'Saturday'
      },
      {
        date: dates[6],
        day: 'Sunday'
      }
    ];
    return (
      <div className="header-calendar">
        <div
          className="header-time"
          style={{
            borderTopLeftRadius: '15px'
          }}
        ></div>
        <div className="header-calendar-week">
          {headers.map((header, index) => {
            if (!header.day) {
              return (
                <div
                  key={index}
                  className="header-time"
                  style={{
                    borderTopRightRadius:
                      index === headers.length - 1 ? '15px' : ''
                  }}
                ></div>
              );
            } else
              return (
                <div
                  key={index}
                  className="header-item"
                  style={{
                    borderTopRightRadius:
                      index === headers.length - 1 ? '15px' : '',
                    backgroundColor: publicHolidayDateArray.includes(
                      formatDate(header.date)
                    )
                      ? 'rgba(3, 78, 162, 0.2)'
                      : ''
                  }}
                  onClick={() => handleSelectDate(header.date)}
                >
                  <div className="day-label">{header.day}</div>
                  <div
                    className={`date-label ${
                      today.getDate() === new Date(header.date).getDate() &&
                      today.getMonth() === new Date(header.date).getMonth()
                        ? 'today'
                        : ''
                    }`}
                  >
                    {new Date(header.date).getDate() || ''}
                  </div>
                  {publicHolidayDateArray.includes(formatDate(header.date)) && (
                    <div className="public-holiday">Public Holiday</div>
                  )}
                </div>
              );
          })}
        </div>
      </div>
    );
  }
);

export default Header;
