import React from 'react';
import MenuIcon from 'components/layout/MenuIcon';

const NotFound: React.FC = () => {
  return (
    <div className="notfound">
      <MenuIcon />
    </div>
  );
};

export default NotFound;
