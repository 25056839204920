import { memo } from 'react';

const NoNotificationSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="98"
      height="120"
      viewBox="0 0 98 120"
      fill="none"
    >
      <ellipse
        cx="49.5365"
        cy="48.8726"
        rx="46.9761"
        ry="46.9761"
        fill="#FAFAFB"
      />
      <g filter="url(#filter0_d_9823_17484)">
        <path
          d="M83.3318 79.1928L78.5716 71.2841C77.5185 69.5593 76.5917 66.236 76.5917 64.2168V56.3502C76.5917 41.0798 64.1648 28.6699 48.9154 28.6699C33.6239 28.712 21.1969 41.0798 21.1969 56.3502V64.1747C21.1969 66.194 20.2702 69.5173 19.2592 71.242L14.499 79.1507C12.6876 82.2216 12.2664 85.7132 13.4038 88.742C14.5411 91.813 17.1108 94.2529 20.4808 95.3466C25.0303 96.861 29.622 97.9548 34.2979 98.7541C34.7613 98.8382 35.2247 98.8803 35.688 98.9644C36.2778 99.0485 36.9097 99.1327 37.5416 99.2168C38.6368 99.3851 39.7321 99.5113 40.8695 99.5954C43.5234 99.8478 46.2194 99.974 48.9154 99.974C51.5693 99.974 54.2232 99.8478 56.835 99.5954C57.8038 99.5113 58.7727 99.4271 59.6995 99.3009C60.4577 99.2168 61.216 99.1327 61.9743 99.0065C62.4376 98.9644 62.901 98.8803 63.3644 98.7961C68.0824 98.0389 72.7583 96.861 77.3079 95.3466C80.5515 94.2529 83.0369 91.813 84.2164 88.7C85.3959 85.5449 85.0589 82.0954 83.3318 79.1928ZM51.9906 62.1134C51.9906 63.8803 50.5583 65.3105 48.789 65.3105C47.0198 65.3105 45.5875 63.8803 45.5875 62.1134V49.0726C45.5875 47.3057 47.0198 45.8755 48.789 45.8755C50.5583 45.8755 51.9906 47.3057 51.9906 49.0726V62.1134Z"
          fill="white"
        />
        <path
          d="M13.4038 88.742C14.5411 91.813 17.1108 94.2529 20.4808 95.3466C25.0303 96.861 29.622 97.9548 34.2979 98.7541C34.5296 98.7961 34.7613 98.8277 34.993 98.8592C35.2247 98.8908 35.4564 98.9223 35.688 98.9644L13.4038 88.742ZM37.6041 98.7207C36.9762 98.6371 36.3513 98.5539 35.768 98.4707C35.5286 98.4276 35.291 98.3952 35.0656 98.3645L35.0604 98.3638C34.827 98.332 34.6062 98.3019 34.3872 98.2621L34.3822 98.2612C29.7275 97.4656 25.1608 96.3775 20.6387 94.8722L20.6352 94.871C17.4022 93.8218 14.9539 91.4878 13.8726 88.5684L13.8718 88.5663C12.795 85.6987 13.1839 82.3655 14.9286 79.4065C14.929 79.4059 14.9293 79.4053 14.9297 79.4047L19.6876 71.4999L19.6876 71.4999L19.6905 71.4949C20.2263 70.581 20.7248 69.2734 21.0892 67.9517C21.4535 66.6304 21.6969 65.2467 21.6969 64.1747V56.3502C21.6969 41.358 33.8981 29.2116 48.9161 29.1699C63.8892 29.1703 76.0917 41.3563 76.0917 56.3502V64.2168C76.0917 65.2889 76.3353 66.673 76.7052 67.9956C77.075 69.3174 77.5848 70.627 78.1441 71.5434C78.1444 71.5438 78.1446 71.5442 78.1449 71.5447L82.9021 79.4484C82.9024 79.4489 82.9026 79.4493 82.9029 79.4498C84.5533 82.2244 84.8731 85.5147 83.7485 88.5238C82.6213 91.4977 80.2483 93.8275 77.1481 94.8728C72.6227 96.3791 67.9737 97.55 63.2852 98.3024L63.2851 98.3023L63.2751 98.3042C62.8078 98.389 62.3665 98.4688 61.9291 98.5085L61.9105 98.5102L61.8922 98.5133C61.1496 98.6369 60.4048 98.7196 59.6443 98.804L59.6443 98.8038L59.632 98.8055C58.7195 98.9298 57.763 99.0129 56.7917 99.0973L56.7869 99.0977C54.1914 99.3486 51.5536 99.474 48.9154 99.474C46.2349 99.474 43.5547 99.3485 40.9168 99.0976L40.9168 99.0975L40.9063 99.0968C39.7824 99.0136 38.7001 98.8889 37.6175 98.7226L37.6175 98.7225L37.6076 98.7212L37.6041 98.7207ZM48.789 65.8105C50.8338 65.8105 52.4906 64.157 52.4906 62.1134V49.0726C52.4906 47.0289 50.8338 45.3755 48.789 45.3755C46.7443 45.3755 45.0875 47.0289 45.0875 49.0726V62.1134C45.0875 64.1571 46.7443 65.8105 48.789 65.8105Z"
          stroke="#E7E7E7"
        />
      </g>
      <g filter="url(#filter1_d_9823_17484)">
        <path
          d="M60.7527 98.9522C58.9834 103.832 54.3075 107.324 48.8312 107.324C45.5033 107.324 42.2175 105.977 39.9006 103.58C38.5526 102.318 37.5416 100.635 36.9518 98.9102C37.4995 98.9943 38.0471 99.0364 38.6369 99.1205C39.6057 99.2467 40.6167 99.3729 41.6278 99.457C44.0289 99.6674 46.4722 99.7936 48.9154 99.7936C51.3166 99.7936 53.7177 99.6674 56.0767 99.457C56.9614 99.3729 57.846 99.3308 58.6885 99.2046C59.3625 99.1205 60.0365 99.0364 60.7527 98.9522Z"
          fill="white"
        />
        <path
          d="M40.2423 103.215C39.1597 102.201 38.2988 100.894 37.716 99.5114C37.7181 99.5117 37.7203 99.5119 37.7224 99.5122C37.7386 99.514 37.7548 99.5158 37.771 99.5177C38.0354 99.5475 38.2965 99.577 38.5662 99.6155L38.5662 99.6155L38.5723 99.6163C39.5415 99.7426 40.5621 99.87 41.5852 99.9552C43.9979 100.167 46.4555 100.294 48.9154 100.294C51.3339 100.294 53.7501 100.166 56.1212 99.9551L56.1241 99.9548C56.3945 99.9291 56.6727 99.9069 56.9541 99.8844C57.5561 99.8364 58.1723 99.7872 58.7565 99.7C59.16 99.6497 59.5628 99.5994 59.9744 99.5491C58.0819 103.832 53.7981 106.824 48.8312 106.824C45.6341 106.824 42.4796 105.529 40.2602 103.232L40.2515 103.223L40.2423 103.215Z"
          stroke="#E7E7E7"
        />
      </g>
      <rect
        x="43.5666"
        y="44.7852"
        width="10.2122"
        height="22.4668"
        rx="5.1061"
        fill="#C5D4E4"
      />
      <path
        d="M78.8633 37.7311C78.8633 42.5816 74.9256 46.5149 70.0667 46.5149C65.2078 46.5149 61.2701 42.5816 61.2701 37.7311C61.2701 32.8806 65.2078 28.9473 70.0667 28.9473C74.9256 28.9473 78.8633 32.8806 78.8633 37.7311Z"
        fill="#F1416C"
        stroke="#E7E7E7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.69972 24.1827C8.43021 24.1827 9.02106 24.7731 9.02106 25.5041C9.02106 26.2351 8.43021 26.8254 7.69972 26.8254C6.96873 26.8254 6.37789 26.2351 6.37789 25.5041C6.37789 24.7731 6.96873 24.1827 7.69972 24.1827ZM11.6638 24.1827C12.3947 24.1827 12.9851 24.7731 12.9851 25.5041C12.9851 26.2351 12.3947 26.8254 11.6638 26.8254C10.9328 26.8254 10.3424 26.2351 10.3424 25.5041C10.3424 24.7731 10.9328 24.1827 11.6638 24.1827ZM15.6278 24.1827C16.3588 24.1827 16.9491 24.7731 16.9491 25.5041C16.9491 26.2351 16.3588 26.8254 15.6278 26.8254C14.8968 26.8254 14.3064 26.2351 14.3064 25.5041C14.3064 24.7731 14.8968 24.1827 15.6278 24.1827ZM6.22114 32.7721H12.5271C13.1218 32.7721 13.1218 32.7765 13.4065 32.9869C13.6917 33.1974 16.9457 35.4148 16.9457 35.4148V32.7721H17.098C18.8403 32.7721 20.2486 31.459 20.2486 29.8237V21.1974C20.2486 19.5621 18.8369 18.2363 17.098 18.2363H6.22114C4.48278 18.2363 3.07062 19.5621 3.07062 21.1974V29.8237C3.07062 31.459 4.48278 32.7721 6.22114 32.7721Z"
        fill="#FFC107"
      />
      <path
        d="M73.8875 7.33174L71.9196 7.27148"
        stroke="#D2D8DF"
        strokeLinecap="round"
      />
      <path
        d="M72.4757 3.59961L71.0644 4.918"
        stroke="#D2D8DF"
        strokeLinecap="round"
      />
      <path
        d="M68.7974 1.89648L68.7678 3.82046"
        stroke="#D2D8DF"
        strokeLinecap="round"
      />
      <path
        d="M65.0062 3.2168L66.3766 4.61995"
        stroke="#D2D8DF"
        strokeLinecap="round"
      />
      <path
        d="M63.3232 6.79102L65.2901 6.85127"
        stroke="#D2D8DF"
        strokeLinecap="round"
      />
      <path
        d="M64.7344 10.5235L66.1467 9.20508"
        stroke="#D2D8DF"
        strokeLinecap="round"
      />
      <path
        d="M68.4133 12.2248L68.4429 10.3008"
        stroke="#D2D8DF"
        strokeLinecap="round"
      />
      <path
        d="M72.2045 10.9051L70.834 9.50195"
        stroke="#D2D8DF"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_d_9823_17484"
          x="0.772827"
          y="24.6699"
          width="88.1393"
          height="87.3047"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9823_17484"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9823_17484"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_9823_17484"
          x="24.9518"
          y="94.9102"
          width="39.8008"
          height="24.4141"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9823_17484"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9823_17484"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default memo(NoNotificationSVG);
