import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppComingSoon from 'components/common/AppComingSoon';
import { memo } from 'react';

const ComingSoonPage = () => {
  return (
    <>
      <div style={{ width: '100%', minHeight: '100vh' }}>
        <AppBreadCrumb items={[{ name: 'Coming Soon', href: '/' }]} />

        <div style={{ paddingTop: '50px' }}>
          <AppComingSoon />
        </div>
      </div>
    </>
  );
};

export default memo(ComingSoonPage);
