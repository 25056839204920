import { Suspense, lazy, memo, useEffect, useMemo, useState } from 'react';
import AppSubTabs from 'common/components/AppSubTabs';
import { useSearchParams } from 'react-router-dom';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { useAuth } from 'context/AuthContext';
import { PERMISSION } from 'common/enums/permission.enum';
import { TAB } from 'common/constants';

import './desktop.scss';

const TabStudentList = lazy(() => import('./components/TabStudentList'));
const TabHistory = lazy(() => import('./components/TabHistory'));




const BulkManagementCreditList = () => {
  const { hasPermission } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState<number>(TAB.IDLE);

  const tabs = useMemo(() => {
    return [
      {
        name: 'STUDENT LIST',
        component: <TabStudentList />,
        hidden: !hasPermission(PERMISSION.LIST_STUDENT)
      },
      {
        name: 'HISTORY',
        component: <TabHistory />,
        hidden: !hasPermission(PERMISSION.LIST_BULK_CREDIT_MANAGEMENT)
      }
    ];
  }, [hasPermission]);

  const handleSelectedTab = (val: number) => {
    setTabIndex(val);

    searchParams.set('indexRoute', `${val}`);
    setSearchParams(searchParams);
  };

  const indexRoute = Number(searchParams.get('indexRoute'));

  useEffect(() => {
    if (indexRoute >= 0 && indexRoute <= tabs.length) {
      setTabIndex(indexRoute);
    } else {
      setTabIndex(TAB.INIT);

      searchParams.set('indexRoute', `${TAB.INIT}`);
      setSearchParams(searchParams, { replace: true });
    }
  }, [indexRoute, searchParams, setSearchParams, tabs]);

  return (
    <section className="bulkManagementCreditList">
      <AppSubTabs
        tabs={tabs}
        activeTabIndex={tabIndex}
        onChangeTab={handleSelectedTab}
      />
      {tabIndex >= 0 ? (
        <Suspense fallback={<AppLoadingContainer />}>
          {tabs[tabIndex].component}
        </Suspense>
      ) : null}
    </section>
  );
};

export default memo(BulkManagementCreditList);
