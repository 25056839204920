import {
  useEffect,
  useState,
  useCallback,
  useMemo,
  ReactNode,
  memo
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useToast } from 'context/ToastContext';
import {
  getLocationPinsDetail,
  updateLocationPins
} from 'services/locationPins.service';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import { formatData } from 'common/helpers/dataFormat.helper';
import AppInput from 'common/components/AppInput';
import { FormUpdateLocationPinDto } from 'DTOs/locationPin.dto';
import yup from 'validators/locationPin.validator';
import { Resolver, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ILocationPin } from 'common/interfaces/locationPin.interface';
import { BeatLoader } from 'react-spinners';
import { HiOutlineBookmark, HiOutlineXCircle } from 'react-icons/hi';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppTextArea from 'common/components/AppTextArea';
import { TEMP_SITE_PATH } from 'common/constants';
import { objectContainObj } from 'common/helpers/object.helper';

import './desktop.scss';

interface Props {
  onUpdated?: () => void;
}

const validationSchema = yup.OBJECT({
  doorName: yup.LOCATION_PIN_STRING_REQUIRED,
  pinCode: yup.LOCATION_PIN_STRING_REQUIRED
});

const LocationPinDetail = ({ onUpdated }: Props) => {
  const { id } = useParams();
  const toast = useToast();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ILocationPin | null>(null);

  const [isEdit, setIsEdit] = useState(false);

  const onOpenEdit = () => {
    setIsEdit(true);
  };

  const onCloseEdit = () => {
    setIsEdit(false);
  };

  const {
    register,
    setValue,
    clearErrors,
    control,
    trigger,
    reset,
    formState: { errors },
    handleSubmit
  } = useForm<FormUpdateLocationPinDto>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<FormUpdateLocationPinDto>,
    defaultValues: {
      doorName: data?.doorName,
      pinCode: data?.pinCode,
      instruction: data?.instruction
    }
  });

  const watchAllFields = useWatch({ control });

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (watchAllFields?.doorName && watchAllFields?.pinCode) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [errors, watchAllFields]);

  const handleInitValue = useCallback(() => {
    clearErrors();

    reset({
      doorName: data?.doorName,
      pinCode: data?.pinCode,
      instruction: data?.instruction
    });
  }, [reset, data, clearErrors]);

  useEffect(() => {
    if (!data?._id) return;

    handleInitValue();
  }, [data, handleInitValue]);

  useEffect(() => {
    if (isEdit) return;

    handleInitValue();
  }, [isEdit, handleInitValue]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      switch (event.target.name) {
        default:
          // @ts-ignore
          setValue(event.target.name, event.target.value);

          // @ts-ignore
          trigger(event.target.name);
          break;
      }
    },
    [setValue, trigger]
  );

  const onSubmit = useCallback(async () => {
    try {
      const formData = { ...watchAllFields };

      if (!id || disabled || !formData) {
        trigger();
        return;
      }

      const mapInitData = {
        doorName: data?.doorName,
        pinCode: data?.pinCode,
        instruction: data?.instruction
      };

      const mapData = {
        doorName: formData?.doorName,
        pinCode: formData?.pinCode,
        instruction: formData?.instruction
      };

      if (objectContainObj(mapInitData, mapData)) {
        setDisabled(false);
        setLoading(false);
        onCloseEdit();

        return;
      }

      setLoading(true);
      setDisabled(true);

      const payload: FormUpdateLocationPinDto = {
        doorName: formData?.doorName || '',
        pinCode: formData?.pinCode || '',
        instruction: formData?.instruction || ''
      };

      await updateLocationPins(id, payload);
      await fetchData();

      toast.success('Update location pins successfully');
    } finally {
      setDisabled(false);
      setLoading(false);
      onCloseEdit();
    }

    // eslint-disable-next-line
  }, [disabled, watchAllFields, id, trigger, data]);

  const __renderIcons = useMemo((): ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSubmit)}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
  }, [isEdit, onSubmit, loading, handleSubmit]);

  const __renderContent = useCallback((): ReactNode => {
    if (isEdit) {
      return (
        <>
          <div className="item padY2">
            <AppInput
              {...register('doorName')}
              label="Door Name*"
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.doorName?.message || ''
              }}
            />
          </div>
          <div className="item padY2">
            <AppInput
              {...register('pinCode')}
              label="PIN Code*"
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.pinCode?.message || ''
              }}
            />
          </div>
          <div className="item_custom padY2">
            <AppTextArea
              {...register('instruction')}
              label="Instruction"
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.instruction?.message || ''
              }}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div className="item padY">
          <AppCardContentItem
            subtitle="Door Name"
            title={formatData(data?.doorName)}
          />
        </div>
        <div className="item padY">
          <AppCardContentItem
            subtitle="PIN Code"
            title={formatData(data?.pinCode)}
          />
        </div>
        <div
          className="item_custom"
          style={{
            pointerEvents: 'none'
          }}
        >
          <AppTextArea
            label="Instruction"
            value={formatData(data?.instruction)}
          />
        </div>
      </>
    );
  }, [isEdit, data, errors, handleChange, register]);

  const fetchData = useCallback(async () => {
    try {
      if (!id) return;

      setLoading(true);

      const result = await getLocationPinsDetail(id);

      setData(result);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fetch location pins failed'
      );

      setData(null);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="locationPinDetails">
      <AppBreadCrumb
        items={[
          {
            name: 'PIN Codes',
            href: localStorage?.getItem(TEMP_SITE_PATH) || '/locations'
          },
          {
            name: 'PIN code details',
            href: location?.pathname + location?.search
          }
        ]}
      />

      <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_LOCATION_PIN}>
        <section className="areaInformation layoutContainer">
          <AppCard>
            <AppCardContent className="card-content">
              {loading ? <div className="loadData"/> : null}

              <div className="areaInformation-main-content">
                <AppCardHeader
                  title="Information"
                  suffix={
                    <PermissionWrapper
                      permission={PERMISSION.UPDATE_LOCATION_PIN}
                    >
                      {__renderIcons}
                    </PermissionWrapper>
                  }
                />

                <div className="content">{__renderContent()}</div>
              </div>
            </AppCardContent>
          </AppCard>
        </section>
      </PermissionWrapper>
    </div>
  );
};

export default memo(LocationPinDetail);
