import { useState, useRef, useCallback, memo } from 'react';
import PermissionWrapper from 'components/PermissionWrapper';
import ResponsiblePersonList, {
  RefHandle
} from 'components/ResponsiblePerson/ResponsiblePersonList';
import { PERMISSION } from 'common/enums/permission.enum';
import AddResponsiblePerson from 'components/ResponsiblePerson/AddResponsiblePerson';

const IndexPage = () => {
  const ref = useRef<RefHandle>(null);

  const [openAdd, setOpenAdd] = useState(false);

  const onOpenAdd = useCallback(() => setOpenAdd(true), []);

  const onCloseAdd = useCallback(() => {
    setOpenAdd(false);

    ref.current?.refetchReset();
  }, []);

  const onAddSuccess = useCallback(() => {
    onCloseAdd();
  }, [onCloseAdd]);

  return (
    <>
      {/* ADD */}

      <PermissionWrapper permission={PERMISSION.CREATE_RESPONSIBLE_PERSON}>
        <AddResponsiblePerson
          openAdd={openAdd}
          onCloseAdd={onCloseAdd}
          onSuccess={onAddSuccess}
        />
      </PermissionWrapper>

      <PermissionWrapper permission={PERMISSION.LIST_RESPONSIBLE_PERSON}>
        <ResponsiblePersonList ref={ref} onOpenAdd={onOpenAdd} />
      </PermissionWrapper>
    </>
  );
};

export default memo(IndexPage);
