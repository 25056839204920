import React, { useRef, useState } from 'react';
import { HiMiniEllipsisVertical } from 'react-icons/hi2';
import { ILog } from 'common/interfaces/log.interface';

interface IViewDetailPopperProps {
  log: ILog;
  onClick: (log: ILog) => void;
}

const ViewDetailPopper = (props: IViewDetailPopperProps) => {
  const { log, onClick } = props;

  const popperRef = useRef<any>(null);
  const verticalButtonRef = useRef<any>(null);

  const [open, setOpen] = useState<boolean>(false);

  const onToggle = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // Close popper if click outside the popper
    const handleClickOutside = (event: MouseEvent) => {
      // Click at vertical button to close
      if (
        verticalButtonRef.current &&
        verticalButtonRef.current.contains(event.target)
      ) {
        return;
      }
      // Handle click outside to close pop up
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        onClose();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [verticalButtonRef, popperRef]);

  return (
    <React.Fragment>
      <div ref={verticalButtonRef} className="ellipsisVertical">
        <HiMiniEllipsisVertical onClick={onToggle} />
        {open && (
          <div
            ref={popperRef}
            className="viewDetailPopup"
            onClick={() => {
              onClick(log);
              onClose();
            }}
          >
            View Detail
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ViewDetailPopper;
