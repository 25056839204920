import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AppSelect from 'common/components/AppSelect';
import './desktop.scss';
import { createColumnHelper } from '@tanstack/react-table';
import { IClass } from 'common/interfaces/class.interface';
import { formatData, formatTime } from 'common/helpers/dataFormat.helper';
import { HiOutlineAcademicCap } from 'react-icons/hi';
import AppTable from 'common/components/AppTable';
import AppCheckbox from 'common/components/AppCheckbox';
import dayjs from 'dayjs';
import { every, some } from 'lodash';
import {
  BOOKING_ERROR_TYPE,
  BOOKING_TYPE
} from 'common/enums/classBooking.enum';
import {
  ISchedule,
  ISchedulesOrigin
} from 'common/interfaces/schedules.interface';
import { BOOKING_OPTIONS } from 'common/constants/classBooking.constant';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { useMoveBooking } from 'context/MoveBookingContext';

type HolidaySchedule = {
  dateLabel: string;
  schedules: ISchedule[];
};

interface Props {
  classData: ISchedulesOrigin;
  studentId: string;
}
const ClassBookingSection: React.FC<Props> = ({
  classData,
  studentId
}: Props) => {
  const { studentDataBooking: student, onChangeStudentDataBooking } =
    useMoveBooking();
  const classInStudentData = useMemo(
    () => student?.classesData?.find((item) => item._id === classData._id),
    [student?.classesData, classData._id]
  );

  const [errorMessage, setErrorMessage] = useState<string>('');
  const columnHelper = createColumnHelper<IClass>();
  const [arraySchedules, setArraySchedules] = useState<HolidaySchedule[]>([]);

  const ENROLLMENT_TYPE_OPTIONS = useMemo(() => {
    const options = BOOKING_OPTIONS.filter(
      (item) =>
        item.value !== BOOKING_TYPE.MAKE_UP &&
        item.value !== BOOKING_TYPE.HOLIDAY_PROGRAM &&
        item.value !== BOOKING_TYPE.CASUAL_CLASS
    );
    return options;
  }, []);

  const getDatesInWeek = useCallback((startDate: string) => {
    const firstDay = new Date(startDate);
    firstDay.setHours(0, 0, 0, 0);
    const dayOfWeek = firstDay.getDay();
    const startDay =
      firstDay.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    firstDay.setDate(startDay);

    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(firstDay);
      days.push(day);
      firstDay.setDate(firstDay.getDate() + 1);
    }

    return [days[0], days[6]];
  }, []);

  useEffect(() => {
    if (
      [
        CLASS_TYPES.ASSESSMENT_TRIAL,
        CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM
      ].includes(classData.classInfo.type)
    ) {
      let startDate = dayjs(
        dayjs(classData?.schedules[0].startTime).format('YYYY-MM-DD')
      );
      const arraySchedules: HolidaySchedule[] = [];
      let lastDate = startDate;
      const terms = classData.classInfo.template.terms;
      const termActive = terms.find(
        (item) => item.termId === classData.classInfo.template.termIsActive
      );
      while (
        startDate.isBefore(
          dayjs(classData.schedules[classData.schedules.length - 1]?.startTime)
        )
      ) {
        const days = getDatesInWeek(startDate.format('YYYY-MM-DD'));
        classData.schedules.forEach((schedule) => {
          const dateFrom = dayjs(days[0]);
          const dateTo = dayjs(days[1]);
          if (
            (dayjs(schedule.startTime).isAfter(dateFrom) ||
              dayjs(schedule.startTime).isSame(dateFrom)) &&
            dayjs(schedule.startTime).isBefore(dayjs(dateTo.add(1, 'days')))
          ) {
            const dateLabel = `${dateFrom.format('MMM D')} - ${dateTo.format(
              'MMM D, YYYY'
            )}`;
            const indexOfItem = arraySchedules.findIndex((item) => {
              return item.dateLabel === dateLabel;
            });
            const index =
              indexOfItem > -1 ? indexOfItem : arraySchedules.length;
            arraySchedules[index] = {
              dateLabel,
              schedules: arraySchedules[index]?.schedules
                ? [...arraySchedules[index].schedules, schedule]
                : [schedule]
            };
          }
        });
        startDate = startDate.add(7, 'day');
        lastDate = dayjs(days[1]);
      }
      if (lastDate.isBefore(dayjs(termActive?.termDetail?.endDate))) {
        const days = getDatesInWeek(startDate.format('YYYY-MM-DD'));
        classData.schedules.forEach((schedule) => {
          const dateFrom = dayjs(days[0]);
          const dateTo = dayjs(days[1]);
          if (
            (dayjs(schedule.startTime).isAfter(dateFrom) ||
              dayjs(schedule.startTime).isSame(dateFrom)) &&
            dayjs(schedule.startTime).isBefore(dayjs(dateTo.add(1, 'days')))
          ) {
            const dateLabel = `${dateFrom.format('MMM D')} - ${dateTo.format(
              'MMM D, YYYY'
            )}`;
            const indexOfItem = arraySchedules.findIndex((item) => {
              return item.dateLabel === dateLabel;
            });
            const index =
              indexOfItem > -1 ? indexOfItem : arraySchedules.length;
            arraySchedules[index] = {
              dateLabel,
              schedules: arraySchedules[index]?.schedules
                ? [...arraySchedules[index].schedules, schedule]
                : [schedule]
            };
          }
        });
        startDate = startDate.add(7, 'day');
        lastDate = dayjs(days[1]);
      }
      setArraySchedules(arraySchedules);
    }
  }, [classData, getDatesInWeek]);

  const handleSelectSchedule = useCallback(
    (scheduleId: string) => {
      student &&
        onChangeStudentDataBooking({
          ...student,
          classesData: student.classesData.map((classItem) => {
            if (classItem._id === classData._id) {
              return {
                ...classItem,
                schedules: classItem.schedules.map((schedule) => {
                  if (schedule._id === scheduleId) {
                    return {
                      ...schedule,
                      checked: !schedule.checked
                    };
                  }
                  return {
                    ...schedule,
                    checked: false
                  };
                })
              };
            }
            return classItem;
          })
        });
    },
    [onChangeStudentDataBooking, student, classData]
  );

  const handleSelectAllScheduleItem = useCallback(
    (scheduleItem: HolidaySchedule) => {
      student &&
        onChangeStudentDataBooking({
          ...student,
          classesData: student.classesData.map((classItem) => {
            if (classItem._id === classData._id) {
              const classItemCheckedIds = classItem?.schedules
                .filter((item) => item.checked)
                .map((item) => item._id);
              const scheduleItemIds = scheduleItem?.schedules.map(
                (item) => item._id
              );
              const isAllChecked = every(scheduleItemIds, (item) =>
                classItemCheckedIds.includes(item)
              );
              return {
                ...classItem,
                schedules: classItem.schedules.map((schedule, index) => {
                  if (scheduleItemIds.includes(schedule._id)) {
                    return {
                      ...schedule,
                      checked: !isAllChecked
                    };
                  }

                  return {
                    ...schedule,
                    checked: false
                  };
                })
              };
            }
            return classItem;
          })
        });
    },
    [onChangeStudentDataBooking, student, classData]
  );

  const getColumns = (dateLabel?: string): any[] => {
    const scheduleItem = arraySchedules.find((item) => {
      return item.dateLabel === dateLabel;
    });
    return [
      columnHelper.accessor('checked', {
        header: () => {
          if (scheduleItem) {
            const checkedScheduleIds = classInStudentData?.schedules
              .filter((item) => item.checked)
              .map((item) => item._id);
            const scheduleItemIds = scheduleItem.schedules.map(
              (item) => item._id
            );
            if (
              some(
                scheduleItem.schedules,
                (item) => item.capacity - item.occupied === 0
              ) &&
              !every(scheduleItemIds, (item) =>
                checkedScheduleIds?.includes(item)
              )
            ) {
              return null;
            }
            return (
              <AppCheckbox
                onChange={() => handleSelectAllScheduleItem(scheduleItem)}
                checked={every(scheduleItemIds, (item) =>
                  checkedScheduleIds?.includes(item)
                )}
              />
            );
          }
        },
        cell: (info) => {
          const checked =
            classInStudentData?.schedules.find(
              (item) => item._id === info.row.original._id
            )?.checked || false;
          const available =
            info.row.original.capacity - (info.row.original.occupied || 0);
          if (
            (available > 0 || (checked && available === 0)) &&
            classData.classInfo.type !== CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM
          ) {
            return (
              <AppCheckbox
                onChange={() => handleSelectSchedule(info.row.original._id)}
                checked={checked}
              />
            );
          }
          return;
        }
      }),
      columnHelper.accessor('startTime', {
        header: () => <span>DATE, TIME START</span>,
        cell: (info) => {
          const item = info.row.original;
          return (
            <div data-tooltip-id={`content-tooltip-${item._id}`}>
              <div className="content-cell">{`${dayjs(item.startTime).format(
                'ddd, MMM D,'
              )} ${formatTime(item.startTime)} - ${formatTime(
                item.endTime
              )}`}</div>
            </div>
          );
        },
        size: 250
      }),
      columnHelper.accessor('name', {
        header: () => <span>CLASS</span>,
        cell: (info) => formatData(classData.classInfo.name)
      }),
      columnHelper.accessor('levelBreakdown', {
        header: () => <span>Level</span>,
        cell: (info) =>
          formatData(classData.classInfo?.levelBreakdown?.name || 'All Levels')
      }),
      columnHelper.accessor('instructor', {
        header: () => <span>INSTRUCTOR</span>,
        cell: (info) => formatData(classData.classInfo.instructor.firstName)
      }),
      columnHelper.accessor('area', {
        header: () => <span>AREA</span>,
        cell: (info) =>
          formatData(info.row.original.session?.area || info.getValue()),
        size: 50
      }),
      columnHelper.accessor('capacity', {
        header: () => <span>CAPACITY</span>,
        cell: (info) => {
          const capacity = info.row.original.capacity;
          const occupied = info.row.original.occupied || 0;
          return (
            <div style={{ cursor: 'pointer' }} className="content-cell">
              <HiOutlineAcademicCap fontSize={20} />
              <span style={{ whiteSpace: 'nowrap', marginLeft: '4px' }}>
                {formatData(occupied)}/{formatData(info.getValue())} (
                {formatData(capacity - occupied)} left)
              </span>
            </div>
          );
        }
      })
    ];
  };

  const handleSelectEnrollmentType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const enrollmentType = e.target.value as BOOKING_TYPE;
      if (
        classData.schedules.length === 1 &&
        classData.schedules[0].capacity - classData.schedules[0].occupied ===
          0 &&
        enrollmentType !== BOOKING_TYPE.NONE
      ) {
        setErrorMessage(BOOKING_ERROR_TYPE.NO_SLOT);
        return;
      } else {
        setErrorMessage('');
      }
      student &&
        onChangeStudentDataBooking({
          ...student,
          classesData: student.classesData.map((classItem) => {
            if (classItem._id === classData._id) {
              if (classItem.schedules.length === 1) {
                classItem.schedules[0].checked = true;
              }
              return {
                ...classItem,
                enrollmentType
              };
            }
            return classItem;
          })
        });
    },
    [onChangeStudentDataBooking, student, classData]
  );
  const handleCheckClass = useCallback(() => {
    if (student) {
      const newStudent = {
        ...student,
        classesData: student?.classesData?.map((classItem) => {
          if (classItem._id === classData._id) {
            const schedules = classItem.schedules;
            if (schedules.length === 1) {
              schedules[0].checked = !schedules[0].checked;
            }
            return {
              ...classData,
              checked: !classItem.checked,
              schedules
            };
          }
          return classItem;
        })
      };
      onChangeStudentDataBooking(newStudent);
    }
  }, [onChangeStudentDataBooking, student, classData]);

  const isChecked = useCallback(() => {
    const classesId =
      student?.classesData
        ?.filter((item) => item.checked)
        .map((item) => item._id) || [];
    return classesId.includes(classData._id);
  }, [classData, student?.classesData]);

  const renderTable = () => {
    const columns = getColumns();
    return <AppTable columns={columns} data={classData.schedules} />;
  };

  return (
    <div className="booking-classes-item">
      {!classInStudentData?.checked &&
      classData.schedules.length === 1 &&
      classData.schedules[0].capacity - classData.schedules[0].occupied ===
        0 ? (
        <>
          <div className="class-name">
            {`${classData.classInfo.name} (${dayjs(
              classData.schedules?.[0]?.startTime
            ).format('ddd, MMM D, HH:mm')} - ${formatTime(
              classData.schedules?.[0]?.endTime
            )})`}
          </div>
          <div className="no-slot">No slot available</div>
        </>
      ) : (
        <div className="class-name">
          <AppCheckbox
            checked={isChecked()}
            onChange={() => handleCheckClass()}
          />
          {`${classData.classInfo.name} (${dayjs(
            classData.schedules?.[0]?.startTime
          ).format('ddd, MMM D, HH:mm')} - ${formatTime(
            classData.schedules?.[0]?.endTime
          )})`}
        </div>
      )}

      {isChecked() && (
        <div className="select-class-session">
          {[CLASS_TYPES.PRIVATE, CLASS_TYPES.SERIES].includes(
            classData.classInfo.type
          ) && (
            <AppSelect
              placeholder="Select Type"
              label="Enrolment Type"
              options={ENROLLMENT_TYPE_OPTIONS}
              value={classInStudentData?.enrollmentType || ''}
              searchable={false}
              style={{ width: '360px' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleSelectEnrollmentType(e)
              }
              message={{ type: 'error', text: errorMessage }}
            />
          )}
          {[CLASS_TYPES.PRIVATE, CLASS_TYPES.SERIES].includes(
            classData.classInfo.type
          ) &&
            classData.schedules.length > 1 &&
            !!classInStudentData?.enrollmentType &&
            renderTable()}
          {[
            CLASS_TYPES.ASSESSMENT_TRIAL,
            CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM
          ].includes(classData.classInfo.type) && (
            <div className="schedules">
              {arraySchedules.map((item, index) => {
                const columns = getColumns(item.dateLabel);
                return (
                  <div key={index}>
                    <div className="class-date">{item.dateLabel}</div>
                    <AppTable columns={columns} data={item?.schedules} />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ClassBookingSection;
