export const fuzzySearch = <T>(
  query: string,
  items: Array<T>,
  key: string
): Array<T> => {
  if (query === '') {
    return items;
  }
  const matchingItems: Array<T> = [];

  for (const item of items) {
    // @ts-ignore
    if (item[key].toLowerCase().includes(query.toLowerCase())) {
      matchingItems.push(item);
    }
  }
  return matchingItems;
};
