import React, { useCallback, useEffect, useState } from 'react';

import AppSelect from 'common/components/AppSelect';
import AppButton from 'common/components/AppButton';
import AppDatePicker from 'common/components/AppDatePicker';
import AppTimePicker from 'common/components/AppTimePicker';

import { HiArrowLeft, HiPlus, HiXMark } from 'react-icons/hi2';

import {
  ASSESSMENT_REVIEW_STATUS_OPTIONS,
  ASSESSMENT_STATUS_OPTIONS,
  PROGRAM_TYPE_OPTIONS
} from 'common/constants/index';
import { IStudentFilter, IStudentFilterActiveValue } from '../interface';
import { ILevel } from 'common/interfaces/levelBreakdown.interface';
import { IOption } from 'common/interfaces';
import { IInstructor } from 'common/interfaces/instructor.interface';
import './desktop.scss';
import {
  BOOKING_OPTIONS,
  CLASS_BOOKING_PAYMENT_OPTIONS
} from 'common/constants/classBooking.constant';
import dayjs, { Dayjs } from 'dayjs';
import { ILocation } from 'common/interfaces/location.interface';
import { getLevelBreakdowns } from 'services/levelBreakdown.service';
import { getInstructorList } from 'services/instructor.service';
import { ITerm } from 'common/interfaces/term.interface';
import { getTerms } from 'services/term.service';
import { TERM_TYPE } from 'common/enums/term.enum';
import { getLocations } from 'services/location.service';
import { getLocationAreas } from 'services/locationArea.service';
import { ILocationArea } from 'common/interfaces/locationArea.interface';
import { useBrandLocation } from 'context/BrandLocationContext';
import { BeatLoader } from 'react-spinners';
import { initFilterValue } from '../constants';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { PAYMENT_VALUE } from 'common/enums/classBooking.enum';

interface Props {
  showFilter: boolean;
  onFilter: (data: IStudentFilter) => void;
  onClose: () => void;
  isVoucherModule?: boolean;
}

const OUTSTANDING_FEE_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'YES', value: 'true' },
  { label: 'NO', value: 'false' }
];

const StudentFilter: React.FC<Props> = ({
  showFilter,
  onFilter,
  onClose,
  isVoucherModule
}: Props) => {
  const { selectedLocation } = useBrandLocation();
  const [filterValue, setFilterValue] = useState<IStudentFilter>({
    ...initFilterValue,
    locationId: selectedLocation?._id || '',
    paymentType: isVoucherModule
      ? PAYMENT_VALUE.DIRECT_DEBIT
      : PAYMENT_VALUE.IDLE
  });
  const [areas, setAreas] = useState<IOption[]>([]);
  const [currentLevels, setCurrentLevels] = useState<ILevel[]>([]);
  const [instructors, setInstructors] = useState<IInstructor[]>([]);
  const [locations, setLocations] = useState<ILocation[]>([]);
  const [terms, setTerms] = useState<ITerm[]>([]);
  const [allLevels, setAllLevels] = useState<ILevel[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const [
        { data: levelResult },
        { data: instructorResult },
        { data: termResult },
        { data: locationResult }
      ] = await Promise.all([
        getLevelBreakdowns(1, 100),
        getInstructorList({ page: 1, limit: 100 }),
        getTerms(
          1,
          100,
          '',
          '',
          [TERM_TYPE.TERM],
          true
        ),
        getLocations({ page: 1, limit: 100 })
      ]);

      const allLevels =
        levelResult?.data?.data.map((item: ILevel) => ({
          ...item,
          label: item.name,
          value: item._id
        })) || [];

      setCurrentLevels(allLevels);
      setAllLevels(allLevels);

      setInstructors(
        instructorResult.map((item: IInstructor) => ({
          ...item,
          label: item.firstName,
          value: item._id
        }))
      );
      setTerms(
        termResult?.data?.data.map((item: ITerm) => ({
          ...item,
          label: item.name,
          value: item._id
        }))
      );
      setLocations(
        locationResult?.data?.data.map((item: ILocation) => ({
          ...item,
          label: item.name,
          value: item._id
        }))
      );
    } catch (error: any) {
      setAreas([]);
      setCurrentLevels([]);
      setInstructors([]);
      setLocations([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (filterValue?.locationId) {
      const locationIdsSelected = filterValue?.locationId?.split(',');
      const newLevels = allLevels.filter(
        (levelItem: ILevel) =>
          levelItem?.locations?.findIndex((locationItem: ILocation) =>
            locationIdsSelected?.includes(locationItem._id)
          ) !== -1
      );
      setCurrentLevels(newLevels);
    } else {
      setCurrentLevels([...allLevels]);
    }
  }, [filterValue?.locationId, allLevels]);

  const fetchAreas = useCallback(async () => {
    try {
      const { data } = await getLocationAreas({
        locationId: filterValue.locationId || '',
        page: 1,
        limit: 100
      });
      setAreas(
        data.data?.data?.map((item: ILocationArea) => ({
          ...item,
          label: item.areaName,
          value: item.areaName
        })) || []
      );
    } catch (error: any) {
      setAreas([]);
    }
  }, [filterValue.locationId]);

  useEffect(() => {
    fetchAreas();
  }, [fetchAreas]);
  const onChangeFilterValue = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    setFilterValue({ ...filterValue, [key]: event.target.value });
  };

  const onChangeFilterActive = (key: IStudentFilterActiveValue) => {
    if (key === 'dateField') {
      setFilterValue({
        ...filterValue,
        dateFrom: '',
        dateTo: '',
        dateField: false
      });
    } else if (key === 'timeField') {
      setFilterValue({
        ...filterValue,
        timeFrom: '',
        timeTo: '',
        timeField: false
      });
    } else setFilterValue({ ...filterValue, [key]: '' });
  };

  const onChangeDateValue = (date: Dayjs | null, key: string) => {
    const filterValueNew = {
      ...filterValue,
      [key]: date?.format('YYYY-MM-DD')
    };
    setFilterValue({
      ...filterValueNew,
      dateField: filterValueNew.dateFrom && filterValueNew.dateTo ? true : false
    });
  };
  const onChangeTimeValue = (date: Dayjs | null, key: string) => {
    const filterValueNew = { ...filterValue, [key]: date?.format('HH:mm') };
    setFilterValue({
      ...filterValueNew,
      timeField: filterValueNew.timeFrom && filterValueNew.timeTo ? true : false
    });
  };

  const handleReset = () => {
    setFilterValue(initFilterValue);
    onFilter(initFilterValue);
  };

  const handleApplyFilter = () => {
    const selectedLocations = filterValue.locationId.split(',');
    const newFilterValue = {
      ...filterValue,
      selectedLocationNames: locations
        .filter((item) => selectedLocations.includes(item._id))
        .map((item) => item.name)
        .join(', '),
      selectedAreaNames: areas
        .filter((item) =>
          filterValue.areaId.split(',').includes(item.value as string)
        )
        .map((item) => item.label)
        .join(', '),
      selectedInstructorNames: instructors
        .filter((item) =>
          filterValue.instructorId.split(',').includes(item._id)
        )
        .map((item) => item.firstName)
        .join(', '),
      selectedTermNames: terms
        .filter((item) => filterValue.termId.split(',').includes(item._id))
        .map((item) => item.name)
        .join(', '),
      selectedLevelNames: currentLevels
        .filter((item) => filterValue.levelId.split(',').includes(item._id))
        .map((item) => item.name)
        .join(', ')
    };
    onFilter(newFilterValue);
  };
  return (
    <div className={`filter-student-component ${showFilter && 'open'}`}>
      <div>
        <div className="filter-header">
          <div className="filter-header filter-header-text">
            <HiArrowLeft size={24} onClick={onClose} />
            <div className="filter-title">Filters</div>
          </div>
          <div className="reset-label" onClick={handleReset}>
            Reset
          </div>
        </div>
        <div className="filter-body">
          {loading ? (
            <BeatLoader color="white" />
          ) : (
            <>
              {/* location */}
              <div className="filter-field">
                <AppSelect
                  options={locations}
                  inputSize="small"
                  label="Location"
                  value={filterValue.locationId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeFilterValue(e, 'locationId')
                  }
                  multiValue
                />
                <div
                  className={`filter-field-active-wrapper ${
                    filterValue.locationId && 'active'
                  }`}
                  onClick={() => onChangeFilterActive('locationId')}
                >
                  {filterValue.locationId ? <HiXMark /> : <HiPlus />}
                </div>
              </div>

              {/* program type */}
              <div className="filter-field">
                <AppSelect
                  options={
                    isVoucherModule
                      ? PROGRAM_TYPE_OPTIONS.filter(
                          (item) => item.value !== CLASS_TYPES.ASSESSMENT_TRIAL
                        )
                      : PROGRAM_TYPE_OPTIONS
                  }
                  inputSize="small"
                  label="Program Type"
                  value={filterValue.programType}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeFilterValue(e, 'programType')
                  }
                  searchable={false}
                  multiValue
                />
                <div
                  className={`filter-field-active-wrapper ${
                    filterValue.programType && 'active'
                  }`}
                  onClick={() => onChangeFilterActive('programType')}
                >
                  {filterValue.programType ? <HiXMark /> : <HiPlus />}
                </div>
              </div>
              {/* enrollment type */}
              <div className="filter-field">
                <AppSelect
                  options={BOOKING_OPTIONS}
                  inputSize="small"
                  label="Enrolment Type"
                  value={filterValue.enrollmentType}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeFilterValue(e, 'enrollmentType')
                  }
                  searchable={false}
                  multiValue
                />
                <div
                  className={`filter-field-active-wrapper ${
                    filterValue.enrollmentType && 'active'
                  }`}
                  onClick={() => onChangeFilterActive('enrollmentType')}
                >
                  {filterValue.enrollmentType ? <HiXMark /> : <HiPlus />}
                </div>
              </div>

              {/* date */}
              <div className="filter-field">
                <div className="date-field">
                  <AppDatePicker
                    label="Date From"
                    value={dayjs(filterValue.dateFrom)}
                    onChange={(value: Dayjs | null) =>
                      onChangeDateValue(value, 'dateFrom')
                    }
                    formats="DD/MM/YY"
                    size="x-small"
                    disablePast={isVoucherModule}
                  />
                  <AppDatePicker
                    label="Date To"
                    value={dayjs(filterValue.dateTo)}
                    onChange={(value: Dayjs | null) =>
                      onChangeDateValue(value, 'dateTo')
                    }
                    size="x-small"
                    formats="DD/MM/YY"
                  />
                </div>
                <div
                  className={`filter-field-active-wrapper ${
                    filterValue.dateField && 'active'
                  }`}
                  onClick={() => onChangeFilterActive('dateField')}
                >
                  {filterValue.dateField ? <HiXMark /> : <HiPlus />}
                </div>
              </div>

              {/* time */}
              <div className="filter-field">
                <div className="date-field">
                  <AppTimePicker
                    label="Time From"
                    value={dayjs(filterValue.timeFrom)}
                    onChange={(value: Dayjs | null) =>
                      onChangeTimeValue(value, 'timeFrom')
                    }
                    size="x-small"
                  />
                  <AppTimePicker
                    label="Time To"
                    value={dayjs(filterValue.timeTo)}
                    onChange={(value: Dayjs | null) =>
                      onChangeTimeValue(value, 'timeTo')
                    }
                    size="x-small"
                  />
                </div>
                <div
                  className={`filter-field-active-wrapper ${
                    filterValue.timeField && 'active'
                  }`}
                  onClick={() => onChangeFilterActive('timeField')}
                >
                  {filterValue.timeField ? <HiXMark /> : <HiPlus />}
                </div>
              </div>

              {/* level */}
              <div className="filter-field">
                <AppSelect
                  options={currentLevels}
                  inputSize="small"
                  label="Level"
                  value={filterValue.levelId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeFilterValue(e, 'levelId')
                  }
                  multiValue
                />
                <div
                  className={`filter-field-active-wrapper ${
                    filterValue.levelId && 'active'
                  }`}
                  onClick={() => onChangeFilterActive('levelId')}
                >
                  {filterValue.levelId ? <HiXMark /> : <HiPlus />}
                </div>
              </div>
              {/* instructor */}
              <div className="filter-field">
                <AppSelect
                  options={instructors}
                  inputSize="small"
                  label="Instructor"
                  value={filterValue.instructorId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeFilterValue(e, 'instructorId')
                  }
                  multiValue
                />
                <div
                  className={`filter-field-active-wrapper ${
                    filterValue.instructorId && 'active'
                  }`}
                  onClick={() => onChangeFilterActive('instructorId')}
                >
                  {filterValue.instructorId ? <HiXMark /> : <HiPlus />}
                </div>
              </div>
              {/* term */}
              <div className="filter-field">
                <AppSelect
                  options={terms}
                  inputSize="small"
                  label="Session"
                  value={filterValue.termId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeFilterValue(e, 'termId')
                  }
                />
                <div
                  className={`filter-field-active-wrapper ${
                    filterValue.termId && 'active'
                  }`}
                  onClick={() => onChangeFilterActive('termId')}
                >
                  {filterValue.termId ? <HiXMark /> : <HiPlus />}
                </div>
              </div>

              {/* area */}
              <div className="filter-field">
                <AppSelect
                  options={areas}
                  inputSize="small"
                  label="Area"
                  value={filterValue.areaId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeFilterValue(e, 'areaId')
                  }
                  multiValue
                />
                <div
                  className={`filter-field-active-wrapper ${
                    filterValue.areaId && 'active'
                  }`}
                  onClick={() => onChangeFilterActive('areaId')}
                >
                  {filterValue.areaId ? <HiXMark /> : <HiPlus />}
                </div>
              </div>

              {/* Assessment Result */}
              <div className="filter-field">
                <AppSelect
                  options={ASSESSMENT_STATUS_OPTIONS}
                  inputSize="small"
                  label="Assessment Result"
                  value={filterValue.assessmentResult}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeFilterValue(e, 'assessmentResult')
                  }
                  searchable={false}
                />
                <div
                  className={`filter-field-active-wrapper ${
                    filterValue.assessmentResult && 'active'
                  }`}
                  onClick={() => onChangeFilterActive('assessmentResult')}
                >
                  {filterValue.assessmentResult ? <HiXMark /> : <HiPlus />}
                </div>
              </div>
              {/* Assessment Status */}
              <div className="filter-field">
                <AppSelect
                  options={ASSESSMENT_REVIEW_STATUS_OPTIONS}
                  inputSize="small"
                  label="Assessment Status"
                  value={filterValue.assessmentStatus}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeFilterValue(e, 'assessmentStatus')
                  }
                  searchable={false}
                />
                <div
                  className={`filter-field-active-wrapper ${
                    filterValue.assessmentStatus && 'active'
                  }`}
                  onClick={() => onChangeFilterActive('assessmentStatus')}
                >
                  {filterValue.assessmentStatus ? <HiXMark /> : <HiPlus />}
                </div>
              </div>
              {/* Payment type*/}
              <div className="filter-field">
                <AppSelect
                  options={CLASS_BOOKING_PAYMENT_OPTIONS}
                  inputSize="small"
                  label="Payment Type"
                  value={filterValue.paymentType}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeFilterValue(e, 'paymentType')
                  }
                  searchable={false}
                  disabled={isVoucherModule}
                />
                <div
                  className={`filter-field-active-wrapper ${
                    filterValue.paymentType && 'active'
                  }`}
                  onClick={() => onChangeFilterActive('paymentType')}
                >
                  {filterValue.paymentType ? <HiXMark /> : <HiPlus />}
                </div>
              </div>
              {/* Outstanding Fee */}
              <div className="filter-field">
                <AppSelect
                  options={OUTSTANDING_FEE_OPTIONS}
                  inputSize="small"
                  label="Outstanding Fee"
                  value={filterValue.outstandingFee}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeFilterValue(e, 'outstandingFee')
                  }
                  searchable={false}
                />
                <div
                  className={`filter-field-active-wrapper ${
                    filterValue.outstandingFee && 'active'
                  }`}
                  onClick={() => onChangeFilterActive('outstandingFee')}
                >
                  {filterValue.outstandingFee ? <HiXMark /> : <HiPlus />}
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="footer-filter">
        <AppButton
          variant="secondary"
          buttonSize="small"
          className="apply-btn"
          onClick={onClose}
          style={{ marginRight: '8px' }}
        >
          Close
        </AppButton>
        <AppButton
          buttonSize="small"
          className="apply-btn"
          onClick={handleApplyFilter}
        >
          Apply
        </AppButton>
      </div>
    </div>
  );
};

export default StudentFilter;
