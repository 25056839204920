import { createColumnHelper } from '@tanstack/react-table';
import Upload, {
  ERROR_MESSAGE_LIMIT_FILES,
  ERROR_MESSAGE_LIMIT_SIZE
} from 'components/Upload';
import AppButton from 'common/components/AppButton';
import AppCard, {
  AppCardContent,
  AppCardHeader
} from 'common/components/AppCard';
import AppModal, {
  AppModalFormActions,
  AppModalFormContent,
  AppModalFormTitle,
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import AppSelect from 'common/components/AppSelect';
import AppTable from 'common/components/AppTable';
import { CREDENTIAL_OPTIONS } from 'common/constants/instructor.constants';
import { useToast } from 'context/ToastContext';
import { FILE_ASSET_TYPE } from 'common/enums/uploadFile.enum';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import { ICredential } from 'common/interfaces/credential.interface';
import { useCallback, useEffect, useState } from 'react';
import { HiOutlineTrash, HiPlus } from 'react-icons/hi';
import { HiPencilSquare } from 'react-icons/hi2';
import { useParams } from 'react-router-dom';
import {
  createCredentialsForInstructor,
  deleteCredentialsForInstructor,
  getCredentialsForInstructor,
  updateCredentialsForInstructor
} from 'services/credential';
import { uploadFile } from 'services/uploadFile.service';
import yupInstructor from 'validators/instructor.validators';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AppDatePicker from 'common/components/AppDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { convertToUnixTime } from 'common/helpers/time.helper';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useAuth } from 'context/AuthContext';

const Credentials = () => {
  const columnHelper = createColumnHelper<ICredential>();

  const columns = [
    columnHelper.accessor('name', {
      header: () => <span>credential</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('createdAt', {
      header: () => <span>Upload date</span>,
      cell: (info) => formatDate(info.getValue()),
      size: 20
    }),
    columnHelper.accessor('documents', {
      header: () => <span>documents</span>,
      cell: (info) => {
        return (
          <div className="credentials__table-documents">
            {info.getValue().map((document: string, index: number) => {
              return (
                <div key={index} className="credentials__table-document">
                  {document.includes('pdf') ? (
                    <embed src={document || ''} />
                  ) : (
                    <img src={document || ''} alt="document" />
                  )}
                </div>
              );
            })}
          </div>
        );
      }
    }),
    columnHelper.accessor('expiredAt', {
      header: () => <span>Expiry Date</span>,
      cell: (info) => formatDate(info.getValue()),
      size: 20
    }),
    columnHelper.accessor('_id', {
      header: () => (
        <span
          style={{
            display: 'block',
            textAlign: 'center'
          }}
        >
          Action
        </span>
      ),
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_CREDENTIAL}>
              <div
                onClick={() => handleSelectUpdateCredential(info.row.original)}
              >
                <HiPencilSquare />
              </div>
            </PermissionWrapper>
            <PermissionWrapper permission={PERMISSION.DELETE_CREDENTIAL}>
              <div
                onClick={() => handleSelectDeleteCredential(info.row.original)}
              >
                <HiOutlineTrash />
              </div>
            </PermissionWrapper>
          </div>
        );
      },
      size: 20
    })
  ];

  const params = useParams();
  const toast = useToast();
  const { hasPermission } = useAuth();

  const [loading, setLoading] = useState<boolean>(true);

  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageTotal, setPageTotal] = useState<number>(-1);
  const [credentials, setCredentials] = useState<Array<ICredential>>([]);

  const [name, setName] = useState<string>('');
  const [expiredAt, setExpiredAt] = useState<string>('');

  const [openAddCredentials, setOpenAddCredentials] = useState<boolean>(false);

  const [selectedDeleteCredential, setSelectedDeleteCredential] =
    useState<ICredential | null>(null);
  const [selectedUpdateCredential, setSelectedUpdateCredential] =
    useState<ICredential | null>(null);

  const [files, setFiles] = useState<Array<File>>([]);

  const [documents, setDocuments] = useState<Array<string>>([]);

  const validationSchema = yupInstructor.OBJECT({
    name: yupInstructor.CERT_NAME,
    expiredAt: yupInstructor.INSTRUCTOR_CREDENTIAL_EXPIRY_DATE
  });

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: true
  });

  // DELETE
  const handleSelectDeleteCredential = (credential: ICredential) => {
    setSelectedDeleteCredential(credential);
  };
  const handleCloseDeleteCredential = () => {
    setSelectedDeleteCredential(null);
  };

  const handleDeleteCredential = async () => {
    setLoading(true);
    try {
      await deleteCredentialsForInstructor(selectedDeleteCredential?._id || '');

      await fetchData();

      handleCloseDeleteCredential();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to delete credential'
      );
    } finally {
      setLoading(false);
    }
  };

  // Update
  const handleSelectUpdateCredential = (credential: ICredential) => {
    setSelectedUpdateCredential(credential);
    setName(credential.name);
    setExpiredAt(credential.expiredAt);
    setDocuments(credential.documents);
    reset(credential);
  };
  const handleCloseUpdateCredential = () => {
    setSelectedUpdateCredential(null);

    setLoading(false);
    setName('');
    setExpiredAt('');
    setFiles([]);
    setDocuments([]);
  };

  const handleUpdateCredential = async () => {
    setLoading(true);
    try {
      const promises: Array<Promise<any>> = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const promise = uploadFile(FILE_ASSET_TYPE.CREDENTIAL, file);
        promises.push(promise);
      }

      Promise.all(promises).then(async (results) => {
        const payloadDocuments = [
          ...documents,
          ...results.map((e) => e?.data?.data || '')
        ];

        await updateCredentialsForInstructor(
          selectedUpdateCredential?._id || '',
          {
            name: name,
            documents: payloadDocuments,
            expiredAt: convertToUnixTime(expiredAt)
          }
        );

        await fetchData();
        toast.success('Successfully update credentials');
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to upload credentials'
      );
    } finally {
      handleCloseUpdateCredential();
    }
  };

  // ADD
  const handleOpenAddCredentials = () => {
    setOpenAddCredentials(true);
    reset({ name: '', expiredAt: '' });
  };
  const handleCloseAddCredentials = () => {
    setLoading(false);
    setName('');
    setExpiredAt('');
    setFiles([]);
    setOpenAddCredentials(false);
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setValue('name', event.target.value);
    trigger('name');
  };
  const handleChangeExpiredAt = (value: Dayjs | null) => {
    let date = '';
    if (value) {
      date = dayjs(value).format('YYYY-MM-DD');
    }
    setExpiredAt(date);
    setValue('expiredAt', date);
    trigger('expiredAt');
  };

  const handleChangeFiles = (files: Array<File>) => {
    setFiles(files);
  };

  const handleChangeDocuments = (documents: Array<string>) => {
    setDocuments(documents);
  };

  const handleAddCredentials = async () => {
    if (files.length === 0) {
      toast.error('Please upload credential');
      return;
    }
    setLoading(true);
    try {
      const promises: Array<Promise<any>> = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const promise = uploadFile(FILE_ASSET_TYPE.CREDENTIAL, file);
        promises.push(promise);
      }

      Promise.all(promises).then(async (results) => {
        const payloadDocuments = results.map((e) => e?.data?.data || '');

        await createCredentialsForInstructor({
          name: name,
          instructorId: params?.id || '',
          documents: payloadDocuments,
          expiredAt: convertToUnixTime(expiredAt)
        });

        toast.success('Successfully upload credentials');
        await fetchData();
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to upload credentials'
      );
    } finally {
      handleCloseAddCredentials();
    }
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getCredentialsForInstructor(
        params?.id || '',
        pageIndex,
        pageSize
      );
      setCredentials(result.data.data.data);
      setPageTotal(result.data.data.total);
    } catch (error) {
      setCredentials([]);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <section className="credentials">
      {/* DELETE */}
      <AppModal
        open={!!selectedDeleteCredential}
        onClose={handleCloseDeleteCredential}
      >
        <AppModalTitle>Delete Credential</AppModalTitle>
        <AppModalContent>
          ARE YOU SURE YOU WANT TO DELETE THIS Credential?
        </AppModalContent>
        <AppModalActions>
          <AppButton
            disabled={loading}
            variant="secondary"
            onClick={handleCloseDeleteCredential}
          >
            No
          </AppButton>
          <AppButton isLoading={loading} onClick={handleDeleteCredential}>
            Yes
          </AppButton>
        </AppModalActions>
      </AppModal>

      <AppCard>
        <AppCardHeader
          title="CREDENTIAL(S)"
          suffix={
            <PermissionWrapper permission={PERMISSION.CREATE_CREDENTIAL}>
              <AppButton buttonSize="small" onClick={handleOpenAddCredentials}>
                <HiPlus size={22} />
                CREDENTIAL
              </AppButton>
            </PermissionWrapper>
          }
        />
        <AppCardContent>
          <div className="credentials__table">
            <AppTable
              data={credentials}
              columns={columns}
              pagination={{
                index: pageIndex,
                size: pageSize,
                total: pageTotal
              }}
              onChangePage={(index: number, size: number) => {
                setPageIndex(index);
                setPageSize(size);
              }}
              loading={loading}
            />

            {credentials?.length === 0 && (
              <div className="credentials-noResult">
                <AppButton
                  onClick={handleOpenAddCredentials}
                  buttonSize="small"
                >
                  <HiPlus size={22} />
                  CREDENTIAL
                </AppButton>
              </div>
            )}
          </div>
        </AppCardContent>
      </AppCard>

      {/* UPDATE CREDENTIAL MODAL */}
      <AppModal
        open={!!selectedUpdateCredential}
        onClose={handleCloseUpdateCredential}
      >
        <AppModalFormTitle>Update credential</AppModalFormTitle>
        <AppModalFormContent>
          <AppSelect
            label="Credential*"
            options={CREDENTIAL_OPTIONS}
            value={name}
            {...register('name')}
            onChange={handleChangeName}
            message={{
              type: 'error',
              text: errors?.name?.message || ''
            }}
            disabled={!hasPermission(PERMISSION.UPDATE_CREDENTIAL)}
          />
          <AppDatePicker
            label="Expiry Date"
            disabled={!hasPermission(PERMISSION.UPDATE_CREDENTIAL)}
            {...register('expiredAt')}
            value={dayjs(expiredAt || '')}
            onChange={handleChangeExpiredAt}
            message={{
              type: 'error',
              text: errors.expiredAt?.message || ''
            }}
            size="small"
          />
          <Upload
            disabled={!hasPermission(PERMISSION.UPDATE_CREDENTIAL)}
            files={files}
            documents={documents}
            onChangeDocuments={handleChangeDocuments}
            onChangeFiles={handleChangeFiles}
            onErrorFiles={(errorMessage: string) => {
              if (errorMessage === ERROR_MESSAGE_LIMIT_SIZE) {
                toast.error('Exceed the size 5mb');
              } else if (errorMessage === ERROR_MESSAGE_LIMIT_FILES) {
                toast.error('Exceed the number of files');
              }
            }}
          />
        </AppModalFormContent>
        <AppModalFormActions>
          <PermissionWrapper permission={PERMISSION.UPDATE_CREDENTIAL}>
            <AppButton
              isLoading={loading}
              onClick={handleSubmit(handleUpdateCredential)}
            >
              save
            </AppButton>
          </PermissionWrapper>
          <AppButton
            disabled={loading}
            variant="secondary"
            onClick={handleCloseUpdateCredential}
          >
            cancel
          </AppButton>
        </AppModalFormActions>
      </AppModal>

      {/* ADD CREDENTIAL MODAL */}
      <AppModal open={openAddCredentials} onClose={handleCloseAddCredentials}>
        <AppModalFormTitle>add credential</AppModalFormTitle>
        <AppModalFormContent>
          <AppSelect
            label="Credential*"
            options={CREDENTIAL_OPTIONS}
            value={name}
            {...register('name')}
            onChange={handleChangeName}
            message={{
              type: 'error',
              text: errors?.name?.message || ''
            }}
          />
          <AppDatePicker
            label="Expiry Date"
            {...register('expiredAt')}
            value={dayjs(expiredAt || '')}
            onChange={handleChangeExpiredAt}
            message={{
              type: 'error',
              text: errors.expiredAt?.message || ''
            }}
            size="small"
          />

          <Upload
            files={files}
            documents={[]}
            onChangeDocuments={() => {}}
            onChangeFiles={handleChangeFiles}
            onErrorFiles={(errorMessage: string) => {
              if (errorMessage === ERROR_MESSAGE_LIMIT_SIZE) {
                toast.error('Exceed the size 5mb');
              } else if (errorMessage === ERROR_MESSAGE_LIMIT_FILES) {
                toast.error('Exceed the number of files');
              }
            }}
          />
        </AppModalFormContent>
        <AppModalFormActions>
          <AppButton
            isLoading={loading}
            onClick={handleSubmit(handleAddCredentials)}
          >
            save
          </AppButton>
          <AppButton
            disabled={loading}
            variant="secondary"
            onClick={handleCloseAddCredentials}
          >
            cancel
          </AppButton>
        </AppModalFormActions>
      </AppModal>
    </section>
  );
};

export default Credentials;
