import { useState } from 'react';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import AppButton from 'common/components/AppButton';
import { useToast } from 'context/ToastContext';
import { IHealthQuestionnaire } from 'common/interfaces/healthQuestionnaire.interface';
import { deleteHealthQuestionnaire } from 'services/healthQuestionnaire.service';

interface IHealthQuestionnaireDeleteModalProps {
  healthQuestionnaire: IHealthQuestionnaire;
  onClose: () => void;
  onSuccess: () => void;
}

const HealthQuestionnaireDeleteModal = (
  props: IHealthQuestionnaireDeleteModalProps
) => {
  const { healthQuestionnaire, onClose, onSuccess } = props;

  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteHealthQuestionnaire(healthQuestionnaire?._id);
      toast.success('Deleted this health questionnaire');
      onSuccess();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'Failed to delete this health questionnaire'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppModal open={!!healthQuestionnaire} onClose={onClose}>
      <AppModalTitle>Delete health questionnaire</AppModalTitle>
      <AppModalContent>
        ARE YOU SURE YOU WANT TO DELETE THIS health questionnaire?
      </AppModalContent>
      <AppModalActions>
        <AppButton variant="secondary" onClick={onClose}>
          No
        </AppButton>
        <AppButton isLoading={loading} onClick={handleDelete}>
          Yes
        </AppButton>
      </AppModalActions>
    </AppModal>
  );
};

export default HealthQuestionnaireDeleteModal;
