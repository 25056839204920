import React from 'react';
import AppButton from 'common/components/AppButton';
import AppDatePicker from 'common/components/AppDatePicker';
import AppModal, {
  AppModalActions,
  AppModalFormContent,
  AppModalTitle
} from 'common/components/AppModal';
import AppTimePicker from 'common/components/AppTimePicker';
import { DATE_CONSTANT } from 'common/constants';
import dayjs, { Dayjs } from 'dayjs';
import './desktop.scss';
import yup from 'validators/notification.validator';
import { ScheduleNotification } from 'DTOs/notification.dto';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  scheduleDateTime: ScheduleNotification;
  onChangeScheduleDateTime: (value: ScheduleNotification) => void;
}
const validationSchema = yup.OBJECT({
  scheduleDate: yup.NOTIFICATION_DATE.test(
    'date',
    'Date must be grater than or equal to today',
    (value) => {
      return dayjs(value).isAfter(dayjs().subtract(1, 'day'));
    }
  ),
  scheduleTime: yup.NOTIFICATION_TIME.test(
    'time',
    'Time must be grater than or equal to today',
    (value, context) => {
      const { parent } = context;
      const { scheduleDate } = parent;
      if (
        dayjs(scheduleDate).format('YYYY-MM-DD') ===
        dayjs().format('YYYY-MM-DD')
      ) {
        const timeNow = dayjs().format('HH:mm');
        const timeSelected = dayjs(`${DATE_CONSTANT} ${value}`).format(
          'HH:mm'
        );

        return timeSelected > timeNow;
      }
      return true;
    }
  )
});
const ScheduleFormModal: React.FC<Props> = ({
  open,
  onClose,
  onSave,
  scheduleDateTime,
  onChangeScheduleDateTime
}: Props) => {
  const initScheduleForm: ScheduleNotification = {
    ...scheduleDateTime
  };
  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<ScheduleNotification>({
    resolver: yupResolver(validationSchema) as Resolver<ScheduleNotification>,
    defaultValues: initScheduleForm
  });

  return (
    <div className="scheduleForm--wrapper">
      <AppModal open={open} onClose={onClose}>
        <AppModalTitle>Schedule </AppModalTitle>
        <AppModalFormContent>
          <AppDatePicker
            label=" Date"
            disablePast
            {...register('scheduleDate')}
            message={{
              type: 'error',
              text: errors?.scheduleDate?.message || ''
            }}
            onChange={(value: Dayjs | null) => {
              if (value) {
                setValue('scheduleDate', value.format('YYYY-MM-DD'));
                onChangeScheduleDateTime({
                  ...scheduleDateTime,
                  scheduleDate: value.format('YYYY-MM-DD')
                });
              } else {
                setValue('scheduleDate', '');
                onChangeScheduleDateTime({
                  ...scheduleDateTime,
                  scheduleDate: ''
                });
              }
              trigger('scheduleTime');
            }}
            value={dayjs(scheduleDateTime.scheduleDate)}
          />
          <AppTimePicker
            label=" Time"
            {...register('scheduleTime')}
            message={{
              type: 'error',
              text: errors?.scheduleTime?.message || ''
            }}
            minutesStep={1}
            onChange={(value: Dayjs | null) => {
              if (value) {
                setValue('scheduleTime', value.format('HH:mm'));
                onChangeScheduleDateTime({
                  ...scheduleDateTime,
                  scheduleTime: value.format('HH:mm')
                });
              } else {
                setValue('scheduleTime', '');
                onChangeScheduleDateTime({
                  ...scheduleDateTime,
                  scheduleTime: ''
                });
              }
              trigger('scheduleTime');
            }}
            value={
              scheduleDateTime.scheduleTime
                ? dayjs(`${DATE_CONSTANT} ${scheduleDateTime.scheduleTime}`)
                : null
            }
          />
        </AppModalFormContent>
        <AppModalActions>
          <AppButton variant="secondary" onClick={onClose}>
            Cancel
          </AppButton>
          <AppButton onClick={handleSubmit(onSave)}>Save</AppButton>
        </AppModalActions>
      </AppModal>
    </div>
  );
};

export default ScheduleFormModal;
