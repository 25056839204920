import AppCard, { AppCardContent } from 'common/components/AppCard';
// import AppButton from 'common/components/AppButton';
import { useMemo } from 'react';
// import { HiOutlinePrinter } from 'react-icons/hi';
import { ISummaryInfoRollCall } from 'common/interfaces/rollCall.interface';
interface Props {
  data: ISummaryInfoRollCall;
}
const SummaryInformation = ({ data }: Props) => {
  const __renderContent = useMemo((): React.ReactNode => {
    return (
      <div>
        <div className="summary-info_name">Roll Calls</div>
        <div className="summary-info_wrap">
          <div className="summary-info_wrap-content">
            <div className="summary-info-item">
              <span className="summary-info-item_label">Date: </span>
              <span className="summary-info-item_value">{data.date}</span>
            </div>
            <div className="summary-info-item">
              <span className="summary-info-item_label">Classes From: </span>
              <span className="summary-info-item_value">{data.time}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }, [data]);

  return (
    <section className="summary-info">
      <AppCard>
        <div className="card-content">
          <AppCardContent>{__renderContent}</AppCardContent>
          {/* <AppButton
            variant="secondary"
            buttonSize="small"
            className="print-btn"
            style={{ border: 'none' }}
          >
            <HiOutlinePrinter
              fontSize={20}
              style={{ verticalAlign: 'middle', marginRight: '5px' }}
            />
            Print
          </AppButton> */}
        </div>
      </AppCard>
    </section>
  );
};

export default SummaryInformation;
