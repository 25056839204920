import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { HiArrowLeft } from 'react-icons/hi2';
import { addLocation } from 'services/location.service';
import { CreateLocationDto, FormCreateLocationDto } from 'DTOs/location.dto';
import { useToast } from 'context/ToastContext';
import AppButton from 'common/components/AppButton';
import { STATIC_BRAND_CLUBLIME } from 'common/constants';
import yup from 'validators/location.validator';
import { Resolver, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AppCard, { AppCardHeader } from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';

import './desktop.scss';

interface Props {
  openAdd: boolean;
  onCloseAdd: () => void;
  onSuccess: () => void;
}

const validationSchema = yup.OBJECT({
  name: yup.string().required('This field is required'),
  shortName: yup.string(),
  streetAddress: yup.string().required('This field is required'),
  suburb: yup.string().required('This field is required'),
  city: yup.string().required('This field is required'),
  state: yup.string().required('This field is required'),
  postCode: yup.string().required('This field is required'),
  country: yup.string().required('This field is required'),
  creditCard: yup.string(),
  bankAccount: yup.string()
});

const LocationAdd = ({ openAdd, onCloseAdd, onSuccess }: Props) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const {
    register,
    setValue,
    reset,
    control,
    clearErrors,
    trigger,
    formState: { errors },
    handleSubmit
  } = useForm<FormCreateLocationDto>({
    resolver: yupResolver(validationSchema) as Resolver<FormCreateLocationDto>
  });

  const watchAllFields = useWatch({ control });

  useEffect(() => {
    if (
      watchAllFields?.name &&
      watchAllFields?.streetAddress &&
      watchAllFields?.suburb &&
      watchAllFields?.city &&
      watchAllFields?.state &&
      watchAllFields?.postCode && 
      watchAllFields?.country
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [setDisabled, watchAllFields]);

  const handleResetForm = useCallback(() => {
    clearErrors();

    reset({
      name: '',
      shortName: '',
      streetAddress: '',
      suburb: '',
      city: '',
      state: '',
      postCode: '',
      country: '',
      creditCard: '',
      bankAccount: ''
    });
  }, [reset, clearErrors]);

  const handleCloseModal = useCallback(() => {
    // set timeout match the close modal timeout
    setTimeout(() => handleResetForm(), 200);

    if (onCloseAdd) onCloseAdd();
  }, [handleResetForm, onCloseAdd]);

  const handleBack = () => {
    onCloseAdd();
  };

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index?: number
    ) => {
      switch (event.target.name) {
        default:
          // @ts-ignore
          setValue(event.target.name, event.target.value);

          // @ts-ignore
          trigger(event.target.name);
          break;
      }
    },
    [setValue, trigger]
  );

  const onSubmit = async () => {
    try {
      const formData = { ...watchAllFields };

      if (disabled || !formData) {
        return;
      }

      setLoading(true);
      setDisabled(true);

      const payload: CreateLocationDto = {
        name: formData?.name || '',
        shortName: formData?.shortName || '',
        brandId: STATIC_BRAND_CLUBLIME._id,
        address: {
          streetAddress: formData?.streetAddress || '',
          suburb: formData?.suburb || '',
          city: formData?.city || '',
          state: formData?.state || '',
          postCode: formData?.postCode || '',
          country: formData?.country || ''
        },
        bankInfo: {
          creditCard: formData?.creditCard || '',
          bankAccount: formData?.bankAccount || ''
        }
      };

      await addLocation(payload);

      toast.success('Add location successfully');

      onSuccess();

      handleCloseModal();
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Add location failed');
    } finally {
      setDisabled(false);
      setLoading(false);
    }
  };

  return (
    <div className="locationAddContainer">
      <div
        className={`overlay ${openAdd ? 'active' : ' '}`}
        onClick={handleCloseModal}
      ></div>
      <div className={`locationAddForm ${openAdd ? 'active' : ' '}`}>
        <div className="locationAddForm__header">
          <HiArrowLeft
            size={24}
            style={{ cursor: 'pointer' }}
            onClick={handleBack}
          />
          <p>Add Location</p>
        </div>

        <div className="locationAddForm__content disable-scroll">
          <AppCard>
            <div className="locationAddForm__content-wrapper">
              {/* Location INFORMATION */}
              <>
                <div className="locationAddForm__content-wrapper-header">
                  <AppCardHeader title="Location INFORMATION" />
                </div>
                <div className="locationAddForm__content-information">
                  <div className="item">
                    <AppInput
                      {...register('name')}
                      label="Location Name*"
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.name?.message || ''
                      }}
                    />
                  </div>
                  <div className="item">
                    <AppInput
                      {...register('shortName')}
                      label="Short Name"
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.shortName?.message || ''
                      }}
                    />
                  </div>
                </div>
              </>

              {/* Location ADDRESS DETAILS */}
              <>
                <div className="locationAddForm__content-wrapper-header">
                  <AppCardHeader title="Location ADDRESS DETAILS" />
                </div>
                <div className="locationAddForm__content-personal_information">
                  <div className="item">
                    <AppInput
                      {...register('streetAddress')}
                      label="Address*"
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.streetAddress?.message || ''
                      }}
                    />
                  </div>
                  <div className="item">
                    <AppInput
                      {...register('suburb')}
                      label="Suburb*"
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.suburb?.message || ''
                      }}
                    />
                  </div>
                  <div className="item">
                    <AppInput
                      {...register('city')}
                      label="City*"
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.city?.message || ''
                      }}
                    />
                  </div>
                  <div className="item">
                    <AppInput
                      {...register('state')}
                      label="State*"
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.state?.message || ''
                      }}
                    />
                  </div>
                  <div className="item">
                    <AppInput
                      {...register('postCode')}
                      label="Postcode*"
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.postCode?.message || ''
                      }}
                    />
                  </div>
                  <div className="item">
                    <AppInput
                      {...register('country')}
                      label="Country*"
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.country?.message || ''
                      }}
                    />
                  </div>
                </div>
              </>

              {/* Bank Details */}
              <>
                <div className="locationAddForm__content-wrapper-header">
                  <AppCardHeader title="Bank Details" />
                </div>
                <div className="locationAddForm__content-information">
                  <div className="item">
                    <AppInput
                      {...register('creditCard')}
                      label="DD Descriptor Credit Card"
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.creditCard?.message || ''
                      }}
                    />
                  </div>
                  <div className="item">
                    <AppInput
                      {...register('bankAccount')}
                      label="DD Descriptor Bank Account"
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.bankAccount?.message || ''
                      }}
                    />
                  </div>
                </div>
              </>
            </div>
          </AppCard>
        </div>

        <div className="locationAddForm__actions">
          <AppButton
            type="button"
            variant={disabled ? 'disabled' : 'primary'}
            onClick={handleSubmit(onSubmit)}
            isLoading={loading}
          >
            Save
          </AppButton>
          <AppButton
            type="button"
            variant="secondary"
            onClick={handleCloseModal}
          >
            Cancel
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default memo(LocationAdd);
