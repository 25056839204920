import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppCard from 'common/components/AppCard';
import AppInputSearch from 'common/components/AppInputSearch';
import AppButton from 'common/components/AppButton';
import useDebounce from 'common/hooks/useDebounce';
import { HiMagnifyingGlass, HiStar, HiXMark } from 'react-icons/hi2';
import AppTable from 'common/components/AppTable';
import { createColumnHelper } from '@tanstack/react-table';
import { formatData } from 'common/helpers/dataFormat.helper';
import { GoFilter } from 'react-icons/go';
import {
  approveAssessmentResults,
  bulkApproveAssessmentResults,
  getAssessmentResults,
  reassessAssessmentResults,
  updateAssessmentResults
} from 'services/assessment.service';
import { useToast } from 'context/ToastContext';
import {
  IAssessmentResults,
  ISkillResult
} from 'common/interfaces/assessment.interface';
import {
  getLevelBreakdowns,
  updateLevelSetting
} from 'services/levelBreakdown.service';
import AppSelect from 'common/components/AppSelect';
import { IClass } from 'common/interfaces/class.interface';
import { IInstructor } from 'common/interfaces/instructor.interface';
import { getInstructorList } from 'services/instructor.service';
import { useBrandLocation } from 'context/BrandLocationContext';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { getClassList } from 'services/classes.service';
import {
  ASSESSMENT_REVIEW_STATUS,
  ASSESSMENT_STATUS
} from 'common/enums/assessment.enum';
import ClassAssessmentModal from 'components/Assessment/ClassAssessmentModal';
import {
  CreateSkillResultsDTO,
  UpdateAssessmentResultsDTO
} from 'DTOs/assessment.dto';
import { useAuth } from 'context/AuthContext';
import { PERMISSION } from 'common/enums/permission.enum';
import PermissionWrapper from 'components/PermissionWrapper';
import 'swiper/css';
import { IOption } from 'common/interfaces';
import { every, uniq } from 'lodash';
import AppCheckbox from 'common/components/AppCheckbox';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { useSearchParams } from 'react-router-dom';
import AppTabs from 'common/components/AppTabs';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { TAB } from 'common/constants';

import './desktop.scss';
import ColorLevelQuickFilter from 'components/colorLevelFilter/ColorLevelQuickFilter';
import { IColorSetting } from 'common/interfaces/dashboard.interface';
import { LevelSetting } from 'common/interfaces/levelBreakdown.interface';
import { USER_TYPE } from 'common/enums/user.enum';

const LIMIT = 200;

enum ASSESSMENT_MODE {
  SINGLE = 'single',
  MULTIPLE = 'multiple'
}

const AssessmentList = () => {
  const toast = useToast();
  const { hasPermission, user, __isInstructorAccount } = useAuth();
  const { selectedLocation: globalLocation } = useBrandLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const columnHelper = createColumnHelper<IAssessmentResults>();

  const columns = [
    columnHelper.accessor('_id', {
      header: () => (
        <AppCheckbox checked={__isCheckAll} onChange={handleToggleAll} />
      ),
      cell: (info) => (
        <AppCheckbox
          value={info.getValue()}
          checked={selectedAssessmentIds.includes(info.getValue())}
          onChange={handleToggleAssessment}
        />
      )
    }),
    columnHelper.accessor('student', {
      header: () => <span>Surname, name</span>,
      cell: (info) => {
        return (
          <div>
            {`${formatData(info.getValue()?.lastName)} , ${formatData(
              info.getValue()?.firstName
            )}`}
          </div>
        );
      }
    }),
    columnHelper.accessor('class', {
      header: () => <span>class</span>,
      cell: (info) => {
        return <div>{formatData(info.getValue()?.name)}</div>;
      }
    }),
    columnHelper.accessor('class', {
      header: () => <span>level</span>,
      cell: (info) => {
        return (
          <div>
            {formatData(info.getValue()?.levelBreakdown?.name || 'All Levels')}
          </div>
        );
      }
    }),
    columnHelper.accessor('class', {
      header: () => <span>instructor</span>,
      cell: (info) => {
        return <div>{formatData(info.getValue()?.instructor?.firstName)}</div>;
      }
    }),
    columnHelper.accessor('assessmentStatus', {
      header: () => <span>result</span>,
      cell: (info) => {
        const isNewSTAssessment =
          info.row.original.class?.type === CLASS_TYPES.ASSESSMENT_TRIAL;
        if (isNewSTAssessment) {
          return <div>{formatData(info.row.original?.resultLevel?.name)}</div>;
        }
        return (
          <div>
            <div
              className={`assessmentStatus assessmentStatus__${info.getValue()}`}
            >
              {formatData(info.getValue()?.replace('-', ' '))}
            </div>
          </div>
        );
      }
    }),
    columnHelper.accessor('reviewStatus', {
      header: () => <span>status</span>,
      cell: (info) => (
        <div>
          <div
            className={`assessmentStatus assessmentStatus__${info.getValue()}`}
          >
            {formatData(info.getValue()?.replace('-', ' '))}
          </div>
        </div>
      )
    })
  ];

  const [loading, setLoading] = useState(true);
  const [initLoad, setInitLoad] = useState(true);

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageTotal, setPageTotal] = useState(-1);

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);

  const [assessmentStatus, setAssessmentStatus] = useState<ASSESSMENT_STATUS>(
    ASSESSMENT_STATUS.IDLE
  );

  const [levelBreakdowns, setLevelBreakdowns] = useState<IColorSetting[]>([]);
  const [instructors, setInstructors] = useState<IInstructor[]>([]);
  const [classList, setClassList] = useState<IClass[]>([]);
  const [assessmentResults, setAssessmentResults] = useState<
    IAssessmentResults[]
  >([]);

  const [activeTab, setActiveTab] = useState<ASSESSMENT_REVIEW_STATUS>(
    ASSESSMENT_REVIEW_STATUS.IDLE
  );

  const [selectedClassId, setSelectedClassId] = useState('');
  const [selectedLevelBreakdownId, setSelectedLevelBreakdownId] = useState('');
  const [selectedInstructorId, setSelectedInstructorId] = useState('');

  const filterClassId = useRef('');
  const filterLevelBreakdownId = useRef('');
  const filterInstructorId = useRef('');

  const [selectedAssessmentResults, setSelectedAssessmentResults] =
    useState<IAssessmentResults | null>(null);
  const [selectedAssessmentIndex, setSelectedAssessmentIndex] = useState(-1);

  const [openFilter, setOpenFilter] = useState(false);

  const [openReassess, setOpenReassess] = useState(false);

  const [selectedAssessmentIds, setSelectedAssessmentIds] = useState<string[]>(
    []
  );

  const [assessmentMode, setAssessmentMode] = useState<ASSESSMENT_MODE>(
    ASSESSMENT_MODE.SINGLE
  );

  const maxScoreDisplay = useMemo(() => {
    if (
      selectedAssessmentResults?.class?.type === CLASS_TYPES.ASSESSMENT_TRIAL
    ) {
      return 1;
    } else {
      return selectedAssessmentResults?.maxScore || 3;
    }
  }, [selectedAssessmentResults]);

  // Re-assess section
  const handleOpenUpdateReassess = useCallback(() => {
    setOpenReassess(true);
  }, []);

  const handleCloseUpdateReassess = useCallback(() => {
    setOpenReassess(false);
  }, []);

  // Fetch data
  const fetchAssessmentResults = useCallback(async () => {
    try {
      if (activeTab === ASSESSMENT_REVIEW_STATUS.IDLE) return;
      let instructorId = '';
      if (user?.userType === USER_TYPE.INSTRUCTOR) {
        instructorId = user?._id;
      } else {
        instructorId = selectedInstructorId;
      }

      setLoading(true);
      setSelectedAssessmentIndex(-1);
      setSelectedAssessmentResults(null);

      const result = await getAssessmentResults(
        selectedLevelBreakdownId,
        selectedClassId,
        instructorId,
        activeTab,
        pageIndex,
        pageSize,
        search,
        assessmentStatus
      );
      setAssessmentResults(result.data.data.data);
      setPageTotal(result.data.data.total);
    } catch (error: any) {
      setAssessmentResults([]);
      setPageTotal(-1);
      toast.error(
        error?.response?.data?.message || 'Failed to get assessment results'
      );
    } finally {
      setLoading(false);
      if (initLoad) setInitLoad(false);
    }
    //eslint-disable-next-line
  }, [
    activeTab,
    pageIndex,
    pageSize,
    debouncedSearch,
    selectedLevelBreakdownId,
    selectedClassId,
    selectedInstructorId,
    assessmentStatus
  ]);

  // Call API Approve/Re-assess
  const handleSelectNextAssessment = useCallback(
    async (reviewStatus: ASSESSMENT_REVIEW_STATUS) => {
      if (assessmentResults.length === 1 && pageIndex > 1) {
        setPageIndex(pageIndex - 1);
      } else {
        await fetchAssessmentResults();
      }
      toast.success(
        `${
          reviewStatus === ASSESSMENT_REVIEW_STATUS.APPROVED
            ? 'Approve'
            : 'Re-assess'
        } the assessment successfully`
      );
    },

    // eslint-disable-next-line
    [assessmentResults, pageIndex, fetchAssessmentResults]
  );

  const handleApproveAssessment = useCallback(async () => {
    try {
      if (!selectedAssessmentResults) return;

      setLoading(true);

      await approveAssessmentResults(selectedAssessmentResults._id);
      await handleSelectNextAssessment(ASSESSMENT_REVIEW_STATUS.APPROVED);

      handleUnselectAssessmentResults();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to approve this assessment'
      );
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [selectedAssessmentResults, handleSelectNextAssessment]);

  const handleReassessAssessment = useCallback(async () => {
    try {
      if (!selectedAssessmentResults) return;

      setLoading(true);

      await reassessAssessmentResults(selectedAssessmentResults._id);
      await handleSelectNextAssessment(ASSESSMENT_REVIEW_STATUS.REASSESS);

      handleUnselectAssessmentResults();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to re-assess this assessment'
      );
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [selectedAssessmentResults, handleSelectNextAssessment]);

  const [tabIndex, setTabIndex] = useState(TAB.IDLE);

  const tabs = useMemo(() => {
    return [
      {
        name: 'pending',
        value: ASSESSMENT_REVIEW_STATUS.APPROVAL_PENDING,
        component: (
          <>
            <PermissionWrapper permission={PERMISSION.APPROVE_ASSESSMENT}>
              <AppButton
                variant="primary"
                buttonSize="small"
                onClick={handleApproveAssessment}
              >
                Approve
              </AppButton>
            </PermissionWrapper>
            <PermissionWrapper permission={PERMISSION.REASSESS_ASSESSMENT}>
              <AppButton
                variant="secondary"
                buttonSize="small"
                onClick={handleReassessAssessment}
              >
                re-assess
              </AppButton>
            </PermissionWrapper>
          </>
        )
      },
      ...(hasPermission(PERMISSION.LIST_ASSESSMENT_RESULT)
        ? [
            {
              name: 'approved',
              value: ASSESSMENT_REVIEW_STATUS.APPROVED,
              component: (
                <>
                  <PermissionWrapper
                    permission={PERMISSION.REASSESS_ASSESSMENT}
                  >
                    <AppButton
                      variant="secondary"
                      buttonSize="small"
                      onClick={handleReassessAssessment}
                    >
                      re-assess
                    </AppButton>
                  </PermissionWrapper>
                </>
              )
            }
          ]
        : []),
      {
        name: 're-assess',
        value: ASSESSMENT_REVIEW_STATUS.REASSESS,
        component: (
          <>
            <PermissionWrapper permission={PERMISSION.APPROVE_ASSESSMENT}>
              <AppButton
                variant="primary"
                buttonSize="small"
                onClick={handleApproveAssessment}
              >
                Approve
              </AppButton>
            </PermissionWrapper>
            <PermissionWrapper
              permission={[
                PERMISSION.UPDATE_ASSESSMENT_RESULT,
                PERMISSION.VIEW_DETAIL_LEVEL_BREAKDOWN
              ]}
            >
              <AppButton
                variant="secondary"
                buttonSize="small"
                onClick={handleOpenUpdateReassess}
              >
                re-assess
              </AppButton>
            </PermissionWrapper>
          </>
        )
      }
    ];
  }, [
    handleApproveAssessment,
    handleOpenUpdateReassess,
    handleReassessAssessment,
    hasPermission
  ]);

  const __appliedFilter = useMemo((): string => {
    let count = 0;
    count =
      count +
      (!!selectedClassId ? 1 : 0) +
      (!!selectedLevelBreakdownId ? 1 : 0) +
      (!!selectedInstructorId ? 1 : 0);
    return count > 0 ? `(${count})` : '';
  }, [selectedClassId, selectedLevelBreakdownId, selectedInstructorId]);

  const ASSESSMENT_STATUS_OPTIONS = useMemo((): Array<IOption> => {
    let result: Array<IOption> = [
      {
        label: ASSESSMENT_STATUS.ACHIEVED,
        value: ASSESSMENT_STATUS.ACHIEVED
      },
      {
        label: ASSESSMENT_STATUS.DEVELOPING,
        value: ASSESSMENT_STATUS.DEVELOPING
      }
    ];
    if (assessmentStatus !== ASSESSMENT_STATUS.IDLE)
      result.unshift({
        label: 'All',
        value: ASSESSMENT_STATUS.IDLE
      });
    return result;
  }, [assessmentStatus]);

  // Bulk approval

  const __isDisabledBulkApprovalButton = useMemo((): boolean => {
    return assessmentResults.length === 0;
  }, [assessmentResults]);

  const __isDisabledCreateApproveButton = useMemo((): boolean => {
    return selectedAssessmentIds.length === 0;
  }, [selectedAssessmentIds]);

  const __isCheckAll = useMemo((): boolean => {
    if (pageTotal < pageSize) {
      return selectedAssessmentIds.length === pageTotal;
    }
    return every(assessmentResults, (assessment: IAssessmentResults) =>
      selectedAssessmentIds.includes(assessment._id)
    );
    // eslint-disable-next-line
  }, [
    selectedAssessmentIds,
    pageIndex,
    pageSize,
    pageTotal,
    assessmentResults
  ]);

  const handleOpenBulkApproval = () => {
    setAssessmentMode(ASSESSMENT_MODE.MULTIPLE);

    handleUnselectAssessmentResults();
  };
  const handleCloseBulkApproval = useCallback(() => {
    setTimeout(() => {
      setAssessmentMode(ASSESSMENT_MODE.SINGLE);
      setSelectedAssessmentIds([]);
      handleChangeAssessmentStatus(ASSESSMENT_STATUS.IDLE);
    }, 200);
  }, []);

  const handleToggleAssessment = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.stopPropagation();

    const newSelectedAssessments = [...selectedAssessmentIds];
    const findIndex = selectedAssessmentIds.findIndex(
      (e) => e === event.target.value
    );
    if (findIndex === -1) {
      newSelectedAssessments.push(event.target.value);
    } else {
      newSelectedAssessments.splice(findIndex, 1);
    }
    setSelectedAssessmentIds(newSelectedAssessments);
  };

  const handleToggleAll = () => {
    const ids = assessmentResults.map((item) => item._id);
    if (__isCheckAll) {
      setSelectedAssessmentIds((prevIds) =>
        prevIds.filter((id) => !ids.includes(id))
      );
    } else {
      setSelectedAssessmentIds(uniq(ids.concat(selectedAssessmentIds)));
    }
  };

  // Search + change tab
  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  const onClearSearch = () => {
    setSearch('');
  };

  const [openAssessmentDetails, setOpenAssessmentDetails] = useState(false);

  // Detail assessment
  const handleSelectAssessmentResults = useCallback(
    (assessmentResults: IAssessmentResults, index: number) => {
      if (assessmentMode === ASSESSMENT_MODE.MULTIPLE) return;

      setOpenAssessmentDetails(true);
      setSelectedAssessmentResults(assessmentResults);
      setSelectedAssessmentIndex(index);
    },
    [assessmentMode]
  );

  const handleUnselectAssessmentResults = useCallback(() => {
    setOpenAssessmentDetails(false);

    setTimeout(() => {
      handleChangeAssessmentStatus(ASSESSMENT_STATUS.IDLE);
      setSelectedAssessmentResults(null);
      setSelectedAssessmentIndex(-1);
    }, 200);
  }, []);

  const handleSelectedTab = useCallback(
    (val: number) => {
      setActiveTab(tabs?.[val]?.value);

      // Reset
      handleCloseBulkApproval();
      handleUnselectAssessmentResults();

      setTabIndex(val);

      searchParams.set('indexRoute', `${val}`);
      setSearchParams(searchParams);
    },
    [
      tabs,
      searchParams,
      setSearchParams,
      handleCloseBulkApproval,
      handleUnselectAssessmentResults
    ]
  );

  const indexRoute = Number(searchParams.get('indexRoute'));

  useEffect(() => {
    if (indexRoute >= 0 && indexRoute <= tabs.length) {
      setTabIndex(indexRoute);
      setActiveTab(tabs?.[indexRoute]?.value);
    } else {
      setTabIndex(TAB.INIT);
      setActiveTab(tabs?.[TAB.INIT]?.value);

      searchParams.set('indexRoute', `${TAB.INIT}`);
      setSearchParams(searchParams, { replace: true });
    }
  }, [indexRoute, searchParams, setSearchParams, tabs]);

  const handleChangeAssessmentStatus = (value: string) => {
    setAssessmentStatus(value as ASSESSMENT_STATUS);
  };

  // Filter
  const handleOpenFilter = () => {
    filterLevelBreakdownId.current = selectedLevelBreakdownId;
    filterClassId.current = selectedClassId;
    filterInstructorId.current = selectedInstructorId;
    setOpenFilter(true);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const handleApplyFilter = () => {
    setSelectedLevelBreakdownId(filterLevelBreakdownId.current);
    setSelectedClassId(filterClassId.current);
    setSelectedInstructorId(filterInstructorId.current);
    handleCloseFilter();
  };
  const handleResetFilter = () => {
    setSelectedLevelBreakdownId('');
    setSelectedClassId('');
    setSelectedInstructorId('');
    handleCloseFilter();
  };
  const handleChangeLevelBreakdown = (id: string) => {
    filterLevelBreakdownId.current = id;
  };
  const handleChangeClass = (id: string) => {
    filterClassId.current = id;
  };
  const handleChangeInstructor = (id: string) => {
    filterInstructorId.current = id;
  };

  const handleApprove = async () => {
    if (
      assessmentResults?.length > 0 &&
      activeTab === ASSESSMENT_REVIEW_STATUS.APPROVED
    ) {
      setSelectedAssessmentResults(assessmentResults[0]);
      setSelectedAssessmentIndex(0);
      return;
    }

    try {
      setLoading(true);
      await bulkApproveAssessmentResults(selectedAssessmentIds);
      await handleSelectNextAssessment(ASSESSMENT_REVIEW_STATUS.APPROVED);
      // Reset
      handleCloseBulkApproval();
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Failed to bulk approve');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateReassessAssessment = async (
    _: number,
    skillResults: Array<CreateSkillResultsDTO>
  ) => {
    try {
      if (!skillResults || !selectedAssessmentResults) return;

      setLoading(true);

      const payload: UpdateAssessmentResultsDTO = {
        reviewStatus: ASSESSMENT_REVIEW_STATUS.APPROVED,
        skillResults: skillResults
      };
      await updateAssessmentResults(selectedAssessmentResults._id, payload);
      await handleSelectNextAssessment(ASSESSMENT_REVIEW_STATUS.REASSESS);
      handleCloseUpdateReassess();
      setOpenAssessmentDetails(false);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to re-assess this assessment'
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchData = useCallback(async () => {
    try {
      if (!globalLocation?._id) return;
      const [instructors, classList, levels] = await Promise.all([
        hasPermission(PERMISSION.LIST_INSTRUCTOR) &&
          getInstructorList({
            page: 1,
            limit: LIMIT,
            status: 'active'
          }),
        hasPermission(PERMISSION.LIST_CLASS) &&
          getClassList({
            page: 1,
            limit: LIMIT,
            locationId: globalLocation?._id || '',
            instructors: __isInstructorAccount ? user?._id || '' : undefined
          }),
        hasPermission(PERMISSION.LIST_LEVEL_BREAKDOWN) &&
          getLevelBreakdowns(1, LIMIT, globalLocation?._id || '')
      ]);

      setInstructors(instructors?.data || []);
      setClassList(classList?.data || []);
      setLevelBreakdowns(levels?.data?.data?.data || []);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to get data for filters'
      );
    }
    //eslint-disable-next-line
  }, [globalLocation?._id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchAssessmentResults();
  }, [fetchAssessmentResults]);

  useEffect(() => {
    setPageIndex(1);
  }, [debouncedSearch, selectedLevelBreakdownId, activeTab, assessmentStatus]);

  useEffect(() => {
    const appFooter = document.querySelector('.c_footer-app');
    if (assessmentMode === ASSESSMENT_MODE.MULTIPLE) {
      appFooter?.setAttribute('style', 'min-height:74px');
    } else {
      appFooter?.setAttribute('style', 'min-height:51px');
    }
    return () => {
      appFooter?.setAttribute('style', 'min-height:51px');
    };
  }, [assessmentMode]);

  const handleSortColorSetting = useCallback(
    async (newColorSettings: IColorSetting[]) => {
      if (!user?._id || !newColorSettings.length) return;
      setLevelBreakdowns(newColorSettings);
      try {
        const payload: LevelSetting = {
          levelOrders: newColorSettings.map((item, index) => {
            return { levelId: item._id, order: index };
          })
        };
        await updateLevelSetting(user?._id, payload);
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Cannot sort color setting'
        );
      }
    },
    // eslint-disable-next-line
    [user?._id]
  );

  return (
    <main id="assessmentListPage">
      {/* Update re-assess assessment*/}
      {!!selectedAssessmentResults && (
        <ClassAssessmentModal
          levelId={selectedAssessmentResults.levelId}
          open={openReassess}
          onClose={handleCloseUpdateReassess}
          onSave={handleUpdateReassessAssessment}
          loading={loading}
          defaultSkillResults={selectedAssessmentResults?.skillResults}
          canEdit
          isReAssess
          title={`${formatData(
            selectedAssessmentResults?.student?.firstName
          )} - ${formatData(
            selectedAssessmentResults?.class?.levelBreakdown?.name
          )}`}
          newSTAssessment={
            selectedAssessmentResults?.class?.type ===
            CLASS_TYPES.ASSESSMENT_TRIAL
          }
          resultLevel={selectedAssessmentResults?.resultLevel}
        />
      )}

      <AppBreadCrumb items={[{ name: 'Assessments', href: '/assessments' }]} />

      <section className="layoutContainer assessments">
        <div
          className={`assessments__main ${
            openAssessmentDetails ? 'active' : ''
          }`}
        >
          <AppTabs
            tabs={tabs}
            activeTabIndex={tabIndex}
            onChangeTab={handleSelectedTab}
          />

          <div className={`assessments__filter ${openFilter ? 'active' : ''}`}>
            <div className="assessments__filter__header">
              <HiOutlineArrowLeft size={24} onClick={handleCloseFilter} />
              <p className="assessments__filter__header--title">filters</p>
              <p
                style={{ cursor: 'pointer' }}
                className="assessments__filter__header--reset"
                onClick={handleResetFilter}
              >
                Reset
              </p>
            </div>
            {openFilter && (
              <div className="assessments__filter__content">
                <PermissionWrapper permission={PERMISSION.LIST_CLASS}>
                  <AppSelect
                    label="Class"
                    value={selectedClassId}
                    onChange={(event) => handleChangeClass(event.target.value)}
                    options={[{ value: '', label: 'All' }].concat(
                      classList.map((classItem: IClass) => ({
                        value: classItem._id || '',
                        label: classItem.name || ''
                      }))
                    )}
                  />
                </PermissionWrapper>
                <PermissionWrapper permission={PERMISSION.LIST_LEVEL_BREAKDOWN}>
                  <AppSelect
                    label="Level"
                    value={selectedLevelBreakdownId}
                    onChange={(event) =>
                      handleChangeLevelBreakdown(event.target.value)
                    }
                    options={[{ value: '', label: 'All' }].concat(
                      levelBreakdowns.map((levelBreakdown: IColorSetting) => ({
                        value: levelBreakdown._id,
                        label: levelBreakdown.shortName
                      }))
                    )}
                  />
                </PermissionWrapper>
                <PermissionWrapper permission={PERMISSION.LIST_INSTRUCTOR}>
                  <AppSelect
                    label="Instructor"
                    value={selectedInstructorId}
                    onChange={(event) =>
                      handleChangeInstructor(event.target.value)
                    }
                    options={[{ value: '', label: 'All' }].concat(
                      instructors.map((instructor: IInstructor) => ({
                        value: instructor._id,
                        label: instructor.firstName
                      }))
                    )}
                  />
                </PermissionWrapper>
              </div>
            )}
            <div className="assessments__filter__actions">
              <AppButton
                variant="primary"
                buttonSize="small"
                onClick={handleApplyFilter}
              >
                apply
              </AppButton>
            </div>
          </div>

          <AppCard className="assessments__table">
            <div className="assessments__table-header">
              <h2 className="assessments__table-header-title">Students</h2>
              <div className="assessments__table-header-search">
                <AppInputSearch
                  type="text"
                  placeholder="Search name, level or instructor"
                  startIcon={<HiMagnifyingGlass />}
                  value={search}
                  onChange={onChangeSearch}
                  onClearSearch={onClearSearch}
                />
                {(hasPermission(PERMISSION.LIST_LEVEL_BREAKDOWN) ||
                  hasPermission(PERMISSION.LIST_CLASS) ||
                  hasPermission(PERMISSION.LIST_INSTRUCTOR)) && (
                  <AppButton
                    variant="secondary"
                    buttonSize="small"
                    onClick={handleOpenFilter}
                  >
                    <GoFilter size={22} />
                    Filters {__appliedFilter}
                  </AppButton>
                )}
                <AppSelect
                  label="Result"
                  noFloatingLabel
                  searchable={false}
                  options={ASSESSMENT_STATUS_OPTIONS}
                  value={assessmentStatus}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    handleChangeAssessmentStatus(event.target.value)
                  }
                />
                {assessmentMode === ASSESSMENT_MODE.SINGLE &&
                  activeTab !== ASSESSMENT_REVIEW_STATUS.APPROVED && (
                    <PermissionWrapper
                      permission={PERMISSION.APPROVE_ASSESSMENT}
                    >
                      <AppButton
                        variant="primary"
                        buttonSize="small"
                        onClick={handleOpenBulkApproval}
                        disabled={__isDisabledBulkApprovalButton}
                      >
                        Bulk Approve
                      </AppButton>
                    </PermissionWrapper>
                  )}
              </div>
            </div>

            <PermissionWrapper permission={PERMISSION.LIST_LEVEL_BREAKDOWN}>
              <div className="assessments__table__levels">
                <PermissionWrapper permission={PERMISSION.LIST_COLOR_SETTING}>
                  <ColorLevelQuickFilter
                    colorSettings={levelBreakdowns}
                    onClickLevel={(levelId: string) => {
                      setSelectedLevelBreakdownId(levelId);
                      const newSetting = levelBreakdowns.map((item) => {
                        if (item._id === levelId) {
                          item.active = true;
                        } else {
                          item.active = false;
                        }
                        return item;
                      });
                      setLevelBreakdowns(newSetting);
                    }}
                    onClickAllLevel={() => setSelectedLevelBreakdownId('')}
                    levelIds={selectedLevelBreakdownId}
                    onSortColorSetting={handleSortColorSetting}
                  />
                </PermissionWrapper>
              </div>
            </PermissionWrapper>

            <div
              className={`assessments__table-content ${
                assessmentMode === ASSESSMENT_MODE.MULTIPLE
                  ? 'multiple-mode'
                  : ''
              }`}
            >
              <AppTable
                data={assessmentResults}
                columns={columns}
                pagination={{
                  index: pageIndex,
                  size: pageSize,
                  total: pageTotal
                }}
                onChangePage={(index: number, size: number) => {
                  setPageIndex(index);
                  setPageSize(size);
                }}
                loading={loading}
                activeRow={selectedAssessmentIndex}
                onSelectRow={handleSelectAssessmentResults}
                columnVisibility={{
                  _id: assessmentMode === ASSESSMENT_MODE.MULTIPLE
                }}
              />
            </div>
          </AppCard>
        </div>

        {initLoad ? null : (
          <AppCard
            className={`assessments__detail ${
              openAssessmentDetails
                ? 'showAssessmentDetails'
                : 'hideAssessmentDetails'
            }`}
          >
            <div className="assessments__detail__header">
              <p className="assessments__detail__header--title">{` ${formatData(
                selectedAssessmentResults?.student?.firstName
              )} -
              ${formatData(
                selectedAssessmentResults?.class?.levelBreakdown?.name
              )}`}</p>
              <HiXMark size={24} onClick={handleUnselectAssessmentResults} />
            </div>
            {selectedAssessmentResults?.class?.type ===
              CLASS_TYPES.ASSESSMENT_TRIAL && (
              <div className="c__classAssessmentModal-result-text">
                Assessment Result:{' '}
                {selectedAssessmentResults?.resultLevel?.name}
              </div>
            )}

            <div className="assessments__detail__content">
              <div className="skills">
                {(selectedAssessmentResults?.skillResults || []).map(
                  (skillResult: ISkillResult) => {
                    return (
                      <div key={skillResult._id} className="skill">
                        <p className="skill__title">
                          {formatData(skillResult?.name)}
                        </p>
                        <div className="skill__milestones">
                          {skillResult.milestoneScores?.length > 0 &&
                            skillResult.milestoneScores.map(
                              (milestoneScore) => {
                                return (
                                  <div
                                    key={milestoneScore.milestoneId}
                                    className="skill__milestone"
                                  >
                                    <p className="skill__milestone__title">
                                      {formatData(milestoneScore.name)}
                                    </p>
                                    <div className="skill__milestone__stars">
                                      {[...Array(maxScoreDisplay)].map(
                                        (_, scoreIndex: number) => {
                                          return (
                                            <HiStar
                                              key={scoreIndex}
                                              size={32}
                                              className={`skill__milestone__star ${
                                                milestoneScore.score >=
                                                maxScoreDisplay - scoreIndex
                                                  ? 'active'
                                                  : ''
                                              }`}
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>

            <div className="assessments__detail__actions">
              {tabIndex >= 0 ? (
                <Suspense fallback={<AppLoadingContainer />}>
                  {tabs[tabIndex].component}
                </Suspense>
              ) : null}
            </div>
          </AppCard>
        )}
      </section>

      {assessmentMode === ASSESSMENT_MODE.MULTIPLE && (
        <div className="assessments__footer">
          <AppButton
            buttonSize="small"
            variant="secondary"
            disabled={loading}
            onClick={handleCloseBulkApproval}
          >
            cancel
          </AppButton>
          <AppButton
            buttonSize="small"
            variant="primary"
            disabled={__isDisabledCreateApproveButton || loading}
            onClick={handleApprove}
          >
            approve
          </AppButton>
        </div>
      )}
    </main>
  );
};

export default AssessmentList;
