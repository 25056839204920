import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { useToast } from 'context/ToastContext';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import yupRole from 'validators/role.validator';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateRoleDto } from 'DTOs/role.dto';
import {
  HiMagnifyingGlass,
  HiMiniPlay,
  HiOutlineBookmark,
  HiOutlinePencilSquare,
  HiOutlineXCircle
} from 'react-icons/hi2';
import { BeatLoader } from 'react-spinners';
import { IRole } from 'common/interfaces/role.interface';
import AppSelect from 'common/components/AppSelect';
import AppInput from 'common/components/AppInput';
import { STAFF_TYPE_OPTIONS } from 'common/constants/index';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import {
  getPermissionList,
  getRoleDetail,
  updateRoles
} from 'services/roles.service';
import { IPermission } from 'common/interfaces/hierarchy.interface';
import AppCheckbox from 'common/components/AppCheckbox';
import { createColumnHelper } from '@tanstack/react-table';
import { IStaff } from 'common/interfaces/staff.interface';
import { STATUS_TYPE } from 'common/enums/staff.enum';
import AppToggle from 'common/components/AppToggle';
import AppTable from 'common/components/AppTable';
import useDebounce from 'common/hooks/useDebounce';
import AppInputSearch from 'common/components/AppInputSearch';
import { getStaffsByRole } from 'services/staff.service';
import { groupBy, mapValues, omit, uniq } from 'lodash';
import { HiOutlineExclamation } from 'react-icons/hi';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import './desktop.scss';

const validationSchema = yupRole.OBJECT({
  name: yupRole.ROLE_NAME,
  permissions: yupRole.ROLE_PERMISSIONS
});

const UserGroupDetail = () => {
  const params = useParams();
  const toast = useToast();

  const columnHelper = createColumnHelper<IStaff>();

  const columns = [
    columnHelper.accessor('memberId', {
      header: () => <span>ID</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('lastName', {
      header: () => <span>SURNAME, NAME</span>,
      cell: (info) => (
        <div className="line-clamp-1">
          {formatData(
            info.row.original.lastName + ',' + info.row.original.firstName
          )}
        </div>
      )
    }),
    columnHelper.accessor('dob', {
      header: () => <span>dob</span>,
      cell: (info) => formatDate(info.getValue())
    }),
    columnHelper.accessor('email', {
      header: () => <span>email</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('status', {
      header: () => <span>Status</span>,
      cell: (info) => {
        return (
          <div>
            <AppToggle
              value={info.getValue() === STATUS_TYPE.ACTIVE}
              disabled
            />
          </div>
        );
      }
    })
  ];

  const columnHelperPermission = createColumnHelper<any>();

  const columnsPermission = [
    columnHelperPermission.accessor('name', {
      header: () => <span>Permission type</span>,
      cell: (info) => (
        <div className="permissionBlock">
          <input type="checkbox" id={info.getValue()} hidden />
          <div className="permissionBlock-name">
            <label htmlFor={info.getValue()}>
              <HiMiniPlay />
            </label>
            <p>{formatData(info.getValue()?.replace('-', ' '))}</p>
          </div>
          <div className="permissionBlock-items">
            {info.row.original.permissions
              .sort((e: any) => (!e?.attached ? 1 : -1))
              .map((e: any) => {
                return (
                  <p
                    key={e.name}
                    className={`permissionBlock-item ${
                      !e?.attached ? 'inactive' : 'active'
                    }`}
                  >
                    {e.name?.replaceAll('-', ' ')}
                  </p>
                );
              })}
          </div>
        </div>
      )
    }),
    columnHelperPermission.accessor('attached', {
      header: () => (
        <span style={{ display: 'block', textAlign: 'center' }}>attached</span>
      ),
      cell: (info) => (
        <div style={{ textAlign: 'center' }}>
          {info.getValue()} / {info.row.original.total}
        </div>
      )
    })
  ];

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingAPI, setLoadingAPI] = useState<boolean>(false);
  const [loadingStaffs, setLoadingStaffs] = useState<boolean>(false);

  const [userGroup, setUserGroup] = useState<IRole | null>(null);
  const userGroupInit = useRef<IRole | null>(null);

  const [permissions, setPermissions] = useState<Array<IPermission>>([]);

  const [permissionTypes, setPermissionTypes] = useState<any>(null);
  const [selectedPermissionType, setSelectedPermissionType] =
    useState<string>('');
  const [selectedPermissions, setSelectedPermissions] = useState<Array<string>>(
    []
  );

  const [staffs, setStaffs] = useState<Array<IStaff>>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageTotal, setPageTotal] = useState<number>(-1);

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [tab, setTab] = useState<number>(0);

  const onChangeTab = (tab: 0 | 1) => {
    setTab(tab);
  };

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<UpdateRoleDto>({
    resolver: yupResolver(validationSchema) as Resolver<UpdateRoleDto>,
    defaultValues: userGroup || {}
  });

  const onOpenEdit = () => {
    setIsEdit(true);
  };

  const onCloseEdit = () => {
    setIsEdit(false);
    setUserGroup(userGroupInit.current);
    reset(userGroupInit.current || {});
    setSelectedPermissions(userGroupInit.current?.permissions || []);
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // @ts-ignore
      setUserGroup({
        ...userGroup,
        [event.target.name]: event.target.value
      });
      // @ts-ignore
      setValue(event.target.name, event.target.value);
      // @ts-ignore
      trigger(event.target.name);
    },
    [userGroup, setValue, trigger]
  );

  const handleChangePermissionType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedPermissionType(event.target.value);
  };

  const __isCheckAllByType = useMemo((): boolean => {
    // Permissions are displayed when user select type.
    let displayedPermissions: Array<string> = [];
    if (selectedPermissionType === '' || selectedPermissionType === 'all') {
      displayedPermissions = permissions.map((permission) => permission.slug);
    } else {
      displayedPermissions = permissions
        .filter((permission) => permission.type === selectedPermissionType)
        .map((permission) => permission.slug);
    }
    return displayedPermissions.every((permission) =>
      selectedPermissions.includes(permission)
    );
  }, [selectedPermissionType, permissions, selectedPermissions]);

  const handleSelectAllPermissionByType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // Permissions are displayed when user select type.
    let displayedPermissions: Array<string> = [];
    if (event.target.value === '' || event.target.value === 'all') {
      displayedPermissions = permissions.map((permission) => permission.slug);
    } else {
      displayedPermissions = permissions
        .filter((permission) => permission.type === selectedPermissionType)
        .map((permission) => permission.slug);
    }
    // -- Check is selected all by type --
    // Yes => Unselect all by type
    // No => Select all by type
    const isSelectedAll: boolean = displayedPermissions.every((permission) =>
      selectedPermissions.includes(permission)
    );
    // CASE ALL
    if (event.target.value === '' || event.target.value === 'all') {
      if (!isSelectedAll) {
        setSelectedPermissions(displayedPermissions);
      } else {
        setSelectedPermissions([]);
      }
    }
    // CASE TYPE
    else {
      if (!isSelectedAll) {
        setSelectedPermissions(
          uniq([...selectedPermissions, ...displayedPermissions])
        );
      } else {
        setSelectedPermissions(
          selectedPermissions.filter(
            (selectedPermission) =>
              !displayedPermissions.includes(selectedPermission)
          )
        );
      }
    }
  };

  const handleTogglePermission = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSelectedPermissions = [...selectedPermissions];
      const findIndex = selectedPermissions.findIndex(
        (e) => e === event.target.value
      );
      if (findIndex === -1) {
        newSelectedPermissions.push(event.target.value);
      } else {
        newSelectedPermissions.splice(findIndex, 1);
      }
      setSelectedPermissions(newSelectedPermissions);
    },
    [selectedPermissions]
  );

  const onSaveEdit = useCallback(async () => {
    if (userGroup) {
      setLoadingAPI(true);
      try {
        const payload: UpdateRoleDto = {
          name: userGroup.name,
          permissions: selectedPermissions
        };
        await updateRoles(userGroup._id, payload);
        toast.success('Saved successfully');
        // Reset
        setSelectedPermissionType('');
        fetchData();
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Failed to save user group'
        );
        setUserGroup(userGroupInit.current);
      } finally {
        setLoadingAPI(false);
        setIsEdit(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroup, selectedPermissions]);

  const __renderIcons = useMemo((): React.ReactNode => {
    if (isEdit) {
      return loadingAPI ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSaveEdit)}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
    // eslint-disable-next-line
  }, [isEdit, onSaveEdit, handleSubmit, loadingAPI]);

  const __renderContent = useMemo((): React.ReactNode => {
    if (isEdit) {
      return (
        <>
          <AppCardContent>
            <div className="userGroupDetail__content-fields">
              <AppInput
                label="Name*"
                {...register('name')}
                value={userGroup?.name}
                onChange={handleChange}
                message={{
                  type: 'error',
                  text: errors?.name?.message || ''
                }}
              />
              <AppSelect
                label="Staff Type*"
                options={STAFF_TYPE_OPTIONS}
                value={userGroup?.staffType || ''}
                disabled
              />
            </div>
          </AppCardContent>
          <AppCard className="userGroupDetail__content-permissions userGroupDetail__content-permissions-edit">
            <AppSelect
              label="Permission Types"
              options={[
                {
                  label: 'all',
                  value: ''
                }
              ].concat(
                Object.keys(permissionTypes).map((type: string) => {
                  return {
                    label: type?.replace('-', ' '),
                    value: type
                  };
                })
              )}
              value={selectedPermissionType}
              onChange={handleChangePermissionType}
            />
            <div className="userGroupDetail__content-permissions-all">
              <AppCheckbox
                label={`All ${
                  selectedPermissionType === '' ||
                  selectedPermissionType === 'all'
                    ? ''
                    : selectedPermissionType?.replaceAll('-', ' ')
                } permissions`}
                value={selectedPermissionType}
                checked={__isCheckAllByType}
                onChange={handleSelectAllPermissionByType}
              />
            </div>
            <div className="userGroupDetail__content-permissions-list">
              {!!permissions &&
                permissions.map((permission) => {
                  return permission.type === selectedPermissionType ||
                    selectedPermissionType === '' ||
                    selectedPermissionType === 'all' ? (
                    <div
                      key={permission.slug}
                      className="userGroupDetail__content-permissions-item"
                    >
                      <AppCheckbox
                        label={permission.name.replaceAll('-', ' ')}
                        checked={selectedPermissions.includes(permission.slug)}
                        value={permission.slug}
                        onChange={handleTogglePermission}
                      />
                    </div>
                  ) : null;
                })}
            </div>
          </AppCard>
        </>
      );
    }
    return (
      <>
        <AppCardContent>
          <div className="userGroupDetail__content-fields">
            <AppCardContentItem
              subtitle="Name"
              title={formatData(userGroup?.name)}
            />
            <AppCardContentItem
              subtitle="Staff Type"
              title={formatData(userGroup?.staffType)}
            />
          </div>
        </AppCardContent>
        <AppCardContent>
          <div className="tabContainer">
            <div className="tabs">
              <div
                className={`tab ${tab === 0 ? 'tab-active' : ''}`}
                onClick={() => onChangeTab(0)}
              >
                Users
              </div>
              <div
                className={`tab ${tab === 1 ? 'tab-active' : ''}`}
                onClick={() => onChangeTab(1)}
              >
                Permissions
              </div>
            </div>
            {tab === 0 && (
              <div className="tabPanel">
                {staffs?.length > 0 ? (
                  <div className="staffs__table">
                    <div className="staffs__table-header">
                      <div className="staffs__table-header-search">
                        <AppInputSearch
                          type="text"
                          value={search}
                          onChange={onChangeSearch}
                          placeholder="Surname, name or email"
                          onClearSearch={onClearSearch}
                          startIcon={<HiMagnifyingGlass />}
                        />
                      </div>
                    </div>
                    <div className="staffs__table-content">
                      <AppTable
                        data={staffs}
                        columns={columns}
                        pagination={{
                          index: pageIndex,
                          size: pageSize,
                          total: pageTotal
                        }}
                        onChangePage={(index: number, size: number) => {
                          setPageIndex(index);
                          setPageSize(size);
                        }}
                        loading={loadingStaffs}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="userGroupDetail__content-permissions-noItem">
                    <HiOutlineExclamation /> This user group is now empty.
                  </div>
                )}
              </div>
            )}
            {tab === 1 && (
              <div className="tabPanel">
                <div className="staffs__table">
                  <div className="staffs__table-content">
                    <AppTable
                      data={Object.keys(permissionTypes).map(
                        (permissionType) => {
                          return {
                            name: permissionType,
                            permissions: permissionTypes[permissionType],
                            attached: permissionTypes[permissionType].filter(
                              (e: any) => !!e?.attached
                            )?.length,
                            total: permissionTypes[permissionType]?.length
                          };
                        }
                      )}
                      columns={columnsPermission}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </AppCardContent>
      </>
    );
    // eslint-disable-next-line
  }, [
    isEdit,
    handleChange,
    register,
    errors,
    userGroup,
    handleTogglePermission,
    permissionTypes,
    permissions,
    selectedPermissionType,
    selectedPermissions,
    loadingStaffs,
    staffs,
    search,
    tab,
    columnsPermission
  ]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const [dataRoleDetail, dataPermissionList] = await Promise.all([
        getRoleDetail(params?.id || ''),
        getPermissionList()
      ]);
      setUserGroup(dataRoleDetail);
      userGroupInit.current = dataRoleDetail;

      setPermissions(dataPermissionList);
      const tempPermissionTypes: Record<string, any> = mapValues(
        groupBy(dataPermissionList, 'type'),
        (dataPermission: Array<IRole>) =>
          dataPermission.map((permission: IRole) => omit(permission, 'type'))
      );
      // eslint-disable-next-line
      Object.keys(tempPermissionTypes).map((tempPermissionType) => {
        for (const index in tempPermissionTypes[tempPermissionType]) {
          if (
            dataRoleDetail?.permissions.includes(
              tempPermissionTypes[tempPermissionType][index].slug
            )
          ) {
            tempPermissionTypes[tempPermissionType][index].attached = true;
          } else {
            tempPermissionTypes[tempPermissionType][index].attached = false;
          }
        }
      });
      setPermissionTypes(tempPermissionTypes);
      setSelectedPermissions(dataRoleDetail?.permissions);
    } catch (error) {
      setUserGroup(null);
      setPermissions([]);
    } finally {
      setLoading(false);
    }
  }, [params?.id]);

  const fetchDataStaffs = useCallback(async () => {
    setLoadingStaffs(true);
    try {
      const result = await getStaffsByRole(
        params?.id || '',
        pageIndex,
        pageSize,
        search
      );
      setStaffs(result.data.data.data);
      setPageTotal(result.data.data.total);
    } catch (error) {
      setStaffs([]);
    } finally {
      setLoadingStaffs(false);
    }
    // eslint-disable-next-line
  }, [params?.id, debouncedSearch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchDataStaffs();
  }, [fetchDataStaffs]);

  return (
    <main className="userGroupDetailPage">
      <AppBreadCrumb
        items={[
          { name: 'User Groups', href: '/user-groups' },
          { name: 'User Group Details', href: '' }
        ]}
      />
      {loading ? (
        <AppLoadingContainer />
      ) : (
        <div className="layoutContainer userGroupDetail">
          <AppCard>
            <AppCardHeader
              title="User Group"
              suffix={
                <PermissionWrapper permission={PERMISSION.UPDATE_ROLE}>
                  {__renderIcons}
                </PermissionWrapper>
              }
            />
            {__renderContent}
          </AppCard>
        </div>
      )}
    </main>
  );
};

export default UserGroupDetail;
