import React, { InputHTMLAttributes } from 'react';
import './styles.scss';

interface IAppRadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const AppRadio = React.forwardRef<HTMLInputElement, IAppRadioProps>(
  (props, ref) => {
    const { label } = props;

    return (
      <label className="c__radio">
        {!!label && label}
        <input
          ref={ref}
          className="c__radio-input"
          type="radio"
          hidden={!label}
          {...props}
        />
        <span
          className={`c__radio-checkMark ${
            !!label ? '' : 'c__radio-checkMark-noLabel'
          }`}
        ></span>
      </label>
    );
  }
);

export default AppRadio;
