import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateInstructorPersonalInformationDTO } from 'DTOs/instructor.dto';
import PermissionWrapper from 'components/PermissionWrapper';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppDatePicker from 'common/components/AppDatePicker';
import AppInput from 'common/components/AppInput';
import AppSelect from 'common/components/AppSelect';
import AppToggle from 'common/components/AppToggle';
import { GENDER_TYPE_OPTIONS } from 'common/constants/index';
import { useToast } from 'context/ToastContext';
import dayjs from 'dayjs';
import { PERMISSION } from 'common/enums/permission.enum';
import { STATUS_TYPE } from 'common/enums/staff.enum';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { IInstructor } from 'common/interfaces/instructor.interface';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { HiOutlineBookmark, HiOutlineXCircle } from 'react-icons/hi';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import { BeatLoader } from 'react-spinners';
import { updateInstructor } from 'services/instructor.service';
import yup from 'validators/instructor.validators';
import { useBrandLocation } from 'context/BrandLocationContext';
import AppSelection from 'common/components/AppSelection';
import { ILevelBreakdown } from 'common/interfaces/levelBreakdown.interface';
import { getLevelBreakdowns } from 'services/levelBreakdown.service';
import LocationListInfo from 'components/ListInfo';
import AppLoadingContainer from 'common/components/AppLoadingContainer';

const validationSchema = yup.OBJECT({
  status: yup.INSTRUCTOR_STATUS,
  firstName: yup.INSTRUCTOR_NAME,
  lastName: yup.INSTRUCTOR_LAST_NAME,
  displayName: yup.INSTRUCTOR_DISPLAY_NAME,
  dob: yup.INSTRUCTOR_DOB,
  gender: yup.INSTRUCTOR_GENDER,
  locationIds: yup.INSTRUCTOR_LOCATION_IDS,
  isAllLocation: yup.INSTRUCTOR_ALL_LOCATION,
  levelIds: yup.INSTRUCTOR_LEVELS,
  isAllLevel: yup.INSTRUCTOR_ALL_LEVEL
});

interface Props {
  state: IInstructor;
  onSuccess: () => void;
}

const PersonalInformation = (props: Props) => {
  const { state, onSuccess } = props;
  const toast = useToast();
  const { locations, isLoadLocations } = useBrandLocation();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [instructor, setInstructor] = useState<IInstructor>({
    ...state,
    levelIds: state.levels?.map((item) => item._id)
  });
  const [loading, setLoading] = useState(false);
  const [levelList, setLevelList] = useState<ILevelBreakdown[]>([]);

  const [isAllFetched, setIsAllFetched] = useState(false);

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<UpdateInstructorPersonalInformationDTO>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<UpdateInstructorPersonalInformationDTO>,
    defaultValues: instructor
  });

  const __locationPlaceholderText: string = useMemo(() => {
    return instructor.locationIds.length === 0
      ? 'Select option'
      : `${instructor.locationIds.length} selected`;
  }, [instructor.locationIds]);

  const locationList = useMemo(() => {
    return locations?.map((item) => {
      return {
        label: item.shortName || item.name,
        value: item._id
      };
    });
  }, [locations]);

  const __levelPlaceholderText: string = useMemo(() => {
    return !instructor.levelIds?.length
      ? 'Select option'
      : `${instructor.levelIds?.length} selected`;
  }, [instructor.levelIds]);

  const fetchLevelList = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await getLevelBreakdowns(
        1,
        100,
        undefined,
        undefined,
        instructor.locationIds
      );
      setLevelList(
        data.data.data.map((item: ILevelBreakdown) => ({
          ...item,
          value: item._id,
          label: item.name
        }))
      );
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Failed fetching levels');
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [instructor.locationIds]);

  useEffect(() => {
    fetchLevelList();
  }, [fetchLevelList]);

  const handleToggleLevel = useCallback(
    (value: string) => {
      const levels = [...instructor.levelIds];
      const findIndex = levels.findIndex((item) => item === value);
      if (findIndex === -1) {
        levels.push(value);
      } else {
        levels.splice(findIndex, 1);
      }
      setInstructor({
        ...instructor,
        levelIds: levels
      });
      setValue('levelIds', levels);
      trigger('levelIds');
    },
    // eslint-disable-next-line
    [instructor]
  );
  const handleSelectAllLevel = useCallback(() => {
    const values = levelList?.map((level) => level.value) || [];
    if (instructor.levelIds?.length === levelList?.length) {
      setInstructor({
        ...instructor,
        levelIds: []
      });
      setValue('levelIds', []);
    } else {
      setInstructor({
        ...instructor,
        levelIds: values
      });
      setValue('levelIds', values);
    }
    trigger('levelIds');
  }, [instructor, levelList, setValue, trigger]);

  const handleToggleLocation = useCallback(
    (value: string) => {
      const locations = [...instructor.locationIds];
      const findIndex = locations.findIndex((item) => item === value);
      if (findIndex === -1) {
        locations.push(value);
      } else {
        locations.splice(findIndex, 1);
      }
      setInstructor({
        ...instructor,
        locationIds: locations,
        levelIds: []
      });
      setValue('locationIds', locations);
      setValue('levelIds', []);
      trigger('locationIds');
      trigger('levelIds');
    },
    [instructor, setValue, trigger]
  );

  const handleSelectAllLocation = useCallback(() => {
    const values = locationList?.map((location) => location.value);
    if (instructor.locationIds.length === locationList?.length) {
      setInstructor({
        ...instructor,
        locationIds: [],
        levelIds: []
      });
      setValue('locationIds', []);
      setValue('levelIds', []);
      trigger('levelIds');
    } else {
      setInstructor({
        ...instructor,
        locationIds: values
      });
      setValue('locationIds', values);
    }
    trigger('locationIds');
    // eslint-disable-next-line
  }, [instructor, locationList]);

  useEffect(() => {
    setInstructor({
      ...state,
      locationIds: state.locations.map((item) => item._id),
      levelIds: state.levels?.map((item) => item._id),
      isAllLevel: state.additionalInfo?.isAllLevel || false
    });

    setValue('levelIds', state.levels?.map((item) => item._id) || []);
  }, [state, setValue]);

  const handleOpenEdit = () => {
    setIsEdit(true);
  };

  const handleCloseEdit = useCallback(() => {
    const valueInstructor = {
      ...state,
      locationIds: state.locations.map((item) => item._id),
      levelIds: state.levels?.map((item) => item._id),
      isAllLevel: state.additionalInfo?.isAllLevel || false
    };
    setInstructor(valueInstructor);
    reset(valueInstructor);
    setIsEdit(false);
  }, [state, reset]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInstructor({
        ...instructor,
        [event.target.name]: event.target.value
      });
      // @ts-ignore
      setValue(event.target.name, event.target.value);
      trigger();
    },
    [instructor, setValue, trigger]
  );

  const onSubmit = useCallback(
    async (data: UpdateInstructorPersonalInformationDTO) => {
      // Custom error
      setLoading(true);
      try {
        const payload: UpdateInstructorPersonalInformationDTO = {
          firstName: data.firstName,
          lastName: data.lastName,
          displayName: data.displayName,
          dob: convertToUnixTime(String(data.dob)),
          gender: data.gender,
          status: data.status,
          isAllLocation: instructor.locationIds.length === locationList?.length,
          locationIds: data.locationIds,
          isAllLevel: instructor.levelIds.length === levelList?.length,
          levelIds: data.levelIds
        };
        await updateInstructor(state._id, payload);

        reset({
          ...instructor
        });
        handleCloseEdit();
        onSuccess();
      } catch (error: any) {
        toast.error(
          error.response.data.message || 'Failed to update instructor'
        );
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [instructor, reset, setIsEdit]
  );

  const __renderIcons = useMemo((): React.ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSubmit)}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={handleCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={handleOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
  }, [isEdit, loading, onSubmit, handleCloseEdit, handleSubmit]);

  const handleChangeStatus = useCallback(() => {
    const newStatus =
      instructor.status === STATUS_TYPE.ACTIVE
        ? STATUS_TYPE.INACTIVE
        : STATUS_TYPE.ACTIVE;
    setInstructor({
      ...instructor,
      status: newStatus
    });
    setValue('status', newStatus);
  }, [instructor, setValue]);

  const handleChangeDOB = useCallback(
    (value: dayjs.Dayjs | null) => {
      setInstructor({
        ...instructor,
        dob: value?.format('YYYY-MM-DD') || ''
      });
      setValue('dob', value?.format('YYYY-MM-DD') || '');
      trigger('dob');
    },
    [instructor, setValue, trigger]
  );
  const __renderContent = useMemo((): React.ReactNode => {
    if (isEdit) {
      return (
        <>
          <div className="customToggle">
            <AppToggle
              value={instructor.status === STATUS_TYPE.ACTIVE}
              {...register('status')}
              onChange={handleChangeStatus}
            />
            <p>Active</p>
          </div>
          <AppInput
            label="Name*"
            value={instructor.firstName}
            {...register('firstName')}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.firstName?.message || ''
            }}
          />
          <AppInput
            label="LastName*"
            value={instructor.lastName}
            {...register('lastName')}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.lastName?.message || ''
            }}
          />
          <AppInput
            label="Display Name*"
            value={instructor.displayName}
            {...register('displayName')}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.displayName?.message || ''
            }}
          />
          <AppDatePicker
            label="DOB*"
            value={dayjs(instructor.dob)}
            onChange={handleChangeDOB}
            message={{
              type: 'error',
              text: errors?.dob?.message || ''
            }}
            disableFuture
          />
          <AppSelect
            label="Gender*"
            options={GENDER_TYPE_OPTIONS}
            {...register('gender')}
            value={instructor.gender}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.gender?.message || ''
            }}
          />
          <AppSelection
            label="Location*"
            options={locationList}
            selectedIds={instructor.locationIds}
            onSelect={handleToggleLocation}
            onSelectAll={handleSelectAllLocation}
            message={{
              type: 'error',
              text: errors?.locationIds?.message || ''
            }}
            placeholder={__locationPlaceholderText}
            size="large"
            isSearch
          />
          <AppSelection
            label="Level*"
            options={levelList}
            onSelect={handleToggleLevel}
            onSelectAll={handleSelectAllLevel}
            selectedIds={instructor.levelIds}
            placeholder={__levelPlaceholderText}
            size="large"
            message={{
              type: 'error',
              text: errors?.levelIds?.message || ''
            }}
            isSearch
          />
        </>
      );
    }
    return (
      <>
        <div className="customToggle">
          <AppToggle
            value={instructor.status === STATUS_TYPE.ACTIVE}
            disabled
          />
          <p>Active</p>
        </div>
        <AppCardContentItem
          subtitle="Name"
          title={formatData(instructor.firstName)}
        />
        <AppCardContentItem
          subtitle="Surname"
          title={formatData(instructor.lastName)}
        />
        <AppCardContentItem
          subtitle="Display Name"
          title={formatData(instructor.displayName)}
        />
        <AppCardContentItem subtitle="DOB" title={formatDate(instructor.dob)} />
        <AppCardContentItem
          subtitle="Gender"
          title={formatData(instructor.gender)}
        />
        <AppCardContentItem subtitle="Location" isHtml>
          <LocationListInfo locations={instructor.locations} />
        </AppCardContentItem>
        {instructor.isAllLevel ? (
          <AppCardContentItem subtitle="Level" title={'All Levels'} />
        ) : (
          <AppCardContentItem subtitle="Level" isHtml>
            <LocationListInfo locations={instructor.levels} />
          </AppCardContentItem>
        )}
        <AppCardContentItem
          subtitle="Employee ID"
          title={formatData(instructor.memberId)}
        />
      </>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEdit,
    instructor,
    handleChange,
    handleChangeStatus,
    handleChangeDOB,
    errors,
    register,
    locationList,
    handleToggleLocation,
    __locationPlaceholderText,
    handleSelectAllLocation,
    __levelPlaceholderText,
    handleToggleLevel,
    handleSelectAllLevel,
    levelList,
    errors?.levelIds
  ]);

  useEffect(() => {
    setIsAllFetched(isLoadLocations);
  }, [isLoadLocations]);

  return (
    <section className="personalInformation">
      {isAllFetched ? <AppLoadingContainer /> : null}
      <AppCard>
        <AppCardHeader
          title="PERSONAL INFORMATION"
          suffix={
            <PermissionWrapper permission={PERMISSION.UPDATE_INSTRUCTOR}>
              {__renderIcons}
            </PermissionWrapper>
          }
        />
        <AppCardContent className={`cardContent ${isEdit ? 'edit' : ''}`}>
          {__renderContent}
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default memo(PersonalInformation);
