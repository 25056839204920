import { memo, useCallback, useEffect, useRef } from 'react';
import { MAX_IDLE_TIMEOUT } from 'common/constants';
import { useAuth } from 'context/AuthContext';
import { useLocation } from 'react-router-dom';

const whitelist = ['/login'];
const __isDevEnv = process.env.REACT_APP_BASE_ENV === 'DEV_ENV';

const IdleTimer = () => {
  const { logout } = useAuth();
  const location = useLocation();

  let timeout = useRef<NodeJS.Timeout | null>(null);

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const handleAutoLogout = useCallback(() => {
    if (timeout?.current) {
      clearTimeout(timeout?.current);
    }

    timeout.current = setTimeout(() => {
      handleLogout();
    }, MAX_IDLE_TIMEOUT);
  }, [handleLogout]);

  const _onMouseMove = useCallback(() => {
    handleAutoLogout();
  }, [handleAutoLogout]);

  useEffect(() => {
    // Whitelist certain pages
    let preventReset = false;

    for (const path of whitelist) {
      if (path === location?.pathname) {
        preventReset = true;
      }
    }

    if (preventReset || __isDevEnv) {
      return;
    }

    // initiate timeout
    handleAutoLogout();

    // listen for mouse events
    window?.addEventListener('mousemove', _onMouseMove);

    // cleanup fc
    return () => {
      if (timeout?.current) {
        clearTimeout(timeout?.current);
        window?.removeEventListener('mousemove', _onMouseMove);
      }
    };
  }, [location?.pathname, _onMouseMove, handleAutoLogout]);
  return <div />;
};

export default memo(IdleTimer);
