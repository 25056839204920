import { object as OBJECT, boolean, number, string } from 'yup';

const CLASS_TEMPLATE_NAME = string().required(
  'Please provide class template name'
);
const CLASS_TEMPLATE_DURATION = string().required(
  'Please select class template duration'
);
const CLASS_TEMPLATE_TERM = string().required(
  'Please select Session'
);
const CLASS_TEMPLATE_DESCRIPTION = string().required(
  'Please provide class template description'
);
const CLASS_TEMPLATE_LEVEL = string().required(
  'Please provide class template level'
);
const CLASS_TEMPLATE_PRICE = number()
  .typeError('Price must be a number')
  .min(0, 'Price must be greater than 0');
const CLASS_TEMPLATE_ACTIVE = boolean();

const validations = {
  OBJECT,
  CLASS_TEMPLATE_NAME,
  CLASS_TEMPLATE_DURATION,
  CLASS_TEMPLATE_TERM,
  CLASS_TEMPLATE_DESCRIPTION,
  CLASS_TEMPLATE_ACTIVE,
  CLASS_TEMPLATE_PRICE,
  CLASS_TEMPLATE_LEVEL
};
export default validations;
