import React, { ButtonHTMLAttributes } from 'react';
import { HiOutlineDocumentArrowDown } from 'react-icons/hi2';
import styles from './styles.module.scss';

interface IReportExportButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
    label?: string;
  }

const ReportExportButton = React.forwardRef<
  HTMLButtonElement,
  IReportExportButtonProps
>((props, ref) => {
  return (
    <button ref={ref} {...props} className={styles.reportExportButton}>
      <HiOutlineDocumentArrowDown size={22} />
      {props.label || 'Export'}
    </button>
    
  );
});

export default ReportExportButton;
