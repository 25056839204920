import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  ReactNode
} from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import useLocalStorage from 'common/hooks/useLocalStorage';
import { KEY_STEP_CONTROLLER } from 'common/constants/localStorage.constant';
import { useBrandLocation } from 'context/BrandLocationContext';
import { useToast } from 'context/ToastContext';
import { getSchedulesClass } from 'services/schedules.service';
import { getStudentList } from 'services/students.service';
import { IResponsiblePerson } from 'common/interfaces/responsiblePerson.interface';
import { IClass } from 'common/interfaces/class.interface';
import { IBookingData, ISchedule } from 'common/interfaces/schedules.interface';
import {
  BOOKING_ERROR_UNION_TYPE,
  CustomFormForStudentEnrollment,
  CustomStudentEnrollment,
  DataSetFlowBookingClass,
  IClassBooked,
  IClassesBooked,
  IResponsiblePersonBooked,
  PAYMENT_METHOD_TYPE,
  PAYMENT_TYPE,
  ScheduleGroupByWeek,
  UPDATE_TYPE_OPTIONS,
  UpdateEnrollmentList
} from 'common/interfaces/bookingClass.interface';
import {
  IStudent,
  IStudentTableFilter
} from 'common/interfaces/student.interface';
import { FLOW_ENUM } from 'common/enums/step.enum';
import {
  BOOKING_ERROR_TYPE,
  BOOKING_TYPE,
  PAYMENT_METHOD
} from 'common/enums/classBooking.enum';
import { PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import { getNextDebitDay } from 'services/payment.service';
import { FlowTypes } from 'common/interfaces/stepController.interface';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { TERM_TYPE } from 'common/enums/term.enum';
import { getTerms } from 'services/term.service';
import { ITerm } from 'common/interfaces/term.interface';
import { handleGetPriceDirectDebit } from 'common/helpers/classBooking.helper';
import {
  __isGoBackToStep5,
  checkBookingClassData,
  checkIfAllEnrollmentDataHavePaymentTypeSelected,
  checkIfAnyEnrollmentTypeBefore,
  getBillingCycle,
  handleCheckTotalSlotSelected,
  handleClearSTform,
  handleSumAllFinalPayment,
  handleSumAllMakeupPayment,
  handleSumAllNextDirectDebitPayment
} from 'common/helpers/classListBooking.helper';
import { IVoucher } from 'common/interfaces/voucher.interface';
import { getStudentsActiveOngoingOfRP } from 'services/responsiblePerson.service';
import { sortBy } from 'lodash';
import { handleGetDataWithDiscountClassListFlow } from 'pages/classes/ClassBooking/helper';

interface StepControllerContextType {
  dataSet?: DataSetFlowBookingClass;
}

interface Props {
  children: ReactNode;
}

const SUPPORT_DOMAIN = 'classes';

const StepControllerContext = createContext<StepControllerContextType | null>(
  null
);

export const useStepController = (): StepControllerContextType => {
  const stepControllerContext = useContext(StepControllerContext);
  if (!stepControllerContext) {
    throw new Error(
      'useStepController must be used within an stepControllerContext'
    );
  }
  return stepControllerContext;
};

export const StepControllerProvider = ({ children }: Props) => {
  const toast = useToast();

  const [storedStepData, setStoredStepData] =
    useLocalStorage<DataSetFlowBookingClass>(KEY_STEP_CONTROLLER, null);
  const location = useLocation();
  const { selectedLocation: globalLocation } = useBrandLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [dataSet, setDataSet] = useState<DataSetFlowBookingClass>(null);

  const fetchActiveOngoingStudents = useCallback(
    async (responsiblePersonId: string) => {
      if (!responsiblePersonId) return;
      try {
        const { data } = await getStudentsActiveOngoingOfRP(
          responsiblePersonId
        );
        return data.data;
      } catch (error) {}
    },
    []
  );

  const handleApplyPromoCode = useCallback(
    (
      voucher: IVoucher | undefined,
      classesData: CustomStudentEnrollment,
      studentId: string
    ) => {
      let result: DataSetFlowBookingClass = null;

      const classId = classesData.info._id;

      setDataSet((dataSet) => {
        if (
          dataSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
          (dataSet?.step === 6 || dataSet?.step === 7)
        ) {
          result = {
            ...dataSet,
            responsiblePersonsBooked: dataSet?.responsiblePersonsBooked?.map(
              (RPdata) => {
                return {
                  ...RPdata,
                  students: RPdata?.students?.map((STdata) => {
                    if (STdata?.info?._id === studentId) {
                      return {
                        ...STdata,
                        classes: STdata?.classes?.map((classData) => {
                          if (
                            classData?.info?._id === classId &&
                            classData.form.schedulesBooked?.[0]?._id ===
                              classesData.form.schedulesBooked?.[0]._id
                          ) {
                            if (
                              classData.form.paymentType ===
                              PAYMENT_VALUE.DIRECT_DEBIT
                            ) {
                              return {
                                ...classData,
                                voucher,
                                form: {
                                  ...classData?.form,
                                  billingCycle: {
                                    info: {
                                      ...classData?.form?.billingCycle?.info
                                    },
                                    cycles: getBillingCycle(
                                      { ...classData, voucher },
                                      dataSet?.nextDebitDay,
                                      dataSet?.subtractTerms
                                    )
                                  }
                                }
                              };
                            } else {
                              return {
                                ...classData,
                                voucher
                              };
                            }
                          }
                          return classData;
                        })
                      };
                    }
                    return STdata;
                  })
                };
              }
            )
          };
        }

        return result;
      });
    },
    []
  );

  const handlePaymentAmount = useCallback(
    (schedulesGroupByWeek?: ScheduleGroupByWeek[]) => {
      setDataSet((pre) => {
        let result: DataSetFlowBookingClass = null;

        if (
          pre?.flow === FLOW_ENUM.CLASS_BOOKING &&
          (pre?.step === 6 || pre?.step === 7)
        ) {
          result = {
            ...pre,
            responsiblePersonsBooked: pre?.responsiblePersonsBooked?.map(
              (RPdata) => {
                const students = RPdata?.students?.map((STdata) => {
                  let newCustomBookingData: CustomStudentEnrollment[] = [];

                  const selectedClasses = STdata?.classes?.filter(
                    (classData) => classData?.form?.classBooked
                  );

                  if (!selectedClasses.length) return STdata;

                  for (const classesData of STdata?.classes) {
                    if (checkBookingClassData(classesData)) {
                      if (
                        classesData?.form?.enrollmentType ===
                        BOOKING_TYPE.HOLIDAY_PROGRAM
                      ) {
                        let holidaySchedules: ISchedule[][] = [];

                        classesData.form.schedulesBooked?.forEach((item) => {
                          const result = schedulesGroupByWeek?.find(
                            (schedule) =>
                              schedule.schedules?.[0].startTime ===
                              item.startTime
                          );

                          if (result) {
                            holidaySchedules = [
                              ...holidaySchedules,
                              [...result.schedules]
                            ];
                          }
                        });

                        const customBookingDataList = holidaySchedules.map(
                          (item) => {
                            return {
                              ...classesData,
                              form: {
                                ...classesData.form,
                                schedulesBooked: item
                              }
                            };
                          }
                        );

                        newCustomBookingData = [
                          ...newCustomBookingData,
                          ...customBookingDataList
                        ];
                      } else {
                        const newClassesData =
                          classesData.form.schedulesBooked?.map((schedule) => {
                            return {
                              ...classesData,
                              form: {
                                ...classesData.form,
                                schedulesBooked: [schedule]
                              }
                            };
                          });

                        newCustomBookingData = [
                          ...newCustomBookingData,
                          ...sortBy(newClassesData, 'startTime')
                        ];
                      }

                      newCustomBookingData = [...newCustomBookingData]?.map(
                        (classData) => {
                          const customClassItem: IBookingData = {
                            _id: classData?.info?._id,
                            classInfo: classData?.info,
                            schedulesUntilNextDD:
                              classData?.schedulesUntilNextDD,
                            schedulesUntilNextDD2:
                              classData?.schedulesUntilNextDD2,
                            activeTermSchedules: classData?.activeTermSchedules,
                            schedules: classData?.schedules
                              ?.map((schedule) => {
                                if (
                                  !!classData?.form?.schedulesBooked?.filter(
                                    (scheduleBooked) =>
                                      scheduleBooked?._id === schedule?._id
                                  )?.length
                                ) {
                                  return { ...schedule, checked: true };
                                }

                                return { ...schedule, checked: false };
                              })
                              .filter((schedule) => schedule?.checked),
                            enrollmentType: classData?.form?.enrollmentType
                          } as IBookingData;

                          const newPrice = handleGetPriceDirectDebit(
                            pre?.subtractTerms,
                            customClassItem
                          );

                          if (checkBookingClassData(classData)) {
                            if (
                              classData?.info?.type === CLASS_TYPES?.SERIES ||
                              classData?.info?.type === CLASS_TYPES?.PRIVATE
                            ) {
                              if (
                                classData?.form?.enrollmentType ===
                                BOOKING_TYPE?.CASUAL_CLASS
                              ) {
                                return {
                                  ...classData,
                                  form: {
                                    ...classData?.form,
                                    paymentType: PAYMENT_VALUE?.UPFRONT,
                                    totalPrice: newPrice,
                                    payNow: newPrice
                                  }
                                };
                              }

                              if (
                                classData?.form?.enrollmentType ===
                                BOOKING_TYPE?.ONGOING_CLASS
                              ) {
                                return {
                                  ...classData,
                                  form: {
                                    ...classData?.form,
                                    paymentType: PAYMENT_VALUE?.IDLE,
                                    totalPrice: newPrice,
                                    payNow: 0
                                  }
                                };
                              }

                              if (
                                classData?.form?.enrollmentType ===
                                BOOKING_TYPE?.MAKE_UP
                              ) {
                                return {
                                  ...classData,
                                  form: {
                                    ...classData?.form,
                                    paymentType: PAYMENT_VALUE?.MAKE_CREDIT,
                                    totalPrice:
                                      classData?.form?.schedulesBooked?.length,
                                    payNow:
                                      classData?.form?.schedulesBooked?.length
                                  }
                                };
                              }
                            }

                            if (
                              classData?.info?.type ===
                                CLASS_TYPES?.ASSESSMENT_TRIAL ||
                              classData?.info?.type ===
                                CLASS_TYPES?.INTENSIVE_HOLIDAY_PROGRAM
                            ) {
                              return {
                                ...classData,
                                form: {
                                  ...classData?.form,
                                  paymentType: PAYMENT_VALUE?.UPFRONT,
                                  totalPrice: newPrice,
                                  payNow: newPrice
                                }
                              };
                            }

                            if (
                              classData?.form?.paymentType ===
                              PAYMENT_VALUE?.UPFRONT
                            ) {
                              return {
                                ...classData,
                                form: {
                                  ...classData?.form,
                                  totalPrice: newPrice,
                                  payNow: newPrice
                                }
                              };
                            }

                            if (
                              classData?.form?.paymentType ===
                              PAYMENT_VALUE?.DIRECT_DEBIT
                            ) {
                              if (pre?.nextDebitDay) {
                                return {
                                  ...classData,
                                  form: {
                                    ...classData?.form,
                                    totalPrice: newPrice,
                                    payNow:
                                      getBillingCycle(
                                        classData,
                                        pre?.nextDebitDay,
                                        pre?.subtractTerms,
                                        true
                                      )?.[0]?.price ?? 0,
                                    billingCycle: {
                                      info: {
                                        name:
                                          STdata?.info?.lastName +
                                          ', ' +
                                          STdata?.info?.firstName
                                      },
                                      cycles: getBillingCycle(
                                        classData,
                                        pre?.nextDebitDay,
                                        pre?.subtractTerms
                                      )
                                    }
                                  }
                                };
                              }

                              return {
                                ...classData,
                                form: {
                                  ...classData?.form,
                                  totalPrice: newPrice,
                                  billingCycle: {
                                    info: {
                                      name:
                                        STdata?.info?.lastName +
                                        ', ' +
                                        STdata?.info?.firstName
                                    },
                                    cycles: []
                                  }
                                }
                              };
                            }

                            return {
                              ...classData,
                              form: {
                                ...classData?.form,
                                totalPrice: newPrice
                              }
                            };
                          }

                          return {
                            ...classData,
                            form: {
                              ...classData?.form,
                              totalPrice: 0
                            }
                          };
                        }
                      );
                    } else {
                      newCustomBookingData = [
                        ...newCustomBookingData,
                        classesData
                      ];
                    }
                  }

                  if (!!newCustomBookingData?.length) {
                    return {
                      ...STdata,
                      classes: newCustomBookingData
                    };
                  } else {
                    return STdata;
                  }
                });

                const newStudentsWithAutoDiscount =
                  handleGetDataWithDiscountClassListFlow(
                    students,
                    RPdata?.info?.activeOngoingStudents
                  );

                return {
                  ...RPdata,
                  students: newStudentsWithAutoDiscount
                };
              }
            ),
            handleApplyPromoCode
          };

          if (checkIfAllEnrollmentDataHavePaymentTypeSelected(result)) {
            return {
              ...result,
              paymentMethod: PAYMENT_METHOD?.VIVA_PAY,
              paymentAmount: handleSumAllFinalPayment(result),
              totalCreditAmount: handleSumAllMakeupPayment(result),
              totalNextDirectDebitPaymentAmount:
                handleSumAllNextDirectDebitPayment(result),
              step: 7 as const
            };
          } else {
            return {
              ...result,
              handleApplyPromoCode,
              paymentMethod: PAYMENT_METHOD?.VIVA_PAY,
              paymentAmount: handleSumAllFinalPayment(result),
              totalCreditAmount: handleSumAllMakeupPayment(result),
              totalNextDirectDebitPaymentAmount:
                handleSumAllNextDirectDebitPayment(result),
              step: 6 as const
            };
          }
        }

        return pre;
      });
    },
    [handleApplyPromoCode]
  );

  const handlePaymentMethod = useCallback(
    (paymentMethod: PAYMENT_METHOD_TYPE) => {
      setDataSet((pre: DataSetFlowBookingClass) => {
        let result: DataSetFlowBookingClass = null;

        if (
          pre?.flow === FLOW_ENUM.CLASS_BOOKING &&
          (pre?.step === 6 || pre?.step === 7)
        ) {
          if (paymentMethod === PAYMENT_METHOD?.VIVA_PAY) {
            result = {
              ...pre,
              step: 7,
              handleApplyPromoCode,
              paymentMethod
            };
          } else {
            result = {
              ...pre,
              step: 6,
              paymentMethod,
              handleApplyPromoCode,
              handlePaymentAmount
            };
          }

          if (checkIfAllEnrollmentDataHavePaymentTypeSelected(result)) {
            return { ...result, step: 7 as const };
          } else {
            return { ...result, handleApplyPromoCode, step: 6 as const };
          }
        }

        return pre;
      });
    },
    [handlePaymentAmount, handleApplyPromoCode]
  );

  const handleChangeClassPaymentType = useCallback(
    (
      STuuid: string,
      classItem: CustomStudentEnrollment,
      newPaymentType: PAYMENT_TYPE
    ) => {
      const { _id: classId } = classItem.info;

      setDataSet((pre: DataSetFlowBookingClass) => {
        let result: DataSetFlowBookingClass = null;

        if (
          pre?.flow === FLOW_ENUM.CLASS_BOOKING &&
          (pre?.step === 6 || pre?.step === 7)
        ) {
          result = {
            ...pre,
            handleApplyPromoCode,
            responsiblePersonsBooked: pre?.responsiblePersonsBooked?.map(
              (RPdata) => {
                if (!!RPdata?.students?.length) {
                  const newStudents: CustomFormForStudentEnrollment[] =
                    RPdata?.students?.map((STdata) => {
                      if (
                        !!STdata?.classes?.length &&
                        STdata?.info?._id === STuuid
                      ) {
                        return {
                          ...STdata,
                          classes: STdata?.classes?.map((classesData) => {
                            if (!classesData.form.classBooked) {
                              return classesData;
                            }
                            if (
                              !!classesData?.form?.schedulesBooked?.length &&
                              classesData?.info?._id === classId &&
                              classesData.form.schedulesBooked[0]?._id ===
                                classItem?.form?.schedulesBooked[0]?._id
                            ) {
                              if (newPaymentType === PAYMENT_VALUE.UPFRONT) {
                                return {
                                  ...classesData,
                                  form: {
                                    ...classesData?.form,
                                    paymentType: PAYMENT_VALUE.UPFRONT,
                                    payNow: classesData?.form?.totalPrice
                                  }
                                };
                              }

                              if (
                                newPaymentType === PAYMENT_VALUE.DIRECT_DEBIT
                              ) {
                                return {
                                  ...classesData,
                                  form: {
                                    ...classesData?.form,
                                    paymentType: PAYMENT_VALUE.DIRECT_DEBIT,
                                    payNow: getBillingCycle(
                                      classesData,
                                      pre?.nextDebitDay,
                                      pre?.subtractTerms,
                                      true
                                    )?.[0]?.price,
                                    billingCycle: {
                                      info: {
                                        name:
                                          STdata?.info?.lastName +
                                          ', ' +
                                          STdata?.info?.firstName
                                      },
                                      cycles: getBillingCycle(
                                        classesData,
                                        pre?.nextDebitDay,
                                        pre?.subtractTerms
                                      )
                                    }
                                  }
                                };
                              }

                              if (newPaymentType === PAYMENT_VALUE.IDLE) {
                                return {
                                  ...classesData,
                                  form: {
                                    ...classesData?.form,
                                    paymentType: PAYMENT_VALUE.IDLE
                                  }
                                };
                              }
                            }
                            return classesData;
                          })
                        };
                      }

                      return STdata;
                    });

                  const newStudentsWithAutoDiscount =
                    handleGetDataWithDiscountClassListFlow(
                      newStudents,
                      RPdata.info.activeOngoingStudents
                    );

                  return {
                    ...RPdata,
                    students: newStudentsWithAutoDiscount.map((student) => {
                      return {
                        ...student,
                        classes: student?.classes?.map((classesData) => {
                          if (
                            classesData.form.paymentType ===
                            PAYMENT_VALUE.DIRECT_DEBIT
                          ) {
                            return {
                              ...classesData,
                              form: {
                                ...classesData?.form,
                                billingCycle: {
                                  ...classesData?.form?.billingCycle,
                                  cycles: getBillingCycle(
                                    classesData,
                                    pre?.nextDebitDay,
                                    pre?.subtractTerms
                                  )
                                }
                              }
                            };
                          }
                          return classesData;
                        })
                      };
                    })
                  };
                }

                return RPdata;
              }
            )
          };

          if (checkIfAllEnrollmentDataHavePaymentTypeSelected(result)) {
            return {
              ...result,
              paymentAmount: handleSumAllFinalPayment(result),
              totalCreditAmount: handleSumAllMakeupPayment(result),
              totalNextDirectDebitPaymentAmount:
                handleSumAllNextDirectDebitPayment(result),
              handleApplyPromoCode,
              step: 7 as const
            };
          } else {
            return {
              ...result,
              paymentAmount: handleSumAllFinalPayment(result),
              totalCreditAmount: handleSumAllMakeupPayment(result),
              totalNextDirectDebitPaymentAmount:
                handleSumAllNextDirectDebitPayment(result),
              handleApplyPromoCode,
              step: 6 as const
            };
          }
        }

        return pre;
      });
    },
    [handleApplyPromoCode]
  );

  const handleUpdateListEnrollment = useCallback(
    (params: UpdateEnrollmentList) => {
      const { updateType, STuuid, classId } = params;

      let result: DataSetFlowBookingClass = null;

      const SLOT_ENUM = 1;
      const MINIMUM_CREDIT_SCORE_REQUIRED = 1;

      let newError: BOOKING_ERROR_UNION_TYPE = BOOKING_ERROR_TYPE?.NONE;

      const handleCheckIfEnrollmentTypeChangeWithEnrollmentTypeExists = (
        responsiblePersonsBooked: IResponsiblePersonBooked[]
      ): boolean => {
        return !!(
          updateType === UPDATE_TYPE_OPTIONS.ENROLLMENT_TYPE &&
          params?.enrollmentType &&
          checkIfAnyEnrollmentTypeBefore(
            responsiblePersonsBooked,
            STuuid,
            classId
          ) &&
          updateType === UPDATE_TYPE_OPTIONS.ENROLLMENT_TYPE &&
          (params?.enrollmentType === BOOKING_TYPE?.CASUAL_CLASS ||
            params?.enrollmentType === BOOKING_TYPE.ONGOING_CLASS ||
            params?.enrollmentType === BOOKING_TYPE?.MAKE_UP)
        );
      };

      const updatedCurrentStudentCreditScore = (
        STdata: CustomFormForStudentEnrollment
      ): number => {
        let newCreditScore: number = STdata?.info?.totalCredit;

        const getCurrentClassByClassId = STdata?.classes?.filter(
          (classesData) => classesData?.info?._id === classId
        )?.[0];

        if (updateType === UPDATE_TYPE_OPTIONS.CLASS_BOOKED) {
          // When un-check the class booked, restore the credit base on the schedule been booked

          for (const classesData of STdata?.classes) {
            if (classesData?.info?._id === classId) {
              if (
                checkBookingClassData(classesData) &&
                classesData?.form?.enrollmentType === BOOKING_TYPE?.MAKE_UP
              ) {
                newCreditScore += classesData?.form?.schedulesBooked?.length;
                newError = BOOKING_ERROR_TYPE?.NONE;
              } else {
                newCreditScore -= classesData?.form?.schedulesBooked?.length;
              }
            }
          }
        }

        if (updateType === UPDATE_TYPE_OPTIONS.SCHEDULES_BOOKED) {
          const { enrollmentType } = getCurrentClassByClassId?.form;
          const { scheduleId } = params;

          const isScheduleExist =
            !!getCurrentClassByClassId?.form?.schedulesBooked?.filter(
              (schedule) => schedule?._id === params?.scheduleId
            )?.length;

          const getScheduleByScheduleId =
            getCurrentClassByClassId?.schedules?.filter(
              (schedule) => schedule?._id === params?.scheduleId
            );

          const isHaveEnoughSlot =
            getScheduleByScheduleId?.[0]?.capacity -
              getScheduleByScheduleId?.[0]?.occupied >=
            SLOT_ENUM;

          for (const schedule of getCurrentClassByClassId?.schedules) {
            if (enrollmentType === BOOKING_TYPE?.MAKE_UP) {
              if (schedule?._id === scheduleId) {
                if (isScheduleExist) {
                  newCreditScore += SLOT_ENUM;

                  newError = BOOKING_ERROR_TYPE?.NO_SLOT;
                }

                const isHaveEnoughCreditScore =
                  isHaveEnoughSlot &&
                  STdata?.info?.totalCredit >= MINIMUM_CREDIT_SCORE_REQUIRED;

                if (isHaveEnoughCreditScore) {
                  newCreditScore -= SLOT_ENUM;

                  newError = BOOKING_ERROR_TYPE?.NONE;
                }
              }
            }
          }
        }

        return newCreditScore;
      };

      const handleResetCreditScore = (
        STdata: CustomFormForStudentEnrollment
      ): number => {
        const currentClass = STdata?.classes?.filter(
          (cl) => cl?.info?._id === classId
        )?.[0];

        if (
          updateType === UPDATE_TYPE_OPTIONS.ENROLLMENT_TYPE &&
          currentClass?.form?.enrollmentType === BOOKING_TYPE?.MAKE_UP
        ) {
          let preCreditScore = STdata?.info?.totalCredit;

          for (const classesData of STdata?.classes) {
            if (
              classesData?.info?._id === classId &&
              checkBookingClassData(classesData) &&
              classesData?.form?.enrollmentType === BOOKING_TYPE?.MAKE_UP &&
              !!classesData?.form?.schedulesBooked?.length
            ) {
              preCreditScore += classesData?.form?.schedulesBooked?.length;
              newError = BOOKING_ERROR_TYPE?.NONE;
            }
          }

          return preCreditScore;
        }

        return STdata?.info?.totalCredit;
      };

      const resetStudents = (
        RPdata: IResponsiblePersonBooked
      ): CustomFormForStudentEnrollment[] => {
        return RPdata?.students?.map((STdata) => {
          if (STdata?.info?._id === STuuid) {
            return {
              ...STdata,
              info: {
                ...STdata?.info,
                totalCredit: handleResetCreditScore(STdata)
              },
              classes: STdata?.classes?.map((classData) => {
                if (
                  classData?.info?._id === classId &&
                  updateType === UPDATE_TYPE_OPTIONS.ENROLLMENT_TYPE &&
                  !!params?.enrollmentType
                ) {
                  return {
                    ...classData,
                    schedules: classData?.schedules?.map((schedule) => {
                      const foundScheduleBooked =
                        !!classData?.form?.schedulesBooked?.filter(
                          (scheduleBooked) =>
                            scheduleBooked?._id === schedule?._id
                        )?.length;

                      if (foundScheduleBooked) {
                        return {
                          ...schedule,
                          occupied: schedule?.occupied - SLOT_ENUM
                        };
                      }

                      return schedule;
                    }),
                    form: {
                      ...classData?.form,
                      enrollmentType: params?.enrollmentType,
                      schedulesBooked: [],
                      error: newError,
                      payNow: 0,
                      totalPrice: 0,
                      paymentType: PAYMENT_VALUE.IDLE
                    },
                    voucher: undefined,
                    automaticDiscount: undefined
                  };
                }

                return classData;
              })
            };
          }

          const newResetOthersScheduleClassBooked = (
            classData: CustomStudentEnrollment,
            allSTdata: CustomFormForStudentEnrollment[]
          ): ISchedule[] => {
            const getCurrentStudentData = allSTdata?.filter(
              (STdata) => STdata?.info?._id === STuuid
            )?.[0];

            const getCurrentClassData = getCurrentStudentData?.classes?.filter(
              (classData) => classData?.info?._id === classId
            )?.[0];

            if (
              updateType === UPDATE_TYPE_OPTIONS.ENROLLMENT_TYPE &&
              params?.enrollmentType
            ) {
              return classData?.schedules?.map((schedule) => {
                const foundScheduleBooked =
                  !!getCurrentClassData?.form?.schedulesBooked?.filter(
                    (scheduleBooked) => scheduleBooked?._id === schedule?._id
                  )?.length;

                if (foundScheduleBooked) {
                  return {
                    ...schedule,
                    occupied: schedule?.occupied - SLOT_ENUM
                  };
                }

                return schedule;
              });
            }

            return classData?.schedules;
          };

          return {
            ...STdata,
            classes: STdata?.classes?.map((classData) => {
              if (
                classData?.info?._id === classId &&
                !!classData?.schedules?.length
              ) {
                return {
                  ...classData,
                  schedules: newResetOthersScheduleClassBooked(
                    classData,
                    RPdata?.students
                  )
                };
              }

              return classData;
            })
          };
        });
      };

      const newOtherStudentsGlobalSchedules = (
        classData: CustomStudentEnrollment,
        allSTdata: CustomFormForStudentEnrollment[]
      ): ISchedule[] => {
        const getCurrentStudentData = allSTdata?.filter(
          (STdata) => STdata?.info?._id === STuuid
        )?.[0];

        const getCurrentClassData = getCurrentStudentData?.classes?.filter(
          (classData) => classData?.info?._id === classId
        )?.[0];

        if (updateType === UPDATE_TYPE_OPTIONS.CLASS_BOOKED) {
          return classData?.schedules?.map((schedule) => {
            const foundScheduleBooked =
              !!getCurrentClassData?.form?.schedulesBooked?.filter(
                (scheduleBooked) => scheduleBooked?._id === schedule?._id
              )?.length;

            if (foundScheduleBooked) {
              return {
                ...schedule,
                occupied: schedule?.occupied - SLOT_ENUM
              };
            }

            return schedule;
          });
        }

        if (updateType === UPDATE_TYPE_OPTIONS.SCHEDULES_BOOKED) {
          const { enrollmentType } = getCurrentClassData?.form;
          const { scheduleId } = params;

          const isScheduleExist =
            !!getCurrentClassData?.form?.schedulesBooked?.filter(
              (schedule) => schedule?._id === params?.scheduleId
            )?.length;

          const getScheduleByScheduleId = classData?.schedules?.filter(
            (schedule) => schedule?._id === params?.scheduleId
          );

          const isHaveEnoughSlot =
            getScheduleByScheduleId?.[0]?.capacity -
              getScheduleByScheduleId?.[0]?.occupied >=
            SLOT_ENUM;

          if (
            classData?.info?.type === CLASS_TYPES?.SERIES ||
            classData?.info?.type === CLASS_TYPES?.PRIVATE
          ) {
            if (
              (enrollmentType === BOOKING_TYPE?.CASUAL_CLASS ||
                enrollmentType === BOOKING_TYPE.ONGOING_CLASS) &&
              scheduleId
            ) {
              return classData?.schedules?.map((schedule) => {
                if (schedule?._id === scheduleId) {
                  if (isScheduleExist) {
                    return {
                      ...schedule,
                      occupied: schedule?.occupied - SLOT_ENUM
                    };
                  }

                  if (isHaveEnoughSlot) {
                    return {
                      ...schedule,
                      occupied: schedule?.occupied + SLOT_ENUM
                    };
                  }
                }

                return schedule;
              });
            }

            if (enrollmentType === BOOKING_TYPE?.MAKE_UP && scheduleId) {
              return classData?.schedules?.map((schedule) => {
                if (schedule?._id === scheduleId) {
                  if (isScheduleExist) {
                    return {
                      ...schedule,
                      occupied: schedule?.occupied - SLOT_ENUM
                    };
                  }

                  const isHaveEnoughCreditScore =
                    isHaveEnoughSlot &&
                    getCurrentStudentData?.info?.totalCredit >=
                      MINIMUM_CREDIT_SCORE_REQUIRED;

                  if (isHaveEnoughCreditScore) {
                    return {
                      ...schedule,
                      occupied: schedule?.occupied + SLOT_ENUM
                    };
                  }
                }

                return schedule;
              });
            }
          }

          if (classData?.info?.type === CLASS_TYPES?.ASSESSMENT_TRIAL) {
            return classData?.schedules?.map((schedule) => {
              if (schedule?._id === scheduleId) {
                if (isScheduleExist) {
                  return {
                    ...schedule,
                    occupied: schedule?.occupied - SLOT_ENUM
                  };
                }

                if (isHaveEnoughSlot) {
                  return {
                    ...schedule,
                    occupied: schedule?.occupied + SLOT_ENUM
                  };
                }
              }

              if (
                !!getCurrentClassData?.form?.schedulesBooked?.filter(
                  (scheduleBooked) => scheduleBooked?._id === schedule?._id
                )?.length
              ) {
                return {
                  ...schedule,
                  occupied: schedule?.occupied - SLOT_ENUM
                };
              }

              return schedule;
            });
          }

          if (
            classData?.info?.type === CLASS_TYPES?.INTENSIVE_HOLIDAY_PROGRAM
          ) {
            return classData?.schedules?.map((schedule) => {
              if (schedule?._id === scheduleId) {
                if (isScheduleExist) {
                  return {
                    ...schedule,
                    occupied: schedule?.occupied - SLOT_ENUM
                  };
                }

                if (isHaveEnoughSlot) {
                  return {
                    ...schedule,
                    occupied: schedule?.occupied + SLOT_ENUM
                  };
                }
              }

              return schedule;
            });
          }
        }

        return classData?.schedules;
      };

      const updatedOtherStudentClasses = (
        STdata: CustomFormForStudentEnrollment,
        allSTdata: CustomFormForStudentEnrollment[]
      ): CustomStudentEnrollment[] => {
        if (!STdata?.classes?.length) {
          console.error(`This ST id:${STdata?.info?._id} don't have any class`);

          return [];
        }

        if (
          updateType === UPDATE_TYPE_OPTIONS.CLASS_BOOKED ||
          (updateType === UPDATE_TYPE_OPTIONS.ENROLLMENT_TYPE &&
            params?.enrollmentType) ||
          updateType === UPDATE_TYPE_OPTIONS.SCHEDULES_BOOKED
        ) {
          return STdata?.classes?.map((classData) => {
            if (
              classData?.info?._id === classId &&
              !!classData?.schedules?.length
            ) {
              return {
                ...classData,
                schedules: newOtherStudentsGlobalSchedules(classData, allSTdata)
              };
            }

            return classData;
          });
        }

        return STdata?.classes;
      };

      const newCurrentStudentGlobalSchedules = (
        classData: CustomStudentEnrollment,
        STdata: CustomFormForStudentEnrollment
      ): ISchedule[] => {
        if (updateType === UPDATE_TYPE_OPTIONS.CLASS_BOOKED) {
          return classData?.schedules?.map((schedule) => {
            const foundScheduleBooked =
              !!classData?.form?.schedulesBooked?.filter(
                (scheduleBooked) => scheduleBooked?._id === schedule?._id
              )?.length;

            if (foundScheduleBooked) {
              newError = BOOKING_ERROR_TYPE?.NONE;

              return {
                ...schedule,
                occupied: schedule?.occupied - SLOT_ENUM
              };
            }

            return schedule;
          });
        }

        if (updateType === UPDATE_TYPE_OPTIONS.SCHEDULES_BOOKED) {
          const { enrollmentType } = classData?.form;
          const { scheduleId } = params;

          const isScheduleExist = !!classData?.form?.schedulesBooked?.filter(
            (schedule) => schedule?._id === params?.scheduleId
          )?.length;

          const getScheduleByScheduleId = classData?.schedules?.filter(
            (schedule) => schedule?._id === params?.scheduleId
          );

          const isHaveEnoughSlot =
            getScheduleByScheduleId?.[0]?.capacity -
              getScheduleByScheduleId?.[0]?.occupied >=
            SLOT_ENUM;

          if (
            classData?.info?.type === CLASS_TYPES?.SERIES ||
            classData?.info?.type === CLASS_TYPES?.PRIVATE
          ) {
            if (
              (enrollmentType === BOOKING_TYPE?.CASUAL_CLASS ||
                enrollmentType === BOOKING_TYPE.ONGOING_CLASS) &&
              scheduleId
            ) {
              return classData?.schedules?.map((schedule) => {
                if (schedule?._id === scheduleId) {
                  if (isScheduleExist) {
                    newError = BOOKING_ERROR_TYPE?.NONE;

                    return {
                      ...schedule,
                      occupied: schedule?.occupied - SLOT_ENUM
                    };
                  }

                  if (isHaveEnoughSlot) {
                    newError = BOOKING_ERROR_TYPE?.NONE;

                    return {
                      ...schedule,
                      occupied: schedule?.occupied + SLOT_ENUM
                    };
                  } else {
                    newError = BOOKING_ERROR_TYPE?.NO_SLOT;

                    console.error(
                      `we have no slot to change capacity case ${enrollmentType}`
                    );
                  }
                }

                return schedule;
              });
            }

            if (enrollmentType === BOOKING_TYPE?.MAKE_UP && scheduleId) {
              return classData?.schedules?.map((schedule) => {
                if (schedule?._id === scheduleId) {
                  if (isScheduleExist) {
                    newError = BOOKING_ERROR_TYPE?.NONE;

                    return {
                      ...schedule,
                      occupied: schedule?.occupied - SLOT_ENUM
                    };
                  }

                  const isHaveEnoughCreditScore =
                    isHaveEnoughSlot &&
                    STdata?.info?.totalCredit >= MINIMUM_CREDIT_SCORE_REQUIRED;

                  if (isHaveEnoughCreditScore) {
                    newError = BOOKING_ERROR_TYPE?.NONE;

                    return {
                      ...schedule,
                      occupied: schedule?.occupied + SLOT_ENUM
                    };
                  } else {
                    if (
                      STdata?.info?.totalCredit < MINIMUM_CREDIT_SCORE_REQUIRED
                    ) {
                      newError = BOOKING_ERROR_TYPE?.NO_CREDIT;
                      console.error(
                        `we have no credit ${STdata?.info?.totalCredit} case ${enrollmentType}`
                      );
                    } else {
                      newError = BOOKING_ERROR_TYPE?.NO_SLOT;
                      console.error(
                        `we have no slot to change capacity case ${enrollmentType}`
                      );
                    }
                  }
                }

                return schedule;
              });
            }
          }

          if (classData?.info?.type === CLASS_TYPES?.ASSESSMENT_TRIAL) {
            return classData?.schedules?.map((schedule) => {
              if (schedule?._id === scheduleId) {
                if (isScheduleExist) {
                  newError = BOOKING_ERROR_TYPE?.NONE;

                  return {
                    ...schedule,
                    occupied: schedule?.occupied - SLOT_ENUM
                  };
                }

                if (isHaveEnoughSlot) {
                  newError = BOOKING_ERROR_TYPE?.NONE;

                  return {
                    ...schedule,
                    occupied: schedule?.occupied + SLOT_ENUM
                  };
                } else {
                  newError = BOOKING_ERROR_TYPE?.NO_SLOT;

                  console.error(
                    `we have no slot to change capacity case ${enrollmentType}`
                  );
                }
              }

              if (
                !!classData?.form?.schedulesBooked?.filter(
                  (scheduleBooked) => scheduleBooked?._id === schedule?._id
                )?.length
              ) {
                return {
                  ...schedule,
                  occupied: schedule?.occupied - SLOT_ENUM
                };
              }

              return schedule;
            });
          }

          if (
            classData?.info?.type === CLASS_TYPES?.INTENSIVE_HOLIDAY_PROGRAM
          ) {
            return classData?.schedules?.map((schedule) => {
              if (schedule?._id === scheduleId) {
                if (isScheduleExist) {
                  newError = BOOKING_ERROR_TYPE?.NONE;

                  return {
                    ...schedule,
                    occupied: schedule?.occupied - SLOT_ENUM
                  };
                }

                if (isHaveEnoughSlot) {
                  newError = BOOKING_ERROR_TYPE?.NONE;

                  return {
                    ...schedule,
                    occupied: schedule?.occupied + SLOT_ENUM
                  };
                } else {
                  newError = BOOKING_ERROR_TYPE?.NO_SLOT;

                  console.error(
                    `we have no slot to change capacity case ${enrollmentType}`
                  );
                }
              }

              return schedule;
            });
          }
        }

        return classData?.schedules;
      };

      const newCurrentStudentFormScheduleBooked = (
        classData: CustomStudentEnrollment,
        STdata: CustomFormForStudentEnrollment
      ): ISchedule[] => {
        if (updateType === UPDATE_TYPE_OPTIONS.SCHEDULES_BOOKED) {
          const { enrollmentType } = classData?.form;

          const isScheduleExist = !!classData?.form?.schedulesBooked?.filter(
            (schedule) => schedule?._id === params?.scheduleId
          )?.length;

          const getScheduleByScheduleId = classData?.schedules?.filter(
            (schedule) => schedule?._id === params?.scheduleId
          );

          const removedScheduleByScheduleId = [
            ...classData?.form?.schedulesBooked
          ]?.filter((schedule) => schedule?._id !== params?.scheduleId);

          const isHaveEnoughSlot =
            getScheduleByScheduleId?.[0]?.capacity -
              getScheduleByScheduleId?.[0]?.occupied >=
            SLOT_ENUM;

          if (
            classData?.info?.type === CLASS_TYPES?.SERIES ||
            classData?.info?.type === CLASS_TYPES?.PRIVATE
          ) {
            if (
              enrollmentType === BOOKING_TYPE?.CASUAL_CLASS ||
              enrollmentType === BOOKING_TYPE.ONGOING_CLASS
            ) {
              if (isScheduleExist) {
                return removedScheduleByScheduleId;
              }

              if (isHaveEnoughSlot) {
                return [
                  ...classData?.form?.schedulesBooked,
                  ...getScheduleByScheduleId
                ];
              }
            }

            if (enrollmentType === BOOKING_TYPE?.MAKE_UP) {
              if (isScheduleExist) {
                return removedScheduleByScheduleId;
              }

              const isHaveEnoughCreditScore =
                isHaveEnoughSlot &&
                STdata?.info?.totalCredit >= MINIMUM_CREDIT_SCORE_REQUIRED;

              if (isHaveEnoughCreditScore) {
                return [
                  ...classData?.form?.schedulesBooked,
                  ...getScheduleByScheduleId
                ];
              }
            }
          }

          if (classData?.info?.type === CLASS_TYPES?.ASSESSMENT_TRIAL) {
            if (isScheduleExist) {
              return removedScheduleByScheduleId;
            }

            if (
              isHaveEnoughSlot &&
              classData?.form?.schedulesBooked?.length <= SLOT_ENUM
            ) {
              return getScheduleByScheduleId;
            }
          }

          if (
            classData?.info?.type === CLASS_TYPES?.INTENSIVE_HOLIDAY_PROGRAM
          ) {
            if (isScheduleExist) {
              return removedScheduleByScheduleId;
            }

            if (isHaveEnoughSlot) {
              return [
                ...classData?.form?.schedulesBooked,
                ...getScheduleByScheduleId
              ];
            }
          }
        }

        return classData?.form?.schedulesBooked;
      };

      const updatedCurrentStudentClasses = (
        STdata: CustomFormForStudentEnrollment
      ): CustomStudentEnrollment[] => {
        if (!STdata?.classes?.length) {
          console.error(`This ST id:${STdata?.info?._id} don't have any class`);

          return [];
        }

        if (updateType === UPDATE_TYPE_OPTIONS.CLASS_BOOKED) {
          return STdata?.classes?.map((classData) => {
            if (classData?.info?._id === classId) {
              const isClassBooked = !!classData?.form?.classBooked;

              if (
                classData?.info?.type === CLASS_TYPES?.SERIES ||
                classData?.info?.type === CLASS_TYPES?.PRIVATE
              ) {
                return {
                  ...classData,
                  schedules: newCurrentStudentGlobalSchedules(
                    classData,
                    STdata
                  ),
                  form: {
                    ...classData?.form,
                    classBooked: !isClassBooked,
                    enrollmentType: !isClassBooked
                      ? classData?.form?.enrollmentType
                      : BOOKING_TYPE?.NONE,
                    schedulesBooked: !isClassBooked
                      ? classData?.form?.schedulesBooked
                      : [],
                    error: newError,
                    payNow: !isClassBooked ? classData?.form?.payNow : 0,
                    totalPrice: !isClassBooked ? classData?.form?.totalPrice : 0
                  }
                };
              }

              if (classData?.info?.type === CLASS_TYPES?.ASSESSMENT_TRIAL) {
                return {
                  ...classData,
                  schedules: newCurrentStudentGlobalSchedules(
                    classData,
                    STdata
                  ),
                  form: {
                    ...classData?.form,
                    classBooked: !isClassBooked,
                    enrollmentType: !isClassBooked
                      ? BOOKING_TYPE?.ASSESSMENT_TRIAL
                      : BOOKING_TYPE?.NONE,
                    schedulesBooked: !isClassBooked
                      ? classData?.form?.schedulesBooked
                      : [],
                    error: newError,
                    payNow: !isClassBooked ? classData?.form?.payNow : 0,
                    totalPrice: !isClassBooked ? classData?.form?.totalPrice : 0
                  }
                };
              }

              if (
                classData?.info?.type === CLASS_TYPES?.INTENSIVE_HOLIDAY_PROGRAM
              ) {
                return {
                  ...classData,
                  schedules: newCurrentStudentGlobalSchedules(
                    classData,
                    STdata
                  ),
                  form: {
                    ...classData?.form,
                    classBooked: !isClassBooked,
                    enrollmentType: !isClassBooked
                      ? BOOKING_TYPE?.HOLIDAY_PROGRAM
                      : BOOKING_TYPE?.NONE,
                    schedulesBooked: !isClassBooked
                      ? classData?.form?.schedulesBooked
                      : [],
                    error: newError,
                    payNow: !isClassBooked ? classData?.form?.payNow : 0,
                    totalPrice: !isClassBooked ? classData?.form?.totalPrice : 0
                  }
                };
              }
            }
            return classData;
          });
        }

        if (
          updateType === UPDATE_TYPE_OPTIONS.ENROLLMENT_TYPE &&
          !!params?.enrollmentType
        ) {
          return STdata?.classes?.map((classData) => {
            if (classData?.info?._id === classId) {
              return {
                ...classData,
                schedules: newCurrentStudentGlobalSchedules(classData, STdata),
                form: {
                  ...classData?.form,
                  enrollmentType: params?.enrollmentType,
                  schedulesBooked: newCurrentStudentFormScheduleBooked(
                    classData,
                    STdata
                  ),
                  error: newError,
                  payNow: 0,
                  totalPrice: 0,
                  paymentType: PAYMENT_VALUE?.IDLE
                }
              };
            }
            return classData;
          });
        }

        if (updateType === UPDATE_TYPE_OPTIONS.SCHEDULES_BOOKED) {
          return STdata?.classes?.map((classData) => {
            if (
              classData?.info?._id === classId &&
              !!classData?.schedules?.length
            ) {
              const isClassBooked = !!classData?.form?.classBooked;

              return {
                ...classData,
                schedules: newCurrentStudentGlobalSchedules(classData, STdata),
                form: {
                  ...classData?.form,
                  schedulesBooked: newCurrentStudentFormScheduleBooked(
                    classData,
                    STdata
                  ),
                  error: newError,
                  payNow: !isClassBooked ? classData?.form?.payNow : 0,
                  totalPrice: !isClassBooked ? classData?.form?.totalPrice : 0
                }
              };
            }

            return classData;
          });
        }

        return STdata?.classes;
      };

      const newStudents = (
        RPdata: IResponsiblePersonBooked
      ): CustomFormForStudentEnrollment[] => {
        if (!RPdata?.students?.length) {
          console.error("This RP don't have any students");
          return [];
        }

        return RPdata?.students?.map((STdata) => {
          if (STdata?.info?._id === STuuid) {
            return {
              ...STdata,
              info: {
                ...STdata?.info,
                totalCredit: updatedCurrentStudentCreditScore(STdata)
              },
              classes: updatedCurrentStudentClasses(STdata)
            };
          }

          return {
            ...STdata,
            classes: updatedOtherStudentClasses(STdata, RPdata?.students)
          };
        });
      };

      setDataSet((pre) => {
        if (
          pre?.flow === FLOW_ENUM.CLASS_BOOKING &&
          (pre.step === 4 || pre.step === 5)
        ) {
          // reset schedule booked when change enrollment type
          if (
            updateType === UPDATE_TYPE_OPTIONS.ENROLLMENT_TYPE &&
            !!params?.enrollmentType &&
            handleCheckIfEnrollmentTypeChangeWithEnrollmentTypeExists(
              pre?.responsiblePersonsBooked
            )
          ) {
            result = {
              ...pre,
              responsiblePersonsBooked: pre?.responsiblePersonsBooked?.map(
                (RPdata) => {
                  return {
                    ...RPdata,
                    students: resetStudents(RPdata)
                  };
                }
              )
            };
          } else {
            result = {
              ...pre,
              responsiblePersonsBooked: pre?.responsiblePersonsBooked?.map(
                (RPdata) => {
                  return {
                    ...RPdata,
                    students: newStudents(RPdata)
                  };
                }
              )
            };
          }

          if (handleCheckTotalSlotSelected(result) >= SLOT_ENUM) {
            return { ...result, step: 5 as const };
          } else {
            return { ...result, step: 4 as const };
          }
        }

        return pre;
      });
    },
    []
  );

  const handleGetNextDebitDay = useCallback(() => {
    if (!globalLocation?._id) {
      console.error('no globalLocation?._id provided');

      return;
    }

    try {
      Promise?.all([
        getNextDebitDay(globalLocation?._id),
        getTerms(
          1,
          1000,
          globalLocation?._id,
          undefined,
          [TERM_TYPE.STOP_BILLING],
          true
        )
      ]).then((res) => {
        const nextDebitDay = res?.[0]?.data?.data as string;
        const subtractTerms = res?.[1].data.data?.data as ITerm[];

        setDataSet((pre) => {
          if (pre?.flow === FLOW_ENUM?.CLASS_BOOKING && pre?.step === 4) {
            return { ...pre, nextDebitDay, subtractTerms };
          }

          return pre;
        });
      });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fetch next debit day failed'
      );
    }

    // eslint-disable-next-line
  }, [globalLocation?._id]);

  const handleGetListEnrollmentByRP = useCallback(
    async (
      responsiblePersonId: string,
      callback?: (isLoading: boolean) => void
    ) => {
      try {
        if (!responsiblePersonId || !globalLocation?._id) {
          console.error('no RP id provided');

          return;
        }

        if (callback) callback(true);

        const params: IStudentTableFilter = {
          locationId: globalLocation?._id,
          limit: 100,
          page: 1,
          responsiblePersonId: responsiblePersonId
        };

        const { data } = await getStudentList(params);

        let result: DataSetFlowBookingClass = null;

        if (!data?.length) {
          console.error(
            `No student found from this RP id:${responsiblePersonId}`
          );

          return;
        }

        const newFetchedStudents = (
          classList: IClassesBooked[]
        ): CustomFormForStudentEnrollment[] => {
          const mapped: CustomFormForStudentEnrollment[] = data?.map(
            (STdata: IStudent) => {
              return {
                info: {
                  ...STdata,
                  totalCredit:
                    (!!STdata?.creditScore ? STdata?.creditScore : 0) +
                    (!!STdata?.noExpiredCreditScore
                      ? STdata?.noExpiredCreditScore
                      : 0),
                  initTotalCredit:
                    (!!STdata?.creditScore ? STdata?.creditScore : 0) +
                    (!!STdata?.noExpiredCreditScore
                      ? STdata?.noExpiredCreditScore
                      : 0)
                },
                classes: classList?.map((classBooked) => {
                  return {
                    ...classBooked,
                    form: {
                      classBooked: false,
                      enrollmentType: BOOKING_TYPE?.NONE,
                      schedulesBooked: [],
                      totalPrice: 0,
                      payNow: 0,
                      error: BOOKING_ERROR_TYPE?.NONE,
                      paymentType: PAYMENT_VALUE?.IDLE
                    }
                  };
                })
              };
            }
          );

          return mapped;
        };

        setDataSet((pre) => {
          if (
            pre?.flow === FLOW_ENUM.CLASS_BOOKING &&
            pre.step === 4 &&
            !!pre?.responsiblePersonsBooked?.length &&
            !!pre?.classesBooked?.length
          ) {
            const isNewFetch =
              !pre?.responsiblePersonsBooked?.[0]?.students?.filter(
                (STdata) =>
                  !!STdata?.classes?.filter(
                    (classData) => !!classData?.form?.classBooked
                  )?.length
              )?.length;

            result = {
              ...pre,
              responsiblePersonsBooked: pre?.responsiblePersonsBooked?.map(
                (RPdata) => {
                  return {
                    ...RPdata,
                    students: isNewFetch
                      ? newFetchedStudents(pre?.classesBooked)
                      : RPdata?.students
                  };
                }
              )
            };

            return result;
          }

          if (
            pre?.flow === FLOW_ENUM.CLASS_BOOKING &&
            pre.step === 4 &&
            (!pre?.responsiblePersonsBooked?.length ||
              !pre?.classesBooked?.length)
          ) {
            console.error(
              `Missing data responsiblePersonsBooked: ${pre?.responsiblePersonsBooked}; classesBooked: ${pre?.classesBooked}`
            );
          }

          return pre;
        });
      } catch (error: any) {
        console.error(
          error?.response?.data?.message ||
            `Fetch student found from this RP id:${responsiblePersonId}`
        );

        setDataSet((pre) => {
          if (
            pre?.flow === FLOW_ENUM.CLASS_BOOKING &&
            pre.step === 4 &&
            !!pre?.responsiblePersonsBooked?.length
          ) {
            return {
              ...pre,
              responsiblePersonsBooked: pre?.responsiblePersonsBooked?.map(
                (RPdata) => {
                  return {
                    ...RPdata,
                    students: []
                  };
                }
              )
            };
          }

          return pre;
        });
      } finally {
        if (callback) callback(false);
      }
    },
    [globalLocation?._id]
  );

  const handleGetSchedulesOfClasses = useCallback((classIds: string[]) => {
    if (!classIds) {
      console.error('No classIds provided');
      return;
    }

    const payload = {
      classIds: classIds
    };

    try {
      getSchedulesClass(payload).then((res) => {
        const data: {
          classInfo: IClass;
          schedules: ISchedule[];
          activeTermSchedules: ISchedule[];
          schedulesUntilNextDD: ISchedule[];
          schedulesUntilNextDD2: ISchedule[];
        }[] = res?.data;

        let result: DataSetFlowBookingClass = null;

        if (!data?.length) {
          console.error('No schedules from all of these classes');

          return;
        }

        const getClassInfoByClass = (classId: string) => {
          if (!classId) {
            console.error('No classId provided');
            return [];
          }

          const classInfo = data?.filter(
            (item) => item?.classInfo?._id === classId
          )?.[0]?.classInfo;

          return classInfo;
        };

        const getSchedulesByClass = (classId: string) => {
          if (!classId) {
            console.error('No classId provided');
            return [];
          }

          const schedulesByClass = data?.filter(
            (item) => item?.classInfo?._id === classId
          )?.[0]?.schedules;

          if (!!schedulesByClass?.length) {
            return schedulesByClass;
          }

          console.error(`No schedulesByClass id:${classId} found`);

          return [];
        };

        const getActiveTermSchedulesByClass = (classId: string) => {
          if (!classId) {
            console.error('No classId provided');
            return [];
          }

          const activeTermSchedules = data?.filter(
            (item) => item?.classInfo?._id === classId
          )?.[0]?.activeTermSchedules;
          return activeTermSchedules;
        };

        const getSchedulesUntilNextDDByClass = (classId: string) => {
          if (!classId) {
            console.error('No classId provided');
            return [];
          }

          const schedulesUntilNextDD = data?.filter(
            (item) => item?.classInfo?._id === classId
          )?.[0]?.schedulesUntilNextDD;
          return schedulesUntilNextDD;
        };
        const getSchedulesUntilNextDD2ByClass = (classId: string) => {
          if (!classId) {
            console.error('No classId provided');
            return [];
          }

          const schedulesUntilNextDD2 = data?.filter(
            (item) => item?.classInfo?._id === classId
          )?.[0]?.schedulesUntilNextDD2;
          return schedulesUntilNextDD2;
        };

        setDataSet((pre) => {
          if (
            pre?.flow === FLOW_ENUM.CLASS_BOOKING &&
            (pre.step === 2 || pre.step === 4)
          ) {
            if (!!pre?.classesBooked?.length) {
              result = {
                ...pre,
                // @ts-ignore
                classesBooked: pre?.classesBooked?.map((classBooked) => {
                  if (
                    classBooked?.info?._id &&
                    !!getSchedulesByClass(classBooked?.info?._id)?.length
                  ) {
                    return {
                      info: getClassInfoByClass(classBooked?.info?._id),
                      schedules: getSchedulesByClass(classBooked?.info?._id),
                      schedulesUntilNextDD: getSchedulesUntilNextDDByClass(
                        classBooked?.info?._id
                      ),
                      schedulesUntilNextDD2: getSchedulesUntilNextDD2ByClass(
                        classBooked?.info?._id
                      ),
                      activeTermSchedules: getActiveTermSchedulesByClass(
                        classBooked?.info?._id
                      )
                    };
                  }

                  return classBooked;
                })
              };
            }

            return result;
          }

          return pre;
        });
      });
    } catch (error: any) {
      console.error(
        error?.response?.data?.message || 'Fetch schedules list failed'
      );
    }
  }, []);

  const handleSelectRP = useCallback(
    async (responsiblePersonsBooked: IResponsiblePerson | null) => {
      if (!responsiblePersonsBooked?._id) return;

      const activeOngoingStudents = await fetchActiveOngoingStudents(
        responsiblePersonsBooked?._id
      );

      setDataSet((pre) => {
        let result: DataSetFlowBookingClass = null;

        if (pre?.flow === FLOW_ENUM.CLASS_BOOKING) {
          if (responsiblePersonsBooked) {
            if (pre.step === 0) {
              result = {
                ...pre,
                step: 1 as const,
                classesBooked: [],
                responsiblePersonsBooked: [
                  {
                    info: {
                      ...responsiblePersonsBooked,
                      activeOngoingStudents
                    },
                    students: []
                  }
                ],
                handleAddStepBackward
              };
            }

            if (pre.step === 2) {
              result = {
                ...pre,
                step: 3 as const,
                responsiblePersonsBooked: [
                  {
                    info: {
                      ...responsiblePersonsBooked,
                      activeOngoingStudents
                    },
                    students: []
                  }
                ]
              };
            }

            if (pre.step === 3) {
              const RPExisted = !!pre?.responsiblePersonsBooked?.filter(
                (RPdata) => RPdata?.info?._id === responsiblePersonsBooked?._id
              )?.length;

              if (RPExisted) {
                const newResponsiblePersonsBooked =
                  pre?.responsiblePersonsBooked?.filter(
                    (classInfo) =>
                      classInfo?.info?._id !== responsiblePersonsBooked?._id
                  );

                if (!!newResponsiblePersonsBooked?.length) {
                  result = {
                    ...pre,
                    responsiblePersonsBooked: newResponsiblePersonsBooked
                  };
                } else {
                  result = {
                    ...pre,
                    step: 2 as const,
                    handleGetSchedulesOfClasses
                  };
                }
              } else {
                result = {
                  ...pre,
                  // we handle single data this case
                  responsiblePersonsBooked: [
                    {
                      info: {
                        ...responsiblePersonsBooked,
                        activeOngoingStudents
                      },
                      students: []
                    }
                  ]
                };
              }
            }
          } else {
            if (pre.step === 2) {
              result = {
                ...pre
              };
            }

            if (pre.step === 3) {
              result = {
                ...pre,
                step: 2 as const,
                handleGetSchedulesOfClasses
              };
            }
          }

          return result;
        }

        return pre;
      });
    },

    // eslint-disable-next-line
    [handleGetSchedulesOfClasses]
  );

  const handleAddStepForward = useCallback(() => {
    let result: DataSetFlowBookingClass = null;

    setDataSet((pre: DataSetFlowBookingClass) => {
      if (pre?.flow === FLOW_ENUM.CLASS_BOOKING) {
        if (pre?.step === 1) {
          if (pre?.responsiblePersonsBooked?.[0]?.info?._id) {
            result = {
              ...pre,
              handleGetSchedulesOfClasses,
              responsiblePersonsBooked: pre?.responsiblePersonsBooked?.map(
                (RPdata) => {
                  return {
                    ...RPdata,
                    students: []
                  };
                }
              ),
              step: 4,
              nextDebitDay: '',
              subtractTerms: [],
              handleGetListEnrollmentByRP,
              handleUpdateListEnrollment,
              handleGetNextDebitDay
            };

            return result;
          }

          result = {
            ...pre,
            step: 2,
            handleSelectRP,
            handleGetSchedulesOfClasses
          };

          return result;
        }

        if (pre?.step === 3) {
          result = {
            ...pre,
            responsiblePersonsBooked: pre?.responsiblePersonsBooked?.map(
              (RPdata) => {
                return {
                  ...RPdata,
                  students: []
                };
              }
            ),
            step: 4,
            nextDebitDay: '',
            subtractTerms: [],
            handleGetListEnrollmentByRP,
            handleUpdateListEnrollment,
            handleGetNextDebitDay,
            handleGetSchedulesOfClasses
          };

          return result;
        }

        if (pre?.step === 5) {
          result = {
            ...pre,
            step: 6,
            paymentMethod: PAYMENT_METHOD?.VIVA_PAY,
            paymentAmount: 0,
            totalCreditAmount: 0,
            totalNextDirectDebitPaymentAmount: 0,
            responsiblePersonsBooked: pre?.responsiblePersonsBooked?.map(
              (RPdata) => {
                return {
                  ...RPdata,
                  students: RPdata.students.map((student) => {
                    return {
                      ...student,
                      voucher: undefined
                    };
                  })
                };
              }
            ),
            handlePaymentMethod,
            handleChangeClassPaymentType,
            handleApplyPromoCode,
            handlePaymentAmount
          };

          return result;
        }
      }
      return pre;
    });
  }, [
    handleApplyPromoCode,
    handleSelectRP,
    handleGetSchedulesOfClasses,
    handlePaymentMethod,
    handleChangeClassPaymentType,
    handlePaymentAmount,
    handleGetListEnrollmentByRP,
    handleUpdateListEnrollment,
    handleGetNextDebitDay
  ]);

  const handleAddStepBackward = useCallback(
    (moveTo?: number, currentDateSet?: DataSetFlowBookingClass) => {
      setDataSet((pre: DataSetFlowBookingClass) => {
        let result: DataSetFlowBookingClass = null;

        if (pre?.flow === FLOW_ENUM.CLASS_BOOKING) {
          const repeatAtt = {
            flow: pre?.flow,
            cleanUpFlow: cleanUpFlow,
            handleAddStepForward,
            handleAddStepBackward
          };

          if (typeof moveTo === 'number' && currentDateSet) {
            if (
              moveTo === 1 &&
              currentDateSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
              (currentDateSet?.step === 2 ||
                currentDateSet?.step === 3 ||
                currentDateSet?.step === 4 ||
                currentDateSet?.step === 5 ||
                currentDateSet?.step === 6 ||
                currentDateSet?.step === 7)
            ) {
              result = {
                step: moveTo,
                classesBooked: currentDateSet?.classesBooked,
                responsiblePersonsBooked:
                  currentDateSet?.responsiblePersonsBooked,
                handleClassBooking,
                handleSelectRP,
                ...repeatAtt
              };
            }

            if (
              moveTo === 3 &&
              currentDateSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
              (currentDateSet?.step === 3 ||
                currentDateSet?.step === 4 ||
                currentDateSet?.step === 5 ||
                currentDateSet?.step === 6 ||
                currentDateSet?.step === 7)
            ) {
              result = {
                step: moveTo,
                classesBooked: currentDateSet?.classesBooked,
                responsiblePersonsBooked:
                  currentDateSet?.responsiblePersonsBooked,
                handleSelectRP,
                ...repeatAtt
              };
            }

            if (
              moveTo === 5 &&
              currentDateSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
              (currentDateSet?.step === 5 ||
                currentDateSet?.step === 6 ||
                currentDateSet?.step === 7)
            ) {
              result = {
                step: moveTo,
                classesBooked: currentDateSet?.classesBooked,
                responsiblePersonsBooked: handleClearSTform(currentDateSet),
                nextDebitDay: currentDateSet?.nextDebitDay,
                subtractTerms: currentDateSet?.subtractTerms,
                handleGetListEnrollmentByRP,
                handleUpdateListEnrollment,
                handleGetNextDebitDay,
                handleGetSchedulesOfClasses,
                ...repeatAtt
              };
            }
          } else {
            if (pre?.step === 1) {
              result = {
                flow: FLOW_ENUM.CLASS_BOOKING,
                step: 0,
                handleClassBooking: pre?.handleClassBooking,
                handleSelectRP: pre?.handleSelectRP,
                handleAddStepForward: pre?.handleAddStepForward,
                cleanUpFlow: pre?.cleanUpFlow
              };
            }

            if (pre?.step === 2) {
              result = {
                step: 1,
                classesBooked: pre?.classesBooked,
                responsiblePersonsBooked: pre?.responsiblePersonsBooked,
                handleClassBooking,
                handleSelectRP,
                ...repeatAtt
              };
            }

            if (pre?.step === 3) {
              result = {
                step: 2,
                classesBooked: pre?.classesBooked,
                responsiblePersonsBooked: [],
                handleSelectRP: pre?.handleSelectRP,
                handleGetSchedulesOfClasses,
                ...repeatAtt
              };
            }

            if (pre?.step === 4) {
              result = {
                step: 3,
                responsiblePersonsBooked: handleClearSTform(pre),
                classesBooked: pre?.classesBooked,
                handleSelectRP,
                ...repeatAtt
              };
            }

            if (pre?.step === 5) {
              result = {
                step: 3,
                responsiblePersonsBooked: handleClearSTform(pre),
                classesBooked: pre?.classesBooked,
                handleSelectRP,
                ...repeatAtt
              };
            }

            if (pre?.step === 6) {
              result = {
                step: 5,
                responsiblePersonsBooked: handleClearSTform(pre),
                classesBooked: pre?.classesBooked,
                nextDebitDay: pre?.nextDebitDay,
                subtractTerms: pre?.subtractTerms,
                handleGetListEnrollmentByRP,
                handleUpdateListEnrollment,
                handleGetNextDebitDay,
                handleGetSchedulesOfClasses,
                ...repeatAtt
              };
            }

            if (pre?.step === 7) {
              if (__isGoBackToStep5(pre)) {
                result = {
                  step: 5,
                  responsiblePersonsBooked: handleClearSTform(pre),
                  classesBooked: pre?.classesBooked,
                  nextDebitDay: pre?.nextDebitDay,
                  subtractTerms: pre?.subtractTerms,
                  handleGetListEnrollmentByRP,
                  handleUpdateListEnrollment,
                  handleGetNextDebitDay,
                  handleGetSchedulesOfClasses,
                  ...repeatAtt
                };
              } else {
                result = {
                  step: 6,
                  responsiblePersonsBooked: handleClearSTform(pre),
                  classesBooked: pre?.classesBooked,
                  nextDebitDay: pre?.nextDebitDay,
                  subtractTerms: pre?.subtractTerms,
                  paymentMethod: PAYMENT_METHOD?.VIVA_PAY,
                  paymentAmount: 0,
                  totalCreditAmount: 0,
                  totalNextDirectDebitPaymentAmount: 0,
                  handlePaymentMethod,
                  handleChangeClassPaymentType,
                  handlePaymentAmount,
                  handleApplyPromoCode,
                  ...repeatAtt
                };
              }
            }
          }

          return result;
        }
        return pre;
      });
    },
    // eslint-disable-next-line
    []
  );

  const handleClassBooking = useCallback(
    (ClassInfo: IClassBooked | null) => {
      if (!ClassInfo) return;

      setDataSet((pre: DataSetFlowBookingClass) => {
        let result: DataSetFlowBookingClass = null;

        if (pre?.flow === FLOW_ENUM.CLASS_BOOKING) {
          if (pre.step === 0) {
            result = {
              ...pre,
              step: 1 as const,
              classesBooked: [
                {
                  info: ClassInfo,
                  schedulesUntilNextDD: [],
                  schedulesUntilNextDD2: [],
                  activeTermSchedules: [],
                  schedules: []
                }
              ],
              responsiblePersonsBooked: [],
              handleAddStepForward,
              handleAddStepBackward
            };
          }

          if (pre.step === 1) {
            const classInfoExisted = !!pre?.classesBooked?.filter(
              (classInfo) => classInfo?.info?._id === ClassInfo?._id
            )?.length;

            if (classInfoExisted) {
              const newClassInfo = pre?.classesBooked?.filter(
                (classInfo) => classInfo?.info?._id !== ClassInfo?._id
              );

              if (!!newClassInfo?.length) {
                result = {
                  ...pre,
                  classesBooked: newClassInfo
                };
              } else {
                result = {
                  ...pre,
                  step: 0 as const
                };
              }
            } else {
              result = {
                ...pre,
                classesBooked: [
                  ...pre?.classesBooked,
                  {
                    info: ClassInfo,
                    schedules: [],
                    schedulesUntilNextDD: [],
                    schedulesUntilNextDD2: [],
                    activeTermSchedules: []
                  }
                ]
              };
            }
          }

          return result;
        }

        return pre;
      });
    },
    [handleAddStepForward, handleAddStepBackward]
  );

  const setFlow = useCallback(
    (flow: FlowTypes) => {
      if (flow === FLOW_ENUM.INIT) return;

      setDataSet((pre: DataSetFlowBookingClass) => {
        let result: DataSetFlowBookingClass = null;

        if (pre?.flow === FLOW_ENUM.INIT && flow === FLOW_ENUM.CLASS_BOOKING) {
          result = {
            flow: FLOW_ENUM.CLASS_BOOKING,
            step: 0,
            handleClassBooking,
            handleSelectRP,
            handleAddStepForward,
            cleanUpFlow
          };

          return result;
        }

        return pre;
      });
    },

    // eslint-disable-next-line
    [handleClassBooking]
  );

  const cleanUpFlow = useCallback(() => {
    setDataSet((pre: DataSetFlowBookingClass) => {
      if (pre?.flow === FLOW_ENUM.CLASS_BOOKING) {
        return {
          flow: FLOW_ENUM.INIT,
          setFlow,
          cleanUpFlow: pre?.cleanUpFlow
        };
      }
      return pre;
    });
  }, [setFlow]);

  const updateBookingStorage = useCallback(() => {
    if (!storedStepData) {
      setDataSet({
        flow: FLOW_ENUM.INIT,
        setFlow,
        cleanUpFlow
      });

      return;
    }

    if (storedStepData.flow === FLOW_ENUM.CLASS_BOOKING) {
      let result: DataSetFlowBookingClass = null;

      if (storedStepData.step === 0) {
        result = {
          ...storedStepData,
          cleanUpFlow,
          handleClassBooking,
          handleSelectRP,
          handleAddStepForward
        };

        setDataSet(result);
      }

      const repeatAtt = {
        cleanUpFlow,
        handleAddStepForward,
        handleAddStepBackward
      };

      if (storedStepData.step === 1) {
        result = {
          ...storedStepData,
          handleClassBooking,
          handleSelectRP,
          ...repeatAtt
        };

        setDataSet(result);
      }

      if (storedStepData.step === 2) {
        result = {
          ...storedStepData,
          handleSelectRP,
          handleGetSchedulesOfClasses,
          ...repeatAtt
        };

        setDataSet(result);
      }

      if (storedStepData.step === 3) {
        result = {
          ...storedStepData,
          handleSelectRP,
          ...repeatAtt
        };

        setDataSet(result);
      }

      if (storedStepData.step === 4) {
        result = {
          ...storedStepData,
          handleGetListEnrollmentByRP,
          handleUpdateListEnrollment,
          handleGetNextDebitDay,
          handleGetSchedulesOfClasses,
          ...repeatAtt
        };

        setDataSet(result);
      }

      if (storedStepData.step === 5) {
        result = {
          ...storedStepData,
          handleGetListEnrollmentByRP,
          handleUpdateListEnrollment,
          handleGetNextDebitDay,
          handleGetSchedulesOfClasses,
          ...repeatAtt
        };

        setDataSet(result);
      }

      if (storedStepData.step === 6) {
        result = {
          ...storedStepData,
          handlePaymentMethod,
          handleChangeClassPaymentType,
          handlePaymentAmount,
          ...repeatAtt
        };

        setDataSet(result);
      }

      if (storedStepData.step === 7) {
        result = {
          ...storedStepData,
          handlePaymentMethod,
          handleChangeClassPaymentType,
          ...repeatAtt
        };

        setDataSet(result);
      }
    }
  }, [
    storedStepData,
    setFlow,
    setDataSet,
    cleanUpFlow,
    handleClassBooking,
    handleAddStepForward,
    handleAddStepBackward,
    handleSelectRP,
    handleGetSchedulesOfClasses,
    handleGetListEnrollmentByRP,
    handleUpdateListEnrollment,
    handlePaymentAmount,
    handlePaymentMethod,
    handleChangeClassPaymentType,
    handleGetNextDebitDay
  ]);

  // fetch storage booking step data
  useEffect(() => {
    updateBookingStorage();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!dataSet) {
      setDataSet({
        flow: FLOW_ENUM.INIT,
        setFlow,
        cleanUpFlow
      });
    } else {
      setStoredStepData(dataSet);
    }
  }, [dataSet, setFlow, cleanUpFlow, setStoredStepData]);

  useEffect(() => {
    if (!dataSet || location?.pathname?.split('/')?.[1] !== SUPPORT_DOMAIN)
      return;

    if (
      dataSet?.flow === FLOW_ENUM?.CLASS_BOOKING &&
      !(dataSet?.step === 4 || dataSet?.step === 5) &&
      searchParams.get('tab')
    ) {
      searchParams.delete('tab');
      setSearchParams(searchParams, { replace: true });
    }
  }, [dataSet, location?.pathname, searchParams, setSearchParams]);

  useEffect(() => {
    if (
      location?.pathname?.split('/')?.length === 2 &&
      location?.pathname?.split('/')?.[1] === SUPPORT_DOMAIN
    )
      return;

    if (dataSet?.cleanUpFlow) {
      dataSet.cleanUpFlow();
    }

    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    window?.addEventListener('beforeunload', () => {
      if (location?.pathname.split('/')[1] === SUPPORT_DOMAIN) {
        setStoredStepData(dataSet);
      }
    });

    // eslint-disable-next-line
  }, [dataSet, location?.pathname]);

  const layoutContextValue: StepControllerContextType = {
    dataSet
  };

  return (
    <StepControllerContext.Provider value={layoutContextValue}>
      {children}
    </StepControllerContext.Provider>
  );
};
