import { BeatLoader } from 'react-spinners';

import './desktop.scss';

const AppLoadingContainer = () => {
  return (
    <div className="c__loading__container">
      <div className="c__loading__container-main">
        <BeatLoader />
      </div>
    </div>
  );
};

export default AppLoadingContainer;
