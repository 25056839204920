import API from './API';

const PAYMENT_ENDPOINT = '/payments';
const CARD_ENDPOINT = '/cards';

export const tokenizationCardRequest = (description: string) => {
  return API.put(`${PAYMENT_ENDPOINT}/tokenization-card-request`, {
    body: {
      description
    }
  });
};

export const cardRequestAuthenticated = (id: string) => {
  return API.get(`${PAYMENT_ENDPOINT}/card-request-authenticated/${id}`);
};

export const getCardByUserId = (userId: string) => {
  return API.get(`${CARD_ENDPOINT}/${userId}`);
};

export const getNextDebitDay = (locationId: string) => {
  return API.get(`${PAYMENT_ENDPOINT}/next-debit-day`, {
    locationId
  });
};
