import { arrayToStringAsPayload } from 'common/helpers/object.helper';
import API from './API';

import { getSchedulesByClassIds } from 'DTOs/schedules.dto';
// import {} from 'common/interfaces/schedules.inteface';

const RP_API_ENDPOINT = '/schedules';

export const getSchedulesClass = async (payload: getSchedulesByClassIds) => {
  const reMapPayload = arrayToStringAsPayload('classIds', payload.classIds);
  const response = await API.get(`${RP_API_ENDPOINT}/booking/?${reMapPayload}`);

  return response.data;
};
