import { useState } from 'react';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import AppButton from 'common/components/AppButton';
import { useToast } from 'context/ToastContext';
import { IMilestone } from 'common/interfaces/levelBreakdown.interface';
import { deleteMilestone } from 'services/levelBreakdown.service';

interface IMilestoneDeleteModalProps {
  milestone: IMilestone;
  onClose: () => void;
  onSuccess: () => void;
}

const MilestoneDeleteModal = (props: IMilestoneDeleteModalProps) => {
  const { milestone, onClose, onSuccess } = props;

  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteMilestone(milestone?._id);
      toast.success('Deleted this milestone');
      onSuccess();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to delete this milestone'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppModal open={!!milestone} onClose={onClose}>
      <AppModalTitle>Delete milestone</AppModalTitle>
      <AppModalContent>
        ARE YOU SURE YOU WANT TO DELETE THIS milestone?
      </AppModalContent>
      <AppModalActions>
        <AppButton variant="secondary" onClick={onClose}>
          No
        </AppButton>
        <AppButton isLoading={loading} onClick={handleDelete}>
          Yes
        </AppButton>
      </AppModalActions>
    </AppModal>
  );
};

export default MilestoneDeleteModal;
