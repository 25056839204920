import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import VersionModal from 'components/VersionModal';
import AppButton from 'common/components/AppButton';

import './index.scss';
import AppModal, { AppModalContent } from 'common/components/AppModal';
import { ENROLLMENT_STEP } from 'common/enums/student.enum';
import { useMoveBooking } from 'context/MoveBookingContext';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import PaymentModal from 'components/PaymentModal';
import { moveBookingForStudent } from 'services/students.service';
import { StudentMoveBookingDTO } from 'DTOs/student.dto';
import { FAILED_TO_GET_CARD_TOKEN } from 'common/constants/classBooking.constant';
import { useToast } from 'context/ToastContext';
import {
  calculateExtraPrice,
  getNewEnrolmentSessions
} from 'helpers/moveBooking.helper';
import { IBookingData } from 'common/interfaces/schedules.interface';

interface Props {
  selectedClassTotal: number;
  nextDebitDay: string;
}
const MoveBookingFooter = ({ selectedClassTotal, nextDebitDay }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const [stepBooking, setStepBooking] = useState<string>(
    searchParams.get('stepBooking') || ENROLLMENT_STEP.BOOKING
  );
  const navigate = useNavigate();
  const toast = useToast();
  const {
    fetchClassesData,
    studentDataBooking,
    onChangeStudentDataBooking,
    __totalPayNow,
    paymentMethod,
    currentEnrolmentClass,
    newEnrolmentClass,
    __canConfirmMove,
    onChangeCurrentEnrolmentClass,
    moveFrom,
    onChangeSelectedSession,
    onChangeSelectedClassIds
  } = useMoveBooking();

  const [showModal, setShowModal] = useState(false);
  const [openPaymentSuccessModal, setOpenPaymentSuccessModal] =
    useState<boolean>(false);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [isFailedToGetCardToken, setIsFailedToGetCardToken] =
    useState<boolean>(false);

  useEffect(() => {
    setStepBooking(searchParams.get('stepBooking') || ENROLLMENT_STEP.BOOKING);
  }, [searchParams]);

  const handleClassBooking = () => {
    const step = Number(stepBooking);
    if (step === 1) {
      fetchClassesData();
    }
    if (step <= 2) {
      searchParams.set('stepBooking', `${step + 1}`);
      setSearchParams(searchParams, { replace: true });
      if (step === 2) {
        if (!studentDataBooking || !currentEnrolmentClass || !newEnrolmentClass)
          return;
        const studentBookingDataNew = {
          ...studentDataBooking,
          bookingData: studentDataBooking.classesData
            .filter((classItem) => classItem.checked)
            .map((classItem) => {
              const upfrontPayment =
                classItem.classInfo.type ===
                CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM;
              const paymentOption = upfrontPayment
                ? PAYMENT_VALUE.UPFRONT
                : PAYMENT_VALUE.IDLE;

              const item: IBookingData = {
                ...classItem,
                paymentOption
              };

              const newEnrolmentSchedulesTemp = getNewEnrolmentSessions(
                item,
                newEnrolmentClass.schedules
              );

              const { extraFee: extraPrice } = calculateExtraPrice(
                currentEnrolmentClass,
                item,
                newEnrolmentSchedulesTemp,
                nextDebitDay
              );
              const price =
                paymentOption === PAYMENT_VALUE.UPFRONT
                  ? extraPrice
                  : classItem.classInfo.price;

              return {
                ...classItem,
                studentId: studentDataBooking._id,
                schedules: classItem.schedules.filter(
                  (schedule) => schedule.checked
                ),
                price: price,
                paymentOption: upfrontPayment
                  ? PAYMENT_VALUE.UPFRONT
                  : PAYMENT_VALUE.IDLE,
                pricePayNow: upfrontPayment ? price : 0,
                nextBillingCircle: []
              };
            })
        };
        onChangeStudentDataBooking(studentBookingDataNew);
      }
    } else {
      setOpenPaymentModal(true);
    }
  };

  const handleGoBack = () => {
    if (!selectedClassTotal) {
      searchParams.delete('stepBooking');
      setSearchParams(searchParams);
      return;
    }

    if (stepBooking === ENROLLMENT_STEP.ENROLLMENT) {
      searchParams.delete('stepBooking');
      setSearchParams(searchParams);
    }

    if (stepBooking === ENROLLMENT_STEP.PAYMENT) {
      searchParams.set('stepBooking', ENROLLMENT_STEP.ENROLLMENT);
      setSearchParams(searchParams);
    }
  };

  const handleCancel = useCallback(() => {
    if (moveFrom === 'students') {
      navigate(`/${moveFrom}/${params.studentId}?indexRoute=1&tabNumber=0`);
    } else {
      navigate(`/${moveFrom}/${params.RPId}?indexRoute=1&sub1=0`);
    }
    onChangeCurrentEnrolmentClass(null);
    onChangeSelectedSession(null);
    onChangeSelectedClassIds([]);
    // eslint-disable-next-line
  }, []);

  const handlePayment = async (cardId: string) => {
    try {
      const payload: StudentMoveBookingDTO = {
        responsiblePersonId: params.RPId,
        studentId: params.studentId,
        bookClassId: params.bookClassId,
        newSessionIds: studentDataBooking?.bookingData[0].schedules?.map(
          (schedule) => schedule._id
        ),
        newClassId: studentDataBooking?.classesData[0]?._id || '',
        paymentMethod: paymentMethod,
        paymentType: studentDataBooking?.bookingData[0].paymentOption,
        amount: __totalPayNow,
        cardId: cardId,
        locationId: studentDataBooking?.classesData[0]?.classInfo?.locationId,
        isSaveCardToken: true,
        statementDescriptor: {
          name: 'Payer'
        }
      };
      await moveBookingForStudent(payload);

      setOpenPaymentModal(false);
      setOpenPaymentSuccessModal(true);

      setTimeout(() => {
        handleCancel();
      }, 2000);
    } catch (error: any) {
      if (error?.response?.data?.errorCode === FAILED_TO_GET_CARD_TOKEN) {
        setIsFailedToGetCardToken(true);
      } else {
        setIsFailedToGetCardToken(false);
        toast.error(error?.response?.data?.message || 'Fail to make payment');
      }
    }
  };

  const handleCloseModalSuccess = () => {
    setOpenPaymentSuccessModal(false);
    handleCancel();
  };
  return (
    <>
      {openPaymentModal && (
        <PaymentModal
          open={openPaymentModal}
          onClose={() => {
            setIsFailedToGetCardToken(false);
            setOpenPaymentModal(false);
          }}
          amount={__totalPayNow}
          payerId={params?.RPId || ''}
          onMakePayment={handlePayment}
          isFailedToGetCardToken={isFailedToGetCardToken}
        />
      )}
      <AppModal
        open={openPaymentSuccessModal}
        onClose={handleCloseModalSuccess}
      >
        <img
          src="/icons/approve-icon.svg"
          alt=""
          style={{ display: 'flex', margin: '38px auto 14px' }}
        />
        <AppModalContent>
          You have successfully booked the class
        </AppModalContent>
      </AppModal>
      <div className="c_footer-app-enrollment">
        <div className="c_footer-app_content">
          <div>© Viva Labs, a division of Viva Leisure Limited | </div>
          <div>ver 1.0.0</div>
          <img
            src="/icons/document.svg"
            width={15}
            alt=""
            style={{ cursor: 'pointer' }}
            onClick={() => setShowModal(true)}
          />
        </div>
        <div className="c_footer-app_btn">
          <AppButton
            type="button"
            variant="secondary"
            buttonSize="small"
            onClick={handleCancel}
          >
            Cancel
          </AppButton>
          {Number(searchParams.get('stepBooking') || ENROLLMENT_STEP.BOOKING) >=
          2 ? (
            <>
              <AppButton
                type="button"
                variant="secondary"
                buttonSize="small"
                onClick={handleGoBack}
              >
                Previous
              </AppButton>
            </>
          ) : null}
          {!!selectedClassTotal && (
            <AppButton
              type="button"
              buttonSize="small"
              onClick={handleClassBooking}
              disabled={!__canConfirmMove}
            >
              {stepBooking === ENROLLMENT_STEP.PAYMENT ? 'Confirm' : 'Next'}
            </AppButton>
          )}
        </div>
      </div>
      <VersionModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default MoveBookingFooter;
