const notAllowNumRegExp = /^([^0-9]*)$/;
const numRegExp = /^([0-9]*)$/;
const notSpecialRegExp = /^[^*|\\":<>[\]{}`\\()';@&$!#%^//=_~?+-]+$/;
const passUpperRegExp = /^.*[A-Z].*$/;
const passLowerRegExp = /^.*[a-z].*$/;
const passDigitSpecialRegExp = /^(?=.*?[0-9])|(?=.*?\W).*$/;
const notAllowDiffNumCharRegExp = /^[a-zA-Z0-9]*$/;
const notAllowSpaceRegExp = /^\S*$/;

export {
  notSpecialRegExp,
  notAllowNumRegExp,
  numRegExp,
  passUpperRegExp,
  passLowerRegExp,
  passDigitSpecialRegExp,
  notAllowDiffNumCharRegExp,
  notAllowSpaceRegExp
};
