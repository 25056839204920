import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import AppSelect from 'common/components/AppSelect';
import { FORMAT_END_OF_DATE, WEEK_DAY_STRING_OPTIONS } from 'common/constants';
import AppButton from 'common/components/AppButton';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import {
  DAY_OF_WEEK,
  ERROR_MESSAGE_DATE,
  FORMAT_START_OF_DATE,
  REPORT_PATH,
  REPORT_PROGRAM_TYPE_OPTIONS
} from '../constant';
import AppDatePicker from 'common/components/AppDatePicker';
import { createColumnHelper } from '@tanstack/react-table';
import {
  formatData,
  formatDate,
  formatSecretPhoneNumber,
  formatTime
} from 'common/helpers/dataFormat.helper';
import AppTable from 'common/components/AppTable';
import './desktop.scss';
import { CLASS_TYPES } from 'common/enums/class.enum';
import dayjs, { Dayjs } from 'dayjs';
import { getDateOfLastAssessmentReport } from 'services/report.service';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { useToast } from 'context/ToastContext';
import ReportSelection from '../components/ReportSelection';
import { IOption } from 'common/interfaces';
import { ILevel } from 'common/interfaces/levelBreakdown.interface';
import { getLevelBreakdowns } from 'services/levelBreakdown.service';
import { useBrandLocation } from 'context/BrandLocationContext';
import { uniq } from 'lodash';
import { IDateOfLastAssessmentReport } from '../interface';
// import { HiOutlineDocumentDownload } from 'react-icons/hi';

type FilterDateOfLastAssessmentReport = {
  dateFrom: string;
  dateTo: string;
  programType: string;
  lessonDay: string;
  levelIds: string;
};
const initFilterValue: FilterDateOfLastAssessmentReport = {
  dateFrom: '',
  dateTo: '',
  programType: '',
  lessonDay: '',
  levelIds: ''
};
const ReportDateOfLastAssessment = () => {
  const { selectedLocation: __globalLocation } = useBrandLocation();
  const toast = useToast();

  const [data, setData] = useState<IDateOfLastAssessmentReport[]>([]);
  const [isGenerated, setIsGenerated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageTotal, setPageTotal] = useState<number>(0);
  const [levels, setLevels] = React.useState<Array<ILevel>>([]);

  const [levelIds, setLevelIds] = React.useState<Array<string>>([]);
  const [dateFrom, setDateFrom] = useState<string>('');
  const [dateTo, setDateTo] = useState<string>('');
  const [programType, setProgramType] = useState<CLASS_TYPES | ''>('');
  const [lessonDay, setLessonDay] = useState<string>('');
  const currenFilter =
    useRef<FilterDateOfLastAssessmentReport>(initFilterValue);
  const dateError = useRef<string>('');
  const columnHelper = createColumnHelper<IDateOfLastAssessmentReport>();

  const __levelOptions: Array<IOption> = React.useMemo(() => {
    if (levels?.length === 0) return [];
    return levels.map((level) => ({
      label: formatData(level?.shortName),
      value: level?._id
    }));
  }, [levels]);

  const __selectedLevelPlaceholderText: string = React.useMemo(() => {
    return levelIds.length === 0
      ? 'Select option'
      : `${levelIds.length} selected`;
  }, [levelIds]);

  const __isDisableGenerateButton: boolean = useMemo(() => {
    return (
      !dateFrom ||
      !dateTo ||
      !programType ||
      (programType === CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM
        ? false
        : !lessonDay) ||
      !!dateError.current ||
      levelIds.length === 0
    );
  }, [dateFrom, dateTo, programType, lessonDay, levelIds]);

  const handleToggleLevel = (value: string) => {
    const newLevelIds = [...levelIds];
    const findIndex = newLevelIds.findIndex((levelId) => levelId === value);
    if (findIndex === -1) {
      newLevelIds.push(value);
    } else {
      newLevelIds.splice(findIndex, 1);
    }
    setLevelIds(newLevelIds);
  };
  const handleSelectAllLevel = () => {
    const values = levels.map((level: ILevel) => level._id);
    if (levelIds.length === levels?.length) {
      setLevelIds([]);
    } else {
      setLevelIds(uniq(values.concat(levelIds)));
    }
  };

  const fetchInitData = React.useCallback(async () => {
    if (!__globalLocation?._id) return;
    try {
      const resultLevel = await getLevelBreakdowns(
        1,
        100,
        __globalLocation._id
      );
      setLevels(resultLevel?.data?.data?.data);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Failed to get init data');
      setLevels([]);
    } finally {
      setLevelIds([]);
    }
    // eslint-disable-next-line
  }, [__globalLocation?._id]);

  useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  const columns = [
    columnHelper.accessor('student.memberId', {
      header: () => <span>student ID</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('student', {
      header: () => <span>Student name</span>,
      cell: (info) => (
        <div>
          {`${formatData(info.getValue()?.lastName)}, ${formatData(
            info.getValue()?.firstName
          )}`}
        </div>
      )
    }),
    columnHelper.accessor('student.dob', {
      header: () => <span>Student Age</span>,
      cell: (info) => {
        const birthDate = dayjs(info.getValue());
        const currentDate = dayjs();
        const years = currentDate.diff(birthDate, 'year');
        const months = currentDate.diff(birthDate.add(years, 'year'), 'month');

        return `${years} years ${months} months`;
      }
    }),
    columnHelper.accessor('schedules', {
      header: () => {
        return <span>Class day</span>;
      },
      cell: (info) => {
        return formatData(DAY_OF_WEEK?.[info.getValue().dayOfWeek - 1 || 0]);
      }
    }),
    columnHelper.accessor('schedules', {
      header: () => <span>Class time</span>,
      cell: (info) =>
        `${formatTime(info.getValue().startTime)} - ${formatTime(
          info.getValue().endTime
        )}`
    }),
    columnHelper.accessor('level', {
      header: () => <span>Level</span>,
      cell: (info) => formatData(info.getValue()?.name)
    }),
    columnHelper.accessor('schedules', {
      header: () => <span>Area</span>,
      cell: (info) => formatData(info.getValue()?.area)
    }),
    columnHelper.accessor('instructor', {
      header: () => <span>Instructor</span>,
      cell: (info) => (
        <div>
          {`${formatData(info.getValue()?.lastName)}, ${formatData(
            info.getValue()?.firstName
          )}`}
        </div>
      )
    }),
    columnHelper.accessor('assessmentDate', {
      header: () => <span>Assessment date</span>,
      cell: (info) => formatDate(info.getValue())
    }),
    columnHelper.accessor('responsiblePerson', {
      header: () => <span>RP name</span>,
      cell: (info) => (
        <div>
          {`${formatData(info.getValue()?.lastName)}, ${formatData(
            info.getValue()?.firstName
          )}`}
        </div>
      )
    }),
    columnHelper.accessor('responsiblePerson', {
      header: () => <span>RP id</span>,
      cell: (info) => formatData(info.getValue()?.memberId)
    }),
    columnHelper.accessor('responsiblePerson.phoneNumber', {
      header: () => <span>RP Phone</span>,
      cell: (info) => formatSecretPhoneNumber(info.getValue())
    })
  ];

  const columnsForIntensiveProgram = [
    ...columns.slice(0, 3),
    ...columns.slice(4)
  ];

  const handleChangeDateFrom = (date: Dayjs | null) => {
    setDateFrom(date?.format(FORMAT_START_OF_DATE) || '');
    if (date?.isAfter(dayjs(dateTo))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeDateTo = (date: Dayjs | null) => {
    setDateTo(date?.format(FORMAT_END_OF_DATE) || '');
    if (date?.isBefore(dayjs(dateFrom))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const fetchDataDateOfLastAssessment = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getDateOfLastAssessmentReport(
        pageIndex,
        pageSize,
        convertToUnixTime(currenFilter.current.dateFrom),
        convertToUnixTime(currenFilter.current.dateTo),
        currenFilter.current.programType,
        currenFilter.current.lessonDay,
        currenFilter.current.levelIds.split(','),
        __globalLocation?._id
      );
      setIsGenerated(true);
      setData(data.data.data);
      setPageTotal(data.data.total);
      setDateFrom(currenFilter.current.dateFrom);
      setDateTo(currenFilter.current.dateTo);
      setProgramType(currenFilter.current.programType as CLASS_TYPES);
      setLessonDay(currenFilter.current.lessonDay);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || ' Failed to generate data');
      setIsGenerated(false);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [pageIndex, pageSize, __globalLocation?._id]);

  const handleGenerateData = async () => {
    currenFilter.current = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      programType: programType,
      lessonDay: lessonDay,
      levelIds: levelIds.toString()
    };
    await fetchDataDateOfLastAssessment();
  };

  useEffect(() => {
    if (isGenerated) fetchDataDateOfLastAssessment();
    // eslint-disable-next-line
  }, [pageIndex, pageSize]);

  return (
    <main
      id="reportDateOfLastAssessment"
      className="reportDateOfLastAssessment"
    >
      <AppBreadCrumb
        items={[
          { name: 'Reporting', href: REPORT_PATH },
          { name: 'Date of last Assessment Report' }
        ]}
      />
      <div className="layoutContainer reportDateOfLastAssessment__wrapper">
        <section className="reportDateOfLastAssessment__search">
          <div className="reportDateOfLastAssessment__search__fields">
            <AppDatePicker
              size="small"
              label="Date from"
              value={dayjs(dateFrom)}
              onChange={handleChangeDateFrom}
            />
            <AppDatePicker
              size="small"
              label="Date to"
              value={dayjs(dateTo)}
              onChange={handleChangeDateTo}
              message={{
                type: 'error',
                text: dateError.current
              }}
            />
            <AppSelect
              searchable={false}
              inputSize="small"
              label="Program Type"
              placeholder="Select option"
              options={REPORT_PROGRAM_TYPE_OPTIONS}
              value={programType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setProgramType(e.target.value as CLASS_TYPES);
                if (e.target.value === CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM) {
                  setLessonDay('');
                }
              }}
            />
            <AppSelect
              searchable={false}
              inputSize="small"
              label="Lesson Day"
              placeholder="Select option"
              options={WEEK_DAY_STRING_OPTIONS}
              value={lessonDay}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLessonDay(e.target.value);
              }}
              disabled={programType === CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM}
            />
            <ReportSelection
              label="Level"
              placeholder={__selectedLevelPlaceholderText}
              options={__levelOptions}
              onSelect={handleToggleLevel}
              onSelectAll={handleSelectAllLevel}
              selectedIds={levelIds}
            />
          </div>
          <AppButton
            variant="primary"
            buttonSize="small"
            onClick={handleGenerateData}
            disabled={__isDisableGenerateButton}
          >
            generate
          </AppButton>
        </section>

        {isGenerated && (
          <section className="reportDateOfLastAssessment__content">
            <div className="reportDateOfLastAssessment__content__header">
              <p className="reportDateOfLastAssessment__content__header--title">
                Date of last Assessment
              </p>
              {/* <button className="export-btn-wrapper">
                <div>
                  <HiOutlineDocumentDownload fontSize={22} />
                </div>
                <span className="export-btn-label">Export</span>
              </button> */}
            </div>

            <div className="reportDateOfLastAssessment__content__table">
              <AppTable
                data={data}
                columns={
                  programType === CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM
                    ? columnsForIntensiveProgram
                    : columns
                }
                pagination={{
                  index: pageIndex,
                  size: pageSize,
                  total: pageTotal
                }}
                loading={loading}
                onChangePage={(index: number, size: number) => {
                  setPageIndex(index);
                  setPageSize(size);
                }}
              />
            </div>
          </section>
        )}
      </div>
    </main>
  );
};

export default ReportDateOfLastAssessment;
