import React from 'react';
import './index.scss';
import './table.scss';
import Table from './Table';
import { HiArrowLeft, HiOutlineExclamationCircle } from 'react-icons/hi';
import { ISearchCalendar } from './calendar/types';
import { IClass } from 'common/interfaces/class.interface';
import dayjs from 'dayjs';
interface Props {
  goBack: () => void;
  searchAdvanceValue: ISearchCalendar;
  classesData: IClass[];
}
const SearchResult: React.FC<Props> = ({
  goBack,
  classesData,
  searchAdvanceValue
}: Props) => {
  return (
    <div className="search-content">
      <div className="search-result-container">
        <div
          className="header"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            gap: '10px'
          }}
        >
          <div onClick={goBack}>
            <HiArrowLeft fontSize={20} style={{ cursor: 'pointer' }} />
          </div>
          <div>{`Result: ${dayjs(searchAdvanceValue.search.fromDate).format(
            'MMM D'
          )} - ${dayjs(searchAdvanceValue.search.toDate).format(
            'MMM D, YYYY'
          )}`}</div>
        </div>
        {classesData.length > 0 ? (
          <Table classesData={classesData} />
        ) : (
          <>
            <div className="no-result">
              <HiOutlineExclamationCircle fontSize={24} />
              <div>No result</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default SearchResult;
