import { CreateInstructorDTO } from 'DTOs/instructor.dto';
import API from './API';

const INSTRUCTOR_API_ENDPOINT = '/instructors';

export const getInstructorList = async (
  params: Record<string, unknown> = {}
) => {
  try {
    const response = await API.get(INSTRUCTOR_API_ENDPOINT, params);
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const checkInstructorConflict = async (payload: any) => {
  try {
    const response = await API.post(
      `${INSTRUCTOR_API_ENDPOINT}/available-or-conflict`,
      {
        body: payload
      }
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const deleteInstructor = async (instructorId: string) => {
  const response = await API.delete(
    `${INSTRUCTOR_API_ENDPOINT}/${instructorId}`
  );
  return response.data;
};
export const createInstructor = async (payload: CreateInstructorDTO) => {
  const response = await API.post(INSTRUCTOR_API_ENDPOINT, {
    body: payload
  });
  return response.data.data;
};

export const getInstructorDetail = async (instructorId: string) => {
  const response = await API.get(`${INSTRUCTOR_API_ENDPOINT}/${instructorId}`);
  return response.data.data;
};

export const updateInstructor = async (instructorId: string, payload: any) => {
  const response = await API.patch(
    `${INSTRUCTOR_API_ENDPOINT}/${instructorId}`,
    {
      body: payload
    }
  );
  return response.data.data;
};
