// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import { HiXMark } from 'react-icons/hi2';

interface IUploadDocumentSliderProps {
  open: boolean;
  onClose: () => void;
  documents: Array<string>;
  initialSlide?: number;
}

const UploadDocumentSlider = (props: IUploadDocumentSliderProps) => {
  const { documents, open, onClose, initialSlide = 0 } = props;

  if (!open) return null;

  return (
    <div className="c__documents-slider">
      <div className="c__documents-slider-header">
        <p>Document</p>
        <div onClick={onClose}>
          <HiXMark size={24} />
        </div>
      </div>
      <Swiper
        initialSlide={initialSlide}
        spaceBetween={0}
        slidesPerView={1}
        navigation={true}
        noSwiping
        loop
        effect="slide"
        modules={[Navigation]}
      >
        {documents.map((item) => {
          return (
            <SwiperSlide key={item}>
              {item.includes('pdf') ? (
                <embed
                  style={{
                    width: '100%',
                    minHeight: '100%'
                  }}
                  src={item || ''}
                />
              ) : (
                <img src={item} alt="document" />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default UploadDocumentSlider;
