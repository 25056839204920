import { IStudentBookingData } from 'common/interfaces/student.interface';
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import { getStudentDetail } from 'services/students.service';
import { useToast } from './ToastContext';
import { ISchedulesOrigin } from 'common/interfaces/schedules.interface';
import { cloneDeep, map } from 'lodash';
import { getClassSessions } from 'services/dashboard.service';
import {
  BOOKING_TYPE,
  PAYMENT_METHOD,
  PAYMENT_VALUE
} from 'common/enums/classBooking.enum';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ENROLLMENT_STEP } from 'common/enums/student.enum';
import {
  formatData,
  formatMoneySign,
  roundByTwo
} from 'common/helpers/dataFormat.helper';
import {
  DiscountApplied,
  DiscountData
} from 'common/interfaces/bookingClass.interface';
import { DISCOUNT_TYPE } from 'common/enums';
import { getProgramType } from 'common/helpers/index.helper';
import { handleDateTimeRecord } from 'common/helpers/classBooking.helper';
import useLocalStorage from 'common/hooks/useLocalStorage';
import {
  KEY_CURRENT_ENROLMENT_CLASS,
  KEY_MOVE_BOOKING_COUNT
} from 'common/constants/localStorage.constant';
import { IRPEnrollmentClass } from 'common/interfaces/responsiblePerson.interface';
import { getClassDetail } from 'services/classes.service';
import { IClass } from 'common/interfaces/class.interface';
import { ISession } from 'common/interfaces/session.interface';
import dayjs from 'dayjs';
import { getStartEndDateOfWeek } from 'helpers/moveBooking.helper';
import { WARNING_MOVING_TYPE } from 'common/enums/moving.enum';
import { getNextDebitDay } from 'services/payment.service';
import { useBrandLocation } from './BrandLocationContext';
import {
  AUTOMATION_DISCOUNT_TYPE,
  DISCOUNT_FROM
} from 'common/enums/voucher.enum';

interface MoveBookingContextType {
  selectedClassIds: Array<string>;
  onChangeSelectedClassIds: (classIds: string[]) => void;
  studentDataBooking: IStudentBookingData | null;
  onChangeStudentDataBooking: (data: IStudentBookingData) => void;
  classesData: ISchedulesOrigin[];
  fetchClassesData: () => Promise<void>;
  __canConfirmMove: boolean;
  fetchStudentDetail: (studentId: string) => Promise<void>;
  currentEnrolmentClass: IRPEnrollmentClass | null;
  onChangeCurrentEnrolmentClass: (data: IRPEnrollmentClass | null) => void;
  __discountData: DiscountData[];
  __totalPayNow: number;
  onChangePaymentMethod: (value: PAYMENT_METHOD) => void;
  paymentMethod: PAYMENT_METHOD;
  moveBookingCount: number;
  onChangeMoveBookingCount: (value: number) => void;
  newEnrolmentClass: IClass | null;
  onChangeMoneyCredit: (value: number) => void;
  moneyCredit: number;
  __warningMovingType: WARNING_MOVING_TYPE;
  selectedSession: ISession | null;
  onChangeSelectedSession: (session: ISession | null) => void;
  nextDebitDay: string;
  __discountApplied: DiscountApplied | undefined;
  moveFrom: string;
  onChangeMoveFrom: (value: string) => void;
  __moneyCredit: number;
}

export const KEY_MOVE_BOOKING_FROM = `g__move__booking__from`;
const MoveBookingContext = createContext<MoveBookingContextType | null>(null);

export const useMoveBooking = (): MoveBookingContextType => {
  const moveBookingContext = useContext(MoveBookingContext);

  if (!moveBookingContext) {
    throw new Error(
      'moveBookingContext must be used within an MoveBookingProvider'
    );
  }

  return moveBookingContext;
};

const checkWarningMovingType = (
  newLesson: ISession,
  currentClass: IRPEnrollmentClass,
  nextDebitDay?: string,
  newPaymentType?: PAYMENT_VALUE
): WARNING_MOVING_TYPE => {
  if (!currentClass || !newLesson) return WARNING_MOVING_TYPE.IDLE;
  let warningMovingType = WARNING_MOVING_TYPE.IDLE;
  // Current day
  const today: dayjs.Dayjs = dayjs();

  // Find the start and end of the current week (Monday to Sunday)
  const [startOfWeek, endOfWeek] = getStartEndDateOfWeek(today.toDate());
  // From allSessions return sessions in this week. Can be []
  const sessionsInCurrentWeek: Array<ISession> =
    currentClass?.allSessions?.filter((session) => {
      return (
        dayjs(session.startTime).isAfter(startOfWeek) &&
        dayjs(session.startTime).isBefore(endOfWeek)
      );
    });

  // From sessionsInCurrentWeek return only one session is nearest today.
  // Current lesson in this week.
  // If sessionsInCurrentWeek === [] => UNDEFINED
  const nearestSession: ISession | undefined = sessionsInCurrentWeek.reduce(
    (nearestSession, currentSession) => {
      const currentDiff = Math.abs(
        dayjs(currentSession?.startTime).diff(today)
      );
      const nearestDiff = Math.abs(
        dayjs(nearestSession?.startTime).diff(today)
      );
      return currentDiff < nearestDiff ? currentSession : nearestSession;
    },
    sessionsInCurrentWeek?.[0]
  );

  if (!nearestSession) {
    warningMovingType = WARNING_MOVING_TYPE.IDLE;
  } else if (
    dayjs(nearestSession?.startTime).isBefore(today) &&
    dayjs(newLesson?.startTime).isAfter(today) &&
    dayjs(newLesson?.startTime).isBefore(endOfWeek)
  ) {
    if (
      !!nextDebitDay &&
      !!newPaymentType &&
      dayjs(newLesson?.startTime).add(7, 'day').isBefore(nextDebitDay) &&
      newPaymentType === PAYMENT_VALUE.DIRECT_DEBIT
    ) {
      warningMovingType =
        WARNING_MOVING_TYPE.LESSON_HAS_HAPPENED_MOVE_THIS_WEEK_PLUS_DD;
    } else {
      warningMovingType =
        WARNING_MOVING_TYPE.LESSON_HAS_HAPPENED_MOVE_THIS_WEEK;
    }
  } else if (
    dayjs(nearestSession?.startTime).isAfter(today) &&
    dayjs(nearestSession?.startTime).isBefore(endOfWeek) &&
    dayjs(newLesson?.startTime).isAfter(endOfWeek)
  ) {
    warningMovingType = WARNING_MOVING_TYPE.LESSON_NOT_HAPPENED_MOVE_NEXT_WEEK;
  } else if (
    dayjs(nearestSession?.startTime).isAfter(today) &&
    dayjs(nearestSession?.startTime).isBefore(endOfWeek) &&
    dayjs(newLesson?.startTime).isAfter(today) &&
    dayjs(newLesson?.startTime).isBefore(endOfWeek)
  ) {
    warningMovingType = WARNING_MOVING_TYPE.LESSON_NOT_HAPPENED_MOVE_THIS_WEEK;
  } else {
    warningMovingType = WARNING_MOVING_TYPE.IDLE;
  }

  // Return sessions - array with only one item.
  return warningMovingType;
};

export const MoveBookingProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children
}) => {
  const toast = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { selectedLocation } = useBrandLocation();

  const [studentDataBooking, setStudentDataBooking] =
    useState<IStudentBookingData | null>(null);
  const [selectedClassIds, setSelectedClassIds] = useState<Array<string>>([]);
  const [classesData, setClassesData] = useState<ISchedulesOrigin[]>([]);
  const [classesDataForCapacity, setClassesDataForCapacity] = useState<
    ISchedulesOrigin[]
  >([]);
  const [currentEnrolmentClass, setCurrentEnrolmentClass] =
    useLocalStorage<IRPEnrollmentClass | null>(
      KEY_CURRENT_ENROLMENT_CLASS,
      null
    );

  const [newEnrolmentClass, setNewEnrolmentClass] = useState<IClass | null>(
    null
  );
  const [moveBookingCount, setMoveBookingCount] = useLocalStorage<number>(
    KEY_MOVE_BOOKING_COUNT,
    0
  );
  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_METHOD>(
    PAYMENT_METHOD.VIVA_PAY
  );
  const [moneyCredit, setMoneyCredit] = useState<number>(0);
  const [selectedSession, setSelectedSession] = useState<ISession | null>(null);

  const [nextDebitDay, setNextDebitDay] = useState<string>('');
  // moveFrom
  const [moveFrom, setMoveFrom] = useLocalStorage<string>(
    KEY_MOVE_BOOKING_FROM,
    ''
  );

  const handleGetNextDebitDay = useCallback(async () => {
    if (!selectedLocation?._id) return;
    try {
      const response = await getNextDebitDay(selectedLocation?._id);
      setNextDebitDay(response.data.data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Get next debit day failed'
      );
      setNextDebitDay('');
    }
    // eslint-disable-next-line
  }, [selectedLocation?._id]);

  useEffect(() => {
    handleGetNextDebitDay();
  }, [handleGetNextDebitDay]);

  const __canConfirmMove = useMemo(() => {
    const step = searchParams.get('stepBooking');
    if (step === ENROLLMENT_STEP.BOOKING) {
      return !!selectedClassIds.length;
    }
    if (step === ENROLLMENT_STEP.ENROLLMENT) {
      const classChecked =
        studentDataBooking?.classesData?.filter(
          (classItem) => classItem.checked
        ) || [];
      if (currentEnrolmentClass?.type === BOOKING_TYPE.ONGOING_CLASS) {
        return classChecked.length > 0;
      }
      if (currentEnrolmentClass?.type === BOOKING_TYPE.HOLIDAY_PROGRAM) {
        return (
          classChecked[0]?.schedules.filter((item) => item.checked).length > 0
        );
      }
      return classChecked.length > 0;
    }
    if (step === ENROLLMENT_STEP.PAYMENT) {
      return !!studentDataBooking?.bookingData?.[0]?.paymentOption;
    }
    return true;
  }, [
    studentDataBooking,
    selectedClassIds,
    searchParams,
    currentEnrolmentClass
  ]);

  const __warningMovingType = useMemo(() => {
    if (
      !currentEnrolmentClass ||
      selectedClassIds.length === 0 ||
      currentEnrolmentClass?.type === BOOKING_TYPE.HOLIDAY_PROGRAM ||
      !selectedSession
    ) {
      return WARNING_MOVING_TYPE.IDLE;
    }
    return checkWarningMovingType(
      selectedSession,
      currentEnrolmentClass,
      nextDebitDay,
      studentDataBooking?.bookingData?.[0]?.paymentOption
    );
  }, [
    currentEnrolmentClass,
    selectedSession,
    selectedClassIds,
    nextDebitDay,
    studentDataBooking
  ]);

  const __discountApplied = useMemo((): DiscountApplied | undefined => {
    if (!currentEnrolmentClass || !currentEnrolmentClass.discountType) {
      return undefined;
    }
    if (
      currentEnrolmentClass?.discountType === DISCOUNT_TYPE.PERCENTAGE &&
      currentEnrolmentClass.discountFrom === DISCOUNT_FROM.AUTOMATION_DISCOUNT
    ) {
      if (currentEnrolmentClass.isQualified) {
        return {
          discountType: currentEnrolmentClass.discountType,
          discountFrom: currentEnrolmentClass.discountFrom as DISCOUNT_FROM,
          discountValue: currentEnrolmentClass.discountValue || 0,
          automationDiscountType: currentEnrolmentClass.automationDiscountType,
          remainDiscountAmount: currentEnrolmentClass.remainDiscountAmount || 0,
          voucherCode: currentEnrolmentClass.voucherCode
        };
      } else {
        return undefined;
      }
    }

    if (currentEnrolmentClass.discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
      return {
        discountType: currentEnrolmentClass.discountType,
        discountFrom: currentEnrolmentClass.discountFrom as DISCOUNT_FROM,
        discountValue: currentEnrolmentClass.discountValue || 0,
        automationDiscountType: currentEnrolmentClass.automationDiscountType,
        remainDiscountAmount: currentEnrolmentClass.remainDiscountAmount || 0,
        numberOfFreeLesson: currentEnrolmentClass.numberOfFreeLesson,
        remainFreeLesson: currentEnrolmentClass.remainFreeLesson,
        duration: currentEnrolmentClass.duration,
        startDateFree: currentEnrolmentClass.startDateFree,
        endDateFree: currentEnrolmentClass.endDateFree,
        voucherCode: currentEnrolmentClass.voucherCode
      };
    }
    return {
      discountType: currentEnrolmentClass.discountType,
      discountFrom: currentEnrolmentClass.discountFrom as DISCOUNT_FROM,
      discountValue: currentEnrolmentClass.discountValue || 0,
      automationDiscountType: currentEnrolmentClass.automationDiscountType,
      remainDiscountAmount: currentEnrolmentClass.remainDiscountAmount || 0,
      voucherCode: currentEnrolmentClass.voucherCode
    };
  }, [currentEnrolmentClass]);

  const __discountData = useMemo(() => {
    if (!studentDataBooking?.bookingData) return [];
    const bookingData = cloneDeep(studentDataBooking.bookingData[0]);
    const result = [];
    if (__discountApplied) {
      const {
        discountType,
        discountValue,
        automationDiscountType,
        discountFrom,
        remainDiscountAmount
      } = __discountApplied;

      if (
        discountFrom === DISCOUNT_FROM.AUTOMATION_DISCOUNT &&
        automationDiscountType === AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT &&
        bookingData.paymentOption === PAYMENT_VALUE.UPFRONT
      ) {
        return [];
      }
      if (discountType === DISCOUNT_TYPE.AMOUNT && remainDiscountAmount === 0) {
        return [];
      }
      let amount = 0;
      let valueDiscount = '';
      const { pricePayNow = 0 } = bookingData;
      if (discountType === DISCOUNT_TYPE.PERCENTAGE) {
        if (pricePayNow > 0)
          amount = roundByTwo(pricePayNow * (discountValue / 100));
        valueDiscount = `${discountValue}% off`;
      } else {
        if (pricePayNow > 0) {
          amount =
            remainDiscountAmount < pricePayNow
              ? roundByTwo(remainDiscountAmount)
              : pricePayNow;
        }
        valueDiscount = `${formatMoneySign(discountValue)} off`;
      }

      result.push({
        description: `${valueDiscount} (${getProgramType(
          bookingData.classInfo.type
        )}) (${handleDateTimeRecord(bookingData)}) ${formatData(
          studentDataBooking.lastName
        )}, ${formatData(studentDataBooking.firstName)}`,
        amount: amount
      });
    }
    return result;
  }, [studentDataBooking, __discountApplied]);

  const __moneyCredit = useMemo((): number => {
    if (!studentDataBooking?.bookingData?.[0]) return 0;
    const subTotalNow = studentDataBooking.bookingData?.[0].pricePayNow || 0;
    if (subTotalNow === 0) return 0;
    const discountAmount = __discountData.reduce(
      (a: number, b: DiscountData) => {
        return a + b.amount;
      },
      0
    );
    if (subTotalNow - discountAmount > moneyCredit) {
      return moneyCredit;
    } else {
      return subTotalNow - discountAmount;
    }
  }, [__discountData, studentDataBooking, moneyCredit]);

  const __totalPayNow = useMemo(() => {
    if (!studentDataBooking) return 0;
    const discountAmount = roundByTwo(
      __discountData.reduce((a: number, b: DiscountData) => {
        return a + b.amount;
      }, 0)
    );
    const result = roundByTwo(
      roundByTwo(
        studentDataBooking?.bookingData
          ?.map((classItem) => {
            return classItem.pricePayNow || 0;
          })
          .reduce((a: number, b: number) => {
            return a + b;
          }, 0) - discountAmount
      ) - __moneyCredit
    );
    return result;
  }, [studentDataBooking, __discountData, __moneyCredit]);

  useEffect(() => {
    if (location.pathname?.includes('move-booking')) {
      if (!selectedClassIds.length) {
        searchParams.set('stepBooking', ENROLLMENT_STEP.BOOKING);
        setSearchParams(searchParams);
      }
    }
    // eslint-disable-next-line
  }, [selectedClassIds]);

  useEffect(() => {
    const step = searchParams.get('stepBooking');
    if (step === ENROLLMENT_STEP.ENROLLMENT) {
      let classDataTemp: ISchedulesOrigin[] = cloneDeep(classesDataForCapacity);
      if (!classDataTemp.length) return;

      const newClassesData = map(classDataTemp, (classData) => {
        const scheduleIds: string[] = [];
        studentDataBooking?.classesData?.forEach((classDataInStudent) => {
          if (
            classDataInStudent.checked &&
            classDataInStudent._id === classData._id
          ) {
            scheduleIds.push(
              ...classDataInStudent.schedules
                .filter((scheduleInStudent) => {
                  return scheduleInStudent.checked;
                })
                .map((scheduleInStudent) => scheduleInStudent._id)
            );
          }
        });
        classData.schedules = map(classData.schedules, (scheduleOriginItem) => {
          map(scheduleIds, (scheduleId) => {
            if (scheduleOriginItem._id === scheduleId) {
              scheduleOriginItem.occupied = scheduleOriginItem.occupied + 1;
            }
          });
          return scheduleOriginItem;
        });
        return classData;
      });
      setClassesData(newClassesData);
    }
  }, [studentDataBooking, classesDataForCapacity, searchParams]);

  const fetchStudentDetail = useCallback(async (studentId: string) => {
    try {
      const result = await getStudentDetail(studentId);
      setStudentDataBooking(result);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to get student detail'
      );
      setStudentDataBooking(null);
    }
    // eslint-disable-next-line
  }, []);

  const onChangeCurrentEnrolmentClass = (value: IRPEnrollmentClass | null) => {
    setCurrentEnrolmentClass(value);
  };

  const onChangeSelectedClassIds = (classIds: string[]) => {
    setSelectedClassIds(classIds);
  };

  const onChangeStudentDataBooking = (data: IStudentBookingData) => {
    setStudentDataBooking(data);
  };

  const onChangePaymentMethod = (value: PAYMENT_METHOD) => {
    setPaymentMethod(value);
  };

  const onChangeMoneyCredit = (value: number) => {
    setMoneyCredit(value);
  };

  const onChangeMoveBookingCount = (value: number) => {
    setMoveBookingCount(value);
  };

  const onChangeSelectedSession = (value: ISession | null) => {
    setSelectedSession(value);
  };

  const onChangeMoveFrom = (value: string) => {
    setMoveFrom(value);
  };

  const fetchClassesData = useCallback(async () => {
    try {
      const data: ISchedulesOrigin[] = await getClassSessions(selectedClassIds);
      const classesData = data.map((item) => {
        let enrollmentType = BOOKING_TYPE.NONE;
        switch (item?.classInfo?.type) {
          case CLASS_TYPES.SERIES:
            enrollmentType = BOOKING_TYPE.ONGOING_CLASS;
            break;
          case CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM:
            enrollmentType = BOOKING_TYPE.HOLIDAY_PROGRAM;
            break;

          default:
            break;
        }

        return {
          ...item,
          enrollmentType
        };
      });
      setClassesData(classesData);
      studentDataBooking &&
        setStudentDataBooking({
          ...studentDataBooking,
          classesData
        });
      setClassesDataForCapacity(classesData);

      // handle get data of new class
      const { data: newClass } = await getClassDetail(selectedClassIds[0]);
      setNewEnrolmentClass(newClass);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || `Can't fetch classes data`);
    }
    // eslint-disable-next-line
  }, [selectedClassIds]);

  const moveBookingContextValue: MoveBookingContextType = {
    selectedClassIds,
    onChangeSelectedClassIds,
    studentDataBooking,
    onChangeStudentDataBooking,
    classesData,
    fetchClassesData,
    __canConfirmMove,
    fetchStudentDetail,
    currentEnrolmentClass,
    onChangeCurrentEnrolmentClass,
    __discountData,
    __totalPayNow,
    onChangePaymentMethod,
    paymentMethod,
    moveBookingCount,
    onChangeMoveBookingCount,
    newEnrolmentClass,
    moneyCredit,
    onChangeMoneyCredit,
    __warningMovingType,
    selectedSession,
    onChangeSelectedSession,
    nextDebitDay,
    __discountApplied,
    moveFrom,
    onChangeMoveFrom,
    __moneyCredit
  };

  return (
    <MoveBookingContext.Provider value={moveBookingContextValue}>
      {children}
    </MoveBookingContext.Provider>
  );
};
