import {
  ApplyVoucherToEnrolmentDTO,
  BulkApplyVoucherToEnrolmentDTO,
  CreateNotificationVoucherPayloadDTO,
  CreateUpdateVoucherPayloadDTO,
  ValidateVoucherDTO
} from 'DTOs/voucher.dto';
import { DISCOUNT_TYPE } from 'common/enums';
import API from './API';
import { IEnrolmentFilter } from 'pages/vouchers/interface';
const VOUCHER_ENDPOINT = '/voucher';

export const validateVoucher = (payload: ValidateVoucherDTO) => {
  return API.post(`${VOUCHER_ENDPOINT}/validate`, {
    body: payload
  });
};

export const getVouchers = (params: {
  page: number;
  limit: number;
  filter?: string;
  type: DISCOUNT_TYPE;
  locationId: string;
}) => {
  return API.get(`${VOUCHER_ENDPOINT}`, params);
};

export const createVoucher = (payload: CreateUpdateVoucherPayloadDTO) => {
  return API.post(VOUCHER_ENDPOINT, {
    body: payload
  });
};

export const updateVoucher = (
  id: string,
  payload: CreateUpdateVoucherPayloadDTO
) => {
  return API.put(`${VOUCHER_ENDPOINT}/${id}`, {
    body: payload
  });
};

export const deleteVoucher = async (id: string) => {
  return API.delete(`${VOUCHER_ENDPOINT}/${id}`);
};

export const getDetailVoucher = (id: string) => {
  return API.get(`${VOUCHER_ENDPOINT}/${id}`);
};

export const sendVoucherToMember = (
  payload: CreateNotificationVoucherPayloadDTO
) => {
  return API.post(`${VOUCHER_ENDPOINT}/send-to-member`, {
    body: payload
  });
};

export const applyVoucherToEnrolment = async (
  payload: ApplyVoucherToEnrolmentDTO
) => {
  return API.post(`${VOUCHER_ENDPOINT}/apply`, {
    body: payload
  });
};

export const removeVoucherFromEnrolment = async (id: string) => {
  return API.post(`${VOUCHER_ENDPOINT}/remove`, {
    body: { bookClassId: id }
  });
};

export const bulkApplyVoucherToEnrolment = async (
  payload: BulkApplyVoucherToEnrolmentDTO
) => {
  return API.post(`${VOUCHER_ENDPOINT}/bulk-apply`, {
    body: payload
  });
};

export const getEnrolmentList = async (params: IEnrolmentFilter) => {
  return await API.get(`/bookings`, params);
};

export const getStudentListOfVoucher = (
  id: string,
  page: number,
  limit: number,
  search?: string
) => {
  return API.get(`${VOUCHER_ENDPOINT}/${id}/students`, {
    page,
    limit,
    keyword: search
  });
};
