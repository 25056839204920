export enum WEEK_DAY_TYPES {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6
}

export enum NOTIFICATION_EVENT {
  NEW_WEBSITE_ENQUIRIES = 'new_website_enquiries',
  NEW_ENROLLMENTS = 'new_enrollments',
  STUDENT_CANCELLATIONS = 'student_cancellations',
  ABSENCE_FORMS = 'absence_forms',
  NON_ATTENDANCE = 'non_attendance',
  MANUAL = 'manual',
  NEW_EMAIL = 'new_email',
  NEW_SMS = 'new_sms'
}

export enum FILTER_NOTIFICATION_STATUS {
  IDLE = '',
  READ = 'read',
  UNREAD = 'unread'
}
