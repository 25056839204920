import { Suspense, memo, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import EnrollmentHeader from './Header/index';
import TableClasses from './Class/TableClasses';
import BookingClasses from 'components/Enrollment/BookingClasses';
import EnrollmentFooter from './Footer';
import ReviewNPayment from 'components/Enrollment/ReviewNPayment';
import { useLayout } from 'context/LayoutContext';
import { ENROLLMENT_STEP } from 'common/enums/student.enum';
import { useToast } from 'context/ToastContext';
import { getNextDebitDay } from 'services/payment.service';
import { useBrandLocation } from 'context/BrandLocationContext';
import { useEnrollmentContext } from './EnrollmentContext';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { TAB } from 'common/constants';

const EnrollmentFlow = () => {
  const { handleMouseLeaveMenu } = useLayout();
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();
  const { selectedLocation } = useBrandLocation();
  const { selectedClassIds } = useEnrollmentContext();
  const [tabIndex, setTabIndex] = useState<number>(TAB.IDLE);
  const [nextDebitDay, setNextDebitDay] = useState('');

  const handleGetNextDebitDay = useCallback(async () => {
    try {
      if (!selectedLocation?._id) return;

      const response = await getNextDebitDay(selectedLocation?._id);
      setNextDebitDay(response.data.data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Get next debit day failed'
      );
      setNextDebitDay('');
    }

    // eslint-disable-next-line
  }, [selectedLocation?._id]);

  const tabs = [
    {
      name: 'Booking',
      component: <TableClasses />
    },
    {
      name: 'Enrolment',
      component: <BookingClasses />
    },
    {
      name: 'Review & Payment',
      component: <ReviewNPayment nextDebitDay={nextDebitDay} />
    }
  ];

  useEffect(() => {
    handleGetNextDebitDay();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const params = searchParams.get('stepBooking') || ENROLLMENT_STEP.BOOKING;
    setTabIndex(Number(params) - 1);
  }, [searchParams]);

  const handleClickStep = (value: string) => {
    searchParams.set('stepBooking', value);
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <div className="booking" onMouseEnter={handleMouseLeaveMenu}>
      <EnrollmentHeader items={[]} onClickStep={handleClickStep} />
      {tabIndex >= 0 ? (
        <Suspense fallback={<AppLoadingContainer />}>
          {tabs[tabIndex].component}
        </Suspense>
      ) : null}
      <EnrollmentFooter selectedClassTotal={selectedClassIds.length} />
    </div>
  );
};

export default memo(EnrollmentFlow);
