import { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import UploadAvatar from 'components/UploadAvatar';
import AppButton from 'common/components/AppButton';
import { useBrandLocation } from 'context/BrandLocationContext';
import { IStudent } from 'common/interfaces/student.interface';
import {
  formatData,
  formatDate,
  formatSecretPhoneNumber
} from 'common/helpers/dataFormat.helper';
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi';

import './desktop.scss';

type ShowPhoneType = { idx: number; value: boolean };

interface Props {
  data: IStudent | null;
}

const ResponsiblePersonInformation = ({ data }: Props) => {
  const navigate = useNavigate();

  const { locations: globalLocations } = useBrandLocation();

  const [RPdata, setRPdata] = useState({
    ...(data?.listResponsiblePerson?.[0] ?? {})
  });

  const [showPhone, setShowPhone] = useState<ShowPhoneType[]>([
    {
      idx: 0,
      value: false
    }
  ]);

  const handleShowPhone = (phoneIndex: number) => {
    setShowPhone((pre: ShowPhoneType[]) => {
      if (pre?.filter((ob: ShowPhoneType) => ob.idx === phoneIndex)?.length) {
        return pre?.map((obj: ShowPhoneType) => {
          if (obj?.idx === phoneIndex) {
            return { ...obj, value: !obj?.value };
          }
          return obj;
        });
      }

      return [...pre, { idx: phoneIndex, value: true }];
    });
  };

  useEffect(() => {
    setRPdata({
      ...(data?.listResponsiblePerson?.[0] ?? {})
    });
  }, [data]);

  if (!RPdata?._id) {
    return <AppLoadingContainer />;
  }

  return (
    <section className="responsible-person-information">
      <AppCard>
        <AppCardContent className="card-content">
          <>
            <div className="responsible-person-information-main-content">
              <div
                style={{
                  pointerEvents: 'none'
                }}
              >
                <UploadAvatar
                  defaultImage={RPdata?.responsiblePersonInfo?.avatarUrl}
                />
              </div>

              <div className="content">
                <div className="content_tittle">
                  <AppCardHeader title="Responsible Person INFORMATION" />

                  <AppButton
                    type="button"
                    buttonSize={'small'}
                    onClick={() =>
                      navigate(
                        `/responsible-person/${RPdata?.responsiblePersonInfo?._id}`
                      )
                    }
                  >
                    <div>Go to RP Profile</div>
                  </AppButton>
                </div>
                <div className="content_grid">
                  <div className="item">
                    <AppCardContentItem
                      subtitle="Name"
                      title={formatData(
                        RPdata?.responsiblePersonInfo?.firstName
                      )}
                    />
                  </div>
                  <div className="item">
                    <AppCardContentItem
                      subtitle="Surname"
                      title={formatData(
                        RPdata?.responsiblePersonInfo?.lastName
                      )}
                    />
                  </div>
                  <div className="item">
                    <AppCardContentItem
                      subtitle="Alias"
                      title={formatData(
                        RPdata?.responsiblePersonInfo?.aliasName
                      )}
                    />
                  </div>
                  <div className="item">
                    <AppCardContentItem
                      subtitle="DOB"
                      title={formatDate(RPdata?.responsiblePersonInfo?.dob)}
                    />
                  </div>
                  <div className="item">
                    <AppCardContentItem
                      subtitle="Joining Date"
                      title={formatDate(
                        RPdata?.responsiblePersonInfo?.additionalInfo
                          ?.joiningDate
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="responsible-person-information-sub-content">
              <div className="item_full">
                <AppCardHeader title="CONTACT DETAILS" />
              </div>
              <div className="item">
                <AppCardContentItem subtitle="Mobile Number">
                  <span className="custom_show_phone">
                    {!!showPhone.filter(
                      (obj: ShowPhoneType) => obj.idx === 0 && !!obj.value
                    )?.length ? (
                      <a
                        href={`tel:${RPdata?.responsiblePersonInfo?.phoneNumber}`}
                      >
                        {RPdata?.responsiblePersonInfo?.phoneNumber}{' '}
                      </a>
                    ) : (
                      <span>
                        {formatSecretPhoneNumber(
                          RPdata?.responsiblePersonInfo?.phoneNumber
                        )}
                      </span>
                    )}

                    <button onClick={() => handleShowPhone(0)}>
                      {!!showPhone.filter(
                        (obj: ShowPhoneType) => obj.idx === 0 && !!obj.value
                      )?.length ? (
                        <HiOutlineEye fontSize={20} />
                      ) : (
                        <HiOutlineEyeOff fontSize={20} />
                      )}
                    </button>
                  </span>
                </AppCardContentItem>
              </div>
              <div className="item">
                <AppCardContentItem
                  subtitle="Email Address"
                  title={formatData(RPdata?.responsiblePersonInfo?.email)}
                />
              </div>
              <div className="item">
                <AppCardContentItem
                  subtitle="Home Club"
                  title={formatData(
                    [...globalLocations]
                      .map((location) => {
                        return {
                          value: location._id,
                          label: location.name
                        };
                      })
                      ?.filter(
                        (location) =>
                          location.value ===
                          RPdata?.responsiblePersonInfo?.locationId
                      )?.[0]?.label ?? ''
                  )}
                />
              </div>
            </div>
          </>
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default memo(ResponsiblePersonInformation);
