import {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  memo,
  ChangeEvent,
  useRef,
  useMemo
} from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Resolver, useForm, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from 'lodash';
import AppButton from 'common/components/AppButton';
import AppDatePicker from 'common/components/AppDatePicker';
import AppInput from 'common/components/AppInput';
import AppPhoneNumberInput from 'common/components/AppPhoneNumberInput';
import AppLocationInput from 'components/common/AppLocationInput';
import AppCard, { AppCardHeader } from 'common/components/AppCard';
import UploadAvatar, {
  ERROR_MESSAGE_LIMIT_SIZE
} from 'components/UploadAvatar';
import { HiArrowLeft, HiMiniXMark } from 'react-icons/hi2';
import { useToast } from 'context/ToastContext';
import { addResponsiblePerson } from 'services/responsiblePerson.service';
import { uploadFile } from 'services/uploadFile.service';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { CreateResponsiblePersonDto } from 'DTOs/responsiblePerson.dto';
import { FILE_ASSET_TYPE } from 'common/enums/uploadFile.enum';
import yup from 'validators/responsiblePerson.validator';
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber
} from 'react-phone-number-input';
import { RESPONSE_CHECK_USER } from 'common/enums';
import { checkExisted } from 'services/auth.service';
import {
  ERROR_MESSAGE_EMAIL_FORMAT,
  ERROR_MESSAGE_EMAIL_TAKEN,
  ERROR_MESSAGE_PHONE_NUMBER_FORMAT,
  ERROR_MESSAGE_PHONE_TAKEN
} from 'common/constants/error.constant';

import './desktop.scss';

export interface RefHandle {
  resetForm: () => void;
}

interface Props {
  openAdd?: boolean;
  onCloseAdd?: () => void;
  onSuccess?: () => void;
  onDisable?: (disable: boolean) => void;
  onSubmitForm?: (formData: any) => void;
}

const mainRoute = '/responsible-person';

const validationSchema = yup.OBJECT({
  firstName: yup.RESPONSIBLE_PERSON_NAME,
  lastName: yup.RESPONSIBLE_PERSON_SURNAME,
  aliasName: yup.RESPONSIBLE_PERSON_ALIAS,
  phoneNumber: yup.RESPONSIBLE_PERSON_PHONE_NUMBER,
  email: yup.RESPONSIBLE_PERSON_EMAIL_ADDRESS,
  locationId: yup.RESPONSIBLE_PERSON_LOCATION,
  dob: yup.RESPONSIBLE_PERSON_DOB,
  joiningDate: yup.RESPONSIBLE_PERSON_JOINING_DATE
});

const AddResponsiblePerson = forwardRef<RefHandle, Props>(
  ({ openAdd, onCloseAdd, onSuccess, onDisable, onSubmitForm }, ref) => {
    useImperativeHandle(ref, () => ({
      resetForm() {
        handleResetForm();
      }
    }));

    const location = useLocation();
    const toast = useToast();

    const {
      register,
      setValue,
      reset,
      clearErrors,
      trigger,
      setError,
      control,
      formState: { errors }
    } = useForm<CreateResponsiblePersonDto>({
      resolver: yupResolver(
        validationSchema
      ) as Resolver<CreateResponsiblePersonDto>,
      defaultValues: {
        joiningDate: convertToUnixTime(dayjs()?.format())
      }
    });

    const watchAllFields = useWatch({ control });

    const [loading, setLoading] = useState(false);

    const [avatarUrl, setAvatarUrl] = useState('');

    const [disabled, setDisabled] = useState(false);

    const [isCheckOnServer, setIsCheckOnServer] = useState(false);

    const validateFieldDOB = useMemo(
      () =>
        !(dayjs(watchAllFields?.dob).format('YYYY-MM-DD') === 'Invalid Date') &&
        !dayjs(watchAllFields?.dob).isAfter(dayjs()) &&
        !dayjs(watchAllFields?.dob).isBefore(dayjs().subtract(200, 'year')) &&
        !dayjs(watchAllFields?.dob).isAfter(dayjs().subtract(18, 'year')),
      [watchAllFields?.dob]
    );

    useEffect(() => {
      const noErrors =
        !Object.keys(errors)?.length &&
        // use this field to re-fetch the errors fields from useForm
        !isCheckOnServer;

      if (
        watchAllFields.firstName &&
        watchAllFields.lastName &&
        watchAllFields.email &&
        watchAllFields.phoneNumber &&
        !!validateFieldDOB &&
        noErrors
      ) {
        setDisabled(false);
        if (onDisable) onDisable(false);
      } else {
        setDisabled(true);
        if (onDisable) onDisable(true);
      }
    }, [watchAllFields, errors, onDisable, isCheckOnServer, validateFieldDOB]);

    const abortConRef = useRef<AbortController>();

    // eslint-disable-next-line
    const checkExisting = useCallback(
      debounce(async (phoneNumber?: string, email?: string) => {
        try {
          if (abortConRef.current) abortConRef.current.abort();
          abortConRef.current = new AbortController();

          await checkExisted(
            phoneNumber,
            email?.toLowerCase(),
            abortConRef.current.signal
          );

          const checker = [
            ...(email ? ['email'] : []),
            ...(phoneNumber ? ['phoneNumber'] : [])
          ];

          // @ts-ignore
          clearErrors(checker);
        } catch (error: any) {
          if (
            error?.response?.data?.message === RESPONSE_CHECK_USER.BOTH_EXISTED
          ) {
            setError('phoneNumber', {
              type: 'optionality',
              message: ERROR_MESSAGE_PHONE_TAKEN
            });
            setError('email', {
              type: 'optionality',
              message: ERROR_MESSAGE_EMAIL_TAKEN
            });
            return;
          } else if (
            error?.response?.data?.message === RESPONSE_CHECK_USER.EMAIL_EXISTED
          ) {
            setError('email', {
              type: 'optionality',
              message: ERROR_MESSAGE_EMAIL_TAKEN
            });
            return;
          } else if (
            error?.response?.data?.message ===
            RESPONSE_CHECK_USER.PHONE_NUMBER_EXISTED
          ) {
            setError('phoneNumber', {
              type: 'optionality',
              message: ERROR_MESSAGE_PHONE_TAKEN
            });
            return;
          } else if (
            error?.response?.data?.message?.[0] ===
            RESPONSE_CHECK_USER.PHONE_NUMBER_MUST_BE_A_VALID_PHONE_NUMBER
          ) {
            setError('phoneNumber', {
              type: 'optionality',
              message: ERROR_MESSAGE_PHONE_NUMBER_FORMAT
            });
            return;
          } else if (
            error?.response?.data?.message?.[0] ===
            RESPONSE_CHECK_USER.EMAIL_MUST_BE_AN_EMAIL
          ) {
            setError('email', {
              type: 'optionality',
              message: ERROR_MESSAGE_EMAIL_FORMAT
            });
            return;
          }
        } finally {
          setIsCheckOnServer(false);
        }
      }, 300),
      []
    );

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target.name === 'firstName') {
          setValue('aliasName', event.target.value);
        }

        // @ts-ignore
        setValue(event.target.name, event.target.value);

        if (event.target.name === 'email') {
          trigger(event.target.name).then((res) => {
            if (!res || !event.target.value) return;

            setIsCheckOnServer(true);

            checkExisting('', event.target.value);
          });
        } else {
          // @ts-ignore
          trigger(event.target.name);
        }
      },
      [trigger, setValue, checkExisting]
    );

    const handleChangeDOB = useCallback(
      (val: Dayjs | null) => {
        setValue('dob', convertToUnixTime(dayjs(val)?.format()));

        setIsCheckOnServer(true);

        trigger('dob').then(() => {
          setIsCheckOnServer(false);
        });
      },
      [setValue, trigger]
    );

    const handleChangeLocation = useCallback(
      (val: string) => {
        setValue('locationId', val);

        trigger('locationId');
      },
      [setValue, trigger]
    );

    const handleChangePhoneNumber = useCallback(
      (val: string) => {
        setValue('phoneNumber', val);

        if (!val) {
          trigger('phoneNumber');

          return;
        }

        const phoneNumberCondition = !!(
          isValidPhoneNumber(val) && isPossiblePhoneNumber(val)
        );

        if (phoneNumberCondition) {
          clearErrors('phoneNumber');

          setIsCheckOnServer(true);

          checkExisting(val, '');
        } else {
          setError('phoneNumber', {
            type: 'custom',
            message: 'Invalid phone number'
          });
        }
      },

      [setValue, trigger, setError, checkExisting, clearErrors]
    );

    const handleUploadFile = useCallback(async (file: File) => {
      if (file) {
        const resultAvatar = await uploadFile(FILE_ASSET_TYPE.AVATAR, file);

        setAvatarUrl(resultAvatar.data.data || '');
      }
    }, []);

    const handleRemoveAvatar = useCallback(async () => {
      setAvatarUrl('');
    }, []);

    const handleResetForm = useCallback(() => {
      clearErrors();

      reset({
        firstName: '',
        lastName: '',
        aliasName: '',
        dob: '',
        joiningDate: convertToUnixTime(dayjs()?.format()),
        phoneNumber: '',
        email: '',
        locationId: ''
      });

      setAvatarUrl('');
    }, [reset, clearErrors]);

    const handleCloseModal = useCallback(() => {
      // set timeout match the close modal timeout
      setTimeout(() => handleResetForm(), 100);

      if (onCloseAdd) onCloseAdd();
    }, [onCloseAdd, handleResetForm]);

    const handleSubmit = useCallback(async () => {
      if (disabled) {
        const triggerList = [
          ...(!watchAllFields?.firstName ? ['firstName'] : []),
          ...(!watchAllFields?.lastName ? ['lastName'] : []),
          ...(!watchAllFields?.email ? ['email'] : []),
          ...(!watchAllFields?.phoneNumber ? ['phoneNumber'] : []),
          ...(!validateFieldDOB ? ['dob'] : [])
        ];

        if (triggerList?.length) {
          // @ts-ignore
          trigger(triggerList);
        }
        return;
      }

      try {
        const submitData = { ...watchAllFields };

        if (!submitData) return;

        const mapData = {
          ...submitData,
          ...(avatarUrl ? { avatarUrl } : undefined)
        } as CreateResponsiblePersonDto;

        await checkExisted(
          submitData.phoneNumber,
          (submitData?.email || '').toLowerCase()
        );

        setLoading(true);
        setDisabled(true);

        await addResponsiblePerson(mapData);

        toast.success('Create responsible person successfully');

        // set timeout match the close modal timeout
        setTimeout(() => handleResetForm(), 100);

        if (onSuccess) onSuccess();
      } catch (error: any) {
        if (
          error?.response?.data?.message === RESPONSE_CHECK_USER.BOTH_EXISTED
        ) {
          setError('phoneNumber', {
            type: 'optionality',
            message: ERROR_MESSAGE_PHONE_TAKEN
          });
          setError('email', {
            type: 'optionality',
            message: ERROR_MESSAGE_EMAIL_TAKEN
          });
          return;
        } else if (
          error?.response?.data?.message === RESPONSE_CHECK_USER.EMAIL_EXISTED
        ) {
          setError('email', {
            type: 'optionality',
            message: ERROR_MESSAGE_EMAIL_TAKEN
          });
          return;
        } else if (
          error?.response?.data?.message ===
          RESPONSE_CHECK_USER.PHONE_NUMBER_EXISTED
        ) {
          setError('phoneNumber', {
            type: 'optionality',
            message: ERROR_MESSAGE_PHONE_TAKEN
          });
          return;
        } else if (
          error?.response?.data?.message?.[0] ===
          RESPONSE_CHECK_USER.EMAIL_MUST_BE_AN_EMAIL
        ) {
          setError('email', {
            type: 'optionality',
            message: ERROR_MESSAGE_EMAIL_FORMAT
          });
          return;
        } else {
          toast.error(
            error?.response?.data?.message || 'Create responsible person failed'
          );
        }
      } finally {
        setDisabled(false);
        setLoading(false);
      }

      // eslint-disable-next-line
    }, [
      disabled,
      handleResetForm,
      onSuccess,
      trigger,
      watchAllFields,
      avatarUrl,
      setError
    ]);

    useEffect(() => {
      const submitData = { ...watchAllFields };

      if (!onSubmitForm || !submitData) return;

      onSubmitForm(submitData);
    }, [watchAllFields, avatarUrl, onSubmitForm]);

    const AddResponsiblePersonPure = useCallback(() => {
      return (
        <>
          <div className="responsiblePersonAddForm__content">
            <AppCard>
              <div className="responsiblePersonAddForm__content-wrapper">
                {/* PERSONAL INFORMATION */}
                <>
                  <div className="responsiblePersonAddForm__content-responsible_person_add_information_wrapper">
                    <div className="avatar__container">
                      <UploadAvatar
                        defaultImage={avatarUrl ? avatarUrl : undefined}
                        onChangeFile={handleUploadFile}
                        onErrorFile={(errorMessage: string) => {
                          if (errorMessage === ERROR_MESSAGE_LIMIT_SIZE) {
                            toast.error('Exceed the MB');
                          }
                        }}
                      />

                      {avatarUrl ? (
                        <HiMiniXMark
                          className="remove__avatar"
                          size={24}
                          onClick={handleRemoveAvatar}
                        />
                      ) : null}
                    </div>
                    <div className="responsiblePersonAddForm__content-responsible_person_add">
                      <div className="item_tittle">
                        <AppCardHeader title="PERSONAL INFORMATION" />
                      </div>

                      <div className="item">
                        <AppInput
                          {...register('firstName')}
                          label="Name*"
                          onChange={handleChange}
                          message={{
                            type: 'error',
                            text: errors?.firstName?.message || ''
                          }}
                        />
                      </div>
                      <div className="item">
                        <AppInput
                          {...register('lastName')}
                          label="Surname*"
                          onChange={handleChange}
                          message={{
                            type: 'error',
                            text: errors?.lastName?.message || ''
                          }}
                        />
                      </div>
                      <div className="item">
                        <AppInput
                          {...register('aliasName')}
                          label="Alias"
                          onChange={handleChange}
                          message={{
                            type: 'error',
                            text: errors?.aliasName?.message || ''
                          }}
                        />
                      </div>

                      <div className="item">
                        <AppDatePicker
                          {...register('dob')}
                          label="D.O.B*"
                          value={
                            watchAllFields?.dob
                              ? dayjs(watchAllFields?.dob)
                              : null
                          }
                          disableFuture
                          onChange={handleChangeDOB}
                          message={{
                            type: 'error',
                            text: errors?.dob?.message || ''
                          }}
                        />
                      </div>
                      <div className="item">
                        <AppDatePicker
                          label="Profile creation date*"
                          value={dayjs()}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="responsiblePersonAddForm__content-responsible_person_add_contact_wrapper">
                    <div className="item_tittle">
                      <AppCardHeader title="Contact Detail" />
                    </div>

                    <div className="item">
                      <AppPhoneNumberInput
                        {...register('phoneNumber')}
                        label="Mobile Number*"
                        value={watchAllFields?.phoneNumber || ''}
                        onChange={handleChangePhoneNumber}
                        message={{
                          type: 'error',
                          text: errors?.phoneNumber?.message || ''
                        }}
                      />
                    </div>
                    <div className="item">
                      <AppInput
                        {...register('email')}
                        label="Email Address*"
                        onChange={handleChange}
                        message={{
                          type: 'error',
                          text: errors?.email?.message || ''
                        }}
                      />
                    </div>
                    <div className="item">
                      <AppLocationInput
                        {...register('locationId')}
                        label="Home Club*"
                        onChangeLocation={handleChangeLocation}
                      />
                    </div>
                  </div>
                </>
              </div>
            </AppCard>
          </div>
        </>
      );

      // eslint-disable-next-line
    }, [
      avatarUrl,
      errors,
      watchAllFields,
      handleChange,
      handleChangeDOB,
      handleChangeLocation,
      handleChangePhoneNumber,
      handleRemoveAvatar,
      handleUploadFile,
      register
    ]);

    if (location.pathname === mainRoute) {
      return (
        <div className="responsiblePersonAddContainer">
          <div
            className={`overlay ${openAdd ? 'active' : ' '}`}
            onClick={handleCloseModal}
          ></div>
          <div
            className={`responsiblePersonAddForm ${openAdd ? 'active' : ' '}`}
          >
            <div className="responsiblePersonAddForm__header">
              <HiArrowLeft
                size={24}
                style={{ cursor: 'pointer' }}
                onClick={handleCloseModal}
              />
              <p>Add Responsible Person</p>
            </div>

            {openAdd ? AddResponsiblePersonPure() : null}

            <div className="responsiblePersonAddForm__actions">
              <AppButton
                type="button"
                variant={disabled ? 'disabled' : 'primary'}
                onClick={handleSubmit}
                isLoading={loading}
              >
                Save
              </AppButton>
              <AppButton variant="secondary" onClick={handleCloseModal}>
                Cancel
              </AppButton>
            </div>
          </div>
        </div>
      );
    }

    return <>{openAdd ? AddResponsiblePersonPure() : null}</>;
  }
);

export default memo(AddResponsiblePerson);
