import React from 'react';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import AppButton from 'common/components/AppButton';

interface INotificationTemplateDeleteProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
}

const NotificationTemplateDelete = (
  props: INotificationTemplateDeleteProps
) => {
  const { open, onClose, onConfirm, loading } = props;

  return (
    <AppModal open={open} onClose={onClose}>
      <AppModalTitle>Delete </AppModalTitle>
      <AppModalContent>
        ARE YOU SURE YOU WANT TO Delete THIS template?
      </AppModalContent>
      <AppModalActions>
        <AppButton variant="secondary" onClick={onClose}>
          No
        </AppButton>
        <AppButton isLoading={loading} onClick={onConfirm}>
          Yes
        </AppButton>
      </AppModalActions>
    </AppModal>
  );
};

export default NotificationTemplateDelete;
