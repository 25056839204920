import React from 'react';
import { HiOutlineCloudArrowUp } from 'react-icons/hi2';

interface IUploadFormProps {
  isDragging: boolean;
  onDrag: (event: React.DragEvent<HTMLFormElement | HTMLDivElement>) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  limitFiles: number;
  limitSize: number;
  accept: string;
}

const UploadForm = React.forwardRef<HTMLInputElement, IUploadFormProps>(
  (
    { isDragging, onDrag, onDrop, onChange, limitFiles, limitSize, accept },
    ref
  ) => {
    return (
      <form
        className="c__file-upload"
        onDragEnter={onDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          ref={ref}
          type="file"
          accept={accept}
          className="c__file-upload-input"
          id="input-file-upload"
          multiple={limitFiles > 1}
          onChange={onChange}
          onClick={(event: any) => {
            event.target.value = null;
          }}
        />
        <label
          className={`c__file-upload-label ${isDragging ? 'drag-active' : ''}`}
          htmlFor="input-file-upload"
        >
          <div className="c__file-upload-label-content">
            <div className="c__file-upload-label-content-image">
              <HiOutlineCloudArrowUp size={32} />
            </div>
            <p>
              Drag and drop file here or{' '}
              <strong className="upload-button">browse to file.</strong>
            </p>
            <span>
              Select up to {limitFiles} files <br />
              Max size: {limitSize}mb <br />
              Image: {accept.replaceAll('.', '')}
            </span>
          </div>
        </label>
        {isDragging && (
          <div
            className="c__file-upload-drag-element"
            onDragEnter={onDrag}
            onDragLeave={onDrag}
            onDragOver={onDrag}
            onDrop={onDrop}
          ></div>
        )}
      </form>
    );
  }
);

export default UploadForm;
