import {
  CreateStaffDTO,
  UpdateStaffAccessDTO,
  UpdateStaffContactDetailDTO,
  UpdateStaffPersonalInformationDTO,
  UpdateStaffStatusDTO
} from 'DTOs/staff.dto';
import API from './API';

const STAFF_ENDPOINT = `/staffs`;

export const getStaffs = (
  locationId: string,
  page: number,
  limit: number,
  keyword: string
) => {
  return API.get(`${STAFF_ENDPOINT}`, {
    locationId,
    page,
    limit,
    keyword
  });
};

export const getStaffsByRole = (
  roleId: string,
  page: number,
  limit: number,
  keyword: string
) => {
  return API.get(`${STAFF_ENDPOINT}`, {
    filter: JSON.stringify({ 'additionalInfo.roleId': roleId }),
    page,
    limit,
    keyword
  });
};

export const getStaffById = (id: string) => {
  return API.get(`${STAFF_ENDPOINT}/${id}`);
};

export const deleteStaff = (id: string) => {
  return API.delete(`${STAFF_ENDPOINT}/${id}`);
};

export const createStaff = (payload: CreateStaffDTO) => {
  return API.post(`${STAFF_ENDPOINT}`, {
    body: payload
  });
};

export const updateStaffPersonalInformation = (
  id: string,
  payload: UpdateStaffPersonalInformationDTO
) => {
  return API.patch(`${STAFF_ENDPOINT}/${id}/personal-information`, {
    body: payload
  });
};

export const updateStaffContactDetail = (
  id: string,
  payload: UpdateStaffContactDetailDTO
) => {
  return API.patch(`${STAFF_ENDPOINT}/${id}/contact-detail`, {
    body: payload
  });
};

export const updateStaffAccess = (
  id: string,
  payload: UpdateStaffAccessDTO
) => {
  return API.patch(`${STAFF_ENDPOINT}/${id}/staff-access`, {
    body: payload
  });
};

export const updateStaffStatus = (
  id: string,
  payload: UpdateStaffStatusDTO
) => {
  return API.patch(`${STAFF_ENDPOINT}/${id}/personal-information`, {
    body: payload
  });
};
