import { ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import './desktop.scss';

type TAB = {
  name: string;
  component: string | ReactNode;
  hidden?: boolean;
};

interface Props {
  tabs: TAB[];
  activeTabIndex: number;
  onChangeTab?: (tabIndex: number) => void;
}

const AppSubTabs = ({ tabs, activeTabIndex, onChangeTab }: Props) => {
  return (
    <>
      <div className="c_appSubTabs">
        <Swiper freeMode={true} slidesPerView={'auto'}>
          {tabs.map((tab, key) => {
            if (tab?.hidden) {
              return <></>;
            } else {
              return (
                <SwiperSlide
                  key={key}
                  id={`c_appSubTabs_id_${key}`}
                  className={key === activeTabIndex ? 'active' : ''}
                  onClick={() => {
                    if (!onChangeTab) return;
                    onChangeTab(key);
                  }}
                >
                  <div
                    className={`tab-item ${
                      key === activeTabIndex ? 'active_tab' : 'inactive_tab'
                    }`}
                  >
                    <p>{tab?.name ?? ''}</p>
                  </div>
                </SwiperSlide>
              );
            }
          })}

          <SwiperSlide />
        </Swiper>
      </div>
    </>
  );
};

export default AppSubTabs;
