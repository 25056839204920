import React from 'react';
import { useAuth } from 'context/AuthContext';
import { createColumnHelper } from '@tanstack/react-table';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useBrandLocation } from 'context/BrandLocationContext';
import useDebounce from 'common/hooks/useDebounce';
import AppInputSearch from 'common/components/AppInputSearch';
import { HiMagnifyingGlass, HiPlus } from 'react-icons/hi2';
import AppButton from 'common/components/AppButton';
import AppTable from 'common/components/AppTable';
import NotificationTemplateAdd from '../NotificationTemplateAdd';
import ActionPopper from './ActionPopper';
import { INotificationTemplate } from 'common/interfaces/notificationTemplate.interface';
import {
  createNotificationTemplate,
  deleteNotificationTemplate,
  getNotificationTemplateList
} from 'services/notificationTemplate.service';
import NotificationTemplateDelete from '../NotificationTemplateDelete';
import { useToast } from 'context/ToastContext';
import { CreateNotificationTemplateDTO } from 'DTOs/notificationTemplate.dto';
import NotificationTemplateView from '../NotificationTemplateView';
import './desktop.scss';
import { NOTIFICATION_TYPE } from 'common/enums/notification.enum';

const NotificationTemplateList = () => {
  const toast = useToast();
  const { selectedLocation: __globalLocation } = useBrandLocation();
  const { hasPermission } = useAuth();

  const columnHelper = createColumnHelper<INotificationTemplate>();

  const columns = [
    columnHelper.accessor('title', {
      header: () => <span>NAME</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('createdAt', {
      header: () => <span>Creation date</span>,
      cell: (info) => formatDate(info.getValue())
    }),
    columnHelper.accessor('types', {
      header: () => <span>type</span>,
      cell: (info) =>
        formatData(
          (info.getValue() as Array<string>)
            .join(', ')
            .replace(NOTIFICATION_TYPE.PUSH, 'Member Portal')
        )
    }),
    columnHelper.accessor('_id', {
      header: () => <span>Action</span>,
      cell: (info) => (
        <ActionPopper
          onEdit={
            hasPermission(PERMISSION.VIEW_NOTIFICATION_TEMPLATE)
              ? () => handleSelectEdit(info.row.original)
              : undefined
          }
          onDuplicate={
            hasPermission(PERMISSION.CREATE_NOTIFICATION_TEMPLATE)
              ? () => handleDuplicate(info.row.original)
              : undefined
          }
          onDelete={
            hasPermission(PERMISSION.DELETE_NOTIFICATION_TEMPLATE)
              ? () => handleSelectDelete(info.row.original)
              : undefined
          }
        />
      )
    })
  ];

  const [loading, setLoading] = React.useState<boolean>(true);
  const [pageIndex, setPageIndex] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [pageTotal, setPageTotal] = React.useState<number>(-1);

  const [data, setData] = React.useState<Array<INotificationTemplate>>([]);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce<string>(search);

  const [isOpenAddForm, setIsOpenAddForm] = React.useState<boolean>(false);

  const [selectedEdit, setSelectedEdit] =
    React.useState<INotificationTemplate | null>(null);

  const [selectedDelete, setSelectedDelete] =
    React.useState<INotificationTemplate | null>(null);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  const onClearSearch = () => {
    setSearch('');
  };

  // EDIT SECTION
  const handleEditSuccess = async () => {
    handleCloseEdit();
    setSearch('');
    setPageIndex(1);
    fetchData();
  };
  const handleSelectEdit = (notificationTemplate: INotificationTemplate) => {
    setSelectedEdit(notificationTemplate);
  };
  const handleCloseEdit = () => {
    setSelectedEdit(null);
  };
  //  END - EDIT SECTION

  // ADD SECTION
  const handleAddSuccess = async () => {
    handleCloseAddForm();
    setSearch('');
    setPageIndex(1);
    fetchData();
  };
  const handleOpenAddForm = () => {
    setIsOpenAddForm(true);
  };
  const handleCloseAddForm = () => {
    setIsOpenAddForm(false);
  };
  //  END - ADD SECTION

  // DELETE SECTION
  const handleSelectDelete = (notificationTemplate: INotificationTemplate) => {
    setSelectedDelete(notificationTemplate);
  };
  const handleCancelDelete = () => {
    setSelectedDelete(null);
  };
  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      await deleteNotificationTemplate(selectedDelete?._id || '');
      toast.success('Delete notification template successfully');
      setSelectedDelete(null);
      setSearch('');
      setPageIndex(1);
      fetchData();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'Failed to delete notification template'
      );
    } finally {
      setLoading(false);
    }
  };
  // END - DELETE SECTION

  // DUPLICATE SECTION
  const handleDuplicate = async (
    notificationTemplate: INotificationTemplate
  ) => {
    if (!__globalLocation?._id) return;
    setLoading(true);
    try {
      const payload: CreateNotificationTemplateDTO = {
        title: notificationTemplate.title,
        types: notificationTemplate.types,
        message: notificationTemplate.message,
        attachmentFiles: notificationTemplate.attachmentFiles,
        locationId: __globalLocation?._id
      };

      if (notificationTemplate?.attachmentFiles?.length === 0)
        delete payload.attachmentFiles;

      await createNotificationTemplate(payload);

      toast.success('Duplicate notification template successfully');

      setSearch('');
      setPageIndex(1);
      fetchData();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'Failed to duplicate notification template'
      );
    } finally {
      setLoading(false);
    }
  };
  // END - DUPLICATE SECTION

  const fetchData = React.useCallback(async () => {
    setLoading(true);
    try {
      const result = await getNotificationTemplateList(
        pageIndex,
        pageSize,
        search
      );
      setData(result?.data?.data?.data);
      setPageTotal(result?.data?.data?.total);
    } catch (error) {
      setData([]);
      setPageTotal(-1);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [debouncedSearch, pageIndex, pageSize]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    setPageIndex(1);
  }, [debouncedSearch]);

  return (
    <main className="notificationTemplateList">
      {/* ADD FORM */}
      {isOpenAddForm && (
        <NotificationTemplateAdd
          open={isOpenAddForm}
          onClose={handleCloseAddForm}
          onSuccess={handleAddSuccess}
        />
      )}

      {/* VIEW FORM */}
      {!!selectedEdit && (
        <NotificationTemplateView
          open={!!selectedEdit}
          dataNotificationTemplate={selectedEdit}
          onClose={handleCloseEdit}
          onSuccess={handleEditSuccess}
        />
      )}

      {/* DELETE FORM */}
      <NotificationTemplateDelete
        open={!!selectedDelete}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        loading={loading}
      />

      <div className="notificationTemplateList__table">
        <div className="notificationTemplateList__table--header">
          <h2 className="notificationTemplateList__table--header--title">
            Notification Template(s)
          </h2>
          <div className="notificationTemplateList__table--header--search">
            <AppInputSearch
              type="text"
              value={search}
              onChange={onChangeSearch}
              placeholder="Notification name"
              onClearSearch={onClearSearch}
              startIcon={<HiMagnifyingGlass />}
            />
            {hasPermission(PERMISSION.CREATE_NOTIFICATION_TEMPLATE) && (
              <AppButton
                variant="primary"
                buttonSize="small"
                type="submit"
                onClick={handleOpenAddForm}
              >
                <div className="plusIcon">
                  <HiPlus />
                </div>
                <p>notification Template</p>
              </AppButton>
            )}
          </div>
        </div>
        <div className="notificationTemplateList__table--content">
          <AppTable
            data={data}
            columns={columns}
            pagination={{
              index: pageIndex,
              size: pageSize,
              total: pageTotal
            }}
            onChangePage={(index: number, size: number) => {
              setPageIndex(index);
              setPageSize(size);
            }}
            loading={loading}
            columnVisibility={
              !hasPermission(PERMISSION.VIEW_DETAIL_CLASS_TEMPLATE) &&
              !hasPermission(PERMISSION.DELETE_CLASS_TEMPLATE)
                ? { _id: false }
                : undefined
            }
          />
        </div>
      </div>
    </main>
  );
};
export default NotificationTemplateList;
