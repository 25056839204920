import { ReactNode, lazy, Suspense, FC } from 'react';

import './index.scss';

const Tabs = lazy(() => import('./Tabs'));

type TAB = {
  name: string;
  component: string | ReactNode;
};

interface Props {
  tabs: Array<TAB>;
  activeTabIndex: number;
  onChangeTab?: (tabIndex: number) => void;
}

const AppTabs: FC<Props> = ({ tabs, activeTabIndex, onChangeTab }) => {
  return (
    <>
      <Suspense>
        <Tabs
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          onChangeTab={onChangeTab}
        />
      </Suspense>
    </>
  );
};

export default AppTabs;
