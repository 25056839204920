import React, { InputHTMLAttributes, useMemo } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  Updater,
  VisibilityState
} from '@tanstack/react-table';
import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronLeft,
  HiChevronRight,
  HiOutlineExclamationCircle
} from 'react-icons/hi';
import AppSelect from '../../components/AppSelect';
import BeatLoader from 'react-spinners/BeatLoader';

import './desktop.scss';

interface IPageSizeOptions {
  value: 'string';
  label: 'string';
}
interface IPagination {
  index: number;
  size: number;
  total: number;
}
type InputSize = 'large' | 'small';

interface IColumn {
  accessorKey: string;
  cell: (info: any) => void;
  header: () => void;
}

interface IAppTableProps extends InputHTMLAttributes<HTMLInputElement> {
  columns?: any[];
  data?: any[];
  onChangePage?: (index: number, size: number) => void;
  onSelectRow?: (original: any, index: number) => void;
  activeRow?: number;

  columnVisibility?: VisibilityState | undefined;
  pagination?: IPagination;
  pageSizeOptions?: Array<IPageSizeOptions>;
  loading?: boolean;

  inputSize?: InputSize;
}

const AppTable = React.forwardRef<HTMLInputElement, IAppTableProps>(
  (
    {
      columns = [],
      data = [],
      onChangePage,
      onSelectRow,
      activeRow,
      loading = false,
      columnVisibility,
      pagination,
      pageSizeOptions = [
        { label: '10', value: '10' },
        { label: '20', value: '20' },
        { label: '30', value: '30' },
        { label: '40', value: '40' },
        { label: '50', value: '50' }
      ],
      inputSize = 'large',
      ...props
    },
    ref
  ) => {
    const pageCount = useMemo(() => {
      if (!!pagination) {
        return Math.ceil(pagination.total / pagination.size);
      }
      return -1;
    }, [pagination]);

    function Table({ data, columns }: { data: any[]; columns: IColumn[] }) {
      const table = useReactTable({
        data,
        columns,
        // Pagination.
        pageCount: !!pagination ? pageCount : undefined,
        state: {
          columnVisibility: !!columnVisibility ? columnVisibility : undefined,
          pagination: !!pagination
            ? {
                pageIndex: pagination?.index - 1,
                pageSize: pagination?.size
              }
            : undefined
        },
        onPaginationChange: (updater: Updater<any>) => {
          if (!!pagination && !!onChangePage) {
            const newState = updater({
              pageIndex: pagination.index - 1,
              pageSize: pagination.size
            });
            onChangePage(newState.pageIndex + 1, newState.pageSize);
          }
        },
        // Pipeline.
        getCoreRowModel: getCoreRowModel(),
        //
        debugTable: false
      });

      return (
        <div
          className="app-table"
          style={loading ? { minHeight: '200px' } : undefined}
        >
          <table cellSpacing="0" cellPadding="0" className="content-table">
            <thead>
              {table.getHeaderGroups().map((headerGroup, i) => (
                <tr key={i} className="table-header">
                  {headerGroup.headers.map((header, i) => (
                    <th
                      key={i}
                      className="table-header-item "
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, i) => (
                <tr
                  key={i}
                  className={`table-content-item ${
                    activeRow === i ? 'active' : ''
                  }`}
                  onClick={
                    onSelectRow ? () => onSelectRow(row.original, i) : undefined
                  }
                >
                  {row.getVisibleCells().map((cell, i) => (
                    <td key={i}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          {loading ? (
            <div className="app-table__loading">
              <div className="app-table__loading-main">
                <BeatLoader />
              </div>
            </div>
          ) : null}

          {!table.getRowModel().rows.length && !loading ? (
            <>
              <div className="table-content-no-results">
                <HiOutlineExclamationCircle fontSize={24} />
                <div>No result</div>
              </div>
            </>
          ) : null}

          {table.getRowModel().rows.length && !!pagination && !!onChangePage ? (
            <div className="flex items-center pagination-table">
              <div className="results-per-page">
                <div>RESULTS PER PAGE</div>
                <div style={{ width: 90, marginLeft: 10 }}>
                  <AppSelect
                    options={pageSizeOptions}
                    inputSize="small"
                    value={
                      pagination.size.toString() || pageSizeOptions[0].value
                    }
                    searchable={false}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onChangePage(1, parseInt(event.target.value));
                    }}
                    style={{ padding: 0, margin: 0 }}
                  />
                </div>
              </div>
              <div className="table-pagination">
                <div className="current-page">
                  <div>
                    PAGE {pagination.index} of {pageCount}
                  </div>
                </div>
                <button
                  className="border rounded p-1 pagination-button"
                  onClick={() => {
                    onChangePage(1, pagination.size);
                  }}
                  disabled={!table.getCanPreviousPage()}
                >
                  <HiChevronDoubleLeft
                    size="16px"
                    style={{
                      opacity: !table.getCanPreviousPage() ? 0.6 : 1,
                      cursor: !table.getCanPreviousPage()
                        ? 'not-allowed'
                        : 'pointer'
                    }}
                  />
                </button>
                <button
                  className="border rounded p-1 pagination-button"
                  onClick={() => {
                    onChangePage(pagination.index - 1, pagination.size);
                  }}
                  disabled={!table.getCanPreviousPage()}
                >
                  <HiChevronLeft
                    size="16px"
                    style={{
                      opacity: !table.getCanPreviousPage() ? 0.6 : 1,
                      cursor: !table.getCanPreviousPage()
                        ? 'not-allowed'
                        : 'pointer'
                    }}
                  />
                </button>
                <button
                  className="border rounded p-1 pagination-button"
                  onClick={() => {
                    onChangePage(pagination.index + 1, pagination.size);
                  }}
                  disabled={!table.getCanNextPage()}
                >
                  <HiChevronRight
                    size="16px"
                    style={{
                      opacity: !table.getCanNextPage() ? 0.6 : 1,
                      cursor: !table.getCanNextPage()
                        ? 'not-allowed'
                        : 'pointer'
                    }}
                  />
                </button>
                <button
                  className="border rounded p-1 pagination-button"
                  onClick={() => {
                    onChangePage(pageCount, pagination.size);
                  }}
                  disabled={!table.getCanNextPage()}
                >
                  <HiChevronDoubleRight
                    size="16px"
                    style={{
                      opacity: !table.getCanNextPage() ? 0.6 : 1,
                      cursor: !table.getCanNextPage()
                        ? 'not-allowed'
                        : 'pointer'
                    }}
                  />
                </button>
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <div style={props.style}>
        <Table
          {...{
            data,
            columns
          }}
        />
      </div>
    );
  }
);

export default AppTable;
