import { Suspense, memo, useEffect, useMemo, useState } from 'react';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import AppTabs from 'common/components/AppTabs';
import { DISCOUNT_TYPE } from 'common/enums';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import { useSearchParams } from 'react-router-dom';
import { PATH } from './constant';
import VoucherList from './voucherList';
import { TAB } from 'common/constants';

import './desktop.scss';

const VoucherListPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState<number>(TAB.IDLE);

  const tabs = useMemo(() => {
    return [
      {
        name: 'FIXED VOUCHER',
        component: <VoucherList discountType={DISCOUNT_TYPE.AMOUNT} />,
        path: PATH.FIXED_VOUCHER
      },
      {
        name: 'PERCENTAGE VOUCHER',
        component: <VoucherList discountType={DISCOUNT_TYPE.PERCENTAGE} />,
        path: PATH.PERCENTAGE_VOUCHER
      },
      {
        name: 'COMPLIMENTARY VOUCHER',
        component: <VoucherList discountType={DISCOUNT_TYPE.COMPLIMENTARY} />,
        path: PATH.COMPLIMENTARY_VOUCHER
      }
    ];
  }, []);

  const handleSelectedTab = (val: number) => {
    setTabIndex(val);

    searchParams.set('indexRoute', `${val}`);
    setSearchParams(searchParams);
  };

  const indexRoute = Number(searchParams.get('indexRoute'));

  useEffect(() => {
    if (indexRoute >= 0 && indexRoute <= tabs.length) {
      setTabIndex(indexRoute);
    } else {
      setTabIndex(TAB.INIT);

      searchParams.set('indexRoute', `${TAB.INIT}`);
      setSearchParams(searchParams, { replace: true });
    }
  }, [indexRoute, searchParams, setSearchParams, tabs]);

  return (
    <main className="voucherListPage">
      <AppBreadCrumb items={[{ name: 'Vouchers', href: '/vouchers' }]} />
      <div className="layoutContainer voucherListPage__wrapper">
        <AppTabs
          tabs={tabs}
          activeTabIndex={tabIndex}
          onChangeTab={handleSelectedTab}
        />

        {tabIndex >= 0 ? (
          <Suspense fallback={<AppLoadingContainer />}>
            {tabs[tabIndex].component}
          </Suspense>
        ) : null}
      </div>
    </main>
  );
};

export default memo(VoucherListPage);
