import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage
} from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;

initializeApp(firebaseConfig);

// const analytics = getAnalytics(app);

const messaging = async () => {
  try {
    const isSupportedBrowser = await isSupported();

    if (isSupportedBrowser) {
      return getMessaging();
    }

    console.info('Firebase not supported this browser');
    return null;
  } catch (err) {
    console.info(err);
    return null;
  }
};

const PERMISSION_GRANTED = 'granted';

export const requestForToken = async (
  callback?: (currentToken: string) => void
) => {
  try {
    if ('serviceWorker' in navigator) {
      await navigator.serviceWorker.register(`/firebase-messaging-sw.js`, {
        scope: `/`
      });

      const permission = await Notification.requestPermission();

      if (permission === PERMISSION_GRANTED) {
        const messagingResolve = await messaging();

        if (!messagingResolve) return;

        try {
          const currentToken = await getToken(messagingResolve, {
            vapidKey: VAPID_KEY
          });

          if (currentToken) {
            if (callback) callback(currentToken);
          } else {
            console.error('Failed to generate the registration token.');
          }
        } catch (error) {
          console.error(
            'An error occurred when requesting to receive the token.',
            error
          );
        }
      } else {
        console.error('User Permission Denied.');
      }
    }
  } catch (err) {
    console.error('An error occurred while retrieving token. ', err);
  }
};

export const onMessageListener = async () => {
  try {
    const messagingResolve = await messaging();

    if (!messagingResolve) return;

    return new Promise((resolve) => {
      onMessage(messagingResolve, (payload) => {
        resolve(payload);
      });
    });
  } catch (err) {
    console.error(err);
  }
};
