import { useState } from 'react';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import AppButton from 'common/components/AppButton';
import { deleteClass } from 'services/classes.service';
import { useToast } from 'context/ToastContext';

interface IClassDeleteModalProps {
  classSelected: any;
  onClose: () => void;
  onSuccess: () => void;
}

const ClassDeleteModal = (props: IClassDeleteModalProps) => {
  const { classSelected, onClose, onSuccess } = props;

  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteClass(classSelected?._id);
      toast.success('Deleted the class successfully');
      onSuccess();
    } catch (error) {
      toast.error('Failed to delete the class');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppModal open={!!classSelected} onClose={onClose}>
      <AppModalTitle>Delete CLASS </AppModalTitle>
      <AppModalContent>
        ARE YOU SURE YOU WANT TO DELETE THIS CLASS ?
      </AppModalContent>
      <AppModalActions>
        <AppButton variant="secondary" onClick={onClose}>
          No
        </AppButton>
        <AppButton isLoading={loading} onClick={handleDelete}>
          Yes
        </AppButton>
      </AppModalActions>
    </AppModal>
  );
};

export default ClassDeleteModal;
