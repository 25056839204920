import {
  useState,
  useEffect,
  useRef,
  Suspense,
  lazy,
  memo,
  useCallback
} from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppTabs from 'common/components/AppTabs';
import SummaryInformation, { RefetchHandle } from './LocationSummaryInfo';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { useBrandLocation } from 'context/BrandLocationContext';
import { TAB } from 'common/constants';

import './desktop.scss';

const LocationDetail = lazy(() => import('./LocationDetail'));
const LocationArea = lazy(() => import('./LocationArea'));
const LocationPin = lazy(() => import('./LocationPin'));
const LocationDoor = lazy(() => import('./LocationDoor'));
const LocationSiteMap = lazy(() => import('./LocationSiteMap'));
const LocationHours = lazy(() => import('./LocationHours'));
const LocationWebsite = lazy(() => import('./LocationWebsite'));

const LocationDetailRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { onRefetchLocation } = useBrandLocation();

  const ref = useRef<RefetchHandle>(null);

  const handleRefetch = useCallback(() => {
    ref?.current?.refetch();

    onRefetchLocation();
  }, [onRefetchLocation]);

  const [tabIndex, setTabIndex] = useState(TAB.IDLE);

  const tabs = [
    {
      name: 'Club Details',
      component: <LocationDetail onUpdated={handleRefetch} />
    },
    {
      name: 'Hours',
      component: <LocationHours />
    },
    {
      name: 'Website',
      component: <LocationWebsite />
    },
    {
      name: 'Door access',
      component: <LocationDoor />
    },
    {
      name: 'PIN codes',
      component: <LocationPin />
    },
    // {
    //   name: 'Levels',
    //   component: <AppComingSoon />
    // },
    {
      name: 'Area',
      component: <LocationArea />
    },
    {
      name: 'Site map',
      component: <LocationSiteMap />
    }
    // {
    //   name: 'Calendar',
    //   component: <AppComingSoon />
    // }
  ];

  const handleSelectedTab = useCallback(
    (val: number) => {
      setTabIndex(val);

      // delete it's sub page when it's changed
      searchParams.delete('sub1');
      setSearchParams(searchParams, { replace: true });

      searchParams.set('indexRoute', `${val}`);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const indexRoute = Number(searchParams.get('indexRoute'));

  useEffect(() => {
    if (indexRoute >= 0 && indexRoute <= tabs.length) {
      setTabIndex(indexRoute);
    } else {
      setTabIndex(TAB.INIT);

      searchParams.set('indexRoute', `${TAB.INIT}`);
      setSearchParams(searchParams, { replace: true });
    }
  }, [indexRoute, searchParams, setSearchParams, tabs.length]);

  return (
    <div className="location_route">
      {tabIndex >= 0 ? (
        <>
          <AppBreadCrumb
            items={[
              { name: 'Locations', href: '/locations' },
              {
                name: tabs[tabIndex].name,
                href: location?.pathname + location?.search
              }
            ]}
          />

          <SummaryInformation ref={ref} />

          <div className="location_route-tab">
            <AppTabs
              tabs={tabs}
              activeTabIndex={tabIndex}
              onChangeTab={handleSelectedTab}
            />

            {tabIndex >= 0 ? (
              <Suspense fallback={<AppLoadingContainer />}>
                {tabs[tabIndex].component}
              </Suspense>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default memo(LocationDetailRoute);
