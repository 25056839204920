import { memo } from 'react';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import { reportList } from '../constant';
import ReportCard from './ReportCard';

import './desktop.scss';

const ReportList = () => {
  return (
    <main id="reportListPage" className="reportListPage">
      <AppBreadCrumb items={[{ name: 'Reporting', href: '/reporting' }]} />
      <div className="layoutContainer reportList">
        {reportList.map((report, index: number) => {
          return <ReportCard key={index} report={report} />;
        })}
      </div>
    </main>
  );
};

export default memo(ReportList);
