import './desktop.scss';

import { useCallback, useEffect, useState } from 'react';

import AppButton from 'common/components/AppButton';
import AppDatePicker from 'common/components/AppDatePicker';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalFormTitle
} from 'common/components/AppModal';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs, { Dayjs } from 'dayjs';
import { Resolver, useForm } from 'react-hook-form';
import yup from 'validators/bulkManagement.validator';
import { FormCapacityValue } from '../type';
import AppInput from 'common/components/AppInput';
import AppLoadingContainer from 'common/components/AppLoadingContainer';

interface Props {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSave: (value: FormCapacityValue) => void;
}

const STEPS = {
  ENTER_CAPACITY: 0,
  SELECT_DATE: 1
};
const initValueForm: FormCapacityValue = {
  capacity: 0,
  date: ''
};

const validationSchema = yup.OBJECT({
  capacity: yup.CLASS_CAPACITY,
  date: yup.START_DATE
});
const ChangeCapacityModal: React.FC<Props> = ({
  open,
  loading,
  onClose,
  onSave
}: Props) => {
  const [step, setStep] = useState<number>(STEPS.ENTER_CAPACITY);
  const [formValue, setFormValue] = useState<FormCapacityValue>(initValueForm);

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema) as Resolver<FormCapacityValue>,
    defaultValues: initValueForm
  });

  useEffect(() => {
    setStep(STEPS.ENTER_CAPACITY);
  }, [open]);

  const handleSave = useCallback(() => {
    if (step === STEPS.ENTER_CAPACITY) {
      onSave(formValue);
    } else {
      onSave(formValue);
    }
  }, [onSave, step, formValue]);

  const handleClose = useCallback(() => {
    if (step === STEPS.SELECT_DATE) {
      setStep(STEPS.ENTER_CAPACITY);
    } else {
      onClose();
    }
  }, [step, onClose]);

  const handleChangeDate = (
    value: Dayjs | null,
    key: keyof FormCapacityValue
  ) => {
    if (value && dayjs(value).isValid()) {
      setFormValue({
        ...formValue,
        [key]: dayjs(value).format('YYYY-MM-DD')
      });
      setValue(key, dayjs(value).format('YYYY-MM-DD'));
    } else {
      setFormValue({
        ...formValue,
        [key]: ''
      });
      setValue(key, '');
    }
    trigger(key);
  };

  const handleChangeCapacity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('capacity', Number(event.target.value));
    setFormValue({
      ...formValue,
      capacity: Number(event.target.value)
    });
    trigger('capacity');
  };

  return (
    <div className="changeClassDetailModal">
      <AppModal open={open} onClose={onClose}>
        {loading ? (
          <AppLoadingContainer />
        ) : (
          <>
            <AppModalFormTitle>Change Capacity</AppModalFormTitle>
            {step === STEPS.ENTER_CAPACITY && (
              <AppModalContent>
                <p className="description">
                  Please enter the new Class capacity
                </p>
                <AppInput
                  label="Capacity"
                  {...register('capacity')}
                  value={formValue.capacity}
                  onChange={handleChangeCapacity}
                  message={{
                    type: 'error',
                    text: errors.capacity?.message || ''
                  }}
                  type="number"
                  inputSize="small"
                />
              </AppModalContent>
            )}
            {step === STEPS.SELECT_DATE && (
              <AppModalContent>
                <p className="description">
                  Please choose a date for when the changes will take effect.
                </p>
                <AppDatePicker
                  label="Select Date"
                  disablePast
                  {...register('date')}
                  value={dayjs(formValue.date)}
                  onChange={(value) => handleChangeDate(value, 'date')}
                  message={{
                    type: 'error',
                    text: errors.date?.message || ''
                  }}
                />
              </AppModalContent>
            )}
            <AppModalActions>
              <AppButton
                variant="secondary"
                onClick={handleClose}
                buttonSize="small"
              >
                {step === STEPS.ENTER_CAPACITY ? 'Cancel' : 'Previous'}
              </AppButton>
              {step === STEPS.ENTER_CAPACITY ? (
                <AppButton onClick={handleSave} buttonSize="small">
                  Update
                </AppButton>
              ) : (
                <AppButton
                  onClick={handleSubmit(handleSave)}
                  buttonSize="small"
                >
                  Save
                </AppButton>
              )}
            </AppModalActions>
          </>
        )}
      </AppModal>
    </div>
  );
};

export default ChangeCapacityModal;
