import React, { memo, useMemo, useState } from 'react';
import AppModal, {
  AppModalFormActions,
  AppModalFormContent,
  AppModalFormTitle
} from 'common/components/AppModal';
import AppButton from 'common/components/AppButton';
import AppInput from 'common/components/AppInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { AddSaveFilterModalProps } from './types';

const AddSaveFilterModal: React.FC<AddSaveFilterModalProps> = memo(
  ({
    handleAddToSavedFilter,
    setShowModalSaveFilter,
    showModalSaveFilter
  }: AddSaveFilterModalProps) => {
    const [nameFilter, setNameFilter] = useState('');
    const validationSchema = yup.object({
      nameFilter: yup.string().required('Please provide name filter')
    });
    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue
    } = useForm({
      resolver: yupResolver(validationSchema),
      mode: 'onSubmit',
      reValidateMode: 'onSubmit'
    });
    const __renderErrorMessage = useMemo((): string => {
      return errors?.nameFilter?.message || '';
    }, [errors?.nameFilter]);
    const onSubmit = () => {
      handleAddToSavedFilter(nameFilter);
      setShowModalSaveFilter(false);
    };
    const onChangeNameFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue('nameFilter', event.target.value);
      setNameFilter(event.target.value);
    };
    const onClearNameFilter = () => {
      setNameFilter('');
      setValue('nameFilter', '');
    };
    return (
      <AppModal
        open={showModalSaveFilter}
        onClose={() => setShowModalSaveFilter(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <AppModalFormTitle>Add to save filters</AppModalFormTitle>
          <AppModalFormContent style={{ width: '500px', overflow: 'hidden' }}>
            <AppInput
              {...register('nameFilter')}
              type="text"
              label="Name the filter*"
              name="nameFilter"
              message={{
                type: 'error',
                text: __renderErrorMessage
              }}
              value={nameFilter}
              onChange={onChangeNameFilter}
              onClearSearch={onClearNameFilter}
            />
          </AppModalFormContent>
          <AppModalFormActions style={{ justifyContent: 'flex-end' }}>
            <AppButton
              buttonSize="small"
              onClick={() => setShowModalSaveFilter(false)}
            >
              Cancel
            </AppButton>
            <AppButton variant="secondary" buttonSize="small" type="submit">
              Save
            </AppButton>
          </AppModalFormActions>
        </form>
      </AppModal>
    );
  }
);
export default AddSaveFilterModal;
