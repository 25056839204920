import { useEffect, useState } from 'react';
import AppModal, {
  AppModalFormActions,
  AppModalFormContent,
  AppModalFormTitle
} from 'common/components/AppModal';
import AppButton from 'common/components/AppButton';
import { IRecurrence } from 'common/interfaces/class.interface';
import { RECURRENCE_VALUES } from 'common/enums/class.enum';

interface Props {
  showModalRecurrence: boolean;
  setShowModalRecurrence: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseRecurrence: () => void;
  onSaveRecurrence: (recurrence: IRecurrence) => void;
  recurrence: IRecurrence;
}
const RecurrenceModal = (props: Props) => {
  const {
    showModalRecurrence,
    setShowModalRecurrence,
    onCloseRecurrence,
    onSaveRecurrence
  } = props;
  const initDays = [
    {
      value: false,
      name: 'mon',
      label: 'M'
    },
    {
      value: false,
      name: 'tue',
      label: 'T'
    },
    {
      value: false,
      name: 'wed',
      label: 'W'
    },
    {
      value: false,
      name: 'thur',
      label: 'T'
    },
    {
      value: false,
      name: 'fri',
      label: 'F'
    },
    {
      value: false,
      name: 'sat',
      label: 'S'
    },
    {
      value: false,
      name: 'sun',
      label: 'S'
    }
  ];
  const [daysRecurrence, setDaysRecurrence] = useState(initDays);

  useEffect(() => {
    const recurrence = props.recurrence;
    if (recurrence) {
      setDaysRecurrence([
        {
          value: recurrence.mon || false,
          name: 'mon',
          label: 'M'
        },
        {
          value: recurrence.tue || false,
          name: 'tue',
          label: 'T'
        },
        {
          value: recurrence.wed || false,
          name: 'wed',
          label: 'W'
        },
        {
          value: recurrence.thur || false,
          name: 'thur',
          label: 'T'
        },
        {
          value: recurrence.fri || false,
          name: 'fri',
          label: 'F'
        },
        {
          value: recurrence.sat || false,
          name: 'sat',
          label: 'S'
        },
        {
          value: recurrence.sun || false,
          name: 'sun',
          label: 'S'
        }
      ]);
    }
  }, [props.recurrence]);

  const onSave = () => {
    const recurrence: IRecurrence = {
      mon: daysRecurrence.find((item) => item.name === 'mon')?.value || false,
      tue: daysRecurrence.find((item) => item.name === 'tue')?.value || false,
      wed: daysRecurrence.find((item) => item.name === 'wed')?.value || false,
      thur: daysRecurrence.find((item) => item.name === 'thur')?.value || false,
      fri: daysRecurrence.find((item) => item.name === 'fri')?.value || false,
      sat: daysRecurrence.find((item) => item.name === 'sat')?.value || false,
      sun: daysRecurrence.find((item) => item.name === 'sun')?.value || false,
      type: RECURRENCE_VALUES.CUSTOM,
      endDate: 0
    };

    onSaveRecurrence(recurrence);
  };
  return (
    <AppModal
      open={showModalRecurrence}
      onClose={() => setShowModalRecurrence(false)}
    >
      <AppModalFormTitle>Custom recurrence</AppModalFormTitle>
      <AppModalFormContent style={{ width: '500px' }}>
        <div>Repeat on </div>
        <div className="day-daily">
          {daysRecurrence.map((item) => (
            <div
              key={item.name}
              className={`day ${item.value ? 'active' : ''}`}
              onClick={() => {
                setDaysRecurrence(
                  daysRecurrence.map((day) => {
                    if (day.name === item.name) {
                      return {
                        ...day,
                        value: !day.value
                      };
                    }
                    return day;
                  })
                );
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      </AppModalFormContent>
      <AppModalFormActions>
        <AppButton onClick={onSave}>Save</AppButton>
        <AppButton variant="secondary" onClick={onCloseRecurrence}>
          Cancel
        </AppButton>
      </AppModalFormActions>
    </AppModal>
  );
};
export default RecurrenceModal;
