import { memo, useCallback, useEffect, useState } from 'react';
import { useToast } from 'context/ToastContext';
import { useBrandLocation } from 'context/BrandLocationContext';
import { REPORT_PATH } from '../constant';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import {
  formatData,
  formatDate,
  formatSecretPhoneNumber
} from 'common/helpers/dataFormat.helper';
import { getAbandonedCartReport } from 'services/report.service';
import { createColumnHelper } from '@tanstack/react-table';
import { IAbandonedCartListReport } from 'common/interfaces/report.interface';
import AppTable from 'common/components/AppTable';

import './desktop.scss';

const ReportAbandonedCartList = () => {
  const toast = useToast();
  const { selectedLocation: __globalLocation } = useBrandLocation();

  const columnHelper = createColumnHelper<IAbandonedCartListReport>();

  const columns = [
    columnHelper.accessor('memberId', {
      header: () => <span>Student ID</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('firstName', {
      header: () => <span>Student name</span>,
      cell: (info) => (
        <>
          {formatData(info.row.original?.lastName) +
            ', ' +
            formatData(info.row.original?.firstName)}
        </>
      )
    }),
    columnHelper.accessor('dob', {
      header: () => <span>Student Age</span>,
      cell: (info) => {
        // Convert the date string to a Date object
        const date = new Date(info.getValue());

        // Get the current date
        const currentDate = new Date();

        // Calculate the number of years and months
        let years = currentDate.getFullYear() - date.getFullYear();
        let months = currentDate.getMonth() - date.getMonth();

        // Adjust the months if the result is negative
        if (months < 0) {
          years -= 1;
          months += 12;
        }
        if (years > 0) {
          return `${formatData(years)} years ${formatData(months)} months`;
        } else {
          return `${formatData(months)} months`;
        }
      }
    }),
    columnHelper.accessor('responsiblePerson', {
      header: () => <span>RP NAME</span>,
      cell: (info) => (
        <>
          {formatData(info.getValue()?.lastName) +
            ', ' +
            formatData(info.getValue()?.firstName)}
        </>
      )
    }),
    columnHelper.accessor('responsiblePerson', {
      header: () => <span>RP ID</span>,
      cell: (info) => <>{formatData(info.getValue()?.memberId)}</>
    }),
    columnHelper.accessor('responsiblePerson', {
      header: () => <span>RP Phone</span>,
      cell: (info) => (
        <>{formatSecretPhoneNumber(info.getValue()?.phoneNumber)}</>
      )
    }),
    columnHelper.accessor('responsiblePerson', {
      header: () => <span>RP EMAIL</span>,
      cell: (info) => <>{formatData(info.getValue()?.email)}</>
    }),
    columnHelper.accessor('createdAt', {
      header: () => <span>Profile Created Date</span>,
      cell: (info) => <>{formatDate(info.getValue())}</>
    })
  ];

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IAbandonedCartListReport[]>([]);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(-1);

  const fetchData = useCallback(async () => {
    if (!__globalLocation?._id) return;

    try {
      setLoading(true);

      const result = await getAbandonedCartReport(
        page,
        limit,
        __globalLocation?._id
      );

      setData(result?.data?.data?.data);
      setTotal(result?.data?.data?.total);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to get non attendance report'
      );
      setData([]);
      setTotal(-1);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [page, limit, __globalLocation?._id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <main id="reportAbandonedCartList">
      <AppBreadCrumb
        items={[
          { name: 'Reporting', href: REPORT_PATH },
          { name: 'Abandoned cart list' }
        ]}
      />
      <div className="layoutContainer">
        {loading ? (
          <AppLoadingContainer />
        ) : (
          <div className="reportAbandonedCartList__content">
            <div className="reportAbandonedCartList__content__header">
              <p className="reportAbandonedCartList__content__header--title">
                ABANDONED CART LIST
              </p>
            </div>

            <div className="reportAbandonedCartList__content__table">
              <AppTable
                loading={loading}
                data={data}
                columns={columns}
                pagination={{
                  index: page,
                  size: limit,
                  total: total
                }}
                onChangePage={(index: number, size: number) => {
                  setPage(index);
                  setLimit(size);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default memo(ReportAbandonedCartList);
