import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import AppModal, {
  AppModalFormActions,
  AppModalFormContent,
  AppModalFormTitle
} from 'common/components/AppModal';
import AppButton from 'common/components/AppButton';
import AppCard, {
  AppCardContent,
  AppCardHeader
} from 'common/components/AppCard';
import { HiMiniXMark, HiStar } from 'react-icons/hi2';
import { getLevelBreakdownById } from 'services/levelBreakdown.service';
import {
  ILevel,
  ILevelBreakdown,
  IMilestone,
  IMilestoneScore,
  ISkill
} from 'common/interfaces/levelBreakdown.interface';
import { ISkillResult } from 'common/interfaces/assessment.interface';
import { CreateSkillResultsDTO } from 'DTOs/assessment.dto';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import AppTextArea from 'common/components/AppTextArea';
import AppCheckbox from 'common/components/AppCheckbox';
import { HiExclamationCircle } from 'react-icons/hi';
import AppTooltip from 'common/components/AppToolTip';

import './desktop.scss';

interface IClassAssessmentModalProps {
  levelId: string;
  open: boolean;
  onClose: () => void;

  onSave?: (
    maxScore: number,
    skillResults: Array<CreateSkillResultsDTO>
  ) => void;

  loading?: boolean;

  defaultSkillResults?: Array<ISkillResult>;
  canEdit?: boolean;
  isReAssess?: boolean;
  newSTAssessment?: boolean;
  resultLevel?: ILevelBreakdown;
  title: string;
}

const ClassAssessmentModal = (props: IClassAssessmentModalProps) => {
  const {
    levelId,
    open,
    onClose,
    onSave,
    loading = false,
    newSTAssessment = false,
    defaultSkillResults,
    canEdit = !!defaultSkillResults ? false : true,
    title,
    resultLevel,
    isReAssess
  } = props;

  const [loadingLB, setLoadingLB] = useState<boolean>(true);

  const [data, setData] = useState<ILevel | null>(null);
  const [skillResults, setSkillResults] = useState<
    Array<CreateSkillResultsDTO>
  >([]);

  const maxScoreDisplay = useMemo(() => {
    if (newSTAssessment) {
      return 1;
    } else {
      return data?.assessmentScore.maxScore || 3;
    }
  }, [data, newSTAssessment]);

  const [selectedSkillIds, setSelectedSkillIds] = useState<Array<string>>([]);

  const handleSelectSkill = useCallback(
    (skillId: string) => {
      const existSkillIds = selectedSkillIds.find((item) => item === skillId);
      if (!existSkillIds) {
        setSelectedSkillIds((prev) => [...prev, skillId]);
      } else {
        setSelectedSkillIds((prev) => prev.filter((id) => id !== skillId));
      }
    },
    [selectedSkillIds]
  );

  const handleAssessmentResults = () => {
    const finalSkillResult = skillResults.filter((skillResult) => {
      return selectedSkillIds.includes(skillResult.skillId);
    });
    if (data && onSave) {
      onSave(data.assessmentScore?.maxScore, finalSkillResult);
    }
  };

  const __isDisabledSaveButton = useMemo(() => {
    if (selectedSkillIds.length === 0) {
      return true;
    }
    const skillResultsSelected = skillResults.filter((skill) =>
      selectedSkillIds.includes(skill.skillId)
    );
    return !skillResultsSelected.every((skillResult) => {
      return skillResult?.milestoneScores.every(
        (milestone) => milestone?.score !== 0
      );
    });
  }, [skillResults, selectedSkillIds]);

  const handleChangeAssessmentScore = (
    value: number,
    skillPosition: number,
    milestonePosition: number
  ) => {
    setSkillResults((prevSkillResults) => {
      return prevSkillResults.map((skillResult, skillIndex: number) => {
        if (skillIndex === skillPosition) {
          return {
            ...skillResult,
            milestoneScores: skillResult?.milestoneScores.map(
              (milestone, milestoneIndex: number) => {
                if (milestoneIndex === milestonePosition) {
                  return {
                    ...milestone,
                    score: value
                  };
                } else {
                  return milestone;
                }
              }
            )
          };
        } else return skillResult;
      });
    });
  };

  const fetchData = useCallback(() => {
    if (!!defaultSkillResults && !isReAssess) {
      setLoadingLB(false);
      return;
    }
    getLevelBreakdownById(levelId)
      .then((result) => {
        const skills = result.data.data.skills;
        setData(result.data.data);

        setSkillResults(
          skills.map((skill: ISkill, index: number) => {
            return {
              skillId: skill._id,
              milestoneScores: skill?.milestones.map((milestone) => {
                return {
                  milestoneId: milestone._id,
                  score: 0
                };
              })
            };
          })
        );
      })
      .catch(() => {
        setData(null);
      })
      .finally(() => {
        setLoadingLB(false);
      });
  }, [levelId, defaultSkillResults, isReAssess]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderViewAssessment = () => {
    if (!defaultSkillResults) return;
    return defaultSkillResults.map((skill) => {
      return (
        <AppCard key={skill._id}>
          {canEdit ? (
            <div className="c__classAssessmentModal__skill-name">
              <AppCheckbox
                checked={selectedSkillIds.includes(skill._id)}
                onChange={() => handleSelectSkill(skill._id)}
              />
              {skill.name}
            </div>
          ) : (
            <AppCardHeader title={skill.name} />
          )}

          {((canEdit && selectedSkillIds.includes(skill._id)) || !canEdit) && (
            <AppCardContent>
              <div className="c__classAssessmentModal__skills">
                {skill.milestoneScores.map(
                  (milestoneScore: IMilestoneScore) => {
                    return (
                      <div
                        key={milestoneScore.milestoneId}
                        className="c__classAssessmentModal__skill"
                      >
                        {newSTAssessment ? (
                          <AppTextArea
                            value={milestoneScore.name}
                            readOnly
                            label=""
                            rows={3}
                          />
                        ) : (
                          <p className="c__classAssessmentModal__skill-name">
                            {milestoneScore.name}
                          </p>
                        )}
                        <div className="c__classAssessmentModal__skill-scores">
                          {[...Array(maxScoreDisplay)].map(
                            (_, scoreIndex: number) => {
                              return (
                                <HiStar
                                  key={scoreIndex}
                                  size={32}
                                  className={`c__classAssessmentModal__skill-score ${
                                    milestoneScore?.score >=
                                    maxScoreDisplay - scoreIndex
                                      ? 'active'
                                      : ''
                                  } ${canEdit ? '' : 'disabled'} `}
                                />
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </AppCardContent>
          )}
        </AppCard>
      );
    });
  };
  const handleSelectAllSkill = () => {
    if (selectedSkillIds.length === data?.skills?.length) {
      setSelectedSkillIds([]);
    } else {
      setSelectedSkillIds(data?.skills?.map((skill) => skill._id) || []);
    }
  };

  return (
    <div className="c__classAssessmentModal">
      <AppModal open={open} onClose={onClose}>
        {loadingLB && <AppLoadingContainer />}

        <AppModalFormTitle>
          <p>{title}</p>
          <HiMiniXMark size={24} onClick={onClose} />
        </AppModalFormTitle>

        <AppModalFormContent>
          {newSTAssessment && (
            <div className="c__classAssessmentModal-result-text">
              Assessment Result: {resultLevel?.name}
            </div>
          )}

          {!defaultSkillResults && (
            <div className="select-all-wrapper">
              <AppCheckbox
                checked={selectedSkillIds.length === data?.skills?.length}
                onChange={handleSelectAllSkill}
              />
              Select all Skills
            </div>
          )}
          {!!defaultSkillResults && !isReAssess
            ? renderViewAssessment()
            : !!data?.skills &&
              data.skills.map((skill, skillIndex) => {
                return (
                  <AppCard key={skill.position}>
                    {canEdit ? (
                      <div className="c__classAssessmentModal__skill-name">
                        <AppCheckbox
                          checked={selectedSkillIds.includes(skill._id)}
                          onChange={() => handleSelectSkill(skill._id)}
                        />
                        {skill.name}
                      </div>
                    ) : (
                      <AppCardHeader title={skill.name} />
                    )}

                    {((canEdit && selectedSkillIds.includes(skill._id)) ||
                      !canEdit) && (
                      <AppCardContent>
                        <div className="c__classAssessmentModal__skills">
                          {skill.milestones.map((milestone: IMilestone) => {
                            return (
                              <div
                                key={milestone.position}
                                className="c__classAssessmentModal__skill"
                              >
                                <div className="c__classAssessmentModal__skill-name-wrapper">
                                  {skillResults[skillIndex]?.milestoneScores[
                                    milestone.position
                                  ]?.score === 0 && (
                                    <AppTooltip tooltipText="Assessment not completed">
                                      <HiExclamationCircle size={22} />
                                    </AppTooltip>
                                  )}
                                  {newSTAssessment ? (
                                    <AppTextArea
                                      value={milestone.name}
                                      readOnly
                                      label=""
                                      rows={3}
                                    />
                                  ) : (
                                    <p className="c__classAssessmentModal__skill-name">
                                      {milestone.name}
                                    </p>
                                  )}
                                </div>
                                <div className="c__classAssessmentModal__skill-scores">
                                  {[...Array(maxScoreDisplay)].map(
                                    (_, scoreIndex: number) => {
                                      return (
                                        <HiStar
                                          key={scoreIndex}
                                          size={32}
                                          className={`c__classAssessmentModal__skill-score ${
                                            skillResults[skillIndex]
                                              ?.milestoneScores[
                                              milestone.position
                                            ]?.score >=
                                            maxScoreDisplay - scoreIndex
                                              ? 'active'
                                              : ''
                                          } ${canEdit ? '' : 'disabled'} `}
                                          onClick={() =>
                                            handleChangeAssessmentScore(
                                              maxScoreDisplay - scoreIndex,
                                              skill.position,
                                              milestone.position
                                            )
                                          }
                                        />
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </AppCardContent>
                    )}
                  </AppCard>
                );
              })}
        </AppModalFormContent>

        <AppModalFormActions>
          {onSave && (
            <AppButton
              buttonSize="small"
              variant="primary"
              isLoading={loading}
              disabled={__isDisabledSaveButton}
              onClick={handleAssessmentResults}
            >
              Save {!!defaultSkillResults ? '& Approve' : ''}
            </AppButton>
          )}
          <AppButton
            buttonSize="small"
            variant="secondary"
            disabled={loading}
            onClick={onClose}
          >
            {canEdit ? 'Cancel' : 'Close'}
          </AppButton>
        </AppModalFormActions>
      </AppModal>
    </div>
  );
};

export default memo(ClassAssessmentModal);
