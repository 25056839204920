import { ABSENCE_STATUS } from '../enums/absence.enum';
import {
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
  HiOutlineXCircle
} from 'react-icons/hi';

export const renderAbsenceStatus = (
  status: ABSENCE_STATUS | undefined
): React.ReactNode => {
  switch (status) {
    case ABSENCE_STATUS.PENDING:
      return (
        <HiOutlineExclamationCircle
          size={20}
          className="absenceStatus absenceStatus--pending"
        />
      );
    case ABSENCE_STATUS.APPROVED:
      return (
        <HiOutlineCheckCircle
          size={20}
          className="absenceStatus absenceStatus--approved"
        />
      );
    case ABSENCE_STATUS.DECLINED:
      return (
        <HiOutlineXCircle
          size={20}
          className="absenceStatus absenceStatus--declined"
        />
      );
    default:
      return null;
  }
};
