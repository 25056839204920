import { object as OBJECT, boolean, number, string } from 'yup';

//  ===== LOCATION  =====
const LOCATION_NAME = string().required('Please provide location name');
const LOCATION_SHORT_NAME = string().required(
  'Please provide location short name'
);
const LOCATION_ADDRESS = string().required('Please provide location address');
const LOCATION_SUBURB = string().required('Please provide location suburb');
const LOCATION_CITY = string().required('Please provide location city');
const LOCATION_STATE = string().required('Please provide location state');
const LOCATION_POSTCODE = string().required(
  'Please provide location post code'
);
const LOCATION_COUNTRY = string().required('Please provide location country');
const LOCATION_DD_CREDIT_CARD = string().required(
  'Please provide DD credit card'
);
const LOCATION_DD_BANK_ACCOUNT = string().required(
  'Please provide DD bank account '
);

const LOCATION_PHONE_NUMBER = string().required(
  'Please provide location phone number'
);

const LOCATION_ABN = string().required('Please provide location ABN');

const LOCATION_CLUB_PAGE_URL = string().required(
  'Please provide location club page url'
);
const LOCATION_WALKTHROUGH_URL = string().required(
  'Please provide location walkthrough url'
);

const LOCATION_OPEN_24_HOURS = boolean().required(
  'Please provide location open 24 hours'
);
const LOCATION_CLUB_OPEN = boolean().required(
  'Please provide location club open'
);
const LOCATION_CHECK_OPEN_TIME = boolean().required(
  'Please provide location check open time'
);
const LOCATION_CLASS_BOOKING = boolean().required(
  'Please provide location class booking'
);
const LOCATION_CRECHE = boolean().required('Please provide location creche');

const LOCATION_CREDIT_CARD = string().required('Please provide credit card');
const LOCATION_BANK_ACCOUNT = string().required('Please provide bank account');
const LOCATION_ACCOUNT_NAME = string().required('Please provide account name');
const LOCATION_ACCOUNT_NUMBER = string().required(
  'Please provide account number'
);
const LOCATION_BSB = string().required('Please provide bsb');

const LOCATION_PRE_SALE_ON = boolean().required('Please provide pre-sale');
const LOCATION_HIDDEN_ONLINE = boolean().required(
  'Please provide hidden online'
);
const LOCATION_FREE_TRIAL_APP = boolean().required(
  'Please provide pre trial app'
);
const LOCATION_FREE_TRIAL_LENGTH_DAYS = number()
  .typeError('This value must be a number')
  .required('Please provide free trial length days');
const LOCATION_TRIAL_VISITS_LIMIT = number()
  .required('Please provide trial visits limit')
  .typeError('This value must be a number');

const LOCATION_SHOW_LEADS_SECTION = boolean().required(
  'Please provide show leads section'
);
const LOCATION_MAIL_CHIMP_CODE = string().required(
  'Please provide mail chimp code'
);
const LOCATION_EMAIL_ADDRESS = string().required(
  'Please provide email address'
);
const LOCATION_SALE_ID = string().required('Please provide sale id');
const LOCATION_HIIT_ID = string().required('Please provide hiit id');
const LOCATION_LEAD_HEADER = string().required('Please provide lead header');
const LOCATION_LEAD_CONTENT = string().required('Please provide lead content');
//  ===== END LOCATION  =====

const validations = {
  OBJECT,
  string,
  number,
  boolean,
  LOCATION_NAME,
  LOCATION_SHORT_NAME,
  LOCATION_ADDRESS,
  LOCATION_SUBURB,
  LOCATION_CITY,
  LOCATION_STATE,
  LOCATION_POSTCODE,
  LOCATION_COUNTRY,
  LOCATION_DD_CREDIT_CARD,
  LOCATION_DD_BANK_ACCOUNT,
  LOCATION_PHONE_NUMBER,
  LOCATION_ABN,
  LOCATION_OPEN_24_HOURS,
  LOCATION_CLUB_OPEN,
  LOCATION_CHECK_OPEN_TIME,
  LOCATION_CLASS_BOOKING,
  LOCATION_CRECHE,
  LOCATION_CLUB_PAGE_URL,
  LOCATION_WALKTHROUGH_URL,
  LOCATION_CREDIT_CARD,
  LOCATION_ACCOUNT_NAME,
  LOCATION_BANK_ACCOUNT,
  LOCATION_ACCOUNT_NUMBER,
  LOCATION_BSB,
  LOCATION_PRE_SALE_ON,
  LOCATION_HIDDEN_ONLINE,
  LOCATION_FREE_TRIAL_APP,
  LOCATION_FREE_TRIAL_LENGTH_DAYS,
  LOCATION_TRIAL_VISITS_LIMIT,
  LOCATION_SHOW_LEADS_SECTION,
  LOCATION_MAIL_CHIMP_CODE,
  LOCATION_EMAIL_ADDRESS,
  LOCATION_SALE_ID,
  LOCATION_HIIT_ID,
  LOCATION_LEAD_HEADER,
  LOCATION_LEAD_CONTENT
};
export default validations;
