import { Fragment, memo, useEffect, useRef, useState } from 'react';
import { HiOutlineBell } from 'react-icons/hi2';
import NotificationList from 'pages/notifications/NotificationList';
import { useAuth } from 'context/AuthContext';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useLocation } from 'react-router-dom';

const NotificationPopper = () => {
  const { totalNotification } = useAuth();
  const location = useLocation();

  const popperRef = useRef<any>(null);
  const verticalButtonRef = useRef<any>(null);

  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(totalNotification);
  }, [totalNotification]);

  const onToggle = () => {
    if (location?.pathname === '/notifications') return;

    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Close popper if click outside the popper
    const handleClickOutside = (event: MouseEvent) => {
      // Click at vertical button to close
      if (
        verticalButtonRef.current &&
        verticalButtonRef.current.contains(event.target)
      ) {
        return;
      }
      // Handle click outside to close pop up
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        onClose();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [verticalButtonRef, popperRef]);

  return (
    <Fragment>
      <PermissionWrapper permission={PERMISSION.LIST_NOTIFICATION}>
        <div
          ref={verticalButtonRef}
          className="notifications__ellipsisVertical"
        >
          <div>
            <HiOutlineBell size={24} onClick={onToggle}></HiOutlineBell>
            {total ? (
              <div className="notification--counter">{total}</div>
            ) : null}
          </div>

          {open && (
            <div ref={popperRef} className="viewDetailPopper">
              <NotificationList
                asPopup
                emitTotal={setTotal}
                closePopup={onClose}
              />
            </div>
          )}
        </div>
      </PermissionWrapper>
    </Fragment>
  );
};

export default memo(NotificationPopper);
