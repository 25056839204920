import './desktop.scss';

import { useCallback, useEffect, useState } from 'react';

import AppButton from 'common/components/AppButton';
import AppDatePicker from 'common/components/AppDatePicker';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalFormTitle
} from 'common/components/AppModal';
import AppSelect from 'common/components/AppSelect';
import { IOption } from 'common/interfaces';
import { ILocationArea } from 'common/interfaces/locationArea.interface';

import { useBrandLocation } from 'context/BrandLocationContext';
import { useToast } from 'context/ToastContext';

import { yupResolver } from '@hookform/resolvers/yup';
import { CHANGE_TYPE } from 'common/enums';
import dayjs, { Dayjs } from 'dayjs';
import { Resolver, useForm } from 'react-hook-form';
import { getLocationAreas } from 'services/locationArea.service';
import yup from 'validators/bulkManagement.validator';
import { FormAreaValue } from '../type';
import AppLoadingContainer from 'common/components/AppLoadingContainer';

interface Props {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onSave: (value: FormAreaValue) => void;
  changeType: CHANGE_TYPE;
}

const STEPS = {
  SELECT_AREA: 0,
  SELECT_DATE: 1
};
const initValueForm: FormAreaValue = {
  area: '',
  date: '',
  endDate: '',
  type: CHANGE_TYPE.PERMANENT
};

const validationSchema = yup.OBJECT({
  area: yup.string(),
  type: yup.string(),
  date: yup.START_DATE,
  endDate: yup.END_DATE.test(
    'required if temporary',
    'Please provide date',
    (endDate, context) => {
      const { type } = context.parent;
      if (type === CHANGE_TYPE.TEMPORARY && !endDate) {
        return false;
      }
      return true;
    }
  ).test(
    'endDate',
    'This date must be greater than effective date',
    (endDate, context) => {
      if (!endDate) return true;

      const { date } = context.parent;
      return dayjs(endDate).isAfter(dayjs(date));
    }
  )
});
const ChangeAreaModal: React.FC<Props> = ({
  open,
  loading,
  onClose,
  onSave,
  changeType
}: Props) => {
  const toast = useToast();
  const { selectedLocation } = useBrandLocation();

  const [step, setStep] = useState<number>(STEPS.SELECT_AREA);
  const [areas, setAreas] = useState<IOption[]>([]);
  const [formValue, setFormValue] = useState<FormAreaValue>(initValueForm);
  const [showAreaError, setShowAreaError] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema) as Resolver<FormAreaValue>,
    defaultValues: initValueForm
  });
  const fetchData = useCallback(async () => {
    setLoadingData(true);
    if (!selectedLocation?._id) return;
    try {
      const { data } = await getLocationAreas({
        page: 1,
        limit: 100,
        locationId: selectedLocation?._id || ''
      });
      setAreas(
        data.data.data.map((item: ILocationArea) => ({
          value: item.areaName,
          label: item.areaName
        }))
      );
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to load list location areas!'
      );
    } finally {
      setLoadingData(false);
    }
    // eslint-disable-next-line
  }, [selectedLocation?._id]);

  useEffect(() => {
    setValue('type', changeType);
    setFormValue({
      ...formValue,
      type: changeType
    });
    // eslint-disable-next-line
  }, [changeType]);
  useEffect(() => {
    setStep(STEPS.SELECT_AREA);
  }, [open]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSave = useCallback(() => {
    if (formValue.area.length === 0) {
      setShowAreaError(true);
      return;
    }
    if (step === STEPS.SELECT_AREA) {
      setStep(STEPS.SELECT_DATE);
      setShowAreaError(false);
    } else {
      onSave(formValue);
    }
  }, [onSave, step, formValue]);

  const handleClose = useCallback(() => {
    if (step === STEPS.SELECT_DATE) {
      setStep(STEPS.SELECT_AREA);
    } else {
      onClose();
    }
  }, [step, onClose]);

  const handleChangeDate = (value: Dayjs | null, key: keyof FormAreaValue) => {
    if (value && dayjs(value).isValid()) {
      setFormValue({
        ...formValue,
        [key]: dayjs(value).format('YYYY-MM-DD')
      });
      setValue(key, dayjs(value).format('YYYY-MM-DD'));
    } else {
      setFormValue({
        ...formValue,
        [key]: ''
      });
      setValue(key, '');
    }
    trigger(key);
  };

  return (
    <div className="changeAreaModal">
      <AppModal open={open} onClose={onClose}>
        {loading || loadingData ? (
          <AppLoadingContainer />
        ) : (
          <>
            <AppModalFormTitle>Change Area</AppModalFormTitle>
            {step === STEPS.SELECT_AREA && (
              <AppModalContent>
                <p className="description">Please select the new area</p>
                <AppSelect
                  label="Select Area"
                  options={areas}
                  value={formValue.area}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    setFormValue({ ...formValue, area: event.target.value })
                  }
                  inputSize="small"
                  message={{
                    type: 'error',
                    text:
                      formValue.area.length === 0 && showAreaError
                        ? 'Please select area'
                        : ''
                  }}
                />
              </AppModalContent>
            )}
            {step === STEPS.SELECT_DATE && (
              <AppModalContent>
                <p className="description">
                  Please choose a date for when the changes will take effect.
                </p>
                <AppDatePicker
                  label="Select Date"
                  disablePast
                  {...register('date')}
                  value={dayjs(formValue.date)}
                  onChange={(value) => handleChangeDate(value, 'date')}
                  message={{
                    type: 'error',
                    text: errors.date?.message || ''
                  }}
                />
                {changeType === CHANGE_TYPE.TEMPORARY && (
                  <div>
                    <div className="divider"></div>
                    <p className="description">
                      Please choose a date for return as before.
                    </p>
                    <AppDatePicker
                      label="Select Date"
                      {...register('endDate')}
                      value={dayjs(formValue.endDate)}
                      disablePast
                      onChange={(value) => handleChangeDate(value, 'endDate')}
                      message={{
                        type: 'error',
                        text: errors.endDate?.message || ''
                      }}
                    />
                  </div>
                )}
              </AppModalContent>
            )}
            <AppModalActions>
              <AppButton
                variant="secondary"
                onClick={handleClose}
                buttonSize="small"
              >
                {step === STEPS.SELECT_AREA ? 'Cancel' : 'Previous'}
              </AppButton>
              {step === STEPS.SELECT_AREA ? (
                <AppButton onClick={handleSave} buttonSize="small">
                  Next
                </AppButton>
              ) : (
                <AppButton
                  onClick={handleSubmit(handleSave)}
                  buttonSize="small"
                >
                  Save
                </AppButton>
              )}
            </AppModalActions>
          </>
        )}
      </AppModal>
    </div>
  );
};

export default ChangeAreaModal;
