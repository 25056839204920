import React, { useCallback, useEffect, useMemo } from 'react';
import NotificationAdd from '../NotificationAdd';
import NotificationEdit from '../NotificationDetail';
import { createColumnHelper } from '@tanstack/react-table';
import { formatData } from 'common/helpers/dataFormat.helper';
import { HiOutlineIdentification, HiPlus } from 'react-icons/hi';
import AppCard from 'common/components/AppCard';
import AppButton from 'common/components/AppButton';
import AppTable from 'common/components/AppTable';
import './desktop.scss';
import { INotification } from 'common/interfaces/notification.interface';
import {
  getNotifications,
  getNotificationsForResponsiblePerson
} from 'services/notifications.service';
import { useToast } from 'context/ToastContext';
import { useLocation, useParams } from 'react-router-dom';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import dayjs from 'dayjs';
import AppInputSearch from 'common/components/AppInputSearch';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import AppSelect from 'common/components/AppSelect';
import { IOption } from 'common/interfaces';
import { getStudentList } from 'services/students.service';
import { IStudent } from 'common/interfaces/student.interface';
import { useBrandLocation } from 'context/BrandLocationContext';
import useDebounce from 'common/hooks/useDebounce';
import { NOTIFICATION_TYPE } from 'common/enums/notification.enum';

const NotificationList: React.FC = () => {
  const columnHelper = createColumnHelper<INotification>();
  const toast = useToast();
  const location = useLocation();
  const params = useParams();
  const { selectedLocation } = useBrandLocation();

  const __isStudentPage = useMemo(
    () => location.pathname.includes('/students/'),
    [location.pathname]
  );
  const __isRPPage = useMemo(
    () => location.pathname.includes('/responsible-person/'),
    [location.pathname]
  );

  const __RPId = useMemo(() => {
    if (__isRPPage) {
      return params.id;
    }
    return '';
  }, [params, __isRPPage]);
  const __studentId = useMemo(() => {
    if (__isStudentPage) {
      return params.id;
    }
    return '';
  }, [params, __isStudentPage]);

  const columns = [
    columnHelper.accessor('title', {
      header: () => <span>title</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('types', {
      header: () => <span>type</span>,
      cell: (info) => {
        if (info.getValue()?.[0] === NOTIFICATION_TYPE.PUSH) {
          return formatData('Member Portal');
        }
        return formatData(info.getValue().join(', '));
      }
    }),
    columnHelper.accessor('totalReceiver', {
      header: () => <span>sent</span>,
      cell: (info) =>
        `${formatData(info.row.original.totalSent)}/${formatData(
          info.getValue()
        )}`
    }),
    columnHelper.accessor('scheduleDateTime', {
      header: () => <span>schedule</span>,
      cell: (info) =>
        formatData(dayjs(info.getValue()).format('DD/MM/YYYY HH:mm')),
      size: 200
    }),
    columnHelper.accessor('status', {
      header: () => <span>status</span>,
      cell: (info) => (
        <div
          className={`notification-status notification-status-${info.getValue()}`}
        >
          {formatData(info.getValue())}
        </div>
      )
    }),
    columnHelper.accessor('_id', {
      header: () => (
        <span
          style={{
            display: 'block',
            textAlign: 'center'
          }}
        >
          Action
        </span>
      ),
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <PermissionWrapper permission={PERMISSION.VIEW_NOTIFICATION}>
              <div
                onClick={() =>
                  handleSelectNotificationHistory(info.row.original)
                }
              >
                <HiOutlineIdentification size={24} />
              </div>
            </PermissionWrapper>
          </div>
        );
      }
    })
  ];

  const __columnsInRPDetail = [...columns.slice(0, 2), ...columns.slice(3, 6)];

  const [loading, setLoading] = React.useState<boolean>(false);

  const [data, setData] = React.useState<Array<INotification>>([]);
  const [pageIndex, setPageIndex] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [pageTotal, setPageTotal] = React.useState<number>(-1);

  const [isOpenAddForm, setIsOpenAddForm] = React.useState<boolean>(false);

  const [selectedNotification, setSelectedNotification] =
    React.useState<INotification | null>(null);
  const [search, setSearch] = React.useState<string>('');
  const [selectedSTIdOption, setSelectedSTIdOption] = React.useState('');
  const [STOptions, setSTOptions] = React.useState<IOption[]>([]);

  const debounceSearch = useDebounce(search, 500);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageIndex(1);
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const handleOpenAddForm = () => {
    setIsOpenAddForm(true);
  };
  const handleCloseAddForm = () => {
    setIsOpenAddForm(false);
  };
  const handleAddNotificationSuccess = () => {
    setIsOpenAddForm(false);
    fetchNotifications();
  };

  const handleSelectNotificationHistory = (value: any) => {
    setSelectedNotification(value);
  };
  const handleUnselectNotificationHistory = () => {
    setSelectedNotification(null);
  };

  const handleUpdateNotificationSuccess = () => {
    setSelectedNotification(null);
    fetchNotifications();
  };

  const fetchStudents = useCallback(async () => {
    if (__isRPPage) {
      const { data } = await getStudentList({
        page: 1,
        limit: 100,
        responsiblePersonId: __RPId,
        locationId: selectedLocation?._id || ''
      });

      setSTOptions(
        data.map((item: IStudent) => ({
          value: item._id,
          label: item.firstName
        }))
      );
    }
  }, [__isRPPage, __RPId, selectedLocation]);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  const fetchNotifications = useCallback(() => {
    setLoading(true);
    const params: {
      page: number;
      limit: number;
      responsiblePersonId?: string;
      keyword?: string;
      studentId?: string;
    } = { page: pageIndex, limit: pageSize };
    if (search.length) {
      params.keyword = search;
    }
    if (__isRPPage) {
      params.responsiblePersonId = __RPId;

      getNotificationsForResponsiblePerson(params)
        .then((res) => {
          setData(res.data.data.data);
          setPageTotal(res.data.data.total);
        })
        .catch((error: any) => {
          setData([]);
          setPageTotal(-1);
          toast.error(
            error?.response?.data?.message ||
              'Failed to fetch notification history'
          );
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }
    if (__isStudentPage) {
      params.studentId = __studentId;
    }
    if (selectedSTIdOption) {
      params.studentId = selectedSTIdOption;
    }
    getNotifications(params)
      .then((res) => {
        setData(res.data.data.data);
        setPageTotal(res.data.data.total);
      })
      .catch((error: any) => {
        setData([]);
        setPageTotal(-1);
        toast.error(
          error?.response?.data?.message ||
            'Failed to fetch notification history'
        );
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [
    pageIndex,
    pageSize,
    selectedSTIdOption,
    debounceSearch,
    __isRPPage,
    __RPId,
    __isStudentPage,
    __studentId,
    selectedLocation
  ]);

  // Ignore eslint purpose
  React.useEffect(() => {
    setLoading(false);
    fetchNotifications();
  }, [fetchNotifications]);

  return (
    <section className="bulkManagementNotificationList">
      {isOpenAddForm && (
        <PermissionWrapper permission={PERMISSION.CREATE_NOTIFICATION}>
          <NotificationAdd
            open={isOpenAddForm}
            onClose={handleCloseAddForm}
            onSuccess={handleAddNotificationSuccess}
          />
        </PermissionWrapper>
      )}

      {selectedNotification && (
        <PermissionWrapper permission={PERMISSION.UPDATE_NOTIFICATION}>
          <NotificationEdit
            open={!!selectedNotification}
            onClose={handleUnselectNotificationHistory}
            data={selectedNotification}
            onSuccess={handleUpdateNotificationSuccess}
          />
        </PermissionWrapper>
      )}

      <AppCard>
        <div className="bulkManagementNotificationList__table">
          <div className="bulkManagementNotificationList__table__header">
            <h2 className="bulkManagementNotificationList__table__header--title">
              Notification History
            </h2>
            <div className="bulkManagementNotificationList__table__header--actions">
              {__isRPPage && (
                <>
                  <AppInputSearch
                    type="text"
                    value={search}
                    onChange={onChangeSearch}
                    placeholder="Title"
                    onClearSearch={onClearSearch}
                    startIcon={<HiMagnifyingGlass />}
                  />
                  <AppSelect
                    label="All student"
                    noFloatingLabel
                    searchable={false}
                    options={[
                      {
                        label: 'All students  ',
                        value: ''
                      },
                      ...STOptions
                    ]}
                    value={selectedSTIdOption}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                      setSelectedSTIdOption(event.target.value)
                    }
                    inputSize="small"
                  />
                </>
              )}
              <PermissionWrapper permission={PERMISSION.CREATE_NOTIFICATION}>
                <AppButton
                  variant="primary"
                  buttonSize="small"
                  type="submit"
                  onClick={handleOpenAddForm}
                >
                  <HiPlus size={24} />
                  <p>Notification</p>
                </AppButton>
              </PermissionWrapper>
            </div>
          </div>
          <div className="bulkManagementNotificationList__table__content">
            <AppTable
              data={data}
              columns={
                __isRPPage || __isStudentPage ? __columnsInRPDetail : columns
              }
              pagination={{
                index: pageIndex,
                size: pageSize,
                total: pageTotal
              }}
              onChangePage={(index: number, size: number) => {
                setPageIndex(index);
                setPageSize(size);
              }}
              loading={loading}
            />
          </div>
        </div>
      </AppCard>
    </section>
  );
};

export default NotificationList;
