import React, { useCallback, useEffect, useRef, useState } from 'react';
import AppButton from 'common/components/AppButton';
import { createColumnHelper } from '@tanstack/react-table';
import AppTable from 'common/components/AppTable';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import AppTextArea from 'common/components/AppTextArea';
import { formatData } from 'common/helpers/dataFormat.helper';
import { useParams } from 'react-router-dom';
import { useToast } from 'context/ToastContext';
import {
  getAbsencesByClass,
  updateAbsencesInfo
} from 'services/absence.service';
import { IAbsence } from 'common/interfaces/absence.interface';
import dayjs from 'dayjs';
import { renderAbsenceStatus } from 'common/helpers/absence.helper';
import ActionPopper from './ActionPopper';
import AbsenceModal from 'components/AbsenceModal';
import { uploadFile } from 'services/uploadFile.service';
import { FILE_ASSET_TYPE } from 'common/enums/uploadFile.enum';
import { ApproveAbsenceDTO, UpdateAbsenceInfoDTO } from 'DTOs/absence.dto';
import { ABSENCE_STATUS, REFUND_CREDIT_TYPE } from 'common/enums/absence.enum';
import { approveAbsences } from 'services/absence.service';
import { DeclineAbsenceDTO } from 'DTOs/absence.dto';
import { declineAbsences } from 'services/absence.service';
import { PERMISSION } from 'common/enums/permission.enum';
import { useAuth } from 'context/AuthContext';
import './desktop.scss';
import { USER_TYPE } from 'common/constants/permission.constant';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationDeclineAbsenceSchema } from 'validators/absence.validator';

interface IAbsentFormProps {
  getTotalPendingAbsenceForms: () => void;
}

const TabAbsentForm = (props: IAbsentFormProps) => {
  const { getTotalPendingAbsenceForms } = props;

  const toast = useToast();
  const params = useParams();
  const { hasPermission } = useAuth();
  const columnHelper = createColumnHelper<IAbsence>();

  const columns = [
    columnHelper.accessor('student', {
      header: () => <span>SURNAME, NAME</span>,
      cell: (info) => (
        <div>
          {formatData(info.getValue()?.lastName)}
          {', '}
          {formatData(info.getValue()?.firstName)}
        </div>
      )
    }),
    columnHelper.accessor('schedules', {
      header: () => <span>Class, Date Time</span>,
      cell: (info) => (
        <div className="classGroup">
          {info.getValue().map((schedule) => {
            return (
              <div key={schedule._id} className="classItem">
                {renderAbsenceStatus(info.row.original.status)}
                {`${formatData(info.row.original.class.name)}, ${dayjs(
                  schedule.startTime
                ).format('ddd, MMM DD, HH:mm')}-${dayjs(
                  schedule.endTime
                ).format('HH:mm')}`}
              </div>
            );
          })}
        </div>
      )
    }),
    columnHelper.accessor('createdAt', {
      header: () => <span>Submitted at</span>,
      cell: (info) => dayjs(info.getValue()).format('ddd, MMM DD, HH:mm:ss')
    }),
    columnHelper.accessor('creator', {
      header: () => <span>Submitted by</span>,
      cell: (info) =>
        `${
          info.getValue().userType === USER_TYPE.RESPONSIBLE_PERSON
            ? 'RP'
            : 'Staff'
        }: ${info.row.original.creator.lastName}, ${info.getValue().firstName}`
    }),
    columnHelper.accessor('reason', {
      header: () => <span>Reason</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('certificateMedicals', {
      header: () => <span>Document</span>,
      cell: (info) => (
        <div className="documentGroup">
          {info.getValue().map((e: string, index: number) => {
            return (
              <div key={index} className="documentItem">
                {e.includes('pdf') ? (
                  <embed src={e || ''} />
                ) : (
                  <img src={e || ''} alt="certificate-medical" />
                )}
              </div>
            );
          })}
        </div>
      )
    }),
    columnHelper.accessor('_id', {
      header: () => (
        <span
          style={{
            display: 'block',
            textAlign: 'center'
          }}
        >
          Action
        </span>
      ),
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <ActionPopper
              item={info.row.original}
              onViewAbsence={() => handleViewAbsence(info.row.original)}
              onApproveAbsence={
                hasPermission(PERMISSION.UPDATE_ABSENCE)
                  ? () => handleOpenApproveAbsence(info.getValue())
                  : undefined
              }
              onDeclineAbsence={
                hasPermission(PERMISSION.UPDATE_ABSENCE)
                  ? () => handleOpenDeclineAbsence(info.getValue())
                  : undefined
              }
            />
          </div>
        );
      }
    })
  ];

  const [loading, setLoading] = useState<boolean>(true);

  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);

  const [total, setTotal] = useState<number>(0);

  const [absenceForms, setAbsenceForms] = useState<Array<IAbsence>>([]);

  // Form absence info
  const [declineReason, setDeclineReason] = useState<string>('');
  const [files, setFiles] = useState<Array<File>>([]);

  // View/Edit
  const [selectedViewAbsence, setSelectedViewAbsence] =
    useState<IAbsence | null>(null);

  // Approve/ Decline
  const [selectedApproveAbsenceId, setSelectedApproveAbsenceId] =
    useState<string>('');
  const [selectedDeclineAbsenceId, setSelectedDeclineAbsenceId] =
    useState<string>('');

  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const successStatus = useRef<ABSENCE_STATUS>(ABSENCE_STATUS.IDLE);

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<DeclineAbsenceDTO>({
    resolver: yupResolver(
      validationDeclineAbsenceSchema
    ) as Resolver<DeclineAbsenceDTO>
  });

  const handleOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  };
  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  };

  const handleOpenApproveAbsence = (absenceId: string) => {
    setSelectedApproveAbsenceId(absenceId);
  };
  const handleCloseApproveAbsence = () => {
    setSelectedApproveAbsenceId('');
  };

  const handleOpenDeclineAbsence = (absenceId: string) => {
    setSelectedDeclineAbsenceId(absenceId);
  };
  const handleCloseDeclineAbsence = () => {
    setDeclineReason('');
    setSelectedDeclineAbsenceId('');
  };

  // View/edit
  const handleViewAbsence = (absence: IAbsence) => {
    setSelectedViewAbsence(absence);
  };
  const handleCloseViewAbsence = () => {
    setSelectedViewAbsence(null);
    setFiles([]);
  };

  // Modal form info functions
  const handleChangeDeclineReason = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDeclineReason(event.target.value);
    setValue('declineReason', event.target.value);
    trigger('declineReason');
  };
  const handleChangeFiles = (files: Array<File>) => {
    setFiles(files);
    if (
      selectedViewAbsence &&
      selectedViewAbsence?.certificateMedicals?.length === 0 &&
      files.length === 0
    ) {
      handleChangeRefundCreditType(REFUND_CREDIT_TYPE.MAKEUP_CREDIT);
    }
  };
  const handleChangeDocuments = (documents: Array<string>) => {
    if (selectedViewAbsence) {
      if (documents?.length === 0 && files?.length === 0) {
        setSelectedViewAbsence({
          ...selectedViewAbsence,
          certificateMedicals: documents,
          refundCreditType: REFUND_CREDIT_TYPE.MAKEUP_CREDIT
        });
      } else {
        setSelectedViewAbsence({
          ...selectedViewAbsence,
          certificateMedicals: documents
        });
      }
    }
  };
  const handleChangeRefundCreditType = (value: REFUND_CREDIT_TYPE) => {
    if (selectedViewAbsence) {
      setSelectedViewAbsence({
        ...selectedViewAbsence,
        refundCreditType: value
      });
    }
  };

  // APIs
  const handleUpdateAbsence = async () => {
    if (!!selectedViewAbsence) {
      if (files.length + selectedViewAbsence.certificateMedicals.length === 0) {
        try {
          const payload: UpdateAbsenceInfoDTO = {
            reason: selectedViewAbsence.reason,
            certificateMedicals: [],
            refundCreditType: selectedViewAbsence.refundCreditType
          };
          if (typeof selectedViewAbsence.declineReason !== 'undefined')
            payload.declineReason = selectedViewAbsence.declineReason;
          await updateAbsencesInfo(selectedViewAbsence._id, payload);
          await fetchData();
          toast.success('Update absence successfully');
          // Reset
          handleCloseViewAbsence();
        } catch (error: any) {
          toast.error(
            error?.response?.data?.message || 'Failed to update absence'
          );
        } finally {
          setLoading(false);
        }
        return;
      }
      setLoading(true);
      const promises: Array<Promise<any>> = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const promise = uploadFile(FILE_ASSET_TYPE.MEDICAL_CERTIFICATE, file);
        promises.push(promise);
      }
      Promise.all(promises)
        .then(async (results) => {
          const payloadCertificateMedicals = [
            ...selectedViewAbsence.certificateMedicals,
            ...results.map((e) => e?.data?.data || '')
          ];
          const payload: UpdateAbsenceInfoDTO = {
            reason: selectedViewAbsence.reason,
            certificateMedicals: payloadCertificateMedicals,
            refundCreditType: selectedViewAbsence.refundCreditType
          };
          if (typeof selectedViewAbsence.declineReason !== 'undefined')
            payload.declineReason = selectedViewAbsence.declineReason;
          await updateAbsencesInfo(selectedViewAbsence._id, payload);
          await fetchData();
          toast.success('Update absence successfully');
          // Reset
          handleCloseViewAbsence();
        })
        .catch((error: any) => {
          toast.error(
            error?.response?.data?.message || 'Failed to update absence'
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleApproveAbsence = async () => {
    try {
      setLoading(true);
      const payload: ApproveAbsenceDTO = {
        status: ABSENCE_STATUS.APPROVED
      };
      await approveAbsences(selectedApproveAbsenceId, payload);
      successStatus.current = ABSENCE_STATUS.APPROVED;
      await fetchData();
      await getTotalPendingAbsenceForms();
      handleCloseApproveAbsence();
      handleCloseViewAbsence();
      handleOpenSuccessModal();
      setTimeout(() => {
        handleCloseSuccessModal();
      }, 3000);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to approve this absence'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeclineAbsence = async () => {
    try {
      setLoading(true);
      const payload: DeclineAbsenceDTO = {
        status: ABSENCE_STATUS.DECLINED,
        declineReason: declineReason
      };
      await declineAbsences(selectedDeclineAbsenceId, payload);
      successStatus.current = ABSENCE_STATUS.DECLINED;
      await fetchData();
      await getTotalPendingAbsenceForms();
      handleCloseDeclineAbsence();
      handleCloseViewAbsence();
      handleOpenSuccessModal();
      setTimeout(() => {
        handleCloseSuccessModal();
      }, 3000);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to decline this absence'
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchData = useCallback(async () => {
    if (params.id) {
      setLoading(true);
      try {
        const result = await getAbsencesByClass(params.id, page, limit);
        setAbsenceForms(result.data.data.data);
        setTotal(result.data.data.total);
      } catch (error: any) {
        setAbsenceForms([]);
        toast.error(
          error?.response?.data?.message || 'Failed to load absence forms'
        );
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [params?.id, page, limit]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    getTotalPendingAbsenceForms();
  }, [getTotalPendingAbsenceForms]);

  return (
    <div className="classDetailTabAbsenceForm">
      {/* View edit absence */}
      {selectedViewAbsence && (
        <AbsenceModal
          loading={loading}
          open={!!selectedViewAbsence}
          onClose={handleCloseViewAbsence}
          reason={selectedViewAbsence.reason}
          onChangeReason={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setSelectedViewAbsence({
              ...selectedViewAbsence,
              reason: event.target.value
            });
          }}
          files={files}
          onChangeFiles={handleChangeFiles}
          documents={selectedViewAbsence.certificateMedicals}
          onChangeDocuments={handleChangeDocuments}
          onSave={() =>
            hasPermission(PERMISSION.UPDATE_ABSENCE) && handleUpdateAbsence
          }
          declineReason={selectedViewAbsence?.declineReason}
          onChangeDeclineReason={
            typeof selectedViewAbsence?.declineReason !== 'undefined'
              ? (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setSelectedViewAbsence({
                    ...selectedViewAbsence,
                    declineReason: event.target.value
                  });
                }
              : undefined
          }
          schedules={selectedViewAbsence.schedules.map((schedule) => ({
            ...schedule,
            className: selectedViewAbsence?.class?.name || ''
          }))}
          status={selectedViewAbsence.status}
          onApproveAbsence={() =>
            hasPermission(PERMISSION.UPDATE_ABSENCE) &&
            handleOpenApproveAbsence(selectedViewAbsence._id)
          }
          onDeclineAbsence={() =>
            hasPermission(PERMISSION.UPDATE_ABSENCE) &&
            handleOpenDeclineAbsence(selectedViewAbsence._id)
          }
          submissionDateTime={dayjs(selectedViewAbsence.updatedAt).format(
            'DD/MM/YYYY, HH:mm'
          )}
          refundCreditType={selectedViewAbsence?.refundCreditType}
          onChangeRefundCreditType={handleChangeRefundCreditType}
          lessonDateTimes={selectedViewAbsence?.schedules?.map(
            (schedule) => schedule?.startTime
          )}
        />
      )}

      {/* Approve absence modal */}
      <AppModal
        open={!!selectedApproveAbsenceId}
        onClose={handleCloseApproveAbsence}
      >
        <AppModalTitle>Approve Absence</AppModalTitle>
        <AppModalContent>
          ARE YOU SURE YOU WANT TO Approve THIS absence?
        </AppModalContent>
        <AppModalActions>
          <AppButton
            buttonSize="small"
            variant="secondary"
            onClick={handleCloseApproveAbsence}
            disabled={loading}
          >
            No
          </AppButton>
          <AppButton
            buttonSize="small"
            variant="primary"
            onClick={handleApproveAbsence}
            isLoading={loading}
          >
            Yes
          </AppButton>
        </AppModalActions>
      </AppModal>

      {/* Decline absence modal */}
      <AppModal
        open={!!selectedDeclineAbsenceId}
        onClose={handleCloseDeclineAbsence}
      >
        <AppModalTitle>Decline Absence</AppModalTitle>
        <AppModalContent>
          ARE YOU SURE YOU WANT TO decline THIS absence?
          <AppTextArea
            rows={2}
            label="Decline Reason"
            disabled={loading}
            {...register('declineReason')}
            value={declineReason}
            onChange={handleChangeDeclineReason}
            message={{
              type: 'error',
              text: errors?.declineReason?.message || ''
            }}
          />
        </AppModalContent>
        <AppModalActions>
          <AppButton
            buttonSize="small"
            variant="secondary"
            onClick={handleCloseDeclineAbsence}
            disabled={loading}
          >
            No
          </AppButton>
          <AppButton
            buttonSize="small"
            variant="primary"
            onClick={handleSubmit(handleDeclineAbsence)}
            isLoading={loading}
          >
            Yes
          </AppButton>
        </AppModalActions>
      </AppModal>

      {/* Success notify */}
      <AppModal open={openSuccessModal} onClose={handleCloseSuccessModal}>
        <img src="/icons/approve-icon.svg" alt="" className="approveIcon" />
        <AppModalTitle>
          {successStatus.current === ABSENCE_STATUS.APPROVED
            ? 'Approved'
            : 'Declined'}
        </AppModalTitle>
        <AppModalContent>
          You have successfully
          {successStatus.current === ABSENCE_STATUS.APPROVED
            ? ' approved '
            : ' declined '}
          the absence
        </AppModalContent>
      </AppModal>

      <div className="absentForm__table">
        <div className="absentForm__table-header">
          <h2 className="absentForm__table-header-title">ABSENCES</h2>
        </div>
        <div className="absentForm__table-content">
          <AppTable
            data={absenceForms}
            columns={columns}
            loading={loading}
            pagination={{
              index: page,
              size: limit,
              total: total
            }}
            onChangePage={(index: number, size: number) => {
              setPage(index);
              setLimit(size);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TabAbsentForm;
