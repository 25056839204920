import React from 'react';
import { useLayout } from 'context/LayoutContext';
import { HiMenu } from 'react-icons/hi';

const MenuIcon: React.FC = () => {
  const { isShowSidebar, toggleCollapse, handleHoverMenu } = useLayout();
  return (
    <div
      className="menu-icon-collapse"
      style={{
        cursor: 'pointer',
        ...(isShowSidebar ? {} : { paddingTop: '2px' })
      }}
      onMouseEnter={handleHoverMenu}
    >
      {!isShowSidebar ? (
        <div className="" onClick={() => toggleCollapse()}>
          <HiMenu size={28} />
        </div>
      ) : null}
    </div>
  );
};

export default MenuIcon;
