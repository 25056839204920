import { object as OBJECT, array, string } from 'yup';
import { NOTIFICATION_TYPE as TYPE } from 'common/enums/notification.enum';

const NOTIFICATION_TYPE = string().required('Please provide type');
const NOTIFICATION_TEMPLATE_ID = string();
const NOTIFICATION_EMAIL_MESSAGE = string().test(
  'required',

  function (value, context) {
    const { parent, path } = context;
    const { types } = parent;
    if (!types.split(',').includes(TYPE.SMS)) {
      if (!value)
        return this.createError({
          path: path,
          message: 'Please provide message'
        });
    }
    return true;
  }
);
const NOTIFICATION_SMS_MESSAGE = string().test(
  'required',

  function (value, context) {
    const { parent, path } = context;
    const { types } = parent;
    if (types.split(',').includes(TYPE.SMS)) {
      if (!value)
        return this.createError({
          path: path,
          message: 'Please provide message'
        });
    }
    return true;
  }
);
const NOTIFICATION_TITLE = string().required('Please provide title');
const NOTIFICATION_MESSAGE = string().required('Please provide message');
const NOTIFICATION_DATE = string().required('Please provide date');
const NOTIFICATION_TIME = string().required('Please provide time');
const NOTIFICATION_STUDENT_ID = array();

const validations = {
  OBJECT,
  NOTIFICATION_TYPE,
  NOTIFICATION_TEMPLATE_ID,
  NOTIFICATION_TITLE,
  NOTIFICATION_EMAIL_MESSAGE,
  NOTIFICATION_SMS_MESSAGE,
  NOTIFICATION_MESSAGE,
  NOTIFICATION_DATE,
  NOTIFICATION_TIME,
  NOTIFICATION_STUDENT_ID
};
export default validations;
