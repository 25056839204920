import React, { useCallback, useEffect, useState } from 'react';
import AppButton from 'common/components/AppButton';
import AppModal, {
  AppModalFormActions,
  AppModalContent,
  AppModalFormTitle,
  AppModalTitle
} from 'common/components/AppModal';
import {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppRadio from 'common/components/AppRadio';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import {
  cardRequestAuthenticated,
  getCardByUserId,
  tokenizationCardRequest
} from 'services/payment.service';
import { HiXMark } from 'react-icons/hi2';
import { ICard } from 'common/interfaces/payment.interface';
import { PAYMENT_USE_CARD } from 'common/enums/payment.enum';
import { formatData } from 'common/helpers/dataFormat.helper';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import './styles.scss';

const VIVAPAY_URL = process.env.REACT_APP_VIVAPAY_URL;

interface IPaymentModalProps {
  open: boolean;
  onClose: () => void;

  payerId: string;
  amount: number;
  onMakePayment: (cardId: string) => void;

  isFailedToGetCardToken?: boolean;
}

const PaymentModal = (props: IPaymentModalProps) => {
  const { open, onClose, payerId, onMakePayment, isFailedToGetCardToken } =
    props;

  const [loading, setLoading] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(
    isFailedToGetCardToken || false
  );

  const [paymentUseCard, setPaymentUseCard] = useState<PAYMENT_USE_CARD>(
    PAYMENT_USE_CARD.NEW
  );

  const [openVivaPayModal, setOpenVivaPayModal] = useState<boolean>(false);

  const [cardOnFile, setCardOnFile] = useState<Partial<ICard> | null>(null);
  const [cardDifferent, setCardDifferent] = useState<Partial<ICard> | null>(
    null
  );

  const [cardId, setCardId] = useState<string>('');

  const handlePayment = async () => {
    setLoading(true);
    try {
      await onMakePayment(
        !!cardDifferent && paymentUseCard === PAYMENT_USE_CARD.DIFFERENT
          ? cardDifferent?.cardId || ''
          : cardOnFile?.cardId || ''
      );
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };

  // Change radios payment use cards
  const handleChangeUseCardOnFile = () => {
    setPaymentUseCard(PAYMENT_USE_CARD.ON_FILE);
    // UNSET DIFFERENT CARD WHEN BACK TO CARD ON FILE.
    setCardDifferent(null);
  };
  const handleChangeUseDifferentCard = async () => {
    setPaymentUseCard(PAYMENT_USE_CARD.DIFFERENT);
    await handleTokenizationCardRequest();
  };

  // Generate CARD TOKEN
  const handleCardRequestAuthenticated = async () => {
    setLoading(true);
    try {
      const result = await cardRequestAuthenticated(cardId);
      if (!!result?.data?.data?.data?.card?.number) {
        setIsError(false);
        if (paymentUseCard === PAYMENT_USE_CARD.NEW) {
          setPaymentUseCard(PAYMENT_USE_CARD.ON_FILE);
          setCardOnFile({
            cardId: result.data.data.data.PK,
            cardNumber: result.data.data.data.card.number,
            expiry: `${result.data.data.data.card.expiry.month}/${result.data.data.data.card.expiry.year}`
          });
        } else if (paymentUseCard === PAYMENT_USE_CARD.ON_FILE) {
          setPaymentUseCard(PAYMENT_USE_CARD.ON_FILE);
          setCardOnFile({
            cardId: result.data.data.data.PK,
            cardNumber: result.data.data.data.card.number,
            expiry: `${result.data.data.data.card.expiry.month}/${result.data.data.data.card.expiry.year}`
          });
        } else {
          setPaymentUseCard(PAYMENT_USE_CARD.DIFFERENT);
          setCardDifferent({
            cardId: result.data.data.data.PK,
            cardNumber: result.data.data.data.card.number,
            expiry: `${result.data.data.data.card.expiry.month}/${result.data.data.data.card.expiry.year}`
          });
        }
      } else {
        if (!!cardOnFile) {
          setPaymentUseCard(PAYMENT_USE_CARD.ON_FILE);
        }
      }
    } catch (error: any) {
    } finally {
      setLoading(false);
    }
  };
  const handleCloseVivaPayModal = async () => {
    await handleCardRequestAuthenticated();
    setOpenVivaPayModal(false);
  };

  // Generate CARD ID and open viva pay modal to enter credit card
  const handleTokenizationCardRequest = useCallback(async () => {
    setLoading(true);
    try {
      const result = await tokenizationCardRequest('Save Card');
      setCardId(result.data.data.data.id);
      setOpenVivaPayModal(true);
    } catch (error) {
      setCardId('');
    } finally {
      setLoading(false);
    }
  }, []);

  // INIT ==> Check first time payment OR saved on file
  const handleGetCard = async () => {
    setLoading(true);
    try {
      const result = await getCardByUserId(payerId);
      setPaymentUseCard(PAYMENT_USE_CARD.ON_FILE);
      setCardId(result.data.data.cardId);
      setCardOnFile({
        cardId: result.data.data.cardId,
        cardNumber: result.data.data.cardNumber,
        expiry: result.data.data.expiry
      });
    } catch (error: any) {
      if (error?.response?.data?.message === 'card not found') {
        setPaymentUseCard(PAYMENT_USE_CARD.NEW);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetCard();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (typeof isFailedToGetCardToken !== 'undefined')
      setIsError(isFailedToGetCardToken);
  }, [isFailedToGetCardToken]);

  return (
    <div className="c__modalPaymentWrapper">
      {loading && <AppLoadingContainer />}
      <div className="c__modalPayment">
        <div className="paymentModal">
          <AppModal open={openVivaPayModal} onClose={handleCloseVivaPayModal}>
            <AppModalFormTitle>
              <HiXMark onClick={handleCloseVivaPayModal} />
            </AppModalFormTitle>
            <iframe
              src={`${VIVAPAY_URL}/p/${cardId}`}
              title={cardId || ''}
            ></iframe>
          </AppModal>
        </div>

        <div className="paymentModal_content">
          <AppModal open={open} onClose={onClose}>
            <AppModalTitle>Make payment</AppModalTitle>
            <AppModalContent>
              <p className="note">
                Credit card surcharge will automatically apply to all payments
                <br />
                (VISA/MASTSERCARD: 1.4%, AMEX:2.5%)
              </p>

              {isError && (
                <div className="paymentModal__errorInfo">
                  <HiOutlineExclamationCircle size={20} />
                  Please enter the card info again.
                </div>
              )}

              <div className="cardOptions">
                {paymentUseCard === PAYMENT_USE_CARD.NEW || isError ? (
                  <React.Fragment>
                    <AppButton
                      buttonSize="small"
                      variant="secondary"
                      className="selectCardButton"
                      onClick={handleTokenizationCardRequest}
                    >
                      Use credit card
                    </AppButton>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="cardOptions__radios">
                      <AppRadio
                        label="Use card on file"
                        checked={paymentUseCard === PAYMENT_USE_CARD.ON_FILE}
                        onChange={handleChangeUseCardOnFile}
                      />
                      <AppRadio
                        label="Use different card"
                        checked={paymentUseCard === PAYMENT_USE_CARD.DIFFERENT}
                        onChange={handleChangeUseDifferentCard}
                      />
                    </div>
                    {!!cardDifferent &&
                    paymentUseCard === PAYMENT_USE_CARD.DIFFERENT ? (
                      <AppCardContent className="cardOptions__info">
                        <AppCardContentItem
                          subtitle="Type"
                          title={formatData(cardDifferent?.type)}
                        />
                        <AppCardContentItem
                          subtitle="Card"
                          title={formatData(cardDifferent?.cardNumber)}
                        />
                        <AppCardContentItem
                          subtitle="Expiry"
                          title={formatData(cardDifferent?.expiry)}
                        />
                      </AppCardContent>
                    ) : (
                      <AppCardContent className="cardOptions__info">
                        <AppCardContentItem
                          subtitle="Type"
                          title={formatData(cardOnFile?.type)}
                        />
                        <AppCardContentItem
                          subtitle="Card"
                          title={formatData(cardOnFile?.cardNumber)}
                        />
                        <AppCardContentItem
                          subtitle="Expiry"
                          title={formatData(cardOnFile?.expiry)}
                        />
                      </AppCardContent>
                    )}
                  </React.Fragment>
                )}
              </div>

              {/* Future features */}
              {false && (
                <div className="futurePaymentOptions">
                  <AppCardHeader title="FUTURE PAYMENT OPTION" />
                  <div className="futurePaymentOptions__radios">
                    <AppRadio label="Future payment will be proceeded with the card above as default" />

                    <AppRadio label="Select this option if you would like to pay your subsequent fees with the new card same as above rather than the card on file" />

                    <AppRadio label="Select this option if you would like to enter Bank/Savings Account number to pay your subsequent fees rather than the above credit card" />
                  </div>
                </div>
              )}
            </AppModalContent>

            <AppModalFormActions>
              <AppButton
                buttonSize="small"
                variant="primary"
                disabled={paymentUseCard === PAYMENT_USE_CARD.NEW}
                isLoading={loading}
                onClick={handlePayment}
              >
                Make Payment
              </AppButton>
              <AppButton
                buttonSize="small"
                variant="secondary"
                disabled={loading}
                onClick={onClose}
              >
                Cancel
              </AppButton>
            </AppModalFormActions>
          </AppModal>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
