import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import * as color from '../../constants/colors';
import { BeatLoader } from 'react-spinners';
import './AppButton.scss';

type ButtonVariant = 'primary' | 'secondary' | 'disabled';
type ButtonType = 'large' | 'small';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  buttonSize?: ButtonType;
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  style?: React.CSSProperties;
}

const AppButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'primary',
      buttonSize = 'large',
      children,
      onClick,
      isLoading = false,
      style,
      disabled,
      ...props
    },
    ref
  ) => {
    return (
      <button
        style={style}
        onClick={onClick}
        ref={ref}
        {...props}
        className={`btn btn-${variant} btn-${buttonSize} ${props.className}`}
        disabled={disabled || isLoading}
      >
        {isLoading ? <BeatLoader color={color.titleColor} /> : children}
      </button>
    );
  }
);

export default AppButton;
