import React, { useMemo, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import AppButton from 'common/components/AppButton';
import AppCard, {
  AppCardContent,
  AppCardHeader
} from 'common/components/AppCard';
import AppRadio from 'common/components/AppRadio';
import AppSelect from 'common/components/AppSelect';
import AppTextArea from 'common/components/AppTextArea';
import AppToggle from 'common/components/AppToggle';

import { HiArrowLeft } from 'react-icons/hi2';

import { useToast } from 'context/ToastContext';
import { useBrandLocation } from 'context/BrandLocationContext';

import {
  HEALTH_ANSWER_TYPE_OPTIONS,
  HEALTH_EXPECTED_ANSWER_OPTIONS,
  HEALTH_SECTION_LABEL_OPTIONS
} from 'common/constants/index';
import {
  HEALTH_ANSWER_TYPE,
  HEALTH_EXPECTED_ANSWER,
  HEALTH_SECTION_LABEL
} from 'common/enums/healthQuestionnaire.enum';

import { createHealthQuestionnaire } from 'services/healthQuestionnaire.service';
import yupHealthQuestionnaire from 'validators/healthQuestionnaire.validator';
import { CreateHealthQuestionnaireDto } from 'DTOs/healthQuestionnaire.dto';
import AppInput from 'common/components/AppInput';

const initHealthQuestionnaire: CreateHealthQuestionnaireDto = {
  question: '',
  answerType: HEALTH_ANSWER_TYPE.IDLE,
  sectionLabel: HEALTH_SECTION_LABEL.IDLE,
  expectedAnswer: HEALTH_EXPECTED_ANSWER.YES,
  isActive: false,
  mandatory: false,
  medicalCondition: '',
  medicalConditionCode: ''
};

const validationSchema = yupHealthQuestionnaire.OBJECT({
  question: yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_QUESTION,
  answerType: yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_ANSWER_TYPE,
  sectionLabel: yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_SECTION_LABEL,
  expectedAnswer: yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_EXPECTED_ANSWER,
  isActive: yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_ACTIVE,
  mandatory: yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_MANDATORY,
  medicalCondition:
    yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_MEDICAL_CONDITION,
  medicalConditionCode:
    yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_MEDICAL_CONDITION_CODE
});

interface IHealthQuestionnaireAddFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const HealthQuestionnaireAddForm = (
  props: IHealthQuestionnaireAddFormProps
) => {
  const { open, onClose, onSuccess } = props;

  const { selectedLocation: globalLocation } = useBrandLocation();
  const toast = useToast();

  const [healthQuestionnaire, setHealthQuestionnaire] =
    useState<CreateHealthQuestionnaireDto>(initHealthQuestionnaire);

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors }
  } = useForm<CreateHealthQuestionnaireDto>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<CreateHealthQuestionnaireDto>,
    defaultValues: healthQuestionnaire
  });

  const __isRequiredExpectedAnswer = useMemo((): boolean => {
    return (
      healthQuestionnaire.answerType === HEALTH_ANSWER_TYPE.NUMBER &&
      healthQuestionnaire.mandatory
    );
  }, [healthQuestionnaire.answerType, healthQuestionnaire.mandatory]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setHealthQuestionnaire({
      ...healthQuestionnaire,
      [event.target.name]: event.target.value
    });
    // @ts-ignore
    setValue(event.target.name, event.target.value);
    // @ts-ignore
    trigger(event.target.name);
  };

  const onSubmit = (data: CreateHealthQuestionnaireDto) => {
    setLoading(true);
    createHealthQuestionnaire({
      ...data,
      isActive: healthQuestionnaire.isActive,
      mandatory: healthQuestionnaire.mandatory,
      expectedAnswer: __isRequiredExpectedAnswer
        ? healthQuestionnaire.expectedAnswer
        : HEALTH_EXPECTED_ANSWER.IDLE,
      locationId: globalLocation?._id
    })
      .then(() => {
        setHealthQuestionnaire({ ...initHealthQuestionnaire });
        reset(initHealthQuestionnaire);
        toast.success('Create health questionnaire successfully');
        onSuccess();
      })
      .catch((error: any) => {
        toast.error(
          error?.response?.data?.message ||
            'Failed to create health questionnaire'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="healthQuestionnaireAddContainer">
      <div
        className={`overlay ${open ? 'active' : ' '}`}
        onClick={onClose}
      ></div>
      <div className={`healthQuestionnaireAddForm ${open ? 'active' : ' '}`}>
        <div className="healthQuestionnaireAddForm__header">
          <HiArrowLeft
            size={24}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
          <p>Add Health Questionnaire</p>
        </div>
        <div className="healthQuestionnaireAddForm__content">
          <AppCard>
            <div className="healthQuestionnaireAddForm__content-wrapper">
              <AppCardHeader title="Health Questionnaire" />
              <AppCardContent className="healthQuestionnaireAddForm__content-fields">
                <AppSelect
                  label="Section Label"
                  options={HEALTH_SECTION_LABEL_OPTIONS}
                  {...register('sectionLabel')}
                  value={healthQuestionnaire.sectionLabel}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.sectionLabel?.message || ''
                  }}
                />
                <AppSelect
                  label="Answer type"
                  options={HEALTH_ANSWER_TYPE_OPTIONS}
                  {...register('answerType')}
                  value={healthQuestionnaire.answerType}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.answerType?.message || ''
                  }}
                />
                <div className="healthQuestionnaireAddForm__content-fields-toggle">
                  <div className="healthQuestionnaireAddForm__content-fields-toggle-item">
                    <AppToggle
                      {...register('isActive')}
                      value={healthQuestionnaire.isActive}
                      onChange={() => {
                        setHealthQuestionnaire({
                          ...healthQuestionnaire,
                          isActive: !healthQuestionnaire.isActive
                        });
                      }}
                    />
                    <p>Active</p>
                  </div>
                  <div className="healthQuestionnaireAddForm__content-fields-toggle-item">
                    <AppToggle
                      {...register('mandatory')}
                      value={healthQuestionnaire.mandatory}
                      onChange={() => {
                        setHealthQuestionnaire({
                          ...healthQuestionnaire,
                          mandatory: !healthQuestionnaire.mandatory
                        });
                      }}
                    />
                    <p>Mandatory</p>
                  </div>
                </div>
              </AppCardContent>

              <AppCardContent className="healthQuestionnaireAddForm__content-fields">
                <AppInput
                  label="Medical Condition"
                  {...register('medicalCondition')}
                  value={healthQuestionnaire.medicalCondition}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.medicalCondition?.message || ''
                  }}
                />
                <AppInput
                  label="Medical Condition Code"
                  {...register('medicalConditionCode')}
                  value={healthQuestionnaire.medicalConditionCode}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.medicalConditionCode?.message || ''
                  }}
                />
              </AppCardContent>

              <AppCardContent className="healthQuestionnaireAddForm__content-area">
                <AppTextArea
                  label="Question"
                  {...register('question')}
                  value={healthQuestionnaire.question}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.question?.message || ''
                  }}
                />
              </AppCardContent>

              {__isRequiredExpectedAnswer && (
                <AppCardContent className="healthQuestionnaireAddForm__content-radio">
                  <p>Expected Answer*</p>
                  <div className="healthQuestionnaireAddForm__content-radio-group">
                    {HEALTH_EXPECTED_ANSWER_OPTIONS.map((expectedAnswer) => {
                      return (
                        <AppRadio
                          name="expectedAnswer"
                          key={expectedAnswer.value}
                          label={expectedAnswer.label}
                          value={expectedAnswer.value}
                          defaultChecked={
                            expectedAnswer.value ===
                            healthQuestionnaire.expectedAnswer
                          }
                          onChange={() => {
                            setHealthQuestionnaire({
                              ...healthQuestionnaire,
                              expectedAnswer: expectedAnswer.value
                            });
                          }}
                        />
                      );
                    })}
                  </div>
                </AppCardContent>
              )}
            </div>
          </AppCard>
        </div>
        <div className="healthQuestionnaireAddForm__actions">
          <AppButton
            onClick={handleSubmit(onSubmit)}
            type="submit"
            isLoading={loading}
          >
            Save
          </AppButton>
          <AppButton variant="secondary" onClick={onClose}>
            Cancel
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default HealthQuestionnaireAddForm;
