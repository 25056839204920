import API from './API';

import { IRole } from 'common/interfaces/role.interface';
import { IPermission } from 'common/interfaces/hierarchy.interface';

const ROLE_API_ENDPOINT = '/roles';
const PERMISSION_API_ENDPOINT = `/permissions`;

export const getRoleList = async (keyword?: string): Promise<IRole[]> => {
  const response = await API.get(`${ROLE_API_ENDPOINT}`, { keyword });
  return response.data.data;
};

export const getPermissionList = async (): Promise<IPermission[]> => {
  const response = await API.get(`${PERMISSION_API_ENDPOINT}`);
  return response.data.data;
};

export const updateRoles = (id: string, payload: any) => {
  return API.patch(`${ROLE_API_ENDPOINT}/${id}`, { body: payload });
};

export const addRole = async (payload: any) => {
  const response = await API.post(`${ROLE_API_ENDPOINT}`, { body: payload });
  return response.data;
};

export const getRoleDetail = async (roleId: string) => {
  try {
    const response = await API.get(`${ROLE_API_ENDPOINT}/${roleId}`);
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const deleteRole = (id: string) => {
  return API.delete(`${ROLE_API_ENDPOINT}/${id}`);
};
