import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ILevel, ISkill } from 'common/interfaces/levelBreakdown.interface';
import { useParams } from 'react-router-dom';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { getLevelBreakdownById } from 'services/levelBreakdown.service';
import LevelsBreakdownInformation from './components/LevelsBreakdownInformation';
import LevelsBreakdownDetailSkillUpdate from './components/LevelsBreakdownDetailSkillUpdate';
import AppInputSearch from 'common/components/AppInputSearch';
import AppButton from 'common/components/AppButton';
import { HiPlus } from 'react-icons/hi';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import useDebounce from 'common/hooks/useDebounce';
import LevelsBreakdownDetailSkillAdd from './components/LevelsBreakdownDetailSkillAdd';
import { fuzzySearch } from 'common/helpers/search.helper';
import LevelsBreakdownAssessmentScoreUpdate from './components/LevelsBreakdownAssessmentScoreUpdate';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import './desktop.scss';
import LevelsBreakdownNextLevel from './components/LevelsBreakdownNextLevel';
import { useToast } from 'context/ToastContext';

const LevelsBreakdownDetail = () => {
  const params = useParams();
  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(true);

  const [level, setLevel] = useState<ILevel | null>(null);

  const initSkills = useRef<Array<ISkill>>([]);

  const [isOpenAddForm, setIsOpenAddForm] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search);

  const handleOpenAddForm = () => {
    setIsOpenAddForm(true);
  };
  const handleCloseAddForm = () => {
    setIsOpenAddForm(false);
  };

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const onEditSuccess = () => {
    fetchData();
  };

  const onAddSkillSuccess = () => {
    fetchData();
    handleCloseAddForm();
  };

  const fetchData = useCallback(() => {
    getLevelBreakdownById(params?.id || '')
      .then((result) => {
        setLevel(result.data.data);
        initSkills.current = result.data.data.skills;
      })
      .catch((error: any) => {
        toast.error(error?.response?.data?.message);
        setLevel(null);
      })
      .finally(() => {
        setLoading(false);
      });
      // eslint-disable-next-line
  }, [params?.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (level) {
      setLevel({
        ...level,
        skills: fuzzySearch(search, initSkills.current, 'name')
      });
    }
    // eslint-disable-next-line
  }, [debouncedSearch]);

  return (
    <main className="levelBreakdownDetailPage">
      <AppBreadCrumb
        items={[
          { name: 'Levels Breakdown', href: '/levels-breakdown' },
          { name: 'Levels Breakdown details', href: '' }
        ]}
      />
      {loading ? (
        <AppLoadingContainer />
      ) : !!level ? (
        <div className="layoutContainer levelBreakdownDetail">
          <div className="levelBreakdownDetail__top">
            <div className="levelBreakdownDetail__top-left">
              <LevelsBreakdownInformation
                data={level}
                onSuccess={onEditSuccess}
                isCanEdit={level.classInUsed === 0}
              />
            </div>
            <div className="levelBreakdownDetail__top-right">
              <LevelsBreakdownAssessmentScoreUpdate
                data={level.assessmentScore}
                onSuccess={onEditSuccess}
                isCanEdit={level.classInUsed === 0}
              />
            </div>
          </div>
          <div className="levelBreakdownDetail__nextLevel">
            <LevelsBreakdownNextLevel data={level} onSuccess={onEditSuccess} />
          </div>
          <div className="levelBreakdownDetail__searchContainer">
            <AppInputSearch
              type="text"
              value={search}
              onChange={onChangeSearch}
              placeholder="Skill"
              onClearSearch={onClearSearch}
              startIcon={<HiMagnifyingGlass />}
            />
            <PermissionWrapper permission={PERMISSION.CREATE_SKILL}>
              <AppButton
                variant="primary"
                buttonSize="small"
                type="submit"
                onClick={handleOpenAddForm}
              >
                <HiPlus />
                <p>Skill</p>
              </AppButton>
            </PermissionWrapper>
          </div>

          {isOpenAddForm && (
            <PermissionWrapper permission={PERMISSION.CREATE_SKILL}>
              <LevelsBreakdownDetailSkillAdd
                open={isOpenAddForm}
                onClose={handleCloseAddForm}
                position={level.skills.length}
                levelId={level._id}
                onSuccess={onAddSkillSuccess}
              />
            </PermissionWrapper>
          )}

          {level.skills.length > 0 &&
            [...level.skills].map((skill) => {
              return (
                <LevelsBreakdownDetailSkillUpdate
                  key={skill._id}
                  data={skill}
                  onSuccess={onEditSuccess}
                  isCanEdit={level.classInUsed === 0}
                />
              );
            })}
        </div>
      ) : (
        <></>
      )}
    </main>
  );
};

export default LevelsBreakdownDetail;
