export enum LOGIN_STAGE {
  LOGIN = 'LOGIN',
  OTP = 'OTP'
}

export enum RESEND_OTP_TYPE {
  IDLE = '',
  EMAIL = 'Email',
  SMS = 'SMS'
}

export enum COLOR_TYPE {
  MULTI_COLOR = 'multi-color',
  SINGLE_COLOR = 'single-color'
}

export enum PROGRAM_TYPE {
  SERIES = 'Weekly Learn To Swim',
  PRIVATE = 'Private Learn To Swim',
  INTENSIVE_HOLIDAY_PROGRAM = 'Intensive Learn To Swim',
  ASSESSMENT_TRIAL = 'New Student Assessment'
}

export enum ENROLMENT_TYPE {
  CASUAL_CLASS = 'Casual Lesson',
  ONGOING_CLASS = 'Ongoing Enrolment',
  MAKE_UP = 'Make-up Lesson',
  HOLIDAY_PROGRAM = 'Ongoing Enrolment',
  ASSESSMENT_TRIAL = 'New Student Assessment'
}

export enum PAYMENT_TYPE {
  UPFRONT = 'Paid in full',
  DIRECT_DEBIT = 'Direct Debit'
}

export enum CHANGE_TYPE {
  PERMANENT = 'PERMANENT',
  TEMPORARY = 'TEMPORARY'
}
export enum MONEY_CREDIT_REFERENCE_TYPE {
  ABSENCE = 'absence',
  CANCELLATION = 'submit-cancellation',
  BOOK_CLASS = 'book-class'
}
export enum DISCOUNT_TYPE {
  IDLE = '',
  PERCENTAGE = 'percentage',
  AMOUNT = 'amount',
  COMPLIMENTARY = 'complimentary'
}

export enum RESPONSE_CHECK_USER {
  PHONE_NUMBER_EXISTED = 'phone_number_existed',
  EMAIL_EXISTED = 'email_existed',
  BOTH_EXISTED = 'both_existed',
  EMAIL_MUST_BE_AN_EMAIL = 'email must be an email',
  PHONE_NUMBER_MUST_BE_A_VALID_PHONE_NUMBER = 'phoneNumber must be a valid phone number',
  ERROR_SECOND_RP_NOT_FOUND = 'SECOND_RP_NOT_FOUND',
  ERROR_PRIMARY_RP_NOT_FOUND = 'PRIMARY_RP_NOT_FOUND',
  ERROR_SECOND_RP_EXISTED = 'SECOND_RP_EXISTED',
  ERROR_RESPONSIBLE_PERSON_IS_PRIMARY_RESPONSIBLE_PERSON = 'RESPONSIBLE_PERSON_IS_PRIMARY_RESPONSIBLE_PERSON'
}

export enum RESPONSE_CHECK_USER_INVITATION {
  INVALID_INVITATION_LINK = 'invalid invitation link'
}

export enum MEMBER_TYPE {
  NEW = 'new',
  EXISTING = 'existing',
  PREVIOUS = 'previous'
}
