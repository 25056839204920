import { useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import { publicIndexRoutes } from './indexRoutes';
import { useAuth } from 'context/AuthContext';

const PublicRoutes = () => {
  const { me } = useAuth();

  const __isAllowed = useMemo((): boolean => {
    return !me;
  }, [me]);

  return (
    <Routes>
      {publicIndexRoutes.map((item, key) => {
        return (
          <Route
            key={key}
            element={
              <ProtectedRoute isAllowed={__isAllowed} redirectPath="/" />
            }
          >
            <Route key={key} path={item.path} element={item.component} />
          </Route>
        );
      })}
    </Routes>
  );
};

export default PublicRoutes;
