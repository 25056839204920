import React from 'react';
import AppCheckbox from 'common/components/AppCheckbox';
import { HiChevronDown } from 'react-icons/hi';
import styles from './styles.module.scss';
import { IOption } from 'common/interfaces';
import { GoXCircleFill } from 'react-icons/go';
import { fuzzySearch } from 'common/helpers/search.helper';

interface IReportSelectionProps {
  label: string;
  placeholder?: string;
  options: Array<IOption>;
  onSelect?: (value: string) => void;
  onSelectAll?: () => void;
  selectedIds?: Array<string>;
  isSearch?: boolean;
}

const ReportSelection = (props: IReportSelectionProps) => {
  const {
    label,
    placeholder,
    options,
    onSelect,
    onSelectAll,
    selectedIds,
    isSearch = false
  } = props;

  const [search, setSearch] = React.useState<string>('');

  const dropdownRef = React.useRef<any>(null);
  const inputRef = React.useRef<any>(null);

  const [open, setOpen] = React.useState<boolean>(false);

  const handleClearSearch = React.useCallback(() => {
    setSearch('');
  }, []);

  const handleChangeSearch = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  const __isCheckAll = React.useMemo((): boolean => {
    return options.length === selectedIds?.length;
  }, [selectedIds, options]);

  const __renderClearSearch = React.useMemo((): JSX.Element | null => {
    if (search === '') return null;
    return <GoXCircleFill size={16} onClick={handleClearSearch} />;
  }, [handleClearSearch, search]);

  const __filteredOptions = React.useMemo((): Array<IOption> => {
    return fuzzySearch(search, options, 'label');
  }, [search, options]);

  const onToggle = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // Close popper if click outside the popper
    const handleClickOutside = (event: MouseEvent) => {
      // Click at vertical button to close
      if (inputRef.current && inputRef.current.contains(event.target)) {
        return;
      }
      // Handle click outside to close pop up
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef, dropdownRef]);

  return (
    <div className={styles.reportSelection}>
      <div className={styles.reportSelectionMain}>
        <span className={styles.reportSelectionMainLabel}>{label}</span>
        <div
          ref={inputRef}
          className={styles.reportSelectionMainInput}
          onClick={onToggle}
        >
          <p>{placeholder ?? `Select option`}</p>
          <HiChevronDown size={16} />
        </div>
        {open && (
          <div ref={dropdownRef} className={styles.reportSelectionMainDropdown}>
            {isSearch && (
              <div className={styles.reportSelectionMainDropdownSearch}>
                <input
                  type="text"
                  value={search}
                  onChange={handleChangeSearch}
                  placeholder="Search..."
                />
                {__renderClearSearch}
              </div>
            )}

            {!!onSelectAll && (
              <div className={styles.reportSelectionMainDropdownItem}>
                <AppCheckbox
                  label="Select all"
                  checked={__isCheckAll}
                  onChange={onSelectAll}
                />
              </div>
            )}
            {__filteredOptions.map((option) => {
              return (
                <div
                  key={option.value}
                  className={styles.reportSelectionMainDropdownItem}
                >
                  <AppCheckbox
                    label={option.label}
                    value={option.value}
                    checked={selectedIds?.includes(option.value.toString())}
                    onChange={
                      onSelect
                        ? (event: React.ChangeEvent<HTMLInputElement>) =>
                            onSelect(event.target.value)
                        : undefined
                    }
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ReportSelection);
