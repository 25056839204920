// Text color
export const titleColor = '#FFFFFF';
export const subTitleColor = '#85858F';
export const labelColor = '#63636E';
export const placeholderColor = '#8E8E96';
export const lineColor = '#40404A';

// Background color
export const bgPrimary = '#1C1C24';
export const bgSecondary = '#13131A';
export const bgTertiary = '#292933';

// State color
export const primaryColor = '#034EA2';
export const errorColor = '#EB5757';
export const successColor = '#0BB783';
export const warningColor = '#F4B740';
