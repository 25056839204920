import { BOOKING_TYPE, PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import { AUTOMATION_DISCOUNT_TYPE } from 'common/enums/voucher.enum';
import {
  getAutomationDiscountPosition,
  getAutomationDiscountValue
} from 'common/helpers/voucher.helper';
import { IStudentBookingData } from 'common/interfaces/student.interface';
import { some, uniqBy } from 'lodash';

export const handleGetDataWithDiscount = (
  studentBookingNewData: IStudentBookingData[],
  totalValidActiveOngoingStudent: number
): IStudentBookingData[] => {
  const studentListHaveOngoingClass = uniqBy(
    studentBookingNewData.filter((studentBookingData: IStudentBookingData) =>
      some(
        studentBookingData?.bookingData,
        (bookingItem) =>
          bookingItem?.enrollmentType === BOOKING_TYPE.ONGOING_CLASS
      )
    ),
    '_id'
  );
  let studentIdsCanApplyN1AutomaticDiscount: string[] = [];
  const AUTOMATIC_DISCOUNT_N1 = getAutomationDiscountPosition(
    AUTOMATION_DISCOUNT_TYPE.THIRD_STUDENT_ENROLLMENT
  );

  if (studentListHaveOngoingClass.length) {
    if (totalValidActiveOngoingStudent >= AUTOMATIC_DISCOUNT_N1 - 1) {
      studentIdsCanApplyN1AutomaticDiscount = studentListHaveOngoingClass.map(
        (student) => student._id
      );
    } else {
      studentIdsCanApplyN1AutomaticDiscount = studentListHaveOngoingClass
        .map((student) => student._id)
        .slice(
          AUTOMATIC_DISCOUNT_N1 - totalValidActiveOngoingStudent - 1,
          studentListHaveOngoingClass.length
        );
    }
  }
  let studentIdCurrent = studentBookingNewData[0]?._id;
  let countOngoingClass = 0;
  const studentBookingDataNWithDiscount = studentBookingNewData.map(
    (studentBookingData) => {
      if (studentIdCurrent !== studentBookingData._id) {
        studentIdCurrent = studentBookingData._id;
        countOngoingClass = 0;
      }
      const bookingData = studentBookingData?.bookingData.map((classItem) => {
        delete classItem.automaticDiscount;
        if (classItem.enrollmentType === BOOKING_TYPE.ONGOING_CLASS) {
          // class 1st
          if (countOngoingClass === 0) {
            // check if active ST
            if (
              studentIdsCanApplyN1AutomaticDiscount.includes(
                studentBookingData._id
              ) &&
              !classItem.classInfo.isPrivate
            ) {
              classItem.automaticDiscount = {
                type: AUTOMATION_DISCOUNT_TYPE.THIRD_STUDENT_ENROLLMENT,
                value: getAutomationDiscountValue(
                  AUTOMATION_DISCOUNT_TYPE.THIRD_STUDENT_ENROLLMENT
                )
              };
            }
          } else {
            // class 2nd and subsequent
            if (
              countOngoingClass &&
              classItem.paymentOption === PAYMENT_VALUE.DIRECT_DEBIT &&
              !classItem.classInfo.isPrivate
            ) {
              classItem.automaticDiscount = {
                type: AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT,
                value: getAutomationDiscountValue(
                  AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT
                )
              };
            }
          }
          if (studentIdCurrent === studentBookingData._id) {
            countOngoingClass = countOngoingClass + 1;
          }
        }
        return classItem;
      });
      return {
        ...studentBookingData,
        bookingData
      };
    }
  );
  return studentBookingDataNWithDiscount;
};
