import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  lazy,
  useMemo,
  Suspense,
  memo
} from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useDebounce from 'common/hooks/useDebounce';
import { useToast } from 'context/ToastContext';
import { HiOutlineIdentification } from 'react-icons/hi';
import { GoPlus } from 'react-icons/go';
import { HiMagnifyingGlass, HiOutlineExclamationCircle } from 'react-icons/hi2';
import AppTable from 'common/components/AppTable';
import AppInputSearch from 'common/components/AppInputSearch';
import AppButton from 'common/components/AppButton';
import AppCard from 'common/components/AppCard';
import AppRadio from 'common/components/AppRadio';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppCheckbox from 'common/components/AppCheckbox';
import { useBrandLocation } from 'context/BrandLocationContext';
import {
  formatData,
  formatDate,
  formatSecretPhoneNumber
} from 'common/helpers/dataFormat.helper';
import {
  IStudent,
  IStudentTableFilter,
  IStudentListResponsiblePerson
} from 'common/interfaces/student.interface';
import {
  STATUS_TYPE_LABEL,
  ENROLLMENT_STATUS
} from 'common/enums/student.enum';
import { getStudentList } from 'services/students.service';
import { deleteStudentFromRP } from 'services/responsiblePerson.service';
import { PERMISSION } from 'common/enums/permission.enum';
import PermissionWrapper from 'components/PermissionWrapper';
import AppTooltip from 'common/components/AppToolTip';
import AppLoadingContainer from 'common/components/AppLoadingContainer';

import './desktop.scss';

const DeleteStudent = lazy(() => import('components/Student/StudentDelete'));
const StudentStatistic = lazy(() => import('../StudentStatistic'));

export interface RefHandle {
  refetchReset: () => void;
}

interface Props {
  responsiblePersonId?: string;
  byNoResponsiblePerson?: boolean;
  byResponsiblePerson?: boolean;
  openAdd?: boolean;
  onOpenAdd?: () => void;
  onCloseAdd?: () => void;
  onFinish?: (newStep?: number, studentData?: IStudent) => void;
  onSelectedStudent?: (studentData: IStudent | null) => void;
  onSelectedStudents?: (studentData: IStudent[]) => void;
}

const mainRoute = '/students';

const StudentList = forwardRef<RefHandle, Props>(
  (
    {
      responsiblePersonId = '',
      byNoResponsiblePerson = false,
      byResponsiblePerson = false,
      openAdd,
      onOpenAdd,
      onCloseAdd,
      onFinish,
      onSelectedStudent,
      onSelectedStudents
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      refetchReset() {
        handleRefresh();
      }
    }));

    const toast = useToast();
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const columnHelper = createColumnHelper<IStudent>();

    const { selectedLocation: globalLocation, locations: globalLocations } =
      useBrandLocation();

    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 400);

    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pageTotal, setPageTotal] = useState(-1);
    const [data, setData] = useState<IStudent[]>([]);

    const [loadData, setLoadData] = useState(true);

    const [selectedStudent, setSelectedStudent] = useState<IStudent | null>(
      null
    );

    const [selectedStudents, setSelectedStudents] = useState<IStudent[]>([]);

    useEffect(() => {
      if (onSelectedStudents) onSelectedStudents(selectedStudents);
    }, [onSelectedStudents, selectedStudents]);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [deletedStudent, setDeletedStudent] = useState<IStudent | null>(null);

    const [disabled, setDisabled] = useState(false);

    // eslint-disable-next-line
    const handleOpenDeleteModal = (
      open: boolean,
      studentData: IStudent | null
    ) => {
      setOpenDeleteModal(open);

      setDeletedStudent(studentData);
    };

    const handleSelectStudent = useCallback(
      (studentData: IStudent | null) => {
        if (!studentData?._id) return;

        setSelectedStudent(studentData);

        if (onSelectedStudent) onSelectedStudent(studentData);
      },
      [onSelectedStudent]
    );

    const handleSelectStudents = (studentData: IStudent) => {
      setSelectedStudents((preStudentData: IStudent[]) => {
        const tempArr = [...preStudentData];

        if (
          tempArr.filter((t: IStudent) => t?._id === studentData?._id)?.length
        ) {
          const index = tempArr.findIndex(
            (t: IStudent) => t?._id === studentData?._id
          );

          if (index >= 0) {
            tempArr?.splice(index, 1);
          } else {
            tempArr?.splice(index, 1, studentData);
          }

          return tempArr;
        } else {
          return [...tempArr, studentData];
        }
      });
    };

    const handleRefresh = useCallback(() => {
      setPageIndex(1);
      setSearch('');

      setSelectedStudents([]);
      setSelectedStudent(null);

      setOpenDeleteModal(false);
      setDeletedStudent(null);

      setDisabled(false);

      fetchData();

      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (openAdd) handleRefresh();
    }, [openAdd, handleRefresh]);

    const handleDeleteStudent = useCallback(async () => {
      if (!id || !deletedStudent?._id) return;

      const payload: { studentIds: string[] } = {
        studentIds: []
      };

      payload.studentIds = [deletedStudent?._id];

      setDisabled(true);

      try {
        await deleteStudentFromRP(id, payload);
        toast.success('Delete student successfully');

        setOpenDeleteModal(false);
        setDeletedStudent(null);

        handleRefresh();
      } catch (error: any) {
        toast.error(error?.response?.data?.message || 'Delete student failed');
      } finally {
        setDisabled(false);
      }

      // eslint-disable-next-line
    }, [deletedStudent?._id, id, handleRefresh]);

    const columnsNorm = useMemo(() => {
      return [
        columnHelper.accessor('firstName', {
          header: () => <span>SURNAME, NAME</span>,
          cell: (info) => {
            return (
              <div className="student-name-wrapper">
                {info.row.original?.cancellations?.length > 0 && (
                  <AppTooltip tooltipText={`Cancellation Pending`}>
                    <HiOutlineExclamationCircle size={20} />
                  </AppTooltip>
                )}
                {formatData(info.row.original?.lastName) +
                  ', ' +
                  formatData(info.row.original?.firstName)}
              </div>
            );
          }
        }),
        columnHelper.accessor('dob', {
          header: () => <span>Age</span>,
          cell: (info) => {
            // Convert the date string to a Date object
            const date = new Date(info.getValue());

            // Get the current date
            const currentDate = new Date();

            // Calculate the number of years and months
            let years = currentDate.getFullYear() - date.getFullYear();
            let months = currentDate.getMonth() - date.getMonth();

            // Adjust the months if the result is negative
            if (months < 0) {
              years -= 1;
              months += 12;
            }
            if (years > 0) {
              return `${years} years ${months} months`;
            } else {
              return `${months} months`;
            }
          }
        }),
        columnHelper.accessor('actualLevel', {
          header: () => <span>LEVEL</span>,
          cell: (info) =>
            formatData(
              info.row.original?.actualLevel?.name ||
                info.row.original?.enrolledLevel?.name
            )
        }),
        columnHelper.accessor('listResponsiblePerson', {
          header: () => <span>RP SURNAME, NAME</span>,
          cell: (info) => {
            if (info.row.original?.listResponsiblePerson?.length) {
              return info.row.original?.listResponsiblePerson.map(
                (person: IStudentListResponsiblePerson, i: number) => {
                  if (i === 0) {
                    return `${formatData(
                      person?.responsiblePersonInfo?.lastName
                    )}, ${formatData(
                      person?.responsiblePersonInfo?.firstName
                    )}`;
                  }
                  return null;
                }
              );
            }

            return formatData(false) + ', ' + formatData(false);
          },
          size: 200
        }),
        columnHelper.accessor('listResponsiblePerson', {
          header: () => <span>RP Mobile</span>,
          cell: (info) => {
            if (info.row.original?.listResponsiblePerson?.length) {
              return info.row.original?.listResponsiblePerson.map(
                (person: IStudentListResponsiblePerson, i: number) => {
                  if (i === 0) {
                    return formatSecretPhoneNumber(
                      person?.responsiblePersonInfo?.phoneNumber
                    );
                  }
                  return null;
                }
              );
            }

            return formatData(false);
          }
        }),
        columnHelper.accessor('locationId', {
          header: () => <span>Location</span>,
          cell: (info) => {
            return (
              <div>
                {formatData(
                  globalLocations.find((e) => e._id === info?.getValue())?.name
                )}
              </div>
            );
          },
          size: 70
        }),
        columnHelper.accessor('enrollmentStatus', {
          header: () => <span>STATUS</span>,
          cell: (info) => (
            <div
              style={{
                backgroundColor:
                  info.getValue() === ENROLLMENT_STATUS.ACTIVE
                    ? '#034ea2'
                    : '#eb5757',
                borderRadius: '10px',
                display: 'inline-block',
                padding: '2px 10px',
                lineHeight: '2',
                marginRight: '10px',
                fontSize: '8px',
                fontStyle: 'normal',
                fontWeight: '500',
                textTransform: 'uppercase'
              }}
            >
              {info.getValue() === ENROLLMENT_STATUS.ACTIVE
                ? STATUS_TYPE_LABEL.ACTIVE
                : STATUS_TYPE_LABEL.INACTIVE}
            </div>
          ),
          size: 50
        }),
        columnHelper.accessor('_id', {
          header: () => (
            <div style={{ cursor: 'pointer', textAlign: 'center' }}>ACTION</div>
          ),
          cell: (info) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_STUDENT}>
                  <div
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate(`/students/${info.getValue()}`)}
                  >
                    <HiOutlineIdentification fontSize={20} />
                  </div>
                </PermissionWrapper>
              </div>
            );
          }
        })
      ];
    }, [columnHelper, navigate, globalLocations]);

    const columnsWithSelect = useMemo(() => {
      return [
        columnHelper.accessor('_id', {
          header: () => <div style={{ textAlign: 'center' }}></div>,
          cell: (info) => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {byNoResponsiblePerson ? (
                  !!info.row.original?.listResponsiblePerson
                    ?.map(
                      ({ responsiblePersonInfo }) => responsiblePersonInfo?._id
                    )
                    ?.filter((RPId) => RPId === id)?.length ? null : (
                    <>
                      <AppCheckbox
                        checked={
                          selectedStudents?.length
                            ? !!selectedStudents?.filter(
                                (item: IStudent) => item._id === info.getValue()
                              )?.[0]?._id
                            : false
                        }
                        onChange={() => handleSelectStudents(info.row.original)}
                      />
                    </>
                  )
                ) : (
                  <>
                    <AppRadio
                      defaultChecked={selectedStudent?._id === info.getValue()}
                      onClick={() =>
                        selectedStudent?._id === info.getValue()
                          ? handleSelectStudent(null)
                          : handleSelectStudent(info.row.original)
                      }
                    />
                  </>
                )}
              </div>
            );
          },
          size: 20
        }),
        columnHelper.accessor('firstName', {
          header: () => <span>SURNAME, NAME</span>,
          cell: (info) =>
            formatData(info.row.original?.lastName) +
            ', ' +
            formatData(info.row.original?.firstName),
          size: 100
        }),
        columnHelper.accessor('dob', {
          header: () => <span>DOB</span>,
          cell: (info) => formatDate(info.getValue()),
          size: 50
        }),
        columnHelper.accessor('levels', {
          header: () => <span>LEVEL</span>,
          cell: (info) => formatData(info.row.original?.levels?.[0]?.shortName),
          size: 50
        }),
        columnHelper.accessor('listResponsiblePerson', {
          header: () => <span>RP SURNAME, NAME</span>,
          cell: (info) => {
            if (info.row.original?.listResponsiblePerson?.length) {
              return info.row.original?.listResponsiblePerson.map(
                (person: IStudentListResponsiblePerson, i: number) => {
                  if (i === 0) {
                    return (
                      formatData(person?.responsiblePersonInfo?.lastName) +
                      ', ' +
                      formatData(person?.responsiblePersonInfo?.firstName)
                    );
                  }
                  return null;
                }
              );
            }

            return formatData(false) + ', ' + formatData(false);
          }
        }),
        columnHelper.accessor('listResponsiblePerson', {
          header: () => <span>RP Mobile</span>,
          cell: (info) => {
            if (info.row.original?.listResponsiblePerson?.length) {
              return info.row.original?.listResponsiblePerson.map(
                (person: IStudentListResponsiblePerson, i: number) => {
                  if (i === 0) {
                    return formatSecretPhoneNumber(
                      person?.responsiblePersonInfo?.phoneNumber
                    );
                  }
                  return null;
                }
              );
            }

            return formatData(false);
          },
          size: 50
        })
      ];
    }, [
      columnHelper,
      byNoResponsiblePerson,
      handleSelectStudent,
      id,
      selectedStudent?._id,
      selectedStudents
    ]);

    const columnsByResponsiblePersonWithSelect = useMemo(() => {
      return [
        columnHelper.accessor('_id', {
          header: () => <div style={{ textAlign: 'center' }}></div>,
          cell: (info) => {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <AppCheckbox
                  checked={
                    selectedStudents?.length
                      ? !!selectedStudents?.filter(
                          (item: IStudent) => item._id === info.getValue()
                        )?.[0]?._id
                      : false
                  }
                  onChange={() => handleSelectStudents(info.row.original)}
                />
              </div>
            );
          },
          size: 20
        }),
        columnHelper.accessor('firstName', {
          header: () => <span>SURNAME, NAME</span>,
          cell: (info) =>
            formatData(info.row.original?.lastName) +
            ', ' +
            formatData(info.row.original?.firstName)
        }),
        columnHelper.accessor('dob', {
          header: () => <span>DOB</span>,
          cell: (info) => formatDate(info.getValue())
        }),
        columnHelper.accessor('aliasName', {
          header: () => <span>LEVEL</span>,
          cell: (info) => formatData(info.row.original?.levels?.[0]?.shortName),
          size: 80
        }),
        columnHelper.accessor('enrollmentStatus', {
          header: () => <span>STATUS</span>,
          cell: (info) => (
            <div
              style={{
                backgroundColor:
                  info.getValue() === ENROLLMENT_STATUS.ACTIVE
                    ? '#034ea2'
                    : '#eb5757',
                borderRadius: '10px',
                display: 'inline-block',
                padding: '2px 10px',
                lineHeight: '2',
                marginRight: '10px',
                fontSize: '8px',
                fontStyle: 'normal',
                fontWeight: '500',
                textTransform: 'uppercase'
              }}
            >
              {info.getValue() === ENROLLMENT_STATUS.ACTIVE
                ? STATUS_TYPE_LABEL.ACTIVE
                : STATUS_TYPE_LABEL.INACTIVE}
            </div>
          ),
          size: 50
        }),
        columnHelper.accessor('_id', {
          header: () => (
            <div style={{ cursor: 'pointer', textAlign: 'center' }}>ACTION</div>
          ),
          cell: (info) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '12px'
                }}
              >
                <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_STUDENT}>
                  <div
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate(`/students/${info.getValue()}`)}
                  >
                    <HiOutlineIdentification fontSize={20} />
                  </div>
                </PermissionWrapper>
              </div>
            );
          },
          size: 50
        })
      ];
    }, [columnHelper, navigate, selectedStudents]);

    const columnsByResponsiblePerson = useMemo(() => {
      return [
        ...[...columnsNorm]?.splice(0, 3),
        columnHelper.accessor('enrolledClassCount', {
          header: () => <span>CLASS ENROLLED</span>,
          cell: (info) => formatData(info.getValue())
        }),
        columnHelper.accessor('_id', {
          header: () => (
            <div style={{ cursor: 'pointer', textAlign: 'center' }}>ACTION</div>
          ),
          cell: (info) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '12px'
                }}
              >
                <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_STUDENT}>
                  <div
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate(`/students/${info.getValue()}`)}
                  >
                    <HiOutlineIdentification fontSize={20} />
                  </div>
                </PermissionWrapper>
              </div>
            );
          },
          size: 50
        })
      ];
    }, [columnHelper, navigate, columnsNorm]);

    const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPageIndex(1);
      setSearch(event.target.value);
    };

    const onClearSearch = () => {
      setSearch('');
    };

    const handleFinish = (newStep?: number) => {
      if (onFinish)
        if (typeof newStep === 'number' && newStep >= 0) {
          if (selectedStudent?._id) {
            onFinish(newStep, selectedStudent);
          } else {
            onFinish(newStep);
          }
        } else {
          if (selectedStudent?._id) {
            onFinish(undefined, selectedStudent);
          } else {
            onFinish();
          }
        }
    };

    const defineColumn = useMemo(() => {
      if (byResponsiblePerson && responsiblePersonId)
        return columnsByResponsiblePersonWithSelect;
      if (byResponsiblePerson && !responsiblePersonId)
        return columnsByResponsiblePerson;
      if (location.pathname === mainRoute) return columnsNorm;
      if (location.pathname !== mainRoute) return columnsWithSelect;

      return columnsNorm;
    }, [
      responsiblePersonId,
      columnsByResponsiblePersonWithSelect,
      columnsByResponsiblePerson,
      columnsNorm,
      columnsWithSelect,
      location.pathname,
      byResponsiblePerson
    ]);

    const fetchData = useCallback(async () => {
      try {
        if (!globalLocation?._id) return;

        const params: IStudentTableFilter = {
          locationId: globalLocation?._id,
          limit: pageSize,
          page: pageIndex,
          keyword: debouncedSearch
        };

        if (byResponsiblePerson) {
          // when 'byResponsiblePerson' === true, then the 'id' is the RP id

          if (id) {
            params.responsiblePersonId = id;
          } else {
            if (responsiblePersonId)
              params.responsiblePersonId = responsiblePersonId;
          }
        }

        setLoadData(true);

        const { data, total } = await getStudentList(params);

        setData(data);
        setPageTotal(total);
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Fetch student list failed'
        );
      } finally {
        setLoadData(false);
      }

      // eslint-disable-next-line
    }, [
      globalLocation,
      pageSize,
      pageIndex,
      debouncedSearch,
      byResponsiblePerson,
      id,
      location.pathname,
      responsiblePersonId
    ]);

    useEffect(() => {
      fetchData();
    }, [fetchData]);

    const TablePure = () => {
      return (
        <>
          <PermissionWrapper permission={PERMISSION.DELETE_STUDENT}>
            <Suspense>
              <DeleteStudent
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
                onConfirm={disabled ? () => {} : handleDeleteStudent}
              />
            </Suspense>
          </PermissionWrapper>

          <div className="student">
            <AppCard>
              <div className="student__table">
                <div className="student__table-header">
                  <h2 className="student__table-header-title">Students</h2>
                  <div className="student__table-header-search">
                    <AppInputSearch
                      type="text"
                      value={search}
                      onChange={onChangeSearch}
                      placeholder="Surname, name, member id, mobile or email"
                      onClearSearch={onClearSearch}
                      startIcon={<HiMagnifyingGlass />}
                    />

                    <PermissionWrapper permission={PERMISSION.CREATE_STUDENT}>
                      {!responsiblePersonId ? (
                        <>
                          <AppButton
                            variant="primary"
                            buttonSize="small"
                            type="submit"
                            onClick={
                              byResponsiblePerson
                                ? () => (onOpenAdd ? onOpenAdd() : null)
                                : location.pathname === mainRoute
                                ? () => (onOpenAdd ? onOpenAdd() : null)
                                : () => handleFinish(1)
                            }
                          >
                            <div className="plusIcon">
                              <GoPlus
                                style={{ width: '22px', height: '22px' }}
                              />
                            </div>
                            <p>Student</p>
                          </AppButton>
                        </>
                      ) : null}
                    </PermissionWrapper>
                  </div>
                </div>

                <PermissionWrapper permission={PERMISSION.LIST_STUDENT}>
                  <div className="student__table-content">
                    <AppTable
                      loading={loadData}
                      data={data}
                      columns={defineColumn}
                      pagination={{
                        index: pageIndex,
                        size: pageSize,
                        total: pageTotal
                      }}
                      onChangePage={(index: number, size: number) => {
                        setPageIndex(index);
                        setPageSize(size);
                      }}
                    />
                  </div>
                </PermissionWrapper>
              </div>
            </AppCard>
          </div>
        </>
      );
    };

    if (location?.pathname === mainRoute) {
      return (
        <>
          <AppBreadCrumb items={[{ name: 'Students', href: '/students' }]} />
          <div className="layoutContainer">
            <PermissionWrapper permission={PERMISSION.GET_STUDENT_STATISTIC}>
              <Suspense fallback={<AppLoadingContainer />}>
                <StudentStatistic />
              </Suspense>
            </PermissionWrapper>

            {TablePure()}
          </div>
        </>
      );
    }

    return <>{TablePure()}</>;
  }
);

export default memo(StudentList);
