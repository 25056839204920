import React, { useMemo } from 'react';
import AppCheckbox from '../../components/AppCheckbox';
import { HiChevronDown } from 'react-icons/hi';
import { IOption } from '../../interfaces';
import { GoXCircleFill } from 'react-icons/go';
import { fuzzySearch } from '../../helpers/search.helper';
import './desktop.scss';

type Size = 'large' | 'small';

interface IAppSelectionProps {
  label: string;
  placeholder?: string;
  options: Array<IOption>;
  onSelect?: (value: string) => void;
  onSelectAll?: () => void;
  selectedIds?: Array<string>;
  isSearch?: boolean;
  size?: Size;
  message?: {
    type: 'error' | 'success' | 'caption';
    text: string;
  };
  disabled?: boolean;
  selectedIdsCannotBeRemoved?: Array<string>;
}

const AppSelection = (props: IAppSelectionProps) => {
  const {
    label,
    placeholder,
    options,
    onSelect,
    onSelectAll,
    selectedIds,
    message,
    isSearch = false,
    size = 'small',
    disabled,
    selectedIdsCannotBeRemoved = []
  } = props;

  const [search, setSearch] = React.useState<string>('');

  const dropdownRef = React.useRef<any>(null);
  const inputRef = React.useRef<any>(null);

  const [open, setOpen] = React.useState<boolean>(false);

  const handleClearSearch = React.useCallback(() => {
    setSearch('');
  }, []);

  const handleChangeSearch = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  const __isCheckAll = React.useMemo((): boolean => {
    return options.length === selectedIds?.length;
  }, [selectedIds, options]);

  const __renderClearSearch = React.useMemo((): JSX.Element | null => {
    if (search === '') return null;
    return <GoXCircleFill size={16} onClick={handleClearSearch} />;
  }, [handleClearSearch, search]);

  const __filteredOptions = React.useMemo((): Array<IOption> => {
    return fuzzySearch(search, options, 'label');
  }, [search, options]);

  const onToggle = () => {
    if (disabled) return;
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // Close popper if click outside the popper
    const handleClickOutside = (event: MouseEvent) => {
      // Click at vertical button to close
      if (inputRef.current && inputRef.current.contains(event.target)) {
        return;
      }
      // Handle click outside to close pop up
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef, dropdownRef]);

  const __renderMessage = useMemo(() => {
    if (!message) return null;
    return (
      <p
        className={`c__selection-message c__selection-message-${message.type}`}
      >
        {message.text}
      </p>
    );
  }, [message]);

  return (
    <div className="c__selection">
      <div className="c__selectionMain">
        <span className="c__selectionMainLabel">{label}</span>
        <div
          ref={inputRef}
          className={`c__selectionMainInput c__selectionMainInput--${size} ${
            message?.text ? `c__selectionMainInput-border-${message?.type}` : ''
          } ${disabled ? ' c__selectionMainInput--disabled' : ''}`}
          onClick={onToggle}
        >
          <p>{placeholder ?? `Select option`}</p>
          <HiChevronDown size={16} />
        </div>
        {open && (
          <div ref={dropdownRef} className={'c__selectionMainDropdown'}>
            {isSearch && (
              <div className="c__selectionMainDropdownSearch">
                <input
                  type="text"
                  value={search}
                  onChange={handleChangeSearch}
                  placeholder="Search..."
                />
                {__renderClearSearch}
              </div>
            )}

            {!!onSelectAll && (
              <div
                className={`c__selectionMainDropdownItem ${
                  selectedIdsCannotBeRemoved.length === __filteredOptions.length
                    ? 'c__selectionMainDropdownItem--disabled'
                    : ''
                }`}
              >
                <AppCheckbox
                  label="Select all"
                  checked={__isCheckAll}
                  onChange={onSelectAll}
                />
              </div>
            )}
            {__filteredOptions.map((option) => {
              return (
                <div
                  key={option.value}
                  className={`c__selectionMainDropdownItem ${
                    selectedIdsCannotBeRemoved.includes(option.value.toString())
                      ? 'c__selectionMainDropdownItem--disabled'
                      : ''
                  }`}
                >
                  <AppCheckbox
                    label={option.label}
                    value={option.value}
                    checked={selectedIds?.includes(option.value.toString())}
                    onChange={
                      onSelect
                        ? (event: React.ChangeEvent<HTMLInputElement>) =>
                            onSelect(event.target.value)
                        : undefined
                    }
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
      {__renderMessage}
    </div>
  );
};

export default React.memo(AppSelection);
