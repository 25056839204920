import { RollCallCheckInDto, RollCallEmergencyDto } from 'DTOs/rollCall.dto';
import API from './API';
const ROLL_CALL_ENDPOINT = '/roll-call';

export const getGroupRollCall = (
  page: number,
  limit: number,
  locationId: string
) => {
  return API.get(`${ROLL_CALL_ENDPOINT}/group-roll-call`, {
    page,
    limit,
    locationId
  });
};

export const getDetailGroupRollCall = (
  ids: Array<string>,
  levelIds: string,
  areas: string
) => {
  return API.get(`${ROLL_CALL_ENDPOINT}/detail-group-roll-call`, {
    ids: ids.join(','),
    levelIds: levelIds ? levelIds : null,
    areas: areas ? areas : null
  });
};

export const getStudentsByRollCall = (
  id: string,
  keyword?: string,
  limit?: number
) => {
  return API.get(`${ROLL_CALL_ENDPOINT}/${id}/students`, { keyword, limit });
};

export const rollCallCheckIn = (payload: RollCallCheckInDto) => {
  return API.post(`${ROLL_CALL_ENDPOINT}/check-in`, {
    body: payload
  });
};

export const rollCallAttended = (payload: RollCallCheckInDto) => {
  return API.post(`${ROLL_CALL_ENDPOINT}/attended`, {
    body: payload
  });
};

export const rollCallEvacuated = (payload: RollCallCheckInDto) => {
  return API.post(`${ROLL_CALL_ENDPOINT}/evacuated`, {
    body: payload
  });
};

export const rollCallEmergency = (payload: RollCallEmergencyDto) => {
  return API.post(`${ROLL_CALL_ENDPOINT}/emergency`, {
    body: payload
  });
};
