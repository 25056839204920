import { memo, useEffect, useMemo, useState } from 'react';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import NotificationList from 'components/notifications/NotificationList';
import BulkManagementCreditList from './credits/BulkManagementCreditList';
import BulkManagementClassList from './classes/BulkManagementClassList';
import BulkManagementBillingList from './billings/BulkManagementBillingList';
import { useSearchParams } from 'react-router-dom';
import { PATH, PATHS, SEARCH_PARAMS } from './constant';
import NotificationTemplateList from './notificationTemplates/NotificationTemplateList';

import './desktop.scss';

interface TAB {
  name: string;
  component: JSX.Element;
  path: PATH;
}

const tabs: Array<TAB> = [
  {
    name: 'Notifications',
    component: <NotificationList />,
    path: PATH.NOTIFICATIONS
  },
  {
    name: 'Credits',
    component: <BulkManagementCreditList />,
    path: PATH.CREDITS
  },
  {
    name: 'Classes',
    component: <BulkManagementClassList />,
    path: PATH.CLASSES
  },
  {
    name: 'Billings',
    component: <BulkManagementBillingList />,
    path: PATH.BILLINGS
  },
  {
    name: 'Notification Template',
    component: <NotificationTemplateList />,
    path: PATH.NOTIFICATION_TEMPLATE
  }
];

const BulkManagement = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [activePath, setActivePath] = useState('');

  const handleChangeTab = (path: string) => {
    setActivePath(path);
    searchParams.set(SEARCH_PARAMS, `${path}`);
    setSearchParams(searchParams);
  };

  const params = searchParams.get(SEARCH_PARAMS);

  useEffect(() => {
    if (params && PATHS.includes(params as PATH)) {
      setActivePath(params);
    } else {
      setActivePath(PATH.NOTIFICATIONS);
      searchParams.set(SEARCH_PARAMS, PATH.NOTIFICATIONS);
      setSearchParams(searchParams, { replace: true });
    }
  }, [params, searchParams, setSearchParams, setActivePath]);

  const __renderActiveTab: JSX.Element = useMemo(() => {
    return tabs.find((tab) => tab.path === activePath)?.component ?? <></>;
  }, [activePath]);

  return (
    <main className="bulkManagementPage">
      {/* MAIN */}
      <AppBreadCrumb
        items={[{ name: 'Bulk Management', href: '/bulk-management' }]}
      />
      <div className="layoutContainer bulkManagementPage__wrapper">
        <section className="bulkManagementPage__tabs">
          {tabs.map((tab) => {
            return (
              <div
                key={tab.name}
                className={`bulkManagementPage__tabs__item ${
                  tab.path === activePath ? 'active' : ''
                }`}
                onClick={() => handleChangeTab(tab.path)}
              >
                {tab.name}
              </div>
            );
          })}
        </section>
        <section className="bulkManagementPage__tabPanel">
          {__renderActiveTab}
        </section>
      </div>
    </main>
  );
};

export default memo(BulkManagement);
