import { ILocation } from 'common/interfaces/location.interface';
import React, { useMemo } from 'react';
import './desktop.scss';
import { ILevelBreakdown } from 'common/interfaces/levelBreakdown.interface';

interface Props {
  locations: ILocation[] | ILevelBreakdown[];
}
const ListInfo: React.FC<Props> = ({ locations = [] }: Props) => {
  const [openRestLocations, setOpenRestLocations] = React.useState(false);
  const locationsShow = useMemo(() => {
    return locations?.slice(0, 5);
  }, [locations]);

  const restLocationsText = useMemo(() => {
    const rest = locations?.slice(5);
    return rest?.length > 0 ? `+${rest?.length}` : '';
  }, [locations]);

  const restLocations = useMemo(() => {
    return locations?.slice(5);
  }, [locations]);

  const handleToggleRestLocations = () => {
    setOpenRestLocations(!openRestLocations);
  };

  return (
    <div className="location-list-wrapper">
      {locationsShow.map((location) => (
        <div className="location-item" key={location._id}>
          {location.shortName || location.name}
        </div>
      ))}
      {restLocationsText.length > 0 && (
        <div
          className="location-item rest-location-text"
          onClick={handleToggleRestLocations}
        >
          {restLocationsText}
          {openRestLocations && (
            <div className="rest-locations-popup-wrapper">
              {restLocations?.map((location) => (
                <div className="rest-locations-popup-item" key={location._id}>
                  {location.shortName || location.name}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ListInfo;
