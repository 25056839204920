import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppCard, { AppCardContent } from 'common/components/AppCard';
import AppButton from 'common/components/AppButton';
import { useStepController } from 'context/StepControllerContext';
import { IClass } from 'common/interfaces/class.interface';
import { IClassBooked } from 'common/interfaces/bookingClass.interface';
import { FLOW_ENUM } from 'common/enums/step.enum';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import { HiOutlineTrash } from 'react-icons/hi';
import dayjs from 'dayjs';
import { ISession } from 'common/interfaces/session.interface';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';

interface ISummaryInformationProps {
  data: IClass | null;
  onDeleteClass: () => void;
  lessonData: ISession | null;
}

const SummaryInformation = (props: ISummaryInformationProps) => {
  const { data, onDeleteClass, lessonData } = props;
  const { dataSet } = useStepController();
  const navigate = useNavigate();

  const isShowBookMe = useCallback((): boolean => {
    return !!(
      data?.session &&
      dayjs(data?.session?.startTime).isAfter(dayjs()) &&
      data?.capacity - (data?.occupied || 0) >= 1
    );
  }, [data]);

  const handleBooking = useCallback(() => {
    if (
      dataSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
      (dataSet.step === 0 || dataSet.step === 1)
    ) {
      navigate(`/classes`);

      dataSet.handleClassBooking(data as IClassBooked);
      dataSet?.handleAddStepForward();
    }
  }, [navigate, dataSet, data]);

  useEffect(() => {
    if (dataSet?.flow === FLOW_ENUM.INIT && isShowBookMe()) {
      dataSet.setFlow(FLOW_ENUM.CLASS_BOOKING);
    }
  }, [dataSet, isShowBookMe]);

  const __renderContent = useCallback((): React.ReactNode => {
    return (
      <div>
        <div className="summary-info_name">
          {formatData(data?.template.name)}
        </div>
        <div className="summary-info_wrap">
          <div className="summary-info_wrap-content">
            <div>
              <div
                className={`summary-info-status summary-info-status-${
                  data?.active ? 'active' : 'inactive'
                }`}
              >
                {data?.active ? 'active' : 'inactive'}
              </div>
            </div>
            <div className="summary-info-item">
              <span className="summary-info-item_label">Instructor: </span>
              <span className="summary-info-item_value">
                {formatData(
                  lessonData?.instructor?.firstName ||
                    data?.instructor?.firstName
                )}
              </span>
            </div>
            <div className="summary-info-item">
              <span className="summary-info-item_label">Level: </span>
              <span className="summary-info-item_value">
                {formatData(data?.levelBreakdown?.name || 'All Levels')}
              </span>
            </div>
            <div className="summary-info-item">
              <span className="summary-info-item_label">Capacity: </span>
              <span className="summary-info-item_value">
                {lessonData
                  ? `${formatData(lessonData?.occupied)}/${formatData(
                      lessonData?.capacity
                    )}`
                  : `${formatData(data?.occupied)}/${formatData(
                      data?.capacity
                    )}`}
              </span>
            </div>
            <div className="summary-info-item">
              <span className="summary-info-item_label">Area: </span>
              <span className="summary-info-item_value">
                {formatData(lessonData?.area || data?.area)}
              </span>
            </div>
            <div className="summary-info-item">
              <span className="summary-info-item_label">Lesson Date: </span>
              <span className="summary-info-item_value">
                {formatDate(lessonData?.startTime || data?.session?.startTime)}
              </span>
            </div>
          </div>

          <div className="summary-info_button">
            {isShowBookMe() && (
              <PermissionWrapper permission={PERMISSION.BOOKING_CLASS}>
                <AppButton
                  variant="secondary"
                  buttonSize="small"
                  onClick={handleBooking}
                >
                  ADD STUDENT
                </AppButton>
              </PermissionWrapper>
            )}
            {data?.occupied === 0 && (
              <PermissionWrapper permission={PERMISSION.DELETE_CLASS}>
                <AppButton
                  variant="secondary"
                  buttonSize="small"
                  onClick={onDeleteClass}
                >
                  <HiOutlineTrash fontSize={22} />
                </AppButton>
              </PermissionWrapper>
            )}
          </div>
        </div>
      </div>
    );
  }, [data, isShowBookMe, handleBooking, onDeleteClass, lessonData]);

  return (
    <section className="summary-info">
      <AppCard>
        <AppCardContent className="card-content">
          {__renderContent()}
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default SummaryInformation;
