import React from 'react';
import AppButton from 'common/components/AppButton';
import { HiXMark } from 'react-icons/hi2';
import { CreateAssessmentScoreDTO } from 'DTOs/levelBreakdown.dto';
import { IAssessmentScore } from 'common/interfaces/assessment.interface';
import { formatData } from 'common/helpers/dataFormat.helper';
import './desktop.scss';

interface ILevelAssessmentScoreProps {
  assessmentScore: CreateAssessmentScoreDTO | IAssessmentScore;
  onChangeAssessmentScore: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAddAssessmentScoreDetail: () => void;
  onRemoveAssessmentScoreDetail: (position: number) => void;
  onChangeAssessmentScoreDetail: (
    event: React.ChangeEvent<HTMLInputElement>,
    position: number
  ) => void;
}

const LevelAssessmentScore = (props: ILevelAssessmentScoreProps) => {
  const {
    assessmentScore,
    onChangeAssessmentScore,
    onAddAssessmentScoreDetail,
    onRemoveAssessmentScoreDetail,
    onChangeAssessmentScoreDetail
  } = props;

  return (
    <div className="level__assessmentScore">
      <h2 className="level__assessmentScore-title">Assessment score</h2>

      <div className="level__assessmentScore-score">
        <p className="level__assessmentScore-score-title">Score</p>
        <div className="level__assessmentScore-score-form">
          <input
            className="level__assessmentScore-score-form-input"
            type="number"
            min={1}
            name="maxScore"
            value={assessmentScore.maxScore}
            onChange={onChangeAssessmentScore}
          />
          <div className="level__assessmentScore-score-form-icon">
            <img src={assessmentScore.icon} alt="default-icon" />
          </div>
        </div>
      </div>

      <div className="level__assessmentScore-scoreDetails">
        {assessmentScore?.assessmentScoreDetails?.length > 0 &&
          assessmentScore.assessmentScoreDetails.map(
            (assessmentScoreDetail, index: number) => {
              return (
                <div
                  key={index}
                  className="level__assessmentScore-scoreDetails-form"
                >
                  {index > 2 && (
                    <div
                      className="level__assessmentScore-scoreDetails-form-remove"
                      onClick={() => onRemoveAssessmentScoreDetail(index)}
                    >
                      <HiXMark size={22} />
                    </div>
                  )}
                  <input
                    className="level__assessmentScore-scoreDetails-form-input"
                    type="text"
                    min={1}
                    name="fieldName"
                    value={assessmentScoreDetail.fieldName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeAssessmentScoreDetail(event, index)
                    }
                  />
                  <input
                    className="level__assessmentScore-scoreDetails-form-input"
                    type="number"
                    min={1}
                    max={assessmentScore.maxScore}
                    name="fieldValue"
                    value={assessmentScoreDetail.fieldValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      onChangeAssessmentScoreDetail(event, index)
                    }
                  />
                  <div className="level__assessmentScore-scoreDetails-form-of">
                    Of
                  </div>
                  <div className="level__assessmentScore-scoreDetails-form-maxScore line-clamp-1">
                    {formatData(assessmentScore.maxScore)}
                  </div>
                </div>
              );
            }
          )}
      </div>

      <div className="level__assessmentScore-actions">
        <AppButton
          buttonSize="small"
          variant="secondary"
          className="level__assessmentScore-actions-addMore"
          onClick={onAddAssessmentScoreDetail}
        >
          Add more
        </AppButton>
      </div>
    </div>
  );
};

export default LevelAssessmentScore;
