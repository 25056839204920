import { CreateColorSetting } from 'DTOs/dashboard.dto';
import API from './API';
const FILTER_DASHBOARD_API_ENDPOINT = '/filter-dashboard';
const COLOR_SETTING_API_ENDPOINT = '/color-setting-dashboard';
const SCHEDULE_API_ENDPOINT = '/schedules';

// start filter dashboard
export const getFilterList = async (params: {
  page: number;
  limit: number;
  filter?: string;
}) => {
  const response = await API.get(FILTER_DASHBOARD_API_ENDPOINT, params);
  return response.data.data;
};
export const createFilterDashboard = async (payload: any) => {
  const response = await API.post(`${FILTER_DASHBOARD_API_ENDPOINT}`, {
    body: payload
  });
  return response.data;
};
export const deleteFilterDashboard = async (id: string) => {
  const response = await API.delete(`${FILTER_DASHBOARD_API_ENDPOINT}/${id}`);
  return response.data;
};
export const getFilterDashboardDetail = async (id: string) => {
  const response = await API.get(`${FILTER_DASHBOARD_API_ENDPOINT}/${id}`);
  return response.data.data;
};
// end filter dashboard

// start color setting
export const getColorSettingList = async ({
  page = 1,
  limit = 100,
  filter
}: {
  page: number;
  limit: number;
  filter?: string;
}) => {
  const response = await API.get(COLOR_SETTING_API_ENDPOINT, {
    page,
    limit,
    filter
  });
  return response.data.data;
};
export const createColorSetting = async (payload: CreateColorSetting) => {
  const response = await API.post(`${COLOR_SETTING_API_ENDPOINT}`, {
    body: payload
  });
  return response.data;
};

export const updateColorSetting = async (
  id: string,
  payload: CreateColorSetting
) => {
  const response = await API.put(`${COLOR_SETTING_API_ENDPOINT}/${id}`, {
    body: payload
  });
  return response.data;
};

export const resetColorSetting = async () => {
  const response = await API.post(
    `${COLOR_SETTING_API_ENDPOINT}/reset-default`
  );
  return response.data;
};
// end color setting

// start schedule calendar
export const getScheduleCalendar = async (payload: {}) => {
  const response = await API.post(`${SCHEDULE_API_ENDPOINT}/filter`, {
    body: payload
  });
  return response.data.data;
};

export const searchScheduleCalendar = async (payload: {}) => {
  const response = await API.post(`${SCHEDULE_API_ENDPOINT}/search`, {
    body: payload
  });
  return response.data.data;
};
// end schedule calendar

export const getStudentsInClass = async (
  classId: string,
  page: number = 1,
  limit: number = 5,
  keyword?: string
) => {
  const response = await API.get(
    `${SCHEDULE_API_ENDPOINT}/list-student/${classId}?page=${page}&limit=${limit}&keyword=${keyword}`
  );
  return response.data.data;
};

export const getClassSessions = async (classIds: string[]) => {
  const response = await API.get(
    `${SCHEDULE_API_ENDPOINT}/booking?classIds=${classIds.join(',')}`
  );
  return response.data.data;
};
