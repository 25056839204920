import AppModal, {
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import './desktop.scss';

interface Props {
  open: boolean;
  onClose: () => void;
}
const UpdateSuccessfullyModal: React.FC<Props> = ({ open, onClose }: Props) => {
  return (
    <div className="successfullyModal">
      <AppModal open={open} onClose={onClose}>
        <img
          src="/icons/done.svg"
          alt=""
        />
        <AppModalTitle>Successfully</AppModalTitle>
        <AppModalContent>
          Your bulk update has been processed successfully
        </AppModalContent>
      </AppModal>
    </div>
  );
};
export default UpdateSuccessfullyModal;
