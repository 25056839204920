import React, { useCallback, useEffect, useState } from 'react';
import AppCard from 'common/components/AppCard';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppInputSearch from 'common/components/AppInputSearch';
import AppButton from 'common/components/AppButton';
import AppTable from 'common/components/AppTable';
import useDebounce from 'common/hooks/useDebounce';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { getClassTemplates } from 'services/classTemplate.service';
import ClassTemplateAddForm from './components/ClassTemplateAddForm';
import { IClassTemplate } from 'common/interfaces/classTemplate.interface';
import AppToggle from 'common/components/AppToggle';
import ClassTemplateDeleteModal from './components/ClassTemplateDeleteModal';
import { useBrandLocation } from 'context/BrandLocationContext';
import {
  HiOutlineIdentification,
  HiOutlineTrash,
  HiPlus
} from 'react-icons/hi';
import {
  HiMagnifyingGlass
} from 'react-icons/hi2';
import { useAuth } from 'context/AuthContext';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import './desktop.scss';
import { formatData } from 'common/helpers/dataFormat.helper';

const ClassTemplateList = () => {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<IClassTemplate>();

  const { hasPermission } = useAuth();

  const columns = [
    columnHelper.accessor('name', {
      header: () => <span>NAME</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('duration', {
      header: () => <span>duration</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('levelBreakdown.name', {
      header: () => <span>Level</span>,
      cell: (info) => formatData(info.getValue() || 'All Levels')
    }),
    columnHelper.accessor('terms', {
      header: () => <span>Session</span>,
      cell: (info) =>
        formatData(
          info
            .getValue()
            .map((t) => t?.termDetail?.name)
            .join(', ')
        )
    }),
    columnHelper.accessor('classInUsed', {
      header: () => <span>class in used</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('active', {
      header: () => <span>active</span>,
      cell: (info) => {
        return (
          <div>
            <AppToggle value={info.getValue()} disabled />
          </div>
        );
      }
    }),
    columnHelper.accessor('_id', {
      header: () => (
        <span
          style={{
            display: 'block',
            textAlign: 'center'
          }}
        >
          Action
        </span>
      ),
      cell: (info) => {
        return (
          <div className="buttonGroupsContainer">
            <div className="buttonGroups">
              <PermissionWrapper
                permission={PERMISSION.VIEW_DETAIL_CLASS_TEMPLATE}
              >
                <div
                  onClick={() => navigate(`/class-template/${info.getValue()}`)}
                >
                  <HiOutlineIdentification />
                </div>
              </PermissionWrapper>
              {info.row.original?.classInUsed === 0 && (
                <PermissionWrapper
                  permission={PERMISSION.DELETE_CLASS_TEMPLATE}
                >
                  <div
                    onClick={() =>
                      onChooseDeleteClassTemplate(info.row.original)
                    }
                  >
                    <HiOutlineTrash />
                  </div>
                </PermissionWrapper>
              )}
            </div>
          </div>
        );
      }
    })
  ];

  const { selectedLocation: globalLocation } = useBrandLocation();

  const [loading, setLoading] = useState<boolean>(true);

  const [data, setData] = useState<Array<IClassTemplate>>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageTotal, setPageTotal] = useState<number>(-1);

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search);

  const [selectedClassTemplate, setSelectedClassTemplate] =
    useState<IClassTemplate | null>(null);

  const [isOpenAddForm, setIsOpenAddForm] = useState<boolean>(false);

  // ADD SECTION
  const onAddSuccess = async () => {
    onCloseAddForm();
    setSearch('');
    setPageIndex(1);
    fetchData();
  };
  const onOpenAddForm = () => {
    setIsOpenAddForm(true);
  };
  const onCloseAddForm = () => {
    setIsOpenAddForm(false);
  };
  //  END - ADD SECTION

  // DELETE SECTION
  const onChooseDeleteClassTemplate = (classTemplate: IClassTemplate) => {
    setSelectedClassTemplate(classTemplate);
  };
  const onCancelDeleteClassTemplate = () => {
    setSelectedClassTemplate(null);
  };
  const onDeleteSuccess = async () => {
    setSelectedClassTemplate(null);
    setSearch('');
    setPageIndex(1);
    fetchData();
  };
  // END - DELETE SECTION

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getClassTemplates(
        globalLocation?._id || '',
        pageIndex,
        pageSize,
        search
      );
      setData(result.data.data.data);
      setPageTotal(result.data.data.total);
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [globalLocation?._id, debouncedSearch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setPageIndex(1);
  }, [debouncedSearch, globalLocation?._id]);

  return (
    <main className="classTemplatePage">
      {/* ADD FORM */}
      <PermissionWrapper permission={PERMISSION.CREATE_CLASS_TEMPLATE}>
        {isOpenAddForm && (
          <ClassTemplateAddForm
            open={isOpenAddForm}
            onClose={onCloseAddForm}
            onSuccess={onAddSuccess}
          />
        )}
      </PermissionWrapper>

      {/* DELETE */}
      {!!selectedClassTemplate && (
        <PermissionWrapper permission={PERMISSION.DELETE_CLASS_TEMPLATE}>
          <ClassTemplateDeleteModal
            classTemplate={selectedClassTemplate}
            onClose={onCancelDeleteClassTemplate}
            onSuccess={onDeleteSuccess}
          />
        </PermissionWrapper>
      )}

      {/* MAIN */}
      <AppBreadCrumb
        items={[{ name: 'Class Template', href: '/class-template' }]}
      />
      <div className="layoutContainer classTemplate">
        <AppCard>
          <div className="classTemplate__table">
            <div className="classTemplate__table-header">
              <h2 className="classTemplate__table-header-title">
                Class templates
              </h2>
              <div className="classTemplate__table-header-search">
                <AppInputSearch
                  type="text"
                  value={search}
                  onChange={onChangeSearch}
                  placeholder="Name"
                  onClearSearch={onClearSearch}
                  startIcon={<HiMagnifyingGlass />}
                />
                <PermissionWrapper
                  permission={PERMISSION.CREATE_CLASS_TEMPLATE}
                >
                  <AppButton
                    variant="primary"
                    buttonSize="small"
                    type="submit"
                    onClick={onOpenAddForm}
                  >
                    <div className="plusIcon">
                      <HiPlus />
                    </div>
                    <p>Template</p>
                  </AppButton>
                </PermissionWrapper>
              </div>
            </div>
            <div className="classTemplate__table-content">
              <AppTable
                data={data}
                columns={columns}
                pagination={{
                  index: pageIndex,
                  size: pageSize,
                  total: pageTotal
                }}
                onChangePage={(index: number, size: number) => {
                  setPageIndex(index);
                  setPageSize(size);
                }}
                loading={loading}
                columnVisibility={
                  !hasPermission(PERMISSION.VIEW_DETAIL_CLASS_TEMPLATE) &&
                  !hasPermission(PERMISSION.DELETE_CLASS_TEMPLATE)
                    ? { _id: false }
                    : undefined
                }
              />
            </div>
          </div>
        </AppCard>
      </div>
    </main>
  );
};

export default ClassTemplateList;
