export const INSTRUCTOR_PERMISSIONS = [
  'view-detail-credential',
  'view-detail-instructor',
  // 'list-student',
  'list-credential',
  // 'create-credential',
  // 'update-credential',
  'list-class',
  'view-detail-class',
  'list-filter',
  'view-detail-filter',
  'create-filter',
  'update-filter',
  'list-color-setting',
  'view-detail-color-setting',
  'create-color-setting',
  'update-color-setting',
  'delete-color-setting',
  // 'list-responsible-person',
  'list-duplicated-class',
  'list-duplicated-schedule',
  'filter-group-class',
  'list-class-of-group-dashboard',
  'reset-default-setting',
  'search-class',
  'list-upcoming-class',
  'list-student-checkin',
  'view-detail-level-breakdown',
  'list-level-breakdown',
  'list-report',
  'list-trial',
  'view-booking-schedule',
  'list-student-booked-class',
  'create-assessment-result',
  'list-student-by-class',
  'view-assessment-history',
  'create-batch-assessment-result',
  'list-assessment-result',
  'list-assessment-by-student',
  'get-current-class',
  'list-assessment-history',
  'create-absence',
  // 'list-absence',
  'get-student-enrollment',
  'list-term',
  'detail-term',
  'list-today-schedule',
  'list-class-by-student',
  'get-level-student',
  'list-attendance-history',
  'delete-filter',
  'list-student-level-by-responsible-person',
  'list-enrollment-by-responsible-person',
  'list-attendance-history-by-responsible-person',
  'list-class-by-responsible-person',
  'list-class-template-by-term',
  'time-table-by-instructor',
  'list-student-roll-call',
  'list-location',
  'list-location-area',
  'list-available-area',
  'list-location-door',
  'list-instructor',
  // 'list-notification',
];
