import React, { useCallback, useEffect, useState } from 'react';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import RollCallByTime from './RollCallByTime';
import '../desktop.scss';
import { useParams, useSearchParams } from 'react-router-dom';
import { ROLL_CALL_TYPE } from 'common/enums/rollCall.enum';
import { getDetailGroupRollCall } from 'services/rollCalls.service';
import { groupBy } from 'lodash';
import { IRollCallDetail } from 'common/interfaces/rollCall.interface';
import { useToast } from 'context/ToastContext';

interface Props {}
const RollCallDetail: React.FC<Props> = () => {
  const params = useParams();
  const [searchParam] = useSearchParams();
  const toast = useToast();

  const [isEmergency] = useState<boolean>(
    searchParam.get('type') === ROLL_CALL_TYPE.EMERGENCY
  );
  const [groupRolCall, setGroupRollCall] = useState<IRollCallDetail[][]>([]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getDetailGroupRollCall(
        (params.id || '')?.split(','),
        '',
        ''
      );
      const rollCalls = data.data.map((item: IRollCallDetail) => {
        return {
          ...item,
          timeRollCall: item.startTime + item.endTime
        };
      });
      const groupByTime = groupBy(rollCalls, 'timeRollCall');
      setGroupRollCall(Object.values(groupByTime));
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to load list roll call!'
      );
    }
    // eslint-disable-next-line
  }, [params.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <main className="rollCallPage">
      <AppBreadCrumb
        items={[
          {
            name: isEmergency ? 'Emergency Roll Calls' : 'Roll Calls',
            href: '/roll-calls'
          }
        ]}
      />
      <div className="layoutContainer rollCall">
        {groupRolCall.map((rollCalls: IRollCallDetail[], index: number) => (
          <RollCallByTime rollCalls={rollCalls} key={index} />
        ))}
      </div>
    </main>
  );
};
export default RollCallDetail;
