export enum ASSESSMENT_STATUS {
  IDLE = '',
  NOT_ATTEMPTED = 'not-attempted',
  ACHIEVED = 'achieved',
  DEVELOPING = 'developing'
}

export enum ASSESSMENT_REVIEW_STATUS {
  IDLE = '',
  APPROVAL_PENDING = 'approval-pending',
  APPROVED = 'approved',
  REASSESS = 'reassess'
}
