import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  Fragment
} from 'react';
import { useToast } from 'context/ToastContext';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import AppCheckbox from 'common/components/AppCheckbox';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import {
  getFirebaseNotificationSettings,
  updateNotificationSettings
} from 'services/firebase.service';
import { IFirebaseNotificationSetting } from 'common/interfaces/firebase.interface';
import SettingItem from './SettingItem';
import { useForm, useWatch } from 'react-hook-form';
import { getRoleList } from 'services/roles.service';
import { IRole } from 'common/interfaces/hierarchy.interface';
import {
  FormUpdateNotificationSettings,
  UpdateFirebaseNotificationSettingDTO
} from 'DTOs/firebase.dto';
import AppButton from 'common/components/AppButton';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';

import './desktop.scss';

export interface RefHandle {
  refetchReset: () => void;
}

interface Props {
  asPopup?: boolean;
  emitTotal?: (total: number) => void;
  closePopup?: () => void;
}

const NotificationList = forwardRef<RefHandle, Props>(
  ({ asPopup, emitTotal, closePopup }, ref) => {
    useImperativeHandle(ref, () => ({
      refetchReset() {
        handleRefresh();
      }
    }));

    const toast = useToast();

    const [initLoad, setInitLoad] = useState(true);
    const [roles, setRoles] = useState<IRole[]>([]);

    const [isCheckAllEmail, setCheckAllEmail] = useState(false);
    const [isCheckAllPushNotification, setCheckAllPushNotification] =
      useState(false);

    const { setValue, reset, control, getValues } = useForm<{
      settingForm: FormUpdateNotificationSettings[];
    }>();

    const form = useWatch({ name: 'settingForm', control });

    const fetchData = useCallback(async () => {
      try {
        const { data } = await getFirebaseNotificationSettings();
        const result = await getRoleList('');

        reset({
          settingForm: data?.data?.map((dt: IFirebaseNotificationSetting) => {
            return {
              _id: dt?._id,
              isEmail: dt?.isEmail,
              eventName: dt?.eventName,
              isPushNotification: dt?.isPushNotification,
              userGroupIds: dt?.userGroupIds?.join(','),
              ...(typeof dt?.weekDay === 'number' && {
                weekDay: dt?.weekDay
              })
            };
          })
        });

        setRoles(result);

        setInitLoad(false);
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message ||
            'Fail to fetch notification settings'
        );
      }
      // eslint-disable-next-line
    }, [emitTotal]);

    const handleRefresh = useCallback(() => {
      fetchData();
    }, [fetchData]);

    useEffect(() => {
      fetchData();
    }, [fetchData]);

    const handleSubmit = useCallback(async () => {
      if (!form?.length) {
        return;
      }

      try {
        await Promise.all(
          form?.map((record) => {
            const payload: UpdateFirebaseNotificationSettingDTO = {
              isEmail: !!record?.isEmail,
              isPushNotification: !!record?.isPushNotification,
              userGroupIds: record?.userGroupIds
                ? record?.userGroupIds?.split(',')
                : []
            };

            return updateNotificationSettings(record?._id, payload);
          })
        );

        handleRefresh();
        toast.success('Update setting successfully');
      } catch (error: any) {
        toast.error(error?.response?.data?.message || 'Update setting failed');
      }

      // eslint-disable-next-line
    }, [form, handleRefresh]);

    const handleCheckAll = useCallback(
      (field: 'isEmail' | 'isPushNotification') => {
        reset({
          settingForm: form?.map((dt) => {
            const getValueBoolean = !!dt?.[`${field}`];

            if (!!form?.every((dt) => !!dt?.[`${field}`])) {
              return {
                ...dt,
                [`${field}`]: false
              };
            }

            return {
              ...dt,
              [`${field}`]: getValueBoolean ? getValueBoolean : !getValueBoolean
            };
          })
        });
      },
      [form, reset]
    );

    useEffect(() => {
      setCheckAllPushNotification(
        !!form?.every((dt) => !!dt?.isPushNotification)
      );

      setCheckAllEmail(!!form?.every((dt) => !!dt?.isEmail));
    }, [form]);

    return (
      <>
        <PermissionWrapper
          permission={PERMISSION.LIST_SETTING_USER_ROLE_NOTIFICATION}
        >
          {!asPopup ? (
            <AppBreadCrumb
              items={[{ name: 'Notifications', href: '/notifications' }]}
            />
          ) : null}

          <div className={`notification ${!asPopup ? 'layoutContainer' : ''}`}>
            <div
              className="notification__header"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '8px'
              }}
            >
              <div
                className="tittle"
                style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
              >
                Settings
              </div>

              <div className="subTittle">
                Select the notification methods you are happy to be contacted
                by.
              </div>
            </div>

            <div className="notification__body" style={{ borderTop: 'none' }}>
              {initLoad ? (
                <div className="notification__initLoad">
                  <AppLoadingContainer />
                </div>
              ) : (
                <div className="settings__body__content">
                  <div className="item1 fakeGap"></div>
                  <div
                    className="item2 fakeGap"
                    style={{
                      flexDirection: 'column',
                      gap: '20px'
                    }}
                  >
                    <p>Email</p>
                    <AppCheckbox
                      checked={isCheckAllEmail}
                      onChange={() => handleCheckAll('isEmail')}
                    />
                  </div>
                  <div
                    className="item2 fakeGap"
                    style={{
                      flexDirection: 'column',
                      gap: '20px'
                    }}
                  >
                    <p>Push Notification</p>
                    <AppCheckbox
                      checked={isCheckAllPushNotification}
                      onChange={() => handleCheckAll('isPushNotification')}
                    />
                  </div>
                  <div className="item3 fakeGap"></div>
                  {form?.map((dt, key) => (
                    <Fragment key={key}>
                      <SettingItem
                        {...{
                          roles: roles?.map((role) => {
                            return {
                              label: role?.name,
                              value: role?._id
                            };
                          }),
                          data: dt,
                          index: key,
                          control,
                          setValue,
                          getValues
                        }}
                        onUpdate={handleRefresh}
                      />
                    </Fragment>
                  ))}
                </div>
              )}
            </div>

            <PermissionWrapper
              permission={PERMISSION.UPDATE_SETTING_USER_ROLE_NOTIFICATION}
            >
              <AppButton style={{ marginTop: '24px' }} onClick={handleSubmit}>
                Save
              </AppButton>
            </PermissionWrapper>
          </div>
        </PermissionWrapper>
      </>
    );
  }
);

export default NotificationList;
