import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import SidebarItem from './SideBarItem';
import { useLayout } from 'context/LayoutContext';
import { useAuth } from 'context/AuthContext';
import { getUserProfile, updateDefaultLocation } from 'services/auth.service';
import { USER_TYPE } from 'common/constants/permission.constant';
import AppSelect from 'common/components/AppSelect';
import { RiSettings4Line } from 'react-icons/ri';
import {
  HiMenu,
  HiOutlineLocationMarker,
  HiOutlineLogout
} from 'react-icons/hi';
import { useBrandLocation } from 'context/BrandLocationContext';
import { PERMISSION } from 'common/enums/permission.enum';
import { IMenuItem } from 'common/interfaces';
import { every } from 'lodash';
import NotificationPopper from './NotificationPopper';
import { useNavigate } from 'react-router';
import { formatData } from '../../common/helpers/dataFormat.helper';
import { BeatLoader } from 'react-spinners';

import './desktop.scss';

const DELAY_SHOW_NAME_TIME = 1.2 * 1000; // in seconds
const SHOW_NAME_TIME = 5; // in seconds

const Sidebar = () => {
  const navigate = useNavigate();
  const { isShowSidebar, toggleCollapse, isHoverMenu } = useLayout();
  const { isLoadLocations, locations, selectedLocation, onChangeLocation } =
    useBrandLocation();
  const { user, logout, onChangeRole, onChangeUser, hasPermission } = useAuth();

  const [openKey, setOpenKey] = useState(-1);

  const onLogout = () => {
    logout();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await getUserProfile();
        onChangeRole(userData.data.role);
        onChangeUser(userData.data.user);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [onChangeRole, onChangeUser]);

  const menu = useMemo((): Array<IMenuItem> => {
    const menuData: Array<IMenuItem> = [
      {
        title: 'Dashboard',
        path: '/',
        role: [
          USER_TYPE.STAFF,
          USER_TYPE.INSTRUCTOR,
          USER_TYPE.STUDENT,
          USER_TYPE.RESPONSIBLE_PERSON
        ]
        // permissions: [PERMISSION.FILTER_GROUP_CLASS]
      },
      {
        title: 'Students',
        path: '/students',
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permissions: [
          PERMISSION.LIST_STUDENT,
          PERMISSION.LIST_RESPONSIBLE_PERSON
        ],
        children: [
          {
            title: 'Students',
            path: '/students',
            permission: PERMISSION.LIST_STUDENT
          },
          {
            title: 'Responsible Person',
            path: '/responsible-person',
            permission: PERMISSION.LIST_RESPONSIBLE_PERSON
          }
        ]
      },
      {
        title: 'Classes',
        path: '/classes',
        role: [
          USER_TYPE.STAFF,
          USER_TYPE.INSTRUCTOR,
          USER_TYPE.RESPONSIBLE_PERSON,
          USER_TYPE.STUDENT
        ],
        // permissions: [
        //   PERMISSION.LIST_CLASS,
        //   PERMISSION.LIST_CLASS_TEMPLATE,
        //   PERMISSION.LIST_GROUP_ROLL_CALL,
        //   PERMISSION.LIST_LEVEL_BREAKDOWN,
        //   PERMISSION.LIST_TRIAL,
        //   PERMISSION.LIST_ASSESSMENT_RESULT
        // ],
        children: [
          {
            title: 'Classes',
            path: '/classes',
            permission: PERMISSION.LIST_CLASS
          },
          {
            title: 'Class Template',
            path: '/class-template',
            permission: PERMISSION.LIST_CLASS_TEMPLATE
          },
          {
            title: 'Roll calls',
            path: '/roll-calls',
            permission: PERMISSION.LIST_GROUP_ROLL_CALL
          },
          {
            title: 'Assessments',
            path: '/assessments',
            permission: PERMISSION.LIST_ASSESSMENT_RESULT
          }
        ]
      },
      {
        title: 'Instructors',
        path: '/instructors',
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permissions: [PERMISSION.LIST_INSTRUCTOR]
      },
      {
        title: 'Reporting',
        path: '/reporting',
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permissions: [PERMISSION.LIST_REPORT]
      },
      {
        title: 'Staff',
        path: '/staff',
        role: [USER_TYPE.STAFF],
        permissions: [PERMISSION.LIST_STAFF]
      },
      {
        title: 'Location',
        path: '/locations',
        role: [USER_TYPE.STAFF],
        permissions: [PERMISSION.LIST_LOCATION, PERMISSION.LIST_TERM],
        children: [
          {
            title: 'Location Management',
            path: '/locations',
            permission: PERMISSION.LIST_LOCATION
          },
          {
            title: 'Sessions/Terms',
            path: '/terms',
            permission: PERMISSION.LIST_TERM
          },
          {
            title: 'Public Holidays',
            path: '/holiday',
            permission: PERMISSION.LIST_TERM
          }
        ]
      },
      // {
      //   title: 'Membership',
      //   path: '/membership',
      //   role: [USER_TYPE.STAFF]
      //   // permissions: []
      // },
      {
        title: 'Management',
        path: '/activity',
        permissions: [
          PERMISSION.LIST_ACTIVITY,
          PERMISSION.LIST_AUDIT_LOG,
          PERMISSION.LIST_ROLE,
          PERMISSION.LIST_HEALTH_QUESTIONNAIRE,
          PERMISSION.LIST_SETTING_USER_ROLE_NOTIFICATION
        ],
        role: [
          USER_TYPE.STAFF,
          // USER_TYPE.INSTRUCTOR,
          USER_TYPE.RESPONSIBLE_PERSON,
          USER_TYPE.STUDENT
        ],
        children: [
          {
            title: 'Levels Breakdown',
            path: '/levels-breakdown',
            permission: PERMISSION.LIST_LEVEL_BREAKDOWN
          },
          {
            title: 'Bulk Management',
            path: '/bulk-management',
            permission: PERMISSION.LIST_NOTIFICATION
          },
          {
            title: 'Vouchers',
            path: '/vouchers'
            // permission: PERMISSION.LIST_VOUCHERS
          },
          {
            title: 'User Activity',
            path: '/activity',
            permission: PERMISSION.LIST_ACTIVITY
          },
          {
            title: 'Audit Log',
            path: '/audit-log',
            permission: PERMISSION.LIST_AUDIT_LOG
          },
          {
            title: 'Roles & Permissions',
            path: '/user-groups',
            permission: PERMISSION.LIST_ROLE
          },
          {
            title: 'Health Questionnaire',
            path: '/health-questionnaire',
            permission: PERMISSION.LIST_HEALTH_QUESTIONNAIRE
          },
          {
            title: 'Notification settings',
            path: '/notification-settings',
            permission: PERMISSION.LIST_SETTING_USER_ROLE_NOTIFICATION
          }
        ]
      }
    ];
    if (user?.userType === USER_TYPE.SUPER_ADMIN) {
      return menuData;
    }

    // FILTER Role => Permissions => Children permission.
    return menuData
      .filter((menu) => {
        return menu.role.includes(user?.userType || '');
      })
      .filter((menu) => {
        if (
          menu.permissions &&
          every(menu.permissions, (permission) => !hasPermission(permission))
        ) {
          return false;
        }
        return true;
      })
      .map((menu) => {
        if (!!menu?.children) {
          return {
            ...menu,
            children: menu.children.filter(
              (item) => !item.permission || hasPermission(item.permission)
            )
          };
        } else {
          return menu;
        }
      });
  }, [user?.userType, hasPermission]);

  const [counter, setCounter] = useState(0);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isHover, setIsHover] = useState(true);

  useEffect(() => {
    let timer: any = null;

    if (counter > 0) {
      timer = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
    } else if (counter === 0) {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [counter]);

  const __isAlwaysOpenTooltip = useMemo((): boolean => {
    return !counter && !!openTooltip && !isHover;
  }, [counter, openTooltip, isHover]);

  const __isAlwaysCloseTooltip = useMemo((): boolean => {
    return !counter && !openTooltip && !isHover;
  }, [counter, openTooltip, isHover]);

  useEffect(() => {
    setTimeout(() => setCounter(SHOW_NAME_TIME), DELAY_SHOW_NAME_TIME);
  }, []);

  const handleToggleTooltipName = useCallback(
    (type: 'hover' | 'click', value?: boolean) => {
      if (!user) return;

      if (type === 'hover' && isHover) {
        if (typeof value === 'boolean' && !counter) {
          setOpenTooltip(value);
          return;
        }

        if (counter && typeof value !== 'boolean') {
          setCounter(0);
          return;
        }
      }

      if (type === 'click' && typeof value !== 'boolean') {
        setIsHover((pre) => !pre);

        if (!isHover) {
          setOpenTooltip((pre) => !pre);
        } else {
          setCounter(0);
        }

        return;
      }
    },
    [counter, isHover, user]
  );

  return (
    <>
      <div
        className={`sidebar ${
          isShowSidebar
            ? 'open'
            : !isHoverMenu
            ? 'collapsed'
            : 'collapsed hover'
        }`}
      >
        {!isHoverMenu ? (
          <div className="menu-icon" onClick={() => toggleCollapse()}>
            <HiMenu />
          </div>
        ) : null}
        <div>
          <div
            style={{
              display: 'flex',
              margin: '24px 0 20px 0px',
              padding: '0 24px'
            }}
          >
            <img
              src="/icons/logo-swim-school.svg"
              alt="logo"
              style={{ width: '100%' }}
            />
          </div>
          <div className="locations">
            {isLoadLocations ? (
              <BeatLoader color="white" />
            ) : (
              <AppSelect
                startIcon={<HiOutlineLocationMarker size={22} />}
                showLabel={false}
                name="Location"
                value={selectedLocation?._id || ''}
                options={locations.map((location) => {
                  return {
                    label: location.shortName || location.name,
                    value: location._id
                  };
                })}
                inputSize="small"
                label="Location"
                searchable={false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChangeLocation(event.target.value);
                  user?._id &&
                    updateDefaultLocation(user?._id, event.target.value);
                }}
                style={{ padding: '0px' }}
              />
            )}
          </div>
          <div className={`menu  ${isHoverMenu ? 'sidebar-hover' : ''}`}>
            {menu.map((item, key) => (
              <SidebarItem
                key={key}
                open={openKey === key}
                setOpen={() => setOpenKey(openKey === key ? -1 : key)}
                item={item}
              />
            ))}
          </div>
        </div>

        <div>
          <div className="info-box">
            <div className="info-items">
              <div
                className="avatar"
                style={{
                  cursor: 'pointer',
                  ...(__isAlwaysOpenTooltip
                    ? {
                        outline: '3px solid var(--primaryColor)',
                        border: 'none'
                      }
                    : undefined),
                  ...(__isAlwaysCloseTooltip
                    ? {
                        outline: '3px solid var(--subTitleColor)',
                        border: 'none'
                      }
                    : undefined)
                }}
                onClick={() => handleToggleTooltipName('click')}
                onMouseOver={() => handleToggleTooltipName('hover', true)}
                onMouseLeave={() => handleToggleTooltipName('hover', false)}
              >
                <div
                  className={`showName showName--${
                    __isAlwaysOpenTooltip || counter || openTooltip
                      ? 'open'
                      : 'close'
                  }`}
                  onMouseOver={(e) => e?.stopPropagation()}
                >
                  {`${formatData(user?.lastName)}, ${formatData(
                    user?.firstName
                  )}`}
                </div>

                {user?.avatarUrl ? (
                  <img src={user?.avatarUrl} alt="user avatar" />
                ) : (
                  <div className="avatarTxt">
                    {(user?.lastName || user?.firstName)?.split('')?.[0]}
                  </div>
                )}
              </div>

              {hasPermission(PERMISSION.LIST_NOTIFICATION) ? (
                <NotificationPopper />
              ) : null}

              {hasPermission(PERMISSION.LIST_SETTING_USER_ROLE_NOTIFICATION) ? (
                <RiSettings4Line
                  style={{ cursor: 'pointer' }}
                  size={24}
                  onClick={() => navigate('/notification-settings')}
                />
              ) : (
                null
              )}
              <HiOutlineLogout
                style={{ cursor: 'pointer' }}
                size={24}
                onClick={onLogout}
              />
            </div>
          </div>
          <div className="footer-box" style={{ height: '50px' }}></div>
        </div>
      </div>
    </>
  );
};

export default memo(Sidebar);
