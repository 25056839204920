import React from 'react';
import AppSelect from 'common/components/AppSelect';
import AppButton from 'common/components/AppButton';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import {
  ERROR_MESSAGE_DATE,
  FORMAT_END_OF_DATE,
  FORMAT_START_OF_DATE,
  REPORT_PATH,
  REPORT_PROGRAM_TYPE_OPTIONS,
  WEEKDAY,
  initResultStatisticAchieved,
  initResultStatisticDeveloping,
  initStatusStatisticApproved,
  initStatusStatisticPending
} from '../constant';
import AppDatePicker from 'common/components/AppDatePicker';
import { createColumnHelper } from '@tanstack/react-table';
import {
  formatData,
  formatDate,
  formatTime
} from 'common/helpers/dataFormat.helper';
import AppTable from 'common/components/AppTable';
import { ILevel } from 'common/interfaces/levelBreakdown.interface';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { useBrandLocation } from 'context/BrandLocationContext';
import { useToast } from 'context/ToastContext';
import { IOption } from 'common/interfaces';
import dayjs, { Dayjs } from 'dayjs';
import { uniq } from 'lodash';
import { getLevelBreakdowns } from 'services/levelBreakdown.service';
import AppSelection from 'common/components/AppSelection';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { ASSESSMENT_STATUS } from 'common/enums/assessment.enum';
import { capitalizeFirstLetter } from '../helper';
import { getAssessmentOutcomeReport } from 'services/report.service';
import { convertToUnixTime } from 'common/helpers/time.helper';
import {
  IAssessmentOutcomeReport,
  IAssessmentStatisticReport
} from 'common/interfaces/report.interface';
import './desktop.scss';
import { ASSESSMENT_STATISTIC } from '../enum';

interface IFilter {
  dateFrom: string;
  dateTo: string;
  programType: CLASS_TYPES;
  assessmentResults: Array<ASSESSMENT_STATUS>;
  lessonDay: string;
  levelIds: Array<string>;
}

const initFilter: IFilter = {
  dateFrom: '',
  dateTo: '',
  programType: CLASS_TYPES.NONE,
  assessmentResults: [],
  lessonDay: '',
  levelIds: []
};

const ReportAssessmentOutcome = () => {
  const toast = useToast();
  const { selectedLocation: __globalLocation } = useBrandLocation();

  const columnHelper = createColumnHelper<IAssessmentOutcomeReport>();

  const columns = [
    columnHelper.accessor('student.memberId', {
      header: () => <span>student ID</span>,
      cell: (info) => formatData(formatData(info.getValue()))
    }),
    columnHelper.accessor('student.firstName', {
      header: () => <span>Student name</span>,
      cell: (info) => (
        <div>
          {`${formatData(info.row.original.student?.lastName)}, ${formatData(
            info.getValue()
          )}`}
        </div>
      )
    }),
    columnHelper.accessor('student.dob', {
      header: () => <span>Student Age</span>,
      cell: (info) => {
        const birthDate = dayjs(info.getValue());
        const currentDate = dayjs();
        const years = currentDate.diff(birthDate, 'year');
        const months = currentDate.diff(birthDate.add(years, 'year'), 'month');

        return `${years} years ${months} months`;
      }
    }),
    columnHelper.accessor('__v', {
      header: () => <span>Lesson Day</span>,
      cell: (info) => dayjs(info.row.original.startTime).format('dddd')
    }),
    columnHelper.accessor('startTime', {
      header: () => <span>Lesson Time</span>,
      cell: (info) => formatTime(info.getValue())
    }),
    columnHelper.accessor('level.name', {
      header: () => <span>Level</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('area', {
      header: () => <span>Area</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('instructor.firstName', {
      header: () => <span>Instructor Name</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('graduationDate', {
      header: () => <span>Assessment date</span>,
      cell: (info) => formatDate(info.getValue())
    }),
    columnHelper.accessor('assessmentResult', {
      header: () => <span>Assessment Result</span>,
      cell: (info) => (
        <div
          className={`assessmentStatus assessmentStatus__${info.getValue()}`}
        >
          {formatData(info.getValue())}
        </div>
      )
    }),
    columnHelper.accessor('assessmentStatus', {
      header: () => <span>Assessment Status</span>,
      cell: (info) => (
        <div
          className={`assessmentStatus assessmentStatus__${info.getValue()}`}
        >
          {formatData(info.getValue()?.replaceAll('-', ' '))}
        </div>
      )
    })
  ];

  const dateError = React.useRef<string>('');
  const [levels, setLevels] = React.useState<Array<ILevel>>([]);

  const [isGenerated, setIsGenerated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [limit, setLimit] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(-1);

  const [data, setData] = React.useState<Array<IAssessmentOutcomeReport>>([]);

  const [resultStatisticAchieved, setResultStatisticAchieved] =
    React.useState<IAssessmentStatisticReport>(initResultStatisticAchieved);
  const [resultStatisticDeveloping, setResultStatisticDeveloping] =
    React.useState<IAssessmentStatisticReport>(initResultStatisticDeveloping);
  const [statusStatisticApproved, setStatusStatisticApproved] =
    React.useState<IAssessmentStatisticReport>(initStatusStatisticApproved);
  const [statusStatisticPending, setStatusStatisticPending] =
    React.useState<IAssessmentStatisticReport>(initStatusStatisticPending);

  // Search section
  const [dateFrom, setDateFrom] = React.useState<string>('');
  const [dateTo, setDateTo] = React.useState<string>('');
  const [programType, setProgramType] = React.useState<CLASS_TYPES>(
    CLASS_TYPES.NONE
  );
  const [assessmentResults, setAssessmentResults] = React.useState<
    Array<ASSESSMENT_STATUS>
  >([]);
  const [lessonDay, setLessonDay] = React.useState<string>('');
  const [levelIds, setLevelIds] = React.useState<Array<string>>([]);

  const filter = React.useRef<IFilter>(initFilter);

  const __assessmentResultOptions: Array<IOption> = React.useMemo(() => {
    return [
      {
        label: capitalizeFirstLetter(ASSESSMENT_STATUS.DEVELOPING),
        value: ASSESSMENT_STATUS.DEVELOPING
      },
      {
        label: capitalizeFirstLetter(ASSESSMENT_STATUS.ACHIEVED),
        value: ASSESSMENT_STATUS.ACHIEVED
      }
    ];
  }, []);

  const __lessonDayOptions: Array<IOption> = React.useMemo(() => {
    return Object.keys(WEEKDAY).map((key) => ({
      value: WEEKDAY[key as keyof typeof WEEKDAY],
      label: key
    }));
  }, []);

  const __levelOptions: Array<IOption> = React.useMemo(() => {
    if (levels?.length === 0) return [];
    return levels.map((level) => ({
      label: formatData(level?.shortName),
      value: level?._id
    }));
  }, [levels]);

  const __selectedAssessmentResultPlaceholderText: string =
    React.useMemo(() => {
      return assessmentResults.length === 0
        ? 'Select option'
        : `${assessmentResults.length} selected`;
    }, [assessmentResults]);

  const __selectedLevelPlaceholderText: string = React.useMemo(() => {
    return levelIds.length === 0
      ? 'Select option'
      : `${levelIds.length} selected`;
  }, [levelIds]);

  const __isDisableLessonDay: boolean = React.useMemo(() => {
    return programType === CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM;
  }, [programType]);

  const __isDisableGenerateButton: boolean = React.useMemo(() => {
    return (
      !dateFrom ||
      !dateTo ||
      !programType ||
      assessmentResults.length === 0 ||
      (__isDisableLessonDay ? false : !lessonDay) ||
      levelIds.length === 0 ||
      !!dateError.current
    );
  }, [
    dateFrom,
    dateTo,
    programType,
    assessmentResults,
    lessonDay,
    levelIds,
    dateError,
    __isDisableLessonDay
  ]);

  const handleChangeDateFrom = (value: Dayjs | null) => {
    setDateFrom(value?.format(FORMAT_START_OF_DATE) || '');
    if (value?.isAfter(dayjs(dateTo))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeDateTo = (value: Dayjs | null) => {
    setDateTo(value?.format(FORMAT_END_OF_DATE) || '');
    if (value?.isBefore(dayjs(dateFrom))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeProgramType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setProgramType(event.target.value as CLASS_TYPES);
  };

  const handleToggleAssessmentResult = (value: string) => {
    const newAssessmentResults = [...assessmentResults];
    const findIndex = newAssessmentResults.findIndex(
      (assessmentResult) => assessmentResult === value
    );
    if (findIndex === -1) {
      newAssessmentResults.push(value as ASSESSMENT_STATUS);
    } else {
      newAssessmentResults.splice(findIndex, 1);
    }
    setAssessmentResults(newAssessmentResults);
  };
  const handleSelectAllAssessmentResult = () => {
    const values = [ASSESSMENT_STATUS.DEVELOPING, ASSESSMENT_STATUS.ACHIEVED];
    if (assessmentResults?.length === values?.length) {
      setAssessmentResults([]);
    } else {
      setAssessmentResults(uniq(values.concat(assessmentResults)));
    }
  };

  const handleChangeLessonDay = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setLessonDay(event.target.value);
  };

  const handleToggleLevel = (value: string) => {
    const newLevelIds = [...levelIds];
    const findIndex = newLevelIds.findIndex((levelId) => levelId === value);
    if (findIndex === -1) {
      newLevelIds.push(value);
    } else {
      newLevelIds.splice(findIndex, 1);
    }
    setLevelIds(newLevelIds);
  };
  const handleSelectAllLevel = () => {
    const values = levels.map((level: ILevel) => level._id);
    if (levelIds.length === levels?.length) {
      setLevelIds([]);
    } else {
      setLevelIds(uniq(values.concat(levelIds)));
    }
  };

  const handleGenerate = async () => {
    setPage(1);

    filter.current = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      programType: programType,
      assessmentResults: assessmentResults,
      lessonDay: lessonDay,
      levelIds: levelIds
    };

    fetchAssessmentOutcomeReport();
  };

  const fetchAssessmentOutcomeReport = async () => {
    if (!__globalLocation?._id) return;

    try {
      setIsGenerated(false);
      setLoading(true);

      const result = await getAssessmentOutcomeReport(
        page,
        limit,
        __globalLocation?._id,
        convertToUnixTime(filter.current.dateFrom),
        convertToUnixTime(filter.current.dateTo),
        filter.current.programType,
        filter.current.assessmentResults,
        filter.current.levelIds,
        filter.current.programType === CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM
          ? undefined
          : filter.current.lessonDay
      );

      setData(result?.data?.data?.data);
      setTotal(result?.data?.data?.total);

      const resultStatistic: Array<IAssessmentStatisticReport> =
        result?.data?.data?.resultStatistic || [];
      const statusStatistic: Array<IAssessmentStatisticReport> =
        result?.data?.data?.statusStatistic || [];

      const resultStatisticAchieved = resultStatistic.find(
        (item: IAssessmentStatisticReport) =>
          item._id === ASSESSMENT_STATISTIC.ACHIEVED
      );
      if (!!resultStatisticAchieved)
        setResultStatisticAchieved(resultStatisticAchieved);
      else setResultStatisticAchieved(initResultStatisticAchieved);

      const resultStatisticDeveloping = resultStatistic.find(
        (item: IAssessmentStatisticReport) =>
          item._id === ASSESSMENT_STATISTIC.DEVELOPING
      );
      if (!!resultStatisticDeveloping)
        setResultStatisticDeveloping(resultStatisticDeveloping);
      else setResultStatisticDeveloping(initResultStatisticDeveloping);

      const statusStatisticApproved = statusStatistic.find(
        (item: IAssessmentStatisticReport) =>
          item._id === ASSESSMENT_STATISTIC.APPROVED
      );
      if (!!statusStatisticApproved)
        setStatusStatisticApproved(statusStatisticApproved);
      else setStatusStatisticApproved(initStatusStatisticApproved);

      const statusStatisticPending = statusStatistic.find(
        (item: IAssessmentStatisticReport) =>
          item._id === ASSESSMENT_STATISTIC.APPROVAL_PENDING
      );
      if (!!statusStatisticPending)
        setStatusStatisticPending(statusStatisticPending);
      else setStatusStatisticPending(initStatusStatisticPending);

      setIsGenerated(true);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'Failed to get assessment outcome report'
      );

      setData([]);
      setTotal(-1);

      setResultStatisticAchieved(initResultStatisticAchieved);
      setResultStatisticDeveloping(initResultStatisticDeveloping);
      setStatusStatisticApproved(initStatusStatisticApproved);
      setStatusStatisticPending(initStatusStatisticPending);
    } finally {
      setLoading(false);
    }
  };

  const fetchInitData = React.useCallback(async () => {
    if (!__globalLocation?._id) return;
    try {
      const resultLevel = await getLevelBreakdowns(
        1,
        100,
        __globalLocation._id
      );
      setLevels(resultLevel?.data?.data?.data);
      setLevelIds([]);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Failed to get init data');
      setLevels([]);
    }
    // eslint-disable-next-line
  }, [__globalLocation?._id]);

  React.useEffect(() => {
    if (isGenerated) {
      fetchAssessmentOutcomeReport();
    }
    // eslint-disable-next-line
  }, [page, limit]);

  React.useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  return (
    <main id="reportAssessmentOutcome" className="reportAssessmentOutcome">
      <AppBreadCrumb
        items={[
          { name: 'Reporting', href: REPORT_PATH },
          { name: 'Assessment Outcome Report' }
        ]}
      />
      <div className="layoutContainer reportAssessmentOutcome__wrapper">
        <section className="reportAssessmentOutcome__search">
          <div className="reportAssessmentOutcome__search__fields">
            <AppDatePicker
              size="small"
              label="Date from"
              value={dayjs(dateFrom)}
              onChange={handleChangeDateFrom}
            />
            <AppDatePicker
              size="small"
              label="Date to"
              value={dayjs(dateTo)}
              onChange={handleChangeDateTo}
              message={{
                type: 'error',
                text: dateError.current
              }}
            />
            <AppSelect
              searchable={false}
              inputSize="small"
              label="Program Type"
              placeholder="Select option"
              options={REPORT_PROGRAM_TYPE_OPTIONS}
              value={programType}
              onChange={handleChangeProgramType}
            />
            <AppSelection
              label="Assessment Result"
              placeholder={__selectedAssessmentResultPlaceholderText}
              options={__assessmentResultOptions}
              onSelect={handleToggleAssessmentResult}
              onSelectAll={handleSelectAllAssessmentResult}
              selectedIds={assessmentResults}
            />
            <AppSelect
              searchable={false}
              inputSize="small"
              label="Lesson Day"
              placeholder="Select option"
              options={__lessonDayOptions}
              value={lessonDay}
              onChange={handleChangeLessonDay}
              disabled={__isDisableLessonDay}
            />

            <AppSelection
              label="Level"
              placeholder={__selectedLevelPlaceholderText}
              options={__levelOptions}
              onSelect={handleToggleLevel}
              onSelectAll={handleSelectAllLevel}
              selectedIds={levelIds}
            />
          </div>
          <AppButton
            variant="primary"
            buttonSize="small"
            isLoading={loading}
            disabled={__isDisableGenerateButton}
            onClick={handleGenerate}
          >
            generate
          </AppButton>
        </section>

        {!isGenerated && loading && <AppLoadingContainer />}

        {isGenerated && !loading && (
          <React.Fragment>
            <section className="reportAssessmentOutcome__statistics">
              <div className="reportAssessmentOutcome__statistic">
                <p>result</p>
                <div className="reportAssessmentOutcome__statistic__percent">
                  <div className="reportAssessmentOutcome__statistic__percent--item">
                    <div
                      className={`assessmentStatus assessmentStatus__achieved`}
                    >
                      Achieved
                    </div>
                    <p>{formatData(resultStatisticAchieved?.percentage)}</p>
                  </div>
                  <div className="reportAssessmentOutcome__statistic__percent--item">
                    <div
                      className={`assessmentStatus assessmentStatus__developing`}
                    >
                      Developing
                    </div>
                    <p>{formatData(resultStatisticDeveloping?.percentage)}</p>
                  </div>
                </div>
              </div>
              <div className="reportAssessmentOutcome__statistic">
                <p>Status</p>
                <div className="reportAssessmentOutcome__statistic__percent">
                  <div className="reportAssessmentOutcome__statistic__percent--item">
                    <div
                      className={`assessmentStatus assessmentStatus__approved`}
                    >
                      Approved
                    </div>
                    <p>{formatData(statusStatisticApproved?.percentage)}</p>
                  </div>
                  <div className="reportAssessmentOutcome__statistic__percent--item">
                    <div
                      className={`assessmentStatus assessmentStatus__approval-pending`}
                    >
                      Pending
                    </div>
                    <p>{formatData(statusStatisticPending?.percentage)}</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="reportAssessmentOutcome__content">
              <div className="reportAssessmentOutcome__content__header">
                <p className="reportAssessmentOutcome__content__header--title">
                  Assessment Outcome Report
                </p>
              </div>

              <div className="reportAssessmentOutcome__content__table">
                <AppTable
                  loading={loading}
                  data={data}
                  columns={columns}
                  pagination={{
                    index: page,
                    size: limit,
                    total: total
                  }}
                  onChangePage={(index: number, size: number) => {
                    setPage(index);
                    setLimit(size);
                  }}
                  columnVisibility={{
                    __v: filter.current.programType === CLASS_TYPES.SERIES
                  }}
                />
              </div>
            </section>
          </React.Fragment>
        )}
      </div>
    </main>
  );
};

export default ReportAssessmentOutcome;
