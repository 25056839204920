import React from 'react';
import { IReportCard } from '../interface';
import { Link } from 'react-router-dom';
import { REPORT_PATH } from '../constant';
import PermissionWrapper from 'components/PermissionWrapper';

interface IReportCardProps {
  report: IReportCard;
}

const ReportCard = (props: IReportCardProps) => {
  const { report } = props;

  return (
    <PermissionWrapper permission={report.permission || []}>
      <div className="reportCard">
        <div className="reportCard__main">
          <p className="reportCard__main--name">{report.name}</p>
          <div className="reportCard__main--button">
            <Link to={`${REPORT_PATH}/${report.link}`}>open</Link>
          </div>
        </div>
      </div>
    </PermissionWrapper>
  );
};

export default React.memo(ReportCard);
