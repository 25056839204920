import { useToast } from 'context/ToastContext';
import { useBrandLocation } from 'context/BrandLocationContext';
import dayjs, { Dayjs } from 'dayjs';
import {
  ERROR_MESSAGE_DATE,
  FORMAT_END_OF_DATE,
  FORMAT_START_OF_DATE,
  REPORT_PATH
} from '../constant';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppDatePicker from 'common/components/AppDatePicker';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import {
  convertToOrdinal,
  formatData,
  formatMoneySign,
  formatPercentNumber
} from 'common/helpers/dataFormat.helper';
import { getDiscountedEnrolmentReport } from 'services/report.service';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { createColumnHelper } from '@tanstack/react-table';
import { IDiscountedEnrolmentReport } from 'common/interfaces/report.interface';
import AppTable from 'common/components/AppTable';
import './desktop.scss';
import AppButton from 'common/components/AppButton';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DISCOUNT_TYPE } from 'common/enums';

interface IFilter {
  dateFrom: string;
  dateTo: string;
}

const initFilter: IFilter = {
  dateFrom: '',
  dateTo: ''
};

const ReportDiscountedEnrolment = () => {
  const toast = useToast();
  const { selectedLocation: __globalLocation } = useBrandLocation();

  const columnHelper = createColumnHelper<IDiscountedEnrolmentReport>();

  const getDiscountValueText = (
    discountType: DISCOUNT_TYPE,
    discountValue: number
  ): string => {
    switch (discountType) {
      case DISCOUNT_TYPE.AMOUNT:
        return `${formatMoneySign(discountValue)}`;
      case DISCOUNT_TYPE.PERCENTAGE:
        return `${formatPercentNumber(discountValue)}`;
      case DISCOUNT_TYPE.COMPLIMENTARY:
        return DISCOUNT_TYPE.COMPLIMENTARY;
      default:
        return '';
    }
  };

  const columns = [
    columnHelper.accessor('responsiblePersonId', {
      header: () => <span>RP ID</span>,
      cell: (info) =>
        formatData(
          // @ts-ignore
          info.row.original.student.listResponsiblePerson?.find(
            // @ts-ignore
            (rp) => rp?.responsiblePersonInfo?.[0]?._id === info.getValue()
          )?.responsiblePersonInfo?.[0]?.memberId
        )
    }),
    columnHelper.accessor('student.memberId', {
      header: () => <span>student ID</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('student', {
      header: () => <span>Student name</span>,
      cell: (info) => {
        return (
          <div>
            {`${formatData(info.getValue()?.lastName)} , ${formatData(
              info.getValue()?.firstName
            )}`}
          </div>
        );
      }
    }),
    columnHelper.accessor('studentNumberInFamily', {
      header: () => <span>Student number in family</span>,
      cell: (info) =>
        `${formatData(convertToOrdinal(info.getValue() + 1))} CHILD`
    }),
    columnHelper.accessor('classPrice', {
      header: () => <span>Class price</span>,
      cell: (info) => formatMoneySign(info.getValue())
    }),
    columnHelper.accessor('discountValue', {
      header: () => <span>Discount amount</span>,
      cell: (info) =>
        getDiscountValueText(info.row.original?.discountType, info.getValue())
    }),
    columnHelper.accessor('classCharge', {
      header: () => <span>Class charge</span>,
      cell: (info) => formatMoneySign(info.getValue())
    })
  ];

  const dateError = useRef('');

  const [isGenerated, setIsGenerated] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IDiscountedEnrolmentReport[]>([]);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(-1);

  // Search section
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const filter = useRef<IFilter>(initFilter);

  const __isDisableGenerateButton: boolean = useMemo(() => {
    return !dateFrom || !dateTo || !!dateError.current;
  }, [dateFrom, dateTo, dateError]);

  const handleChangeDateFrom = (value: Dayjs | null) => {
    setDateFrom(value?.format(FORMAT_START_OF_DATE) || '');
    if (value?.isAfter(dayjs(dateTo))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeDateTo = (value: Dayjs | null) => {
    setDateTo(value?.format(FORMAT_END_OF_DATE) || '');
    if (value?.isBefore(dayjs(dateFrom))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };

  const handleGenerate = async () => {
    setPage(1);

    filter.current = {
      dateFrom: dateFrom,
      dateTo: dateTo
    };

    fetchDiscountedEnrolmentReport();
  };

  const fetchDiscountedEnrolmentReport = async () => {
    if (!__globalLocation?._id) return;

    try {
      setLoading(true);

      const result = await getDiscountedEnrolmentReport(
        page,
        limit,
        __globalLocation?._id,
        convertToUnixTime(filter.current.dateFrom),
        convertToUnixTime(filter.current.dateTo)
      );

      setData(result?.data?.data?.data);
      setTotal(result?.data?.data?.total);

      setIsGenerated(true);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to get discounted enrolment report'
      );
      setData([]);
      setTotal(-1);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isGenerated) {
      fetchDiscountedEnrolmentReport();
    }
    // eslint-disable-next-line
  }, [page, limit]);

  return (
    <main id="reportDiscountedEnrolment" className="reportDiscountedEnrolment">
      <AppBreadCrumb
        items={[
          { name: 'Reporting', href: REPORT_PATH },
          { name: 'Discount Enrolment Report' }
        ]}
      />
      <div className="layoutContainer reportDiscountedEnrolment__wrapper">
        <section className="reportDiscountedEnrolment__search">
          <div className="reportDiscountedEnrolment__search__fields">
            <AppDatePicker
              size="small"
              label="Date from"
              value={dayjs(dateFrom)}
              onChange={handleChangeDateFrom}
            />
            <AppDatePicker
              size="small"
              label="Date to"
              value={dayjs(dateTo)}
              onChange={handleChangeDateTo}
              message={{
                type: 'error',
                text: dateError.current
              }}
            />
          </div>
          <AppButton
            variant="primary"
            buttonSize="small"
            isLoading={loading}
            disabled={__isDisableGenerateButton}
            onClick={handleGenerate}
          >
            generate
          </AppButton>
        </section>

        {!isGenerated && loading && <AppLoadingContainer />}

        {isGenerated && !loading && (
          <div className="reportDiscountedEnrolment__content">
            <div className="reportExportFamilyDetails__content__header">
              <p className="reportExportFamilyDetails__content__header--title">
                Discount Enrolment Report
              </p>
            </div>

            <div className="reportDiscountedEnrolment__content__table">
              <AppTable
                loading={loading}
                data={data}
                columns={columns}
                pagination={{
                  index: page,
                  size: limit,
                  total: total
                }}
                onChangePage={(index: number, size: number) => {
                  setPage(index);
                  setLimit(size);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default ReportDiscountedEnrolment;
