import { object as OBJECT, boolean, string } from 'yup';

const HEALTH_QUESTIONNAIRE_BRAND = string().required('Please select brand');
const HEALTH_QUESTIONNAIRE_QUESTION = string().required(
  'Please provide question'
);

const HEALTH_QUESTIONNAIRE_MEDICAL_CONDITION = string().required(
  'Please provide medical condition'
);

const HEALTH_QUESTIONNAIRE_MEDICAL_CONDITION_CODE = string().required(
  'Please provide medical condition code'
);

const HEALTH_QUESTIONNAIRE_ANSWER_TYPE = string().required(
  'Please select answer type'
);
const HEALTH_QUESTIONNAIRE_SECTION_LABEL = string().required(
  'Please select section label'
);
const HEALTH_QUESTIONNAIRE_ACTIVE = boolean();
const HEALTH_QUESTIONNAIRE_MANDATORY = boolean();
const HEALTH_QUESTIONNAIRE_EXPECTED_ANSWER = string();

const validations = {
  OBJECT,
  HEALTH_QUESTIONNAIRE_BRAND,
  HEALTH_QUESTIONNAIRE_QUESTION,
  HEALTH_QUESTIONNAIRE_ANSWER_TYPE,
  HEALTH_QUESTIONNAIRE_SECTION_LABEL,
  HEALTH_QUESTIONNAIRE_ACTIVE,
  HEALTH_QUESTIONNAIRE_MANDATORY,
  HEALTH_QUESTIONNAIRE_EXPECTED_ANSWER,
  HEALTH_QUESTIONNAIRE_MEDICAL_CONDITION,
  HEALTH_QUESTIONNAIRE_MEDICAL_CONDITION_CODE
};
export default validations;
