import { object as OBJECT, boolean, number, string } from 'yup';

//  ===== LOCATION_PIN  =====

const LOCATION_PIN_STRING_REQUIRED = string().required(
  'This field is required'
);

const LOCATION_PIN_BOOLEAN_REQUIRED = boolean().required(
  'This field is required'
);

const LOCATION_PIN_NUMBER_REQUIRED = number()
  .typeError('This value must be a number')
  .required('This field is required');

//  ===== END LOCATION_PIN  =====

const validations = {
  OBJECT,
  string,
  LOCATION_PIN_STRING_REQUIRED,
  LOCATION_PIN_BOOLEAN_REQUIRED,
  LOCATION_PIN_NUMBER_REQUIRED
};

export default validations;
