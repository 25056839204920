import React from 'react';
import './desktop.scss';
import { useToast } from 'context/ToastContext';
import { HiArrowLeft } from 'react-icons/hi';
import AppCard, {
  AppCardContent,
  AppCardHeader
} from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import AppSelect from 'common/components/AppSelect';
import AppTextArea from 'common/components/AppTextArea';
import AppCKEditor from 'components/common/AppCKEditor';
import Upload, {
  ERROR_MESSAGE_LIMIT_FILES,
  ERROR_MESSAGE_LIMIT_SIZE
} from 'components/Upload';
import AppButton from 'common/components/AppButton';
import yup from 'validators/notificationTemplate.validator';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateNotificationTemplateDTO } from 'DTOs/notificationTemplate.dto';
import { NOTIFICATION_TYPE } from 'common/enums/notification.enum';
import { uploadFile } from 'services/uploadFile.service';
import { FILE_ASSET_TYPE } from 'common/enums/uploadFile.enum';
import { updateNotificationTemplate } from 'services/notificationTemplate.service';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { INotificationTemplate } from 'common/interfaces/notificationTemplate.interface';
import { PERMISSION } from 'common/enums/permission.enum';
import { useAuth } from 'context/AuthContext';
import { isHTML } from 'common/helpers/index.helper';

const NOTIFICATION_TYPE_OPTIONS = [
  {
    label: NOTIFICATION_TYPE.EMAIL,
    value: NOTIFICATION_TYPE.EMAIL
  },
  {
    label: NOTIFICATION_TYPE.SMS,
    value: NOTIFICATION_TYPE.SMS
  },
  {
    label: 'Member Portal',
    value: NOTIFICATION_TYPE.PUSH
  }
];

const validationSchema = yup.OBJECT({
  title: yup.NOTIFICATION_TEMPLATE_NAME,
  types: yup.NOTIFICATION_TEMPLATE_TYPES,
  messageRaw: yup.NOTIFICATION_TEMPLATE_CONTENT,
  messageEditor: yup.NOTIFICATION_TEMPLATE_CONTENT,
  attachmentFiles: yup.NOTIFICATION_TEMPLATE_ATTACHMENT_FILES,
  locationId: yup.NOTIFICATION_TEMPLATE_LOCATION_ID
});

interface INotificationTemplateViewProps {
  open: boolean;
  dataNotificationTemplate: INotificationTemplate;
  onClose: () => void;
  onSuccess: () => void;
}

const NotificationTemplateView = (props: INotificationTemplateViewProps) => {
  const { open, dataNotificationTemplate, onClose, onSuccess } = props;
  const toast = useToast();
  const { hasPermission } = useAuth();

  const [loading, setLoading] = React.useState<boolean>(false);

  const [notificationTemplate, setNotificationTemplate] =
    React.useState<UpdateNotificationTemplateDTO>(() => {
      const isMessageHTML = isHTML(dataNotificationTemplate?.message || '');
      return {
        title: dataNotificationTemplate.title,
        types: (dataNotificationTemplate.types as Array<string>).join(','),
        message: dataNotificationTemplate?.message,
        attachmentFiles: dataNotificationTemplate.attachmentFiles,
        messageRaw: isMessageHTML ? '' : dataNotificationTemplate?.message,
        messageEditor: isMessageHTML ? dataNotificationTemplate?.message : ''
      };
    });

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<UpdateNotificationTemplateDTO>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<UpdateNotificationTemplateDTO>,
    defaultValues: dataNotificationTemplate
  });

  const __isDisplayContentRaw: boolean = React.useMemo(() => {
    const currentTypes = (notificationTemplate.types as string).split(',');
    const isDisplayRaw: boolean = currentTypes.includes(NOTIFICATION_TYPE.SMS);
    if (isDisplayRaw) {
      setValue('messageRaw', notificationTemplate?.messageRaw || '');
      setValue('messageEditor', notificationTemplate?.messageEditor || 'temp');
    } else {
      setValue('messageRaw', notificationTemplate?.messageRaw || 'temp');
      setValue('messageEditor', notificationTemplate?.messageEditor || '');
    }
    return isDisplayRaw;
  }, [
    notificationTemplate.types,
    notificationTemplate?.messageEditor,
    notificationTemplate?.messageRaw,
    setValue
  ]);

  const [files, setFiles] = React.useState<Array<File>>([]);

  const handleCloseForm = () => {
    reset(dataNotificationTemplate);
    setNotificationTemplate(dataNotificationTemplate);
    onClose();
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNotificationTemplate({
      ...notificationTemplate,
      [event.target.name]: event.target.value
    });
    // @ts-ignore
    setValue(event.target.name, event.target.value);
    // @ts-ignore
    trigger(event.target.name);
  };

  const handleChangeFiles = (files: Array<File>) => {
    setFiles(files);
  };

  const handleUpdate = async (data: UpdateNotificationTemplateDTO) => {
    setLoading(true);

    let attachments: Array<string> = [];

    if (files?.length > 0) {
      const promises: Array<Promise<any>> = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const promise = uploadFile(FILE_ASSET_TYPE.FILE_ATTACHMENT, file);
        promises.push(promise);
      }
      try {
        const results = await Promise.all(promises);
        attachments = [
          ...(notificationTemplate?.attachmentFiles || []),
          ...results.map((e) => e?.data?.data || '')
        ];
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Failed to upload attachments'
        );
      }
    }

    try {
      const payload: UpdateNotificationTemplateDTO = {
        title: data.title,
        types: (data.types as string)?.split(',') || [],
        message: __isDisplayContentRaw
          ? notificationTemplate?.messageRaw
          : notificationTemplate?.messageEditor,
        attachmentFiles: attachments
      };

      if (attachments.length === 0) delete payload.attachmentFiles;

      await updateNotificationTemplate(dataNotificationTemplate._id, payload);

      toast.success('Update notification template successfully');

      onSuccess();
      handleCloseForm();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'Failed to update notification template'
      );
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setValue(
      'types',
      (dataNotificationTemplate.types as Array<string>).join(',')
    );
  }, [dataNotificationTemplate?.types, setValue]);

  return (
    <div className="notificationTemplateViewWrapper">
      {loading && <AppLoadingContainer />}

      <div
        className={`overlay ${open ? 'active' : ' '}`}
        onClick={handleCloseForm}
      ></div>

      <div className={`notificationTemplateView ${open ? 'active' : ''}`}>
        <div className="notificationTemplateView__header">
          <div onClick={handleCloseForm}>
            <HiArrowLeft size={24} />
          </div>
          <p>Edit Notification template</p>
        </div>

        <div className="notificationTemplateView__info">
          <AppCard>
            <div className="notificationTemplateView__info--wrapper">
              <AppCardHeader title="new notification template" />

              <AppCardContent>
                <div className="notificationTemplateView__info--top">
                  <AppInput
                    label="Name*"
                    {...register('title')}
                    value={notificationTemplate.title}
                    onChange={handleChange}
                    message={{
                      type: 'error',
                      text: errors?.title?.message || ''
                    }}
                  />
                  <AppSelect
                    label="Notification Type*"
                    multiValue
                    options={NOTIFICATION_TYPE_OPTIONS}
                    {...register('types')}
                    // @ts-ignore
                    value={notificationTemplate.types}
                    onChange={handleChange}
                    message={{
                      type: 'error',
                      text: errors?.types?.message || ''
                    }}
                  />
                </div>

                {__isDisplayContentRaw ? (
                  <AppTextArea
                    label="Content*"
                    {...register('messageRaw')}
                    value={notificationTemplate?.messageRaw || ''}
                    onChange={handleChange}
                    message={{
                      type: 'error',
                      text: errors?.messageRaw?.message || ''
                    }}
                  />
                ) : (
                  <div className="notificationTemplateView__info--content">
                    <p className="notificationTemplateView__info--content--label">
                      Content*
                    </p>
                    <AppCKEditor
                      {...register('messageEditor')}
                      value={notificationTemplate?.messageEditor || ''}
                      onChange={(value: string) => {
                        setNotificationTemplate({
                          ...notificationTemplate,
                          messageEditor: value
                        });
                        setValue('messageEditor', value);
                        trigger('messageEditor');
                      }}
                    />
                    {errors?.messageEditor?.message && (
                      <p className="notificationTemplateView__info--content--error">
                        {errors?.messageEditor?.message}
                      </p>
                    )}
                  </div>
                )}

                <div className="notificationTemplateView__info--upload">
                  <p className="notificationTemplateView__info--upload--label">
                    Attachment
                  </p>
                  <Upload
                    files={files}
                    onChangeFiles={handleChangeFiles}
                    documents={notificationTemplate?.attachmentFiles || []}
                    onChangeDocuments={(documents: Array<string>) => {
                      setNotificationTemplate({
                        ...notificationTemplate,
                        attachmentFiles: documents
                      });
                    }}
                    onErrorFiles={(errorMessage: string) => {
                      if (errorMessage === ERROR_MESSAGE_LIMIT_SIZE) {
                        toast.error('Exceed the size');
                      } else if (errorMessage === ERROR_MESSAGE_LIMIT_FILES) {
                        toast.error('Exceed the number of files');
                      }
                    }}
                  />
                </div>
              </AppCardContent>
            </div>
          </AppCard>
        </div>

        <div className="notificationTemplateView__actions">
          {hasPermission(PERMISSION.UPDATE_NOTIFICATION_TEMPLATE) && (
            <AppButton isLoading={loading} onClick={handleSubmit(handleUpdate)}>
              Save
            </AppButton>
          )}
          <AppButton variant="secondary" onClick={handleCloseForm}>
            Cancel
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default NotificationTemplateView;
