import {
  ASSESSMENT_REVIEW_STATUS,
  ASSESSMENT_STATUS
} from 'common/enums/assessment.enum';
import { BOOKING_TYPE, PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { IStudentFilter } from './interface';

export const initFilterValue: IStudentFilter = {
  programType: CLASS_TYPES.NONE,
  enrollmentType: BOOKING_TYPE.NONE,
  dateField: false,
  dateFrom: '',
  dateTo: '',
  timeField: false,
  timeFrom: '',
  timeTo: '',
  levelId: '',
  instructorId: '',
  termId: '',
  locationId: '',
  areaId: '',
  assessmentResult: ASSESSMENT_STATUS.IDLE,
  assessmentStatus: ASSESSMENT_REVIEW_STATUS.IDLE,
  paymentType: PAYMENT_VALUE.IDLE,
  outstandingFee: ''
};
