import React, { useCallback, useEffect, useState } from 'react';
import styles from './calendar/scss/index.module.scss';
import './index.scss';
import Header from './calendar/Header';
import WeekViewNew from './calendar/WeekViewNew';
import DayViewNew from './calendar/DayViewNew';
import dayjs from 'dayjs';
import { VIEW_MODE } from 'common/constants/index';
import FilterCalendar from './calendar/FilterCalendar';
import AddSaveFilterModal from './calendar/AddSaveFilterModal';
import SavedFilterComponent from './calendar/SavedFilterComponent';
import {
  FilterValue,
  FilterValuePayload,
  ISearchCalendar,
  SavedFilterList
} from './calendar/types';
import MonthView from './calendar/MonthView';
import ColorSetting from './calendar/ColorSetting';
import {
  HiOutlineChevronRight,
  HiOutlineChevronLeft,
  HiOutlineCalendar,
  HiOutlineStar,
  HiChevronDown,
  HiOutlineCheck
} from 'react-icons/hi';
import { GoFilter } from 'react-icons/go';
import {
  createFilterDashboard,
  deleteFilterDashboard,
  getFilterList,
  getScheduleCalendar,
  searchScheduleCalendar
} from 'services/dashboard.service';
import { useToast } from 'context/ToastContext';
import {
  IColorSetting,
  IFilterDashboard
} from 'common/interfaces/dashboard.interface';
import { convertArrayToString } from 'common/helpers/string.helper';
import { useBrandLocation } from 'context/BrandLocationContext';
import { getInstructorList } from 'services/instructor.service';
import {
  getLevelBreakdowns,
  updateLevelSetting
} from 'services/levelBreakdown.service';
import { isEmpty, pickBy, sortBy } from 'lodash';
import { convertToUnixTime } from 'common/helpers/time.helper';
import Schedule from './calendar/Schedule';
import { BeatLoader } from 'react-spinners';
import HeaderDashboard from './HeaderDashboard';
import { useLayout } from 'context/LayoutContext';
import {
  ILevelBreakdown,
  LevelSetting
} from 'common/interfaces/levelBreakdown.interface';
import ColorLevelQuickFilter from 'components/colorLevelFilter/ColorLevelQuickFilter';
import useDebounce from 'common/hooks/useDebounce';
import { IInstructor } from 'common/interfaces/instructor.interface';
import SearchResult from './SearchResult';
import { IClass } from 'common/interfaces/class.interface';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { ITerm } from 'common/interfaces/term.interface';
import { getTerms } from 'services/term.service';
import { TERM_TYPE } from 'common/enums/term.enum';
import Popup from 'reactjs-popup';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import { useDashboardContext } from './DashboardContext';
import AppToggle from 'common/components/AppToggle';
import AppCheckbox from 'common/components/AppCheckbox';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import { useAuth } from 'context/AuthContext';
import { getDaysArray } from 'common/helpers/classBooking.helper';

const DashboardComponent: React.FC = () => {
  const {
    selectedLevel,
    onChangeSelectedDayForPreview,
    onChangeSelectedLevel,
    onChangeSelectedTime,
    onChangeClassesDate
  } = useDashboardContext();
  const { hasPermission } = useAuth();
  const now = new Date();
  const today = new Date(now.toISOString());
  const [startDate, setStartDate] = useState<Date>(today);
  const [selectedDate, setSelectedDay] = useState<Date>(today);
  const [month, setMonth] = useState<number>(now.getMonth() + 1);
  const [year, setYear] = useState<number>(now.getFullYear());
  const [calendarLabel, setCalendarLabel] = useState<string>('');
  const [modeView, setModeView] = useState<String>(VIEW_MODE.WEEK);
  const [dates, setDates] = useState<Date[]>([]);
  const [showModeViewSelect, setShowModeViewSelect] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showModalSaveFilter, setShowModalSaveFilter] =
    useState<boolean>(false);
  const [showSavedFilterList, setShowSavedFilterList] =
    useState<boolean>(false);
  const [currentFilter, setCurrentFilter] = useState<FilterValue>({
    classType: '',
    levelIds: '',
    instructorIds: '',
    areas: '',
    locationId: '',
    startDate: convertToUnixTime(dayjs(dates[0]).format()),
    endDate: convertToUnixTime(dayjs(dates[dates.length - 1]).format()),
    duration: '',
    vacancy: 1,
    termId: '',
    canBeBookMakeUp: false,
    applyVacancy: false
  });
  const [savedFilterList, setSavedFilterList] = useState<SavedFilterList[]>([]);
  const [colorSettings, setColorSettings] = useState<IColorSetting[]>([]);
  const [instructors, setInstructors] = useState<IInstructor[]>([]);
  const [levels, setLevels] = useState<ILevelBreakdown[]>([]);
  const [classesBySearch, setClassesBySearch] = useState<IClass[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCalendar, setLoadingCalendar] = useState<boolean>(false);

  const [vacancy, setVacancy] = useState<number>(1);

  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedVacancy = useDebounce<number>(vacancy, 800);
  const debouncedSearch = useDebounce<string>(searchValue);
  const [filterFieldNumber, setFilterFieldNumber] = useState<number>(0);

  const initialSearchAdvanceValue: ISearchCalendar = {
    filter: currentFilter,
    search: {
      what: '',
      who: '',
      fromDate: dayjs(dates[0]).format('YYYY-MM-DD'),
      toDate: dayjs(dates[dates.length - 1]).format('YYYY-MM-DD')
    },
    pagination: {
      page: 1,
      limit: 100
    }
  };
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [selectedTerm, setSelectedTerm] = useState<ITerm>({} as ITerm);
  const [terms, setTerms] = useState<ITerm[]>([]);
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
  const [searchAdvanceValue, setSearchAdvanceValue] = useState<ISearchCalendar>(
    initialSearchAdvanceValue
  );

  const toast = useToast();
  const { selectedLocation: globalLocation } = useBrandLocation();
  const { user, __isInstructorAccount } = useAuth();
  const { handleMouseLeaveMenu } = useLayout();

  const [publicHolidayDateArray, setPublicHolidayDateArray] = useState<
    string[]
  >([]);
  const [stopBillings, setStopBillings] = useState<ITerm[]>([]);

  useEffect(() => {
    const finalDates: string[] = [];
    stopBillings.forEach((item) => {
      const dateArray = getDaysArray(
        new Date(item.startDate),
        new Date(item.endDate)
      );
      const result = [...dateArray.map((item) => formatDate(item))];
      finalDates.push(...result);
    });
    setPublicHolidayDateArray(finalDates);
  }, [stopBillings]);

  useEffect(() => {
    if (currentFilter.applyVacancy)
      setCurrentFilter({ ...currentFilter, vacancy: vacancy });
    // eslint-disable-next-line
  }, [debouncedVacancy]);

  useEffect(() => {
    if (searchValue.length) {
      setSearchAdvanceValue({
        ...searchAdvanceValue,
        search: { ...searchAdvanceValue.search, what: searchValue }
      });
      searchCalendar({
        ...searchAdvanceValue,
        search: { ...searchAdvanceValue.search, what: searchValue }
      });
    }
    // eslint-disable-next-line
  }, [debouncedSearch]);

  const handleSearch = (value: any, advance?: boolean) => {
    if (advance) {
      setSearchAdvanceValue({
        ...searchAdvanceValue,
        search: {
          what: value.what,
          who: value.who,
          fromDate: value.fromDate,
          toDate: value.toDate
        }
      });
      searchCalendar({
        ...searchAdvanceValue,
        search: {
          what: value.what,
          who: value.who,
          fromDate: value.fromDate,
          toDate: value.toDate
        }
      });
    } else {
      setSearchValue(value);
    }
  };

  const searchCalendar = async (value: ISearchCalendar) => {
    setLoadingCalendar(true);
    const payload = {
      locationId: globalLocation?._id,
      classType: currentFilter.classType,
      levelIds: currentFilter.levelIds.split(',').filter((item) => item !== ''),
      instructorIds: currentFilter.instructorIds
        ?.split(',')
        .filter((item) => item !== ''),
      areas: currentFilter.areas.split(',').filter((item) => item !== ''),
      startDate: value.search.fromDate
        ? convertToUnixTime(value.search.fromDate)
        : currentFilter.startDate,
      endDate: value.search.toDate
        ? convertToUnixTime(value.search.toDate)
        : currentFilter.endDate,
      duration:
        currentFilter.duration?.length > 0
          ? Number(currentFilter.duration)
          : null
    };
    if (!payload.locationId) {
      return;
    }

    try {
      const newPayload = {
        ...pickBy(
          payload,
          (value) =>
            !isEmpty(value) ||
            (value && Array.isArray(value) && value.length) ||
            typeof value === 'number'
        )
      };

      const { data } = await searchScheduleCalendar({
        ...value,
        filter: newPayload
      });
      setClassesBySearch(
        data.map((item: IClass) => {
          return {
            ...item,
            date: dayjs(item.startTime).format('YYYY-MM-DD')
          };
        })
      );
      setLoadingCalendar(false);
      setShowSearchResult(true);
    } catch (error) {
      setLoadingCalendar(false);
      setShowSearchResult(false);
      toast.error('Search failed');
    }
  };

  useEffect(() => {
    if (loadingCalendar) {
      onChangeSelectedLevel(null);
      onChangeSelectedDayForPreview(today);
      onChangeSelectedTime('');
    }
    // eslint-disable-next-line
  }, [loadingCalendar]);

  const onClickLevel = useCallback(
    (levelId: string) => {
      const newSetting = colorSettings.map((item) => {
        if (item._id === levelId) {
          item.active = !item.active;
        }
        return item;
      });
      setColorSettings(newSetting);
      const levelIds = newSetting
        .filter((item) => item.active)
        .map((item) => item._id);
      setCurrentFilter({ ...currentFilter, levelIds: levelIds.join(',') });
    },
    [colorSettings, currentFilter]
  );

  const onSortColorSetting = useCallback(
    async (newColorSettings: IColorSetting[]) => {
      if (!user?._id || !newColorSettings.length) return;
      setColorSettings(newColorSettings);
      try {
        const payload: LevelSetting = {
          levelOrders: newColorSettings.map((item, index) => {
            return { levelId: item._id, order: index };
          })
        };
        await updateLevelSetting(user?._id, payload);
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Cannot sort color setting'
        );
      }
    },
    // eslint-disable-next-line
    [user?._id]
  );

  useEffect(() => {
    if (showSearch) {
      setShowFilter(false);
      setShowSettings(false);
      setShowSavedFilterList(false);
    }
  }, [showSearch]);

  const getDatesInWeek = useCallback(() => {
    const firstDay = new Date(startDate);
    firstDay.setHours(0, 0, 0, 0);
    const dayOfWeek = firstDay.getDay();
    const startDay =
      firstDay.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    firstDay.setDate(startDay);

    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(firstDay);
      days.push(day);
      firstDay.setDate(firstDay.getDate() + 1);
    }

    const startOfMonth = new Date(days[0]);
    const endOfMonth = new Date(days[6]);

    const startLabel = `${dayjs(startOfMonth).format('MMMM')}`;
    const endLabel = `${dayjs(endOfMonth).format('MMMM')}`;

    let calendarLabel;
    if (startLabel !== endLabel) {
      calendarLabel = `${startLabel} - ${endLabel}, ${startOfMonth.getFullYear()}`;
    } else {
      calendarLabel = `${endLabel}, ${startOfMonth.getFullYear()}`;
    }

    setCalendarLabel(calendarLabel);
    setDates([...days]);
    setMonth(startOfMonth.getMonth() + 1);
    setYear(startOfMonth.getFullYear());
  }, [startDate]);

  useEffect(() => {
    getDatesInWeek();
    setMonth(startDate.getMonth() + 1);
    setYear(startDate.getFullYear());
  }, [startDate, getDatesInWeek]);

  useEffect(() => {
    try {
      const getData = async () => {
        setLoading(true);
        await getSavedFilterList();
        setLoading(false);
      };
      getData();
      setTimeout(() => {
        handleScrollIntoView();
      }, 1000);
    } catch {}
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentFilter.levelIds.length > 0) {
      const newSetting = colorSettings.map((item) => {
        if (currentFilter.levelIds.split(',').includes(item._id)) {
          item.active = true;
        } else {
          item.active = false;
        }
        return item;
      });
      setColorSettings(newSetting);
    } else {
      setColorSettings(
        colorSettings.map((item) => ({ ...item, active: false }))
      );
    }
    // eslint-disable-next-line
  }, [currentFilter]);

  useEffect(() => {
    getClassOnCalendar();
    // eslint-disable-next-line
  }, [
    modeView,
    dates,
    selectedDate,
    month,
    year,
    currentFilter,
    globalLocation?._id,
    user
  ]);

  useEffect(() => {
    onChangeSelectedLevel(null);
    setCurrentFilter({
      classType: '',
      levelIds: '',
      instructorIds: '',
      areas: '',
      locationId: '',
      startDate: convertToUnixTime(dayjs(dates[0]).format()),
      endDate: convertToUnixTime(dayjs(dates[dates.length - 1]).format()),
      duration: '',
      vacancy: 1,
      termId: '',
      canBeBookMakeUp: false,
      applyVacancy: true
    });
    setVacancy(1);
    // eslint-disable-next-line
  }, [globalLocation?._id]);

  const fetchDataForFilter = useCallback(async () => {
    if (!globalLocation?._id) return;
    try {
      const [{ data: instructorList }, { data: levelBreakdowns }] =
        await Promise.all([
          hasPermission(PERMISSION.LIST_INSTRUCTOR) &&
            getInstructorList({
              page: 1,
              limit: 100,
              locationId: globalLocation?._id
            }),
          hasPermission(PERMISSION.LIST_LEVEL_BREAKDOWN) &&
            getLevelBreakdowns(1, 100, globalLocation?._id)
        ]);

      setInstructors(
        [...(instructorList || [])].map((item: IInstructor) => ({
          ...item,
          value: item._id,
          label: item.firstName
        }))
      );
      setColorSettings([...(levelBreakdowns?.data?.data || [])]);
      setLevels(
        [...(levelBreakdowns?.data?.data || [])].map(
          (item: ILevelBreakdown) => ({
            ...item,
            value: item._id,
            label: item.shortName || item.name
          })
        )
      );
    } catch (error) {
      console.log(error);
    }
  }, [globalLocation?._id, hasPermission]);

  useEffect(() => {
    fetchDataForFilter();
  }, [fetchDataForFilter]);

  const getClassOnCalendar = useCallback(async () => {
    if (modeView === VIEW_MODE.WEEK && isEmpty(dates)) {
      return;
    }
    setLoadingCalendar(true);
    if (!user?._id) return;
    const payload: FilterValuePayload = {
      locationId: globalLocation?._id,
      classType: currentFilter.classType,
      levelIds: currentFilter.levelIds.split(',').filter((item) => item !== ''),
      instructorIds: __isInstructorAccount
        ? [user?._id || '']
        : currentFilter.instructorIds?.split(',').filter((item) => item !== ''),
      areas: currentFilter.areas.split(',').filter((item) => item !== ''),
      startDate: currentFilter.startDate,
      endDate: currentFilter.endDate,
      vacancy: Number(currentFilter.vacancy),
      duration:
        currentFilter.duration?.length > 0
          ? Number(currentFilter.duration)
          : null,
      termId: selectedTerm._id,
      canBeBookMakeUp: currentFilter.canBeBookMakeUp
    };
    if (!payload.locationId) {
      return;
    }
    if (currentFilter.applyVacancy) {
      payload.vacancy = Number(currentFilter.vacancy);
    } else {
      delete payload.vacancy;
    }
    switch (modeView) {
      case VIEW_MODE.WEEK:
        payload.startDate = convertToUnixTime(
          dayjs(dates[0]).format('YYYY-MM-DD')
        );
        payload.endDate = convertToUnixTime(
          dayjs(dates[dates.length - 1]).format('YYYY-MM-DD')
        );
        break;
      case VIEW_MODE.MONTH:
        const firstDayOfMonth = new Date(year, month - 1, 1);
        const lastDayOfMonth = new Date(year, month, 0);
        const daysInMonth = lastDayOfMonth.getDate();

        const startDayOfWeek = firstDayOfMonth.getDay(); // 0 (Sunday) to 6 (Saturday)
        const endDayOfWeek = lastDayOfMonth.getDay();

        const firstDay = new Date(year, month - 1, 1 - startDayOfWeek);
        const lastDay = new Date(
          year,
          month - 1,
          daysInMonth + (6 - endDayOfWeek)
        );
        payload.startDate = convertToUnixTime(
          dayjs(firstDay).format('YYYY-MM-DD')
        );
        payload.endDate = convertToUnixTime(
          dayjs(lastDay).format('YYYY-MM-DD')
        );
        break;
      case VIEW_MODE.DAY:
        payload.startDate = convertToUnixTime(
          dayjs(selectedDate).format('YYYY-MM-DD')
        );
        payload.endDate = convertToUnixTime(
          dayjs(selectedDate).format('YYYY-MM-DD')
        );
        break;
      default:
        break;
    }
    let filterFieldNumber = 0;
    if (payload.classType) {
      filterFieldNumber++;
    }
    if (payload.levelIds.length > 0) {
      filterFieldNumber++;
    }
    if (payload.instructorIds.length > 0 && !__isInstructorAccount) {
      filterFieldNumber++;
    }
    if (payload.areas.length > 0) {
      filterFieldNumber++;
    }
    if (payload.duration) {
      filterFieldNumber++;
    }
    setFilterFieldNumber(filterFieldNumber);
    setSearchAdvanceValue({
      ...searchAdvanceValue,
      search: {
        ...searchAdvanceValue.search,
        fromDate: dayjs(payload.startDate).format('YYYY-MM-DD'),
        toDate: dayjs(payload.endDate).format('YYYY-MM-DD')
      }
    });

    try {
      const newPayload = {
        ...pickBy(
          payload,
          (value) =>
            !isEmpty(value) ||
            (value && Array.isArray(value) && value.length) ||
            typeof value === 'number' ||
            typeof value === 'boolean'
        )
      };

      const response = await getScheduleCalendar(newPayload);
      onChangeClassesDate(response);
    } catch {
      toast.error('Failed to load calendar');
    } finally {
      setLoadingCalendar(false);
    }
    // eslint-disable-next-line
  }, [
    currentFilter,
    dates,
    modeView,
    year,
    month,
    globalLocation,
    selectedTerm,
    user?._id,
    __isInstructorAccount
  ]);

  const fetchTerms = useCallback(async () => {
    if (!globalLocation?._id) return;
    try {
      const { data: stopBillings } = await getTerms(
        1,
        100,
        globalLocation?._id,
        undefined,
        [TERM_TYPE.STOP_BILLING]
      );
      setStopBillings(
        sortBy(stopBillings.data.data, ['startDate', 'endDate']) as ITerm[]
      );

      const { data } = await getTerms(
        1,
        100,
        globalLocation?._id,
        undefined,
        [TERM_TYPE.TERM],
        true
      );
      setTerms(sortBy(data.data.data, ['startDate', 'endDate']) as ITerm[]);
      setSelectedTerm({} as ITerm);
    } catch (error: any) {
      setTerms([]);
      setSelectedTerm({} as ITerm);
      toast.error(error?.response?.data?.message || 'Failed to load sessions');
    }
    // eslint-disable-next-line
  }, [globalLocation?._id]);

  useEffect(() => {
    fetchTerms();
  }, [fetchTerms]);

  const handleSelectTerm = useCallback(
    (item: ITerm) => {
      if (item._id === selectedTerm._id) {
        setSelectedTerm({} as ITerm);
        setCurrentFilter({ ...currentFilter, termId: '' });
        setStartDate(dayjs().toDate());
        setSelectedDay(dayjs().toDate());
      } else {
        setSelectedTerm(item);
        setCurrentFilter({
          ...currentFilter,
          termId: item._id
        });
        setStartDate(dayjs(item.startDate).toDate());
        setSelectedDay(dayjs(item.startDate).toDate());
      }
      setShowTerms(false);
    },
    [currentFilter, selectedTerm]
  );

  const onClickAllLevel = () => {
    const newSetting = colorSettings.map((item) => {
      item.active = false;
      return item;
    });
    setColorSettings(newSetting);
    setCurrentFilter({ ...currentFilter, levelIds: '' });
  };

  const getSavedFilterList = async () => {
    try {
      const response = await getFilterList({ page: 1, limit: 100 });
      setSavedFilterList(
        response.data.map((item: IFilterDashboard) => {
          return {
            ...item,
            levelIds: convertArrayToString(item.levelIds),
            instructorIds: convertArrayToString(item.instructorIds),
            areas: convertArrayToString(item.areas)
          };
        })
      );
    } catch {
      setSavedFilterList([]);
    }
  };

  const handleScrollIntoView = useCallback(() => {
    const elm = document.getElementsByClassName('current-time-text')[0];
    elm?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, []);

  useEffect(() => {
    onChangeSelectedLevel(null);
    // eslint-disable-next-line
  }, [modeView]);

  const handleGetPreviousData = useCallback(() => {
    const currentDate = selectedDate.setDate(selectedDate.getDate() - 1);
    const startDateTemp = new Date(startDate);
    switch (modeView) {
      case VIEW_MODE.DAY:
        setSelectedDay(new Date(currentDate));
        setStartDate(new Date(currentDate));
        break;
      case VIEW_MODE.WEEK:
        setStartDate(
          new Date(startDateTemp.setDate(startDateTemp.getDate() - 7))
        );
        break;
      case VIEW_MODE.MONTH:
        setStartDate(
          new Date(startDateTemp.setMonth(startDateTemp.getMonth() - 1))
        );
        break;

      default:
        break;
    }
  }, [selectedDate, startDate, modeView]);
  const handleGetNextData = useCallback(() => {
    const startDateTemp = new Date(startDate);
    const currentDate = selectedDate.setDate(selectedDate.getDate() + 1);
    switch (modeView) {
      case VIEW_MODE.DAY:
        setSelectedDay(new Date(currentDate));
        setStartDate(new Date(currentDate));
        break;
      case VIEW_MODE.WEEK:
        setStartDate(
          new Date(startDateTemp.setDate(startDateTemp.getDate() + 7))
        );
        break;
      case VIEW_MODE.MONTH:
        const date = new Date(
          startDateTemp.setMonth(startDateTemp.getMonth() + 1, 28)
        );
        setStartDate(date);
        break;

      default:
        break;
    }
  }, [selectedDate, startDate, modeView]);

  const handleClickTodayBtn = useCallback(() => {
    setStartDate(new Date());
    setSelectedDay(new Date());
    setMonth(new Date().getMonth() + 1);
  }, [setStartDate, setSelectedDay, setMonth]);
  const changeModeView = useCallback(
    (mode: String) => {
      if (mode !== modeView) {
        setShowModeViewSelect(false);
        setMonth(new Date(selectedDate).getMonth() + 1);
        setSelectedDay(new Date());
        setStartDate(new Date());
        setModeView(mode);
      }
    },
    [modeView, selectedDate, setModeView, setMonth, setSelectedDay]
  );
  const renderMonthLabel = useCallback(() => {
    switch (modeView) {
      case VIEW_MODE.DAY:
        return dayjs(selectedDate).format('dddd, MMMM D, YYYY');
      case VIEW_MODE.MONTH:
        return dayjs(startDate).format('MMMM, YYYY');
      case VIEW_MODE.WEEK:
        return calendarLabel;
      default:
        break;
    }
  }, [startDate, selectedDate, modeView, calendarLabel]);
  const handleFilter = useCallback(
    (value: FilterValue, saveFilter?: boolean) => {
      setCurrentFilter(value);
      if (saveFilter) {
        setShowModalSaveFilter(true);
      }
      setShowFilter(false);
    },
    [setCurrentFilter, setShowModalSaveFilter, setShowFilter]
  );
  const handleAddToSavedFilter = useCallback(
    (name: string) => {
      const payload = {
        classType: currentFilter.classType,
        levelIds: currentFilter.levelIds
          .split(',')
          .filter((item) => item !== ''),
        instructorIds: currentFilter.instructorIds
          .split(',')
          .filter((item) => item !== ''),
        areas: currentFilter.areas.split(',').filter((item) => item !== ''),
        name: name
      };
      const newPayload = {
        ...pickBy(
          payload,
          (value) =>
            !isEmpty(value) ||
            (value && Array.isArray(value) && value.length) ||
            typeof value === 'number'
        )
      };
      try {
        createFilterDashboard({ ...newPayload }).then((response) => {
          setSavedFilterList([...savedFilterList, response.data]);
          toast.success('We’ve saved this filter successfully.');
          getSavedFilterList();
        });
      } catch (error) {
        toast.error('Failed to create saved filter');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFilter, savedFilterList]
  );

  const handleToggleFilterMakeupVacancy = useCallback(() => {
    setCurrentFilter({
      ...currentFilter,
      canBeBookMakeUp: !currentFilter.canBeBookMakeUp
    });
  }, [currentFilter]);

  const handleToggleFilterVacancy = useCallback(() => {
    setCurrentFilter({
      ...currentFilter,
      applyVacancy: !currentFilter.applyVacancy,
      vacancy: vacancy
    });
  }, [currentFilter, vacancy]);

  const handleClickFilterOption = (option: FilterValue) => {
    setCurrentFilter({
      ...currentFilter,
      ...option
    });
    setShowSavedFilterList(false);
  };
  const handleOnClickDate = (date: Date) => {
    setSelectedDay(date);
    setModeView(VIEW_MODE.DAY);
  };
  const handleDeleteOption = (option: any) => {
    try {
      deleteFilterDashboard(option._id).then(() => {
        getSavedFilterList();
      });
    } catch (error) {
      toast.error('Failed to delete saved filter');
    }
  };
  const onColorSettingSuccess = () => {
    getClassOnCalendar();
  };

  const handleToggleTerm = useCallback(() => {
    setShowTerms((preValue) => !preValue);
  }, []);

  const renderCalendar = useCallback(() => {
    if (loadingCalendar) {
      return (
        <div className="loading-page">
          <BeatLoader color="white" />
        </div>
      );
    }
    if (modeView === VIEW_MODE.DAY) {
      return (
        <div className="calendar-wrapper">
          <div className="calendar-wrapper-left">
            <DayViewNew
              date={selectedDate}
              publicHolidayDateArray={publicHolidayDateArray}
            />
          </div>
          {selectedLevel && <Schedule />}
        </div>
      );
    } else if (modeView === VIEW_MODE.WEEK) {
      return (
        <div className="calendar-wrapper">
          <div className="calendar-wrapper-left">
            <Header
              dates={dates}
              handleSelectDate={(value) => {
                value && setSelectedDay(value);
                setModeView(VIEW_MODE.DAY);
              }}
              publicHolidayDateArray={publicHolidayDateArray}
            />
            <div className="calendar-scroll-week">
              <WeekViewNew dates={dates} />
            </div>
          </div>
          {selectedLevel && <Schedule />}
        </div>
      );
    } else if (modeView === VIEW_MODE.MONTH) {
      return (
        <div className="calendar-wrapper">
          <div className="calendar-wrapper-left">
            <MonthView
              month={month}
              year={year}
              onClickDate={handleOnClickDate}
              publicHolidayDateArray={publicHolidayDateArray}
            />
          </div>
          {selectedLevel && <Schedule monthView={true} />}
        </div>
      );
    }
  }, [
    modeView,
    month,
    year,
    dates,
    loadingCalendar,
    selectedLevel,
    selectedDate,
    publicHolidayDateArray
  ]);

  if (showSearchResult) {
    return (
      <div
        className="dashboard-content-detail"
        onMouseEnter={handleMouseLeaveMenu}
      >
        <HeaderDashboard
          handleSearch={handleSearch}
          searchValue={searchValue}
          setShowSearch={setShowSearch}
          searchAdvanceValue={searchAdvanceValue}
        />
        <div className="dashboard-content">
          <SearchResult
            goBack={() => setShowSearchResult(false)}
            searchAdvanceValue={searchAdvanceValue}
            classesData={classesBySearch}
          />
        </div>
      </div>
    );
  }

  const renderFilter = () => {
    return (
      <FilterCalendar
        handleFilter={handleFilter}
        goBack={() => setShowFilter(false)}
        setVacancy={setVacancy}
        showFilter={showFilter}
        currentFilter={currentFilter}
        areas={(globalLocation?.areas || [])?.map((item: string) => {
          return {
            value: item,
            label: item
          };
        })}
        instructors={instructors}
        levels={levels}
      />
    );
  };

  const renderAddSaveFilterModal = () => {
    return (
      <AddSaveFilterModal
        setShowModalSaveFilter={setShowModalSaveFilter}
        showModalSaveFilter={showModalSaveFilter}
        handleAddToSavedFilter={handleAddToSavedFilter}
      />
    );
  };

  const renderColorSetting = () => {
    return (
      <ColorSetting
        goBack={() => setShowSettings(false)}
        showSettings={showSettings}
        colorSettings={colorSettings}
        areas={(globalLocation?.areas || [])?.map((item: string) => {
          return {
            value: item,
            label: item
          };
        })}
        instructors={instructors}
        levels={levels}
        onSuccess={onColorSettingSuccess}
      />
    );
  };

  const renderButtonModeView = () => {
    return (
      <div className={styles.btnModeView}>
        <button
          className={styles.btnActionHeaderCalendar}
          onClick={() => setShowModeViewSelect(!showModeViewSelect)}
          onMouseLeave={() => setShowModeViewSelect(false)}
        >
          <div>
            <HiOutlineCalendar fontSize={20} />
          </div>
          <span className={styles.btnHeaderLabel}>{modeView}</span>
          <div>
            <HiChevronDown fontSize={20} />
          </div>
        </button>
        <div
          className={`modeViewSelect ${showModeViewSelect ? 'open' : ''}`}
          onMouseEnter={() => setShowModeViewSelect(true)}
          onMouseLeave={() => setShowModeViewSelect(false)}
        >
          <div
            className={styles.modeViewOption}
            onClick={() => changeModeView(VIEW_MODE.DAY)}
          >
            Day
          </div>
          <div
            className={styles.modeViewOption}
            onClick={() => changeModeView(VIEW_MODE.WEEK)}
          >
            Week
          </div>
          <div
            className={styles.modeViewOption}
            onClick={() => changeModeView(VIEW_MODE.MONTH)}
          >
            Month
          </div>
        </div>
      </div>
    );
  };
  return (
    <div
      className="dashboard-content-detail"
      onMouseEnter={handleMouseLeaveMenu}
    >
      <AppBreadCrumb items={[{ name: 'Dashboard', href: '/' }]} />
      <div className="dashboard-content" onMouseEnter={handleMouseLeaveMenu}>
        <div>
          {showModalSaveFilter && renderAddSaveFilterModal()}
          <PermissionWrapper permission={PERMISSION.LIST_FILTER}>
            {renderFilter()}
          </PermissionWrapper>
          <PermissionWrapper permission={PERMISSION.LIST_COLOR_SETTING}>
            {renderColorSetting()}
          </PermissionWrapper>
          <div className={styles.headerCalendarSetting}>
            <div className={styles.leftHeaderCalendar}>
              <button className={styles.btnToday} onClick={handleClickTodayBtn}>
                Today
              </button>
              <div className={styles.btnArrow} onClick={handleGetPreviousData}>
                <HiOutlineChevronLeft fontSize={24} />
              </div>
              <div className={styles.btnArrow} onClick={handleGetNextData}>
                <HiOutlineChevronRight fontSize={24} />
              </div>
              <div className={styles.monthLabel} onClick={handleToggleTerm}>
                {renderMonthLabel()}
                <Popup
                  open={showTerms && terms.length > 0}
                  position={['bottom left', 'top left']}
                  className="term-options-container"
                  trigger={<div></div>}
                  arrow={false}
                  on={['focus']}
                  closeOnDocumentClick={false}
                >
                  {terms.map((term: ITerm, index) => (
                    <div
                      key={index}
                      onClick={() => handleSelectTerm(term)}
                      className={`term-option-item ${
                        selectedTerm?._id === term._id ? 'active' : ''
                      } ${index === terms.length - 1 ? 'last' : ''}`}
                    >
                      <div>
                        <p>{formatData(term.name)}</p>
                        <span>
                          {formatDate(term.startDate, 'slash')} -{' '}
                          {formatDate(term.endDate, 'slash')}
                        </span>
                      </div>
                      {selectedTerm?._id === term._id && (
                        <HiOutlineCheck size={16} />
                      )}
                    </div>
                  ))}
                </Popup>
              </div>
            </div>
            <div className={styles.rightHeaderCalendar}>
              {renderButtonModeView()}
              <PermissionWrapper permission={PERMISSION.FILTER_GROUP_CLASS}>
                <button
                  className={styles.btnActionHeaderCalendar}
                  onClick={() => {
                    setShowFilter(!showFilter);
                    setShowSavedFilterList(false);
                    setShowSettings(false);
                  }}
                >
                  <GoFilter fontSize={25} />
                  <span className={styles.btnHeaderLabel}>
                    Filters
                    {filterFieldNumber > 0 && (
                      <div className="filter-field-number">
                        {filterFieldNumber}
                      </div>
                    )}
                  </span>
                </button>
              </PermissionWrapper>
              <PermissionWrapper permission={PERMISSION.LIST_FILTER}>
                <div
                  className={`${styles.btnModeView} c__select`}
                  style={{ padding: 0 }}
                >
                  {/* Button starred */}
                  <button
                    className={styles.btnActionHeaderCalendar}
                    onClick={() => setShowSavedFilterList(!showSavedFilterList)}
                  >
                    <div>
                      <HiOutlineStar fontSize={25} />
                    </div>
                    <span className={styles.btnHeaderLabel}>Favourites</span>
                    <div>
                      <HiChevronDown fontSize={20} />
                    </div>
                  </button>
                  {/* starred block */}
                  <div
                    className={`modeViewSelect ${
                      showSavedFilterList ? 'open-saved-filter' : ''
                    }`}
                  >
                    <SavedFilterComponent
                      options={savedFilterList}
                      handleClick={handleClickFilterOption}
                      handleDelete={handleDeleteOption}
                    />
                  </div>
                </div>
              </PermissionWrapper>
            </div>
          </div>
          {loading ? (
            <div className="loading-page">
              <BeatLoader color="white" />
            </div>
          ) : (
            <>
              <div className="color-setting-wrapper">
                <div className="vacancy-input">
                  <AppToggle
                    value={currentFilter.applyVacancy}
                    onChange={handleToggleFilterVacancy}
                  />
                  <span className="text">Min. Vac.</span>
                  <input
                    type="number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setVacancy(Number(e.target.value))
                    }
                    value={vacancy}
                    min={0}
                    inputMode="numeric"
                  />
                </div>
                <div className="vacancy-input">
                  <AppCheckbox
                    checked={currentFilter.canBeBookMakeUp}
                    onChange={handleToggleFilterMakeupVacancy}
                  />
                  <span>Make-up Vac.</span>
                </div>
                <PermissionWrapper permission={PERMISSION.LIST_COLOR_SETTING}>
                  <ColorLevelQuickFilter
                    colorSettings={colorSettings}
                    onClickLevel={onClickLevel}
                    onClickAllLevel={onClickAllLevel}
                    levelIds={currentFilter.levelIds}
                    onSortColorSetting={onSortColorSetting}
                  />
                </PermissionWrapper>
              </div>
              <div className="calendar--wrapper">{renderCalendar()}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
const Dashboard = React.memo(DashboardComponent);
export default Dashboard;
