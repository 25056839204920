import React, { createContext, useState, useContext } from 'react';

interface LayoutContextType {
  isShowSidebar: boolean;
  isHoverMenu: boolean;
  toggleCollapse: () => void;
  handleHoverMenu: () => void;
  handleMouseLeaveMenu: () => void;
}

const LayoutContext = createContext<LayoutContextType | null>(null);

export const useLayout = (): LayoutContextType => {
  const layoutContext = useContext(LayoutContext);

  if (!layoutContext) {
    throw new Error('useLayout must be used within an LayoutProvider');
  }

  return layoutContext;
};

export const LayoutProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children
}) => {
  const [isShowSidebar, setIsShowSidebar] = useState(true);
  const [isHoverMenu, setIsHoverMenu] = useState(false);

  const toggleCollapse = (): void => {
    setIsHoverMenu(!isHoverMenu);
    setIsShowSidebar(!isShowSidebar);
  };
  const handleHoverMenu = (): void => {
    setIsHoverMenu(true);
  };
  const handleMouseLeaveMenu = (): void => {
    setIsHoverMenu(false);
  };

  const layoutContextValue: LayoutContextType = {
    isShowSidebar,
    isHoverMenu,
    toggleCollapse,
    handleHoverMenu,
    handleMouseLeaveMenu
  };

  return (
    <LayoutContext.Provider value={layoutContextValue}>
      {children}
    </LayoutContext.Provider>
  );
};
