import API from './API';

export const getUserProfile = async () => {
  try {
    const response = await API.get('/users/profile');
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getUserById = (id: string) => {
  return API.get(`/users/${id}`);
};

export const verifyByEmail = (email: string) => {
  return API.get(`/users/by-email/${email}`);
};

export const checkExisted = (
  phoneNumber?: string,
  email?: string,
  signal?: AbortSignal
) => {
  return API.post(
    `/users/check-existed`,
    {
      body: {
        ...(phoneNumber ? { phoneNumber } : {}),
        ...(email ? { email } : {})
      }
    },
    undefined,
    signal
  );
};

export const updateDefaultLocation = (userId: string, locationId: string) => {
  return API.patch(`/users/default-location/${userId}`, {
    body: {
      locationId
    }
  });
};
