import React, { useMemo } from 'react';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

type Props = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
};

const BASE_URL = process.env.REACT_APP_BASE_URL;
const serverUrl = BASE_URL?.substring(0, BASE_URL.length -7)

const VersionModal: React.FC<Props> = ({ showModal, setShowModal }) => {
  const appQuery = useQuery({
    queryKey: ['app-version'],
    queryFn: () => axios.get('/version.txt')
  })

  const appVersion = useMemo(() => appQuery.data?.data, [appQuery.data])

  const serverQuery = useQuery({
    queryKey: ['server-version'],
    queryFn: async () => {
      const { data } = await axios.get(`${serverUrl}/version`)
      return data
    }
  })

  const serverVersion = useMemo(() => serverQuery.data?.data, [serverQuery.data])

  return (
    <div>
      <AppModal open={showModal} onClose={() => setShowModal(false)}>
        <AppModalTitle>About</AppModalTitle>
        {/* <AppModalContent style={{ width: '500px', overflow: 'hidden' }}>
          Release Ver. 1.0.0 - Aug 30, 2023
        </AppModalContent> */}
        <AppModalContent style={{ width: '500px', overflow: 'hidden' }}>
          App - <code>{ appVersion }</code>
        </AppModalContent>
        <AppModalContent style={{ width: '500px', overflow: 'hidden' }}>
          Server - <code>{ serverVersion }</code>
        </AppModalContent>
        <AppModalActions>
          <button onClick={() => setShowModal(false)}>Close</button>
        </AppModalActions>
      </AppModal>
    </div>
  );
};
export default VersionModal;
