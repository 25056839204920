import {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  memo,
  ChangeEvent,
  useMemo
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import AppCard, { AppCardContent } from 'common/components/AppCard';
import { GoArrowSwitch } from 'react-icons/go';
import { useToast } from 'context/ToastContext';
import {
  getSTMakeupCreditHistory,
  getStudentDetail,
  updateSTmakeUpCredit
} from 'services/students.service';
import {
  ISTMoneyCreditHistory,
  IStudent
} from 'common/interfaces/student.interface';
import {
  STATUS_TYPE_LABEL,
  ENROLLMENT_STATUS
} from 'common/enums/student.enum';
import { COLORS_STATUS } from 'common/constants/colors.constant';
import {
  formatData,
  formatDate,
  formatMoneySign
} from 'common/helpers/dataFormat.helper';
import { BeatLoader } from 'react-spinners';
import { FLOW_ENUM } from 'common/enums/step.enum';
import { useStepController } from 'context/StepControllerContext';
import { IResponsiblePerson } from 'common/interfaces/responsiblePerson.interface';
import { getResponsiblePersonDetail } from 'services/responsiblePerson.service';
import { useMoveBooking } from 'context/MoveBookingContext';
import { REFUND_CREDIT_TYPE } from 'common/enums/absence.enum';
import AppTable from 'common/components/AppTable';
import AppUpdateForm from 'components/AppUpdateForm';
import { HiChevronRight, HiOutlineCreditCard } from 'react-icons/hi2';
import dayjs from 'dayjs';
import AppModal, {
  AppModalFormActions,
  AppModalFormContent,
  AppModalFormTitle
} from 'common/components/AppModal';
import AppButton from 'common/components/AppButton';
import { Resolver, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from 'validators/student.validator';
import AppRadio from 'common/components/AppRadio';
import AppInput from 'common/components/AppInput';
import { UPDATE_MONEY_CREDIT_ACTIONS } from 'common/enums/responsiblePerson.enum';
import { UpdateFormSToneyCreditDto } from 'DTOs/student.dto';
import { useBrandLocation } from 'context/BrandLocationContext';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useAuth } from 'context/AuthContext';

import './desktop.scss';

export interface RefetchHandle {
  refetch: () => void;
}

const validationSchema = yup.OBJECT({
  value: yup.STUDENT_MAKE_UP_CREDIT_VALUE
});

const SummaryInformation = forwardRef<RefetchHandle>((_, ref) => {
  useImperativeHandle(ref, () => ({
    refetch() {
      handleResetForm();
    }
  }));

  const { selectedLocation: globalLocation } = useBrandLocation();
  const { id } = useParams();
  const toast = useToast();
  const { dataSet } = useStepController();
  const navigate = useNavigate();
  const { onChangeMoveBookingCount } = useMoveBooking();
  const { hasPermission } = useAuth();

  const [initLoad, setInitLoad] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [data, setData] = useState<IStudent | null>(null);
  const [RPdata, setRPdata] = useState<IResponsiblePerson | null>(null);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(-1);

  const [optionSelected, setOptionSelected] = useState(true);

  const onChangeOptionSelected = useCallback(() => {
    setOptionSelected((pre) => !pre);
  }, []);

  const [moneyCreditHistory, setMoneyCreditHistory] = useState<
    ISTMoneyCreditHistory[]
  >([]);

  const [openMoneyHistory, setOpenMoneyHistory] = useState(false);

  const onOpenMoneyHistory = useCallback(() => {
    setOpenMoneyHistory(true);
  }, []);

  const onCloseMoneyHistory = useCallback(() => {
    setOpenMoneyHistory(false);
  }, []);

  const [openEditMoneyCredit, setOpenMoneyCredit] = useState(false);

  const onOpenMoneyCredit = useCallback(() => {
    setOpenMoneyCredit(true);
  }, []);

  const onCloseMoneyCredit = useCallback(() => {
    setOpenMoneyCredit(false);
  }, []);

  const __moneyDisplay = useCallback(
    (history: ISTMoneyCreditHistory): string => {
      let result = '';

      if (history?.amount >= 0) {
        result = '+ ';
      }

      if (history?.amount < 0) {
        result = '- ';
      }

      if (history?.refundCreditType === REFUND_CREDIT_TYPE.MONEY_CREDIT) {
        result += `${formatMoneySign(Math.abs(history?.amount))}`;
      }

      if (history?.refundCreditType === REFUND_CREDIT_TYPE.MAKEUP_CREDIT) {
        let extend = 'MAKEUP';

        if (history?.amount > 1) extend += 'S';

        result += `${Math.abs(history?.amount)} ${extend}`;
      }

      return result;
    },
    []
  );

  const handleBooking = useCallback(() => {
    if (
      dataSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
      (dataSet.step === 0 || dataSet.step === 1) &&
      RPdata
    ) {
      dataSet.handleSelectRP(RPdata as IResponsiblePerson);

      navigate(`/classes`);
    }
  }, [navigate, dataSet, RPdata]);

  useEffect(() => {
    if (dataSet?.flow === FLOW_ENUM.INIT && RPdata) {
      dataSet.setFlow(FLOW_ENUM.CLASS_BOOKING);
    }
  }, [dataSet, RPdata]);

  const {
    register,
    setValue,
    reset,
    clearErrors,
    trigger,
    control,
    formState: { errors },
    handleSubmit
  } = useForm<UpdateFormSToneyCreditDto>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<UpdateFormSToneyCreditDto>
  });

  const watchAllFields = useWatch({ control });

  const [disabled, setDisabled] = useState(false);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // @ts-ignore
      setValue(event.target.name, event.target.value);

      // @ts-ignore
      trigger(event.target.name);
    },
    [setValue, trigger]
  );

  const handleResetForm = useCallback(async () => {
    clearErrors();

    reset({
      value: undefined
    });

    await fetchData();
    await fetchMoneyCreditHistory();

    // eslint-disable-next-line
  }, [clearErrors, reset]);

  const __disableEditMoneyCredit = useMemo((): boolean => {
    const fieldValue = watchAllFields?.value && watchAllFields?.value > 0;

    return !!fieldValue;
  }, [watchAllFields]);

  const onSubmit = useCallback(
    async (data: UpdateFormSToneyCreditDto) => {
      if (!id) return;

      if (disabled) {
        trigger();

        return;
      }

      try {
        setLoadData(true);
        setDisabled(true);

        await updateSTmakeUpCredit(id, {
          action: optionSelected
            ? UPDATE_MONEY_CREDIT_ACTIONS.ADD_MONEY_CREDIT
            : UPDATE_MONEY_CREDIT_ACTIONS.REMOVE_MONEY_CREDIT,
          value: data?.value
        });

        toast.success('Update make up credit successfully');

        await handleResetForm();

        onCloseMoneyCredit();
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Update make up credit failed'
        );
      } finally {
        setDisabled(false);
        setLoadData(false);
      }
    },

    // eslint-disable-next-line
    [id, disabled, trigger, handleResetForm, optionSelected, onCloseMoneyCredit]
  );

  const fetchMoneyCreditHistory = useCallback(async () => {
    try {
      if (
        !id ||
        !globalLocation?._id ||
        !hasPermission(PERMISSION.LIST_MAKE_UP_CREDIT_HISTORIES)
      )
        return;

      setLoadData(true);

      const { data } = await getSTMakeupCreditHistory(id, {
        page: page,
        limit: limit
      });

      setMoneyCreditHistory(data?.data?.data);
      setTotal(data?.data?.total);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fetch money credit history failed'
      );

      setData(null);
    } finally {
      setLoadData(false);
    }

    // eslint-disable-next-line
  }, [id, page, limit, globalLocation?._id, hasPermission]);

  useEffect(() => {
    fetchMoneyCreditHistory();
  }, [fetchMoneyCreditHistory]);

  const fetchData = useCallback(async () => {
    if (!id) return;

    try {
      const result = await getStudentDetail(id);

      setData(result);
      onChangeMoveBookingCount(result?.moveBookingCount || 0);
      if (!result?.listResponsiblePerson?.[0]?.responsiblePersonInfo?._id)
        return;

      const { data } = await getResponsiblePersonDetail(
        result?.listResponsiblePerson?.[0]?.responsiblePersonInfo?._id
      );

      setRPdata(data?.data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fetch student detail failed'
      );

      setData(null);
    } finally {
      setLoadData(false);

      if (initLoad) setInitLoad(false);
    }

    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (initLoad) {
    return <AppLoadingContainer />;
  }

  return (
    <section className="summary-info">
      <PermissionWrapper permission={PERMISSION.LIST_MAKE_UP_CREDIT_HISTORIES}>
        <AppUpdateForm
          tittle="History"
          openEdit={openMoneyHistory}
          onCloseEdit={onCloseMoneyHistory}
          footer={<></>}
        >
          {moneyCreditHistory?.length ? (
            <div className="moneyCreditHistory">
              {moneyCreditHistory?.map((history, key) => {
                return (
                  <div key={key} className="moneyCreditHistory__item">
                    <div className="tag">
                      <HiOutlineCreditCard />
                    </div>

                    <div className="content">
                      <div className="tittle">
                        {formatData(history?.message)}
                      </div>

                      <div className="date">{`${formatDate(
                        history?.createdAt
                      )} ${formatData(
                        dayjs(history?.createdAt)?.format('HH:mm')
                      )}`}</div>
                    </div>

                    <div
                      className={`moneyText ${
                        history?.amount > 0 ? 'green' : ''
                      } ${history?.amount < 0 ? 'red' : ''}`}
                    >
                      {__moneyDisplay(history)}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}

          <AppTable
            data={moneyCreditHistory}
            pagination={{
              index: page,
              size: limit,
              total: total
            }}
            onChangePage={(index, size) => {
              setPage(index);
              setLimit(size);
            }}
          />
        </AppUpdateForm>
      </PermissionWrapper>

      <AppModal open={openEditMoneyCredit} onClose={onCloseMoneyCredit}>
        <AppModalFormTitle>Makeup (s)</AppModalFormTitle>
        {loadData ? (
          <AppLoadingContainer />
        ) : (
          <>
            <AppModalFormContent>
              <div className="options">
                <AppRadio
                  checked={optionSelected}
                  label="Add makeup (s)"
                  onChange={onChangeOptionSelected}
                />

                <AppRadio
                  checked={!optionSelected}
                  label="Remove makeup (s)"
                  onChange={onChangeOptionSelected}
                />
              </div>

              <div className="inputs">
                <AppInput
                  {...register('value')}
                  type="number"
                  min={0}
                  max={10000}
                  label="Number of make up (s)*"
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.value?.message || ''
                  }}
                />
              </div>
            </AppModalFormContent>
            <AppModalFormActions>
              <AppButton
                buttonSize="small"
                variant="secondary"
                disabled={loadData}
                onClick={onCloseMoneyCredit}
              >
                cancel
              </AppButton>
              <AppButton
                buttonSize="small"
                variant={__disableEditMoneyCredit ? 'primary' : 'disabled'}
                isLoading={loadData}
                onClick={handleSubmit(onSubmit)}
              >
                Confirm
              </AppButton>
            </AppModalFormActions>
          </>
        )}
      </AppModal>

      <AppCard>
        <AppCardContent className="card-content">
          {loadData ? (
            <div className="pageLoading">
              <div className="pageLoading-main">
                <BeatLoader />
              </div>
            </div>
          ) : null}

          <div className="summary-info_header">
            <div className="summary-info_name">
              {formatData(data?.firstName)}
            </div>
            <div className="summary-info_button">
              {/* <div>
              <HiOutlineUserCircle size={22} />
              <div>Entry</div>
            </div> */}

              {/* <div>
              <GoNote size={22} />
              <div>Note</div>
            </div> */}

              {!!data?.totalCredit && !!RPdata ? (
                <PermissionWrapper permission={PERMISSION.BOOKING_CLASS}>
                  <div style={{ cursor: 'pointer' }} onClick={handleBooking}>
                    <GoArrowSwitch size={22} />
                    <div>Makeup Class</div>
                  </div>
                </PermissionWrapper>
              ) : null}
            </div>
          </div>

          <div className="summary-info_wrap">
            <div className="summary-info_wrap-content">
              {loadData ? null : (
                <div
                  className="summary-info-status"
                  style={{
                    backgroundColor: `${
                      data?.enrollmentStatus === ENROLLMENT_STATUS.ACTIVE
                        ? COLORS_STATUS.BG_BLUE
                        : COLORS_STATUS.BG_RED
                    }`
                  }}
                >
                  <div>
                    {data?.enrollmentStatus === ENROLLMENT_STATUS.ACTIVE
                      ? STATUS_TYPE_LABEL.ACTIVE
                      : STATUS_TYPE_LABEL.INACTIVE}
                  </div>
                </div>
              )}
              <div className="summary-info-item">
                <span className="summary-info-item_label">Gender: </span>
                <span className="summary-info-item_value">
                  {formatData(data?.gender)}
                </span>
              </div>
              <div className="summary-info-item">
                <span className="summary-info-item_label">Current Level: </span>
                <span className="summary-info-item_value">
                  {formatData(
                    data?.actualLevel?.name || data?.enrolledLevel?.name
                  )}
                </span>
              </div>

              <PermissionWrapper
                permission={PERMISSION.LIST_MAKE_UP_CREDIT_HISTORIES}
              >
                <div className="summary-info-item">
                  <button
                    className="moneyCreditHistory_btn"
                    onClick={onOpenMoneyCredit}
                  >
                    <span className="summary-info-item_label">Makeup (s):</span>
                    <span className="summary-info-item_value">
                      {formatData(data?.totalCredit)}
                    </span>
                    <HiChevronRight size={16} />
                  </button>

                  <button
                    className="moneyCreditHistory_btn"
                    onClick={onOpenMoneyHistory}
                  >
                    <div className="text">History</div>
                    <HiChevronRight size={16} />
                  </button>
                </div>
              </PermissionWrapper>
            </div>
          </div>
        </AppCardContent>
      </AppCard>
    </section>
  );
});

export default memo(SummaryInformation);
