import React, { Fragment, useCallback, useMemo, useState } from 'react';
import './desktop.scss';
import {
  formatData,
  formatDate,
  formatMoneySign,
  formatTime,
  roundByTwo
} from 'common/helpers/dataFormat.helper';
import dayjs, { Dayjs } from 'dayjs';
import { cloneDeep, forEach, round } from 'lodash';
import {
  IBookingData,
  ISchedule,
  NextBillingCircle
} from 'common/interfaces/schedules.interface';
import { BOOKING_TYPE, PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import { PAYMENT_CARDS } from 'common/constants/classBooking.constant';
import AppModal from 'common/components/AppModal';
import { HiOutlineX } from 'react-icons/hi';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { IVoucher } from 'common/interfaces/voucher.interface';
import ReviewBookingDataRow from './ReviewBookingDataRow';
import { DiscountData } from 'common/interfaces/bookingClass.interface';
import { useMoveBooking } from 'context/MoveBookingContext';
import { useBrandLocation } from 'context/BrandLocationContext';
import { HiArrowRight, HiOutlineExclamationCircle } from 'react-icons/hi2';
import { IClass, IRecurrence } from 'common/interfaces/class.interface';
import {
  calculateExtraPrice,
  getNewEnrolmentSessions
} from 'helpers/moveBooking.helper';
import { ISession } from 'common/interfaces/session.interface';
import { DISCOUNT_TYPE } from 'common/enums';
import {
  AUTOMATION_DISCOUNT_TYPE,
  DISCOUNT_FROM,
  VOUCHER_DURATION
} from 'common/enums/voucher.enum';
import { getAutomationDiscountText } from 'common/helpers/voucher.helper';
import { getProgramType } from 'common/helpers/index.helper';
import { handleDateTimeRecord } from 'common/helpers/classBooking.helper';
import { handleGetPayNowForMoveBooking } from 'helpers/enrolment.helper';
import { getLessonsDayInRange } from 'common/helpers/classListBooking.helper';

interface Props {
  nextDebitDay: string;
}
const ReviewNPayment: React.FC<Props> = ({ nextDebitDay }: Props) => {
  const {
    studentDataBooking,
    onChangeStudentDataBooking,
    currentEnrolmentClass,
    __totalPayNow,
    // __discountData,
    __discountApplied,
    newEnrolmentClass,
    __moneyCredit
  } = useMoveBooking();

  const { locations } = useBrandLocation();

  const [showNextBillDay, setShowNextBillDay] = useState<boolean>(false);
  const [studentName, setStudentName] = useState<string>('');
  const [nextBillingCircle, setNextBillingCircle] = useState<
    NextBillingCircle[]
  >([]);

  const [numberOfFreeLessonFromMove, setNumberOfFreeLessonFromMove] =
    useState<number>(0);
  const __discountData = useMemo(() => {
    if (!studentDataBooking?.bookingData) return [];
    const bookingData = cloneDeep(studentDataBooking.bookingData[0]);
    const result = [];
    if (bookingData.paymentOption === PAYMENT_VALUE.IDLE) {
      return [];
    }
    const lastLesson =
      newEnrolmentClass?.schedules[newEnrolmentClass.schedules.length - 1];
    if (__discountApplied) {
      const {
        discountType,
        discountValue,
        automationDiscountType,
        discountFrom,
        remainDiscountAmount,
        voucherCode,
        startDateFree,
        endDateFree,
        numberOfFreeLesson,
        remainFreeLesson = 0
      } = __discountApplied;

      if (
        discountFrom === DISCOUNT_FROM.AUTOMATION_DISCOUNT &&
        automationDiscountType === AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT &&
        bookingData.paymentOption === PAYMENT_VALUE.UPFRONT
      ) {
        return [];
      }
      if (discountType === DISCOUNT_TYPE.AMOUNT && remainDiscountAmount === 0) {
        return [];
      }
      let amount = 0;
      let valueDiscount = '';
      let amountDisplay = '';
      const { pricePayNow = 0, paymentOption, classInfo } = bookingData;
      if (discountType === DISCOUNT_TYPE.PERCENTAGE) {
        amount = roundByTwo(pricePayNow * (discountValue / 100));
        valueDiscount = `${discountValue}% OFF  ${
          voucherCode ? `(${voucherCode})` : ''
        }`;
        if (paymentOption === PAYMENT_VALUE.UPFRONT) {
          valueDiscount += ` UPFRONT PAYMENT`;
          if (discountFrom === DISCOUNT_FROM.AUTOMATION_DISCOUNT) {
            valueDiscount += ` (${getAutomationDiscountText(
              automationDiscountType
            )})`;
          }
        } else {
          valueDiscount += ` EVERY DD`;
          amount = roundByTwo(classInfo.price * 2 * (discountValue / 100));
          if (discountFrom === DISCOUNT_FROM.AUTOMATION_DISCOUNT) {
            valueDiscount += ` (${getAutomationDiscountText(
              automationDiscountType
            )})`;
          }
        }
      } else if (discountType === DISCOUNT_TYPE.AMOUNT) {
        amount = roundByTwo(remainDiscountAmount);
        valueDiscount = `${formatMoneySign(
          discountValue
        )} OFF (${voucherCode})`;
      } else if (discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
        if (__discountApplied.duration === VOUCHER_DURATION.TIME_RANGE) {
          if (bookingData.paymentOption === PAYMENT_VALUE.DIRECT_DEBIT) {
            amountDisplay = '--';
          }
          if (bookingData.paymentOption === PAYMENT_VALUE.UPFRONT) {
            const startDate = endDateFree || lastLesson?.startTime;
            const lessonsFree = getLessonsDayInRange(
              newEnrolmentClass?.schedules as ISchedule[],
              dayjs(startDateFree).format(),
              dayjs(startDate).format(),
              []
            );
            amount = roundByTwo(classInfo.price * lessonsFree.length);
          }
          valueDiscount = `Complimentary ${formatDate(startDateFree)} - ${
            endDateFree ? formatDate(endDateFree) : 'Ongoing'
          }`;
        } else if (
          __discountApplied.duration ===
          VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS
        ) {
          valueDiscount = `Complimentary ${numberOfFreeLesson} lessons`;
          if (paymentOption === PAYMENT_VALUE.UPFRONT) {
            amount = roundByTwo(classInfo.price * remainFreeLesson);
          } else {
            amountDisplay = '--';
          }
        }
      }

      result.push({
        description: `${valueDiscount} (${getProgramType(
          bookingData.classInfo.type
        )}) (${handleDateTimeRecord(bookingData)}) ${formatData(
          studentDataBooking.lastName
        )}, ${formatData(studentDataBooking.firstName)}`,
        amount: amount,
        amountDisplay
      });
    }
    return result;
  }, [studentDataBooking, __discountApplied, newEnrolmentClass]);
  const __totalNextDirectDebit = useMemo(() => {
    if (!studentDataBooking) return 0;
    if (
      studentDataBooking?.bookingData?.[0]?.paymentOption !==
      PAYMENT_VALUE.DIRECT_DEBIT
    )
      return 0;
    return (
      studentDataBooking?.bookingData?.[0]?.nextBillingCircle?.reduce(
        (total, item) => total + item.price || 0,
        0
      ) || 0
    );
  }, [studentDataBooking]);

  const __nextDirectDebits = React.useMemo(() => {
    return studentDataBooking?.bookingData?.[0]?.nextBillingCircle || [];
  }, [studentDataBooking]);

  const __showWarningPIF = useMemo(() => {
    if (currentEnrolmentClass?.paymentType === PAYMENT_VALUE.UPFRONT)
      return false;
    return (
      studentDataBooking?.bookingData?.[0]?.paymentOption ===
        PAYMENT_VALUE.UPFRONT &&
      studentDataBooking?.bookingData?.[0]?.enrollmentType ===
        BOOKING_TYPE.ONGOING_CLASS
    );
  }, [studentDataBooking, currentEnrolmentClass?.paymentType]);

  const __showWarningMoveToFutureTerm = useMemo(() => {
    if (newEnrolmentClass?.type === CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM)
      return false;

    const termInLesson =
      studentDataBooking?.bookingData?.[0]?.schedules[0]?.termId;
    const termIsActive = newEnrolmentClass?.template?.terms.find(
      (item) => item.termId === termInLesson
    );
    return dayjs().isBefore(termIsActive?.termDetail?.startDate);
  }, [newEnrolmentClass, studentDataBooking]);

  const handleChangePaymentOption = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: IBookingData
  ) => {
    if (!studentDataBooking) return;

    const paymentOption = event.target.value as PAYMENT_VALUE;
    let studentBookingNewData = {
      ...studentDataBooking,
      bookingData: studentDataBooking.bookingData.map((classItem) => {
        if (classItem._id === item.classInfo._id) {
          const pricePayNow = 0;
          return {
            ...item,
            paymentOption,
            pricePayNow
          };
        }
        return classItem;
      })
    };
    if (!newEnrolmentClass || !currentEnrolmentClass?.class) {
      return;
    }
    const newEnrolmentSchedulesTemp = getNewEnrolmentSessions(
      { ...studentBookingNewData.bookingData[0] },
      newEnrolmentClass.schedules
    );
    let { extraFee: extraPrice, numberOfFreeLessonFromMove: numberOfFree } =
      calculateExtraPrice(
        currentEnrolmentClass,
        studentBookingNewData.bookingData[0],
        newEnrolmentSchedulesTemp,
        nextDebitDay,
        __discountApplied
      );
    let numberOfFreeLessonFromMoveForCalculate = numberOfFree || 0;
    if (currentEnrolmentClass.freeLessonFromMove) {
      if (extraPrice > 0) {
        const numberOfExtraLesson = roundByTwo(
          extraPrice / studentBookingNewData.bookingData[0].classInfo.price
        );
        const result =
          numberOfExtraLesson - currentEnrolmentClass.freeLessonFromMove;
        const remainFreeLesson =
          (currentEnrolmentClass.freeLessonFromMove || 0) - numberOfExtraLesson;
        numberOfFreeLessonFromMoveForCalculate +=
          remainFreeLesson > 0 ? remainFreeLesson : 0;
        extraPrice = roundByTwo(
          result * studentBookingNewData.bookingData[0].classInfo.price
        );
      } else {
        numberOfFreeLessonFromMoveForCalculate +=
          currentEnrolmentClass.freeLessonFromMove || 0;
      }
    }
    setNumberOfFreeLessonFromMove(
      (numberOfFree || 0) + (currentEnrolmentClass.freeLessonFromMove || 0)
    );

    const price =
      paymentOption === PAYMENT_VALUE.UPFRONT
        ? extraPrice
        : item.classInfo.price;

    studentBookingNewData = {
      ...studentBookingNewData,
      bookingData: studentBookingNewData.bookingData.map((classItem) => {
        if (classItem._id === item.classInfo._id) {
          const pricePayNow = extraPrice;
          const newItem = {
            ...item,
            pricePayNow,
            paymentOption,
            price
          };

          return {
            ...item,
            pricePayNow,
            paymentOption,
            price,
            nextBillingCircle:
              paymentOption === PAYMENT_VALUE.UPFRONT
                ? []
                : handleGetNextBillingCircle(
                    newItem,
                    numberOfFreeLessonFromMoveForCalculate
                  )
          };
        }
        return classItem;
      })
    };
    onChangeStudentDataBooking(studentBookingNewData);
  };

  const getSchedules = useCallback(
    (previousDebitDay: Dayjs, debitDayTemp: Dayjs) => {
      return (newEnrolmentClass?.schedules.filter(
        (session) =>
          !dayjs(session.startTime).isBefore(previousDebitDay) &&
          dayjs(session.startTime).isBefore(debitDayTemp) &&
          dayjs(session.startTime).format('ddd') ===
            dayjs(
              studentDataBooking?.bookingData[0]?.schedules[0]?.startTime
            ).format('ddd')
      ) || []) as ISchedule[];
    },
    [newEnrolmentClass, studentDataBooking]
  );

  const handleGetNextBillingCircle = useCallback(
    (item: IBookingData, numberOfFreeLessonFromMove: number = 0) => {
      const schedules = newEnrolmentClass?.schedules;
      if (!schedules) return;
      const { pricePayNow = 0 } = item;
      const { remainFreeLesson = 0 } = __discountApplied || {};
      const schedulesNumber = newEnrolmentClass?.schedules.length || 0;
      let endDate = schedules[schedulesNumber - 1].startTime;

      let debitDayTemp = dayjs(nextDebitDay).add(14, 'days');
      let previousDebitDay = dayjs(nextDebitDay);
      const nextDebitDays: Array<NextBillingCircle> = [];

      let remainDiscountAmount = 0;
      let remainingLessonsFromMove = numberOfFreeLessonFromMove;

      if (__discountApplied?.discountType === DISCOUNT_TYPE.AMOUNT) {
        remainDiscountAmount =
          (__discountApplied?.remainDiscountAmount || 0) - pricePayNow;
      }
      if (
        __discountApplied?.duration ===
          VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS &&
        remainFreeLesson
      ) {
        remainDiscountAmount = roundByTwo(
          remainFreeLesson * newEnrolmentClass.price - pricePayNow
        );
      }

      while (dayjs(debitDayTemp).isBefore(dayjs(endDate))) {
        const lessons = getSchedules(previousDebitDay, debitDayTemp);
        let price = 0;
        let originalValue = lessons?.length * item.classInfo.price;
        if (remainingLessonsFromMove > 0) {
          const numberOfMustPaidLesson =
            lessons?.length - remainingLessonsFromMove;

          remainingLessonsFromMove = remainingLessonsFromMove - lessons?.length;
          originalValue =
            numberOfMustPaidLesson > 0
              ? numberOfMustPaidLesson * item.classInfo.price
              : 0;
        }
        if (originalValue > 0) {
          if (!!__discountApplied) {
            if (__discountApplied?.discountType === DISCOUNT_TYPE.PERCENTAGE) {
              if (
                __discountApplied.discountFrom ===
                DISCOUNT_FROM.AUTOMATION_DISCOUNT
              ) {
                // check continue apply automation discount
                if (currentEnrolmentClass?.isQualified) {
                  let discountValue = roundByTwo(
                    originalValue * (__discountApplied?.discountValue / 100)
                  );

                  price = roundByTwo(originalValue - discountValue);
                } else {
                  price = roundByTwo(originalValue);
                }
              } else {
                let discountValue = roundByTwo(
                  originalValue * (__discountApplied?.discountValue / 100)
                );

                price = roundByTwo(originalValue - discountValue);
              }
            } else {
              if (remainDiscountAmount > 0) {
                let discountValue = 0;
                if (originalValue > remainDiscountAmount) {
                  discountValue = remainDiscountAmount;
                } else {
                  discountValue = originalValue;
                  remainDiscountAmount = roundByTwo(
                    remainDiscountAmount - originalValue
                  );
                }
                price = roundByTwo(originalValue - discountValue);
              } else if (
                __discountApplied.duration === VOUCHER_DURATION.TIME_RANGE
              ) {
                const { startDateFree, endDateFree = debitDayTemp } =
                  __discountApplied;
                if (
                  dayjs(startDateFree).isAfter(dayjs(debitDayTemp)) ||
                  dayjs(endDateFree).isBefore(dayjs(previousDebitDay))
                ) {
                  price = roundByTwo(lessons?.length * item.classInfo.price);
                } else {
                  const lessonsFree = getLessonsDayInRange(
                    lessons,
                    dayjs(startDateFree).format(),
                    dayjs(endDateFree).format(),
                    []
                  );
                  price = roundByTwo(
                    (lessons?.length - lessonsFree.length) *
                      item.classInfo.price
                  );
                }
              } else {
                price = roundByTwo(lessons?.length * item.classInfo.price);
              }
            }
          } else {
            price = roundByTwo(originalValue);
          }
        }
        if (price > 0) {
          nextDebitDays.push({
            price: roundByTwo(price),
            date: formatDate(previousDebitDay)
          });
        }
        previousDebitDay = dayjs(debitDayTemp);
        debitDayTemp = debitDayTemp.add(14, 'days');
      }

      if (dayjs(endDate).isAfter(dayjs(previousDebitDay))) {
        let price = 0;
        const lessons = getSchedules(previousDebitDay, debitDayTemp);
        let originalValue = lessons?.length * item.classInfo.price;
        if (remainingLessonsFromMove > 0) {
          const numberOfMustPaidLesson =
            lessons?.length - remainingLessonsFromMove;

          remainingLessonsFromMove = remainingLessonsFromMove - lessons?.length;
          originalValue =
            numberOfMustPaidLesson > 0
              ? numberOfMustPaidLesson * item.classInfo.price
              : 0;
        }
        if (originalValue > 0) {
          if (!!__discountApplied) {
            if (__discountApplied?.discountType === DISCOUNT_TYPE.PERCENTAGE) {
              const discountValue = roundByTwo(
                originalValue * (__discountApplied?.discountValue / 100)
              );
              price = originalValue - discountValue;
            } else {
              if (remainDiscountAmount > 0) {
                let discountValue = 0;
                if (originalValue > remainDiscountAmount) {
                  discountValue = remainDiscountAmount;
                } else {
                  discountValue = originalValue;
                  remainDiscountAmount = roundByTwo(
                    remainDiscountAmount - originalValue
                  );
                }
                price = roundByTwo(originalValue - discountValue);
              }
            }
          } else {
            price = roundByTwo(originalValue);
          }
          if (price > 0)
            nextDebitDays.push({ price, date: formatDate(previousDebitDay) });
        }
      }

      return nextDebitDays;
    },
    [
      nextDebitDay,
      newEnrolmentClass,
      getSchedules,
      __discountApplied,
      currentEnrolmentClass?.isQualified
    ]
  );

  const handleShowNextBillDay = useCallback(
    (nextBillingCircle: NextBillingCircle[]) => {
      if (!studentDataBooking) return;
      setShowNextBillDay(!showNextBillDay);
      setNextBillingCircle(nextBillingCircle);
      setStudentName(
        `${studentDataBooking?.lastName}, ${studentDataBooking?.firstName}`
      );
    },
    [showNextBillDay, studentDataBooking, setStudentName]
  );

  const handleCloseBillingCircle = useCallback(() => {
    setShowNextBillDay(false);
  }, [setShowNextBillDay]);

  const getSubTotalPayNow = useCallback(() => {
    if (!studentDataBooking?.bookingData?.[0]) return 0;
    return handleGetPayNowForMoveBooking(
      studentDataBooking?.bookingData?.[0],
      __discountApplied
    );
  }, [studentDataBooking, __discountApplied]);

  const getTotalDirectDebit = useCallback(() => {
    if (
      studentDataBooking?.bookingData?.[0]?.paymentOption !==
      PAYMENT_VALUE.DIRECT_DEBIT
    )
      return 0;
    let total = 0;
    forEach(studentDataBooking?.bookingData, (classItem) => {
      total = total + (classItem?.nextBillingCircle?.[0]?.price || 0);
    });
    return total;
  }, [studentDataBooking]);

  const handleApplyPromoCode = useCallback(
    (voucher: IVoucher | undefined, studentId: string, classId: string) => {
      studentDataBooking &&
        onChangeStudentDataBooking({
          ...studentDataBooking,
          bookingData: studentDataBooking?.bookingData.map((classItem) => {
            if (classItem._id === classId) {
              return {
                ...classItem,
                voucher
              };
            }
            return classItem;
          })
        });
    },
    [studentDataBooking, onChangeStudentDataBooking]
  );
  if (studentDataBooking === null) return null;

  const renderLessonDateNTime = (
    enrolClass: IClass | null,
    recurrence: IRecurrence,
    actualSessions: Array<ISession>,
    allSessions?: Array<ISession>
  ): string => {
    if (!enrolClass) return '--';
    let value: string = '';
    if (enrolClass?.type === CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM) {
      value += `FROM ${dayjs(actualSessions[0]?.startTime).format(
        'ddd DD/MM'
      )} to ${dayjs(actualSessions[actualSessions.length - 1]?.endTime).format(
        'ddd DD/MM'
      )} `;
    } else {
      value += dayjs(actualSessions?.[0]?.startTime).format('ddd');
    }
    const firstLesson = actualSessions?.[0] || allSessions?.[0];
    const time = ` ${formatTime(firstLesson?.startTime)} - ${formatTime(
      firstLesson?.endTime
    )}`;
    return (value += time);
  };

  if (!currentEnrolmentClass || !newEnrolmentClass) return null;

  return (
    <div className="layoutContainer classes-main">
      <AppModal open={showNextBillDay} onClose={handleCloseBillingCircle}>
        <div className="next-bill-title">
          {studentName}
          <HiOutlineX
            onClick={handleCloseBillingCircle}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="next-bill-content">
          <div className="title">Next billing circle</div>
          <div className="next-bill-content-table">
            <div className="table-header">
              <div className="table-header-th">Payment date</div>
              <div className="table-header-th">Price</div>
            </div>
            {nextBillingCircle.map((item: NextBillingCircle, index: number) => (
              <div className="table-row" key={index}>
                <div className="table-row-th">{item.date}</div>
                <div className="table-row-th">
                  {formatData(`$${item.price}`)}
                </div>
              </div>
            ))}
          </div>
          <div className="total-price">
            Total {formatData(`$${round(__totalNextDirectDebit, 2)}`)}
          </div>
        </div>
      </AppModal>

      <div className="review-n-payment-move-booking-wrapper">
        {/* start review section */}
        <div className="section review-section">
          <div className="title">Review Summary</div>
          {/* start student info */}
          <div className="student-info">
            <div className="student-info-name">{`${studentDataBooking.firstName}, ${studentDataBooking.lastName}`}</div>
            <div className="review_body_body-row">
              <div className="span3 header">PROGRAM TYPE</div>
              <div className="span3 header">ENROLMENT TYPE</div>
              <div className="span2 header">Day, Time</div>
              <div className="span2 header">FIRST, LAST LESSON</div>
              <div className="span3 header">Payment Option</div>
              <div className="span2 header" style={{ textAlign: 'right' }}>
                Price
              </div>
              <div className="span2 header" style={{ textAlign: 'right' }}>
                Discounted Price
              </div>
              <div className="span2 header" style={{ textAlign: 'right' }}>
                Pay now
              </div>
            </div>
            {studentDataBooking?.bookingData?.map((classItem, index) => {
              return (
                <ReviewBookingDataRow
                  key={index}
                  bookingData={classItem}
                  onChangePaymentOption={handleChangePaymentOption}
                  onShowNextBillDay={handleShowNextBillDay}
                  onApplyPromoCode={(code: IVoucher | undefined) =>
                    handleApplyPromoCode(
                      code,
                      studentDataBooking?._id,
                      classItem._id
                    )
                  }
                  discountData={
                    currentEnrolmentClass.discountType
                      ? __discountApplied
                      : undefined
                  }
                  currentPaymentOption={currentEnrolmentClass.paymentType}
                />
              );
            })}
          </div>
          {__showWarningPIF && (
            <div className="warning-pif-section">
              <HiOutlineExclamationCircle />
              <div className="text">
                Enrolments made with upfront payment will automatically end at
                the end of the session, causing the student to lose their spot
                for that particular lesson day and time. To maintain a
                consistent lesson day and time from week to week and session to
                session, <strong>it's best to choose direct debit.</strong>
              </div>
            </div>
          )}
          {__showWarningMoveToFutureTerm && (
            <div className="warning-wrapper">
              <div className="text">
                You can move your enrollment to the next session only if you
                stay on Direct Debit. If you want to choose Upfront Payment now,
                you can only move your lesson to any available date/time before
                the end of the current session.
              </div>
            </div>
          )}
          {numberOfFreeLessonFromMove > 0 && (
            <div className="warning-wrapper number-of-lesson-free">
              <div className="text">
                You have {numberOfFreeLessonFromMove} paid lesson
                {numberOfFreeLessonFromMove > 1 ? 's' : ''} not yet happened on
                the current enrolment. It will be used to deduct the enrolment
                fee on your next payment(s).
              </div>
            </div>
          )}
          {/* end student info */}
          <div className="total-section">
            {__nextDirectDebits.length > 0 && (
              <React.Fragment>
                <div className="item">
                  <p className="title">Direct debit details</p>
                  <p></p>
                </div>
                {__nextDirectDebits.map((nextDirectDebit, index: number) => {
                  if (index === 0) {
                    return (
                      <div key={nextDirectDebit.date} className="item discount">
                        <div className="title">first Direct debit date:</div>
                        <div className="value">{nextDirectDebit.date}</div>
                      </div>
                    );
                  }
                  if (index === 1) {
                    return (
                      <div key={nextDirectDebit.date} className="item discount">
                        <div className="title">Next Direct debit date:</div>
                        <div className="value">
                          <div className="value">{nextDirectDebit.date}</div>
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </React.Fragment>
            )}
            <div className="item">
              <div className="title">SUBTOTAL</div>
              <div>{formatMoneySign(getSubTotalPayNow())}</div>
            </div>
            {__discountData.length > 0 && (
              <div className="item">
                <div className="title">Discount</div>
                <div></div>
              </div>
            )}
            {__discountData?.map((item: DiscountData, index: number) => (
              <div className="item discount" key={index}>
                <div>{item.description}</div>
                <div className="value">
                  {!!item.amountDisplay
                    ? item.amountDisplay
                    : `-${formatMoneySign(item.amount)}`}
                </div>
              </div>
            ))}
            {__moneyCredit > 0 && (
              <div className="item ">
                <div className="title">Money credit</div>
                <div>-{formatMoneySign(__moneyCredit)}</div>
              </div>
            )}
            {__nextDirectDebits.length > 0 && (
              <div className="item">
                <div className="title">TOTAL FIRST DEBIT</div>
                <div>{formatMoneySign(getTotalDirectDebit())}</div>
              </div>
            )}
            <div className="item">
              <div className="title">TOTAL PAYING TODAY</div>
              <div className="total-price">
                {formatMoneySign(__totalPayNow)}
              </div>
            </div>
          </div>
        </div>
        {/* end review section */}

        {/* start move booking detail */}
        <div className="moveBookingDetail">
          <div className="current-enrolment">
            <div className="header">Current Enrolment</div>
            <div className="title">LEVEL</div>
            <div className="value">
              {currentEnrolmentClass?.classLevel?.name || 'All Levels'}
            </div>
            <div className="title">Lesson date and time</div>
            <div className="value">
              {renderLessonDateNTime(
                currentEnrolmentClass?.class,
                currentEnrolmentClass?.class.recurrence,
                currentEnrolmentClass.actualSessions,
                currentEnrolmentClass.allSessions
              )}
            </div>
            <div className="title">Location</div>
            <div className="value">
              {
                locations.find(
                  (item) => item._id === currentEnrolmentClass?.locationId
                )?.name
              }
            </div>
          </div>
          <div className="icon">
            <HiArrowRight size={22} />
          </div>
          <div className="next-enrolment">
            <div className="header">New Enrolment</div>
            <div className="title">LEVEL</div>
            <div className="value">
              {studentDataBooking.classesData[0]?.classInfo?.levelBreakdown
                ?.name || 'All Levels'}
            </div>
            <div className="title">Lesson date and time</div>
            <div className="value">
              {renderLessonDateNTime(
                newEnrolmentClass,
                newEnrolmentClass.recurrence,
                studentDataBooking.bookingData?.[0].schedules
              )}
            </div>
            <div className="title">Location</div>
            <div className="value">
              {
                locations.find(
                  (item) =>
                    item._id ===
                    studentDataBooking.classesData[0]?.classInfo?.locationId
                )?.name
              }
            </div>
          </div>
        </div>
        {/* end move booking detail */}

        {/* start payment section */}
        <div className="section payment-section">
          <div className="title">Payment method</div>
          <div className="payment-method-list">
            {PAYMENT_CARDS.map((item, index) => (
              <Fragment key={index}>
                {
                  <button
                    type="button"
                    className="card"
                    // className={`item card ${
                    //   item.name === paymentMethod ? 'active' : ''
                    // }`}
                    // onClick={() => setPaymentMethod(item.name)}
                  >
                    <img
                      className={`logo ${
                        ![1, 8].includes(index) ? 'bg-white square' : 'round'
                      }`}
                      src={item.image}
                      alt="logo"
                    />
                    <div className="name">
                      {formatData(item.name).replaceAll('_', ' ')}
                    </div>
                  </button>
                }
              </Fragment>
            ))}
          </div>
        </div>
        {/* end payment section */}
      </div>
    </div>
  );
};

export default ReviewNPayment;
