export enum PAYMENT_TYPE {
  PAYMENT = 'payment',
  TOKENIZATION = 'tokenization'
}

export enum PAYMENT_USE_CARD {
  NEW = 'new',
  ON_FILE = 'onFile',
  DIFFERENT = 'different'
}

export enum PAYMENT_FUTURE_OPTION {
  DEFAULT = 'default',
  NEW = 'new',
  BANK = 'bank'
}
export enum PAYMENT_STATUS_TYPE {
  PENDING = 'pending',
  PAID = 'paid',
  UNPAID = 'unpaid',
  FAILED = 'failed'
}

export enum PAYMENT_STATUS {
  COMPLETED = 'completed',
  CANCEL = 'cancel',
  ONGOING = 'on_going',
  PENDING = 'pending'
}

export enum PAYMENT_ACTION {
  DIRECT_BOOKING = 'direct_booking',
  CHARGE_FORTNIGHTLY = 'charge_fortnightly'
}
