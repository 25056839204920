import React, { useEffect, useRef, useState } from 'react';
import { HiMiniEllipsisVertical } from 'react-icons/hi2';
import './desktop.scss';

interface IActionPopperProps {
  onViewVoucher?: () => void;
  onEditVoucher?: () => void;
  onDeleteVoucher?: () => void;
  onSendToMember?: () => void;
  onApplyToMember?: () => void;
}

const ActionPopper = (props: IActionPopperProps) => {
  const {
    onViewVoucher,
    onEditVoucher,
    onDeleteVoucher,
    onSendToMember,
    onApplyToMember
  } = props;

  const popperRef = useRef<any>(null);
  const verticalButtonRef = useRef<any>(null);

  const [open, setOpen] = useState<boolean>(false);

  const onToggle = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Close popper if click outside the popper
    const handleClickOutside = (event: MouseEvent) => {
      // Click at vertical button to close
      if (
        verticalButtonRef.current &&
        verticalButtonRef.current.contains(event.target)
      ) {
        return;
      }
      // Handle click outside to close pop up
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        onClose();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [verticalButtonRef, popperRef]);

  useEffect(() => {
    if (open && verticalButtonRef.current && popperRef.current) {
      const refRect = verticalButtonRef.current.getBoundingClientRect();
      const popperRect = popperRef.current.getBoundingClientRect();

      let top = refRect.bottom;
      let bottom = refRect.top;

      if (top + popperRect.height > window.innerHeight) {
        top = 'unset';
        bottom = '100%';
      } else {
        top = '100%';
        bottom = 'unset';
      }

      popperRef.current.style.top = `${top}`;
      popperRef.current.style.bottom = `${bottom}`;
    }
  }, [open]);

  return (
    <React.Fragment>
      <div
        ref={verticalButtonRef}
        className="voucherListPage__ellipsisVertical"
      >
        <HiMiniEllipsisVertical onClick={onToggle} />

        {open && (
          <div ref={popperRef} className="viewDetailPopper">
            {onViewVoucher && (
              <div className="viewDetailPopper-item" onClick={onViewVoucher}>
                View Voucher
              </div>
            )}
            {onEditVoucher && (
              <div className="viewDetailPopper-item" onClick={onEditVoucher}>
                Edit Voucher
              </div>
            )}
            {onSendToMember && (
              <div className="viewDetailPopper-item" onClick={onSendToMember}>
                Send To Member
              </div>
            )}
            {onApplyToMember && (
              <div className="viewDetailPopper-item" onClick={onApplyToMember}>
                Apply To Member
              </div>
            )}
            {onDeleteVoucher && (
              <div className="viewDetailPopper-item" onClick={onDeleteVoucher}>
                Delete Voucher
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ActionPopper;
