import { Prettify } from '../helpers/types.helper';
import { FLOW_ENUM } from '../enums/step.enum';

import { DataSetFlowInit } from './stepController.interface';
import { IClass } from '../interfaces/class.interface';
import { IResponsiblePerson } from '../interfaces/responsiblePerson.interface';
import { ISchedule } from '../interfaces/schedules.interface';
import {
  IActiveOngoingStudent,
  IStudent
} from '../interfaces/student.interface';
import {
  PAYMENT_VALUE,
  PAYMENT_METHOD,
  BOOKING_TYPE,
  BOOKING_ERROR_TYPE
} from '../enums/classBooking.enum';
import { ITerm } from './term.interface';
import { IAutomaticDiscount, IVoucher } from './voucher.interface';
import { DISCOUNT_TYPE } from '../enums';
import {
  AUTOMATION_DISCOUNT_TYPE,
  DISCOUNT_FROM,
  VOUCHER_DURATION
} from '../enums/voucher.enum';

export type BOOKING_ERROR_UNION_TYPE = `${BOOKING_ERROR_TYPE}`;

export type BOOKING_UNION_TYPE = `${BOOKING_TYPE}`;

export type PAYMENT_METHOD_TYPE = `${PAYMENT_METHOD}`;

export type PAYMENT_TYPE = `${PAYMENT_VALUE}`;

export enum UPDATE_TYPE_OPTIONS {
  CLASS_BOOKED = 'classBooked',
  ENROLLMENT_TYPE = 'enrollmentType',
  SCHEDULES_BOOKED = 'schedulesBooked'
}

export type UPDATE_TYPE_UNION_TYPE = `${UPDATE_TYPE_OPTIONS}`;

export type IClassBooked = Prettify<IClass & { ongoingBookable?: number }>;

export type IClassesBooked = Prettify<{
  info: IClassBooked;
  schedules: ISchedule[];
  activeTermSchedules: ISchedule[];
  schedulesUntilNextDD: ISchedule[];
  schedulesUntilNextDD2: ISchedule[];
}>;

export type IBillingCycle = Prettify<{
  paymentDate: string;
  price: number;
}>;

export type IFormBillingCycle = Prettify<{
  info: { name: string };
  cycles: IBillingCycle[];
}>;

export type CustomFormEnrollment = Prettify<
  | {
      classBooked: boolean;
      enrollmentType: BOOKING_UNION_TYPE;
      schedulesBooked: ISchedule[];
      error: BOOKING_ERROR_UNION_TYPE;
      totalPrice: number;
      payNow: number;
      paymentType: Exclude<PAYMENT_VALUE, PAYMENT_VALUE.DIRECT_DEBIT>;
    }
  | {
      classBooked: boolean;
      enrollmentType: BOOKING_UNION_TYPE;
      schedulesBooked: ISchedule[];
      error: BOOKING_ERROR_UNION_TYPE;
      totalPrice: number;
      payNow: number;
      paymentType: PAYMENT_VALUE.DIRECT_DEBIT;
      billingCycle: IFormBillingCycle;
    }
>;

export type CustomStudentEnrollment = Prettify<
  IClassesBooked & {
    form: CustomFormEnrollment;
    voucher?: IVoucher;
    automaticDiscount?: IAutomaticDiscount;
  }
>;

export type CustomFormForStudentEnrollment = Prettify<{
  info: IStudent & {
    initTotalCredit?: number;
  };
  classes: CustomStudentEnrollment[];
}>;

export type IResponsiblePersonBooked = Prettify<{
  info: IResponsiblePerson & {
    activeOngoingStudents: IActiveOngoingStudent[];
  };
  students: Prettify<CustomFormForStudentEnrollment[]>;
}>;

export type UpdateEnrollmentList = Prettify<
  | {
      updateType: UPDATE_TYPE_OPTIONS.CLASS_BOOKED;
      STuuid: string;
      classId: string;
    }
  | {
      updateType: UPDATE_TYPE_OPTIONS.ENROLLMENT_TYPE;
      STuuid: string;
      classId: string;
      enrollmentType: BOOKING_UNION_TYPE;
    }
  | {
      updateType: UPDATE_TYPE_OPTIONS.SCHEDULES_BOOKED;
      STuuid: string;
      classId: string;
      scheduleId: string;
    }
>;

type DataSetFlowBookingClassStep0 = Prettify<
  {
    flow: FLOW_ENUM.CLASS_BOOKING;
    step: 0;
    handleClassBooking: (ClassInfo: IClassBooked | null) => void;
    handleSelectRP: (
      responsiblePersonsBooked: IResponsiblePerson | null
    ) => void;
    handleAddStepForward: (
      moveTo?: number,
      currentDateSet?: DataSetFlowBookingClass
    ) => void;
  } & Pick<DataSetFlowInit, 'cleanUpFlow'>
>;

type DataSetFlowBookingClassStep1 = Prettify<
  {
    step: 1;
    classesBooked: IClassesBooked[];
    responsiblePersonsBooked: IResponsiblePersonBooked[];
    handleAddStepBackward: (
      moveTo?: number,
      currentDateSet?: DataSetFlowBookingClass
    ) => void;
  } & Pick<
    DataSetFlowBookingClassStep0,
    | 'flow'
    | 'handleClassBooking'
    | 'handleSelectRP'
    | 'cleanUpFlow'
    | 'handleAddStepForward'
  >
>;

type DataSetFlowBookingClassStep2 = Prettify<
  {
    step: 2;
    handleGetSchedulesOfClasses: (classIds: string[]) => void;
  } & Pick<
    DataSetFlowBookingClassStep0,
    'flow' | 'cleanUpFlow' | 'handleSelectRP'
  > &
    Pick<
      DataSetFlowBookingClassStep1,
      | 'classesBooked'
      | 'responsiblePersonsBooked'
      | 'handleAddStepForward'
      | 'handleAddStepBackward'
    >
>;

type DataSetFlowBookingClassStep3 = Prettify<
  {
    step: 3;
  } & Pick<
    DataSetFlowBookingClassStep0,
    'flow' | 'cleanUpFlow' | 'handleSelectRP'
  > &
    Pick<
      DataSetFlowBookingClassStep1,
      | 'classesBooked'
      | 'responsiblePersonsBooked'
      | 'handleAddStepForward'
      | 'handleAddStepBackward'
    >
>;

type DataSetFlowBookingClassStep4 = Prettify<
  {
    step: 4;
    nextDebitDay: string;
    subtractTerms: ITerm[];
    handleGetListEnrollmentByRP: (
      responsiblePersonId: string,
      callback?: (isLoading: boolean) => void
    ) => void;
    handleGetNextDebitDay: () => void;
    handleUpdateListEnrollment: (params: UpdateEnrollmentList) => void;
  } & Pick<DataSetFlowBookingClassStep0, 'flow' | 'cleanUpFlow'> &
    Pick<
      DataSetFlowBookingClassStep1,
      | 'classesBooked'
      | 'responsiblePersonsBooked'
      | 'handleAddStepForward'
      | 'handleAddStepBackward'
    > &
    Pick<DataSetFlowBookingClassStep2, 'handleGetSchedulesOfClasses'>
>;

type DataSetFlowBookingClassStep5 = Prettify<
  {
    step: 5;
  } & Pick<DataSetFlowBookingClassStep0, 'flow' | 'cleanUpFlow'> &
    Pick<
      DataSetFlowBookingClassStep1,
      | 'classesBooked'
      | 'responsiblePersonsBooked'
      | 'handleAddStepForward'
      | 'handleAddStepBackward'
    > &
    Pick<
      DataSetFlowBookingClassStep4,
      | 'handleUpdateListEnrollment'
      | 'handleGetListEnrollmentByRP'
      | 'nextDebitDay'
      | 'subtractTerms'
      | 'handleGetNextDebitDay'
    > &
    Pick<DataSetFlowBookingClassStep2, 'handleGetSchedulesOfClasses'>
>;

type DataSetFlowBookingClassStep6 = Prettify<
  {
    step: 6;
    paymentMethod: PAYMENT_METHOD_TYPE;
    paymentAmount: number;
    totalCreditAmount: number;
    totalNextDirectDebitPaymentAmount: number;
    handlePaymentMethod: (paymentMethod: PAYMENT_METHOD_TYPE) => void;
    handleChangeClassPaymentType: (
      STuuid: string,
      classItem: CustomStudentEnrollment,
      newPaymentType: PAYMENT_TYPE
    ) => void;
    handlePaymentAmount: (schedulesGroupByWeek: ScheduleGroupByWeek[]) => void;
    handleApplyPromoCode: (
      voucher: IVoucher | undefined,
      classItem: CustomStudentEnrollment,
      studentId: string
    ) => void;
  } & Pick<DataSetFlowBookingClassStep0, 'flow' | 'cleanUpFlow'> &
    Pick<
      DataSetFlowBookingClassStep1,
      | 'classesBooked'
      | 'responsiblePersonsBooked'
      | 'handleAddStepForward'
      | 'handleAddStepBackward'
    > &
    Pick<DataSetFlowBookingClassStep4, 'nextDebitDay' | 'subtractTerms'>
>;

type DataSetFlowBookingClassStep7 = Prettify<
  {
    step: 7;
    handleApplyPromoCode: (
      voucher: IVoucher | undefined,
      classItem: CustomStudentEnrollment,
      studentId: string
    ) => void;
  } & Pick<DataSetFlowBookingClassStep0, 'flow' | 'cleanUpFlow'> &
    Pick<
      DataSetFlowBookingClassStep1,
      | 'classesBooked'
      | 'responsiblePersonsBooked'
      | 'handleAddStepForward'
      | 'handleAddStepBackward'
    > &
    Pick<
      DataSetFlowBookingClassStep6,
      | 'paymentAmount'
      | 'totalCreditAmount'
      | 'totalNextDirectDebitPaymentAmount'
      | 'paymentMethod'
      | 'handlePaymentMethod'
      | 'handlePaymentAmount'
      | 'handleChangeClassPaymentType'
    > &
    Pick<DataSetFlowBookingClassStep4, 'nextDebitDay' | 'subtractTerms'>
>;

export type DataSetFlowBookingClass = Prettify<
  | null
  | DataSetFlowInit
  | Prettify<
      | DataSetFlowBookingClassStep0
      | DataSetFlowBookingClassStep1
      | DataSetFlowBookingClassStep2
      | DataSetFlowBookingClassStep3
      | DataSetFlowBookingClassStep4
      | DataSetFlowBookingClassStep5
      | DataSetFlowBookingClassStep6
      | DataSetFlowBookingClassStep7
    >
>;

export type DiscountData = {
  description: string;
  amount: number;
  amountDisplay?: string;
};

export type DiscountApplied = {
  discountType: DISCOUNT_TYPE;
  discountFrom: DISCOUNT_FROM;
  discountValue: number;
  automationDiscountType?: AUTOMATION_DISCOUNT_TYPE;
  remainDiscountAmount: number;
  numberOfFreeLesson?: number;
  remainFreeLesson?: number;
  duration?: VOUCHER_DURATION;
  startDateFree?: string;
  endDateFree?: string;
  voucherCode?: string;
};

export type ScheduleGroupByWeek = {
  label: string;
  schedules: ISchedule[];
};
