import React, { InputHTMLAttributes, useMemo } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import './desktop.scss';

export type InputSize = 'large' | 'small';

export interface ILevelAgeInput extends InputHTMLAttributes<HTMLInputElement> {
  value: string;
  onChangeAge: (value: string) => void;
  label: string;
  inputSize?: InputSize;
  noFloatingLabel?: boolean;
  message?: {
    type: 'error' | 'success' | 'caption';
    text: string;
  };
}

export enum LevelAgeUnit {
  YEAR = 'Years',
  MONTH = 'Months'
}

const LevelAgeInput = React.forwardRef<HTMLInputElement, ILevelAgeInput>(
  (
    {
      value,
      onChangeAge,
      label,
      inputSize = 'large',
      noFloatingLabel = false,
      message,
      ...props
    },
    ref
  ) => {
    const popperRef = React.useRef<any>(null);
    const selectRef = React.useRef<any>(null);
    const [age, setAge] = React.useState<string>('');

    const [unit, setUnit] = React.useState<LevelAgeUnit>();

    React.useEffect(() => {
      if (!value) setAge('');
      const words = value?.toString()?.split(' ');
      const lastWord = words[words.length - 1];
      if (
        lastWord &&
        Object.values(LevelAgeUnit).includes(lastWord as LevelAgeUnit)
      ) {
        setAge(value.replace(/\s+\w+$/, ''));
      } else setAge('');
    }, [value]);

    React.useEffect(() => {
      if (!value) setUnit(LevelAgeUnit.YEAR);
      const words = value?.toString()?.split(' ');
      const lastWord = words[words.length - 1];
      if (
        lastWord &&
        Object.values(LevelAgeUnit).includes(lastWord as LevelAgeUnit)
      )
        setUnit(lastWord as LevelAgeUnit);
      else setUnit(LevelAgeUnit.YEAR);
    }, [value]);

    const [open, setOpen] = React.useState<boolean>(false);

    const handleChangeUnit = (unit: LevelAgeUnit) => {
      setUnit(unit);
      onChangeAge(age + ' ' + unit);
      onClose();
    };

    const handleChangeAge = (value: string) => {
      if (value === '') {
        onChangeAge('');
        return;
      }
      onChangeAge(value + ' ' + unit);
      setAge(value);
    };

    const onToggle = () => {
      setOpen(!open);
    };

    const onClose = () => {
      setOpen(false);
    };

    React.useEffect(() => {
      // Close popper if click outside the popper
      const handleClickOutside = (event: MouseEvent) => {
        // Click at vertical button to close
        if (selectRef.current && selectRef.current.contains(event.target)) {
          return;
        }
        // Handle click outside to close pop up
        if (popperRef.current && !popperRef.current.contains(event.target)) {
          onClose();
        }
      };
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [selectRef, popperRef]);

    const __renderMessage = useMemo(() => {
      if (!message) return null;
      return (
        <p
          className={`levelAgeInput-message levelAgeInput-message-${message.type}`}
        >
          {message.text}
        </p>
      );
    }, [message]);

    return (
      <div className={`levelAgeInput `} style={props.style}>
        <div style={{ position: 'relative' }}>
          <input
            {...props}
            className={`levelAgeInput-field levelAgeInput-field-${inputSize}  ${
              message?.text ? `levelAgeInput-border-${message?.type}` : ''
            }${props.disabled ? ' levelAgeInput-field-disabled' : ''}`}
            id={props.name}
            ref={ref}
            placeholder={label}
            autoComplete="off"
            value={age}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleChangeAge(event.target.value);
            }}
          />

          <label
            className={`levelAgeInput-label ${
              noFloatingLabel ? 'levelAgeInput-label-noFloating' : ' '
            }`}
            htmlFor={props.name}
          >
            {label}
          </label>

          <React.Fragment>
            <div ref={selectRef} className="levelAgeInput__select">
              <div
                onClick={onToggle}
                className={`levelAgeInput__select__container ${
                  open ? 'open' : ''
                }`}
              >
                <p>{unit}</p>
                <HiChevronDown size={16} />
              </div>
              {open && (
                <div ref={popperRef} className="levelAgeInput__select__popper">
                  <div
                    className="levelAgeInput__select__popper--item"
                    onClick={() => handleChangeUnit(LevelAgeUnit.YEAR)}
                  >
                    {LevelAgeUnit.YEAR}
                  </div>
                  <div
                    className="levelAgeInput__select__popper--item"
                    onClick={() => handleChangeUnit(LevelAgeUnit.MONTH)}
                  >
                    {LevelAgeUnit.MONTH}
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        </div>
        {__renderMessage}
      </div>
    );
  }
);

export default LevelAgeInput;
