import React, { memo, useEffect, useState } from 'react';
import './scss/calendar.scss';
import LevelList from './LevelList';
import { convertFloatToTime, getCurrentTime } from 'common/helpers/time.helper';
import { ICalendarCell } from 'common/interfaces/dashboard.interface';
import { formatDate } from 'common/helpers/dataFormat.helper';
interface Props {
  date: Date;
  publicHolidayDateArray: string[];
}
const DayViewNew: React.FC<Props> = memo(({ date, publicHolidayDateArray }) => {
  const [times, setTime] = useState<String[]>([]);
  const [calendarCells, setCalendarCells] = useState<ICalendarCell[]>([]);

  useEffect(() => {
    const times: string[] = [];
    for (var i = 6; i <= 21.5; i += 0.5) {
      times.push(convertFloatToTime(i));
    }
    setTime(times);
    const calendarCells: ICalendarCell[] = [];
    times.forEach((time) => {
      calendarCells.push({
        date: date,
        time: time
      });
    });
    setCalendarCells(calendarCells);
  }, [date]);

  useEffect(() => {
    handleScrollIntoView();
  }, []);

  const handleScrollIntoView = () => {
    const elm = document.getElementsByClassName('current-time-text')[0];
    setTimeout(() => {
      elm.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 500);
  };

  return (
    <>
      <div className="header-calendar">
        <div
          className="header-time"
          style={{
            borderTopLeftRadius: '15px',
            width: '34px'
          }}
        ></div>
        <div
          className={`header-item ${
            publicHolidayDateArray.includes(formatDate(date))
              ? 'public-holiday-header'
              : ''
          }`}
          style={{
            borderLeft: 'none'
          }}
        >
          <div className="day-label"></div>
          {publicHolidayDateArray.includes(formatDate(date)) && (
            <div className="public-holiday">Public Holiday</div>
          )}
        </div>
      </div>

      <div className="week-calendar calendar-scroll-day ">
        <div className="time-wrapper" style={{ borderTop: 'none' }}>
          <div
            className="current-time-text"
            style={{
              top: getCurrentTime().top - 5,
              marginLeft: 5
            }}
          >
            {getCurrentTime().hours}:{getCurrentTime().minutes}
          </div>

          {times.map((time, idx) => {
            return (
              <div
                key={idx}
                className={`time-item${
                  idx === times.length - 1 ? ' last' : ''
                }`}
              >
                {idx !== 0 && <div className="time">{time}</div>}
              </div>
            );
          })}
        </div>
        <div style={{ width: '100%' }}>
          <div className="calendar-grid-content-day">
            {calendarCells.map((cell, index) => (
              <div
                className={`calendar-day-container ${
                  index === calendarCells.length - 1 ? ' last' : ''
                }`}
                key={index}
              >
                <div
                  key={index}
                  className={`calendar-day`}
                  style={{
                    borderBottomRightRadius:
                      index === calendarCells.length - 1 ? '15px' : '',
                    borderBottomLeftRadius:
                      index === calendarCells.length - 7 ? '15px' : ''
                  }}
                >
                  <LevelList cell={cell} key={index} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
});
const DayView = React.memo(DayViewNew);
export default DayView;
