import API from './API';
import { FilterClasses } from 'pages/classes/types';
import {
  ChangeClassesAreaDTO,
  ChangeClassesCapacityDTO,
  ChangeClassesInstructorDTO,
  ChangeClassesPriceDTO,
  CreateClassPayloadDTO
} from 'DTOs/class.dto';

const CLASS_API_ENDPOINT = '/classes';

export const getClassStatistic = (params: { locationId: string }) => {
  return API.get(`${CLASS_API_ENDPOINT}/class-statistic`, params);
};

export const getClassListMoveBooking = (params: FilterClasses) => {
  return API.get(`${CLASS_API_ENDPOINT}`, params);
};

export const getClassList = async (params: FilterClasses) => {
  const response = await API.get(`${CLASS_API_ENDPOINT}/next-session`, params);
  return response.data.data;
};

export const getClassDetail = async (id: string) => {
  const response = await API.get(`${CLASS_API_ENDPOINT}/${id}`);
  return response.data;
};

export const deleteClass = async (id: string) => {
  const response = await API.delete(`${CLASS_API_ENDPOINT}/${id}`);
  return response.data;
};

export const createClass = async (payload: CreateClassPayloadDTO) => {
  const response = await API.post(`${CLASS_API_ENDPOINT}`, { body: payload });
  return response.data;
};

export const updateClass = async (id: string, payload: any) => {
  const response = await API.patch(`${CLASS_API_ENDPOINT}/${id}`, {
    body: payload
  });
  return response.data;
};

export const getClassesByIds = async (ids: string[]) => {
  const response = await API.get(`${CLASS_API_ENDPOINT}/by-ids`, { ids });
  return response.data.data;
};

export const getUpcomingClasses = (
  studentId: string,
  page: number,
  limit: number,
  isCanAbsence?: boolean
) => {
  const params: {
    page: number;
    limit: number;
    isCanAbsence?: boolean;
  } = { page: page, limit: limit };

  if (typeof isCanAbsence !== 'undefined') params.isCanAbsence = isCanAbsence;

  return API.get(`${CLASS_API_ENDPOINT}/upcoming-class/${studentId}`, params);
};

export const changeClassPrice = (payload: ChangeClassesPriceDTO) => {
  return API.put(`${CLASS_API_ENDPOINT}/change-price`, { body: payload });
};

export const changeClassCapacity = (payload: ChangeClassesCapacityDTO) => {
  return API.put(`${CLASS_API_ENDPOINT}/change-capacity`, { body: payload });
};

export const changeClassInstructor = (payload: ChangeClassesInstructorDTO) => {
  return API.put(`${CLASS_API_ENDPOINT}/change-instructor`, { body: payload });
};

export const changeClassArea = (payload: ChangeClassesAreaDTO) => {
  return API.put(`${CLASS_API_ENDPOINT}/change-area`, { body: payload });
};

export const getLessonDetail = (classId: string, lessonId: string) => {
  return API.get(`${CLASS_API_ENDPOINT}/${classId}/session/${lessonId}/`);
};
