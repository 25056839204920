export const convertHexToRgb = (hexCode: string, opacity: number): string => {
  if (hexCode) {
    // Remove the '#' character if present
    hexCode = hexCode?.replace('#', '');

    // Check if the hex code is valid
    if (!/^[0-9A-F]{6}$/i.test(hexCode)) {
      throw new Error('Invalid hex code');
    }

    // Extract the RGB components from the hex code
    const r = parseInt(hexCode.substring(0, 2), 16);
    const g = parseInt(hexCode.substring(2, 4), 16);
    const b = parseInt(hexCode.substring(4, 6), 16);

    // Return the RGB value
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
  return 'rgba(1, 1, 1, 1)';
};
