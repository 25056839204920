import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from 'context/ToastContext';
import { IHealthQuestionnaire } from 'common/interfaces/healthQuestionnaire.interface';
import {
  getHealthQuestionnaireById,
  updateHealthQuestionnaire
} from 'services/healthQuestionnaire.service';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppSelect from 'common/components/AppSelect';
import {
  HEALTH_ANSWER_TYPE_OPTIONS,
  HEALTH_EXPECTED_ANSWER_OPTIONS,
  HEALTH_SECTION_LABEL_OPTIONS
} from 'common/constants/index';
import yupHealthQuestionnaire from 'validators/healthQuestionnaire.validator';
import { Resolver, useForm, useWatch } from 'react-hook-form';
import { UpdateHealthQuestionnaireDto } from 'DTOs/healthQuestionnaire.dto';
import { yupResolver } from '@hookform/resolvers/yup';
import AppToggle from 'common/components/AppToggle';
import AppTextArea from 'common/components/AppTextArea';
import { HEALTH_ANSWER_TYPE } from 'common/enums/healthQuestionnaire.enum';
import AppRadio from 'common/components/AppRadio';
import {
  HiOutlinePencilSquare,
  HiOutlineXCircle,
  HiOutlineBookmark
} from 'react-icons/hi2';
import { BeatLoader } from 'react-spinners';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';

import './desktop.scss';
import AppInput from 'common/components/AppInput';

const validationSchema = yupHealthQuestionnaire.OBJECT({
  question: yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_QUESTION,
  answerType: yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_ANSWER_TYPE,
  sectionLabel: yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_SECTION_LABEL,
  expectedAnswer: yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_EXPECTED_ANSWER,
  isActive: yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_ACTIVE,
  mandatory: yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_MANDATORY,
  medicalCondition:
    yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_MEDICAL_CONDITION,
  medicalConditionCode:
    yupHealthQuestionnaire.HEALTH_QUESTIONNAIRE_MEDICAL_CONDITION_CODE
});

const HealthQuestionnaireDetail = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    trigger,
    formState: { errors }
  } = useForm<UpdateHealthQuestionnaireDto>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<UpdateHealthQuestionnaireDto>
  });

  const watchAllFields = useWatch({ control });

  const params = useParams();
  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingAPI, setLoadingAPI] = useState<boolean>(false);
  const [healthQuestionnaire, setHealthQuestionnaire] =
    useState<IHealthQuestionnaire | null>(null);
  const healthQuestionnaireInit = useRef<IHealthQuestionnaire | null>(null);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const onOpenEdit = () => {
    setIsEdit(true);
  };

  const onCloseEdit = useCallback(() => {
    setIsEdit(false);
    setHealthQuestionnaire(healthQuestionnaireInit.current);
    reset();
  }, [reset]);

  const __isRequiredExpectedAnswer = useMemo((): boolean => {
    return (
      healthQuestionnaire?.answerType === HEALTH_ANSWER_TYPE.NUMBER &&
      healthQuestionnaire?.mandatory
    );
  }, [healthQuestionnaire?.answerType, healthQuestionnaire?.mandatory]);

  const onSaveEdit = useCallback(
    async (data: UpdateHealthQuestionnaireDto) => {
      if (
        !healthQuestionnaire ||
        (__isRequiredExpectedAnswer && !healthQuestionnaire.expectedAnswer)
      )
        return;

      setLoadingAPI(true);
      try {
        const payload: UpdateHealthQuestionnaireDto = {
          isActive: healthQuestionnaire.isActive,
          question: healthQuestionnaire.question,
          answerType: healthQuestionnaire.answerType,
          sectionLabel: healthQuestionnaire.sectionLabel,
          mandatory: healthQuestionnaire.mandatory,
          expectedAnswer: healthQuestionnaire.expectedAnswer,
          medicalCondition: healthQuestionnaire.medicalCondition,
          medicalConditionCode: healthQuestionnaire.medicalConditionCode
        };

        await updateHealthQuestionnaire(healthQuestionnaire._id, payload);
        toast.success('Saved successfully');
        setIsEdit(false);
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Failed to save class template'
        );
        // setHealthQuestionnaire(healthQuestionnaireInit.current);
      } finally {
        setLoadingAPI(false);
      }
    },
    // eslint-disable-next-line
    [healthQuestionnaire, __isRequiredExpectedAnswer]
  );

  const __renderIcons = useMemo((): React.ReactNode => {
    if (isEdit) {
      return loadingAPI ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSaveEdit)}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
  }, [isEdit, onSaveEdit, handleSubmit, loadingAPI, onCloseEdit]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!!healthQuestionnaire) {
        setHealthQuestionnaire({
          ...healthQuestionnaire,
          [event.target.name]: event.target.value
        });
        // @ts-ignore
        setValue(event.target.name, event.target.value);
        // @ts-ignore
        trigger(event.target.name);
      }
    },
    [healthQuestionnaire, setValue, trigger]
  );

  const __renderContent = useMemo((): React.ReactNode => {
    if (!healthQuestionnaire) return null;
    if (isEdit) {
      return (
        <div className="healthQuestionnaireDetail__card healthQuestionnaireDetail__card-edit">
          <div className="healthQuestionnaireDetail__card-fields healthQuestionnaireDetail__card-fields-edit">
            <AppSelect
              label="Section Label"
              options={HEALTH_SECTION_LABEL_OPTIONS}
              {...register('sectionLabel')}
              value={healthQuestionnaire.sectionLabel}
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.sectionLabel?.message || ''
              }}
            />
            <AppSelect
              label="Answer type"
              options={HEALTH_ANSWER_TYPE_OPTIONS}
              {...register('answerType')}
              value={healthQuestionnaire.answerType}
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.answerType?.message || ''
              }}
            />
            <div className="healthQuestionnaireDetail__card-fields-toggle">
              <div className="healthQuestionnaireDetail__card-fields-toggle-item">
                <AppToggle
                  {...register('isActive')}
                  value={healthQuestionnaire.isActive}
                  onChange={() => {
                    setValue('isActive', !healthQuestionnaire.isActive);
                    trigger('isActive');
                    setHealthQuestionnaire({
                      ...healthQuestionnaire,
                      isActive: !healthQuestionnaire.isActive
                    });
                  }}
                />
                <p>Active</p>
              </div>
              <div className="healthQuestionnaireDetail__card-fields-toggle-item">
                <AppToggle
                  {...register('mandatory')}
                  value={healthQuestionnaire.mandatory}
                  onChange={() => {
                    setValue('mandatory', !healthQuestionnaire.mandatory);
                    trigger('mandatory');
                    setHealthQuestionnaire({
                      ...healthQuestionnaire,
                      mandatory: !healthQuestionnaire.mandatory
                    });
                  }}
                />
                <p>Mandatory</p>
              </div>
            </div>
          </div>

          <AppCardContent className="healthQuestionnaireDetail__card-fields healthQuestionnaireDetail__card-fields-edit">
            <AppInput
              label="Medical Condition"
              {...register('medicalCondition')}
              value={healthQuestionnaire.medicalCondition}
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.medicalCondition?.message || ''
              }}
            />
            <AppInput
              label="Medical Condition Code"
              {...register('medicalConditionCode')}
              value={healthQuestionnaire.medicalConditionCode}
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.medicalConditionCode?.message || ''
              }}
            />
          </AppCardContent>

          <AppCardContent className="healthQuestionnaireDetail__card-area">
            <AppTextArea
              label="Question"
              {...register('question')}
              value={healthQuestionnaire.question}
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.question?.message || ''
              }}
            />
          </AppCardContent>

          {__isRequiredExpectedAnswer && (
            <AppCardContent className="healthQuestionnaireDetail__card-radio">
              <p>Expected Answer*</p>
              {!watchAllFields?.expectedAnswer ? (
                <div className="errText">Please select expected answer</div>
              ) : null}
              <div className="healthQuestionnaireDetail__card-radio-group">
                {HEALTH_EXPECTED_ANSWER_OPTIONS.map((expectedAnswer) => {
                  return (
                    <AppRadio
                      name="expectedAnswer"
                      key={expectedAnswer.value}
                      label={expectedAnswer.label}
                      value={expectedAnswer.value}
                      checked={
                        expectedAnswer.value ===
                        healthQuestionnaire.expectedAnswer
                      }
                      onChange={() => {
                        setValue('expectedAnswer', expectedAnswer.value);
                        trigger('expectedAnswer');
                        setHealthQuestionnaire({
                          ...healthQuestionnaire,
                          expectedAnswer: expectedAnswer.value
                        });
                      }}
                    />
                  );
                })}
              </div>
            </AppCardContent>
          )}
        </div>
      );
    }
    return (
      <div className="healthQuestionnaireDetail__card healthQuestionnaireDetail__card-display">
        <div className="healthQuestionnaireDetail__card-fields">
          <AppCardContentItem subtitle="Brand" title={'Club Lime'} />
          <AppCardContentItem
            subtitle="Selection Label"
            title={healthQuestionnaire.sectionLabel.replaceAll('_', ' ')}
          />
          <AppCardContentItem
            subtitle="Answer Type"
            title={healthQuestionnaire.answerType}
          />
          <div className="healthQuestionnaireDetail__card-fields-toggle">
            <div className="healthQuestionnaireDetail__card-fields-toggle-item">
              <AppToggle value={healthQuestionnaire.isActive} disabled />
              <p>Active</p>
            </div>
            <div className="healthQuestionnaireDetail__card-fields-toggle-item">
              <AppToggle value={healthQuestionnaire.mandatory} disabled />
              <p>Mandatory</p>
            </div>
          </div>
        </div>

        <div className="healthQuestionnaireDetail__card-fields">
          <AppCardContentItem
            subtitle="Medical Condition"
            title={healthQuestionnaire.medicalCondition}
          />

          <AppCardContentItem
            subtitle="Medical Condition Code"
            title={healthQuestionnaire.medicalConditionCode}
          />
        </div>

        <div className="h0 ealthQuestionnaireDetail__card-area">
          <AppCardContentItem
            subtitle="Question"
            title={healthQuestionnaire.question}
          />
        </div>
        {__isRequiredExpectedAnswer && (
          <div className="healthQuestionnaireDetail__card-radio">
            <AppCardContentItem
              subtitle="Expected Answer"
              title={healthQuestionnaire.expectedAnswer}
            />
          </div>
        )}
      </div>
    );
  }, [
    isEdit,
    healthQuestionnaire,
    register,
    __isRequiredExpectedAnswer,
    setValue,
    trigger,
    errors,
    handleChange,
    watchAllFields
  ]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await getHealthQuestionnaireById(params?.id || '');
      setHealthQuestionnaire(data.data);
      healthQuestionnaireInit.current = data.data;
      console.log('Data ', data.data);

      setValue('expectedAnswer', data.data?.expectedAnswer);
    } catch (error: any) {
      setHealthQuestionnaire(null);

      toast.error(error?.response?.data?.message || 'Fetch HQ failed');
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [params?.id, setValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <main className="healthQuestionnaireDetailPage">
      <AppBreadCrumb
        items={[
          { name: 'Health Questionnaire', href: '/health-questionnaire' },
          { name: 'Health Questionnaire Details', href: '' }
        ]}
      />
      {loading ? (
        <AppLoadingContainer />
      ) : (
        <div className="layoutContainer healthQuestionnaireDetail">
          <AppCard>
            <AppCardHeader
              title="Health Questionnaire"
              suffix={
                <PermissionWrapper
                  permission={PERMISSION.UPDATE_HEALTH_QUESTIONNAIRE}
                >
                  {__renderIcons}
                </PermissionWrapper>
              }
            />
            <AppCardContent>{__renderContent}</AppCardContent>
          </AppCard>
        </div>
      )}
    </main>
  );
};

export default HealthQuestionnaireDetail;
