import { Fragment, memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLayout } from 'context/LayoutContext';
import { Link } from 'react-router-dom';
import { HiCheck } from 'react-icons/hi';
import MenuIcon from 'components/layout/MenuIcon';
import { useStepController } from 'context/StepControllerContext';
import { FLOW_ENUM } from 'common/enums/step.enum';

import './desktop.scss';

interface IBreadcrumbItem {
  name: string;
  href?: string;
}

interface IAppBreadCrumbProps {
  items: Array<IBreadcrumbItem>;
}

type BookingStep = {
  title: string;
  active: boolean;
};

const initBookingSteps = [
  { title: 'Booking', active: false },
  { title: 'Add student', active: false },
  { title: 'Enrolment', active: false },
  { title: 'Review & Payment', active: false }
];

const AppHeader = ({ items }: IAppBreadCrumbProps) => {
  const { dataSet } = useStepController();

  const location = useLocation();

  const { handleMouseLeaveMenu, isShowSidebar } = useLayout();

  const [breadCrumb, setBreadCrumb] = useState<IBreadcrumbItem[]>([]);
  const [bookingSteps, setBookingSteps] = useState<BookingStep[]>([
    ...initBookingSteps
  ]);

  if (items.length < 1) {
    throw new Error('items in AppHeader must have at least 1 item');
  }

  const handleAddStepBackward = (index: number) => {
    if (
      dataSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
      (dataSet?.step === 2 ||
        dataSet?.step === 3 ||
        dataSet?.step === 4 ||
        dataSet?.step === 5 ||
        dataSet?.step === 6 ||
        dataSet?.step === 7)
    ) {
      dataSet?.handleAddStepBackward(index * 2 + 1, dataSet);
    }
  };

  const handleShowStepBackward = (index: number): boolean => {
    return (
      index <
        bookingSteps.findIndex(
          (bookingStep: BookingStep) => bookingStep.active
        ) && [1, 3, 5, 7].includes(index * 2 + 1)
    );
  };

  useEffect(() => {
    if (dataSet?.flow === FLOW_ENUM.CLASS_BOOKING) {
      for (const [index, item] of [0, 2, 4, 6].entries()) {
        if ([item, item + 1].includes(dataSet?.step)) {
          setBookingSteps((bookingSteps: BookingStep[]) => {
            return bookingSteps.map((step: BookingStep, idx) => {
              if (index === idx) {
                return { ...step, active: true };
              }

              return { ...step, active: false };
            });
          });
        }
      }
    }
  }, [dataSet]);

  useEffect(() => {
    if (!location?.search) {
      setBreadCrumb(items);
    } else {
      setBreadCrumb([
        ...items,
        ...[
          {
            name: bookingSteps.filter((step: BookingStep) => step.active)?.[0]
              ?.title,
            href: location?.pathname + location?.search
          }
        ]
      ]);
    }
  }, [bookingSteps, location?.pathname, location?.search, items]);

  return (
    <div
      className={`c__app_header__wrapper ${
        isShowSidebar ? ' ' : 'c_app_header__wrapper-collapsed'
      }`}
    >
      <div className="c__app_header_cover">
        <MenuIcon />

        <div
          className={`c__app_header ${
            dataSet?.flow === FLOW_ENUM.CLASS_BOOKING && dataSet?.step === 0
              ? 'btn_end'
              : 'btn_start'
          }`}
          onMouseEnter={handleMouseLeaveMenu}
        >
          {dataSet?.flow === FLOW_ENUM.CLASS_BOOKING && dataSet?.step === 0 ? (
            <Link
              to={breadCrumb?.[0]?.href || ''}
              className="c__app_header-title"
            >
              {breadCrumb?.[0]?.name}
            </Link>
          ) : null}

          {breadCrumb?.length > 1 ? (
            <div className="c__app_header-items">
              {[...breadCrumb].slice(1).map((item: IBreadcrumbItem, index) => {
                return (
                  <div key={index} className="c__app_header-item">
                    <Link to={item?.href || ''}>{item.name}</Link>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>

      <div
        className={`c__app_header_steps_wrapper ${
          dataSet?.flow === FLOW_ENUM.CLASS_BOOKING && dataSet?.step >= 1
            ? 'btn_end'
            : 'btn_start'
        }`}
      >
        {bookingSteps?.map((bookingStep: BookingStep, index) => (
          <Fragment key={index}>
            <div
              className={`c__app_header_step ${
                handleShowStepBackward(index) ? 'allow_step_back' : ''
              }`}
              onClick={() =>
                handleShowStepBackward(index)
                  ? handleAddStepBackward(index)
                  : null
              }
            >
              <div
                className={`c__app_header_step_icon ${
                  [index, index + 1].includes(
                    bookingSteps.findIndex(
                      (bookingStep: BookingStep) => bookingStep.active
                    )
                  )
                    ? 'icon_active'
                    : ''
                } ${
                  index <
                  bookingSteps.findIndex(
                    (bookingStep: BookingStep) => bookingStep.active
                  )
                    ? 'icon_inactive'
                    : ''
                } ${
                  index >
                  bookingSteps.findIndex(
                    (bookingStep: BookingStep) => bookingStep.active
                  )
                    ? 'icon_default'
                    : ''
                }`}
              >
                {index <
                bookingSteps.findIndex(
                  (bookingStep: BookingStep) => bookingStep.active
                ) ? (
                  <>
                    <HiCheck size={28} style={{ color: 'white' }} />
                  </>
                ) : (
                  <></>
                )}

                {index ===
                bookingSteps.findIndex(
                  (bookingStep: BookingStep) => bookingStep.active
                ) ? (
                  <>
                    <div className="inner_icon_inactive"></div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="c__app_header_step_text">
                {bookingStep?.title}
              </div>
            </div>

            {index >= 0 && index < bookingSteps?.length - 1 ? (
              <>
                <div className="c__app_header_steps_wrapper_break"></div>
              </>
            ) : null}
          </Fragment>
        ))}
      </div>
      <div></div>
    </div>
  );
};

export default memo(AppHeader);
