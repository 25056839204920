import { useLayout } from 'context/LayoutContext';
import './styles.scss';
import { Link } from 'react-router-dom';
import MenuIcon from 'components/layout/MenuIcon';

interface IBreadcrumbItem {
  name: string;
  href?: string;
}

interface IAppBreadCrumbProps {
  items: Array<IBreadcrumbItem>;
}

const AppBreadCrumb = (props: IAppBreadCrumbProps) => {
  const { items } = props;

  const { handleMouseLeaveMenu, isShowSidebar } = useLayout();

  if (items.length < 1) {
    throw new Error('items in AppBreadCrumb must have at least 1 item');
  }

  return (
    <div
      className={`c__breadcrumb__wrapper ${
        isShowSidebar ? ' ' : 'c__breadcrumb__wrapper-collapsed'
      }`}
    >
      <MenuIcon />
      <div className="c__breadcrumb" onMouseEnter={handleMouseLeaveMenu}>
        <Link to={items[0].href || ''} className="c__breadcrumb-title">
          {items[0].name}
        </Link>
        {items.length > 1 && (
          <div className="c__breadcrumb-items">
            {[...items].slice(1).map((item: IBreadcrumbItem, key) => {
              return (
                <div key={key} className="c__breadcrumb-item">
                  <Link to={item?.href || ''}>{item.name}</Link>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppBreadCrumb;
