import React from 'react';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppDatePicker from 'common/components/AppDatePicker';
import AppSelect from 'common/components/AppSelect';
import AppButton from 'common/components/AppButton';
import AppInputSearch from 'common/components/AppInputSearch';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import {
  FIXED_PAGE,
  FIXED_SIZE,
  FORMAT_END_OF_DATE,
  FORMAT_START_OF_DATE,
  REPORT_PATH,
  REPORT_PROGRAM_TYPE_OPTIONS
} from '../constant';
import ReportSelection from '../components/ReportSelection';
import { useBrandLocation } from 'context/BrandLocationContext';
import { useToast } from 'context/ToastContext';
import { getStudentBookingSummaryReport } from 'services/report.service';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { ILevel } from 'common/interfaces/levelBreakdown.interface';
import { getLevelBreakdowns } from 'services/levelBreakdown.service';
import { IOption } from 'common/interfaces';
import { uniq } from 'lodash';
import { convertToUnixTime } from 'common/helpers/time.helper';
import dayjs, { Dayjs } from 'dayjs';
import { IStudentBookingSummary } from 'common/interfaces/report.interface';
import { formatData } from 'common/helpers/dataFormat.helper';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import './desktop.scss';

interface IFilter {
  dateFrom: string;
  dateTo: string;
  programType: string;
  levelIds: Array<string>;
}

const initFilter: IFilter = {
  dateFrom: '',
  dateTo: '',
  programType: '',
  levelIds: []
};

const ReportStudentBookingSummary = () => {
  const toast = useToast();
  const { selectedLocation: __globalLocation } = useBrandLocation();

  const [isGenerated, setIsGenerated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  // null === ALL
  const [selectedWeekDay, setSelectedWeekDay] = React.useState<Dayjs | null>(
    null
  );
  const [studentBookingSummaries, setStudentBookingSummaries] = React.useState<
    Array<IStudentBookingSummary>
  >([]);

  // Search section
  const [dateFrom, setDateFrom] = React.useState<string>('');
  const [dateTo, setDateTo] = React.useState<string>('');
  const [programType, setProgramType] = React.useState<CLASS_TYPES | ''>('');
  const [levelIds, setLevelIds] = React.useState<Array<string>>([]);

  const filter = React.useRef<IFilter>(initFilter);

  const [levels, setLevels] = React.useState<Array<ILevel>>([]);

  const [search, setSearch] = React.useState<string>('');

  const __levelOptions: Array<IOption> = React.useMemo(() => {
    return levels.map((level) => ({ label: level.name, value: level._id }));
  }, [levels]);

  const __levelPlaceholderText: string = React.useMemo(() => {
    return levelIds.length === 0
      ? 'Select option'
      : `${levelIds.length} selected`;
  }, [levelIds]);

  const __isDisableGenerateButton: boolean = React.useMemo(() => {
    return !dateFrom || !programType || levelIds.length === 0;
  }, [dateFrom, programType, levelIds]);

  const __weekDays: dayjs.Dayjs[] = React.useMemo(() => {
    const parsedDate = dayjs(filter.current.dateFrom);
    if (!parsedDate.isValid()) {
      return [];
    }
    const days: dayjs.Dayjs[] = [];
    for (let i = 0; i < 7; i++) {
      const nextDay = parsedDate.add(i, 'day');
      days.push(nextDay);
    }
    return days;
    // eslint-disable-next-line
  }, [filter?.current?.dateFrom]);

  const __studentBookingSummaries: Array<IStudentBookingSummary> =
    React.useMemo(() => {
      return studentBookingSummaries.filter((studentBookingSummary) =>
        studentBookingSummary.level.shortName
          .replace(/\s/g, '')
          .toLowerCase()
          .includes(search.replace(/\s/g, '').toLowerCase())
      );
    }, [studentBookingSummaries, search]);

  const __total: IStudentBookingSummary = React.useMemo(() => {
    const total: IStudentBookingSummary = {
      level: {
        _id: '',
        name: 'Total',
        shortName: 'Total'
      },
      maxStudent: 0,
      numberClass: 0,
      percentBooking: '0.00',
      totalStudentBooked: 0,
      totalStudentBookedDD: 0,
      totalStudentBookedUpfront: 0,
      totalStudentCasual: 0,
      totalStudentMakeUp: 0,
      vacancies: 0
    };

    __studentBookingSummaries.forEach((studentBookingSummary) => {
      total.numberClass += studentBookingSummary.numberClass;
      total.maxStudent += studentBookingSummary.maxStudent;
      total.totalStudentBookedDD += studentBookingSummary.totalStudentBookedDD;
      total.totalStudentBookedUpfront +=
        studentBookingSummary.totalStudentBookedUpfront;
      total.totalStudentMakeUp += studentBookingSummary.totalStudentMakeUp;
      total.totalStudentCasual += studentBookingSummary.totalStudentCasual;
      total.totalStudentBooked += studentBookingSummary.totalStudentBooked;
      total.vacancies += studentBookingSummary.vacancies;
      // @ts-ignore
      if (!isNaN(studentBookingSummary.percentBooking)) {
        total.percentBooking = (
          parseFloat(total.percentBooking) +
          parseFloat(studentBookingSummary.percentBooking)
        ).toFixed(2);
      }
    });

    return total;
  }, [__studentBookingSummaries]);

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  const handleClearSearch = () => {
    setSearch('');
  };

  const handleChangeDateFrom = (value: Dayjs | null) => {
    setDateFrom(value?.format(FORMAT_START_OF_DATE) || '');
    setDateTo(value?.add(6, 'day')?.format(FORMAT_END_OF_DATE) || '');
  };

  const handleChangeProgramType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setProgramType(event.target.value as CLASS_TYPES);
  };

  const handleToggleLevel = (value: string) => {
    const newLevelIds = [...levelIds];
    const findIndex = newLevelIds.findIndex((levelId) => levelId === value);
    if (findIndex === -1) {
      newLevelIds.push(value);
    } else {
      newLevelIds.splice(findIndex, 1);
    }
    setLevelIds(newLevelIds);
  };

  const handleSelectAllLevel = () => {
    const ids = levels.map((level) => level._id);
    if (levelIds.length === levels.length) {
      setLevelIds([]);
    } else {
      setLevelIds(uniq(ids.concat(levelIds)));
    }
  };

  const handleChangeSelectedWeekDay = (weekday: Dayjs | null) => {
    setSelectedWeekDay(weekday);
    if (!!weekday) {
      fetchStudentBookingSummary(
        weekday.format(FORMAT_START_OF_DATE),
        weekday.format(FORMAT_END_OF_DATE)
      );
    } else {
      fetchStudentBookingSummary(
        filter.current.dateFrom,
        filter.current.dateTo
      );
    }
  };

  const handleGenerate = () => {
    setSelectedWeekDay(null);
    filter.current = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      programType: programType,
      levelIds: levelIds
    };
    fetchStudentBookingSummary(filter.current.dateFrom, filter.current.dateTo);
  };

  const fetchStudentBookingSummary = async (
    dateFrom: string,
    dateTo: string
  ) => {
    if (!__globalLocation?._id) return;

    setLoading(true);
    try {
      const result = await getStudentBookingSummaryReport(
        convertToUnixTime(dateFrom),
        convertToUnixTime(dateTo),
        __globalLocation._id,
        filter.current.programType as CLASS_TYPES,
        filter.current.levelIds
      );
      setStudentBookingSummaries(result?.data?.data);
      setIsGenerated(true);
    } catch (error: any) {
      setStudentBookingSummaries([]);
      toast.error(
        error?.response?.data?.message ||
          'Failed to get student booking summary'
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchInitData = React.useCallback(async () => {
    try {
      const result = await getLevelBreakdowns(
        FIXED_PAGE,
        FIXED_SIZE,
        __globalLocation?._id || ''
      );
      setLevels(result.data.data.data);
      setLevelIds([]);
    } catch (error: any) {
      setLevels([]);
      toast.error(error?.response?.data?.message || 'Failed to get init data');
    }
    // eslint-disable-next-line
  }, [__globalLocation]);

  React.useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  return (
    <main
      id="reportStudentBookingSummary"
      className="reportStudentBookingSummary"
    >
      <AppBreadCrumb
        items={[
          { name: 'Reporting', href: REPORT_PATH },
          { name: 'Student Booking Summary' }
        ]}
      />
      <div className="layoutContainer reportStudentBookingSummary__wrapper">
        <section className="reportStudentBookingSummary__search">
          <AppDatePicker
            size="small"
            label="Date from"
            value={dayjs(dateFrom)}
            onChange={handleChangeDateFrom}
          />
          <AppDatePicker
            size="small"
            label="Date to"
            value={dayjs(dateTo)}
            disabled
          />
          <AppSelect
            searchable={false}
            inputSize="small"
            label="Program Type"
            placeholder="Select option"
            options={REPORT_PROGRAM_TYPE_OPTIONS}
            value={programType}
            onChange={handleChangeProgramType}
          />
          <ReportSelection
            label="Level"
            placeholder={__levelPlaceholderText}
            options={__levelOptions}
            onSelect={handleToggleLevel}
            onSelectAll={handleSelectAllLevel}
            selectedIds={levelIds}
          />
          <AppButton
            variant="primary"
            buttonSize="small"
            onClick={handleGenerate}
            disabled={__isDisableGenerateButton}
          >
            generate
          </AppButton>
        </section>

        {!isGenerated && loading && <AppLoadingContainer />}

        {isGenerated && (
          <section className="reportStudentBookingSummary__content">
            {loading && <AppLoadingContainer />}
            <div className="reportStudentBookingSummary__content__header">
              <p className="reportStudentBookingSummary__content__header--title">
                Student booking
              </p>
              <AppInputSearch
                type="text"
                startIcon={<HiMagnifyingGlass size={22} />}
                placeholder="Search"
                value={search}
                onChange={handleChangeSearch}
                onClearSearch={handleClearSearch}
              />
            </div>

            <div className="reportStudentBookingSummary__content__days">
              <div
                className={`reportStudentBookingSummary__content__day ${
                  selectedWeekDay === null ? 'active' : ''
                }`}
                onClick={() => handleChangeSelectedWeekDay(null)}
              >
                All
              </div>
              {__weekDays.map((weekDay, index) => {
                return (
                  <div
                    key={index}
                    className={`reportStudentBookingSummary__content__day ${
                      selectedWeekDay === weekDay ? 'active' : ''
                    }`}
                    onClick={() => handleChangeSelectedWeekDay(weekDay)}
                  >
                    {weekDay.format('ddd, MMM D')}
                  </div>
                );
              })}
            </div>

            <div className="reportStudentBookingSummary__content__table">
              <table>
                <thead>
                  <tr>
                    <th>level</th>
                    <th>Number Class</th>
                    <th>Max Student</th>
                    <th>Total Student Booked DD</th>
                    <th>Total Student Booked Upfront</th>
                    <th>Total Student make-up</th>
                    <th>Total Student Casual</th>
                    <th>Total Student booked</th>
                    <th>vacancies</th>
                    <th>Booked (%)</th>
                  </tr>
                </thead>
                <tbody>
                  {__studentBookingSummaries.map(
                    (studentBookingSummary, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            {formatData(studentBookingSummary.level.shortName)}
                          </td>
                          <td>
                            {formatData(studentBookingSummary.numberClass)}
                          </td>
                          <td>
                            {formatData(studentBookingSummary.maxStudent)}
                          </td>
                          <td>
                            {formatData(
                              studentBookingSummary.totalStudentBookedDD
                            )}
                          </td>
                          <td>
                            {formatData(
                              studentBookingSummary.totalStudentBookedUpfront
                            )}
                          </td>
                          <td>
                            {formatData(
                              studentBookingSummary.totalStudentMakeUp
                            )}
                          </td>
                          <td>
                            {formatData(
                              studentBookingSummary.totalStudentCasual
                            )}
                          </td>
                          <td>
                            {formatData(
                              studentBookingSummary.totalStudentBooked
                            )}
                          </td>
                          <td>{formatData(studentBookingSummary.vacancies)}</td>
                          {/* Check case NaN (number / 0) => 0% */}
                          <td>
                            {/* @ts-ignore */}
                            {isNaN(studentBookingSummary?.percentBooking)
                              ? '0.00'
                              : formatData(
                                  studentBookingSummary?.percentBooking
                                )}
                          </td>
                        </tr>
                      );
                    }
                  )}
                  {/* Total Row */}
                  <tr>
                    <td>{formatData(__total.level.shortName)}</td>
                    <td>{formatData(__total.numberClass)}</td>
                    <td>{formatData(__total.maxStudent)}</td>
                    <td>{formatData(__total.totalStudentBookedDD)}</td>
                    <td>{formatData(__total.totalStudentBookedUpfront)}</td>
                    <td>{formatData(__total.totalStudentMakeUp)}</td>
                    <td>{formatData(__total.totalStudentCasual)}</td>
                    <td>{formatData(__total.totalStudentBooked)}</td>
                    <td>{formatData(__total.vacancies)}</td>
                    {/* Check case NaN (number / 0) => 0% */}
                    <td>
                      {/* @ts-ignore */}
                      {isNaN(__total?.percentBooking)
                        ? '0.00'
                        : formatData(__total?.percentBooking)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        )}
      </div>
    </main>
  );
};

export default ReportStudentBookingSummary;
