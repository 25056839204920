export enum REPORT_LINK {
  STUDENT_BOOKING_SUMMARY = 'student-booking-summary',
  PERIOD_SUMMARY_REPORT = 'period-summary-report',
  MEMBER_REPORT = 'member-report',
  EXPORT_FAMILY_DETAILS = 'export-family-details',
  TIMETABLE_BY_TEACHER = 'timetable-by-teacher',
  TIMETABLE_BY_SUPERVISOR = 'timetable-by-supervisor',
  OUTSTANDINGS = 'outstandings',
  CREDIT_ADJUSTMENT_REPORT = 'credit-adjustment-report',
  GRADUATED_AND_NOT_MOVED = 'graduated-and-not-moved',
  ASSESSMENT_OUTCOME_REPORT = 'assessment-outcome-report',
  DATE_OF_LAST_ASSESSMENT_REPORT = 'date-of-last-assessment-report',
  DISCOUNTED_ENROLMENT_REPORT = 'discounted-enrolment-report',
  NOT_ATTENDANCE_REPORT = 'not-attendance-report',
  ABANDONED_CARD_LIST = 'abandoned-card-list',
  GST_REPORT = 'gst-report'
}

export enum REPORT_VIEW_RESULT {
  SUMMARY_VIEW = 'summary_view',
  DETAILED_VIEW = 'detailed_view'
}

// EXPORT FAMILY DETAILS REPORT
export enum FAMILY_STUDENT_STATUS {
  IDLE = '',
  ONGOING_BOOKING = 'ongoing_booking',
  EXPIRED_BOOKING_WITHOUT_RENEWAL = 'expired_booking_without_renewal',
  ACTIVE_BOOKING_FIRST_WEEK_OF_THE_TERM = 'active_booking_first_week_of_the_term',
  STUDENT_AND_LAST_LESSON = 'student_and_last_lesson',
  BOOKING_NEW_ASSESSMENT = 'booking_new_assessment'
}
export enum STUDENT_OPTION {
  IDLE = '',
  STUDENT = 'student',
  FAMILY = 'family'
}

export enum ASSESSMENT_STATISTIC {
  NONE = '',
  ACHIEVED = 'achieved',
  DEVELOPING = 'developing',
  APPROVED = 'approved',
  APPROVAL_PENDING = 'approval-pending'
}
