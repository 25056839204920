import { createColumnHelper } from '@tanstack/react-table';

import AppTable from 'common/components/AppTable';
import AppToolTip from 'common/components/AppToolTip';
import AppCard, {
  AppCardContent,
  AppCardHeader
} from 'common/components/AppCard';

import { IStudent } from 'common/interfaces/student.interface';
import {
  HEALTH_ANSWER_TYPE,
  HEALTH_ANSWER
} from 'common/enums/healthQuestionnaire.enum';

import './index.scss';

interface Props {
  data: IStudent | null;
}

const HealthQuestionnaire: React.FC<Props> = ({ data }) => {
  const columnHelper = createColumnHelper<any>();

  const columns = [
    columnHelper.accessor('questionInfo', {
      header: () => <span>Question</span>,
      cell: (info) => (
        <>
          <div className="health-questionnaire-table_quest">
            {info.row.original?.questionInfo?.question ?? ''}
          </div>
        </>
      ),
      size: 300
    }),
    columnHelper.accessor('answer', {
      header: () => <span>Answer</span>,
      cell: (info) => {
        if (
          info.row.original?.questionInfo?.answerType ===
          HEALTH_ANSWER_TYPE.STRING
        ) {
          return (
            <>
              <AppToolTip
                tooltipText={info.row.original?.answer}
                typeToolTipText={'fit'}
              >
                <div style={{ cursor: 'pointer' }} className="line-clamp-1">
                  {info.row.original?.answer}
                </div>
              </AppToolTip>
            </>
          );
        }

        return (
          <>
            <div>
              {info.row.original?.answer === HEALTH_ANSWER.YES ? 'YES' : 'NO'}
            </div>
          </>
        );
      }
    })
  ];

  return (
    <section className="health-questionnaire">
      <AppCard>
        <AppCardContent className="cardContent">
          <AppCardHeader title="Health Questionnaire" />

          <div className="health-questionnaire-table">
            <AppTable data={data?.healthQuestionnaires} columns={columns} />
          </div>
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default HealthQuestionnaire;
