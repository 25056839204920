import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import AppCard, { AppCardContent } from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import AppSelect from 'common/components/AppSelect';
import { createColumnHelper } from '@tanstack/react-table';
import { formatData } from 'common/helpers/dataFormat.helper';
import AppTable from 'common/components/AppTable';
import AppInputSearch from 'common/components/AppInputSearch';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import AppButton from 'common/components/AppButton';
import { GoFilter } from 'react-icons/go';
import { useToast } from 'context/ToastContext';
import AppTextArea from 'common/components/AppTextArea';
import AppCKEditor from 'components/common/AppCKEditor';
import './desktop.scss';
import { useLocation, useParams } from 'react-router-dom';
import {
  INotification,
  INotificationActivity
} from 'common/interfaces/notification.interface';
import {
  CreateNotificationDTO,
  ScheduleNotification,
  UpdateNotificationPayloadDTO
} from 'DTOs/notification.dto';
import {
  NOTIFICATION_STATUS,
  NOTIFICATION_TYPE
} from 'common/enums/notification.enum';
import { DATE_CONSTANT, NOTIFICATION_TYPE_OPTIONS } from 'common/constants';
import yup from 'validators/notification.validator';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getNotificationDetail,
  getNotificationsActivity,
  updateNotification
} from 'services/notifications.service';
import { useAuth } from 'context/AuthContext';
import { PERMISSION } from 'common/enums/permission.enum';
import ScheduleFormModal from '../components/ScheduleFormModal';
import dayjs from 'dayjs';
import { convertToUnixTime } from 'common/helpers/time.helper';
import PermissionWrapper from 'components/PermissionWrapper';
import StudentFilter from '../components/StudentFilter';
import { IStudentFilter } from '../interface';
import { IStudentListFilter } from 'common/interfaces/student.interface';
import useDebounce from 'common/hooks/useDebounce';
import { htmlToPlainText, plainTextToHtml } from 'helpers/index.helper';
import { initFilterValue } from '../constants';

interface INotificationEditProps {
  open: boolean;
  onClose: () => void;
  data: INotification | null;
  onSuccess: () => void;
}

const validationSchema = yup.OBJECT({
  types: yup.NOTIFICATION_TYPE,
  title: yup.NOTIFICATION_TITLE,
  smsDescription: yup.NOTIFICATION_SMS_MESSAGE,
  emailDescription: yup.NOTIFICATION_EMAIL_MESSAGE,
  studentIds: yup.NOTIFICATION_STUDENT_ID
});
const NotificationEdit = (props: INotificationEditProps) => {
  const { open, onClose, data, onSuccess } = props;

  const toast = useToast();
  const location = useLocation();
  const { hasPermission } = useAuth();
  const params = useParams();

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<CreateNotificationDTO>({
    resolver: yupResolver(validationSchema) as Resolver<CreateNotificationDTO>
  });
  const __isStudentPage = useMemo(
    () => location.pathname.includes('/students/'),
    [location.pathname]
  );
  const __isRPPage = useMemo(
    () => location.pathname.includes('/responsible-person/'),
    [location.pathname]
  );
  const __RPId = useMemo(() => {
    if (__isRPPage) {
      return params.id;
    }
    return '';
  }, [params, __isRPPage]);

  const columnHelper = createColumnHelper<INotificationActivity>();

  const columns = [
    columnHelper.accessor('student.firstName', {
      header: () => <span>surname, name</span>,
      cell: (info) => (
        <div>
          {formatData(info.row.original.student?.lastName)},{' '}
          {formatData(info.getValue())}
        </div>
      )
    }),
    columnHelper.accessor('responsiblePerson', {
      header: () => <span>rp name</span>,
      cell: (info) => (
        <div>
          {formatData(info.getValue()?.lastName)},{' '}
          {formatData(info.getValue()?.firstName)}
        </div>
      )
    }),
    columnHelper.accessor('responsiblePerson.phoneNumber', {
      header: () => <span>rp mobile</span>,
      cell: (info) => <div>{formatData(info.getValue())}</div>
    }),
    columnHelper.accessor('responsiblePerson.email', {
      header: () => <span>rp email</span>,
      cell: (info) => <div>{formatData(info.getValue())}</div>
    }),
    columnHelper.accessor('status', {
      header: () => <span>status</span>,
      cell: (info) => (
        <div
          className={`notification-status notification-status-${info.getValue()}`}
        >
          {formatData(info.getValue())}
        </div>
      )
    })
  ];

  const initNotification: CreateNotificationDTO = {
    title: data?.title || '',
    smsDescription: data?.message || '',
    emailDescription: data?.message || '',
    types: data?.types.join(',') || NOTIFICATION_TYPE.SMS
  };

  const [studentsNotification, setStudentsNotification] = React.useState<
    Array<INotificationActivity>
  >([]);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce<string>(search);

  const [notification, setNotification] =
    React.useState<CreateNotificationDTO>(initNotification);
  const [openScheduleModal, setOpenScheduleModal] =
    React.useState<boolean>(false);
  const initScheduleForm: ScheduleNotification = {
    scheduleDate: dayjs(data?.scheduleDateTime).format('YYYY-MM-DD'),
    scheduleTime: dayjs(data?.scheduleDateTime).format('HH:mm')
  };
  const [scheduleDateTime, setScheduleDateTime] =
    useState<ScheduleNotification>(initScheduleForm);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const currentFilter = useRef<IStudentFilter | null>({
    ...initFilterValue
  });
  const [loadingStudents, setLoadingStudents] = React.useState<boolean>(false);
  const [filterFieldNumber, setFilterFieldNumber] = useState<number>(0);

  const fetchStudents = React.useCallback(async () => {
    if (!currentFilter.current || !data) return;

    try {
      setLoadingStudents(true);

      const params: IStudentListFilter = { notificationId: data._id };
      if (debouncedSearch) {
        params.keyword = debouncedSearch;
      }
      let filterFieldNumber = 0;
      if (currentFilter.current.termId) {
        params.termId = currentFilter.current.termId;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.levelId) {
        params.levelIds = currentFilter.current.levelId;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.instructorId) {
        params.instructorIds = currentFilter.current.instructorId;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.assessmentResult) {
        params.assessmentResult = currentFilter.current.assessmentResult;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.assessmentStatus) {
        params.assessmentStatus = currentFilter.current.assessmentStatus;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.paymentType) {
        params.paymentType = currentFilter.current.paymentType;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.outstandingFee) {
        params.outstandingFee = currentFilter.current.outstandingFee;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.enrollmentType) {
        params.enrollmentTypes = currentFilter.current.enrollmentType;
        filterFieldNumber += 1;
      }

      if (currentFilter.current.dateField) {
        filterFieldNumber += 1;
        if (currentFilter.current.dateFrom)
          params.fromDate = convertToUnixTime(currentFilter.current.dateFrom);
        if (currentFilter.current.dateTo)
          params.toDate = convertToUnixTime(currentFilter.current.dateTo);
      }
      if (currentFilter.current.timeField) {
        filterFieldNumber += 1;
        if (currentFilter.current.timeFrom)
          params.fromTime = convertToUnixTime(
            `${DATE_CONSTANT} ${currentFilter.current.timeFrom}`
          );
        if (currentFilter.current.timeTo)
          params.toTime = convertToUnixTime(
            `${DATE_CONSTANT} ${currentFilter.current.timeTo}`
          );
      }
      if (currentFilter.current.areaId) {
        filterFieldNumber += 1;
        params.areas = currentFilter.current.areaId;
      }

      if (currentFilter.current.programType) {
        filterFieldNumber += 1;
        params.classTypes = currentFilter.current.programType;
      }

      if (currentFilter.current.locationId) {
        filterFieldNumber += 1;
        params.locationIds = currentFilter.current.locationId;
      }
      setFilterFieldNumber(filterFieldNumber);
      if (__isRPPage) {
        params.responsiblePersonId = __RPId || '';
      }
      if (Object.keys(params).length === 0) {
        return;
      }
      const { data: studentList } = await getNotificationsActivity(params);

      setStudentsNotification(studentList.data.data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fetch Student List failed'
      );
    } finally {
      setLoadingStudents(false);
    }
    // eslint-disable-next-line
  }, [debouncedSearch, currentFilter, data]);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  const handleOpenFilter = () => {
    setShowFilter(true);
  };
  const handleCloseFilter = () => {
    setShowFilter(false);
  };
  const handleFilter = (filterValue: IStudentFilter) => {
    handleCloseFilter();
    currentFilter.current = filterValue;
    fetchStudents();
  };
  const __isCanEdit = useMemo(() => {
    if (
      data?.status &&
      [NOTIFICATION_STATUS.SENT, NOTIFICATION_STATUS.CANCELED].includes(
        data?.status
      )
    ) {
      return false;
    }
    if (hasPermission(PERMISSION.UPDATE_ABSENCE)) {
      return true;
    }
    return false;
  }, [data, hasPermission]);

  const fetchNotification = useCallback(async () => {
    if (!data) return;
    setLoading(true);
    setLoadingStudents(true);
    try {
      const notificationData = await getNotificationDetail(data._id || '');
      setNotification({
        title: notificationData.title,
        smsDescription: notificationData.message,
        emailDescription: notificationData.message,
        types: notificationData.types.join(',')
      });
      setScheduleDateTime({
        scheduleDate: dayjs(notificationData.scheduleDateTime).format(
          'YYYY-MM-DD'
        ),
        scheduleTime: dayjs(notificationData.scheduleDateTime).format('HH:mm')
      });
      setStudentsNotification(notificationData.notificationActivities || []);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fetch Notification failed'
      );
    } finally {
      setLoading(false);
      setLoadingStudents(false);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    fetchNotification();
  }, [fetchNotification]);

  React.useEffect(() => {
    setSelectedStudentIds([...(data?.studentIds || [])]);
    const dataNotificationForm = {
      title: data?.title || '',
      smsDescription: data?.message || '',
      emailDescription: data?.message || '',
      types: data?.types.join(',') || NOTIFICATION_TYPE.SMS
    };
    setNotification(dataNotificationForm);
    reset(dataNotificationForm);
    // eslint-disable-next-line
  }, [data]);
  const [selectedStudentIds, setSelectedStudentIds] = React.useState<
    Array<string>
  >([]);

  const [loading, setLoading] = React.useState<boolean>(false);

  if (!data || !notification) return;

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleClearSearch = () => {
    setSearch('');
  };

  const handleChangeScheduleTime = (value: ScheduleNotification) => {
    setScheduleDateTime(value);
  };

  const handleUpdateNotification = () => {
    setLoading(true);
    const payload: UpdateNotificationPayloadDTO = {
      title: notification.title,
      message:
        notification.types === NOTIFICATION_TYPE.SMS
          ? notification.smsDescription
          : notification.emailDescription,
      scheduleDateTime: convertToUnixTime(
        `${scheduleDateTime.scheduleDate} ${scheduleDateTime.scheduleTime}`
      ),
      types: notification.types.split(','),
      studentIds: selectedStudentIds
    };
    updateNotification(data._id, payload)
      .then(() => {
        toast.success('Update Notification successfully');
        setOpenScheduleModal(false);
        onSuccess();
      })
      .catch(() => {
        toast.error('Update Notification failed');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.name === 'types') {
      const newTypes = event.target.value.split(',');
      const currentTypes = notification.types.split(',');
      const newTypesIncludesSMS = newTypes.includes(NOTIFICATION_TYPE.SMS);
      const currentTypesIncludesSMS = currentTypes.includes(
        NOTIFICATION_TYPE.SMS
      );
      let emailContent = notification.emailDescription;
      let smsContent = notification.smsDescription;
      if (!currentTypesIncludesSMS && newTypesIncludesSMS) {
        smsContent = htmlToPlainText(emailContent);
      }
      if (currentTypesIncludesSMS && !newTypesIncludesSMS) {
        emailContent = plainTextToHtml(smsContent);
      }
      setNotification({
        ...notification,
        [event.target.name]: event.target.value,
        emailDescription: emailContent,
        smsDescription: smsContent
      });
      setValue(event.target.name, event.target.value);
      trigger(event.target.name);
      return;
    }
    setNotification({
      ...notification,
      [event.target.name]: event.target.value
    });
    // @ts-ignore
    setValue(event.target.name, event.target.value);
    // @ts-ignore
    trigger(event.target.name);
  };

  if (!open || !data || !notification) return null;

  const handleOpenSchedule = () => {
    if (!selectedStudentIds.length && !__isRPPage) {
      toast.error('Please filter and select student');
      return;
    }
    setOpenScheduleModal(true);
  };

  const handleAbortSchedule = async () => {
    try {
      setLoading(true);
      await updateNotification(data._id, {
        status: NOTIFICATION_STATUS.CANCELED
      });

      toast.success('Abort schedule successfully');
      onSuccess();
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Abort schedule failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="bulkManagementNotificationEditContainer">
      <div
        className={`overlay ${open ? 'active' : ' '}`}
        onClick={onClose}
      ></div>
      <div
        className={`bulkManagementNotificationEdit ${open ? 'active' : ' '}`}
      >
        <div className="bulkManagementNotificationEdit__header">
          <div onClick={onClose}>
            <HiArrowLeft size={24} />
          </div>
          <p>NOtification History detail</p>
        </div>
        {openScheduleModal && (
          <ScheduleFormModal
            open={openScheduleModal}
            onClose={() => setOpenScheduleModal(false)}
            onSave={handleUpdateNotification}
            scheduleDateTime={scheduleDateTime}
            onChangeScheduleDateTime={handleChangeScheduleTime}
          />
        )}
        <PermissionWrapper permission={PERMISSION.LIST_STUDENT}>
          <StudentFilter
            showFilter={showFilter}
            onClose={handleCloseFilter}
            onFilter={handleFilter}
          />
        </PermissionWrapper>
        <div className="bulkManagementNotificationEdit__content">
          <AppCard>
            <div className="bulkManagementNotificationEdit__info">
              <AppCardContent>
                <AppSelect
                  label="Type*"
                  options={NOTIFICATION_TYPE_OPTIONS}
                  {...register('types')}
                  message={{
                    type: 'error',
                    text: errors?.types?.message || ''
                  }}
                  name="types"
                  value={notification.types}
                  onChange={handleChange}
                  searchable={false}
                  disabled={!__isCanEdit}
                />
                {/*
                <AppSelect
                  label="Template*"
                  options={__templateOptions}
                  name="template"
                  value={notification.template}
                  onChange={handleChange}
                />
                 */}
              </AppCardContent>
            </div>
          </AppCard>

          <AppCard>
            <div className="bulkManagementNotificationEdit__description">
              <AppCardContent>
                <AppInput
                  label="Title*"
                  {...register('title')}
                  message={{
                    type: 'error',
                    text: errors?.title?.message || ''
                  }}
                  value={notification.title}
                  onChange={handleChange}
                  disabled={!__isCanEdit}
                />
                {notification.types
                  .split(',')
                  .includes(NOTIFICATION_TYPE.SMS) ? (
                  <AppTextArea
                    label="Content*"
                    {...register('smsDescription')}
                    message={{
                      type: 'error',
                      text: errors?.smsDescription?.message || ''
                    }}
                    name="smsDescription"
                    value={notification.smsDescription}
                    onChange={handleChange}
                    disabled={!__isCanEdit}
                  />
                ) : (
                  <div className="bulkManagementNotificationEdit__description--email">
                    <p className="bulkManagementNotificationEdit__description--email--label">
                      Content*
                    </p>
                    <AppCKEditor
                      value={notification.emailDescription}
                      disabled={!__isCanEdit}
                      onChange={(value: string) => {
                        setNotification({
                          ...notification,
                          emailDescription: value
                        });
                        setValue('emailDescription', value);
                        trigger('emailDescription');
                      }}
                    />
                    <p className="bulkManagementNotificationEdit__description--email--error">
                      {errors?.emailDescription?.message}
                    </p>
                  </div>
                )}
              </AppCardContent>
            </div>
          </AppCard>

          {!__isStudentPage && !__isRPPage && (
            <AppCard>
              <div className="bulkManagementNotificationEdit__table">
                <div className="bulkManagementNotificationEdit__table__header">
                  <h2 className="bulkManagementNotificationEdit__table__header--title">
                    SEND TO {data.studentIds.length} STUDENTS
                  </h2>
                  <div className="bulkManagementNotificationEdit__table__header--actions">
                    <AppInputSearch
                      type="text"
                      value={search}
                      onChange={handleChangeSearch}
                      placeholder="Surname, name"
                      onClearSearch={handleClearSearch}
                      startIcon={<HiMagnifyingGlass />}
                    />
                    <AppButton
                      variant="secondary"
                      buttonSize="small"
                      onClick={handleOpenFilter}
                    >
                      <GoFilter size={22} />
                      Filters{' '}
                      {filterFieldNumber > 0 && (
                        <div className="filter-field-number">
                          {filterFieldNumber}
                        </div>
                      )}
                    </AppButton>
                  </div>
                </div>
                <div className="bulkManagementNotificationEdit__table__content">
                  <AppTable
                    data={studentsNotification}
                    columns={columns}
                    loading={loadingStudents}
                  />
                </div>
              </div>
            </AppCard>
          )}
        </div>

        <div className="bulkManagementNotificationEdit__actions">
          {__isCanEdit && (
            <AppButton
              variant="primary"
              buttonSize="large"
              isLoading={loading}
              onClick={handleSubmit(handleOpenSchedule)}
            >
              Edit Schedule
            </AppButton>
          )}
          {__isCanEdit && (
            <AppButton
              className="abortButton"
              variant="primary"
              buttonSize="large"
              isLoading={loading}
              onClick={handleAbortSchedule}
            >
              Abort Schedule
            </AppButton>
          )}
          <AppButton variant="secondary" buttonSize="large" onClick={onClose}>
            Cancel
          </AppButton>
        </div>
      </div>
    </section>
  );
};

export default NotificationEdit;
