import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  ReactNode,
  useMemo,
  Suspense,
  lazy,
  memo
} from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useDebounce from 'common/hooks/useDebounce';
import { useToast } from 'context/ToastContext';
import {
  HiOutlineIdentification,
  HiOutlineChartSquareBar,
  HiOutlineUserCircle,
  HiOutlineCurrencyDollar,
  HiOutlineTrash
} from 'react-icons/hi';
import { GoPlus, GoCalendar } from 'react-icons/go';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import AppTable from 'common/components/AppTable';
import AppInputSearch from 'common/components/AppInputSearch';
import AppButton from 'common/components/AppButton';
import AppCard from 'common/components/AppCard';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppSummaryCard from 'common/components/AppSummaryCard';
import { formatData } from 'common/helpers/dataFormat.helper';
import { PERMISSION } from 'common/enums/permission.enum';
import PermissionWrapper from 'components/PermissionWrapper';
import { deleteLocation, getLocations } from 'services/location.service';
import {
  ILocation,
  ILocationTableFilter
} from 'common/interfaces/location.interface';
import { useBrandLocation } from 'context/BrandLocationContext';

import './desktop.scss';

const LocationDelete = lazy(() => import('../LocationDelete'));

interface DataSummary {
  title: string;
  label: string;
  icon: ReactNode;
  value: string;
}

export interface RefHandle {
  refetchReset: () => void;
}

interface Props {
  openAdd?: boolean;
  onOpenAdd?: () => void;
  onCloseAdd?: () => void;
}

const mainRoute = '/locations';

const LocationList = forwardRef<RefHandle, Props>(
  ({ openAdd, onOpenAdd, onCloseAdd }, ref) => {
    useImperativeHandle(ref, () => ({
      refetchReset() {
        handleRefresh();
      }
    }));

    const toast = useToast();
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const columnHelper = createColumnHelper<ILocation>();
    const { onRefetchLocation } = useBrandLocation();

    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 400);

    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pageTotal, setPageTotal] = useState(-1);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<ILocation[]>([]);

    const [dataSummary, setDataSummary] = useState<DataSummary[]>([
      {
        title: 'Total clubs',
        label: 'Total clubs',
        icon: <GoCalendar size={22} />,
        value: formatData(null)
      },
      {
        title: 'Future',
        label: 'Future',
        icon: <HiOutlineChartSquareBar size={26} />,
        value: formatData(null)
      },
      {
        title: 'AVG. DAILY VISITS',
        label: 'AVG. DAILY VISITS',
        icon: <HiOutlineUserCircle size={26} />,
        value: formatData(null)
      },
      {
        title: 'AVG. MEMBERS',
        label: 'AVG. MEMBERS',
        icon: <HiOutlineCurrencyDollar size={26} />,
        value: formatData(null)
      }
    ]);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deletedLocationId, setDeletedLocationId] = useState('');

    const [disabled, setDisabled] = useState(false);

    const handleOpenDeleteModal = (locationAreaId: string) => {
      setOpenDeleteModal(true);
      setDeletedLocationId(locationAreaId);
    };

    const handleDeleteLocation = async () => {
      try {
        if (!deletedLocationId) return;

        setDisabled(true);

        await deleteLocation(deletedLocationId);

        toast.success('Delete location  successfully');

        setOpenDeleteModal(false);
        setDeletedLocationId('');

        handleRefresh();
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Delete location  failed'
        );
      } finally {
        setDisabled(false);
      }
    };

    const fetchData = useCallback(async () => {
      try {
        const params: ILocationTableFilter = {
          limit: pageSize,
          page: pageIndex,
          keyword: debouncedSearch
        };

        const { data } = await getLocations(params);

        setPageTotal(data?.data?.total);

        setData(data?.data?.data);

        if (location.pathname === mainRoute) {
          setDataSummary([
            {
              title: 'Total clubs',
              label: 'Total clubs',
              icon: <GoCalendar size={22} />,
              value: formatData(data?.data?.total)
            },
            {
              title: 'Future',
              label: 'Future',
              icon: <HiOutlineChartSquareBar size={26} />,
              value: formatData(null)
            },
            {
              title: 'AVG. DAILY VISITS',
              label: 'AVG. DAILY VISITS',
              icon: <HiOutlineUserCircle size={26} />,
              value: formatData(null)
            },
            {
              title: 'AVG. MEMBERS',
              label: 'AVG. MEMBERS',
              icon: <HiOutlineCurrencyDollar size={26} />,
              value: formatData(null)
            }
          ]);
        }
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Fetch Student list failed'
        );
      }

      setLoading(false);

      // eslint-disable-next-line
    }, [pageSize, pageIndex, debouncedSearch, id, location.pathname]);

    const handleRefresh = useCallback(() => {
      setPageIndex(1);
      setSearch('');

      onRefetchLocation();

      fetchData();
    }, [fetchData, onRefetchLocation]);

    useEffect(() => {
      if (openAdd) handleRefresh();
    }, [openAdd, handleRefresh]);

    const columnsNorm = useMemo(() => {
      return [
        columnHelper.accessor('shortName', {
          header: () => <span>Location</span>,
          cell: (info) =>
            formatData(info.getValue() || info?.row?.original?.name)
        }),
        columnHelper.accessor('name', {
          header: () => <span>Hour</span>,
          cell: (info) => formatData(null)
        }),
        columnHelper.accessor('managementStaff', {
          header: () => <span>CLUB MANAGER</span>,
          cell: (info) => formatData(info?.getValue()?.facilityManager)
        }),
        columnHelper.accessor('updatedAt', {
          header: () => <span>AREA MANAGER</span>,
          cell: (info) =>
            formatData(info?.row?.original?.managementStaff?.areaManager)
        }),
        columnHelper.accessor('_id', {
          header: () => (
            <div style={{ cursor: 'pointer', textAlign: 'center' }}>ACTION</div>
          ),
          cell: (info) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '20px'
                }}
              >
                <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_LOCATION}>
                  <div
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate(`/locations/${info.getValue()}`)}
                  >
                    <HiOutlineIdentification fontSize={20} />
                  </div>
                </PermissionWrapper>

                <PermissionWrapper permission={PERMISSION.DELETE_LOCATION}>
                  <div
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() => handleOpenDeleteModal(info?.getValue())}
                  >
                    <HiOutlineTrash fontSize={20} />
                  </div>
                </PermissionWrapper>
              </div>
            );
          },
          size: 50
        })
      ];
    }, [columnHelper, navigate]);

    const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPageIndex(1);
      setSearch(event.target.value);
    };

    const onClearSearch = () => {
      setSearch('');
    };

    const defineColumn = useMemo(() => {
      if (location.pathname === mainRoute) return columnsNorm;

      return columnsNorm;
    }, [columnsNorm, location.pathname]);

    useEffect(() => {
      fetchData();
    }, [fetchData]);

    const TablePure = () => {
      return (
        <>
          <div className="location">
            <PermissionWrapper permission={PERMISSION.DELETE_LOCATION_AREA}>
              <Suspense>
                <LocationDelete
                  open={openDeleteModal}
                  onClose={() => setOpenDeleteModal(false)}
                  onConfirm={disabled ? () => {} : handleDeleteLocation}
                />
              </Suspense>
            </PermissionWrapper>

            <AppCard>
              <div className="location__table">
                <div className="location__table-header">
                  <h2 className="location__table-header-title">Location(s)</h2>
                  <div className="location__table-header-search">
                    <AppInputSearch
                      type="text"
                      value={search}
                      onChange={onChangeSearch}
                      placeholder="Location name"
                      onClearSearch={onClearSearch}
                      startIcon={<HiMagnifyingGlass />}
                    />

                    <PermissionWrapper permission={PERMISSION.CREATE_LOCATION}>
                      <AppButton
                        variant="primary"
                        buttonSize="small"
                        type="submit"
                        onClick={() => (onOpenAdd ? onOpenAdd() : null)}
                      >
                        <div className="plusIcon">
                          <GoPlus style={{ width: '22px', height: '22px' }} />
                        </div>
                        <p>Location</p>
                      </AppButton>
                    </PermissionWrapper>
                  </div>
                </div>

                <PermissionWrapper permission={PERMISSION.LIST_LOCATION}>
                  <div className="location__table-content">
                    <AppTable
                      loading={loading}
                      data={data}
                      columns={defineColumn}
                      pagination={{
                        index: pageIndex,
                        size: pageSize,
                        total: pageTotal
                      }}
                      onChangePage={(index: number, size: number) => {
                        setPageIndex(index);
                        setPageSize(size);
                      }}
                    />
                  </div>
                </PermissionWrapper>
              </div>
            </AppCard>
          </div>
        </>
      );
    };

    if (location.pathname === mainRoute) {
      return (
        <>
          <AppBreadCrumb items={[{ name: 'Locations', href: '/locations' }]} />
          <div className="layoutContainer">
            <div className="location-summary-card">
              {dataSummary.map((item, idx) => {
                return (
                  <AppSummaryCard
                    key={idx}
                    title={item.title}
                    label={item.label}
                    value={item.value}
                    icon={item.icon}
                  />
                );
              })}
            </div>
            {TablePure()}
          </div>
        </>
      );
    }

    return <>{TablePure()}</>;
  }
);

export default memo(LocationList);
