import { DISCOUNT_TYPE } from 'common/enums';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import {
  AUTOMATION_DISCOUNT_TYPE,
  DISCOUNT_FROM
} from 'common/enums/voucher.enum';
import { getLessonsDayInRange } from 'common/helpers/classListBooking.helper';
import { roundByTwo } from 'common/helpers/dataFormat.helper';
import {
  CustomStudentEnrollment,
  DiscountApplied
} from 'common/interfaces/bookingClass.interface';
import { IBookingData } from 'common/interfaces/schedules.interface';
import { ITerm } from 'common/interfaces/term.interface';
import dayjs from 'dayjs';

export const handleGetPayNow = (
  bookingItem: IBookingData,
  stopBilling: ITerm[] = [],
  nextDebitDay: string
) => {
  const {
    pricePayNow = 0,
    voucher,
    automaticDiscount,
    paymentOption
  } = bookingItem;
  if (paymentOption === PAYMENT_VALUE.IDLE) return 0;
  const firstLesson = bookingItem.schedules[0];
  if (voucher) {
    if (voucher?.discountType === DISCOUNT_TYPE.AMOUNT) {
      const result =
        voucher?.discountValue > pricePayNow
          ? 0
          : roundByTwo(pricePayNow - voucher?.discountValue);
      return result;
    }
    if (voucher?.discountType === DISCOUNT_TYPE.PERCENTAGE) {
      const discountedPrice = roundByTwo(
        pricePayNow * (voucher?.discountValue / 100)
      );
      return roundByTwo(pricePayNow - discountedPrice);
    }
    if (voucher?.discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
      let numberOfLessonMustBePaid = [];
      let numberOfLessonFree = [];
      if (!!voucher.numberOfLesson) {
        const amount = roundByTwo(
          pricePayNow - voucher.numberOfLesson * bookingItem.classInfo.price
        );
        return amount > 0 ? amount : 0;
      } else if (!!voucher.startDateFree) {
        // case no end date
        if (!voucher.endDateFree) {
          // start Date Free is before first lesson => no need to pay
          if (
            dayjs(voucher.startDateFree).isBefore(dayjs(firstLesson.startTime))
          ) {
            return 0;
          }
          // start Date Free is after first lesson => need to pay
          else {
            // for PIF
            if (bookingItem.paymentOption === PAYMENT_VALUE.UPFRONT) {
              numberOfLessonMustBePaid = getLessonsDayInRange(
                bookingItem.schedules,
                firstLesson.startTime,
                dayjs(voucher.startDateFree).subtract(1, 'days').format(),
                stopBilling
              );
              return (
                numberOfLessonMustBePaid.length * bookingItem.classInfo.price
              );
            }
            // for DD
            else {
              numberOfLessonMustBePaid = getLessonsDayInRange(
                bookingItem.schedules,
                firstLesson.startTime,
                nextDebitDay,
                stopBilling
              );
              numberOfLessonFree = getLessonsDayInRange(
                bookingItem.schedules,
                voucher.startDateFree,
                nextDebitDay,
                stopBilling
              );
              return roundByTwo(
                (numberOfLessonMustBePaid.length - numberOfLessonFree.length) *
                  bookingItem.classInfo.price
              );
            }
          }
        }
        // case have end date
        else {
          if (bookingItem.paymentOption === PAYMENT_VALUE.UPFRONT) {
            const endDateOfTerm = bookingItem.classInfo.template.terms.find(
              (term) => term.termDetail._id === firstLesson.termId
            )?.termDetail.endDate;
            let endDate = dayjs(voucher.endDateFree).isAfter(endDateOfTerm)
              ? endDateOfTerm
              : voucher.endDateFree;
            numberOfLessonFree = getLessonsDayInRange(
              bookingItem.schedules,
              voucher.startDateFree,
              endDate || voucher.endDateFree,
              stopBilling
            );
          } else if (bookingItem.paymentOption === PAYMENT_VALUE.DIRECT_DEBIT) {
            numberOfLessonFree = getLessonsDayInRange(
              bookingItem.schedules,
              voucher.startDateFree,
              nextDebitDay,
              stopBilling
            );
          }
          const amount = roundByTwo(
            pricePayNow -
              numberOfLessonFree.length * bookingItem.classInfo.price
          );
          return amount > 0 ? amount : 0;
        }
      }
    }
  } else if (automaticDiscount) {
    const discountedPrice = roundByTwo(
      pricePayNow * (automaticDiscount?.value / 100)
    );
    return roundByTwo(pricePayNow - discountedPrice);
  }
  return pricePayNow;
};
export const handleGetPayNowForMoveBooking = (
  bookingItem: IBookingData,
  discountData?: DiscountApplied
) => {
  const { pricePayNow = 0 } = bookingItem;
  if (discountData) {
    if (discountData.discountFrom === DISCOUNT_FROM.VOUCHER) {
      if (discountData?.discountType === DISCOUNT_TYPE.AMOUNT) {
        const result =
          discountData?.remainDiscountAmount > pricePayNow
            ? 0
            : roundByTwo(pricePayNow - discountData?.remainDiscountAmount);
        return result;
      }
      if (discountData?.discountType === DISCOUNT_TYPE.PERCENTAGE) {
        const discountedPrice = roundByTwo(
          pricePayNow * (discountData?.discountValue / 100)
        );
        return roundByTwo(pricePayNow - discountedPrice);
      }
    } else if (
      discountData.discountFrom === DISCOUNT_FROM.AUTOMATION_DISCOUNT
    ) {
      if (
        discountData.automationDiscountType ===
          AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT &&
        bookingItem.paymentOption === PAYMENT_VALUE.UPFRONT
      ) {
        return pricePayNow;
      }
      const discountedPrice = roundByTwo(
        pricePayNow * (discountData?.discountValue / 100)
      );
      return roundByTwo(pricePayNow - discountedPrice);
    }
  }
  return pricePayNow;
};
export const handleGetPayNowForBookingClassList = (
  bookingItem: CustomStudentEnrollment,
  stopBilling: ITerm[] = [],
  nextDebitDay: string
) => {
  const { voucher, automaticDiscount, form } = bookingItem;
  const { payNow: pricePayNow = 0, paymentType: paymentOption } = form;
  if (voucher) {
    if (voucher?.discountType === DISCOUNT_TYPE.AMOUNT) {
      const result =
        voucher?.discountValue > pricePayNow
          ? 0
          : roundByTwo(pricePayNow - voucher?.discountValue);
      return result;
    }
    if (voucher?.discountType === DISCOUNT_TYPE.PERCENTAGE) {
      const discountedPrice = roundByTwo(
        pricePayNow * (voucher?.discountValue / 100)
      );
      return roundByTwo(pricePayNow - discountedPrice);
    }
    if (voucher?.discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
      const firstLesson = bookingItem.form.schedulesBooked[0];
      const lastLesson =
        bookingItem.form.schedulesBooked[
          bookingItem.form.schedulesBooked.length - 1
        ];
      let numberOfLessonMustBePaid = [];
      let numberOfLessonFree: string[] = [];
      if (!!voucher.numberOfLesson) {
        const amount = roundByTwo(
          pricePayNow - voucher.numberOfLesson * bookingItem.info.price
        );
        return amount > 0 ? amount : 0;
      } else if (!!voucher.startDateFree) {
        // case no end date
        if (!voucher.endDateFree) {
          // start Date Free is before first lesson => no need to pay
          if (
            dayjs(voucher.startDateFree).isBefore(dayjs(firstLesson.startTime))
          ) {
            return 0;
          }
          // start Date Free is after first lesson => need to pay
          else {
            // for PIF
            if (paymentOption === PAYMENT_VALUE.UPFRONT) {
              numberOfLessonMustBePaid = getLessonsDayInRange(
                form.schedulesBooked,
                firstLesson.startTime,
                dayjs(voucher.startDateFree).subtract(1, 'days').format(),
                stopBilling
              );
              return numberOfLessonMustBePaid.length * bookingItem.info.price;
            }
            // for DD
            else {
              numberOfLessonMustBePaid = getLessonsDayInRange(
                form.schedulesBooked,
                firstLesson.startTime,
                nextDebitDay,
                stopBilling
              );
              numberOfLessonFree = getLessonsDayInRange(
                form.schedulesBooked,
                voucher.startDateFree,
                nextDebitDay,
                stopBilling
              );
              return roundByTwo(
                (numberOfLessonMustBePaid.length - numberOfLessonFree.length) *
                  bookingItem.info.price
              );
            }
          }
        }
        // case have end date
        else {
          if (paymentOption === PAYMENT_VALUE.UPFRONT) {
            if (
              dayjs(voucher.endDateFree).isBefore(dayjs(firstLesson.startTime))
            ) {
              numberOfLessonFree = [];
            } else if (
              bookingItem.info.type === CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM &&
              dayjs(voucher.startDateFree).isAfter(dayjs(lastLesson.startTime))
            ) {
            } else {
              const endDateOfTerm = bookingItem.info.template.terms.find(
                (term) => term.termDetail._id === firstLesson.termId
              )?.termDetail.endDate;
              let endDate = dayjs(voucher.endDateFree).isAfter(endDateOfTerm)
                ? endDateOfTerm
                : voucher.endDateFree;
              numberOfLessonFree = getLessonsDayInRange(
                form.schedulesBooked,
                voucher.startDateFree,
                endDate || voucher.endDateFree,
                stopBilling
              );
            }
          } else if (paymentOption === PAYMENT_VALUE.DIRECT_DEBIT) {
            numberOfLessonFree = getLessonsDayInRange(
              form.schedulesBooked,
              voucher.startDateFree,
              nextDebitDay,
              stopBilling
            );
          }
          const amount = roundByTwo(
            pricePayNow - numberOfLessonFree.length * bookingItem.info.price
          );
          return amount > 0 ? amount : 0;
        }
      }
    }
  } else if (automaticDiscount) {
    const discountedPrice = roundByTwo(
      pricePayNow * (automaticDiscount?.value / 100)
    );
    return roundByTwo(pricePayNow - discountedPrice);
  }
  return pricePayNow;
};
