import './desktop.scss';

import {
  ClassFilterValuePayload,
  FilterValue,
  IClass,
  IClassSearchAdvance
} from 'common/interfaces/class.interface';
import { useCallback, useEffect, useRef, useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import AppCard from 'common/components/AppCard';
import AppCheckbox from 'common/components/AppCheckbox';
import AppSearchCalendar from 'common/components/AppSearchCalendar';
import AppTable from 'common/components/AppTable';
import { CHANGE_TYPE } from 'common/enums';
import { PERMISSION } from 'common/enums/permission.enum';
import { getClassType } from 'common/helpers/class.helper';
import { formatData, formatTime } from 'common/helpers/dataFormat.helper';
import {
  convertRecurrenceToString,
  convertToUnixTime,
  getCurrentUserTimeZone
} from 'common/helpers/time.helper';
import useDebounce from 'common/hooks/useDebounce';
import { IColorSetting } from 'common/interfaces/dashboard.interface';
import PermissionWrapper from 'components/PermissionWrapper';

import ColorLevelQuickFilter from 'components/colorLevelFilter/ColorLevelQuickFilter';
import { useAuth } from 'context/AuthContext';
import { useBrandLocation } from 'context/BrandLocationContext';
import { useLayout } from 'context/LayoutContext';
import { useToast } from 'context/ToastContext';

import dayjs from 'dayjs';
import { FilterClasses as FilterClassesType } from 'pages/classes/types';
import { GoFilter } from 'react-icons/go';
import { HiOutlineAcademicCap } from 'react-icons/hi';
import {
  changeClassArea,
  changeClassCapacity,
  changeClassInstructor,
  changeClassPrice,
  getClassList
} from 'services/classes.service';
import ActionPopper from './ActionPopper';
import ChangeAreaModal from './components/ChangeAreaModal';
import ChangeInstructorModal from './components/ChangeInstructorModal';
import UpdateSuccessfullyModal from './components/UpdateSuccessfullyModal';
import {
  FormAreaValue,
  FormCapacityValue,
  FormInstructorValue,
  FormPriceValue
} from './type';
import ChangeCapacityModal from './components/ChangeCapacityModal';
import ChangePriceModal from './components/ChangePriceModal';
import {
  ChangeClassesAreaDTO,
  ChangeClassesCapacityDTO,
  ChangeClassesInstructorDTO,
  ChangeClassesPriceDTO
} from 'DTOs/class.dto';
import FilterClasses from 'components/FilterClasses';
import AppToggle from 'common/components/AppToggle';
import {
  getLevelBreakdowns,
  updateLevelSetting
} from 'services/levelBreakdown.service';
import { LevelSetting } from 'common/interfaces/levelBreakdown.interface';
import { uniq } from 'lodash';
import { Tooltip } from 'react-tooltip';

const initialSearchAdvanceValue = {
  what: '',
  who: '',
  fromDate: '',
  toDate: ''
};

const initialFilterClasses = {
  classType: '',
  level: '',
  instructor: '',
  area: '',
  dayOfWeek: '',
  duration: '',
  vacancy: 1
};

const BulkManagementClassList = () => {
  const { selectedLocation: globalLocation } = useBrandLocation();
  const toast = useToast();
  const { hasPermission, user } = useAuth();
  const { handleMouseLeaveMenu } = useLayout();

  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageTotal, setPageTotal] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(true);
  const [classes, setClasses] = useState<IClass[]>([]);
  const [searchAdvanceValue, setSearchAdvanceValue] =
    useState<IClassSearchAdvance>(initialSearchAdvanceValue);
  const [searchValue, setSearchValue] = useState<string>('');
  const [levelSelected, setLevelSelected] = useState<string>('');
  const [searchByValue, setSearchByValue] = useState<boolean>(true);

  const debouncedSearch = useDebounce<string>(searchValue);
  const filterValueRef = useRef<FilterValue>(initialFilterClasses);

  // bulk update class
  const [changeType, setChangeType] = useState<CHANGE_TYPE>(
    CHANGE_TYPE.PERMANENT
  );
  const [showSuccessfullyModal, setShowSuccessfullyModal] =
    useState<boolean>(false);
  const [showChangeAreaModal, setShowChangeAreaModal] =
    useState<boolean>(false);
  const [showChangeInstructorModal, setShowChangeInstructorModal] =
    useState<boolean>(false);
  const [showChangeCapacityModal, setShowChangeCapacityModal] =
    useState<boolean>(false);
  const [showChangePriceModal, setShowChangePriceModal] =
    useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [totalRecord, setTotalRecord] = useState<number>(-1);

  const [vacancy, setVacancy] = useState<number>(1);
  const [applyVacancy, setApplyVacancy] = useState<boolean>(false);
  const [canBeBookMakeUp, setCanBeBookMakeUp] = useState<boolean>(false);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [unselectedClassIds, setUnselectedClassIds] = useState<Array<string>>(
    []
  );

  const debouncedVacancy = useDebounce<number>(vacancy, 500);
  useEffect(() => {
    handleFilter({ ...initialFilterClasses });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalLocation?._id]);

  useEffect(() => {
    filterValueRef.current.vacancy = vacancy;
    if (applyVacancy) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedVacancy]);

  const handleToggleFilterVacancy = useCallback(() => {
    setApplyVacancy(!applyVacancy);
  }, [applyVacancy]);

  const handleToggleFilterMakeupVacancy = () => {
    setCanBeBookMakeUp(!canBeBookMakeUp);
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!globalLocation?._id) return;
    const params: FilterClassesType = {
      limit: pageSize,
      page: pageIndex,
      locationId: globalLocation?._id || ''
    };
    let numberFilterField = 0;
    if (filterValueRef.current) {
      if (filterValueRef.current.classType) {
        params.classTypes = filterValueRef.current.classType;
        numberFilterField++;
      }

      if (filterValueRef.current.level) {
        params.levels = filterValueRef.current.level;
        numberFilterField++;
      }

      if (filterValueRef.current.instructor) {
        params.instructors = filterValueRef.current.instructor;
        numberFilterField++;
      }

      if (filterValueRef.current.area) {
        params.areas = filterValueRef.current.area;
        numberFilterField++;
      }

      if (filterValueRef.current.dayOfWeek) {
        params.dayOfWeeks = filterValueRef.current.dayOfWeek;
        numberFilterField++;
      }

      if (filterValueRef.current.duration) {
        params.durations = filterValueRef.current.duration;
        numberFilterField++;
      }

      if (filterValueRef.current.applyVacancy) {
        params.vacancy = filterValueRef.current.vacancy;
      }
      if (canBeBookMakeUp) {
        params.makeupVacancy = canBeBookMakeUp;
      }
    }
    setFilterFieldNumber(numberFilterField);
    if (applyVacancy) {
      params.vacancy = filterValueRef.current.vacancy;
    }
    if (searchByValue) {
      params.keyword = debouncedSearch;
    } else {
      if (searchAdvanceValue.who) {
        params.instructorName = searchAdvanceValue.who;
      }

      if (searchAdvanceValue.what) {
        params.keyword = searchAdvanceValue.what;
      }

      if (searchAdvanceValue.fromDate) {
        params.fromDate = convertToUnixTime(searchAdvanceValue.fromDate);
      }

      if (searchAdvanceValue.toDate) {
        params.toDate = convertToUnixTime(searchAdvanceValue.toDate);
      }
    }

    try {
      const { data, total } = await getClassList(params);
      setPageTotal(total);
      setClasses(data);
      setSelectedClassIds([]);
      if (isSelectAll) {
        setSelectedClassIds(
          uniq([
            ...data.map((item: IClass) => item._id),
            ...selectedClassIds
          ]).filter((item) => !unselectedClassIds.includes(item))
        );
      } else {
        setSelectedClassIds([]);
        setUnselectedClassIds([]);
        setTotalRecord(total);
      }
    } catch (error) {
      setClasses([]);
      toast.error('Failed to get class list');
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [
    pageSize,
    pageIndex,
    debouncedSearch,
    filterValueRef.current,
    searchAdvanceValue,
    searchByValue,
    globalLocation?._id,
    applyVacancy,
    canBeBookMakeUp
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const columnHelper = createColumnHelper<IClass>();

  const [showFilter, setShowFilter] = useState(false);
  const [colorSettings, setColorSettings] = useState<IColorSetting[]>([]);
  const [selectedClassIds, setSelectedClassIds] = useState<string[]>([]);
  const [filterFieldNumber, setFilterFieldNumber] = useState<number>(0);

  const getColorSettingData = useCallback(async () => {
    try {
      const { data } = await getLevelBreakdowns(
        1,
        100,
        globalLocation?._id || ''
      );

      setColorSettings(
        data.data.data.map((item: IColorSetting) => {
          return {
            ...item,
            colorCode: item.userColorSetting?.colorCode || item.colorCode
          };
        })
      );
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to load list level!'
      );
    }
    // eslint-disable-next-line
  }, [globalLocation?._id]);

  useEffect(() => {
    getColorSettingData();
  }, [getColorSettingData]);

  const onSortColorSetting = useCallback(
    async (newColorSettings: IColorSetting[]) => {
      if (!user?._id || !newColorSettings.length) return;
      setColorSettings(newColorSettings);
      try {
        const payload: LevelSetting = {
          levelOrders: newColorSettings.map((item, index) => {
            return { levelId: item._id, order: index };
          })
        };
        await updateLevelSetting(user?._id, payload);
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Cannot sort color setting'
        );
      }
    },
    // eslint-disable-next-line
    [user?._id]
  );
  const onClickAllLevel = () => {
    const newSetting = colorSettings.map((item) => {
      item.active = false;
      return item;
    });
    setColorSettings(newSetting);
    handleFilter({ ...filterValueRef.current, level: '' });
  };

  const handleFilter = useCallback(
    (value: FilterValue) => {
      filterValueRef.current = value;
      filterValueRef.current.vacancy = vacancy;
      setPageIndex(1);
      setShowFilter(false);
      setLevelSelected(value.level);

      setSelectedClassIds([]);
      setUnselectedClassIds([]);
      setIsSelectAll(false);
      const newSetting = colorSettings.map((item: IColorSetting) => {
        if (value.level.split(',').includes(item._id)) {
          item.active = true;
        } else {
          item.active = false;
        }
        return item;
      });
      setColorSettings(newSetting);
    },
    // eslint-disable-next-line
    [colorSettings, setPageIndex, debouncedVacancy, globalLocation?._id]
  );

  const onClickLevel = useCallback(
    (levelId: string) => {
      const newSetting = colorSettings.map((item) => {
        if (item._id === levelId) {
          item.active = !item.active;
        }

        return item;
      });

      setColorSettings(newSetting);

      const levelIds = newSetting
        .filter((item) => item.active)
        .map((item) => item._id);
      handleFilter({ ...filterValueRef.current, level: levelIds.join(',') });
    },
    [colorSettings, filterValueRef, handleFilter]
  );

  const handleSelectAll = () => {
    const tempClassIds = classes?.map((item) => item._id) || [];
    if (!unselectedClassIds.length) {
      if (isSelectAll) {
        setSelectedClassIds([]);
        setUnselectedClassIds([]);
        setIsSelectAll(false);
      } else {
        setSelectedClassIds(tempClassIds);
        setUnselectedClassIds([]);
        setIsSelectAll(true);
        setTotalRecord(pageTotal);
      }
    } else {
      setSelectedClassIds(tempClassIds);
      setUnselectedClassIds([]);
      setIsSelectAll(true);
      setTotalRecord(pageTotal);
    }
  };

  const handleSelectClass = (classId: string) => {
    const newSelectedStudentIds = [...selectedClassIds];
    const findIndex = selectedClassIds.findIndex((e) => e === classId);
    if (findIndex === -1) {
      newSelectedStudentIds.push(classId);
      if (unselectedClassIds.length) {
        setUnselectedClassIds(
          unselectedClassIds.filter((id) => id !== classId)
        );
      }
    } else {
      newSelectedStudentIds.splice(findIndex, 1);
      if (isSelectAll) {
        setUnselectedClassIds([...unselectedClassIds, classId]);
      }
    }
    setSelectedClassIds(newSelectedStudentIds);
  };

  const columns = [
    columnHelper.accessor('_id', {
      header: () => (
        <AppCheckbox
          onChange={() => handleSelectAll()}
          checked={isSelectAll && !unselectedClassIds.length}
          disabled={!classes?.length}
        />
      ),
      cell: (info) => {
        return (
          <AppCheckbox
            onChange={() => handleSelectClass(info.getValue())}
            checked={selectedClassIds.includes(info.getValue())}
          />
        );
      },
      size: 50
    }),
    columnHelper.accessor('session', {
      header: () => <span>Date, Time</span>,
      cell: (info) => {
        const item = info.row.original;
        if (!item?.session) return formatData(null);
        return (
          <div data-tooltip-id={`content-tooltip-${item._id}`}>
            <div className="content-cell">
              <span className="line-clamp-1">{`${dayjs(
                item.session?.startTime || item.startTime
              ).format('ddd, MMM D, HH:mm')} - ${formatTime(
                item?.session?.endTime || item.endTime
              )}`}</span>
            </div>
            <Tooltip
              id={`content-tooltip-${item._id}`}
              opacity={1}
              className="tooltip-detail"
              style={{ backgroundColor: '#034EA2' }}
              render={() => <div>{convertRecurrenceToString(item)}</div>}
            />
          </div>
        );
      },
      size: 200
    }),
    columnHelper.accessor('template.name', {
      header: () => <span>CLASS</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('levelBreakdown', {
      header: () => <span>Level</span>,
      cell: (info) =>
        formatData(info.row.original?.levelBreakdown?.name || 'All Levels')
    }),
    columnHelper.accessor('type', {
      header: () => <span>PROGRAM TYPE</span>,
      cell: (info) => formatData(getClassType(info.getValue()))
    }),
    columnHelper.accessor('instructor.firstName', {
      header: () => <span>INSTRUCTOR</span>,
      cell: (info) =>
        formatData(
          info.row.original.session?.instructor?.firstName || info.getValue()
        )
    }),
    columnHelper.accessor('area', {
      header: () => <span>AREA</span>,
      cell: (info) =>
        formatData(info.row.original.session?.area || info.getValue())
    }),
    columnHelper.accessor('session.capacity', {
      header: () => <span>CAPACITY</span>,
      cell: (info) => {
        const session = info.row.original.session;
        const capacity = info.getValue() || 0;
        const occupied = session?.occupied || 0;
        const available = capacity - occupied;
        return (
          <div style={{ cursor: 'pointer' }} className="content-cell">
            <HiOutlineAcademicCap fontSize={20} />
            {!session ? (
              `--/--`
            ) : (
              <span style={{ whiteSpace: 'nowrap', marginLeft: '4px' }}>
                {formatData(occupied)}/{formatData(capacity)}{' '}
                {available >= 0 ? `(${available} left)` : '(overbooked)'}
              </span>
            )}
          </div>
        );
      }
    })
  ];

  const handleSearch = (
    value: string | IClassSearchAdvance,
    advance?: boolean
  ) => {
    if (advance && typeof value === 'object') {
      setSearchAdvanceValue(value);
      setSearchByValue(false);
    } else if (typeof value === 'string') {
      setSearchValue(value);
      setSearchByValue(true);
    }
    setPageIndex(1);
  };

  // start change area
  const openChangeAreaModal = (value: CHANGE_TYPE) => {
    if (selectedClassIds.length === 0) {
      toast.error('Please select classes');
      return;
    }
    setShowChangeAreaModal(true);
    setChangeType(value);
  };

  const getFilterValuePayload = () => {
    const filter: ClassFilterValuePayload = {};
    if (isSelectAll) {
      if (
        filterValueRef.current &&
        Object.keys(filterValueRef.current).length > 1
      ) {
        const { level, instructor, area } = filterValueRef.current;
        if (level) {
          filter.levels = level.split(',');
        }
        if (instructor) {
          filter.instructors = instructor.split(',');
        }
        if (area) {
          filter.areas = area.split(',');
        }
      }
      if (searchByValue) {
        filter.keyword = searchValue;
      } else {
        if (searchAdvanceValue.who) {
          filter.instructorName = searchAdvanceValue.who;
        }

        if (searchAdvanceValue.what) {
          filter.keyword = searchAdvanceValue.what;
        }

        if (searchAdvanceValue.fromDate) {
          filter.fromDate = convertToUnixTime(searchAdvanceValue.fromDate);
        }

        if (searchAdvanceValue.toDate) {
          filter.toDate = convertToUnixTime(searchAdvanceValue.toDate);
        }
      }
    }
    return filter;
  };

  const handleChangeArea = async (value: FormAreaValue) => {
    setLoadingModal(true);
    try {
      const payload: ChangeClassesAreaDTO = {
        classIds: selectedClassIds,
        area: value.area,
        fromDate: convertToUnixTime(value.date),
        timezone: getCurrentUserTimeZone()
      };
      if (changeType === CHANGE_TYPE.TEMPORARY && value.endDate) {
        payload.toDate = convertToUnixTime(value.endDate);
      }
      if (selectedClassIds.length < pageTotal && isSelectAll) {
        payload.filter = getFilterValuePayload();
      }
      payload.notIncludeIds = unselectedClassIds;
      await changeClassArea(payload);
      setShowChangeAreaModal(false);
      setShowSuccessfullyModal(true);
      setIsSelectAll(false);
      setSelectedClassIds([]);
      setUnselectedClassIds([]);
      fetchData();
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Failed to change area!');
    } finally {
      setLoadingModal(false);
    }
  };
  // end change area

  // start change Instructor
  const openChangeInstructorModal = (value: CHANGE_TYPE) => {
    if (selectedClassIds.length === 0) {
      toast.error('Please select classes');
      return;
    }
    setShowChangeInstructorModal(true);
    setChangeType(value);
  };

  const handleChangeInstructor = async (value: FormInstructorValue) => {
    setLoadingModal(true);
    try {
      const payload: ChangeClassesInstructorDTO = {
        classIds: selectedClassIds,
        instructorId: value.instructor,
        fromDate: convertToUnixTime(value.date),
        timezone: getCurrentUserTimeZone()
      };
      if (changeType === CHANGE_TYPE.TEMPORARY && value.endDate) {
        payload.toDate = convertToUnixTime(value.endDate);
      }

      if (isSelectAll) {
        payload.filter = getFilterValuePayload();
      }
      payload.notIncludeIds = unselectedClassIds;
      await changeClassInstructor(payload);
      setShowChangeInstructorModal(false);
      setShowSuccessfullyModal(true);
      setIsSelectAll(false);
      setSelectedClassIds([]);
      setUnselectedClassIds([]);
      fetchData();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to change instructor!'
      );
    } finally {
      setLoadingModal(false);
    }
  };
  // end change Instructor

  // start change capacity
  const openChangeCapacityModal = () => {
    if (selectedClassIds.length === 0) {
      toast.error('Please select classes');
      return;
    }
    setShowChangeCapacityModal(true);
  };

  const handleChangeCapacity = async (value: FormCapacityValue) => {
    setLoadingModal(true);
    try {
      const payload: ChangeClassesCapacityDTO = {
        classIds: selectedClassIds,
        capacity: value.capacity
      };

      if (selectedClassIds.length < pageTotal && isSelectAll) {
        payload.filter = getFilterValuePayload();
      }
      payload.notIncludeIds = unselectedClassIds;
      await changeClassCapacity(payload);
      setShowChangeCapacityModal(false);
      setShowSuccessfullyModal(true);
      setIsSelectAll(false);
      setSelectedClassIds([]);
      setUnselectedClassIds([]);
      fetchData();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to change capacity!'
      );
    } finally {
      setLoadingModal(false);
    }
  };
  // end change capacity

  // start change Price
  const openChangePriceModal = () => {
    if (selectedClassIds.length === 0) {
      toast.error('Please select classes');
      return;
    }
    setShowChangePriceModal(true);
  };

  const handleChangePrice = async (value: FormPriceValue) => {
    setLoadingModal(true);
    try {
      const payload: ChangeClassesPriceDTO = {
        classIds: selectedClassIds,
        price: value.price
      };

      if (selectedClassIds.length < pageTotal) {
        payload.filter = getFilterValuePayload();
      }
      payload.notIncludeIds = unselectedClassIds;
      await changeClassPrice(payload);
      setShowChangePriceModal(false);
      setShowSuccessfullyModal(true);
      setIsSelectAll(false);
      setSelectedClassIds([]);
      setUnselectedClassIds([]);
      fetchData();
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Failed to change price!');
    } finally {
      setLoadingModal(false);
    }
  };
  // end change capacity

  return (
    <section className="bulkManagementClassList">
      {/* show successfully modal */}
      {showSuccessfullyModal && (
        <UpdateSuccessfullyModal
          open={showSuccessfullyModal}
          onClose={() => setShowSuccessfullyModal(false)}
        />
      )}
      {/* change area */}
      {showChangeAreaModal && (
        <ChangeAreaModal
          open={showChangeAreaModal}
          onClose={() => setShowChangeAreaModal(false)}
          onSave={handleChangeArea}
          changeType={changeType}
          loading={loadingModal}
        />
      )}
      {/* change instructor */}
      {showChangeInstructorModal && (
        <ChangeInstructorModal
          open={showChangeInstructorModal}
          onClose={() => setShowChangeInstructorModal(false)}
          onSave={handleChangeInstructor}
          changeType={changeType}
          loading={loadingModal}
        />
      )}
      {/* change capacity */}
      {showChangeCapacityModal && (
        <ChangeCapacityModal
          open={showChangeCapacityModal}
          onClose={() => setShowChangeCapacityModal(false)}
          onSave={handleChangeCapacity}
          loading={loadingModal}
        />
      )}
      {/* change Price */}
      {showChangePriceModal && (
        <ChangePriceModal
          open={showChangePriceModal}
          onClose={() => setShowChangePriceModal(false)}
          onSave={handleChangePrice}
          loading={loadingModal}
        />
      )}
      <AppCard>
        <div className="classes-main" onMouseEnter={handleMouseLeaveMenu}>
          <PermissionWrapper permission={PERMISSION.LIST_CLASS}>
            <FilterClasses
              handleFilter={handleFilter}
              goBack={() => setShowFilter(false)}
              showFilter={showFilter}
              levelSelected={levelSelected}
            />
          </PermissionWrapper>

          <div className="classes-table">
            <div className="classes-table_header">
              <p>Classes</p>

              <div className="classes-table_header_right">
                <PermissionWrapper permission={PERMISSION.SEARCH_CLASS}>
                  <div className="classes-input-search">
                    <AppSearchCalendar
                      handleSearch={handleSearch}
                      searchValue={searchValue}
                    />
                  </div>
                </PermissionWrapper>

                <PermissionWrapper permission={PERMISSION.LIST_CLASS}>
                  <div className="classes-btn-filter">
                    <button
                      className="btnActionHeaderClass"
                      onClick={() => {
                        setShowFilter(!showFilter);
                      }}
                    >
                      <GoFilter fontSize={22} />
                      <div className="btnHeaderLabel">
                        Filters
                        {filterFieldNumber > 0 && (
                          <div className="filter-field-number">
                            {filterFieldNumber}
                          </div>
                        )}
                      </div>
                    </button>
                  </div>
                  <div className="classes-btn-bulk-action">
                    <ActionPopper
                      onChangeArea={openChangeAreaModal}
                      onChangeInstructor={openChangeInstructorModal}
                      onChangeCapacity={openChangeCapacityModal}
                      onChangePrice={openChangePriceModal}
                    />
                  </div>
                </PermissionWrapper>
              </div>
            </div>

            <PermissionWrapper permission={PERMISSION.LIST_COLOR_SETTING}>
              <div
                style={{ marginBottom: '10px' }}
                className="color-setting-wrapper"
              >
                <div className="vacancy-input">
                  <AppToggle
                    value={applyVacancy}
                    onChange={handleToggleFilterVacancy}
                  />
                  <span className="text">Min. Vac.</span>
                  <input
                    type="number"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setVacancy(Number(e.target.value))
                    }
                    value={vacancy || 0}
                    min={0}
                    inputMode="numeric"
                  />
                </div>
                <div className="vacancy-input">
                  <AppCheckbox
                    checked={canBeBookMakeUp}
                    onChange={handleToggleFilterMakeupVacancy}
                  />
                  <span>Make-up Vac.</span>
                </div>
                <ColorLevelQuickFilter
                  colorSettings={colorSettings}
                  onClickLevel={onClickLevel}
                  onClickAllLevel={onClickAllLevel}
                  levelIds={filterValueRef.current?.level || ''}
                  onSortColorSetting={onSortColorSetting}
                />
              </div>
            </PermissionWrapper>
            <div className="classes-table-content-selected">
              {selectedClassIds.length === 0
                ? '0'
                : selectedClassIds.length < classes.length && !isSelectAll
                ? selectedClassIds.length
                : pageTotal < 0
                ? 0
                : totalRecord - unselectedClassIds.length}
              {' record'}
              {`${selectedClassIds.length > 1 ? 's are' : ' is'}`} selected
            </div>
            <AppTable
              data={classes}
              columns={columns}
              pagination={{
                index: pageIndex,
                size: pageSize,
                total: pageTotal
              }}
              onChangePage={(index: number, size: number) => {
                setPageIndex(index);
                setPageSize(size);
              }}
              loading={loading}
              columnVisibility={{
                _id: hasPermission(PERMISSION.VIEW_DETAIL_CLASS),
                action: hasPermission(PERMISSION.BOOKING_CLASS)
              }}
            />
          </div>
        </div>
      </AppCard>
    </section>
  );
};

export default BulkManagementClassList;
