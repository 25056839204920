import { memo, useCallback, useMemo, useState } from 'react';
import { SavedFilterComponentProps } from './types';
import { HiOutlineTrash } from 'react-icons/hi';

import 'common/components/AppSelect/desktop.scss';

const SavedFilterComponent = ({
  options,
  handleClick,
  handleDelete
}: SavedFilterComponentProps) => {
  const [searchValue, setSearchValue] = useState('');

  const handleClearSearch = useCallback(() => {
    setSearchValue('');
  }, []);

  const __renderClearSearch = useMemo(() => {
    if (searchValue === '') return null;
    else {
      return (
        <div className="c__select-clear" onClick={handleClearSearch}>
          <img src="/icons/x-circle.svg" alt="close" />
        </div>
      );
    }
  }, [searchValue, handleClearSearch]);

  const filteredOptions = useMemo(() => {
    if (!searchValue) {
      return options;
    }
    const searchLowercase = searchValue.toLowerCase();
    return options.filter((option: any) =>
      option.name.toLowerCase().includes(searchLowercase)
    );
  }, [options, searchValue]);

  return (
    <div
      className="c__select-options-container"
      style={{ maxHeight: '330px', overflowY: 'scroll' }}
    >
      <div className="c__select-search">
        <input
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search..."
        />
        {__renderClearSearch}
      </div>
      <div className="c__select-options">
        {filteredOptions.length ? (
          filteredOptions.map((option, idx) => (
            <div
              key={idx}
              className={`c__select-option`}
              style={{ padding: '10px 12px' }}
            >
              <div
                className="c__select-option-label"
                onClick={() => handleClick(option)}
              >
                {option.name}
              </div>
              <HiOutlineTrash
                fontSize={25}
                onClick={() => handleDelete(option)}
              />
            </div>
          ))
        ) : (
          <div style={{ padding: '10px 12px', textAlign: 'center' }}>
            <div className="c__select-option-label">No value</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(SavedFilterComponent);
