import { useMemo } from 'react';
import './styles.scss';

type AvatarSize = 'medium' | 'large' | 'small';

interface IAppAvatarProps {
  src?: string;
  size?: AvatarSize;
  name?: string;
}

const AppAvatar = (props: IAppAvatarProps) => {
  const { src = '', size = 'medium', name = '' } = props;

  const __renderImage = useMemo(() => {
    if (!src && name) {
      return <p className="c__avatar-name">{name?.charAt(0)}</p>;
    } else {
      return <img src={src} alt={name || 'avatar'} />;
    }
  }, [name, src]);

  return (
    <div className={`c__avatar c__avatar-size-${size}`}>{__renderImage}</div>
  );
};

export default AppAvatar;
