import AppModal, {
  AppModalFormContent,
  AppModalFormTitle
} from 'common/components/AppModal';
import { HiMiniXMark } from 'react-icons/hi2';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import { createColumnHelper } from '@tanstack/react-table';
import AppTable from 'common/components/AppTable';
import {
  formatData,
  formatDate,
  formatSecretPhoneNumber,
  formatTime
} from 'common/helpers/dataFormat.helper';
import { ILog } from 'common/interfaces/log.interface';
import { IStudentHealthQuestionnaires } from 'common/interfaces/student.interface';
import {
  HEALTH_ANSWER,
  HEALTH_EXPECTED_ANSWER
} from 'common/enums/healthQuestionnaire.enum';
import { memo, useState } from 'react';
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi';

interface ICreateAuditLogModalProps {
  log: ILog;
  open: boolean;
  onClose: () => void;
}

type ShowPhoneType = { idx: number; value: boolean };

const CreateAuditLogModal = (props: ICreateAuditLogModalProps) => {
  const { log, open, onClose } = props;

  const [showPhone, setShowPhone] = useState<ShowPhoneType[]>([
    {
      idx: 0,
      value: false
    }
  ]);

  const handleShowPhone = (phoneIndex: number) => {
    setShowPhone((pre: ShowPhoneType[]) => {
      if (pre?.filter((ob: ShowPhoneType) => ob.idx === phoneIndex)?.length) {
        return pre?.map((obj: ShowPhoneType) => {
          if (obj?.idx === phoneIndex) {
            return { ...obj, value: !obj?.value };
          }
          return obj;
        });
      }

      return [...pre, { idx: phoneIndex, value: true }];
    });
  };

  const columnHelper = createColumnHelper<IStudentHealthQuestionnaires>();

  const columns = [
    columnHelper.accessor('questionInfo', {
      header: () => <span>Question</span>,
      cell: (info) => formatData(info.getValue().question)
    }),
    columnHelper.accessor('answer', {
      header: () => <span>Answer</span>,
      cell: (info) => {
        if (info.getValue() === HEALTH_ANSWER.YES) {
          return HEALTH_EXPECTED_ANSWER.YES;
        } else if (info.getValue() === HEALTH_ANSWER.NO) {
          return HEALTH_EXPECTED_ANSWER.NO;
        } else {
          return formatData(info.getValue());
        }
      }
    })
  ];

  return (
    <div className="createAuditLogModal">
      <AppModal open={open} onClose={onClose}>
        <AppModalFormTitle>
          <p>
            {log.createdByUserName} created student on{' '}
            {formatDate(log.createdAt)} {formatTime(log.createdAt)}
          </p>
          <HiMiniXMark onClick={onClose} />
        </AppModalFormTitle>
        <AppModalFormContent>
          <AppCard>
            <AppCardHeader title="PERSONAL INFORMATION" />
            <AppCardContent>
              <AppCardContentItem
                subtitle="Name"
                title={formatData(log.data?.firstName)}
              />
              <AppCardContentItem
                subtitle="Surname"
                title={formatData(log.data?.lastName)}
              />
              <AppCardContentItem
                subtitle="Alias"
                title={formatData(log.data?.aliasName)}
              />
              <AppCardContentItem
                subtitle="DOB"
                title={formatDate(log.data?.dob)}
              />
              <AppCardContentItem
                subtitle="Gender"
                title={formatData(log.data?.gender)}
              />
              <AppCardContentItem
                subtitle="Joining Date"
                title={formatDate(log.data?.createdAt)}
              />
            </AppCardContent>
            {log.data?.emergencyContacts?.length > 0 && (
              <>
                <AppCardHeader title="Emergency contact" />
                {log.data.emergencyContacts.map((item) => {
                  return (
                    <AppCardContent key={item._id}>
                      <AppCardContentItem
                        subtitle="Name"
                        title={formatData(item.contactName)}
                      />
                      <AppCardContentItem
                        subtitle="Surname"
                        title={formatData(item.contactSurname)}
                      />

                      <AppCardContentItem subtitle="Mobile Number">
                        <span className="custom_show_phone">
                          {!!showPhone.filter(
                            (obj: ShowPhoneType) => obj.idx === 0 && !!obj.value
                          )?.length ? (
                            <a href={`tel:${item.phoneNumber}`}>
                              {item.phoneNumber}{' '}
                            </a>
                          ) : (
                            <span>
                              {formatSecretPhoneNumber(item.phoneNumber)}
                            </span>
                          )}

                          <button onClick={() => handleShowPhone(0)}>
                            {!!showPhone.filter(
                              (obj: ShowPhoneType) =>
                                obj.idx === 0 && !!obj.value
                            )?.length ? (
                              <HiOutlineEye fontSize={20} />
                            ) : (
                              <HiOutlineEyeOff fontSize={20} />
                            )}
                          </button>
                        </span>
                      </AppCardContentItem>
                      <AppCardContentItem
                        subtitle="Relationship"
                        title={formatData(item.relationship)}
                      />
                    </AppCardContent>
                  );
                })}
              </>
            )}
            {(log.data?.healthQuestionnaires?.length || 0) > 0 && (
              <>
                <AppCardHeader title="Health Questionnaire" />
                <AppTable
                  data={log.data.healthQuestionnaires}
                  columns={columns}
                />
              </>
            )}
          </AppCard>
        </AppModalFormContent>
      </AppModal>
    </div>
  );
};

export default memo(CreateAuditLogModal);
