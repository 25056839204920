import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useMemo,
  memo
} from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate, useLocation } from 'react-router-dom';
import useDebounce from 'common/hooks/useDebounce';
import { useToast } from 'context/ToastContext';
import { HiOutlineIdentification } from 'react-icons/hi';
import { GoPlus } from 'react-icons/go';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppTable from 'common/components/AppTable';
import AppInputSearch from 'common/components/AppInputSearch';
import AppButton from 'common/components/AppButton';
import AppCard from 'common/components/AppCard';
import AppRadio from 'common/components/AppRadio';
import PermissionWrapper from 'components/PermissionWrapper';
import { useBrandLocation } from 'context/BrandLocationContext';
import { getResponsiblePersonList } from 'services/responsiblePerson.service';
import {
  IResponsiblePerson,
  IResponsiblePersonTableFilter
} from 'common/interfaces/responsiblePerson.interface';
import { PERMISSION } from 'common/enums/permission.enum';
import {
  formatData,
  formatDate,
  formatSecretPhoneNumber
} from 'common/helpers/dataFormat.helper';

import './desktop.scss';

export interface RefHandle {
  refetchReset: () => void;
}

interface Props {
  openAdd?: boolean;
  requireRPHaveStudent?: boolean;
  onOpenAdd?: () => void;
  onCloseAdd?: () => void;
  onFinish?: (newStep?: number, RPdata?: IResponsiblePerson) => void;
  defaultRPdata?: IResponsiblePerson | null;
  onSelectedRP?: (RPdata: IResponsiblePerson | null) => void;
}

const mainRoute = '/responsible-person';
const subRoutes = ['/classes'];

const ResponsiblePersonList = forwardRef<RefHandle, Props>(
  (
    {
      openAdd,
      onOpenAdd,
      onCloseAdd,
      onFinish,
      defaultRPdata,
      onSelectedRP,
      requireRPHaveStudent
    },
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      refetchReset() {
        setPageIndex(1);
        setSearch('');

        fetchData();
      }
    }));

    const toast = useToast();
    const location = useLocation();
    const { selectedLocation: globalLocation } = useBrandLocation();

    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 400);

    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pageTotal, setPageTotal] = useState(-1);
    const [data, setData] = useState<IResponsiblePerson[]>([]);

    const [loadData, setLoadData] = useState(true);

    const [selectedRP, setSelectedRP] = useState<IResponsiblePerson | null>(
      defaultRPdata ? defaultRPdata : null
    );

    useEffect(() => {
      if (defaultRPdata || defaultRPdata === null) setSelectedRP(defaultRPdata);
    }, [defaultRPdata]);

    const handleSelectRP = useCallback(
      (RPdata: IResponsiblePerson | null) => {
        setSelectedRP(RPdata);

        if (onSelectedRP) onSelectedRP(RPdata);
      },
      [onSelectedRP]
    );

    const fetchData = useCallback(async () => {
      try {
        if (!globalLocation?._id) return;

        setLoadData(true);

        const params: IResponsiblePersonTableFilter = {
          locationId: globalLocation?._id,
          limit: pageSize,
          page: pageIndex,
          keyword: debouncedSearch
        };

        const { data } = await getResponsiblePersonList(params);

        setData(data?.data?.data);
        setPageTotal(data?.data?.total);
      } catch (error: any) {
        toast.error(error?.response?.data?.message || 'Fetch RP list failed');
      } finally {
        setLoadData(false);
      }

      // eslint-disable-next-line
    }, [globalLocation?._id, pageSize, pageIndex, debouncedSearch]);

    const navigate = useNavigate();

    const columnHelper = createColumnHelper<IResponsiblePerson>();

    const columnsNorm = useMemo(() => {
      return [
        columnHelper.accessor('firstName', {
          header: () => <span>SURNAME, NAME</span>,
          cell: (info) =>
            formatData(info.row.original?.lastName) +
            ', ' +
            formatData(info.row.original?.firstName)
        }),
        columnHelper.accessor('dob', {
          header: () => <span>DOB</span>,
          cell: (info) => formatDate(info.getValue())
        }),
        columnHelper.accessor('phoneNumber', {
          header: () => <span>Mobile</span>,
          cell: (info) => formatSecretPhoneNumber(info.getValue())
        }),
        columnHelper.accessor('email', {
          header: () => <span>Email</span>,
          cell: (info) => formatData(info.getValue())
        }),
        columnHelper.accessor('additionalInfo', {
          header: () => <span>Students</span>,
          cell: (info) =>
            formatData(info.row.original?.additionalInfo?.totalStudentRelative),
          size: 50
        }),
        columnHelper.accessor('_id', {
          header: () => (
            <div style={{ cursor: 'pointer', textAlign: 'center' }}>ACTION</div>
          ),
          cell: (info) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '12px'
                }}
              >
                <PermissionWrapper
                  permission={PERMISSION.CREATE_RESPONSIBLE_PERSON}
                >
                  <div
                    style={{
                      cursor: 'pointer'
                    }}
                    onClick={() =>
                      navigate(`/responsible-person/${info.getValue()}`)
                    }
                  >
                    <HiOutlineIdentification fontSize={20} />
                  </div>
                </PermissionWrapper>

                {/* <div
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  <HiOutlineUserAdd fontSize={20} />
                </div> */}
              </div>
            );
          }
        })
      ];
    }, [columnHelper, navigate]);

    const columnsWithSelect = useMemo(() => {
      return [
        columnHelper.accessor('_id', {
          header: () => <div style={{ textAlign: 'center' }}></div>,
          cell: (info) => {
            return (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <AppRadio
                    defaultChecked={selectedRP?._id === info.getValue()}
                    onClick={() =>
                      selectedRP?._id === info.getValue()
                        ? handleSelectRP(null)
                        : handleSelectRP(info.row.original)
                    }
                  />
                </div>
              </>
            );
          },
          size: 20
        }),
        ...[...columnsNorm]?.splice(0, 5)
      ];
    }, [columnHelper, handleSelectRP, columnsNorm, selectedRP?._id]);

    const columnsWithSelectRPhaveST = useMemo(() => {
      return [
        columnHelper.accessor('_id', {
          header: () => <div style={{ textAlign: 'center' }}></div>,
          cell: (info) => {
            return (
              <>
                {requireRPHaveStudent &&
                info.row.original?.additionalInfo?.totalStudentRelative > 0 ? (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <AppRadio
                        defaultChecked={selectedRP?._id === info.getValue()}
                        onClick={() =>
                          selectedRP?._id === info.getValue()
                            ? handleSelectRP(null)
                            : handleSelectRP(info.row.original)
                        }
                      />
                    </div>
                  </>
                ) : null}
              </>
            );
          },
          size: 20
        }),
        ...[...columnsNorm]?.splice(0, 5)
      ];
    }, [
      columnHelper,
      handleSelectRP,
      columnsNorm,
      requireRPHaveStudent,
      selectedRP?._id
    ]);

    const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPageIndex(1);
      setSearch(event.target.value);
    };

    const onClearSearch = () => {
      setSearch('');
    };

    const handleFinish = (newStep?: number) => {
      if (onFinish)
        if (typeof newStep === 'number' && newStep >= 0) {
          if (selectedRP?._id) {
            onFinish(newStep, selectedRP);
          } else {
            onFinish(newStep);
          }
        } else {
          if (selectedRP?._id) {
            onFinish(undefined, selectedRP);
          } else {
            onFinish();
          }
        }
    };

    useEffect(() => {
      if (!openAdd) return;

      handleSelectRP(null);

      fetchData();
    }, [fetchData, openAdd, handleSelectRP]);

    const defineColumn = useMemo(() => {
      if (requireRPHaveStudent) return columnsWithSelectRPhaveST;
      if (location.pathname !== mainRoute) return columnsWithSelect;

      return columnsNorm;
    }, [
      requireRPHaveStudent,
      columnsNorm,
      columnsWithSelect,
      columnsWithSelectRPhaveST,
      location.pathname
    ]);

    useEffect(() => {
      fetchData();
    }, [fetchData]);

    const TablePure = () => {
      return (
        <div className="responsible_person">
          <AppCard>
            <div className="responsible_person__table">
              <div className="responsible_person__table-header">
                <h2 className="responsible_person__table-header-title">
                  {location.pathname === mainRoute ||
                  subRoutes.includes(location.pathname)
                    ? 'Responsible Person(s)'
                    : 'Add Responsible Person'}
                </h2>

                <div className="responsible_person__table-header-search">
                  <AppInputSearch
                    type="text"
                    value={search}
                    onChange={onChangeSearch}
                    placeholder="Surname, name, member id, mobile or email"
                    onClearSearch={onClearSearch}
                    startIcon={<HiMagnifyingGlass />}
                  />

                  <PermissionWrapper
                    permission={PERMISSION.CREATE_RESPONSIBLE_PERSON}
                  >
                    {location.pathname === subRoutes[0] ? null : (
                      <>
                        <AppButton
                          variant="primary"
                          buttonSize="small"
                          type="submit"
                          onClick={
                            location.pathname === mainRoute
                              ? () => (onOpenAdd ? onOpenAdd() : null)
                              : () => handleFinish(1)
                          }
                        >
                          <div className="header__add">
                            <GoPlus size={24} />

                            <div className="text">New RP</div>
                          </div>
                        </AppButton>
                      </>
                    )}
                  </PermissionWrapper>
                </div>
              </div>
              <div className="responsible_person__table-content">
                <PermissionWrapper
                  permission={PERMISSION.LIST_RESPONSIBLE_PERSON}
                >
                  <AppTable
                    loading={loadData}
                    data={data}
                    columns={defineColumn}
                    pagination={{
                      index: pageIndex,
                      size: pageSize,
                      total: pageTotal
                    }}
                    onChangePage={(index: number, size: number) => {
                      setPageIndex(index);
                      setPageSize(size);
                    }}
                  />
                </PermissionWrapper>
              </div>
            </div>
          </AppCard>
        </div>
      );
    };

    if (location.pathname === mainRoute) {
      return (
        <>
          <AppBreadCrumb
            items={[
              { name: 'Responsible Person', href: '/responsible-person' }
            ]}
          />
          <div className="layoutContainer">{TablePure()}</div>
        </>
      );
    }

    return <>{TablePure()}</>;
  }
);

export default memo(ResponsiblePersonList);
