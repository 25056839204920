import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import VersionModal from 'components/VersionModal';
import AppButton from 'common/components/AppButton';

import './index.scss';
import PaymentModal from 'components/PaymentModal';
import { changePaymentMethod } from 'services/students.service';
import { ChangePaymentMethodDTO } from 'DTOs/student.dto';
import { useToast } from 'context/ToastContext';
import AppModal, { AppModalContent } from 'common/components/AppModal';
import { PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import { FAILED_TO_GET_CARD_TOKEN } from 'common/constants/classBooking.constant';
import { useMoveBooking } from 'context/MoveBookingContext';

interface Props {
  amount: number;
  paymentType: PAYMENT_VALUE;
}
const Footer = ({ amount, paymentType }: Props) => {
  const params = useParams();
  const toast = useToast();
  const { RPId, studentId, bookClassId } = params;
  const navigate = useNavigate();
  const { moveFrom, currentEnrolmentClass } = useMoveBooking();

  const [isFailedToGetCardToken, setIsFailedToGetCardToken] =
    useState<boolean>(false);

  const [showModal, setShowModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false);
  const [openPaymentSuccessModal, setOpenPaymentSuccessModal] =
    useState<boolean>(false);

  const handlePayment = async (cardId: string) => {
    if (
      !bookClassId ||
      !studentId ||
      !RPId ||
      !currentEnrolmentClass?.locationId
    )
      return;
    try {
      const payload: ChangePaymentMethodDTO = {
        responsiblePersonId: RPId || '',
        studentId: studentId || '',
        bookClassId,
        amount: amount,
        cardId: cardId,
        locationId: currentEnrolmentClass?.locationId,
        isSaveCardToken: true,
        statementDescriptor: {
          name: 'Payer'
        },
        paymentType: paymentType
      };
      await changePaymentMethod(payload);

      setOpenPaymentModal(false);
      setOpenPaymentSuccessModal(true);

      setTimeout(() => {
        handleCancel();
      }, 2000);
    } catch (error: any) {
      if (error?.response?.data?.errorCode === FAILED_TO_GET_CARD_TOKEN) {
        setIsFailedToGetCardToken(true);
      } else {
        setIsFailedToGetCardToken(false);
        toast.error(error?.response?.data?.message || 'Fail to make payment');
      }
    }
  };

  const handleShowMakePayment = () => {
    setOpenPaymentModal(true);
  };

  const handleCancel = useCallback(() => {
    if (moveFrom === 'students') {
      navigate(`/${moveFrom}/${params.studentId}?indexRoute=1&tabNumber=0`);
    } else {
      navigate(`/${moveFrom}/${params.RPId}?indexRoute=1&sub1=0`);
    }
    // eslint-disable-next-line
  }, [moveFrom]);

  const handleCloseModalSuccess = () => {
    setOpenPaymentSuccessModal(false);
    handleCancel();
  };
  return (
    <>
      {openPaymentModal && (
        <PaymentModal
          open={openPaymentModal}
          onClose={() => {
            setIsFailedToGetCardToken(false);
            setOpenPaymentModal(false);
          }}
          amount={amount}
          payerId={RPId || ''}
          onMakePayment={handlePayment}
          isFailedToGetCardToken={isFailedToGetCardToken}
        />
      )}
      <AppModal
        open={openPaymentSuccessModal}
        onClose={handleCloseModalSuccess}
      >
        <img
          src="/icons/approve-icon.svg"
          alt=""
          style={{ display: 'flex', margin: '38px auto 14px' }}
        />
        <AppModalContent>
          You have successfully change the payment method
        </AppModalContent>
      </AppModal>
      <div className="c_footer-app-enrollment">
        <div className="c_footer-app_content">
          <div>© Viva Labs, a division of Viva Leisure Limited | </div>
          <div>ver 1.0.0</div>
          <img
            src="/icons/document.svg"
            width={15}
            alt=""
            style={{ cursor: 'pointer' }}
            onClick={() => setShowModal(true)}
          />
        </div>
        <div className="c_footer-app_btn">
          <AppButton
            type="button"
            variant="secondary"
            buttonSize="small"
            onClick={handleCancel}
          >
            Cancel
          </AppButton>
          <AppButton
            type="button"
            buttonSize="small"
            onClick={handleShowMakePayment}
            // disabled={disabledNext}
          >
            Confirm
          </AppButton>
        </div>
      </div>
      <VersionModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default Footer;
