import { Suspense, lazy, memo, useCallback, useEffect, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import AppToggle from 'common/components/AppToggle';
import AppTable from 'common/components/AppTable';
import AppButton from 'common/components/AppButton';
import AppSearchCalendar from 'common/components/AppSearchCalendar';
import ColorLevelQuickFilter from 'components/colorLevelFilter/ColorLevelQuickFilter';
import PermissionWrapper from 'components/PermissionWrapper';
import ClassDeleteModal from '../components/ClassDeleteModal';
import {
  HiOutlineAcademicCap,
  HiOutlineIdentification,
  HiOutlineTrash
} from 'react-icons/hi';
import { GoFilter } from 'react-icons/go';
import { useLayout } from 'context/LayoutContext';
import { useAuth } from 'context/AuthContext';
import { useStepController } from 'context/StepControllerContext';
import { useToast } from 'context/ToastContext';
import { IColorSetting } from 'common/interfaces/dashboard.interface';
import { FilterValue, IClass } from 'common/interfaces/class.interface';
import { IClassBooked } from 'common/interfaces/bookingClass.interface';
import { FLOW_ENUM } from 'common/enums/step.enum';
import { PERMISSION } from 'common/enums/permission.enum';
import { convertRecurrenceToString } from 'common/helpers/time.helper';
import { formatData, formatTime } from 'common/helpers/dataFormat.helper';
import { getClassType } from 'common/helpers/class.helper';
import AppCheckbox from 'common/components/AppCheckbox';
import useDebounce from 'common/hooks/useDebounce';
import FilterClasses from 'components/FilterClasses';
import { LevelSetting } from 'common/interfaces/levelBreakdown.interface';
import {
  getLevelBreakdowns,
  updateLevelSetting
} from 'services/levelBreakdown.service';
import { useBrandLocation } from 'context/BrandLocationContext';
import useLocalStorage from 'common/hooks/useLocalStorage';
import { KEY_FILTER_CLASSES } from 'common/constants/localStorage.constant';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import dayjs from 'dayjs';

const ClassStatistic = lazy(() => import('../ClassStatistic'));

const initialFilterClasses = {
  classType: '',
  level: '',
  instructor: '',
  area: '',
  dayOfWeek: '',
  duration: ''
};

interface Props {
  classes?: IClass[];
  setSearchValue: (value: string) => void;
  onChangeFilterValue: (value: FilterValue) => void;
  onOpenAdd?: () => void;
  onDeleteSuccess: () => void;
  searchAdvance: (value: any) => void;
  loading: boolean;
  setPageIndex: (value: number) => void;
  setPageSize: (value: number) => void;
  pageTotal: number;
  pageIndex: number;
  pageSize: number;
  filterFieldNumber: number;
  searchValue: string;
}

const TableClasses = ({
  classes,
  onOpenAdd,
  setSearchValue,
  onChangeFilterValue,
  setPageIndex,
  setPageSize,
  onDeleteSuccess,
  searchAdvance,
  loading,
  pageTotal,
  pageIndex,
  pageSize,
  filterFieldNumber,
  ...props
}: Props) => {
  const columnHelper = createColumnHelper<IClass>();

  const navigate = useNavigate();
  const { dataSet } = useStepController();
  const { selectedLocation: globalLocation } = useBrandLocation();

  const { hasPermission, user } = useAuth();
  const { handleMouseLeaveMenu } = useLayout();
  const toast = useToast();

  const [showFilter, setShowFilter] = useState(false);
  const [selectedClass, setSelectedClass] = useState<IClass | null>(null);
  const [colorSettings, setColorSettings] = useState<IColorSetting[]>([]);
  const [currentFilter, setCurrentFilter] = useLocalStorage<FilterValue>(
    KEY_FILTER_CLASSES,
    initialFilterClasses
  );

  const [levelSelected, setLevelSelected] = useState('');
  const [vacancy, setVacancy] = useState(1);
  const [applyVacancy, setApplyVacancy] = useState(true);
  const [canBeBookMakeUp, setCanBeBookMakeUp] = useState(false);

  const debouncedVacancy = useDebounce(vacancy, 500);

  useEffect(() => {
    if (applyVacancy) {
      handleFilter(currentFilter);
    }
    // eslint-disable-next-line
  }, [debouncedVacancy]);

  useEffect(() => {
    handleFilter(currentFilter);
    // eslint-disable-next-line
  }, [applyVacancy]);

  useEffect(() => {
    handleFilter(currentFilter);
    // eslint-disable-next-line
  }, [canBeBookMakeUp]);

  useEffect(() => {
    handleFilter(initialFilterClasses);
    // eslint-disable-next-line
  }, [globalLocation?._id]);

  useEffect(() => {
    if (dataSet?.flow === FLOW_ENUM.INIT) {
      dataSet.setFlow(FLOW_ENUM.CLASS_BOOKING);
    }
  }, [dataSet]);

  const getColorSettingData = useCallback(async () => {
    try {
      if (!globalLocation?._id) return;

      const { data } = await getLevelBreakdowns(1, 100, globalLocation?._id);

      setColorSettings(
        data.data.data.map((item: IColorSetting) => {
          return {
            ...item,
            colorCode: item.userColorSetting?.colorCode || item.colorCode,
            active: !!currentFilter.level?.split(',')?.includes(item._id)
          };
        })
      );
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to load list level!'
      );
    }
    // eslint-disable-next-line
  }, [globalLocation?._id, currentFilter]);

  useEffect(() => {
    getColorSettingData();
  }, [getColorSettingData]);

  const onSortColorSetting = useCallback(
    async (newColorSettings: IColorSetting[]) => {
      if (!user?._id || !newColorSettings.length) return;
      try {
        setColorSettings(newColorSettings);

        const payload: LevelSetting = {
          levelOrders: newColorSettings.map((item, index) => {
            return { levelId: item._id, order: index };
          })
        };
        await updateLevelSetting(user?._id, payload);
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Cannot sort color setting'
        );
      }
    },
    // eslint-disable-next-line
    [user?._id]
  );

  const onClickAllLevel = () => {
    const newSetting = colorSettings.map((item) => {
      item.active = false;
      return item;
    });

    setColorSettings(newSetting);

    handleFilter({ ...currentFilter, level: '' });
  };

  const handleFilter = useCallback(
    (value: FilterValue) => {
      setCurrentFilter(value);
      onChangeFilterValue({
        ...value,
        vacancy: vacancy,
        applyVacancy: applyVacancy,
        canBeBookMakeUp: canBeBookMakeUp
      });
      setPageIndex(1);

      setShowFilter(false);
      setLevelSelected(value.level);

      const newSetting = colorSettings.map((item: IColorSetting) => {
        if (value.level.split(',').includes(item._id)) {
          item.active = true;
        } else {
          item.active = false;
        }
        return item;
      });

      setColorSettings(newSetting);
    },
    // eslint-disable-next-line
    [
      onChangeFilterValue,
      colorSettings,
      setPageIndex,
      debouncedVacancy,
      applyVacancy,
      canBeBookMakeUp
    ]
  );

  const onChooseDeleteClass = (classSelected: IClass) => {
    setSelectedClass(classSelected);
  };

  const onCancelDeleteClass = () => {
    setSelectedClass(null);
  };

  const handleDeleteSuccess = async () => {
    setSelectedClass(null);
    setPageIndex(1);
    onDeleteSuccess();
  };

  const onClickLevel = useCallback(
    (levelId: string) => {
      const newSetting = colorSettings.map((item) => {
        if (item._id === levelId) {
          item.active = !item.active;
        }

        return item;
      });

      setColorSettings(newSetting);

      const levelIds = newSetting
        .filter((item) => item.active)
        .map((item) => item._id);
      handleFilter({
        ...currentFilter,
        level: levelIds.join(',')
      });
    },
    [colorSettings, handleFilter, currentFilter]
  );

  const isHaveBookedId = useCallback(
    (id: string) => {
      if (dataSet?.flow === FLOW_ENUM.CLASS_BOOKING && dataSet.step === 1) {
        return !!dataSet?.classesBooked?.filter(
          (classInfo) => classInfo?.info?._id === id
        )?.length;
      }

      return false;
    },
    [dataSet]
  );

  const isShowBookMe = useCallback((row: IClass): boolean => {
    return !!(
      row.active &&
      row?.session &&
      dayjs(row?.session.startTime).isAfter(dayjs()) &&
      row?.capacity - (row?.session?.occupied || 0) >= 1
    );
  }, []);

  const handleBookMe = useCallback(
    (classId: string) => {
      const classTarget =
        (classes?.filter(
          (classInfo) => classInfo?._id === classId
        )?.[0] as IClassBooked) || null;

      if (
        dataSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
        (dataSet?.step === 0 || dataSet?.step === 1) &&
        classTarget
      ) {
        dataSet?.handleClassBooking(classTarget);
      }
    },
    [dataSet, classes]
  );

  const columns = [
    columnHelper.accessor('startTime', {
      header: () => <span> Next class</span>,
      cell: (info) => {
        const item = info.row.original;
        if (!item.session) return formatData(null);
        return (
          <div data-tooltip-id={`content-tooltip-${item._id}`}>
            <div className="content-cell">
              <span className="line-clamp-1">{`${dayjs(
                item.session?.startTime || item.startTime
              ).format('ddd, MMM D, HH:mm')} - ${formatTime(
                item?.session?.endTime || item.endTime
              )}`}</span>
            </div>
            <Tooltip
              id={`content-tooltip-${item._id}`}
              opacity={1}
              className="tooltip-detail"
              style={{ backgroundColor: '#034EA2' }}
              render={() => <div>{convertRecurrenceToString(item)}</div>}
            />
          </div>
        );
      },
      size: 250
    }),
    columnHelper.accessor('template.name', {
      header: () => <span>CLASS</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('levelBreakdown', {
      header: () => <span>Level</span>,
      cell: (info) =>
        formatData(info.row.original?.levelBreakdown?.name || 'All Levels')
    }),
    columnHelper.accessor('type', {
      header: () => <span>PROGRAM TYPE</span>,
      cell: (info) => formatData(getClassType(info.getValue()))
    }),
    columnHelper.accessor('instructor', {
      header: () => <span>INSTRUCTOR</span>,
      cell: (info) =>
        formatData(
          info.row.original.session?.instructor?.firstName ||
            info.getValue()?.firstName
        )
    }),
    columnHelper.accessor('area', {
      header: () => <span>AREA</span>,
      cell: (info) =>
        formatData(info.row.original.session?.area || info.getValue())
    }),
    columnHelper.accessor('session', {
      header: () => <span>CAPACITY</span>,
      cell: (info) => {
        const session = info.row.original.session;
        const capacity = info.getValue()?.capacity || 0;
        const occupied = session?.occupied || 0;
        const available = capacity - occupied;
        return (
          <div style={{ cursor: 'pointer' }} className="content-cell">
            <HiOutlineAcademicCap fontSize={20} />
            {!session ? (
              `--/--`
            ) : (
              <span style={{ whiteSpace: 'nowrap', marginLeft: '4px' }}>
                {formatData(occupied)}/{formatData(capacity)}{' '}
                {available >= 0 ? `(${available} left)` : '(overbooked)'}
              </span>
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor('active', {
      header: () => <div style={{ textAlign: 'center' }}>ACTIVE</div>,
      cell: (info) => {
        return (
          <div style={{ cursor: 'default', textAlign: 'center' }}>
            <AppToggle value={info.getValue()} disabled={true} />
          </div>
        );
      }
    }),
    columnHelper.accessor('_id', {
      header: () => <div style={{ cursor: 'pointer' }}>ACTION</div>,
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_CLASS}>
              <div
                onClick={() =>
                  navigate(
                    `/classes/${info.getValue()}?lessonId=${
                      info.row.original?.session?._id
                    }`
                  )
                }
              >
                <HiOutlineIdentification fontSize={18} />
              </div>
            </PermissionWrapper>
            {info.row.original.occupied === 0 && (
              <PermissionWrapper permission={PERMISSION.DELETE_CLASS}>
                <div onClick={() => onChooseDeleteClass(info.row.original)}>
                  <HiOutlineTrash fontSize={18} />
                </div>
              </PermissionWrapper>
            )}
          </div>
        );
      },
      size: 50
    }),
    columnHelper.accessor('_id', {
      header: () => <span></span>,
      cell: (info) => {
        if (isShowBookMe(info.row.original))
          return (
            <PermissionWrapper permission={PERMISSION.BOOKING_CLASS}>
              <div
                className="table_custom_btn"
                style={{ cursor: 'pointer' }}
                onClick={() => handleBookMe(info.row.original?._id)}
              >
                <AppButton
                  variant={
                    isHaveBookedId(info.row.original?._id)
                      ? 'primary'
                      : 'secondary'
                  }
                  buttonSize="small"
                  type="submit"
                >
                  {isHaveBookedId(info.row.original?._id)
                    ? 'Selected'
                    : 'Book me'}
                </AppButton>
              </div>
            </PermissionWrapper>
          );

        return <div className="table_custom_btn"></div>;
      }
    })
  ];

  const handleSearch = (value: any, advance?: boolean) => {
    if (advance) {
      searchAdvance(value);
    } else {
      setSearchValue(value);
    }
    setPageIndex(1);
  };
  const handleToggleFilterVacancy = useCallback(() => {
    setApplyVacancy(!applyVacancy);
  }, [applyVacancy]);

  const handleToggleFilterMakeupVacancy = () => {
    setCanBeBookMakeUp(!canBeBookMakeUp);
  };

  return (
    <div
      className="layoutContainer classes-main"
      onMouseEnter={handleMouseLeaveMenu}
    >
      <PermissionWrapper permission={PERMISSION.LIST_CLASS}>
        <FilterClasses
          handleFilter={handleFilter}
          goBack={() => setShowFilter(false)}
          showFilter={showFilter}
          levelSelected={levelSelected}
          currentFilter={currentFilter}
        />
      </PermissionWrapper>

      <PermissionWrapper permission={PERMISSION.GET_CLASS_STATISTIC}>
        <Suspense fallback={<AppLoadingContainer />}>
          <ClassStatistic />
        </Suspense>
      </PermissionWrapper>

      <div className="classes-table">
        <div className="classes-table_header">
          <div>Classes</div>

          <div className="classes-table_header_right">
            <PermissionWrapper permission={PERMISSION.SEARCH_CLASS}>
              <div className="classes-input-search">
                <AppSearchCalendar
                  handleSearch={handleSearch}
                  searchValue={props.searchValue}
                />
              </div>
            </PermissionWrapper>

            <PermissionWrapper permission={PERMISSION.LIST_CLASS}>
              <div className="classes-btn-filter">
                <button
                  className="btnActionHeaderClass"
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                >
                  <GoFilter fontSize={22} />
                  <div className="btnHeaderLabel">
                    Filters
                    {filterFieldNumber > 0 && (
                      <div className="filter-field-number">
                        {filterFieldNumber}
                      </div>
                    )}
                  </div>
                </button>
              </div>
            </PermissionWrapper>

            {/* <div className="btn-export">
              <button className={styleClass.btnActionHeaderClass}>
                <div>
                  <HiOutlineDocumentDownload fontSize={22} />
                </div>
                <span className={styleClass.btnHeaderLabel}>Export</span>
              </button>
            </div> */}

            <PermissionWrapper permission={PERMISSION.CREATE_CLASS}>
              <AppButton
                variant="primary"
                buttonSize="small"
                type="submit"
                onClick={onOpenAdd}
              >
                + Class
              </AppButton>
            </PermissionWrapper>
          </div>
        </div>

        <PermissionWrapper permission={PERMISSION.LIST_COLOR_SETTING}>
          <div
            style={{ marginBottom: '10px' }}
            className="color-setting-wrapper"
          >
            <div className="vacancy-input">
              <AppToggle
                value={applyVacancy}
                onChange={handleToggleFilterVacancy}
              />
              <span className="text">Min. Vac.</span>
              <input
                type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setVacancy(Number(e.target.value))
                }
                value={vacancy || 0}
                min={0}
                inputMode="numeric"
              />
            </div>
            <div className="vacancy-input">
              <AppCheckbox
                checked={canBeBookMakeUp}
                onChange={handleToggleFilterMakeupVacancy}
              />
              <span>Make-up Vac.</span>
            </div>
            <ColorLevelQuickFilter
              colorSettings={colorSettings}
              onClickLevel={onClickLevel}
              onClickAllLevel={onClickAllLevel}
              levelIds={levelSelected}
              onSortColorSetting={onSortColorSetting}
            />
          </div>
        </PermissionWrapper>

        <AppTable
          data={classes}
          columns={columns}
          pagination={{
            index: pageIndex,
            size: pageSize,
            total: pageTotal
          }}
          onChangePage={(index: number, size: number) => {
            setPageIndex(index);
            setPageSize(size);
          }}
          loading={loading}
          columnVisibility={{
            _id: hasPermission(PERMISSION.VIEW_DETAIL_CLASS),
            action: hasPermission(PERMISSION.BOOKING_CLASS)
          }}
        />
      </div>

      {/* DELETE */}
      {!!selectedClass ? (
        <ClassDeleteModal
          classSelected={selectedClass}
          onClose={onCancelDeleteClass}
          onSuccess={handleDeleteSuccess}
        />
      ) : null}
    </div>
  );
};
export default memo(TableClasses);
