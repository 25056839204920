import { KEY_THEME } from 'common/constants/localStorage.constant';
import { THEME } from 'common/enums/theme.enum';
import useLocalStorage from 'common/hooks/useLocalStorage';
import React, { createContext, useContext } from 'react';

interface IThemeContext {
  theme: THEME;
  onToggleTheme: () => void;
}

const ThemeContext = createContext<IThemeContext | null>(null);

export const useTheme = (): IThemeContext => {
  const useThemeContext = useContext(ThemeContext);

  if (!useThemeContext) {
    throw new Error('useTheme must be used within an ThemeProvider');
  }

  return useThemeContext;
};

export const ThemeProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children
}) => {
  const [theme, setTheme] = useLocalStorage(KEY_THEME, THEME.DARK);

  const onToggleTheme = () => {
    const newTheme = theme === THEME.DARK ? THEME.LIGHT : THEME.DARK;
    setTheme(newTheme);
  };

  const themeContextValue: IThemeContext = {
    theme,
    onToggleTheme
  };

  return (
    <ThemeContext.Provider value={themeContextValue}>
      <div id="themeWrapper" className="themeWrapper" data-theme={theme}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
};
