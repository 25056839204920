import {
  useMemo,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  ChangeEvent,
  memo
} from 'react';
import { useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { useToast } from 'context/ToastContext';
import { Resolver, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  HiOutlinePencilSquare,
  HiOutlineXCircle,
  HiOutlineBookmark,
  HiMiniXMark
} from 'react-icons/hi2';
import { BeatLoader } from 'react-spinners';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppDatePicker from 'common/components/AppDatePicker';
import AppInput from 'common/components/AppInput';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import UploadAvatar, {
  ERROR_MESSAGE_LIMIT_SIZE
} from 'components/UploadAvatar';
import PermissionWrapper from 'components/PermissionWrapper';
import { updateRPPersonalInfo } from 'services/responsiblePerson.service';
import { uploadFile } from 'services/uploadFile.service';
import yup from 'validators/responsiblePerson.validator';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import { objectContainObj } from 'common/helpers/object.helper';
import { IResponsiblePerson } from 'common/interfaces/responsiblePerson.interface';
import { UpdateResponsiblePersonPersonalInfoDto } from 'DTOs/responsiblePerson.dto';
import { FILE_ASSET_TYPE } from 'common/enums/uploadFile.enum';
import { PERMISSION } from 'common/enums/permission.enum';

import './desktop.scss';

interface Props {
  data: IResponsiblePerson | null;
  onUpdated: () => void;
}

export const BLANK_IMAGE =
  'https://images.squarespace-cdn.com/content/v1/5ba24ff7fcf7fdb9d4c3e95e/1544106754797-TZN1YT7FVM4J2VXAM6G8/image-asset.jpeg';

const validationSchema = yup.OBJECT({
  firstName: yup.RESPONSIBLE_PERSON_NAME,
  lastName: yup.RESPONSIBLE_PERSON_SURNAME,
  aliasName: yup.RESPONSIBLE_PERSON_ALIAS,
  dob: yup.RESPONSIBLE_PERSON_DOB,
  joiningDate: yup.RESPONSIBLE_PERSON_JOINING_DATE
});

const PersonalInformation = ({ data, onUpdated }: Props) => {
  const toast = useToast();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const onOpenEdit = useCallback(() => {
    setIsEdit(true);
  }, []);

  const onCloseEdit = useCallback(() => {
    setIsEdit(false);
  }, []);

  const {
    register,
    setValue,
    clearErrors,
    trigger,
    control,
    reset,
    formState: { errors },
    handleSubmit
  } = useForm<UpdateResponsiblePersonPersonalInfoDto>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<UpdateResponsiblePersonPersonalInfoDto>,
    defaultValues: {
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      aliasName: data?.aliasName || '',
      // @ts-ignore
      dob: convertToUnixTime(data?.dob || ''),
      joiningDate: convertToUnixTime(data?.additionalInfo?.joiningDate || '')
    }
  });

  const watchAllFields = useWatch({ control });

  const [disabled, setDisabled] = useState(true);
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    setDisabled(
      !(
        !!watchAllFields &&
        !Object.values(errors)?.filter((values: any) => values.message)?.length
      )
    );
  }, [watchAllFields, errors]);

  const handleInitValue = useCallback(() => {
    clearErrors();

    setAvatarUrl(data?.avatarUrl || '');

    reset({
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      aliasName: data?.aliasName || '',
      // @ts-ignore
      dob: convertToUnixTime(data?.dob || ''),
      joiningDate: convertToUnixTime(data?.additionalInfo?.joiningDate || '')
    });
  }, [reset, data, clearErrors]);

  useEffect(() => {
    if (!data?._id) return;

    handleInitValue();
  }, [data, handleInitValue]);

  useEffect(() => {
    if (isEdit) return;

    handleInitValue();
  }, [isEdit, handleInitValue]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // @ts-ignore
      setValue(event.target.name, event.target.value);

      // @ts-ignore
      trigger(event.target.name);
    },
    [setValue, trigger]
  );

  const handleChangeDOB = useCallback(
    (val: Dayjs | null) => {
      setValue('dob', convertToUnixTime(dayjs(val)?.format()));

      trigger('dob');
    },
    [setValue, trigger]
  );

  const handleUploadFile = useCallback(async (file: File) => {
    if (file) {
      const resultAvatar = await uploadFile(FILE_ASSET_TYPE.AVATAR, file);

      setAvatarUrl(resultAvatar.data.data || '');
    }
  }, []);

  const handleRemoveAvatar = useCallback(async () => {
    setAvatarUrl(BLANK_IMAGE);
  }, []);

  const onSubmit = useCallback(async () => {
    if (disabled) {
      trigger();
      return;
    }

    const submitData = { ...watchAllFields };

    if (!data || !id || !submitData) return;

    const mapInitData = {
      ...data,
      dob: convertToUnixTime(dayjs(data?.dob).format()),
      joiningDate: convertToUnixTime(data.additionalInfo.joiningDate)
    } as UpdateResponsiblePersonPersonalInfoDto;

    const mapData = {
      ...submitData,
      dob: convertToUnixTime(dayjs(submitData?.dob).format()),
      joiningDate: convertToUnixTime(data.additionalInfo.joiningDate),
      ...(avatarUrl ? { avatarUrl } : undefined)
    } as UpdateResponsiblePersonPersonalInfoDto;

    if (objectContainObj(mapInitData, mapData)) {
      onCloseEdit();

      setDisabled(false);
      setLoading(false);

      return;
    }

    setDisabled(true);
    setLoading(true);

    try {
      await updateRPPersonalInfo(id, mapData);

      toast.success('Update RP personal info successfully');

      onCloseEdit();
      onUpdated();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Update RP personal info failed'
      );
    } finally {
      setDisabled(false);
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [
    id,
    onUpdated,
    avatarUrl,
    disabled,
    watchAllFields,
    data,
    trigger,
    onCloseEdit
  ]);

  const __renderIcons = useMemo((): ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSubmit)}>
            <HiOutlineBookmark size={24} />
          </div>

          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle size={24} />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
  }, [isEdit, onSubmit, loading, handleSubmit, onOpenEdit, onCloseEdit]);

  const __renderContent = useMemo((): ReactNode => {
    if (isEdit) {
      return (
        <>
          <div className="responsible_person-personal_information_wrapper">
            <div className="avatar__container">
              <UploadAvatar
                defaultImage={avatarUrl ? avatarUrl : undefined}
                onChangeFile={handleUploadFile}
                onErrorFile={(errorMessage: string) => {
                  if (errorMessage === ERROR_MESSAGE_LIMIT_SIZE) {
                    toast.error('Exceed the MB');
                  }
                }}
              />

              {avatarUrl ? (
                <HiMiniXMark
                  className="remove__avatar"
                  size={24}
                  onClick={handleRemoveAvatar}
                />
              ) : null}
            </div>

            <div className="responsible_person-personal_information_cover responsible_person-personal_information_cover-isEdit">
              <div className="item_tittle">
                <AppCardHeader
                  title="PERSONAL INFORMATION"
                  suffix={
                    <PermissionWrapper
                      permission={
                        PERMISSION.UPDATE_RESPONSIBLE_PERSON_PERSONAL_INFO
                      }
                    >
                      {__renderIcons}
                    </PermissionWrapper>
                  }
                />
              </div>

              <div className="item">
                <AppInput
                  {...register('firstName')}
                  label="Name*"
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.firstName?.message || ''
                  }}
                />
              </div>
              <div className="item">
                <AppInput
                  {...register('lastName')}
                  label="Surname*"
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.lastName?.message || ''
                  }}
                />
              </div>
              <div className="item">
                <AppInput
                  {...register('aliasName')}
                  label="Alias"
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.aliasName?.message || ''
                  }}
                />
              </div>

              <div className="item">
                <AppDatePicker
                  {...register('dob')}
                  label="D.O.B*"
                  value={
                    watchAllFields?.dob ? dayjs(watchAllFields?.dob) : null
                  }
                  disableFuture
                  onChange={handleChangeDOB}
                  message={{
                    type: 'error',
                    text: errors?.dob?.message || ''
                  }}
                />
              </div>

              <div className="item">
                <AppDatePicker
                  label="Profile creation date*"
                  value={dayjs(data?.additionalInfo?.joiningDate)}
                  disabled
                />
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="responsible_person-personal_information_wrapper">
          <div
            style={{
              pointerEvents: isEdit ? 'all' : 'none'
            }}
          >
            <UploadAvatar defaultImage={data?.avatarUrl} />
          </div>

          <div className="responsible_person-personal_information_cover">
            <div className="item_tittle">
              <AppCardHeader
                title="PERSONAL INFORMATION"
                suffix={
                  <PermissionWrapper
                    permission={
                      PERMISSION.UPDATE_RESPONSIBLE_PERSON_PERSONAL_INFO
                    }
                  >
                    {__renderIcons}
                  </PermissionWrapper>
                }
              />
            </div>

            <div className="item">
              <AppCardContentItem
                subtitle="Name"
                title={formatData(data?.firstName)}
              />
            </div>
            <div className="item">
              <AppCardContentItem
                subtitle="Surname"
                title={formatData(data?.lastName)}
              />
            </div>
            <div className="item">
              <AppCardContentItem
                subtitle="Alias"
                title={formatData(data?.aliasName)}
              />
            </div>
            <div className="item">
              <AppCardContentItem
                subtitle="DOB"
                title={formatDate(data?.dob)}
              />
            </div>
            <div className="item">
              <AppCardContentItem
                subtitle="Profile creation date"
                title={formatDate(data?.additionalInfo?.joiningDate)}
              />
            </div>
          </div>
        </div>
      </>
    );

    // eslint-disable-next-line
  }, [
    isEdit,
    data,
    errors,
    handleChange,
    register,
    __renderIcons,
    avatarUrl,
    handleChangeDOB,
    handleRemoveAvatar,
    handleUploadFile,
    watchAllFields
  ]);

  if (loading) {
    return <AppLoadingContainer />;
  }

  return (
    <section className="personal-information">
      <AppCard>
        <AppCardContent className="card-content">
          <div className="personal-information-main-content">
            <div className="content">{__renderContent}</div>
          </div>
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default memo(PersonalInformation);
