import React, { useState } from 'react';
import { HiOutlineSquare2Stack } from 'react-icons/hi2';
import './desktop.scss';

interface IAppCopyButtonProps {
  textToCopy: string;
}

const AppCopyButton: React.FC<IAppCopyButtonProps> = ({ textToCopy }) => {
  const [tooltipText, setTooltipText] = useState('Copy to clipboard');

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setTooltipText('Copied');
    });
  };

  const handleMouseEnter = () => {
    if (tooltipText === 'Copied') {
      setTooltipText('Copy to clipboard');
    }
  };

  return (
    <div className="c__copy-button-container" onMouseEnter={handleMouseEnter}>
      <div className="c__copy-button" onClick={handleCopy}>
        <HiOutlineSquare2Stack size={22} onClick={handleCopy} />
      </div>
      {<p className="c__tooltip">{tooltipText}</p>}
    </div>
  );
};

export default AppCopyButton;
