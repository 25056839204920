import API from './API';
import {
  IStudentListFilter,
  IStudentTableFilter
} from 'common/interfaces/student.interface';
import {
  CreateStudentDto,
  UpdateStudentPersonalInfoDto,
  UpdateStudentEmergencyContactDto,
  BookClassDto,
  ApplyCreditDTO,
  StudentMoveBookingDTO,
  ISTMoneyCreditHistoryTableFilter,
  UpdateSTMoneyCreditDto,
  ChangePaymentMethodDTO,
  RequestSpecialEnrolmentDTO
} from 'DTOs/student.dto';

const STUDENT_API_ENDPOINT = '/students';
const SCHEDULE_API_ENDPOINT = '/schedules';

export const getStudentStatistic = (params?: { locationId?: string }) => {
  return API.get(`${STUDENT_API_ENDPOINT}/student-statistic`, params);
};

export const getStudentList = async (params: IStudentTableFilter) => {
  const response = await API.get(`${STUDENT_API_ENDPOINT}`, params);
  return response.data.data;
};

export const addStudent = (payload: CreateStudentDto) => {
  return API.post(`${STUDENT_API_ENDPOINT}`, { body: payload });
};

export const getStudentDetail = async (studentId: string) => {
  const response = await API.get(`${STUDENT_API_ENDPOINT}/${studentId}`);
  return response.data.data;
};

export const updateStudentPersonalInfo = (
  studentId: string,
  payload: UpdateStudentPersonalInfoDto
) => {
  return API.patch(`${STUDENT_API_ENDPOINT}/${studentId}/personal-info`, {
    body: payload
  });
};

export const updateStudentEmergencyContact = async (
  studentId: string,
  payload: UpdateStudentEmergencyContactDto
) => {
  const response = await API.put(
    `${STUDENT_API_ENDPOINT}/${studentId}/emergency-contact`,
    {
      body: payload
    }
  );
  return response.data;
};

export const testBookClass = (payload: BookClassDto) => {
  return API.post(`${STUDENT_API_ENDPOINT}/validate-book-class`, {
    body: payload
  });
};

export const bookClass = async (payload: BookClassDto) => {
  return API.post(`${STUDENT_API_ENDPOINT}/book-class`, {
    body: payload
  });
};

export const getStudentsInClass = async (
  classId: string,
  page: number,
  limit: number,
  keyword?: string,
  locationId: string = ''
) => {
  const response = await API.get(
    `${STUDENT_API_ENDPOINT}/booked-class/${classId}?page=${page}&limit=${limit}&keyword=${keyword}&locationId=${locationId}`
  );
  return response.data.data;
};

export const listStudentByLesson = (
  lessonId: string,
  page: number,
  limit: number,
  keyword: string,
  isCanAssess?: boolean
) => {
  const params: {
    page: number;
    limit: number;
    keyword: string;
    isCanAssess?: boolean;
  } = { page: page, limit: limit, keyword: keyword };

  if (typeof isCanAssess !== 'undefined') params.isCanAssess = isCanAssess;

  return API.get(`${SCHEDULE_API_ENDPOINT}/list-student/${lessonId}`, params);
};

export const listStudentByClass = (
  classId: string,
  locationId: string,
  page: number,
  limit: number,
  keyword: string,
  isCanAssess?: boolean
) => {
  const params: {
    locationId: string;
    page: number;
    limit: number;
    keyword: string;
    isCanAssess?: boolean;
  } = { locationId: locationId, page: page, limit: limit, keyword: keyword };

  if (typeof isCanAssess !== 'undefined') params.isCanAssess = isCanAssess;

  return API.get(`${STUDENT_API_ENDPOINT}/class/${classId}`, params);
};

export const getCurrentClassByStudent = (studentId: string) => {
  return API.get(`${STUDENT_API_ENDPOINT}/${studentId}/current-class`);
};

export const getAttendanceHistory = (
  studentId: string,
  page: number,
  limit: number,
  isCanAbsence?: boolean
) => {
  const params: {
    page: number;
    limit: number;
    isCanAbsence?: boolean;
  } = { page, limit };

  if (typeof isCanAbsence !== 'undefined') params.isCanAbsence = isCanAbsence;

  return API.get(`${STUDENT_API_ENDPOINT}/attendance/${studentId}`, params);
};

export const getLevelByStudentId = (studentId: string) => {
  return API.get(`${STUDENT_API_ENDPOINT}/${studentId}/level`);
};

export const updateLevelByStudent = (
  studentId: string,
  body: {
    trialLevelId?: string;
    actualLevelId?: string;
  }
) => {
  return API.patch(`${STUDENT_API_ENDPOINT}/${studentId}/level`, {
    body
  });
};

export const findStudentList = async (params: IStudentListFilter) => {
  const response = await API.get(`${STUDENT_API_ENDPOINT}/search`, params);
  return response.data.data;
};

export const applyCreditsToStudents = (body: ApplyCreditDTO) => {
  return API.post(`${STUDENT_API_ENDPOINT}/add-makeup-credit`, { body });
};

export const getStudentEnrollmentHistory = async (
  studentId: string,
  page: number,
  limit: number
) => {
  const response = await API.get(
    `${STUDENT_API_ENDPOINT}/${studentId}/enrollment`,
    { page, limit }
  );
  return response.data.data;
};

export const moveBookingForStudent = (body: StudentMoveBookingDTO) => {
  return API.post(`${STUDENT_API_ENDPOINT}/move-booking`, {
    body
  });
};

export const getSTMakeupCreditHistory = (
  studentId: string,
  params: ISTMoneyCreditHistoryTableFilter
) => {
  return API.get(
    `${STUDENT_API_ENDPOINT}/${studentId}/makeup-credit-histories`,
    params
  );
};

export const updateSTmakeUpCredit = (
  studentId: string,
  payload: UpdateSTMoneyCreditDto
) => {
  return API.patch(`${STUDENT_API_ENDPOINT}/${studentId}/make-up-credit`, {
    body: payload
  });
};

export const changePaymentMethod = async (payload: ChangePaymentMethodDTO) => {
  return API.post(`${STUDENT_API_ENDPOINT}/change-payment-method`, {
    body: payload
  });
};

export const requestSpecialEnrolment = async (
  payload: RequestSpecialEnrolmentDTO
) => {
  return API.post(`${STUDENT_API_ENDPOINT}/request-special-enrollment`, {
    body: payload
  });
};

export const exportEnrolmentDetail = async (id: string) => {
  return API.get(`${STUDENT_API_ENDPOINT}/export-enrolment-detail/${id}`);
};
