import AppBreadCrumb from 'components/common/AppBreadcrumb';
import PersonalInformation from './components/PersonalInformation';
import ContactDetails from './components/ContactDetails';
import Credentials from './components/Credentials';
import ClassList from './components/ClassList';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from 'context/AuthContext';
import { useParams } from 'react-router-dom';
import { getInstructorDetail } from 'services/instructor.service';
import { IInstructor } from 'common/interfaces/instructor.interface';
import { BeatLoader } from 'react-spinners';
import { PERMISSION } from 'common/enums/permission.enum';
import AppLoadingContainer from 'common/components/AppLoadingContainer';

import './desktop.scss';

const InstructorDetail = () => {
  const { hasPermission } = useAuth();
  const [state, setState] = useState<IInstructor | null>(null);
  const params = useParams();

  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      if (!params?.id) return;

      setLoading(true);
      const data = await getInstructorDetail(params?.id);
      setState(data);
    } catch (error) {
      setState(null);
    } finally {
      setLoading(false);
    }
  }, [params.id]);

  const onEditSuccess = useCallback(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) return <AppLoadingContainer />;

  return (
    <main className="instructorDetailPage">
      <AppBreadCrumb
        items={[
          { name: 'Instructor', href: '/instructors' },
          { name: 'Instructor details', href: '' }
        ]}
      />
      <div className="layoutContainer">
        {!state ? (
          <BeatLoader
            color="white"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          />
        ) : (
          <>
            <PersonalInformation state={state} onSuccess={onEditSuccess} />
            <ContactDetails state={state} onSuccess={onEditSuccess} />
            <Credentials />
            {hasPermission([
              PERMISSION.LIST_CLASS,
              PERMISSION.LIST_LEVEL_BREAKDOWN
            ]) && <ClassList />}
          </>
        )}
      </div>
    </main>
  );
};

export default InstructorDetail;
