import { yupResolver } from '@hookform/resolvers/yup';
import { CreateStaffDTO } from 'DTOs/staff.dto';
import UploadAvatar, {
  ERROR_MESSAGE_LIMIT_SIZE
} from 'components/UploadAvatar';
import AppButton from 'common/components/AppButton';
import AppCard, {
  AppCardContent,
  AppCardHeader
} from 'common/components/AppCard';
import AppDatePicker from 'common/components/AppDatePicker';
import AppInput from 'common/components/AppInput';
import AppPhoneNumberInput from 'common/components/AppPhoneNumberInput';
import AppSelect from 'common/components/AppSelect';
import {
  GENDER_TYPE_OPTIONS,
  STAFF_TYPE_OPTIONS,
  STATE_OPTIONS
} from 'common/constants/index';
import { useBrandLocation } from 'context/BrandLocationContext';
import { useToast } from 'context/ToastContext';
import dayjs from 'dayjs';
import { FILE_ASSET_TYPE } from 'common/enums/uploadFile.enum';
import { GENDER, STAFF_TYPE } from 'common/enums/user.enum';
import React, { useEffect, useRef, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { uploadFile } from 'services/uploadFile.service';
import yupStaff from 'validators/staff.validator';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { createStaff } from 'services/staff.service';
import { getAllRole } from 'services/hierarchy.service';
import { IOption } from 'common/interfaces';
import { IRole } from 'common/interfaces/hierarchy.interface';
import AppToolTip from 'common/components/AppToolTip';
import { HiArrowLeft, HiOutlineExclamationCircle } from 'react-icons/hi';
import { convertToUnixTime } from 'common/helpers/time.helper';
import AppToggle from 'common/components/AppToggle';
import { STATUS_TYPE } from 'common/enums/staff.enum';

const initStaff: CreateStaffDTO = {
  status: STATUS_TYPE.INACTIVE,
  firstName: '',
  lastName: '',
  aliasName: '',
  dob: '',
  gender: GENDER.IDLE,
  phoneNumber: '',
  email: '',
  address: '',
  suburb: '',
  state: '',
  postalCode: '',
  country: '',
  staffType: STAFF_TYPE.IDLE,
  roleId: '',
  joiningDate: '',
  endDate: '',
  // Use state to handle
  locationId: '',
  avatarUrl: ''
};

const validationSchema = yupStaff.OBJECT({
  status: yupStaff.STAFF_STATUS,
  firstName: yupStaff.STAFF_NAME,
  lastName: yupStaff.STAFF_SURNAME,
  aliasName: yupStaff.STAFF_ALIAS,
  dob: yupStaff.STAFF_DOB,
  gender: yupStaff.STAFF_GENDER,
  phoneNumber: yupStaff.STAFF_MOBILE_NUMBER,
  email: yupStaff.STAFF_EMAIL_ADDRESS,
  address: yupStaff.STAFF_ADDRESS,
  suburb: yupStaff.STAFF_SUBURB,
  state: yupStaff.STAFF_STATE,
  postalCode: yupStaff.STAFF_POSTCODE,
  country: yupStaff.STAFF_COUNTRY,
  staffType: yupStaff.STAFF_TYPE,
  roleId: yupStaff.STAFF_ROLE,
  joiningDate: yupStaff.STAFF_JOINING_DATE,
  endDate: yupStaff.STAFF_END_DATE,
  locationId: yupStaff.STAFF_LOCATION,
  avatarUrl: yupStaff.STAFF_AVATAR_URL
});

interface IStaffAddFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (reload: boolean) => void;
}

const StaffAddForm: React.FC<IStaffAddFormProps> = (
  props: IStaffAddFormProps
) => {
  const { open, onClose, onSuccess } = props;

  const {
    selectedLocation: globalLocation,
    locations: globalLocations
  } = useBrandLocation();
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const [staff, setStaff] = useState<CreateStaffDTO>(() => {
    return {
      ...initStaff,
      locationId: globalLocation?._id || ''
    };
  });

  const [roles, setRoles] = useState<Array<IOption>>([]);

  const [file, setFile] = useState<File | null>(null);

  const phoneNumberError = useRef<string>('');
  const endDateError = useRef<string>('');

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<CreateStaffDTO>({
    resolver: yupResolver(validationSchema) as Resolver<CreateStaffDTO>,
    defaultValues: staff
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'firstName') {
      setStaff({
        ...staff,
        firstName: event.target.value,
        aliasName: event.target.value
      });
      setValue('firstName', event.target.value);
      setValue('aliasName', event.target.value);
      trigger('firstName');
      return;
    }
    setStaff({
      ...staff,
      [event.target.name]: event.target.value
    });
    // @ts-ignore
    setValue(event.target.name, event.target.value);
    // @ts-ignore
    trigger(event.target.name);
  };

  const handleChangeStatus = () => {
    const newStatus =
      staff.status === STATUS_TYPE.ACTIVE
        ? STATUS_TYPE.INACTIVE
        : STATUS_TYPE.ACTIVE;
    setStaff({
      ...staff,
      status: newStatus
    });
    setValue('status', newStatus);
  };

  const handleChangeDOB = (value: dayjs.Dayjs | null) => {
    setStaff({
      ...staff,
      dob: value?.format('YYYY-MM-DD') || ''
    });
    setValue('dob', value?.format('YYYY-MM-DD') || '');
    trigger('dob');
  };

  const handleChangeJoiningDate = (value: dayjs.Dayjs | null) => {
    setStaff({
      ...staff,
      joiningDate: value?.format('YYYY-MM-DD') || ''
    });
    setValue('joiningDate', value?.format('YYYY-MM-DD') || '');
    trigger('joiningDate');
    if (!!staff.endDate) {
      endDateError.current =
        dayjs(value).isSame(staff.endDate) ||
        dayjs(value).isAfter(staff.endDate)
          ? 'End date must be greater than joining date'
          : '';
    }
  };

  const handleChangeEndDate = (value: dayjs.Dayjs | null) => {
    setStaff({
      ...staff,
      endDate: value?.format('YYYY-MM-DD') || ''
    });
    setValue('endDate', value?.format('YYYY-MM-DD') || '');
    trigger('endDate');
    if (!!value) {
      endDateError.current =
        dayjs(value).isSame(staff.joiningDate) ||
        dayjs(value).isBefore(staff.joiningDate)
          ? 'End date must be greater than joining date'
          : '';
    }
  };

  const handleChangePhoneNumber = (value: string) => {
    setStaff({
      ...staff,
      phoneNumber: value
    });
    setValue('phoneNumber', value);
    trigger('phoneNumber');
    phoneNumberError.current = isValidPhoneNumber(value || '')
      ? ''
      : 'Invalid phone number';
  };

  const onSubmit = async (data: CreateStaffDTO) => {
    // Custom error
    if (!!phoneNumberError.current || !!endDateError.current) return;

    setLoading(true);

    let avatarUrl = '';
    if (file) {
      const resultAvatar = await uploadFile(FILE_ASSET_TYPE.AVATAR, file);
      avatarUrl = resultAvatar.data.data || '';
    }

    const payload: CreateStaffDTO = {
      ...data,
      avatarUrl: avatarUrl,
      joiningDate: convertToUnixTime(data.joiningDate.toString()),
      dob: convertToUnixTime(data.dob.toString()),
      endDate: convertToUnixTime(data?.endDate?.toString() || '')
    };

    if (!data?.endDate) delete payload.endDate;
    if (!avatarUrl) delete payload.avatarUrl;

    createStaff(payload)
      .then(() => {
        // reset fields.
        setStaff({ ...initStaff });
        reset(initStaff);
        setFile(null);
        // notify and reload.
        toast.success('Create staff successfully');
        if (payload.locationId === globalLocation?._id) {
          onSuccess(true);
        } else {
          onSuccess(false);
        }
      })
      .catch((error) => {
        if (
          error.response.data.message === 'DUPLICATE_EMAIL' ||
          error.response.data.message === 'duplicate email'
        ) {
          toast.error('Email address is already in used');
        } else {
          toast.error(
            error?.response?.data?.message || 'Failed to create staff'
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (staff?.staffType) {
      // Reset role list when change the staff type
      setRoles([]);

      getAllRole(staff?.staffType)
        .then((result) => {
          const formattedRoles = result.data.data.map((role: IRole) => {
            return {
              label: role.name,
              value: role._id
            };
          });
          setRoles(formattedRoles);
        })
        .catch(() => {
          setRoles([]);
        });
    }
  }, [staff?.staffType]);

  return (
    <div className="staffAddContainer">
      <div
        className={`overlay ${open ? 'active' : ' '}`}
        onClick={onClose}
      ></div>
      <div className={`staffAddForm ${open ? 'active' : ' '}`}>
        <div className="staffAddForm__header">
          <div onClick={onClose}>
            <HiArrowLeft size={24} />
          </div>
          <p>Add Staff</p>
        </div>
        <div className="staffAddForm__content disable-scroll">
          <AppCard>
            <div className="staffAddForm__content-wrapper">
              <AppCardContent className="staffAddForm__content-personal-information">
                <div className="staffAddForm__content-personal-information-left">
                  <UploadAvatar
                    file={file}
                    onChangeFile={(file) => setFile(file)}
                    onErrorFile={(errorMessage: string) => {
                      if (errorMessage === ERROR_MESSAGE_LIMIT_SIZE) {
                        toast.error('Exceed the MB');
                      }
                    }}
                  />
                </div>
                <div className="staffAddForm__content-personal-information-right">
                  <AppCardHeader
                    title="PERSONAL INFORMATION"
                    suffix={
                      <AppToggle
                        value={staff.status === STATUS_TYPE.ACTIVE}
                        onChange={handleChangeStatus}
                      />
                    }
                  />
                  <div className="staffAddForm__content-personal-information-right-content">
                    <AppInput
                      label="Name*"
                      {...register('firstName')}
                      value={staff.firstName}
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.firstName?.message || ''
                      }}
                    />
                    <AppInput
                      label="Surname"
                      {...register('lastName')}
                      value={staff.lastName}
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.lastName?.message || ''
                      }}
                    />
                    <AppInput
                      label="Alias"
                      {...register('aliasName')}
                      value={staff.aliasName}
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.aliasName?.message || ''
                      }}
                    />
                    <AppDatePicker
                      label="D.O.B*"
                      {...register('dob')}
                      value={dayjs(staff.dob)}
                      onChange={handleChangeDOB}
                      message={{
                        type: 'error',
                        text: errors?.dob?.message || ''
                      }}
                      disableFuture
                    />
                    <AppSelect
                      label="Gender*"
                      options={GENDER_TYPE_OPTIONS}
                      {...register('gender')}
                      value={staff.gender}
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.gender?.message || ''
                      }}
                    />
                  </div>
                </div>
              </AppCardContent>
              <AppCardHeader title="Contact Details" />
              <AppCardContent className="staffAddForm__content-contact-details">
                <AppPhoneNumberInput
                  label="Mobile Number*"
                  {...register('phoneNumber')}
                  value={staff.phoneNumber}
                  onChange={handleChangePhoneNumber}
                  message={{
                    type: 'error',
                    text:
                      errors?.phoneNumber?.message ||
                      phoneNumberError.current ||
                      ''
                  }}
                />
                <AppInput
                  label="Email Address*"
                  {...register('email')}
                  value={staff.email}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.email?.message || ''
                  }}
                />
                <AppInput
                  label="Address*"
                  {...register('address')}
                  value={staff.address}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.address?.message || ''
                  }}
                />
                <AppInput
                  label="Suburb*"
                  {...register('suburb')}
                  value={staff.suburb}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.suburb?.message || ''
                  }}
                />
                <AppSelect
                  label="State*"
                  options={STATE_OPTIONS}
                  {...register('state')}
                  value={staff.state}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.state?.message || ''
                  }}
                />
                <AppInput
                  label="Postcode*"
                  {...register('postalCode')}
                  value={staff.postalCode}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.postalCode?.message || ''
                  }}
                />
                <AppInput
                  label="Country*"
                  {...register('country')}
                  value={staff.country}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.country?.message || ''
                  }}
                />
              </AppCardContent>
              <AppCardHeader title="Staff Access" />
              <AppCardContent className="staffAddForm__content-staff-access">
                <AppSelect
                  label="Staff Type*"
                  options={STAFF_TYPE_OPTIONS}
                  {...register('staffType')}
                  value={staff.staffType}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.staffType?.message || ''
                  }}
                />
                <AppSelect
                  label="Role*"
                  options={roles}
                  {...register('roleId')}
                  value={staff.roleId}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.roleId?.message || ''
                  }}
                />
                <AppSelect
                  label="Home Club*"
                  options={[...globalLocations].map((location) => {
                    return {
                      value: location._id,
                      label: location.name
                    };
                  })}
                  {...register('locationId')}
                  value={staff?.locationId || ''}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.locationId?.message || ''
                  }}
                />
                <AppDatePicker
                  label="Joining date*"
                  {...register('joiningDate')}
                  value={dayjs(staff.joiningDate)}
                  onChange={handleChangeJoiningDate}
                  message={{
                    type: 'error',
                    text: errors?.joiningDate?.message || ''
                  }}
                  disablePast
                />
                <div className="addStaff__endDateWrapper">
                  <AppDatePicker
                    label="End date"
                    {...register('endDate')}
                    value={dayjs(staff.endDate)}
                    onChange={handleChangeEndDate}
                    message={{
                      type: 'error',
                      text:
                        errors?.endDate?.message || endDateError.current || ''
                    }}
                    disablePast
                  />
                  <AppToolTip tooltipText={`This is the end of the contract`}>
                    <HiOutlineExclamationCircle />
                  </AppToolTip>
                </div>
              </AppCardContent>
            </div>
          </AppCard>
        </div>
        <div className="staffAddForm__actions">
          <AppButton isLoading={loading} onClick={handleSubmit(onSubmit)}>
            Save
          </AppButton>
          <AppButton variant="secondary" onClick={onClose}>
            Cancel
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default StaffAddForm;
