import dayjs from 'dayjs';
import { object as OBJECT, array, object, string, number } from 'yup';

//  ===== STUDENT  =====
const STUDENT_NAME = string().required('Please provide student name');

const STUDENT_SURNAME = string().required('Please provide student surname');

const STUDENT_ALIAS = string();

const STUDENT_DOB = number()
  .typeError('Please provide D.O.B')
  .required('Please provide D.O.B')
  .label('dob')
  .test('dob', (value, { createError }) => {
    if (dayjs(value).format('YYYY-MM-DD') === 'Invalid Date') {
      return createError({
        message: 'Please provide D.O.B'
      });
    }

    if (dayjs(value).isAfter(dayjs())) {
      return createError({
        message: 'Date must be in the past'
      });
    }

    const maxYearsLimit = dayjs().subtract(200, 'year');

    if (dayjs(value).isBefore(maxYearsLimit)) {
      return createError({
        message: 'Invalid D.O.B'
      });
    }

    return true;
  });

const STUDENT_GENDER = string().required('Please provide student gender');

const STUDENT_MEMBER_NUMBER = string().required(
  'Please provide student member number'
);

const STUDENT_PHONE_NUMBER = string().required(
  'Please provide student phone number'
);

const STUDENT_EMAIL_ADDRESS = string()
  .email('Please provide correct email format')
  .required('Please provide student email address');

const STUDENT_RELATIONSHIP = string().required(
  'Please provide student relationship'
);

const STUDENT_JOINING_DATE = string().required(
  'Please provide student joining date'
);

const STUDENT_LOCATION = string().required('Please provide student location');

const STUDENT_LEVEL = string().required('Please provide student level');

const STUDENT_TIMEZONE = string().required('Please provide student timezone');

const STUDENT_EMERGENCY_CONTACT_NAME = string().required(
  'Please provide emergency contact name'
);

const STUDENT_EMERGENCY_CONTACT_SURNAME = string().required(
  'Please provide emergency contact surname'
);

const STUDENT_EMERGENCY_CONTACTS = array(
  object({
    contactName: STUDENT_EMERGENCY_CONTACT_NAME,
    contactSurname: STUDENT_EMERGENCY_CONTACT_SURNAME,
    phoneNumber: STUDENT_PHONE_NUMBER,
    relationship: STUDENT_RELATIONSHIP
  })
).required('Please provide at least one emergency contact');

const STUDENT_EMAIL_ADDRESS_NO_REQUIRED = string().email(
  'Please provide correct email format'
);

const STUDENT_PHONE_NUMBER_NO_REQUIRED = string();

const STUDENT_MAKE_UP_CREDIT_VALUE = number()
  .typeError('makeup credit must be a number')
  .min(1, 'makeup credit must be greater than 0')
  .max(10000, 'makeup credit must be less than 10000');

//  ===== END STUDENT  =====

const validations = {
  OBJECT,
  string,
  number,
  STUDENT_NAME,
  STUDENT_SURNAME,
  STUDENT_ALIAS,
  STUDENT_DOB,
  STUDENT_GENDER,
  STUDENT_MEMBER_NUMBER,
  STUDENT_PHONE_NUMBER,
  STUDENT_EMAIL_ADDRESS,
  STUDENT_RELATIONSHIP,
  STUDENT_JOINING_DATE,
  STUDENT_LOCATION,
  STUDENT_LEVEL,
  STUDENT_TIMEZONE,
  STUDENT_EMERGENCY_CONTACTS,
  STUDENT_EMAIL_ADDRESS_NO_REQUIRED,
  STUDENT_PHONE_NUMBER_NO_REQUIRED,
  STUDENT_MAKE_UP_CREDIT_VALUE
};
export default validations;
