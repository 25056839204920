import {
  useMemo,
  useState,
  useEffect,
  useCallback,
  Fragment,
  useRef,
  ReactNode,
  ChangeEvent
} from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from 'context/ToastContext';
import { Resolver, useForm, useFieldArray, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  isValidPhoneNumber,
  isPossiblePhoneNumber
} from 'react-phone-number-input';
import {
  HiOutlinePencilSquare,
  HiOutlineXCircle,
  HiOutlineBookmark,
  HiXMark
} from 'react-icons/hi2';
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi';
import { BeatLoader } from 'react-spinners';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import AppPhoneNumberInput from 'common/components/AppPhoneNumberInput';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import yup from 'validators/student.validator';
import {
  formatData,
  formatSecretPhoneNumber
} from 'common/helpers/dataFormat.helper';
import { updateStudentEmergencyContact } from 'services/students.service';
import { arrayContainArray } from 'common/helpers/object.helper';
import {
  IStudent,
  IStudentEmergencyContact
} from 'common/interfaces/student.interface';
import { UpdateStudentEmergencyContactDto } from 'DTOs/student.dto';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { debounce } from 'lodash';
import { RESPONSE_CHECK_USER } from 'common/enums';
import { checkExisted } from 'services/auth.service';
import { ERROR_MESSAGE_PHONE_TAKEN } from 'common/constants/error.constant';

import './desktop.scss';

type ShowPhoneType = { idx: number; value: boolean };

interface Props {
  data: IStudent | null;
  onUpdated: () => void;
}

const validationSchema = yup.OBJECT({
  emergencyContacts: yup.STUDENT_EMERGENCY_CONTACTS
});

const EmergencyContacts = ({ data, onUpdated }: Props) => {
  const toast = useToast();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [disabled, setDisabled] = useState(false);
  // eslint-disable-next-line
  const [isCheckOnServer, setIsCheckOnServer] = useState(false);

  const [showPhone, setShowPhone] = useState<ShowPhoneType[]>([
    {
      idx: 0,
      value: false
    }
  ]);

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!isEdit) return;

    setShowPhone([
      {
        idx: 0,
        value: false
      }
    ]);
  }, [isEdit]);

  const onOpenEdit = useCallback(() => {
    setIsEdit(true);
  }, []);

  const onCloseEdit = useCallback(() => {
    setIsEdit(false);
  }, []);

  const {
    register,
    setValue,
    clearErrors,
    control,
    trigger,
    setError,
    getValues,
    formState: { errors }
  } = useForm<UpdateStudentEmergencyContactDto>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<UpdateStudentEmergencyContactDto>,
    defaultValues: {
      emergencyContacts: (data?.emergencyContacts || [])?.map((ec) => {
        return {
          phoneNumber: ec.phoneNumber,
          contactName: ec.contactName,
          contactSurname: ec.contactSurname,
          relationship: ec.relationship
        };
      })
    }
  });

  const { fields, append, remove } = useFieldArray({
    name: 'emergencyContacts',
    control
  });

  const watchAllFields = useWatch({ control });

  useEffect(() => {
    const noEmptyECS = watchAllFields.emergencyContacts?.every(
      (emergencyContact) => {
        return Object.values(emergencyContact)?.every((value) => !!value);
      }
    );

    const noECsErrorValues = () => {
      if (errors?.emergencyContacts) {
        // @ts-ignore
        return !errors?.emergencyContacts
          ?.filter((ec) => !!ec)
          ?.filter(
            (values: any) =>
              Object.values(values)?.filter((values) => !values)?.length
          );
      }

      return true;
    };

    if (noEmptyECS && noECsErrorValues() && count) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [watchAllFields, errors, count]);

  const handleInitValue = useCallback(() => {
    clearErrors();

    setValue(
      'emergencyContacts',
      (data?.emergencyContacts || [])?.map((ec) => {
        return {
          phoneNumber: ec.phoneNumber || '',
          contactName: ec.contactName || '',
          contactSurname: ec.contactSurname || '',
          relationship: ec.relationship || ''
        };
      })
    );
  }, [setValue, data, clearErrors]);

  useEffect(() => {
    if (!data?.emergencyContacts?.length) return;

    handleInitValue();
  }, [data, handleInitValue]);

  useEffect(() => {
    if (isEdit) return;

    handleInitValue();
  }, [isEdit, handleInitValue]);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index?: number
    ) => {
      if (event.target.name && typeof index === 'number' && index >= 0) {
        // @ts-ignore
        setValue(event.target.name, event.target.value);

        // @ts-ignore
        trigger(event.target.name);
      }
    },
    [setValue, trigger]
  );

  const abortConRef = useRef<AbortController>();

  // eslint-disable-next-line
  const checkExisting = useCallback(
    debounce(async (fieldName: string, keyword: string) => {
      try {
        if (abortConRef.current) abortConRef.current.abort();
        abortConRef.current = new AbortController();

        await checkExisted(keyword, '', abortConRef.current.signal);

        // @ts-ignore
        clearErrors(fieldName);

        setIsCheckOnServer(false);
      } catch (error: any) {
        if (
          error?.response?.data?.message === RESPONSE_CHECK_USER.BOTH_EXISTED
        ) {
          // @ts-ignore
          setError(fieldName, {
            type: 'optionality',
            message: ERROR_MESSAGE_PHONE_TAKEN
          });

          return;
        } else if (
          error?.response?.data?.message ===
          RESPONSE_CHECK_USER.PHONE_NUMBER_EXISTED
        ) {
          // @ts-ignore
          setError(fieldName, {
            type: 'optionality',
            message: ERROR_MESSAGE_PHONE_TAKEN
          });
          return;
        }
      }
    }, 300),
    []
  );

  const triggerValidate = useCallback(() => {
    if (!watchAllFields?.emergencyContacts?.length) return;

    const groups: any = {};

    watchAllFields?.emergencyContacts.forEach((obj, idx) => {
      const value = obj.phoneNumber;
      if (value) {
        groups[value] = groups[value] || [];
        groups[value].push(idx);
      }
    });

    const results: any[] = Object.values(groups).filter(
      (indexes: any) => indexes.length > 1
    );

    const result: number[] = [].concat(...results).sort((a, b) => a - b);

    for (const [
      key,
      emergencyContact
    ] of watchAllFields?.emergencyContacts.entries()) {
      if (key > 0) {
        if (
          result?.includes(key) &&
          emergencyContact?.phoneNumber &&
          isValidPhoneNumber(emergencyContact?.phoneNumber) &&
          isPossiblePhoneNumber(emergencyContact?.phoneNumber)
        ) {
          trigger([
            `emergencyContacts.${key}.contactName`,
            `emergencyContacts.${key}.contactSurname`,
            `emergencyContacts.${key}.relationship`
          ]).then(() => {
            setCount((pre) => (pre += 1));
          });

          setError(`emergencyContacts.${key}.phoneNumber`, {
            type: 'custom',
            message: 'This phone number is used by other emergency contacts'
          });
        } else {
          clearErrors(`emergencyContacts.${key}.phoneNumber`);

          trigger([
            `emergencyContacts.${key}.contactName`,
            `emergencyContacts.${key}.contactSurname`,
            `emergencyContacts.${key}.relationship`,
            `emergencyContacts.${key}.phoneNumber`
          ]).then(() => {
            setCount((pre) => (pre += 1));
          });
        }
      } else {
        trigger([`emergencyContacts.${key}.relationship`]);
      }
    }
  }, [trigger, setError, watchAllFields, clearErrors]);

  const handleChangePhoneNumber = useCallback(
    (val: string, index?: number) => {
      if (typeof index !== 'number' || index < 0) return;

      setValue(`emergencyContacts.${index}.phoneNumber`, val);

      if (!val) {
        clearErrors(`emergencyContacts.${index}.phoneNumber`);

        return;
      }

      setCount((pre) => (pre += 1));

      const phoneNumberCondition = !!(
        isValidPhoneNumber(val) && isPossiblePhoneNumber(val)
      );

      if (phoneNumberCondition) {
        const isConflicts = watchAllFields?.emergencyContacts?.filter(
          (ec) => ec?.phoneNumber === val
        )?.length;

        if (!isConflicts) {
          // setIsCheckOnServer(true);

          clearErrors(`emergencyContacts.${index}.phoneNumber`);

          // checkExisting(`emergencyContacts.${index}.phoneNumber`, val);
        }
      } else {
        setError(`emergencyContacts.${index}.phoneNumber`, {
          type: 'custom',
          message: 'Invalid phone number'
        });
      }
    },
    [setValue, clearErrors, setError, watchAllFields?.emergencyContacts]
  );

  useEffect(() => {
    if (!watchAllFields.emergencyContacts?.length) return;

    const groups: any = {};

    watchAllFields.emergencyContacts.forEach((obj, idx) => {
      const value = obj.phoneNumber;
      if (value) {
        groups[value] = groups[value] || [];
        groups[value].push(idx);
      }
    });

    const results: any[] = Object.values(groups).filter(
      (indexes: any) => indexes.length > 1
    );

    const result: number[] = [].concat(...results).sort((a, b) => a - b);

    for (const [
      key,
      emergencyContact
    ] of watchAllFields.emergencyContacts.entries()) {
      if (
        key > 0 &&
        emergencyContact?.phoneNumber &&
        isValidPhoneNumber(emergencyContact?.phoneNumber) &&
        isPossiblePhoneNumber(emergencyContact?.phoneNumber)
      ) {
        if (result?.includes(key)) {
          setError(`emergencyContacts.${key}.phoneNumber`, {
            type: 'custom',
            message: 'This phone number is used by other emergency contacts'
          });

          setCount((pre) => (pre += 1));
        } else {
          clearErrors(`emergencyContacts.${key}.phoneNumber`);
        }
      }
    }
  }, [setError, clearErrors, getValues, watchAllFields]);

  const handleAddMore = useCallback(() => {
    append({
      contactName: '',
      contactSurname: '',
      phoneNumber: '',
      relationship: ''
    });
    setCount((pre) => (pre += 1));
  }, [append]);

  const handleRemoveEmergencyContact = useCallback(
    (index: number) => {
      remove(index);
      setCount((pre) => (pre += 1));
    },
    [remove]
  );

  const handleShowPhone = (phoneIndex: number) => {
    setShowPhone((pre: ShowPhoneType[]) => {
      if (pre?.filter((ob: ShowPhoneType) => ob.idx === phoneIndex)?.length) {
        return pre?.map((obj: ShowPhoneType) => {
          if (obj?.idx === phoneIndex) {
            return { ...obj, value: !obj?.value };
          }
          return obj;
        });
      }

      return [...pre, { idx: phoneIndex, value: true }];
    });
  };

  const handleSubmit = useCallback(async () => {
    if (disabled) {
      triggerValidate();

      return;
    }

    try {
      const submitData = { ...watchAllFields };

      if (!id || !submitData || !data) return;

      const mapData: UpdateStudentEmergencyContactDto = {
        ...(submitData as UpdateStudentEmergencyContactDto)
      };

      if (
        arrayContainArray(data.emergencyContacts, mapData.emergencyContacts)
      ) {
        onCloseEdit();

        setDisabled(false);
        setLoading(false);

        return;
      }

      setLoading(true);
      setDisabled(true);

      await updateStudentEmergencyContact(id, mapData);

      toast.success('Update emergency contacts successfully');

      // set timeout to avoid the UI update too quick when the close the edit and the list emergency is less length
      setTimeout(() => onCloseEdit(), 100);

      onUpdated();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Update emergency contacts failed'
      );
    } finally {
      setDisabled(false);
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [
    onUpdated,
    id,
    disabled,
    watchAllFields,
    data,
    triggerValidate,
    onCloseEdit
  ]);

  const __renderIcons = useMemo((): ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit}>
            <HiOutlineBookmark size={24} />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle size={24} />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
  }, [isEdit, handleSubmit, loading, onOpenEdit, onCloseEdit]);

  const __renderContent = useMemo((): ReactNode => {
    if (isEdit) {
      return (
        <>
          {fields.map((field, index) => (
            <Fragment key={field.id}>
              <div className="item">
                <AppInput
                  {...register(
                    `emergencyContacts.${index}.contactName` as const
                  )}
                  disabled={index === 0}
                  label="Name*"
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => handleChange(event, index)}
                  message={{
                    type: 'error',
                    text:
                      errors?.emergencyContacts?.[index]?.contactName
                        ?.message || ''
                  }}
                />
              </div>
              <div className="item">
                <AppInput
                  {...register(
                    `emergencyContacts.${index}.contactSurname` as const
                  )}
                  disabled={index === 0}
                  label="Surname*"
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => handleChange(event, index)}
                  message={{
                    type: 'error',
                    text:
                      errors?.emergencyContacts?.[index]?.contactSurname
                        ?.message || ''
                  }}
                />
              </div>
              <div className="item">
                <AppPhoneNumberInput
                  {...register(
                    `emergencyContacts.${index}.phoneNumber` as const
                  )}
                  disabled={index === 0}
                  label="Mobile Number*"
                  value={
                    watchAllFields?.emergencyContacts?.[index]?.phoneNumber ||
                    ''
                  }
                  onChange={(val) => handleChangePhoneNumber(val, index)}
                  message={{
                    type: 'error',
                    text: count
                      ? errors?.emergencyContacts?.[index]?.phoneNumber
                          ?.message || ''
                      : ''
                  }}
                />
              </div>
              <div className="item">
                <AppInput
                  {...register(
                    `emergencyContacts.${index}.relationship` as const
                  )}
                  label="Relationship*"
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => handleChange(event, index)}
                  message={{
                    type: 'error',
                    text:
                      errors?.emergencyContacts?.[index]?.relationship
                        ?.message || ''
                  }}
                />
              </div>

              <div className="item_single">
                <PermissionWrapper
                  permission={PERMISSION.DELETE_STUDENT_EMERGENCY_CONTACT}
                >
                  {!!fields.length && !!index ? (
                    <button
                      type="button"
                      onClick={() => handleRemoveEmergencyContact(index)}
                    >
                      <HiXMark fontSize={24} />
                    </button>
                  ) : null}
                </PermissionWrapper>
              </div>
            </Fragment>
          ))}

          <PermissionWrapper
            permission={PERMISSION.CREATE_STUDENT_EMERGENCY_CONTACT}
          >
            <button
              type="button"
              className="content-btn_add_more"
              onClick={handleAddMore}
            >
              Add more
            </button>
          </PermissionWrapper>
        </>
      );
    }
    return (
      <div className="content_display">
        {data?.emergencyContacts?.length
          ? data?.emergencyContacts.map(
              (emergencyContact: IStudentEmergencyContact, index: number) => (
                <Fragment key={emergencyContact._id}>
                  <div className="item">
                    <AppCardContentItem
                      subtitle="Name"
                      title={formatData(emergencyContact.contactName)}
                    />
                  </div>
                  <div className="item">
                    <AppCardContentItem
                      subtitle="Surname"
                      title={formatData(emergencyContact.contactSurname)}
                    />
                  </div>
                  <div className="item">
                    <AppCardContentItem subtitle="Mobile Number">
                      <span className="custom_show_phone">
                        {!!showPhone.filter(
                          (obj: ShowPhoneType) =>
                            obj.idx === index && !!obj.value
                        )?.length ? (
                          <a href={`tel:${emergencyContact.phoneNumber}`}>
                            {emergencyContact.phoneNumber}{' '}
                          </a>
                        ) : (
                          <span>
                            {formatSecretPhoneNumber(
                              emergencyContact.phoneNumber
                            )}
                          </span>
                        )}

                        <button onClick={() => handleShowPhone(index)}>
                          {!!showPhone.filter(
                            (obj: ShowPhoneType) =>
                              obj.idx === index && !!obj.value
                          )?.length ? (
                            <HiOutlineEye fontSize={20} />
                          ) : (
                            <HiOutlineEyeOff fontSize={20} />
                          )}
                        </button>
                      </span>
                    </AppCardContentItem>
                  </div>
                  <div className="item">
                    <AppCardContentItem
                      subtitle="Relationship"
                      title={formatData(emergencyContact.relationship)}
                    />
                  </div>
                </Fragment>
              )
            )
          : null}
      </div>
    );
  }, [
    isEdit,
    data,
    handleChange,
    register,
    fields,
    handleAddMore,
    handleRemoveEmergencyContact,
    showPhone,
    handleChangePhoneNumber,
    watchAllFields?.emergencyContacts,
    errors?.emergencyContacts,
    count
  ]);

  if (!data?._id) {
    return <AppLoadingContainer />;
  }

  return (
    <section className="emergency-contacts">
      <AppCard>
        <AppCardContent className="card-content">
          <div className="emergency-contacts-main-content">
            <AppCardHeader
              title="Emergency Contacts"
              suffix={
                <PermissionWrapper
                  permission={PERMISSION.UPDATE_STUDENT_EMERGENCY_CONTACT}
                >
                  {__renderIcons}
                </PermissionWrapper>
              }
            />
            <div className="content">{__renderContent}</div>
          </div>
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default EmergencyContacts;
