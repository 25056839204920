import React, { useCallback, useEffect, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import AppCard, {
  AppCardContent,
  AppCardHeader
} from 'common/components/AppCard';
import AppButton from 'common/components/AppButton';
import AppTextArea from 'common/components/AppTextArea';
import AppSelect from 'common/components/AppSelect';
import AppToggle from 'common/components/AppToggle';
import AppInput from 'common/components/AppInput';

import { HiArrowLeft } from 'react-icons/hi2';

import { useToast } from 'context/ToastContext';
import { useBrandLocation } from 'context/BrandLocationContext';

import { createClassTemplate } from 'services/classTemplate.service';
import yupClassTemplate from 'validators/classTemplate.validator';
import {
  CreateClassTemplateDto,
  CreateClassTemplatePayloadDto
} from 'DTOs/classTemplate.dto';
import { getTerms } from 'services/term.service';
import { ITerm } from 'common/interfaces/term.interface';
import { CLASS_TEMPLATE_DURATIONS } from 'common/constants/index';
import dayjs from 'dayjs';
import { TERM_TYPE } from 'common/enums/term.enum';
import { getLevelBreakdowns } from 'services/levelBreakdown.service';
import { ILevelBreakdown } from 'common/interfaces/levelBreakdown.interface';
import { handleNumberInput } from 'helpers/index.helper';

const initClassTemplate: CreateClassTemplateDto = {
  active: true,
  name: '',
  duration: '',
  termIds: '',
  description: '',
  levelId: '',
  price: 0
};

const validationSchema = yupClassTemplate.OBJECT({
  active: yupClassTemplate.CLASS_TEMPLATE_ACTIVE,
  name: yupClassTemplate.CLASS_TEMPLATE_NAME,
  duration: yupClassTemplate.CLASS_TEMPLATE_DURATION,
  termIds: yupClassTemplate.CLASS_TEMPLATE_TERM,
  description: yupClassTemplate.CLASS_TEMPLATE_DESCRIPTION,
  price: yupClassTemplate.CLASS_TEMPLATE_PRICE,
  levelId: yupClassTemplate.CLASS_TEMPLATE_LEVEL
});

interface IClassTemplateAddFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const ClassTemplateAddForm = (props: IClassTemplateAddFormProps) => {
  const { open, onClose, onSuccess } = props;

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors }
  } = useForm<CreateClassTemplateDto>({
    resolver: yupResolver(validationSchema) as Resolver<CreateClassTemplateDto>
  });

  const { selectedLocation: globalLocation } = useBrandLocation();

  const toast = useToast();

  const [classTemplate, setClassTemplate] =
    useState<CreateClassTemplateDto>(initClassTemplate);
  const [terms, setTerms] = useState<ITerm[]>([]);
  const [levels, setLevels] = useState<ILevelBreakdown[]>([]);
  const [selectedLevel, setLevelSelected] = useState<ILevelBreakdown>();

  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    if (!globalLocation?._id) return;
    try {
      const response = await Promise.all([
        getTerms(1, 100, globalLocation._id, undefined, [TERM_TYPE.TERM], true),
        getLevelBreakdowns(1, 200, globalLocation._id)
      ]);

      setTerms(
        response[0].data.data.data.map((item: ITerm) => ({
          ...item,
          value: item._id,
          label: item.name,
          hint: (
            <span style={{ fontSize: 12 }}>
              (
              {`${dayjs(item.startDate).format('DD/MM/YYYY')} -  
              ${dayjs(item.endDate).format('DD/MM/YYYY')}`}
              )
            </span>
          )
        }))
      );
      const levels = response[1].data.data.data.map(
        (item: ILevelBreakdown) => ({
          ...item,
          value: item._id,
          label: item.name
        })
      );
      setLevels(levels);
    } catch (error: any) {
      setLevels([]);
      setTerms([]);
      toast.error(error?.response?.data?.message || 'Failed to fetch  terms');
    }
    // eslint-disable-next-line
  }, [globalLocation?._id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.name === undefined) {
      setClassTemplate({
        ...classTemplate,
        termIds: event.target.value
      });
      setValue('termIds', event.target.value);
      trigger('termIds');
      return;
    }
    if (event.target.name === 'levelId') {
      const level =
        levels.find((item) => item._id === event.target.value) || undefined;
      setLevelSelected(level);
    }
    if (event.target.name === 'price') {
      const price = handleNumberInput(event.target.value, classTemplate.price);
      // @ts-ignore
      setValue('price', price);
      trigger(event.target.name);
      setClassTemplate({
        ...classTemplate,
        price: Number(price)
      });
      return;
    }
    setClassTemplate({
      ...classTemplate,
      [event.target.name]: event.target.value
    });
    // @ts-ignore
    setValue(event.target.name, event.target.value);
    // @ts-ignore
    trigger(event.target.name);
  };

  const onSubmit = (data: CreateClassTemplateDto) => {
    setLoading(true);
    const payload: CreateClassTemplatePayloadDto = {
      ...data,
      active: classTemplate.active,
      duration: Number(data.duration),
      termIds: data.termIds.split(','),
      locationId: globalLocation?._id,
      price: Number(data.price),
      allLevel: data.levelId === 'all'
    };
    if (payload.allLevel) {
      delete payload.levelId;
    }
    createClassTemplate(payload)
      .then(() => {
        setClassTemplate({ ...initClassTemplate });
        reset(initClassTemplate);
        toast.success('Create class template successfully');
        onSuccess();
      })
      .catch((error: any) => {
        toast.error(
          error?.response?.data?.message || 'Failed to create class template'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="classTemplateAddContainer">
      <div
        className={`overlay ${open ? 'active' : ' '}`}
        onClick={onClose}
      ></div>
      <div className={`classTemplateAddForm ${open ? 'active' : ' '}`}>
        <div className="classTemplateAddForm__header">
          <HiArrowLeft
            size={24}
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          />
          <p>Add template</p>
        </div>
        <div className="classTemplateAddForm__content">
          <AppCard>
            <div className="classTemplateAddForm__content-wrapper">
              <AppCardHeader
                title="Template"
                suffix={
                  <AppToggle
                    value={classTemplate.active}
                    onChange={() => {
                      setClassTemplate({
                        ...classTemplate,
                        active: !classTemplate.active
                      });
                    }}
                  />
                }
              />
              <AppCardContent className="classTemplateAddForm__content-template">
                <AppInput
                  label="Name*"
                  {...register('name')}
                  message={{
                    type: 'error',
                    text: errors?.name?.message || ''
                  }}
                />
                <div className="classTemplateAddForm__content-duration">
                  <AppSelect
                    label="Duration (mins)*"
                    options={CLASS_TEMPLATE_DURATIONS}
                    {...register('duration')}
                    value={classTemplate.duration.toString()}
                    onChange={handleChange}
                    message={{
                      type: 'error',
                      text: errors?.duration?.message || ''
                    }}
                    searchable={false}
                  />
                  <AppInput
                    label="Price*"
                    {...register('price')}
                    onChange={handleChange}
                    message={{
                      type: 'error',
                      text: errors?.price?.message || ''
                    }}
                  />
                </div>
              </AppCardContent>
              <AppCardContent className="classTemplateAddForm__content-template">
                <AppSelect
                  label="Session*"
                  options={terms}
                  value={classTemplate.termIds}
                  {...register('termIds')}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.termIds?.message || ''
                  }}
                  multiValue
                />
                <div className="classTemplateAddForm__content-duration">
                  <AppSelect
                    label="Level*"
                    options={levels}
                    {...register('levelId')}
                    value={classTemplate.levelId}
                    onChange={handleChange}
                    message={{
                      type: 'error',
                      text: errors?.levelId?.message || ''
                    }}
                  />
                  <div className="pick-color-container">
                    <div
                      className="pick-color"
                      style={{
                        backgroundColor: selectedLevel?.colorCode || '#034EA2'
                      }}
                    ></div>
                  </div>
                </div>
              </AppCardContent>
              <AppCardContent className="classTemplateAddForm__content-area">
                <AppTextArea
                  label="Description"
                  {...register('description')}
                  value={classTemplate.description}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.description?.message || ''
                  }}
                />
              </AppCardContent>
            </div>
          </AppCard>
        </div>
        <div className="classTemplateAddForm__actions">
          <AppButton
            onClick={handleSubmit(onSubmit)}
            type="submit"
            isLoading={loading}
          >
            Save
          </AppButton>
          <AppButton variant="secondary" onClick={onClose}>
            Cancel
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default ClassTemplateAddForm;
