export enum LOG_EVENT_TYPE {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete'
}

export enum ACTIVITY_EVENT_TYPE {
  BOOK_CLASS = 'book_class',
  CHECK_IN = 'check_in',
  ABSENCE = 'absence',
  TIME_CHANGE = 'time_change',
  DATE_CHANGE = 'date_change',
  RECURRENCE_CHANGE = 'recurrence_change',
  STATUS_CHANGE = 'status_change'
}
