import React, { useEffect } from 'react';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import {
  ERROR_MESSAGE_DATE,
  ERROR_MESSAGE_TIME,
  FIXED_PAGE,
  FIXED_SIZE,
  FORMAT_END_OF_DATE,
  FORMAT_START_OF_DATE,
  FORMAT_TIME,
  REPORT_PATH,
  REPORT_PROGRAM_TYPE_OPTIONS
} from '../constant';
import AppDatePicker from 'common/components/AppDatePicker';
import AppTimePicker from 'common/components/AppTimePicker';
import AppSelect from 'common/components/AppSelect';
import AppButton from 'common/components/AppButton';
import ReportSelection from '../components/ReportSelection';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { useToast } from 'context/ToastContext';
import { useBrandLocation } from 'context/BrandLocationContext';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { IInstructor } from 'common/interfaces/instructor.interface';
import { IOption } from 'common/interfaces';
import dayjs, { Dayjs } from 'dayjs';
import { sortBy, uniq } from 'lodash';
import { getInstructorList } from 'services/instructor.service';
import {
  exportTimetableByTeacher,
  getTimetableByTeacherReport
} from 'services/report.service';
import {
  convertToUnixTime,
  getCurrentUserTimeZone
} from 'common/helpers/time.helper';
import { getUnixTimeOfValue } from 'helpers/time.hepler';
import {
  ITimetableByTeacher,
  ITimetableByTeacherInstructor
} from 'common/interfaces/report.interface';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import { getEnrolmentType } from 'common/helpers/index.helper';
import { BOOKING_TYPE } from 'common/enums/classBooking.enum';

import './desktop.scss';
import { HiOutlineDocumentArrowDown } from 'react-icons/hi2';

interface IFilter {
  dateFrom: string;
  dateTo: string;
  timeFrom: string;
  timeTo: string;
  programType: string;
  instructorIds: Array<string>;
}

const initFilter: IFilter = {
  dateFrom: '',
  dateTo: '',
  timeFrom: '',
  timeTo: '',
  programType: '',
  instructorIds: []
};

const ReportTimetableByTeacher = () => {
  const toast = useToast();
  const { selectedLocation: __globalLocation } = useBrandLocation();

  const dateError = React.useRef<string>('');
  const timeError = React.useRef<string>('');

  const [isGenerated, setIsGenerated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingExport, setLoadingExport] = React.useState<boolean>(false);

  const [selectedWeekDay, setSelectedWeekDay] = React.useState<Dayjs | null>(
    null
  );

  const [timetableByTeachers, setTimetableByTeachers] = React.useState<
    Array<ITimetableByTeacher>
  >([]);

  // Search section
  const [dateFrom, setDateFrom] = React.useState<string>(
    dayjs().format(FORMAT_START_OF_DATE)
  );
  const [dateTo, setDateTo] = React.useState<string>('');
  const [timeFrom, setTimeFrom] = React.useState<string>('');
  const [timeTo, setTimeTo] = React.useState<string>('');
  const [instructorIds, setInstructorIds] = React.useState<Array<string>>([]);
  const [programType, setProgramType] = React.useState<CLASS_TYPES | ''>('');

  const filter = React.useRef<IFilter>(initFilter);

  const [instructors, setInstructors] = React.useState<Array<IInstructor>>([]);

  const __instructorOptions: Array<IOption> = React.useMemo(() => {
    if (instructors.length === 0) return [];
    return sortBy(
      instructors.map((instructor) => ({
        label: instructor.lastName + ', ' + instructor.firstName,
        value: instructor._id
      })),
      'label'
    );
  }, [instructors]);

  const __instructorPlaceholderText: string = React.useMemo(() => {
    return instructorIds.length === 0
      ? 'Select option'
      : `${instructorIds.length} selected`;
  }, [instructorIds]);

  const __isDisableGenerateButton: boolean = React.useMemo(() => {
    return (
      !dateFrom ||
      !dateTo ||
      !programType ||
      instructorIds.length === 0 ||
      !!dateError.current ||
      !!timeError.current
    );
  }, [dateFrom, dateTo, programType, instructorIds, dateError, timeError]);

  const __isDisableChevronLeft: boolean = React.useMemo(() => {
    if (!selectedWeekDay) return true;
    return !selectedWeekDay.isAfter(filter.current.dateFrom, 'day');
    // eslint-disable-next-line
  }, [selectedWeekDay, filter.current.dateFrom]);

  const __isDisableChevronRight: boolean = React.useMemo(() => {
    if (!selectedWeekDay) return true;
    return !selectedWeekDay.isBefore(filter.current.dateTo, 'day');
    // eslint-disable-next-line
  }, [selectedWeekDay, filter.current.dateTo]);

  const __uniqueInstructors: ITimetableByTeacherInstructor[] =
    React.useMemo(() => {
      return timetableByTeachers.reduce(
        (accumulator: ITimetableByTeacherInstructor[], currentItem) => {
          currentItem.instructors.forEach(
            (instructorEntry: ITimetableByTeacherInstructor) => {
              const existingIndex = accumulator.findIndex(
                (item) => item.instructor._id === instructorEntry.instructor._id
              );
              if (existingIndex === -1) {
                accumulator.push(instructorEntry);
              }
            }
          );
          return accumulator;
        },
        []
      );
    }, [timetableByTeachers]);

  const renderDataByInstructor = (
    startHour: string,
    endHour: string,
    instructorId: string
  ) => {
    const foundTimetableByTeacher: ITimetableByTeacher | undefined =
      timetableByTeachers.find(
        (timetableByTeacher) =>
          timetableByTeacher.time.startHour === startHour &&
          timetableByTeacher.time.endHour === endHour
      );
    if (!foundTimetableByTeacher) return <td>--</td>;

    const foundInstructor: ITimetableByTeacherInstructor | undefined =
      foundTimetableByTeacher.instructors.find(
        (instructor) => instructor.instructor._id === instructorId
      );
    if (!foundInstructor) return <td>--</td>;

    return (
      <td key={instructorId}>
        <span className="classDetails">
          {`${formatData(foundInstructor?.level)} ${formatData(
            foundInstructor?.area
          )} ${formatData(
            foundInstructor?.occupied?.toString()?.padStart(2, '0')
          )}/${formatData(
            foundInstructor?.capacity?.toString()?.padStart(2, '0')
          )} ${formatData(foundInstructor?.averageAge)}y`}
        </span>
        <br />
        {foundInstructor.students.map((student) => {
          return (
            <React.Fragment key={student._id}>
              - {formatData(student?.lastName)},{' '}
              {formatData(student?.firstName)}
              {'; '}
              {student?.medicalCondition?.length && (
                <span className="classDetails">
                  [ {student.medicalCondition.join(', ')} ];{' '}
                </span>
              )}
              {formatData(student.memberId)}
              {'; '}
              {dayjs().diff(student?.dob, 'year')}.
              {dayjs().diff(student?.dob, 'month') % 12}y{'; '}
              {formatDate(student?.firstStartDate, 'slash')}
              {((student?.enrolmentType !== BOOKING_TYPE.ONGOING_CLASS &&
                student?.enrolmentType !== BOOKING_TYPE.HOLIDAY_PROGRAM) ||
                (student?.absence?.certificateMedicals &&
                  student?.absence?.certificateMedicals?.length > 0)) &&
                `;  ${getEnrolmentType(student?.enrolmentType)} `}
              {student?.absence && `; absent`}
              {student?.absence?.certificateMedicals &&
                student?.absence?.certificateMedicals?.length > 0 &&
                ` MC`}
              <br />
            </React.Fragment>
          );
        })}
      </td>
    );
  };

  const handleChangeDateFrom = (value: Dayjs | null) => {
    setDateFrom(value?.format(FORMAT_START_OF_DATE) || '');
    if (value?.isAfter(dayjs(dateTo))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeDateTo = (value: Dayjs | null) => {
    setDateTo(value?.format(FORMAT_END_OF_DATE) || '');
    if (value?.isBefore(dayjs(dateFrom))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };

  const handleChangeTimeFrom = (value: Dayjs | null) => {
    setTimeFrom(value?.format(FORMAT_TIME) || '');
    if (value?.isAfter(dayjs(timeTo))) {
      timeError.current = ERROR_MESSAGE_TIME;
    } else {
      timeError.current = '';
    }
  };
  const handleChangeTimeTo = (value: Dayjs | null) => {
    setTimeTo(value?.format(FORMAT_TIME) || '');
    if (value?.isBefore(dayjs(timeFrom))) {
      timeError.current = ERROR_MESSAGE_TIME;
    } else {
      timeError.current = '';
    }
  };

  const handleChangeProgramType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setProgramType(event.target.value as CLASS_TYPES);
  };

  const handleToggleInstructor = (value: string) => {
    const newInstructorIds = [...instructorIds];
    const findIndex = newInstructorIds.findIndex(
      (levelId) => levelId === value
    );
    if (findIndex === -1) {
      newInstructorIds.push(value);
    } else {
      newInstructorIds.splice(findIndex, 1);
    }
    setInstructorIds(newInstructorIds);
  };

  const handleSelectAllInstructor = () => {
    const ids = instructors.map((instructor) => instructor._id);
    if (instructorIds.length === instructors.length) {
      setInstructorIds([]);
    } else {
      setInstructorIds(uniq(ids.concat(instructorIds)));
    }
  };

  const handleChangeSelectedWeekDay = (weekday: Dayjs) => {
    setSelectedWeekDay(weekday);
    if (!!weekday) {
      fetchTimetableByTeacher(
        weekday.format(FORMAT_START_OF_DATE),
        weekday.format(FORMAT_END_OF_DATE)
      );
    }
  };

  const handleExport = async () => {
    if (!__globalLocation?._id) return;
    setLoadingExport(true);
    try {
      const currentTimezone: string = getCurrentUserTimeZone();

      const result = await exportTimetableByTeacher(
        convertToUnixTime(
          selectedWeekDay?.format(FORMAT_START_OF_DATE) || dateFrom
        ),
        convertToUnixTime(dateTo),
        getUnixTimeOfValue(dayjs(filter.current.timeFrom).format('HH:mm:ss')),
        dayjs(
          dayjs(dateTo).format('YYYY-MM-DD') +
            dayjs(filter.current.timeTo).format('THH:mm:ss')
        ).valueOf(),
        __globalLocation._id,
        filter.current.programType as CLASS_TYPES,
        filter.current.instructorIds,
        currentTimezone || 'Australia/Sydney'
      );

      const url = window.URL.createObjectURL(
        new Blob([result?.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `TimeTable ${formatDate(dateFrom)} ${formatDate(dateTo)}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'Failed to export timetable by teacher'
      );
    } finally {
      setLoadingExport(false);
    }
  };

  const handleGenerate = () => {
    filter.current = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      timeFrom: !timeFrom ? dayjs().format(FORMAT_START_OF_DATE) : timeFrom,
      timeTo: !timeTo ? dayjs().format(FORMAT_END_OF_DATE) : timeTo,
      programType: programType,
      instructorIds: instructorIds
    };
    setSelectedWeekDay(dayjs(filter.current.dateFrom));
    fetchTimetableByTeacher(
      dayjs(filter.current.dateFrom).format(FORMAT_START_OF_DATE),
      dayjs(filter.current.dateFrom).format(FORMAT_END_OF_DATE)
    );
  };

  const fetchTimetableByTeacher = async (dateFrom: string, dateTo: string) => {
    if (!__globalLocation?._id) return;

    setLoading(true);

    try {
      const result = await getTimetableByTeacherReport(
        convertToUnixTime(dateFrom),
        convertToUnixTime(dateTo),
        getUnixTimeOfValue(dayjs(filter.current.timeFrom).format('HH:mm:ss')),
        dayjs(
          dayjs(dateTo).format('YYYY-MM-DD') +
            dayjs(filter.current.timeTo).format('THH:mm:ss')
        ).valueOf(),
        __globalLocation._id,
        filter.current.programType as CLASS_TYPES,
        filter.current.instructorIds
      );
      setTimetableByTeachers(result?.data?.data);
      setIsGenerated(true);
    } catch (error: any) {
      setTimetableByTeachers([]);
      toast.error(
        error?.response?.data?.message || 'Failed to get timetable by teacher'
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchInitData = React.useCallback(async () => {
    try {
      const result = await getInstructorList({
        page: FIXED_PAGE,
        limit: FIXED_SIZE,
        status: 'active'
      });
      setInstructors(result.data);
    } catch (error: any) {
      setInstructors([]);
      toast.error(error?.response?.data?.message || 'Failed to get init data');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  useEffect(() => {}, [setTimetableByTeachers]);

  return (
    <main id="reportTimetableByTeacher" className="reportTimetableByTeacher">
      {loadingExport && (
        <div className="reportTimetableByTeacher__loadingExport">
          <AppLoadingContainer />
        </div>
      )}

      <AppBreadCrumb
        items={[
          { name: 'Reporting', href: REPORT_PATH },
          { name: 'Timetable by Teacher' }
        ]}
      />
      <div className="layoutContainer reportTimetableByTeacher__wrapper">
        <section className="reportTimetableByTeacher__search">
          <div className="reportTimetableByTeacher__search__fields">
            <AppDatePicker
              size="small"
              label="Date from"
              value={dayjs(dateFrom)}
              onChange={handleChangeDateFrom}
            />
            <AppDatePicker
              size="small"
              label="Date to"
              value={dayjs(dateTo)}
              onChange={handleChangeDateTo}
              message={{
                type: 'error',
                text: dateError.current
              }}
            />
            <AppTimePicker
              size="small"
              label="Time from"
              value={dayjs(timeFrom)}
              onChange={handleChangeTimeFrom}
            />
            <AppTimePicker
              size="small"
              label="Time to"
              value={dayjs(timeTo)}
              onChange={handleChangeTimeTo}
              message={{
                type: 'error',
                text: timeError.current
              }}
            />
            <ReportSelection
              label="Instructor"
              placeholder={__instructorPlaceholderText}
              options={__instructorOptions}
              onSelect={handleToggleInstructor}
              onSelectAll={handleSelectAllInstructor}
              selectedIds={instructorIds}
              isSearch={true}
            />
            <AppSelect
              searchable={false}
              inputSize="small"
              label="Program Type"
              placeholder="Select option"
              options={REPORT_PROGRAM_TYPE_OPTIONS}
              value={programType}
              onChange={handleChangeProgramType}
            />
          </div>
          <AppButton
            variant="primary"
            buttonSize="small"
            disabled={__isDisableGenerateButton}
            onClick={handleGenerate}
          >
            generate
          </AppButton>
        </section>

        {!isGenerated && loading && <AppLoadingContainer />}

        {isGenerated && (
          <section className="reportTimetableByTeacher__content">
            <div className="reportTimetableByTeacher__content__header">
              <p className="reportTimetableByTeacher__content__header--title">
                Classes timetable by teacher
              </p>
              <div className="reportTimetableByTeacher__content__header--actions">
                <div className="exportButton" onClick={handleExport}>
                  <HiOutlineDocumentArrowDown size={22} />
                  export
                </div>
              </div>
            </div>

            {loading && <AppLoadingContainer />}

            <div className="reportTimetableByTeacher__content__table">
              <table>
                <thead>
                  <tr>
                    <th>Class Time</th>
                    {__uniqueInstructors.map((uniqueInstructor) => {
                      return (
                        <th key={uniqueInstructor.instructor._id}>
                          {uniqueInstructor.instructor.firstName}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {timetableByTeachers.length === 0 && selectedWeekDay && (
                    <div className="reportTimetableByTeacher__content__table--noData">
                      There is no schedule
                    </div>
                  )}
                  {timetableByTeachers.map(
                    (timetableByTeacher, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            {dayjs(timetableByTeacher.time.startTime)
                              .tz(
                                __globalLocation?.timezone || 'Australia/Sydney'
                              )
                              .format('HH:mm')}{' '}
                          </td>
                          {__uniqueInstructors.map((uniqueInstructor) => {
                            return renderDataByInstructor(
                              timetableByTeacher.time.startHour,
                              timetableByTeacher.time.endHour,
                              uniqueInstructor.instructor._id
                            );
                          })}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>

            {selectedWeekDay && (
              <div className="reportTimetableByTeacher__content__date">
                <button
                  className="reportTimetableByTeacher__content__date--arrow"
                  onClick={() =>
                    handleChangeSelectedWeekDay(
                      selectedWeekDay.subtract(1, 'day')
                    )
                  }
                  disabled={__isDisableChevronLeft}
                >
                  <HiChevronLeft size={18} />
                </button>
                <p className="reportTimetableByTeacher__content__date--text">
                  {selectedWeekDay.format('ddd, MMM D YYYY')}
                </p>
                <button
                  className="reportTimetableByTeacher__content__date--arrow"
                  onClick={() =>
                    handleChangeSelectedWeekDay(selectedWeekDay.add(1, 'day'))
                  }
                  disabled={__isDisableChevronRight}
                >
                  <HiChevronRight size={18} />
                </button>
              </div>
            )}
          </section>
        )}
      </div>
    </main>
  );
};

export default ReportTimetableByTeacher;
