import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppCard from 'common/components/AppCard';
import AppDatePicker from 'common/components/AppDatePicker';
import AppInputSearch from 'common/components/AppInputSearch';
import useDebounce from 'common/hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { HiChevronDown, HiMagnifyingGlass } from 'react-icons/hi2';
import { useBrandLocation } from 'context/BrandLocationContext';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { getStudentActivities } from 'services/log.service';
import { IGroupActivity, ILogSearch } from 'common/interfaces/log.interface';
import GroupActivity from './components/GroupActivity';
import dayjs from 'dayjs';
import AppButton from 'common/components/AppButton';
import AppInput from 'common/components/AppInput';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import './desktop.scss';

const INIT_FETCH_PAGE = 1;
const INIT_SCROLL_PAGE = 2;
const LIMIT_ON_LOAD = 20;

const initialSearch: ILogSearch = {
  keyword: '',
  what: '',
  fromDate: '',
  toDate: ''
};

function combineGroupActivitiesByDate(
  currentGroups: Array<IGroupActivity>,
  newGroups: Array<IGroupActivity>
): Array<IGroupActivity> {
  if (
    currentGroups?.[currentGroups.length - 1]?.date === newGroups?.[0]?.date
  ) {
    const middle = [
      {
        date: newGroups[0].date,
        activities: [
          ...currentGroups[currentGroups.length - 1].activities,
          ...newGroups[0].activities
        ]
      }
    ];
    return [...currentGroups.slice(0, -1), ...middle, ...newGroups.slice(1)];
  } else {
    return [...currentGroups, ...newGroups];
  }
}

const ActivityList = () => {
  const { selectedLocation: globalLocation } = useBrandLocation();

  const [loading, setLoading] = useState<boolean>(true);

  const [activities, setActivities] = useState<Array<IGroupActivity>>([]);
  const [pageIndex, setPageIndex] = useState<number>(INIT_SCROLL_PAGE);
  const [pageTotal, setPageTotal] = useState<number>(-1);

  const [openSearch, setOpenSearch] = useState<boolean>(false);

  const [search, setSearch] = useState<ILogSearch>(initialSearch);

  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const debouncedSearchKeyword = useDebounce<string>(searchKeyword);

  // Search input
  const onChangeSearchKeyword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchKeyword(event.target.value);
  };
  const onClearSearchKeyword = () => {
    setSearchKeyword('');
  };
  // Search component
  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({ ...search, [event.target.name]: event.target.value });
  };
  const onClearSearch = (key: string) => {
    setSearch({ ...search, [key]: '' });
  };
  const onChangeFromDate = (value: dayjs.Dayjs | null) => {
    setSearch({ ...search, fromDate: value?.format('YYYY-MM-DD') || '' });
  };
  const onChangeToDate = (value: dayjs.Dayjs | null) => {
    setSearch({ ...search, toDate: value?.format('YYYY-MM-DD') || '' });
  };

  const onToggleSearch = () => {
    setOpenSearch(!openSearch);
  };
  const onCloseSearch = () => {
    setOpenSearch(false);
  };

  const onSearch = () => {
    setSearchKeyword(search.keyword);
    fetchData(search.keyword, search.what, search?.fromDate, search?.toDate);
    onCloseSearch();
  };
  const onReset = () => {
    setSearch(initialSearch);
    setSearchKeyword('');
    fetchData('', '', undefined, undefined);
    onCloseSearch();
  };

  const fetchData = async (
    keyword: string,
    what: string,
    fromDate?: string,
    toDate?: string
  ) => {
    setLoading(true);
    try {
      const result = await getStudentActivities(
        globalLocation?._id || '',
        INIT_FETCH_PAGE,
        LIMIT_ON_LOAD,
        keyword,
        what,
        !!fromDate ? convertToUnixTime(fromDate) : undefined,
        !!toDate
          ? convertToUnixTime(toDate)
          : convertToUnixTime(dayjs().format('YYYY-MM-DD'))
      );
      setActivities(result.data.data.data);
      setPageTotal(result.data.data.total);
    } catch (error) {
      setActivities([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data init
  useEffect(() => {
    fetchData(search.keyword, search.what, search.fromDate, search.toDate);
    // eslint-disable-next-line
  }, []);

  // Fetch data on change location
  useEffect(() => {
    setPageIndex(INIT_SCROLL_PAGE);
    fetchData(search.keyword, search.what, search.fromDate, search.toDate);
    // eslint-disable-next-line
  }, [globalLocation?._id]);

  // Fetch data on search
  useEffect(() => {
    setPageIndex(INIT_SCROLL_PAGE);
    if (searchKeyword !== search.keyword) {
      fetchData(searchKeyword, search.what, search.fromDate, search.toDate);
      setSearch({ ...search, keyword: searchKeyword });
    }
    // eslint-disable-next-line
  }, [debouncedSearchKeyword]);

  // ON SCROLL
  const fetchDataOnScroll = async () => {
    setLoading(true);
    try {
      const result = await getStudentActivities(
        globalLocation?._id || '',
        pageIndex,
        LIMIT_ON_LOAD,
        search.keyword,
        search.what,
        !!search.fromDate ? convertToUnixTime(search.fromDate) : undefined,
        !!search.toDate
          ? convertToUnixTime(search.toDate)
          : convertToUnixTime(dayjs().format('YYYY-MM-DD'))
      );
      setActivities(
        combineGroupActivitiesByDate(activities, result.data.data.data)
      );
      setPageIndex(pageIndex + 1);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop !==
          document.documentElement.offsetHeight ||
        loading
      ) {
        return;
      }
      fetchDataOnScroll();
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line
  }, [loading]);

  // CALL ON SCROLL ONE MORE IF NOT ENOUGH HEIGHT
  useEffect(() => {
    if (
      document.documentElement.scrollHeight <= window.innerHeight &&
      activities.reduce(
        (activity, current) => activity + current.activities.length,
        0
      ) < pageTotal &&
      pageIndex === INIT_SCROLL_PAGE
    ) {
      fetchDataOnScroll();
    }
    // eslint-disable-next-line
  }, [activities, pageTotal, pageIndex]);

  return (
    <main className="activityListPage">
      {/* MAIN */}
      <AppBreadCrumb items={[{ name: 'Activity', href: '/activity' }]} />
      <div className="layoutContainer activityList">
        <AppCard>
          <div className="activityList__main">
            <div className="activityList__main-header">
              <h2 className="activityList__main-header-title">Activity(s)</h2>
              <div className="activityList__main-header-search">
                <div className="searchContainer">
                  <AppInputSearch
                    type="text"
                    placeholder="Name"
                    name="keyword"
                    value={searchKeyword}
                    onChange={onChangeSearchKeyword}
                    onClearSearch={onClearSearchKeyword}
                    startIcon={<HiMagnifyingGlass />}
                    endIcon={
                      <div
                        className={`arrowIcon ${openSearch ? 'active' : ''}`}
                        onClick={onToggleSearch}
                      >
                        <HiChevronDown />
                      </div>
                    }
                  />
                  <div
                    className={`searchComponent ${openSearch ? 'active' : ''}`}
                  >
                    <AppInput
                      inputSize="small"
                      label="What"
                      name="what"
                      value={search.what}
                      onChange={onChangeSearch}
                      onClearSearch={() => onClearSearch('what')}
                    />
                    <AppInput
                      inputSize="small"
                      label="Who"
                      name="keyword"
                      value={search.keyword}
                      onChange={onChangeSearch}
                      onClearSearch={() => onClearSearch('keyword')}
                    />
                    <AppDatePicker
                      size="small"
                      label="Date"
                      value={dayjs(search.fromDate)}
                      onChange={onChangeFromDate}
                      maxDate={dayjs(search.toDate)}
                      disableFuture
                    />
                    <AppDatePicker
                      size="small"
                      label="Date"
                      value={dayjs(search.toDate)}
                      onChange={onChangeToDate}
                      minDate={dayjs(search.fromDate)}
                      disableFuture
                    />

                    <div className="searchComponent__actions">
                      <AppButton
                        variant="secondary"
                        buttonSize="small"
                        isLoading={loading}
                        onClick={onReset}
                      >
                        Reset
                      </AppButton>
                      <AppButton
                        variant="primary"
                        buttonSize="small"
                        isLoading={loading}
                        onClick={onSearch}
                      >
                        Search
                      </AppButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="activityList__main-blocks">
              {activities?.length > 0 &&
                activities.map(
                  (groupActivity: IGroupActivity, index: number) => {
                    return (
                      <GroupActivity
                        key={index}
                        groupActivity={groupActivity}
                      />
                    );
                  }
                )}
            </div>

            {loading && <AppLoadingContainer />}
          </div>
        </AppCard>
      </div>
    </main>
  );
};

export default ActivityList;
