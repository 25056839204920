import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import { HiXMark } from 'react-icons/hi2';
import './styles.scss';
import { useEffect, useState } from 'react';

interface IFileSliderProps {
  open: boolean;
  key?: string;
  onClose: () => void;
  documents: Array<Record<string, any>>;
  initialSlide?: number;
  title?: string;
}

const FileViwer = (props: IFileSliderProps) => {
  const { documents, open, onClose, initialSlide = 0, key = '' } = props;
  const [title, setTitle] = useState<string>('');
  useEffect(() => {
    setTitle(documents[initialSlide].title || '');
  }, [documents, initialSlide]);

  if (!open) return null;

  return (
    <div className="file-viewer">
      <div className="c__documents-slider" id={key} key={key}>
        <div className="c__documents-slider-header">
          <div onClick={onClose}>
            <span className="header-container">
              <HiXMark size={20} />
              <span className="document-title-container">{title}</span>
            </span>
          </div>
        </div>
        <Swiper
          initialSlide={initialSlide}
          spaceBetween={0}
          slidesPerView={1}
          navigation={true}
          noSwiping
          loop
          effect="slide"
          modules={[Navigation]}
          onSlideChange={(ev) => {
            console.log(ev);
            setTitle(documents[ev.realIndex].title || '');
          }}
        >
          {documents.map((item) => {
            return (
              <SwiperSlide key={item.source}>
                {item.source.includes('pdf') ? (
                  <embed
                    style={{
                      width: '100%',
                      minHeight: '100%'
                    }}
                    src={item.source || ''}
                  />
                ) : (
                  <img src={item.source} alt="document" />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default FileViwer;
