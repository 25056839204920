import { memo, useCallback, useEffect, useRef, useState } from 'react';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import { useToast } from 'context/ToastContext';
import { formatData } from 'common/helpers/dataFormat.helper';
import { useParams } from 'react-router-dom';
import { useBrandLocation } from 'context/BrandLocationContext';

import './desktop.scss';
import { getMedicalConditionsByStudentId } from 'services/healthQuestionnaire.service';
import { BeatLoader } from 'react-spinners';
import FileViwer from 'components/FileViewer';

interface Props {
  studentId?: string;
}

const MedicalConditions = ({ studentId }: Props) => {
  const params = useParams();
  const toast = useToast();
  const { selectedLocation } = useBrandLocation();

  const [loadingAPI, setLoadingAPI] = useState<boolean>(false);
  const [medicalConditions, setMedicalConditions] = useState<any[]>([]);
  const [openSlider, setOpenSlider] = useState(false);

  const initialSlide = useRef<number>(0);
  const [selectedDocuments, setSelectedDocuments] = useState<
    Record<string, any>[]
  >([]);

  const fetchData = useCallback(async () => {
    if (!studentId) return;

    try {
      const medicalConditions = await getMedicalConditionsByStudentId(
        studentId
      );
      setMedicalConditions(medicalConditions?.data?.data || []);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Failed to get level');
    } finally {
      setLoadingAPI(false);
    }
    // eslint-disable-next-line
  }, [params.id, selectedLocation]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!openSlider) {
      document.body.style.overflow = 'auto';
    } else {
      document.documentElement.style.cssText = 'scrollbar-gutter: stable';
      document.body.style.overflow = 'hidden';
    }
  }, [openSlider]);

  const handleOpenSlider = useCallback(() => {
    setOpenSlider(true);
  }, []);

  const handleCloseSlider = useCallback(() => {
    setOpenSlider(false);
  }, []);

  const handleViewDocument = (itemIndex: number, documentIndex: number) => {
    initialSlide.current = documentIndex;
    setSelectedDocuments(
      medicalConditions[itemIndex]?.medicalCertificateUrl.map((url: string) => {
        return { source: url, title: decodeURI(url).split('/').slice(-1)[0] };
      })
    );
    handleOpenSlider();
  };

  return (
    <div className="medical_condition__wrapper">
      <AppCard>
        <AppCardHeader title="Medical Conditions" />
        <AppCardContent>
          <div className="medical_condition__wrapper__card">
            {loadingAPI ? (
              <div className="pageLoading">
                <div className="pageLoading-main">
                  <BeatLoader />
                </div>
              </div>
            ) : null}
            {medicalConditions.length > 0 ? (
              <>
                {medicalConditions.map((item, itemIndex) => (
                  <div key={item._id}>
                    <AppCardContentItem
                      key={item.id}
                      subtitle={
                        item?.question?.medicalCondition +
                        ' (' +
                        item?.question?.medicalConditionCode +
                        ')'
                      }
                      title={formatData(item.medicalNote)}
                    />
                    <br />
                    <AppCardContentItem subtitle="Files" />
                    {item?.medicalCertificateUrl?.length > 0 ? (
                      item?.medicalCertificateUrl?.map(
                        (certificateURL: string, index: number) => (
                          <div className="" key={index}>
                            <span
                              className="document-link"
                              onClick={() => {
                                handleViewDocument(itemIndex, index);
                              }}
                            >
                              {
                                decodeURI(certificateURL)
                                  .split('/')
                                  .slice(-1)[0]
                              }
                            </span>
                          </div>
                        )
                      )
                    ) : (
                      <div>No files</div>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <div>No medical conditions.</div>
            )}
          </div>

          {openSlider && (
            <FileViwer
              open={openSlider}
              onClose={handleCloseSlider}
              documents={selectedDocuments}
              initialSlide={initialSlide.current}
            />
          )}
        </AppCardContent>
      </AppCard>
    </div>
  );
};

export default memo(MedicalConditions);
