import { createColumnHelper } from '@tanstack/react-table';
import AppButton from 'common/components/AppButton';
import AppCard from 'common/components/AppCard';
import AppTable from 'common/components/AppTable';
import { DISCOUNT_TYPE } from 'common/enums';
import { PERMISSION } from 'common/enums/permission.enum';
import {
  formatData,
  formatDate,
  formatMoneySign,
  formatPercentNumber
} from 'common/helpers/dataFormat.helper';
import { IVoucher } from 'common/interfaces/voucher.interface';
import PermissionWrapper from 'components/PermissionWrapper';
import { useAuth } from 'context/AuthContext';
import { useBrandLocation } from 'context/BrandLocationContext';
import { useToast } from 'context/ToastContext';
import dayjs from 'dayjs';
import ActionPopper from 'pages/vouchers/components/ActionPopper';
import AddEditVoucher from 'pages/vouchers/components/AddEditVoucher';
import ApplyToMember from 'pages/vouchers/components/ApplyToMember';
import SendToMember from 'pages/vouchers/components/SendToMember';
import VoucherDeleteModal from 'pages/vouchers/components/VoucherDeleteModal';
import { MODE } from 'pages/vouchers/constant';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { getVouchers } from 'services/voucher.service';
import './desktop.scss';
import { VOUCHER_DURATION } from 'common/enums/voucher.enum';
import AppCopyButton from 'common/components/AppCopyButton';

interface IVoucherListProps {
  discountType: DISCOUNT_TYPE;
}

const VoucherList = (props: IVoucherListProps) => {
  const { discountType } = props;
  const columnHelper = createColumnHelper<IVoucher>();
  const navigate = useNavigate();
  const toast = useToast();
  const { hasPermission } = useAuth();
  const { selectedLocation } = useBrandLocation();

  const [isOpenAddEditForm, setIsOpenAddEditForm] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [isOpenApplyToMember, setIsOpenApplyToMember] =
    useState<boolean>(false);
  const [isOpenSendToMember, setIsOpenSendToMember] = useState<boolean>(false);
  const [selectedVoucher, setSelectedVoucher] = useState<IVoucher | null>(null);
  const [mode, setMode] = useState<MODE>(MODE.ADD);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageTotal, setPageTotal] = useState<number>(-1);
  const [data, setData] = useState<IVoucher[]>([]);

  const columns = [
    columnHelper.accessor('code', {
      header: () => <span>code name</span>,
      cell: (info) => {
        return (
          <div className="voucher-code-cell">
            <span>{formatData(info.getValue())}</span>
            <AppCopyButton textToCopy={info.getValue()} />
          </div>
        );
      }
    }),
    columnHelper.accessor('quantity', {
      header: () => <span>quantity</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('redeemCount', {
      header: () => <span>code in used</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('startDate', {
      header: () => <span>start date</span>,
      cell: (info) => formatDate(info.getValue())
    }),
    columnHelper.accessor('endDate', {
      header: () => <span>end date</span>,
      cell: (info) => formatDate(info.getValue())
    }),
    columnHelper.accessor('discountValue', {
      header: () => <span>value</span>,
      cell: (info) =>
        info.row.original?.discountType === DISCOUNT_TYPE.PERCENTAGE
          ? formatPercentNumber(info.getValue())
          : formatMoneySign(info.getValue())
    }),
    columnHelper.accessor('duration', {
      header: () => <span>duration</span>,
      cell: (info) => {
        return (
          <div>
            {info.row.original?.duration === VOUCHER_DURATION.TIME_RANGE ? (
              <p>
                {formatDate(info.row.original?.startDateFree)}
                {info.row.original?.endDateFree && (
                  <span>
                    - <br />
                    {formatDate(info.row.original.endDateFree)}
                  </span>
                )}
              </p>
            ) : (
              `${formatData(info.row.original?.numberOfLesson)} lessons`
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor('createdAt', {
      header: () => <span>Created Time</span>,
      cell: (info) =>
        formatData(dayjs(info.getValue()).format('DD-MM-YYYY HH:mm'))
    }),
    columnHelper.accessor('createdBy', {
      header: () => <span>Created By</span>,
      cell: (info) => formatData(info.getValue()?.firstName)
    }),
    columnHelper.accessor('_id', {
      header: () => (
        <span
          style={{
            display: 'block',
            textAlign: 'center'
          }}
        >
          Action
        </span>
      ),
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <ActionPopper
              onViewVoucher={
                hasPermission(PERMISSION.VIEW_DETAIL_VOUCHER)
                  ? () => handleViewVoucher(info.row.original._id)
                  : undefined
              }
              onSendToMember={
                hasPermission(PERMISSION.SEND_VOUCHER) &&
                info.row.original.quantity - info.row.original.redeemCount > 0
                  ? () => handleSendToMember(info.row.original)
                  : undefined
              }
              onDeleteVoucher={
                hasPermission(PERMISSION.DELETE_VOUCHER)
                  ? () => handleDeleteVoucher(info.row.original)
                  : undefined
              }
              onApplyToMember={
                hasPermission(PERMISSION.BULK_APPLY_VOUCHER) &&
                info.row.original.quantity - info.row.original.redeemCount > 0
                  ? () => handleApplyToMember(info.row.original)
                  : undefined
              }
              onEditVoucher={
                hasPermission(PERMISSION.UPDATE_VOUCHER)
                  ? () => handleEditVoucher(info.row.original)
                  : undefined
              }
            />
          </div>
        );
      },
      size: 100
    })
  ];

  const handleViewVoucher = useCallback(
    (voucherId: string) => {
      navigate(`/vouchers/${voucherId}`);
    },
    [navigate]
  );

  const handleDeleteVoucher = useCallback((value: IVoucher) => {
    setIsOpenDeleteModal(true);
    setSelectedVoucher(value);
  }, []);

  const handleSendToMember = useCallback((value: IVoucher) => {
    setSelectedVoucher(value);
    setIsOpenSendToMember(true);
  }, []);

  const handleApplyToMember = useCallback((value: IVoucher) => {
    setSelectedVoucher(value);
    setIsOpenApplyToMember(true);
  }, []);

  const handleEditVoucher = useCallback((value: IVoucher) => {
    setMode(MODE.EDIT);
    setSelectedVoucher(value);
    setIsOpenAddEditForm(true);
  }, []);

  const handleAddVoucher = useCallback(() => {
    setMode(MODE.ADD);
    setIsOpenAddEditForm(true);
  }, []);

  const handleCloseAddEditForm = useCallback(() => {
    setIsOpenAddEditForm(false);
    setSelectedVoucher(null);
  }, []);

  const handleCloseDeleteModal = useCallback(() => {
    setIsOpenDeleteModal(false);
    setSelectedVoucher(null);
  }, []);

  const handleCloseApplyToMember = useCallback(() => {
    setIsOpenApplyToMember(false);
    setSelectedVoucher(null);
  }, []);

  const handleCloseSendToMember = useCallback(() => {
    setIsOpenSendToMember(false);
    setSelectedVoucher(null);
  }, []);

  const handleSendToMemberSuccess = useCallback(() => {
    setIsOpenSendToMember(false);
    setSelectedVoucher(null);
  }, []);

  const fetchVouchers = useCallback(async () => {
    if (!selectedLocation?._id) return;

    setLoading(true);
    try {
      const { data } = await getVouchers({
        page: pageIndex,
        limit: pageSize,
        locationId: selectedLocation?._id,
        type: discountType
        // filter: ''
      });

      setData(data.data.data);
      setPageTotal(data.data.total);
    } catch (error: any) {
      setData([]);
      setPageTotal(-1);
      toast.error(
        error?.response?.data?.message || 'Failed to fetch list vouchers'
      );
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [pageIndex, pageSize, selectedLocation?._id, discountType]);

  const handleApplyMemberSuccess = useCallback(() => {
    setIsOpenApplyToMember(false);
    fetchVouchers();
    setSelectedVoucher(null);
  }, [fetchVouchers]);

  const handleAddEditSuccess = useCallback(() => {
    handleCloseAddEditForm();
    setPageIndex(1);
    fetchVouchers();
    setSelectedVoucher(null);
  }, [fetchVouchers, handleCloseAddEditForm]);

  const handleDeleteSuccess = useCallback(() => {
    setIsOpenDeleteModal(false);
    setSelectedVoucher(null);
    setPageIndex(1);
    fetchVouchers();
  }, [fetchVouchers]);

  useEffect(() => {
    fetchVouchers();
  }, [fetchVouchers]);

  useEffect(() => {
    setPageIndex(1);
  }, [discountType]);

  const __renderTitleModalAddEdit = useMemo(() => {
    switch (discountType) {
      case DISCOUNT_TYPE.AMOUNT:
        return `${mode} fixed voucher`;
      case DISCOUNT_TYPE.PERCENTAGE:
        return `${mode} percentage voucher`;
      case DISCOUNT_TYPE.COMPLIMENTARY:
        return `${mode} complimentary voucher`;
      default:
        return '';
    }
  }, [mode, discountType]);

  return (
    <section className="voucherList">
      {isOpenAddEditForm && (
        <PermissionWrapper
          permission={[PERMISSION.CREATE_VOUCHER, PERMISSION.UPDATE_VOUCHER]}
        >
          <AddEditVoucher
            data={selectedVoucher}
            title={__renderTitleModalAddEdit}
            mode={mode}
            open={isOpenAddEditForm}
            type={discountType}
            onClose={handleCloseAddEditForm}
            onSuccess={handleAddEditSuccess}
          />
        </PermissionWrapper>
      )}

      <AppCard>
        <div className="voucherList__table">
          <div className="voucherList__table__header">
            <h2 className="voucherList__table__header--title">Vouchers</h2>
            <div className="bulkManagementNotificationList__table__header--actions">
              <PermissionWrapper permission={PERMISSION.CREATE_VOUCHER}>
                <AppButton
                  variant="primary"
                  buttonSize="small"
                  type="submit"
                  onClick={handleAddVoucher}
                >
                  <HiPlus size={24} />
                  <p>Voucher</p>
                </AppButton>
              </PermissionWrapper>
            </div>
          </div>
          <div className="voucherList__table__content">
            <PermissionWrapper permission={PERMISSION.LIST_VOUCHER}>
              <AppTable
                data={data}
                columns={columns}
                pagination={{
                  index: pageIndex,
                  size: pageSize,
                  total: pageTotal
                }}
                onChangePage={(index: number, size: number) => {
                  setPageIndex(index);
                  setPageSize(size);
                }}
                loading={loading}
                columnVisibility={{
                  duration: discountType === DISCOUNT_TYPE.COMPLIMENTARY,
                  discountValue: discountType !== DISCOUNT_TYPE.COMPLIMENTARY
                }}
              />
            </PermissionWrapper>
          </div>
        </div>
      </AppCard>

      {isOpenDeleteModal && selectedVoucher && (
        <VoucherDeleteModal
          voucherSelected={selectedVoucher}
          onClose={handleCloseDeleteModal}
          onSuccess={handleDeleteSuccess}
        />
      )}

      {isOpenApplyToMember && selectedVoucher && (
        <ApplyToMember
          open={isOpenApplyToMember}
          onClose={handleCloseApplyToMember}
          onSuccess={handleApplyMemberSuccess}
          voucher={selectedVoucher}
        />
      )}

      {isOpenSendToMember && selectedVoucher && (
        <SendToMember
          data={selectedVoucher}
          open={isOpenSendToMember}
          onClose={handleCloseSendToMember}
          onSuccess={handleSendToMemberSuccess}
        />
      )}
    </section>
  );
};

export default VoucherList;
