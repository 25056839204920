import React from 'react';
import { useToast } from 'context/ToastContext';
import { useBrandLocation } from 'context/BrandLocationContext';
import { CLASS_TYPES } from 'common/enums/class.enum';
import dayjs, { Dayjs } from 'dayjs';
import {
  ERROR_MESSAGE_DATE,
  FORMAT_END_OF_DATE,
  FORMAT_START_OF_DATE,
  REPORT_PATH,
  REPORT_PROGRAM_TYPE_OPTIONS
} from '../constant';
import { uniq } from 'lodash';
import { ILevel } from 'common/interfaces/levelBreakdown.interface';
import { getLevelBreakdowns } from 'services/levelBreakdown.service';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppDatePicker from 'common/components/AppDatePicker';
import AppSelect from 'common/components/AppSelect';
import ReportSelection from '../components/ReportSelection';
import AppInput from 'common/components/AppInput';
import AppButton from 'common/components/AppButton';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { IOption } from 'common/interfaces';
import { formatData } from 'common/helpers/dataFormat.helper';
import { getNonAttendanceReport } from 'services/report.service';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { createColumnHelper } from '@tanstack/react-table';
import { INonAttendanceReport } from 'common/interfaces/report.interface';
import AppTable from 'common/components/AppTable';
import './desktop.scss';

interface IFilter {
  dateFrom: string;
  dateTo: string;
  programType: CLASS_TYPES;
  numberOfConsecutive: number;
  levelIds: Array<string>;
}

const initFilter: IFilter = {
  dateFrom: '',
  dateTo: '',
  programType: CLASS_TYPES.NONE,
  numberOfConsecutive: 0,
  levelIds: []
};

const ReportNonAttendance = () => {
  const toast = useToast();
  const { selectedLocation: __globalLocation } = useBrandLocation();

  const columnHelper = createColumnHelper<INonAttendanceReport>();

  const columns = [
    columnHelper.accessor('student.memberId', {
      header: () => <span>Student ID</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('student.firstName', {
      header: () => <span>Student name</span>,
      cell: (info) => (
        <>
          {formatData(info.row.original?.student?.lastName)},{' '}
          {formatData(info.getValue())}
        </>
      )
    }),
    columnHelper.accessor('student.dob', {
      header: () => <span>Student Age</span>,
      cell: (info) => {
        const birthDate = dayjs(info.getValue());
        const today = dayjs();

        let age = today.year() - birthDate.year();

        if (
          today.month() < birthDate.month() ||
          (today.month() === birthDate.month() &&
            today.date() < birthDate.date())
        ) {
          age--;
        }

        return <div>{age} years</div>;
      }
    }),
    columnHelper.accessor('schedule.startTime', {
      header: () => <span>lesson day</span>,
      cell: (info) => <span>{dayjs(info.getValue()).format('dddd')}</span>
    }),
    columnHelper.accessor('schedule.startTime', {
      header: () => <span>lesson time</span>,
      cell: (info) => dayjs(info.getValue()).format('HH:mm')
    }),
    columnHelper.accessor('schedule.levelId', {
      header: () => <span>Level</span>,
      cell: (info) => (
        <span>
          {info.row.original.schedule?.allLevel
            ? 'All levels'
            : formatData(info.row.original?.schedule?.level?.shortName)}
        </span>
      )
    }),
    columnHelper.accessor('student.listResponsiblePerson', {
      header: () => <span>RP name</span>,
      cell: (info) => (
        <>
          {formatData(
            info.row.original?.student?.listResponsiblePerson?.[0]
              ?.responsiblePersonInfo?.lastName
          )}
          ,{' '}
          {formatData(
            info.row.original?.student?.listResponsiblePerson?.[0]
              ?.responsiblePersonInfo?.firstName
          )}
        </>
      )
    }),
    columnHelper.accessor('student.listResponsiblePerson', {
      header: () => <span>RP ID</span>,
      cell: (info) =>
        formatData(
          info.row.original?.student?.listResponsiblePerson?.[0]
            ?.responsiblePersonInfo?.memberId
        )
    }),
    columnHelper.accessor('numberOfLessonAttended', {
      header: () => <span>Number of lessons attended</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('numberOfLessonNotAttended', {
      header: () => <span>Number of lessons not attended</span>,
      cell: (info) => formatData(info.getValue())
    })
  ];

  const dateError = React.useRef<string>('');
  const consecutiveError = React.useRef<string>('');

  const [isGenerated, setIsGenerated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [levels, setLevels] = React.useState<Array<ILevel>>([]);

  const [data, setData] = React.useState<Array<INonAttendanceReport>>([]);

  const [limit, setLimit] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(-1);

  // Search section
  const [dateFrom, setDateFrom] = React.useState<string>('');
  const [dateTo, setDateTo] = React.useState<string>('');
  const [programType, setProgramType] = React.useState<CLASS_TYPES>(
    CLASS_TYPES.NONE
  );
  const [numberOfConsecutive, setNumberOfConsecutive] =
    React.useState<number>(0);
  const [levelIds, setLevelIds] = React.useState<Array<string>>([]);

  const filter = React.useRef<IFilter>(initFilter);

  const __levelOptions: Array<IOption> = React.useMemo(() => {
    if (levels?.length === 0) return [];
    return levels.map((level) => ({
      label: formatData(level?.shortName),
      value: level?._id
    }));
  }, [levels]);

  const __selectedLevelPlaceholderText: string = React.useMemo(() => {
    return levelIds.length === 0
      ? 'Select option'
      : `${levelIds.length} selected`;
  }, [levelIds]);

  const __isDisableGenerateButton: boolean = React.useMemo(() => {
    return (
      !dateFrom ||
      !dateTo ||
      !programType ||
      levelIds.length === 0 ||
      !numberOfConsecutive ||
      !!dateError.current ||
      !!consecutiveError.current
    );
  }, [
    dateFrom,
    dateTo,
    programType,
    levelIds,
    numberOfConsecutive,
    dateError,
    consecutiveError
  ]);

  const handleChangeDateFrom = (value: Dayjs | null) => {
    setDateFrom(value?.format(FORMAT_START_OF_DATE) || '');
    if (value?.isAfter(dayjs(dateTo))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeDateTo = (value: Dayjs | null) => {
    setDateTo(value?.format(FORMAT_END_OF_DATE) || '');
    if (value?.isBefore(dayjs(dateFrom))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeProgramType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setProgramType(event.target.value as CLASS_TYPES);
  };

  const handleChangeNumberOfConsecutive = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;

    if (/^\d*$/.test(newValue) && (newValue === '' || parseInt(newValue) > 0)) {
      consecutiveError.current = '';
    } else {
      consecutiveError.current = `Please correct the number of consecutive`;
    }
    setNumberOfConsecutive(parseInt(newValue));
  };

  const handleToggleLevel = (value: string) => {
    const newLevelIds = [...levelIds];
    const findIndex = newLevelIds.findIndex((levelId) => levelId === value);
    if (findIndex === -1) {
      newLevelIds.push(value);
    } else {
      newLevelIds.splice(findIndex, 1);
    }
    setLevelIds(newLevelIds);
  };
  const handleSelectAllLevel = () => {
    const values = levels.map((level: ILevel) => level._id);
    if (levelIds.length === levels?.length) {
      setLevelIds([]);
    } else {
      setLevelIds(uniq(values.concat(levelIds)));
    }
  };

  const handleGenerate = async () => {
    setPage(1);

    filter.current = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      programType: programType,
      numberOfConsecutive: numberOfConsecutive,
      levelIds: levelIds
    };

    fetchNonAttendanceReport();
  };

  const fetchNonAttendanceReport = async () => {
    if (!__globalLocation?._id) return;

    try {
      setLoading(true);

      const result = await getNonAttendanceReport(
        page,
        limit,
        __globalLocation?._id,
        convertToUnixTime(filter.current.dateFrom),
        convertToUnixTime(filter.current.dateTo),
        filter.current.programType,
        filter.current.numberOfConsecutive,
        filter.current.levelIds?.toString()
      );

      setData(result?.data?.data?.data);
      setTotal(result?.data?.data?.total);

      setIsGenerated(true);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to get non attendance report'
      );
      setData([]);
      setTotal(-1);
    } finally {
      setLoading(false);
    }
  };

  const fetchInitData = React.useCallback(async () => {
    if (!__globalLocation?._id) return;
    try {
      const resultLevel = await getLevelBreakdowns(
        1,
        100,
        __globalLocation._id
      );
      setLevels(resultLevel?.data?.data?.data);
      setLevelIds([]);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Failed to get init data');
      setLevels([]);
    }
    // eslint-disable-next-line
  }, [__globalLocation?._id]);

  React.useEffect(() => {
    if (isGenerated) {
      fetchNonAttendanceReport();
    }
    // eslint-disable-next-line
  }, [page, limit]);

  React.useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  return (
    <main id="reportNonAttendance" className="reportNonAttendance">
      <AppBreadCrumb
        items={[
          { name: 'Reporting', href: REPORT_PATH },
          { name: 'Non Attendance Report' }
        ]}
      />
      <div className="layoutContainer reportNonAttendance__wrapper">
        <section className="reportNonAttendance__search">
          <div className="reportNonAttendance__search__fields">
            <AppDatePicker
              size="small"
              label="Date from"
              value={dayjs(dateFrom)}
              onChange={handleChangeDateFrom}
            />
            <AppDatePicker
              size="small"
              label="Date to"
              value={dayjs(dateTo)}
              onChange={handleChangeDateTo}
              message={{
                type: 'error',
                text: dateError.current
              }}
            />
            <AppSelect
              searchable={false}
              inputSize="small"
              label="Program Type"
              placeholder="Select option"
              options={REPORT_PROGRAM_TYPE_OPTIONS}
              value={programType}
              onChange={handleChangeProgramType}
            />
            <AppInput
              name="numberOfConsecutive"
              label="Number of consecutive"
              inputSize="small"
              type="number"
              min="0"
              max="999"
              step="1"
              value={numberOfConsecutive}
              onChange={handleChangeNumberOfConsecutive}
              message={{
                type: 'error',
                text: consecutiveError.current
              }}
            />
            <ReportSelection
              label="Level"
              placeholder={__selectedLevelPlaceholderText}
              options={__levelOptions}
              onSelect={handleToggleLevel}
              onSelectAll={handleSelectAllLevel}
              selectedIds={levelIds}
            />
          </div>
          <AppButton
            variant="primary"
            buttonSize="small"
            isLoading={loading}
            disabled={__isDisableGenerateButton}
            onClick={handleGenerate}
          >
            generate
          </AppButton>
        </section>

        {!isGenerated && loading && <AppLoadingContainer />}

        {isGenerated && !loading && (
          <div className="reportNonAttendance__content">
            <div className="reportExportFamilyDetails__content__header">
              <p className="reportExportFamilyDetails__content__header--title">
                Non Attendance Report
              </p>
            </div>

            <div className="reportNonAttendance__content__table">
              <AppTable
                loading={loading}
                data={data}
                columns={columns}
                pagination={{
                  index: page,
                  size: limit,
                  total: total
                }}
                onChangePage={(index: number, size: number) => {
                  setPage(index);
                  setLimit(size);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default ReportNonAttendance;
