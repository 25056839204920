import dayjs from 'dayjs';
import { convert } from 'html-to-text';

export const plainTextToHtml = (text: string): string => {
  const html = text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
    .replace(/\n/g, '<br>'); // Thêm các dòng mới thành thẻ <br>

  return html;
};

export const htmlToPlainText = (html: string): string => {
  return convert(html);
};
export const getValidDates = (dateRanges: string[][]) => {
  const validDates = [];

  for (let i = 0; i < dateRanges.length; i++) {
    const [start, end] = dateRanges[i];
    let currentDate = dayjs(start);
    const endDate = dayjs(end);

    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
      validDates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }
  }

  return validDates;
};

export const handleNumberInput = (input: string, oldValue: number) => {
  let value: string | number = input.replaceAll(',', '.');
  if (value.includes('.')) {
    const decimalValue = value.split('.')[1];
    if (
      decimalValue.length > 0 &&
      decimalValue !== '0' &&
      decimalValue.length < 2
    ) {
      value = Number(Number(value)?.toFixed(2));
    } else if (decimalValue.length > 2) {
      value = (oldValue);
    }
  }
  return value;
};
