import {
  CreateLocationDto,
  FormUpdateSaleInfoDto,
  GetAreasAvailableForLocationDTO,
  UpdateBankDetailsDto,
  UpdateClubSettingsDto,
  UpdateLeadsInfoDto,
  UpdateManageStaffDto
} from 'DTOs/location.dto';
import API from './API';
import { ILocationTableFilter } from 'common/interfaces/location.interface';

const LOCATIONS_ENDPOINT = `/locations`;

export const getLocationsByBrandId = (brandId: string) => {
  return API.get(`${LOCATIONS_ENDPOINT}/brands/${brandId}`);
};

export const getAreas = (payload: GetAreasAvailableForLocationDTO) => {
  return API.post(`${LOCATIONS_ENDPOINT}/list-available-area`, {
    body: payload
  });
};

export const getLocations = (params: ILocationTableFilter) => {
  return API.get(`${LOCATIONS_ENDPOINT}`, params);
};

export const getAllLocations = () => {
  return API.get(LOCATIONS_ENDPOINT);
};

export const addLocation = async (payload: CreateLocationDto) => {
  const response = await API.post(`${LOCATIONS_ENDPOINT}`, { body: payload });
  return response.data;
};

export const getLocationDetail = async (locationId: string) => {
  const response = await API.get(`${LOCATIONS_ENDPOINT}/${locationId}`);
  return response.data.data;
};

export const updateManagerStaff = (
  locationId: string,
  body: UpdateManageStaffDto
) => {
  return API.patch(`${LOCATIONS_ENDPOINT}/${locationId}/management-staffs`, {
    body
  });
};

export const updateClubSettings = (
  locationId: string,
  body: UpdateClubSettingsDto
) => {
  return API.patch(`${LOCATIONS_ENDPOINT}/${locationId}/settings`, {
    body
  });
};

export const updateBankDetails = (
  locationId: string,
  body: UpdateBankDetailsDto
) => {
  return API.patch(`${LOCATIONS_ENDPOINT}/${locationId}/bank-details`, {
    body
  });
};

export const updateSaleInfo = (
  locationId: string,
  body: FormUpdateSaleInfoDto
) => {
  return API.patch(`${LOCATIONS_ENDPOINT}/${locationId}/sales`, {
    body
  });
};

export const updateLeadsInfo = (
  locationId: string,
  body: UpdateLeadsInfoDto
) => {
  return API.patch(`${LOCATIONS_ENDPOINT}/${locationId}/leads`, {
    body
  });
};

export const deleteLocation = async (id: string) => {
  const response = await API.delete(`${LOCATIONS_ENDPOINT}/${id}`);
  return response.data;
};
