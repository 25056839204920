import { ReactNode, FC } from 'react';

import './desktop.scss';

interface SummaryProps {
  title?: string;
  label?: string;
  icon?: string | ReactNode;
  value?: string;
}

const AppSummaryCard: FC<SummaryProps> = ({ title, label, icon, value }) => {
  return (
    <div className="summary-card">
      <div className="summary-card-wrap">
        <div className="summary-card_title">{title}</div>
        {typeof icon === 'string' ? <img src={icon} alt="" /> : <>{icon}</>}
      </div>
      <div className="summary-card_label">{label}</div>
      <div className="summary-card_value">{value}</div>
    </div>
  );
};

export default AppSummaryCard;
