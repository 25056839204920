import { useEffect, useState, useCallback, FC } from 'react';
import { useParams } from 'react-router-dom';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import PersonalInformation from './components/PersonalInformation';
import ContactDetails from './components/ContactDetails';
import RPStudentList from './components/RPStudentList';
import { useToast } from 'context/ToastContext';
import { getResponsiblePersonDetail } from 'services/responsiblePerson.service';
import { IResponsiblePerson } from 'common/interfaces/responsiblePerson.interface';

import './desktop.scss';

interface Props {
  onUpdated?: () => void;
}

const StudentDetail: FC<Props> = ({ onUpdated }) => {
  const { id } = useParams();
  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<IResponsiblePerson | null>(null);

  const fetchData = useCallback(async () => {
    try {
      if (!id) return;

      const { data } = await getResponsiblePersonDetail(id);

      setData(data?.data);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Fetch RP detail failed');

      setData(null);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [id]);

  const handleRefetch = useCallback(() => {
    fetchData();
    if (onUpdated) onUpdated();

  }, [onUpdated, fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <AppLoadingContainer />;
  }

  return (
    <div className="tab-student-detail">
      <PersonalInformation data={data} onUpdated={handleRefetch} />

      <ContactDetails data={data} onUpdated={handleRefetch} />

      <RPStudentList onUpdated={handleRefetch} />
    </div>
  );
};

export default StudentDetail;
