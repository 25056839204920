import {
  Suspense,
  lazy,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import SummaryInformation from './SummaryInformation';
import { getClassDetail, getLessonDetail } from 'services/classes.service';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { formatTime } from 'common/helpers/dataFormat.helper';
import AppTabs from 'common/components/AppTabs';
import AssessmentHistoryInClass from './components/TabAssessmentHistory';
import { IClass } from 'common/interfaces/class.interface';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useToast } from 'context/ToastContext';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import dayjs from 'dayjs';
import { ITerm } from 'common/interfaces/term.interface';
import TabAbsentForm from './components/TabAbsenceForm';
import { useAuth } from 'context/AuthContext';
import { ABSENCE_STATUS } from 'common/enums/absence.enum';
import { getAbsencesByClass } from 'services/absence.service';
import ClassDeleteModal from '../components/ClassDeleteModal';
import { ISession } from 'common/interfaces/session.interface';
import { TAB } from 'common/constants';

import './desktop.scss';

const StudentList = lazy(() => import('./components/TabStudentsList'));
const TabClassDetail = lazy(() => import('./components/TabClassDetail'));

const ClassDetail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const toast = useToast();
  const { hasPermission } = useAuth();
  const navigate = useNavigate();

  const [lessonData, setLessonData] = useState<ISession | null>(null);

  const [classDetail, setClassDetail] = useState<IClass | null>(null);
  const [tabIndex, setTabIndex] = useState<number>(TAB.IDLE);
  const [loading, setLoading] = useState(true);
  const [loadingLesson, setLoadingLesson] = useState(false);

  const [totalAbsenceForms, setTotalAbsenceForms] = useState(-1);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const handleOpenClassDeleteModal = useCallback(() => {
    setOpenModalDelete(true);
  }, []);

  const handleCloseClassDeleteModal = useCallback(() => {
    setOpenModalDelete(false);
  }, []);

  const handleDeleteSuccess = useCallback(() => {
    setOpenModalDelete(false);
    navigate('/classes');
  }, [navigate]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await getClassDetail(params.id || '');
      const termActive = data.template.terms.find(
        (t: ITerm) => t.termId === data.template.termIsActive
      );
      const endDate = dayjs(termActive?.termDetail?.endDate).format(
        'YYYY-MM-DD'
      );
      setClassDetail({
        ...data,
        startTimeDisplay: formatTime(data.session?.startTime || data.startTime),
        endTimeDisplay: formatTime(data.session?.endTime || data.endTime),
        recurrence: { ...data.recurrence, endDate: endDate },
        endDate: endDate,
        session: data.session || data.pastSession
      });
    } catch (error: any) {
      setClassDetail(null);
      toast.error(
        error?.response?.data?.message || 'Failed to get class detail'
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [params.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchDataLesson = useCallback(async () => {
    const lessonId = searchParams.get('lessonId');

    if (!lessonId || lessonId === 'undefined' || !params.id) {
      setLessonData(null);
      return;
    }
    setLoadingLesson(true);
    try {
      const { data } = await getLessonDetail(params.id, lessonId);

      setLessonData(data.data.session || null);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to get lesson detail'
      );
      setLessonData(null);
    } finally {
      setLoadingLesson(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    !lessonData && fetchDataLesson();
  }, [fetchDataLesson, lessonData]);
  // Get total pending absence forms to display on the tab item.
  const fetchTotalPendingAbsenceForms = useCallback(async () => {
    if (!hasPermission(PERMISSION.LIST_ABSENCE)) {
      return;
    }
    if (params.id) {
      try {
        const result = await getAbsencesByClass(
          params.id,
          1,
          200,
          ABSENCE_STATUS.PENDING
        );
        setTotalAbsenceForms(result.data.data.total);
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message ||
            'Failed to load pending absence forms'
        );
      }
    }
    // eslint-disable-next-line
  }, [params.id]);

  const refetch = useCallback(() => {
    fetchData();
    fetchDataLesson();
  }, [fetchData, fetchDataLesson]);

  const tabs = useMemo(() => {
    const result = [
      {
        name: 'Class details',
        component: (
          <TabClassDetail
            classDetail={classDetail}
            refetch={refetch}
            lessonData={lessonData}
          />
        )
      },
      {
        name: 'Student List',
        component: <StudentList classDetail={classDetail} />
      },
      {
        name: 'Assessment History',
        component: <AssessmentHistoryInClass classDetail={classDetail} />
      },
      ...(hasPermission(PERMISSION.LIST_ABSENCE)
        ? [
            {
              name: `Absence Form (${totalAbsenceForms})`,
              component: (
                <TabAbsentForm
                  getTotalPendingAbsenceForms={fetchTotalPendingAbsenceForms}
                />
              )
            }
          ]
        : [])
    ];
    return result;
  }, [
    lessonData,
    classDetail,
    fetchTotalPendingAbsenceForms,
    refetch,
    totalAbsenceForms,
    hasPermission
  ]);

  const handleSelectedTab = (val: number) => {
    setTabIndex(val);

    searchParams.set('indexRoute', `${val}`);
    setSearchParams(searchParams);
  };

  const indexRoute = Number(searchParams.get('indexRoute'));

  useEffect(() => {
    if (indexRoute >= 0 && indexRoute <= tabs.length) {
      setTabIndex(indexRoute);
    } else {
      setTabIndex(TAB.INIT);

      searchParams.set('indexRoute', `${TAB.INIT}`);
      setSearchParams(searchParams, { replace: true });
    }
  }, [indexRoute, searchParams, setSearchParams, tabs]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchTotalPendingAbsenceForms();
  }, [fetchTotalPendingAbsenceForms]);

  return (
    <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_CLASS}>
      {loading || loadingLesson ? (
        <AppLoadingContainer />
      ) : (
        <div className="class-content-detail">
          {tabIndex >= 0 ? (
            <>
              <AppBreadCrumb
                items={[
                  { name: 'Classes', href: '/classes' },
                  { name: 'Class details', href: '' }
                ]}
              />
              <SummaryInformation
                data={classDetail}
                onDeleteClass={handleOpenClassDeleteModal}
                lessonData={lessonData}
              />
              <div className="class-tab">
                {classDetail && Object.keys(classDetail).length > 0 && (
                  <div className="student_route-tab">
                    <AppTabs
                      tabs={tabs}
                      activeTabIndex={tabIndex}
                      onChangeTab={handleSelectedTab}
                    />

                    {tabIndex >= 0 ? (
                      <Suspense fallback={<AppLoadingContainer />}>
                        {tabs[tabIndex].component}
                      </Suspense>
                    ) : null}
                  </div>
                )}
              </div>
            </>
          ) : null}
          {openModalDelete && classDetail && (
            <ClassDeleteModal
              classSelected={classDetail}
              onClose={handleCloseClassDeleteModal}
              onSuccess={handleDeleteSuccess}
            />
          )}
        </div>
      )}
    </PermissionWrapper>
  );
};

export default memo(ClassDetail);
