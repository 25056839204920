import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import useDebounce from 'common/hooks/useDebounce';
import { getHealthQuestionnaires } from 'services/healthQuestionnaire.service';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppCard from 'common/components/AppCard';
import AppInputSearch from 'common/components/AppInputSearch';
import AppButton from 'common/components/AppButton';
import AppTable from 'common/components/AppTable';
import { IHealthQuestionnaire } from 'common/interfaces/healthQuestionnaire.interface';
import AppToggle from 'common/components/AppToggle';
import HealthQuestionnaireAddForm from './components/HealthQuestionnaireAddForm';
import HealthQuestionnaireDeleteModal from './components/HealthQuestionnaireDeleteModal';
import { useBrandLocation } from 'context/BrandLocationContext';
import {
  HiOutlineIdentification,
  HiOutlineTrash,
  HiPlus
} from 'react-icons/hi';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useAuth } from 'context/AuthContext';
import './desktop.scss';

const HealthQuestionnaireList = () => {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<IHealthQuestionnaire>();

  const { hasPermission } = useAuth();

  const columns = [
    columnHelper.accessor('question', {
      header: () => <span>question</span>,
      cell: (info) => {
        return <div className="line-clamp-1">{info.getValue()}</div>;
      }
    }),
    columnHelper.accessor('sectionLabel', {
      header: () => <span>section Label</span>,
      cell: (info) => info.getValue().replaceAll('_', ' ')
    }),
    columnHelper.accessor('isActive', {
      header: () => <span>status</span>,
      cell: (info) => (
        <div>
          <AppToggle value={info.getValue()} disabled />
        </div>
      )
    }),
    columnHelper.accessor('_id', {
      header: () => (
        <span
          style={{
            display: 'block',
            textAlign: 'center'
          }}
        >
          Action
        </span>
      ),
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <PermissionWrapper
              permission={PERMISSION.VIEW_DETAIL_HEALTH_QUESTIONNAIRE}
            >
              <div
                onClick={() =>
                  navigate(`/health-questionnaire/${info.getValue()}`)
                }
              >
                <HiOutlineIdentification />
              </div>
            </PermissionWrapper>
            <PermissionWrapper
              permission={PERMISSION.DELETE_HEALTH_QUESTIONNAIRE}
            >
              <div
                onClick={() =>
                  onChooseDeleteHealthQuestionnaire(info.row.original)
                }
              >
                <HiOutlineTrash />
              </div>
            </PermissionWrapper>
          </div>
        );
      }
    })
  ];

  const { selectedLocation: globalLocation } = useBrandLocation();

  const [loading, setLoading] = useState<boolean>(true);

  const [data, setData] = useState<Array<IHealthQuestionnaire>>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageTotal, setPageTotal] = useState<number>(-1);

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search);

  const [selectedHealthQuestionnaire, setSelectedHealthQuestionnaire] =
    useState<IHealthQuestionnaire | null>(null);
  const [isOpenAddForm, setIsOpenAddForm] = useState<boolean>(false);

  // ADD SECTION
  const onAddSuccess = async () => {
    onCloseAddForm();
    setSearch('');
    setPageIndex(1);
    fetchData();
  };
  const onOpenAddForm = () => {
    setIsOpenAddForm(true);
  };
  const onCloseAddForm = () => {
    setIsOpenAddForm(false);
  };
  //  END - ADD SECTION

  // DELETE SECTION
  const onChooseDeleteHealthQuestionnaire = (
    healthQuestionnaire: IHealthQuestionnaire
  ) => {
    setSelectedHealthQuestionnaire(healthQuestionnaire);
  };
  const onCancelDeleteHealthQuestionnaire = () => {
    setSelectedHealthQuestionnaire(null);
  };
  const onDeleteSuccess = async () => {
    setSelectedHealthQuestionnaire(null);
    setSearch('');
    setPageIndex(1);
    fetchData();
  };
  // END - DELETE SECTION

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getHealthQuestionnaires(
        globalLocation?._id || '',
        pageIndex,
        pageSize,
        search
      );
      setData(result.data.data.data);
      setPageTotal(result.data.data.total);
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [globalLocation?._id, debouncedSearch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setPageIndex(1);
  }, [debouncedSearch, globalLocation?._id]);

  return (
    <main className="healthQuestionnaireListPage">
      {/* ADD FORM */}
      <HealthQuestionnaireAddForm
        open={isOpenAddForm}
        onClose={onCloseAddForm}
        onSuccess={onAddSuccess}
      />

      {/* DELETE */}
      {!!selectedHealthQuestionnaire && (
        <HealthQuestionnaireDeleteModal
          healthQuestionnaire={selectedHealthQuestionnaire}
          onClose={onCancelDeleteHealthQuestionnaire}
          onSuccess={onDeleteSuccess}
        />
      )}

      {/* MAIN */}
      <AppBreadCrumb
        items={[
          { name: 'Health Questionnaire', href: '/health-questionnaire' }
        ]}
      />
      <div className="layoutContainer healthQuestionnaire">
        <AppCard>
          <div className="healthQuestionnaire__table">
            <div className="healthQuestionnaire__table-header">
              <h2 className="healthQuestionnaire__table-header-title">
                Health Questionnaire(s)
              </h2>
              <div className="healthQuestionnaire__table-header-search">
                <AppInputSearch
                  type="text"
                  value={search}
                  onChange={onChangeSearch}
                  placeholder="Question"
                  onClearSearch={onClearSearch}
                  startIcon={<HiMagnifyingGlass />}
                />
                <PermissionWrapper
                  permission={PERMISSION.CREATE_HEALTH_QUESTIONNAIRE}
                >
                  <AppButton
                    variant="primary"
                    buttonSize="small"
                    type="submit"
                    onClick={onOpenAddForm}
                  >
                    <HiPlus />
                    <p>Question</p>
                  </AppButton>
                </PermissionWrapper>
              </div>
            </div>
            <div className="healthQuestionnaire__table-content">
              <AppTable
                data={data}
                columns={columns}
                pagination={{
                  index: pageIndex,
                  size: pageSize,
                  total: pageTotal
                }}
                onChangePage={(index: number, size: number) => {
                  setPageIndex(index);
                  setPageSize(size);
                }}
                loading={loading}
                columnVisibility={
                  !hasPermission(PERMISSION.VIEW_DETAIL_HEALTH_QUESTIONNAIRE) &&
                  !hasPermission(PERMISSION.DELETE_HEALTH_QUESTIONNAIRE)
                    ? { _id: false }
                    : undefined
                }
              />
            </div>
          </div>
        </AppCard>
      </div>
    </main>
  );
};

export default HealthQuestionnaireList;
