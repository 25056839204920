import {
  forwardRef,
  useState,
  useEffect,
  InputHTMLAttributes,
  useCallback
} from 'react';

import AppSelect from 'common/components/AppSelect';

import { useBrandLocation } from 'context/BrandLocationContext';

export interface RefHandle {}

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  defaultValue?: string;
  searchable?: boolean;
  message?: {
    type: 'error' | 'success' | 'caption';
    text: string;
  };
  onChangeLocation?: (val: string) => void;
}

const AppLocationInput = forwardRef<RefHandle, Props>(
  (
    {
      label = ' ',
      defaultValue = '',
      searchable = true,
      message,
      onChangeLocation,
      ...props
    },
    ref
  ) => {
    const {
      selectedLocation: globalLocation,
      locations: globalLocations
    } = useBrandLocation();

    const [formLocation, setFormLocation] = useState<string>(
      globalLocation?._id || ''
    );

    const handleEmitChange = useCallback((location: string) => {
      if (onChangeLocation) onChangeLocation(location);

      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (defaultValue) {
        setFormLocation(defaultValue);

        handleEmitChange(defaultValue);
      } else {
        if (!globalLocation?._id) return;
        setFormLocation(globalLocation?._id);

        handleEmitChange(globalLocation?._id);
      }
    }, [globalLocation?._id, defaultValue, handleEmitChange]);

    return (
      <>
        <AppSelect
          {...props}
          label={label}
          searchable={searchable}
          options={[...globalLocations].map((location) => {
            return {
              value: location._id,
              label: location.name
            };
          })}
          value={formLocation}
          onChange={(val) => {
            setFormLocation(val.target.value);

            handleEmitChange(val.target.value);
          }}
          message={message}
        />
      </>
    );
  }
);

export default AppLocationInput;
