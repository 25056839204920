import { useEffect, useState } from 'react';
import { HiXMark } from 'react-icons/hi2';

interface IUploadFileProps {
  file: File;
  onRemove?: (file: File) => void;
}

const UploadFile = (props: IUploadFileProps) => {
  const { file, onRemove } = props;

  const [image, setImage] = useState<string>('');

  useEffect(() => {
    setImage(URL.createObjectURL(file));
  }, [file]);

  return (
    <div className="c__upload-files-item">
      <div className="c__upload-files-item-content">
        <div className="c__upload-files-item-content-image">
          {file.type.includes('application') ? (
            <embed src={image || '/icons/document-1.svg'} />
          ) : (
            <img src={image || '/icons/document-1.svg'} alt="document" />
          )}
        </div>
        {onRemove && (
          <p className="c__upload-files-item-content-name">{file.name}</p>
        )}
      </div>
      {onRemove && (
        <div className="c__upload-files-item-action">
          <div className="c__upload-files-item-action-delete">
            <div onClick={() => onRemove(file)}>
              <HiXMark size={24} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadFile;
