import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UpdateLevelDTO } from 'DTOs/levelBreakdown.dto';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppSelect from 'common/components/AppSelect';
import { useToast } from 'context/ToastContext';
import { formatData } from 'common/helpers/dataFormat.helper';
import {
  ILevel,
  ILevelBreakdown
} from 'common/interfaces/levelBreakdown.interface';
import { HiOutlineBookmark, HiOutlineXCircle } from 'react-icons/hi';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {
  getLevelBreakdowns,
  updateLevelBreakdown
} from 'services/levelBreakdown.service';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useBrandLocation } from 'context/BrandLocationContext';

interface ILevelsBreakdownNextLevelProps {
  data: ILevel;
  onSuccess: () => void;
}

const LevelsBreakdownNextLevel = (props: ILevelsBreakdownNextLevelProps) => {
  const { data, onSuccess } = props;

  const params = useParams();
  const toast = useToast();
  const { selectedLocation } = useBrandLocation();

  const [loading, setLoading] = useState<boolean>(false);

  const [level, setLevel] = useState<ILevel>({
    ...data
  });

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [levelList, setLevelList] = useState<ILevelBreakdown[]>([]);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getLevelBreakdowns(
        1,
        100,
        selectedLocation?._id || ''
      );
      setLevelList(
        data.data.data
          .map((item: ILevel) => ({
            ...item,
            label: item.name,
            value: item._id
          }))
          .filter((item: ILevel) => item._id !== level._id)
      );
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to fetch level breakdown list'
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLevel({
      ...data,
      nextLevelIds: data?.nextLevels.map((item) => item._id)?.join(',')
    });
  }, [data]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onCloseEdit = () => {
    setIsEdit(false);
    setLevel({ ...data });
  };

  const onOpenEdit = () => {
    setIsEdit(true);
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLevel({
        ...level,
        nextLevelIds: event.target.value
      });
    },
    [level]
  );

  const onSubmit = async () => {
    setLoading(true);
    try {
      const payload: UpdateLevelDTO = {
        name: level.name,
        shortName: level.shortName,
        fromAge: level.fromAge,
        toAge: level.toAge,
        colorCode: level.colorCode,
        description: level.description,
        backgroundImage: level.backgroundImage,
        mascotImage: level.mascotImage,
        nextLevelIds: level.nextLevelIds?.split(',').filter((id) => !!id),
        locationIds: level.locationIds
      };
      await updateLevelBreakdown(params?.id || '', payload);
      onSuccess();
      toast.success('Saved');
      setIsEdit(false);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'Failed to save level breakdown information'
      );
    } finally {
      setLoading(false);
    }
  };

  const __renderIcons = useMemo((): React.ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={onSubmit}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
    // eslint-disable-next-line
  }, [isEdit, onCloseEdit, loading]);

  const __renderContent = useMemo((): React.ReactNode => {
    if (isEdit) {
      return (
        <AppSelect
          label="Next Level"
          value={level.nextLevelIds || ''}
          onChange={handleChange}
          options={levelList}
          multiValue
        />
      );
    }
    
    return (
      <>
        <AppCardContentItem
          subtitle="Next level"
          title={formatData(
            level?.nextLevels?.map((item) => item.name).join(', ')
          )}
        />
      </>
    );
    // eslint-disable-next-line
  }, [isEdit, handleChange, level, formatData]);

  return (
    <section className="levelInformation">
      <AppCard className={`${isEdit ? 'edit' : ''}`}>
        <AppCardHeader
          title="Next Level"
          suffix={
            <PermissionWrapper permission={PERMISSION.UPDATE_LEVEL_BREAKDOWN}>
              {__renderIcons}
            </PermissionWrapper>
          }
        />
        <AppCardContent
          className={`cardContent ${isEdit ? 'cardContent-edit' : ''}`}
        >
          {__renderContent}
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default LevelsBreakdownNextLevel;
