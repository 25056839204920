import API from './API';
import {
  CreateClassTemplatePayloadDto,
  UpdateClassTemplatePayloadDto
} from 'DTOs/classTemplate.dto';

const CLASS_TEMPLATE_ENDPOINT = `/class-templates`;

export const getClassTemplates = (
  locationId: string,
  page: number,
  limit: number,
  keyword: string,
  active?: boolean
) => {
  return API.get(`${CLASS_TEMPLATE_ENDPOINT}`, {
    locationId,
    page,
    limit,
    keyword,
    active
  });
};

export const getClassTemplateById = (id: string) => {
  return API.get(`${CLASS_TEMPLATE_ENDPOINT}/${id}`);
};

export const createClassTemplate = (payload: CreateClassTemplatePayloadDto) => {
  return API.post(`${CLASS_TEMPLATE_ENDPOINT}`, {
    body: payload
  });
};

export const updateClassTemplate = (
  id: string,
  payload: UpdateClassTemplatePayloadDto
) => {
  return API.patch(`${CLASS_TEMPLATE_ENDPOINT}/${id}`, {
    body: payload
  });
};

export const deleteClassTemplate = (id: string) => {
  return API.delete(`${CLASS_TEMPLATE_ENDPOINT}/${id}`);
};

export const addTermToTemplate = (id: string, termId: string) => {
  return API.post(`${CLASS_TEMPLATE_ENDPOINT}/${id}/add-term`, {
    body: {
      termId: termId.split(",")
    }
  });
};

export const removeTermFromTemplate = (id: string, termId: string) => {
  
  return API.delete(`${CLASS_TEMPLATE_ENDPOINT}/${id}/remove-term`, {
    data: {
      termId: termId.split(",")
    }
  });
};
