import { memo, useCallback, useEffect, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs, { Dayjs } from 'dayjs';
import * as colors from 'common/constants/colors';
import yup from 'validators/class.validators';
import AppButton from 'common/components/AppButton';
import AppCard, {
  AppCardContent,
  AppCardHeader
} from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import AppSelect from 'common/components/AppSelect';
import AppTextArea from 'common/components/AppTextArea';
import AppToggle from 'common/components/AppToggle';
import RecurrenceModal from '../components/RecurrenceModal';
import { HiArrowLeft } from 'react-icons/hi2';
import { IClass, IRecurrence } from 'common/interfaces/class.interface';
import { createClass } from 'services/classes.service';
import { getClassTemplates } from 'services/classTemplate.service';
import { checkInstructorConflict } from 'services/instructor.service';
import { useToast } from 'context/ToastContext';
import {
  CLASS_TEMPLATE_DURATIONS,
  PROGRAM_TYPE_OPTIONS,
  DATE_CONSTANT,
  RECURRENCE_OPTIONS
} from 'common/constants/index';
import {
  calculateEndTime,
  convertRecurrenceToString,
  convertToUnixTime,
  getCurrentUserTimeZone
} from 'common/helpers/time.helper';
import AppTimePicker from 'common/components/AppTimePicker';
import { useBrandLocation } from 'context/BrandLocationContext';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import { formatData } from 'common/helpers/dataFormat.helper';
import { IInstructor } from 'common/interfaces/instructor.interface';
import { cloneDeep, isEmpty, pickBy, round, sortBy } from 'lodash';
import { MdEventAvailable } from 'react-icons/md';
import { CgUnavailable } from 'react-icons/cg';
import {
  IClassTemplate,
  IClassTemplateOption
} from 'common/interfaces/classTemplate.interface';
import { CLASS_TYPES, RECURRENCE_VALUES } from 'common/enums/class.enum';
import { getAreas } from 'services/location.service';
import { IArea } from 'common/interfaces/location.interface';
import { GetAreasAvailableForLocationDTO } from 'DTOs/location.dto';
import { HiOutlineExclamationCircle } from 'react-icons/hi2';
import {
  CheckConflictInstructorDTO,
  CreateClassDto,
  CreateClassPayloadDTO
} from 'DTOs/class.dto';
import { generateTermData } from 'common/helpers/term.helper';
import AppDatePicker from 'common/components/AppDatePicker';
import { ITerm } from 'common/interfaces/term.interface';
import { getUnixTimeOfValue } from 'helpers/time.hepler';

import './desktop.scss';
import { getValidDates, handleNumberInput } from 'helpers/index.helper';
import { ILevelBreakdown } from 'common/interfaces/levelBreakdown.interface';

const initClass: CreateClassDto = {
  active: true,
  area: '',
  capacity: 0,
  colorCode: colors.primaryColor,
  endTime: '',
  startTime: '',
  type: CLASS_TYPES.SERIES,
  instructorId: '',
  recurrenceValue: RECURRENCE_VALUES.WEEKLY,
  recurrence: {
    mon: false,
    tue: false,
    wed: false,
    thur: false,
    fri: false,
    sat: false,
    sun: false,
    type: RECURRENCE_VALUES.WEEKLY,
    endDate: 0
  } as IRecurrence,
  price: 0,
  templateId: '',
  startDate: dayjs().format(),
  endDate: dayjs().format(),
  isPrivate: false
};
const validationSchema = yup.OBJECT({
  instructorId: yup.CLASS_INSTRUCTOR,
  active: yup.CLASS_ACTIVE,
  startTime: yup.CLASS_START_TIME,
  endTime: yup.CLASS_END_TIME,
  colorCode: yup.string(),
  type: yup.CLASS_TYPE,
  capacity: yup.CLASS_CAPACITY,
  area: yup.CLASS_AREA,
  recurrence: yup.CLASS_RECURRENCE,
  recurrenceValue: yup.CLASS_RECURRENCE_VALUE,
  price: yup.CLASS_PRICE,
  templateId: yup.CLASS_TEMPLATE_ID,
  startDate: yup.CLASS_START_DATE,
  isPrivate: yup.boolean(),
  endDate: yup.CLASS_END_DATE
});
interface Props {
  openAdd: boolean;
  onCloseAdd: () => void;
  onSuccess: () => void;
}
const AddClass = ({ openAdd, onCloseAdd: onClose, onSuccess }: Props) => {
  const [classTemplates, setClassTemplates] = useState<IClassTemplateOption[]>(
    []
  );

  const [instructorList, setInstructorList] = useState<IInstructor[]>([]);
  const [classData, setClassData] = useState<CreateClassDto>(initClass);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInstructor, setLoadingInstructor] = useState<boolean>(false);
  const [loadingArea, setLoadingArea] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [showModalRecurrence, setShowModalRecurrence] =
    useState<boolean>(false);
  const [showWarningInstructor, setShowWarningInstructor] =
    useState<boolean>(false);
  const [classConflicts, setClassConflicts] = useState<IClass>();
  const [instructorNotSameLevel, setInstructorNotSameLevel] =
    useState<boolean>(false);
  const [instructorLevels, setInstructorLevel] = useState<string[]>([]);
  const [showWarningArea, setShowWarningArea] = useState<boolean>(false);
  const [areas, setAreas] = useState<IArea[]>([]);
  const [classConflictArea, setClassConflictArea] = useState<IClass>();
  const [selectedTemplate, setTemplateSelected] =
    useState<IClassTemplateOption | null>(null);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [disableBeforeDate, setDisableBeforeDate] = useState<Date>(
    dayjs().subtract(1, 'day').toDate()
  );
  const [rangeValidDate, setRangeValidDate] = useState<string[]>([]);
  const [selectedIntensiveProgram, setSelectedIntensiveProgram] =
    useState<Boolean>(false);

  const toast = useToast();
  const { selectedLocation: globalLocation } = useBrandLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    getValues,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema) as Resolver<CreateClassDto>,
    defaultValues: classData,
    context: {
      rangeValidDate: rangeValidDate,
      disableBeforeDate: disableBeforeDate,
      setSelectedIntensiveProgram: setSelectedIntensiveProgram
    }
  });

  useEffect(() => {
    if (!selectedTemplate?.terms?.length) return;
    const { terms } = selectedTemplate || {};
    const endDate = terms?.[terms.length - 1]?.termDetail?.endDate;
    const rangeDates = getValidDates(
      terms.map((item) => [item.termDetail.startDate, item.termDetail.endDate])
    );
    setRangeValidDate(rangeDates);
    setEndDate(dayjs(endDate).format('YYYY-MM-DD'));
  }, [selectedTemplate]);

  const fetchData = useCallback(async () => {
    if (!globalLocation?._id) return;
    setLoadingData(true);
    try {
      const response = await Promise.all([
        getClassTemplates(globalLocation?._id || '', 1, 100, '', true)
      ]);
      setClassTemplates(
        response[0].data.data.data
          .map((item: IClassTemplate) => ({
            ...item,
            label: item.name,
            value: item._id
          }))
          .filter((item: IClassTemplate) => item.terms.length)
      );
    } catch (error) {
      setClassTemplates([]);
    } finally {
      setLoadingData(false);
    }
  }, [globalLocation?._id]);

  const isInstructorConflict = useCallback(async () => {
    if (!openAdd || !selectedTemplate) return;
    setLoading(true);
    setLoadingInstructor(true);
    const recurrence = cloneDeep(classData.recurrence || {});
    let recurrenceValue = {
      mon: recurrence.mon,
      tue: recurrence.tue,
      wed: recurrence.wed,
      thur: recurrence.thur,
      fri: recurrence.fri,
      sat: recurrence.sat,
      sun: recurrence.sun,
      type: recurrence.type
    };

    const day = new Date(classData.startDate).getDay();
    recurrenceValue = {
      type: RECURRENCE_VALUES.WEEKLY,
      mon: day === 1,
      tue: day === 2,
      wed: day === 3,
      thur: day === 4,
      fri: day === 5,
      sat: day === 6,
      sun: day === 0
    };
    let startDateValue = startDate;
    if (
      [RECURRENCE_VALUES.WEEKLY, RECURRENCE_VALUES.DO_NOT_REPEAT].includes(
        recurrence.type as RECURRENCE_VALUES
      )
    ) {
      startDateValue = classData.startDate;
    }
    const payload: CheckConflictInstructorDTO = {
      startDate: convertToUnixTime(
        dayjs(`${startDateValue} ${classData.startTime}`).format()
      ),
      endDate: convertToUnixTime(
        dayjs(`${endDate} ${classData.endTime}`).format()
      ),
      recurrence: recurrenceValue,
      startTime: classData.startTime
        ? getUnixTimeOfValue(`${classData.startTime}`)
        : null,
      endTime: classData.endTime
        ? getUnixTimeOfValue(`${classData.endTime}`)
        : null,
      timezone: getCurrentUserTimeZone(),
      levelId: selectedTemplate?.levelId || '',
      templateId: selectedTemplate?._id || '',
      locationId: globalLocation?._id || ''
    };
    const newPayload = {
      ...pickBy(
        payload,
        (value) =>
          !isEmpty(value) ||
          (value && Array.isArray(value) && value.length) ||
          typeof value === 'number'
      )
    };
    try {
      const response = await checkInstructorConflict(newPayload);
      setInstructorList(
        sortBy(
          response.map((item: IInstructor) => ({
            ...item,
            value: item._id,
            label: `${item.lastName}, ${item.firstName}`,
            hint:
              !item.isConflict && item.isCanTeachThisLevel ? (
                <MdEventAvailable />
              ) : (
                <CgUnavailable />
              )
          })),
          'lastName'
        )
      );
    } catch (error) {
      setShowWarningInstructor(false);
    } finally {
      setLoading(false);
      setLoadingInstructor(false);
    }
  }, [
    startDate,
    endDate,
    classData.startTime,
    classData.endTime,
    classData.recurrence,
    openAdd,
    selectedTemplate,
    classData.startDate,
    globalLocation?._id
  ]);

  const handleCheckInstructorConflict = useCallback(() => {
    if (instructorList.length === 0) return;
    setLoadingInstructor(true);
    const data = instructorList?.find(
      (instructor: IInstructor) => instructor._id === classData.instructorId
    );
    if (!data) {
      setLoadingInstructor(false);
      return;
    }
    if (data.isConflict) {
      setShowWarningInstructor(true);
      setClassConflicts(data.class);
      setLoadingInstructor(false);
      return;
    } else {
      setShowWarningInstructor(false);
    }
    if (data?.isCanTeachThisLevel) {
      setInstructorNotSameLevel(false);
      setShowWarningInstructor(false);
    } else {
      setShowWarningInstructor(true);
      setInstructorNotSameLevel(true);
      setInstructorLevel(
        data?.levels?.map((item: ILevelBreakdown) => item.shortName) || []
      );
    }
    setLoadingInstructor(false);
  }, [classData.instructorId, instructorList]);

  useEffect(() => {
    handleCheckInstructorConflict();
  }, [handleCheckInstructorConflict]);

  const handleCheckAreaConflict = useCallback(() => {
    if (areas.length === 0) return;
    setLoadingArea(true);
    const area = areas?.find((item: IArea) => item.area === classData.area);
    if (area && area.isConflict) {
      setShowWarningArea(true);
      setClassConflictArea(area.classes?.[0]);
    } else {
      setShowWarningArea(false);
    }
    setLoadingArea(false);
  }, [classData.area, areas]);

  useEffect(() => {
    handleCheckAreaConflict();
  }, [handleCheckAreaConflict]);

  const getAreasData = useCallback(async () => {
    if (!openAdd || !globalLocation?._id || !selectedTemplate) return;
    setLoadingArea(true);
    try {
      let startDateValue = startDate;
      let recurrence = {
        ...classData.recurrence,
        endDate: convertToUnixTime(endDate)
      };
      const day = new Date(classData.startDate).getDay();
      recurrence = {
        type: RECURRENCE_VALUES.WEEKLY,
        mon: day === 1,
        tue: day === 2,
        wed: day === 3,
        thur: day === 4,
        fri: day === 5,
        sat: day === 6,
        sun: day === 0,
        endDate: convertToUnixTime(endDate)
      };
      if (
        [RECURRENCE_VALUES.WEEKLY, RECURRENCE_VALUES.DO_NOT_REPEAT].includes(
          recurrence.type as RECURRENCE_VALUES
        )
      ) {
        startDateValue = classData.startDate;
      }
      const payload: GetAreasAvailableForLocationDTO = {
        startDate: convertToUnixTime(
          dayjs(`${startDateValue} ${classData.startTime}`).format()
        ),
        endDate: convertToUnixTime(
          dayjs(`${endDate} ${classData.endTime}`).format()
        ),
        recurrence: recurrence,
        locationId: globalLocation?._id,
        startTime: classData.startTime
          ? getUnixTimeOfValue(`${classData.startTime}`)
          : null,
        endTime: classData.endTime
          ? getUnixTimeOfValue(`${classData.endTime}`)
          : null,
        timezone: getCurrentUserTimeZone(),
        templateId: selectedTemplate?._id || ''
      };
      const { data } = await getAreas(payload);
      setAreas(
        data.data.map((item: IArea) => ({
          ...item,
          value: item.area,
          label: item.area,
          hint: !item.isConflict ? <MdEventAvailable /> : <CgUnavailable />
        }))
      );
    } catch (error: any) {
      setAreas([]);
      setShowWarningArea(false);
      setClassConflictArea(undefined);
      toast.error(
        error?.response?.data?.message || 'Failed to load list areas!'
      );
    } finally {
      setLoadingArea(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    classData.startTime,
    classData.endTime,
    globalLocation?._id,
    classData.recurrence,
    classData.startDate,
    startDate,
    endDate,
    openAdd,
    selectedTemplate
  ]);

  useEffect(() => {
    getAreasData();
  }, [getAreasData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    isInstructorConflict();
  }, [isInstructorConflict]);

  const shouldDisableDate = (date: Date) => {
    return (
      date < disableBeforeDate ||
      !rangeValidDate.includes(dayjs(date).format('YYYY-MM-DD'))
    );
  };

  const handleChangeClassTemplate = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const template = classTemplates.find(
      (item: IClassTemplateOption) => item._id === e.target.value
    );
    if (!template) return;
    setTemplateSelected(template);
    const activeTerm = template?.terms.find(
      (item: ITerm) => item.termId === template?.termIsActive
    );
    const isPrivate = template?.name.toLocaleLowerCase().includes('private');
    const today = dayjs();
    const startDateOfTerm = dayjs(activeTerm?.termDetail?.startDate);
    const endDateOfTerm = dayjs(activeTerm?.termDetail?.endDate);

    const startDate = startDateOfTerm.isBefore(today) ? today : startDateOfTerm;

    setStartDate(startDate.format('YYYY-MM-DD'));
    setDisableBeforeDate(
      startDateOfTerm.isBefore(today)
        ? today.subtract(1, 'day').toDate()
        : startDateOfTerm.subtract(1, 'day').toDate()
    );
    setClassData({
      ...classData,
      price: template?.price || 0,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDateOfTerm.format('YYYY-MM-DD'),
      isPrivate
    });
    setValue('templateId', template?._id || '');
    setValue('price', round(template?.price, 2) || 0);
    setValue('isPrivate', isPrivate);
    setValue('startDate', startDate.format('YYYY-MM-DD'));
    setValue('endDate', endDateOfTerm.format('YYYY-MM-DD'));
    if (classData.startTime) {
      const endTime = calculateEndTime(
        dayjs(`${DATE_CONSTANT} ${classData.startTime}`),
        template?.duration || 0
      );
      setClassData({
        ...classData,
        endTime: endTime
      });
      setValue('endTime', endTime);
    }
    trigger('templateId');
  };

  const onSaveRecurrence = (recurrence: IRecurrence) => {
    setClassData({ ...classData, recurrence });
    setValue('recurrenceValue', RECURRENCE_VALUES.CUSTOM);
    setValue('recurrence', recurrence);
    setShowModalRecurrence(false);
    trigger('recurrenceValue');
    setClassData({ ...classData, recurrence });
  };
  const handleChangeRecurrence = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const startDate = dayjs(classData.startDate).format('YYYY-MM-DD');
    let recurrence = {
      ...getValues('recurrence'),
      endDate: convertToUnixTime(endDate)
    };
    switch (event.target.value) {
      case RECURRENCE_VALUES.DO_NOT_REPEAT: {
        recurrence = {
          mon: false,
          tue: false,
          wed: false,
          thur: false,
          fri: false,
          sat: false,
          sun: false,
          type: RECURRENCE_VALUES.DO_NOT_REPEAT,
          endDate: convertToUnixTime(endDate)
        };
        setValue('recurrence', recurrence);
        setValue('startDate', startDate);
        break;
      }
      case RECURRENCE_VALUES.WEEKLY: {
        const day = new Date(startDate).getDay();
        recurrence = {
          type: RECURRENCE_VALUES.WEEKLY,
          mon: day === 1,
          tue: day === 2,
          wed: day === 3,
          thur: day === 4,
          fri: day === 5,
          sat: day === 6,
          sun: day === 0,
          endDate: convertToUnixTime(endDate)
        };
        setValue('recurrence', recurrence);
        setValue('startDate', startDate);
        break;
      }
      case RECURRENCE_VALUES.CUSTOM:
        recurrence = { ...recurrence, type: RECURRENCE_VALUES.CUSTOM };
        setShowModalRecurrence(true);
        break;

      default:
        break;
    }
    if (event.target.value === RECURRENCE_VALUES.CUSTOM) {
      setClassData({
        ...classData,
        recurrenceValue: event.target.value,
        startDate
      });
    } else {
      setClassData({
        ...classData,
        recurrenceValue: event.target.value,
        startDate,
        recurrence
      });
    }
    setValue('recurrenceValue', event.target.value);
    trigger('recurrenceValue');
  };
  const onCloseRecurrence = () => {
    setShowModalRecurrence(false);
    setValue('recurrenceValue', '');
    trigger('recurrenceValue');
  };

  const handleChangeStartTime = (e: Dayjs | null) => {
    if (e?.format('YYYY-MM-DD') === 'Invalid Date') {
      setValue('startTime', '');
      setValue('endTime', '');
      trigger('startTime');
      return;
    }
    const day = dayjs(e);
    const endTime = calculateEndTime(day, selectedTemplate?.duration || 0);
    setClassData({
      ...classData,
      startTime: day.format('HH:mm'),
      endTime
    });
    setValue('startTime', day.format('HH:mm'));
    setValue('endTime', endTime);
    trigger('startTime');
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.name === 'instructorId') {
      const instructor = [...instructorList].find(
        (item: IInstructor) => item._id === event.target.value
      );
      setShowWarningInstructor(instructor?.isConflict || false);
      setClassConflicts(instructor?.class);

      setClassData({
        ...classData,
        [event.target.name]: event.target.value
      });
      // @ts-ignore
      setValue(event.target.name, event.target.value);
      trigger(event.target.name);
      return;
    }
    if (event.target.name === 'type') {
      setSelectedIntensiveProgram(false);
      switch (event.target.value) {
        case CLASS_TYPES.PRIVATE:
          setValue('capacity', 1);
          setClassData({
            ...classData,
            capacity: 1,
            type: event.target.value
          });
          break;
        case CLASS_TYPES.ASSESSMENT_TRIAL:
          setValue('price', 0);
          setClassData({
            ...classData,
            price: 0,
            type: event.target.value
          });
          break;
        case CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM:
          setSelectedIntensiveProgram(true);
          setClassData({
            ...classData,
            type: event.target.value as CLASS_TYPES
          });
          break;
        default:
          const activeTerm = selectedTemplate?.terms.find(
            (item: ITerm) => item.termId === selectedTemplate?.termIsActive
          );
          const endDateOfTerm = dayjs(activeTerm?.termDetail?.endDate);
          setEndDate(endDateOfTerm.format('YYYY-MM-DD'));

          const updatedClassData = { ...classData };
          updatedClassData.endDate = endDateOfTerm.format('YYYY-MM-DD');
          setClassData({
            ...classData,
            type: event.target.value as CLASS_TYPES,
            endDate: endDateOfTerm.format('YYYY-MM-DD')
          });

          break;
      }
      setValue('type', event.target.value as CLASS_TYPES);
      trigger();
      return;
    }
    if (event.target.name === 'area') {
      setClassData({
        ...classData,
        [event.target.name]: event.target.value
      });
      // @ts-ignore
      setValue(event.target.name, event.target.value);
      trigger(event.target.name);
      const selectedArea = areas.find(
        (item: IArea) => item.area === event.target.value
      );
      if (selectedArea?.isConflict) {
        setShowWarningArea(true);
        setClassConflictArea(selectedArea.classes?.[0]);
      } else {
        setShowWarningArea(false);
      }
      return;
    }
    if (event.target.name === 'price') {
      const price = handleNumberInput(event.target.value, classData.price);
      // @ts-ignore
      setValue('price', price);
      trigger(event.target.name);
      setClassData({
        ...classData,
        price: Number(price)
      });
      return;
    }
    setClassData({
      ...classData,
      [event.target.name]: event.target.value
    });
    // @ts-ignore
    setValue(event.target.name, event.target.value);
    // @ts-ignore
    trigger(event.target.name);
  };

  const onSubmit = (data: CreateClassDto) => {
    if (!globalLocation?._id || !selectedTemplate?._id) return;
    setLoading(true);
    const {
      startTime,
      endTime,
      capacity,
      area,
      active,
      colorCode,
      instructorId,
      type
    } = data;
    let startDateUnixTime = convertToUnixTime(data.startDate);
    let recurrence = { ...data.recurrence, endDate: data.endDate };

    if (
      [RECURRENCE_VALUES.DO_NOT_REPEAT, RECURRENCE_VALUES.WEEKLY].includes(
        recurrence.type as RECURRENCE_VALUES
      )
    ) {
      startDateUnixTime = convertToUnixTime(data.startDate);
      if (recurrence.type === RECURRENCE_VALUES.DO_NOT_REPEAT) {
        recurrence = {
          type: RECURRENCE_VALUES.DO_NOT_REPEAT,
          mon: false,
          tue: false,
          wed: false,
          thur: false,
          fri: false,
          sat: false,
          sun: false,
          endDate: data.endDate
        };
      }
      if (recurrence.type === RECURRENCE_VALUES.WEEKLY) {
        const day = new Date(data.startDate).getDay();
        recurrence = {
          type: RECURRENCE_VALUES.WEEKLY,
          mon: day === 1,
          tue: day === 2,
          wed: day === 3,
          thur: day === 4,
          fri: day === 5,
          sat: day === 6,
          sun: day === 0,
          endDate: data.endDate
        };
      }
    }
    const payload: CreateClassPayloadDTO = {
      templateId: selectedTemplate?._id,
      colorCode: colorCode || colors.primaryColor,
      active,
      startTime: getUnixTimeOfValue(`${startTime}`),
      endTime: getUnixTimeOfValue(`${endTime}`),
      capacity,
      area,
      recurrence: {
        ...recurrence,
        endDate: dayjs(data.endDate).valueOf()
      },
      instructorId,
      type,
      startDate: startDateUnixTime,
      locationId: globalLocation?._id,
      timezone: getCurrentUserTimeZone(),
      price: Number(data.price),
      isPrivate: data.isPrivate && data.type === CLASS_TYPES.SERIES
    };

    createClass(payload)
      .then(() => {
        setClassData(initClass);
        reset(initClass);
        toast.success('Create class successfully');
        onSuccess();
      })
      .catch((error: any) => {
        toast.error(error?.response?.data?.message || 'Failed to create class');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onCloseAdd = () => {
    setClassData(initClass);
    reset(initClass);
    setTemplateSelected(null);
    onClose && onClose();
  };

  const handleChangeStartDate = (date: Dayjs | null) => {
    if (date) {
      const startDate = dayjs(date).format('YYYY-MM-DD');
      let recurrence = { ...classData.recurrence };
      if (classData.recurrenceValue === RECURRENCE_VALUES.WEEKLY) {
        const day = new Date(startDate).getDay();
        recurrence = {
          type: RECURRENCE_VALUES.WEEKLY,
          mon: day === 1,
          tue: day === 2,
          wed: day === 3,
          thur: day === 4,
          fri: day === 5,
          sat: day === 6,
          sun: day === 0,
          endDate: convertToUnixTime(endDate)
        };
      }
      setValue('startDate', startDate);
      setValue('recurrence', recurrence);
      setClassData({
        ...classData,
        startDate: startDate,
        recurrence
      });
    } else {
      setClassData({
        ...classData,
        startDate: ''
      });
      setValue('startDate', '');
    }
    trigger('startDate');
  };

  const handleChangeEndDate = (date: Dayjs | null) => {
    if (date) {
      if (
        dayjs(date).isSame(dayjs(classData.startDate)) ||
        dayjs(date).isBefore(dayjs(classData.startDate))
      ) {
        setError('endDate', {
          type: 'error',
          message: 'End date must be after start date'
        });
        return;
      }
      const endDate = dayjs(date).format('YYYY-MM-DD');

      let recurrence = { ...classData.recurrence };
      if (classData.recurrenceValue === RECURRENCE_VALUES.WEEKLY) {
        const day = new Date(startDate).getDay();
        recurrence = {
          type: RECURRENCE_VALUES.WEEKLY,
          mon: day === 1,
          tue: day === 2,
          wed: day === 3,
          thur: day === 4,
          fri: day === 5,
          sat: day === 6,
          sun: day === 0,
          endDate: convertToUnixTime(endDate)
        };
      }
      recurrence.endDate = convertToUnixTime(endDate);

      setValue('endDate', endDate);
      setValue('recurrence', recurrence);

      trigger('recurrence');
      setClassData({
        ...classData,
        endDate: endDate,
        recurrence
      });
    } else {
      setClassData({
        ...classData
      });
    }
    trigger('endDate');
  };

  const renderTooltipArea = () => {
    return (
      <div className="warning-conflict-tooltip">
        <div data-tooltip-id={`conflict-tooltip-area`}>
          <HiOutlineExclamationCircle />
        </div>
        <Tooltip
          id={`conflict-tooltip-area`}
          opacity={1}
          clickable={true}
          place="top"
          border={'1px solid #63636e'}
          style={{
            backgroundColor: '#1C1C24',
            borderRadius: 8,
            zIndex: 10
          }}
          render={() => {
            return (
              <div
                className="class-conflict-description"
                onClick={() => navigate(`/classes/${classConflictArea?._id}`)}
              >
                {`This ares is booked to teach the ${formatData(
                  classConflictArea?.name
                ).toUpperCase()}, ${convertRecurrenceToString(
                  classConflictArea
                )}`}
              </div>
            );
          }}
        />
      </div>
    );
  };

  const renderTooltipInstructor = () => {
    return (
      <div className="warning-conflict-tooltip">
        <div data-tooltip-id={`conflict-tooltip-calendar`}>
          <HiOutlineExclamationCircle />
        </div>
        <Tooltip
          id={`conflict-tooltip-calendar`}
          opacity={1}
          clickable={true}
          place="top"
          border={'1px solid #63636e'}
          style={{
            backgroundColor: '#1C1C24',
            borderRadius: 8,
            zIndex: 10
          }}
          render={() => {
            if (instructorNotSameLevel) {
              return (
                <div className="class-conflict-description">
                  This instructor only teaches in the levels:{' '}
                  {instructorLevels.join(', ')}.
                </div>
              );
            }

            return (
              <div
                className="class-conflict-description"
                onClick={() => navigate(`/classes/${classConflicts?._id}`)}
              >
                {`This instructor is scheduled to teach the ${formatData(
                  classConflicts?.name
                ).toUpperCase()}, ${convertRecurrenceToString(classConflicts)}`}
              </div>
            );
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className="classAddContainer">
        <div
          className={`overlay ${openAdd ? 'active' : ' '}`}
          onClick={onCloseAdd}
        ></div>
        <div className={`classAddForm ${openAdd ? 'active' : ' '}`}>
          <div className="classAddForm__header">
            <HiArrowLeft
              size={24}
              style={{ cursor: 'pointer' }}
              onClick={onCloseAdd}
            />

            <p>Add Class</p>
          </div>
          <div className="classAddForm__content disable-scroll">
            <AppCard>
              <div className="classAddForm__content-wrapper">
                <div className="classAddForm__content-wrapper-template">
                  <AppCardHeader title="TEMPLATE" />
                  <div>
                    <AppCardContent className="classAddForm__content-template">
                      <AppSelect
                        label="Template*"
                        searchable={false}
                        options={classTemplates}
                        value={selectedTemplate?._id || ''}
                        {...register('templateId')}
                        onChange={(e) => handleChangeClassTemplate(e)}
                        message={{
                          type: 'error',
                          text: errors?.templateId?.message || ''
                        }}
                        loading={loadingData}
                      />
                      <div className="classAddForm__content-template">
                        <AppSelect
                          label="Duration (min)*"
                          searchable={false}
                          disabled
                          options={CLASS_TEMPLATE_DURATIONS}
                          value={String(selectedTemplate?.duration)}
                          name="duration"
                        />
                        <AppInput
                          label="Price"
                          disabled
                          value={selectedTemplate?.price}
                        />
                      </div>
                      {!!selectedTemplate ? (
                        <AppTextArea
                          disabled
                          label="Session*"
                          value={generateTermData(selectedTemplate?.terms)}
                          name="term"
                        />
                      ) : (
                        <AppInput label="Session*" disabled value={''} />
                      )}
                      <div className="classAddForm__content-template">
                        <AppInput
                          label="Level"
                          disabled
                          value={formatData(
                            selectedTemplate?.levelBreakdown?.name ||
                              'All Levels'
                          )}
                          name="duration"
                        />
                        <div className="pick-color-container">
                          <div
                            className="pick-color"
                            style={{
                              backgroundColor:
                                selectedTemplate?.levelBreakdown?.colorCode ||
                                classData.colorCode
                            }}
                          ></div>
                        </div>
                      </div>
                    </AppCardContent>
                    <AppCardContent style={{ marginTop: '8px' }}>
                      <AppTextArea
                        label="Description"
                        disabled
                        value={selectedTemplate?.description}
                      />
                    </AppCardContent>
                  </div>
                </div>
                <div>
                  <AppCardHeader
                    title="Class information"
                    suffix={
                      <div className="classAddForm__content-wrapper-status">
                        <div style={{ display: 'flex' }}>
                          <AppToggle
                            style={{ marginRight: '10px' }}
                            value={classData.active}
                            {...register('active')}
                            onChange={() => {
                              setValue('active', !classData.active);
                              setClassData({
                                ...classData,
                                active: !classData.active
                              });
                            }}
                          />
                          Active
                        </div>
                        {classData.type === CLASS_TYPES.SERIES && (
                          <div style={{ display: 'flex' }}>
                            <AppToggle
                              style={{ marginRight: '10px' }}
                              value={classData.isPrivate}
                              {...register('isPrivate')}
                              onChange={() => {
                                setValue('isPrivate', !classData.isPrivate);
                                setClassData({
                                  ...classData,
                                  isPrivate: !classData.isPrivate
                                });
                                trigger('capacity');
                              }}
                            />
                            Private
                          </div>
                        )}
                      </div>
                    }
                    style={{ marginBottom: '20px' }}
                  />
                  <AppCardContent className="classAddForm__content-wrapper-template">
                    <div className="classAddForm__content-info">
                      <AppSelect
                        label="Instructor*"
                        disabled={!selectedTemplate}
                        options={[...instructorList]}
                        value={classData.instructorId || ''}
                        {...register('instructorId')}
                        message={{
                          type: 'error',
                          text: errors?.instructorId?.message || ''
                        }}
                        onChange={handleChange}
                        warning={
                          showWarningInstructor || instructorNotSameLevel
                        }
                        warningValue={
                          instructorNotSameLevel ? (
                            <span>Conflicts.</span>
                          ) : (
                            <span>Schedule conflicts.</span>
                          )
                        }
                        tooltipComponent={renderTooltipInstructor()}
                        loading={loadingInstructor}
                      />
                      <AppSelect
                        disabled={!selectedTemplate}
                        label="Program Type*"
                        options={PROGRAM_TYPE_OPTIONS}
                        value={classData.type}
                        {...register('type')}
                        searchable={false}
                        message={{
                          type: 'error',
                          text: errors?.type?.message || ''
                        }}
                        onChange={handleChange}
                      />
                      <AppInput
                        label="Price*"
                        disabled={!selectedTemplate}
                        {...register('price')}
                        onChange={handleChange}
                        message={{
                          type: 'error',
                          text: errors?.price?.message || ''
                        }}
                      />
                    </div>
                    <div className="class-time">
                      <div className="class-information">
                        <AppTimePicker
                          disabled={!selectedTemplate}
                          label="Start Time*"
                          {...register('startTime')}
                          value={
                            classData.startTime
                              ? dayjs(`${DATE_CONSTANT} ${classData.startTime}`)
                              : null
                          }
                          message={{
                            type: 'error',
                            text: errors?.startTime?.message || ''
                          }}
                          onChange={handleChangeStartTime}
                        />
                        <AppInput
                          label="End Time*"
                          value={classData.endTime}
                          {...register('endTime')}
                          message={{
                            type: 'error',
                            text: errors?.endTime?.message || ''
                          }}
                          disabled
                          style={{ minWidth: '130px' }}
                        />
                        <AppInput
                          label="Capacity"
                          disabled={!selectedTemplate}
                          value={classData.capacity}
                          {...register('capacity')}
                          message={{
                            type: 'error',
                            text: errors?.capacity?.message || ''
                          }}
                          onChange={handleChange}
                          type="number"
                          min={0}
                        />
                        <AppSelect
                          disabled={!selectedTemplate}
                          label="Area*"
                          searchable={false}
                          {...register('area')}
                          options={areas}
                          value={classData.area}
                          message={{
                            type: 'error',
                            text: errors?.area?.message || ''
                          }}
                          onChange={handleChange}
                          warning={showWarningArea}
                          warningValue={<span>Conflicts.</span>}
                          tooltipComponent={renderTooltipArea()}
                          loading={loadingArea}
                        />
                      </div>
                    </div>

                    <div className="class-time">
                      <div className="class-information">
                        <AppSelect
                          disabled={!selectedTemplate}
                          label="Recurrence*"
                          searchable={false}
                          options={RECURRENCE_OPTIONS}
                          value={classData.recurrenceValue || ''}
                          {...register('recurrenceValue')}
                          message={{
                            type: 'error',
                            text: errors?.recurrenceValue?.message || ''
                          }}
                          onChange={handleChangeRecurrence}
                        />
                        {[
                          RECURRENCE_VALUES.DO_NOT_REPEAT,
                          RECURRENCE_VALUES.WEEKLY,
                          RECURRENCE_VALUES.CUSTOM
                        ].includes(
                          classData.recurrenceValue as RECURRENCE_VALUES
                        ) && (
                          <AppDatePicker
                            label="Start Date*"
                            {...register('startDate')}
                            value={dayjs(classData.startDate)}
                            message={{
                              type: 'error',
                              text: errors?.startDate?.message || ''
                            }}
                            disablePast
                            onChange={(e) => handleChangeStartDate(e)}
                            shouldDisableDate={shouldDisableDate}
                            disabled={!selectedTemplate}
                          />
                        )}
                        {[
                          RECURRENCE_VALUES.DO_NOT_REPEAT,
                          RECURRENCE_VALUES.WEEKLY,
                          RECURRENCE_VALUES.CUSTOM
                        ].includes(
                          classData.recurrenceValue as RECURRENCE_VALUES
                        ) && (
                          <AppDatePicker
                            label="End Date*"
                            {...register('endDate')}
                            value={dayjs(classData.endDate)}
                            message={{
                              type: 'error',
                              text: errors?.endDate?.message || ''
                            }}
                            disablePast
                            onChange={(e) => handleChangeEndDate(e)}
                            shouldDisableDate={shouldDisableDate}
                            disabled={
                              !selectedTemplate || !selectedIntensiveProgram
                            }
                          />
                        )}
                      </div>
                    </div>
                  </AppCardContent>
                </div>
              </div>
              <RecurrenceModal
                showModalRecurrence={showModalRecurrence}
                setShowModalRecurrence={setShowModalRecurrence}
                onCloseRecurrence={onCloseRecurrence}
                recurrence={classData.recurrence}
                onSaveRecurrence={onSaveRecurrence}
              />
            </AppCard>
          </div>
          <div className="classAddForm__actions">
            <AppButton
              disabled={
                showWarningInstructor ||
                showWarningArea ||
                Object.keys(errors).length > 0 ||
                loading ||
                loadingArea ||
                loadingInstructor
              }
              onClick={handleSubmit(onSubmit)}
              isLoading={loading || loadingArea || loadingInstructor}
            >
              Save
            </AppButton>
            <AppButton variant="secondary" onClick={onCloseAdd}>
              Cancel
            </AppButton>
          </div>
        </div>
      </div>
    </>
  );
};
export default memo(AddClass);
