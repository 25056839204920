import { ChangeEvent, memo, useEffect, useState } from 'react';
import AppInput from '../../components/AppInput';
import AppButton from '../../components/AppButton';
import AppDatePicker from '../../components/AppDatePicker';
import { ISearchCalendar } from '../../types/calendar.type';
import dayjs from 'dayjs';

import './desktop.scss';

interface Props {
  handleSearch: (value: any) => void;
  showSearch: boolean;
  searchValue: string;
  searchAdvanceValue?: ISearchCalendar;
}

const SearchCalendar = ({
  handleSearch,
  showSearch,
  searchAdvanceValue,
  ...props
}: Props) => {
  const initialValue = {
    what: '',
    who: '',
    fromDate: '',
    toDate: ''
  };
  const [searchValue, setSearchValue] = useState({
    ...initialValue,
    what: props.searchValue,
    fromDate: searchAdvanceValue?.search.fromDate || '',
    toDate: searchAdvanceValue?.search.toDate || ''
  });

  useEffect(() => {
    setSearchValue({
      ...searchValue,
      what: props.searchValue,
      fromDate: searchAdvanceValue?.search.fromDate || '',
      toDate: searchAdvanceValue?.search.toDate || ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchValue, searchAdvanceValue]);
  const onChangeSearchValue = (
    event: ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    setSearchValue({ ...searchValue, [key]: event.target.value });
  };
  const onClearSearchValue = (key: string) => {
    setSearchValue({ ...searchValue, [key]: '' });
  };
  return (
    <div className={`search-component ${showSearch && 'open'}`}>
      <AppInput
        label="What"
        name="what"
        value={searchValue.what}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChangeSearchValue(event, 'what')
        }
        onClearSearch={() => onClearSearchValue('what')}
      />
      <AppInput
        label="Who"
        name="who"
        value={searchValue.who}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChangeSearchValue(event, 'who')
        }
        onClearSearch={() => onClearSearchValue('who')}
      />
      <div className="date-search">
        <div style={{ width: '99%', paddingRight: '1%' }}>
          <AppDatePicker
            label="From date"
            value={dayjs(searchValue.fromDate)}
            onChange={(e: any) => {
              setSearchValue({
                ...searchValue,
                fromDate: dayjs(e).format('YYYY-MM-DD')
              });
            }}
          />
        </div>
        <div style={{ width: '99%', paddingLeft: '1%' }}>
          <AppDatePicker
            label="To date"
            value={dayjs(searchValue.toDate)}
            onChange={(e: any) => {
              setSearchValue({
                ...searchValue,
                toDate: dayjs(e).format('YYYY-MM-DD')
              });
            }}
          />
        </div>
      </div>
      <div className="footer-search">
        <AppButton
          variant="secondary"
          buttonSize="small"
          className="search-btn"
          onClick={() => {
            setSearchValue(initialValue);
            handleSearch(initialValue);
          }}
          style={{ marginRight: '10px' }}
        >
          Reset
        </AppButton>
        <AppButton
          buttonSize="small"
          className="search-btn"
          onClick={() => handleSearch(searchValue)}
        >
          Search
        </AppButton>
      </div>
    </div>
  );
};
export default memo(SearchCalendar);
