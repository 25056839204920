import { Navigate } from 'react-router-dom';
import Layout from 'pages/Layout';
import Students from 'pages/students/StudentList';
import StudentDetail from 'pages/students/StudentDetail';
import Login from 'pages/Login';
import InstructorDetail from 'pages/instructors/InstructorDetail';
import InstructorList from 'pages/instructors/InstructorList';
import Classes from 'pages/classes/ClassList';
import ClassesDetail from 'pages/classes/ClassDetail';
import StaffList from 'pages/staffs/StaffList';
import StaffDetail from 'pages/staffs/StaffDetail';
import StaffAuditReport from 'pages/staffs/StaffAuditReport';
import ClassTemplate from 'pages/classes/ClassTemplateList';
import ClassTemplateDetail from 'pages/classes/ClassTemplateDetail';
import LevelsBreakDownList from 'pages/levels/LevelsBreakdownList';
import HealthQuestionnaireList from 'pages/healthQuestionnaire/HealthQuestionnaireList';
import HealthQuestionnaireDetail from 'pages/healthQuestionnaire/HealthQuestionnaireDetail';
import AuditLogList from 'pages/auditLogs/AuditLogList';
import UserGroupList from 'pages/userGroups/UserGroupList';
import UserGroupDetail from 'pages/userGroups/UserGroupDetail';
import ActivityList from 'pages/activities/ActivityList';
import ResponsiblePersonList from 'pages/responsiblePerson/ResponsiblePersonList';
import LevelsBreakdownDetail from 'pages/levels/LevelsBreakdownDetail';
import ResponsiblePersonDetail from 'pages/responsiblePerson/ResponsiblePersonDetail';
import RollCalls from 'pages/classes/RollCalls';
import RollCallDetail from 'pages/classes/RollCalls/RollCallsDetail';
import { PERMISSION } from 'common/enums/permission.enum';
import { IRoute } from 'common/interfaces/index';
import Enrollment from 'pages/students/Enrollment';
import AssessmentList from 'pages/assessments/AssessmentList';
import Terms from 'pages/term/termList';
import TermDetail from 'pages/term/termDetail';
import ComingSoon from 'pages/ComingSoon';
import BulkManagement from 'pages/bulkManagements';
import LocationList from 'pages/location/LocationList';
import LocationDetail from 'pages/location/LocationDetail';
import LocationAreaDetail from 'pages/location/LocationDetail/LocationArea/LocationAreaDetail';
import LocationPinDetail from 'pages/location/LocationDetail/LocationPin/LocationPinDetail';
import NotificationList from 'pages/notifications/NotificationList';
import ReportTimetableByTeacher from 'pages/reports/ReportTimetableByTeacher';
import ReportExportFamilyDetails from 'pages/reports/ReportExportFamilyDetails';
import ReportMember from 'pages/reports/ReportMember';
import ReportOutstanding from 'pages/reports/ReportOutstanding';
import ReportAssessmentOutcome from 'pages/reports/ReportAssessmentOutcome';
import ReportDateOfLastAssessment from 'pages/reports/ReportDateOfLastAssessment';
import NotificationSettings from 'pages/notifications/NotificationSettings';
import ReportList from 'pages/reports/ReportList';
import { REPORT_PATH } from 'pages/reports/constant';
import { REPORT_LINK } from 'pages/reports/enum';
import ReportStudentBookingSummary from 'pages/reports/ReportStudentBookingSummary';
import ReportPeriodSummary from 'pages/reports/ReportPeriodSummary';
import DashboardPage from 'pages/dashboard/DashboardPage';
import ReportCreditAdjustment from 'pages/reports/ReportCreditAdjustment';
import MoveBookingFlow from 'pages/moveBookingFlow';
import ChangePaymentMethod from 'pages/changePaymentMethod';
import VoucherList from 'pages/vouchers';
import VoucherDetail from 'pages/vouchers/voucherDetail';
import ReportGraduatedAndNotMoved from 'pages/reports/ReportGraduatedAndNotMoved';
import ReportNonAttendance from 'pages/reports/ReportNonAttendance';
import ReportAbandonedCartList from 'pages/reports/ReportAbandonedCartList';
import ReportGst from 'pages/reports/ReportGst';
import ReportDiscountedEnrolment from 'pages/reports/ReportDiscountedEnrolment';
import { USER_TYPE } from 'common/enums/user.enum';

export const publicIndexRoutes: IRoute[] = [
  {
    path: '/login',
    component: <Login />,
    isPublic: true
  },
  {
    path: '*',
    component: <Navigate to="/login" replace />,
    isPublic: true
  }
];

export const privateIndexRoutes: IRoute[] = [
  {
    layout: <Layout />,
    path: '/',
    routes: [
      {
        title: 'Dashboard',
        path: '/',
        component: <DashboardPage />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR]
      },
      {
        title: 'Students',
        path: '/students',
        component: <Students />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_STUDENT
      },
      {
        title: 'Student Detail',
        path: '/students/:id',
        component: <StudentDetail />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.VIEW_DETAIL_STUDENT
      },
      {
        title: 'Responsible Person',
        path: '/responsible-person',
        component: <ResponsiblePersonList />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_RESPONSIBLE_PERSON
      },
      {
        title: 'Responsible Person Detail',
        path: '/responsible-person/:id',
        component: <ResponsiblePersonDetail />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.VIEW_DETAIL_RESPONSIBLE_PERSON
      },
      {
        title: 'Classes',
        path: '/classes',
        component: <Classes />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_CLASS
      },
      {
        title: 'Classes',
        path: '/classes/:id',
        component: <ClassesDetail />,
        role: [
          USER_TYPE.STAFF,
          USER_TYPE.INSTRUCTOR,
          USER_TYPE.RESPONSIBLE_PERSON,
          USER_TYPE.STUDENT
        ],
        permission: PERMISSION.VIEW_DETAIL_CLASS
      },
      {
        title: 'Roll calls detail',
        path: '/roll-calls/:id',
        component: <RollCallDetail />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.DETAIL_GROUP_ROLL_CALL
      },
      {
        title: 'Roll calls',
        path: '/roll-calls',
        component: <RollCalls />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_GROUP_ROLL_CALL
      },
      {
        title: 'Class Template',
        path: '/class-template',
        component: <ClassTemplate />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_CLASS_TEMPLATE
      },
      {
        title: 'Class Template Details',
        path: '/class-template/:id',
        component: <ClassTemplateDetail />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.VIEW_DETAIL_CLASS_TEMPLATE
      },
      {
        title: 'Levels Breakdown',
        path: '/levels-breakdown',
        component: <LevelsBreakDownList />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_LEVEL_BREAKDOWN
      },
      {
        title: 'Levels Breakdown Details',
        path: '/levels-breakdown/:id',
        component: <LevelsBreakdownDetail />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.VIEW_DETAIL_LEVEL_BREAKDOWN
      },
      {
        title: 'Trials',
        path: '/trials',
        component: <ComingSoon />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_TRIAL
      },
      {
        title: 'Assessments',
        path: '/assessments',
        component: <AssessmentList />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR]
      },
      {
        title: 'Instructors',
        path: '/instructors',
        component: <InstructorList />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_INSTRUCTOR
      },
      {
        title: 'Instructor Details',
        path: '/instructors/:id',
        component: <InstructorDetail />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.VIEW_DETAIL_INSTRUCTOR
      },
      {
        title: 'Staffs',
        path: '/staff',
        component: <StaffList />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_STAFF
      },
      {
        title: 'StaffDetail',
        path: '/staff/:id',
        component: <StaffDetail />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.VIEW_DETAIL_STAFF
      },
      {
        title: 'StaffAuditReport',
        path: '/staff-audit-report/:id',
        component: <StaffAuditReport />,
        role: [USER_TYPE.STAFF],
        permission: PERMISSION.VIEW_DETAIL_STAFF
      },
      {
        title: 'Membership',
        path: '/membership',
        component: <ComingSoon />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_REPORT
      },
      {
        title: 'Reporting',
        path: REPORT_PATH,
        component: <ReportList />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_REPORT
      },
      {
        title: 'ReportStudentBookingSummary',
        path: `${REPORT_PATH}/${REPORT_LINK.STUDENT_BOOKING_SUMMARY}`,
        component: <ReportStudentBookingSummary />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_REPORT
      },
      {
        title: 'ReportPeriodSummary',
        path: `${REPORT_PATH}/${REPORT_LINK.PERIOD_SUMMARY_REPORT}`,
        component: <ReportPeriodSummary />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_REPORT
      },
      {
        title: 'ReportMember',
        path: `${REPORT_PATH}/${REPORT_LINK.MEMBER_REPORT}`,
        component: <ReportMember />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_REPORT
      },
      {
        title: 'ReportExportFamilyDetails',
        path: `${REPORT_PATH}/${REPORT_LINK.EXPORT_FAMILY_DETAILS}`,
        component: <ReportExportFamilyDetails />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_REPORT
      },
      {
        title: 'ReportTimetableByTeacher',
        path: `${REPORT_PATH}/${REPORT_LINK.TIMETABLE_BY_TEACHER}`,
        component: <ReportTimetableByTeacher />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_REPORT
      },
      {
        title: 'ReportOutstanding',
        path: `${REPORT_PATH}/${REPORT_LINK.OUTSTANDINGS}`,
        component: <ReportOutstanding />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_REPORT
      },
      {
        title: 'ReportCreditAdjustment',
        path: `${REPORT_PATH}/${REPORT_LINK.CREDIT_ADJUSTMENT_REPORT}`,
        component: <ReportCreditAdjustment />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_REPORT
      },
      {
        title: 'ReportAssessmentOutcome',
        path: `${REPORT_PATH}/${REPORT_LINK.ASSESSMENT_OUTCOME_REPORT}`,
        component: <ReportAssessmentOutcome />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_REPORT
      },
      {
        title: 'ReportDateOfLastAssessment',
        path: `${REPORT_PATH}/${REPORT_LINK.DATE_OF_LAST_ASSESSMENT_REPORT}`,
        component: <ReportDateOfLastAssessment />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_REPORT
      },
      {
        title: 'ReportGraduatedAndNotMoved',
        path: `${REPORT_PATH}/${REPORT_LINK.GRADUATED_AND_NOT_MOVED}`,
        component: <ReportGraduatedAndNotMoved />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_REPORT
      },
      {
        title: 'ReportDiscountedEnrolment',
        path: `${REPORT_PATH}/${REPORT_LINK.DISCOUNTED_ENROLMENT_REPORT}`,
        component: <ReportDiscountedEnrolment />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.DISCOUNT_ENROLLMENT_REPORT
      },
      {
        title: 'ReportNonAttendance',
        path: `${REPORT_PATH}/${REPORT_LINK.NOT_ATTENDANCE_REPORT}`,
        component: <ReportNonAttendance />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_REPORT
      },
      {
        title: 'ReportAbandonedCartList',
        path: `${REPORT_PATH}/${REPORT_LINK.ABANDONED_CARD_LIST}`,
        component: <ReportAbandonedCartList />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.ABANDONED_CART_REPORT
      },
      {
        title: 'ReportGst',
        path: `${REPORT_PATH}/${REPORT_LINK.GST_REPORT}`,
        component: <ReportGst />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.GST_REPORT
      },
      {
        title: 'Health Questionnaire List',
        path: '/health-questionnaire',
        component: <HealthQuestionnaireList />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_HEALTH_QUESTIONNAIRE
      },
      {
        title: 'Health Questionnaire Details',
        path: '/health-questionnaire/:id',
        component: <HealthQuestionnaireDetail />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.VIEW_DETAIL_HEALTH_QUESTIONNAIRE
      },
      {
        title: 'User Groups',
        path: '/user-groups',
        component: <UserGroupList />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_ROLE
      },
      {
        title: 'User Group Detail',
        path: '/user-groups/:id',
        component: <UserGroupDetail />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.VIEW_DETAIL_ROLE
      },
      {
        title: 'Audit Log',
        path: '/audit-log',
        component: <AuditLogList />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_AUDIT_LOG
      },
      {
        title: 'Activities',
        path: '/activity',
        component: <ActivityList />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_ACTIVITY
      },
      {
        title: 'Booking',
        path: '/enrolment/:RPId/:studentIds',
        component: <Enrollment />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.BOOKING_CLASS
      },
      {
        title: 'Location Management',
        path: '/locations',
        component: <LocationList />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_LOCATION
      },
      {
        title: 'Location Details',
        path: '/locations/:id',
        component: <LocationDetail />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.VIEW_DETAIL_LOCATION
      },
      {
        title: 'Area Details',
        path: '/location-areas/:id',
        component: <LocationAreaDetail />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.VIEW_DETAIL_LOCATION_AREA
      },
      {
        title: 'Pin Details',
        path: '/location-pins/:id',
        component: <LocationPinDetail />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.VIEW_DETAIL_LOCATION_PIN
      },
      {
        title: 'Session',
        path: '/terms',
        component: <Terms />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_TERM
      },
      {
        title: 'Session Detail',
        path: '/terms/:id',
        component: <TermDetail />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.DETAIL_TERM
      },
      {
        title: 'Bulk Management',
        path: '/bulk-management',
        component: <BulkManagement />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR]
      },
      {
        title: 'Vouchers',
        path: '/vouchers',
        component: <VoucherList />,
        permission: PERMISSION.LIST_VOUCHER,
        role: [USER_TYPE.STAFF]
      },
      {
        title: 'Voucher Detail',
        path: '/vouchers/:id',
        component: <VoucherDetail />,
        permission: PERMISSION.VIEW_DETAIL_VOUCHER,
        role: [USER_TYPE.STAFF]
      },
      {
        title: 'Holiday',
        path: '/holiday',
        component: <Terms />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_TERM
      },
      {
        title: 'Holiday Details',
        path: '/holiday/:id',
        component: <TermDetail />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.DETAIL_TERM
      },
      {
        title: 'Notifications',
        path: '/notifications',
        component: <NotificationList />,
        role: [USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR],
        permission: PERMISSION.LIST_NOTIFICATION
      },
      {
        title: 'Notification settings',
        path: '/notification-settings',
        component: <NotificationSettings />,
        role: [USER_TYPE.RESPONSIBLE_PERSON, USER_TYPE.STUDENT]
      },
      {
        title: 'Move Booking',
        path: '/move-booking/:RPId/:studentId/:bookClassId',
        component: <MoveBookingFlow />,
        role: [USER_TYPE.STAFF]
      },
      {
        title: 'Change Payment Method',
        path: '/change-payment-method/:RPId/:studentId/:bookClassId',
        component: <ChangePaymentMethod />,
        role: [USER_TYPE.STAFF]
      },
      {
        title: '',
        path: '*',
        component: <Navigate to="/" replace />,
        role: []
      }
    ]
  }
];
