import React, { useCallback, useEffect, useMemo } from 'react';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppCard from 'common/components/AppCard';
import AppInputSearch from 'common/components/AppInputSearch';
import AppTable from 'common/components/AppTable';
import {
  HiOutlineIdentification,
  HiOutlineTrash,
  HiPlus
} from 'react-icons/hi';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import './desktop.scss';
import AppButton from 'common/components/AppButton';
import { createColumnHelper } from '@tanstack/react-table';
import { ITerm } from 'common/interfaces/term.interface';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import TermAddForm from './TermAddForm';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToast } from 'context/ToastContext';
import { getTerms } from 'services/term.service';
import useDebounce from 'common/hooks/useDebounce';
import { useBrandLocation } from 'context/BrandLocationContext';
import TermDeleteModal from './TermDeleteModal';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { TERM_TYPE } from 'common/enums/term.enum';

const Terms: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { selectedLocation } = useBrandLocation();
  const columnHelper = createColumnHelper<ITerm>();
  const [search, setSearch] = React.useState<string>('');
  const [openAddTerm, setOpenAddTerm] = React.useState<boolean>(false);
  const [terms, setTerms] = React.useState<ITerm[]>([]);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(10);
  const [total, setTotal] = React.useState<number>(-1);
  const [termId, setTermId] = React.useState<string>('');

  const isHoliday = useMemo(
    () => location.pathname.includes('/holiday'),
    [location.pathname]
  );
  const searchDebounce = useDebounce(search, 500);

  const fetchTerms = useCallback(async () => {
    if (!selectedLocation?._id) return;
    try {
      const response = await getTerms(
        page,
        limit,
        selectedLocation?._id,
        searchDebounce,
        isHoliday ? [TERM_TYPE.STOP_BILLING] : [TERM_TYPE.TERM]
      );
      setTerms(response.data.data.data);
      setTotal(response.data.data.total);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Failed to fetch terms');
      setTerms([]);
      setTotal(-1);
    }
    // eslint-disable-next-line
  }, [page, limit, searchDebounce, selectedLocation?._id, location]);

  useEffect(() => {
    fetchTerms();
  }, [fetchTerms]);

  useEffect(() => {
    setPage(1);
  }, [selectedLocation?._id]);

  const goToDetail = useCallback(
    (id: string) => {
      if (isHoliday) {
        navigate(`/holiday/${id}`);
      } else navigate(`/terms/${id}`);
    },
    [isHoliday, navigate]
  );

  const columns = useMemo(() => {
    const initColumns = [
      columnHelper.accessor('name', {
        header: () => <span>NAME</span>,
        cell: (info) => formatData(info.getValue())
      }),
      columnHelper.accessor('startDate', {
        header: () => <span>START DATE</span>,
        cell: (info) => formatDate(info.getValue())
      }),
      columnHelper.accessor('endDate', {
        header: () => <span>END DATE</span>,
        cell: (info) => formatDate(info.getValue())
      }),
      columnHelper.accessor('type', {
        header: () => <span>TYPE</span>,
        cell: (info) => {
          if (info.getValue() === TERM_TYPE.TERM) {
            return 'SESSION';
          }
          return formatData(info.getValue().replaceAll('_', ' '));
        }
      }),
      columnHelper.accessor('templateInUsed', {
        header: () => <span>TEMPLATE IN USES</span>,
        cell: (info) => formatData(info.getValue())
      }),
      columnHelper.accessor('_id', {
        header: () => (
          <span
            style={{
              display: 'block',
              textAlign: 'center'
            }}
          >
            Action
          </span>
        ),
        cell: (info) => {
          return (
            <div className="buttonGroupsContainer">
              <div className="buttonGroups">
                <PermissionWrapper permission={PERMISSION.DETAIL_TERM}>
                  <div onClick={() => goToDetail(info.getValue())}>
                    <HiOutlineIdentification />
                  </div>
                </PermissionWrapper>
                {info.row.original?.templateInUsed === 0 && (
                  <PermissionWrapper permission={PERMISSION.DELETE_TERM}>
                    <div onClick={() => setTermId(info.getValue())}>
                      <HiOutlineTrash />
                    </div>
                  </PermissionWrapper>
                )}
              </div>
            </div>
          );
        }
      })
    ];
    if (isHoliday) {
      const result = initColumns.slice(0, 4);
      return [...result, initColumns[5]];
    }
    return initColumns;
  }, [isHoliday, columnHelper, goToDetail, setTermId]);

  const onChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    [setSearch]
  );

  const onClearSearch = useCallback(() => {
    setSearch('');
  }, [setSearch]);

  const handleCloseAddTerm = useCallback(() => {
    setOpenAddTerm(false);
  }, [setOpenAddTerm]);

  const handleOpenAddForm = useCallback(() => {
    setOpenAddTerm(true);
  }, [setOpenAddTerm]);

  const handleFinishAddTerm = useCallback(() => {
    setOpenAddTerm(false);
    fetchTerms();
  }, [setOpenAddTerm, fetchTerms]);

  const onCancelDeleteTerm = useCallback(() => {
    setTermId('');
  }, [setTermId]);

  const onDeleteSuccess = useCallback(() => {
    setTermId('');
    fetchTerms();
  }, [setTermId, fetchTerms]);

  return (
    <div className="termsPage">
      {/* ADD */}
      <PermissionWrapper permission={PERMISSION.CREATE_TERM}>
        {openAddTerm && (
          <TermAddForm
            open={openAddTerm}
            onClose={handleCloseAddTerm}
            onSuccess={handleFinishAddTerm}
          />
        )}
      </PermissionWrapper>
      {/* DELETE */}
      {!!termId && (
        <PermissionWrapper permission={PERMISSION.DELETE_TERM}>
          <TermDeleteModal
            termId={termId}
            onClose={onCancelDeleteTerm}
            onSuccess={onDeleteSuccess}
          />
        </PermissionWrapper>
      )}
      {/* MAIN */}
      <AppBreadCrumb
        items={[
          {
            name: isHoliday
              ? 'Public Holiday Management'
              : 'Session Management',
            href: isHoliday ? '/holiday' : '/terms'
          }
        ]}
      />
      <div className="layoutContainer termList">
        <AppCard>
          <div className="termList-header">
            <h3>{isHoliday ? 'Public Holidays' : 'Session(s)'}</h3>
            <div className="search-term">
              <AppInputSearch
                type="text"
                value={search}
                onChange={onChangeSearch}
                placeholder="Name"
                onClearSearch={onClearSearch}
                startIcon={<HiMagnifyingGlass />}
              />
              <PermissionWrapper permission={PERMISSION.CREATE_TERM}>
                <AppButton
                  variant="primary"
                  buttonSize="small"
                  onClick={handleOpenAddForm}
                >
                  <div className="plusIcon">
                    <HiPlus />
                  </div>
                  <p>{isHoliday ? ' Holiday' : ' Session'}</p>
                </AppButton>
              </PermissionWrapper>
            </div>
          </div>
          <div className="termList-content">
            <AppTable
              columns={columns}
              data={terms}
              pagination={{
                index: page,
                size: limit,
                total: total
              }}
              onChangePage={(index: number, size: number) => {
                setPage(index);
                setLimit(size);
              }}
            />
          </div>
        </AppCard>
      </div>
    </div>
  );
};

export default Terms;
