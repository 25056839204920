import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import AppPhoneNumberInput from 'common/components/AppPhoneNumberInput';
import AppSelect from 'common/components/AppSelect';
import { IStaff } from 'common/interfaces/staff.interface';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import yupStaff from 'validators/staff.validator';
import {
  HiOutlinePencilSquare,
  HiOutlineXCircle,
  HiOutlineBookmark
} from 'react-icons/hi2';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { STATE_OPTIONS } from 'common/constants/index';
import { useParams } from 'react-router-dom';
import { useToast } from 'context/ToastContext';
import { BeatLoader } from 'react-spinners';
import { UpdateStaffContactDetailDTO } from 'DTOs/staff.dto';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { updateStaffContactDetail } from 'services/staff.service';
import {
  formatData,
  formatSecretPhoneNumber
} from 'common/helpers/dataFormat.helper';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi';

const validationSchema = yupStaff.OBJECT({
  email: yupStaff.STAFF_EMAIL_ADDRESS,
  phoneNumber: yupStaff.STAFF_MOBILE_NUMBER,
  address: yupStaff.STAFF_ADDRESS,
  suburb: yupStaff.STAFF_SUBURB,
  state: yupStaff.STAFF_STATE,
  postalCode: yupStaff.STAFF_POSTCODE,
  country: yupStaff.STAFF_COUNTRY
});

interface IContactDetailsProps {
  data: IStaff;
  onSuccess: () => void;
}

type ShowPhoneType = { idx: number; value: boolean };

const ContactDetails = (props: IContactDetailsProps) => {
  const { data, onSuccess } = props;

  const params = useParams();
  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  const [staff, setStaff] = useState<UpdateStaffContactDetailDTO>({
    ...data
  });

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [showPhone, setShowPhone] = useState<ShowPhoneType[]>([
    {
      idx: 0,
      value: false
    }
  ]);

  const handleShowPhone = (phoneIndex: number) => {
    setShowPhone((pre: ShowPhoneType[]) => {
      if (pre?.filter((ob: ShowPhoneType) => ob.idx === phoneIndex)?.length) {
        return pre?.map((obj: ShowPhoneType) => {
          if (obj?.idx === phoneIndex) {
            return { ...obj, value: !obj?.value };
          }
          return obj;
        });
      }

      return [...pre, { idx: phoneIndex, value: true }];
    });
  };

  const phoneNumberError = useRef<string>('');

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<UpdateStaffContactDetailDTO>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<UpdateStaffContactDetailDTO>,
    defaultValues: staff
  });

  const onOpenEdit = () => {
    setIsEdit(true);
  };

  const onCloseEdit = () => {
    setIsEdit(false);
    setStaff({ ...data });
    reset({ ...data });
    phoneNumberError.current = '';
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStaff({
        ...staff,
        [event.target.name]: event.target.value
      });
      // @ts-ignore
      setValue(event.target.name, event.target.value);
      // @ts-ignore
      trigger(event.target.name);
    },
    [staff, setValue, trigger]
  );

  const handleChangePhoneNumber = useCallback(
    (value: string) => {
      setStaff({
        ...staff,
        phoneNumber: value
      });
      setValue('phoneNumber', value);
      trigger('phoneNumber');
      phoneNumberError.current = isValidPhoneNumber(value || '')
        ? ''
        : 'Invalid phone number';
    },
    [setValue, staff, trigger]
  );

  const onSubmit = async (data: UpdateStaffContactDetailDTO) => {
    // Custom error
    if (!!phoneNumberError.current) return;
    setLoading(true);
    try {
      const payload: UpdateStaffContactDetailDTO = {
        phoneNumber: data.phoneNumber,
        address: data.address,
        suburb: data.suburb,
        state: data.state,
        postalCode: data.postalCode,
        country: data.country
      };
      await updateStaffContactDetail(params?.id || '', payload);
      onSuccess();
      toast.success('Saved');
      setIsEdit(false);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'Failed to update staff contact details'
      );
    } finally {
      setLoading(false);
    }
  };

  const __renderIcons = useMemo((): React.ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSubmit)}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
    // eslint-disable-next-line
  }, [isEdit, onCloseEdit, loading]);

  const __renderContent = useMemo((): React.ReactNode => {
    if (isEdit) {
      return (
        <>
          <AppPhoneNumberInput
            label="Mobile Number*"
            {...register('phoneNumber')}
            value={staff.phoneNumber}
            onChange={handleChangePhoneNumber}
            message={{
              type: 'error',
              text:
                errors?.phoneNumber?.message || phoneNumberError.current || ''
            }}
          />
          <AppInput
            label="Email Address*"
            {...register('email')}
            value={staff.email}
            disabled
          />
          <AppInput
            label="Address*"
            {...register('address')}
            value={staff.address}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.address?.message || ''
            }}
          />
          <AppInput
            label="Suburb*"
            {...register('suburb')}
            value={staff.suburb}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.suburb?.message || ''
            }}
          />
          <AppSelect
            label="State*"
            options={STATE_OPTIONS}
            {...register('state')}
            value={staff.state}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.state?.message || ''
            }}
          />
          <AppInput
            label="Postcode*"
            {...register('postalCode')}
            value={staff.postalCode}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.postalCode?.message || ''
            }}
          />
          <AppInput
            label="Country*"
            {...register('country')}
            value={staff.country}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.country?.message || ''
            }}
          />
        </>
      );
    }
    return (
      <>
        <AppCardContentItem subtitle="Mobile Number">
          <span className="custom_show_phone">
            {!!showPhone.filter(
              (obj: ShowPhoneType) => obj.idx === 0 && !!obj.value
            )?.length ? (
              <a href={`tel:${staff?.phoneNumber}`}>{staff?.phoneNumber} </a>
            ) : (
              <span>{formatSecretPhoneNumber(staff?.phoneNumber)}</span>
            )}

            <button onClick={() => handleShowPhone(0)}>
              {!!showPhone.filter(
                (obj: ShowPhoneType) => obj.idx === 0 && !!obj.value
              )?.length ? (
                <HiOutlineEye fontSize={20} />
              ) : (
                <HiOutlineEyeOff fontSize={20} />
              )}
            </button>
          </span>
        </AppCardContentItem>
        <AppCardContentItem
          subtitle="Email Address"
          title={formatData(staff.email)}
        />
        <AppCardContentItem
          subtitle="Address"
          title={formatData(staff.address)}
        />
        <AppCardContentItem
          subtitle="Suburb"
          title={formatData(staff.suburb)}
        />
        <AppCardContentItem subtitle="State" title={formatData(staff.state)} />
        <AppCardContentItem
          subtitle="Postcode"
          title={formatData(staff.postalCode)}
        />
        <AppCardContentItem
          subtitle="Country"
          title={formatData(staff.country)}
        />
      </>
    );
  }, [isEdit, handleChange, handleChangePhoneNumber, register, errors, staff, showPhone]);

  return (
    <section className="contactDetails">
      <AppCard>
        <AppCardHeader
          title="Contact Details"
          suffix={
            <PermissionWrapper
              permission={[
                PERMISSION.UPDATE_STAFF,
                PERMISSION.UPDATE_STAFF_CONTACT_DETAIL
              ]}
            >
              {__renderIcons}
            </PermissionWrapper>
          }
        />
        <AppCardContent
          className={`cardContent ${isEdit ? 'cardContent-edit' : ''}`}
        >
          {__renderContent}
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default memo(ContactDetails);
