// TRACKING BOOK ON STAFF OR MEMBER PORTAL
export enum BOOKING_ON {
  STAFF_PORTAL = 'staff_portal',
  MEMBER_PORTAL = 'member_portal'
}

export enum PAYMENT_VALUE {
  IDLE = '',
  UPFRONT = 'upfront_payment',
  DIRECT_DEBIT = 'direct_debit',
  MAKE_CREDIT = 'credit'
}

export enum PAYMENT_METHOD {
  VIVA_PAY = 'viva_pay',
  VISA_CARD = 'visa_card',
  MASTER_CARD = 'master_card',
  SAMSUNG_PAY = 'samsung_pay',
  APPLE_PAY = 'apple_pay',
  PAYPAL = 'paypal',
  ALIPAY = 'alipay',
  GOOGLE_PAY = 'google_pay',
  CASH = 'cash',
  NONE = ''
}

export enum BOOKING_TYPE {
  NONE = '',
  CASUAL_CLASS = 'casual_class',
  ONGOING_CLASS = 'ongoing_class',
  MAKE_UP = 'make_up',
  HOLIDAY_PROGRAM = 'holiday_program',
  ASSESSMENT_TRIAL = 'assessment_trial'
}

export enum BOOKING_ERROR_TYPE {
  NONE = '',
  NO_SLOT = 'There are no slots available',
  NO_CREDIT = 'There are no credit available'
}
