import React from 'react';
import AppInput from 'common/components/AppInput';
import LevelMilestoneCard from '../../../components/LevelMilestoneCard';
import { CreateSkillDTO } from 'DTOs/levelBreakdown.dto';
import { HiXMark } from 'react-icons/hi2';
import LevelMilestoneCardAdd from 'pages/levels/components/LevelMilestoneCardAdd';
import './desktop.scss';

interface ILevelSkillAddProps {
  skill: CreateSkillDTO;
  onChangeSkillName: (value: string) => void;
  onRemoveSkill: (skillPosition: number) => void;
  onAddMilestone: (value: CreateSkillDTO) => void;
  onRemoveMilestone: (milestonePosition: number) => void;
  onChangeMilestone: (value: string, milestonePosition: number) => void;

  canBeRemoved: boolean;
  index: number;
}

const LevelSkillAdd = (props: ILevelSkillAddProps) => {
  const {
    skill,
    onChangeSkillName,
    onRemoveSkill,
    onAddMilestone,
    onRemoveMilestone,
    onChangeMilestone,
    canBeRemoved,
    index
  } = props;

  return (
    <div className="level__skill__add">
      <div className="level__skill__add__form">
        <AppInput
          value={skill.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeSkillName(event.target.value)
          }
          label="Skill of the level*"
          id={`create-skill-of-the-level-${index}`}
          message={
            skill?.error
              ? {
                  type: 'error',
                  text: skill.error
                }
              : undefined
          }
        />
        {canBeRemoved && (
          <div
            className="level__skill__add__form-remove"
            onClick={() => onRemoveSkill(skill.position)}
          >
            <HiXMark size={22} />
          </div>
        )}
      </div>

      <div className="level__skill__add__milestones">
        {skill.milestones.length > 0 &&
          skill.milestones.map((milestone) => {
            return (
              <LevelMilestoneCard
                key={milestone.position}
                milestone={milestone}
                onChangeMilestone={onChangeMilestone}
                onRemoveMilestone={onRemoveMilestone}
                errorMessage={milestone.error}
              />
            );
          })}

        <div onClick={() => onAddMilestone(skill)}>
          <LevelMilestoneCardAdd />
        </div>
      </div>
    </div>
  );
};

export default React.memo(LevelSkillAdd);
