import markerSDK from '@marker.io/browser';

const MARKER_IO_PROJECT_ID = process.env.REACT_APP_MARKER_IO_PROJECT_ID;

async function loadMarkerWidget() {
  try {
    return markerSDK.loadWidget({ project: MARKER_IO_PROJECT_ID as string, source: 'snippet' });
  } catch (error) {
    console.error(error)
  }
}

loadMarkerWidget()
