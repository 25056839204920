import {
  CreateNotificationForResponsiblePersonPayloadDTO,
  CreateNotificationPayloadDTO,
  UpdateNotificationPayloadDTO
} from 'DTOs/notification.dto';
import API from './API';
import { IStudentListFilter } from 'common/interfaces/student.interface';
const NOTIFICATIONS_ENDPOINT = `/notifications`;

export const getNotifications = (params: {
  page: number;
  limit: number;
  keyword?: string;
  responsiblePersonId?: string;
  studentId?: string;
}) => {
  return API.get(`${NOTIFICATIONS_ENDPOINT}`, params);
};
export const getNotificationsForResponsiblePerson = (params: {
  page: number;
  limit: number;
  keyword?: string;
  responsiblePersonId?: string;
}) => {
  return API.get(`${NOTIFICATIONS_ENDPOINT}/filter-responsible-person`, params);
};
export const getNotificationsActivity = (params: IStudentListFilter) => {
  return API.get(`${NOTIFICATIONS_ENDPOINT}/filter-activity`, params);
};

export const createNotification = (payload: CreateNotificationPayloadDTO) => {
  return API.post(`${NOTIFICATIONS_ENDPOINT}`, {
    body: payload
  });
};

export const createNotificationForRP = (
  payload: CreateNotificationForResponsiblePersonPayloadDTO
) => {
  return API.post(`${NOTIFICATIONS_ENDPOINT}/responsible-person`, {
    body: payload
  });
};

export const getNotificationDetail = async (notificationId: string) => {
  const response = await API.get(`${NOTIFICATIONS_ENDPOINT}/${notificationId}`);
  return response.data.data;
};

export const updateNotification = (
  notificationId: string,
  body: UpdateNotificationPayloadDTO
) => {
  return API.patch(`${NOTIFICATIONS_ENDPOINT}/${notificationId}`, {
    body
  });
};
