import AppModal, {
  AppModalFormContent,
  AppModalFormTitle
} from 'common/components/AppModal';
import { HiMiniXMark } from 'react-icons/hi2';
import { AppCardContentItem, AppCardHeader } from 'common/components/AppCard';
import {
  formatData,
  formatDate,
  formatTime
} from 'common/helpers/dataFormat.helper';
import { ILog } from 'common/interfaces/log.interface';
import { HiOutlineArrowDown } from 'react-icons/hi';
import { isArray, isEqual } from 'lodash';

interface IUpdateAuditLogModalProps {
  log: ILog;
  open: boolean;
  onClose: () => void;
}

enum FIELD_NAME {
  // -- emergencyContacts -- DATA: OLD/NEW VALUE => OBJECT
  EMERGENCY_CONTACT = 'emergencyContacts'
  // THE REST => VALUE => STRING
}

const redundantFields = [
  '_id',
  'createdBy',
  'studentId',
  '__v',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'updatedBy'
];

const timeFields = ['joiningDate', 'dob'];

const formattedChangedFields = (
  oldValue: Array<Record<string, any>>,
  newValue: Array<Record<string, any>>
): {
  new: Array<Record<string, any>>;
  old: Array<Record<string, any>>;
} => {
  let resultOldValue: Array<Record<string, any>> = [];
  let resultNewValue: Array<Record<string, any>> = [];

  // CHECK UPDATE VALUES
  if (oldValue.length === newValue.length) {
    for (let index = 0; index < oldValue.length; index++) {
      const oldElement = { ...oldValue[index] };
      const newElement = { ...newValue[index] };

      if (!isEqual(oldElement, newElement)) {
        for (const iterator in oldElement) {
          if (
            oldElement[iterator] === newElement[iterator] ||
            formatDate(oldElement[iterator]) ===
              formatDate(newElement[iterator])
          ) {
            delete oldElement[iterator];
            delete newElement[iterator];
          }
        }
        resultOldValue.push(oldElement);
        resultNewValue.push(newElement);
      }
    }
  }
  // CHECK DELETE OLD VALUE
  else if (oldValue.length > newValue.length) {
    resultOldValue = resultOldValue.concat(
      [...oldValue].splice(-(oldValue.length - newValue.length))
    );
  }
  // CHECK ADD NEW VALUE
  else {
    resultNewValue = resultNewValue.concat(
      [...newValue].splice(-(newValue.length - oldValue.length))
    );
  }

  return {
    new: resultNewValue,
    old: resultOldValue
  };
};
const UpdateAuditLogModal = (props: IUpdateAuditLogModalProps) => {
  const { log, open, onClose } = props;

  return (
    <div className="updateAuditLogModal">
      <AppModal open={open} onClose={onClose}>
        <AppModalFormTitle>
          <p>
            {log.createdByUserName} edited student on{' '}
            {formatDate(log.createdAt)} {formatTime(log.createdAt)}
          </p>
          <HiMiniXMark onClick={onClose} />
        </AppModalFormTitle>
        <AppModalFormContent>
          <div className="updateAuditLogModal-content">
            <div className="updateAuditLogModal-content-info">
              <AppCardContentItem
                subtitle="Name"
                title={formatData(log.data?.firstName)}
              />
              <AppCardContentItem
                subtitle="Surname"
                title={formatData(log.data?.lastName)}
              />
              <AppCardContentItem
                subtitle="Alias"
                title={formatData(log.data?.aliasName)}
              />
            </div>
            <div className="updateAuditLogModal-content-changedFields">
              <div className="updateAuditLogModal-content-changedFields__block updateAuditLogModal-content-changedFields__block-old">
                <AppCardHeader title="old" />
                <div className="updateAuditLogModal-content-changedFields__block-items">
                  {log.data?.changedFields?.length > 0 &&
                    log.data?.changedFields.map((changedField) => {
                      if (redundantFields.includes(changedField.fieldName)) {
                        return null;
                      } else if (
                        changedField.fieldName ===
                          FIELD_NAME.EMERGENCY_CONTACT &&
                        isArray(changedField.oldValue) &&
                        isArray(changedField.newValue)
                      ) {
                        return (
                          <div
                            className="updateAuditLogModal-content-changedFields__block-items-emergencyContact"
                            key={changedField.fieldName}
                          >
                            {formattedChangedFields(
                              changedField.oldValue,
                              changedField.newValue
                            ).old.map(
                              (value: Record<string, any>, index: number) => {
                                return (
                                  <div
                                    className="updateAuditLogModal-content-changedFields__block-items-emergencyContact-block"
                                    key={index}
                                  >
                                    <AppCardHeader title="emergency contact" />
                                    <div className="updateAuditLogModal-content-changedFields__block-items-emergencyContact-block-items">
                                      {Object.keys(value).map((e) => {
                                        if (redundantFields.includes(e)) {
                                          return null;
                                        }
                                        return (
                                          <AppCardContentItem
                                            key={e}
                                            subtitle={e.replace(
                                              /([A-Z])/g,
                                              ' $1'
                                            )}
                                            title={value[e]}
                                          />
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                      return (
                        <AppCardContentItem
                          key={changedField.fieldName}
                          subtitle={formatData(changedField.fieldName).replace(
                            /([A-Z])/g,
                            ' $1'
                          )}
                          title={
                            timeFields.includes(changedField.fieldName)
                              ? formatDate(changedField.oldValue)
                              : formatData(changedField.oldValue)
                          }
                        />
                      );
                    })}
                </div>
              </div>
              <div className="updateAuditLogModal-content-changedFields__horizontal">
                <div>
                  <HiOutlineArrowDown size={24} />
                </div>
              </div>
              <div className="updateAuditLogModal-content-changedFields__block updateAuditLogModal-content-changedFields__block-new">
                <AppCardHeader title="new" />
                <div className="updateAuditLogModal-content-changedFields__block-items">
                  {log.data?.changedFields?.length > 0 &&
                    log.data?.changedFields.map((changedField) => {
                      if (redundantFields.includes(changedField.fieldName)) {
                        return null;
                      } else if (
                        changedField.fieldName ===
                          FIELD_NAME.EMERGENCY_CONTACT &&
                        isArray(changedField.oldValue) &&
                        isArray(changedField.newValue)
                      ) {
                        return (
                          <div
                            className="updateAuditLogModal-content-changedFields__block-items-emergencyContact"
                            key={changedField.fieldName}
                          >
                            {formattedChangedFields(
                              changedField.oldValue,
                              changedField.newValue
                            ).new.map(
                              (value: Record<string, any>, index: number) => {
                                return (
                                  <div
                                    className="updateAuditLogModal-content-changedFields__block-items-emergencyContact-block"
                                    key={index}
                                  >
                                    <AppCardHeader title="emergency contact" />
                                    <div className="updateAuditLogModal-content-changedFields__block-items-emergencyContact-block-items">
                                      {Object.keys(value).map((e) => {
                                        if (redundantFields.includes(e)) {
                                          return null;
                                        }
                                        return (
                                          <AppCardContentItem
                                            key={e}
                                            subtitle={e.replace(
                                              /([A-Z])/g,
                                              ' $1'
                                            )}
                                            title={value[e]}
                                          />
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                      return (
                        <AppCardContentItem
                          key={changedField.fieldName}
                          subtitle={formatData(changedField.fieldName).replace(
                            /([A-Z])/g,
                            ' $1'
                          )}
                          title={
                            timeFields.includes(changedField.fieldName)
                              ? formatDate(changedField.newValue)
                              : formatData(changedField.newValue)
                          }
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </AppModalFormContent>
      </AppModal>
    </div>
  );
};

export default UpdateAuditLogModal;
