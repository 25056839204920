import React from 'react';
import { HiXMark } from 'react-icons/hi2';
import { IMilestone } from 'common/interfaces/levelBreakdown.interface';
import { CreateMilestoneDTO } from 'DTOs/levelBreakdown.dto';
import './desktop.scss';

interface ILevelMilestoneCardProps {
  milestone: CreateMilestoneDTO | IMilestone;
  onRemoveMilestone?: (milestonePosition: number) => void;
  onChangeMilestone?: (value: string, milestonePosition: number) => void;
  index?: number;
  errorMessage?: string;
}

const LevelMilestoneCard = (props: ILevelMilestoneCardProps) => {
  const {
    milestone,
    onRemoveMilestone,
    onChangeMilestone,
    index,
    errorMessage
  } = props;

  return (
    <div
      className={`level__milestoneCardContainer ${
        !!errorMessage ? 'error' : ''
      }`}
    >
      <div className="level__milestoneCard">
        {!!onRemoveMilestone && (
          <div
            className="level__milestoneCard__remove"
            onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              event.stopPropagation();
              onRemoveMilestone(index || milestone.position);
            }}
          >
            <HiXMark />
          </div>
        )}

        <textarea
          className="level__milestoneCard__name line-clamp-3"
          rows={3}
          value={milestone.name}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            onChangeMilestone &&
            onChangeMilestone(event.target.value, index || milestone.position)
          }
          disabled={!onChangeMilestone}
          autoFocus
        />
      </div>

      <p className="level__milestoneCard__errorMessage">{errorMessage}</p>
    </div>
  );
};

export default React.memo(LevelMilestoneCard);
