import React from 'react';
import AppComingSoon from 'components/common/AppComingSoon';
import './desktop.scss';

const BulkManagementBillingList = () => {
  return (
    <section className="bulkManagementBillingList">
      <AppComingSoon />
    </section>
  );
};

export default BulkManagementBillingList;
