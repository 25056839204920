import React, { useCallback, useMemo, useState } from 'react';
import {
  IAssessmentScore,
  IAssessmentScoreDetail
} from 'common/interfaces/assessment.interface';
import { BeatLoader } from 'react-spinners';
import { HiOutlineBookmark, HiOutlineXCircle } from 'react-icons/hi';
import { HiOutlinePencilSquare, HiXMark } from 'react-icons/hi2';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import { formatData } from 'common/helpers/dataFormat.helper';
import AppTable from 'common/components/AppTable';
import { createColumnHelper } from '@tanstack/react-table';
import AppButton from 'common/components/AppButton';
import { updateAssessmentScore } from 'services/levelBreakdown.service';
import { CreateAssessmentScoreDTO } from 'DTOs/levelBreakdown.dto';
import { useToast } from 'context/ToastContext';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';

interface ILevelsBreakdownAssessmentScoreProps {
  data: IAssessmentScore;
  onSuccess: () => void;
  isCanEdit: boolean;
}

const LevelsBreakdownAssessmentScoreUpdate = (
  props: ILevelsBreakdownAssessmentScoreProps
) => {
  const { data, onSuccess, isCanEdit } = props;

  const toast = useToast();

  const columnHelper = createColumnHelper<IAssessmentScoreDetail>();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('fieldName', {
        header: () => <span>name</span>,
        cell: (info) => {
          return (
            <div className="line-clamp-1">{formatData(info.getValue())}</div>
          );
        }
      }),
      columnHelper.accessor('fieldValue', {
        header: () => <span>Maximum Score</span>,
        cell: (info) => {
          return (
            <div className="line-clamp-1">{formatData(info.getValue())}</div>
          );
        }
      })
    ];
  }, [columnHelper]);

  const [loading, setLoading] = useState<boolean>(false);

  const [assessmentScore, setAssessmentScore] = useState<IAssessmentScore>({
    ...data
  });

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const onOpenEdit = () => {
    setIsEdit(true);
  };

  const onCloseEdit = () => {
    setIsEdit(false);
    setAssessmentScore({ ...data });
  };

  const handleChangeAssessmentScore = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAssessmentScore((currentAssessmentScore) => ({
        ...currentAssessmentScore,
        maxScore: parseInt(event.target.value)
      }));
    },
    []
  );

  const handleAddAssessmentScoreDetail = useCallback(() => {
    setAssessmentScore((currentAssessmentScore) => {
      return {
        ...currentAssessmentScore,
        assessmentScoreDetails: [
          ...currentAssessmentScore.assessmentScoreDetails,
          { fieldName: '', fieldValue: 0 }
        ]
      };
    });
  }, []);

  const handleRemoveAssessmentScoreDetail = useCallback((position: number) => {
    setAssessmentScore((currentAssessmentScore) => {
      return {
        ...currentAssessmentScore,
        assessmentScoreDetails: currentAssessmentScore.assessmentScoreDetails.filter(
          (_, index) => index !== position
        )
      };
    });
  }, []);

  const handleChangeAssessmentScoreDetail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, position: number) => {
      setAssessmentScore((currentAssessmentScore) => {
        return {
          ...currentAssessmentScore,
          assessmentScoreDetails: currentAssessmentScore.assessmentScoreDetails.map(
            (assessmentScoreDetail, index: number) => {
              if (position === index)
                return {
                  ...assessmentScoreDetail,
                  [event.target.name]:
                    event.target.name === 'fieldValue'
                      ? parseInt(event.target.value)
                      : event.target.value
                };
              else return assessmentScoreDetail;
            }
          )
        };
      });
    },
    []
  );

  const onSubmit = async () => {
    setLoading(true);
    try {
      const payload: CreateAssessmentScoreDTO = {
        icon: assessmentScore.icon,
        maxScore: assessmentScore.maxScore,
        assessmentScoreDetails: assessmentScore.assessmentScoreDetails
      };

      // Validations with toast
      if (!payload.maxScore || payload.maxScore <= 0) {
        toast.error(`Please correct the maximum score`);
        return;
      }
      for (const assessmentScoreDetail of payload.assessmentScoreDetails) {
        if (
          !assessmentScoreDetail.fieldName ||
          !assessmentScoreDetail.fieldValue
        ) {
          toast.error(
            `Please fulfil information for the assessment score details`
          );
          return;
        }
        if (
          0 > +assessmentScoreDetail.fieldValue ||
          +assessmentScoreDetail.fieldValue > +payload.maxScore
        ) {
          toast.error(
            `Please correct the score for the assessment score details`
          );
          return;
        }
      }

      await updateAssessmentScore(assessmentScore._id, payload);
      onSuccess();
      toast.success('Saved');
      setIsEdit(false);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to save assessment score'
      );
    } finally {
      setLoading(false);
    }
  };

  const __renderIcons = useMemo((): React.ReactNode => {
    if (!isCanEdit) return <></>;
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={onSubmit}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
    // eslint-disable-next-line
  }, [isEdit, onCloseEdit, loading, isCanEdit]);

  const __renderContent = useMemo((): React.ReactNode => {
    if (isEdit) {
      return (
        <div className="levelAssessmentScoreEdit">
          <div className="levelAssessmentScoreEdit-score">
            <p className="levelAssessmentScoreEdit-score-title">Score</p>
            <div className="levelAssessmentScoreEdit-score-form">
              <input
                className="levelAssessmentScoreEdit-score-form-input"
                type="number"
                min={1}
                name="maxScore"
                value={assessmentScore.maxScore}
                onChange={handleChangeAssessmentScore}
              />
              <div className="levelAssessmentScoreEdit-score-form-icon">
                <img src={assessmentScore.icon} alt="default-icon" />
              </div>
            </div>
          </div>

          <div className="levelAssessmentScoreEdit-scoreDetails">
            {assessmentScore?.assessmentScoreDetails?.length > 0 &&
              assessmentScore.assessmentScoreDetails.map(
                (assessmentScoreDetail, index: number) => {
                  return (
                    <div
                      key={index}
                      className="levelAssessmentScoreEdit-scoreDetails-form"
                    >
                      {index > 2 && (
                        <div
                          className="levelAssessmentScoreEdit-scoreDetails-form-remove"
                          onClick={() =>
                            handleRemoveAssessmentScoreDetail(index)
                          }
                        >
                          <HiXMark size={22} />
                        </div>
                      )}
                      <input
                        className="levelAssessmentScoreEdit-scoreDetails-form-input"
                        type="text"
                        min={1}
                        name="fieldName"
                        value={assessmentScoreDetail.fieldName}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleChangeAssessmentScoreDetail(event, index)}
                      />
                      <input
                        className="levelAssessmentScoreEdit-scoreDetails-form-input"
                        type="number"
                        min={1}
                        max={assessmentScore.maxScore}
                        name="fieldValue"
                        value={assessmentScoreDetail.fieldValue}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleChangeAssessmentScoreDetail(event, index)}
                      />
                      <div className="levelAssessmentScoreEdit-scoreDetails-form-of">
                        Of
                      </div>
                      <div className="levelAssessmentScoreEdit-scoreDetails-form-maxScore line-clamp-1">
                        {formatData(assessmentScore.maxScore)}
                      </div>
                    </div>
                  );
                }
              )}
          </div>

          <div className="levelAssessmentScoreEdit-actions">
            <AppButton
              buttonSize="small"
              variant="secondary"
              className="levelAssessmentScoreEdit-actions-addMore"
              onClick={handleAddAssessmentScoreDetail}
            >
              Add more
            </AppButton>
          </div>
        </div>
      );
    }
    return (
      <>
        <AppCardContentItem
          subtitle="Maximum Score"
          title={formatData(assessmentScore?.maxScore)}
        />
        <AppCardContentItem subtitle="Icon">
          <img src={assessmentScore?.icon || ''} alt="" />
        </AppCardContentItem>

        <AppTable
          data={assessmentScore.assessmentScoreDetails}
          columns={columns}
        />
      </>
    );
  }, [
    isEdit,
    assessmentScore,
    columns,
    handleAddAssessmentScoreDetail,
    handleChangeAssessmentScore,
    handleChangeAssessmentScoreDetail,
    handleRemoveAssessmentScoreDetail
  ]);

  return (
    <section className="levelAssessmentScore">
      <AppCard className={`${isEdit ? 'edit' : ''}`}>
        <AppCardHeader
          title="Assessment score"
          suffix={
            <PermissionWrapper permission={PERMISSION.UPDATE_ASSESSMENT_SCORE}>
              {__renderIcons}
            </PermissionWrapper>
          }
        />
        <AppCardContent
          className={`cardContent ${isEdit ? 'cardContent-edit' : ''}`}
        >
          {__renderContent}
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default LevelsBreakdownAssessmentScoreUpdate;
