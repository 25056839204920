import {
  useMemo,
  FC,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  ChangeEvent,
  useRef
} from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from 'context/ToastContext';
import { Resolver, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  HiOutlinePencilSquare,
  HiOutlineXCircle,
  HiOutlineBookmark
} from 'react-icons/hi2';
import { BeatLoader } from 'react-spinners';
import {
  isValidPhoneNumber,
  isPossiblePhoneNumber
} from 'react-phone-number-input';
import { debounce } from 'lodash';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import AppLocationInput from 'components/common/AppLocationInput';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import AppPhoneNumberInput from 'common/components/AppPhoneNumberInput';
import yup from 'validators/responsiblePerson.validator';
import {
  formatData,
  formatSecretPhoneNumber
} from 'common/helpers/dataFormat.helper';
import { useBrandLocation } from 'context/BrandLocationContext';
import { UpdateResponsiblePersonContactDetailsDto } from 'DTOs/responsiblePerson.dto';
import { IResponsiblePerson } from 'common/interfaces/responsiblePerson.interface';
import { updateRPContactDetails } from 'services/responsiblePerson.service';
import { objectContainObj } from 'common/helpers/object.helper';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { checkExisted } from 'services/auth.service';
import { RESPONSE_CHECK_USER } from 'common/enums';
import {
  ERROR_MESSAGE_EMAIL_FORMAT,
  ERROR_MESSAGE_EMAIL_TAKEN,
  ERROR_MESSAGE_PHONE_NUMBER_FORMAT,
  ERROR_MESSAGE_PHONE_TAKEN
} from 'common/constants/error.constant';
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi';

import './desktop.scss';

interface Props {
  data: IResponsiblePerson | null;
  onUpdated: () => void;
}

type ShowPhoneType = { idx: number; value: boolean };

const validationSchema = yup.OBJECT({
  phoneNumber: yup.RESPONSIBLE_PERSON_PHONE_NUMBER,
  email: yup.RESPONSIBLE_PERSON_EMAIL_ADDRESS
});

const ContactDetails: FC<Props> = ({ data, onUpdated }) => {
  const toast = useToast();
  const { id } = useParams();
  const { locations: globalLocations } = useBrandLocation();

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [showPhone, setShowPhone] = useState<ShowPhoneType[]>([
    {
      idx: 0,
      value: false
    }
  ]);

  const handleShowPhone = (phoneIndex: number) => {
    setShowPhone((pre: ShowPhoneType[]) => {
      if (pre?.filter((ob: ShowPhoneType) => ob.idx === phoneIndex)?.length) {
        return pre?.map((obj: ShowPhoneType) => {
          if (obj?.idx === phoneIndex) {
            return { ...obj, value: !obj?.value };
          }
          return obj;
        });
      }

      return [...pre, { idx: phoneIndex, value: true }];
    });
  };

  const [isEdit, setIsEdit] = useState(false);

  const onOpenEdit = useCallback(() => {
    setIsEdit(true);
  }, []);

  const onCloseEdit = useCallback(() => {
    setIsEdit(false);
  }, []);

  const {
    register,
    setValue,
    clearErrors,
    trigger,
    setError,
    control,
    reset,
    formState: { errors },
    handleSubmit
  } = useForm<UpdateResponsiblePersonContactDetailsDto>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<UpdateResponsiblePersonContactDetailsDto>,
    defaultValues: {
      phoneNumber: data?.phoneNumber,
      email: data?.email
    }
  });

  const watchAllFields = useWatch({ control });

  const handleInitValue = useCallback(() => {
    clearErrors();

    reset({
      phoneNumber: data?.phoneNumber,
      email: data?.email
    });
  }, [reset, data, clearErrors]);

  useEffect(() => {
    if (!data?._id) return;

    handleInitValue();
  }, [data, handleInitValue]);

  useEffect(() => {
    if (isEdit) return;

    handleInitValue();
  }, [isEdit, handleInitValue]);

  const abortConRef = useRef<AbortController>();

  // eslint-disable-next-line
  const checkExisting = useCallback(
    debounce(async (phoneNumber?: string, email?: string) => {
      try {
        if (abortConRef.current) abortConRef.current.abort();
        abortConRef.current = new AbortController();

        await checkExisted(
          phoneNumber,
          email?.toLowerCase(),
          abortConRef.current.signal
        );

        clearErrors(['phoneNumber', 'email']);
      } catch (error: any) {
        if (
          error?.response?.data?.message === RESPONSE_CHECK_USER.BOTH_EXISTED
        ) {
          if (phoneNumber !== data?.phoneNumber) {
            setError('phoneNumber', {
              type: 'optionality',
              message: ERROR_MESSAGE_PHONE_TAKEN
            });
          }

          if (email?.toLowerCase() !== data?.email?.toLowerCase()) {
            setError('email', {
              type: 'optionality',
              message: ERROR_MESSAGE_EMAIL_TAKEN
            });
          }
          return;
        } else if (
          error?.response?.data?.message === RESPONSE_CHECK_USER.EMAIL_EXISTED
        ) {
          if (email?.toLowerCase() !== data?.email?.toLowerCase()) {
            setError('email', {
              type: 'optionality',
              message: ERROR_MESSAGE_EMAIL_TAKEN
            });
          }
          return;
        } else if (
          error?.response?.data?.message ===
          RESPONSE_CHECK_USER.PHONE_NUMBER_EXISTED
        ) {
          if (phoneNumber !== data?.phoneNumber) {
            setError('phoneNumber', {
              type: 'optionality',
              message: ERROR_MESSAGE_PHONE_TAKEN
            });
          }
          return;
        } else if (
          error?.response?.data?.message?.[0] ===
          RESPONSE_CHECK_USER.EMAIL_MUST_BE_AN_EMAIL
        ) {
          setError('email', {
            type: 'optionality',
            message: ERROR_MESSAGE_EMAIL_FORMAT
          });
          return;
        }
      }
    }, 300),
    [data]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // @ts-ignore
      setValue(event.target.name, event.target.value);

      if (event.target.name === 'email') {
        if (event.target.value === data?.email) {
          clearErrors('phoneNumber');

          return;
        }

        trigger(event.target.name).then((res) => {
          if (!res || !event.target.value) return;

          checkExisting(watchAllFields?.phoneNumber, event.target.value);
        });
      } else {
        // @ts-ignore
        trigger(event.target.name);
      }
    },
    [
      trigger,
      setValue,
      checkExisting,
      data?.email,
      clearErrors,
      watchAllFields?.phoneNumber
    ]
  );

  const handleChangePhoneNumber = useCallback(
    (val: string) => {
      if (!val) return;

      setValue('phoneNumber', val);

      if (val === data?.phoneNumber) {
        clearErrors('phoneNumber');

        return;
      }

      setValue('phoneNumber', val);

      const phoneNumberCondition = !!(
        isValidPhoneNumber(val) && isPossiblePhoneNumber(val)
      );

      if (phoneNumberCondition) {
        clearErrors('phoneNumber');

        checkExisting(val, watchAllFields?.email);
      } else {
        setError('phoneNumber', {
          type: 'custom',
          message: 'Invalid phone number'
        });
      }
    },

    [
      setValue,
      setError,
      checkExisting,
      clearErrors,
      data?.phoneNumber,
      watchAllFields?.email
    ]
  );

  const onSubmit = useCallback(async () => {
    if (disabled) {
      trigger();

      return;
    }

    try {
      const submitData = { ...watchAllFields };

      if (!data || !submitData) return;

      await checkExisted(
        submitData.phoneNumber,
        (submitData?.email || '').toLowerCase()
      );
    } catch (error: any) {
      const submitData = { ...watchAllFields };

      if (!data || !submitData) return;

      if (error?.response?.data?.message === RESPONSE_CHECK_USER.BOTH_EXISTED) {
        if (submitData.phoneNumber !== data?.phoneNumber) {
          setError('phoneNumber', {
            type: 'optionality',
            message: ERROR_MESSAGE_PHONE_TAKEN
          });
        }

        if (
          (submitData?.email || '')?.toLowerCase() !==
          data?.email?.toLowerCase()
        ) {
          setError('email', {
            type: 'optionality',
            message: ERROR_MESSAGE_EMAIL_TAKEN
          });
        }
        return;
      } else if (
        error?.response?.data?.message === RESPONSE_CHECK_USER.EMAIL_EXISTED
      ) {
        if (
          (submitData?.email || '')?.toLowerCase() !==
          data?.email?.toLowerCase()
        ) {
          setError('email', {
            type: 'optionality',
            message: ERROR_MESSAGE_EMAIL_TAKEN
          });
        }
        return;
      } else if (
        error?.response?.data?.message ===
        RESPONSE_CHECK_USER.PHONE_NUMBER_EXISTED
      ) {
        if (submitData.phoneNumber !== data?.phoneNumber) {
          setError('phoneNumber', {
            type: 'optionality',
            message: ERROR_MESSAGE_PHONE_TAKEN
          });
        }
        return;
      } else if (
        error?.response?.data?.message?.[0] ===
        RESPONSE_CHECK_USER.EMAIL_MUST_BE_AN_EMAIL
      ) {
        setError('email', {
          type: 'optionality',
          message: ERROR_MESSAGE_EMAIL_FORMAT
        });
        return;
      } else if (
        error?.response?.data?.message?.[0] ===
        RESPONSE_CHECK_USER.PHONE_NUMBER_MUST_BE_A_VALID_PHONE_NUMBER
      ) {
        setError('phoneNumber', {
          type: 'optionality',
          message: ERROR_MESSAGE_PHONE_NUMBER_FORMAT
        });
        return;
      }
    } finally {
      try {
        if (Object.keys(errors)?.length) return;

        setLoading(true);
        setDisabled(true);

        const submitData = { ...watchAllFields };

        if (!data || !submitData || !id) return;

        if (objectContainObj(data, submitData)) {
          onCloseEdit();

          setDisabled(false);
          setLoading(false);

          return;
        }

        await updateRPContactDetails(
          id,
          submitData as UpdateResponsiblePersonContactDetailsDto
        );

        toast.success('Update RP contact detail successfully');
        onCloseEdit();

        onUpdated();
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Update RP contact detail failed'
        );
      } finally {
        setDisabled(false);
        setLoading(false);
      }
    }

    // eslint-disable-next-line
  }, [
    id,
    onUpdated,
    disabled,
    data,
    watchAllFields,
    trigger,
    setError,
    onCloseEdit,
    errors
  ]);

  const __renderIcons = useMemo((): ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSubmit)}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
  }, [isEdit, onSubmit, loading, handleSubmit, onOpenEdit, onCloseEdit]);

  const __renderContent = useCallback((): ReactNode => {
    const locationData = [...globalLocations]
      .map((location) => {
        return {
          value: location._id,
          label: location.name
        };
      })
      ?.filter(
        (location) =>
          location.value ===
          (isEdit ? watchAllFields?.locationId : data?.locationId)
      )?.[0];

    if (isEdit) {
      return (
        <>
          <div className="item">
            <AppPhoneNumberInput
              {...register('phoneNumber')}
              label="Mobile Number*"
              value={watchAllFields?.phoneNumber || ''}
              onChange={handleChangePhoneNumber}
              message={{
                type: 'error',
                text: errors?.phoneNumber?.message || ''
              }}
            />
          </div>
          <div className="item">
            <AppInput
              {...register('email')}
              // disabled
              label="Email Address*"
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.email?.message || ''
              }}
            />
          </div>
          <div className="item">
            <AppLocationInput
              label="Home Club*"
              disabled
              defaultValue={locationData?.value}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div className="item">
          <AppCardContentItem subtitle="Mobile Number">
            <span className="custom_show_phone">
              {!!showPhone.filter(
                (obj: ShowPhoneType) => obj.idx === 0 && !!obj.value
              )?.length ? (
                <a href={`tel:${data?.phoneNumber}`}>{data?.phoneNumber} </a>
              ) : (
                <span>{formatSecretPhoneNumber(data?.phoneNumber)}</span>
              )}

              <button onClick={() => handleShowPhone(0)}>
                {!!showPhone.filter(
                  (obj: ShowPhoneType) => obj.idx === 0 && !!obj.value
                )?.length ? (
                  <HiOutlineEye fontSize={20} />
                ) : (
                  <HiOutlineEyeOff fontSize={20} />
                )}
              </button>
            </span>
          </AppCardContentItem>
        </div>
        <div className="item">
          <AppCardContentItem
            subtitle="Email Address"
            title={formatData(data?.email)}
          />
        </div>
        <div className="item">
          <AppCardContentItem
            subtitle="Home Club"
            title={formatData(locationData?.label ?? '')}
          />
        </div>
      </>
    );
  }, [
    isEdit,
    data,
    errors,
    handleChange,
    register,
    globalLocations,
    handleChangePhoneNumber,
    watchAllFields,
    showPhone
  ]);

  if (loading) {
    return <AppLoadingContainer />;
  }

  return (
    <section className="contact_details">
      <AppCard>
        <AppCardContent className="card-content">
          <div className="contact_details-main-content">
            <AppCardHeader
              title="Contact Details"
              suffix={
                <PermissionWrapper
                  permission={
                    PERMISSION.UPDATE_RESPONSIBLE_PERSON_CONTACT_DETAIL
                  }
                >
                  {__renderIcons}
                </PermissionWrapper>
              }
            />
            <div className="content">{__renderContent()}</div>
          </div>
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default ContactDetails;
