import { CLASS_TYPES, RECURRENCE_VALUES } from 'common/enums/class.enum';
import dayjs from 'dayjs';
import { object as OBJECT, boolean, string, number, object } from 'yup';

const CLASS_TEMPLATE_ID = string().required('Please select class template ');
const CLASS_LEVEL = string().required('Please select class level ');
const CLASS_INSTRUCTOR = string().required('Please select Instructor ');
const CLASS_NAME = string().required('Please input class name ');
const CLASS_ACTIVE = boolean();
const CLASS_START_DATE = string()
  .required('Please select start date')
  .test('custom-type', function (value, testContext) {
    const { parent, path, options } = testContext;
    const { rangeValidDate, disableBeforeDate, isEdit } = options.context || {};
    const { startDate } = parent;
    if (startDate) {
      if (
        dayjs(startDate).format('YYYY-MM-DD') <=
          dayjs(disableBeforeDate).format('YYYY-MM-DD') &&
        !isEdit
      ) {
        return this.createError({
          path: path,
          message: 'Start date must be greater than or equal to today '
        });
      }
      if (!rangeValidDate.includes(dayjs(startDate).format('YYYY-MM-DD'))) {
        return this.createError({
          path: path,
          message: 'Start date must be in the range of session date'
        });
      }
    }
    return true;
  });
const CLASS_START_TIME = string().required('Please input start time');
const CLASS_END_TIME = string();
const CLASS_END_DATE = string();
const CLASS_TYPE = string().required('Please select program type');
const CLASS_RECURRENCE_VALUE = string()
  .required('Please select recurrence')
  .test('custom-type', function (value, testContext) {
    const { parent, path } = testContext;
    const { recurrenceValue, recurrence } = parent;
    const { mon, tue, wed, thur, fri, sat, sun } = recurrence;
    if (recurrenceValue === RECURRENCE_VALUES.CUSTOM) {
      if (!mon && !tue && !wed && !thur && !fri && !sat && !sun) {
        return this.createError({
          path: path,
          message: 'Please select at least one day of the week'
        });
      }
    }
    return true;
  });
const CLASS_CAPACITY = number()
  .typeError('Capacity must be a number')
  .min(1, 'Capacity must be greater than 0')
  .integer()
  .max(10000, 'Capacity must be less than 10000')
  .test('private-class-capacity', function (value, testContext) {
    const { parent, path } = testContext;
    const { isPrivate, type } = parent;
    if (isPrivate && type === CLASS_TYPES.SERIES) {
      if (Number(value) > 2) {
        return this.createError({
          path: path,
          message: 'Private class capacity should be less than 3'
        });
      }
    }
    return true;
  });

const CLASS_PRICE = number()
  .typeError('Price must be a number')
  .test('assessment-trial-class', function (value, testContext) {
    const { parent, path } = testContext;
    const { type } = parent;
    if (type === CLASS_TYPES.ASSESSMENT_TRIAL) {
      if (Number(value) === 0) {
        return true;
      }
      return this.createError({
        path: path,
        message: 'Assessment trial class cannot have a price'
      });
    } else {
      if (Number(value) <= 0) {
        return this.createError({
          path: path,
          message: 'Price must be greater than 0'
        });
      }
    }
    return true;
  });
const CLASS_AREA = string().required('Please select area');
const CLASS_RECURRENCE = object({
  mon: boolean(),
  tue: boolean(),
  wed: boolean(),
  thur: boolean(),
  fri: boolean(),
  sat: boolean(),
  sun: boolean(),
  type: string(),
  endDate: number()
});

const validations = {
  OBJECT,
  CLASS_TEMPLATE_ID,
  CLASS_LEVEL,
  CLASS_INSTRUCTOR,
  CLASS_NAME,
  CLASS_ACTIVE,
  CLASS_START_DATE,
  CLASS_START_TIME,
  CLASS_END_TIME,
  CLASS_TYPE,
  CLASS_RECURRENCE_VALUE,
  CLASS_END_DATE,
  CLASS_CAPACITY,
  CLASS_PRICE,
  string,
  number,
  boolean,
  CLASS_AREA,
  CLASS_RECURRENCE
};
export default validations;
