import dayjs from 'dayjs';
import { object as OBJECT, string, boolean, array } from 'yup';

const INSTRUCTOR_STATUS = string();
const INSTRUCTOR_NAME = string().required('Please provide name');
const INSTRUCTOR_LAST_NAME = string().required('Please provide last name');
const INSTRUCTOR_ALIAS = string();
const INSTRUCTOR_DISPLAY_NAME = string().required(
  'Please provide display name'
);
const INSTRUCTOR_DOB = string()
  .required('Please select D.O.B')
  .test('exceed-today', function (value, context) {
    if (dayjs(value).isAfter(dayjs()) || value === 'Invalid Date') {
      return this.createError({
        path: context.path,
        message: 'Please provide correct date'
      });
    }
    return true;
  })
  .test('greater-than-18', function (value, context) {
    if (dayjs().diff(value, 'year', true) < 16 || value === 'Invalid Date') {
      return this.createError({
        path: context.path,
        message: 'Age must be minimum of 16 years'
      });
    }
    return true;
  })
  .required('Please provide D.O.B');
const INSTRUCTOR_GENDER = string().required('Please select gender');
const INSTRUCTOR_MOBILE_NUMBER = string().required(
  'Please provide mobile number'
);
const INSTRUCTOR_EMAIL_ADDRESS = string()
  .required('Please provide email address')
  .email('Please correct the email address');
const INSTRUCTOR_LOCATION_IDS = array().min(
  1,
  'Please select at least one location'
);
const INSTRUCTOR_ADDITIONAL_INFO = OBJECT({
  levelIds: string().required('Please select level')
});
const INSTRUCTOR_TIMEZONE = string().required('Please select timezone');
const INSTRUCTOR_LEVELS = array()
  .required('Please select levels')
  .min(1, 'Please select at least one level');
const CERT_NAME = string().required('Please select Credential name');
const INSTRUCTOR_ALL_LOCATION = boolean();
const INSTRUCTOR_ALL_LEVEL = boolean();
const INSTRUCTOR_CREDENTIAL_NAME = string();
const INSTRUCTOR_CREDENTIAL_FILES = array();
const INSTRUCTOR_CREDENTIAL_EXPIRY_DATE = string()
  .required('Please provide expiry date')
  .test('greater-than-today', function (value, context) {
    if (dayjs(value).isBefore(dayjs()) || value === 'Invalid Date') {
      return this.createError({
        path: context.path,
        message: 'Please provide date in the future'
      });
    }
    return true;
  });

const validations = {
  OBJECT,
  INSTRUCTOR_STATUS,
  INSTRUCTOR_NAME,
  INSTRUCTOR_LAST_NAME,
  INSTRUCTOR_ALIAS,
  INSTRUCTOR_DISPLAY_NAME,
  INSTRUCTOR_DOB,
  INSTRUCTOR_GENDER,
  INSTRUCTOR_MOBILE_NUMBER,
  INSTRUCTOR_EMAIL_ADDRESS,
  INSTRUCTOR_LOCATION_IDS,
  INSTRUCTOR_ADDITIONAL_INFO,
  INSTRUCTOR_TIMEZONE,
  INSTRUCTOR_LEVELS,
  CERT_NAME,
  boolean,
  INSTRUCTOR_ALL_LOCATION,
  INSTRUCTOR_CREDENTIAL_NAME,
  INSTRUCTOR_CREDENTIAL_FILES,
  INSTRUCTOR_ALL_LEVEL,
  INSTRUCTOR_CREDENTIAL_EXPIRY_DATE
};
export default validations;
