import dayjs from 'dayjs';
import {
  AUTOMATION_DISCOUNT_TYPE,
  AUTOMATION_DISCOUNT_VALUE,
  DISCOUNT_STATUS
} from '../enums/voucher.enum';
import { IVoucher } from '../interfaces/voucher.interface';
import { isDateInRangeValid } from './validate.helper';
import { DISCOUNT_TYPE } from '../enums';
import { formatMoneySign, formatPercentNumber } from './dataFormat.helper';

export const getAutomationDiscountPosition = (
  automationDiscountType?: AUTOMATION_DISCOUNT_TYPE
): number => {
  switch (automationDiscountType) {
    case AUTOMATION_DISCOUNT_TYPE.THIRD_STUDENT_ENROLLMENT:
      return 3;
    case AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT:
      return 2;
    default:
      return -1;
  }
};

export const getAutomationDiscountValue = (
  automationDiscountType?: AUTOMATION_DISCOUNT_TYPE
): number => {
  switch (automationDiscountType) {
    case AUTOMATION_DISCOUNT_TYPE.THIRD_STUDENT_ENROLLMENT:
      return AUTOMATION_DISCOUNT_VALUE.THIRD_STUDENT_ENROLLMENT;
    case AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT:
      return AUTOMATION_DISCOUNT_VALUE.SECOND_ENROLLMENT;
    default:
      return -1;
  }
};

export const getAutomationDiscountText = (
  automationDiscountType?: AUTOMATION_DISCOUNT_TYPE
): string => {
  switch (automationDiscountType) {
    case AUTOMATION_DISCOUNT_TYPE.THIRD_STUDENT_ENROLLMENT:
      return '3rd+ Student Discount';
    case AUTOMATION_DISCOUNT_TYPE.SECOND_ENROLLMENT:
      return '2nd+ Additional Enrolment Discount';
    default:
      return '';
  }
};

export const getVoucherStatus = (voucher: IVoucher): string => {
  if (
    isDateInRangeValid(
      dayjs().format('YYYY-MM-DD'),
      dayjs(voucher.startDate)?.format('YYYY-MM-DD'),
      dayjs(voucher.endDate)?.format('YYYY-MM-DD'),
      'day'
    )
  ) {
    return DISCOUNT_STATUS.USE_NOW;
  } else if (dayjs()?.isBefore(voucher.startDate, 'day')) {
    return DISCOUNT_STATUS.NOT_STARTED;
  } else if (dayjs()?.isAfter(voucher.endDate, 'day')) {
    return DISCOUNT_STATUS.EXPIRED;
  } else {
    return '';
  }
};

export const getDiscountValueText = (discount?: IVoucher): string => {
  const discountType = discount?.discountType;

  if (!discountType) return '';

  switch (discountType) {
    case DISCOUNT_TYPE.AMOUNT:
      return `${formatMoneySign(discount.discountValue)} OFF`;
    case DISCOUNT_TYPE.PERCENTAGE:
      return `${formatPercentNumber(discount.discountValue)} OFF`;
    case DISCOUNT_TYPE.COMPLIMENTARY:
      return DISCOUNT_TYPE.COMPLIMENTARY;
    default:
      return '';
  }
};
