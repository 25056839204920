import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import AppButton from 'common/components/AppButton';

interface IUndoActionRollCallProps {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  action: string;
}

const UndoActionRollCall: React.FC<IUndoActionRollCallProps> = (
  props: IUndoActionRollCallProps
) => {
  const { onClose, onConfirm, open, action } = props;

  return (
    <AppModal open={open} onClose={onClose} style={{ width: '510px' }}>
      <AppModalTitle>{action.replace('-', ' ')} </AppModalTitle>
      <AppModalContent>
        {`ARE YOU SURE YOU WANT TO REVERT THIS ${action.replace('-', ' ')}${
          action !== 'check-in' ? ' STATUS' : ''
        }?`}
      </AppModalContent>
      <AppModalActions>
        <AppButton variant="secondary" onClick={onClose}>
          No
        </AppButton>
        <AppButton onClick={onConfirm}>Yes</AppButton>
      </AppModalActions>
    </AppModal>
  );
};

export default UndoActionRollCall;
