import {
  ASSESSMENT_REVIEW_STATUS,
  ASSESSMENT_STATUS
} from '../enums/assessment.enum';
import { MEMBER_TYPE, PROGRAM_TYPE } from '../enums';
import { CLASS_TYPES, RECURRENCE_VALUES } from '../enums/class.enum';
import {
  HEALTH_ANSWER_TYPE,
  HEALTH_EXPECTED_ANSWER,
  HEALTH_SECTION_LABEL
} from '../enums/healthQuestionnaire.enum';
import { GENDER, STAFF_TYPE } from '../enums/user.enum';
import { IOption } from '../interfaces';
import { IBrand } from '../interfaces/brand.interface';
import { NOTIFICATION_TYPE } from '../enums/notification.enum';
import { FILTER_NOTIFICATION_STATUS } from '../enums/firebase.enum';
import { VOUCHER_DURATION } from '../enums/voucher.enum';

export const ERROR_MESSAGE_FORBIDDEN_RESOURCE = 'Forbidden resource';

// 5 minutes
export const OTP_TIME = 300;
// 1 minute
export const RESEND_OTP_TIME = 60;

export const MAX_IDLE_TIMEOUT = 30 * 60 * 1000; // 30 minutes

export const STATIC_BRAND_CLUBLIME: IBrand = {
  _id: 'cbefa832-0c79-453a-93ee-31b720844b63',
  name: 'Club Lime',
  createdAt: '2023-09-14T04:49:58.658Z',
  updatedAt: '2023-09-14T04:49:58.658Z',
  __v: 0
};

export const PROGRAM_TYPE_OPTIONS: Array<IOption> = [
  {
    label: PROGRAM_TYPE.SERIES,
    value: CLASS_TYPES.SERIES
  },
  // {
  //   label: PROGRAM_TYPE.PRIVATE,
  //   value: CLASS_TYPES.PRIVATE
  // },
  {
    label: PROGRAM_TYPE.INTENSIVE_HOLIDAY_PROGRAM,
    value: CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM
  },
  {
    label: PROGRAM_TYPE.ASSESSMENT_TRIAL,
    value: CLASS_TYPES.ASSESSMENT_TRIAL
  }
];

export const SPECIAL_PROGRAM_TYPE_OPTIONS: Array<IOption> = [
  {
    label: PROGRAM_TYPE.SERIES,
    value: CLASS_TYPES.SERIES
  },
  {
    label: PROGRAM_TYPE.INTENSIVE_HOLIDAY_PROGRAM,
    value: CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM
  }
];

export const CLASS_TEMPLATE_DURATIONS: Array<IOption> = [
  {
    label: '30',
    value: '30'
  },
  {
    label: '60',
    value: '60'
  }
];

export const CLASS_TEMPLATE_TERMS: Array<IOption> = [
  {
    label: '1',
    value: '1'
  },
  {
    label: '3',
    value: '3'
  },
  {
    label: '6',
    value: '6'
  },
  {
    label: '9',
    value: '9'
  },
  {
    label: '12',
    value: '12'
  }
];

export const VIEW_MODE = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month'
};

export const HEIGHT_CELL_CALENDAR = 150 * 2;

export const RECURRENCE_OPTIONS = [
  { label: "Don't repeat", value: RECURRENCE_VALUES.DO_NOT_REPEAT },
  { label: 'Weekly', value: RECURRENCE_VALUES.WEEKLY },
  { label: 'Custom...', value: RECURRENCE_VALUES.CUSTOM }
];

export const HEALTH_ANSWER_TYPE_OPTIONS: Array<IOption> = [
  {
    label: HEALTH_ANSWER_TYPE.NUMBER,
    value: HEALTH_ANSWER_TYPE.NUMBER
  },
  {
    label: HEALTH_ANSWER_TYPE.STRING,
    value: HEALTH_ANSWER_TYPE.STRING
  }
];

export const HEALTH_SECTION_LABEL_OPTIONS: Array<IOption> = [
  {
    label: 'Health Check',
    value: HEALTH_SECTION_LABEL.HEALTH_CHECK
  },
  {
    label: 'Medical Declaration',
    value: HEALTH_SECTION_LABEL.MEDICAL_DECLARATION
  }
];

export const HEALTH_EXPECTED_ANSWER_OPTIONS: Array<{
  label: string;
  value: HEALTH_EXPECTED_ANSWER;
}> = [
  {
    label: HEALTH_EXPECTED_ANSWER.YES,
    value: HEALTH_EXPECTED_ANSWER.YES
  },
  {
    label: HEALTH_EXPECTED_ANSWER.NO,
    value: HEALTH_EXPECTED_ANSWER.NO
  },
  {
    label: HEALTH_EXPECTED_ANSWER.OTHER,
    value: HEALTH_EXPECTED_ANSWER.OTHER
  }
];

export const GENDER_TYPE_OPTIONS: Array<IOption> = [
  {
    label: GENDER.MALE,
    value: GENDER.MALE
  },
  {
    label: GENDER.FEMALE,
    value: GENDER.FEMALE
  },
  {
    label: GENDER.OTHER,
    value: GENDER.OTHER
  }
];

export const STATE_OPTIONS: Array<IOption> = [
  {
    label: 'NSW',
    value: 'NSW'
  },
  {
    label: 'VIC',
    value: 'VIC'
  },
  {
    label: 'QLD',
    value: 'QLD'
  },
  {
    label: 'WA',
    value: 'WA'
  },
  {
    label: 'SA',
    value: 'SA'
  },
  {
    label: 'TAS',
    value: 'TAS'
  },
  {
    label: 'ACT',
    value: 'ACT'
  },
  {
    label: 'NT',
    value: 'NT'
  }
];

export const STAFF_TYPE_OPTIONS: Array<IOption> = [
  {
    label: STAFF_TYPE.STAFF,
    value: STAFF_TYPE.STAFF
  }
];

export const WEEK_DAYS = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY'
];

export const DATE_CONSTANT = '2000-01-01';

export const TIMEZONE_DEFAULT = 'Australia/Brisbane';

export const MINUTES_CHECK_IN_BEFORE_CLASS = 90;
export const MINUTES_CHECK_IN_AFTER_CLASS = 0;

export const TEMP_SITE_PATH = 'TEMP_SITE_PATH';

export const ASSESSMENT_STATUS_OPTIONS: Array<IOption> = [
  {
    label: 'All',
    value: ASSESSMENT_STATUS.IDLE
  },
  {
    label: ASSESSMENT_STATUS.NOT_ATTEMPTED.replaceAll('-', ' '),
    value: ASSESSMENT_STATUS.NOT_ATTEMPTED
  },
  {
    label: ASSESSMENT_STATUS.DEVELOPING,
    value: ASSESSMENT_STATUS.DEVELOPING
  },
  {
    label: ASSESSMENT_STATUS.ACHIEVED,
    value: ASSESSMENT_STATUS.ACHIEVED
  }
];

export const ASSESSMENT_REVIEW_STATUS_OPTIONS: Array<IOption> = [
  {
    label: 'All',
    value: ASSESSMENT_REVIEW_STATUS.IDLE
  },
  {
    label: ASSESSMENT_REVIEW_STATUS.APPROVAL_PENDING.replaceAll('-', ' '),
    value: ASSESSMENT_REVIEW_STATUS.APPROVAL_PENDING
  },
  {
    label: ASSESSMENT_REVIEW_STATUS.APPROVED,
    value: ASSESSMENT_REVIEW_STATUS.APPROVED
  },
  {
    label: ASSESSMENT_REVIEW_STATUS.REASSESS,
    value: ASSESSMENT_REVIEW_STATUS.REASSESS
  }
];
export const NOTIFICATION_TYPE_OPTIONS: Array<IOption> = [
  {
    label: NOTIFICATION_TYPE.EMAIL,
    value: NOTIFICATION_TYPE.EMAIL
  },
  {
    label: NOTIFICATION_TYPE.SMS,
    value: NOTIFICATION_TYPE.SMS
  },
  {
    label: 'Member Portal',
    value: NOTIFICATION_TYPE.PUSH,
    canNotBeRemoved: true
  }
];
export const WEEK_DAY_STRING_OPTIONS: IOption[] = [
  { label: 'SUNDAY', value: 'sun' },
  { label: 'MONDAY', value: 'mon' },
  { label: 'TUESDAY', value: 'tue' },
  { label: 'WEDNESDAY', value: 'wed' },
  { label: 'THURSDAY', value: 'thur' },
  { label: 'FRIDAY', value: 'fri' },
  { label: 'SATURDAY', value: 'sat' }
];

export const NOTIFICATIONS_STATUS_FILTER_OPTIONS: IOption[] = [
  { label: 'All', value: FILTER_NOTIFICATION_STATUS.IDLE },
  { label: 'Read', value: FILTER_NOTIFICATION_STATUS.READ },
  { label: 'Not Read', value: FILTER_NOTIFICATION_STATUS.UNREAD }
];

export const MEMBER_TYPE_OPTIONS: Array<IOption> = [
  {
    label: MEMBER_TYPE.NEW,
    value: MEMBER_TYPE.NEW
  },
  {
    label: MEMBER_TYPE.EXISTING,
    value: MEMBER_TYPE.EXISTING
  },
  {
    label: MEMBER_TYPE.PREVIOUS,
    value: MEMBER_TYPE.PREVIOUS
  }
];

export const VOUCHER_DURATION_OPTIONS: Array<IOption> = [
  // {
  //   label: '1 session',
  //   value: VOUCHER_DURATION.ONE_SESSION
  // },
  // {
  //   label: 'ongoing',
  //   value: VOUCHER_DURATION.ONGOING
  // },
  // {
  //   label: VOUCHER_DURATION.TWO_SESSION,
  //   value: VOUCHER_DURATION.TWO_SESSION
  // },
  // {
  //   label: VOUCHER_DURATION.DIRECT_DEBIT,
  //   value: VOUCHER_DURATION.DIRECT_DEBIT
  // },
  {
    label: 'time range',
    value: VOUCHER_DURATION.TIME_RANGE
  },
  {
    label: 'specific number of lessons',
    value: VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS
  }
];

export enum TAB {
  INIT = 0,
  IDLE = -1
}
export const FORMAT_END_OF_DATE = 'YYYY-MM-DD 23:59:59';
