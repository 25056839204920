export enum ABSENCE_STATUS {
  IDLE = 'idle',
  PENDING = 'pending',
  APPROVED = 'approved',
  DECLINED = 'declined'
}

export enum REFUND_CREDIT_TYPE {
  MAKEUP_CREDIT = 'makeup_credit',
  MONEY_CREDIT = 'money_credit'
}
