export enum HEALTH_ANSWER_TYPE {
  IDLE = "",
  NUMBER = "number",
  STRING = "string",
}

export enum HEALTH_SECTION_LABEL {
  IDLE = "",
  HEALTH_CHECK = "health_check",
  MEDICAL_DECLARATION = "medical_declaration",
}

export enum HEALTH_EXPECTED_ANSWER {
  IDLE = "",
  YES = "yes",
  NO = "no",
  OTHER = "other",
}

export enum HEALTH_ANSWER {
  YES = "1",
  NO = "0",
}

export enum HEALTH_ANSWER_LABEL {
  YES = "yes",
  NO = "no",
}
