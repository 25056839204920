import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  ICalendarCell,
  IGroupClassSession
} from 'common/interfaces/dashboard.interface';
import { cloneDeep, every, groupBy, some, sortBy } from 'lodash';
import { useDashboardContext } from '../DashboardContext';
import { BiInfoCircle } from 'react-icons/bi';
interface RenderLevelsProps {
  cell: ICalendarCell;
}

const LevelList: React.FC<RenderLevelsProps> = ({ cell }) => {
  const {
    classesData: classes,
    onChangeSelectedTime,
    onChangeSelectedDayForPreview,
    onChangeSelectedLevel,
    onChangeSelectedEndTime
  } = useDashboardContext();
  const [levelsInCell, setLevelsInCell] = useState<IGroupClassSession[]>([]);
  const [textShowMore, setTextShowMore] = useState('');
  const [numberRow, setNumberRow] = useState<number>(0);

  useEffect(() => {
    const levelsInCell = classes.filter((item: IGroupClassSession) => {
      const startTime = dayjs(item.startTime);
      const endTime = dayjs(item.endTime).subtract(30, 'minutes');
      const desiredTime = dayjs(
        `${dayjs(cell.date).format('YYYY-MM-DD')} ${cell.time}`
      );
      const timeDiff = dayjs(item.endTime).diff(
        dayjs(item.startTime),
        'minutes'
      );

      return (
        startTime.isSame(desiredTime) ||
        (endTime.isSame(desiredTime) && timeDiff === 60)
      );
    });

    if (levelsInCell.length > 0) {
      let data: IGroupClassSession[] = [];
      const levelGroups = groupBy(
        cloneDeep(levelsInCell),
        'levelBreakdown._id'
      );
      Object.keys(levelGroups).forEach((key) => {
        const level = levelGroups[key][0];
        data.push(level);
      });
      data = sortBy(data, 'level');
      let text = '';
      const levelsShowed = data.slice(0, 6);
      const remainingLevels = data.length - 6;
      if (remainingLevels > 1) {
        text = `+${remainingLevels}`;
      } else if (remainingLevels === 1) {
        levelsShowed.push(data[6]);
      }
      const dataResult = sortBy(levelsShowed, 'levelBreakdown.shortName').map(
        (item) => {
          return {
            ...item,
            isCanBook: some(
              item.schedules,
              (schedule) => schedule.vacancySession > 0
            ),
            isOverBooked: every(
              item.schedules,
              (schedule) => schedule.vacancySession < 0
            )
          };
        }
      );
      setLevelsInCell(dataResult);
      setTextShowMore(text);
      if (text.length) {
        setNumberRow(levelsShowed.length + 1);
      } else {
        setNumberRow(levelsShowed.length);
      }
    } else {
      setLevelsInCell([]);
      setTextShowMore('');
    }
  }, [classes, cell]);
  const handleOpenPopup = (item: IGroupClassSession) => {
    onChangeSelectedTime(cell.time);
    const endTime = dayjs(cell.date).format('YYYY-MM-DD') + ' ' + cell.time;
    onChangeSelectedEndTime(dayjs(endTime).add(30, 'minutes').format('HH:mm'));
    onChangeSelectedDayForPreview(cell.date);
    onChangeSelectedLevel(item);
  };

  return (
    <>
      {[...levelsInCell].map((item: IGroupClassSession, idx: number) => (
        <div
          key={idx}
          className="level-item"
          style={{
            borderLeft: `4px solid ${
              item?.colorCode || item?.levelBreakdown?.colorCode || '#034ea2'
            }`,
            height: `calc((100% - ${numberRow * 2}px) / ${numberRow})`
          }}
          onClick={() => handleOpenPopup(item)}
        >
          <div className="text">
            {item.isOverBooked ? <BiInfoCircle /> : null}
            <span>{`${item.levelBreakdown?.shortName || 'All Levels'} `}</span>
          </div>
          {item.isCanBook ? (
            <div
              className="active"
              style={{
                backgroundColor:
                  item?.colorCode ||
                  item?.levelBreakdown?.colorCode ||
                  '#034ea2'
              }}
            ></div>
          ) : null}
        </div>
      ))}
      {textShowMore.length > 0 && (
        <div>
          <div
            className="level-item"
            style={{
              height: `calc((100% - ${numberRow * 2}px) / ${numberRow})`,
              justifyContent: 'center'
            }}
            onClick={() => handleOpenPopup(levelsInCell[0])}
          >
            <div className="text">{textShowMore}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default LevelList;
