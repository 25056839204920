import { memo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import AppTable from 'common/components/AppTable';
import ReportExportButton from '../components/ReportExportButton';

interface ReportLocationGst {
  _id: string; 
  name: string; 
  total: number;
  gstAmount: number;
  totalExcludingGst: number;
  createdAt: number;
}

interface Props {
  data: {
    data: ReportLocationGst[];
    total: number;
  };
  loading?: boolean;
  onClickExport?: () => void;
  onClickExportBreakdown?: () => void;
}

const reportLocationDetailedView = ({
  data,
  loading,
  onClickExport,
  onClickExportBreakdown
}: Props) => {
  const columnHelper = createColumnHelper<ReportLocationGst>();

  const columns = [
    columnHelper.accessor('name', {
      header: () => <span>Name</span>,
      cell: (info: any) => info.getValue()
    }),

    columnHelper.accessor('total', {
      header: () => <span>Total</span>,
      cell: (info: any) => info.getValue()
    }),

    columnHelper.accessor('gstAmount', {
      header: () => <span>Gst Amount</span>,
      cell: (info: any) => info.getValue()
    }),

    columnHelper.accessor('totalExcludingGst', {
      header: () => <span>Total Excluding Gst</span>,
      cell: (info: any) => info.getValue()
    }),
  ];

  return (
    <section className="reportLocationsDetailedView">
      <div className="reportLocationsDetailedView__header">
        <p className="reportLocationsDetailedView__header--title">Locations</p>
        <div className="reportLocationsDetailedView__header--actions">
          <ReportExportButton onClick={onClickExport}/>
          <ReportExportButton label="Export Breakdown" onClick={onClickExportBreakdown}/>
        </div>
      </div>

      <div className="reportLocationsDetailedView__table">
        <AppTable
          loading={loading}
          data={data?.data}
          columns={columns}
        />
      </div>
    </section>
  );
};

export default memo(reportLocationDetailedView);
