import { useAuth } from 'context/AuthContext';
import { PERMISSION } from 'common/enums/permission.enum';
import { isArray, some } from 'lodash';
import React from 'react';

interface IPermissionWrapperProps {
  permission: PERMISSION | Array<PERMISSION>;
  children: React.ReactNode;
}

const PermissionWrapper = (props: IPermissionWrapperProps): React.ReactNode => {
  const { permission, children } = props;

  const { hasPermission } = useAuth();

  if (isArray(permission) && some(permission, (item) => !hasPermission(item)))
    return null;

  if (!isArray(permission) && !hasPermission(permission)) return null;

  return children;
};

export default PermissionWrapper;
