import {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  memo
} from 'react';
import { useParams } from 'react-router-dom';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import AppCard, { AppCardContent } from 'common/components/AppCard';
import { useToast } from 'context/ToastContext';
import { getLocationDetail } from 'services/location.service';
import { formatData } from 'common/helpers/dataFormat.helper';
import { BeatLoader } from 'react-spinners';
import { ILocation } from 'common/interfaces/location.interface';

import './desktop.scss';

export interface RefetchHandle {
  refetch: () => void;
}

const LocationSummaryInfo = forwardRef<RefetchHandle>((_, ref) => {
  useImperativeHandle(ref, () => ({
    refetch() {
      fetchData();
    }
  }));

  const { id } = useParams();
  const toast = useToast();

  const [initLoad, setInitLoad] = useState(false);
  const [dataLoad, setDataLoad] = useState(true);
  const [data, setData] = useState<ILocation | null>(null);

  const fetchData = useCallback(async () => {
    try {
      if (!id) return;

      const data = await getLocationDetail(id);

      setData(data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fetch location detail failed'
      );

      setData(null);
    } finally {
      setDataLoad(false);

      if (initLoad) setInitLoad(false);
    }

    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (initLoad) <AppLoadingContainer />;

  return (
    <section className="summary-info">
      <AppCard>
        <AppCardContent className="card-content">
          {dataLoad ? (
            <div className="loadData">
              <BeatLoader />
            </div>
          ) : null}

          <div className="summary-info_header">
            <div className="summary-info_name">{formatData(data?.name)}</div>
            <div className="summary-info_button"></div>
          </div>

          <div className="summary-info_wrap">
            <div className="summary-info_wrap-content">
              <div className="summary-info-item">
                <span className="summary-info-item_label">Address: </span>
                <span className="summary-info-item_value">
                  {formatData(data?.address?.streetAddress)}
                </span>
              </div>
            </div>
          </div>
        </AppCardContent>
      </AppCard>
    </section>
  );
});

export default memo(LocationSummaryInfo);
