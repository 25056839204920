import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLayout } from 'context/LayoutContext';
import { Link } from 'react-router-dom';

import AppRadio from 'common/components/AppRadio';

import MenuIcon from 'components/layout/MenuIcon';

import './styles.scss';
import { ENROLLMENT_STEP } from 'common/enums/student.enum';
import { BookingStep } from 'common/interfaces/student.interface';

interface IBreadcrumbItem {
  name: string;
  href?: string;
}

interface IAppBreadCrumbProps {
  items: Array<IBreadcrumbItem>;
  onClickStep: (step: string) => void;
}

const initBookingSteps = [
  { title: 'Booking', active: false, id: ENROLLMENT_STEP.BOOKING },
  { title: 'Enrolment', active: false, id: ENROLLMENT_STEP.ENROLLMENT },
  { title: 'Review & Payment', active: false, id: ENROLLMENT_STEP.PAYMENT }
];

const EnrollmentHeader: FC<IAppBreadCrumbProps> = ({ onClickStep }) => {
  const [searchParams] = useSearchParams();

  const { handleMouseLeaveMenu, isShowSidebar } = useLayout();

  const [breadCrumb] = useState<IBreadcrumbItem[]>([]);
  const [bookingSteps, setBookingSteps] = useState<BookingStep[]>([
    ...initBookingSteps
  ]);
  const [stepBooking, setStepBooking] = useState<number>(1);

  useEffect(() => {
    const params = searchParams.get('stepBooking') || ENROLLMENT_STEP.BOOKING;
    setStepBooking(Number(params));
    if (params) {
      setBookingSteps((bookingSteps: BookingStep[]) => {
        return bookingSteps.map((step: BookingStep) => {
          if (step.id === params) {
            return { ...step, active: true };
          }

          return { ...step, active: false };
        });
      });
    }
  }, [searchParams]);

  const renderStep = useCallback(() => {
    return bookingSteps?.map((bookingStep: BookingStep, index: number) => (
      <Fragment key={index}>
        <div
          className="app_header_step"
          key={index}
          style={{
            cursor: stepBooking - 1 > index ? 'pointer' : 'default'
          }}
          onClick={() =>
            stepBooking - 1 > index ? onClickStep(bookingStep.id) : undefined
          }
        >
          {stepBooking - 1 > index ? (
            <img
              src="/icons/step-filled.svg"
              alt=""
              width={22}
              style={{ marginRight: '12px' }}
            />
          ) : (
            <AppRadio checked={bookingStep?.active} disabled />
          )}

          <div className="app_header_step_text">{bookingStep?.title}</div>
        </div>

        {index >= 0 && index < bookingSteps?.length - 1 ? (
          <>
            <div
              key={`${index}-book`}
              className="app_header_steps_wrapper_break"
            ></div>
          </>
        ) : null}
      </Fragment>
    ));
  }, [bookingSteps, stepBooking, onClickStep]);

  return (
    <div
      className={`app_header__wrapper ${
        isShowSidebar ? ' ' : 'c_app_header__wrapper-collapsed'
      }`}
      style={{ width: isShowSidebar ? 'calc(100% - 280px)' : '100%' }}
    >
      <div>
        <MenuIcon />
        <div className="app_header" onMouseEnter={handleMouseLeaveMenu}>
          {breadCrumb?.length > 1 && (
            <div className="app_header-items">
              {[...breadCrumb].slice(1).map((item: IBreadcrumbItem, key) => {
                return (
                  <div key={key} className="app_header-item">
                    <Link to={item?.href || ''}>{item.name}</Link>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <div className="app_header_steps_wrapper">{renderStep()}</div>
      <div></div>
    </div>
  );
};

export default EnrollmentHeader;
