import React from 'react';
import AppModal, {
  AppModalFormActions,
  AppModalFormContent,
  AppModalFormTitle
} from 'common/components/AppModal';
import AppTextArea from 'common/components/AppTextArea';
import Upload, {
  ERROR_MESSAGE_LIMIT_FILES,
  ERROR_MESSAGE_LIMIT_SIZE
} from 'components/Upload';
import AppButton from 'common/components/AppButton';
import { useToast } from 'context/ToastContext';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import yupAbsence from 'validators/absence.validator';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateAbsenceDTO } from 'DTOs/absence.dto';
import dayjs from 'dayjs';
import { formatTime, getDoTypeDay } from 'common/helpers/dataFormat.helper';
import { ISchedule } from 'common/interfaces/schedules.interface';
import { ABSENCE_STATUS, REFUND_CREDIT_TYPE } from 'common/enums/absence.enum';
import AppInput from 'common/components/AppInput';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import AppRadio from 'common/components/AppRadio';
import { useEffect, useState } from 'react';

import './desktop.scss';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';

dayjs.extend(customParseFormat);

type Schedule = Pick<
  ISchedule,
  '_id' | 'startTime' | 'startHour' | 'endHour'
> & {
  className: string;
};

interface IAbsenceModalProps {
  loading?: boolean;
  open: boolean;
  onClose: () => void;
  onSave: () => void;

  files: Array<File>;
  onChangeFiles: (files: Array<File>) => void;
  reason: string;
  onChangeReason: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  documents?: Array<string>;
  onChangeDocuments?: (documents: Array<string>) => void;
  declineReason?: string;
  onChangeDeclineReason?: (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  // Tab absence form
  schedules?: Array<Schedule>;
  status?: ABSENCE_STATUS;
  onApproveAbsence?: () => void;
  onDeclineAbsence?: () => void;

  lessonDateTimes: Array<string>;

  // BH: 381 - Display Date and Time of absence submission.
  submissionDateTime?: string;
  refundCreditType?: REFUND_CREDIT_TYPE;
  onChangeRefundCreditType?: (refundCreditType: REFUND_CREDIT_TYPE) => void;

  // SS1311 - Constraint: Special Enrolments can NOT request for Money Credit when submitting absence forms
  isSpecialEnrollment?: boolean;
  isCreate?: boolean;
}

const AbsenceModal = (props: IAbsenceModalProps) => {
  const {
    loading = false,
    open,
    onClose,
    onSave,
    files,
    onChangeFiles,
    reason,
    onChangeReason,
    documents = [],
    onChangeDocuments = () => undefined,
    declineReason,
    onChangeDeclineReason = () => undefined,
    schedules = [],
    status = ABSENCE_STATUS.IDLE,
    onApproveAbsence,
    onDeclineAbsence,
    submissionDateTime,
    lessonDateTimes,
    refundCreditType,
    onChangeRefundCreditType,
    isSpecialEnrollment,
    isCreate = false
  } = props;

  const [tempRefundCreditType, setTempRefundCreditType] =
    useState(refundCreditType);

  useEffect(() => {
    setTempRefundCreditType(refundCreditType);
  }, [refundCreditType]);

  const toast = useToast();

  const validationSchema = yupAbsence.OBJECT({
    reason: yupAbsence.ABSENCE_REASON,
    certificateMedicals: yupAbsence.ABSENCE_CERTIFICATE_MEDICALS,
    declineReason:
      typeof declineReason === 'undefined'
        ? yupAbsence.ABSENCE_DECLINE_REASON
        : yupAbsence.ABSENCE_DECLINE_REASON_REQUIRED,
    status: yupAbsence.ABSENCE_STATUS,
    refundCreditType: yupAbsence.ABSENCE_REFUND_CREDIT_TYPE
  });

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<UpdateAbsenceDTO>({
    resolver: yupResolver(validationSchema) as Resolver<UpdateAbsenceDTO>,
    shouldUnregister: true
  });

  if (!open) return null;

  return (
    <div className="absenceModalWrapper">
      <AppModal open={open} onClose={onClose}>
        {loading && <AppLoadingContainer />}

        <AppModalFormTitle>
          <div className="tittle_wrapper">
            <div className="tittle">
              <div className="tt1">Absence Form</div>
              <div className="tt2">
                Current date and time
                {` ${getDoTypeDay(dayjs())} ${dayjs().format(
                  'MMM, YYYY HH:mm'
                )}`}
              </div>
            </div>
          </div>
        </AppModalFormTitle>
        <AppModalFormContent>
          <div className="lessonDateAndTimes">
            <p>Lesson date and time:</p>
            {lessonDateTimes?.map((lessonDateTime, index) => {
              return (
                <div key={index}>
                  {getDoTypeDay(dayjs(lessonDateTime))}{' '}
                  {dayjs(lessonDateTime).format('MMM, YYYY')}{' '}
                  {formatTime(lessonDateTime)}
                </div>
              );
            })}
          </div>
          {submissionDateTime && (
            <div className="dateTime">
              {submissionDateTime && (
                <AppInput
                  label="Submission Date"
                  value={submissionDateTime}
                  disabled
                />
              )}
            </div>
          )}
          <div className="reason">
            <AppTextArea
              label="Reason*"
              {...register('reason')}
              name="reason"
              value={reason}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                // @ts-ignore
                setValue(event.target.name, event.target.value);
                // @ts-ignore
                trigger(event.target.name);
                onChangeReason(event);
              }}
              message={{
                type: 'error',
                text: errors?.reason?.message || ''
              }}
            />
          </div>

          <p className="uploadTitle">Medical Certificate</p>
          <Upload
            files={files}
            documents={documents}
            onChangeDocuments={onChangeDocuments}
            onChangeFiles={onChangeFiles}
            onErrorFiles={(errorMessage: string) => {
              if (errorMessage === ERROR_MESSAGE_LIMIT_SIZE) {
                toast.error('Exceed the size');
              } else if (errorMessage === ERROR_MESSAGE_LIMIT_FILES) {
                toast.error('Exceed the number of files');
              }
            }}
          />

          {typeof declineReason !== 'undefined' && (
            <div className="declineReason">
              <AppTextArea
                rows={2}
                label="Decline Reason"
                {...register('declineReason')}
                name="declineReason"
                value={declineReason}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  // @ts-ignore
                  setValue(event.target.name, event.target.value);
                  // @ts-ignore
                  trigger(event.target.name);
                  onChangeDeclineReason(event);
                }}
                message={{
                  type: 'error',
                  text: errors?.declineReason?.message || ''
                }}
              />
            </div>
          )}

          <div
            style={{
              display: 'flex',
              gap: '4px',
              marginTop: '25px',
              alignItems: 'center'
            }}
          >
            <AppRadio
              checked={
                tempRefundCreditType === REFUND_CREDIT_TYPE.MAKEUP_CREDIT
              }
              onChange={() => {
                if (onChangeRefundCreditType)
                  onChangeRefundCreditType(REFUND_CREDIT_TYPE.MAKEUP_CREDIT);

                setTempRefundCreditType(REFUND_CREDIT_TYPE.MAKEUP_CREDIT);
              }}
            />
            <div>I'd like to receive a make-up credit for this class</div>
          </div>

          {isSpecialEnrollment ? null : (
            <>
              {files?.length || documents?.length ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      gap: '4px',
                      marginTop: '15px',
                      alignItems: 'center'
                    }}
                  >
                    <AppRadio
                      checked={
                        tempRefundCreditType === REFUND_CREDIT_TYPE.MONEY_CREDIT
                      }
                      onChange={() => {
                        if (onChangeRefundCreditType)
                          onChangeRefundCreditType(
                            REFUND_CREDIT_TYPE.MONEY_CREDIT
                          );

                        setTempRefundCreditType(
                          REFUND_CREDIT_TYPE.MONEY_CREDIT
                        );
                      }}
                    />
                    <div>
                      I'd like the cost of this class credited to my account.
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
        </AppModalFormContent>
        <AppModalFormActions>
          <div className="commonBtnGroup">
            <PermissionWrapper
              permission={
                isCreate ? PERMISSION.CREATE_ABSENCE : PERMISSION.UPDATE_ABSENCE
              }
            >
              <AppButton
                buttonSize="small"
                variant="primary"
                isLoading={loading}
                onClick={handleSubmit(onSave)}
              >
                save
              </AppButton>
            </PermissionWrapper>
            <AppButton
              buttonSize="small"
              variant="secondary"
              disabled={loading}
              onClick={onClose}
            >
              cancel
            </AppButton>
          </div>

          {status === ABSENCE_STATUS.PENDING && schedules.length > 0 && (
            <div className="absenceBtnGroup">
              {!!onApproveAbsence && (
                <PermissionWrapper permission={PERMISSION.UPDATE_ABSENCE}>
                  <AppButton
                    buttonSize="small"
                    variant="primary"
                    isLoading={loading}
                    onClick={onApproveAbsence}
                  >
                    approve
                  </AppButton>
                </PermissionWrapper>
              )}
              {!!onDeclineAbsence && (
                <PermissionWrapper permission={PERMISSION.UPDATE_ABSENCE}>
                  <AppButton
                    className="declineBtn"
                    buttonSize="small"
                    variant="secondary"
                    isLoading={loading}
                    onClick={onDeclineAbsence}
                  >
                    decline
                  </AppButton>
                </PermissionWrapper>
              )}
            </div>
          )}
        </AppModalFormActions>
      </AppModal>
    </div>
  );
};

export default AbsenceModal;
