import React, { MouseEvent } from 'react';
import { CustomPicker } from 'react-color';
import { Saturation, Hue } from 'react-color/lib/components/common';
import tinycolor2 from 'tinycolor2';

const inlineStyles = {
  container: {
    display: 'flex',
    height: 'auto',
    width: '258px',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  pointer: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    backgroundColor: 'transparent',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.37)',
    border: '3px solid white'
  },
  slider: {
    width: '8px',
    borderRadius: '8px',
    height: '24px',
    boxShadow: '0 0 2px rgba(0, 0, 0, .6)',
    background: 'var(--titleColor)',
    transform: 'translate(1px, -2px)'
  },
  saturation: {
    width: '100%',
    paddingBottom: '95%',
    overflow: 'hidden'
  },
  swatchCircle: {
    minWidth: 20,
    minHeight: 20,
    margin: '1px 2px',
    cursor: 'pointer',
    boxShadow: '0 0 2px rgba(0, 0, 0, .6)',
    borderRadius: '50%'
  }
};

const onPointerMouseDown = (event: MouseEvent<HTMLDivElement>) => {
  const pointer = document.querySelector('.custom-pointer') as HTMLDivElement;
  const pointerContainer = pointer?.parentElement as HTMLDivElement;
  if (pointerContainer) {
    pointerContainer.style.top = event.clientY + 'px';
    pointerContainer.style.left = event.clientX + 'px';
  }
};

const CustomSlider = () => {
  return <div style={inlineStyles.slider} />;
};

const CustomPointer = () => {
  return <div className="custom-pointer" style={inlineStyles.pointer} />;
};

interface Props {
  colors?: string[];
  hexCode: string;
  onChange: (color: any) => void;
}
interface State {
  hsl: { h: number; s: number; l: number };
  hsv: { h: number; s: number; v: number };
  hex: string;
}

class CustomColorPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hsl: {
        h: 0,
        s: 0,
        l: 0
      },
      hsv: {
        h: 0,
        s: 0,
        v: 0
      },
      hex: this.props.hexCode
    };
  }

  componentDidMount() {
    const color = tinycolor2(this.props.hexCode);
    this.setState({
      hsv: color.toHsv(),
      hsl: color.toHsl(),
      hex: color.toHex()
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.hexCode !== this.props.hexCode) {
      const color = tinycolor2(this.props.hexCode);
      this.setState({
        hsv: color.toHsv(),
        hsl: color.toHsl(),
        hex: color.toHex()
      });
    }
  }

  handleHueChange = (hue: any) => {
    this.setState({
      hsl: hue
    });
  };

  handleSaturationChange = (hsv: any) => {
    const color = tinycolor2(hsv);
    this.props.onChange(hsv);
    this.setState({
      hsl: color.toHsl()
    });
  };

  displayColorSwatches = (colors: string[]) => {
    return colors.map((color) => {
      return (
        <div
          onClick={() => this.props.onChange(color)}
          key={color}
          style={{ ...inlineStyles.swatchCircle, backgroundColor: color }}
        />
      );
    });
  };

  render() {
    return (
      <div
        style={{
          ...inlineStyles.container,
          flexDirection: 'column',
          textAlign: 'center'
        }}
      >
        <div
          style={{ ...inlineStyles.saturation, position: 'relative' }}
          onMouseDown={onPointerMouseDown}
        >
          <Saturation
            hsl={this.state.hsl}
            hsv={this.state.hsv}
            pointer={CustomPointer}
            onChange={this.handleSaturationChange}
          />
        </div>
        <div
          style={{
            height: 20,
            position: 'relative',
            margin: '10px 0px',
            width: '100%'
          }}
        >
          <Hue
            hsl={this.state.hsl}
            pointer={CustomSlider}
            // @ts-ignore
            onChange={this.handleHueChange}
            direction={'horizontal'}
          />
        </div>
      </div>
    );
  }
}

// @ts-ignore
export default CustomPicker(CustomColorPicker);
