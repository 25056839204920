import AppButton from 'common/components/AppButton';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import { IVoucher } from 'common/interfaces/voucher.interface';
import { useToast } from 'context/ToastContext';
import { useCallback, useState } from 'react';
import { deleteVoucher } from 'services/voucher.service';

interface IVoucherDeleteModalProps {
  voucherSelected: IVoucher;
  onClose: () => void;
  onSuccess: () => void;
}

const VoucherDeleteModal = (props: IVoucherDeleteModalProps) => {
  const { voucherSelected, onSuccess, onClose } = props;

  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      await deleteVoucher(voucherSelected._id);
      toast.success('Deleted this voucher');
      onSuccess();
    } catch (error: any) {
      toast.error('Failed to delete this voucher');
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [voucherSelected._id, onSuccess]);

  return (
    <AppModal open={!!voucherSelected} onClose={onClose}>
      <AppModalTitle>Delete</AppModalTitle>
      <AppModalContent>
        ARE YOU SURE YOU WANT TO DELETE THIS <br /> VOUCHER ?
      </AppModalContent>
      <AppModalActions>
        <AppButton variant="secondary" onClick={onClose}>
          No
        </AppButton>
        <AppButton isLoading={loading} onClick={handleDelete}>
          Yes
        </AppButton>
      </AppModalActions>
    </AppModal>
  );
};

export default VoucherDeleteModal;
