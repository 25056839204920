import { yupResolver } from '@hookform/resolvers/yup';
import { createColumnHelper } from '@tanstack/react-table';
import {
  CreateNotificationVoucherDTO,
  CreateNotificationVoucherPayloadDTO
} from 'DTOs/voucher.dto';
import AppButton from 'common/components/AppButton';
import AppCard, { AppCardContent } from 'common/components/AppCard';
import AppCheckbox from 'common/components/AppCheckbox';
import AppCopyButton from 'common/components/AppCopyButton';
import AppInput from 'common/components/AppInput';
import AppInputSearch from 'common/components/AppInputSearch';
import AppSelect from 'common/components/AppSelect';
import AppTable from 'common/components/AppTable';
import AppTextArea from 'common/components/AppTextArea';
import { DATE_CONSTANT, NOTIFICATION_TYPE_OPTIONS } from 'common/constants';
import { NOTIFICATION_TYPE } from 'common/enums/notification.enum';
import { PERMISSION } from 'common/enums/permission.enum';
import { formatData } from 'common/helpers/dataFormat.helper';
import { convertToUnixTime } from 'common/helpers/time.helper';
import useDebounce from 'common/hooks/useDebounce';
import { IOption } from 'common/interfaces';
import { INotificationTemplate } from 'common/interfaces/notificationTemplate.interface';
import {
  IStudent,
  IStudentListFilter
} from 'common/interfaces/student.interface';
import { IVoucher } from 'common/interfaces/voucher.interface';
import PermissionWrapper from 'components/PermissionWrapper';
import AppCKEditor from 'components/common/AppCKEditor';
import StudentFilter from 'components/notifications/components/StudentFilter';
import { initFilterValue } from 'components/notifications/constants';
import { IStudentFilter } from 'components/notifications/interface';
import { useBrandLocation } from 'context/BrandLocationContext';
import { useToast } from 'context/ToastContext';
import { htmlToPlainText, plainTextToHtml } from 'helpers/index.helper';
import { every, uniq } from 'lodash';
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { GoFilter } from 'react-icons/go';
import { HiArrowLeft } from 'react-icons/hi';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import { getNotificationTemplateList } from 'services/notificationTemplate.service';
import { findStudentList } from 'services/students.service';
import { sendVoucherToMember } from 'services/voucher.service';
import yupVoucher from 'validators/voucher.validator';
import './desktop.scss';

interface ISendToMemberProps {
  data: IVoucher;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const initNotification: CreateNotificationVoucherDTO = {
  notificationTypes: [],
  studentIds: [],
  title: '',
  message: '',
  voucherId: '',
  templateId: ''
};

const validationSchema = yupVoucher.OBJECT({
  templateId: yupVoucher.NOTIFICATION_TEMPLATE,
  notificationTypes: yupVoucher.NOTIFICATION_TYPES,
  title: yupVoucher.NOTIFICATION_TITLE,
  message: yupVoucher.NOTIFICATION_CONTENT,
  studentIds: yupVoucher.NOTIFICATION_STUDENT_ID,
  voucherId: yupVoucher.VOUCHER_ID
});

const SendToMember = (props: ISendToMemberProps) => {
  const columnHelper = createColumnHelper<IStudent>();

  const { data, open, onSuccess, onClose } = props;

  const toast = useToast();
  const { selectedLocation: globalLocation } = useBrandLocation();

  const currentFilter = useRef<IStudentFilter | null>({
    ...initFilterValue,
    locationId: globalLocation?._id || ''
  });

  const [notificationTemplates, setNotificationTemplates] = useState<
    Array<INotificationTemplate>
  >([]);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [notification, setNotification] =
    useState<CreateNotificationVoucherDTO>({
      ...initNotification,
      voucherId: data._id
    });
  const [selectedStudentIds, setSelectedStudentIds] = useState<Array<string>>(
    []
  );
  const [pageTotal, setPageTotal] = useState<number>(0);
  const [students, setStudents] = useState<Array<IStudent>>([]);
  const [loadingStudents, setLoadingStudents] = useState<boolean>(false);
  const [filterFieldNumber, setFilterFieldNumber] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search);

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<CreateNotificationVoucherDTO>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<CreateNotificationVoucherDTO>,
    defaultValues: { ...initNotification, voucherId: data._id }
  });

  const columns = [
    columnHelper.accessor('_id', {
      header: () => (
        <AppCheckbox checked={__isCheckAll} onChange={handleToggleAll} />
      ),
      cell: (info) => (
        <AppCheckbox
          value={info.row.original?._id}
          checked={selectedStudentIds.includes(info.row.original?._id)}
          onChange={handleToggleStudent}
        />
      )
    }),
    columnHelper.accessor('firstName', {
      header: () => <span>surname, name</span>,
      cell: (info) => (
        <div>
          {formatData(info.row.original.lastName)},{' '}
          {formatData(info.getValue())}
        </div>
      )
    }),
    columnHelper.accessor('responsiblePersonInfo', {
      header: () => <span>rp name</span>,
      cell: (info) => (
        <div>
          {formatData(info.getValue()?.lastName)},{' '}
          {formatData(info.getValue()?.firstName)}
        </div>
      )
    }),
    columnHelper.accessor('responsiblePersonInfo', {
      header: () => <span>rp mobile</span>,
      cell: (info) => <div>{formatData(info.getValue()?.phoneNumber)}</div>
    }),
    columnHelper.accessor('responsiblePersonInfo', {
      header: () => <span>rp email</span>,
      cell: (info) => <div>{formatData(info.getValue()?.email)}</div>
    })
  ];

  const handleOpenFilter = useCallback(() => {
    setShowFilter(true);
  }, []);

  const handleCloseFilter = useCallback(() => {
    setShowFilter(false);
  }, []);

  const handleToggleStudent = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newSelectedStudentIds = [...selectedStudentIds];
      const findIndex = selectedStudentIds.findIndex(
        (id: string) => id === event.target.value
      );
      if (findIndex === -1) {
        newSelectedStudentIds.push(event.target.value);
      } else {
        newSelectedStudentIds.splice(findIndex, 1);
      }
      setSelectedStudentIds(newSelectedStudentIds);
    },
    [selectedStudentIds]
  );

  const __isCheckAll = useMemo((): boolean => {
    if (students.length === 0) return false;

    return every(students, (student: IStudent) =>
      selectedStudentIds.includes(student._id)
    );

    // eslint-disable-next-line
  }, [selectedStudentIds, pageTotal, students]);

  const handleToggleAll = useCallback(() => {
    const tempStudentIds = students.map((student) => student._id);

    if (__isCheckAll) {
      setSelectedStudentIds((prev) => {
        return prev.filter((studentId) => !tempStudentIds.includes(studentId));
      });
    } else {
      setSelectedStudentIds((prev) => {
        return uniq([...prev, ...tempStudentIds]);
      });
    }
  }, [__isCheckAll, students]);

  const fetchNotificationTemplate = useCallback(async () => {
    try {
      const result = await getNotificationTemplateList(1, 100, '');
      setNotificationTemplates(result?.data?.data?.data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fail to get notification template'
      );
      setNotificationTemplates([]);
    }
    // eslint-disable-next-line
  }, []);

  const fetchStudents = useCallback(async () => {
    if (!globalLocation?._id || !currentFilter.current) return;

    try {
      setLoadingStudents(true);

      const params: IStudentListFilter = {};
      if (debouncedSearch) {
        params.keyword = debouncedSearch;
      }

      let filterFieldNumber = 0;

      if (currentFilter.current.termId) {
        params.termId = currentFilter.current.termId;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.levelId) {
        params.levelIds = currentFilter.current.levelId;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.instructorId) {
        params.instructorIds = currentFilter.current.instructorId;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.assessmentResult) {
        params.assessmentResult = currentFilter.current.assessmentResult;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.assessmentStatus) {
        params.assessmentStatus = currentFilter.current.assessmentStatus;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.paymentType) {
        params.paymentType = currentFilter.current.paymentType;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.outstandingFee) {
        params.outstandingFee = currentFilter.current.outstandingFee;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.enrollmentType) {
        params.enrollmentTypes = currentFilter.current.enrollmentType;
        filterFieldNumber += 1;
      }

      if (currentFilter.current.dateField) {
        filterFieldNumber += 1;
        if (currentFilter.current.dateFrom)
          params.fromDate = convertToUnixTime(currentFilter.current.dateFrom);
        if (currentFilter.current.dateTo)
          params.toDate = convertToUnixTime(currentFilter.current.dateTo);
      }
      if (currentFilter.current.timeField) {
        filterFieldNumber += 1;
        if (currentFilter.current.timeFrom)
          params.fromTime = convertToUnixTime(
            `${DATE_CONSTANT} ${currentFilter.current.timeFrom}`
          );
        if (currentFilter.current.timeTo)
          params.toTime = convertToUnixTime(
            `${DATE_CONSTANT} ${currentFilter.current.timeTo}`
          );
      }
      if (currentFilter.current.areaId) {
        filterFieldNumber += 1;
        params.areas = currentFilter.current.areaId;
      }

      if (currentFilter.current.programType) {
        filterFieldNumber += 1;
        params.classTypes = currentFilter.current.programType;
      }

      if (currentFilter.current.locationId) {
        filterFieldNumber += 1;
        params.locationIds = currentFilter.current.locationId;
      }

      setFilterFieldNumber(filterFieldNumber);

      if (Object.keys(params).length === 0) {
        return;
      }

      const { data, total } = await findStudentList(params);

      setPageTotal(total);
      setStudents(data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fetch Student List failed'
      );
    } finally {
      setLoadingStudents(false);
    }

    // eslint-disable-next-line
  }, [globalLocation, currentFilter, debouncedSearch]);

  const handleChangeTemplate = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const foundTemplate: INotificationTemplate | undefined =
        notificationTemplates.find(
          (notificationTemplate) =>
            notificationTemplate._id === event.target.value
        );

      if (foundTemplate) {
        setNotification({
          ...notification,
          title: foundTemplate.title,
          message: foundTemplate.message || '',
          notificationTypes: foundTemplate.types as Array<string>
        });

        reset({
          ...notification,
          title: foundTemplate.title || '',
          message: foundTemplate.message || '',
          notificationTypes: foundTemplate.types as Array<string>
        });
      }
    },
    [notification, notificationTemplates, reset]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let values = {
        ...notification,
        [event.target.name]: event.target.value
      };

      if (event.target.name === 'notificationTypes') {
        const newTypes = event.target.value?.split(',');
        const currentTypes = notification.notificationTypes;
        const newTypesIncludesSMS = newTypes.includes(NOTIFICATION_TYPE.SMS);
        const currentTypesIncludesSMS = currentTypes.includes(
          NOTIFICATION_TYPE.SMS
        );
        let messageContent = notification.message;

        if (!currentTypesIncludesSMS && newTypesIncludesSMS) {
          messageContent = htmlToPlainText(messageContent);
        }
        if (currentTypesIncludesSMS && !newTypesIncludesSMS) {
          messageContent = plainTextToHtml(messageContent);
        }

        values = {
          ...values,
          notificationTypes: event.target.value
            ? event.target.value?.split(',')
            : [],
          message: messageContent
        };

        setValue('message', messageContent);
      }

      setNotification(values);

      if (event.target.name === 'notificationTypes') {
        // @ts-ignore
        setValue(
          event.target.name,
          event.target.value ? event.target.value?.split(',') : []
        );
      } else {
        // @ts-ignore
        setValue(event.target.name, event.target.value);
      }
      // @ts-ignore
      trigger(event.target.name);
    },
    [notification, setValue, trigger]
  );

  const handleInsertVoucherCode = useCallback(() => {
    const isTypesIncludesSMS = notification.notificationTypes.includes(
      NOTIFICATION_TYPE.SMS
    );
    let newMessage = notification.message;

    if (isTypesIncludesSMS) {
      newMessage = `${newMessage} ${data.code}`;
    } else {
      newMessage += `<div>${data.code}</div>`;
    }

    setNotification({ ...notification, message: newMessage });
    setValue('message', newMessage);
    trigger('message');
  }, [data, notification, setValue, trigger]);

  const __notificationTemplateOptions: Array<IOption> = useMemo(() => {
    return notificationTemplates.map(
      (notificationTemplate: INotificationTemplate) => {
        return {
          value: notificationTemplate._id,
          label: formatData(notificationTemplate?.title)
        };
      }
    );
  }, [notificationTemplates]);

  const __notificationTypeOptions: Array<IOption> = useMemo(() => {
    return NOTIFICATION_TYPE_OPTIONS.map((option: IOption) => {
      return {
        value: option.value,
        label: formatData(option?.label)
      };
    });
  }, []);

  const handleChangeEmailMessage = useCallback(
    (value: string) => {
      setValue('message', value);
      setNotification((prev) => ({
        ...prev,
        message: value
      }));
      trigger('message');
    },
    [trigger, setValue]
  );

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  const handleFilter = useCallback(
    (filterValue: IStudentFilter) => {
      handleCloseFilter();
      setSelectedStudentIds([]);
      currentFilter.current = filterValue;
      fetchStudents();
    },
    [fetchStudents, handleCloseFilter]
  );

  const onClearSearch = useCallback(() => {
    setSearch('');
  }, []);

  const handleSendVoucherToMember = useCallback(
    async (dataForm: CreateNotificationVoucherDTO) => {
      if (!selectedStudentIds.length) {
        toast.error('Please filter and select student');
        return;
      }

      const payload: CreateNotificationVoucherPayloadDTO = {
        title: dataForm.title,
        voucherId: dataForm.voucherId,
        notificationTypes: dataForm.notificationTypes,
        message: dataForm.message,
        studentIds: selectedStudentIds
      };

      setLoading(true);

      try {
        await sendVoucherToMember(payload);

        toast.success('Send voucher to member successfully');
        onSuccess();
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Send voucher to member failed'
        );
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [onSuccess, selectedStudentIds.length]
  );

  useEffect(() => {
    fetchNotificationTemplate();
  }, [fetchNotificationTemplate]);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  return (
    <section className="vouchersSendToMemberContainer">
      <PermissionWrapper permission={PERMISSION.LIST_STUDENT}>
        <StudentFilter
          onClose={handleCloseFilter}
          showFilter={showFilter}
          onFilter={handleFilter}
        />
      </PermissionWrapper>
      <div
        className={`overlay ${open ? 'active' : ' '}`}
        onClick={onClose}
      ></div>
      <div className={`vouchersSendToMemberContainer ${open ? 'active' : ' '}`}>
        <div className="vouchersSendToMemberContainer__header">
          <div onClick={onClose}>
            <HiArrowLeft size={24} />
          </div>
          <p>SEND TO MEMBER</p>
        </div>

        <div className="vouchersSendToMemberContainer__content">
          <AppCard>
            <div className="vouchersSendToMemberContainer__info">
              <AppCardContent>
                <AppSelect
                  label="Template"
                  options={__notificationTemplateOptions}
                  name="template"
                  value={notification.templateId}
                  onChange={handleChangeTemplate}
                />
                <AppSelect
                  label="Type*"
                  placeholder="Type*"
                  options={__notificationTypeOptions}
                  {...register('notificationTypes')}
                  message={{
                    type: 'error',
                    text: errors?.notificationTypes?.message || ''
                  }}
                  name="notificationTypes"
                  value={notification.notificationTypes?.toString()}
                  onChange={handleChange}
                  multiValue
                />
              </AppCardContent>
            </div>
          </AppCard>

          <AppCard>
            <div className="vouchersSendToMemberContainer__description">
              <AppCardContent>
                <AppInput
                  label="Title*"
                  {...register('title')}
                  message={{
                    type: 'error',
                    text: errors?.title?.message || ''
                  }}
                  value={notification.title}
                  onChange={handleChange}
                />
                <div className="vouchersSendToMemberContainer__description--voucherCode">
                  <div>
                    <AppInput label="Voucher code" value={data.code} disabled />
                    <AppCopyButton textToCopy={data.code} />
                  </div>
                  <AppButton
                    buttonSize="large"
                    variant="secondary"
                    onClick={handleInsertVoucherCode}
                  >
                    Insert
                  </AppButton>
                </div>
                <div className="vouchersSendToMemberContainer__description--content">
                  <AppCardContent>
                    {notification.notificationTypes.includes(
                      NOTIFICATION_TYPE.SMS
                    ) ? (
                      <AppTextArea
                        label="Content*"
                        {...register('message')}
                        message={{
                          type: 'error',
                          text: errors?.message?.message || ''
                        }}
                        name="message"
                        value={notification.message}
                        onChange={handleChange}
                      />
                    ) : (
                      <div className="vouchersSendToMemberContainer__description--email">
                        <p className="vouchersSendToMemberContainer__description--email--label">
                          Content*
                        </p>
                        <AppCKEditor
                          value={notification.message}
                          onChange={handleChangeEmailMessage}
                        />
                        {errors?.message?.message && (
                          <p className="message--error">
                            {errors?.message?.message}
                          </p>
                        )}
                      </div>
                    )}
                  </AppCardContent>
                </div>
              </AppCardContent>
            </div>
          </AppCard>

          <AppCard>
            <div className="vouchersSendToMemberContainer__table">
              <div className="vouchersSendToMemberContainer__table__header">
                <h2 className="vouchersSendToMemberContainer__table__header--title">
                  SEND TO {selectedStudentIds.length} STUDENT
                  {pageTotal > 1 ? 'S' : ''}
                </h2>
                <PermissionWrapper permission={PERMISSION.LIST_STUDENT}>
                  <div className="vouchersSendToMemberContainer__table__header--actions">
                    <AppInputSearch
                      type="text"
                      onChange={handleSearchChange}
                      placeholder="Surname, name"
                      startIcon={<HiMagnifyingGlass />}
                      value={search}
                      onClearSearch={onClearSearch}
                    />
                    <AppButton
                      variant="secondary"
                      buttonSize="small"
                      onClick={handleOpenFilter}
                    >
                      <GoFilter size={22} />
                      Filters
                      {filterFieldNumber > 0 && (
                        <div className="filter-field-number">
                          {filterFieldNumber}
                        </div>
                      )}
                    </AppButton>
                  </div>
                </PermissionWrapper>
              </div>
              <div className="vouchersSendToMemberContainer__table__content">
                <AppTable
                  data={students}
                  columns={columns}
                  loading={loadingStudents}
                />
              </div>
            </div>
          </AppCard>
        </div>

        <div className="vouchersSendToMemberContainer__actions">
          <AppButton
            variant="primary"
            buttonSize="large"
            isLoading={loading}
            onClick={handleSubmit(handleSendVoucherToMember)}
            disabled={loading}
          >
            SEND
          </AppButton>
          <AppButton variant="secondary" buttonSize="large" onClick={onClose}>
            Cancel
          </AppButton>
        </div>
      </div>
    </section>
  );
};

export default SendToMember;
