import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppToggle from 'common/components/AppToggle';
import AppTextArea from 'common/components/AppTextArea';
import AppSelect from 'common/components/AppSelect';
import AppInput from 'common/components/AppInput';
import { IClassTemplate } from 'common/interfaces/classTemplate.interface';
import {
  addTermToTemplate,
  getClassTemplateById,
  removeTermFromTemplate,
  updateClassTemplate
} from 'services/classTemplate.service';
import { useParams } from 'react-router-dom';
import { useToast } from 'context/ToastContext';
import {
  UpdateClassTemplateDto,
  UpdateClassTemplatePayloadDto
} from 'DTOs/classTemplate.dto';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import yupClassTemplate from 'validators/classTemplate.validator';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CLASS_TEMPLATE_DURATIONS } from 'common/constants/index';
import {
  HiOutlinePencilSquare,
  HiOutlineXCircle,
  HiOutlineBookmark
} from 'react-icons/hi2';
import { BeatLoader } from 'react-spinners';
import { PERMISSION } from 'common/enums/permission.enum';
import { useAuth } from 'context/AuthContext';
import './desktop.scss';
import { formatData, formatMoneySign } from 'common/helpers/dataFormat.helper';
import { getTerms } from 'services/term.service';
import { useBrandLocation } from 'context/BrandLocationContext';
import { ITerm } from 'common/interfaces/term.interface';
import { generateTermDataHtml } from 'common/helpers/term.helper';
import dayjs from 'dayjs';
import { TERM_TYPE } from 'common/enums/term.enum';
import AppButton from 'common/components/AppButton';
import { HiPlus } from 'react-icons/hi';
import AppModal, {
  AppModalFormActions,
  AppModalFormContent,
  AppModalFormTitle
} from 'common/components/AppModal';
import { ILevelBreakdown } from 'common/interfaces/levelBreakdown.interface';
import { getLevelBreakdowns } from 'services/levelBreakdown.service';
import { handleNumberInput } from 'helpers/index.helper';

const validationSchema = yupClassTemplate.OBJECT({
  active: yupClassTemplate.CLASS_TEMPLATE_ACTIVE,
  name: yupClassTemplate.CLASS_TEMPLATE_NAME,
  duration: yupClassTemplate.CLASS_TEMPLATE_DURATION,
  termIds: yupClassTemplate.CLASS_TEMPLATE_TERM,
  description: yupClassTemplate.CLASS_TEMPLATE_DESCRIPTION,
  price: yupClassTemplate.CLASS_TEMPLATE_PRICE,
  levelId: yupClassTemplate.CLASS_TEMPLATE_LEVEL
});
const validationAddTermSchema = yupClassTemplate.OBJECT({
  termIds: yupClassTemplate.CLASS_TEMPLATE_TERM
});

const ClassTemplateDetail = () => {
  const params = useParams();
  const toast = useToast();

  const { selectedLocation: globalLocation } = useBrandLocation();
  const { hasPermission } = useAuth();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingAPI, setLoadingAPI] = useState<boolean>(false);
  const [classTemplate, setClassTemplate] = useState<IClassTemplate | null>(
    null
  );
  const classTemplateInit = useRef<IClassTemplate | null>(null);

  const [showModalAddTerm, setShowModalAddTerm] = useState<boolean>(false);
  const [showModalRemoveTerm, setShowModalRemoveTerm] =
    useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [terms, setTerms] = useState<ITerm[]>([]);
  const [selectedTermId, setSelectedTermId] = useState<string>('');
  const [levels, setLevels] = useState<ILevelBreakdown[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<ILevelBreakdown | null>(
    null
  );

  const __sessionsCannotBeRemoved = useMemo(() => {
    if (!classTemplate) return [];
    return classTemplate?.terms.filter((item) => !item.termDetail?.canRemove);
  }, [classTemplate]);

  const __sessionCanBeRemovedOptions = useMemo(() => {
    if (!classTemplate) return [];
    return classTemplate?.terms
      .filter((item) => item.termDetail?.canRemove)
      .map((item) => {
        return {
          value: item.termDetail?._id,
          label: item.termDetail?.name,
          hint: (
            <span style={{ fontSize: 12 }}>
              (
              {`${dayjs(item.termDetail.startDate).format('DD/MM/YYYY')} -  
            ${dayjs(item.termDetail.endDate).format('DD/MM/YYYY')}`}
              )
            </span>
          )
        };
      });
  }, [classTemplate]);

  const __sessionCanBeAddedOptions = useMemo(() => {
    if (!classTemplate) return [];
    const addedIds = classTemplate?.terms.map((item) => item.termDetail?._id);
    return terms
      .filter((item) => !addedIds.includes(item._id))
      .map((item) => {
        return {
          value: item._id,
          label: item?.name,
          hint: (
            <span style={{ fontSize: 12 }}>
              (
              {`${dayjs(item.startDate).format('DD/MM/YYYY')} -  
            ${dayjs(item.endDate).format('DD/MM/YYYY')}`}
              )
            </span>
          )
        };
      });
  }, [classTemplate, terms]);

  const __termsOption = useMemo(() => {
    return terms.map((term) => {
      const isExistedTerm = classTemplate?.terms.find(
        (item) => item.termId === term._id
      );
      return {
        ...term,
        canNotBeRemoved: isExistedTerm ? !!classTemplate?.classInUsed : false
      };
    });
  }, [terms, classTemplate]);

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    reset,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm<UpdateClassTemplateDto>({
    resolver: yupResolver(validationSchema) as Resolver<UpdateClassTemplateDto>
  });

  const {
    register: registerTerm,
    handleSubmit: handleSubmitTerm,
    setValue: setValueTerm,
    trigger: triggerTerm,
    formState: { errors: errorsAddTerm },
    reset: resetTerm
  } = useForm({
    resolver: yupResolver(validationAddTermSchema)
  });

  const onOpenEdit = () => {
    setIsEdit(true);
  };

  const onCloseEdit = () => {
    setIsEdit(false);
    setClassTemplate(classTemplateInit.current);
    reset({ ...classTemplateInit.current });
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.target.name === undefined) {
        setValue('termIds', event.target.value);
        trigger();
        // @ts-ignore
        setClassTemplate({
          ...classTemplate,
          termIds: event.target.value
        });
        return;
      }
      if (event.target.name === 'levelId') {
        const level =
          levels.find((item) => item._id === event.target.value) || undefined;
        level && setSelectedLevel(level);
      }
      if (event.target.name === 'price') {
        const price = handleNumberInput(
          event.target.value,
          classTemplate?.price || 0
        );
        // @ts-ignore
        setValue('price', price);
        trigger(event.target.name);
        // @ts-ignore
        setClassTemplate({
          ...classTemplate,
          price: Number(price)
        });
        return;
      }
      if (event.target.name === 'termIds') {
        setValue('termIds', event.target.value);

        if (!event.target.value) {
          setError(event.target.name, {
            type: 'optionality',
            message: 'Please select Session'
          });
        } else {
          clearErrors(['termIds']);
        }
        // @ts-ignore
        setClassTemplate({
          ...classTemplate,
          termIds: event.target.value
        });
        return;
      }

      // @ts-ignore
      setValue(event.target.name, event.target.value);
      // @ts-ignore
      trigger(event.target.name);
      // @ts-ignore
      setClassTemplate({
        ...classTemplate,
        [event.target.name]: event.target.value
      });
    },
    [classTemplate, setValue, trigger, levels, setError, clearErrors]
  );

  const handleChangeActive = useCallback(() => {
    if (classTemplate) {
      setClassTemplate({
        ...classTemplate,
        active: !classTemplate.active
      });
    }
  }, [classTemplate]);

  const onSaveEdit = useCallback(async () => {
    if (classTemplate) {
      setLoadingAPI(true);
      try {
        const payload: UpdateClassTemplatePayloadDto = {
          active: classTemplate.active,
          description: classTemplate.description,
          name: classTemplate.name,
          termIds: classTemplate.termIds.split(','),
          duration: Number(classTemplate.duration),
          price: Number(classTemplate.price),
          levelId: classTemplate.levelId,
          allLevel: classTemplate.levelId === 'all'
        };
        if (payload.allLevel) {
          payload.levelId = null;
        }
        await updateClassTemplate(classTemplate._id, payload);
        toast.success('Saved successfully');
        setIsEdit(false);
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Failed to save class template'
        );
      } finally {
        setLoadingAPI(false);
        fetchData();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classTemplate]);

  const __isCanEdit = useMemo((): boolean => {
    if (!!classTemplate?.classInUsed && classTemplate?.classInUsed > 0) {
      return false;
    }
    return true;
  }, [classTemplate?.classInUsed]);

  const __renderIcons = useMemo((): React.ReactNode => {
    if (isEdit) {
      return loadingAPI ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSaveEdit)}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <>
        {!__isCanEdit && (
          <>
            {__sessionCanBeAddedOptions.length > 0 && (
              <AppButton
                variant="primary"
                buttonSize="small"
                type="submit"
                onClick={() => setShowModalAddTerm(true)}
              >
                <div className="plusIcon">
                  <HiPlus />
                </div>
                <p>Session</p>
              </AppButton>
            )}
          </>
        )}
        <div className="icon" onClick={onOpenEdit}>
          <HiOutlinePencilSquare />
        </div>
      </>
    );
    // eslint-disable-next-line
  }, [isEdit, onCloseEdit, loadingAPI]);

  const __renderContent = useMemo((): React.ReactNode => {
    if (!classTemplate) return null;
    if (isEdit) {
      return (
        <div className="classTemplateDetail__card classTemplateDetail__card-edit">
          <div className="classTemplateDetail__card-toggle">
            <div className="customToggle">
              <AppToggle
                {...register('active')}
                value={classTemplate.active}
                onChange={handleChangeActive}
              />
              <p>Active</p>
            </div>
          </div>
          <div className="classTemplateDetail__card-fields classTemplateDetail__card-fields-edit">
            <AppInput
              label="Name*"
              {...register('name')}
              value={classTemplate.name}
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.name?.message || ''
              }}
            />
            <div className="classTemplateDetail__card-fields classTemplateDetail__card-fields-edit">
              <AppSelect
                label="Duration (mins)*"
                options={CLASS_TEMPLATE_DURATIONS}
                {...register('duration')}
                value={classTemplate.duration.toString()}
                onChange={handleChange}
                message={{
                  type: 'error',
                  text: errors?.duration?.message || ''
                }}
                disabled={!__isCanEdit}
              />
              <AppInput
                label="Price*"
                {...register('price')}
                onChange={handleChange}
                message={{
                  type: 'error',
                  text: errors?.price?.message || ''
                }}
              />
            </div>
          </div>
          <div className="classTemplateDetail__card-fields classTemplateDetail__card-fields-edit">
            <AppSelect
              label="Session (months)*"
              options={__termsOption}
              {...register('termIds')}
              value={classTemplate.termIds}
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.termIds?.message || ''
              }}
              multiValue
            />
            <div className="classTemplateDetail__card-fields classTemplateDetail__card-fields-edit">
              <AppSelect
                label="Level*"
                options={levels}
                {...register('levelId')}
                value={classTemplate.levelId}
                onChange={handleChange}
                message={{
                  type: 'error',
                  text: errors?.levelId?.message || ''
                }}
                disabled={!__isCanEdit}
              />
              <div className="pick-color-container">
                <div
                  className="pick-color"
                  style={{
                    backgroundColor: selectedLevel?.colorCode || '#034EA2'
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="classTemplateDetail__card-description">
            <AppTextArea
              label="Description"
              {...register('description')}
              value={classTemplate.description}
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.description?.message || ''
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="classTemplateDetail__card classTemplateDetail__card-display">
        <div className="classTemplateDetail__card-toggle">
          <div className="customToggle">
            <AppToggle value={classTemplate.active} disabled />
            <p>Active</p>
          </div>
        </div>
        <div className="classTemplateDetail__card-fields">
          <AppCardContentItem
            subtitle="Name"
            title={formatData(classTemplate.name)}
          />
          <div className="classTemplateDetail__card-fields">
            <AppCardContentItem
              subtitle="Duration (mins)"
              title={formatData(classTemplate.duration.toString())}
            />
            <AppCardContentItem
              subtitle="Price"
              title={formatMoneySign(classTemplate.price)}
            />
          </div>
          <AppCardContentItem
            subtitle="Session (months)"
            isHtml
            children={generateTermDataHtml(
              classTemplate.terms || [],
              classTemplate.termIsActive
            )}
          />
          <div className="classTemplateDetail__card-fields">
            <AppCardContentItem
              subtitle="Level"
              title={formatData(
                classTemplate?.levelBreakdown?.name || 'All Levels'
              )}
            />
            <AppCardContentItem
              subtitle="Color"
              title={formatData(
                classTemplate?.levelBreakdown?.colorCode || '#034EA2'
              )}
              isColor
            />
          </div>
        </div>
        <div className="classTemplateDetail__card-description">
          <AppCardContentItem
            subtitle="Description"
            title={formatData(classTemplate.description)}
          />
        </div>
      </div>
    );
  }, [
    isEdit,
    classTemplate,
    handleChange,
    handleChangeActive,
    errors,
    register,
    __termsOption,
    levels,
    selectedLevel,
    __isCanEdit
  ]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!globalLocation?._id) return;
    try {
      const response = await Promise.all([
        getClassTemplateById(params?.id || ''),
        getTerms(1, 100, globalLocation._id, undefined, [TERM_TYPE.TERM], true),
        getLevelBreakdowns(1, 100, globalLocation._id)
      ]);
      const termIds = response[0].data.data.terms.map(
        (item: ITerm) => item.termId
      );
      const classTemplate = {
        ...response[0].data.data,
        termIds: termIds.join(','),
        levelId: response[0].data.data.levelBreakdown?._id || 'all'
      };
      setClassTemplate(classTemplate);
      setSelectedLevel(classTemplate.levelBreakdown);
      classTemplateInit.current = classTemplate;
      setTerms(
        response[1].data.data.data.map((item: ITerm) => ({
          ...item,
          value: item._id,
          label: item.name,
          hint: (
            <span style={{ fontSize: 12 }}>
              (
              {`${dayjs(item.startDate).format('DD/MM/YYYY')} -  
              ${dayjs(item.endDate).format('DD/MM/YYYY')}`}
              )
            </span>
          )
        }))
      );
      const levels = response[2].data.data.data.map(
        (item: ILevelBreakdown) => ({
          ...item,
          value: item._id,
          label: item.name
        })
      );
      setLevels(levels);
    } catch (error: any) {
      setClassTemplate(null);
      setTerms([]);
      toast.error(
        error?.response?.data?.message ||
          'Failed to fetch class template and Session data'
      );
    } finally {
      setLoading(false);
      reset({ ...classTemplateInit.current });
    }
    // eslint-disable-next-line
  }, [params?.id, globalLocation?._id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onAddTerm = useCallback(async () => {
    try {
      await addTermToTemplate(classTemplate?._id || '', selectedTermId);
      fetchData();
      setShowModalAddTerm(false);
      setSelectedTermId('');
      resetTerm();
      toast.success('Add Session successfully');
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Add Session failed');
    }
    // eslint-disable-next-line
  }, [classTemplate?._id, fetchData, selectedTermId]);

  const onRemoveTerm = useCallback(async () => {
    try {
      await removeTermFromTemplate(classTemplate?._id || '', selectedTermId);
      fetchData();
      setShowModalRemoveTerm(false);
      setSelectedTermId('');
      resetTerm();
      toast.success('Remove Session successfully');
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Remove Session failed');
    }
    // eslint-disable-next-line
  }, [classTemplate?._id, fetchData, selectedTermId]);

  const handleChangeTerm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedTermId(event.target.value);
      setValueTerm('termIds', event.target.value);
      triggerTerm('termIds');
    },
    [setSelectedTermId, setValueTerm, triggerTerm]
  );

  const handleCancelAddTerm = useCallback(() => {
    setShowModalAddTerm(false);
    setSelectedTermId('');
    resetTerm();
  }, [resetTerm]);

  const handleCancelRemoveTerm = useCallback(() => {
    setShowModalRemoveTerm(false);
    setSelectedTermId('');
    resetTerm();
  }, [resetTerm]);

  return (
    <main className="classTemplateDetailPage">
      <AppBreadCrumb
        items={[
          { name: 'Class Template', href: '/class-template' },
          { name: 'Class Template details', href: '' }
        ]}
      />
      {loading ? (
        <AppLoadingContainer />
      ) : (
        <div className="layoutContainer classTemplateDetail">
          <AppCard>
            <AppCardHeader
              title="TEMPLATE"
              suffix={
                hasPermission(PERMISSION.UPDATE_CLASS_TEMPLATE) && __renderIcons
              }
            />
            <AppCardContent>{__renderContent}</AppCardContent>
          </AppCard>
          <AppModal open={showModalAddTerm} onClose={handleCancelAddTerm}>
            <AppModalFormTitle>Add Session in template</AppModalFormTitle>
            <AppModalFormContent>
              <AppSelect
                label="Session"
                options={__sessionCanBeAddedOptions}
                {...registerTerm('termIds')}
                message={{
                  type: 'error',
                  text: errorsAddTerm?.termIds?.message || ''
                }}
                value={selectedTermId}
                onChange={handleChangeTerm}
                multiValue
              />
            </AppModalFormContent>
            <AppModalFormActions>
              <AppButton
                onClick={handleSubmitTerm(onAddTerm)}
                buttonSize="small"
              >
                Save
              </AppButton>
              <AppButton
                onClick={handleCancelAddTerm}
                variant="secondary"
                buttonSize="small"
              >
                Cancel
              </AppButton>
            </AppModalFormActions>
          </AppModal>
          <AppModal open={showModalRemoveTerm} onClose={handleCancelRemoveTerm}>
            <AppModalFormTitle>Remove Session in template</AppModalFormTitle>
            <AppModalFormContent>
              {__sessionsCannotBeRemoved?.length > 0 && (
                <div className="info-text">
                  The sessions can not be removed:{' '}
                  {__sessionsCannotBeRemoved
                    ?.map((item: ITerm) => item.termDetail.name)
                    .join(', ')}
                </div>
              )}

              <AppSelect
                label="Session"
                options={__sessionCanBeRemovedOptions}
                {...registerTerm('termIds')}
                message={{
                  type: 'error',
                  text: errorsAddTerm?.termIds?.message || ''
                }}
                value={selectedTermId}
                onChange={handleChangeTerm}
                multiValue
              />
            </AppModalFormContent>
            <AppModalFormActions>
              <AppButton
                onClick={handleSubmitTerm(onRemoveTerm)}
                buttonSize="small"
              >
                Save
              </AppButton>
              <AppButton
                onClick={handleCancelRemoveTerm}
                variant="secondary"
                buttonSize="small"
              >
                Cancel
              </AppButton>
            </AppModalFormActions>
          </AppModal>
        </div>
      )}
    </main>
  );
};

export default ClassTemplateDetail;
