import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import './desktop.scss';
import {
  formatData,
  formatDate,
  formatMoneySign,
  roundByTwo
} from 'common/helpers/dataFormat.helper';
import dayjs from 'dayjs';
import { every, forEach, groupBy, round, some, sortBy } from 'lodash';
import {
  IBookingData,
  NextBillingCircle
} from 'common/interfaces/schedules.interface';
import { BOOKING_TYPE, PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import { PAYMENT_CARDS } from 'common/constants/classBooking.constant';
import {
  handleDateTimeRecord,
  handleGetPriceDirectDebit
} from 'common/helpers/classBooking.helper';
import AppModal from 'common/components/AppModal';
import { HiOutlineX } from 'react-icons/hi';
import { CLASS_TYPES, RECURRENCE_VALUES } from 'common/enums/class.enum';
import { useEnrollmentContext } from 'pages/students/Enrollment/EnrollmentContext';
import { IStudentBookingData } from 'common/interfaces/student.interface';
import { IVoucher } from 'common/interfaces/voucher.interface';
import ReviewBookingDataRow from './ReviewBookingDataRow';
import { DISCOUNT_TYPE } from 'common/enums';
import { getProgramType } from 'common/helpers/index.helper';
import { DiscountData } from 'common/interfaces/bookingClass.interface';
import { handleGetDataWithDiscount } from 'pages/students/Enrollment/helper';
import { handleGetPayNow } from 'helpers/enrolment.helper';
import { getAutomationDiscountText } from 'common/helpers/voucher.helper';
import { HiOutlineExclamationCircle } from 'react-icons/hi2';
import { VOUCHER_DURATION } from 'common/enums/voucher.enum';
import { FORMAT_END_OF_DATE } from 'pages/reports/constant';
import { getLessonsDayInRange } from 'common/helpers/classListBooking.helper';

type IStudentBookingDataGroup = {
  studentBookingData: IStudentBookingData[];
  studentId: string;
  studentName: string;
};

interface Props {
  nextDebitDay: string;
}
const ReviewNPayment: React.FC<Props> = ({ nextDebitDay }: Props) => {
  const {
    students,
    canConfirm,
    setCanConfirm,
    setFinalData,
    stopBillingTerms,
    moneyCredit,
    activeOngoingStudents,
    arraySchedulesByWeek
  } = useEnrollmentContext();

  const [showNextBillDay, setShowNextBillDay] = useState<boolean>(false);
  const [studentName, setStudentName] = useState<string>('');
  const [nextBillingCircle, setNextBillingCircle] = useState<
    NextBillingCircle[]
  >([]);
  const [studentBookingDataGroup, setStudentBookingDataGroup] = useState<
    IStudentBookingDataGroup[]
  >([]);
  const [showPaymentSection, setShowPaymentSection] = useState<boolean>(true);

  const __totalValidActiveOngoingStudent = React.useMemo((): number => {
    return activeOngoingStudents.filter(
      (activeOngoingStudent) => activeOngoingStudent?.count > 0
    )?.length;
  }, [activeOngoingStudents]);

  const __showWarningPIF = useMemo(() => {
    return some(studentBookingDataGroup, (studentBookingDataGroup) => {
      return some(
        studentBookingDataGroup?.studentBookingData,
        (item) =>
          item?.bookingData[0]?.paymentOption === PAYMENT_VALUE.UPFRONT &&
          item?.bookingData?.[0]?.enrollmentType === BOOKING_TYPE.ONGOING_CLASS
      );
    });
  }, [studentBookingDataGroup]);

  const getStudentBookingDataGroup = useCallback(
    (studentBookingData: IStudentBookingData[]) => {
      const data = studentBookingData.reduce(
        (result, currentItem: IStudentBookingData) => {
          const { bookingData } = currentItem;
          const newItems: IStudentBookingDataGroup[] = [];
          bookingData.forEach((classItem: IBookingData) => {
            if (classItem.enrollmentType === BOOKING_TYPE.HOLIDAY_PROGRAM) {
              arraySchedulesByWeek.forEach((item) => {
                if (
                  classItem.schedules.find(
                    (schedule) =>
                      schedule.startTime === item.schedules?.[0].startTime
                  )
                ) {
                  newItems.push({
                    studentBookingData: [
                      {
                        ...currentItem,
                        bookingData: [
                          {
                            ...classItem,
                            schedules: item.schedules.map((schedule) => ({
                              ...schedule,
                              checked: true
                            })),
                            price:
                              currentItem.classesData[0].classInfo.price *
                              item.schedules.length,
                            pricePayNow:
                              currentItem.classesData[0].classInfo.price *
                              item.schedules.length
                          }
                        ]
                      }
                    ],
                    studentId: currentItem._id,
                    studentName:
                      currentItem.lastName + ', ' + currentItem.firstName
                  });
                }
              });
            } else {
              classItem.schedules.forEach((schedule) => {
                newItems.push({
                  studentBookingData: [
                    {
                      ...currentItem,
                      bookingData: [{ ...classItem, schedules: [schedule] }]
                    }
                  ],
                  studentId: currentItem._id,
                  studentName:
                    currentItem.lastName + ', ' + currentItem.firstName
                });
              });
            }
          });
          result.push(...newItems);
          return result;
        },
        [] as IStudentBookingDataGroup[]
      );
      const groupData = groupBy(data, 'studentId');
      const result: IStudentBookingDataGroup[] = [];
      Object.keys(groupData).forEach((item: string) => {
        result.push({
          studentBookingData: groupData[item].reduce((result, item) => {
            item.studentBookingData.forEach((i: IStudentBookingData) =>
              result.push(i)
            );
            return result;
          }, [] as IStudentBookingData[]),
          studentId: groupData[item][0].studentId,
          studentName: groupData[item][0].studentName
        });
      });
      return result;
    },
    [arraySchedulesByWeek]
  );

  const studentBookingData = useMemo(() => {
    const data = studentBookingDataGroup.reduce((result, item) => {
      result.push(...item.studentBookingData);
      return result;
    }, [] as IStudentBookingData[]);

    const result = handleGetDataWithDiscount(
      data,
      __totalValidActiveOngoingStudent
    );
    return result;
  }, [studentBookingDataGroup, __totalValidActiveOngoingStudent]);

  const __discountData = useMemo(() => {
    const discounts = studentBookingData.reduce(
      (result: DiscountData[], student) => {
        student.bookingData.forEach((bookingData) => {
          if (bookingData.voucher?.code) {
            const { discountType, discountValue, code } = bookingData.voucher;

            let amount = 0;
            let valueDiscount = '';
            let amountDisplay = '';
            const { pricePayNow = 0, classInfo } = bookingData;
            if (discountType === DISCOUNT_TYPE.PERCENTAGE) {
              amount = roundByTwo(pricePayNow * (discountValue / 100));
              valueDiscount = `${discountValue}% OFF`;
              if (bookingData.paymentOption === PAYMENT_VALUE.UPFRONT) {
                valueDiscount += ` UPFRONT PAYMENT (${code})`;
              } else if (
                bookingData.paymentOption === PAYMENT_VALUE.DIRECT_DEBIT
              ) {
                amount = roundByTwo(
                  classInfo.price * 2 * (discountValue / 100)
                );
                valueDiscount += ` EVERY DD (${code})`;
              }
            } else if (discountType === DISCOUNT_TYPE.AMOUNT) {
              amount = discountValue;
              valueDiscount = `${formatMoneySign(discountValue)} OFF (${code})`;
            } else if (discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
              if (bookingData.paymentOption === PAYMENT_VALUE.DIRECT_DEBIT) {
                amountDisplay = `--`;
              }
              if (
                !!bookingData.voucher.numberOfLesson &&
                bookingData.voucher.duration ===
                  VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS
              ) {
                const discountPrice = roundByTwo(
                  bookingData.voucher.numberOfLesson * classInfo.price
                );
                amount =
                  discountPrice < pricePayNow ? discountPrice : pricePayNow;
              } else if (
                bookingData.voucher.duration === VOUCHER_DURATION.TIME_RANGE
              ) {
                const pricePayNowActual = handleGetPayNow(
                  bookingData,
                  stopBillingTerms,
                  nextDebitDay
                );
                amount = roundByTwo(pricePayNow - pricePayNowActual);
              }
              const { numberOfLesson = 0 } = bookingData.voucher;
              const duration =
                bookingData.voucher.duration === VOUCHER_DURATION.TIME_RANGE
                  ? `${formatDate(
                      bookingData.voucher.startDateFree,
                      'slash'
                    )} - ${
                      !!bookingData.voucher.endDateFree
                        ? formatDate(bookingData.voucher.endDateFree, 'slash')
                        : 'Ongoing'
                    }`
                  : `${numberOfLesson} ${
                      numberOfLesson > 1 ? 'Lessons' : 'Lesson'
                    }`;
              valueDiscount = `Complimentary ${duration}`;
            }

            result.push({
              description: `${valueDiscount} (${getProgramType(
                bookingData.classInfo.type
              )}) (${handleDateTimeRecord(bookingData)}) ${formatData(
                student.lastName
              )}, ${formatData(student.firstName)}`,
              amount: amount,
              amountDisplay
            });
          } else {
            if (bookingData.automaticDiscount) {
              const { value, type } = bookingData.automaticDiscount;
              let amount = 0;
              let valueDiscount = '';
              const { classInfo, pricePayNow = 0 } = bookingData;
              valueDiscount = `${value}% OFF`;
              if (bookingData.paymentOption === PAYMENT_VALUE.UPFRONT) {
                valueDiscount += ` UPFRONT PAYMENT (${getAutomationDiscountText(
                  type
                )})`;
                amount = roundByTwo(pricePayNow * (value / 100));
              } else if (
                bookingData.paymentOption === PAYMENT_VALUE.DIRECT_DEBIT
              ) {
                valueDiscount += ` EVERY DD (${getAutomationDiscountText(
                  type
                )})`;
                amount = roundByTwo(classInfo.price * 2 * (value / 100));
              }
              result.push({
                description: `${valueDiscount} (${getProgramType(
                  bookingData.classInfo.type
                )}) (${handleDateTimeRecord(bookingData)}) ${formatData(
                  student.lastName
                )}, ${formatData(student.firstName)}`,
                amount: amount
              });
            }
          }
        });
        return result;
      },
      []
    );
    return discounts;
  }, [studentBookingData, stopBillingTerms, nextDebitDay]);

  const __nextDirectDebits = React.useMemo(() => {
    const allDirectDebits = studentBookingData.reduce(
      (acc: Array<NextBillingCircle>, cur: IStudentBookingData) => {
        return acc.concat(
          cur.bookingData.reduce(
            (acc2: Array<NextBillingCircle>, cur2: IBookingData) => {
              return acc2.concat(cur2?.nextBillingCircle || []);
            },
            []
          )
        );
      },
      []
    );
    const ascDirectDebits = [...allDirectDebits].sort((a, b) =>
      dayjs(a.date, 'DD-MM-YYYY').diff(dayjs(b.date, 'DD-MM-YYYY'))
    );
    // Create a map to store the grouped items
    const groupedItemsMap: Map<string, number> = new Map();
    // Iterate through the items array
    ascDirectDebits.forEach((item) => {
      const date = dayjs(item.date, 'DD-MM-YYYY').format('YYYY-MM-DD'); // Standardize date format
      if (groupedItemsMap.has(date)) {
        // If the date already exists in groupedItemsMap, add the price to the existing total
        const currentTotal = groupedItemsMap.get(date)!;
        groupedItemsMap.set(date, currentTotal + item.price);
      } else {
        // If the date doesn't exist in groupedItemsMap, set the price as the total
        groupedItemsMap.set(date, item.price);
      }
    });
    // Convert the map to an array of objects
    const groupedItemsArray = Array.from(groupedItemsMap.entries()).map(
      ([date, price]) => ({
        date: date,
        price: price
      })
    );
    return groupedItemsArray;
  }, [studentBookingData]);

  const __totalNextDirectDebit = useMemo(() => {
    return __nextDirectDebits[0]?.price;
  }, [__nextDirectDebits]);

  useEffect(() => {
    canConfirm && setFinalData(studentBookingData);
    let allAssessmentTrial = true;
    forEach(studentBookingData, (student: IStudentBookingData) => {
      forEach(student.bookingData, (item: IBookingData) => {
        if (item.enrollmentType !== 'assessment_trial') {
          allAssessmentTrial = false;
          return;
        }
      });
    });
    // Check if all enrollment type are assessment_trial
    if (allAssessmentTrial) {
      setShowPaymentSection(false);
    } else {
      setShowPaymentSection(true);
    }
  }, [canConfirm, setFinalData, studentBookingData]);

  useEffect(() => {
    setCanConfirm(
      every(studentBookingData, (item) =>
        every(
          item.bookingData,
          (item) => item.paymentOption !== PAYMENT_VALUE.IDLE
        )
      )
    );
  }, [studentBookingData, setCanConfirm]);

  useEffect(() => {
    const studentsBookingData = students.map((item) => {
      return {
        ...item,
        bookingData: item.classesData
          .filter((classItem) => classItem.checked)
          .map((classItem) => {
            const upfrontPayment =
              [
                CLASS_TYPES.ASSESSMENT_TRIAL,
                CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM
              ].includes(classItem.classInfo.type) ||
              BOOKING_TYPE.CASUAL_CLASS === classItem.enrollmentType;
            let price = handleGetPriceDirectDebit(stopBillingTerms, classItem);
            let pricePayNow = upfrontPayment ? price : 0;
            if (BOOKING_TYPE.CASUAL_CLASS === classItem.enrollmentType) {
              pricePayNow = classItem.classInfo.price;
              price = classItem.classInfo.price;
            }
            return {
              ...classItem,
              studentId: item._id,
              schedules: classItem.schedules.filter(
                (schedule) => schedule.checked
              ),
              price: price,
              paymentOption: upfrontPayment
                ? PAYMENT_VALUE.UPFRONT
                : PAYMENT_VALUE.IDLE,
              pricePayNow: pricePayNow,
              nextBillingCircle: []
            };
          })
      };
    });
    const data = studentsBookingData.reduce(
      (result, currentItem: IStudentBookingData) => {
        const { bookingData } = currentItem;
        const newItems: IStudentBookingDataGroup[] = [];

        bookingData.forEach((classItem: IBookingData) => {
          if (classItem.checked) {
            if (classItem.enrollmentType === BOOKING_TYPE.HOLIDAY_PROGRAM) {
              arraySchedulesByWeek.forEach((item) => {
                if (
                  classItem.schedules.find(
                    (schedule) =>
                      schedule.startTime === item.schedules?.[0].startTime
                  )
                ) {
                  newItems.push({
                    studentBookingData: [
                      {
                        ...currentItem,
                        bookingData: [
                          {
                            ...classItem,
                            schedules: item.schedules.map((schedule) => ({
                              ...schedule,
                              checked: true
                            })),
                            price:
                              currentItem.classesData[0].classInfo.price *
                              item.schedules.length,
                            pricePayNow:
                              currentItem.classesData[0].classInfo.price *
                              item.schedules.length
                          }
                        ]
                      }
                    ],
                    studentId: currentItem._id,
                    studentName:
                      currentItem.lastName + ', ' + currentItem.firstName
                  });
                }
              });
            } else {
              classItem.schedules.forEach((schedule) => {
                newItems.push({
                  studentBookingData: [
                    {
                      ...currentItem,
                      bookingData: [{ ...classItem, schedules: [schedule] }]
                    }
                  ],
                  studentId: currentItem._id,
                  studentName:
                    currentItem.lastName + ', ' + currentItem.firstName
                });
              });
            }
          }
        });
        result.push(...newItems);
        return result;
      },
      [] as IStudentBookingDataGroup[]
    );
    const groupData = groupBy(data, 'studentId');
    const result: IStudentBookingDataGroup[] = [];
    Object.keys(groupData).forEach((item: string) => {
      result.push({
        studentBookingData: groupData[item].reduce((result, item) => {
          item.studentBookingData.forEach((i: IStudentBookingData) =>
            result.push(i)
          );
          return result;
        }, [] as IStudentBookingData[]),
        studentId: groupData[item][0].studentId,
        studentName: groupData[item][0].studentName
      });
    });
    setStudentBookingDataGroup(result);
    // setStudentBookingData(studentsBookingData);
  }, [students, stopBillingTerms, arraySchedulesByWeek]);

  const handleChangePaymentOption = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: IBookingData
  ) => {
    const paymentOption = event.target.value as PAYMENT_VALUE;
    const studentBookingNewData = studentBookingData.map(
      (studentBookingItem) => {
        if (studentBookingItem._id === item.studentId) {
          return {
            ...studentBookingItem,
            bookingData: studentBookingItem.bookingData.map((classItem) => {
              if (
                classItem._id === item.classInfo._id &&
                classItem.schedules[0]?._id === item.schedules[0]?._id
              ) {
                const pricePayNow =
                  paymentOption === PAYMENT_VALUE.UPFRONT
                    ? handleGetPriceDirectDebit(stopBillingTerms, item)
                    : handleGetPriceDirectDebit(
                        stopBillingTerms,
                        item,
                        nextDebitDay,
                        undefined,
                        true
                      );

                return {
                  ...item,
                  paymentOption,
                  pricePayNow
                };
              }
              return classItem;
            })
          };
        }
        return studentBookingItem;
      }
    );
    const studentBookingDataNWithDiscount = handleGetDataWithDiscount(
      studentBookingNewData,
      __totalValidActiveOngoingStudent
    ).map((item) => {
      return {
        ...item,
        bookingData: item.bookingData.map((classItem) => {
          return {
            ...classItem,
            nextBillingCircle:
              classItem.paymentOption === PAYMENT_VALUE.DIRECT_DEBIT
                ? handleGetNextBillingCircle(classItem)
                : []
          };
        })
      };
    });
    const studentBookingDataGroup = getStudentBookingDataGroup(
      studentBookingDataNWithDiscount
    );
    setStudentBookingDataGroup(studentBookingDataGroup);
    // setStudentBookingData(studentBookingDataNWithDiscount);
  };

  const handleGetNextBillingCircle = useCallback(
    (item: IBookingData) => {
      if (item.paymentOption !== PAYMENT_VALUE.DIRECT_DEBIT) return [];
      const sortedTerms = sortBy(
        item.classInfo.template.terms,
        'termDetail.startDate'
      );
      const currentTermIndex = sortedTerms.findIndex(
        (term) => term.termId === item.schedules[0].termId
      );
      const currentTerm = sortedTerms.find(
        (term) => term.termId === item.schedules[0].termId
      );
      const endDateOfClass = dayjs(currentTerm?.termDetail?.endDate).format(
        'YYYY-MM-DD 23:59:59'
      );
      let endDate =
        item.classInfo.recurrence.type === RECURRENCE_VALUES.DO_NOT_REPEAT
          ? item.schedules[0].startTime
          : endDateOfClass;
      const nextTerm = sortedTerms[currentTermIndex + 1];
      let discountAmountLeft = 0;
      let discountPercentage = 0;
      const { pricePayNow = 0, automaticDiscount } = item;
      if (item.voucher?.code) {
        const {
          discountType,
          discountValue,
          duration,
          numberOfLesson = 0
        } = item.voucher;
        if (discountType === DISCOUNT_TYPE.AMOUNT) {
          if (pricePayNow > 0) {
            discountAmountLeft =
              roundByTwo(discountValue - pricePayNow) > 0
                ? roundByTwo(discountValue - pricePayNow)
                : 0;
          } else {
            discountAmountLeft = discountValue;
          }
        } else if (discountType === DISCOUNT_TYPE.PERCENTAGE) {
          discountPercentage = discountValue;
        } else if (discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
          if (duration === VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS) {
            const discountPrice = numberOfLesson * item.classInfo.price;
            discountAmountLeft = roundByTwo(discountPrice - pricePayNow);
          } else if (item.voucher.duration === VOUCHER_DURATION.TIME_RANGE) {
            // no end date
            if (!item.voucher.endDateFree) {
              if (!dayjs(item.voucher.startDateFree).isAfter(nextDebitDay)) {
                return [];
              }
            }
          }
        }
      } else if (automaticDiscount) {
        discountPercentage = automaticDiscount.value;
      }

      if (nextTerm) {
        endDate = dayjs(nextTerm.termDetail?.endDate).format('YYYY-MM-DD');
      }

      let debitDayTemp = dayjs(nextDebitDay).add(14, 'days');
      let previousDebitDay = dayjs(nextDebitDay);
      const nextDebitDays: Array<NextBillingCircle> = [];
      while (dayjs(debitDayTemp).isBefore(dayjs(endDate))) {
        const price = handleGetPriceDirectDebit(
          stopBillingTerms,
          item,
          debitDayTemp.format(),
          previousDebitDay.format()
        );
        if (price > 0) {
          if (item.voucher?.discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
            if (
              item.voucher.duration ===
              VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS
            ) {
              if (discountAmountLeft > 0) {
                const result = roundByTwo(price - discountAmountLeft);
                discountAmountLeft = roundByTwo(discountAmountLeft - price);
                if (result > 0) {
                  nextDebitDays.push({
                    date: formatDate(previousDebitDay),
                    price: result
                  });
                }
              } else {
                nextDebitDays.push({
                  date: formatDate(previousDebitDay),
                  price
                });
              }
            } else if (item.voucher.duration === VOUCHER_DURATION.TIME_RANGE) {
              let endDateFree = item.voucher.endDateFree;
              const { startDateFree } = item.voucher;
              if (!endDateFree) {
                endDateFree = debitDayTemp.format(FORMAT_END_OF_DATE);
              }
              if (
                dayjs(startDateFree).isAfter(debitDayTemp) ||
                dayjs(endDateFree).isBefore(previousDebitDay)
              ) {
                nextDebitDays.push({
                  date: formatDate(previousDebitDay),
                  price
                });
              } else {
                const startDate = previousDebitDay.isAfter(startDateFree)
                  ? previousDebitDay.format('YYYY-MM-DD')
                  : dayjs(startDateFree).format('YYYY-MM-DD');
                const endDate = debitDayTemp.isBefore(endDateFree)
                  ? debitDayTemp.format('YYYY-MM-DD')
                  : dayjs(endDateFree).format(FORMAT_END_OF_DATE);

                const numberOfLessonFree = getLessonsDayInRange(
                  item.schedules,
                  startDate,
                  endDate,
                  stopBillingTerms
                );
                const discountPrice =
                  numberOfLessonFree.length * item.classInfo.price;
                if (discountPrice < price) {
                  nextDebitDays.push({
                    date: formatDate(previousDebitDay),
                    price: roundByTwo(price - discountPrice)
                  });
                }
              }
            }
          } else if (discountAmountLeft > 0) {
            const result = roundByTwo(price - discountAmountLeft);
            discountAmountLeft = roundByTwo(discountAmountLeft - price);
            if (result > 0) {
              nextDebitDays.push({
                date: formatDate(previousDebitDay),
                price: result
              });
            }
          } else if (discountPercentage > 0) {
            const result =
              price - roundByTwo(price * (discountPercentage / 100));
            if (result > 0) {
              nextDebitDays.push({
                date: formatDate(previousDebitDay),
                price: result
              });
            }
          } else {
            nextDebitDays.push({
              date: formatDate(previousDebitDay),
              price
            });
          }
        }
        previousDebitDay = dayjs(debitDayTemp);
        debitDayTemp = debitDayTemp.add(14, 'days');
      }

      if (dayjs(endDate).isAfter(dayjs(previousDebitDay))) {
        const price = handleGetPriceDirectDebit(
          stopBillingTerms,
          item,
          debitDayTemp.format(),
          previousDebitDay.format()
        );
        if (price > 0) {
          if (item.voucher?.discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
            if (
              item.voucher.duration ===
              VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS
            ) {
              if (discountAmountLeft > 0) {
                const result = roundByTwo(price - discountAmountLeft);
                discountAmountLeft = roundByTwo(discountAmountLeft - price);
                if (result > 0) {
                  nextDebitDays.push({
                    date: formatDate(previousDebitDay),
                    price: result
                  });
                }
              } else {
                nextDebitDays.push({
                  date: formatDate(previousDebitDay),
                  price
                });
              }
            } else if (item.voucher.duration === VOUCHER_DURATION.TIME_RANGE) {
              let endDateFree = item.voucher.endDateFree;
              const { startDateFree } = item.voucher;
              if (!endDateFree) {
                endDateFree = debitDayTemp.format(FORMAT_END_OF_DATE);
              }
              if (
                dayjs(startDateFree).isAfter(debitDayTemp) ||
                dayjs(endDateFree).isBefore(previousDebitDay)
              ) {
                nextDebitDays.push({
                  date: formatDate(previousDebitDay),
                  price
                });
              } else {
                const startDate = previousDebitDay.isAfter(startDateFree)
                  ? previousDebitDay.format('YYYY-MM-DD')
                  : dayjs(startDateFree).format('YYYY-MM-DD');
                const endDate = debitDayTemp.isBefore(endDateFree)
                  ? debitDayTemp.format('YYYY-MM-DD')
                  : dayjs(endDateFree).format(FORMAT_END_OF_DATE);

                const numberOfLessonFree = getLessonsDayInRange(
                  item.schedules,
                  startDate,
                  endDate,
                  stopBillingTerms
                );
                const discountPrice =
                  numberOfLessonFree.length * item.classInfo.price;
                if (discountPrice < price) {
                  nextDebitDays.push({
                    date: formatDate(previousDebitDay),
                    price: roundByTwo(price - discountPrice)
                  });
                }
              }
            }
          } else if (discountAmountLeft > 0) {
            const result = roundByTwo(price - discountAmountLeft);
            discountAmountLeft = roundByTwo(discountAmountLeft - price);
            if (result > 0) {
              nextDebitDays.push({
                date: formatDate(previousDebitDay),
                price: result
              });
            }
          } else if (discountPercentage > 0) {
            const result =
              price - roundByTwo(price * (discountPercentage / 100));
            if (result > 0) {
              nextDebitDays.push({
                date: formatDate(previousDebitDay),
                price: result
              });
            }
          } else {
            nextDebitDays.push({
              date: formatDate(previousDebitDay),
              price
            });
          }
        }
      }

      return nextDebitDays;
    },
    [nextDebitDay, stopBillingTerms]
  );

  const handleShowNextBillDay = useCallback(
    (nextBillingCircle: NextBillingCircle[], studentId: string) => {
      setShowNextBillDay(!showNextBillDay);
      setNextBillingCircle(nextBillingCircle);
      const student = students.find((item) => item._id === studentId);
      setStudentName(`${student?.lastName}, ${student?.firstName}`);
    },
    [showNextBillDay, students, setStudentName]
  );

  const handleCloseBillingCircle = useCallback(() => {
    setShowNextBillDay(false);
  }, [setShowNextBillDay]);

  const getSubTotalPayNow = useCallback(() => {
    return studentBookingData
      .map((item) => {
        return item.bookingData
          .map((classItem) => {
            return handleGetPayNow(classItem, stopBillingTerms, nextDebitDay);
          })
          .reduce((a: number, b: number) => {
            return a + b;
          }, 0);
      })
      .reduce((a: number, b: number) => {
        return a + b;
      }, 0);
  }, [studentBookingData, stopBillingTerms, nextDebitDay]);

  const __moneyCredit = useMemo((): number => {
    const subTotalNow = getSubTotalPayNow();
    if (subTotalNow === 0) return 0;
    if (subTotalNow > moneyCredit) {
      return moneyCredit;
    } else {
      return subTotalNow;
    }
  }, [getSubTotalPayNow, moneyCredit]);

  const getTotalPayNow = useCallback(() => {
    return (
      studentBookingData
        .map((item) => {
          return item.bookingData
            .map((classItem) => {
              return handleGetPayNow(classItem, stopBillingTerms, nextDebitDay);
            })
            .reduce((a: number, b: number) => {
              return a + b;
            }, 0);
        })
        .reduce((a: number, b: number) => {
          return a + b;
        }, 0) - __moneyCredit
    );
  }, [studentBookingData, __moneyCredit, stopBillingTerms, nextDebitDay]);

  const handleApplyPromoCode = useCallback(
    (
      voucher: IVoucher | undefined,
      studentId: string,
      classId: string,
      scheduleId: string
    ) => {
      setStudentBookingDataGroup((prev) => {
        return prev.map((item) => {
          if (item.studentId === studentId) {
            const studentBookingData = item.studentBookingData.map(
              (element) => {
                if (
                  element.bookingData[0]._id === classId &&
                  element.bookingData[0].schedules[0]._id === scheduleId
                ) {
                  const bookingData = { ...element.bookingData[0], voucher };
                  return {
                    ...element,
                    bookingData: [
                      {
                        ...element.bookingData[0],
                        voucher,
                        nextBillingCircle:
                          element.bookingData[0].paymentOption ===
                          PAYMENT_VALUE.DIRECT_DEBIT
                            ? handleGetNextBillingCircle(bookingData)
                            : []
                      }
                    ]
                  };
                }
                return element;
              }
            );
            return { ...item, studentBookingData };
          }
          return item;
        });
      });
    },
    [handleGetNextBillingCircle]
  );

  return (
    <div className="layoutContainer classes-main">
      <AppModal open={showNextBillDay} onClose={handleCloseBillingCircle}>
        <div className="next-bill-title">
          {studentName}
          <HiOutlineX
            onClick={handleCloseBillingCircle}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="next-bill-content">
          <div className="title">Next billing circle</div>
          <div className="next-bill-content-table">
            <div className="table-header">
              <div className="table-header-th">Payment date</div>
              <div className="table-header-th">Price</div>
            </div>
            {nextBillingCircle.map((item: NextBillingCircle, index: number) => (
              <div className="table-row" key={index}>
                <div className="table-row-th">{item.date}</div>
                <div className="table-row-th">
                  {formatData(`$${item.price}`)}
                </div>
              </div>
            ))}
            <div className="total-price">
              Total
              {formatData(
                ` $${round(
                  nextBillingCircle
                    .map((billing) => billing?.price)
                    .reduce(
                      (accumulator, currentValue) => accumulator + currentValue,
                      0
                    ),
                  2
                )}`
              )}
            </div>
          </div>
        </div>
      </AppModal>

      <div className="review-n-payment-wrapper">
        {/* start review section */}
        <div className="section review-section">
          <div className="title">Review Summary</div>
          {/* start student info */}
          {studentBookingDataGroup.map((studentBookingDataGroupItem) => {
            return (
              <div
                key={studentBookingDataGroupItem.studentId}
                className="student-info-wrapper"
              >
                <div className="student-info-name">
                  {studentBookingDataGroupItem.studentName}
                </div>
                <div className="student-info">
                  <div className="review_body_body-row">
                    <div className="span3 header">PROGRAM TYPE</div>
                    <div className="span3 header">ENROLMENT TYPE</div>
                    <div className="span2 header">Day, Time</div>
                    <div className="span2 header">FIRST, LAST LESSON</div>
                    <div className="span3 header">Payment Option</div>
                    <div
                      className="span2 header"
                      style={{ textAlign: 'right' }}
                    >
                      Price
                    </div>
                    <div
                      className="span2 header"
                      style={{ textAlign: 'right' }}
                    >
                      Discounted Price
                    </div>
                    <div
                      className="span2 header"
                      style={{ textAlign: 'right' }}
                    >
                      Pay now
                    </div>
                  </div>
                </div>
                {studentBookingDataGroupItem.studentBookingData.map(
                  (item, index) => {
                    return (
                      <div className="student-info" key={index}>
                        {item.bookingData.map((classItem, index) => {
                          return (
                            <ReviewBookingDataRow
                              key={index}
                              bookingData={classItem}
                              onChangePaymentOption={handleChangePaymentOption}
                              onShowNextBillDay={handleShowNextBillDay}
                              onApplyPromoCode={(code: IVoucher | undefined) =>
                                handleApplyPromoCode(
                                  code,
                                  item._id,
                                  classItem._id,
                                  classItem.schedules[0]?._id
                                )
                              }
                            />
                          );
                        })}
                      </div>
                    );
                  }
                )}
              </div>
            );
          })}
          {__showWarningPIF && (
            <div className="warning-pif-section">
              <HiOutlineExclamationCircle />
              <div className="text">
                Enrolments made with upfront payment will automatically end at
                the end of the session, causing the student to lose their spot
                for that particular lesson day and time. To maintain a
                consistent lesson day and time from week to week and session to
                session, <strong>it's best to choose direct debit.</strong>
              </div>
            </div>
          )}
          {/* end student info */}
          <div className="total-section">
            {__nextDirectDebits.length > 0 && (
              <React.Fragment>
                <div className="item">
                  <p className="title">Direct debit details</p>
                  <p></p>
                </div>
                {__nextDirectDebits.map((nextDirectDebit, index: number) => {
                  if (index === 0) {
                    return (
                      <div key={nextDirectDebit.date} className="item discount">
                        <div className="title">first Direct debit date:</div>
                        <div className="value">
                          {dayjs(nextDirectDebit.date).format('DD-MM-YYYY')}
                        </div>
                      </div>
                    );
                  }
                  if (index === 1) {
                    return (
                      <div key={nextDirectDebit.date} className="item discount">
                        <div className="title">Next Direct debit date:</div>
                        <div className="value">
                          {dayjs(nextDirectDebit.date).format('DD-MM-YYYY')}
                        </div>
                      </div>
                    );
                  }
                  return null;
                })}
              </React.Fragment>
            )}
            <div className="item">
              <div className="title">SUBTOTAL</div>
              <div>{formatMoneySign(getSubTotalPayNow())}</div>
            </div>
            {__discountData.length > 0 && (
              <div className="item">
                <div className="title">Discount</div>
                <div></div>
              </div>
            )}
            {__discountData?.map((item: DiscountData, index: number) => (
              <div className="item discount" key={index}>
                <div>{item.description}</div>
                <div className="value">
                  {!!item.amountDisplay
                    ? item.amountDisplay
                    : `-${formatMoneySign(item.amount)}`}
                </div>
              </div>
            ))}
            {__moneyCredit > 0 && (
              <div className="item">
                <div className="title">Money credit</div>
                <div>-{formatMoneySign(__moneyCredit)}</div>
              </div>
            )}

            <div className="item">
              <div className="title">TOTAL FIRST DEBIT</div>
              <div>{formatMoneySign(__totalNextDirectDebit)}</div>
            </div>
            <div className="item">
              <div className="title">TOTAL PAYING TODAY</div>
              <div className="total-price">
                {formatMoneySign(getTotalPayNow())}
              </div>
            </div>
          </div>
        </div>
        {/* end review section */}
        {/* start payment section */}
        {showPaymentSection && (
          <div className="section payment-section">
            <div className="title">Payment method</div>
            <div className="payment-method-list">
              {PAYMENT_CARDS.map((item, index) => (
                <Fragment key={index}>
                  {
                    <button
                      type="button"
                      className="item card"
                      // className={`item card ${
                      //   item.name === paymentMethod ? 'active' : ''
                      // }`}
                      // onClick={() => setPaymentMethod(item.name)}
                    >
                      <img
                        className={`logo ${
                          ![1, 8].includes(index) ? 'bg-white square' : 'round'
                        }`}
                        src={item.image}
                        alt="logo"
                      />
                      <div className="name">
                        {formatData(item.name).replaceAll('_', ' ')}
                      </div>
                    </button>
                  }
                </Fragment>
              ))}
            </div>
          </div>
        )}
        {/* end payment section */}
      </div>
    </div>
  );
};

export default ReviewNPayment;
