import { Dispatch, SetStateAction, memo, useState } from 'react';
import SearchCalendar from './SearchCalendar';
import * as colors from '../../constants/colors';
import { HiOutlineSearch } from 'react-icons/hi';
import { ISearchCalendar } from '../../types/calendar.type';

import './desktop.scss';
interface Props {
  handleSearch: (value: any, advance?: boolean) => void;
  searchValue: string;
  searchAdvanceValue?: ISearchCalendar;
  setShowSearch?: Dispatch<SetStateAction<boolean>>;
}
const AppSearchCalendarComponent = ({
  handleSearch,
  setShowSearch: changeShowSearch,
  searchAdvanceValue,
  ...props
}: Props) => {
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>(props.searchValue);

  const onSearch = (value: string) => {
    setShowSearch(false);
    changeShowSearch && changeShowSearch(false);
    setSearchValue('');
    handleSearch(value, true);
  };

  // const handleShowSearchAdvance = () => {
  // changeShowSearch && changeShowSearch(!showSearch);
  // setShowSearch(!showSearch);
  // };

  return (
    <div style={{ position: 'relative' }}>
      <div className="search-container">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            background: 'none',
            width: '98%'
          }}
        >
          {!showSearch && (
            <>
              <HiOutlineSearch fontSize={20} />
            </>
          )}
          <div style={styles.searchText}>
            <input
              placeholder="Search"
              style={{
                width: '100%',
                outline: 0,
                background: 'transparent',
                fontSize: '16px',
                opacity: showSearch ? 0 : 1
              }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleSearch(e.target.value);
              }}
            />
          </div>
        </div>
        {/* <HiChevronDown
          onClick={handleShowSearchAdvance}
          style={{ cursor: 'pointer' }}
          fontSize={20}
        />*/}
      </div>
      <SearchCalendar
        handleSearch={onSearch}
        showSearch={showSearch}
        searchValue={searchValue}
        searchAdvanceValue={searchAdvanceValue}
      />
    </div>
  );
};
const AppSearchCalendar = memo(AppSearchCalendarComponent);
export default AppSearchCalendar;

const styles = {
  dashboardHeader: {
    display: 'flex',
    padding: '20px 30px 20px 0',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.bgPrimary
  },
  dashboardHeaderCollapsed: {
    display: 'flex',
    padding: '20px 30px 20px 30px',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.bgPrimary
  },
  dashboardHeaderText: {
    color: colors.titleColor,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '140%',
    letterSpacing: '0.5px',
    background: 'none'
  },
  searchText: {
    color: colors.placeholderColor,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    background: 'none',
    marginLeft: '10px',
    width: '90%'
  }
};
