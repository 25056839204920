export enum PERMISSION {
  //student permission
  LIST_STUDENT = 'list-student',
  LIST_STUDENT_BOOKED_CLASS = 'list-student-booked-class',
  CREATE_STUDENT = 'create-student',
  VIEW_DETAIL_STUDENT = 'view-detail-student',
  UPDATE_STUDENT_EMERGENCY_CONTACT = 'update-student-emergency-contact',
  CREATE_STUDENT_EMERGENCY_CONTACT = 'create-student-emergency-contact',
  DELETE_STUDENT_EMERGENCY_CONTACT = 'delete-student-emergency-contact',
  UPDATE_STUDENT_PERSONAL_INFO = 'update-student-personal-info',
  DELETE_STUDENT = 'delete-student',
  BOOKING_CLASS = 'booking-class',
  UPDATE_LEVEL_STUDENT = 'update-level-student',

  // assessment
  LIST_STUDENT_BY_CLASS = 'list-student-by-class',
  LIST_ASSESSMENT_RESULT = 'list-assessment-result',
  LIST_ASSESSMENT_HISTORY = 'list-assessment-history',
  LIST_ASSESSMENT_BY_STUDENT = 'list-assessment-by-student',

  VIEW_ASSESSMENT_HISTORY = 'view-assessment-history',
  GET_CURRENT_CLASS = 'get-current-class',

  CREATE_ASSESSMENT_RESULT = 'create-assessment-result',
  CREATE_BATCH_ASSESSMENT_RESULT = 'create-batch-assessment-result',

  UPDATE_ASSESSMENT_SCORE = 'update-assessment-score',
  UPDATE_ASSESSMENT_RESULT = 'update-assessment-result',
  APPROVE_ASSESSMENT = 'approve-assessment',
  REASSESS_ASSESSMENT = 'reassess-assessment',

  // absence
  LIST_ABSENCE = 'list-absence',
  CREATE_ABSENCE = 'create-absence',
  UPDATE_ABSENCE = 'update-absence',

  //staff permission
  LIST_STAFF = 'list-staff',
  CREATE_STAFF = 'create-staff',
  VIEW_DETAIL_STAFF = 'view-detail-staff',
  DELETE_STAFF = 'delete-staff',
  UPDATE_STAFF = 'update-staff',
  UPDATE_STAFF_CONTACT_DETAIL = 'update-staff-contact-detail',
  UPDATE_STAFF_PERSONAL_INFO = 'update-staff-personal-info',
  UPDATE_STAFF_ACCESS = 'update-staff-access',

  // class permission
  LIST_CLASS = 'list-class',
  LIST_UPCOMING_CLASS = 'list-upcoming-class',
  LIST_CLASS_OF_GROUP_DASHBOARD = 'list-class-of-group-dashboard',
  CREATE_CLASS = 'create-class',
  VIEW_DETAIL_CLASS = 'view-detail-class',
  UPDATE_CLASS = 'update-class',
  DELETE_CLASS = 'delete-class',

  // class template permission
  LIST_CLASS_TEMPLATE = 'list-class-template',
  CREATE_CLASS_TEMPLATE = 'create-class-template',
  VIEW_DETAIL_CLASS_TEMPLATE = 'view-detail-class-template',
  UPDATE_CLASS_TEMPLATE = 'update-class-template',
  DELETE_CLASS_TEMPLATE = 'delete-class-template',

  //instructor permission
  LIST_INSTRUCTOR = 'list-instructor',
  CREATE_INSTRUCTOR = 'create-instructor',
  VIEW_DETAIL_INSTRUCTOR = 'view-detail-instructor',
  UPDATE_INSTRUCTOR = 'update-instructor',
  DELETE_INSTRUCTOR = 'delete-instructor',

  //credential permission
  LIST_CREDENTIAL = 'list-credential',
  CREATE_CREDENTIAL = 'create-credential',
  VIEW_DETAIL_CREDENTIAL = 'view-detail-credential',
  UPDATE_CREDENTIAL = 'update-credential',
  DELETE_CREDENTIAL = 'delete-credential',

  //filter dashboard user setting permission
  LIST_FILTER = 'list-filter',
  VIEW_DETAIL_FILTER = 'view-detail-filter',
  CREATE_FILTER = 'create-filter',
  UPDATE_FILTER = 'update-filter',
  DELETE_FILTER = 'delete-filter',

  //location
  VIEW_DETAIL_LOCATION = 'view-detail-location',
  CREATE_LOCATION = 'create-location',
  UPDATE_LOCATION = 'update-location',
  DELETE_LOCATION = 'delete-location',
  LIST_LOCATION = 'list-location',
  UPDATE_LOCATION_MANAGEMENT_STAFF = 'update-location-management-staff',
  UPDATE_LOCATION_BANK_DETAIL = 'update-location-bank-detail',
  UPDATE_LOCATION_SALE_INFO = 'update-location-sale-info',
  UPDATE_LOCATION_LEAD_INFO = 'update-location-lead-info',
  UPDATE_LOCATION_CLUB_SETTING = 'update-location-club-setting',

  //location area
  CREATE_LOCATION_AREA = 'create-location-area',
  UPDATE_LOCATION_AREA = 'update-location-area',
  DELETE_LOCATION_AREA = 'delete-location-area',
  LIST_LOCATION_AREA = 'list-location-area',
  VIEW_DETAIL_LOCATION_AREA = 'view-detail-location-area',

  //location pin
  CREATE_LOCATION_PIN = 'create-location-pin',
  UPDATE_LOCATION_PIN = 'update-location-pin',
  DELETE_LOCATION_PIN = 'delete-location-pin',
  LIST_LOCATION_PIN = 'list-location-pin',
  VIEW_DETAIL_LOCATION_PIN = 'view-detail-location-pin',

  //location door
  CREATE_LOCATION_DOOR = 'create-location-door',
  UPDATE_LOCATION_DOOR = 'update-location-door',
  DELETE_LOCATION_DOOR = 'delete-location-door',
  LIST_LOCATION_DOOR = 'list-location-door',
  VIEW_DETAIL_LOCATION_DOOR = 'view-detail-location-door',
  UPDATE_LOCATION_DOOR_ACTIVATION_DAYS = 'update-location-door-activation-days',

  //location hour
  GET_LOCATION_HOUR = 'get-location-hour',
  UPDATE_LOCATION_HOUR = 'update-location-hour',

  //location website
  GET_LOCATION_WEBSITE = 'get-location-website',
  UPDATE_LOCATION_WEBSITE_FEATURES = 'update-location-website-features',
  UPDATE_LOCATION_WEBSITE_SUMMARY = 'update-location-website-summary',
  UPDATE_LOCATION_WEBSITE_IMAGE = 'update-location-website-image',

  //location sitemap
  UPDATE_LOCATION_SITEMAP_INFO = 'update-location-sitemap-info',

  //health questionnaire permission
  LIST_HEALTH_QUESTIONNAIRE = 'list-health-questionnaire',
  CREATE_HEALTH_QUESTIONNAIRE = 'create-health-questionnaire',
  VIEW_DETAIL_HEALTH_QUESTIONNAIRE = 'view-detail-health-questionnaire',
  UPDATE_HEALTH_QUESTIONNAIRE = 'update-health-questionnaire',
  DELETE_HEALTH_QUESTIONNAIRE = 'delete-health-questionnaire',

  //color setting dashboard permission
  LIST_COLOR_SETTING = 'list-color-setting',
  VIEW_DETAIL_COLOR_SETTING = 'view-detail-color-setting',
  CREATE_COLOR_SETTING = 'create-color-setting',
  UPDATE_COLOR_SETTING = 'update-color-setting',
  DELETE_COLOR_SETTING = 'delete-color-setting',
  RESET_DEFAULT_SETTING = 'reset-default-setting',

  //staff permission
  LIST_RESPONSIBLE_PERSON = 'list-responsible-person',
  CREATE_RESPONSIBLE_PERSON = 'create-responsible-person',
  VIEW_DETAIL_RESPONSIBLE_PERSON = 'view-detail-responsible-person',
  DELETE_RESPONSIBLE_PERSON = 'delete-responsible-person',
  UPDATE_RESPONSIBLE_PERSON_CONTACT_DETAIL = 'update-responsible-person-contact-detail',
  UPDATE_RESPONSIBLE_PERSON_PERSONAL_INFO = 'update-responsible-person-personal-info',
  ADD_STUDENT_TO_RESPONSIBLE_PERSON = 'add-student-to-responsible-person',
  REMOVE_STUDENT_OUT_RESPONSIBLE_PERSON = 'remove-student-out-responsible-person',

  //schedule permission
  VIEW_DETAIL_SCHEDULE = 'view-detail-schedule',
  LIST_DUPLICATED_SCHEDULE = 'list-duplicated-schedule',

  //dashboard permission
  FILTER_GROUP_CLASS = 'filter-group-class',
  SEARCH_CLASS = 'search-class',
  LIST_STUDENT_CHECKIN = 'list-student-checkin',

  //role
  VIEW_DETAIL_ROLE = 'view-detail-role',
  CREATE_ROLE = 'create-role',
  UPDATE_ROLE = 'update-role',
  DELETE_ROLE = 'delete-role',
  LIST_ROLE = 'list-role',

  //permission
  LIST_PERMISSION = 'list-permission',

  //level-breakdown
  VIEW_DETAIL_LEVEL_BREAKDOWN = 'view-detail-level-breakdown',
  CREATE_LEVEL_BREAKDOWN = 'create-level-breakdown',
  UPDATE_LEVEL_BREAKDOWN = 'update-level-breakdown',
  DELETE_LEVEL_BREAKDOWN = 'delete-level-breakdown',
  LIST_LEVEL_BREAKDOWN = 'list-level-breakdown',

  //milestone
  UPDATE_MILESTONE = 'update-milestone',
  DELETE_MILESTONE = 'delete-milestone',

  //skill
  UPDATE_SKILL = 'update-skill',
  DELETE_SKILL = 'delete-skill',
  CREATE_SKILL = 'create-skill',

  //report
  LIST_REPORT = 'list-report',
  STUDENT_BOOKING_SUMMARY = 'student-booking-summary',
  TIME_TABLE_BY_INSTRUCTOR = 'time-table-by-instructor',
  STUDENT_OUTSTANDING_REPORT = 'student-outstanding-report',
  PERIOD_SUMMARY_REPORT = 'period-summary-report',
  CREDIT_ADJUSTMENT_REPORT = 'credit-adjustment-report',
  MEMBER_REPORT = 'member-report',
  FAMILY_REPORT = 'family-report',
  NON_ATTENDED_REPORT = 'non-attendance-report',
  GRADUATION_REPORT = 'graduation-report',
  ASSESSMENT_OUTCOME_REPORT = 'assessment-outcome-report',
  DATE_OF_LAST_ASSESSMENT_REPORT = 'date-of-last-assessment-report',
  DISCOUNT_ENROLLMENT_REPORT = 'discount-enrollment-report',
  ABANDONED_CART_REPORT = 'abandoned-cart-report',
  GST_REPORT='gst-report',

  //trial
  LIST_TRIAL = 'list-trial',

  //logs permission
  LIST_AUDIT_LOG = 'list-audit-log',

  //activity permission
  LIST_ACTIVITY = 'list-activity',

  // roll call permission
  LIST_GROUP_ROLL_CALL = 'list-group-roll-call',
  DETAIL_GROUP_ROLL_CALL = 'detail-group-roll-call',
  LIST_STUDENT_ROLL_CALL = 'list-student-roll-call',
  CHECK_IN = 'check-in',
  CREATE_EMERGENCY_ROLL_CALL = 'create-emergency-roll-call',

  // term
  CREATE_TERM = 'create-term',
  LIST_TERM = 'list-term',
  DETAIL_TERM = 'detail-term',
  UPDATE_TERM = 'update-term',
  DELETE_TERM = 'delete-term',

  // cancellation
  CREATE_CANCELLATION = 'create-cancellation',
  UPDATE_CANCELLATION = 'update-cancellation',
  DELETE_CANCELLATION = 'delete-cancellation',

  // notifications
  LIST_NOTIFICATION = 'list-notification',
  VIEW_NOTIFICATION = 'view-notification',
  CREATE_NOTIFICATION = 'create-notification',
  UPDATE_NOTIFICATION = 'update-notification',

  //notification-template
  LIST_NOTIFICATION_TEMPLATE = 'list-notification-template',
  VIEW_NOTIFICATION_TEMPLATE = 'view-notification-template',
  CREATE_NOTIFICATION_TEMPLATE = 'create-notification-template',
  UPDATE_NOTIFICATION_TEMPLATE = 'update-notification-template',
  DELETE_NOTIFICATION_TEMPLATE = 'delete-notification-template',

  // billing
  GET_PAYMENT_HISTORY = 'get-payment-history',
  GET_FUTURE_PAYMENT = 'get-future-payment',
  GET_STUDENT_ENROLLMENT = 'get-student-enrollment',
  MARK_AS_PAID = 'mark-as-paid',

  // firebase notification settings
  LIST_SETTING_USER_ROLE_NOTIFICATION = 'list-setting-user-role-notification',
  UPDATE_SETTING_USER_ROLE_NOTIFICATION = 'update-setting-user-role-notification',

  // statistic
  GET_CLASS_STATISTIC = 'get-class-statistic',
  GET_STUDENT_STATISTIC = 'get-statistic-student',

  // auto discount
  COUNT_STUDENT_ACTIVE_ONGOING = 'count-student-active-ongoing',

  // bulk management
  LIST_BULK_CREDIT_MANAGEMENT = 'list-bulk-credit-management',
  VIEW_DETAIL_BULK_CREDIT_MANAGEMENT = 'view-detail-credit-bulk-management',
  LIST_STUDENT_BULK_CREDIT_MANAGEMENT = 'list-student-credit-bulk-management',

  // credit histories
  LIST_MAKE_UP_CREDIT_HISTORIES = 'list-makeup-credit-histories',
  LIST_MONEY_CREDIT_HISTORIES = 'list-money-credit-histories',

  // vouchers
  LIST_VOUCHER = 'list-voucher',
  VIEW_DETAIL_VOUCHER = 'view-detail-voucher',
  CREATE_VOUCHER = 'create-voucher',
  UPDATE_VOUCHER = 'update-voucher',
  DELETE_VOUCHER = 'delete-voucher',
  VALIDATE_VOUCHER = 'validate-voucher',
  SEND_VOUCHER = 'send-voucher',
  APPLY_VOUCHER = 'apply-voucher',
  REMOVE_VOUCHER = 'remove-voucher',
  BULK_APPLY_VOUCHER = 'bulk-apply-voucher',

  // Special enrolment
  BOOKING_SPECIAL_CLASS = 'booking-special-class',
  REQUEST_SPECIAL_ENROLLMENT = 'request-special-enrollment'
}
