import {
  CreateAssessmentScoreDTO,
  CreateLevelDTOPayload,
  CreateMilestoneDTO,
  CreateSkillForLevelDTO,
  UpdateLevelDTO,
  UpdateSkillDTO
} from 'DTOs/levelBreakdown.dto';
import API from './API';
import { LevelSetting } from 'common/interfaces/levelBreakdown.interface';

const LEVEL_BREAKDOWN_ENDPOINT = `/level-breakdowns`;
const SKILLS_ENDPOINT = `/skills`;
const MILESTONES_ENDPOINT = `/milestones`;
const ASSESSMENT_SCORES_ENDPOINT = `/assessment-scores`;
const LEVEL_SETTING_ENDPOINT = `/level-setting`;

// LEVELS SECTION
export const getLevelBreakdowns = (
  page: number,
  limit: number,
  locationId?: string,
  keyword?: string,
  locationIds?: string[]
) => {
  return API.get(`${LEVEL_BREAKDOWN_ENDPOINT}`, {
    page,
    limit,
    locationId,
    keyword,
    locationIds
  });
};

export const getLevelBreakdownById = (id: string) => {
  return API.get(`${LEVEL_BREAKDOWN_ENDPOINT}/${id}`);
};

export const createLevelBreakdown = (payload: CreateLevelDTOPayload) => {
  return API.post(`${LEVEL_BREAKDOWN_ENDPOINT}`, {
    body: payload
  });
};

export const updateLevelBreakdown = (id: string, payload: UpdateLevelDTO) => {
  return API.patch(`${LEVEL_BREAKDOWN_ENDPOINT}/${id}`, {
    body: payload
  });
};

export const deleteLevelBreakdown = (id: string) => {
  return API.delete(`${LEVEL_BREAKDOWN_ENDPOINT}/${id}`);
};

// SKILLS SECTION
export const createSkill = (payload: CreateSkillForLevelDTO) => {
  return API.post(`${SKILLS_ENDPOINT}`, {
    body: payload
  });
};

export const updateSkill = (id: string, payload: UpdateSkillDTO) => {
  return API.patch(`${SKILLS_ENDPOINT}/${id}`, {
    body: payload
  });
};

export const deleteSkill = (id: string) => {
  return API.delete(`${SKILLS_ENDPOINT}/${id}`);
};

// MILESTONES SECTION
export const createMilestones = (
  skillId: string,
  payload: Array<CreateMilestoneDTO>
) => {
  return API.post(`${MILESTONES_ENDPOINT}`, {
    body: {
      milestones: payload,
      skillId: skillId
    }
  });
};

export const updateBatchMilestone = (payload: {
  skillId: string;
  milestones: Array<CreateMilestoneDTO>;
}) => {
  return API.patch(`${MILESTONES_ENDPOINT}/update-batch`, {
    body: payload
  });
};

export const deleteMilestone = (id: string) => {
  return API.delete(`${MILESTONES_ENDPOINT}/${id}`);
};

// ASSESSMENT SCORE SECTION
export const updateAssessmentScore = (
  id: string,
  payload: CreateAssessmentScoreDTO
) => {
  return API.patch(`${ASSESSMENT_SCORES_ENDPOINT}/${id}`, {
    body: payload
  });
};

export const updateLevelSetting = (userId: string, payload: LevelSetting) => {
  if (payload.levelOrders.length === 0) return;
  return API.put(`${LEVEL_SETTING_ENDPOINT}/${userId}`, {
    body: payload
  });
};
