import AppSelect from 'common/components/AppSelect';
import { CLASS_BOOKING_PAYMENT_OPTIONS } from 'common/constants/classBooking.constant';
import { DISCOUNT_TYPE } from 'common/enums';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { BOOKING_TYPE, PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import {
  getFirstLastLessonBookingItem,
  handleDateTimeRecord
} from 'common/helpers/classBooking.helper';
import {
  formatData,
  formatMoneySign,
  roundByTwo
} from 'common/helpers/dataFormat.helper';
import { getEnrolmentType, getProgramType } from 'common/helpers/index.helper';
import {
  IBookingData,
  NextBillingCircle
} from 'common/interfaces/schedules.interface';
import {
  IValidateVoucherData,
  IVoucher
} from 'common/interfaces/voucher.interface';
import PromoCodeInput from 'components/PromoCodeInput';
import { useMoveBooking } from 'context/MoveBookingContext';
import { handleGetPayNow } from 'helpers/enrolment.helper';
import { round } from 'lodash';
import { useEnrollmentContext } from 'pages/students/Enrollment/EnrollmentContext';
import React, { useMemo } from 'react';
// import { HiOutlineExclamationCircle } from 'react-icons/hi';

interface Props {
  bookingData: IBookingData;
  onChangePaymentOption: (
    event: React.ChangeEvent<HTMLInputElement>,
    bookingData: IBookingData
  ) => void;
  onShowNextBillDay: (
    nextBillingCircle: NextBillingCircle[],
    studentId: string
  ) => void;
  onApplyPromoCode: (code: IVoucher | undefined) => void;
}
const ReviewBookingDataRow: React.FC<Props> = ({
  bookingData,
  onChangePaymentOption,
  onShowNextBillDay,
  onApplyPromoCode
}: Props) => {
  const { classInfo, activeTermSchedules, automaticDiscount } = bookingData;
  const { stopBillingTerms } = useEnrollmentContext();
  const { nextDebitDay } = useMoveBooking();

  const __bookingData = useMemo((): IValidateVoucherData => {
    return {
      studentId: bookingData._id,
      classType: bookingData.classInfo?.type,
      bookingType: bookingData.enrollmentType,
      locationId: bookingData.classInfo.locationId
    };
  }, [bookingData]);

  const getDisplayPriceBookingItem = (bookingItem: IBookingData): string => {
    let price: string = '';
    if (bookingItem.enrollmentType === BOOKING_TYPE.MAKE_UP) {
      price = `${bookingItem.schedules.length} Makeup (s)`;
      return price;
    }
    if (bookingItem.classInfo.type === CLASS_TYPES.ASSESSMENT_TRIAL) {
      price = formatMoneySign(bookingItem.price || 0);
    }
    if (bookingItem.classInfo.type === CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM) {
      price = `${formatMoneySign(bookingItem?.pricePayNow || 0)} for ${round(
        (bookingItem.pricePayNow || 0) / bookingItem.classInfo.price
      )} lessons`;
    } else {
      if (bookingItem.enrollmentType === BOOKING_TYPE.ONGOING_CLASS) {
        if (bookingItem.paymentOption === PAYMENT_VALUE.DIRECT_DEBIT) {
          price = `${formatMoneySign(
            bookingItem.classInfo.price * bookingItem.schedules.length * 2
          )} per fortnight`;
        } else if (bookingItem.paymentOption === PAYMENT_VALUE.UPFRONT) {
          price = `${formatMoneySign(
            bookingItem?.pricePayNow || 0
          )} for ${round(
            (bookingItem.pricePayNow || 0) / bookingItem.classInfo.price
          )} lessons`;
        } else {
          price = formatMoneySign(0);
        }
      } else {
        price = `${formatMoneySign(bookingItem?.price || 0)}`;
      }
    }
    return price;
  };

  const handleGetDiscountedPrice = (bookingItem: IBookingData) => {
    const {
      pricePayNow = 0,
      paymentOption,
      voucher,
      automaticDiscount,
      classInfo
    } = bookingItem;
    if (paymentOption === PAYMENT_VALUE.IDLE) return '--';
    if (paymentOption === PAYMENT_VALUE.UPFRONT) {
      if (voucher?.code) {
        if (voucher?.discountType === DISCOUNT_TYPE.AMOUNT) {
          const discountedPrice = roundByTwo(
            pricePayNow - voucher?.discountValue
          );
          return `${formatMoneySign(
            discountedPrice > 0 ? discountedPrice : 0
          )} for ${round(
            (pricePayNow || 0) / bookingItem.classInfo.price
          )} lessons`;
        }
        if (voucher?.discountType === DISCOUNT_TYPE.PERCENTAGE) {
          const discountedAmount = roundByTwo(
            pricePayNow * (voucher?.discountValue / 100)
          );
          const discountedPrice = roundByTwo(pricePayNow - discountedAmount);
          return `${formatMoneySign(discountedPrice)} for ${round(
            (pricePayNow || 0) / bookingItem.classInfo.price
          )} lessons`;
        }
      }
      if (automaticDiscount) {
        const discountedAmount = roundByTwo(
          pricePayNow * (automaticDiscount?.value / 100)
        );
        const discountedPrice = roundByTwo(pricePayNow - discountedAmount);
        return `${formatMoneySign(discountedPrice)} for ${round(
          (pricePayNow || 0) / bookingItem.classInfo.price
        )} lessons`;
      }
    }
    if (paymentOption === PAYMENT_VALUE.DIRECT_DEBIT) {
      if (voucher?.code) {
        if (voucher?.discountType === DISCOUNT_TYPE.AMOUNT) {
          return `--`;
        }
        if (voucher?.discountType === DISCOUNT_TYPE.PERCENTAGE) {
          const discountedAmount = roundByTwo(
            classInfo.price * 2 * (voucher?.discountValue / 100)
          );
          const discountedPrice = roundByTwo(
            classInfo.price * 2 - discountedAmount
          );
          return `${formatMoneySign(discountedPrice)} per fortnight`;
        }
      }
      if (automaticDiscount) {
        const discountedAmount = roundByTwo(
          classInfo.price * 2 * (automaticDiscount?.value / 100)
        );
        const discountedPrice = roundByTwo(
          classInfo.price * 2 - discountedAmount
        );
        return `${formatMoneySign(discountedPrice)} per fortnight`;
      }
    }
    return '--';
  };

  const renderPaymentOption = () => {
    if (bookingData.enrollmentType === BOOKING_TYPE.CASUAL_CLASS) {
      return formatData(PAYMENT_VALUE.UPFRONT.replaceAll('_', ' '));
    }
    if (
      [
        CLASS_TYPES.ASSESSMENT_TRIAL,
        CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM
      ].includes(bookingData.classInfo.type)
    ) {
      return formatData(PAYMENT_VALUE.UPFRONT.replaceAll('_', ' '));
    }
    return (
      <AppSelect
        options={CLASS_BOOKING_PAYMENT_OPTIONS}
        value={bookingData.paymentOption || ''}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          onChangePaymentOption(event, bookingData)
        }
        inputSize="small"
        searchable={false}
      />
    );
  };
  return (
    <div className="review_body_body">
      <>
        <div className="review_body_body-row">
          <div className="span3">{getProgramType(classInfo.type)}</div>
          <div className="span3">
            {getEnrolmentType(bookingData?.enrollmentType)}
          </div>
          <div className="span2">{handleDateTimeRecord(bookingData)}</div>
          <div className="span2">
            {getFirstLastLessonBookingItem(bookingData, activeTermSchedules)}
          </div>
          <div className="span3">{renderPaymentOption()}</div>

          <div className="span2" style={{ textAlign: 'right' }}>
            {getDisplayPriceBookingItem(bookingData)}
          </div>
          <div className="span2" style={{ textAlign: 'right' }}>
            {handleGetDiscountedPrice(bookingData)}
          </div>

          <div className="span2 debit_custom" style={{ textAlign: 'right' }}>
            <div className="pay-now-container">
              {/* {bookingData.paymentOption === PAYMENT_VALUE.DIRECT_DEBIT &&
                (bookingData.nextBillingCircle || [])?.length > 0 && (
                  <HiOutlineExclamationCircle
                    onClick={() =>
                      onShowNextBillDay(
                        bookingData.nextBillingCircle || [],
                        bookingData.studentId || ''
                      )
                    }
                  />
                )} */}
              {formatMoneySign(
                handleGetPayNow(bookingData, stopBillingTerms, nextDebitDay)
              )}
            </div>
          </div>
        </div>
        {[BOOKING_TYPE.ONGOING_CLASS, BOOKING_TYPE.HOLIDAY_PROGRAM].includes(
          bookingData.enrollmentType
        ) && (
          <PromoCodeInput
            onApplyPromoCode={onApplyPromoCode}
            automaticDiscount={automaticDiscount}
            bookingData={__bookingData}
          />
        )}
      </>
    </div>
  );
};

export default ReviewBookingDataRow;
