import React, { memo, useEffect, useState } from 'react';
import './scss/calendar.scss';
import { lineColor } from 'common/constants/colors';
import dayjs from 'dayjs';
import { MonthViewProps } from './types';
import LevelListPerDay from './LevelListPerDay';
import { formatDate } from 'common/helpers/dataFormat.helper';

const MonthView: React.FC<MonthViewProps> = memo(
  ({ year, month, onClickDate, publicHolidayDateArray }) => {
    const [currentYear, setCurrentYear] = useState(year);
    const [currentMonth, setCurrentMonth] = useState(month);
    const [currentDate] = useState(new Date());

    useEffect(() => {
      setCurrentYear(year);
      setCurrentMonth(month);
    }, [year, month]);

    const getDatesInMonth = (year: number, month: number) => {
      const dates: Date[] = [];
      const firstDayOfMonth = new Date(year, month - 1, 1);
      const lastDayOfMonth = new Date(year, month, 0);
      const daysInMonth = lastDayOfMonth.getDate();

      const startDayOfWeek = firstDayOfMonth.getDay(); // 0 (Sunday) to 6 (Saturday)
      const endDayOfWeek = lastDayOfMonth.getDay();

      const startDate = new Date(year, month - 1, 1 - startDayOfWeek);
      const endDate = new Date(
        year,
        month - 1,
        daysInMonth + (6 - endDayOfWeek)
      );

      for (
        let date = startDate;
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        dates.push(new Date(date));
      }

      return dates;
    };

    const calendarDates = getDatesInMonth(currentYear, currentMonth);

    return (
      <div>
        <div className="calendar-grid">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(
            (label, idx) => (
              <div
                key={label}
                className="header-day"
                style={{
                  borderTopLeftRadius: idx === 0 ? '15px' : '',
                  borderTopRightRadius: idx === 6 ? '15px' : '',
                  borderLeft: idx === 0 ? `1px solid ${lineColor}` : '',
                  borderTop: `1px solid ${lineColor}`
                }}
              >
                {label}
              </div>
            )
          )}
        </div>
        <div className="calendar-grid-content">
          {calendarDates.map((date, index) => (
            <div
              className="calendar-day-container month"
              style={{
                borderLeft:
                  date?.getDay() === 0 ? `1px solid ${lineColor}` : '',
                borderBottomRightRadius:
                  index === calendarDates.length - 1 ? '15px' : '',
                borderBottomLeftRadius:
                  index === calendarDates.length - 7 ? '15px' : '',
                backgroundColor: publicHolidayDateArray.includes(
                  formatDate(date)
                )
                  ? 'rgba(3, 78, 162, 0.2)'
                  : ''
              }}
              key={index}
            >
              <div key={index} className={`calendar-day `}>
                <div>
                  {date?.getDate() === 1 ? (
                    <div className="date-text-month">
                      <span>{dayjs(date).format('MMM ')}</span>
                      <span
                        className={`date-text ${
                          date?.getDate() === currentDate.getDate() &&
                          date.getMonth() === currentDate.getMonth()
                            ? 'today'
                            : ''
                        }`}
                        onClick={() => onClickDate(date)}
                      >
                        {date?.getDate() === 1
                          ? `${dayjs(date).format('D')}`
                          : date?.getDate()}
                      </span>
                    </div>
                  ) : (
                    <div className="date-text-month">
                      <div
                        className={`date-text ${
                          date?.getDate() === currentDate.getDate() &&
                          date.getMonth() === currentDate.getMonth()
                            ? 'today'
                            : ''
                        }`}
                        onClick={() => onClickDate(date)}
                      >
                        {date?.getDate() === 1
                          ? `${dayjs(date).format('D')}`
                          : date?.getDate()}
                      </div>
                    </div>
                  )}
                  {publicHolidayDateArray.includes(formatDate(date)) && (
                    <div className="public-holiday">Public Holiday</div>
                  )}
                </div>
                <LevelListPerDay date={date} key={index} />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
);
export default MonthView;
