import React, { useEffect, useState } from 'react';
import './desktop.scss';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import { createColumnHelper } from '@tanstack/react-table';
import AppCard from 'common/components/AppCard';
import AppInputSearch from 'common/components/AppInputSearch';
import AppButton from 'common/components/AppButton';
import AppTable from 'common/components/AppTable';
import useDebounce from 'common/hooks/useDebounce';
import { auditReportData } from './mock';
import AppDatePicker from 'common/components/AppDatePicker';
import dayjs from 'dayjs';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import AppTextArea from 'common/components/AppTextArea';
import { HiMagnifyingGlass } from 'react-icons/hi2';

// TEST, please delete after integrate.
function fuzzySearch(query: string, items: Array<any>): Array<any> {
  if (query === '') {
    return items;
  }
  const matchingItems: Array<any> = [];
  for (const item of items) {
    if (item.name.toLowerCase().includes(query.toLowerCase())) {
      matchingItems.push(item);
    }
  }
  return matchingItems;
}

const StaffAuditReport = () => {
  const columnHelper = createColumnHelper<any>();

  const columns = [
    columnHelper.accessor('date', {
      header: () => <span>Date</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('clickedBy', {
      header: () => <span>Clicked By</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('member', {
      header: () => <span>Member</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('location', {
      header: () => <span>Location</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('class', {
      header: () => <span>Class</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('id', {
      header: () => <span>Action</span>,
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <div onClick={() => onSelectStaff(info.getValue())}>
              <img src="/icons/more-vertical.svg" alt="mover-vertical" />
            </div>
          </div>
        );
      }
    })
  ];

  const [selectedStaff, setSelectedStaff] = useState<any | null>(null);

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search, 800);

  const onSelectStaff = (staffId: any) => {
    setSelectedStaff(data.filter((e) => e.id === staffId));
  };

  const onCloseStaff = () => {
    setSelectedStaff(null);
  };

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const [data, setData] = useState<Array<any>>(auditReportData);

  useEffect(() => {
    setData(fuzzySearch(debouncedSearch, auditReportData));
  }, [debouncedSearch]);

  return (
    <main className="staffAuditReportPage">
      {/* STAFF AUDIT REPORT DETAILS */}
      <AppModal open={!!selectedStaff} onClose={onCloseStaff}>
        <AppModalTitle>STAFF AUDIT REPORT DETAILS</AppModalTitle>
        <AppModalContent>
          <AppDatePicker label="Date" value={dayjs()} onChange={() => {}} />
          <AppTextArea
            label="Report"
            value={
              'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
            }
          />
        </AppModalContent>
        <AppModalActions>
          <AppButton variant="secondary" onClick={onCloseStaff}>
            Close
          </AppButton>
        </AppModalActions>
      </AppModal>

      <AppBreadCrumb
        items={[
          { name: 'Staff', href: '/staff' },
          { name: 'Staff Audit Report', href: '' }
        ]}
      />
      <div className="layoutContainer staffAuditReport">
        <AppCard className="staffAuditReport__generate">
          <div className="staffAuditReport__generate-left">
            <p>Show reports between</p>
            <AppDatePicker
              noFloatingLabel
              label="mm/dd/yyyy"
              value={null}
              onChange={() => {}}
            />
            <AppDatePicker label="" value={dayjs()} onChange={() => {}} />
          </div>
          <div className="staffAuditReport__generate-right">
            <AppButton variant="primary" buttonSize="small" type="submit">
              Generate
            </AppButton>
          </div>
        </AppCard>
        <AppCard>
          <div className="staffAuditReport__table">
            <div className="staffAuditReport__table-header">
              <h2 className="staffAuditReport__table-header-title">
                Result(s)
              </h2>
              <div className="staffAuditReport__table-header-search">
                <AppInputSearch
                  type="text"
                  value={search}
                  onChange={onChangeSearch}
                  placeholder="Member, location, class"
                  onClearSearch={onClearSearch}
                  startIcon={<HiMagnifyingGlass />}
                />
                <AppButton variant="secondary" buttonSize="small" type="submit">
                  <div className="downloadDocumentIcon">
                    <img
                      src="/icons/document-download.svg"
                      alt="document-download"
                    />
                  </div>
                  <p>Excel</p>
                </AppButton>
                <AppButton variant="secondary" buttonSize="small" type="submit">
                  <div className="downloadDocumentIcon">
                    <img
                      src="/icons/document-download.svg"
                      alt="document-download"
                    />
                  </div>
                  <p>CSV</p>
                </AppButton>
              </div>
            </div>
            <div className="staffAuditReport__table-content">
              <AppTable data={data} columns={columns} />
            </div>
          </div>
        </AppCard>
      </div>
    </main>
  );
};

export default StaffAuditReport;
