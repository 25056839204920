import React from 'react';
import { HiPlus } from 'react-icons/hi';
import './desktop.scss';

const LevelMilestoneCardAdd = () => {
  return (
    <div className="level__milestoneCardAdd">
      <div className="level__milestoneCardAdd__main">
        <HiPlus />
        <p>Add Milestone</p>
      </div>
    </div>
  );
};

export default React.memo(LevelMilestoneCardAdd);
