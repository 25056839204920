import React from 'react';
import ReportSelection from '../components/ReportSelection';
import AppSelect from 'common/components/AppSelect';
import AppButton from 'common/components/AppButton';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppDatePicker from 'common/components/AppDatePicker';
import { createColumnHelper } from '@tanstack/react-table';
import {
  formatData,
  formatDate,
  formatMoneySign
} from 'common/helpers/dataFormat.helper';
import AppTable from 'common/components/AppTable';
import { getTerms } from 'services/term.service';
import { useToast } from 'context/ToastContext';
import { ITerm } from 'common/interfaces/term.interface';
import { IOption } from 'common/interfaces';
import { PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import { uniq } from 'lodash';
import { CLASS_TYPES } from 'common/enums/class.enum';
import dayjs, { Dayjs } from 'dayjs';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { useBrandLocation } from 'context/BrandLocationContext';
import { FAMILY_STUDENT_STATUS, STUDENT_OPTION } from '../enum';
import { getExportFamilyDetailsReport } from 'services/report.service';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { IExportFamilyDetailReport } from 'common/interfaces/report.interface';
import { ExportFamilyDetailPrams } from '../type';
import {
  ERROR_MESSAGE_DATE,
  FIXED_PAGE,
  FIXED_SIZE,
  FORMAT_END_OF_DATE,
  FORMAT_START_OF_DATE,
  REPORT_PATH,
  REPORT_PAYMENT_TYPE_OPTIONS,
  REPORT_PROGRAM_TYPE_OPTIONS,
  REPORT_STUDENT_FAMILY_OPTIONS,
  REPORT_STUDENT_FAMILY_STATUS_OPTIONS
} from '../constant';
import './desktop.scss';
import { getProgramType } from 'common/helpers/index.helper';

interface IFilter {
  programType: CLASS_TYPES;
  paymentTypes: Array<string>;
  studentOption: STUDENT_OPTION;
  familyStudentStatus: FAMILY_STUDENT_STATUS;
  termA?: ITerm;
  termB?: ITerm;
  dateFrom?: string;
  dateTo?: string;
}

const initFilter: IFilter = {
  programType: CLASS_TYPES.NONE,
  paymentTypes: [],
  studentOption: STUDENT_OPTION.IDLE,
  familyStudentStatus: FAMILY_STUDENT_STATUS.IDLE
};

const ReportExportFamilyDetails = () => {
  const toast = useToast();
  const { selectedLocation: __globalLocation } = useBrandLocation();

  const columnHelper = createColumnHelper<IExportFamilyDetailReport>();

  const columns = [
    columnHelper.accessor('student', {
      header: () => <span>student ID</span>,
      cell: (info) => formatData(info.getValue()?.memberId)
    }),
    columnHelper.accessor('student', {
      header: () => <span>Student name</span>,
      cell: (info) => (
        <div>
          {`${formatData(info.getValue()?.lastName)}, ${formatData(
            info.getValue()?.firstName
          )}`}
        </div>
      )
    }),
    columnHelper.accessor('class.level', {
      header: () => <span>Student Age</span>,
      cell: (info) =>
        `${formatData(info.getValue()?.fromAge)} - ${formatData(
          info.getValue()?.toAge
        )}`
    }),
    columnHelper.accessor('class.startDate', {
      header: () => <span>Lesson day</span>,
      cell: (info) => dayjs(info.getValue()).format('dddd')
    }),
    columnHelper.accessor('class.startTime', {
      header: () => <span>Lesson Time</span>,
      cell: (info) => dayjs(info.getValue()).format('HH:mm')
    }),
    columnHelper.accessor('class.level.shortName', {
      header: () => <span>Lesson Level</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('class.area', {
      header: () => <span>Lesson Area</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('class.instructor.firstName', {
      header: () => <span>Instructor Name</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('session.startTime', {
      header: () => <span>Student Enrolment start date</span>,
      cell: (info) => formatDate(info.getValue())
    }),
    columnHelper.accessor('responsiblePerson.memberId', {
      header: () => <span>rp id</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('responsiblePerson', {
      header: () => <span>RP Name</span>,
      cell: (info) => (
        <div>
          {`${formatData(info.getValue()?.lastName)}, ${formatData(
            info.getValue()?.firstName
          )}`}
        </div>
      )
    }),
    columnHelper.accessor('responsiblePerson.phoneNumber', {
      header: () => <span>RP PHONE</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('responsiblePerson.email', {
      header: () => <span>RP EMAIL</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('totalOutstanding', {
      header: () => <span>money outstanding</span>,
      cell: (info) => formatMoneySign(info.getValue())
    }),
    columnHelper.accessor('paymentType', {
      header: () => <span>Enrolment Payment Type</span>,
      cell: (info) => formatData(info.getValue()?.replaceAll('_', ' '))
    })
  ];

  const columnsBookingNewAssessment = [
    columnHelper.accessor('student', {
      header: () => <span>student ID</span>,
      cell: (info) => formatData(info.getValue()?.memberId)
    }),
    columnHelper.accessor('student', {
      header: () => <span>Student name</span>,
      cell: (info) => (
        <div>
          {`${formatData(info.getValue()?.lastName)}, ${formatData(
            info.getValue()?.firstName
          )}`}
        </div>
      )
    }),
    columnHelper.accessor('class.level', {
      header: () => <span>Student Age</span>,
      cell: (info) =>
        `${formatData(info.getValue()?.fromAge)} - ${formatData(
          info.getValue()?.toAge
        )}`
    }),
    columnHelper.accessor('class.startDate', {
      header: () => <span>assessment Date, Time</span>,
      cell: (info) =>
        `${formatDate(info.getValue())} ${dayjs(
          info.row.original.class.startTime
        ).format('HH:mm')}`
    }),
    columnHelper.accessor('bookedNewAssessmentClasses', {
      header: () => <span>assessment Attended</span>,
      cell: (info) =>
        info.getValue()?.[0]?.checkIns?.[0]?.attended ? 'Yes' : 'No'
    }),
    columnHelper.accessor('class.startTime', {
      header: () => <span>Enrolment Status</span>,
      cell: (info) => `Enrolled`
    }),
    columnHelper.accessor('bookedNewAssessmentClasses', {
      header: () => <span>Assessment Result</span>,
      cell: (info) =>
        formatData(info.getValue()?.[0]?.assessmentResult?.replaceAll('-', ' '))
    }),
    columnHelper.accessor('class.type', {
      header: () => <span>Program Type</span>,
      cell: (info) => getProgramType(info.getValue())
    }),
    columnHelper.accessor('responsiblePerson.memberId', {
      header: () => <span>rp id</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('responsiblePerson', {
      header: () => <span>RP Name</span>,
      cell: (info) => (
        <div>
          {`${formatData(info.getValue()?.lastName)}, ${formatData(
            info.getValue()?.firstName
          )}`}
        </div>
      )
    })
  ];

  const dateError = React.useRef<string>('');

  const [isGenerated, setIsGenerated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [data, setData] = React.useState<Array<IExportFamilyDetailReport>>([]);

  const [pageIndex, setPageIndex] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [pageTotal, setPageTotal] = React.useState<number>(-1);

  const [terms, setTerms] = React.useState<Array<ITerm>>([]);

  // Search section
  const [programType, setProgramType] = React.useState<CLASS_TYPES>(
    CLASS_TYPES.NONE
  );
  const [paymentTypes, setPaymentTypes] = React.useState<Array<string>>([]);
  const [studentOption, setStudentOption] = React.useState<STUDENT_OPTION>(
    STUDENT_OPTION.IDLE
  );
  const [familyStudentStatus, setFamilyStudentStatus] =
    React.useState<FAMILY_STUDENT_STATUS>(FAMILY_STUDENT_STATUS.IDLE);
  // Search optional
  const [dateFrom, setDateFrom] = React.useState<string>('');
  const [dateTo, setDateTo] = React.useState<string>('');
  const [termA, setTermA] = React.useState<ITerm | undefined>(undefined);
  const [termB, setTermB] = React.useState<ITerm | undefined>(undefined);

  const filter = React.useRef<IFilter>(initFilter);

  const __paymentTypePlaceholderText: string = React.useMemo(() => {
    return paymentTypes.length === 0
      ? 'Select option'
      : `${paymentTypes.length} selected`;
  }, [paymentTypes]);

  const __termOptions: Array<IOption> = React.useMemo(() => {
    return terms
      .sort((a, b) => {
        return dayjs(a.startDate).isBefore(b.startDate) ? -1 : 1;
      })
      .map((term) => {
        return {
          label: `${term.name} (${formatDate(term.startDate)} - ${formatDate(
            term.endDate
          )})`,
          value: term._id
        };
      });
  }, [terms]);

  const __isDisableGenerateButton: boolean = React.useMemo(() => {
    // Check main fields
    if (!programType || paymentTypes.length === 0 || !studentOption) {
      return true;
    }
    // Check option fields
    let flag: boolean = false;
    switch (familyStudentStatus) {
      case FAMILY_STUDENT_STATUS.ONGOING_BOOKING:
        if (!dateFrom) flag = true;
        break;
      case FAMILY_STUDENT_STATUS.EXPIRED_BOOKING_WITHOUT_RENEWAL:
        if (!termA || !termB) flag = true;
        break;
      case FAMILY_STUDENT_STATUS.ACTIVE_BOOKING_FIRST_WEEK_OF_THE_TERM:
        if (!termA) flag = true;
        break;
      case FAMILY_STUDENT_STATUS.STUDENT_AND_LAST_LESSON:
      case FAMILY_STUDENT_STATUS.BOOKING_NEW_ASSESSMENT:
        if (!dateFrom || !dateTo || !!dateError.current) flag = true;
        break;
      case FAMILY_STUDENT_STATUS.IDLE:
        flag = true;
        break;
      default:
        break;
    }
    return flag;
  }, [
    programType,
    paymentTypes,
    studentOption,
    familyStudentStatus,
    dateFrom,
    dateTo,
    termA,
    termB,
    dateError
  ]);

  // Search section methods
  const handleChangeProgramType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setProgramType(event.target.value as CLASS_TYPES);
  };
  const handleTogglePaymentType = (value: string) => {
    const newPaymentTypes = [...paymentTypes];
    const findIndex = newPaymentTypes.findIndex((levelId) => levelId === value);
    if (findIndex === -1) {
      newPaymentTypes.push(value as PAYMENT_VALUE);
    } else {
      newPaymentTypes.splice(findIndex, 1);
    }
    setPaymentTypes(newPaymentTypes);
  };
  const handleSelectAllPaymentType = () => {
    const values = REPORT_PAYMENT_TYPE_OPTIONS.map((paymentType) =>
      paymentType.value?.toString()
    );
    if (paymentTypes.length === REPORT_PAYMENT_TYPE_OPTIONS.length) {
      setPaymentTypes([]);
    } else {
      setPaymentTypes(uniq(values.concat(paymentTypes)));
    }
  };
  const handleChangeStudentOption = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setStudentOption(event.target.value as STUDENT_OPTION);
  };
  const handleChangeSelectedStudentStatus = (value: string) => {
    setFamilyStudentStatus(value as FAMILY_STUDENT_STATUS);
  };
  // Search optional methods
  const handleChangeDateFrom = (value: Dayjs | null) => {
    setDateFrom(value?.format(FORMAT_START_OF_DATE) || '');
    if (
      value?.isAfter(dayjs(dateTo)) &&
      (familyStudentStatus === FAMILY_STUDENT_STATUS.STUDENT_AND_LAST_LESSON ||
        familyStudentStatus === FAMILY_STUDENT_STATUS.BOOKING_NEW_ASSESSMENT)
    ) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeDateTo = (value: Dayjs | null) => {
    setDateTo(value?.format(FORMAT_END_OF_DATE) || '');
    if (
      value?.isBefore(dayjs(dateFrom)) &&
      (familyStudentStatus === FAMILY_STUDENT_STATUS.STUDENT_AND_LAST_LESSON ||
        familyStudentStatus === FAMILY_STUDENT_STATUS.BOOKING_NEW_ASSESSMENT)
    ) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeTermA = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const found = terms.find((term) => term._id === event.target.value);
    setTermA(found);
  };
  const handleChangeTermB = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const found = terms.find((term) => term._id === event.target.value);
    setTermB(found);
  };

  const handleGenerate = () => {
    setPageIndex(1);
    filter.current = {
      programType: programType as CLASS_TYPES,
      paymentTypes: paymentTypes,
      studentOption: studentOption,
      familyStudentStatus: familyStudentStatus,
      dateFrom: dateFrom,
      dateTo: dateTo,
      termA: termA,
      termB: termB
    };
    fetchExportFamilyDetailsReport();
  };

  const fetchExportFamilyDetailsReport = async () => {
    if (!__globalLocation?._id) return;
    setLoading(true);
    try {
      const params: ExportFamilyDetailPrams = {
        locationId: __globalLocation._id,
        page: pageIndex,
        limit: pageSize,
        programType: filter.current.programType,
        paymentTypes: filter.current.paymentTypes.join(',').trim(),
        studentOption: filter.current.studentOption,
        studentStatus: filter.current.familyStudentStatus
      };
      switch (filter.current.familyStudentStatus) {
        case FAMILY_STUDENT_STATUS.ONGOING_BOOKING:
          params.dateFrom = convertToUnixTime(filter.current.dateFrom || '');
          break;
        case FAMILY_STUDENT_STATUS.EXPIRED_BOOKING_WITHOUT_RENEWAL:
          params.termAId = filter.current.termA?._id || '';
          params.termBId = filter.current.termB?._id || '';
          break;
        case FAMILY_STUDENT_STATUS.ACTIVE_BOOKING_FIRST_WEEK_OF_THE_TERM:
          params.termAId = filter.current.termA?._id || '';
          break;
        case FAMILY_STUDENT_STATUS.STUDENT_AND_LAST_LESSON:
        case FAMILY_STUDENT_STATUS.BOOKING_NEW_ASSESSMENT:
          params.dateFrom = convertToUnixTime(filter.current.dateFrom || '');
          params.dateTo = convertToUnixTime(filter.current.dateTo || '');
          break;
        case FAMILY_STUDENT_STATUS.IDLE:
          break;
        default:
          break;
      }
      const result = await getExportFamilyDetailsReport(params);
      setData(result?.data?.data?.data);
      setPageTotal(result?.data?.data?.total);
      setIsGenerated(true);
    } catch (error: any) {
      setData([]);
      setPageTotal(-1);
      toast.error(error?.response?.data?.message || 'Failed to generate date');
    } finally {
      setLoading(false);
    }
  };

  const fetchInitData = React.useCallback(async () => {
    if (!__globalLocation?._id) return;
    try {
      const result = await getTerms(FIXED_PAGE, FIXED_SIZE);
      setTerms(result?.data?.data?.data || []);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Failed to get terms');
      setTerms([]);
    }
    // eslint-disable-next-line
  }, [__globalLocation?._id]);

  React.useEffect(() => {
    if (isGenerated) {
      fetchExportFamilyDetailsReport();
    }
    // eslint-disable-next-line
  }, [pageSize, pageIndex]);

  React.useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  return (
    <main id="reportExportFamilyDetails" className="reportExportFamilyDetails">
      <AppBreadCrumb
        items={[
          { name: 'Reporting', href: REPORT_PATH },
          { name: 'Export Family Details' }
        ]}
      />
      <div className="layoutContainer reportExportFamilyDetails__wrapper">
        <section className="reportExportFamilyDetails__search">
          <div className="reportExportFamilyDetails__search__fields">
            <AppSelect
              searchable={false}
              inputSize="small"
              label="Program Type"
              placeholder="Select option"
              options={REPORT_PROGRAM_TYPE_OPTIONS}
              value={programType}
              onChange={handleChangeProgramType}
            />
            <ReportSelection
              label="Payment Type"
              placeholder={__paymentTypePlaceholderText}
              options={REPORT_PAYMENT_TYPE_OPTIONS}
              onSelect={handleTogglePaymentType}
              onSelectAll={handleSelectAllPaymentType}
              selectedIds={paymentTypes}
            />
            <AppSelect
              searchable={false}
              inputSize="small"
              label="Student Option"
              placeholder="Select option"
              options={REPORT_STUDENT_FAMILY_OPTIONS}
              value={studentOption}
              onChange={handleChangeStudentOption}
            />
            <div className="reportExportFamilyDetails__search__fields--studentStatus">
              <AppSelect
                searchable={false}
                inputSize="small"
                label="Student Status"
                placeholder="Select option"
                options={REPORT_STUDENT_FAMILY_STATUS_OPTIONS}
                value={familyStudentStatus}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  handleChangeSelectedStudentStatus(event.target.value);
                }}
              />
            </div>

            {familyStudentStatus === FAMILY_STUDENT_STATUS.ONGOING_BOOKING && (
              <AppDatePicker
                size="small"
                label="Date"
                value={dayjs(dateFrom)}
                onChange={handleChangeDateFrom}
              />
            )}

            {familyStudentStatus ===
              FAMILY_STUDENT_STATUS.EXPIRED_BOOKING_WITHOUT_RENEWAL && (
              <React.Fragment>
                <AppSelect
                  inputSize="small"
                  label="Term A"
                  placeholder="Select option"
                  options={__termOptions}
                  value={termA?._id || ''}
                  onChange={handleChangeTermA}
                />
                <AppSelect
                  inputSize="small"
                  label="Term B"
                  placeholder="Select option"
                  options={__termOptions}
                  value={termB?._id || ''}
                  onChange={handleChangeTermB}
                />
              </React.Fragment>
            )}

            {familyStudentStatus ===
              FAMILY_STUDENT_STATUS.ACTIVE_BOOKING_FIRST_WEEK_OF_THE_TERM && (
              <AppSelect
                inputSize="small"
                label="Term"
                placeholder="Select option"
                options={__termOptions}
                value={termA?._id || ''}
                onChange={handleChangeTermA}
              />
            )}

            {(familyStudentStatus ===
              FAMILY_STUDENT_STATUS.STUDENT_AND_LAST_LESSON ||
              familyStudentStatus ===
                FAMILY_STUDENT_STATUS.BOOKING_NEW_ASSESSMENT) && (
              <React.Fragment>
                <AppDatePicker
                  size="small"
                  label="Date from"
                  value={dayjs(dateFrom)}
                  onChange={handleChangeDateFrom}
                />
                <AppDatePicker
                  size="small"
                  label="Date to"
                  value={dayjs(dateTo)}
                  onChange={handleChangeDateTo}
                  message={{
                    type: 'error',
                    text: dateError.current
                  }}
                />
              </React.Fragment>
            )}
          </div>
          <AppButton
            variant="primary"
            buttonSize="small"
            isLoading={loading}
            disabled={__isDisableGenerateButton}
            onClick={handleGenerate}
          >
            generate
          </AppButton>
        </section>

        {!isGenerated && loading && <AppLoadingContainer />}

        {isGenerated && (
          <section className="reportExportFamilyDetails__content">
            <div className="reportExportFamilyDetails__content__header">
              <p className="reportExportFamilyDetails__content__header--title">
                Student
              </p>
              {/* <div className="reportExportFamilyDetails__content__header--actions">
                <ReportPrintButton />
                <ReportExportButton />
              </div> */}
            </div>

            <div className="reportExportFamilyDetails__content__table">
              {filter.current.familyStudentStatus ===
              FAMILY_STUDENT_STATUS.BOOKING_NEW_ASSESSMENT ? (
                <AppTable
                  loading={loading}
                  data={data}
                  columns={columnsBookingNewAssessment}
                  pagination={{
                    index: pageIndex,
                    size: pageSize,
                    total: pageTotal
                  }}
                  onChangePage={(index: number, size: number) => {
                    setPageIndex(index);
                    setPageSize(size);
                  }}
                />
              ) : (
                <AppTable
                  loading={loading}
                  data={data}
                  columns={columns}
                  pagination={{
                    index: pageIndex,
                    size: pageSize,
                    total: pageTotal
                  }}
                  onChangePage={(index: number, size: number) => {
                    setPageIndex(index);
                    setPageSize(size);
                  }}
                />
              )}
            </div>
          </section>
        )}
      </div>
    </main>
  );
};

export default ReportExportFamilyDetails;
