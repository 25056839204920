import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/layout/Sidebar';
import { useLayout } from 'context/LayoutContext';
import AppFooter from 'components/common/AppFooter';
import IdleTimer from './IdleTimer';

const Layout = () => {
  const { isShowSidebar, isHoverMenu, handleMouseLeaveMenu } = useLayout();
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div
        className="sidebar-container"
        style={{
          display:
            isShowSidebar || (!isShowSidebar && isHoverMenu) ? 'block' : 'none'
        }}
      >
        <Sidebar />
      </div>
      <div
        className={
          isShowSidebar && !isHoverMenu ? 'container open' : 'container'
        }
        onMouseEnter={handleMouseLeaveMenu}
      >
        <Outlet />

        <IdleTimer />
      </div>
      <AppFooter />
    </div>
  );
};

export default memo(Layout);
