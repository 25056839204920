import React, { useEffect, useState } from 'react';
import './scss/pickColor.scss';
import CustomColorPicker from './CustomColorPicker';
import { HiOutlineX } from 'react-icons/hi';

interface Props {
  onClose: () => void;
  onChangeItemColor: (color: any) => void;
  classColor: string;
  style?: React.CSSProperties;
  showPickColor?: boolean;
}
const PickColor = ({
  onClose,
  classColor,
  onChangeItemColor,
  style,
  showPickColor = false
}: Props) => {
  const [color, setColor] = useState(classColor);

  useEffect(() => {
    setColor(classColor);
  }, [classColor]);

  if (!showPickColor) return;

  const handleColorChange = (newColor: any) => {
    setColor(newColor);
    onChangeItemColor(newColor);
  };
  return (
    <div className="pick-color" style={style}>
      <div className="frame">
        <div className="div">
          <div className="text-wrapper">Custom</div>
          <HiOutlineX onClick={onClose} fontSize={16} />
        </div>
        <div style={{ padding: '0 10px' }}>
          <CustomColorPicker
            hexCode={color}
            onChange={(color: any) => {
              handleColorChange(color.hex);
            }}
          ></CustomColorPicker>
        </div>

        <div className="value-input-controls">
          <div className="hex-input">
            <div className="text-wrapper-2">Hex</div>
            <div className="div-4">
              <div className="div-5" style={{ backgroundColor: color }} />
              <div className="element-wrapper">
                <p className="element">
                  <input
                    className="text-wrapper-3"
                    value={color}
                    onChange={(e) => handleColorChange(e.target.value)}
                    maxLength={7}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="color">
          <div className="text-wrapper-4">Color</div>
          <div className="div-6">
            <div
              className="color-container"
              onClick={() => handleColorChange('#ef2525')}
            >
              <div className="red-box" />
              <div className="text-wrapper-5">Red</div>
            </div>
            <div
              className="color-container"
              onClick={() => handleColorChange('#f47c0d')}
            >
              <div className="orange-box" />
              <div className="text-wrapper-5">Orange</div>
            </div>
            <div
              className="color-container"
              onClick={() => handleColorChange('#e9d415')}
            >
              <div className="yellow-box" />
              <div className="text-wrapper-5">Yellow</div>
            </div>
            <div
              className="color-container"
              onClick={() => handleColorChange('#0eeb23')}
            >
              <div className="green-box" />
              <div className="text-wrapper-5">Green</div>
            </div>
            <div
              className="color-container"
              onClick={() => handleColorChange('#1b31fc')}
            >
              <div className="blue-box" />
              <div className="text-wrapper-5">Blue</div>
            </div>
            <div
              className="color-container"
              onClick={() => handleColorChange('#b60af3')}
            >
              <div className="purple-box" />
              <div className="text-wrapper-5">Purple</div>
            </div>
            <div
              className="color-container"
              onClick={() => handleColorChange('#fa086e')}
            >
              <div className="pink-box" />
              <div className="text-wrapper-5">Pink</div>
            </div>
            <div
              className="color-container"
              onClick={() => handleColorChange('#200e0e')}
            >
              <div className="brown-box" />
              <div className="text-wrapper-5">Brown</div>
            </div>
            <div
              className="color-container"
              onClick={() => handleColorChange('#3e4044')}
            >
              <div className="gray-box" />
              <div className="text-wrapper-5">Gray</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PickColor;
