import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './desktop.scss';

interface IAppCKEditorProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const config = {
  toolbar: ['undo', 'redo', 'numberedList', 'bulletedList', 'link']
};

const AppCKEditor = (props: IAppCKEditorProps) => {
  const { value, onChange, disabled = false } = props;

  return (
    <div className={`c__app__ckEditor ${disabled ? 'disabled' : ''}`}>
      <CKEditor
        editor={ClassicEditor}
        config={config}
        data={value}
        onChange={(_, editor: ClassicEditor) => {
          onChange(editor.getData());
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default React.memo(AppCKEditor);
