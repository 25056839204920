import AppBreadCrumb from 'components/common/AppBreadcrumb';
import {
  ERROR_MESSAGE_DATE,
  FORMAT_END_OF_DATE,
  FORMAT_START_OF_DATE,
  REPORT_PATH
} from '../constant';
import AppDatePicker from 'common/components/AppDatePicker';
import AppSelect from 'common/components/AppSelect';
import AppButton from 'common/components/AppButton';
import { getGstReport, getPdfGstReport, getPdfGstReportBreakdown } from 'services/report.service';
import { useCallback, useMemo, useRef, useState } from 'react';
import { convertToUnixTime } from 'common/helpers/time.helper';
import dayjs, { Dayjs } from 'dayjs';
import { useToast } from 'context/ToastContext';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { useBrandLocation } from 'context/BrandLocationContext';

import './desktop.scss';
import { useQuery } from '@tanstack/react-query';
import { getAllLocations } from 'services/location.service';
import ReportLocationsDetailedView from './ReportLocationsDetailedView';

const ReportGst = () => {
  const toast = useToast();
  const { selectedLocation: globalLocation } = useBrandLocation();

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [selectedLocations, setSelectedLocations] = useState<string[]>([])

  const locationsQuery = useQuery({
    queryKey: ['locations'],
    queryFn: async () => {
      const { data: { data } } = await getAllLocations()
      return data.data
    }
  })

  const locations = useMemo<any[]>(() => (locationsQuery.data || []), [locationsQuery.data])

  const locationsMapped = useMemo<any[]>(() => locations.map(({ _id, name }: any) => ({
    value: _id,
    label: name
  })), [locations])

  const [data, setData] = useState<any>();

  const [loading, setLoading] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);


  const dateError = useRef('');

  const currentFilter = useRef<{
    locationIds: string[];
    dateFrom: number;
    dateTo: number;
  }>({
    locationIds: [],
    dateFrom: 0,
    dateTo: 0,
  });

  const fetchGeneratedReport = useCallback(async () => {
    try {
      setLoading(true);

      const { data: { data } } = await getGstReport(currentFilter.current);

      setData(data)

      setIsGenerated(true);

      setDateFrom(
        dayjs(currentFilter.current.dateFrom)?.format(FORMAT_START_OF_DATE)
      );
      setDateTo(
        dayjs(currentFilter.current.dateTo)?.format(FORMAT_START_OF_DATE)
      );
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to get gst report'
      );
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [globalLocation?._id, dateFrom, dateTo]);

  const handleLocations = (event: any) => {
    const selected: string[] = event.target.value.split(',')
    setSelectedLocations(() => selected)
  }

  const handleChangeDateFrom = (value: Dayjs | null) => {
    setDateFrom(value?.format(FORMAT_START_OF_DATE) || '');
    if (value?.isAfter(dayjs(dateTo))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeDateTo = (value: Dayjs | null) => {
    setDateTo(value?.format(FORMAT_END_OF_DATE) || '');
    if (value?.isBefore(dayjs(dateFrom))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };

  const __isDisableGenerateButton: boolean = useMemo(() => {
    return (
      !dateFrom ||
      !dateTo ||
      !!dateError.current
    );
  }, [dateFrom, dateTo, dateError]);

  const handleGenerate = () => {
    if (!globalLocation?._id) return;

    currentFilter.current = {
      locationIds: selectedLocations,
      dateFrom: convertToUnixTime(dateFrom),
      dateTo: convertToUnixTime(dateTo)
    };

    fetchGeneratedReport();
  };

  function handleExport(breakdown = false) {
    function downloadPdf(responseData: any, filename: string) {
      const url = window.URL.createObjectURL(new Blob([new Uint8Array(responseData.data).buffer], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }

    if(breakdown) {
      getPdfGstReportBreakdown(currentFilter.current).then((response) => {
        downloadPdf(response.data.data, 'gst-breakdown.pdf')
      }).catch((err) => {
        console.log('Failed to download export: ', err)
      })

      return
    }

    getPdfGstReport(currentFilter.current).then((response) => {
      downloadPdf(response.data.data, 'gst.pdf')
    }).catch((err) => {
      console.log('Failed to download export: ', err)
    })
  }

  return (
    <main id="reportGst" className="reportGst">
      <AppBreadCrumb
        items={[
          { name: 'Reporting', href: REPORT_PATH },
          { name: 'GST Report' }
        ]}
      />
      <div className="layoutContainer reportGst__wrapper">
        <section className="reportGst__search">
          <div className="reportGst__search__fields">
            <AppDatePicker
              size="small"
              label="Date from"
              value={dayjs(dateFrom)}
              onChange={handleChangeDateFrom}
            />
            <AppDatePicker
              size="small"
              label="Date to"
              value={dayjs(dateTo)}
              onChange={handleChangeDateTo}
              message={{
                type: 'error',
                text: dateError.current
              }}
            />
            <AppSelect
              searchable={false}
              multiValue={true}
              inputSize="small"
              label="Locations"
              placeholder="Select locations"
              options={locationsMapped}
              value={selectedLocations[0] || ''}
              onChange={handleLocations}
            />
          </div>
          <AppButton
            variant="primary"
            buttonSize="small"
            isLoading={loading}
            disabled={__isDisableGenerateButton}
            onClick={handleGenerate}
          >
            generate
          </AppButton>
        </section>

        {!isGenerated && loading && <AppLoadingContainer />}

        {data && isGenerated ? (
          <ReportLocationsDetailedView
            data={data}
            loading={loading}
            onClickExport={() => handleExport()}
            onClickExportBreakdown={() => handleExport(true)}
          />
        ) : null}
      </div>
    </main>
  );
};

export default ReportGst;
