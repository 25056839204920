import { useCallback, useEffect, useState } from 'react';
import useDebounce from 'common/hooks/useDebounce';
import { createColumnHelper } from '@tanstack/react-table';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppToggle from 'common/components/AppToggle';
import AppCard from 'common/components/AppCard';
import AppButton from 'common/components/AppButton';
import AppInputSearch from 'common/components/AppInputSearch';
import AppTable from 'common/components/AppTable';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import { useNavigate } from 'react-router-dom';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import {
  deleteInstructor,
  getInstructorList,
  updateInstructor
} from 'services/instructor.service';
import { useBrandLocation } from 'context/BrandLocationContext';
import {
  formatData,
  formatSecretPhoneNumber
} from 'common/helpers/dataFormat.helper';
import { IInstructor } from 'common/interfaces/instructor.interface';
import { useToast } from 'context/ToastContext';
import {
  HiOutlineCheckCircle,
  HiOutlineIdentification,
  HiOutlineTrash,
  HiPlus
} from 'react-icons/hi';
import InstructorAddForm from './InstructorAddForm';
import { STATUS_TYPE } from 'common/enums/staff.enum';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useAuth } from 'context/AuthContext';
import './desktop.scss';
import LocationListInfo from 'components/ListInfo';

const InstructorList = () => {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<IInstructor>();
  const { selectedLocation: globalLocation } = useBrandLocation();
  const { hasPermission } = useAuth();
  const toast = useToast();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [total, setTotal] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(true);
  const [openAdd, setOpenAdd] = useState<boolean>(false);

  const [data, setData] = useState<Array<IInstructor>>([]);

  const [selectedInstructorId, setSelectedInstructorId] = useState<
    string | null
  >(null);
  const [deActiveAction, setDeActiveAction] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search, 800);

  // DEACTIVATE SECTION
  const onChooseDeactivateInstructor = (id: string) => {
    setSelectedInstructorId(id);
    setDeActiveAction(true);
  };
  const onCancelDeactivateInstructor = () => {
    setDeActiveAction(false);
    setSelectedInstructorId(null);
  };
  const onDeactivateInstructor = async () => {
    try {
      setLoading(true);
      await updateInstructor(selectedInstructorId || '', {
        status: STATUS_TYPE.INACTIVE
      });
      fetchData();
      setSelectedInstructorId(null);
      setDeActiveAction(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Failed to deactivate');
    } finally {
      setLoading(false);
    }
  };
  const onActivateInstructor = async (id: string) => {
    try {
      setLoading(true);
      await updateInstructor(id, { status: STATUS_TYPE.ACTIVE });
      fetchData();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to active instructor'
      );
    } finally {
      setLoading(false);
    }
  };
  // END - DEACTIVATE SECTION

  const columns = [
    columnHelper.accessor('firstName', {
      header: () => <span>SURNAME, NAME</span>,
      cell: (info) => {
        return (
          formatData(info.row.original?.lastName) +
          ', ' +
          formatData(info.row.original?.firstName)
        );
      }
    }),
    columnHelper.accessor('locations', {
      header: () => <span>Location</span>,
      cell: (info) => <LocationListInfo locations={info.getValue()} />,
      size: 200
    }),
    columnHelper.accessor('phoneNumber', {
      header: () => <span>MOBILE</span>,
      cell: (info) => formatSecretPhoneNumber(info.getValue())
    }),
    columnHelper.accessor('email', {
      header: () => <span>EMAIL</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('additionalInfo.credentialIds', {
      header: () => <span></span>,
      cell: (info) =>
        info.getValue()?.length > 0 && (
          <HiOutlineCheckCircle
            size={20}
            className="absenceStatus absenceStatus--approved"
          />
        ),
      size: 20
    }),
    columnHelper.accessor('status', {
      header: () => <span>Status</span>,
      cell: (info) => {
        return (
          <div>
            {info.getValue() === STATUS_TYPE.ACTIVE ? (
              <AppToggle
                value={true}
                onChange={() =>
                  onChooseDeactivateInstructor(info.row.original._id)
                }
                disabled={!hasPermission(PERMISSION.UPDATE_INSTRUCTOR)}
              />
            ) : (
              <AppToggle
                value={false}
                onChange={() => onActivateInstructor(info.row.original._id)}
                disabled={!hasPermission(PERMISSION.UPDATE_INSTRUCTOR)}
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor('_id', {
      header: () => <span>Action</span>,
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_INSTRUCTOR}>
              <div onClick={() => navigate(`/instructors/${info.getValue()}`)}>
                <HiOutlineIdentification fontSize={18} />
              </div>
            </PermissionWrapper>
            <PermissionWrapper permission={PERMISSION.DELETE_INSTRUCTOR}>
              <div onClick={() => onSelectInstructor(info.getValue())}>
                <HiOutlineTrash fontSize={18} />
              </div>
            </PermissionWrapper>
          </div>
        );
      }
    })
  ];

  const onSelectInstructor = (instructorId: string) => {
    setSelectedInstructorId(instructorId);
  };

  const onConfirmDelete = async () => {
    try {
      selectedInstructorId && (await deleteInstructor(selectedInstructorId));
      toast.success('Successfully deleted');
      fetchData();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to delete instructor'
      );
    }
    setSelectedInstructorId(null);
  };

  const onCancelDelete = () => {
    setSelectedInstructorId(null);
  };

  const onOpenAdd = () => {
    setOpenAdd(true);
  };
  const onCloseAdd = () => {
    setOpenAdd(false);
  };

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const { data, total } = await getInstructorList({
        limit,
        page: pageNumber,
        name: debouncedSearch,
        locationId: globalLocation?._id
      });
      setData(data);
      setTotal(total);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to fetch instructor data'
      );
      setData([]);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [pageNumber, limit, debouncedSearch, globalLocation?._id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setPageNumber(1);
  }, [debouncedSearch, globalLocation?._id]);

  const onAddSuccess = async () => {
    onCloseAdd();
    setSearch('');
    setPageNumber(1);
    fetchData();
  };
  return (
    <main className="instructorsPage">
      {/* DEACTIVATE */}
      <AppModal
        open={!!selectedInstructorId && deActiveAction}
        onClose={onCancelDeactivateInstructor}
      >
        <AppModalTitle>DEACTIVATE</AppModalTitle>
        <AppModalContent>
          Are you sure you want to Deactive this instructor?
        </AppModalContent>
        <AppModalActions>
          <AppButton variant="secondary" onClick={onCancelDeactivateInstructor}>
            No
          </AppButton>
          <AppButton isLoading={loading} onClick={onDeactivateInstructor}>
            Yes
          </AppButton>
        </AppModalActions>
      </AppModal>

      {/* ADD */}
      {openAdd && (
        <InstructorAddForm
          open={openAdd}
          onClose={onCloseAdd}
          onSuccess={onAddSuccess}
        />
      )}
      {/* DELETE */}
      <AppModal
        open={!!selectedInstructorId && !deActiveAction}
        onClose={onCancelDelete}
      >
        <AppModalTitle>Delete Instructor</AppModalTitle>
        <AppModalContent>
          ARE YOU SURE YOU WANT TO DELETE THIS Instructor?
        </AppModalContent>
        <AppModalActions>
          <AppButton
            style={{
              cursor: 'pointer'
            }}
            variant="secondary"
            onClick={onCancelDelete}
          >
            No
          </AppButton>
          <AppButton
            style={{
              cursor: 'pointer'
            }}
            onClick={onConfirmDelete}
          >
            Yes
          </AppButton>
        </AppModalActions>
      </AppModal>

      {/* LIST */}
      <AppBreadCrumb items={[{ name: 'Instructor', href: '/instructors' }]} />
      <div className="layoutContainer instructors">
        <AppCard>
          <div className="instructors__table">
            <div className="instructors__table-header">
              <h2 className="instructors__table-header-title">Instructors</h2>
              <div className="instructors__table-header-search">
                <AppInputSearch
                  type="text"
                  value={search}
                  onChange={onChangeSearch}
                  placeholder="Instructor name"
                  onClearSearch={onClearSearch}
                  startIcon={<HiMagnifyingGlass />}
                />
                <PermissionWrapper permission={PERMISSION.CREATE_INSTRUCTOR}>
                  <AppButton
                    variant="primary"
                    buttonSize="small"
                    type="submit"
                    onClick={onOpenAdd}
                  >
                    <HiPlus />
                    Instructor
                  </AppButton>
                </PermissionWrapper>
              </div>
            </div>
            <div className="instructors__table-content">
              <AppTable
                data={data}
                columns={columns}
                pagination={{
                  index: pageNumber,
                  size: limit,
                  total: total
                }}
                onChangePage={(index: number, size: number) => {
                  setPageNumber(index);
                  setLimit(size);
                }}
                loading={loading}
                columnVisibility={
                  !hasPermission(PERMISSION.VIEW_DETAIL_INSTRUCTOR) &&
                  !hasPermission(PERMISSION.DELETE_INSTRUCTOR)
                    ? { _id: false }
                    : undefined
                }
              />
            </div>
          </div>
        </AppCard>
      </div>
    </main>
  );
};

export default InstructorList;
