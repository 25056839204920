import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  ChangeEvent,
  memo,
  useMemo
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from 'context/ToastContext';
import { HiCheck } from 'react-icons/hi';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import Avatar from './Avatar';
import dayjs from 'dayjs';
import { IFirebaseNotification } from 'common/interfaces/firebase.interface';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import { formatData, formatTime } from 'common/helpers/dataFormat.helper';
import {
  getFirebaseNotifications,
  updateNotificationRead
} from 'services/firebase.service';
import { useAuth } from 'context/AuthContext';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  FILTER_NOTIFICATION_STATUS,
  NOTIFICATION_EVENT
} from 'common/enums/firebase.enum';
import { onMessageListener } from '../../firebase';
import NoNotificationSVG from './NoNotificationSVG';
import UploadDocument from 'components/Upload/UploadDocument';
import UploadDocumentSlider from 'components/Upload/UploadDocumentSlider';
import AppSelect from 'common/components/AppSelect';
import { NOTIFICATIONS_STATUS_FILTER_OPTIONS } from 'common/constants';
import AppInputSearch from 'common/components/AppInputSearch';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import useDebounce from 'common/hooks/useDebounce';
import { BeatLoader } from 'react-spinners';

import './desktop.scss';

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

export interface RefHandle {
  refetchReset: () => void;
}

interface Props {
  asPopup?: boolean;
  emitTotal?: (total: number) => void;
  closePopup?: () => void;
}

const NotificationList = forwardRef<RefHandle, Props>(
  ({ asPopup, emitTotal, closePopup }, ref) => {
    useImperativeHandle(ref, () => ({
      refetchReset() {
        handleRefresh();
      }
    }));

    const { user, totalNotification, onInitTotalNotifications } = useAuth();
    const location = useLocation();
    const toast = useToast();
    const navigate = useNavigate();

    const [initLoad, setInitLoad] = useState(true);
    const [loadData, setLoadData] = useState(true);
    const [data, setData] = useState<IFirebaseNotification[]>([]);

    const initialSlide = useRef<number>(0);

    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 400);

    const onChangeSearch = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
      },
      []
    );

    const onClearSearch = useCallback(() => {
      setSearch('');
    }, []);

    const [openSlider, setOpenSlider] = useState(false);

    const [filter, setFilter] = useState<FILTER_NOTIFICATION_STATUS>(
      FILTER_NOTIFICATION_STATUS.IDLE
    );

    const handleOpenSlider = useCallback(() => {
      setOpenSlider(true);
    }, []);

    const handleCloseSlider = useCallback(() => {
      setOpenSlider(false);
    }, []);

    const handleViewDocument = useCallback(
      (documentIndex: number) => {
        initialSlide.current = documentIndex;

        handleOpenSlider();
      },
      [handleOpenSlider]
    );

    const handleChangeFilter = useCallback(
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilter(event?.target?.value as FILTER_NOTIFICATION_STATUS);
      },
      []
    );

    const checkConflictRoute = useCallback(
      (route?: string): string => {
        if (route && location?.pathname?.split('/')?.[1]?.includes(route)) {
          return '';
        }

        if (route) return `${route}/`;

        return '';
      },
      [location]
    );

    const fetchData = useCallback(async () => {
      try {
        if (!user?._id) return;

        setLoadData(true);

        const { data } = await getFirebaseNotifications({
          userId: user?._id,
          page: 1,
          limit: 100,
          ...(filter
            ? { isRead: filter === FILTER_NOTIFICATION_STATUS.READ }
            : undefined),
          keyword: debouncedSearch
        });

        setData(
          data?.data?.data
            ?.filter((dt: IFirebaseNotification) => !!dt?.message)
            ?.map((dt: IFirebaseNotification) => {
              return {
                ...dt,
                // @ts-ignore
                message: JSON.parse(dt?.message)
              };
            })
        );

        onInitTotalNotifications(
          data?.data?.data?.filter((dt: IFirebaseNotification) => !dt?.isRead)
            ?.length
        );

        if (emitTotal)
          emitTotal(
            data?.data?.data?.filter((dt: IFirebaseNotification) => !dt?.isRead)
              ?.length
          );
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Fail to fetch notifications'
        );
      } finally {
        setLoadData(false);
        if (initLoad) setInitLoad(false);
      }

      // eslint-disable-next-line
    }, [
      user?._id,
      emitTotal,
      onInitTotalNotifications,
      filter,
      debouncedSearch
    ]);

    const handleRefresh = useCallback(() => {
      fetchData();
    }, [fetchData]);

    useEffect(() => {
      fetchData();
    }, [fetchData, totalNotification]);

    useEffect(() => {
      onMessageListener()
        .then(() => fetchData())
        .catch((err) =>
          toast.error('failed: ', err || 'Fail to listen notifications')
        );

      // eslint-disable-next-line
    }, [fetchData]);

    const __disableMarkAsRead = useMemo((): boolean => {
      return !data?.filter((dt) => !dt?.isRead)?.length;
    }, [data]);

    const handleMarkAsRead = useCallback(
      async (notifyId = '') => {
        try {
          const notifyNotRead = data?.filter((dt) => !dt?.isRead);

          if (!notifyNotRead?.length) {
            return;
          }

          setLoadData(true);

          if (notifyId) {
            await updateNotificationRead(notifyId);
          } else {
            await Promise.all(
              notifyNotRead?.map((record) => {
                return updateNotificationRead(record?._id);
              })
            );
          }

          handleRefresh();
        } catch (error: any) {
          toast.error(
            error?.response?.data?.message ||
              'Update notification status failed'
          );
        }
      },
      // eslint-disable-next-line
      [data, handleRefresh]
    );

    const __renderNotificationTime = useCallback(
      (data: IFirebaseNotification) => {
        return (
          <>
            {dayjs(data?.createdAt).fromNow() === 'in a few seconds' ||
            dayjs(data?.createdAt).fromNow() === 'a few seconds ago' ? (
              <div className="text2">has just been created</div>
            ) : null}
            <div className="time2">{dayjs(data?.createdAt).fromNow()}</div>
          </>
        );
      },
      []
    );

    const __renderNotificationContent = useCallback(
      (data: IFirebaseNotification) => {
        if (data?.event === NOTIFICATION_EVENT?.NEW_WEBSITE_ENQUIRIES) {
          return (
            <>
              <div className="ct_tittle">
                <div className="tx">an account for</div>
                {__renderNotificationTime(data)}
              </div>
              <div className="tag">
                {`${formatData(data?.message?.lastName)}, ${formatData(
                  data?.message?.firstName
                )}`}
              </div>
              <div className="tittle2">has just been created</div>
            </>
          );
        }

        if (data?.event === NOTIFICATION_EVENT?.NEW_ENROLLMENTS) {
          return (
            <>
              <div className="ct_tittle">
                <div className="tx">new Enrolment for</div>
                {__renderNotificationTime(data)}
              </div>
              {data?.message?.studentId ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${checkConflictRoute('students')}${
                    data?.message?.studentId
                  }?indexRoute=1&tabNumber=1`}
                  className="tag"
                >
                  {formatData(data?.message?.studentName)}
                </a>
              ) : (
                <div className="tag">
                  {formatData(data?.message?.studentName)}
                </div>
              )}
              <div className="tag">{formatData(data?.message?.className)}</div>
              <div className="tag">
                {dayjs(data?.message?.classDate).format('dddd')}
              </div>
              <div className="tag">{formatData(data?.message?.classTime)}</div>
            </>
          );
        }

        if (data?.event === NOTIFICATION_EVENT?.ABSENCE_FORMS) {
          return (
            <>
              <div className="ct_tittle">
                <div className="tx">New Absence Form</div>
                {__renderNotificationTime(data)}
              </div>
              {data?.message?.studentId ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${checkConflictRoute('students')}${
                    data?.message?.studentId
                  }?indexRoute=1&tabNumber=2`}
                  className="tag"
                >
                  {formatData(data?.message?.studentName)}
                </a>
              ) : (
                <div className="tag">
                  {formatData(data?.message?.studentName)}
                </div>
              )}
              <div className="tag">{formatData(data?.message?.className)}</div>
              <div className="tag">
                {dayjs(data?.message?.classDate).format('dddd')}
              </div>
              <div className="tag">
                {formatTime(data?.message?.classDate)}
              </div>
            </>
          );
        }

        if (data?.event === NOTIFICATION_EVENT?.NON_ATTENDANCE) {
          return (
            <>
              <div className="ct_tittle">
                <div className="tx">Student missed at least 4 consecutive lessons, no absence submitted</div>
                {__renderNotificationTime(data)}
              </div>
              {data?.message?.studentId ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${checkConflictRoute('students')}${
                    data?.message?.studentId
                  }?indexRoute=1&tabNumber=2`}
                  className="tag"
                >
                  {formatData(data?.message?.studentName)}
                </a>
              ) : (
                <div className="tag">
                  {formatData(data?.message?.studentName)}
                </div>
              )}
              <div className="tag">{formatData(data?.message?.className)}</div>
              <div className="tag">
                {dayjs(data?.message?.classDate).format('dddd')}
              </div>
              <div className="tag">
                {formatTime(data?.message?.classDate)}
              </div>
            </>
          );
        }

        if (data?.event === NOTIFICATION_EVENT?.STUDENT_CANCELLATIONS) {
          return (
            <>
              <div className="columWrapper">
                <div className="body_content">
                  <div className="ct_tittle">
                    <div className="tx">Student cancellation for</div>
                    {__renderNotificationTime(data)}
                  </div>
                  {data?.message?.student?._id ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${checkConflictRoute('students')}${
                        data?.message?.student?._id
                      }?indexRoute=1&tabNumber=0`}
                      className="tag"
                    >
                      {`${formatData(
                        data?.message?.student?.lastName
                      )}, ${formatData(data?.message?.student?.firstName)}`}
                    </a>
                  ) : (
                    <div className="tag">
                      {`${formatData(
                        data?.message?.student?.lastName
                      )}, ${formatData(data?.message?.student?.firstName)}`}
                    </div>
                  )}
                  <div className="tag">
                    {formatData(data?.message?.class?.name)}
                  </div>
                  <div className="tag">
                    {formatData(data?.message?.convertedRecurrence)}
                  </div>
                  <div className="tag">
                    {formatData(data?.message?.classTimeInTimezone)}
                  </div>
                </div>

                <div className="certificateWrapper">
                  {data?.message?.certificateMedicals?.length ? (
                    <>
                      {data?.message?.certificateMedicals?.map(
                        (certificate: string, key: number) => {
                          return (
                            <div className="" key={key}>
                              <UploadDocument
                                key={key}
                                document={certificate || ''}
                                onRemove={() => {}}
                                onView={() => handleViewDocument(key)}
                              />
                            </div>
                          );
                        }
                      )}

                      <UploadDocumentSlider
                        open={openSlider}
                        onClose={handleCloseSlider}
                        documents={data?.message?.certificateMedicals}
                        initialSlide={initialSlide.current}
                      />
                    </>
                  ) : (
                    <div className="no_certificate">
                      No medical certificate attached
                    </div>
                  )}
                </div>
              </div>
            </>
          );
        }

        return null;
      },
      [
        __renderNotificationTime,
        handleViewDocument,
        handleCloseSlider,
        openSlider,
        checkConflictRoute
      ]
    );

    return (
      <>
        {!asPopup ? (
          <AppBreadCrumb
            items={[{ name: 'Notifications', href: '/notifications' }]}
          />
        ) : null}

        <div className={`notification ${!asPopup ? 'layoutContainer' : ''}`}>
          {loadData && !initLoad ? (
            <div className="pageLoading">
              <div className="pageLoading-main">
                <BeatLoader />
              </div>
            </div>
          ) : null}

          <div className="notification__header">
            <div className="tittle">Notifications</div>
            <div className="action">
              {asPopup ? null : (
                <>
                  <AppInputSearch
                    type="text"
                    value={search}
                    onChange={onChangeSearch}
                    placeholder="Surname, name, member id, mobile or email"
                    onClearSearch={onClearSearch}
                    startIcon={<HiMagnifyingGlass />}
                  />

                  <AppSelect
                    noFloatingLabel
                    inputSize="small"
                    label={'Filters by'}
                    options={NOTIFICATIONS_STATUS_FILTER_OPTIONS}
                    value={filter}
                    onChange={handleChangeFilter}
                  />
                </>
              )}

              <div
                className="action__text"
                style={
                  __disableMarkAsRead
                    ? { opacity: '0.8', cursor: 'default' }
                    : undefined
                }
                onClick={() => handleMarkAsRead()}
              >
                <HiCheck size={20} />
                mark as read
              </div>

              {asPopup ? null : (
                <div
                  style={{
                    cursor: 'pointer',
                    borderLeft: '1px solid var(--lineColor)',
                    paddingLeft: '8px'
                  }}
                  className="action__text"
                  onClick={() => {
                    if (closePopup) closePopup();
                    navigate('/notification-settings');
                  }}
                >
                  Setting
                </div>
              )}

              {asPopup ? (
                <div
                  style={{
                    cursor: 'pointer',
                    borderLeft: '1px solid var(--lineColor)',
                    paddingLeft: '8px'
                  }}
                  className="action__text"
                  onClick={() => {
                    if (closePopup) closePopup();
                    navigate('/notifications');
                  }}
                >
                  View all
                </div>
              ) : null}
            </div>
          </div>

          <div className="notification__body">
            {initLoad ? (
              <div className="notification__initLoad">
                <AppLoadingContainer />
              </div>
            ) : (
              <>
                {!data?.length ? (
                  <div className="body__noResult">
                    <NoNotificationSVG />

                    <div className="text">
                      No new notifications to report.{' '}
                      <p className="text"> Enjoy the calm!</p>
                    </div>
                  </div>
                ) : (
                  <>
                    {data?.map((nt, key) => (
                      <div
                        key={key}
                        className={`body__item ${
                          !nt?.isRead ? 'statusNew' : 'statusRead'
                        }`}
                        style={nt?.isRead ? { cursor: 'default' } : undefined}
                        onClick={
                          nt?.isRead
                            ? undefined
                            : () => handleMarkAsRead(nt?._id)
                        }
                      >
                        <div className="avatar">
                          <Avatar
                            size="large"
                            src={nt?.avatarUrl}
                            name={nt?.title?.split('')?.[0]}
                          />
                        </div>

                        <div className="content_wrap">
                          <div className="body_content">
                            {__renderNotificationContent(nt)}
                          </div>
                        </div>

                        {!nt?.isRead ? (
                          <div className="statusNew__dot"></div>
                        ) : (
                          <div style={{ marginLeft: 'auto' }}></div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
);

export default memo(NotificationList);
