import {
  useEffect,
  useState,
  useCallback,
  useMemo,
  ReactNode,
  memo,
  useRef
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useToast } from 'context/ToastContext';
import {
  getLocationAreaDetail,
  getLocationAreaName,
  updateLocationArea
} from 'services/locationArea.service';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import { formatData } from 'common/helpers/dataFormat.helper';
import AppInput from 'common/components/AppInput';
import { FormUpdateLocationAreaDto } from 'DTOs/locationArea.dto';
import yup from 'validators/locationArea.validator';
import { Resolver, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ILocationArea,
  ILocationAreaNameFilter
} from 'common/interfaces/locationArea.interface';
import { BeatLoader } from 'react-spinners';
import { HiOutlineBookmark, HiOutlineXCircle } from 'react-icons/hi';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import AppToggle from 'common/components/AppToggle';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppTextArea from 'common/components/AppTextArea';
import { TEMP_SITE_PATH } from 'common/constants';
import { debounce } from 'lodash';
import { objectContainObj } from 'common/helpers/object.helper';
import { useBrandLocation } from 'context/BrandLocationContext';

import './desktop.scss';

const validationSchema = yup.OBJECT({
  areaName: yup.string().required('This field is required'),
  pool: yup.string().required('This field is required'),
  active: yup.LOCATION_AREA_ACTIVE
});

const LocationAreaDetail = () => {
  const { id } = useParams();
  const toast = useToast();
  const location = useLocation();
  const { onRefetchLocation } = useBrandLocation();

  const [disabled, setDisabled] = useState(true);

  const [isCheckOnServer, setIsCheckOnServer] = useState(false);

  const abortConRef = useRef<AbortController>();

  // eslint-disable-next-line
  const checkExisting = useCallback(
    debounce(async (areaName: string) => {
      try {
        if (!id || !areaName) return;

        const filter: ILocationAreaNameFilter = {
          locationId: id,
          areaName
        };

        if (abortConRef.current) abortConRef.current.abort();
        abortConRef.current = new AbortController();

        const { data } = await getLocationAreaName(
          filter,
          abortConRef.current.signal
        );

        if (data?.data) {
          setError('areaName', {
            type: 'optionality',
            message: 'Area name is existed'
          });
        } else {
          clearErrors('areaName');
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsCheckOnServer(false);
      }
    }, 300),
    []
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ILocationArea | null>(null);

  const [isEdit, setIsEdit] = useState(false);

  const onOpenEdit = () => {
    setIsEdit(true);
  };

  const onCloseEdit = () => {
    setIsEdit(false);
  };

  const {
    register,
    setValue,
    clearErrors,
    control,
    trigger,
    reset,
    setError,
    formState: { errors }
  } = useForm<FormUpdateLocationAreaDto>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<FormUpdateLocationAreaDto>,
    defaultValues: {
      areaName: data?.areaName,
      pool: data?.pool,
      description: data?.description,
      active: !!data?.active
    }
  });

  const watchAllFields = useWatch({ control });

  useEffect(() => {
    const noErrors =
      !Object.keys(errors)?.length &&
      // use this field to re-fetch the errors fields from useForm
      !isCheckOnServer;

    if (watchAllFields?.areaName && watchAllFields?.pool && noErrors) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [errors, setDisabled, watchAllFields, isCheckOnServer]);

  const handleInitValue = useCallback(() => {
    clearErrors();

    reset({
      areaName: data?.areaName,
      pool: data?.pool,
      description: data?.description,
      active: !!data?.active
    });
  }, [reset, data, clearErrors]);

  useEffect(() => {
    if (!data?._id) return;

    handleInitValue();
  }, [data, handleInitValue]);

  useEffect(() => {
    if (isEdit) return;

    handleInitValue();
  }, [isEdit, handleInitValue]);

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index?: number
    ) => {
      if (event.target.name === 'areaName') {
        setValue('areaName', event.target.value.toUpperCase());

        trigger(event.target.name).then((res) => {
          if (!res || !event.target.value) return;

          setIsCheckOnServer(true);

          checkExisting(event.target.value.toUpperCase());
        });
      } else {
        // @ts-ignore
        setValue(event.target.name, event.target.value);

        // @ts-ignore
        trigger(event.target.name);
      }
    },
    [setValue, trigger, checkExisting]
  );

  const handleSubmit = useCallback(async () => {
    const formData = { ...watchAllFields };

    if (!id || disabled || !formData) {
      const triggerList = [
        ...(!watchAllFields?.areaName ? ['areaName'] : []),
        ...(!watchAllFields?.pool ? ['pool'] : [])
      ];

      if (triggerList?.length) {
        // @ts-ignore
        trigger(triggerList);
      }
      return;
    }

    const mapInitData = {
      areaName: data?.areaName,
      pool: data?.pool,
      description: data?.description,
      active: !!data?.active
    };

    const mapData = {
      areaName: formData?.areaName,
      pool: formData?.pool,
      description: formData?.description,
      active: !!formData?.active
    };

    if (objectContainObj(mapInitData, mapData)) {
      setDisabled(false);
      setLoading(false);
      onCloseEdit();

      return;
    }

    try {
      setLoading(true);
      setDisabled(true);

      const payload: FormUpdateLocationAreaDto = {
        areaName: formData?.areaName || '',
        pool: formData?.pool || '',
        description: formData?.description || '',
        active: !!formData?.active
      };

      await updateLocationArea(id, payload);
      await fetchData();
      onRefetchLocation()

      toast.success('Update location area successfully');
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Update location area failed'
      );
    } finally {
      setDisabled(false);
      setLoading(false);
      onCloseEdit();
    }

    // eslint-disable-next-line
  }, [disabled, watchAllFields, id, trigger, data]);

  const __renderIcons = useMemo((): ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
  }, [isEdit, handleSubmit, loading]);

  const __renderContent = useCallback((): ReactNode => {
    if (isEdit) {
      return (
        <>
          <div
            className="item_custom padY"
            style={{
              display: 'flex',
              gap: '12px',
              alignItems: 'center'
            }}
          >
            <AppToggle
              {...register('active')}
              value={!!watchAllFields?.active}
              onChange={() => {
                setValue('active', !watchAllFields?.active);
              }}
            />
            <div>Active</div>
          </div>
          <div className="item padY2">
            <AppInput
              {...register('areaName')}
              label="Area Name*"
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.areaName?.message || ''
              }}
            />
          </div>
          <div className="item padY2">
            <AppInput
              {...register('pool')}
              label="Pool*"
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.pool?.message || ''
              }}
            />
          </div>
          <div className="item_custom padY2">
            <AppTextArea
              {...register('description')}
              label="Description"
              onChange={handleChange}
              message={{
                type: 'error',
                text: errors?.description?.message || ''
              }}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div
          className="item_custom padY"
          style={{
            display: 'flex',
            gap: '12px',
            alignItems: 'center'
          }}
        >
          <AppToggle value={!!data?.active} />
          <div>Active</div>
        </div>
        <div className="item padY">
          <AppCardContentItem
            subtitle="Area Name"
            title={formatData(data?.areaName)}
          />
        </div>
        <div className="item padY">
          <AppCardContentItem subtitle="Pool" title={formatData(data?.pool)} />
        </div>
        <div
          className="item_custom"
          style={{
            pointerEvents: 'none'
          }}
        >
          <AppTextArea
            label="Description"
            value={formatData(data?.description)}
          />
        </div>
      </>
    );
  }, [isEdit, data, errors, handleChange, register, watchAllFields, setValue]);

  const fetchData = useCallback(async () => {
    try {
      if (!id) return;

      setLoading(true);

      const result = await getLocationAreaDetail(id);

      setData(result);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fetch location areas failed'
      );

      setData(null);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="locationAreaDetails">
      <AppBreadCrumb
        items={[
          {
            name: 'Areas',
            href: localStorage?.getItem(TEMP_SITE_PATH) || '/locations'
          },
          {
            name: 'Area details',
            href: location?.pathname + location?.search
          }
        ]}
      />

      <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_LOCATION_AREA}>
        <section className="areaInformation layoutContainer">
          <AppCard>
            <AppCardContent className="card-content">
              {loading ? <div className="loadData" /> : null}

              <div className="areaInformation-main-content">
                <AppCardHeader
                  title="Information"
                  suffix={
                    <PermissionWrapper
                      permission={PERMISSION.UPDATE_LOCATION_AREA}
                    >
                      {__renderIcons}
                    </PermissionWrapper>
                  }
                />

                <div className="content">{__renderContent()}</div>
              </div>
            </AppCardContent>
          </AppCard>
        </section>
      </PermissionWrapper>
    </div>
  );
};

export default memo(LocationAreaDetail);
