import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './scss/class-detail.scss';

import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import AppInputSearch from 'common/components/AppInputSearch';
import AppToggle from 'common/components/AppToggle';
import AppTable from 'common/components/AppTable';
import {
  formatData,
  formatDate,
  formatTime
} from 'common/helpers/dataFormat.helper';
import dayjs from 'dayjs';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import { HiUsers } from 'react-icons/hi2';
import {
  HiOutlineClock,
  HiOutlineLocationMarker,
  HiOutlineUser
} from 'react-icons/hi';
import { IStudent } from 'common/interfaces/student.interface';
import useDebounce from 'common/hooks/useDebounce';
import { useToast } from 'context/ToastContext';
import { IScheduleClass } from 'common/interfaces/dashboard.interface';
import {
  getStudentsByRollCall,
  rollCallCheckIn
} from 'services/rollCalls.service';
import {
  MINUTES_CHECK_IN_AFTER_CLASS,
  MINUTES_CHECK_IN_BEFORE_CLASS
} from 'common/constants/index';
import AppButton from 'common/components/AppButton';
import { GoPlus } from 'react-icons/go';
import { useStepController } from 'context/StepControllerContext';
import { FLOW_ENUM } from 'common/enums/step.enum';
import { IClassBooked } from 'common/interfaces/bookingClass.interface';
import { IAbsence } from 'common/interfaces/absence.interface';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useAuth } from 'context/AuthContext';
import { BOOKING_TYPE } from 'common/enums/classBooking.enum';

interface Props {
  content: IScheduleClass;
  level: string;
}
interface IStudentCheckIn {
  student: IStudent;
  checkin: boolean;
  _id: string;
  type: BOOKING_TYPE;
}

type StudentsInLesson = IStudent & {
  absences: IAbsence[];
  type: BOOKING_TYPE;
};

const ClassDetailComponent: React.FC<Props> = ({ content, level }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [students, setStudents] = useState<IStudentCheckIn[]>([]);
  const [loading, setLoading] = useState(false);

  const debouncedSearch = useDebounce(searchValue, 500);
  const toast = useToast();
  const { dataSet } = useStepController();
  const { hasPermission } = useAuth();

  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    if (!content._id) return;

    setLoading(true);
    if (!content.rollCall?._id) {
      setLoading(false);
      return;
    }
    try {
      const { data } = await getStudentsByRollCall(
        content.rollCall?._id,
        debouncedSearch,
        1000
      );
      setStudents(
        data.data.data.map((item: any) => {
          return {
            ...item,
            ...item.student,
            createdAt: item.createdAt
          };
        })
      );
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to get student in class'
      );

      setStudents([]);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [content._id, debouncedSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  async function onChangeSearchValue(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
  }

  const handleClearSearch = () => {
    setSearchValue('');
  };

  const allowToCheckIn = () => {
    // only allow check in before 30 minutes
    if (
      dayjs().diff(dayjs(content.startTime), 'minute') >=
        -MINUTES_CHECK_IN_BEFORE_CLASS &&
      dayjs().diff(dayjs(content.endTime), 'minute') <=
        MINUTES_CHECK_IN_AFTER_CLASS
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleCheckIn = async (studentId: string) => {
    if (!hasPermission(PERMISSION.CHECK_IN)) return;
    if (!allowToCheckIn()) {
      toast.error(
        `The check-in time is between ${formatTime(
          dayjs(content.startTime).subtract(
            MINUTES_CHECK_IN_BEFORE_CLASS,
            'minutes'
          )
        )} and ${formatTime(dayjs(content.endTime))} on ${formatDate(
          content.startTime
        )}`
      );
      return;
    }

    try {
      await rollCallCheckIn({
        studentId: studentId,
        rollCallId: content.rollCall._id
      });
    } catch (error: any) {
      toast.error(
        error.response.data.message || 'Fail to check in for this student'
      );
    } finally {
      fetchData();
    }
  };

  const renderStatus = (student: StudentsInLesson) => {
    if (student.type === BOOKING_TYPE.MAKE_UP) {
      return <div className="tag">M</div>;
    }
    if (student.absences.length > 0) {
      return <div className="tag">A</div>;
    }
    return <></>;
  };

  const columnHelper = createColumnHelper<StudentsInLesson>();

  const columns = [
    columnHelper.accessor('firstName', {
      header: () => <span>SURNAME, NAME</span>,
      cell: (info) => {
        return (
          <div className="nameOfStudent">
            {renderStatus(info.row.original)}
            {formatData(info.row.original?.lastName) +
              ', ' +
              formatData(info.row.original?.firstName)}
          </div>
        );
      }
    }),
    columnHelper.accessor('dob', {
      header: () => <span>D.O.B</span>,
      cell: (info) => formatDate(info.getValue()),
      size: 90
    }),
    columnHelper.accessor('checkin.checkIn', {
      header: () => (
        <span style={{ textAlign: 'center', display: 'block' }}>CHECK IN</span>
      ),
      cell: (info) => {
        if (info.row.original.absences.length > 0) return null;
        return (
          <div
            style={{
              textAlign: 'center'
            }}
          >
            <AppToggle
              value={info.getValue()}
              onChange={() => handleCheckIn(info.row.original?._id)}
              disabled={!hasPermission(PERMISSION.CHECK_IN)}
            />
          </div>
        );
      },
      size: 90
    })
  ];

  const handleClick = () => {
    navigate(
      `/classes/${content.class._id}?indexRoute=1&lessonId=${content._id}`
    );
  };

  const isShowAddStudent = useMemo((): boolean => {
    if (dayjs(content.class?.template?.term?.endDate).isBefore(dayjs()))
      return false;
    return !!(content?.capacity - (content?.occupied || 0) >= 1);
  }, [content]);

  useEffect(() => {
    if (isShowAddStudent) {
      if (dataSet?.flow === FLOW_ENUM.INIT) {
        dataSet.setFlow(FLOW_ENUM.CLASS_BOOKING);
      }
    }
  }, [dataSet, isShowAddStudent]);

  const handleAddStudent = useCallback(() => {
    if (
      dataSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
      (dataSet.step === 0 || dataSet.step === 1)
    ) {
      navigate(`/classes`);

      dataSet.handleClassBooking(content.class as IClassBooked);
      dataSet?.handleAddStepForward();
    }
  }, [navigate, dataSet, content]);

  return (
    <div className="class-detail">
      <div className="header">
        <div className="header-left">
          <div
            className="color"
            style={{
              backgroundColor: content?.colorCode
            }}
          />
          <div className="header-content">{`${formatData(level)} - ${formatData(
            content.class?.name
          )}`}</div>
        </div>
        <div className="header-right">
          <div className="">
            <HiUsers fontSize={20} />
          </div>
          <div className="capacity">
            {content?.occupied}/{formatData(content?.capacity)}
          </div>
        </div>
      </div>

      <div className="contact-detail">
        <div className="contact-item">
          <div className="item">
            <HiOutlineClock fontSize={24} />
          </div>
          <div className="contact-item-content">
            <p>{dayjs(content.startTime).format('dddd, MMMM D, YYYY')}</p>
            <p>
              {formatTime(content.startTime)} to {formatTime(content.endTime)}
            </p>
          </div>
        </div>
        <div className="contact-item">
          <div className="item">
            <HiOutlineLocationMarker fontSize={24} />
          </div>
          <div className="contact-item-content">
            <p>{formatData(content.area)}</p>
          </div>
        </div>
        <div className="contact-item">
          <div className="item">
            <HiOutlineUser fontSize={24} />
          </div>
          <div className="contact-item-content">
            <p>
              {formatData(
                `${content?.instructor?.lastName}, ${content?.instructor?.firstName}`
              )}
            </p>
          </div>
        </div>
      </div>

      <div className="student-list">
        <div className="header">
          <div className="header-left">
            STUDENTS
            <AppInputSearch
              type="text"
              value={searchValue}
              onChange={onChangeSearchValue}
              placeholder="Name"
              onClearSearch={handleClearSearch}
              startIcon={<HiMagnifyingGlass />}
            />
          </div>
          <PermissionWrapper permission={PERMISSION.BOOKING_CLASS}>
            <AppButton
              onClick={handleAddStudent}
              buttonSize="small"
              className="btn-add"
              style={{
                display: isShowAddStudent ? 'flex' : 'none'
              }}
            >
              <div className="plusIcon">
                <GoPlus style={{ width: '22px', height: '22px' }} />
              </div>
              <p>Student</p>
            </AppButton>
          </PermissionWrapper>
        </div>

        <div className="student-content">
          <div className="student-content-header">
            <AppTable data={students} columns={columns} loading={loading} />
          </div>
        </div>
        <div className="student-list-footer">
          <div className="text" onClick={handleClick}>
            View lesson detail
          </div>
          <img src="/icons/arrow-left-blue.svg" alt="arrow" />
        </div>
      </div>
    </div>
  );
};
const ClassDetail = React.memo(ClassDetailComponent);
export default ClassDetail;
