import React, { useEffect, useMemo, useRef, useState } from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import AppCard, {
  AppCardContent,
  AppCardHeader
} from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import AppSelect from 'common/components/AppSelect';
import { createColumnHelper } from '@tanstack/react-table';
import { formatData } from 'common/helpers/dataFormat.helper';
import {
  IStudent,
  IStudentListFilter
} from 'common/interfaces/student.interface';
import AppTable from 'common/components/AppTable';
import AppInputSearch from 'common/components/AppInputSearch';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import useDebounce from 'common/hooks/useDebounce';
import AppCheckbox from 'common/components/AppCheckbox';
import { every, uniq } from 'lodash';
import AppButton from 'common/components/AppButton';
import { GoFilter } from 'react-icons/go';
import { useBrandLocation } from 'context/BrandLocationContext';
import { findStudentList } from 'services/students.service';
import { useToast } from 'context/ToastContext';
import AppTextArea from 'common/components/AppTextArea';
import AppCKEditor from 'components/common/AppCKEditor';
import './desktop.scss';
import { useLocation, useParams } from 'react-router-dom';
import StudentFilter from '../components/StudentFilter';
import ScheduleFormModal from '../components/ScheduleFormModal';
import { IStudentFilter } from '../interface';
import { convertToUnixTime } from 'common/helpers/time.helper';
import yup from 'validators/notification.validator';
import {
  CreateNotificationDTO,
  CreateNotificationForResponsiblePersonPayloadDTO,
  CreateNotificationPayloadDTO,
  ScheduleNotification
} from 'DTOs/notification.dto';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NOTIFICATION_TYPE } from 'common/enums/notification.enum';
import {
  createNotification,
  createNotificationForRP
} from 'services/notifications.service';
import { DATE_CONSTANT, NOTIFICATION_TYPE_OPTIONS } from 'common/constants';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { plainTextToHtml, htmlToPlainText } from 'helpers/index.helper';
import { initFilterValue } from '../constants';
import { INotificationTemplate } from 'common/interfaces/notificationTemplate.interface';
import { IOption } from 'common/interfaces';
import { getNotificationTemplateList } from 'services/notificationTemplate.service';

const initNotification: CreateNotificationDTO = {
  types: NOTIFICATION_TYPE.PUSH,
  title: '',
  smsDescription: '',
  emailDescription: ''
};

interface INotificationAddProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const validationSchema = yup.OBJECT({
  types: yup.NOTIFICATION_TYPE,
  title: yup.NOTIFICATION_TITLE,
  smsDescription: yup.NOTIFICATION_SMS_MESSAGE,
  emailDescription: yup.NOTIFICATION_EMAIL_MESSAGE,
  studentIds: yup.NOTIFICATION_STUDENT_ID
});

const initScheduleForm: ScheduleNotification = {
  scheduleDate: '',
  scheduleTime: ''
};
const NotificationAdd = (props: INotificationAddProps) => {
  const { open, onClose, onSuccess } = props;

  const { selectedLocation: globalLocation } = useBrandLocation();
  const toast = useToast();
  const location = useLocation();
  const params = useParams();

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<CreateNotificationDTO>({
    resolver: yupResolver(validationSchema) as Resolver<CreateNotificationDTO>,
    defaultValues: initNotification
  });

  const __isStudentPage = useMemo(
    () => location.pathname.includes('/students/'),
    [location.pathname]
  );
  const __isRPPage = useMemo(
    () => location.pathname.includes('/responsible-person/'),
    [location.pathname]
  );

  const __RPId = useMemo(() => {
    if (__isRPPage) {
      return params.id;
    }
    return '';
  }, [params, __isRPPage]);

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const currentFilter = useRef<IStudentFilter | null>({
    ...initFilterValue,
    locationId: globalLocation?._id || ''
  });
  const [filterFieldNumber, setFilterFieldNumber] = useState<number>(0);

  const handleOpenFilter = () => {
    setShowFilter(true);
  };
  const handleCloseFilter = () => {
    setShowFilter(false);
  };
  const handleFilter = (filterValue: IStudentFilter) => {
    handleCloseFilter();
    setSelectedStudentIds([]);
    currentFilter.current = filterValue;
    fetchStudents();
  };

  const columnHelper = createColumnHelper<IStudent>();

  const columns = [
    columnHelper.accessor('_id', {
      header: () => (
        <AppCheckbox checked={__isCheckAll} onChange={handleToggleAll} />
      ),
      cell: (info) => (
        <AppCheckbox
          value={info.row.original._id}
          checked={selectedStudentIds.includes(info.row.original._id)}
          onChange={handleToggleStudent}
        />
      )
    }),
    columnHelper.accessor('firstName', {
      header: () => <span>surname, name</span>,
      cell: (info) => (
        <div>
          {formatData(info.row.original.lastName)},{' '}
          {formatData(info.getValue())}
        </div>
      )
    }),
    columnHelper.accessor('responsiblePersonInfo', {
      header: () => <span>rp name</span>,
      cell: (info) => (
        <div>
          {formatData(info.getValue()?.lastName)},{' '}
          {formatData(info.getValue()?.firstName)}
        </div>
      )
    }),
    columnHelper.accessor('responsiblePersonInfo', {
      header: () => <span>rp mobile</span>,
      cell: (info) => <div>{formatData(info.getValue()?.phoneNumber)}</div>
    }),
    columnHelper.accessor('responsiblePersonInfo', {
      header: () => <span>rp email</span>,
      cell: (info) => <div>{formatData(info.getValue()?.email)}</div>
    })
  ];

  const [loadingStudents, setLoadingStudents] = React.useState<boolean>(false);
  const [students, setStudents] = React.useState<Array<IStudent>>([]);
  const [pageTotal, setPageTotal] = React.useState<number>(0);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce<string>(search);

  const [notification, setNotification] =
    React.useState<CreateNotificationDTO>(initNotification);

  const [templateSelected, setTemplateSelected] = React.useState<string>('');
  const [notificationTemplates, setNotificationTemplates] = React.useState<
    Array<INotificationTemplate>
  >([]);

  const [selectedStudentIds, setSelectedStudentIds] = React.useState<
    Array<string>
  >([]);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const [scheduleDateTime, setScheduleDateTime] =
    useState<ScheduleNotification>(initScheduleForm);

  const __notificationTemplateOptions: Array<IOption> = React.useMemo(() => {
    return notificationTemplates.map((notificationTemplate) => {
      return {
        value: notificationTemplate._id,
        label: formatData(notificationTemplate?.title)
      };
    });
  }, [notificationTemplates]);

  const __isCheckAll = React.useMemo((): boolean => {
    if (students.length === 0) return false;
    return every(students, (student: IStudent) =>
      selectedStudentIds.includes(student._id)
    );
    // eslint-disable-next-line
  }, [selectedStudentIds, pageTotal, students]);

  const fetchNotificationTemplate = React.useCallback(async () => {
    try {
      const result = await getNotificationTemplateList(1, 100, '');
      setNotificationTemplates(result?.data?.data?.data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fail to get notification template'
      );
      setNotificationTemplates([]);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    fetchNotificationTemplate();
  }, [fetchNotificationTemplate]);

  const handleToggleStudent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelectedStudentIds = [...selectedStudentIds];
    const findIndex = selectedStudentIds.findIndex(
      (e) => e === event.target.value
    );
    if (findIndex === -1) {
      newSelectedStudentIds.push(event.target.value);
    } else {
      newSelectedStudentIds.splice(findIndex, 1);
    }
    setSelectedStudentIds(newSelectedStudentIds);
  };
  const handleToggleAll = () => {
    const tempStudentIds = students.map((student) => student._id);
    if (__isCheckAll) {
      setSelectedStudentIds((pre) => {
        return pre.filter((studentId) => !tempStudentIds.includes(studentId));
      });
    } else {
      setSelectedStudentIds((pre) => {
        return uniq([...pre, ...tempStudentIds]);
      });
    }
  };

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const handleChangeTemplate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTemplateSelected(event.target.value);
    const foundTemplate = notificationTemplates.find(
      (notificationTemplate) => notificationTemplate._id === event.target.value
    );
    if (foundTemplate) {
      setNotification({
        emailDescription: foundTemplate.message || '',
        smsDescription: foundTemplate.message || '',
        types: foundTemplate.types.toString(),
        title: foundTemplate.title
      });
      reset({
        emailDescription: foundTemplate.message || '',
        smsDescription: foundTemplate.message || '',
        types: foundTemplate.types.toString(),
        title: foundTemplate.title
      });
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.name === 'types') {
      const newTypes = event.target.value.split(',');
      const currentTypes = notification.types.split(',');
      const newTypesIncludesSMS = newTypes.includes(NOTIFICATION_TYPE.SMS);
      const currentTypesIncludesSMS = currentTypes.includes(
        NOTIFICATION_TYPE.SMS
      );
      let emailContent = notification.emailDescription;
      let smsContent = notification.smsDescription;
      if (!currentTypesIncludesSMS && newTypesIncludesSMS) {
        smsContent = htmlToPlainText(emailContent);
      }
      if (currentTypesIncludesSMS && !newTypesIncludesSMS) {
        emailContent = plainTextToHtml(smsContent);
      }
      setNotification({
        ...notification,
        [event.target.name]: event.target.value,
        emailDescription: emailContent,
        smsDescription: smsContent
      });
      setValue(event.target.name, event.target.value);
      trigger(event.target.name);
      return;
    }
    setNotification((pre) => ({
      ...pre,
      [event.target.name]: event.target.value
    }));
    // @ts-ignore
    setValue(event.target.name, event.target.value);
    // @ts-ignore
    trigger(event.target.name);
  };

  const fetchStudents = React.useCallback(async () => {
    if (__isRPPage) {
      return;
    }
    if (__isStudentPage) {
      setSelectedStudentIds(params.id ? [params.id] : []);
      return;
    }
    if (!globalLocation?._id || !currentFilter.current) return;

    try {
      setLoadingStudents(true);

      const params: IStudentListFilter = {};
      if (debouncedSearch) {
        params.keyword = debouncedSearch;
      }
      let filterFieldNumber = 0;
      if (currentFilter.current.termId) {
        params.termId = currentFilter.current.termId;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.levelId) {
        params.levelIds = currentFilter.current.levelId;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.instructorId) {
        params.instructorIds = currentFilter.current.instructorId;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.assessmentResult) {
        params.assessmentResult = currentFilter.current.assessmentResult;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.assessmentStatus) {
        params.assessmentStatus = currentFilter.current.assessmentStatus;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.paymentType) {
        params.paymentType = currentFilter.current.paymentType;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.outstandingFee) {
        params.outstandingFee = currentFilter.current.outstandingFee;
        filterFieldNumber += 1;
      }
      if (currentFilter.current.enrollmentType) {
        params.enrollmentTypes = currentFilter.current.enrollmentType;
        filterFieldNumber += 1;
      }

      if (currentFilter.current.dateField) {
        filterFieldNumber += 1;
        if (currentFilter.current.dateFrom)
          params.fromDate = convertToUnixTime(currentFilter.current.dateFrom);
        if (currentFilter.current.dateTo)
          params.toDate = convertToUnixTime(currentFilter.current.dateTo);
      }
      if (currentFilter.current.timeField) {
        filterFieldNumber += 1;
        if (currentFilter.current.timeFrom)
          params.fromTime = convertToUnixTime(
            `${DATE_CONSTANT} ${currentFilter.current.timeFrom}`
          );
        if (currentFilter.current.timeTo)
          params.toTime = convertToUnixTime(
            `${DATE_CONSTANT} ${currentFilter.current.timeTo}`
          );
      }
      if (currentFilter.current.areaId) {
        filterFieldNumber += 1;
        params.areas = currentFilter.current.areaId;
      }

      if (currentFilter.current.programType) {
        filterFieldNumber += 1;
        params.classTypes = currentFilter.current.programType;
      }

      if (currentFilter.current.locationId) {
        filterFieldNumber += 1;
        params.locationIds = currentFilter.current.locationId;
      }
      setFilterFieldNumber(filterFieldNumber);

      if (__isRPPage) {
        params.responsiblePersonId = __RPId || '';
      }
      if (Object.keys(params).length === 0) {
        return;
      }
      const { data, total } = await findStudentList(params);
      setPageTotal(total);
      setStudents(data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fetch Student List failed'
      );
    } finally {
      setLoadingStudents(false);
    }
    // eslint-disable-next-line
  }, [globalLocation, debouncedSearch, currentFilter, __isRPPage]);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  useEffect(() => {
    if (open) {
      reset(initNotification);
      setNotification(initNotification);
      setStudents([]);
      setPageTotal(0);
      setSelectedStudentIds([]);
      if (currentFilter.current) {
        currentFilter.current = {
          ...initFilterValue,
          locationId: globalLocation?._id || ''
        };
      }
      fetchStudents();
    }
    // eslint-disable-next-line
  }, [open, reset]);

  if (!open) return null;

  const handleChangeEmailMessage = (value: string) => {
    setValue('emailDescription', value);
    setNotification((pre) => ({
      ...pre,
      emailDescription: value
    }));
    trigger('emailDescription');
  };

  const handleOpenSchedule = () => {
    if (!selectedStudentIds.length && !__isRPPage) {
      toast.error('Please filter and select student');
      return;
    }
    setOpenScheduleModal(true);
  };

  const handleChangeScheduleTime = (value: ScheduleNotification) => {
    setScheduleDateTime(value);
  };

  const handleCreateNotification = async () => {
    try {
      if (!scheduleDateTime) {
        toast.error('Please select schedule date and time');
        return;
      }
      setLoading(true);
      if (__isRPPage) {
        const payload: CreateNotificationForResponsiblePersonPayloadDTO = {
          title: notification.title,
          message: notification.types.split(',').includes(NOTIFICATION_TYPE.SMS)
            ? notification.smsDescription
            : notification.emailDescription,
          scheduleDateTime: convertToUnixTime(
            `${scheduleDateTime.scheduleDate} ${scheduleDateTime.scheduleTime}`
          ),
          types: notification.types.split(','),
          responsiblePersonId: __RPId || ''
        };
        await createNotificationForRP(payload);
      } else {
        const payload: CreateNotificationPayloadDTO = {
          title: notification.title,
          message: notification.types.split(',').includes(NOTIFICATION_TYPE.SMS)
            ? notification.smsDescription
            : notification.emailDescription,
          scheduleDateTime: convertToUnixTime(
            `${scheduleDateTime.scheduleDate} ${scheduleDateTime.scheduleTime}`
          ),
          types: notification.types.split(','),
          studentIds: selectedStudentIds
        };

        await createNotification(payload);
      }
      toast.success('Notification created successfully');
      setOpenScheduleModal(false);
      onSuccess();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Create Notification failed'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="bulkManagementNotificationAddContainer">
      <PermissionWrapper permission={PERMISSION.LIST_STUDENT}>
        <StudentFilter
          showFilter={showFilter}
          onClose={handleCloseFilter}
          onFilter={handleFilter}
        />
      </PermissionWrapper>
      {openScheduleModal && (
        <ScheduleFormModal
          open={openScheduleModal}
          onClose={() => setOpenScheduleModal(false)}
          onSave={handleCreateNotification}
          scheduleDateTime={scheduleDateTime}
          onChangeScheduleDateTime={handleChangeScheduleTime}
        />
      )}
      <div
        className={`overlay ${open ? 'active' : ' '}`}
        onClick={onClose}
      ></div>
      <div className={`bulkManagementNotificationAdd ${open ? 'active' : ' '}`}>
        <div className="bulkManagementNotificationAdd__header">
          <div onClick={onClose}>
            <HiArrowLeft size={24} />
          </div>
          <p>Add Notification</p>
        </div>

        <div className="bulkManagementNotificationAdd__content">
          <AppCard>
            <div className="bulkManagementNotificationAdd__info">
              <AppCardHeader title="New Notification" />
              <AppSelect
                label="Template*"
                options={__notificationTemplateOptions}
                name="template"
                value={templateSelected}
                onChange={handleChangeTemplate}
              />
              <AppCardContent>
                <AppSelect
                  label="Type*"
                  options={NOTIFICATION_TYPE_OPTIONS}
                  {...register('types')}
                  message={{
                    type: 'error',
                    text: errors?.types?.message || ''
                  }}
                  name="types"
                  value={notification.types}
                  onChange={handleChange}
                  searchable={false}
                  multiValue
                />
              </AppCardContent>
            </div>
          </AppCard>

          {
            <AppCard>
              <div className="bulkManagementNotificationAdd__description">
                <AppCardContent>
                  <AppInput
                    label="Title*"
                    {...register('title')}
                    message={{
                      type: 'error',
                      text: errors?.title?.message || ''
                    }}
                    value={notification.title}
                    onChange={handleChange}
                  />
                  {notification.types
                    .split(',')
                    .includes(NOTIFICATION_TYPE.SMS) ? (
                    <AppTextArea
                      label="Content*"
                      {...register('smsDescription')}
                      message={{
                        type: 'error',
                        text: errors?.smsDescription?.message || ''
                      }}
                      name="smsDescription"
                      value={notification.smsDescription}
                      onChange={handleChange}
                    />
                  ) : (
                    <div className="bulkManagementNotificationAdd__description--email">
                      <p className="bulkManagementNotificationAdd__description--email--label">
                        Content*
                      </p>
                      <AppCKEditor
                        value={notification.emailDescription}
                        onChange={handleChangeEmailMessage}
                      />
                      {errors?.emailDescription?.message && (
                        <p className="message--error">
                          {errors?.emailDescription?.message}
                        </p>
                      )}
                      {/* {errors?.emailDescription?.message && (
                  <p className="bulkManagementNotificationAdd__description--email--error">
                    {errors?.emailDescription?.message}
                  </p>
                )} */}
                    </div>
                  )}
                </AppCardContent>
              </div>
            </AppCard>
          }

          {!__isStudentPage && !__isRPPage && (
            <AppCard>
              <div className="bulkManagementNotificationAdd__table">
                <div className="bulkManagementNotificationAdd__table__header">
                  <h2 className="bulkManagementNotificationAdd__table__header--title">
                    SEND TO {selectedStudentIds.length}/{pageTotal} STUDENT
                    {pageTotal > 1 ? 'S' : ''}
                  </h2>
                  <PermissionWrapper permission={PERMISSION.LIST_STUDENT}>
                    <div className="bulkManagementNotificationAdd__table__header--actions">
                      <AppInputSearch
                        type="text"
                        value={search}
                        onChange={onChangeSearch}
                        placeholder="Surname, name"
                        onClearSearch={onClearSearch}
                        startIcon={<HiMagnifyingGlass />}
                      />
                      <AppButton
                        variant="secondary"
                        buttonSize="small"
                        onClick={handleOpenFilter}
                      >
                        <GoFilter size={22} />
                        Filters{' '}
                        {filterFieldNumber > 0 && (
                          <div className="filter-field-number">
                            {filterFieldNumber}
                          </div>
                        )}
                      </AppButton>
                    </div>
                  </PermissionWrapper>
                </div>
                <div className="bulkManagementNotificationAdd__table__content">
                  <AppTable
                    data={students}
                    columns={columns}
                    loading={loadingStudents}
                  />
                </div>
              </div>
            </AppCard>
          )}
        </div>

        <div className="bulkManagementNotificationAdd__actions">
          <AppButton
            variant="primary"
            buttonSize="large"
            isLoading={loading}
            onClick={handleSubmit(handleOpenSchedule)}
            disabled={loading}
          >
            Schedule
          </AppButton>
          <AppButton variant="secondary" buttonSize="large" onClick={onClose}>
            Cancel
          </AppButton>
        </div>
      </div>
    </section>
  );
};

export default NotificationAdd;
