import { UpdateStaffPersonalInformationDTO } from 'DTOs/staff.dto';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppDatePicker from 'common/components/AppDatePicker';
import AppInput from 'common/components/AppInput';
import AppSelect from 'common/components/AppSelect';
import AppToggle from 'common/components/AppToggle';
import dayjs from 'dayjs';
import { STATUS_TYPE } from 'common/enums/staff.enum';
import { IStaff } from 'common/interfaces/staff.interface';
import yupStaff from 'validators/staff.validator';
import React, { useCallback, useMemo, useState } from 'react';
import {
  HiOutlinePencilSquare,
  HiOutlineXCircle,
  HiOutlineBookmark
} from 'react-icons/hi2';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GENDER_TYPE_OPTIONS } from 'common/constants/index';
import { updateStaffPersonalInformation } from 'services/staff.service';
import { useParams } from 'react-router-dom';
import { useToast } from 'context/ToastContext';
import { BeatLoader } from 'react-spinners';
import { convertToUnixTime } from 'common/helpers/time.helper';
import UploadAvatar, {
  ERROR_MESSAGE_LIMIT_SIZE
} from 'components/UploadAvatar';
import { uploadFile } from 'services/uploadFile.service';
import { FILE_ASSET_TYPE } from 'common/enums/uploadFile.enum';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';

const validationSchema = yupStaff.OBJECT({
  status: yupStaff.STAFF_STATUS,
  firstName: yupStaff.STAFF_NAME,
  lastName: yupStaff.STAFF_SURNAME,
  aliasName: yupStaff.STAFF_ALIAS,
  dob: yupStaff.STAFF_DOB,
  gender: yupStaff.STAFF_GENDER
});

interface IPersonalInformationProps {
  data: IStaff;
  onSuccess: () => void;
}

const PersonalInformation = (props: IPersonalInformationProps) => {
  const { data, onSuccess } = props;

  const params = useParams();
  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  const [staff, setStaff] = useState<UpdateStaffPersonalInformationDTO>({
    ...data
  });

  const [file, setFile] = useState<File | null>(null);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<UpdateStaffPersonalInformationDTO>({
    resolver: yupResolver(
      validationSchema
    ) as Resolver<UpdateStaffPersonalInformationDTO>,
    defaultValues: staff
  });

  const onOpenEdit = () => {
    setIsEdit(true);
  };

  const onCloseEdit = () => {
    setIsEdit(false);
    setStaff({ ...data });
    reset({ ...data });
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStaff({
        ...staff,
        [event.target.name]: event.target.value
      });
      // @ts-ignore
      setValue(event.target.name, event.target.value);
      // @ts-ignore
      trigger(event.target.name);
    },
    [staff, setValue, trigger]
  );

  const handleChangeDOB = useCallback(
    (value: dayjs.Dayjs | null) => {
      setStaff({
        ...staff,
        dob: value?.format('YYYY-MM-DD') || ''
      });
      setValue('dob', value?.format('YYYY-MM-DD') || '');
      trigger('dob');
    },
    [staff, setValue, trigger]
  );

  const handleChangeStatus = useCallback(() => {
    const newStatus =
      staff.status === STATUS_TYPE.ACTIVE
        ? STATUS_TYPE.INACTIVE
        : STATUS_TYPE.ACTIVE;
    setStaff({
      ...staff,
      status: newStatus
    });
    setValue('status', newStatus);
  }, [staff, setValue]);

  const onSubmit = async (data: UpdateStaffPersonalInformationDTO) => {
    setLoading(true);

    let newAvatarUrl = '';
    if (file) {
      const resultAvatar = await uploadFile(FILE_ASSET_TYPE.AVATAR, file);
      newAvatarUrl = resultAvatar.data.data || '';
    }

    try {
      const payload: UpdateStaffPersonalInformationDTO = {
        status: data.status,
        firstName: data.firstName,
        lastName: data.lastName,
        aliasName: data.aliasName,
        dob: convertToUnixTime(data.dob.toString()),
        gender: data.gender,
        avatarUrl: file && newAvatarUrl ? newAvatarUrl : data.avatarUrl
      };
      await updateStaffPersonalInformation(params?.id || '', payload);
      onSuccess();
      toast.success('Saved');
      setIsEdit(false);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'Failed to update staff personal information'
      );
    } finally {
      setLoading(false);
    }
  };

  const __renderIcons = useMemo((): React.ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSubmit)}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
    // eslint-disable-next-line
  }, [isEdit, onCloseEdit, loading]);

  const __renderContent = useMemo((): React.ReactNode => {
    if (isEdit) {
      return (
        <>
          <div className="customToggle customToggle-edit">
            <AppToggle
              value={staff.status === STATUS_TYPE.ACTIVE}
              onChange={handleChangeStatus}
            />
            <p>Active</p>
          </div>
          <AppInput
            label="Name*"
            {...register('firstName')}
            value={staff.firstName}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.firstName?.message || ''
            }}
          />
          <AppInput
            label="Surname*"
            {...register('lastName')}
            value={staff.lastName}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.lastName?.message || ''
            }}
          />
          <AppInput
            label="Alias"
            {...register('aliasName')}
            value={staff.aliasName}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.aliasName?.message || ''
            }}
          />

          <AppDatePicker
            label="DOB*"
            {...register('dob')}
            value={dayjs(staff.dob)}
            onChange={handleChangeDOB}
            message={{
              type: 'error',
              text: errors?.dob?.message || ''
            }}
            disableFuture
          />
          <AppSelect
            label="Gender*"
            options={GENDER_TYPE_OPTIONS}
            {...register('gender')}
            value={staff.gender}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.gender?.message || ''
            }}
          />
        </>
      );
    }
    return (
      <>
        <div className="customToggle">
          <AppToggle value={staff.status === STATUS_TYPE.ACTIVE} disabled />
          <p>Active</p>
        </div>
        <AppCardContentItem
          subtitle="Name"
          title={formatData(staff?.firstName)}
        />
        <AppCardContentItem
          subtitle="Surname"
          title={formatData(staff?.lastName)}
        />
        <AppCardContentItem
          subtitle="Alias"
          title={formatData(staff?.aliasName)}
        />
        <AppCardContentItem subtitle="DOB" title={formatDate(staff?.dob)} />
        <AppCardContentItem
          subtitle="Gender"
          title={formatData(staff?.gender)}
        />
      </>
    );
  }, [
    isEdit,
    handleChange,
    handleChangeDOB,
    handleChangeStatus,
    register,
    errors,
    staff
  ]);

  return (
    <section className="personalInformation">
      <AppCard>
        <div className="personalInformation-content">
          <div className="personalInformation-content-left">
            <div
              style={{
                pointerEvents: isEdit ? 'all' : 'none'
              }}
            >
              <UploadAvatar
                defaultImage={staff.avatarUrl}
                file={file}
                onChangeFile={(file) => setFile(file)}
                onErrorFile={(errorMessage: string) => {
                  if (errorMessage === ERROR_MESSAGE_LIMIT_SIZE) {
                    toast.error('Exceed the MB');
                  }
                }}
              />
            </div>
          </div>
          <div className="personalInformation-content-right">
            <AppCardHeader
              title="PERSONAL INFORMATION"
              suffix={
                <PermissionWrapper
                  permission={[
                    PERMISSION.UPDATE_STAFF,
                    PERMISSION.UPDATE_STAFF_PERSONAL_INFO
                  ]}
                >
                  {__renderIcons}
                </PermissionWrapper>
              }
            />
            <AppCardContent
              className={`cardContent ${isEdit ? 'cardContent-edit' : ''}`}
            >
              {__renderContent}
            </AppCardContent>
          </div>
        </div>
      </AppCard>
    </section>
  );
};

export default PersonalInformation;
