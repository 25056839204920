import { yupResolver } from '@hookform/resolvers/yup';
import AppButton from 'common/components/AppButton';
import AppCard, {
  AppCardContent,
  AppCardHeader
} from 'common/components/AppCard';
import AppSelect from 'common/components/AppSelect';
import { STAFF_TYPE_OPTIONS } from 'common/constants/index';
import { useToast } from 'context/ToastContext';
import React, { useEffect, useMemo, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import yupRole from 'validators/role.validator';
import { HiArrowLeft } from 'react-icons/hi';
import AppInput from 'common/components/AppInput';
import { STAFF_TYPE } from 'common/enums/user.enum';
import { CreateRoleDto } from 'DTOs/role.dto';
import { addRole, getPermissionList } from 'services/roles.service';
import { IPermission } from 'common/interfaces/hierarchy.interface';
import AppCheckbox from 'common/components/AppCheckbox';
import { uniq } from 'lodash';
import './desktop.scss';

const initUserGroup: CreateRoleDto = {
  name: '',
  staffType: STAFF_TYPE.IDLE,
  permissions: []
};

const validationSchema = yupRole.OBJECT({
  name: yupRole.ROLE_NAME,
  staffType: yupRole.ROLE_STAFF_TYPE,
  permissions: yupRole.ROLE_PERMISSIONS
});

interface IUserGroupAddForm {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const UserGroupAddForm = (props: IUserGroupAddForm) => {
  const { open, onClose, onSuccess } = props;

  const toast = useToast();

  const [userGroup, setUserGroup] = useState<CreateRoleDto>(initUserGroup);

  const [permissions, setPermissions] = useState<Array<IPermission>>([]);

  const [permissionTypes, setPermissionTypes] = useState<Array<string>>([]);
  const [selectedPermissionType, setSelectedPermissionType] = useState<string>(
    ''
  );
  const [selectedPermissions, setSelectedPermissions] = useState<Array<string>>(
    []
  );

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors }
  } = useForm<CreateRoleDto>({
    resolver: yupResolver(validationSchema) as Resolver<CreateRoleDto>,
    defaultValues: userGroup
  });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUserGroup({
      ...userGroup,
      [event.target.name]: event.target.value
    });
    // @ts-ignore
    setValue(event.target.name, event.target.value);
    // @ts-ignore
    trigger(event.target.name);
  };

  const handleChangePermissionType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedPermissionType(event.target.value);
  };

  const __isCheckAllByType = useMemo((): boolean => {
    // Permissions are displayed when user select type.
    let displayedPermissions: Array<string> = [];
    if (selectedPermissionType === '' || selectedPermissionType === 'all') {
      displayedPermissions = permissions.map((permission) => permission.slug);
    } else {
      displayedPermissions = permissions
        .filter((permission) => permission.type === selectedPermissionType)
        .map((permission) => permission.slug);
    }
    return displayedPermissions.every((permission) =>
      selectedPermissions.includes(permission)
    );
  }, [selectedPermissionType, permissions, selectedPermissions]);

  const handleSelectAllPermissionByType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // Permissions are displayed when user select type.
    let displayedPermissions: Array<string> = [];
    if (event.target.value === '' || event.target.value === 'all') {
      displayedPermissions = permissions.map((permission) => permission.slug);
    } else {
      displayedPermissions = permissions
        .filter((permission) => permission.type === selectedPermissionType)
        .map((permission) => permission.slug);
    }
    // -- Check is selected all by type --
    // Yes => Unselect all by type
    // No => Select all by type
    const isSelectedAll: boolean = displayedPermissions.every((permission) =>
      selectedPermissions.includes(permission)
    );
    // CASE ALL
    if (event.target.value === '' || event.target.value === 'all') {
      if (!isSelectedAll) {
        setSelectedPermissions(displayedPermissions);
      } else {
        setSelectedPermissions([]);
      }
    }
    // CASE TYPE
    else {
      if (!isSelectedAll) {
        setSelectedPermissions(
          uniq([...selectedPermissions, ...displayedPermissions])
        );
      } else {
        setSelectedPermissions(
          selectedPermissions.filter(
            (selectedPermission) =>
              !displayedPermissions.includes(selectedPermission)
          )
        );
      }
    }
  };

  const handleTogglePermission = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newSelectedPermissions = [...selectedPermissions];
    const findIndex = selectedPermissions.findIndex(
      (e) => e === event.target.value
    );
    if (findIndex === -1) {
      newSelectedPermissions.push(event.target.value);
    } else {
      newSelectedPermissions.splice(findIndex, 1);
    }
    setSelectedPermissions(newSelectedPermissions);
  };

  const onSubmit = (data: CreateRoleDto) => {
    setLoading(true);
    addRole({
      ...data,
      permissions: selectedPermissions
    })
      .then(() => {
        setUserGroup({ ...initUserGroup });
        setSelectedPermissionType('');
        setSelectedPermissions([]);
        reset(initUserGroup);
        toast.success('Create user group successfully');
        onSuccess();
      })
      .catch((error: any) => {
        toast.error(
          error?.response?.data?.message || 'Failed to create user group'
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPermissionList()
      .then((result) => {
        setPermissions(result);
        const tempPermissionTypes: Array<string> = ['all'];
        for (const iterator of result) {
          if (!tempPermissionTypes.includes(iterator.type)) {
            tempPermissionTypes.push(iterator.type);
          }
        }
        setPermissionTypes(tempPermissionTypes);
      })
      .catch(() => {
        setPermissions([]);
      });
  }, []);

  return (
    <div className="userGroupAddContainer">
      <div
        className={`overlay ${open ? 'active' : ' '}`}
        onClick={onClose}
      ></div>
      <div className={`userGroupAddForm ${open ? 'active' : ' '}`}>
        <div className="userGroupAddForm__header">
          <div onClick={onClose}>
            <HiArrowLeft />
          </div>
          <p>Add User Group</p>
        </div>
        <div className="userGroupAddForm__content">
          <AppCard>
            <div className="userGroupAddForm__content-wrapper">
              <AppCardHeader title="User Group" />
              <AppCardContent className="userGroupAddForm__content-fields">
                <AppInput
                  label="Name*"
                  {...register('name')}
                  value={userGroup.name}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.name?.message || ''
                  }}
                />
                <AppSelect
                  label="Staff Type*"
                  options={STAFF_TYPE_OPTIONS}
                  {...register('staffType')}
                  value={userGroup.staffType}
                  onChange={handleChange}
                  message={{
                    type: 'error',
                    text: errors?.staffType?.message || ''
                  }}
                />
              </AppCardContent>
            </div>
          </AppCard>
          <AppCard className="userGroupAddForm__content-permissions">
            <AppSelect
              label="Permission Types"
              options={permissionTypes.map((permissionType) => {
                return {
                  label: permissionType?.replaceAll('-', ' '),
                  value: permissionType
                };
              })}
              value={selectedPermissionType}
              onChange={handleChangePermissionType}
            />
            <div className="userGroupAddForm__content-permissions-all">
              <AppCheckbox
                label={`All ${
                  selectedPermissionType === '' ||
                  selectedPermissionType === 'all'
                    ? ''
                    : selectedPermissionType?.replaceAll('-', ' ')
                } permissions`}
                value={selectedPermissionType}
                checked={__isCheckAllByType}
                onChange={handleSelectAllPermissionByType}
              />
            </div>
            <div className="userGroupAddForm__content-permissions-list">
              {!!permissions &&
                permissions.map((permission) => {
                  return permission.type === selectedPermissionType ||
                    selectedPermissionType === '' ||
                    selectedPermissionType === 'all' ? (
                    <div
                      key={permission.slug}
                      className="userGroupAddForm__content-permissions-item"
                    >
                      <AppCheckbox
                        label={permission.name.replaceAll('-', ' ')}
                        value={permission.slug}
                        checked={selectedPermissions.includes(permission.slug)}
                        onChange={handleTogglePermission}
                      />
                    </div>
                  ) : null;
                })}
            </div>
          </AppCard>
        </div>
        <div className="userGroupAddForm__actions">
          <AppButton
            onClick={handleSubmit(onSubmit)}
            type="submit"
            isLoading={loading}
          >
            Save
          </AppButton>
          <AppButton variant="secondary" onClick={onClose}>
            Cancel
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default UserGroupAddForm;
