import { forwardRef, useImperativeHandle, useMemo } from 'react';

import './desktop.scss';

export interface RefHandle {
  refetchReset: () => void;
}

type AvatarSize = 'medium' | 'large' | 'small';

interface Props {
  src?: string;
  size?: AvatarSize;
  name?: string;
}

const Avatar = forwardRef<RefHandle, Props>(
  ({ src = '', size = 'medium', name = '' }, ref) => {
    useImperativeHandle(ref, () => ({
      refetchReset() {}
    }));

    const __renderImage = useMemo(() => {
      if (!src && name) {
        return <p className="custom_avatar-name">{name?.charAt(0)}</p>;
      } else {
        return <img src={src} alt={name || 'avatar'} />;
      }
    }, [name, src]);

    return (
      <div className={`custom_avatar custom_avatar-size-${size}`}>
        {__renderImage}
      </div>
    );
  }
);

export default Avatar;
