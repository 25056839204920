import { WARNING_MOVING_TYPE } from 'common/enums/moving.enum';
import React from 'react';

interface IClassEnrolmentMovingWarningProps {
  warningMovingType: WARNING_MOVING_TYPE;
}

const MovingEnrolmentWarning = (
  props: IClassEnrolmentMovingWarningProps
) => {
  const { warningMovingType } = props;

  if (warningMovingType === WARNING_MOVING_TYPE.IDLE) return null;

  return (
    <React.Fragment>
      {warningMovingType ===
        WARNING_MOVING_TYPE.LESSON_HAS_HAPPENED_MOVE_THIS_WEEK_PLUS_DD && (
        <div className="warning-wrapper">
          <ul>
            <li>The student has already attended this week's lesson.</li>
            <li>
              If you want to Move the enrolment to your selected day, you'll
              need to cover the additional cost for the extra lesson in the week
              which will be added to your DD.
            </li>
            <li>
              If you prefer not to pay for an additional lesson, please select a
              day from next week.
            </li>
          </ul>
        </div>
      )}
      {warningMovingType ===
        WARNING_MOVING_TYPE.LESSON_HAS_HAPPENED_MOVE_THIS_WEEK && (
        <div className="warning-wrapper">
          <ul>
            <li>The student has already attended this week's lesson.</li>
            <li>
              If you want to Move the enrolment to your selected day, you'll
              need to cover the additional cost for the extra lesson in the
              week.
            </li>
            <li>
              If you prefer not to pay for an additional lesson, please select a
              day from next week.
            </li>
          </ul>
        </div>
      )}

      {warningMovingType ===
        WARNING_MOVING_TYPE.LESSON_NOT_HAPPENED_MOVE_NEXT_WEEK && (
        <div className="warning-wrapper">
          <ul>
            <li>
              The student remains enrolled in this week's lesson as per the
              current enrolment, and there will be no changes to that.
            </li>
            <li>
              Any modifications to the enrollment will take effect from next
              week.
            </li>
          </ul>
        </div>
      )}

      {warningMovingType ===
        WARNING_MOVING_TYPE.LESSON_NOT_HAPPENED_MOVE_THIS_WEEK && (
        <div className="warning-wrapper">
          <ul>
            <li>
              Since the student hasn't attended this week's lesson, we can
              proceed with moving the enrollment to your chosen date.
            </li>
            <li>
              Please note that by moving the enrollment, the lesson originally
              scheduled for the original day will be rescheduled to the new day.
            </li>
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};

export default React.memo(MovingEnrolmentWarning);
