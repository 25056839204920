import { FC } from 'react';

import './index.scss';

const AppComingSoon: FC = () => {
  return (
    <div className="c__comingSoon">
      <img src="/images/coming_soon_logo.svg" alt="coming soon logo" />
      <div className="sub_text">Site under reconstructor</div>
      <div className="text">Coming soon</div>
    </div>
  );
};

export default AppComingSoon;
