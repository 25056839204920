import { IBookingData, ISchedule } from '../interfaces/schedules.interface';
import { forEach, map, sortBy } from 'lodash';
import dayjs from 'dayjs';
import { BOOKING_TYPE, PAYMENT_VALUE } from '../enums/classBooking.enum';
import { CLASS_TYPES, RECURRENCE_VALUES } from '../../common/enums/class.enum';
import { ITerm } from '../interfaces/term.interface';
import { TERM_TYPE } from '../enums/term.enum';
import { formatDate, formatTime, roundByTwo } from './dataFormat.helper';
import { FORMAT_END_OF_DATE } from '../../common/constants';

export const getDaysArray = (start: Date, end: Date) => {
  const daysArray: Date[] = [];
  let currentDate = new Date(start);

  while (currentDate <= new Date(end)) {
    daysArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  currentDate.setDate(currentDate.getDate() + 1);

  return daysArray;
};

export const handleGetPriceDirectDebit = (
  stopBillingTerms: ITerm[],
  item: IBookingData,
  nextDebitDay?: string,
  previousDebitDay?: string,
  getForNearestDebitDay?: boolean,
  getForNextDebitDay?: boolean
): number => {
  const sortedTerms = sortBy(
    item.classInfo.template.terms,
    'termDetail.startDate'
  );
  if (item?.schedules?.length === 0) return 0;
  const termIdOfLesson = item.schedules?.[0]?.termId;
  const currentTermIndex = sortedTerms.findIndex(
    (term) => term.termId === termIdOfLesson
  );
  const currentTerm = sortedTerms.find(
    (term) => term.termId === termIdOfLesson
  );
  const endDateOfTerm = dayjs(currentTerm?.termDetail?.endDate).format(
    'YYYY-MM-DD'
  );

  const endDateOfClass =
    item.classInfo.recurrence.type === RECURRENCE_VALUES.DO_NOT_REPEAT
      ? dayjs(item.schedules[0].startTime).format('YYYY-MM-DD')
      : dayjs(endDateOfTerm).format('YYYY-MM-DD');
  let endDate = endDateOfClass;

  const startTime = dayjs(item.schedules[0].startTime).format('HH:mm');

  if (nextDebitDay) {
    if (dayjs(nextDebitDay).isBefore(dayjs(`${endDateOfClass} ${startTime}`))) {
      endDate = dayjs(nextDebitDay).format(`YYYY-MM-DD 23:59:59`);
    } else {
      const nextTerm = sortedTerms[currentTermIndex + 1];
      if (
        nextTerm &&
        !dayjs(nextTerm.termDetail?.startDate).isAfter(dayjs(nextDebitDay))
      ) {
        endDate = dayjs(nextDebitDay)
          .subtract(1, 'minute')
          .format(`YYYY-MM-DD 23:59:59`);
      } else {
        endDate = dayjs(endDateOfClass).format(`YYYY-MM-DD 23:59:59`);
      }
    }
  } else {
    endDate = dayjs(endDateOfClass).format(`YYYY-MM-DD 23:59:59`);
  }

  let startDateToCalculate = new Date(item.schedules[0].startTime);

  if (previousDebitDay) {
    startDateToCalculate = new Date(
      dayjs(previousDebitDay).format(`YYYY-MM-DD HH:mm`)
    );
  } else {
    if (getForNearestDebitDay && !!item.schedulesUntilNextDD?.length) {
      const matchSchedules = item.schedulesUntilNextDD?.filter(
        (schedule: ISchedule) =>
          dayjs(schedule.startTime).format('ddd') ===
          dayjs(item.schedules[0].startTime).format('ddd')
      );
      return roundByTwo(matchSchedules?.length * item.classInfo.price);
    }

    if (getForNextDebitDay && !!item.schedulesUntilNextDD2?.length) {
      const matchSchedules = item.schedulesUntilNextDD2?.filter(
        (schedule: ISchedule) =>
          dayjs(schedule.startTime).format('ddd') ===
          dayjs(item.schedules[0].startTime).format('ddd')
      );
      return roundByTwo(matchSchedules?.length * item.classInfo.price);
    }
  }
  const recurrenceClassOnGoing = item.schedules
    .filter((item: ISchedule) => item.checked)
    .map((item: ISchedule) => new Date(item.startTime).getDay());

  if (
    dayjs(startDateToCalculate).isBefore(dayjs(item.schedules[0].startTime)) &&
    dayjs(endDate).isBefore(dayjs(item.schedules[0].startTime))
  ) {
    return 0;
  }

  if (
    dayjs(startDateToCalculate).isBefore(dayjs(item.schedules[0].startTime))
  ) {
    startDateToCalculate = dayjs(item.schedules[0].startTime).toDate();
  }

  const listDateBetweenStartAndEnd = getDaysArray(
    // get the nearest day between the date student start class and the date class start
    new Date(startDateToCalculate),
    new Date(endDate)
  );
  if (
    nextDebitDay &&
    dayjs(nextDebitDay).format('ddd') ===
      dayjs(item.schedules[0].startTime).format('ddd')
  ) {
    if (
      dayjs(nextDebitDay).format('HH:mm') >=
      dayjs(item.schedules[0].startTime).format('HH:mm')
    ) {
      listDateBetweenStartAndEnd.splice(0, 1);
    } else {
      listDateBetweenStartAndEnd.splice(
        listDateBetweenStartAndEnd.length - 1,
        1
      );
    }
  }

  let noStudyDays: string[] = [];
  let stopBillingDays: string[] = [];

  const newStopBillingTerms = stopBillingTerms.filter(
    (term) => term.type === TERM_TYPE.STOP_BILLING
  );

  forEach(newStopBillingTerms, (term) => {
    if (
      dayjs(term.endDate).isAfter(dayjs(item.schedules[0].startTime)) &&
      dayjs(term.startDate).isBefore(dayjs(endDate))
    ) {
      const listDateOfTerm = getDaysArray(
        new Date(term.startDate),
        new Date(term.endDate)
      ).map((item) => dayjs(item).format('YYYY-MM-DD'));
      noStudyDays.push(...listDateOfTerm);
      stopBillingDays.push(...listDateOfTerm);
    }
  });
  if (item.classInfo.type !== CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM) {
    const holidayTerms = stopBillingTerms.filter(
      (term) => term.type === TERM_TYPE.PUBLIC_HOLIDAY
    );

    forEach(holidayTerms, (term) => {
      if (
        dayjs(term.endDate).isAfter(dayjs(item.schedules[0].startTime)) &&
        dayjs(term.startDate).isBefore(dayjs(endDateOfTerm))
      ) {
        const listDateOfTerm = getDaysArray(
          new Date(term.startDate),
          new Date(term.endDate)
        ).map((item) => dayjs(item).format('YYYY-MM-DD'));

        map(listDateOfTerm, (date) => {
          if (!noStudyDays.includes(date)) {
            noStudyDays.push(date);
          }
        });
      }
    });
  }

  const listDateOfClass = listDateBetweenStartAndEnd
    .map((item) => dayjs(item).format('YYYY-MM-DD'))
    .filter((item) => !noStudyDays.includes(item))
    .map((day) => new Date(day).getDay());

  const { ONGOING_CLASS } = BOOKING_TYPE;

  if (item.enrollmentType !== ONGOING_CLASS) {
    const checkedSession = item?.schedules.filter(
      (item: ISchedule) => item.checked
    );

    const listDateOfClass = checkedSession
      .map((item) => dayjs(item.startTime).format('YYYY-MM-DD'))
      .filter((item) => !noStudyDays.includes(item));
    return roundByTwo(item?.classInfo?.price * listDateOfClass.length);
  } else {
    const totalSessionStudentHaveLeft = listDateOfClass.filter((item) =>
      recurrenceClassOnGoing.includes(item)
    ).length;

    return roundByTwo(item?.classInfo?.price * totalSessionStudentHaveLeft);
  }
};

export const getFirstLastLessonBookingItem = (
  bookingItem: IBookingData,
  activeTermSchedules?: ISchedule[]
): string => {
  if (!bookingItem.classInfo) return '';
  const firstLesson: string = formatDate(
    bookingItem?.schedules?.[0]?.startTime,
    'slash'
  );
  let lastLesson: string = '';
  if (
    bookingItem.classInfo?.type === CLASS_TYPES.SERIES ||
    bookingItem.classInfo?.type === CLASS_TYPES.PRIVATE
  ) {
    if (bookingItem.paymentOption === PAYMENT_VALUE.DIRECT_DEBIT) {
      lastLesson = 'ongoing';
    } else {
      if (bookingItem.enrollmentType === BOOKING_TYPE.CASUAL_CLASS) {
        const ascScheduleBookings = [...(bookingItem.schedules || [])];
        lastLesson = formatDate(
          ascScheduleBookings[ascScheduleBookings.length - 1 || 0].startTime,
          'slash'
        );
      } else if (bookingItem.paymentOption !== PAYMENT_VALUE.IDLE) {
        const { endDate } =
          bookingItem.classInfo?.template?.terms.find(
            (item) => item.termId === bookingItem?.schedules?.[0]?.termId
          )?.termDetail || {};
        if (
          activeTermSchedules &&
          activeTermSchedules?.length &&
          activeTermSchedules?.[0]?.termId ===
            bookingItem?.schedules?.[0]?.termId
        ) {
          const firstLesson = bookingItem?.schedules?.[0]?.startTime;
          let index = activeTermSchedules.length - 1;
          let lastSchedule =
            activeTermSchedules[activeTermSchedules.length - 1];
          while (
            index > 0 &&
            dayjs(firstLesson).format('dddd') !==
              dayjs(lastSchedule?.startTime).format('dddd')
          ) {
            lastSchedule = activeTermSchedules[index];
            index--;
          }
          lastLesson = formatDate(lastSchedule?.startTime, 'slash');
        } else {
          lastLesson = dayjs(endDate).format(FORMAT_END_OF_DATE);
          while (
            dayjs(lastLesson).format('YYYY-MM-DD') >=
              dayjs(bookingItem?.schedules?.[0]?.startTime).format(
                'YYYY-MM-DD'
              ) &&
            dayjs(bookingItem?.schedules?.[0]?.startTime).format('dddd') !==
              dayjs(lastLesson).format('dddd')
          ) {
            lastLesson = dayjs(lastLesson)
              .subtract(1, 'days')
              .format(FORMAT_END_OF_DATE);
          }
          lastLesson = formatDate(lastLesson, 'slash');
        }
      }
    }
  } else if (bookingItem.classInfo?.type === CLASS_TYPES.ASSESSMENT_TRIAL) {
    lastLesson = '';
  } else {
    lastLesson = formatDate(
      bookingItem.schedules[bookingItem.schedules.length - 1]?.startTime,
      'slash'
    );
  }
  return `${firstLesson}${lastLesson ? ` - ${lastLesson}` : ''}`;
};
export const handleDateTimeRecord = (bookingData: IBookingData) => {
  if (
    bookingData.classInfo.recurrence.type === RECURRENCE_VALUES.DO_NOT_REPEAT ||
    bookingData.enrollmentType === BOOKING_TYPE.CASUAL_CLASS ||
    CLASS_TYPES.ASSESSMENT_TRIAL === bookingData.classInfo.type
  ) {
    return `${bookingData.schedules
      .map((bookingData) => dayjs(bookingData.startTime).format('ddd - MMM D'))
      .join(', ')} at ${dayjs(bookingData.schedules[0].startTime).format(
      'HH:mm'
    )}`;
  }
  if (bookingData.enrollmentType === BOOKING_TYPE.HOLIDAY_PROGRAM) {
    return `${bookingData.schedules
      .map((bookingData) => dayjs(bookingData.startTime).format('ddd'))
      .join(', ')} at ${dayjs(bookingData.schedules[0].startTime).format(
      'HH:mm'
    )} - ${formatTime(bookingData.schedules[0]?.endTime)}`;
  }
  const checkedSchedules =
    bookingData.schedules.filter((bookingData) => bookingData.checked) || [];
  let isDaily: boolean = false;
  if (checkedSchedules.length > 7) {
    isDaily =
      dayjs(checkedSchedules[6].startTime).diff(
        checkedSchedules[0].startTime,
        'days'
      ) === 6;
    if (!isDaily) {
      const firstDate = dayjs(checkedSchedules[0].startTime).format('ddd');
      let index = 1;
      let dates = [firstDate];
      while (
        checkedSchedules[index] &&
        dayjs(checkedSchedules[index].startTime).format('ddd') !== firstDate
      ) {
        dates.push(dayjs(checkedSchedules[index].startTime).format('ddd'));
        index++;
      }
      return `Weekly on ${dates.join(', ')} at ${dayjs(
        bookingData.schedules[0].startTime
      ).format('HH:mm')} from ${dayjs(checkedSchedules[0].startTime).format(
        'DD/MM'
      )} to ${dayjs(
        checkedSchedules[checkedSchedules.length - 1].startTime
      ).format('DD/MM')}`;
    }
  }
  if (checkedSchedules.length === 7 || isDaily) {
    return `Daily at ${dayjs(bookingData.schedules[0].startTime).format(
      'HH:mm'
    )} - ${formatTime(bookingData.schedules[0]?.endTime)}`;
  }

  return `Weekly on ${checkedSchedules
    .map((bookingData) => dayjs(bookingData.startTime).format('ddd'))
    .join(', ')} at ${dayjs(bookingData.schedules[0].startTime).format(
    'HH:mm'
  )} - ${formatTime(bookingData.schedules[0]?.endTime)}`;
};
