import React from 'react';
import './AppToggle.css';

interface ToggleProps {
  value: boolean;
  onChange?: () => void;
  label?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const AppToggle = React.forwardRef(
  (
    { label, value, onChange, disabled = false, style }: ToggleProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div className="toggle" style={style}>
        <label className="switch">
          <input
            type="checkbox"
            checked={value}
            onChange={onChange ? onChange : () => {}}
            disabled={disabled}
          />
          <span
            className="slider"
            style={{ cursor: disabled ? 'default' : 'pointer' }}
          ></span>
          {label}
        </label>
      </div>
    );
  }
);

export default AppToggle;
