import { object as OBJECT, array, string } from 'yup';

//  ===== STUDENT  =====
const ROLE_NAME = string().required('Please provide name');
const ROLE_SLUG = string().required('Please provide slug');
const ROLE_IS_ACTIVE = string().required('Please provide active status');
const ROLE_STAFF_TYPE = string().required('Please select staff type');
const ROLE_PERMISSIONS = array();

//  ===== END STUDENT  =====

const validations = {
  OBJECT,
  ROLE_NAME,
  ROLE_SLUG,
  ROLE_IS_ACTIVE,
  ROLE_STAFF_TYPE,
  ROLE_PERMISSIONS
};
export default validations;
