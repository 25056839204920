import React, { useEffect, useMemo, useRef, useState } from 'react';
import AppButton from 'common/components/AppButton';
import AppInput from 'common/components/AppInput';
import yup from 'validators/yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import OTPInput from 'common/components/AppOTP';
import { secondsToHms } from 'common/helpers/time.helper';
import { Auth } from 'aws-amplify';
import { useAuth } from 'context/AuthContext';
import { LOGIN_STAGE, RESEND_OTP_TYPE } from 'common/enums';
import { OTP_TIME, RESEND_OTP_TIME } from 'common/constants/index';
import { useToast } from 'context/ToastContext';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { verifyByEmail } from 'services/auth.service';
import { USER_TYPE } from 'common/enums/user.enum';
import styles from './styles.module.scss';

const validationSchema = yup.OBJECT({
  email: yup.EMAIL
});

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  });

  const toast = useToast();

  const { login } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingResendEmail, setLoadingResendEmail] = useState<boolean>(false);
  const [loadingResendSms, setLoadingResendSms] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [session, setSession] = useState<any>(null);
  const [stage, setStage] = useState<LOGIN_STAGE>(LOGIN_STAGE.LOGIN);

  const [otp, setOtp] = useState<string>('');
  const [counter, setCounter] = useState<number>(0);
  const [resendCounterEmail, setResendCounterEmail] = useState<number>(0);
  const [resendCounterSMS, setResendCounterSMS] = useState<number>(0);

  const resendType = useRef<RESEND_OTP_TYPE>(RESEND_OTP_TYPE.IDLE);

  const __renderErrorMessage = useMemo((): string => {
    if (errors?.email && stage === LOGIN_STAGE.LOGIN)
      return errors?.email.message || '';
    else if (!!errorMessage && stage === LOGIN_STAGE.LOGIN) return errorMessage;
    return '';
  }, [errors?.email, errorMessage, stage]);

  const onReset = () => {
    setErrorMessage('');
    setCounter(0);
    setResendCounterEmail(0);
    setResendCounterSMS(0);
    setEmail('');
    setValue('email', '');
    setStage(LOGIN_STAGE.LOGIN);
    resendType.current = RESEND_OTP_TYPE.IDLE;
  };

  const onBack = () => {
    onReset();
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setValue('email', event.target.value);
  };

  const onClearEmail = () => {
    setEmail('');
    setValue('email', '');
  };

  const signIn = async () => {
    setLoading(true);
    const resultVerifyByEmail = await verifyByEmail(email);
    if (
      !!resultVerifyByEmail?.data?.data &&
      ![USER_TYPE.STAFF, USER_TYPE.INSTRUCTOR, USER_TYPE.SUPER_ADMIN].includes(
        resultVerifyByEmail?.data?.data?.userType
      )
    ) {
      setErrorMessage('User is not authorized to access this page');
      setLoading(false);
      return;
    }
    Auth.signIn(email)
      .then((result) => {
        setSession(result);
        setCounter(OTP_TIME);
        setStage(LOGIN_STAGE.OTP);
        setErrorMessage('');
      })
      .catch((error) => {
        if (error.code === 'UserNotFoundException') {
          setErrorMessage('Incorrect email address');
        } else if (error.code === 'NotAuthorizedException') {
          setErrorMessage('User is disabled');
        } else if (error.code === 'UsernameExistsException') {
          signIn();
        } else {
          toast.error(error?.response?.data?.message || 'Fail to send OTP');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resendEmail = () => {
    setLoadingResendEmail(true);
    Auth.sendCustomChallengeAnswer(session, '0', {
      signMethod: RESEND_OTP_TYPE.EMAIL,
      resend: 'true'
    })
      .then((result) => {
        resendType.current = RESEND_OTP_TYPE.EMAIL;
        setSession(result);
        setCounter(OTP_TIME);
        setResendCounterEmail(RESEND_OTP_TIME);
        setErrorMessage('');
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.message || 'Fail to resend otp email'
        );
      })
      .finally(() => {
        setLoadingResendEmail(false);
      });
  };

  const resendSms = () => {
    setLoadingResendSms(true);
    Auth.sendCustomChallengeAnswer(session, '0', {
      signMethod: RESEND_OTP_TYPE.SMS,
      resend: 'true'
    })
      .then((result) => {
        resendType.current = RESEND_OTP_TYPE.SMS;
        setSession(result);
        setCounter(OTP_TIME);
        setResendCounterSMS(RESEND_OTP_TIME);
        setErrorMessage('');
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Fail to resend otp sms');
      })
      .finally(() => {
        setLoadingResendSms(false);
      });
  };

  const verifyOtp = async (otp: string) => {
    setLoading(true);

    // Check failed 3 times -> Back to login.
    try {
      await Auth.sendCustomChallengeAnswer(session, otp);
    } catch {
      onReset();
    }

    try {
      // This will throw an error if the user is not yet authenticated:
      // Return JWT Token
      await Auth.currentSession();
      login();
      setSession(null);
    } catch (error) {
      if (error === 'No current user') {
        setErrorMessage('OTP is not correct, please try again');
        setOtp('');
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = () => {
    signIn();
  };

  useEffect(() => {
    let timer: any = null;
    if (counter > 0) {
      timer = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
    } else if (counter === 0) {
      clearInterval(timer);
      setStage(LOGIN_STAGE.LOGIN);
    }
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    let timer: any = null;
    if (resendCounterEmail > 0) {
      timer = setInterval(() => {
        setResendCounterEmail(resendCounterEmail - 1);
      }, 1000);
    } else if (resendCounterEmail === 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [resendCounterEmail]);

  useEffect(() => {
    let timer: any = null;
    if (resendCounterSMS > 0) {
      timer = setInterval(() => {
        setResendCounterSMS(resendCounterSMS - 1);
      }, 1000);
    } else if (resendCounterSMS === 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [resendCounterSMS]);

  return (
    <div className={styles.loginPage}>
      {stage === LOGIN_STAGE.OTP && (
        <div className={styles.backButton}>
          <HiOutlineArrowLeft size={32} onClick={onBack} />
        </div>
      )}
      <main className={styles.loginWrapper}>
        <div className={styles.login}>
          <div className={styles.logo}>
            <img src="/icons/logo-swim-school.svg" alt="logo" />
          </div>
          {/* LOGIN */}
          {stage === LOGIN_STAGE.LOGIN && (
            <div className={styles.main}>
              <div className={styles.titleGroup}>
                <h1 className={styles.title}>User Login</h1>
                <p className={styles.description}>
                  We suggest using the email address you use at work.
                </p>
              </div>
              <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                <AppInput
                  label="Email Address"
                  {...register('email')}
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  onClearSearch={onClearEmail}
                  message={{
                    type: 'error',
                    text: __renderErrorMessage
                  }}
                />
                <AppButton isLoading={loading}>Continue</AppButton>
              </form>
            </div>
          )}
          {/* OTP */}
          {stage === LOGIN_STAGE.OTP && (
            <React.Fragment>
              <div className={styles.main}>
                <div className={styles.titleGroup}>
                  <h1 className={styles.title}>Verify your email</h1>
                  <p className={styles.description}>
                    We’ve sent a 6-digits code to <strong>{email}</strong>. The
                    code wil be expired in{' '}
                    <strong>{secondsToHms(counter)}</strong>
                  </p>
                </div>
                <div className={styles.otpWrapper}>
                  <OTPInput
                    autoFocus
                    isNumberInput
                    length={6}
                    value={otp}
                    onChangeOTP={(otp: string) => {
                      if (otp.length === 6) {
                        verifyOtp(otp);
                      }
                      setOtp(otp);
                    }}
                  />
                </div>
                {loading && (
                  <div className={styles.loadingOTP}>
                    <div className={styles.loader}>
                      <img src="/gifs/spinner.gif" alt="spinner" />
                    </div>
                    <p>Check your code</p>
                  </div>
                )}

                {!loading && !!errorMessage && (
                  <p className={styles.errorMessageOTP}>{errorMessage}</p>
                )}
              </div>

              <p className={styles.resendCodeHint}>Didn’t receive the code?</p>

              <div className={styles.resendCodeContainer}>
                <div className={styles.resendCode}>
                  <p
                    className={
                      !!resendCounterEmail || loadingResendEmail
                        ? styles.resendCodeDisabled
                        : ''
                    }
                    onClick={resendEmail}
                  >
                    Resend by Email{' '}
                    {!!resendCounterEmail && `(${resendCounterEmail})`}
                  </p>
                  {loadingResendEmail && (
                    <div className={styles.loaderResend}>
                      <img src="/gifs/spinner.gif" alt="spinner" />
                    </div>
                  )}
                </div>

                <div className={styles.resendCode}>
                  <p
                    className={
                      !!resendCounterSMS || loadingResendSms
                        ? styles.resendCodeDisabled
                        : ''
                    }
                    onClick={resendSms}
                  >
                    Resend by SMS{' '}
                    {!!resendCounterSMS && `(${resendCounterSMS})`}
                  </p>
                  {loadingResendSms && (
                    <div className={styles.loaderResend}>
                      <img src="/gifs/spinner.gif" alt="spinner" />
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </main>
    </div>
  );
};

export default Login;
