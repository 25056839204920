import React, { useRef, useState } from 'react';

import AppButton from 'common/components/AppButton';
import { CHANGE_TYPE } from 'common/enums';

interface IActionPopperProps {
  onChangeArea: (value: CHANGE_TYPE) => void;
  onChangeInstructor: (value: CHANGE_TYPE) => void;
  onChangeCapacity: () => void;
  onChangePrice: () => void;
}

const ActionPopper: React.FC<IActionPopperProps> = ({
  onChangeInstructor,
  onChangeArea,
  onChangeCapacity,
  onChangePrice
}: IActionPopperProps) => {
  const popperRef = useRef<any>(null);
  const verticalButtonRef = useRef<any>(null);

  const [open, setOpen] = useState<boolean>(false);

  const onToggle = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // Close popper if click outside the popper
    const handleClickOutside = (event: MouseEvent) => {
      // Click at vertical button to close
      if (
        verticalButtonRef.current &&
        verticalButtonRef.current.contains(event.target)
      ) {
        return;
      }
      // Handle click outside to close pop up
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        onClose();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [verticalButtonRef, popperRef]);

  return (
    <React.Fragment>
      <div ref={verticalButtonRef} className="ellipsisVertical">
        <AppButton buttonSize="small" onClick={onToggle}>
          Bulk update
        </AppButton>

        {open && (
          <div ref={popperRef} className="viewDetailPopup" onClick={onClose}>
            <div
              className="viewDetailPopup-item"
              onClick={() => onChangeInstructor(CHANGE_TYPE.PERMANENT)}
            >
              Change Instructor permanent
            </div>
            <div
              className="viewDetailPopup-item"
              onClick={() => onChangeInstructor(CHANGE_TYPE.TEMPORARY)}
            >
              Change Instructor temporary
            </div>
            <div
              className="viewDetailPopup-item"
              onClick={() => onChangeArea(CHANGE_TYPE.PERMANENT)}
            >
              Change Area permanent
            </div>
            <div
              className="viewDetailPopup-item"
              onClick={() => onChangeArea(CHANGE_TYPE.TEMPORARY)}
            >
              Change Area temporary
            </div>
            <div className="viewDetailPopup-item" onClick={onChangePrice}>
              Change Price
            </div>
            <div className="viewDetailPopup-item" onClick={onChangeCapacity}>
              Change Capacity
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ActionPopper;
