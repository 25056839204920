import { DATE_CONSTANT } from 'common/constants';
import dayjs from 'dayjs';

function getTimezoneOffset() {
  return dayjs().tz().utcOffset() / 60;
}

export const getUnixTimeOfValue = (value: string) => {
  if (getTimezoneOffset() === 10) {
    return dayjs(`2000-07-01 ${value}`).valueOf();
  } else {
    return dayjs(`${DATE_CONSTANT} ${value}`).valueOf();
  }
};
