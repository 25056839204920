import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import AppTable from 'common/components/AppTable';
import AppToggle from 'common/components/AppToggle';
import { createColumnHelper } from '@tanstack/react-table';
import { formatData, formatTime } from 'common/helpers/dataFormat.helper';
import { convertRecurrenceToString } from 'common/helpers/time.helper';
import { IClass } from 'common/interfaces/class.interface';
import {
  HiOutlineAcademicCap,
  HiOutlineArrowLeft,
  HiOutlineIdentification
} from 'react-icons/hi';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { PERMISSION } from 'common/enums/permission.enum';
import { useAuth } from 'context/AuthContext';
import { useToast } from 'context/ToastContext';
import { getClassList } from 'services/classes.service';
import { useBrandLocation } from 'context/BrandLocationContext';
import dayjs from 'dayjs';
import { LevelSetting } from 'common/interfaces/levelBreakdown.interface';
import PermissionWrapper from 'components/PermissionWrapper';
import AppSelect from 'common/components/AppSelect';
import AppButton from 'common/components/AppButton';
import { PROGRAM_TYPE_OPTIONS } from 'common/constants';
import AppInputSearch from 'common/components/AppInputSearch';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import { GoFilter } from 'react-icons/go';
import useDebounce from 'common/hooks/useDebounce';
import {
  getLevelBreakdowns,
  updateLevelSetting
} from 'services/levelBreakdown.service';
import { getProgramType } from 'common/helpers/index.helper';
import ColorLevelQuickFilter from 'components/colorLevelFilter/ColorLevelQuickFilter';
import { IColorSetting } from 'common/interfaces/dashboard.interface';

const ClassList = () => {
  const params = useParams();
  const { hasPermission, user } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const { selectedLocation: globalLocation } = useBrandLocation();

  const columnHelper = createColumnHelper<IClass>();
  const columns = [
    columnHelper.accessor('session.startTime', {
      header: () => <span>Next session</span>,
      cell: (info) => {
        if (!info.getValue()) return formatData(null);
        return (
          <div data-tooltip-id={`content-tooltip-${info.row.original._id}`}>
            <div className="content-cell">
              <span className="line-clamp-1">
                {`${dayjs(info.getValue()).format('ddd, MMM D,')} ${formatTime(
                  info.row.original.session?.startHour
                )} - ${formatTime(info.row.original.session?.endHour)}`}
              </span>
            </div>
            <Tooltip
              id={`content-tooltip-${info.row.original._id}`}
              opacity={1}
              className="tooltip-detail"
              style={{ backgroundColor: '#034EA2' }}
              render={() => (
                <div>{convertRecurrenceToString(info.row.original)}</div>
              )}
            />
          </div>
        );
      },
      size: 250
    }),
    columnHelper.accessor('name', {
      header: () => <span>CLASS</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('levelBreakdown', {
      header: () => <span>Level</span>,
      cell: (info) =>
        formatData(info.row.original?.levelBreakdown?.name || 'All Levels')
    }),
    columnHelper.accessor('type', {
      header: () => <span>TYPE</span>,
      cell: (info) => formatData(getProgramType(info.getValue()))
    }),
    columnHelper.accessor('area', {
      header: () => <span>AREA</span>,
      cell: (info) =>
        formatData(info.row.original.session?.area || info.getValue())
    }),
    columnHelper.accessor('session.capacity', {
      header: () => <span>CAPACITY</span>,
      cell: (info) => {
        const session = info.row.original.session;
        const capacity = info.getValue() || 0;
        const occupied = session.occupied || 0;
        const available = capacity - occupied;
        return (
          <div style={{ cursor: 'pointer' }} className="content-cell">
            <HiOutlineAcademicCap fontSize={20} />
            {!session ? (
              `--/--`
            ) : (
              <span style={{ whiteSpace: 'nowrap', marginLeft: '4px' }}>
                {formatData(occupied)}/{formatData(capacity)}{' '}
                {available >= 0 ? `(${available} left)` : '(overbooked)'}
              </span>
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor('active', {
      header: () => <div style={{ textAlign: 'center' }}>ACTIVE</div>,
      cell: (info) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <AppToggle value={info.getValue()} disabled />
          </div>
        );
      }
    }),
    columnHelper.accessor('_id', {
      header: () => <div style={{ textAlign: 'center' }}>ACTION</div>,
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <div onClick={() => handleViewClass(info.row.original)}>
              <HiOutlineIdentification fontSize={18} />
            </div>
          </div>
        );
      },
      size: 50
    })
  ];

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingAPI, setLoadingAPI] = useState<boolean>(false);

  const [index, setIndex] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [total, setTotal] = useState<number>(-1);

  const [classes, setClasses] = useState<Array<IClass>>([]);
  const [levels, setLevels] = useState<Array<IColorSetting>>([]);

  // Filters
  const [classType, setClassType] = useState<string>('');
  const [levelId, setLevelId] = useState<string>('');
  const [area, setArea] = useState<string>('');

  const filterClassType = useRef<string>('');
  const filterLevelId = useRef<string>('');
  const filterArea = useRef<string>('');

  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search);

  const __appliedFilter = useMemo((): string => {
    let count = 0;
    count =
      count + (!!classType ? 1 : 0) + (!!levelId ? 1 : 0) + (!!area ? 1 : 0);
    return count > 0 ? `(${count})` : '';
  }, [classType, levelId, area]);

  // Popper actions & APIs
  const handleViewClass = useCallback(
    (item: IClass) => {
      if (item._id)
        navigate(`/classes/${item._id}?lessonId=${item.session?._id}`);
      else navigate('/classes');
    },
    [navigate]
  );

  const handleSelectLevel = (id: string) => {
    setLevelId(id);
  };

  // Search
  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  const onClearSearch = () => {
    setSearch('');
  };

  // Filters
  const handleOpenFilter = () => {
    filterClassType.current = classType;
    filterLevelId.current = levelId;
    filterArea.current = area;
    setOpenFilter(true);
  };
  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  const handleApplyFilter = () => {
    setClassType(filterClassType.current);
    setLevelId(filterLevelId.current);
    setArea(filterArea.current);
    handleCloseFilter();
  };
  const handleResetFilter = () => {
    setClassType('');
    setLevelId('');
    setArea('');
    handleCloseFilter();
  };

  const handleChangeClassType = (classType: string) => {
    filterClassType.current = classType;
  };
  const handleChangeLevelId = (id: string) => {
    filterLevelId.current = id;
  };
  const handleChangeArea = (area: string) => {
    filterArea.current = area;
  };

  // APIs
  const fetchClasses = useCallback(
    async () => {
      if (!globalLocation) return;
      setLoadingAPI(true);
      try {
        const paramsClassList: {
          instructors?: string;
          classType?: string;
          levels?: string;
          areas?: string;
          keyword?: string;
          locationId?: string;
          limit: number;
          page: number;
          isActive?: boolean;
        } = {
          locationId: globalLocation?._id || '',
          instructors: params?.id || '',
          // isActive: true,
          limit: limit,
          page: index
        };

        if (!!classType) paramsClassList.classType = classType;
        if (!!levelId) paramsClassList.levels = levelId;
        if (!!area) paramsClassList.areas = area;
        if (!!search) paramsClassList.keyword = search;

        const { data, total } = await getClassList(paramsClassList);
        setClasses(data);
        setTotal(total);
      } catch (error: any) {
        setClasses([]);
        setTotal(-1);
        toast.error(
          error?.response?.data?.message ||
            'Failed to get classes for this instructor'
        );
      } finally {
        setLoadingAPI(false);
      }
    },
    // eslint-disable-next-line
    [
      globalLocation?._id,
      params?.id,
      index,
      limit,
      classType,
      levelId,
      area,
      debouncedSearch
    ]
  );

  const fetchInitData = useCallback(async () => {
    try {
      const [{ data: dataLevelBreakdowns }] = await Promise.all([
        getLevelBreakdowns(1, 200, globalLocation?._id || '')
      ]);
      setLevels(dataLevelBreakdowns.data.data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to fetch init data'
      );
      setLevels([]);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [globalLocation]);

  useEffect(() => {
    fetchClasses();
  }, [fetchClasses]);

  useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  useEffect(() => {
    setIndex(1);
  }, [debouncedSearch, classType, levelId, area]);

  const handleSortColorSetting = useCallback(
    async (newColorSettings: IColorSetting[]) => {
      if (!user?._id || !newColorSettings.length) return;
      setLevels(newColorSettings);
      try {
        const payload: LevelSetting = {
          levelOrders: newColorSettings.map((item, index) => {
            return { levelId: item._id, order: index };
          })
        };
        await updateLevelSetting(user?._id, payload);
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Cannot sort color setting'
        );
      }
    },
    // eslint-disable-next-line
    [user?._id]
  );

  if (loading) {
    return <AppLoadingContainer />;
  }

  return (
    <section className="instructorClassList">
      {/* Filter */}
      <div
        className={`instructorClassList__filter ${openFilter ? 'active' : ''}`}
      >
        <div className="instructorClassList__filter__header">
          <HiOutlineArrowLeft size={24} onClick={handleCloseFilter} />
          <p className="instructorClassList__filter__header--title">filters</p>
          <p
            className="instructorClassList__filter__header--reset"
            style={{ cursor: 'pointer' }}
            onClick={handleResetFilter}
          >
            Reset
          </p>
        </div>
        {openFilter && (
          <div className="instructorClassList__filter__content">
            <AppSelect
              name="classType"
              options={PROGRAM_TYPE_OPTIONS}
              searchable={false}
              inputSize="small"
              label="Program type"
              value={classType}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                handleChangeClassType(event.target.value)
              }
            />
            <PermissionWrapper permission={PERMISSION.LIST_LEVEL_BREAKDOWN}>
              <AppSelect
                label="Level"
                value={levelId}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  handleChangeLevelId(event.target.value)
                }
                options={[{ value: '', label: 'All' }].concat(
                  levels.map((levelBreakdown: IColorSetting) => ({
                    value: levelBreakdown._id,
                    label: levelBreakdown.shortName
                  }))
                )}
              />
            </PermissionWrapper>
            <AppSelect
              value={area}
              options={(globalLocation?.areas || [])?.map((item) => ({
                label: item,
                value: item
              }))}
              inputSize="small"
              label="Area"
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                handleChangeArea(event.target.value)
              }
              searchable={false}
            />
          </div>
        )}
        <div className="instructorClassList__filter__actions">
          <AppButton
            variant="primary"
            buttonSize="small"
            onClick={handleApplyFilter}
          >
            apply
          </AppButton>
        </div>
      </div>

      <div className="instructorClassList__wrapper">
        <div className="instructorClassList__header">
          <p className="instructorClassList__header--title">classes</p>
          <div className="instructorClassList__header--search">
            <AppInputSearch
              type="text"
              placeholder="Class name"
              startIcon={<HiMagnifyingGlass />}
              value={search}
              onChange={onChangeSearch}
              onClearSearch={onClearSearch}
            />
            <AppButton
              variant="secondary"
              buttonSize="small"
              onClick={handleOpenFilter}
            >
              <GoFilter size={22} />
              Filters {__appliedFilter}
            </AppButton>
          </div>
        </div>

        <div className="instructorClassList__levels">
          <PermissionWrapper permission={PERMISSION.LIST_COLOR_SETTING}>
            <ColorLevelQuickFilter
              colorSettings={levels}
              onClickLevel={(levelId: string) => {
                handleSelectLevel(levelId);
                const newSetting = levels.map((item) => {
                  if (item._id === levelId) {
                    item.active = true;
                  } else {
                    item.active = false;
                  }
                  return item;
                });
                setLevels(newSetting);
              }}
              onClickAllLevel={() => handleSelectLevel('')}
              levelIds={levelId}
              onSortColorSetting={handleSortColorSetting}
            />
          </PermissionWrapper>
        </div>

        <div className="instructorClassList__table">
          <AppTable
            data={classes}
            columns={columns}
            pagination={{
              index: index,
              size: limit,
              total: total
            }}
            onChangePage={(index: number, size: number) => {
              setIndex(index);
              setLimit(size);
            }}
            loading={loadingAPI}
            columnVisibility={{
              _id: hasPermission(PERMISSION.VIEW_DETAIL_CLASS)
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default memo(ClassList);
