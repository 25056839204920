import dayjs from 'dayjs';
import { object as OBJECT, string } from 'yup';

const STAFF_STATUS = string();
const STAFF_NAME = string().required('Please provide name');
const STAFF_SURNAME = string().required('Please provide surname');
const STAFF_ALIAS = string();
const STAFF_DOB = string()
  .required('Please select D.O.B')
  .test('exceed-today', function (value, context) {
    if (dayjs(value).isAfter(dayjs()) || value === 'Invalid Date') {
      return this.createError({
        path: context.path,
        message: 'Please provide correct date'
      });
    }
    return true;
  });
const STAFF_GENDER = string().required('Please select gender');
const STAFF_MOBILE_NUMBER = string().required('Please provide mobile number');
const STAFF_EMAIL_ADDRESS = string()
  .required('Please provide email address')
  .email('Please correct the email address');
const STAFF_ADDRESS = string().required('Please provide address');
const STAFF_SUBURB = string().required('Please provide suburb');
const STAFF_STATE = string().required('Please select state');
const STAFF_POSTCODE = string().required('Please provide postcode');
const STAFF_COUNTRY = string().required('Please provide country');
const STAFF_TYPE = string().required('Please select type');
const STAFF_ROLE = string().required('Please select role');
const STAFF_JOINING_DATE = string().required('Please select joining date');
const STAFF_END_DATE = string();

const STAFF_AVATAR_URL = string();
const STAFF_LOCATION = string().required('Please select location');

const validations = {
  OBJECT,
  STAFF_STATUS,
  STAFF_NAME,
  STAFF_SURNAME,
  STAFF_ALIAS,
  STAFF_DOB,
  STAFF_GENDER,
  STAFF_MOBILE_NUMBER,
  STAFF_EMAIL_ADDRESS,
  STAFF_ADDRESS,
  STAFF_SUBURB,
  STAFF_STATE,
  STAFF_POSTCODE,
  STAFF_COUNTRY,
  STAFF_TYPE,
  STAFF_ROLE,
  STAFF_JOINING_DATE,
  STAFF_END_DATE,
  STAFF_AVATAR_URL,
  STAFF_LOCATION
};
export default validations;
