import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AppCard, {
  AppCardContent,
  AppCardHeader
} from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import AppDatePicker from 'common/components/AppDatePicker';
import AppSelect from 'common/components/AppSelect';
import AppPhoneNumberInput from 'common/components/AppPhoneNumberInput';
import { createInstructor } from 'services/instructor.service';
import { HiArrowLeft } from 'react-icons/hi';
import { CreateInstructorDTO } from 'DTOs/instructor.dto';
import { STATUS_TYPE } from 'common/enums/staff.enum';
import { GENDER } from 'common/enums/user.enum';
import yup from 'validators/instructor.validators';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GENDER_TYPE_OPTIONS } from 'common/constants/index';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { convertToUnixTime } from 'common/helpers/time.helper';
import AppButton from 'common/components/AppButton';
import dayjs, { Dayjs } from 'dayjs';
import { useToast } from 'context/ToastContext';
import AppToggle from 'common/components/AppToggle';
import { useBrandLocation } from 'context/BrandLocationContext';
import AppSelection from 'common/components/AppSelection';
import { CREDENTIAL_OPTIONS } from 'common/constants/instructor.constants';
import Upload, { ERROR_MESSAGE_LIMIT_FILES } from 'components/Upload';
import { ERROR_MESSAGE_LIMIT_SIZE } from 'components/UploadAvatar';
import { uploadFile } from 'services/uploadFile.service';
import { FILE_ASSET_TYPE } from 'common/enums/uploadFile.enum';
import { createBulkCredentialsForInstructor } from 'services/credential';
import { ILevelBreakdown } from 'common/interfaces/levelBreakdown.interface';
import { getLevelBreakdowns } from 'services/levelBreakdown.service';
import AppTable from 'common/components/AppTable';
import { createColumnHelper } from '@tanstack/react-table';
import AppModal, {
  AppModalContent,
  AppModalFormActions,
  AppModalFormTitle
} from 'common/components/AppModal';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import { IInstructorCredential } from '../interface';
import UploadFile from 'components/Upload/UploadFile';
import { CreateCredentialDTO } from 'DTOs/credential.dto';

const validationSchema = yup.OBJECT({
  status: yup.INSTRUCTOR_STATUS,
  firstName: yup.INSTRUCTOR_NAME,
  lastName: yup.INSTRUCTOR_LAST_NAME,
  displayName: yup.INSTRUCTOR_DISPLAY_NAME,
  dob: yup.INSTRUCTOR_DOB,
  gender: yup.INSTRUCTOR_GENDER,
  phoneNumber: yup.INSTRUCTOR_MOBILE_NUMBER,
  email: yup.INSTRUCTOR_EMAIL_ADDRESS,
  locationIds: yup.INSTRUCTOR_LOCATION_IDS,
  isAllLocation: yup.INSTRUCTOR_ALL_LOCATION,
  credentialName: yup.INSTRUCTOR_CREDENTIAL_NAME,
  levelIds: yup.INSTRUCTOR_LEVELS,
  isAllLevel: yup.INSTRUCTOR_ALL_LEVEL
});

const validationSchemaForCredential = yup.OBJECT({
  name: yup.INSTRUCTOR_CREDENTIAL_NAME,
  expiryDate: yup.INSTRUCTOR_CREDENTIAL_EXPIRY_DATE,
  files: yup.INSTRUCTOR_CREDENTIAL_FILES,
  documents: yup.INSTRUCTOR_CREDENTIAL_FILES
});

interface IInstructorAddFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const initCredentials: IInstructorCredential[] = CREDENTIAL_OPTIONS.map(
  (item) => {
    return {
      name: item.label,
      files: [],
      expiryDate: '',
      documents: []
    };
  }
);

const InstructorAddForm: React.FC<IInstructorAddFormProps> = (
  props: IInstructorAddFormProps
) => {
  const { open, onClose, onSuccess } = props;
  const toast = useToast();
  const { locations } = useBrandLocation();
  const locationList = useMemo(() => {
    const result = locations
      ?.filter((item) => item?._id)
      ?.map((item) => {
        return {
          label: item.shortName || item.name,
          value: item._id
        };
      });
    return result;
  }, [locations]);

  const initInstructor: CreateInstructorDTO = {
    status: STATUS_TYPE.ACTIVE,
    firstName: '',
    lastName: '',
    displayName: '',
    dob: '',
    gender: GENDER.IDLE,
    phoneNumber: '',
    email: '',
    locationIds: [],
    isAllLocation: false,
    credentialName: '',
    credentialFiles: [],
    levelIds: [],
    isAllLevel: false
  };
  const [loadingOnSubmit, setLoadingOnSubmit] = useState<boolean>(false);
  const [instructor, setInstructor] = useState<CreateInstructorDTO>(() => {
    return {
      ...initInstructor
    };
  });
  const [files, setFiles] = useState<Array<File>>([]);
  const [levelList, setLevelList] = useState<ILevelBreakdown[]>([]);

  const [credentialData, setCredentialData] =
    useState<IInstructorCredential[]>(initCredentials);
  const [selectedCredential, setSelectedCredential] =
    useState<IInstructorCredential | null>(null);

  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<CreateInstructorDTO>({
    resolver: yupResolver(validationSchema) as Resolver<CreateInstructorDTO>,
    defaultValues: instructor
  });

  const {
    register: registerForCredential,
    trigger: triggerForCredential,
    handleSubmit: handleSubmitForCredential,
    setValue: setValueForCredential,
    reset: resetForCredential,
    formState: { errors: errorsForCredential }
  } = useForm<IInstructorCredential>({
    resolver: yupResolver(
      validationSchemaForCredential
    ) as Resolver<IInstructorCredential>,
    defaultValues: initCredentials[0]
  });

  const phoneNumberError = useRef<string>('');
  const __locationPlaceholderText: string = useMemo(() => {
    return instructor.locationIds.length === 0
      ? 'Select option'
      : `${instructor.locationIds.length} selected`;
  }, [instructor.locationIds]);

  const __levelPlaceholderText: string = useMemo(() => {
    return instructor.levelIds.length === 0
      ? 'Select option'
      : `${instructor.levelIds.length} selected`;
  }, [instructor.levelIds]);

  const columnHelper = createColumnHelper<IInstructorCredential>();
  const columns = [
    columnHelper.accessor('name', {
      header: 'Credential',
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('name', {
      header: '',
      cell: (info) => (
        <AppButton
          variant="secondary"
          buttonSize="small"
          style={{ padding: '6px 12px', fontSize: '14px', height: 'auto' }}
          onClick={() => {
            setSelectedCredential(info.row.original);
            setFiles(info.row.original.files || []);
            resetForCredential(info.row.original);
          }}
        >
          Upload
        </AppButton>
      ),
      size: 10
    }),
    columnHelper.accessor('files', {
      header: 'Documents',
      cell: (info) => (
        <div className="documentGroup">
          {info.getValue().map((file: File, index: number) => {
            return <UploadFile key={index} file={file} />;
          })}
        </div>
      )
    }),
    columnHelper.accessor('expiryDate', {
      header: 'Expiry Date',
      cell: (info) =>
        info.getValue() ? formatDate(info.getValue()) : formatData(null),
      size: 10
    })
  ];

  const fetchLevelList = useCallback(async () => {
    setLoadingOnSubmit(true);
    try {
      const { data } = await getLevelBreakdowns(
        1,
        100,
        undefined,
        undefined,
        instructor.locationIds
      );
      setLevelList(
        data.data.data.map((item: ILevelBreakdown) => ({
          ...item,
          value: item._id,
          label: item.name
        }))
      );
      setInstructor({
        ...instructor,
        levelIds: []
      });

      setValue('levelIds', []);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'Failed to get level list by selected locations'
      );
      setInstructor({
        ...instructor,
        levelIds: []
      });
      setValue('levelIds', []);
    } finally {
      setLoadingOnSubmit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructor.locationIds]);

  useEffect(() => {
    fetchLevelList();
  }, [fetchLevelList]);

  const handleChangeFiles = (files: Array<File>) => {
    setFiles(files);
    setValue('credentialFiles', files);
    selectedCredential &&
      setSelectedCredential({ ...selectedCredential, files });
  };

  const onSubmit = async (dataForm: CreateInstructorDTO) => {
    // Custom error
    if (!!phoneNumberError.current) return;
    setLoadingOnSubmit(true);
    const credentialList = credentialData.filter(
      (item: IInstructorCredential) => item.files.length > 0
    );

    if (credentialList.length > 0) {
      await credentialList.forEach(async (item: IInstructorCredential) => {
        try {
          const promises: Array<Promise<any>> = [];
          for (let i = 0; i < item.files.length; i++) {
            const file = item.files[i];
            const promise = uploadFile(FILE_ASSET_TYPE.CREDENTIAL, file);
            promises.push(promise);
          }
          let payloadDocuments: string[] = [];
          await Promise.all(promises).then(async (results) => {
            payloadDocuments = results.map((e) => e?.data?.data || '');
            item.documents = payloadDocuments;
          });
        } catch (error: any) {
          toast.error(
            error?.response?.data?.message || 'Failed to upload credentials'
          );
          return item;
        }
      });
    }

    const { credentialName, credentialFiles, ...data } = dataForm;
    const payload: CreateInstructorDTO = {
      ...data,
      dob: convertToUnixTime(data.dob.toString()),
      isAllLocation: data.locationIds.length === locationList?.length,
      isAllLevel: data.levelIds.length === levelList?.length
    };

    createInstructor(payload)
      .then(async (result) => {
        // reset fields.
        setInstructor({ ...initInstructor });
        reset(initInstructor);

        if (credentialList.length > 0) {
          try {
            await createBulkCredentialsForInstructor({
              credentials: credentialList.map(
                (item: IInstructorCredential): CreateCredentialDTO => {
                  return {
                    name: item.name,
                    documents: item.documents,
                    expiredAt: convertToUnixTime(item.expiryDate.toString())
                  };
                }
              ),
              instructorId: result?._id
            });
            setTimeout(() => {
              onSuccess();
            }, 200);
          } catch (error: any) {
            toast.error(
              error?.response?.data?.message || 'Failed to upload credentials'
            );
          }
        } else {
          setTimeout(() => {
            onSuccess();
          }, 200);
        }
        // notify and reload.
        toast.success('Create instructor successfully');
      })
      .catch((error) => {
        toast.error(error.response.data.message || 'Create instructor failed');
      })
      .finally(() => {
        setLoadingOnSubmit(false);
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstructor({
      ...instructor,
      [event.target.name]: event.target.value
    });
    // @ts-ignore
    setValue(event.target.name, event.target.value);
    // @ts-ignore
    trigger(event.target.name);
  };

  const handleChangePhoneNumber = (value: string) => {
    setInstructor({
      ...instructor,
      phoneNumber: value
    });
    setValue('phoneNumber', value);
    trigger('phoneNumber');
    phoneNumberError.current = isValidPhoneNumber(value || '')
      ? ''
      : 'Invalid phone number';
  };

  const handleChangeDOB = (value: dayjs.Dayjs | null) => {
    setInstructor({
      ...instructor,
      dob: value?.format('YYYY-MM-DD') || ''
    });
    setValue('dob', value?.format('YYYY-MM-DD') || '');
    trigger('dob');
  };
  const handleCloseForm = () => {
    reset(initInstructor);
    setInstructor(initInstructor);
    phoneNumberError.current = '';
    onClose();
  };

  const handleChangeStatus = () => {
    const newStatus =
      instructor.status === STATUS_TYPE.ACTIVE
        ? STATUS_TYPE.INACTIVE
        : STATUS_TYPE.ACTIVE;
    setInstructor({
      ...instructor,
      status: newStatus
    });
    setValue('status', newStatus);
  };
  const handleToggleLocation = (value: string) => {
    const locations = [...instructor.locationIds];
    const findIndex = locations.findIndex((item) => item === value);
    if (findIndex === -1) {
      locations.push(value);
    } else {
      locations.splice(findIndex, 1);
    }
    setInstructor({
      ...instructor,
      locationIds: locations
    });
    setValue('locationIds', locations);
    trigger('locationIds');
  };
  const handleSelectAllLocation = () => {
    const values = locationList?.map((location) => location.value);
    if (instructor.locationIds.length === locationList?.length) {
      setInstructor({
        ...instructor,
        locationIds: []
      });
      setValue('locationIds', []);
    } else {
      setInstructor({
        ...instructor,
        locationIds: values
      });
      setValue('locationIds', values);
    }
    trigger('locationIds');
  };
  const handleToggleLevel = (value: string) => {
    const levels = [...instructor.levelIds];
    const findIndex = levels.findIndex((item) => item === value);
    if (findIndex === -1) {
      levels.push(value);
    } else {
      levels.splice(findIndex, 1);
    }
    setInstructor({
      ...instructor,
      levelIds: levels
    });
    setValue('levelIds', levels);
    trigger('levelIds');
  };
  const handleSelectAllLevel = () => {
    const values = levelList?.map((level) => level.value);
    if (instructor.levelIds.length === levelList?.length) {
      setInstructor({
        ...instructor,
        levelIds: []
      });
      setValue('levelIds', []);
    } else {
      setInstructor({
        ...instructor,
        levelIds: values
      });
      setValue('levelIds', values);
    }
    trigger('levelIds');
  };

  const handleCloseCredentialForm = () => {
    setSelectedCredential(null);
    resetForCredential();
  };

  const handleSaveCredential = () => {
    setCredentialData(
      credentialData.map((e) =>
        e.name === selectedCredential?.name
          ? { ...e, ...selectedCredential }
          : e
      )
    );
    setSelectedCredential(null);
    setFiles([]);
    resetForCredential();
  };
  return (
    <div className="instructorsAddContainer disable-scroll">
      <div
        className={`overlay ${open ? 'active' : ' '}`}
        onClick={handleCloseForm}
      ></div>
      <AppModal open={!!selectedCredential} onClose={handleCloseForm}>
        <AppModalFormTitle>Upload Credential</AppModalFormTitle>
        <AppModalContent>
          <div className="instructorsAddForm__credential">
            <div>{formatData(selectedCredential?.name)}</div>
            <AppDatePicker
              label="Expiry Date"
              {...registerForCredential('expiryDate')}
              value={dayjs(selectedCredential?.expiryDate || '')}
              onChange={(value: Dayjs | null) => {
                selectedCredential &&
                  setSelectedCredential({
                    ...selectedCredential,
                    expiryDate: value ? dayjs(value).format('YYYY-MM-DD') : ''
                  });
                setValueForCredential(
                  'expiryDate',
                  value ? dayjs(value).format('YYYY-MM-DD') : ''
                );
                triggerForCredential('expiryDate');
              }}
              message={{
                type: 'error',
                text: errorsForCredential.expiryDate?.message || ''
              }}
              size="small"
            />
            <Upload
              files={files}
              documents={[]}
              onChangeDocuments={() => {}}
              onChangeFiles={handleChangeFiles}
              onErrorFiles={(errorMessage: string) => {
                if (errorMessage === ERROR_MESSAGE_LIMIT_SIZE) {
                  toast.error('Exceed the size 5mb');
                } else if (errorMessage === ERROR_MESSAGE_LIMIT_FILES) {
                  toast.error('Exceed the number of files');
                }
              }}
            />
          </div>
        </AppModalContent>
        <AppModalFormActions>
          <AppButton
            type="submit"
            onClick={handleSubmitForCredential(handleSaveCredential)}
            buttonSize="small"
            disabled={!selectedCredential?.files.length}
          >
            Save
          </AppButton>
          <AppButton
            variant="secondary"
            onClick={handleCloseCredentialForm}
            buttonSize="small"
          >
            Cancel
          </AppButton>
        </AppModalFormActions>
      </AppModal>
      <div className={`instructorsAddForm ${open ? 'active' : ' '}`}>
        <div className="instructorsAddForm__header">
          <div onClick={onClose}>
            <HiArrowLeft fontSize={24} />
          </div>
          <p>Add Instructor</p>
        </div>
        <div className="instructorsAddForm__content disable-scroll">
          <AppCard>
            <div className="instructorsAddForm__content-wrapper">
              <div className="instructorsAddForm__content-detail-wrapper">
                <AppCardHeader
                  title="PERSONAL INFORMATION"
                  suffix={
                    <AppToggle
                      value={instructor.status === STATUS_TYPE.ACTIVE}
                      onChange={handleChangeStatus}
                    />
                  }
                />
                <AppCardContent className="instructorsAddForm__content-personal-information">
                  <AppInput
                    label="Name*"
                    {...register('firstName')}
                    onChange={handleChange}
                    message={{
                      type: 'error',
                      text: errors?.firstName?.message || ''
                    }}
                  />
                  <AppInput
                    label="Surname*"
                    {...register('lastName')}
                    onChange={handleChange}
                    message={{
                      type: 'error',
                      text: errors?.lastName?.message || ''
                    }}
                  />
                  <AppInput
                    label="Display Name*"
                    {...register('displayName')}
                    onChange={handleChange}
                    message={{
                      type: 'error',
                      text: errors?.displayName?.message || ''
                    }}
                  />
                  <AppDatePicker
                    label="D.O.B*"
                    {...register('dob')}
                    value={dayjs(instructor.dob)}
                    onChange={handleChangeDOB}
                    message={{
                      type: 'error',
                      text: errors?.dob?.message || ''
                    }}
                    disableFuture
                  />
                  <AppSelect
                    label="Gender*"
                    options={GENDER_TYPE_OPTIONS}
                    {...register('gender')}
                    value={instructor.gender}
                    onChange={handleChange}
                    message={{
                      type: 'error',
                      text: errors?.gender?.message || ''
                    }}
                  />
                  <AppSelection
                    label="Location*"
                    options={locationList}
                    onSelect={handleToggleLocation}
                    onSelectAll={handleSelectAllLocation}
                    selectedIds={instructor.locationIds}
                    placeholder={__locationPlaceholderText}
                    size="large"
                    message={{
                      type: 'error',
                      text: errors?.locationIds?.message || ''
                    }}
                    isSearch
                  />
                  <AppSelection
                    label="Level*"
                    options={levelList}
                    onSelect={handleToggleLevel}
                    onSelectAll={handleSelectAllLevel}
                    selectedIds={instructor.levelIds}
                    placeholder={__levelPlaceholderText}
                    size="large"
                    message={{
                      type: 'error',
                      text: errors?.levelIds?.message || ''
                    }}
                    isSearch
                    disabled={instructor.locationIds.length === 0}
                  />
                </AppCardContent>
              </div>
              <div className="instructorsAddForm__content-detail-wrapper">
                <AppCardHeader title="Contact Details" />
                <AppCardContent className="instructorsAddForm__content-contact-details">
                  <AppPhoneNumberInput
                    label="Mobile Number*"
                    {...register('phoneNumber')}
                    value={instructor.phoneNumber}
                    onChange={handleChangePhoneNumber}
                    message={{
                      type: 'error',
                      text:
                        errors?.phoneNumber?.message ||
                        phoneNumberError.current ||
                        ''
                    }}
                  />
                  <AppInput
                    label="Email Address*"
                    {...register('email')}
                    value={instructor.email}
                    onChange={handleChange}
                    message={{
                      type: 'error',
                      text: errors?.email?.message || ''
                    }}
                  />
                </AppCardContent>
              </div>
              <div className="instructorsAddForm__content-detail-wrapper">
                <AppCardHeader title="Credential" />
                <AppCard>
                  <AppTable columns={columns} data={credentialData} />
                </AppCard>
              </div>
            </div>
          </AppCard>
        </div>
        <div className="instructorsAddForm__actions">
          <AppButton
            isLoading={loadingOnSubmit}
            onClick={handleSubmit(onSubmit)}
            disabled={loadingOnSubmit}
          >
            Save
          </AppButton>
          <AppButton
            variant="secondary"
            onClick={handleCloseForm}
            disabled={loadingOnSubmit}
          >
            Cancel
          </AppButton>
        </div>
      </div>
    </div>
  );
};
export default InstructorAddForm;
