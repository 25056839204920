import API from './API';
import {
  ASSESSMENT_REVIEW_STATUS,
  ASSESSMENT_STATUS
} from 'common/enums/assessment.enum';
import {
  CreateAssessmentResultsDTO,
  CreateAssessmentResultsBatchDTO,
  UpdateAssessmentResultsDTO,
  CreateNewAssessmentResultsBatchDTO
} from 'DTOs/assessment.dto';

const ASSESSMENT_RESULT_ENDPOINT = '/assessment-results';

export const getAssessmentResults = (
  levelId: string,
  classId: string,
  instructorId: string,
  reviewStatus: ASSESSMENT_REVIEW_STATUS,
  page: number,
  limit: number,
  keyword: string,
  assessmentStatus: ASSESSMENT_STATUS
) => {
  const params: {
    levelId?: string;
    classId?: string;
    instructorId?: string;
    reviewStatus: ASSESSMENT_REVIEW_STATUS;
    page: number;
    limit: number;
    keyword: string;
    assessmentStatus?: ASSESSMENT_STATUS;
  } = {
    reviewStatus,
    page,
    limit,
    keyword
  };
  if (!!levelId) params.levelId = levelId;
  if (!!classId) params.classId = classId;
  if (!!instructorId) params.instructorId = instructorId;
  if (!!assessmentStatus) params.assessmentStatus = assessmentStatus;
  return API.get(`${ASSESSMENT_RESULT_ENDPOINT}`, params);
};

export const getAssessmentResultsHistory = (
  classId: string,
  page: number,
  limit: number,
  keyword: string
) => {
  return API.get(`${ASSESSMENT_RESULT_ENDPOINT}/history`, {
    classId,
    page,
    limit,
    keyword
  });
};

export const getAssessmentResultsByStudent = (
  studentId: string,
  classId: string,
  page: number,
  limit: number,
  keyword: string
) => {
  return API.get(`${ASSESSMENT_RESULT_ENDPOINT}/student`, {
    studentId,
    classId,
    page,
    limit,
    keyword
  });
};

export const createAssessmentResults = (
  payload: CreateAssessmentResultsDTO
) => {
  return API.post(`${ASSESSMENT_RESULT_ENDPOINT}/`, {
    body: payload
  });
};

export const createNewAssessmentResultsBatch = (
  payload: CreateNewAssessmentResultsBatchDTO
) => {
  return API.post(`${ASSESSMENT_RESULT_ENDPOINT}/batch-new-student-assessment`, {
    body: payload
  });
};

export const createAssessmentResultsBatch = (
  payload: CreateAssessmentResultsBatchDTO
) => {
  return API.post(`${ASSESSMENT_RESULT_ENDPOINT}/batch`, {
    body: payload
  });
};

export const approveAssessmentResults = (assessmentId: string) => {
  return API.post(`${ASSESSMENT_RESULT_ENDPOINT}/${assessmentId}/approve`);
};

export const bulkApproveAssessmentResults = (assessmentIds: Array<string>) => {
  return API.post(`${ASSESSMENT_RESULT_ENDPOINT}/bulk-approve`, {
    body: { assessmentIds }
  });
};

export const reassessAssessmentResults = (assessmentId: string) => {
  return API.post(`${ASSESSMENT_RESULT_ENDPOINT}/${assessmentId}/reassess`);
};

export const updateAssessmentResults = (
  assessmentId: string,
  payload: UpdateAssessmentResultsDTO
) => {
  return API.patch(`${ASSESSMENT_RESULT_ENDPOINT}/${assessmentId}`, {
    body: payload
  });
};
