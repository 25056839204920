import {
  CreateTestFirebaseNotificationDTO,
  FirebaseNotificationTableDTO,
  UpdateFirebaseNotificationSettingDTO
} from 'DTOs/firebase.dto';
import API from './API';

const FIREBASE_NOTIFICATIONS_APIS_ENDPOINT = `/notifications`;
const FIREBASE_USER_API_ENDPOINT = '/users';

export const getFirebaseNotifications = (
  params: FirebaseNotificationTableDTO
) => {
  return API.get(
    `${FIREBASE_NOTIFICATIONS_APIS_ENDPOINT}/filter-notification-firebase`,
    params
  );
};

export const createTestFirebaseNotification = (
  payload: CreateTestFirebaseNotificationDTO
) => {
  return API.post(`${FIREBASE_NOTIFICATIONS_APIS_ENDPOINT}/test-notification`, {
    body: payload
  });
};

export const updateUserFcmToken = (
  id: string,
  payload: { oldToken: string; newToken: string }
) => {
  return API.patch(`${FIREBASE_USER_API_ENDPOINT}/update-fcm-token/${id}`, {
    body: payload
  });
};

export const getFirebaseNotificationSettings = () => {
  return API.get(`${FIREBASE_NOTIFICATIONS_APIS_ENDPOINT}/user-role-setting`);
};

export const updateNotificationSettings = async (
  id: string,
  payload: UpdateFirebaseNotificationSettingDTO
) => {
  return API.patch(
    `${FIREBASE_NOTIFICATIONS_APIS_ENDPOINT}/user-role-setting/${id}`,
    {
      body: payload
    }
  );
};

export const updateNotificationRead = (id: string) => {
  return API.patch(`${FIREBASE_NOTIFICATIONS_APIS_ENDPOINT}/read/${id}`);
};
