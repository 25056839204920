import { object as OBJECT, string, number } from 'yup';

const START_DATE = string().required('Please provide date');
const END_DATE = string();
const CLASS_CAPACITY = number()
  .typeError('Capacity must be a number')
  .integer()
  .min(0, 'Capacity must be equal or greater than 0')
  .max(10000, 'Capacity must be less than 10000');

const validations = {
  OBJECT,
  START_DATE,
  END_DATE,
  string,
  number,
  CLASS_CAPACITY
};
export default validations;
