import {
  useRef,
  useState,
  lazy,
  useEffect,
  Suspense,
  useCallback,
  memo
} from 'react';
import { useSearchParams } from 'react-router-dom';
import StudentList, { RefHandle } from 'components/Student/StudentList';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';

import './desktop.scss';

const RPAddStudent = lazy(() => import('../RPAddStudent'));

interface Props {
  onUpdated: () => void;
}

const RPStudentList = ({ onUpdated }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const ref = useRef<RefHandle>(null);

  const [openAdd, setOpenAdd] = useState(false);

  const onOpenAdd = useCallback(() => setOpenAdd(true), []);

  const onCloseAdd = useCallback(() => {
    searchParams.delete('directAddStudent');
    setSearchParams(searchParams);

    setOpenAdd(false);

    ref.current?.refetchReset();

    onUpdated();
  }, [onUpdated, searchParams, setSearchParams]);

  const onAddSuccess = useCallback(() => {
    onCloseAdd();
  }, [onCloseAdd]);

  const isDirectAddStudent = searchParams.get('directAddStudent');

  useEffect(() => {
    if (!!isDirectAddStudent) {
      onOpenAdd();
    } else {
      onCloseAdd();
    }
  }, [isDirectAddStudent, onOpenAdd, onCloseAdd]);

  return (
    <>
      <section className="responsible_person_student_session">
        <div className="student">
          {/* ADD */}
          <PermissionWrapper
            permission={PERMISSION.ADD_STUDENT_TO_RESPONSIBLE_PERSON}
          >
            <Suspense>
              <RPAddStudent
                openAdd={openAdd}
                onCloseAdd={onCloseAdd}
                onSuccess={onAddSuccess}
              />
            </Suspense>
          </PermissionWrapper>

          <StudentList ref={ref} byResponsiblePerson onOpenAdd={onOpenAdd} />
        </div>
      </section>
    </>
  );
};

export default memo(RPStudentList);
