import React, { useMemo } from 'react';
import { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import './styles.scss';

type Size = 'large' | 'small' | 'x-small';

interface IAppTimePickerProps extends TimePickerProps<any> {
  value: Dayjs | null;
  onChange: (value: Dayjs | null) => void;
  label: string;
  size?: Size;
  noFloatingLabel?: boolean;
  message?: {
    type: 'error' | 'success' | 'caption';
    text: string;
  };
  disabled?: boolean;
}

const AppTimePicker = React.forwardRef(
  (props: IAppTimePickerProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      value,
      onChange,
      label,
      size = 'large',
      noFloatingLabel = false,
      message,
      // OPTIONAL
      disabled = false,
      ampm = false,
      minutesStep = 30
    } = props;

    const __renderMessage = useMemo(() => {
      if (!message) return null;
      return (
        <p
          className={`c__timePicker-message c__timePicker-message-${message.type}`}
        >
          {message.text}
        </p>
      );
    }, [message]);

    return (
      <div
        className={`c__timePicker_wrapper c__timePicker_wrapper-${size} ${
          noFloatingLabel ? 'c__timePicker_wrapper-noFloatingLabel' : ' '
        }  ${
          message?.text ? `c__timePicker_wrapper-border-${message?.type}` : ''
        } ${disabled ? 'disabled' : ''}`}
        ref={ref}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <TimePicker
              {...props}
              views={['hours', 'minutes']}
              skipDisabled
              label={label}
              value={value}
              onChange={onChange}
              // Optional
              disabled={disabled}
              ampm={ampm}
              minutesStep={minutesStep}
              // slotProps={{
              //   textField: {
              //     onBeforeInput: (event: React.FormEvent<HTMLDivElement>) => {
              //       event.preventDefault();
              //     }
              //   }
              // }}
            />
          </DemoContainer>
        </LocalizationProvider>

        {__renderMessage}
      </div>
    );
  }
);

export default AppTimePicker;
