import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import AppCard, {
  AppCardContent,
  AppCardHeader
} from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import AppButton from 'common/components/AppButton';
import AppSelect from 'common/components/AppSelect';
import LevelSkillAdd from './components/LevelSkillAdd';
import {
  CreateLevelDTO,
  CreateLevelDTOPayload,
  CreateSkillDTO
} from 'DTOs/levelBreakdown.dto';
import PickColor from 'pages/dashboard/calendar/PickColor';
import yupLevel from 'validators/level.validator';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  createLevelBreakdown,
  getLevelBreakdowns
} from 'services/levelBreakdown.service';
import { useToast } from 'context/ToastContext';
import LevelAssessmentScore from '../components/LevelAssessmentScore';
import {
  ERROR_MILESTONE_NAME,
  ERROR_SKILL_MILESTONES,
  ERROR_SKILL_NAME,
  initLevel,
  initMilestone,
  initSkill
} from '../constants/index';
import UploadAvatar, {
  ERROR_MESSAGE_LIMIT_SIZE
} from 'components/UploadAvatar';
import { uploadFile } from 'services/uploadFile.service';
import { FILE_ASSET_TYPE } from 'common/enums/uploadFile.enum';
import { ILevelBreakdown } from 'common/interfaces/levelBreakdown.interface';
import LevelAgeInput from '../components/LevelAgeInput';
import AppCKEditor from 'components/common/AppCKEditor';
import './desktop.scss';
import { useBrandLocation } from 'context/BrandLocationContext';
import AppSelection from 'common/components/AppSelection';

const validationSchema = yupLevel.OBJECT({
  name: yupLevel.LEVEL_NAME,
  shortName: yupLevel.LEVEL_SHORTNAME,
  fromAge: yupLevel.LEVEL_AGE_FROM,
  toAge: yupLevel.LEVEL_AGE_TO,
  colorCode: yupLevel.LEVEL_COLOR_CODE,
  description: yupLevel.LEVEL_DESCRIPTION,
  skills: yupLevel.LEVEL_SKILLS,
  assessmentScore: yupLevel.LEVEL_ASSESSMENT_SCORE,
  mascotImage: yupLevel.LEVEL_MASCOT_IMAGE,
  backgroundImage: yupLevel.LEVEL_BACKGROUND_IMAGE,
  nextLevelIds: yupLevel.LEVEL_NEXT_LEVEL,
  locationIds: yupLevel.LEVEL_LOCATION
});

interface ILevelBreakdownAddFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const LevelBreakdownAddForm = (props: ILevelBreakdownAddFormProps) => {
  const { open, onClose, onSuccess } = props;

  const toast = useToast();
  const { selectedLocation, locations } = useBrandLocation();

  const [loading, setLoading] = useState(false);

  const [level, setLevel] = useState<CreateLevelDTO>({ ...initLevel });

  const [skills, setSkills] = useState<Array<CreateSkillDTO>>([
    { ...initSkill }
  ]);

  const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);
  const [mascotImage, setMascotImage] = useState<File | null>(null);
  const [mascotImageUrl, setMascotImageUrl] = useState<string>('');
  const [bgImage, setBgImage] = useState<File | null>(null);
  const [bgImageUrl, setBgImageUrl] = useState<string>('');
  const [levelList, setLevelList] = useState<ILevelBreakdown[]>([]);

  const __locationPlaceholderText: string = useMemo(() => {
    return level.locationIds.length === 0
      ? 'Select option'
      : `${level.locationIds.length} selected`;
  }, [level.locationIds]);
  const locationList = useMemo(() => {
    return locations
      ?.filter((item) => item?._id)
      ?.map((item) => {
        return {
          label: item.shortName || item.name,
          value: item._id
        };
      });
  }, [locations]);

  const handleChangeLocation = (value: string) => {
    const locations = [...level.locationIds];
    const findIndex = locations.findIndex((item) => item === value);
    if (findIndex === -1) {
      locations.push(value);
    } else {
      locations.splice(findIndex, 1);
    }
    setLevel({
      ...level,
      locationIds: locations
    });
    setValue('locationIds', locations);
    trigger('locationIds');
  };

  const handleSelectAllLocation = () => {
    const values = locationList?.map((location) => location.value);
    if (level.locationIds.length === locationList?.length) {
      setLevel({
        ...level,
        locationIds: []
      });
      setValue('locationIds', []);
    } else {
      setLevel({
        ...level,
        locationIds: values
      });
      setValue('locationIds', values);
    }
    trigger('locationIds');
  };
  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<CreateLevelDTO>({
    resolver: yupResolver(validationSchema) as Resolver<CreateLevelDTO>,
    defaultValues: level
  });

  const fetchLevelBreakdownList = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getLevelBreakdowns(
        1,
        100,
        selectedLocation?._id || ''
      );
      var allLevels = result.data.data.data.map((item: ILevelBreakdown) => ({
        ...item,
        value: item._id,
        label: item.name
      }));

      allLevels.sort(function (a: any, b: any) {
        return a?.name.localeCompare(b?.name);
      });
      setLevelList(allLevels);
    } catch (error) {
      setLevelList([]);
    } finally {
      setLoading(false);
    }
  }, [selectedLocation]);

  useEffect(() => {
    fetchLevelBreakdownList();
  }, [fetchLevelBreakdownList]);

  // MILESTONE SECTION
  const handleAddMilestone = useCallback((skillPosition: number) => {
    setSkills((currentSkills) =>
      currentSkills.map((skill) => {
        if (skill.position === skillPosition)
          return {
            ...skill,
            milestones: [
              ...skill.milestones,
              { ...initMilestone, position: skill.milestones.length }
            ]
          };
        else return skill;
      })
    );
  }, []);

  const handleRemoveMilestone = useCallback(
    (skillPosition: number, milestonePosition: number) => {
      setSkills((currentSkills) =>
        currentSkills.map((skill) => {
          if (skill.position === skillPosition)
            return {
              ...skill,
              milestones: skill.milestones
                .filter((milestone) => milestone.position !== milestonePosition)
                .map((milestone, index: number) => ({
                  ...milestone,
                  position: index
                }))
            };
          else return skill;
        })
      );
    },
    []
  );

  const handleChangeMilestone = useCallback(
    (value: string, skillPosition: number, milestonePosition: number) => {
      setSkills((currentSkills) =>
        currentSkills.map((skill) => {
          if (skill.position === skillPosition)
            return {
              ...skill,
              milestones: skill.milestones.map((milestone) => {
                if (milestone.position === milestonePosition)
                  return {
                    ...milestone,
                    name: value
                  };
                else return milestone;
              })
            };
          else return skill;
        })
      );
    },
    []
  );

  // LEVEL SECTION
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLevel({
      ...level,
      [event.target.name]: event.target.value
    });
    // @ts-ignore
    setValue(event.target.name, event.target.value);
    // @ts-ignore
    trigger(event.target.name);
  };

  const handleChangeAge = (name: string, value: string) => {
    setLevel({
      ...level,
      [name]: value
    });
    // @ts-ignore
    setValue(name, value);
    // @ts-ignore
    trigger(name);
  };

  const handleToggleColorPicker = () => {
    setOpenColorPicker(!openColorPicker);
  };

  const handleChangeColor = (color: string) => {
    setLevel({
      ...level,
      colorCode: color
    });
  };

  const handleChangeDescription = (value: string) => {
    setLevel({
      ...level,
      description: value
    });
    setValue('description', value);
    trigger('description');
  };

  // SKILLS SECTION
  const handleAddSkill = () => {
    setSkills((currentSkills) => [
      ...currentSkills,
      {
        ...initSkill,
        position: skills.length
      }
    ]);
  };
  const handleChangeSkillName = useCallback(
    (skillName: string, position: number) => {
      setSkills((currentSkills) =>
        currentSkills.map((skill) => {
          if (skill.position === position)
            return {
              ...skill,
              name: skillName
            };
          else return skill;
        })
      );
    },
    []
  );

  const __canBeRemoved = useMemo((): boolean => {
    return skills.length !== 1;
  }, [skills]);

  const handleRemoveSkill = useCallback((position: number) => {
    setSkills((currentSkills) =>
      currentSkills
        // REMOVE BY POSITION
        .filter((skill) => skill.position !== position)
        // UPDATE POSITION
        .map((skill, index: number) => ({
          ...skill,
          position: index
        }))
    );
  }, []);

  // ASSESSMENT SECTION
  const handleChangeAssessmentScore = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLevel((currentLevel) => {
      return {
        ...currentLevel,
        assessmentScore: {
          ...currentLevel.assessmentScore,
          maxScore: parseInt(event.target.value)
        }
      };
    });
  };
  const handleAddAssessmentScoreDetail = () => {
    setLevel((currentLevel) => {
      return {
        ...currentLevel,
        assessmentScore: {
          ...currentLevel.assessmentScore,
          assessmentScoreDetails: [
            ...currentLevel.assessmentScore.assessmentScoreDetails,
            { fieldName: '', fieldValue: 0 }
          ]
        }
      };
    });
  };
  const handleRemoveAssessmentScoreDetail = (position: number) => {
    setLevel((currentLevel) => {
      return {
        ...currentLevel,
        assessmentScore: {
          ...currentLevel.assessmentScore,
          assessmentScoreDetails:
            currentLevel.assessmentScore.assessmentScoreDetails.filter(
              (_, index) => index !== position
            )
        }
      };
    });
  };
  const handleChangeAssessmentScoreDetail = (
    event: React.ChangeEvent<HTMLInputElement>,
    position: number
  ) => {
    setLevel((currentLevel) => {
      return {
        ...currentLevel,
        assessmentScore: {
          ...currentLevel.assessmentScore,
          assessmentScoreDetails:
            currentLevel.assessmentScore.assessmentScoreDetails.map(
              (assessmentScoreDetail, index: number) => {
                if (position === index)
                  return {
                    ...assessmentScoreDetail,
                    [event.target.name]:
                      event.target.name === 'fieldValue'
                        ? parseInt(event.target.value)
                        : event.target.value
                  };
                else return assessmentScoreDetail;
              }
            )
        }
      };
    });
  };

  const onSubmit = async () => {
    if (!mascotImage || !bgImage) {
      return;
    }
    try {
      setLoading(true);

      // Validate with state
      const tempSkills = [...skills].map((skill) => {
        return {
          ...skill,
          error: !skill.name
            ? ERROR_SKILL_NAME
            : skill.milestones.length === 0
            ? ERROR_SKILL_MILESTONES
            : '',
          milestones: skill.milestones.map((milestone) => {
            return {
              ...milestone,
              error: !milestone.name ? ERROR_MILESTONE_NAME : ''
            };
          })
        };
      });
      setSkills(tempSkills);

      const isHaveErrors = tempSkills.some((skill) => {
        return (
          !!skill?.error ||
          skill.milestones.some((milestone) => {
            return !!milestone?.error;
          })
        );
      });
      if (isHaveErrors) return;

      const payload: CreateLevelDTOPayload = {
        ...level,
        skills: skills.map(({ error, ...skill }) => {
          return {
            ...skill,
            milestones: skill.milestones.map(({ error, ...milestone }) => {
              return {
                ...milestone
              };
            })
          };
        }),
        mascotImage: mascotImageUrl,
        backgroundImage: bgImageUrl,
        isAllLocation: level.locationIds.length === locationList?.length,
        nextLevelIds: level.nextLevelIds?.split(',').filter((id) => !!id)
      };
      if (!payload.nextLevelIds) {
        delete payload.nextLevelIds;
      }

      // Validations with toast
      //assessment score
      if (
        !payload.assessmentScore.maxScore ||
        payload.assessmentScore.maxScore <= 0
      ) {
        toast.error(`Please correct the maximum score`);
        return;
      }
      for (const assessmentScoreDetail of payload.assessmentScore
        .assessmentScoreDetails) {
        if (
          !assessmentScoreDetail.fieldName ||
          !assessmentScoreDetail.fieldValue
        ) {
          toast.error(
            `Please fulfil information for the assessment score details`
          );
          return;
        }
        if (
          0 > +assessmentScoreDetail.fieldValue ||
          +assessmentScoreDetail.fieldValue > +payload.assessmentScore.maxScore
        ) {
          toast.error(
            `Please correct the score for the assessment score details`
          );
          return;
        }
      }

      await createLevelBreakdown(payload);
      // RESET
      setLevel({ ...initLevel });
      reset(initLevel);
      setSkills([{ ...initSkill }]);
      setOpenColorPicker(false);
      toast.success('Create level breakdown successfully');
      setTimeout(() => {
        onSuccess();
      }, 200);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to create level breakdown'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUploadMascotImageFile = async (file: File) => {
    if (file) {
      const result = await uploadFile(FILE_ASSET_TYPE.LEVEL, file);

      setMascotImageUrl(result.data.data || '');

      setMascotImage(file);
    }
  };

  const handleUploadBgImageFile = async (file: File) => {
    if (file) {
      const result = await uploadFile(FILE_ASSET_TYPE.LEVEL, file);

      setBgImageUrl(result.data.data || '');

      setBgImage(file);
    }
  };

  return (
    <div className="levelTemplateAddFormContainer">
      <div
        className={`overlay ${open ? 'active' : ' '}`}
        onClick={onClose}
      ></div>
      <div className={`levelTemplateAddForm ${open ? 'active' : ' '}`}>
        <div className="levelTemplateAddForm__header">
          <div onClick={onClose}>
            <HiArrowLeft size={24} />
          </div>
          <p>Add Level Template</p>
        </div>
        <div className="levelTemplateAddForm__content">
          <AppCard>
            <div className="levelTemplateAddForm__content-wrapper">
              <div className="levelTemplateAddForm__content-wrapper-left">
                <div className="levelTemplateAddForm__content-levelWrapper">
                  <AppCardHeader title="Level" />
                  <AppCardContent>
                    <AppInput
                      label="Name of the level*"
                      {...register('name')}
                      value={level.name}
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.name?.message || ''
                      }}
                    />
                    <AppInput
                      label="Level Short name*"
                      {...register('shortName')}
                      value={level.shortName}
                      onChange={handleChange}
                      message={{
                        type: 'error',
                        text: errors?.shortName?.message || ''
                      }}
                    />

                    <LevelAgeInput
                      label="Age from"
                      {...register('fromAge')}
                      value={level.fromAge}
                      onChangeAge={(value: string) =>
                        handleChangeAge('fromAge', value)
                      }
                      message={{
                        type: 'error',
                        text: errors?.fromAge?.message || ''
                      }}
                    />
                    <LevelAgeInput
                      label="Age to"
                      {...register('toAge')}
                      value={level.toAge}
                      onChangeAge={(value: string) =>
                        handleChangeAge('toAge', value)
                      }
                      message={{
                        type: 'error',
                        text: errors?.toAge?.message || ''
                      }}
                    />
                    <AppSelection
                      label="Location"
                      options={locationList}
                      onSelect={handleChangeLocation}
                      onSelectAll={handleSelectAllLocation}
                      selectedIds={level.locationIds}
                      placeholder={__locationPlaceholderText}
                      message={{
                        type: 'error',
                        text: errors?.locationIds?.message || ''
                      }}
                      size="large"
                      isSearch
                    />

                    <div className="colorPickerWrapper">
                      <div
                        className="colorPicker"
                        style={{
                          backgroundColor: level.colorCode
                        }}
                        onClick={handleToggleColorPicker}
                      ></div>
                      <PickColor
                        onClose={() => setOpenColorPicker(false)}
                        classColor={level.colorCode}
                        onChangeItemColor={handleChangeColor}
                        showPickColor={openColorPicker}
                      />
                    </div>
                  </AppCardContent>
                </div>
                <div className="levelTemplateAddForm__content__description">
                  <p className="levelTemplateAddForm__content__description--label">
                    Description
                  </p>
                  <AppCKEditor
                    value={level.description}
                    onChange={handleChangeDescription}
                  />
                  {errors?.description?.message && (
                    <p className="levelTemplateAddForm__content__description--error">
                      {errors?.description?.message}
                    </p>
                  )}
                </div>
                <div className="levelTemplateAddForm__content-skillWrapper">
                  <AppCardHeader title="Skill" />
                  <AppCardContent className="skills">
                    {skills.length > 0 &&
                      skills.map((skill: CreateSkillDTO, index: number) => {
                        return (
                          <LevelSkillAdd
                            key={skill.position}
                            skill={skill}
                            onChangeSkillName={(skillName) =>
                              handleChangeSkillName(skillName, skill.position)
                            }
                            onRemoveSkill={handleRemoveSkill}
                            onAddMilestone={() =>
                              handleAddMilestone(skill.position)
                            }
                            onRemoveMilestone={(milestonePosition) =>
                              handleRemoveMilestone(
                                skill.position,
                                milestonePosition
                              )
                            }
                            onChangeMilestone={(value, milestonePosition) =>
                              handleChangeMilestone(
                                value,
                                skill.position,
                                milestonePosition
                              )
                            }
                            canBeRemoved={__canBeRemoved}
                            index={index}
                          />
                        );
                      })}
                  </AppCardContent>
                  <AppButton
                    buttonSize="small"
                    variant="secondary"
                    className="levelTemplateAddForm__content-skillWrapper-addMore"
                    onClick={handleAddSkill}
                  >
                    Add more
                  </AppButton>
                </div>
              </div>

              <div className="levelTemplateAddForm__content-wrapper-right">
                <LevelAssessmentScore
                  assessmentScore={level.assessmentScore}
                  onChangeAssessmentScore={handleChangeAssessmentScore}
                  onAddAssessmentScoreDetail={handleAddAssessmentScoreDetail}
                  onRemoveAssessmentScoreDetail={
                    handleRemoveAssessmentScoreDetail
                  }
                  onChangeAssessmentScoreDetail={
                    handleChangeAssessmentScoreDetail
                  }
                />
              </div>
            </div>
            {/* IMAGE */}
            <div className="levelTemplateAddForm__content-imageWrapper">
              <AppCardHeader title="Level Mascot image" />
              <div className="levelTemplateAddForm__content-imageWrapper-images">
                <div>
                  <p className="levelTemplateAddForm__content-imageWrapper-images-title">
                    Mascot Image*
                  </p>
                  <UploadAvatar
                    file={mascotImage}
                    onChangeFile={handleUploadMascotImageFile}
                    onErrorFile={(errorMessage: string) => {
                      if (errorMessage === ERROR_MESSAGE_LIMIT_SIZE) {
                        toast.error('Exceed the MB');
                      }
                    }}
                    ratio={[1, 1]}
                  />
                  {!!mascotImageUrl ? null : (
                    <p className="levelTemplateAddForm__content-imageWrapper-images-error">
                      Please provide mascot image!
                    </p>
                  )}
                </div>
                <div>
                  <p className="levelTemplateAddForm__content-imageWrapper-images-title">
                    Background Image*
                  </p>
                  <UploadAvatar
                    file={bgImage}
                    onChangeFile={handleUploadBgImageFile}
                    onErrorFile={(errorMessage: string) => {
                      if (errorMessage === ERROR_MESSAGE_LIMIT_SIZE) {
                        toast.error('Exceed the MB');
                      }
                    }}
                    resolution={[350, 850]}
                  />
                  {!!bgImageUrl ? null : (
                    <p className="levelTemplateAddForm__content-imageWrapper-images-error">
                      Please provide background image!
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* END IMAGE */}
            {/* NEXT LEVEL */}
            <div className="levelTemplateAddForm__content-nextLevelWrapper">
              <AppCardHeader title="Add next level" />
              <AppSelect
                options={levelList}
                {...register('nextLevelIds')}
                value={level.nextLevelIds || ''}
                onChange={handleChange}
                label="Next level"
                multiValue
              />
            </div>
            {/* END NEXT LEVEL */}
          </AppCard>
        </div>

        <div className="levelTemplateAddForm__actions">
          <AppButton isLoading={loading} onClick={handleSubmit(onSubmit)}>
            Save
          </AppButton>
          <AppButton variant="secondary" onClick={onClose}>
            Cancel
          </AppButton>
        </div>
      </div>
    </div>
  );
};

export default LevelBreakdownAddForm;
