import API from './API';

const LOGS_URL = process.env.REACT_APP_LOGS_URL;

const LOGS_ENDPOINT = `/logs/filter`;
const STUDENT_LOGS_ENDPOINT = `/student-logs/filter`;
const STUDENT_ACTIVITIES_ENDPOINT = `/student-activities/filter`;

export const getLogs = (page: number, limit: number) => {
  return API.get(`${LOGS_URL}${LOGS_ENDPOINT}`, {
    page,
    limit
  });
};

export const getStudentLogs = (
  locationId: string,
  page: number,
  limit: number,
  keyword: string,
  what: string,
  fromDate?: number,
  toDate?: number
) => {
  return API.get(`${LOGS_URL}${STUDENT_LOGS_ENDPOINT}`, {
    locationId,
    page,
    limit,
    keyword,
    what,
    fromDate: fromDate,
    toDate: toDate ? toDate + 24 * 60 * 60 * 1000 : undefined
  });
};

export const getStudentActivities = (
  locationId: string,
  page: number,
  limit: number,
  keyword: string,
  what: string,
  fromDate?: number,
  toDate?: number
) => {
  return API.get(`${LOGS_URL}${STUDENT_ACTIVITIES_ENDPOINT}`, {
    locationId,
    page,
    limit,
    keyword,
    what,
    fromDate: fromDate,
    toDate: toDate ? toDate + 24 * 60 * 60 * 1000 : undefined
  });
};
