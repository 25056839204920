export enum CLASS_TYPES {
  SERIES = 'series',
  PRIVATE = 'private',
  ASSESSMENT_TRIAL = 'assessment_trial',
  INTENSIVE_HOLIDAY_PROGRAM = 'intensive_holiday_program',
  NONE = ''
}
export enum RECURRENCE_VALUES {
  DO_NOT_REPEAT = 'dont_repeat',
  CUSTOM = 'custom',
  WEEKLY = 'weekly'
}

export enum TAG {
  CURRENT = 'current',
  PAST = 'past',
  CANCELLED = 'cancelled',
  CANCELLATION_REQUESTED = 'cancellation_requested',
  MOVED = 'moved'
}
