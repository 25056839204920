import React, { useRef, useState } from 'react';
import { HiMiniEllipsisVertical } from 'react-icons/hi2';

interface IActionPopperProps {
  onViewStudent?: () => void;
}

const ActionPopper: React.FC<IActionPopperProps> = (
  props: IActionPopperProps
) => {
  const { onViewStudent } = props;

  const popperRef = useRef<any>(null);
  const verticalButtonRef = useRef<any>(null);

  const [open, setOpen] = useState<boolean>(false);

  const onToggle = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // Close popper if click outside the popper
    const handleClickOutside = (event: MouseEvent) => {
      // Click at vertical button to close
      if (
        verticalButtonRef.current &&
        verticalButtonRef.current.contains(event.target)
      ) {
        return;
      }
      // Handle click outside to close pop up
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        onClose();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [verticalButtonRef, popperRef]);

  return (
    <React.Fragment>
      <div ref={verticalButtonRef} className="ellipsisVertical">
        <HiMiniEllipsisVertical onClick={onToggle} />
        {open && (
          <div ref={popperRef} className="viewDetailPopup" onClick={onClose}>
            <div
              className="viewDetailPopup-item"
              onClick={onViewStudent && onViewStudent}
            >
              View Student
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ActionPopper;
