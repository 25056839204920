import AppBreadCrumb from 'components/common/AppBreadcrumb';
import {
  ERROR_MESSAGE_DATE,
  FORMAT_END_OF_DATE,
  FORMAT_START_OF_DATE,
  REPORT_PATH,
  REPORT_PAYMENT_TYPE_OPTIONS,
  REPORT_PROGRAM_TYPE_OPTIONS
} from '../constant';
import AppDatePicker from 'common/components/AppDatePicker';
import AppSelect from 'common/components/AppSelect';
import AppButton from 'common/components/AppButton';
import { exportMemberReport, getMemberReport } from 'services/report.service';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import { convertToUnixTime } from 'common/helpers/time.helper';
import dayjs, { Dayjs } from 'dayjs';
import { useToast } from 'context/ToastContext';
import ReportMemberSummaryView from './ReportMemberSummaryView';
import { IMemberReport } from 'common/interfaces/report.interface';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import ReportMemberDetailedView from './ReportMemberDetailedView';
import { useBrandLocation } from 'context/BrandLocationContext';

import './desktop.scss';
import { formatDate } from 'common/helpers/dataFormat.helper';

const ReportMember = () => {
  const toast = useToast();
  const { selectedLocation: globalLocation } = useBrandLocation();

  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [programType, setProgramType] = useState<CLASS_TYPES>(CLASS_TYPES.NONE);

  const [paymentType, setPaymentType] = useState<PAYMENT_VALUE>(
    PAYMENT_VALUE.IDLE
  );

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [data, setData] = useState<IMemberReport>();

  const [loading, setLoading] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  const [filterBy, setFilterBy] = useState<'joiner' | 'leaver' | ''>('');

  const dateError = useRef('');

  const currenFilter = useRef<{
    locationId: string;
    dateFrom: number;
    dateTo: number;
    programType: CLASS_TYPES;
    paymentType: string;
    filterBy?: string;
    page?: number;
    limit?: number;
  }>({
    locationId: '',
    dateFrom: 0,
    dateTo: 0,
    programType: CLASS_TYPES.NONE,
    paymentType: PAYMENT_VALUE.IDLE,
    filterBy: '',
    page: 0,
    limit: 0
  });

  const fetchGeneratedReport = useCallback(async () => {
    if (!globalLocation?._id) return;

    try {
      setLoading(true);

      const { data } = await getMemberReport(currenFilter.current);

      setData(data?.data);

      setIsGenerated(true);

      setProgramType(currenFilter.current.programType);
      setPaymentType(currenFilter.current.paymentType as PAYMENT_VALUE);

      setDateFrom(
        dayjs(currenFilter.current.dateFrom)?.format(FORMAT_START_OF_DATE)
      );
      setDateTo(
        dayjs(currenFilter.current.dateTo)?.format(FORMAT_START_OF_DATE)
      );
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to get member report'
      );
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [globalLocation?._id, dateFrom, dateTo, programType, paymentType]);

  useEffect(() => {
    if (!isGenerated) return;

    currenFilter.current = {
      ...currenFilter.current,
      filterBy,
      page,
      limit
    };

    fetchGeneratedReport();

    // eslint-disable-next-line
  }, [filterBy, page, limit, isGenerated]);

  const handleChangeProgramType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setProgramType(event.target.value as CLASS_TYPES);
  };

  const handleChangePaymentType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPaymentType(event.target.value as PAYMENT_VALUE);
  };

  const handleChangeDateFrom = (value: Dayjs | null) => {
    setDateFrom(value?.format(FORMAT_START_OF_DATE) || '');
    if (value?.isAfter(dayjs(dateTo))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeDateTo = (value: Dayjs | null) => {
    setDateTo(value?.format(FORMAT_END_OF_DATE) || '');
    if (value?.isBefore(dayjs(dateFrom))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };

  const __isDisableGenerateButton: boolean = useMemo(() => {
    return (
      !dateFrom ||
      !dateTo ||
      !programType ||
      !paymentType ||
      !!dateError.current
    );
  }, [dateFrom, dateTo, programType, paymentType, dateError]);

  const handleGenerate = () => {
    if (!globalLocation?._id) return;

    currenFilter.current = {
      locationId: globalLocation?._id,
      dateFrom: convertToUnixTime(dateFrom),
      dateTo: convertToUnixTime(dateTo),
      programType: programType as CLASS_TYPES,
      paymentType: paymentType as PAYMENT_VALUE,
      filterBy,
      page,
      limit
    };

    fetchGeneratedReport();
  };

  const handleExportReport = async () => {
    if (!globalLocation?._id) return;
    setLoadingExport(true);
    try {
      const params = { ...currenFilter.current };
      delete params.page;
      delete params.limit;
      const result = await exportMemberReport(params);

      const url = window.URL.createObjectURL(
        new Blob([result?.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Member Report ${formatDate(dateFrom)} ${formatDate(dateTo)}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to export Member report'
      );
    } finally {
      setLoadingExport(false);
    }
  };

  return (
    <main id="reportMember" className="reportMember">
      {loadingExport && (
        <div className="reportTimetableByTeacher__loadingExport">
          <AppLoadingContainer />
        </div>
      )}
      <AppBreadCrumb
        items={[
          { name: 'Reporting', href: REPORT_PATH },
          { name: 'Member Report' }
        ]}
      />
      <div className="layoutContainer reportMember__wrapper">
        <section className="reportMember__search">
          <div className="reportMember__search__fields">
            <AppDatePicker
              size="small"
              label="Date from"
              value={dayjs(dateFrom)}
              onChange={handleChangeDateFrom}
            />
            <AppDatePicker
              size="small"
              label="Date to"
              value={dayjs(dateTo)}
              onChange={handleChangeDateTo}
              message={{
                type: 'error',
                text: dateError.current
              }}
            />
            <AppSelect
              searchable={false}
              inputSize="small"
              label="Payment Type"
              placeholder="Select option"
              options={REPORT_PAYMENT_TYPE_OPTIONS}
              value={paymentType}
              onChange={handleChangePaymentType}
            />

            <AppSelect
              searchable={false}
              inputSize="small"
              label="Program Type"
              placeholder="Select option"
              options={REPORT_PROGRAM_TYPE_OPTIONS}
              value={programType}
              onChange={handleChangeProgramType}
            />
          </div>
          <AppButton
            variant="primary"
            buttonSize="small"
            isLoading={loading}
            disabled={__isDisableGenerateButton}
            onClick={handleGenerate}
          >
            generate
          </AppButton>
        </section>

        {!isGenerated && loading && <AppLoadingContainer />}

        {data && isGenerated ? <ReportMemberSummaryView data={data} /> : null}
        {data && isGenerated ? (
          <ReportMemberDetailedView
            data={data?.members}
            limit={limit}
            page={page}
            loading={loading}
            onChangeLimit={setLimit}
            onChangePage={setPage}
            onChangeMemberStatus={setFilterBy}
            onExportReport={handleExportReport}
          />
        ) : null}
      </div>
    </main>
  );
};

export default ReportMember;
