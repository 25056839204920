export const auditReportData: Array<any> = [
  {
    id: 'xDOogWNNvr',
    date: '11/5/2122',
    clickedBy: 'Luella Olson',
    member: '--',
    location: 'CISAC',
    class: 'SA-L1'
  },
  {
    id: 'hKfBM',
    date: '9/23/2025',
    clickedBy: 'Lora Rodriguez',
    member: '--',
    location: 'CISAC',
    class: 'SA-L1'
  },
  {
    id: 'SVCJRRBNjX',
    date: '2/27/2032',
    clickedBy: 'Howard Estrada',
    member: '--',
    location: 'CISAC',
    class: 'SA-L1'
  },
  {
    id: 'dRetUm',
    date: '3/12/2077',
    clickedBy: 'Helen Simmons',
    member: '--',
    location: 'CISAC',
    class: 'SA-L1'
  },
  {
    id: 'HmcfGnzfNArqEDNmQ',
    date: '8/1/2117',
    clickedBy: 'Elijah Baldwin',
    member: '--',
    location: 'CISAC',
    class: 'SA-L1'
  },
  {
    id: 'LsCnl',
    date: '2/22/2056',
    clickedBy: 'Charles Ellis',
    member: '--',
    location: 'CISAC',
    class: 'SA-L1'
  },
  {
    id: 'YPCGSHqGjLwHER',
    date: '5/18/2048',
    clickedBy: 'Mike Marshall',
    member: '--',
    location: 'CISAC',
    class: 'SA-L1'
  },
  {
    id: 'RjlPDsHHjxTB',
    date: '10/19/2093',
    clickedBy: 'Madge Turner',
    member: '--',
    location: 'CISAC',
    class: 'SA-L1'
  },
  {
    id: 'GrIGEYeaXQmsfHylh',
    date: '8/4/2061',
    clickedBy: 'Birdie Barton',
    member: '--',
    location: 'CISAC',
    class: 'SA-L1'
  },
  {
    id: 'PjpGrqbtRcVVpmk',
    date: '5/4/2036',
    clickedBy: 'Troy Maxwell',
    member: '--',
    location: 'CISAC',
    class: 'SA-L1'
  },
  {
    id: 'WaWyAECHUrPHHo',
    date: '9/8/2116',
    clickedBy: 'Callie Nguyen',
    member: '--',
    location: 'CISAC',
    class: 'SA-L1'
  },
  {
    id: 'QNnzsuCzmUeokc',
    date: '3/2/2032',
    clickedBy: 'Madge Hardy',
    member: '--',
    location: 'CISAC',
    class: 'SA-L1'
  }
];
