import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateLevelDTO } from 'DTOs/levelBreakdown.dto';
import AppCard, {
  AppCardContent,
  AppCardContentItem,
  AppCardHeader
} from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import { useToast } from 'context/ToastContext';
import { formatData } from 'common/helpers/dataFormat.helper';
import { ILevel } from 'common/interfaces/levelBreakdown.interface';
import PickColor from 'pages/dashboard/calendar/PickColor';
import { Resolver, useForm } from 'react-hook-form';
import { HiOutlineBookmark, HiOutlineXCircle } from 'react-icons/hi';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import yupLevel from 'validators/level.validator';
import { updateLevelBreakdown } from 'services/levelBreakdown.service';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import UploadAvatar, {
  ERROR_MESSAGE_LIMIT_SIZE
} from 'components/UploadAvatar';
import { uploadFile } from 'services/uploadFile.service';
import { FILE_ASSET_TYPE } from 'common/enums/uploadFile.enum';
import LevelAgeInput from 'pages/levels/components/LevelAgeInput';
import AppCKEditor from 'components/common/AppCKEditor';
import { useBrandLocation } from 'context/BrandLocationContext';
import AppSelection from 'common/components/AppSelection';
import LocationListInfo from 'components/ListInfo';

const validationSchema = yupLevel.OBJECT({
  name: yupLevel.LEVEL_NAME,
  shortName: yupLevel.LEVEL_SHORTNAME,
  fromAge: yupLevel.LEVEL_AGE_FROM,
  toAge: yupLevel.LEVEL_AGE_TO,
  colorCode: yupLevel.LEVEL_COLOR_CODE,
  description: yupLevel.LEVEL_DESCRIPTION,
  mascotImage: yupLevel.LEVEL_MASCOT_IMAGE,
  backgroundImage: yupLevel.LEVEL_BACKGROUND_IMAGE,
  isAllLocation: yupLevel.LEVEL_IS_ALL_LOCATION,
  locationIds: yupLevel.LEVEL_LOCATION
});

interface ILevelsBreakdownInformationProps {
  data: ILevel;
  onSuccess: () => void;
  isCanEdit: boolean;
}

const LevelsBreakdownInformation = (
  props: ILevelsBreakdownInformationProps
) => {
  const { data, onSuccess } = props;

  const params = useParams();
  const toast = useToast();
  const { locations } = useBrandLocation();

  const [loading, setLoading] = useState<boolean>(false);

  const [level, setLevel] = useState<UpdateLevelDTO>({
    ...data,
    locationIds: data.locations.map((location) => location._id),
    nextLevelIds: data.nextLevels.map((level) => level._id)
  });

  const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [mascotImage, setMascotImage] = useState<File | null>(null);
  const [mascotImageUrl, setMascotImageUrl] = useState<string>(
    data.mascotImage
  );
  const [bgImage, setBgImage] = useState<File | null>(null);
  const [bgImageUrl, setBgImageUrl] = useState<string>(data.backgroundImage);

  const __locationPlaceholderText: string = useMemo(() => {
    return level.locationIds.length === 0
      ? 'Select option'
      : `${level.locationIds.length} selected`;
  }, [level.locationIds]);
  const locationList = useMemo(() => {
    return locations
      ?.filter((item) => item?._id)
      ?.map((item) => {
        return {
          label: item.shortName || item.name,
          value: item._id
        };
      });
  }, [locations]);

  const handleChangeLocation = (value: string) => {
    const locations = [...level.locationIds];
    const findIndex = locations.findIndex((item) => item === value);
    if (findIndex === -1) {
      locations.push(value);
    } else {
      locations.splice(findIndex, 1);
    }
    setLevel({
      ...level,
      locationIds: locations
    });
    setValue('locationIds', locations);
    trigger('locationIds');
  };

  const handleSelectAllLocation = () => {
    const values = locationList?.map((location) => location.value);
    if (level.locationIds.length === locationList?.length) {
      setLevel({
        ...level,
        locationIds: []
      });
      setValue('locationIds', []);
    } else {
      setLevel({
        ...level,
        locationIds: values
      });
      setValue('locationIds', values);
    }
    trigger('locationIds');
  };
  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm<UpdateLevelDTO>({
    resolver: yupResolver(validationSchema) as Resolver<UpdateLevelDTO>,
    defaultValues: level
  });

  useEffect(() => {
    setMascotImageUrl(data.mascotImage);
    setBgImageUrl(data.backgroundImage);
    const level = {
      ...data,
      locationIds: data.locations.map((location) => location._id),
      nextLevelIds: data.nextLevels.map((level) => level._id)
    };
    setLevel({
      ...level
    });
    reset(level);
  }, [data, reset]);

  const getFileName = (url: string) => {
    if (!url) return '--';
    const name = url.split('/').pop()?.split('_').pop() || '--';
    return decodeURIComponent(name);
  };

  const handleUploadMascotImageFile = async (file: File) => {
    if (file) {
      const result = await uploadFile(FILE_ASSET_TYPE.LEVEL, file);

      setMascotImageUrl(result.data.data || '');

      setMascotImage(file);
      setValue('mascotImage', result.data.data);
    }
  };

  const handleUploadBgImageFile = async (file: File) => {
    if (file) {
      const result = await uploadFile(FILE_ASSET_TYPE.LEVEL, file);

      setBgImageUrl(result.data.data || '');

      setBgImage(file);
      setValue('backgroundImage', result.data.data);
    }
  };
  const onOpenEdit = () => {
    setIsEdit(true);
  };

  const onCloseEdit = () => {
    setIsEdit(false);
    const level = {
      ...data,
      locationIds: data.locations.map((location) => location._id),
      nextLevelIds: data.nextLevels.map((level) => level._id)
    };
    setLevel({ ...level });
    setOpenColorPicker(false);
    reset({ ...level });
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLevel({
        ...level,
        [event.target.name]: event.target.value
      });
      // @ts-ignore
      setValue(event.target.name, event.target.value);
      // @ts-ignore
      trigger(event.target.name);
    },
    [level, setValue, trigger]
  );

  const handleChangeAge = (name: string, value: string) => {
    setLevel({
      ...level,
      [name]: value
    });
    // @ts-ignore
    setValue(name, value);
    // @ts-ignore
    trigger(name);
  };

  const handleToggleColorPicker = useCallback(() => {
    setOpenColorPicker(!openColorPicker);
  }, [openColorPicker]);

  const handleChangeColor = useCallback(
    (color: string) => {
      setLevel({
        ...level,
        colorCode: color
      });
    },
    [level]
  );

  const handleChangeDescription = useCallback(
    (value: string) => {
      setLevel({
        ...level,
        description: value
      });
      setValue('description', value);
      trigger('description');
    },
    [level, setValue, trigger]
  );

  const onSubmit = async (data: UpdateLevelDTO) => {
    setLoading(true);
    try {
      const payload: UpdateLevelDTO = {
        name: data.name,
        shortName: data.shortName,
        fromAge: String(level.fromAge),
        toAge: String(level.toAge),
        colorCode: level.colorCode,
        description: level.description,
        backgroundImage: bgImageUrl || level.backgroundImage,
        mascotImage: mascotImageUrl || level.mascotImage,
        nextLevelIds: data.nextLevelIds,
        locationIds: level.locationIds,
        isAllLocation: level.locationIds.length === locationList?.length
      };
      await updateLevelBreakdown(params?.id || '', payload);
      onSuccess();
      toast.success('Saved');
      setIsEdit(false);
      setOpenColorPicker(false);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message ||
          'Failed to save level breakdown information'
      );
    } finally {
      setLoading(false);
    }
  };

  const __renderIcons = useMemo((): React.ReactNode => {
    if (isEdit) {
      return loading ? (
        <BeatLoader color="white" />
      ) : (
        <>
          <div className="icon" onClick={handleSubmit(onSubmit)}>
            <HiOutlineBookmark />
          </div>
          <div className="icon" onClick={onCloseEdit}>
            <HiOutlineXCircle />
          </div>
        </>
      );
    }
    return (
      <div className="icon" onClick={onOpenEdit}>
        <HiOutlinePencilSquare />
      </div>
    );
    // eslint-disable-next-line
  }, [isEdit, onCloseEdit, loading]);

  const __renderContent = useMemo((): React.ReactNode => {
    if (isEdit) {
      return (
        <>
          <AppInput
            label="Name of the level*"
            {...register('name')}
            value={level.name}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.name?.message || ''
            }}
          />

          <AppInput
            label="Level Short name*"
            {...register('shortName')}
            value={level.shortName}
            onChange={handleChange}
            message={{
              type: 'error',
              text: errors?.shortName?.message || ''
            }}
          />

          <LevelAgeInput
            label="Age from"
            {...register('fromAge')}
            value={level.fromAge}
            onChangeAge={(value: string) => handleChangeAge('fromAge', value)}
            message={{
              type: 'error',
              text: errors?.fromAge?.message || ''
            }}
          />
          <LevelAgeInput
            label="Age to"
            {...register('toAge')}
            value={level.toAge}
            onChangeAge={(value: string) => handleChangeAge('toAge', value)}
            message={{
              type: 'error',
              text: errors?.toAge?.message || ''
            }}
          />
          <AppSelection
            label="Location"
            options={locationList}
            onSelect={handleChangeLocation}
            onSelectAll={handleSelectAllLocation}
            selectedIds={level.locationIds}
            placeholder={__locationPlaceholderText}
            size="large"
            message={{
              type: 'error',
              text: errors?.locationIds?.message || ''
            }}
            isSearch
          />

          <div className="colorPickerWrapper">
            <div
              className="colorPicker"
              style={{
                backgroundColor: level.colorCode
              }}
              onClick={handleToggleColorPicker}
            ></div>
            <PickColor
              onClose={() => setOpenColorPicker(false)}
              classColor={level.colorCode}
              onChangeItemColor={handleChangeColor}
              showPickColor={openColorPicker}
            />
          </div>

          <div className="description">
            <p className="description--label">Description</p>
            <AppCKEditor
              value={level.description || ''}
              onChange={handleChangeDescription}
            />
            {errors?.description?.message && (
              <p className="description--error">
                {errors?.description?.message}
              </p>
            )}
          </div>

          {/* IMAGE */}
          <>
            <div className="imageWrapper-edit">
              <p className="imageWrapper-images-title">Mascot Image*</p>
              <UploadAvatar
                defaultImage={mascotImageUrl ? mascotImageUrl : undefined}
                file={mascotImage}
                onChangeFile={handleUploadMascotImageFile}
                onErrorFile={(errorMessage: string) => {
                  if (errorMessage === ERROR_MESSAGE_LIMIT_SIZE) {
                    toast.error('Exceed the MB');
                  }
                }}
              />
              {!!mascotImageUrl ? null : (
                <p className="imageWrapper-images-error">
                  Please provide mascot image!
                </p>
              )}
            </div>
            <div>
              <p className="imageWrapper-images-title">Background Image*</p>
              <UploadAvatar
                defaultImage={bgImageUrl ? bgImageUrl : undefined}
                file={bgImage}
                onChangeFile={handleUploadBgImageFile}
                onErrorFile={(errorMessage: string) => {
                  if (errorMessage === ERROR_MESSAGE_LIMIT_SIZE) {
                    toast.error('Exceed the MB');
                  }
                }}
              />
              {!!bgImageUrl ? null : (
                <p className="imageWrapper-images-error">
                  Please provide background image!
                </p>
              )}
            </div>
          </>
          {/* END IMAGE */}
        </>
      );
    }
    return (
      <>
        <AppCardContentItem
          subtitle="Name of the level"
          title={formatData(level?.name)}
        />

        <AppCardContentItem
          subtitle="Level short name"
          title={formatData(level?.shortName)}
        />
        <AppCardContentItem
          subtitle="Age From"
          title={`${formatData(level?.fromAge)}`}
        />
        <AppCardContentItem
          subtitle="Age To"
          title={`${formatData(level?.toAge)}`}
        />
        <AppCardContentItem subtitle="Location" isHtml>
          <LocationListInfo locations={data?.locations} />
        </AppCardContentItem>
        <AppCardContentItem
          subtitle="Color"
          title={formatData(level?.colorCode)}
          isColor
        />
        <div className="levelInformation__description">
          <AppCardContentItem
            subtitle="Description"
            children={
              <span
                dangerouslySetInnerHTML={{ __html: level?.description }}
              ></span>
            }
          />
        </div>
        <div className="imageContainer">
          <div className="image">
            <img
              src={mascotImageUrl}
              width={48}
              height={48}
              alt="mascotImage"
            />
          </div>
          <AppCardContentItem
            subtitle="Mascot image"
            title={`${getFileName(level?.mascotImage)}`}
          />
        </div>
        <div className="imageContainer">
          <div className="image">
            <img src={bgImageUrl} width={48} height={48} alt="background" />
          </div>
          <AppCardContentItem
            subtitle="Background image"
            title={`${getFileName(level?.backgroundImage)}`}
          />
        </div>
      </>
    );
    // eslint-disable-next-line
  }, [
    isEdit,
    handleChange,
    register,
    errors,
    level,
    handleChangeColor,
    handleToggleColorPicker,
    openColorPicker,
    handleChange,
    handleChangeAge,
    mascotImageUrl,
    mascotImage,
    handleUploadMascotImageFile,
    bgImageUrl,
    bgImage,
    handleUploadBgImageFile,
    handleChangeDescription
  ]);

  return (
    <section className="levelInformation">
      <AppCard className={`${isEdit ? 'edit' : ''}`}>
        <AppCardHeader
          title="Level"
          suffix={
            <PermissionWrapper permission={PERMISSION.UPDATE_LEVEL_BREAKDOWN}>
              {__renderIcons}
            </PermissionWrapper>
          }
        />
        <AppCardContent
          className={`cardContent ${isEdit ? 'cardContent-edit' : ''}`}
        >
          {__renderContent}
        </AppCardContent>
      </AppCard>
    </section>
  );
};

export default LevelsBreakdownInformation;
