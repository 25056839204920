import { IOption } from '../interfaces';
import {
  PAYMENT_VALUE,
  PAYMENT_METHOD,
  BOOKING_TYPE
} from '../enums/classBooking.enum';
import { ENROLMENT_TYPE } from '../enums';

// Error codes
export const FAILED_TO_GET_CARD_TOKEN = 'FAILED_TO_GET_CARD_TOKEN';

export const CLASS_BOOKING_PAYMENT_OPTIONS: Array<IOption> = [
  {
    label: PAYMENT_VALUE.UPFRONT?.replaceAll('_', ' '),
    value: PAYMENT_VALUE.UPFRONT
  },
  {
    label: PAYMENT_VALUE.DIRECT_DEBIT?.replaceAll('_', ' '),
    value: PAYMENT_VALUE.DIRECT_DEBIT
  }
];

export const PAYMENT_CARDS = [
  { name: PAYMENT_METHOD.VIVA_PAY, image: '/images/vivapay.svg' }
  // { name: PAYMENT_METHOD.VISA_CARD, image: '/images/visa.png' },
  // { name: PAYMENT_METHOD.SAMSUNG_PAY, image: '/images/samsung_pay.png' },
  // { name: PAYMENT_METHOD.APPLE_PAY, image: '/images/apple_pay.png' },
  // { name: PAYMENT_METHOD.MASTER_CARD, image: '/images/master_card.png' },
  // { name: PAYMENT_METHOD.PAYPAL, image: '/images/paypal.png' },
  // { name: PAYMENT_METHOD.GOOGLE_PAY, image: '/images/google_pay.png' },
  // { name: PAYMENT_METHOD.ALIPAY, image: '/images/alipay.png' },
  // { name: PAYMENT_METHOD.CASH, image: '/images/vad.svg' }
];

export const BOOKING_OPTIONS: IOption[] = [
  {
    label: ENROLMENT_TYPE.ONGOING_CLASS,
    value: BOOKING_TYPE.ONGOING_CLASS
  },
  {
    label: ENROLMENT_TYPE.CASUAL_CLASS,
    value: BOOKING_TYPE.CASUAL_CLASS
  },
  {
    label: ENROLMENT_TYPE.MAKE_UP,
    value: BOOKING_TYPE.MAKE_UP
  }
];

