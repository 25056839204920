import { createColumnHelper } from '@tanstack/react-table';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './index.scss';
import AppToggle from 'common/components/AppToggle';
import AppTable from 'common/components/AppTable';
import AppButton from 'common/components/AppButton';
import AppSearchCalendar from 'common/components/AppSearchCalendar';
import { useLayout } from 'context/LayoutContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FilterValue,
  IClass,
  IClassSearchAdvance
} from 'common/interfaces/class.interface';
import { HiOutlineAcademicCap, HiOutlineIdentification } from 'react-icons/hi';
import { GoFilter } from 'react-icons/go';
import { formatData, formatTime } from 'common/helpers/dataFormat.helper';
import {
  convertRecurrenceToString,
  convertToUnixTime
} from 'common/helpers/time.helper';
import ColorLevelQuickFilter from 'components/colorLevelFilter/ColorLevelQuickFilter';
import { IColorSetting } from 'common/interfaces/dashboard.interface';
import useDebounce from 'common/hooks/useDebounce';
import { useToast } from 'context/ToastContext';
import { getClassListMoveBooking } from 'services/classes.service';
import { FilterClasses as FilterClassesType } from 'pages/classes/types';
import { getClassType } from 'common/helpers/class.helper';
import { useBrandLocation } from 'context/BrandLocationContext';
import FilterClasses from 'components/FilterClasses';
import dayjs from 'dayjs';
import { LevelSetting } from 'common/interfaces/levelBreakdown.interface';
import {
  getLevelBreakdowns,
  updateLevelSetting
} from 'services/levelBreakdown.service';
import { useAuth } from 'context/AuthContext';
import { useMoveBooking } from 'context/MoveBookingContext';
import MovingEnrolmentWarning from '../MovingEnrolmentWarning';
import { ISession } from 'common/interfaces/session.interface';
import { Tooltip } from 'react-tooltip';

const initialSearchAdvanceValue = {
  what: '',
  who: '',
  fromDate: '',
  toDate: ''
};

const initialFilterClasses = {
  classType: '',
  level: '',
  instructor: '',
  area: '',
  dayOfWeek: '',
  duration: ''
};

interface Props {}
const BookingStep: React.FC<Props> = () => {
  const {
    selectedClassIds,
    onChangeSelectedClassIds,
    fetchStudentDetail,
    __warningMovingType,
    onChangeSelectedSession
  } = useMoveBooking();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { handleMouseLeaveMenu } = useLayout();
  const { selectedLocation, locations } = useBrandLocation();
  const params = useParams();
  const { bookClassId, studentId } = params;

  const columnHelper = createColumnHelper<IClass>();
  const [showFilter, setShowFilter] = useState(false);
  const [colorSettings, setColorSettings] = useState<IColorSetting[]>([]);

  const [filterFieldNumber, setFilterFieldNumber] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageTotal, setPageTotal] = useState<number>(-1);
  const [loading, setLoading] = useState<boolean>(false);
  const [classes, setClasses] = useState<Array<any>>([]);
  const [searchByValue, setSearchByValue] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchAdvanceValue, setSearchAdvanceValue] =
    useState<IClassSearchAdvance>(initialSearchAdvanceValue);
  const filterValueRef = useRef<FilterValue>(initialFilterClasses);
  const debouncedSearch = useDebounce<string>(searchValue);

  const [levelSelected, setLevelSelected] = useState<string>('');
  const [vacancy, setVacancy] = useState<number>(1);
  const [applyVacancy, setApplyVacancy] = useState<boolean>(true);

  const debouncedVacancy = useDebounce<number>(vacancy, 500);

  useEffect(() => {
    studentId && fetchStudentDetail(studentId);
    // eslint-disable-next-line
  }, [studentId]);

  useEffect(() => {
    if (applyVacancy) {
      handleFilter(filterValueRef.current);
    }
    // eslint-disable-next-line
  }, [debouncedVacancy]);

  useEffect(() => {
    handleFilter(filterValueRef.current);
    // eslint-disable-next-line
  }, [applyVacancy]);

  const toast = useToast();

  useEffect(() => {
    filterValueRef.current.vacancy = vacancy;
    if (applyVacancy) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedVacancy]);

  const handleToggleFilterVacancy = useCallback(() => {
    setApplyVacancy(!applyVacancy);
  }, [applyVacancy]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const params: FilterClassesType = {
      limit: pageSize,
      page: pageIndex,
      bookClassId: bookClassId
    };
    let numberFilterField = 0;
    if (filterValueRef.current) {
      if (filterValueRef.current.classType) {
        params.classTypes = filterValueRef.current.classType;
        numberFilterField++;
      }

      if (filterValueRef.current.level) {
        params.levels = filterValueRef.current.level;
        numberFilterField++;
      }

      if (filterValueRef.current.instructor) {
        params.instructors = filterValueRef.current.instructor;
        numberFilterField++;
      }

      if (filterValueRef.current.area) {
        params.areas = filterValueRef.current.area;
        numberFilterField++;
      }

      if (filterValueRef.current.dayOfWeek) {
        params.dayOfWeeks = filterValueRef.current.dayOfWeek;
        numberFilterField++;
      }

      if (filterValueRef.current.duration) {
        params.durations = filterValueRef.current.duration;
        numberFilterField++;
      }

      if (filterValueRef.current.applyVacancy) {
        params.vacancy = filterValueRef.current.vacancy;
      }
    }
    setFilterFieldNumber(numberFilterField);
    if (applyVacancy) {
      params.vacancy = filterValueRef.current.vacancy;
    }
    if (searchByValue) {
      params.keyword = debouncedSearch;
    } else {
      if (searchAdvanceValue.who) {
        params.instructorName = searchAdvanceValue.who;
      }

      if (searchAdvanceValue.what) {
        params.keyword = searchAdvanceValue.what;
      }

      if (searchAdvanceValue.fromDate) {
        params.fromDate = convertToUnixTime(searchAdvanceValue.fromDate);
      }

      if (searchAdvanceValue.toDate) {
        params.toDate = convertToUnixTime(searchAdvanceValue.toDate);
      }
    }

    try {
      const { data } = await getClassListMoveBooking(params);
      setPageTotal(data.data.total);
      setClasses(data.data.data);
    } catch (error) {
      setClasses([]);
      onChangeSelectedSession(null);
      onChangeSelectedClassIds([]);
      toast.error('Failed to get class list');
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [
    pageSize,
    pageIndex,
    debouncedSearch,
    filterValueRef.current,
    searchAdvanceValue,
    searchByValue,
    selectedLocation?._id,
    applyVacancy,
    studentId,
    bookClassId
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSearchAdvance = (value: any) => {
    setSearchAdvanceValue(value);
    setSearchByValue(false);
  };

  const getColorSettingData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getLevelBreakdowns(1, 100);
      setColorSettings(response.data.data.data);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Failed to load list level!'
      );
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [selectedLocation?._id]);

  useEffect(() => {
    getColorSettingData();
  }, [getColorSettingData]);

  useEffect(() => {
    filterValueRef.current = { ...initialFilterClasses };
  }, [selectedLocation?._id]);

  // eslint-disable-next-line
  const onSortColorSetting = useCallback(
    async (newColorSettings: IColorSetting[]) => {
      const newOrderId = newColorSettings.map((item) => item._id)?.join(',');
      const currentOrderId = colorSettings.map((item) => item._id)?.join(',');
      if (newOrderId === currentOrderId) return;
      if (!user?._id || !newColorSettings.length) return;
      setColorSettings(newColorSettings);
      try {
        const payload: LevelSetting = {
          levelOrders: newColorSettings.map((item, index) => {
            return { levelId: item._id, order: index };
          })
        };
        await updateLevelSetting(user?._id, payload);
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || 'Cannot sort color setting'
        );
      }
    },
    // eslint-disable-next-line
    [user?._id]
  );

  const onClickAllLevel = () => {
    const newSetting = colorSettings.map((item) => {
      item.active = false;
      return item;
    });
    setColorSettings(newSetting);
    handleFilter({ ...filterValueRef.current, level: '' });
  };

  const handleFilter = useCallback(
    (value: FilterValue) => {
      filterValueRef.current = value;
      filterValueRef.current.vacancy = vacancy;
      setPageIndex(1);
      setShowFilter(false);
      const newSetting = colorSettings.map((item: IColorSetting) => {
        if (value.level.split(',').includes(item._id)) {
          item.active = true;
        } else {
          item.active = false;
        }
        return item;
      });
      setLevelSelected(value.level);

      setColorSettings(newSetting);
    },
    // eslint-disable-next-line
    [colorSettings, setPageIndex, debouncedVacancy]
  );

  const onClickLevel = useCallback(
    (levelId: string) => {
      const newSetting = colorSettings.map((item) => {
        if (item._id === levelId) {
          item.active = !item.active;
        }
        return item;
      });
      setColorSettings(newSetting);
      const levelIds = newSetting
        .filter((item) => item.active)
        .map((item) => item._id);
      handleFilter({ ...filterValueRef.current, level: levelIds.join(',') });
    },
    [colorSettings, handleFilter]
  );

  const handleClassBooking = (classId: string, session?: ISession) => {
    if (!classId) return;
    if (selectedClassIds.length > 0 && selectedClassIds.includes(classId)) {
      onChangeSelectedClassIds([]);
      onChangeSelectedSession(null);
    } else {
      onChangeSelectedSession(session ? session : null);
      onChangeSelectedClassIds([classId]);
    }
  };

  const columns = [
    columnHelper.accessor('session', {
      header: () => <span>Next Class</span>,
      cell: (info) => {
        const item = info.row.original;
        if (!item?.session) return formatData(null);
        return (
          <div data-tooltip-id={`content-tooltip-${item._id}`}>
            <div className="content-cell">
              <span className="line-clamp-1">{`${dayjs(
                item.session?.startTime || item.startTime
              ).format('ddd, MMM D, HH:mm')} - ${formatTime(
                item?.session?.endTime || item.endTime
              )}`}</span>
            </div>
            <Tooltip
              id={`content-tooltip-${item._id}`}
              opacity={1}
              className="tooltip-detail"
              style={{ backgroundColor: '#034EA2' }}
              render={() => <div>{convertRecurrenceToString(item)}</div>}
            />
          </div>
        );
      },
      size: 250
    }),
    columnHelper.accessor('name', {
      header: () => <span>CLASS</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('levelBreakdown', {
      header: () => <span>Level</span>,
      cell: (info) => formatData(info.getValue()?.name || 'All Levels')
    }),
    columnHelper.accessor('type', {
      header: () => <span>PROGRAM TYPE</span>,
      cell: (info) => formatData(getClassType(info.getValue()))
    }),
    columnHelper.accessor('instructor.firstName', {
      header: () => <span>INSTRUCTOR</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('locationId', {
      header: () => <span>location</span>,
      cell: (info) =>
        formatData(locations.find((item) => item._id === info.getValue())?.name)
    }),
    columnHelper.accessor('area', {
      header: () => <span>AREA</span>,
      cell: (info) =>
        formatData(info.row.original.session?.area || info.getValue())
    }),
    columnHelper.accessor('capacity', {
      header: () => <span>CAPACITY</span>,
      cell: (info) => {
        const session = info.row.original.session;
        const capacity = info.getValue();
        const occupied = session?.occupied || 0;
        const slot = capacity - occupied;
        return (
          <div style={{ cursor: 'pointer' }} className="content-cell">
            <HiOutlineAcademicCap fontSize={20} />
            {!session ? (
              `--/--`
            ) : (
              <span style={{ whiteSpace: 'nowrap', marginLeft: '4px' }}>
                {formatData(occupied)}/{formatData(capacity)}
                {slot >= 0
                  ? ' (' + formatData(slot) + ' left)'
                  : ' (overbooked)'}
              </span>
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor('active', {
      header: () => <div style={{ textAlign: 'center' }}>ACTIVE</div>,
      cell: (info) => {
        return (
          <div style={{ cursor: 'default', textAlign: 'center' }}>
            <AppToggle value={info.getValue()} disabled={true} />
          </div>
        );
      }
    }),
    columnHelper.accessor('_id', {
      header: () => <div style={{ cursor: 'pointer' }}>ACTION</div>,
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <div
              onClick={() =>
                navigate(
                  `/classes/${info.getValue()}?lessonId=${
                    info.row.original?.session?._id
                  }`
                )
              }
            >
              <HiOutlineIdentification fontSize={18} />
            </div>
          </div>
        );
      },
      size: 50
    }),
    columnHelper.accessor('_id', {
      header: () => <span></span>,
      cell: (info) => {
        const session = info.row.original?.session;
        const available =
          (info.row.original.session?.capacity || 0) -
          (info.row.original.session?.occupied || 0);
        return (
          <div
            className="table_custom_btn"
            style={{ textAlign: 'center' }}
            onClick={() =>
              handleClassBooking(info.getValue(), info.row.original.session)
            }
          >
            {!!session &&
            info.row.original.active &&
            dayjs(session?.startTime).isAfter(dayjs()) &&
            available > 0 ? (
              <AppButton
                variant={
                  selectedClassIds.includes(info.getValue())
                    ? 'primary'
                    : 'secondary'
                }
                buttonSize="small"
                type="submit"
              >
                {selectedClassIds.includes(info.getValue())
                  ? 'Selected'
                  : 'Book me'}
              </AppButton>
            ) : (
              <span></span>
            )}
          </div>
        );
      }
    })
  ];

  const handleSearch = (value: any, advance?: boolean) => {
    if (advance) {
      onSearchAdvance(value);
    } else {
      setSearchValue(value);
    }
    setPageIndex(1);
  };

  return (
    <div
      className="layoutContainer classes-main"
      onMouseEnter={handleMouseLeaveMenu}
    >
      <FilterClasses
        handleFilter={handleFilter}
        goBack={() => setShowFilter(false)}
        showFilter={showFilter}
        levelSelected={levelSelected}
      />

      <div className="classes-table">
        <div className="classes-table_header">
          <div className="header">Classes</div>
          <div className="classes-table_header_right">
            <div className="classes-input-search">
              <AppSearchCalendar
                handleSearch={handleSearch}
                searchValue={searchValue}
              />
            </div>
            <div className="classes-btn-filter">
              <button
                className="btnActionHeaderClass"
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <GoFilter fontSize={22} />
                <div className="btnHeaderLabel">
                  Filters
                  {filterFieldNumber > 0 && (
                    <div className="filter-field-number">
                      {filterFieldNumber}
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
        <MovingEnrolmentWarning warningMovingType={__warningMovingType} />
        <div style={{ margin: '0px' }} className="color-setting-wrapper">
          <div className="vacancy-input">
            <AppToggle
              value={applyVacancy}
              onChange={handleToggleFilterVacancy}
            />
            <span className="text">Min. Vac.</span>
            <input
              type="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setVacancy(Number(e.target.value))
              }
              value={vacancy || 0}
              min={0}
              inputMode="numeric"
            />
          </div>

          <ColorLevelQuickFilter
            colorSettings={colorSettings}
            onClickLevel={onClickLevel}
            onClickAllLevel={onClickAllLevel}
            levelIds={filterValueRef.current.level}
            onSortColorSetting={() => {}}
          />
        </div>
        <AppTable
          data={classes}
          columns={columns}
          pagination={{
            index: pageIndex,
            size: pageSize,
            total: pageTotal
          }}
          onChangePage={(index: number, size: number) => {
            setPageIndex(index);
            setPageSize(size);
          }}
          loading={loading}
        />
      </div>
    </div>
  );
};
export default BookingStep;
