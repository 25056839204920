import { useEffect, useState, useCallback, FC } from 'react';
import { useParams } from 'react-router-dom';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import PersonalInformation from './components/PersonalInformation';
import EmergencyContacts from './components/EmergencyContacts';
import HealthQuestionnaire from './components/HealthQuestionnaire';
import ResponsiblePersonInformation from './components/RPinfo';
import AssessmentLevel from './components/AssessmentLevel';
import { useToast } from 'context/ToastContext';
import { getStudentDetail } from 'services/students.service';
import { IStudent } from 'common/interfaces/student.interface';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { BeatLoader } from 'react-spinners';

import './desktop.scss';
import MedicalConditions from './components/MedicalConditions';

interface Props {
  onUpdated?: () => void;
}

const StudentDetail: FC<Props> = ({ onUpdated }) => {
  const { id } = useParams();
  const toast = useToast();

  const [initLoad, setInitLoad] = useState(true);
  const [loadData, setLoadData] = useState(true);
  const [data, setData] = useState<IStudent | null>(null);

  const fetchData = useCallback(async () => {
    if (!id) return;

    setLoadData(true);

    try {
      const result = await getStudentDetail(id);

      setData(result);
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || 'Fetch student detail failed'
      );

      setData(null);
    } finally {
      setLoadData(false);
      if (initLoad) setInitLoad(false);
    }

    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (initLoad) {
    return <AppLoadingContainer />;
  }

  return (
    <div className="tab-student-detail">
      {loadData ? (
        <div className="pageLoading">
          <div className="pageLoading-main">
            <BeatLoader />
          </div>
        </div>
      ) : null}

      <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_STUDENT}>
        <PersonalInformation
          data={data}
          onUpdated={() => {
            fetchData();
            if (onUpdated) onUpdated();
          }}
        />
        <MedicalConditions studentId={id} />
        <AssessmentLevel
          onUpdated={() => {
            fetchData();
            if (onUpdated) onUpdated();
          }}
        />

        <ResponsiblePersonInformation data={data} />

        <EmergencyContacts
          data={data}
          onUpdated={() => {
            fetchData();
          }}
        />

        <HealthQuestionnaire data={data} />
      </PermissionWrapper>
    </div>
  );
};

export default StudentDetail;
