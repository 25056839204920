import { useCallback, useState, useEffect } from 'react';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import { createColumnHelper } from '@tanstack/react-table';
import { IRole } from 'common/interfaces/role.interface';
import { useNavigate } from 'react-router-dom';
import AppCard from 'common/components/AppCard';
import AppButton from 'common/components/AppButton';
import AppTable from 'common/components/AppTable';
import { getRoleList } from 'services/roles.service';
import {
  HiOutlineCheckCircle,
  HiOutlineExclamation,
  HiOutlineIdentification,
  HiOutlineTrash,
  HiPlus
} from 'react-icons/hi';
import UserGroupAddForm from '../UserGroupAddForm';
import AppInputSearch from 'common/components/AppInputSearch';
import useDebounce from 'common/hooks/useDebounce';
import UserGroupDeleteModal from '../UserGroupDeleteModal';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useAuth } from 'context/AuthContext';
import './desktop.scss';

const UserGroupList = () => {
  const navigate = useNavigate();

  const { hasPermission } = useAuth();

  const columnHelper = createColumnHelper<IRole>();

  const columns = [
    columnHelper.accessor('name', {
      header: () => <span>Group Name</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('totalUser', {
      header: () => (
        <span style={{ display: 'block', textAlign: 'center' }}>Users</span>
      ),
      cell: (info) => (
        <div>
          {info.getValue() === 0 ? (
            <div className="notDefined">
              <HiOutlineExclamation /> 0
            </div>
          ) : (
            formatData(info.getValue())
          )}
        </div>
      )
    }),
    columnHelper.accessor('permissions', {
      header: () => <span>Permissions</span>,
      cell: (info) => (
        <div>
          {info.getValue()?.length === 0 ? (
            <div className="notDefined">
              <HiOutlineExclamation /> Not defined
            </div>
          ) : (
            <div className="defined">
              <HiOutlineCheckCircle /> Defined
            </div>
          )}
        </div>
      )
    }),
    columnHelper.accessor('createdAt', {
      header: () => <span>Creation time</span>,
      cell: (info) => formatDate(info.getValue())
    }),
    columnHelper.accessor('_id', {
      header: () => (
        <div style={{ display: 'block', textAlign: 'center' }}>ACTION</div>
      ),
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_ROLE}>
              <div onClick={() => navigate(`/user-groups/${info.getValue()}`)}>
                <HiOutlineIdentification />
              </div>
            </PermissionWrapper>

            {info.row.original.totalUser === 0 && (
              <PermissionWrapper permission={PERMISSION.DELETE_ROLE}>
                <div onClick={() => onChooseDeleteUserGroup(info.row.original)}>
                  <HiOutlineTrash />
                </div>
              </PermissionWrapper>
            )}
          </div>
        );
      }
    })
  ];

  const [loading, setLoading] = useState<boolean>(true);

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search);

  const [data, setData] = useState<Array<IRole>>([]);

  const [isOpenAddForm, setIsOpenAddForm] = useState<boolean>(false);
  const [selectedUserGroup, setSelectedUserGroup] = useState<IRole | null>(
    null
  );

  // ADD SECTION
  const onAddSuccess = async () => {
    setSearch('');
    fetchData();
    onCloseAddForm();
  };
  const onOpenAddForm = () => {
    setIsOpenAddForm(true);
  };
  const onCloseAddForm = () => {
    setIsOpenAddForm(false);
  };
  //  END - ADD SECTION

  // DELETE SECTION
  const onChooseDeleteUserGroup = (userGroup: IRole) => {
    setSelectedUserGroup(userGroup);
  };
  const onCancelDeleteUserGroup = () => {
    setSelectedUserGroup(null);
  };
  const onDeleteSuccess = async () => {
    setSelectedUserGroup(null);
    setSearch('');
    fetchData();
  };
  // END - DELETE SECTION

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getRoleList(search);
      setData(result);
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [debouncedSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <main className="userGroupListPage">
      {/* Add section */}
      <PermissionWrapper permission={PERMISSION.CREATE_ROLE}>
        <UserGroupAddForm
          open={isOpenAddForm}
          onClose={onCloseAddForm}
          onSuccess={onAddSuccess}
        />
      </PermissionWrapper>

      {/* DELETE */}
      {!!selectedUserGroup && (
        <PermissionWrapper permission={PERMISSION.DELETE_ROLE}>
          <UserGroupDeleteModal
            userGroup={selectedUserGroup}
            onClose={onCancelDeleteUserGroup}
            onSuccess={onDeleteSuccess}
          />
        </PermissionWrapper>
      )}

      <AppBreadCrumb items={[{ name: 'User Groups', href: 'user-groups' }]} />
      <div className="userGroupList layoutContainer">
        <AppCard>
          <div className="userGroupList__table">
            <div className="userGroupList__table-header">
              <h2 className="userGroupList__table-header-title">
                User Group(s)
              </h2>
              <div className="userGroupList__table-header-actions">
                <AppInputSearch
                  type="text"
                  value={search}
                  onChange={onChangeSearch}
                  placeholder="Group name"
                  onClearSearch={onClearSearch}
                  startIcon={<HiMagnifyingGlass />}
                />
                <PermissionWrapper permission={PERMISSION.CREATE_ROLE}>
                  <AppButton
                    variant="primary"
                    buttonSize="small"
                    type="submit"
                    onClick={onOpenAddForm}
                  >
                    <HiPlus />
                    <p>User group</p>
                  </AppButton>
                </PermissionWrapper>
              </div>
            </div>
            <div className="userGroupList__table-content">
              <AppTable
                data={data}
                columns={columns}
                loading={loading}
                columnVisibility={
                  !hasPermission(PERMISSION.VIEW_DETAIL_ROLE) &&
                  !hasPermission(PERMISSION.DELETE_ROLE)
                    ? { _id: false }
                    : undefined
                }
              />
            </div>
          </div>
        </AppCard>
      </div>
    </main>
  );
};

export default UserGroupList;
