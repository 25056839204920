import React, { useCallback, useEffect, useState } from 'react';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppCard from 'common/components/AppCard';
import AppInputSearch from 'common/components/AppInputSearch';
import AppButton from 'common/components/AppButton';
import AppTable from 'common/components/AppTable';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import useDebounce from 'common/hooks/useDebounce';
import AppToggle from 'common/components/AppToggle';
import { useBrandLocation } from 'context/BrandLocationContext';
import StaffAddForm from './components/StaffAddForm';
import { getStaffs, updateStaffStatus } from 'services/staff.service';
import { IStaff } from 'common/interfaces/staff.interface';
import { STATUS_TYPE } from 'common/enums/staff.enum';
import { HiOutlineIdentification, HiPlus } from 'react-icons/hi';
import AppModal, {
  AppModalActions,
  AppModalContent,
  AppModalTitle
} from 'common/components/AppModal';
import { formatData, formatDate } from 'common/helpers/dataFormat.helper';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useAuth } from 'context/AuthContext';
import './desktop.scss';

const StaffList = () => {
  const { hasPermission } = useAuth();

  const navigate = useNavigate();
  const columnHelper = createColumnHelper<IStaff>();

  const columns = [
    columnHelper.accessor('memberId', {
      header: () => <span>ID</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('lastName', {
      header: () => <span>SURNAME, NAME</span>,
      cell: (info) => (
        <div className="line-clamp-1">
          {formatData(
            info.row.original.lastName + ', ' + info.row.original.firstName
          )}
        </div>
      )
    }),
    columnHelper.accessor('role', {
      header: () => <span>role</span>,
      cell: (info) => formatData(info.getValue().name)
    }),
    columnHelper.accessor('dob', {
      header: () => <span>dob</span>,
      cell: (info) => formatDate(info.getValue())
    }),
    columnHelper.accessor('email', {
      header: () => <span>email</span>,
      cell: (info) => formatData(info.getValue())
    }),
    columnHelper.accessor('status', {
      header: () => <span>Status</span>,
      cell: (info) => {
        return (
          <div>
            {info.getValue() === STATUS_TYPE.ACTIVE ? (
              <AppToggle
                value={true}
                onChange={() => onChooseDeactivateStaff(info.row.original)}
                disabled={
                  !hasPermission([
                    PERMISSION.UPDATE_STAFF,
                    PERMISSION.UPDATE_STAFF_PERSONAL_INFO
                  ])
                }
              />
            ) : (
              <AppToggle
                value={false}
                onChange={() => onActivateStaff(info.row.original._id)}
                disabled={
                  !hasPermission([
                    PERMISSION.UPDATE_STAFF,
                    PERMISSION.UPDATE_STAFF_PERSONAL_INFO
                  ])
                }
              />
            )}
          </div>
        );
      }
    }),
    columnHelper.accessor('_id', {
      header: () => (
        <span
          style={{
            display: 'block',
            textAlign: 'center'
          }}
        >
          Action
        </span>
      ),
      cell: (info) => {
        return (
          <div className="buttonGroups">
            <PermissionWrapper permission={PERMISSION.VIEW_DETAIL_STAFF}>
              <div onClick={() => navigate(`/staff/${info.getValue()}`)}>
                <HiOutlineIdentification />
              </div>
            </PermissionWrapper>
          </div>
        );
      }
    })
  ];

  const { selectedLocation: globalLocation } = useBrandLocation();

  const [loading, setLoading] = useState<boolean>(true);

  const [data, setData] = useState<Array<IStaff>>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageTotal, setPageTotal] = useState<number>(-1);

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search);

  const [selectedStaff, setSelectedStaff] = useState<IStaff | null>(null);
  const [isOpenAddForm, setIsOpenAddForm] = useState<boolean>(false);

  // ADD SECTION
  const onAddSuccess = async (reload: boolean) => {
    onCloseAddForm();
    if (reload) {
      setSearch('');
      setPageIndex(1);
      fetchData();
    }
  };
  const onOpenAddForm = () => {
    setIsOpenAddForm(true);
  };
  const onCloseAddForm = () => {
    setIsOpenAddForm(false);
  };
  // END - ADD SECTION

  // DEACTIVATE SECTION
  const onChooseDeactivateStaff = (staff: IStaff) => {
    setSelectedStaff(staff);
  };
  const onCancelDeactivateStaff = () => {
    setSelectedStaff(null);
  };
  const onDeactivateStaff = async () => {
    try {
      setLoading(true);
      await updateStaffStatus(selectedStaff?._id || '', {
        status: STATUS_TYPE.INACTIVE
      });
      fetchData();
      setSelectedStaff(null);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const onActivateStaff = async (staffId: string) => {
    try {
      setLoading(true);
      await updateStaffStatus(staffId, { status: STATUS_TYPE.ACTIVE });
      fetchData();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  // END - DEACTIVATE SECTION

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await getStaffs(
        globalLocation?._id || '',
        pageIndex,
        pageSize,
        search
      );
      setData(result.data.data.data);
      setPageTotal(result.data.data.total);
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [globalLocation?._id, debouncedSearch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setPageIndex(1);
  }, [debouncedSearch, globalLocation?._id]);

  return (
    <main className="staffsPage">
      {/* ADD */}
      <PermissionWrapper permission={PERMISSION.CREATE_STAFF}>
        {isOpenAddForm && (
          <StaffAddForm
            open={isOpenAddForm}
            onClose={onCloseAddForm}
            onSuccess={onAddSuccess}
          />
        )}
      </PermissionWrapper>

      {/* DEACTIVATE */}
      <AppModal open={!!selectedStaff} onClose={onCancelDeactivateStaff}>
        <AppModalTitle>DEACTIVATE</AppModalTitle>
        <AppModalContent>
          Are you sure you want to Deactive {selectedStaff?.firstName}?
        </AppModalContent>
        <AppModalActions>
          <AppButton variant="secondary" onClick={onCancelDeactivateStaff}>
            No
          </AppButton>
          <AppButton isLoading={loading} onClick={onDeactivateStaff}>
            Yes
          </AppButton>
        </AppModalActions>
      </AppModal>

      {/* MAIN */}
      <AppBreadCrumb items={[{ name: 'Staff', href: '/staff' }]} />
      <div className="layoutContainer staffs">
        <AppCard>
          <div className="staffs__table">
            <div className="staffs__table-header">
              <h2 className="staffs__table-header-title">Staff</h2>
              <div className="staffs__table-header-search">
                <AppInputSearch
                  type="text"
                  value={search}
                  onChange={onChangeSearch}
                  placeholder="Surname, name or email"
                  onClearSearch={onClearSearch}
                  startIcon={<HiMagnifyingGlass />}
                />
                <PermissionWrapper permission={PERMISSION.CREATE_STAFF}>
                  <AppButton
                    variant="primary"
                    buttonSize="small"
                    type="submit"
                    onClick={onOpenAddForm}
                  >
                    <HiPlus />
                    <p>staff</p>
                  </AppButton>
                </PermissionWrapper>
              </div>
            </div>
            <div className="staffs__table-content">
              <AppTable
                data={data}
                columns={columns}
                pagination={{
                  index: pageIndex,
                  size: pageSize,
                  total: pageTotal
                }}
                onChangePage={(index: number, size: number) => {
                  setPageIndex(index);
                  setPageSize(size);
                }}
                loading={loading}
                columnVisibility={
                  !hasPermission(PERMISSION.VIEW_DETAIL_STAFF)
                    ? { _id: false }
                    : undefined
                }
              />
            </div>
          </div>
        </AppCard>
      </div>
    </main>
  );
};

export default StaffList;
