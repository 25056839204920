import { BrowserRouter } from 'react-router-dom';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { useMemo } from 'react';
import { useAuth } from 'context/AuthContext';
import { MoveBookingProvider } from 'context/MoveBookingContext';
import { StepControllerProvider } from 'context/StepControllerContext';

const RouteList = () => {
  const { me } = useAuth();

  const __isAllowed = useMemo((): boolean => {
    return !!me;
  }, [me]);

  return (
    <BrowserRouter>
      <StepControllerProvider>
        <MoveBookingProvider>
          <div className="App">
            {__isAllowed ? <PrivateRoutes /> : <PublicRoutes />}
          </div>
        </MoveBookingProvider>
      </StepControllerProvider>
    </BrowserRouter>
  );
};

export default RouteList;
