import { Suspense, memo, useState } from 'react';
import ClassBookingSection from './ClassBookingSection';
import { ISchedulesOrigin } from 'common/interfaces/schedules.interface';
import { IStudentBookingData } from 'common/interfaces/student.interface';
import { useSearchParams } from 'react-router-dom';
import AppTabs from 'common/components/AppTabs';
import { useEnrollmentContext } from 'pages/students/Enrollment/EnrollmentContext';
import AppLoadingContainer from 'common/components/AppLoadingContainer';

import './desktop.scss';

const BookingClasses = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { students, classesData } = useEnrollmentContext();

  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleSelectedTab = (val: number) => {
    setTabIndex(val);

    searchParams.set('tabNumber', `${val}`);
    setSearchParams(searchParams);
  };

  const tabs = students.map((student: IStudentBookingData) => {
    return {
      name: student.firstName,
      component: (
        <div className="booking-classes" key={student._id}>
          {classesData.map((item: ISchedulesOrigin, index: number) => {
            return (
              <ClassBookingSection
                key={item._id || index}
                classData={item}
                studentId={student._id}
              />
            );
          })}
        </div>
      )
    };
  });

  return (
    <div className="layoutContainer classes-main">
      <AppTabs
        tabs={tabs}
        activeTabIndex={tabIndex}
        onChangeTab={handleSelectedTab}
        key="enrollment_route-tab"
      />
      {tabIndex >= 0 ? (
        <Suspense fallback={<AppLoadingContainer />}>
          {tabs[tabIndex]?.component}
        </Suspense>
      ) : null}
    </div>
  );
};

export default memo(BookingClasses);
