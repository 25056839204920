import { IOption } from '../interfaces';

export const CREDENTIAL_OPTIONS: Array<IOption> = [
  {
    label: 'AUSTSWIM Teacher of Infant and Preschool Aquatics (INF)',
    value: 'AUSTSWIM Teacher of Infant and Preschool Aquatics (INF)'
  },
  {
    label: 'AUSTSWIM Teacher of Aquatics - Access and Inclusion (TAI)',

    value: 'AUSTSWIM Teacher of Aquatics - Access and Inclusion (TAI)'
  },
  {
    label: 'AUSTSWIM Teacher of Adults (AD)',
    value: 'AUSTSWIM Teacher of Adults (AD)'
  },
  {
    label: 'AUSTSWIM Teacher of Towards Competitive Strokes (TCS)',
    value: 'AUSTSWIM Teacher of Towards Competitive Strokes (TCS)'
  },
  {
    label: 'Swim Australia Teacher of Babies & Toddlers',
    value: 'AUSTSWIM Teacher of Towards Competitive Strokes (TCS)'
  },
  {
    label: 'Swim Australia Foundation Coach',
    value: 'Swim Australia Foundation Coach'
  },
  {
    label: 'Swim Australia Teacher of Learners with Disability',
    value: 'Swim Australia Teacher of Learners with Disability'
  },
  {
    label: 'Royal Life Saving Teacher of Infant and Preschool Aquatics',
    value: 'Royal Life Saving Teacher of Infant and Preschool Aquatics'
  },
  {
    label: 'Royal Life Saving Teacher of Adults',
    value: 'Royal Life Saving Teacher of Adults'
  },
  {
    label: 'Royal Life Saving Teacher of Aquatics – Access and Inclusion',
    value: 'Royal Life Saving Teacher of Aquatics – Access and Inclusion'
  },
  {
    label: 'Royal Life Saving Teacher of Competitive Strokes',
    value: 'Royal Life Saving Teacher of Competitive Strokes'
  },
  {
    label: 'Diving Induction',
    value: 'Diving Induction'
  }
];
