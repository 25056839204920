import { object as OBJECT, string, number } from 'yup';
import dayjs from 'dayjs';

//  ===== RESPONSIBLE_PERSON  =====
const RESPONSIBLE_PERSON_NAME = string().required(
  'Please provide responsible person name'
);

const RESPONSIBLE_PERSON_SURNAME = string().required(
  'Please provide responsible person surname'
);

const RESPONSIBLE_PERSON_ALIAS = string();

const RESPONSIBLE_PERSON_PHONE_NUMBER = string().required(
  'Please provide responsible person phone number'
);

const RESPONSIBLE_PERSON_EMAIL_ADDRESS = string()
  .email('Please provide correct email format')
  .required('Please provide responsible person email address');

const RESPONSIBLE_PERSON_LOCATION = string().required(
  'Please provide responsible person location'
);

const RESPONSIBLE_PERSON_TIMEZONE = string().required(
  'Please provide responsible person timezone'
);

const RESPONSIBLE_PERSON_DOB = number()
  .typeError('Please provide D.O.B')
  .required('Please provide D.O.B')
  .label('dob')
  .test('dob', (value, { createError }) => {
    if (dayjs(value).format('YYYY-MM-DD') === 'Invalid Date') {
      return createError({
        message: 'Please provide D.O.B'
      });
    }

    const maxYearsLimit = dayjs().subtract(200, 'year');

    if (dayjs(value).isBefore(maxYearsLimit)) {
      return createError({
        message: 'Invalid D.O.B'
      });
    }

    if (dayjs(value).isAfter(dayjs())) {
      return createError({
        message: 'Date must be in the past'
      });
    }

    const eighteenYearsAgo = dayjs().subtract(18, 'year');

    if (dayjs(value).isAfter(eighteenYearsAgo)) {
      return createError({
        message: 'Age must be minimum of 18 years'
      });
    }

    return true;
  });

const RESPONSIBLE_PERSON_JOINING_DATE = number()
  .typeError('Please provide joining date')
  .required('Please provide joining date')
  .label('joiningDate')
  .test('joiningDate', 'Please provide joining date', (value) => {
    return dayjs(value).format('YYYY-MM-DD') !== 'Invalid Date';
  });

const RESPONSIBLE_PERSON_MONEY_CREDIT_VALUE = number()
  .typeError('Money credit must be a number')
  .min(1, 'Money credit must be greater than 0')
  .max(10000, 'Money credit must be less than 10000');

//  ===== END RESPONSIBLE_PERSON  =====

const validations = {
  OBJECT,
  string,
  number,
  RESPONSIBLE_PERSON_NAME,
  RESPONSIBLE_PERSON_SURNAME,
  RESPONSIBLE_PERSON_ALIAS,
  RESPONSIBLE_PERSON_DOB,
  RESPONSIBLE_PERSON_LOCATION,
  RESPONSIBLE_PERSON_PHONE_NUMBER,
  RESPONSIBLE_PERSON_EMAIL_ADDRESS,
  RESPONSIBLE_PERSON_JOINING_DATE,
  RESPONSIBLE_PERSON_TIMEZONE,
  RESPONSIBLE_PERSON_MONEY_CREDIT_VALUE
};
export default validations;
