import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { formatData } from 'common/helpers/dataFormat.helper';
import { useBrandLocation } from 'context/BrandLocationContext';
import useDebounce from 'common/hooks/useDebounce';
import {
  HiOutlineIdentification,
  HiOutlineTrash,
  HiPlus
} from 'react-icons/hi';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppInputSearch from 'common/components/AppInputSearch';
import { HiMagnifyingGlass } from 'react-icons/hi2';
import AppButton from 'common/components/AppButton';
import AppTable from 'common/components/AppTable';
import AppCard from 'common/components/AppCard';
import LevelBreakdownAddForm from '../LevelBreakdownAddForm';
import { ILevel } from 'common/interfaces/levelBreakdown.interface';
import { getLevelBreakdowns } from 'services/levelBreakdown.service';
import LevelBreakdownDeleteModal from './components/LevelBreakdownDeleteModal';
import PermissionWrapper from 'components/PermissionWrapper';
import { PERMISSION } from 'common/enums/permission.enum';
import { useAuth } from 'context/AuthContext';

import './desktop.scss';
import LocationListInfo from 'components/ListInfo';

const LevelsBreakDownList = () => {
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<ILevel>();

  const { hasPermission } = useAuth();

  const columns = [
    columnHelper.accessor('name', {
      header: () => <span>level name</span>,
      cell: (info) => <div>{formatData(info.getValue())}</div>
    }),
    columnHelper.accessor('locations', {
      header: () => <span>location</span>,
      cell: (info) => <LocationListInfo locations={info.getValue()} />
    }),
    columnHelper.accessor('shortName', {
      header: () => <span>Short name</span>,
      cell: (info) => (
        <div className="line-clamp-1">{formatData(info.getValue())}</div>
      )
    }),
    columnHelper.accessor('fromAge', {
      header: () => <span>age from</span>,
      cell: (info) => (
        <div className="line-clamp-1">{formatData(info.getValue())}</div>
      )
    }),
    columnHelper.accessor('toAge', {
      header: () => <span>age to</span>,
      cell: (info) => (
        <div className="line-clamp-1">{formatData(info.getValue())}</div>
      )
    }),
    columnHelper.accessor('_id', {
      header: () => (
        <span
          style={{
            display: 'block',
            textAlign: 'center'
          }}
        >
          Action
        </span>
      ),
      cell: (info) => {
        return (
          <div className="buttonGroupsContainer">
            <div className="buttonGroups">
              <PermissionWrapper
                permission={PERMISSION.VIEW_DETAIL_LEVEL_BREAKDOWN}
              >
                <div
                  onClick={() =>
                    navigate(`/levels-breakdown/${info.getValue()}`)
                  }
                >
                  <HiOutlineIdentification />
                </div>
              </PermissionWrapper>
              <PermissionWrapper permission={PERMISSION.DELETE_LEVEL_BREAKDOWN}>
                {info.row.original.classInUsed === 0 && (
                  <div
                    onClick={() =>
                      onChooseDeleteLevelBreakdown(info.row.original)
                    }
                  >
                    <HiOutlineTrash />
                  </div>
                )}
              </PermissionWrapper>
            </div>
          </div>
        );
      }
    })
  ];

  const { selectedLocation: globalLocation } = useBrandLocation();

  const [loading, setLoading] = useState<boolean>(true);

  const [data, setData] = useState<Array<ILevel>>([]);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageTotal, setPageTotal] = useState<number>(-1);

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search);

  const [selectedLB, setSelectedLB] = useState<ILevel | null>(null);
  const [isOpenAddForm, setIsOpenAddForm] = useState<boolean>(false);

  // ADD SECTION
  const onAddSuccess = async () => {
    onCloseAddForm();
    setSearch('');
    setPageIndex(1);
    fetchData();
  };
  const onOpenAddForm = () => {
    setIsOpenAddForm(true);
  };
  const onCloseAddForm = () => {
    setIsOpenAddForm(false);
  };
  // END - ADD SECTION

  // DELETE SECTION
  const onChooseDeleteLevelBreakdown = (levelBreakdown: ILevel) => {
    setSelectedLB(levelBreakdown);
  };
  const onCancelDeleteLevelBreakdown = () => {
    setSelectedLB(null);
  };
  const onDeleteSuccess = async () => {
    setSelectedLB(null);
    setSearch('');
    setPageIndex(1);
    fetchData();
  };
  // END - DELETE SECTION

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClearSearch = () => {
    setSearch('');
  };

  const fetchData = useCallback(async () => {
    if (!globalLocation?._id) return;
    setLoading(true);
    try {
      const result = await getLevelBreakdowns(
        pageIndex,
        pageSize,
        globalLocation?._id,
        search
      );
      setData(result.data.data.data);
      setPageTotal(result.data.data.total);
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [globalLocation?._id, debouncedSearch, pageIndex, pageSize]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setPageIndex(1);
  }, [debouncedSearch, globalLocation?._id]);

  return (
    <main className="levelsBreakdownListPage">
      {/* ADD */}
      <PermissionWrapper permission={PERMISSION.CREATE_LEVEL_BREAKDOWN}>
        {isOpenAddForm && (
          <LevelBreakdownAddForm
            open={isOpenAddForm}
            onClose={onCloseAddForm}
            onSuccess={onAddSuccess}
          />
        )}
      </PermissionWrapper>

      {/* DELETE */}

      {!!selectedLB && (
        <PermissionWrapper permission={PERMISSION.DELETE_LEVEL_BREAKDOWN}>
          <LevelBreakdownDeleteModal
            levelBreakdown={selectedLB}
            onClose={onCancelDeleteLevelBreakdown}
            onSuccess={onDeleteSuccess}
          />
        </PermissionWrapper>
      )}

      {/* MAIN */}
      <AppBreadCrumb
        items={[{ name: 'Levels Breakdown', href: '/levels-breakdown' }]}
      />
      <div className="layoutContainer levelsBreakdownList">
        <AppCard>
          <div className="levelsBreakdownList__table">
            <div className="levelsBreakdownList__table-header">
              <h2 className="levelsBreakdownList__table-header-title">
                Levels
              </h2>
              <div className="levelsBreakdownList__table-header-search">
                <AppInputSearch
                  type="text"
                  value={search}
                  onChange={onChangeSearch}
                  placeholder="Level full name or short name"
                  onClearSearch={onClearSearch}
                  startIcon={<HiMagnifyingGlass />}
                />
                <PermissionWrapper
                  permission={PERMISSION.CREATE_LEVEL_BREAKDOWN}
                >
                  <AppButton
                    variant="primary"
                    buttonSize="small"
                    type="submit"
                    onClick={onOpenAddForm}
                  >
                    <HiPlus />
                    <p>Level Template</p>
                  </AppButton>
                </PermissionWrapper>
              </div>
            </div>
            <PermissionWrapper permission={PERMISSION.LIST_LEVEL_BREAKDOWN}>
              <div className="levelsBreakdownList__table-content">
                <AppTable
                  data={data}
                  columns={columns}
                  pagination={{
                    index: pageIndex,
                    size: pageSize,
                    total: pageTotal
                  }}
                  onChangePage={(index: number, size: number) => {
                    setPageIndex(index);
                    setPageSize(size);
                  }}
                  loading={loading}
                  columnVisibility={
                    !hasPermission(PERMISSION.VIEW_DETAIL_LEVEL_BREAKDOWN) &&
                    !hasPermission(PERMISSION.DELETE_LEVEL_BREAKDOWN)
                      ? { _id: false }
                      : undefined
                  }
                />
              </div>
            </PermissionWrapper>
          </div>
        </AppCard>
      </div>
    </main>
  );
};

export default LevelsBreakDownList;
