import { memo, useMemo, useState } from 'react';
import VersionModal from 'components/VersionModal';
import AppButton from 'common/components/AppButton';
import AppModal, { AppModalContent } from 'common/components/AppModal';
import PaymentModal from 'components/PaymentModal';
import { useStepController } from 'context/StepControllerContext';
import { useToast } from 'context/ToastContext';
import { useBrandLocation } from 'context/BrandLocationContext';
import { bookClass } from 'services/students.service';
import { PAYMENT_TYPE } from 'common/interfaces/bookingClass.interface';
import { BookClassDto, BookingItem } from 'DTOs/student.dto';
import { BOOKING_ON, PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import { FLOW_ENUM } from 'common/enums/step.enum';
import {
  checkBookingClassData,
  onlyBookMakeupOrAssessmentClass
} from 'common/helpers/classListBooking.helper';
import { FAILED_TO_GET_CARD_TOKEN } from 'common/constants/classBooking.constant';
import { DISCOUNT_TYPE } from 'common/enums';
import { roundByTwo } from 'common/helpers/dataFormat.helper';
import { uniqBy } from 'lodash';
import { VOUCHER_DURATION } from 'common/enums/voucher.enum';
import { handleGetPayNowForBookingClassList } from 'helpers/enrolment.helper';

import './desktop.scss';

const AppFooter = () => {
  const toast = useToast();
  const { dataSet } = useStepController();
  const { selectedLocation: globalLocation } = useBrandLocation();

  const [showModal, setShowModal] = useState(false);

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openPaymentSuccessModal, setOpenPaymentSuccessModal] = useState(false);

  const [isFailedToGetCardToken, setIsFailedToGetCardToken] = useState(false);

  const __moneyCredit = useMemo((): number => {
    return (
      // @ts-ignore
      dataSet?.responsiblePersonsBooked?.[0]?.info?.additionalInfo
        ?.moneyCredit || 0
    );
  }, [dataSet]);

  const __discountAmount: number = useMemo(() => {
    if (
      dataSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
      (dataSet?.step === 6 || dataSet?.step === 7)
    ) {
      return dataSet?.responsiblePersonsBooked[0]?.students.reduce(
        (result: number, student) => {
          student.classes.forEach((bookingData) => {
            if (bookingData.voucher?.code) {
              const { discountType, discountValue } = bookingData.voucher;

              const pricePayNow = bookingData.form.payNow;
              if (discountType === DISCOUNT_TYPE.PERCENTAGE) {
                if (pricePayNow > 0)
                  result += pricePayNow * (discountValue / 100);
              } else if (discountType === DISCOUNT_TYPE.AMOUNT) {
                if (pricePayNow > 0) {
                  result +=
                    discountValue < pricePayNow ? discountValue : pricePayNow;
                }
              } else if (discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
                const { voucher, info: classInfo } = bookingData;
                if (
                  !!voucher.numberOfLesson &&
                  voucher.duration ===
                    VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS
                ) {
                  const discountPrice = roundByTwo(
                    voucher.numberOfLesson * classInfo.price
                  );
                  result +=
                    pricePayNow - discountPrice > 0
                      ? discountPrice
                      : pricePayNow;
                } else if (voucher.duration === VOUCHER_DURATION.TIME_RANGE) {
                  const pricePayNowActual = handleGetPayNowForBookingClassList(
                    bookingData,
                    dataSet.subtractTerms,
                    dataSet.nextDebitDay
                  );
                  result += roundByTwo(pricePayNow - pricePayNowActual);
                }
              }
            } else if (bookingData?.automaticDiscount) {
              const pricePayNow = bookingData.form.payNow;
              if (pricePayNow > 0)
                result += roundByTwo(
                  pricePayNow * (bookingData?.automaticDiscount.value / 100)
                );
            }
          });
          return result;
        },
        0
      );
    }
    return 0;
  }, [dataSet]);

  const handleSubmit = async () => {
    if (
      dataSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
      dataSet?.step === 7 &&
      globalLocation?._id
    ) {
      if (onlyBookMakeupOrAssessmentClass(dataSet)) {
        try {
          let mappedBookingItems: Array<{
            studentId: string;
            classId: string;
            bookingType: string;
            scheduleIds: Array<string>;
            paymentType: PAYMENT_TYPE;
          }> = [];

          for (const STdata of dataSet?.responsiblePersonsBooked?.[0]
            ?.students) {
            for (const classesData of STdata?.classes) {
              if (checkBookingClassData(classesData)) {
                const newMappedBookingItem = (): BookingItem => {
                  if (
                    classesData?.form?.paymentType === PAYMENT_VALUE?.UPFRONT ||
                    classesData?.form?.paymentType ===
                      PAYMENT_VALUE?.DIRECT_DEBIT
                  ) {
                    const item: BookingItem = {
                      studentId: STdata?.info?._id,
                      classId: classesData?.info?._id,
                      bookingType: classesData?.form?.enrollmentType,
                      scheduleIds: classesData?.form?.schedulesBooked?.map(
                        (schedule) => schedule?._id
                      ),
                      paymentType: classesData?.form?.paymentType,
                      bookingOn: BOOKING_ON.STAFF_PORTAL
                    };
                    if (classesData?.voucher?.code) {
                      item.voucherCode = classesData?.voucher?.code;
                    }
                    return item;
                  }

                  return {
                    studentId: STdata?.info?._id,
                    classId: classesData?.info?._id,
                    bookingType: classesData?.form?.enrollmentType,
                    scheduleIds: classesData?.form?.schedulesBooked?.map(
                      (schedule) => schedule?._id
                    ),
                    paymentType: PAYMENT_VALUE?.MAKE_CREDIT,
                    totalCredit: classesData?.form?.payNow,
                    bookingOn: BOOKING_ON.STAFF_PORTAL
                  };
                };

                if (mappedBookingItems) {
                  mappedBookingItems = [
                    ...mappedBookingItems,
                    newMappedBookingItem()
                  ];
                } else {
                  mappedBookingItems = [newMappedBookingItem()];
                }
              }
            }
          }

          if (!mappedBookingItems?.length) return;
          const amount =
            dataSet?.paymentAmount - __discountAmount - __moneyCredit > 0
              ? dataSet?.paymentAmount - __discountAmount - __moneyCredit || 0
              : 0;

          const payload: BookClassDto = {
            bookingItems: mappedBookingItems as BookingItem[],
            responsiblePersonId:
              dataSet?.responsiblePersonsBooked?.[0]?.info?._id,
            amount,
            locationId: globalLocation?._id,
            paymentMethod: null
          };

          await bookClass(payload);

          setOpenPaymentModal(false);

          setOpenPaymentSuccessModal(true);

          toast.success('Make payment successful');
        } catch (error: any) {
          toast.error(error?.response?.data?.message || 'Fail to make payment');
        }
      } else {
        setOpenPaymentModal(true);
      }
    }
  };

  const handleAfterSuccess = () => {
    dataSet?.cleanUpFlow();
    setOpenPaymentSuccessModal(false);
  };

  const handleAddStepBackward = () => {
    if (
      dataSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
      dataSet?.step >= 2 &&
      (dataSet?.step === 2 ||
        dataSet?.step === 3 ||
        dataSet?.step === 4 ||
        dataSet?.step === 5 ||
        dataSet?.step === 6 ||
        dataSet?.step === 7)
    )
      dataSet?.handleAddStepBackward();
  };

  const handlePayment = async (cardId: string) => {
    if (
      dataSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
      dataSet?.step === 7 &&
      globalLocation?._id
    ) {
      try {
        let mappedBookingItems: Array<{
          studentId: string;
          classId: string;
          bookingType: string;
          scheduleIds: Array<string>;
          paymentType: PAYMENT_TYPE;
        }> = [];

        for (const STdata of dataSet?.responsiblePersonsBooked?.[0]?.students) {
          const classes = uniqBy(
            STdata?.classes,
            'form.schedulesBooked[0]._id'
          );
          for (const classesData of classes) {
            if (checkBookingClassData(classesData)) {
              const newMappedBookingItem = (): BookingItem => {
                if (
                  classesData?.form?.paymentType === PAYMENT_VALUE?.UPFRONT ||
                  classesData?.form?.paymentType === PAYMENT_VALUE?.DIRECT_DEBIT
                ) {
                  const item: BookingItem = {
                    studentId: STdata?.info?._id,
                    classId: classesData?.info?._id,
                    bookingType: classesData?.form?.enrollmentType,
                    scheduleIds: classesData?.form?.schedulesBooked?.map(
                      (schedule) => schedule?._id
                    ),
                    paymentType: classesData?.form?.paymentType,
                    bookingOn: BOOKING_ON.STAFF_PORTAL
                  };
                  if (classesData?.voucher?.code) {
                    item.voucherCode = classesData?.voucher?.code;
                  }
                  return item;
                }

                return {
                  studentId: STdata?.info?._id,
                  classId: classesData?.info?._id,
                  bookingType: classesData?.form?.enrollmentType,
                  scheduleIds: classesData?.form?.schedulesBooked?.map(
                    (schedule) => schedule?._id
                  ),
                  paymentType: PAYMENT_VALUE?.MAKE_CREDIT,
                  totalCredit: classesData?.form?.payNow,
                  bookingOn: BOOKING_ON.STAFF_PORTAL
                };
              };

              if (mappedBookingItems) {
                mappedBookingItems = [
                  ...mappedBookingItems,
                  newMappedBookingItem()
                ];
              } else {
                mappedBookingItems = [newMappedBookingItem()];
              }
            }
          }
        }

        if (!mappedBookingItems?.length) return;

        const amount = roundByTwo(
          dataSet?.paymentAmount - __discountAmount - __moneyCredit > 0
            ? dataSet?.paymentAmount - __discountAmount - __moneyCredit || 0
            : 0
        );
        const payload: BookClassDto = {
          bookingItems: mappedBookingItems as BookingItem[],
          responsiblePersonId:
            dataSet?.responsiblePersonsBooked?.[0]?.info?._id,
          amount,
          cardId: cardId,
          locationId: globalLocation?._id,
          isSaveCardToken: true,
          statementDescriptor: {
            name: 'Payer'
          },
          paymentMethod: dataSet?.paymentMethod
        };

        await bookClass(payload);

        setOpenPaymentModal(false);

        setOpenPaymentSuccessModal(true);

        setIsFailedToGetCardToken(false);

        toast.success('Make payment successful');
      } catch (error: any) {
        if (error?.response?.data?.errorCode === FAILED_TO_GET_CARD_TOKEN) {
          setIsFailedToGetCardToken(true);
        } else {
          setIsFailedToGetCardToken(false);
          toast.error(error?.response?.data?.message || 'Fail to make payment');
        }
      }
    }
  };

  return (
    <>
      {dataSet?.flow === FLOW_ENUM.CLASS_BOOKING &&
        dataSet?.step === 7 &&
        openPaymentModal && (
          <PaymentModal
            open={openPaymentModal}
            onClose={() => {
              setOpenPaymentModal(false);
              setIsFailedToGetCardToken(false);
            }}
            amount={dataSet?.paymentAmount}
            payerId={dataSet?.responsiblePersonsBooked?.[0]?.info?._id}
            onMakePayment={handlePayment}
            isFailedToGetCardToken={isFailedToGetCardToken}
          />
        )}

      <AppModal open={openPaymentSuccessModal} onClose={handleAfterSuccess}>
        <img
          src="/icons/approve-icon.svg"
          alt=""
          style={{ display: 'flex', margin: '38px auto 14px' }}
        />
        <AppModalContent>
          You have successfully booked the class
        </AppModalContent>
      </AppModal>

      <div
        className={`c_footer-app ${
          dataSet?.flow === FLOW_ENUM.CLASS_BOOKING && dataSet?.step >= 1
            ? 'height_end'
            : 'height_start'
        }`}
      >
        <div className="c_footer-app_content">
          <div>© Viva Labs, a division of Viva Leisure Limited | </div>
          <div>ver 1.0.0</div>
          <img
            src="/icons/document.svg"
            width={15}
            alt=""
            style={{ cursor: 'pointer' }}
            onClick={() => setShowModal(true)}
          />
        </div>

        {dataSet?.flow === FLOW_ENUM.CLASS_BOOKING ? (
          <>
            <div
              className={`c_footer-app_btn ${
                dataSet?.flow === FLOW_ENUM.CLASS_BOOKING && dataSet?.step >= 1
                  ? 'btn_end'
                  : 'btn_start'
              }`}
            >
              <AppButton
                type="button"
                variant="secondary"
                buttonSize="small"
                onClick={() => dataSet?.cleanUpFlow()}
              >
                Cancel
              </AppButton>

              {dataSet?.step >= 2 ? (
                <AppButton
                  type="button"
                  variant="secondary"
                  buttonSize="small"
                  onClick={handleAddStepBackward}
                >
                  Previous
                </AppButton>
              ) : null}

              <AppButton
                type="button"
                buttonSize="small"
                disabled={
                  dataSet?.step === 0 ||
                  dataSet?.step === 2 ||
                  dataSet?.step === 4 ||
                  dataSet?.step === 6
                }
                onClick={() =>
                  dataSet?.step === 1 ||
                  dataSet?.step === 3 ||
                  dataSet?.step === 5
                    ? dataSet?.handleAddStepForward()
                    : dataSet?.step === 7
                    ? handleSubmit()
                    : null
                }
              >
                {dataSet?.step < 6 ? 'Next' : 'Confirm'}
              </AppButton>
            </div>
          </>
        ) : null}
      </div>
      <VersionModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default memo(AppFooter);
