import { IReportCard } from './interface';
import {
  ASSESSMENT_STATISTIC,
  FAMILY_STUDENT_STATUS,
  REPORT_LINK,
  REPORT_VIEW_RESULT,
  STUDENT_OPTION
} from './enum';
import { PROGRAM_TYPE } from 'common/enums';
import { DATE_CONSTANT } from 'common/constants';
import { IOption } from 'common/interfaces';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import { PERMISSION } from 'common/enums/permission.enum';
import { IAssessmentStatisticReport } from 'common/interfaces/report.interface';

export const REPORT_PATH = '/reporting';

export const FIXED_PAGE = 1;
export const FIXED_SIZE = 200;

export const FORMAT_START_OF_DATE = 'YYYY-MM-DD 00:00:00';
export const FORMAT_END_OF_DATE = 'YYYY-MM-DD 23:59:59';
export const FORMAT_TIME = `${DATE_CONSTANT} HH:mm:ss`;

export const ERROR_MESSAGE_DATE =
  'Date to must be equal or greater than date from';
export const ERROR_MESSAGE_TIME =
  'Time to must be equal or greater than time from';

// ASSESSMENT OUTCOME REPORT
const initStatistic: IAssessmentStatisticReport = {
  _id: ASSESSMENT_STATISTIC.NONE,
  count: 0,
  percentage: '0.00%'
};
export const initResultStatisticAchieved = {
  ...initStatistic,
  _id: ASSESSMENT_STATISTIC.ACHIEVED
};
export const initResultStatisticDeveloping = {
  ...initStatistic,
  _id: ASSESSMENT_STATISTIC.DEVELOPING
};
export const initStatusStatisticApproved = {
  ...initStatistic,
  _id: ASSESSMENT_STATISTIC.APPROVED
};
export const initStatusStatisticPending = {
  ...initStatistic,
  _id: ASSESSMENT_STATISTIC.APPROVAL_PENDING
};

export enum WEEKDAY {
  MONDAY = 'mon',
  TUESDAY = 'tue',
  WEDNESDAY = 'wed',
  THURSDAY = 'thur',
  FRIDAY = 'fri',
  SATURDAY = 'sat',
  SUNDAY = 'sun'
}

export const REPORT_PROGRAM_TYPE_OPTIONS: Array<IOption> = [
  {
    label: PROGRAM_TYPE.SERIES,
    value: CLASS_TYPES.SERIES
  },
  {
    label: PROGRAM_TYPE.INTENSIVE_HOLIDAY_PROGRAM,
    value: CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM
  }
];

// EXPORT FAMILY DETAILS REPORT
export const REPORT_STUDENT_FAMILY_OPTIONS: Array<IOption> = [
  {
    label: 'By Student',
    value: STUDENT_OPTION.STUDENT
  },
  {
    label: 'By Family',
    value: STUDENT_OPTION.FAMILY
  }
];

export const REPORT_STUDENT_FAMILY_STATUS_OPTIONS: Array<IOption> = [
  {
    label: 'Booking has not expired as at',
    value: FAMILY_STUDENT_STATUS.ONGOING_BOOKING
  },
  {
    label: 'Booking has not been renewed from Term A to Term B',
    value: FAMILY_STUDENT_STATUS.EXPIRED_BOOKING_WITHOUT_RENEWAL
  },
  {
    label: 'Active booking for the first week of the term',
    value: FAMILY_STUDENT_STATUS.ACTIVE_BOOKING_FIRST_WEEK_OF_THE_TERM
  },
  {
    label: 'Students whose last lesson was between',
    value: FAMILY_STUDENT_STATUS.STUDENT_AND_LAST_LESSON
  },
  {
    label: 'Students who have been booked for a New Student Assessment',
    value: FAMILY_STUDENT_STATUS.BOOKING_NEW_ASSESSMENT
  }
];

export const REPORT_VIEW_RESULT_OPTIONS: Array<IOption> = [
  {
    label: 'Summary View',
    value: REPORT_VIEW_RESULT.SUMMARY_VIEW
  }
  // {
  //   label: 'Detailed View',
  //   value: REPORT_VIEW_RESULT.DETAILED_VIEW
  // }
];

export const REPORT_PAYMENT_TYPE_OPTIONS: Array<IOption> = [
  {
    label: 'Direct Debit',
    value: PAYMENT_VALUE.DIRECT_DEBIT
  },
  {
    label: 'Paid In Full',
    value: PAYMENT_VALUE.UPFRONT
  }
];

export const reportList: Array<IReportCard> = [
  {
    name: 'Student Booking Summary',
    link: REPORT_LINK.STUDENT_BOOKING_SUMMARY,
    permission: PERMISSION.STUDENT_BOOKING_SUMMARY
  },
  {
    name: 'PERIOD SUMMARY REPORT',
    link: REPORT_LINK.PERIOD_SUMMARY_REPORT,
    permission: PERMISSION.PERIOD_SUMMARY_REPORT
  },
  {
    name: 'Member REPORT',
    link: REPORT_LINK.MEMBER_REPORT,
    permission: PERMISSION.MEMBER_REPORT
  },
  {
    name: 'EXPORT FAMILY DETAILS',
    link: REPORT_LINK.EXPORT_FAMILY_DETAILS,
    permission: PERMISSION.FAMILY_REPORT
  },
  {
    name: 'TIMETABLE - BY TEACHER',
    link: REPORT_LINK.TIMETABLE_BY_TEACHER,
    permission: PERMISSION.TIME_TABLE_BY_INSTRUCTOR
  },
  // {
  //   name: 'TIMETABLE - BY SUPERVISOR',
  //   link: REPORT_LINK.TIMETABLE_BY_SUPERVISOR
  // },
  {
    name: 'OUTSTANDINGs',
    link: REPORT_LINK.OUTSTANDINGS,
    permission: PERMISSION.STUDENT_OUTSTANDING_REPORT
  },
  {
    name: 'CREDIT / ADJUSTMENT REPORT',
    link: REPORT_LINK.CREDIT_ADJUSTMENT_REPORT,
    permission: PERMISSION.CREDIT_ADJUSTMENT_REPORT
  },
  {
    name: 'GRADUATED AND NOT MOVED',
    link: REPORT_LINK.GRADUATED_AND_NOT_MOVED,
    permission: PERMISSION.GRADUATION_REPORT
  },
  {
    name: 'ASSESSMENT OUTCOME REPORT',
    link: REPORT_LINK.ASSESSMENT_OUTCOME_REPORT,
    permission: PERMISSION.ASSESSMENT_OUTCOME_REPORT
  },
  {
    name: 'DATE OF LAST ASSESSMENT REPORT',
    link: REPORT_LINK.DATE_OF_LAST_ASSESSMENT_REPORT,
    permission: PERMISSION.DATE_OF_LAST_ASSESSMENT_REPORT
  },
  {
    name: 'DISCOUNTED ENROLMENT REPORT',
    link: REPORT_LINK.DISCOUNTED_ENROLMENT_REPORT,
    permission: PERMISSION.DISCOUNT_ENROLLMENT_REPORT
  },
  {
    name: 'NON ATTENDANCE REPORT',
    link: REPORT_LINK.NOT_ATTENDANCE_REPORT,
    permission: PERMISSION.NON_ATTENDED_REPORT
  },
  {
    name: 'ABANDONED CART LIST',
    link: REPORT_LINK.ABANDONED_CARD_LIST,
    permission: PERMISSION.ABANDONED_CART_REPORT

  },
  {
    name: 'GST REPORT',
    link: REPORT_LINK.GST_REPORT,
    permission: PERMISSION.GST_REPORT
  }
];

export const DAY_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const mockPeriodDetailedViewData = [
  {
    _id: '0f2f4119-c72c-5614-82ca-3eaee9bb1746',
    programType: PROGRAM_TYPE.SERIES,
    studentId: '1025',
    firstName: 'Christina',
    lastName: 'Banks',
    rpName: 'Hallie Caldwell',
    rpMobile: '26.5762',
    firstLessonDate: '02-02-2024',
    paid: 0,
    owing: 0,
    classDetail: 'mONDAY, 1ST jAN 8:00 am - bb L1 - aLEX'
  },
  {
    _id: '90d4a577-f47b-5f97-b5d0-66ae8fda928b',
    programType: PROGRAM_TYPE.SERIES,
    studentId: '1025',
    firstName: 'Calvin',
    lastName: 'Lewis',
    rpName: 'Jeffery Hansen',
    rpMobile: '54.7824',
    firstLessonDate: '02-02-2024',
    paid: 0,
    owing: 0,
    classDetail: 'mONDAY, 1ST jAN 8:00 am - bb L1 - aLEX'
  },
  {
    _id: '2c212e3e-992d-52bf-9580-14f4f8d1c9e1',
    programType: PROGRAM_TYPE.SERIES,
    studentId: '1025',
    firstName: 'Jeffrey',
    lastName: 'White',
    rpName: 'Lettie Graham',
    rpMobile: '7.2297',
    firstLessonDate: '02-02-2024',
    paid: 0,
    owing: 0,
    classDetail: 'mONDAY, 1ST jAN 8:00 am - bb L1 - aLEX'
  },
  {
    _id: '6d864fc1-64a9-5432-a021-ea142e40fec0',
    programType: PROGRAM_TYPE.SERIES,
    studentId: '1025',
    firstName: 'Ruth',
    lastName: 'Horton',
    rpName: 'Kate Tyler',
    rpMobile: '56.3316',
    firstLessonDate: '02-02-2024',
    paid: 0,
    owing: 0,
    classDetail: 'mONDAY, 1ST jAN 8:00 am - bb L1 - aLEX'
  },
  {
    _id: '6fc27464-8a29-5781-9784-db560deddafb',
    programType: PROGRAM_TYPE.SERIES,
    studentId: '1025',
    firstName: 'Genevieve',
    lastName: 'Wise',
    rpName: 'Stephen Delgado',
    rpMobile: '82.7179',
    firstLessonDate: '02-02-2024',
    paid: 0,
    owing: 0,
    classDetail: 'mONDAY, 1ST jAN 8:00 am - bb L1 - aLEX'
  },
  {
    _id: 'd7f59612-9566-5f72-86cb-4337ef501c73',
    programType: PROGRAM_TYPE.SERIES,
    studentId: '1025',
    firstName: 'Hulda',
    lastName: 'French',
    rpName: 'Ryan Nguyen',
    rpMobile: '64.317',
    firstLessonDate: '02-02-2024',
    paid: 0,
    owing: 0,
    classDetail: 'mONDAY, 1ST jAN 8:00 am - bb L1 - aLEX'
  }
];

export const mockExportFamilyDetailsData = [
  {
    _id: 'eb58d72c-1b7b-57b5-be25-6c29166387f4',
    studentId: '1025',
    firstName: 'Christina',
    lastName: 'Banks',
    studentAge: '6 months - 18 months',
    lessonTime: '02-02-2024',
    lessonDay: 'mONDAY, 1ST jAN 8:00 am - bb L1 - aLEX - A1',
    lessonLevel: '2134',
    lessonArea: 'wARREN, wAVE',
    instructorName: 'Instructor name',
    studentEnrolmentStartDate: 'Student Enrolment start date',
    rpId: '2134',
    rpName: 'Hallie Caldwell',
    rpMobile: '26.5762',
    rpEmail: 'jackson.graham@example.com',
    moneyOutstanding: 0,
    enrolmentPaymentType: 'direct debit'
  },
  {
    _id: 'a7574fe4-5421-5fb7-bb62-294c25196e7c',
    studentId: '1025',
    firstName: 'Christina',
    lastName: 'Banks',
    studentAge: '6 months - 18 months',
    lessonTime: '02-02-2024',
    lessonDay: 'mONDAY, 1ST jAN 8:00 am - bb L1 - aLEX - A1',
    lessonLevel: '2134',
    lessonArea: 'wARREN, wAVE',
    instructorName: 'Instructor name',
    studentEnrolmentStartDate: 'Student Enrolment start date',
    rpId: '2134',
    rpName: 'Hallie Caldwell',
    rpMobile: '26.5762',
    rpEmail: 'jackson.graham@example.com',
    moneyOutstanding: 0,
    enrolmentPaymentType: 'direct debit'
  },
  {
    _id: 'c306fe3d-a58f-5785-9ad0-64ec55bd61b0',
    studentId: '1025',
    firstName: 'Christina',
    lastName: 'Banks',
    studentAge: '6 months - 18 months',
    lessonTime: '02-02-2024',
    lessonDay: 'mONDAY, 1ST jAN 8:00 am - bb L1 - aLEX - A1',
    lessonLevel: '2134',
    lessonArea: 'wARREN, wAVE',
    instructorName: 'Instructor name',
    studentEnrolmentStartDate: 'Student Enrolment start date',
    rpId: '2134',
    rpName: 'Hallie Caldwell',
    rpMobile: '26.5762',
    rpEmail: 'jackson.graham@example.com',
    moneyOutstanding: 0,
    enrolmentPaymentType: 'direct debit'
  },
  {
    _id: 'e6e1ba5b-4e5d-5f15-820f-301f35d65783',
    studentId: '1025',
    firstName: 'Christina',
    lastName: 'Banks',
    studentAge: '6 months - 18 months',
    lessonTime: '02-02-2024',
    lessonDay: 'mONDAY, 1ST jAN 8:00 am - bb L1 - aLEX - A1',
    lessonLevel: '2134',
    lessonArea: 'wARREN, wAVE',
    instructorName: 'Instructor name',
    studentEnrolmentStartDate: 'Student Enrolment start date',
    rpId: '2134',
    rpName: 'Hallie Caldwell',
    rpMobile: '26.5762',
    rpEmail: 'jackson.graham@example.com',
    moneyOutstanding: 0,
    enrolmentPaymentType: 'direct debit'
  },
  {
    _id: '7c60fe27-1a51-5ea2-b8de-335efad512be',
    studentId: '1025',
    firstName: 'Christina',
    lastName: 'Banks',
    studentAge: '6 months - 18 months',
    lessonTime: '02-02-2024',
    lessonDay: 'mONDAY, 1ST jAN 8:00 am - bb L1 - aLEX - A1',
    lessonLevel: '2134',
    lessonArea: 'wARREN, wAVE',
    instructorName: 'Instructor name',
    studentEnrolmentStartDate: 'Student Enrolment start date',
    rpId: '2134',
    rpName: 'Hallie Caldwell',
    rpMobile: '26.5762',
    rpEmail: 'jackson.graham@example.com',
    moneyOutstanding: 0,
    enrolmentPaymentType: 'direct debit'
  }
];
