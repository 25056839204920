import React from 'react';
import ReportPeriodSummaryView from './ReportPeriodSummaryView';
import ReportPeriodDetailedView from './ReportPeriodDetailedView';
import AppBreadCrumb from 'components/common/AppBreadcrumb';
import AppButton from 'common/components/AppButton';
import AppSelect from 'common/components/AppSelect';
import AppDatePicker from 'common/components/AppDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useToast } from 'context/ToastContext';
import { useBrandLocation } from 'context/BrandLocationContext';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { REPORT_VIEW_RESULT } from '../enum';
import { PAYMENT_VALUE } from 'common/enums/classBooking.enum';
import ReportSelection from '../components/ReportSelection';
import { uniq } from 'lodash';
import { getPeriodSummaryReport } from 'services/report.service';
import { convertToUnixTime } from 'common/helpers/time.helper';
import { IPeriodSummaryView } from 'common/interfaces/report.interface';
import AppLoadingContainer from 'common/components/AppLoadingContainer';
import {
  ERROR_MESSAGE_DATE,
  FORMAT_END_OF_DATE,
  FORMAT_START_OF_DATE,
  REPORT_PATH,
  REPORT_PAYMENT_TYPE_OPTIONS,
  REPORT_PROGRAM_TYPE_OPTIONS,
  REPORT_VIEW_RESULT_OPTIONS
} from '../constant';
import { IOption } from 'common/interfaces';
import { renderCamelCaseToSpace } from '../helper';
import './desktop.scss';

interface IFilter {
  dateFrom: string;
  dateTo: string;
  programType: string;
  viewResults: string;
  paymentTypes: Array<string>;
}

const initFilter: IFilter = {
  dateFrom: '',
  dateTo: '',
  programType: '',
  viewResults: '',
  paymentTypes: []
};

const ReportPeriodSummary = () => {
  const toast = useToast();
  const { selectedLocation: __globalLocation } = useBrandLocation();

  const dateError = React.useRef<string>('');

  const [viewMode, setViewMode] = React.useState<REPORT_VIEW_RESULT | ''>('');
  const [isGenerated, setIsGenerated] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  // INIT DATA FOR FILTER BY
  const [initPeriodSummaryView, setInitPeriodSummaryView] =
    React.useState<IPeriodSummaryView | null>(null);
  // SUMMARY VIEW
  const [periodSummaryView, setPeriodSummaryView] =
    React.useState<IPeriodSummaryView | null>(null);

  // Search section
  const [dateFrom, setDateFrom] = React.useState<string>('');
  const [dateTo, setDateTo] = React.useState<string>('');
  const [programType, setProgramType] = React.useState<CLASS_TYPES | ''>('');
  const [viewResults, setViewResults] = React.useState<REPORT_VIEW_RESULT | ''>(
    ''
  );
  const [paymentTypes, setPaymentTypes] = React.useState<Array<string>>([]);

  const filter = React.useRef<IFilter>(initFilter);

  const __filterOptions: Array<IOption> = React.useMemo(() => {
    if (!initPeriodSummaryView) return [];
    return Object.keys(initPeriodSummaryView).map((item: string) => {
      return {
        label: renderCamelCaseToSpace(item),
        value: item
      };
    });
  }, [initPeriodSummaryView]);

  const __paymentTypePlaceholderText: string = React.useMemo(() => {
    return paymentTypes.length === 0
      ? 'Select option'
      : `${paymentTypes.length} selected`;
  }, [paymentTypes]);

  const __isDisableGenerateButton: boolean = React.useMemo(() => {
    return (
      !dateFrom ||
      !dateTo ||
      !programType ||
      paymentTypes.length === 0 ||
      !viewResults ||
      !!dateError.current
    );
  }, [dateFrom, dateTo, programType, paymentTypes, viewResults, dateError]);

  const handleChangeDateFrom = (value: Dayjs | null) => {
    setDateFrom(value?.format(FORMAT_START_OF_DATE) || '');
    if (value?.isAfter(dayjs(dateTo))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeDateTo = (value: Dayjs | null) => {
    setDateTo(value?.format(FORMAT_END_OF_DATE) || '');
    if (value?.isBefore(dayjs(dateFrom))) {
      dateError.current = ERROR_MESSAGE_DATE;
    } else {
      dateError.current = '';
    }
  };
  const handleChangeProgramType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setProgramType(event.target.value as CLASS_TYPES);
  };
  const handleChangeViewResults = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setViewResults(event.target.value as REPORT_VIEW_RESULT);
  };
  const handleTogglePaymentType = (value: string) => {
    const newPaymentTypes = [...paymentTypes];
    const findIndex = newPaymentTypes.findIndex((levelId) => levelId === value);
    if (findIndex === -1) {
      newPaymentTypes.push(value as PAYMENT_VALUE);
    } else {
      newPaymentTypes.splice(findIndex, 1);
    }
    setPaymentTypes(newPaymentTypes);
  };
  const handleSelectAllPaymentType = () => {
    const values = REPORT_PAYMENT_TYPE_OPTIONS.map((paymentType) =>
      paymentType.value?.toString()
    );
    if (paymentTypes.length === REPORT_PAYMENT_TYPE_OPTIONS.length) {
      setPaymentTypes([]);
    } else {
      setPaymentTypes(uniq(values.concat(paymentTypes)));
    }
  };

  const handleGenerate = () => {
    filter.current = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      programType: programType,
      paymentTypes: paymentTypes,
      viewResults: viewResults
    };
    fetchPeriodSummaryReport();
  };

  const fetchPeriodSummaryReport = async () => {
    if (!__globalLocation?._id) return;

    setLoading(true);
    try {
      const result = await getPeriodSummaryReport(
        __globalLocation._id,
        convertToUnixTime(filter.current.dateFrom),
        convertToUnixTime(filter.current.dateTo),
        filter.current.programType as CLASS_TYPES,
        filter.current.paymentTypes.join(',').trim(),
        filter.current.viewResults as REPORT_VIEW_RESULT
      );

      if (viewResults === REPORT_VIEW_RESULT.SUMMARY_VIEW) {
        setPeriodSummaryView(result?.data?.data);
        setViewMode(REPORT_VIEW_RESULT.SUMMARY_VIEW);
      } else if (viewResults === REPORT_VIEW_RESULT.DETAILED_VIEW) {
        setViewMode(REPORT_VIEW_RESULT.DETAILED_VIEW);
      }

      setIsGenerated(true);
    } catch (error: any) {
      setPeriodSummaryView(null);
      setViewMode('');
      toast.error(
        error?.response?.data?.message || 'Failed to get period summary report'
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchInitData = React.useCallback(async () => {
    if (!__globalLocation?._id) return;
    try {
      const result = await getPeriodSummaryReport(
        __globalLocation._id,
        convertToUnixTime(dayjs().format(FORMAT_START_OF_DATE)),
        convertToUnixTime(dayjs().format(FORMAT_END_OF_DATE)),
        CLASS_TYPES.SERIES,
        `${PAYMENT_VALUE.UPFRONT},${PAYMENT_VALUE.DIRECT_DEBIT}`,
        REPORT_VIEW_RESULT.SUMMARY_VIEW
      );
      setInitPeriodSummaryView(result?.data?.data);
    } catch (error: any) {
      setInitPeriodSummaryView(null);
      toast.error(error?.response?.data?.message || 'Failed to get init data');
    }
    // eslint-disable-next-line
  }, [__globalLocation?._id]);

  React.useEffect(() => {
    fetchInitData();
  }, [fetchInitData]);

  return (
    <main
      id="reportStudentBookingSummary"
      className="reportStudentBookingSummary"
    >
      <AppBreadCrumb
        items={[
          { name: 'Reporting', href: REPORT_PATH },
          { name: 'Period Summary Report' }
        ]}
      />
      <div className="layoutContainer reportStudentBookingSummary__wrapper">
        <section className="reportStudentBookingSummary__search">
          <div className="reportStudentBookingSummary__search__fields">
            <AppDatePicker
              size="small"
              label="Date from"
              value={dayjs(dateFrom)}
              onChange={handleChangeDateFrom}
            />
            <AppDatePicker
              size="small"
              label="Date to"
              value={dayjs(dateTo)}
              onChange={handleChangeDateTo}
              message={{
                type: 'error',
                text: dateError.current
              }}
            />
            <ReportSelection
              label="Payment Type"
              placeholder={__paymentTypePlaceholderText}
              options={REPORT_PAYMENT_TYPE_OPTIONS}
              onSelect={handleTogglePaymentType}
              onSelectAll={handleSelectAllPaymentType}
              selectedIds={paymentTypes}
            />
            <AppSelect
              searchable={false}
              inputSize="small"
              label="Program Type"
              placeholder="Select option"
              options={REPORT_PROGRAM_TYPE_OPTIONS}
              value={programType}
              onChange={handleChangeProgramType}
            />
            <AppSelect
              searchable={false}
              inputSize="small"
              label="View Results"
              placeholder="Select option"
              options={REPORT_VIEW_RESULT_OPTIONS}
              value={viewResults}
              onChange={handleChangeViewResults}
            />
          </div>
          <AppButton
            variant="primary"
            buttonSize="small"
            isLoading={loading}
            disabled={__isDisableGenerateButton}
            onClick={handleGenerate}
          >
            generate
          </AppButton>
        </section>

        {(!isGenerated || viewMode !== viewResults) && loading && (
          <AppLoadingContainer />
        )}

        {isGenerated &&
          periodSummaryView &&
          !loading &&
          viewMode === REPORT_VIEW_RESULT.SUMMARY_VIEW && (
            <ReportPeriodSummaryView periodSummaryView={periodSummaryView} />
          )}

        {isGenerated &&
          !loading &&
          viewMode === REPORT_VIEW_RESULT.DETAILED_VIEW && (
            <ReportPeriodDetailedView filterOptions={__filterOptions} />
          )}
      </div>
    </main>
  );
};

export default ReportPeriodSummary;
