import { object as OBJECT, boolean, string, addMethod } from 'yup';

//  ===== LOCATION_AREA  =====

const LOCATION_AREA_NAME = string().required('Please provide area name');
const LOCATION_AREA_POOL = string().required('Please provide area pool');
const LOCATION_AREA_DESCRIPTION = string().required(
  'Please provide area description'
);
const LOCATION_AREA_ACTIVE = boolean().required('Please provide area active');

//  ===== END LOCATION_AREA  =====

const validations = {
  OBJECT,
  string,
  addMethod,
  LOCATION_AREA_NAME,
  LOCATION_AREA_POOL,
  LOCATION_AREA_DESCRIPTION,
  LOCATION_AREA_ACTIVE
};
export default validations;
