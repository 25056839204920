import React, { useRef, useState } from 'react';
import { HiMiniEllipsisVertical } from 'react-icons/hi2';
import { IAbsence } from 'common/interfaces/absence.interface';
import { ABSENCE_STATUS } from 'common/enums/absence.enum';

interface IActionPopperProps {
  item: IAbsence;
  onViewAbsence?: () => void;
  onApproveAbsence?: () => void;
  onDeclineAbsence?: () => void;
}

const ActionPopper = (props: IActionPopperProps) => {
  const { item, onViewAbsence, onApproveAbsence, onDeclineAbsence } = props;

  const popperRef = useRef<any>(null);
  const verticalButtonRef = useRef<any>(null);

  const [open, setOpen] = useState<boolean>(false);

  const onToggle = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    // Close popper if click outside the popper
    const handleClickOutside = (event: MouseEvent) => {
      // Click at vertical button to close
      if (
        verticalButtonRef.current &&
        verticalButtonRef.current.contains(event.target)
      ) {
        return;
      }
      // Handle click outside to close pop up
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        onClose();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [verticalButtonRef, popperRef]);

  return (
    <React.Fragment>
      <div
        ref={verticalButtonRef}
        className="enrollmentAbsenceForm__ellipsisVertical"
      >
        <HiMiniEllipsisVertical onClick={onToggle} />

        {open && (
          <div ref={popperRef} className="viewDetailPopper">
            {!!onViewAbsence && (
              <div className="viewDetailPopper-item" onClick={onViewAbsence}>
                View Absence
              </div>
            )}
            {item.status === ABSENCE_STATUS.PENDING && !!onApproveAbsence && (
              <div className="viewDetailPopper-item" onClick={onApproveAbsence}>
                Approve Absence
              </div>
            )}
            {item.status === ABSENCE_STATUS.PENDING && !!onDeclineAbsence && (
              <div className="viewDetailPopper-item" onClick={onDeclineAbsence}>
                Decline Absence
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ActionPopper;
