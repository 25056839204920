import { DISCOUNT_TYPE } from 'common/enums';
import { CLASS_TYPES } from 'common/enums/class.enum';
import { BOOKING_TYPE, PAYMENT_METHOD } from 'common/enums/classBooking.enum';
import { PERMISSION } from 'common/enums/permission.enum';
import { ENROLLMENT_STEP } from 'common/enums/student.enum';
import { TERM_TYPE } from 'common/enums/term.enum';
import { VOUCHER_DURATION } from 'common/enums/voucher.enum';
import { roundByTwo } from 'common/helpers/dataFormat.helper';
import { PAYMENT_METHOD_TYPE } from 'common/interfaces/bookingClass.interface';
import {
  ISchedule,
  ISchedulesOrigin
} from 'common/interfaces/schedules.interface';
import {
  IActiveOngoingStudent,
  IStudentBookingData
} from 'common/interfaces/student.interface';
import { ITerm } from 'common/interfaces/term.interface';
import { useAuth } from 'context/AuthContext';
import { useBrandLocation } from 'context/BrandLocationContext';
import { useMoveBooking } from 'context/MoveBookingContext';
import { useToast } from 'context/ToastContext';
import { handleGetPayNow } from 'helpers/enrolment.helper';
import { cloneDeep, map } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getClassSessions } from 'services/dashboard.service';
import {
  getResponsiblePersonDetail,
  getStudentsActiveOngoingOfRP
} from 'services/responsiblePerson.service';
import { getStudentDetail } from 'services/students.service';
import { getTerms } from 'services/term.service';

interface EnrollmentContextType {
  students: Array<IStudentBookingData>;
  setStudents: React.Dispatch<React.SetStateAction<IStudentBookingData[]>>;
  selectedClassIds: Array<string>;
  setSelectedClassIds: React.Dispatch<React.SetStateAction<Array<string>>>;
  classesData: ISchedulesOrigin[];
  paymentMethod: PAYMENT_METHOD_TYPE;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PAYMENT_METHOD_TYPE>>;
  canConfirm: boolean;
  setCanConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  finalData: IStudentBookingData[];
  setFinalData: React.Dispatch<React.SetStateAction<IStudentBookingData[]>>;
  stopBillingTerms: ITerm[];
  moneyCredit: number;
  activeOngoingStudents: IActiveOngoingStudent[];
  arraySchedulesByWeek: HolidaySchedule[];
  onChangeArraySchedulesByWeek: (value: HolidaySchedule[]) => void;
  __discountAmount: number;
}

const EnrollmentContext = createContext<EnrollmentContextType | null>(null);

export const useEnrollmentContext = (): EnrollmentContextType => {
  const enrollmentContext = useContext(EnrollmentContext);
  if (!enrollmentContext) {
    throw new Error(
      'useEnrollmentContext must be used within an EnrollmentContext'
    );
  }
  return enrollmentContext;
};

type HolidaySchedule = {
  dateLabel: string;
  schedules: ISchedule[];
};
export const EnrollmentProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children
}) => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedLocation } = useBrandLocation();
  const { nextDebitDay } = useMoveBooking();
  const toast = useToast();
  const { hasPermission } = useAuth();

  const [students, setStudents] = useState<IStudentBookingData[]>([]);
  const [selectedClassIds, setSelectedClassIds] = useState<Array<string>>([]);
  const [classesData, setClassesData] = useState<ISchedulesOrigin[]>([]);
  const [classesDataForCapacity, setClassesDataForCapacity] = useState<
    ISchedulesOrigin[]
  >([]);
  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_METHOD_TYPE>(
    PAYMENT_METHOD.VIVA_PAY
  );
  const [canConfirm, setCanConfirm] = useState<boolean>(true);
  const [finalData, setFinalData] = useState<IStudentBookingData[]>([]);
  const [stopBillingTerms, setStopBillingTerms] = useState<ITerm[]>([]);
  const [moneyCredit, setMoneyCredit] = useState<number>(0);
  const [arraySchedulesByWeek, setArraySchedulesByWeek] = useState<
    HolidaySchedule[]
  >([]);

  // Automatic discount
  const [activeOngoingStudents, setActiveOngoingStudents] = useState<
    IActiveOngoingStudent[]
  >([]);

  const __discountAmount = useMemo(() => {
    return finalData.reduce((result: number, student) => {
      student.bookingData.forEach((bookingData) => {
        const { pricePayNow = 0, classInfo } = bookingData;
        const { voucher } = bookingData;
        if (voucher && voucher.code) {
          const { discountType, discountValue } = voucher;

          let amount = 0;
          if (discountType === DISCOUNT_TYPE.PERCENTAGE) {
            if (pricePayNow > 0)
              amount = roundByTwo(pricePayNow * (discountValue / 100));
          } else if (discountType === DISCOUNT_TYPE.AMOUNT) {
            if (pricePayNow > 0) {
              amount =
                discountValue < pricePayNow ? discountValue : pricePayNow;
            }
          } else if (discountType === DISCOUNT_TYPE.COMPLIMENTARY) {
            if (
              !!voucher.numberOfLesson &&
              voucher.duration === VOUCHER_DURATION.SPECIFIC_NUMBER_OF_LESSONS
            ) {
              const discountPrice = roundByTwo(
                voucher.numberOfLesson * classInfo.price
              );
              amount =
                pricePayNow - discountPrice > 0 ? discountPrice : pricePayNow;
            } else if (voucher.duration === VOUCHER_DURATION.TIME_RANGE) {
              const pricePayNowActual = handleGetPayNow(
                bookingData,
                stopBillingTerms,
                nextDebitDay
              );
              amount = roundByTwo(pricePayNow - pricePayNowActual);
            }
          }

          result += amount;
        } else {
          if (bookingData?.automaticDiscount) {
            if (pricePayNow > 0)
              result += roundByTwo(
                pricePayNow * (bookingData?.automaticDiscount.value / 100)
              );
          }
        }
      });
      return result;
    }, 0);
  }, [finalData, stopBillingTerms, nextDebitDay]);

  const fetchDataClasses = useCallback(async () => {
    try {
      const data: ISchedulesOrigin[] = await getClassSessions(selectedClassIds);
      const classesData = data.map((item: ISchedulesOrigin) => {
        let enrollmentType = BOOKING_TYPE.NONE;
        switch (item?.classInfo?.type) {
          case CLASS_TYPES.ASSESSMENT_TRIAL:
            enrollmentType = BOOKING_TYPE.ASSESSMENT_TRIAL;
            break;
          case CLASS_TYPES.INTENSIVE_HOLIDAY_PROGRAM:
            enrollmentType = BOOKING_TYPE.HOLIDAY_PROGRAM;
            break;

          default:
            break;
        }

        return {
          ...item,
          enrollmentType
        };
      });
      setClassesData(classesData);
      setClassesDataForCapacity(classesData);
      setStudents(
        students.map((student) => {
          return {
            ...student,
            classesData
          };
        })
      );
    } catch (error: any) {
      toast.error(error?.response?.data?.message || `Can't fetch classes data`);
    }
    // eslint-disable-next-line
  }, [selectedClassIds, searchParams]);

  useEffect(() => {
    if (!selectedClassIds.length) {
      searchParams.set('stepBooking', ENROLLMENT_STEP.BOOKING);
      setSearchParams(searchParams);
      return;
    } else {
      fetchDataClasses();
    }
    // eslint-disable-next-line
  }, [selectedClassIds]);

  const fetchActiveOngoingStudents = useCallback(async () => {
    if (!params.RPId || !hasPermission(PERMISSION.COUNT_STUDENT_ACTIVE_ONGOING))
      return;
    try {
      const { data } = await getStudentsActiveOngoingOfRP(params.RPId);
      setActiveOngoingStudents(data.data);
    } catch (error: any) {
      setActiveOngoingStudents([]);
      toast.error(
        error?.response?.data?.message || 'Get active ongoing failed'
      );
    }
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    const step = searchParams.get('stepBooking');
    if (step === ENROLLMENT_STEP.ENROLLMENT) {
      let classDataTemp: ISchedulesOrigin[] = cloneDeep(classesDataForCapacity);
      if (!classDataTemp.length) return;

      const newClassesData = map(classDataTemp, (classData) => {
        const scheduleIds: string[] = [];
        students.forEach((student) => {
          student?.classesData?.forEach((classDataInStudent) => {
            if (
              classDataInStudent.checked &&
              classDataInStudent._id === classData._id
            ) {
              scheduleIds.push(
                ...classDataInStudent.schedules
                  .filter((scheduleInStudent) => {
                    return scheduleInStudent.checked;
                  })
                  .map((scheduleInStudent) => scheduleInStudent._id)
              );
            }
          });
        });
        classData.schedules = map(classData.schedules, (scheduleOriginItem) => {
          map(scheduleIds, (scheduleId) => {
            if (scheduleOriginItem._id === scheduleId) {
              scheduleOriginItem.occupied = scheduleOriginItem.occupied + 1;
            }
          });
          return scheduleOriginItem;
        });
        return classData;
      });
      setClassesData(newClassesData);
    }
    if (step === ENROLLMENT_STEP.PAYMENT) {
      fetchActiveOngoingStudents();
    }
  }, [
    students,
    classesDataForCapacity,
    searchParams,
    fetchActiveOngoingStudents
  ]);

  const fetchStudents = useCallback(async () => {
    const studentIds = params.studentIds?.split(',');
    if (!studentIds) return;
    try {
      const data = await Promise.all(
        studentIds.map((id) => getStudentDetail(id))
      );
      setStudents(data);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Get student failed');
      setStudents([]);
    }
    if (selectedClassIds.length) {
      fetchDataClasses();
    }
    // eslint-disable-next-line
  }, [params.studentIds]);

  const fetchResponsiblePerson = useCallback(async () => {
    if (!params.RPId) return;
    try {
      const { data } = await getResponsiblePersonDetail(params.RPId);

      setMoneyCredit(data?.data.additionalInfo.moneyCredit || 0);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Get RP detail failed');
    }
    // eslint-disable-next-line
  }, [params.RPId]);

  useEffect(() => {
    fetchResponsiblePerson();
  }, [fetchResponsiblePerson]);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  useEffect(() => {
    setSelectedClassIds([]);
  }, [selectedLocation?._id]);

  const fetchStopBilling = useCallback(async () => {
    if (!selectedLocation?._id) return;
    try {
      const { data } = await getTerms(
        1,
        100,
        selectedLocation?._id,
        undefined,
        [TERM_TYPE.STOP_BILLING],
        true
      );
      setStopBillingTerms(data.data.data);
    } catch (error: any) {
      setStopBillingTerms([]);
    }
  }, [selectedLocation?._id]);

  useEffect(() => {
    fetchStopBilling();
  }, [fetchStopBilling]);

  const onChangeArraySchedulesByWeek = useCallback(
    (value: HolidaySchedule[]) => {
      setArraySchedulesByWeek(value);
    },
    []
  );

  const enrollmentContextValue: EnrollmentContextType = {
    students,
    setStudents,
    selectedClassIds,
    setSelectedClassIds,
    classesData,
    paymentMethod,
    setPaymentMethod,
    canConfirm,
    setCanConfirm,
    finalData,
    setFinalData,
    stopBillingTerms,
    activeOngoingStudents,
    moneyCredit,
    arraySchedulesByWeek,
    onChangeArraySchedulesByWeek,
    __discountAmount
  };

  return (
    <EnrollmentContext.Provider value={enrollmentContextValue}>
      {children}
    </EnrollmentContext.Provider>
  );
};
