import { ABSENCE_STATUS } from 'common/enums/absence.enum';
import API from './API';
import {
  ApproveAbsenceDTO,
  CreateAbsenceDTO,
  CreateBulkAbsenceDTO,
  DeclineAbsenceDTO,
  UpdateAbsenceDTO,
  UpdateAbsenceInfoDTO
} from 'DTOs/absence.dto';

const ABSENCE_ENDPOINT = '/absences';

export const getAbsencesByStudent = (
  studentId: string,
  page: number,
  limit: number,
  status?: ABSENCE_STATUS
) => {
  const params: {
    studentId: string;
    page: number;
    limit: number;
    status?: ABSENCE_STATUS;
  } = {
    studentId,
    page,
    limit
  };
  if (typeof status !== 'undefined') params.status = status;
  return API.get(`${ABSENCE_ENDPOINT}`, params);
};

export const getAbsencesByRP = (
  responsiblePersonId: string,
  page: number,
  limit: number,
  status?: ABSENCE_STATUS
) => {
  const params: {
    responsiblePersonId: string;
    page: number;
    limit: number;
    status?: ABSENCE_STATUS;
  } = {
    responsiblePersonId,
    page,
    limit
  };
  if (typeof status !== 'undefined') params.status = status;
  return API.get(`${ABSENCE_ENDPOINT}`, params);
};

export const getAbsencesByClass = (
  classId: string,
  page: number,
  limit: number,
  status?: ABSENCE_STATUS
) => {
  const params: {
    classId: string;
    page: number;
    limit: number;
    status?: ABSENCE_STATUS;
  } = {
    classId,
    page,
    limit
  };
  if (typeof status !== 'undefined') params.status = status;
  return API.get(`${ABSENCE_ENDPOINT}`, params);
};

export const createAbsences = (payload: CreateAbsenceDTO) => {
  return API.post(`${ABSENCE_ENDPOINT}`, {
    body: payload
  });
};

export const createAbsencesBulk = (payload: CreateBulkAbsenceDTO) => {
  return API.post(`${ABSENCE_ENDPOINT}/bulk-absence`, {
    body: payload
  });
};

export const updateAbsences = (id: string, payload: UpdateAbsenceDTO) => {
  return API.patch(`${ABSENCE_ENDPOINT}/${id}`, {
    body: payload
  });
};

export const updateAbsencesInfo = (
  id: string,
  payload: UpdateAbsenceInfoDTO
) => {
  return API.patch(`${ABSENCE_ENDPOINT}/${id}`, {
    body: payload
  });
};

export const approveAbsences = (id: string, payload: ApproveAbsenceDTO) => {
  return API.patch(`${ABSENCE_ENDPOINT}/${id}`, {
    body: payload
  });
};

export const declineAbsences = (id: string, payload: DeclineAbsenceDTO) => {
  return API.patch(`${ABSENCE_ENDPOINT}/${id}`, {
    body: payload
  });
};
